import React, { Component } from 'react'
import './inventory.css'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { Trans, withTranslation } from 'react-i18next'
import { t } from 'i18next'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { TextField, Button, Box, Modal, Tooltip, FormControl, InputLabel, Select, MenuItem, FormHelperText, Stack } from '@mui/material'
import { DateTime } from 'luxon'
import DatePicker from '../../../Components/DatePicker/DatePicker'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents'



class NewDrug extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: 10,
      newDrugListData: [],
      newDrugPopUp: [],
      QueryFromDate: "",
      QueryToDate: "",
      dateFilter: 0,
      searchkey : '',
      disableBtn: false,
    }
  }

  componentDidMount() {
    this.getNewDrugList()
  }

  componentWillReceiveProps(props){
    if(props.search_key != this.state.searchkey){
        this.setState({
            searchkey: props.search_key
        },() => {
            this.getNewDrugList()
        })
    }
  }
  LoaderFunction=(key)=>{
    this.setState({
      isLoader:key
    })
 }
  getNewDrugList = () => {
    this.LoaderFunction(true)
    RestAPIService.getAll(Serviceurls.INVENTORY_NEW_DRUG_GET + "?from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate + `&search_key=${this.state.searchkey}`).
      then((response) => {
        if (response.data.status === "success") {
          var Data = response.data.data ? response.data.data : []
          this.setState({
            newDrugListData: Data
          },()=>{this.LoaderFunction(false)})
        }
      })
  }
  CustomDates = (FromDate, ToDate) => {
    this.setState({
      QueryFromDate: FromDate,
      QueryToDate: ToDate,
      datePickerPop: false
    }, () => this.getNewDrugList())
  }

  PopClose = () => {
    this.setState({
      datePickerPop: false,
      QueryFromDate: "",
      QueryToDate: "",
      dateFilter: 0,
    })
  }

  handleDate = () => {
    var today = DateTime.now().toFormat('yyyy-MM-dd')
    var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
    var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
    var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
    var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
    var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
    const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
    const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');


    if (this.state.dateFilter === 0) {
      this.setState({
        QueryFromDate: "",
        QueryToDate: "",
      }, () => this.getNewDrugList())
    }
    else if (this.state.dateFilter === 1) {
      this.setState({
        QueryFromDate: today,
        QueryToDate: today,
      }, () => this.getNewDrugList()
      )

    }
    else if (this.state.dateFilter === 2) {
      this.setState({
        QueryFromDate: yesterday,
        QueryToDate: yesterday,
      }, () => this.getNewDrugList())
    }
    else if (this.state.dateFilter === 3) {
      this.setState({
        QueryFromDate: startOfPreviousWeek,
        QueryToDate: endOfPreviousWeek,
      }, () => this.getNewDrugList())
    }
    else if (this.state.dateFilter === 4) {
      this.setState({
        QueryFromDate: startOfPreviousMonth,
        QueryToDate: endOfPreviousMonth,
      }, () => this.getNewDrugList())
    }
    else if (this.state.dateFilter === 5) {
      this.setState({ datePickerPop: true })
    }
  }

  DateHelperText = () => {
    if (this.state.dateFilter === 0) {
      return ""
    }
    else if (this.state.dateFilter === 1) {
      return DateTime.now().toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 2) {
      return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 3) {
      // return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
      const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
      return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      
    }
    else if (this.state.dateFilter === 4) {
      // return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
      const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
      return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`
     
    }
    else if (this.state.dateFilter === 5) {
      return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
    }
  }

  putNewDrugList = () => {
    let data = {
      "id": 4,
      "material_type": "",
      "brand_name": "",
      "product_name": "",
      "product_type": "",
      "unit": "",
      "vendor_id": 1,
      "manufacturer_name": "",
      "physical_box_no": "",
      "reorder_threshold": 300,
      "hsn_code": "",
      "gst_percentage": 2.7,
      "expiry_alert_period": 3

    }
    // RestAPIService.updateWithOutId(data,Serviceurls.INVENTORY_NEW_DRUG_PUT).
    // then((response)=>{
    //   if(response.data.status === "success"){
    //     alert(response.data.message)
    //   }else{
    //     alert(response.data.message)
    //   }
    // }).catch((error)=> alert(error.message))
  }

  newDrugViewClose = () => {
    this.setState({
      newDrugView: false
    })

  }

  newDrugViewOpen = (e, values) => {
    this.setState({
      newDrugView: true,
      id: values.row.id,
      newDrugPopUp: values.row
    })
  }

  renderStockViewpopUp = () => {
    const { t } = this.props
    return (
      <>
        <Modal open={this.state.newDrugView} onClose={this.newDrugViewClose} >
          <Box className='active_view_popup'>
            <div className='active_view_header'>
              <label className='active_view_header_text'>{t('View Product Detail')}</label>
            </div>
            <div className='active_view_middle_card'>

              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('MaterialType') + "*"}
                  value={this.state.newDrugPopUp.material_type_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('BrandName') + "*"}
                  value={this.state.newDrugPopUp.brand_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ProductName') + "*"}
                  value={this.state.newDrugPopUp.product_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ProductType') + "*"}
                  value={this.state.newDrugPopUp.product_type_name}
                  disabled
                />
              </div>
              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('Unit') + "*"}
                  value={this.state.newDrugPopUp.unit_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('LatestvendorName')}
                  value={this.state.newDrugPopUp.vendor_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('LatestManufName')}
                  value={this.state.newDrugPopUp.manufacturer_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('PhyBox')}
                  value={this.state.newDrugPopUp.physical_box_no}
                  disabled
                />
              </div>
              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('HSNCode')}
                  value={this.state.newDrugPopUp.hsn_code}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('GST') + "%"}
                  value={this.state.newDrugPopUp.gst_percentage}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ReorderThreshold')}
                  value={this.state.newDrugPopUp.reorder_threshold}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ExpiryPeriod')}
                  value={this.state.newDrugPopUp.expiry_alert_period}
                  disabled
                />
              </div>
              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('UPC')}
                  value={this.state.newDrugPopUp.bar_code}
                  disabled
                />
              </div>
            </div>
            <div className='active_view_footer'>
              <div className='active_view_left'>
                <label className='active_view_text'>
                  <div className='inactive_bulletin'></div>
                  <span className='inactive_span'>{t('Close')} | </span>
                  {t('Returnstockpage')}
                </label>
              </div>
              <div className='active_view_right'>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant='contained'
                  onClick={()=>{this.newDrugViewClose()
                    // this.setState({newDrugView :false })
                    // this.props.selectedTab()
                  }}
                >{t('Close')}</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  getNewProductInventoryexcel = () => {
    const {t} = this.props
    try {
      this.setState({disableBtn: true})
      RestAPIService.excelGet(Serviceurls.INVENTORY_NEW_DRUG_GET + "?export_type=excel" + "&from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate +"&search_key=" + this.state.searchkey).
        then((response) => {
          if(response.data){
          var pom = document.createElement('a');
          var csvContent = response.data; //here we load our csv data 
    
          let filename = response.headers["content-disposition"].split("filename=")[1]
    
          var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', filename ? filename : 'registry.xlsx');
          pom.click();
          this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          }, () => {
            setTimeout(() => {
              this.setState({ isErrorMsg: false })
            }, 2000);
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      }, () => {
        setTimeout(() => {
          this.setState({ isErrorMsg: false })
        }, 2000);
      })
    }
  }

  render() {
    this.state.newDrugListData.forEach((element,index) => element.sno = index  + 1)
    const columns = [
      {field: 'sno', headerName: t("SNo"), sortable: false, flex: 0.05},
      {
        field: 'created_date', headerName: t('Date'), sortable: false, flex: 0.083,align:"center",headerAlign:"center"
      },
      { field: 'material_type_name', headerName: t('MaterialType'), sortable: false, flex: 0.083,
      renderCell: (params) => <div>{params?.row?.material_type_name?.length > 10 ? <Tooltip placement='top' title={params.row.material_type_name} arrow><div>{params?.row?.material_type_name.slice(0,10) +"..."}</div></Tooltip> : params?.row?.material_type_name ? params?.row?.material_type_name : "-"}</div> },
      {
        field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.083,
        renderCell: (params) => <div>{params?.row?.brand_name?.length > 10 ? <Tooltip placement='top' title={params.row.brand_name} arrow><div>{params?.row?.brand_name.slice(0,10) +"..."}</div></Tooltip> : params?.row?.brand_name ? params?.row?.brand_name : "-"}</div>
      },
      {
        field: 'product_name', headerName: t('ProductName'), sortable: false, flex: 0.083,
        renderCell: (params) => <div>{params?.row?.product_name?.length > 10 ? <Tooltip placement='top' title={params.row.product_name} arrow><div>{params?.row?.product_name.slice(0,10) + "..."}</div></Tooltip> : params?.row?.product_name ? params?.row?.product_name : "-"}</div>
      },
      { field: 'unit_name', headerName: t('Unit'), sortable: false, flex: 0.083 },
      {
        field: 'manufacturer_name', headerName: t('ManufactureName'), sortable: false, flex: 0.096,
        renderCell: (params) => <div>{params?.row?.manufacturer_name?.length > 14 ? <Tooltip placement='top' title={params.row.manufacturer_name} arrow><div>{params.row.manufacturer_name.slice(0,10) + "..."}</div></Tooltip> : params.row.manufacturer_name ? params.row.manufacturer_name : "-"}</div>
      },
      { field: 'hsn_code', headerName: t('HSN'), sortable: false, flex: 0.083 ,
      renderCell:(params)=>(<div>{params?.row?.hsn_code ? params?.row?.hsn_code : "-"}</div>)},
      { field: 'gst_percentage', headerName: t('GST') + "%", sortable: false, flex: 0.083 ,
      renderCell:(params)=>(<div>{params?.row?.gst_percentage ? params?.row?.gst_percentage : "-"}</div>)},
      {
        field: 'recommended_by', headerName: t('RecommendedBy'), sortable: false, flex: 0.103,
        renderCell: (params) => <div>{params?.row?.recommended_by?.length > 14 ? <Tooltip placement='top' title={params.row.recommended_by} arrow><div>{params.row.recommended_by}</div></Tooltip> : params.row.recommended_by ? params.row.recommended_by : "-"}</div>
      },
      {
        field: 'status', headerName: t('Status'), sortable: false, flex: 0.083, align:"center",headerAlign:"center",
        renderCell: (params) => (
          <div className={params?.row?.status === "Approved" ? 'paid' : params?.row?.status === "Decliend" ? "pending"
            : null}>{params?.row?.status ? params?.row?.status : "-"}</div>
        )
      },
      {
        field: 'action', headerName: t("Action"), type: "actions", sortable: false, flex: 0.083,
        renderCell: (params) => (
          <Stack direction="row">
            {params.row.status === "Approved" ?
              <Tooltip placement='top' title='View' arrow><div><img className='eMed_upload' src={ImagePaths.LabViewButton.default} onClick={(e) => this.newDrugViewOpen(e, params)} alt='view' /></div></Tooltip> :
              <Tooltip placement='top' title='Edit' arrow><div><img className='eMed_upload'  src={ImagePaths.LabEditButton.default} alt='edit' /></div></Tooltip>}
          </Stack>
        )
      }
    ]
    return (
      <div className='mov_container_card'>
        <div className='mov_header_card'>
          <div className='eMed_date_picker'>
            <FormControl>
              <InputLabel >{t("SelectDate")}</InputLabel>
              <Select sx={{  height: '2.2vw', cursor: "pointer",marginRight:"1vw",width:"10vw"}}
                onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                value={this.state.dateFilter}
                label={t("SelectDate")}
              >
                <MenuItem value={0}>{t("All")}</MenuItem>
                <MenuItem value={1}>{t("Today")}</MenuItem>
                <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                <MenuItem value={3}>{t("Lastweek")}</MenuItem>
                <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
              </Select>
              <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
            </FormControl>
          </div>
          {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
          {/* <img className='mov_image_card' src={ImagePaths.LabPrintIcon.default} alt='print' /> */}
          <img className='eMed_upload' emed_tid='NewDrug_Export_Btn' src={ImagePaths.LabUploadButton.default} onClick={()=>{
            if(!this.state.disableBtn){
              this.getNewProductInventoryexcel()
            }}} alt='upload' />

        </div>
        <div className='mov_table_card'>
          <DataGrid
            rows={this.state.newDrugListData}
            columns={columns}
            pageSize={this.state.pageSize}
            loading={this.state.isLoader}
            onPageSizeChange={(newPageSize) => {
              this.setState({ pageSize: newPageSize })
            }}
            hideFooterSelectedRowCount
            rowsPerPageOptions={[10, 20, 30]}

            getRowId={(row) => row.id}
            pagination
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay:()=>(
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
          />
        </div>
        {this.renderStockViewpopUp()}
      </div>
    )
  }
}


export default withTranslation()(NewDrug)
import { Box, Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../../src/Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../src/Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

class CorporateConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            CorporateList: [],
            ref: props.location?.pathname,

            RegisteredName: "",
            GSTIN: "",
            Address01: "",
            Address02: "",
            City: "",
            Pincode: "",
            State: "",
            Country: "",
            EmployeeRangeFrom: "",
            EmployeeRangeTo: "",
            EmailID: "",
            MobileNumber: "",
            DiscountPercent: "",
            isEditCliked:false,
            isDeleteCliked:false,
            SelectedList:null,
        }
    }

    componentDidMount() {
        this.GetCorporateList()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    GetCorporateList(){
        try{
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE).
            then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        CorporateList: response.data.data
                    },()=>{this.LoaderFunction(false)})
                }
                else{
                    this.LoaderFunction(false)
                    this.errorMessage(response.data.message)
                }
            }).catch((error) => {
                this.LoaderFunction(false)
                this.errorMessage(error.message)
            })
        }
        catch(e){
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getPincodeToCity = (pincode) => {
        fetch("https://api.postalpincode.in/pincode/" + pincode)
            .then((res) => res.json())
            .then(response => {
                if (response && response[0].Status == "Success") {
                    var data = response[0].PostOffice
                    if (data && data.length > 0) {
                        this.setState({
                            City: data[0].District,
                            State: data[0].State,
                            Country: data[0].Country,
                        })
                    }
                } else if (response.data.status == "fail") {
                    // this.showToastBox(true, response.data.message, Constants.ErrorToast)
                }
            })
            .catch(e => {
                //  this.showToastBox(true, e.response.data.message, Constants.ErrorToast)
            });
    }

    onChangeHandler = (event) => {
        try {
            const { name, value } = event.target
            let alpha = CommonValidation.alphabetOnly(value);
            let number = CommonValidation.numberOnly(value);
            let GST = CommonValidation.gstnumberValidation(value)
            let Discount = CommonValidation.DecimalNumber(value)

            if (name === "City" || name === "Country" || name === "State") {
                if (alpha || value === "") {
                    this.setState({ [name]: value })
                }
            }
            else if (name === "Pincode" || name === "EmployeeRangeFrom" || name === "EmployeeRangeTo" || name === "MobileNumber") {

                if (number || value === "") {
                    this.setState({ [name]: value }, () => {
                        if (name === "Pincode" && this.state.Pincode.length === 6) {
                            this.getPincodeToCity(this.state.Pincode)
                        }
                    })
                }
            }
            else if (name === "GSTIN") {
                if (GST || value === "") {
                    this.setState({ [name]: value.toUpperCase() })
                }
            }
            else if (name === "DiscountPercent") {
                if ((Discount && +value <= 100) || value === "") {
                    this.setState({ [name]: value })
                }
            }
            else {
                this.setState({ [name]: value })
            }
        } catch (error) {
            this.errorMessage(error)
        }
    }

    postCorporate = () => {
        try {
            var states = this.state
            var data = {
                "company_name": CommonValidation.removeSpace(states.RegisteredName),
                "gst_no": states.GSTIN,
                "country": CommonValidation.removeSpace(states.Country),
                "pincode": states.Pincode,
                "address_line_1": CommonValidation.removeSpace(states.Address01),
                "address_line_2": CommonValidation.removeSpace(states.Address02),
                "email": CommonValidation.removeSpace(states.EmailID),
                "mobile_no": states.MobileNumber,
                "discount_percent": +states.DiscountPercent,
                "employee_range_from": states.EmployeeRangeFrom,
                "employee_range_to": states.EmployeeRangeTo,
                "city_name": CommonValidation.removeSpace(states.City),
                "state_name": CommonValidation.removeSpace(states.State),
            }
            if(states.isEditCliked){data["id"] = states.SelectedList.id}
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.FO_CONFIG_CORPORATE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.ClearData()
                        this.GetCorporateList()
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if(error?.response?.data?.status === "fail"){
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    }else{
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }   
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClearData() {
        this.setState({
            RegisteredName: "",
            GSTIN: "",
            Address01: "",
            Address02: "",
            City: "",
            Pincode: "",
            State: "",
            Country: "",
            EmployeeRangeFrom: "",
            EmployeeRangeTo: "",
            EmailID: "",
            MobileNumber: "",
            DiscountPercent: "",
            isEditCliked:false,
            isDeleteCliked:false,
            SelectedList:{}
        })
    }

    onEditHandler = (value) => {
        try {
            this.setState({
                isEditCliked:true,
                SelectedList:value.row,
                RegisteredName: value.row.company_name,
                GSTIN: value.row.gst_no,
                Address01: value.row.address_line_1,
                Address02: value.row.address_line_2,
                City: value.row.city_name,
                Pincode: value.row.pincode,
                State: value.row.state_name,
                Country: value.row.country,
                EmployeeRangeFrom: value.row.employee_range_from,
                EmployeeRangeTo: value.row.employee_range_to,
                EmailID: value.row.email,
                MobileNumber: value.row.mobile,
                DiscountPercent: value.row.discount_percent
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (value) => {
        try {
            this.setState({ 
                isDeleteCliked:true,
                SelectedList:value.row,
             })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderCorporateLeft = () => {
        try {
            this.state.CorporateList.forEach((element, index) => {element.sno = index + 1})
            const { t } = this.props
            const columns = [
                { field: "sno", sortable: false, flex: 0.08, headerName: t("SNo") },
                { field: "company_name", flex: 0.21, headerName: t("CompanyName") },
                { field: "mobile", flex: 0.166, headerName: t("MobileNumber") },
                { field: "gst_no", flex: 0.21, headerName: t("GSTIN"), headerAlign: "center", align: 'center' },
                { field: "discount_percent", flex: 0.08, headerName: t("Discount%") },
                {
                    field: "action", flex: 0.166, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
                    renderCell: (params) => <div>
                        <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings",  "Corporate Configuration",  "Corporate", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
                        <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings",  "Corporate Configuration",  "Corporate", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
                    </div>
                }
            ]
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_refdoc_left' >
                        <DataGrid
                            rows={this.state.CorporateList}
                            columns={columns}
                            getRowId={(row) => row['id']}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            hideFooterSelectedRowCount
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            headerHeight={40}
                            components={{
                                Toolbar: CommonGridHeader.CommonToolBar,
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_conf_nodata'>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            disableSelectionOnClick
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            loading={this.state.isLoader}
                        />
                    </Paper>
                </Stack>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTextBox(label, value, name, width, maxLength, mandatory) {
        return (
            <TextField
                inputProps={{ maxLength: maxLength }}
                sx={{ width: width, marginTop: '0.8vw' }}
                size='small'
                label={label}
                name={name}
                value={value}
                onChange={(e) => this.onChangeHandler(e)}
            />
        )
    }

    validateRange(From, To){
        if(From === "" && To === ""){
            return true
        }
        else if(From !== "" && To === ""){
            return false
        }
        else if(From === "" && To !== ""){
            return false
        }
        else if (From !== "" && To !== ""){
            if(+From > +To){
                return false
            }
            else if(+From === 0 && +To === 0){
                return false
            }
            else{
                return true
            }
        }
    }

    validation() {
        try {
            var states = this.state
            let Email = CommonValidation.emailValidation(this.state.EmailID)
            let Rangevalidation = this.validateRange(states.EmployeeRangeFrom, states.EmployeeRangeTo)
            let SameCorpName = [];
            SameCorpName = states.CorporateList.filter(list => list['company_name'].toLowerCase() === states.RegisteredName.toLowerCase());

            var { t } = this.props;
            if (
                Email &&
                CommonValidation.removeSpace(states.RegisteredName) !== "" &&
                states.GSTIN.length === 15 &&
                CommonValidation.removeSpace(states.Address01) !== "" &&
                CommonValidation.removeSpace(states.City) !== "" &&
                states.Pincode.length === 6 &&
                +states.Pincode !== 0 &&
                CommonValidation.removeSpace(states.State) !== "" &&
                CommonValidation.removeSpace(states.Country) !== "" &&
                states.MobileNumber.length === 10 &&
                states.DiscountPercent !== "" &&
                Rangevalidation &&
                (SameCorpName.length === 0 || (SameCorpName.length == 1 && SameCorpName[0].id == states.SelectedList?.id)))
                {
                this.postCorporate()
                }
            else {
                if (CommonValidation.removeSpace(states.RegisteredName) === "") {
                    this.errorMessage(t("EnterCorpName"))
                }
                else if (states.GSTIN.length !== 15) {
                    this.errorMessage(t("EnterGSTIN"))
                }
                else if (CommonValidation.removeSpace(states.Address01) === "") {
                    this.errorMessage(t("EnterAddress01"))
                }
                else if (CommonValidation.removeSpace(states.City) === "") {
                    this.errorMessage(t("EnterCity"))
                }
                else if (states.Pincode.length !== 6 ||  +states.Pincode === 0) {
                    this.errorMessage(t("EnterPincode"))
                }
                else if (CommonValidation.removeSpace(states.State) === "") {
                    this.errorMessage(t("EnterRegion"))
                }
                else if (CommonValidation.removeSpace(states.Country) === "") {
                    this.errorMessage(t("EnterRegion"))
                }
                else if (!Email) {
                    this.errorMessage(t("EnterEmail"))
                }
                else if (states.MobileNumber.length !== 10) {
                    this.errorMessage(t("EnterMobile"))
                }
                else if (states.DiscountPercent === "") {
                    this.errorMessage(t("EnterDiscount"))
                }
                else if ((states.EmployeeRangeFrom != "") && (states.EmployeeRangeTo == "")) {
                    this.errorMessage(t("EnterEmpRangeTo"))
                }
                else if ((states.EmployeeRangeTo != "") && (states.EmployeeRangeFrom == "")) {
                    this.errorMessage(t("EnterEmpRangeFrom"))
                }
                else if (parseInt(states.EmployeeRangeFrom) > parseInt(states.EmployeeRangeTo)) {
                    this.errorMessage(t("InvalidEmpRange"))
                }
                else if ((states.EmployeeRangeFrom == "0") || (states.EmployeeRangeTo == "0")) {
                    this.errorMessage(t("InvalidEmpRange"))
                }
                else if (SameCorpName.length > 0) {
                    this.errorMessage(t("CorpExists"))
                }
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderCorporateRight = () => {
        try {
            const { t } = this.props
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_refdoc_right' elevation={2}>
                        <Typography className='eMed_refdoc_title'>{t("CorpDetails")}</Typography>
                        <Grid container className='eMed_corpconfig_txt_card'>
                            <Grid item xs={12}>
                                {this.renderTextBox(`${t("RegisteredName")} *`, this.state.RegisteredName, "RegisteredName", "27.5vw", 50, true)}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderTextBox(`${t("GSTIN")} *`, this.state.GSTIN, "GSTIN", "27.5vw", 15, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("Address01")} *`, this.state.Address01, "Address01", "13.5vw", 50, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(t("Address02"), this.state.Address02, "Address02", "13.5vw", 50, false)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("Pincode")} *`, this.state.Pincode, "Pincode", "13.5vw", 6, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("City")} *`, this.state.City, "City", "13.5vw", 50, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("State")} *`, this.state.State, "State", "13.5vw", 30, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("Country")} *`, this.state.Country, "Country", "13.5vw", 30, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(t("EmployeeRangeFrom"), this.state.EmployeeRangeFrom, "EmployeeRangeFrom", "13.5vw", 4, false)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(t("EmployeeRangeTo"), this.state.EmployeeRangeTo, "EmployeeRangeTo", "13.5vw", 4, false)}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderTextBox(`${t("Emailid")} *`, this.state.EmailID, "EmailID", "27.5vw", 50, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("MobileNumber")} *`, this.state.MobileNumber, "MobileNumber", "13.5vw", 10, true)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("DiscountPercent")} *`, this.state.DiscountPercent, "DiscountPercent", "13.5vw", 5 ,true)}
                            </Grid>
                        </Grid>
                        <Stack spacing={2} direction="row"  sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"9vh"}>
                                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                                <Button disabled={!CheckAccessFunc("front_office", "Settings",  "Corporate Configuration",  "Corporate", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.validation() }}>{t("Save")}</Button>
                            </Stack>
                    </Paper>
                </Stack>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    popupClose(){
        this.setState({
            isDeleteCliked :false
        })
    }
    DeleteCorporate(){
        try{
            var states = this.state
            RestAPIService.delete( Serviceurls.FO_CONFIG_CORPORATE + "?id=" + states.SelectedList.id).
            then((response) => {
                if (response.data.status === "success") {
                    this.successMessage(response.data.message)
                    this.ClearData()
                    this.GetCorporateList()
                } else {
                    this.errorMessage(response.data.message)
                }
            }).catch(error => {
                this.errorMessage(error.message)
            })
        }
        catch(e){
            this.errorMessage(e.message)
        }
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
    render() {
        let {t}=this.props
        try {
            return (
                <Box component={"div"} className='eMed_refdoc_container'>
                    <Grid container spacing={2} className='eMed_refdoc_content' >
                        <Grid item xs={8}>
                            {this.renderCorporateLeft()}
                        </Grid>
                        <Grid item xs={4}>
                            {this.renderCorporateRight()}
                        </Grid>
                    </Grid>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.isDeleteCliked ? <DeletePopup
                        DeletTitle={`${t("deleteMsg")}  ${this.state.SelectedList ? this.state.SelectedList?.company_name : "-"} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DeleteCorporate.bind(this)}
                        DeleteNotify={t("effectEntireSetting")}
                    /> : null}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
}

export default withTranslation()(CorporateConfig)
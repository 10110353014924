import React, { Component } from 'react';
import {
    Tooltip, Box, Stack, Button, TextField, InputAdornment, Autocomplete, Typography, Dialog,
    FormControlLabel, Checkbox, IconButton, Menu
} from '@mui/material';
import CommonValidation from "../../../../../Components/CommonFunctions/CommonValidation";
import { CommonDeleteButton, CommonPatientDetails, CommonPatientCard } from "../../../../../Components/Common Components/CommonComponents";
import SearchIcon from '@mui/icons-material/Search';
import { withTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs' 
import { DateTime } from "luxon";
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import { AmountFormat } from "../../../../../Components/CommonFunctions/CommonFunctions";
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import Success from '../../../../../Assets/Animation/Success.gif';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Loader from '../../../../../Components/Loader';
import { t } from 'i18next';
import { CurrencySymbol } from '../../../../../Utility/Constants';

class EstimationSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientsearchKey: "",
            patientDetails: {},
            PatientSearchClicked: false,
            SearchedPatientList: [],
            doctordetails: {},
            DoctorsList: [],
            estimate_date: new Date(),
            surgery: "",
            est_period: 0,
            Est_Amt: "",
            particulars: "",
            estimatedData: [],
            totalAmount: 0,
            editIndex: -1,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
            successMsgPopUp: false,
            print_id: null,
            sendEst: false,
            isEdit: false,
            id: null,
            DateTimerOpen: false,
        }
    }

    componentDidMount() {
        this.getDoctorsList();
    }

    componentDidUpdate(prevProps) {
        let history = prevProps.history?.location?.state;
        let EstimateData = history?.EstimateData;
        let states = this.state;
        if ( EstimateData !== undefined && EstimateData?.patient_id !==  undefined && EstimateData?.patient_id !== states.patientDetails.patient_id ) {
            let patientDetail = {
                patient_id: EstimateData.patient_id,
                patient_name: EstimateData.patient_name,
                patient_gender: EstimateData.patient_gender,
                patient_age: EstimateData.patient_age
            }
            let doctorDetail = {
                doctor_name: EstimateData.doctor_name,
                doctor_id: EstimateData.doctor_id,
                qualification: EstimateData?.qualification,
            }
            let detailList =  [...EstimateData["particulars"]["items"]];
            detailList?.forEach((item, i) => item["id"] = i)
            let estPeriod = EstimateData.est_period ? EstimateData.est_period.split("") : 0
            let est_period = [...estPeriod[0], ...estPeriod[1]];

            this.setState({
                patientDetails :  patientDetail,
                doctordetails: doctorDetail,
                estimate_date: new Date(EstimateData.est_date),
                est_period: +est_period.join(""),
                surgery: EstimateData.surgery ? EstimateData.surgery : "",
                estimatedData: detailList,
                totalAmount: EstimateData["particulars"]["tot_est_amt"],
                isEdit: history?.isEdit,
                id:  EstimateData.id
            })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    IPPatientSearch = () => {
        try {
            let { t } = this.props;
            RestAPIService.getAll(Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.patientsearchKey}&address_key=&father_husband_key=`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            SearchedPatientList: response.data.data,
                        }, () => {
                            if (this.state.patientsearchKey !== "") {
                                if (response.data.data.length > 0) {
                                    this.setState({ PatientSearchClicked: true })
                                } else {
                                    this.errorMessage(t("NoRecordsFound"))
                                }
                            }
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getDoctorsList() {
        try {
          RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
          .then((response) => {
              if (response.data.status === "success") {
                this.setState({
                  DoctorsList: response.data?.data?.filter((item) => (item.specialization_active === true))
                })
              }
              else {
                this.errorMessage(response.data?.message)
              }
            }).catch((error) => {
              this.errorMessage(error?.message)
            })
        }
        catch (e) {
          this.errorMessage(e?.message)
        }
      }


    renderSearch(label, statekey, img, Data = []) {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box className="eMed_Ip_Est_ptCard">
                <Box component={'div'} className='eMed_Ip_Est_image'>
                    <img className='eMed_Ip_Est_img' src={img ? img : ImagePaths.Male.default} alt="male" />
                </Box>
                <Box component={'div'}>
                    {statekey === "doctordetails" ?
                        <Autocomplete
                            size='small'
                            clearIcon={false}
                            options={Data}
                            label={t(label)}
                            getOptionLabel={(item) => (typeof (item.doctor_name) === "string" ? item.doctor_name : "")}
                            value={states[statekey] ? states[statekey] : ""}
                            ListboxProps={{ style: { maxHeight: 300 } }}
                            onChange={(event, newValue) => {
                                states[statekey] = newValue
                                this.setState({ states })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (
                                <TextField
                                    size='small'
                                    required
                                    {...params}
                                    placeholder='Enter Doctor Name Here'
                                    label={t(label)}
                                    autoComplete='off'
                                    variant='standard'
                                />
                            )}
                        />
                        :
                        <TextField
                            sx={{ width: "20vw" }}
                            label={t(label)}
                            variant='standard'
                            size="small"
                            autoComplete="off"
                            name={statekey}
                            value={states[statekey] ? states[statekey] : ""}
                            placeholder='Name / Number / UHID'
                            required
                            onKeyDown={(event) => {
                                if (event.key === "Enter" && event.target.value != "") {
                                    this.IPPatientSearch()
                                }
                            }}
                            onChange={(e) => {
                                states[statekey] = e.target.value
                                this.setState({ states })
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton size="small"
                                            disabled={states.patientsearchKey === "" ? true : false}
                                            onClick={() => {
                                                if (states.patientsearchKey !== "") {
                                                    this.IPPatientSearch()
                                                }
                                            }}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    }
                </Box>
                <Menu
                    open={this.state.PatientSearchClicked}
                    className="eMed_IP_Est_Search_Pop"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => {
                        this.setState({
                            PatientSearchClicked: false,
                            patientsearchKey: "",
                        })
                    }}
                >
                    {
                        this.state.SearchedPatientList.length > 0 ?
                            this.state.SearchedPatientList.map((list, index) => {
                                let PatientFullName = `${list.first_name} ${list.last_name}`
                                list["patient_name"] = PatientFullName
                                list["patient_mobile_number"] = list.mobile_number
                                list["patient_photo_url"] = list.photo_url
                                return (
                                    <Box key = {index}>
                                        <Box className="eMed_IP_Est_Search_item">
                                            <CommonPatientDetails data={list} />
                                            <Box component={'div'} className="eMed_Header_Search_icons">
                                                <Tooltip title={t("CreateEstimation")} arrow>
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            patientDetails: list,
                                                            PatientNameSearchKey: "",
                                                            PatientSearchClicked: false,
                                                            SearchedPatientList: []
                                                        })
                                                    }}
                                                        position="end">
                                                        <PersonAddAlt1Icon color='primary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            }) : null
                    }
                </Menu>
            </Box>
        )
    }

    handleText = (e) => {
        let states = this.state
        let name = e.target.name;
        let value = e.target.value.trimStart();
        switch (name) {
            case "Est_Amt":
                let isValid = CommonValidation.NumberWithDot(value)
                if ((value === "" || value >= 0) && isValid) {
                    states["Est_Amt"] = value;
                }
                break;
            case "est_period":
                let isNum = CommonValidation.numberOnly(+value)
                    if ((value === "" || value <= 30 && value.length <=2) && isNum) {
                        states["est_period"] = value.trim()
                    }
                    break;
            default:
                if (value === "" || value?.length <= 150) {
                    states[name] = value;
                }
                break;
        }
        this.setState({
            states
        })

    }
    renderTextField = (label, statekey, width) => {
        const { t } = this.props;
        let states = this.state;
        return (
            statekey === "estimate_date" ?
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        open={states.DateTimerOpen}
                        onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                        onClose={() => { this.setState({ DateTimerOpen: false }) }}
                        label={t(label)}
                        value={states.estimate_date ? states.estimate_date : null}
                        inputFormat='DD-MM-YYYY & hh:mmA'
                        onChange={(value) => {
                            if (value === null) {
                                this.setState({ estimate_date: new Date() })
                            } else {
                                this.setState({ estimate_date: value })
                            }
                        }}
                        renderInput={(params) => <TextField size="small" {...params}
                        required
                        autoComplete='off'
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={() => { this.setState({ DateTimerOpen: true }) }}
                        sx={{ width: width ? width:"13vw" }}
                        />}
                    />
                </LocalizationProvider>
                :
                <TextField
                    label={label ? t(label) : ""}
                    sx={{ width: width ? width : "14.8vw" }}
                    size="small"
                    autoComplete="off"
                    name={statekey}
                    value={states[statekey] ? states[statekey] : ""}
                    required = {statekey === "surgery" ? false : true}
                    InputProps={{
                        startAdornment: statekey === "Est_Amt" ? (
                            <InputAdornment position="start">
                                <img src={CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default} alt={"Rupee"} className='eMed_action_img_small' />
                            </InputAdornment>
                        ) : null,
                    }}
                    onChange={this.handleText}
                />)
    }

    renderTextBoxs = (label, statekey, width) => {
        let states = this.state;
        return (<Box component={'div'} className="eMed_Ip_Est_txtbox">
            <TextField
                    sx={{ width: width ? width : "7vw" }}
                    label={label ? t(label) : ""}
                    size="small"
                    autoComplete="off"
                    name={statekey}
                    value={states[statekey] ? states[statekey] : ""}
                    required
                    onChange={this.handleText}
                />
            <TextField
                sx={{ width:"4.5vw", fontSize: "0.95vw", backgroundColor: "#E4F2F0"}}
                size='small'
                autoComplete="off"
                value={"Days"}
            />
        </Box>)
    }

    handleAdd = () => {
        let { estimatedData, particulars, Est_Amt, editIndex } = this.state;
        let AddToList = "";
        const { t } = this.props;
        let total_amount = 0;

        //validation
        let alreadyExist = estimatedData?.find(item => item["name"].toLowerCase().trim() === particulars.toLowerCase().trim());
        if (alreadyExist) {
            if (editIndex !== alreadyExist?.id) {
                AddToList = `${alreadyExist?.name} ${t("AlreadyExist")}`
            } else if (editIndex === -1) {
                AddToList = `${alreadyExist?.name} ${t("AlreadyExist")}`
            }
        }

        if (AddToList === "") {
            if (editIndex !== -1) { //update
                let temp = {
                    id: editIndex,
                    name: particulars.trim(),
                    amt: +(Est_Amt)
                }
                let newList = estimatedData.map((item, index) => {
                    return index === editIndex ? temp : item
                })
                estimatedData = [...newList]
            } else { //create
                let temp = {
                    id: estimatedData.length + 1,
                    name: particulars.trim(),
                    amt: +(Est_Amt)
                }
                estimatedData = [...estimatedData, temp];
            }
            estimatedData.forEach((acc) => total_amount += parseFloat(acc.amt))
            this.setState({
                estimatedData,
                totalAmount: total_amount ? total_amount.toFixed(2) : 0,
                particulars: "",
                Est_Amt: "",
            })
        } else {
            this.errorMessage(AddToList);
        }
    }

    handleEditTbl = (item) => {
        this.setState({
            particulars: item.name,
            Est_Amt: item.amt,
            editIndex: item.id
        })
    }

    handleDeleteTbl = (item) => {
        let { estimatedData, editIndex } = this.state;
        let total_amount = 0;
        if (editIndex === -1) {
            let deletedArr = [...estimatedData]
            let index = deletedArr.indexOf(item)
            deletedArr.splice(index, 1)
            deletedArr.forEach((acc) => total_amount += parseFloat(acc.amt))
            this.setState({
                estimatedData: [...deletedArr],
                totalAmount: +(total_amount),
            })
        }
    }

    handleClear = () => {
        this.setState({
            estimatedData: [],
            totalAmount: 0,
            particulars: "",
            editIndex: -1,
            Est_Amt: "",
        })
    }

    checkAllFields = () => {
        let states = this.state;
        let patientDetails = Object.keys(states.patientDetails);
        let doctordetails = Object.keys(states.doctordetails);
        let msg = "";
        if(patientDetails.length === 0 && doctordetails.length === 0 && states.estimate_date === "Invalid DateTime" && states.est_period === 0 && states.estimatedData?.length === 0) {
          msg = "Fillmandatory";
        } else if(patientDetails.length === 0){
          msg = "validPatient"
        }else if(doctordetails.length === 0){
            msg = "validDoc"
        } else if(!states.estimate_date){
            msg = "validDateTime"
        }else if(!states.est_period){
            msg = "validEstDate"
        }else if(states.estimatedData?.length === 0){
            msg = "validparticular"
        }
        return msg;    
    }

    createEstimation = () => {
        let states = this.state;
        let {t} = this.props;
        let startDate = new Date(states.estimate_date)
        let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let datas = {   
            "patient_id": states.patientDetails.patient_id,
            "doctor_id": states.doctordetails.doctor_id,
            "est_date": states.estimate_date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : "",
            "est_period": `${states.est_period} ${states.est_period === 1 ? "Day" : "Days"}`,
            "desc": "",
            "surgery": states.surgery,
            "particulars":{ 
                "items": [],
                "tot_est_amt": +(states.totalAmount)
            } 
        };
        states.estimatedData?.forEach((item) => {
            delete item.id;
            datas["particulars"]["items"].push(item)
        })
        if(states.isEdit && states.id){
            datas["id"] = states.id
        }

        let  isAvail = this.checkAllFields();
        if(isAvail === ""){
        try {
            this.LoaderFunction(true)
            RestAPIService.create(datas, Serviceurls.IP_ESTIMATE_BILL_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.handleClear();
                        this.LoaderFunction(false)
                        if(response.data.message === "Estimate Updated Successfully") {this.successPopUpClose();}
                        this.setState({
                            successMsgPopUp:  response.data.message === "Estimate Updated Successfully" ? false : true,
                            print_id: response.data?.data?.id
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data?.message);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e?.message);
        }}else{
            this.errorMessage(t(isAvail));
        }
    }

    CustomFooter = () => {
        let states = this.state;
        const { t } = this.props;
        return (
            <Stack spacing={0.5} direction="column" alignItems="center" justifyContent="center">
                <Stack direction="column" alignItems="flex-end" justifyContent="flex-end" sx={{ width: "100%" }} mr={5}>
                    <Typography id="eMed_Ip_Est_txt1">{t("TotalAmount")}</Typography>
                    <Typography id="eMed_Ip_Est_txt2">{`${CurrencySymbol} ${states.totalAmount ? AmountFormat(states.totalAmount).replace(`${CurrencySymbol}`, "") : "0.00"}`}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" p={2}>
                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.createEstimation() }}>{states.isEdit ? t("UpdateEstimation"): t("CreateEstimation")}</Button>
                </Stack>
            </Stack>
        );
    }

    successPopUpClose = () => {
        this.setState({
            successMsgPopUp: false,
            print_id: null
        }, () => {
            this.props.history.push({
                pathname: "/IPHome/Estimation"
              })
        })
    }

    printEstimateBill = () => {
        try{
            RestAPIService.PrintPDF(`${Serviceurls.IP_ESTIMATE_BILL_GET}?id=${this.state.print_id ? this.state.print_id : 0}&export_type=pdf`).
            then((response) => {
              if (response) {
                this.successPopUpClose()
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            }
            }).catch((error) => {
                console.log(error.message)
            })
        }catch(e){
            this.successPopUpClose()
        }
    }

    removePatient = () => {
        this.setState({
            patientDetails: {},
            patientsearchKey: ""
        })
    }

    removeDoctor = () => {
        this.setState({
            doctordetails: {}
        })
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        let patientGender = (states.patientDetails?.patient_gender === "Male" || states.patientDetails?.patient_gender === "m") ? "M" 
        : (states.patientDetails?.patient_gender === 'Female' || states.patientDetails?.patient_gender === 'f' ) ? "F" 
        : (states.patientDetails?.patient_gender === "Trans" || states.patientDetails?.patient_gender === 't') ? "T" : "-";

        const columns = [
            {
                field: 'name', headerName: t('Particulars'), sortable: false, hideable: false, flex: 0.41, renderCell: ({ row }) => {
                    return (<div>{row?.name?.length > 60 ? <Tooltip placement="top" title={row?.name} arrow><div>{row?.name.slice(0, 58) + "..."}</div></Tooltip> : row?.name ? row?.name : "-"}</div>)
                }
            },
            {
                field: 'amt', headerAlign: "right", headerName: t('Amount') + `(${CurrencySymbol})`, flex: 0.39, sortable: false, hideable: false, align: "right", renderCell: ({ row }) => {
                    let amounts = AmountFormat(row?.amt)
                    return (<Typography>{amounts ? amounts.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.20, sortable: false, hideable: false, align: "center", renderCell: ({ row }) => {
                    return (
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                            <CommonDeleteButton size="1.5vw" onClick={this.handleDeleteTbl.bind(this, row)} />
                        </Stack>
                    )
                }
            }
        ]

        let data = {
            image: states.patientDetails?.patient_gender === "Female" || states.patientDetails?.patient_gender === "f" ? ImagePaths.Female.default : ImagePaths.Male.default ,
            width: "2.3vw",
            height: "2.3vw",
            lineOne: t("PatientDetails"),
            lineTwo: `${states.patientDetails?.patient_name ?  states.patientDetails?.patient_name : "-"} 
            ${states.patientDetails?.patient_age ? states.patientDetails?.patient_age : "-"} / ${patientGender ? patientGender : "-"}`,
          }
          
          let Consultant = {
            image: ImagePaths.Doctor_Icon.default,
            width: "2.3vw",
            height: "2.3vw",
            lineOne: t("ConsultantName"),
            lineTwo: `${states.doctordetails.doctor_name ? states.doctordetails.doctor_name : "-"} ${states.doctordetails.qualification ? states.doctordetails.qualification : "-"}`
          }

        return (
            <Box id='eMed_IP_Appointment'>
                <Box id="eMed_Ip_Est_head_card" >
                    { "patient_name" in states.patientDetails   && states.patientDetails.patient_name !== undefined ?  
                    <CommonPatientCard details={data} ShowCross = {true} onClick = {this.removePatient.bind(this)} showDetailed = {true}/> 
                    : this.renderSearch("PatientDetails", "patientsearchKey", ImagePaths.Male.default)}
                    {"doctor_name" in states.doctordetails  && states.doctordetails.doctor_name !== undefined ? 
                    <CommonPatientCard details={Consultant}  ShowCross = {true} onClick = {this.removeDoctor.bind(this)} showDetailed = {true}/> 
                    : this.renderSearch("ConsultantName", "doctordetails", ImagePaths.Doctor_Icon.default, states.DoctorsList)}
                    {this.renderTextField("EstimationDate&Time", "estimate_date", "14vw")}
                    {this.renderTextField("SurgeryName/Reason", "surgery", "13vw")}
                    {this.renderTextBoxs("Period", "est_period", "7vw")}
                </Box>
                <Box className="eMed_Ip_Est_mainDiv">
                    <Typography id="eMed_Ip_Est_txt" mb={2}>{t("EstimationSummary")}</Typography>
                    <Stack direction="row" spacing={2} justifyContent={"center"} alignItems={"center"} mb={3} >
                        {this.renderTextField("Particulars", "particulars", "70%")}
                        {this.renderTextField("Amount", "Est_Amt", "25%")}
                        <Button variant="contained"
                            size='small' id="eMed_recPayment_btn"
                            disabled={(states.particulars === "" || states.Est_Amt === "") ? true : false}
                            onClick={() => this.handleAdd()}>{t("Add")}</Button>
                    </Stack>
                    {states.estimatedData?.length > 0 ?
                        <Box className="eMed_Ip_Est_Tbl">
                            <DataGrid
                                rows={states.estimatedData}
                                columns={columns}
                                getRowId={(row) => row.id}
                                disableColumnMenu
                                headerHeight={40}
                                components={{
                                    Footer: this.CustomFooter,
                                    NoRowsOverlay: () => ""
                                }}
                                onCellClick={(params) => {
                                    if (params.field !== "actions") {
                                        this.handleEditTbl(params.row)
                                    }
                                }}
                            />
                        </Box>
                        : null}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsgPopUp ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.successMsgPopUp}
                        onClose={this.successPopUpClose}
                        maxWidth={"md"}
                    >
                        <div className='eMed_IP_Est_Dialogbox'>
                            <img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />
                            <h1 style={{ fontSize: "1.5vw", margin: "0.5vw" }}>{states.isEdit ? `${t("EstimateUpMsg")} !` : `${t("EstimateCrtMsg")} !`}</h1>
                            {/* <FormControlLabel className='eMed_create_switch' control={<Checkbox sx={{ fontSize: '0.4vw', margin: "0.5vw" }} checked={this.state.sendEst}
                                onChange={(e) => {
                                    this.setState({ sendEst: e.target.checked })
                                }} size='small' />} label={t("SendeEstimate")} /> */}
                            <Stack direction="row" justifyContent={"center"} alignItems={"center"} spacing={3} m={2}>
                                <Button variant="outlined" size="small" id="eMed_Config_btn" onClick={() => { this.successPopUpClose() }}>{t('Back')}</Button>
                                <Button variant="contained" size="small"
                                    id="eMed_Config_btn"
                                    onClick={() => {
                                        this.printEstimateBill()
                                        this.setState({
                                            successMsgPopUp: false,
                                            sendEst: false
                                        })
                                    }}
                                >{t("Print")}</Button>
                            </Stack>
                        </div>
                    </Dialog>
                    : null}
                    <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(EstimationSummary);

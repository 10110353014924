import { Box, IconButton, Stack, Typography, Tooltip, Button, Grid, Skeleton } from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../Utility/ImagePaths'
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import DoughnutChart from '../ChartDashboard/CommonCharts/DoughnutChart';
import {
    vendorColumns, PurchaseSales, BranchWiseOverallRenvenue, OverallPaymentCollection,BranchTotalPatientCount,
    BranchWiseOverallCreditAmount, PharmaPatientDemographics, OverallAppointmentType,BranchLabSalesDemoChart,BranchTotalDoctorCount,
    OPSpecialzationChart, OPDoctorsChart, OPServicesChart, OPLocationChart, TopSellingDrugs, mostLeastTest, labLocation, BranchPharmaSalesDemoChart
} from '../ChartDashboard/CommonCharts/TableColumns';
import { withTranslation } from 'react-i18next';
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import Barchart from '../ChartDashboard/CommonCharts/Barchart';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { DateTime } from 'luxon';
import Loader from '../../Components/Loader'

class ChartComparison extends Component {
    variableMap = {
        vendorColumns, PurchaseSales, BranchWiseOverallRenvenue, OverallPaymentCollection,BranchTotalPatientCount,
        BranchWiseOverallCreditAmount, PharmaPatientDemographics, OverallAppointmentType,BranchLabSalesDemoChart,BranchTotalDoctorCount,
        OPSpecialzationChart, OPDoctorsChart, OPServicesChart, OPLocationChart, TopSellingDrugs, mostLeastTest, labLocation, BranchPharmaSalesDemoChart
    }
    constructor(props) {
        super(props);
        this.state = {
            branchId: this.props?.history?.location?.state.branchId ? this.props?.history?.location?.state.branchId : '',
            orgId: '',
            successMsg: false,
            isErrorMsg: false,
            successMsgText: '',
            isErrorMsgText: '',
            isChartView: true,
            title: props.title ? props.title : "",
            tblData: [],
            tblColumn: props.tblColumn,
            chart_view: props.chartView,
            page: 0,
            pageSize: 10,
            isFrom: this.props?.history?.location?.state?.isFrom,
            FilterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
            },
            branchData: [],
            tbltitle: '',
            tblColll: [],
            apiLoaded: false,
            tableData: [],
            subTitle: '',
        }
    }

    componentDidMount = () => {
        let org_details = localGetItem("loggedInUserInfo")
        let UserData = JSON.parse(org_details)
        this.setState({ orgId: UserData?.org_id })
    }

    componentDidUpdate = (prevProps) => {
        let history = this.props?.history?.location?.state

        if (prevProps !== this.props) {
            this.setState({
                isFrom: history?.isFrom,
                branchId: history?.branchId,
                title: history?.title,
                fromDate: history?.fromDate,
                toDate: history?.toDate,
                url: history?.URL ? history?.URL : '',
                tbltitle: history?.tableName,
                subTitle: history?.subTitle,
            }, () => {
                this.branchesApiCalls()
            })
        }
    }

    convertTableFormat = (data, from, isFor) => {
        let tableDatas = []
        if (from === "Top Selling Drugs") {
            tableDatas = data?.flatMap(item =>
                item.drug_data?.map(type => ({
                    branch_id: item.branch_id,
                    branch_name: item.branch_name,
                    drug_ms__drug_name: type.drug_ms__drug_name,
                    drug_counnt: type.drug_counnt,
                    drug_amount: type.drug_amount,
                }))
            );
        }
        else if (isFor === "Tests"){
            tableDatas = data?.flatMap(item =>
                item.test_data?.map(type => ({
                    branch_id: item.branch_id,
                    branch_name: item.branch_name,
                    test_name: type.test_name,
                    count: type.count
                }))
            );
            if(from === "Most Performing Services"){
                tableDatas.sort((a, b) => b.count - a.count)
            }
            else if (from === "Least Performing Services"){
                tableDatas.sort((a, b) => a.count - b.count)
            }

        }
        else if (from === "Vendor Payment") {
            tableDatas = data?.flatMap(item =>
                item.vendor_data?.map(type => ({
                    branch_id: item.branch_id,
                    branch_name: item.branch_name,
                    vendor_id: type?.vendor_id,
                    vendor__name: type?.vendor__name,
                    total_paid_amount: type?.total_paid_amount
                }))
            );
        }
        else if (from === "Location Analysis") {
            tableDatas = data?.flatMap(item =>
                item.data?.map(type => ({
                    branch_id: item.branch_id,
                    branch_name: item.branch_name,
                    patient__city__city_name: type?.patient__city__city_name,
                    city_count: type?.city_count,
                }))
            );
        }
        else {
            tableDatas = data?.flatMap(item =>
                item.type_data?.map(type => ({
                    branch_id: item.branch_id,
                    branch_name: item.branch_name,
                    title: type.title,
                    values: type.values
                }))
            );
            if(from === "Most Performing Services"){
                tableDatas.sort((a, b) => b.values - a.values)
            }
            else if (from === "Least Performing Services"){
                tableDatas.sort((a, b) => a.values - b.values)
            }
        }
        return tableDatas
    }

    mostLeastSeparation = (data, SortOrder, from) => {
        let ChartData = []
        let ChartLabel = []
        let rows = [];
        let aggregatedData = {};

        if(from === "Tests"){
            data.forEach((list, i) => {
                if (list.test_data.length > 0) {
                    list.test_data?.forEach(type => {
                        const Type = type.test_name;
                        if (aggregatedData[Type]) {
                            aggregatedData[Type] += type.count;
                        } else {
                            aggregatedData[Type] = type.count;
                        }
                    });
                    let FinalTotalArray = Object.entries(aggregatedData).map(([Type, count]) => ({ "test_name": Type, count }));
                    if (SortOrder === "asc") {
                        FinalTotalArray = FinalTotalArray.sort((a, b) => a.count - b.count);
                    } else if (SortOrder === 'des') {
                        FinalTotalArray = FinalTotalArray.sort((a, b) => b.count - a.count);
                    }
                    FinalTotalArray.forEach((item) => (ChartData.push(item?.count)))
                    FinalTotalArray.forEach((item) => (ChartLabel.push(item?.test_name.replace(/Appointment/g, ''))))
                    rows = [...rows, ...list?.test_data?.map((type, index) => ({
                        test_name: type.test_name,
                        count: type?.count,
                        branch_name: list?.branch_name,
                        branch_id: list?.branch_id,
                    }))]
    
                    if (SortOrder === "asc") {
                        rows = rows.sort((a, b) => a.count - b.count);
                    } else if (SortOrder === 'des') {
                        rows = rows.sort((a, b) => b.count - a.count);
                    }
                    data[i]["TableData"] = rows
                    data[i]["ChartValue"] = ChartData?.slice(0, 6)
                    data[i]["ChartLabel"] = ChartLabel?.slice(0, 6)
                    data[i]["branch_name"] = list?.branch_name
    
                    rows = []
                    ChartData = []
                    ChartLabel = []
                    aggregatedData = {}
                }
                else {
                    data[i]["ChartValue"] = ChartData
                    data[i]["ChartLabel"] = ChartLabel
                    data[i]["TableData"] = rows
                }
            })
        }
        else{
            data.forEach((list, i) => {
                if (list.type_data.length > 0) {
                    list.type_data?.forEach(type => {
                        const Type = type.title;
                        if (aggregatedData[Type]) {
                            aggregatedData[Type] += type.values;
                        } else {
                            aggregatedData[Type] = type.values;
                        }
                    });
                    let FinalTotalArray = Object.entries(aggregatedData).map(([Type, values]) => ({ "title": Type, values }));
                    if (SortOrder === "asc") {
                        FinalTotalArray = FinalTotalArray.sort((a, b) => a.values - b.values);
                    } else if (SortOrder === 'des') {
                        FinalTotalArray = FinalTotalArray.sort((a, b) => b.values - a.values);
                    }
                    FinalTotalArray.forEach((item) => (ChartData.push(item?.values)))
                    FinalTotalArray.forEach((item) => (ChartLabel.push(item?.title.replace(/Appointment/g, ''))))
                    rows = [...rows, ...list?.type_data?.map((type, index) => ({
                        title: type.title,
                        values: type?.values,
                        branch_name: list?.branch_name,
                        branch_id: list?.branch_id,
                    }))]
    
                    if (SortOrder === "asc") {
                        rows = rows.sort((a, b) => a.values - b.values);
                    } else if (SortOrder === 'des') {
                        rows = rows.sort((a, b) => b.values - a.values);
                    }
                    data[i]["TableData"] = rows
                    data[i]["ChartValue"] = ChartData?.slice(0, 6)
                    data[i]["ChartLabel"] = ChartLabel?.slice(0, 6)
                    data[i]["branch_name"] = list?.branch_name
    
                    rows = []
                    ChartData = []
                    ChartLabel = []
                    aggregatedData = {}
                }
                else {
                    data[i]["ChartValue"] = ChartData
                    data[i]["ChartLabel"] = ChartLabel
                    data[i]["TableData"] = rows
                }
            })
        }

        return data
    }

    convertChartFormat = (data, label, value, from) => {
        let chart_data = []
        let chart_label = []

        data?.forEach((item, i) => {
            if (from === "Top Selling Drugs") {
                item?.drug_data?.forEach((list) => {
                    chart_label.push(list[label])
                    chart_data?.push(list[value])
                })
            }
            else if (from === "Location Analysis") {
                item?.data?.forEach((list) => {
                    chart_label.push(list[label])
                    chart_data?.push(list[value])
                })
            }
            else {
                item?.type_data?.forEach((list) => {
                    chart_label.push(list[label])
                    chart_data?.push(list[value])
                })
            }
            data[i]["ChartValue"] = chart_data
            data[i]["ChartLabel"] = chart_label
            data[i]["branch_name"] = item?.branch_name
            chart_data = []
            chart_label = []
            let rows = []
            if (from === "Top Selling Drugs") {
                if (item?.drug_data?.length > 0) {
                    rows = [...rows, ...item?.drug_data?.map((type) => ({
                        drug_ms__drug_name: type[label],
                        drug_counnt: type[value],
                        branch_name: item?.branch_name,
                        branch_id: item?.branch_id,
                        drug_amount: type.drug_amount,
                    }))]
                    data[i]["TableData"] = rows
                }
            }
            else if (from === "Location Analysis"){
                if (item?.data?.length > 0) {
                    rows = [...rows, ...item?.data?.map((type) => ({
                        patient__city__city_name: type[label],
                        city_count: type[value],
                        branch_name: item?.branch_name,
                        branch_id: item?.branch_id,
                    }))]
                    data[i]["TableData"] = rows
                }
            }
            else if (item?.type_data?.length > 0) {
                rows = [...rows, ...item?.type_data?.map((type) => ({
                    title: type[label],
                    values: type[value],
                    branch_name: item?.branch_name,
                    branch_id: item?.branch_id,
                }))]
                data[i]["TableData"] = rows
            }
        })
        return data

    }

    branchesApiCalls = () => {
        try {
            let states = this.state
            let chart_data = []
            let chart_label = []
            let tableDatas = []
            let data = []

            RestAPIService.getAll(states.url + `?from_date=${states.fromDate}&to_date=${states.toDate}&org_id=${states?.orgId}&org_data=true`)
                .then((response) => {
                    if (response.data.status === "success") {
                        data = response?.data?.data

                        if (this.state.isFrom === "barChart") {
                            //for convert response to chart data format
                            switch (this.state.title) {
                                case "Overall Payment Collection":
                                    data?.forEach((item, i) => {
                                        chart_data?.push(item?.cash, item?.card, item?.upi, item?.cheque, item?.bank_transfer)
                                        data[i]["ChartValue"] = chart_data
                                        data[i]["ChartLabel"] = ["Cash", "Card", "UPI", "Cheque", "Bank Trans"]
                                        data[i]["branch_name"] = item?.branch_name
                                        chart_data = []
                                    })
                                    break;

                                case "Appointment Type":
                                    data = this.convertChartFormat(data, "title", "values")
                                    tableDatas = this.convertTableFormat(data)
                                    break;

                                case "Top Selling Drugs":
                                    data = this.convertChartFormat(data, "drug_ms__drug_name", "drug_counnt", "Top Selling Drugs")
                                    tableDatas = this.convertTableFormat(data, "Top Selling Drugs")
                                    break;

                                case "Top Performing Specialzation":
                                    data = this.convertChartFormat(data, "title", "values")
                                    tableDatas = this.convertTableFormat(data)
                                    break;

                                case "Top Performing Doctor":
                                    data = this.convertChartFormat(data, "title", "values")
                                    tableDatas = this.convertTableFormat(data)
                                    break;

                                case "Location Analysis":
                                    if(this.state.tbltitle === "labLocation"){
                                        data = this.convertChartFormat(data, "patient__city__city_name", "city_count", "Location Analysis")
                                        tableDatas = this.convertTableFormat(data, "Location Analysis")
                                        break;

                                    }else{
                                        data = this.convertChartFormat(data, "title", "values")
                                        tableDatas = this.convertTableFormat(data)
                                        break;

                                    }

                                case "Purchase Vs Sales":
                                    data.forEach((item, i) => {
                                        chart_data.push(item?.sales_amount, item?.purchase_amount)
                                        data[i]["ChartValue"] = chart_data
                                        data[i]["ChartLabel"] = ["Sales", "Purchase"]
                                        chart_data = []
                                    })
                                    break;

                                case "Patient Demographics":
                                    data.forEach((item, i) => {
                                        chart_data.push(item?.ip_bill_count, item?.op_bill_count, item?.self_bill_count)
                                        data[i]["ChartValue"] = chart_data
                                        data[i]["ChartLabel"] = ["IP", "OP", "Self"]
                                        chart_data = []
                                    })
                                    break;

                                default:
                                    break;
                            }
                            this.setState({ branchData: data, tableData: tableDatas, apiLoaded: true })

                        } else {
                            switch (this.state.title) {
                                case "Total Revenue":
                                    data.forEach((item, i) => {
                                        chart_data.push(item?.op_revenue, item?.ip_revenue, item?.lab_revenue, item?.pharmacy_revenue, item?.radiology_revenue, item?.ot_revenue)
                                        data[i]["ChartValue"] = chart_data
                                        data[i]["ChartLabel"] = ["OP", "IP", "Lab", "Pharmacy", "Radiology", "OT"]
                                        chart_data = []
                                    })
                                    break;

                                case "Credit Bill Value":
                                    data.forEach((item, i) => {
                                        chart_data.push(item?.op_credit, item?.ip_credit, item?.lab_credit, item?.pharmacy_credit, item?.rad_credit)
                                        data[i]["ChartValue"] = chart_data
                                        data[i]["ChartLabel"] = ["OP", "IP", "Lab", "Pharmacy", "Radiology"]
                                        chart_data = []
                                    })
                                    break;

                                case "Total Patient Report":
                                    data = response?.data?.total_patient?.data
                                    data?.forEach((item, i) => {
                                        chart_data.push(item?.total_op_count, item?.total_ip_count)
                                        data[i]["ChartValue"] = chart_data
                                        data[i]["ChartLabel"] = ["OP", "IP"]
                                        chart_data = []
                                    })
                                    break;

                                case "Total Doctor Report":
                                    data = response?.data?.total_doctors
                                    data = this.convertChartFormat(response?.data?.total_doctors, "title", "values")
                                    tableDatas = this.convertTableFormat(data)
                                    break;

                                case "Most Performing Services":
                                    data = this.mostLeastSeparation(data, "des")
                                    tableDatas = this.convertTableFormat(data,"Most Performing Services")
                                    break;

                                case "Most Performing Tests":
                                    data = this.mostLeastSeparation(data, "des", "Tests")
                                    tableDatas = this.convertTableFormat(data, "Most Performing Services", "Tests")
                                    break;

                                case "Least Performing Services":
                                    data = this.mostLeastSeparation(data, "asc")
                                    tableDatas = this.convertTableFormat(data, "Least Performing Services")
                                    break;

                                case "Least Performing Tests":
                                    data = this.mostLeastSeparation(data, "asc", "Tests")
                                    tableDatas = this.convertTableFormat(data,"Least Performing Services", "Tests")
                                    break;

                                case "Sales Demographics":
                                    data.forEach((item, i) => {
                                        data[i]["ChartValue"] = [item?.total_op_amount, item?.total_inpatient_amount, item?.total_self_amount]
                                        data[i]["ChartLabel"] = ["OP", "IP", "Self"]
                                        data[i]["total_revenue"] = item?.total_op_amount + item?.total_inpatient_amount + item?.total_self_amount
                                        chart_data = []
                                    })
                                    break;

                                case "Vendor Payment":
                                    tableDatas = this.convertTableFormat(data, "Vendor Payment")
                                    data.forEach((item, i) => {
                                        item?.top_five.forEach((list, i) => {
                                            chart_label.push(list?.vendor__name)
                                            chart_data?.push(list?.total_paid_amount)
                                        })
                                        data[i]["ChartValue"] = chart_data
                                        data[i]["ChartLabel"] = chart_label
                                        data[i]["branch_name"] = item?.branch_name
                                        data[i]["TableData"] = tableDatas.filter((list)=>(list?.branch_id === item?.branch_id))
                                        chart_data = []
                                        chart_label = []
                                    })
                                    break;

                                default:
                                    break
                            }
                            this.setState({ branchData: data, apiLoaded: true, tableData: tableDatas })

                        }

                    }
                    else {
                        this.setState({ apiLoaded: true })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ apiLoaded: true })
                    this.errorMessage(error.message)
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    toolbar = () => {
        return (
            <GridToolbarContainer className={'eMed_refdoc_grid'} >
                <div>
                    <Tooltip placement='top' title='Export' arrow><GridToolbarExportContainer>
                        <GridCsvExportMenuItem />
                    </GridToolbarExportContainer></Tooltip>
                </div>
            </GridToolbarContainer>
        )
    }

    handleView = () => {
        this.setState({
            isChartView: !this.state.isChartView
        })
    }
    RefreshAllChartsData = () => {
        this.setState({
            branchData: [],
            apiLoaded: false,
        }, () => { this.branchesApiCalls() })
    }

    HeaderSegment = () => {
        return (
            <Box className="eMed_CharhPage_Header_div">
                <Box flex={0.8}>

                </Box>
                <Box flex={0.2} className="eMed_CharhPage_Header_filter" gap={'0.5vw'}>
                    {/* <Tooltip title="Export" placement='top' arrow>
                        <Button onClick={() => { this.getIpServiceExcel() }} disabled={this.state.disableBtn} variant='outlined' sx={{ backgroundColor: 'white',height:'2.8vw' }}>
                            <img src={ImagePaths.DownloadIcon.default} alt="upload" className='eMed_action_img' />
                        </Button>
                    </Tooltip> */}
                    <Tooltip title={this.state.isChartView ? "Table View" : "Chart View"} placement="top" arrow>
                        <Button onClick={() => this.handleView()} variant='outlined' sx={{ backgroundColor: 'white', height: '2.8vw' }}>
                            {this.state.isChartView ? <img src={ImagePaths.TableChange.default} alt="table" className='eMed_action_img' /> : <PieChartOutlineIcon sx={{ fontSize: "2vw" }} />}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Refresh All Charts" arrow placement='top'>
                        <Button onClick={() => {
                            this.RefreshAllChartsData()
                        }} variant='outlined' sx={{ backgroundColor: 'white', height: '2.8vw' }}>
                            <img src={ImagePaths.RefreshIcon.default} alt="upload" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                </Box>

            </Box>
        )
    }

    renderTable = () => {
        const { t } = this.props;
        let { branchData, tbltitle, tableData, title } = this.state;
        branchData?.length > 0 && branchData?.forEach((item, i) => item["sno"] = i + 1)
        let key = tbltitle
        let name = this.variableMap[key]
        let tableState = []
        let modifiedTb = false
        if ((title === "Appointment Type") || (title === "Top Performing Specialzation") || (title === "Top Performing Doctor") || (title === "Location Analysis") || (title === "Top Selling Drugs") || (title === "Least Performing Services") || (title === "Most Performing Services") || (title === "Vendor Payment") || (title === "Most Performing Tests") || (title === "Least Performing Tests") || (title === "Total Doctor Report")) {
            tableData?.length > 0 && tableData?.forEach((item, i) => item["sno"] = i + 1)
            tableState = tableData ? tableData : []
            modifiedTb = true
        }
        return (
            <Box component={'div'} className="eMed_expandPop_wrapper" backgroundColor={'white'} >
                <DataGrid
                    rows={modifiedTb ? tableState : branchData}
                    columns={name}
                    getRowId={(row) => row.sno}
                    headerHeight={40}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    pagination
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        Toolbar: this.toolbar,
                        NoRowsOverlay: () => {
                            return (
                                <Box className="eMed_noDataDiv">
                                    <p id="eMed_revenueCard_Txt">{t("NoRecordsFound")}</p>
                                </Box>
                            )
                        }
                    }}
                />
            </Box>
        )
    }

    renderChart = () => {
        let { title } = this.state
        let key = this.state.tbltitle
        let tableData = this.variableMap[key]
        let modifiedTb = false
        if ((title === "Appointment Type") || (title === "Top Performing Specialzation") || (title === "Top Performing Doctor") || (title === "Location Analysis") || (title === "Top Selling Drugs") || (title === "Least Performing Services") || (title === "Most Performing Services") || (title === "Least Performing Tests") || (title === "Most Performing Tests") || (title === "Vendor Payment") || (title === "Total Doctor Report")) {
            modifiedTb = true
        }
        return (
            <Box id="branches" className="eMed_Ovarall_Chart_Wrapper" padding={'1vw'}>
                <Typography component={'div'} fontSize={'1.2vw'} fontWeight={'bold'} marginBottom={'.5vw'}>{title}</Typography>
                <Grid container spacing={1} >
                    {this.state.branchData ? this.state.branchData?.map((item, i) => {
                        let array = []
                        array.push(item)
                        return (
                            <Grid item xs={3} >
                                <Box className="eMed_Individual_DashChart">
                                    {this.state.apiLoaded ?
                                        this.state.isFrom === "barChart" ?
                                            <Barchart
                                                title={item?.branch_name}
                                                Tlt2={"count"}
                                                chartLabel={item?.ChartLabel ? item?.ChartLabel : []}
                                                chartData={item?.ChartValue ? item?.ChartValue : []}
                                                tblData={modifiedTb ? item?.TableData : array}
                                                tblColumn={tableData}
                                            />
                                            :
                                            <DoughnutChart
                                                title={item?.branch_name}
                                                chartLabel={item?.ChartLabel ? item.ChartLabel : []}
                                                chartData={item?.ChartValue ? item.ChartValue : []}
                                                tblData={modifiedTb ? item?.TableData : array}
                                                subTitle={this.state.subTitle}
                                                totalAmt={item?.total_revenue ? item?.total_revenue : 0}
                                                position={"bottom"}
                                                showValueAtCenter={true}
                                                tblColumn={tableData}
                                                allow={true}
                                            /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                                </Box>
                            </Grid>
                        )
                    }) :
                        <Box className="eMed_noDataDiv">
                            <p id="eMed_revenueCard_Txt">{"No Branches Available"}</p>
                        </Box>
                    }
                </Grid>
            </Box>
        )
    }

    render() {
        return (
            <Box id='eMed_BM_mainDiv'>
                <Box component={'div'} className='eMed_Pharma_Header_Card'>
                    <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" onClick={() => {
                        this.props?.history?.goBack()
                    }} ><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {"Back to Branch Dashboard"}</Typography></Stack>
                </Box>
                {this.HeaderSegment()}
                {this.state.isChartView ? this.renderChart() : this.renderTable()}

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={!this.state.apiLoaded} />
            </Box>)
    }
}
export default withTranslation()(ChartComparison)

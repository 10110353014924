import { Box, Button, Stack, Tooltip, Typography, Drawer, TextField} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import {CommonGridToolBarWithFilterText} from '../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { Colors } from '../../../Styles/Colors';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { clearCachevalue, setCachevalue } from '../../../Utility/Services/CacheProviderService';

class PharmaPORequestList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      PORequestData: [],
      page: 0,
      pageSize: 10,
      fromDate: '',
      toDate: '',
      dateIndex: 0,
      filterOpen: false,
      isLoader: false,
      id: '',
      BillPreviewPop: false,
      vendorName:'',
      PONumber: '',

    }
  }

  componentDidMount() {
    this.getPORequestData()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  gridToolBar = () => {
    return (
      <Box>
        <CommonGridToolBarWithFilterText noExport={false} />
      </Box>
    )
  }

  ClosePopUp =() => {
    this.setState({BillPreviewPop: false})
  }

  getPORequestData = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.PURCHASE_ORDER_PO_REQUEST_LIST}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_name=${this.state.vendorName}&po_number=${this.state.PONumber}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              PORequestData: response.data?.data,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSalesoverviewPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.PURCHASE_ORDER_PO_REQUEST_LIST + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_name=${this.state.vendorName}&po_number=${this.state.PONumber}&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  closeFilter = () => {
    this.setState({ filterOpen: false })
  }

  filterDate = (From, To, Index) => {
    this.setState({
      fromDate: From,
      toDate: To,
      dateIndex: Index
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid='filter_close_btn' onClick={() => { this.setState({ filterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle' height={'35vh'}>
          <Box component={'div'} className='eMed_rts_dropdown'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.filterDate.bind(this)}
              HideAllMenu={false}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("VendorName")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              inputProps={{emed_tid: 'VendorName'}}
              value={states.vendorName ? states.vendorName : ""}
              onChange={(e) => {
                this.setState({
                  vendorName: e.target.value.trimStart()
                })
              }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("PO Number")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              inputProps={{emed_tid: 'PoNUmber'}}
              value={states.PONumber ? states.PONumber : ""}
              onChange={(e) => {
                this.setState({
                  PONumber: e.target.value.trimStart()
                })
              }}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            emed_tid='fil_clear' 
            onClick={() => {
              this.setState({
                vendorName: "",
                PONumber: "",
                fromDate: '',
                toDate: '',
                dateIndex: 0,
              }, () => {
                this.getPORequestData()
                this.setState({ filterOpen: false })
              })
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small"
            emed_tid='fil_search' 
            onClick={() => {
              this.getPORequestData()
              this.setState({ filterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }


  render() {
    const { t } = this.props
    this.state.PORequestData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      { field: "sno", sortable: false, flex: 0.05, headerName: t("SNo"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "purchase_order_date", flex: 0.1, headerName: t("PO Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.purchase_order_date ? formatDate(params?.row?.purchase_order_date) : "-"}</Box>)
      },
      {
        field: "po_number", flex: 0.15, headerName: t("PO Number"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.po_number ? params?.row?.po_number : "-"}</Box>)
      },
      {
        field: "vendor__name", flex: 0.15, headerName: t("Vendor Name"),
        renderCell: ({ row }) => (<div>{row?.vendor__name?.length > 25 ?
          <Tooltip placement="top" title={row?.vendor__name} arrow><div>{row?.vendor__name.slice(0, 24) + "..."}</div></Tooltip>
          : row?.vendor__name ? row?.vendor__name : "-"}</div>)
      },
      {
        field: "vendor__gstin_number", flex: 0.15, headerName: t("GST No"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.vendor__gstin_number?.length > 18 ?
          <Tooltip placement="top" title={row?.vendor__gstin_number} arrow><div>{row?.vendor__gstin_number.slice(0, 18) + "..."}</div></Tooltip>
          : row?.vendor__gstin_number ? row?.vendor__gstin_number : "-"}</div>)
      },
      {
        field: "no_of_items", flex: 0.15, headerName: t("No of Items"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.no_of_items ? params?.row?.no_of_items : "-"}</Box>)
      },
      {
        field: "status", flex: 0.15, headerName: t("Status"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => {
          let purchase_status = row?.status === "Completely Delivered" ? "Delivered" : row?.status === "Partially Delivered" ? "Partially Delivered" : "Place order"
          let divColor = row?.status === "Completely Delivered" ? "#ECFFE9" : row?.status === "Partially Delivered" ? "#FFE9E9" : null;
          let txtColor = row?.status === "Completely Delivered" ? "#1E972A" : row?.status === "Partially Delivered" ? "#B82121" : null;
          return (
            <Stack sx={{ padding: "0.4vw", borderRadius: "0.2vw", backgroundColor: divColor }}>
              <Typography sx={{ color: txtColor }}>{purchase_status ? purchase_status : "-"}</Typography>
            </Stack>
          );
         }
      },
      {
        field: "action", flex: 0.10, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: ({ row }) =>(<Box alignItems={"center"}>
        <Tooltip title={t("PrintBill")} placement="top" arrow>
            <Button onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: row , id: row?.id}) }} className='eMed_usrconf_btn' emed_tid='PO_requestList_print'>
                <img src={ImagePaths.PrintIcons.default} alt="Create Bill" style={{width: '1.5vw', height: '1.5vw'}} />
            </Button>
        </Tooltip>
        <Tooltip title={t("Edit Request")} placement="top" arrow>
            <Button disabled={row?.status === "Placed Order" ? false : true} onClick={() => {this.props.history.push({pathname:'/PharmacyPurchase/PurchaseOrder/POrequest', states:{selectedID: row.id}}); setCachevalue(true, "IsPORequestEdit");}} className='eMed_usrconf_btn' emed_tid='PO_requestList_print'>
                <img src={row?.status === "Placed Order" ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} alt="Create Bill" style={{width: '1.5vw', height: '1.5vw'}} />
            </Button>
        </Tooltip>
        </Box>
        )
      }
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Purchase_Table_top'>
            <Button emed_tid = "po_requestList_filter" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            {/* <Button emed_tid = "rpt_saleOver_print" className='eMed_Pharma_rts_btn' onClick={()=>{this.getSalesoverviewPrint()}}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button emed_tid = "rpt_saleOver_export" className='eMed_Pharma_rts_btn' onClick={() => this.getSalesoverviewExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button> */}
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.PORequestData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            hideFooterSelectedRowCount
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            initialState={{
              columns:{
                columnVisibilityModel:{
                  state:false,
                  igst:false
                }
              }
            }}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            loading= {this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>

        {
          this.state.BillPreviewPop ?
            <PrintPreviewPop
              PRINT_URL={`${Serviceurls.PURCHASE_ORDER_PO_REQUEST_LIST}?id=${this.state.id}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_name=${this.state.vendorName}&po_number=${this.state.PONumber}&export=pdf`}
              Title={t("BillPreview")}
              BillData={this.state.SelectedBill}
              ClosePopUp={this.ClosePopUp.bind(this)}
              ModifyBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Receipt Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient") : false}
              cancelBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Receipt Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient") : false}
              PrintButton={false}
              Pharmacy={this.state.isPharmacy}
              history={this.props.history} /> : null
        }

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(PharmaPORequestList)

import { Box, Button, Grid, Switch, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from '../../../Utility/Services/RestAPIService';
import '../Configuration.css';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

class UserConfiguration extends Component {
  constructor() {
    super()
    this.state = {
      active_status: "False",
      userConfigData: [],
      page: 0,
      pageSize: 10
    }
  }

  componentDidMount() {
    this.getUserConfigData()
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  onActionHandler = (params) => {
    if (params?.row?.role) {
      this.setState({
        action: !this.state.action
      })
    }
  }

LoaderFunction = (key) => {
  this.setState({
    isLoader: key
  })
}

  getUserConfigData = () => {
    const { t } = this.props
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.FO_CONFIG_USER_CONFIGURATION).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              userConfigData: response.data.data
            },()=>{this.LoaderFunction(false)})
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  postUserConfigData = (login_id) => {
    const { t } = this.props
    try {
      let data = {
        "login_id": login_id,
        "active_status": this.state.active_status
      }
      RestAPIService.create(data, Serviceurls.FO_CONFIG_USER_CONFIGURATION).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.getUserConfigData()
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    this.state.userConfigData.forEach((element, index) => element.sno = index + 1)
    const { t } = this.props
    const columns = [
      { field: "sno", sortable: false, headerName: t('SNo'), flex: 0.125 },
      {
        field: "role_name", headerName: t('role'), flex: 0.125,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.role_name ? params?.row?.role_name : "-"}</Box>)
      },
      // {
      //   field: "user_email", headerName: t('Email'), flex: 0.125,
      //   renderCell: (params) => (<Box component={'div'}>{params?.row?.user_email ? params?.row?.user_email : "-"}</Box>)
      // },
      {
        field: "user_email", headerName: t('Email'), flex: 0.125,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.user_email ? params?.row?.user_email.length > 10 ?
           <Tooltip placement='top' title={params?.row?.user_email} arrow>
             <Typography>{params?.row?.user_email.slice(0,18) + "..."}</Typography></Tooltip>
             : params?.row?.user_email  : "-"}
          </Box>)
      },
      {
        field: 'user_phone_number', headerName: t('MobileNumber'), flex: 0.125, align: "center", headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.user_phone_number ? params?.row?.user_phone_number : "-"}</Box>)
      },
      {
        field: "user_name", headerName: t('userName'), flex: 0.125,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.user_name ? params?.row?.user_name : "-"}</Box>)
      },
      // {
      //   field: 'rst', headerName: t('resetPassword'), flex: 0.125,
      //   renderCell: (params) => (<Stack component={'div'}>{"Nil"}</Stack>)
      // },
      {
        field: 'lock', headerName: t('lockunlock'), flex: 0.125,
        renderCell: (params) => (<Stack component={'div'}><Switch checked={params.row.login_count !== 6 ? true : false} disabled={false} /></Stack>)
      },
      {
        field: 'action', headerName: 'Action', flex: 0.125, headerAlign: "center", align: "center",  sortable: false, hideable: false,
        renderCell: (params) => (<Tooltip placement='top' title={params?.row?.login_count !== 6 ? "Lock" : "Unlock"} arrow><Button disabled={!CheckAccessFunc("front_office", "Settings", "User Access Configuration",  "User Configuration", "SubTab")?.editAccess} className='eMed_usrconf_img'>
          <img src={ImagePaths.LockBtn.default} alt='lock'  onClick={() => {
            this.setState({ active_status: params?.row?.login_count !== 6 ? "False" : "True" },
              () => { this.postUserConfigData(params?.row?.login_id) })
          }} />
        </Button></Tooltip>)
      }
    ]
    return (
      <Box component={"div"} className="eMed_usrconf_container" >
        <Grid container>
          <Grid item xs={12} className='eMed_usrconf_header'>
            {/* Commeted for print and export not done */}
            {/* <Button className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_usrconf_img' />
            </Button>
            <Button className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_usrconf_img' />
            </Button> */}
          </Grid>
          <Grid item xs={12} className="eMed_conf_user_table">
            <DataGrid
              className="eMed_usrConf_tbl"
              rows={this.state.userConfigData}
              columns={columns}
              getRowId={(row) => row.login_id}
              page={this.state.page}
              pageSize={this.state.pageSize}
              onPageChange={(newPage) => this.setState({ page: newPage })}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              loading={this.state.isLoader}
            />
          </Grid>
        </Grid>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(UserConfiguration)
import { Autocomplete, Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { formatDate, AmountFormat, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class IPServiceDetailed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            doctorName: [],
            serviceName: [],
            serviceType: [],
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            serviceDetailedData: [],
            serviceDetailTotalAmount: "",
            serviceDoctorName: [],
            serviceNameAndType: [],
            serviceTypeList:[],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
            },
            timeError: false,
        }
    }

    componentDidMount() {
        this.getServiceDetailedData()
        this.getServiceFilterDoctorList()
        this.getServiceNameAndTypeFilterList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getServiceDetailedData = () => {
        try {
            this.LoaderFunction(true)
            let doctorId = []; let serviceName = []; let serviceType = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.serviceName.forEach(element => serviceName.push(element))
            this.state.serviceType.forEach(element => serviceType.push(element))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_IP_SERVICE_DETAILED + `?doctor_id=${doctorId}&service_type=${serviceType}&service_name=${serviceName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceDetailedData: response.data.data,
                            serviceDetailTotalAmount: response.data.total_amount,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    IPServiceDetailedReportPrint = () =>{
        try {
            let doctorId = []; let serviceName = []; let serviceType = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.serviceName.forEach(element => serviceName.push(element))
            this.state.serviceType.forEach(element => serviceType.push(element))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_IP_SERVICE_DETAILED + `?doctor_id=${doctorId}&service_type=${serviceType}&service_name=${serviceName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getServiceFilterDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            serviceDoctorName: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getServiceNameAndTypeFilterList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCNAME + `?ip_service_detail=${true}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceNameAndType: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        const other_consultant_name = "Others";
        const other_consultant_type = "Consultation Other";
        let serviceTypeList = []; let NewServiceType = [];
        this.state.serviceNameAndType.map((item) => item.data.map((data) => serviceTypeList.push(data.service_name)))
        this.state.serviceNameAndType.map((item) => NewServiceType.push(item.service_type))
        let uniqueArray = [...new Set(serviceTypeList)]
        let serviceTypeUnique = [...new Set(NewServiceType)]
        uniqueArray.push(other_consultant_name);
        serviceTypeUnique.push(other_consultant_type);
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} 
                            HideAllMenu={true}/>
                    </Box>
                    <Box sx={{width: '22vw'}}>
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        FromDate={this.state.fromDate}
                        ToDate={this.state.toDate}
                        onError={this.state.FilterDatas.onError}
                    />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.serviceDoctorName}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                  {option?.doctor_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectDoctor')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={uniqueArray}
                            getOptionLabel={(option) => (option)}
                            value={this.state.serviceName}
                            onChange={(event, newValue) => {
                                this.setState({ serviceName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("selectService")}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={serviceTypeUnique}
                            getOptionLabel={(option) => (option)}
                            value={this.state.serviceType}
                            onChange={(event, newValue) => {
                                this.setState({ serviceType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Select Service Type")}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({ 
                                doctorName: [], 
                                serviceName: [], 
                                serviceType: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime":null,
                                    "endTime":null,
                                    "onError": false
                                },
                                timeError: false,
                            },
                                () => this.getServiceDetailedData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                    disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getServiceDetailedData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
            {label:"To Date", value:formatDate(this.state.FilteredToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return(
          <Box>
             <CommonGridToolBarWithFilterText FilterTextArray={TextArray}/>
          </Box>
    
        )
      }
    render() {
        this.state.serviceDetailedData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "invoice_date", headerName: t("Date"), flex: 0.143, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? formatDate(params?.row?.invoice_date) : '-'}</Box>)
            },
            {
                field: "patient_name", headerName: t("PatientName"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.patient_name} arrow><div>{params?.row?.patient_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.patient_name ? params?.row?.patient_name : "-"}</Box>)
            },
            {
                field: "doctor_name", headerName: t("DoctorName"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.doctor_name ? params?.row?.doctor_name : "-"}</Box>)
            },
            {
                field: "nurse_name", headerName: t("NurseName"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.nurse_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.nurse_name} arrow><div>{params?.row?.nurse_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.nurse_name ? params?.row?.nurse_name : "-"}</Box>)
            },
            {
                field: "invoice_number", headerName: t("InvoiceNumber"), flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
                    <Box>{params?.row?.invoice_number ?
                        "..." + params?.row?.invoice_number.slice(9) : params?.row?.invoice_number}</Box>
                </Tooltip> : "-"}</Box>)
            },
            {
                field: "service_name", headerName: t("ServiceName"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_name ? params?.row?.service_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.service_name} arrow><div>{params?.row?.service_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.service_name : "-"}</Box>)
            },
            {
                field: "service_type", headerName: t("ServiceType"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_type ? params?.row?.service_type?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.service_type} arrow><div>{params?.row?.service_type.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.service_type : "-"}</Box>)
            },
            {
                field: "total_amount", headerName: `${t("ServiceAmount")} (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_amount ? AmountFormat(params?.row?.total_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("TotalAmount"), this.state.serviceDetailTotalAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => this.IPServiceDetailedReportPrint()}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.serviceDetailedData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}


export default withTranslation()(IPServiceDetailed)
import { Typography, TextField, OutlinedInput, InputAdornment, IconButton, Tooltip } from '@mui/material';
import React, { Component } from 'react';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import './home.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Trans, withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
class SavedBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: "",
            savedBillList: [],
            isSelectDatePicker: false,
            filterDate: new Date(),
            isSuccessMsg: false,
            isSuccessMsgText: "",
            isErrorMsg: false,
            isErrorMsgText: "",
            SelectedBill: {},
            disableBtn: false,
        }
    }
    componentDidMount() {
        this.getSavedBillList()
    }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          isSuccessMsg: false,
          isSuccessMsgText: "",
        })
      }

    getSavedBillList() {
        let date = DateTime.fromJSDate(this.state.filterDate).toFormat('yyyy-MM-dd')
        if (this.state.searchkey || date) {
            var serviceUrl = Serviceurls.LAB_PARTIAL_BILL_LIST + "?search_key=" + this.state.searchkey + "&from_date=" + date

        } else {
            var serviceUrl = Serviceurls.LAB_SAVED_BILL_LIST
        }
        try { 
        this.setState({disableBtn: true})
        RestAPIService.getAll(serviceUrl)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        savedBillList: response.data.data,
                        disableBtn: false,
                    });
                } 
            }).catch((error)=>{
                this.setState({
                  isErrorMsg: true,
                  isErrorMsgText: error?.response?.data?.message,
                  disableBtn: false,
                })
              })
          }catch(error){
              this.setState({
                  isErrorMsg: true,
                  isErrorMsgText: error.message,
                  disableBtn: false,
                })
          }
    }
    savedBillDelete = () => {
            try {
                this.setState({disableBtn: true})
                RestAPIService.delete(Serviceurls.LAB_SAVED_BILL_DELETE + "?bill_summary_id=" + this.state.SelectedBill.bill_summary_id )
                    .then(response => {
                        if (response.data.status == "success") {
                            this.setState({
                                isSuccessMsg: true,
                                isSuccessMsgText: response.data.message,
                                SelectedBill : {},
                                disableBtn: false,
                            }, () => {
                                this.getSavedBillList()
                                this.props.savedBillStatus(true)
                            })
                        }
                    }).catch((e => {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.response.data.message,
                            disableBtn: false,
                        })
                    }))
    
            } catch (e) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: e.message,
                    disableBtn: false,
                })
            }
    }
    savedBillPrint = (bill_summary_id) => {
            const {t} = this.props
            try{
                let url;
                   url = Serviceurls.LAB_PARTIAL_BILL_POST
                RestAPIService.getAll(url +  "?bill_summary_id=" + bill_summary_id + "&export_type=pdf").
                then((response)=>{
                    const file = new Blob(
                        [response.data],
                        {type : 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error)=>{
                  this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.reponse.data.message
                  })
                })
            }catch(error){
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("Create Bill Print Error")
                  })
            }
    }

    popupClose() {
        this.setState({
            SelectedBill: {},
        })
    }
    render() {
        const { history } = this.props.properties
        const {t} = this.props
        return (
            <div>
                <div className="emed_header">
                    <h1 className="emed_orderhead">{t("SavedBills")}</h1>
                    <img 
                         src={ImagePaths.LabCloseButton.default} 
                         className='eMed_sts_cls_img'
                         onClick={()=>{this.props.onClickSavedBills(false)}}
                         alt="close"
                         />
                </div>
                <div className='eMed_sts_card'>
                    <OutlinedInput
                        className="emed_ordersearch"
                        emed_tid='Lab_Home_Saved_Filter'
                        placeholder= {t("Search Patient...")}
                        onChange={(e) => {
                            this.setState({
                                searchkey: e.target.value
                            })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "13vw", marginRight: "0.5vw" }}
                        endAdornment={
                            <IconButton disabled={this.state.disableBtn} onClick={() => {
                                this.getSavedBillList()
                            }} position="end">
                                <i class="fas fa-search"></i>
                            </IconButton>
                        }
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                            className="Lab_SavedBill_date"
                            id="Lab_SavedBill_date"
                            label={("")}
                            inputFormat={"dd-MM-yyyy"}
                            autoComplete="off"
                            value={this.state.filterDate}
                            onChange={(newDate) => {
                                this.setState({
                                    filterDate: newDate,
                                }, () => {
                                    this.getSavedBillList()
                                })
                            }}
                            renderInput={(params) => <TextField {...params} size='small' sx={{ marginRight: '0.4vw' }} 
                                error={this.state.expDateerror} helperText={this.state.expDatehelpertext} />}
                        />
                    </LocalizationProvider>
                </div>
               
                {this.state.savedBillList && this.state.savedBillList.length > 0 ?
                    <div className='Lab_Saved_Bill_Main'>
                        {this.state.savedBillList.map((item, index) => {
                            return (
                                <div className="Lab_Home_Saved_Bill">
                                    <div className='SavedBillFirst' >
                                        <Typography className='savedBillText'>{
                                        (item.patient_name + item.surname).length > 15 ? 
                                        <Tooltip placement='top' title={item.patient_name + item.surname}><div>{(item.patient_name + item.surname).slice(0,15) + "..."}</div></Tooltip> :
                                        `${item.patient_name} ${item.surname}`}</Typography>
                                       <div className='Lab_saved_bill_sub_container'>
                                         <Typography  sx= {{ fontSize: "0.8vw" }} className='savedTextLight'>{item.phone_number+ "  " }</Typography>
                                         <Typography  sx= {{ fontSize: "0.8vw", marginLeft: "0.5vw" }} className='savedTextLight'>{" | " + item.patient_accout_number}</Typography>
                                       </div> 
                                    </div>
                                    <div className='SavedBillSecond'>
                                        <Typography className='savedBillText'>{t("Date")}</Typography>
                                        <Typography sx= {{ fontSize: "0.8vw" }}  className='savedTextLight'>{item.invoice_date}</Typography>
                                    </div>
                                    <button size="small" className='Lab_Saved_Bill_Button' emed_tid='Lab_Home_Saved_Bill' disabled={this.state.disableBtn} onClick={() => {
                                        this.setState({disableBtn: true})
                                        history.push({ pathname: "/MainHome", state: { savedBillDetails: item } })
                                        this.props.onClickSavedBills(false)
                                        setTimeout(() => {
                                            history.push({ pathname: "/MainHome"})
                                        }, 2000);
                                    }}><p className='Lab_saved_Bill_Text'>{t("Proceed")}</p></button>
                                    {/* <IconButton size='small'>
                                        <img src={ImagePaths.PrintIcons.default} className='Saved_Bill_Icon' alt='Delete'
                                            onClick={() => {
                                                this.savedBillPrint(item.bill_summary_id)
                                            }}
                                        />
                                    </IconButton> */}
                                     <IconButton size='small' emed_tid='Lab_SavedBill_Delete_Btn' disabled={this.state.disableBtn}>
                                        <img src={ImagePaths.Delete.default} className='Saved_Bill_Icon' alt='Delete'
                                            onClick={() => {
                                                this.setState({ SelectedBill: item })
                                                // this.savedBillDelete(item.bill_summary_id)
                                            }}
                                        />
                                    </IconButton>
                                </div>
                            )
                        })}
                    </div> :
                    <div className='noRecordsStyle'><Typography>{t("No records found")}</Typography></div>}
                     {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.isSuccessMsgText}
                        msgPop={this.msgClose.bind(this)}
                    /> : null
                }
                {Object.keys(this.state.SelectedBill).length > 0 ? <DeletePopup
                    DeletTitle={"Are you sure ? Do you want to delete this Saved bill ?"}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.savedBillDelete.bind(this)}
                    testID="Saved_toBill_deleteOk"
                    BillType="Saved Bill"
                /> : null}
            </div>
        );
    }
}

export default withTranslation()(SavedBill)

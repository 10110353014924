import React, { Component } from 'react';
import "./SubLocationReports.css";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Drawer, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { CommonGridToolBarWithFilterText, CommonPopUp, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import SubLocReportFilter from "./SubLocReportFilter";
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../Utility/Constants';

class SubLocCreditReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DataList: [],
      billDataList: [],
      patientData: {},
      pharmaDetail: {},
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
      },
      totalBill: 0,
      isView: false,
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      pageName: 'SubCreditReport',
      pharmacyId: null,
    }
  }

  componentDidMount() {
      let subLocationDetails = localGetItem("loggedInUserInfo")
      let detail = JSON.parse(subLocationDetails)
      this.setState({
          pharmacyId: detail?.pharmacy_id,
      }, () => {
          this.getSubLocCreditRpt(this.props.RowId)
      })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
      this.getSubLocCreditRpt()
    })
  }

  getSubLocCreditRpt = () => {
    try {
      RestAPIService.getAll(`${Serviceurls.SUBLOC_CREDIT_REPORT_GET}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}`)
        .then((response) => {
          if (response.data.status === "success") {
            let list = response.data?.data?.length > 0 ? response.data.data : [];
            list.length > 0 && list?.forEach((item, i) => {
              item["sno"] = i + 1;
            })
            this.setState({
              DataList: list,
              totalBill: response.data?.total_bill_amount ? response.data.total_bill_amount : 0
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getSubLocCreditRptPrint = () => {
    try {
      RestAPIService.getAll(`${Serviceurls.SUBLOC_CREDIT_REPORT_GET}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response.data.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSubLocCreditRptExcel = () => {
    try {
      RestAPIService.excelGet(`${Serviceurls.SUBLOC_CREDIT_REPORT_GET}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export=Excel`)
        .then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }



  getSubLocCreditIndividualRpt = (item) => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_CREDIT_INDIVIDUAL_REPORT_GET + `?ip_admission_id=${item?.ip_admission_id ? item?.ip_admission_id : ""}` + `&appointment_id=${item?.appointment_id ? item?.appointment_id : ""}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              billDataList: response.data.data
            })
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  // view pop up start

  billViewClose = () => {
    this.setState({
      isView: false,
      billDataList: []
    })
  }
  getPrintPreview = () => {
    try {
      let id = this.state.billDataList[0].id ?this.state.billDataList[0].id : null
        RestAPIService.getAll(Serviceurls.PHARMACY_INVOICE_PRINT + `?id=${id}&pharmacy_id=${this.state.pharmacyId}&export=pdf`).
        then((response) => {
          const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
      }).catch((error) => {
          this.errorMessage(error.response?.data?.message)
      })
    }
    catch (e) {
        this.errorMessage(e.message)
    }
}

  _showBillDetails = () => {
    const { t } = this.props;

    let { billDataList, pharmaDetail } = this.state;
    billDataList?.forEach((element, index) => { element.sno = index + 1 });

    const billColumn = [
      {
        field: 'invoice_number', headerName: t('CreditBillNo'), flex: 0.25, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div> {row?.invoice_number ? row?.invoice_number : "-"}</div>)
      },
      {
        field: 'total_count_item', headerName: t('TotalItems'), flex: 0.19, type: "number",
        renderCell: ({ row }) => (<div>{row?.total_count_item ? row?.total_count_item : "0"}</div>)
      },
      {
        field: 'invoice_date', headerName: t('BillDate'), flex: 0.21, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (
          <div>{row?.invoice_date ? row?.invoice_date : "-"}</div>
        )
      },
      {
        field: 'grand_total', headerName: t('BillAmount'), flex: 0.19, type: "number",
        renderCell: ({ row }) => (<div>{row?.grand_total ? AmountFormat(row?.grand_total) : "0"}</div>)
      },
      {
        field: 'action', headerName: t('Action'), flex: 0.10, sortable: false, hideable: false, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (
          <Tooltip title={t("Print")} placement='top' arrow>
            <IconButton emed_tid='subLoc_crdrpt_indPrint' size="small" onClick={() => { this.getPrintPreview()}}>
              <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_IP_Exp_printimg' />
            </IconButton>
          </Tooltip>
        )
      }
    ];

    return (
      <Box className="eMed_subLocCrdt_Box">
        <Box className="eMed_subLocCrdt_headBox">
          <Box sx={{ flex: 0.25, marginLeft: "0.5vw" }}>
            <Typography id="eMed_PharmaVendor_headTxt" sx={{ margin: "0vw 0vw 0.3vw 0.3vw" }}>{t("PatientDetails")}</Typography>
            <Box display={'flex'}>
              <Box mr={'0.5vw'}>
                <Box component={'img'} src={pharmaDetail?.patient_id__photo_url ? pharmaDetail?.patient_id__photo_url : ImagePaths.Male.default}
                 border={pharmaDetail?.patient_type === 'review_patient' ? '2px solid #29B6F6' : pharmaDetail?.patient_type === 'new_patient' ? "2px solid #F1584A" : '2px solid #800080'} 
                 borderRadius={'100px'} width={'2.5vw'} height={'2.5vw'} />
              </Box>
              <Box>
                {pharmaDetail?.patient_name ? pharmaDetail?.patient_name?.length > 25 ?
                  <Tooltip placement='top' title={`${pharmaDetail?.patient_name} ${pharmaDetail?.patient_age}`}>
                    <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>{`${pharmaDetail?.patient_name?.slice(0, 25) + "..."} ${pharmaDetail?.patient_age}`}</Typography>
                  </Tooltip> :
                  <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>{`${pharmaDetail?.patient_name} ${pharmaDetail?.patient_age}`}</Typography>
                  : '-'}
                <Typography fontSize={'0.8vw'}>{`${pharmaDetail?.patient_id__patient_account_number} | ${pharmaDetail?.patient_id__mobile_number}`}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 0.25 }}>
            <Typography id="eMed_PharmaVendor_headTxt">{t("Pharmacy")}</Typography>
            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>{pharmaDetail.sublocation_id__sublocation_name}</Typography>
            {pharmaDetail.sublocation_id__sublocation_address_1 ? <Typography fontSize={'0.8vw'} color={'gray'}>{pharmaDetail.sublocation_id__sublocation_address_1}</Typography> : null}
            {pharmaDetail.sublocation_id__sublocation_city ? <Typography fontSize={'0.8vw'} color={'gray'}>{pharmaDetail.sublocation_id__sublocation_city}</Typography> : null}
            {pharmaDetail.sublocation_id__sublocation_state ? <Typography fontSize={'0.8vw'} color={'gray'}>{pharmaDetail.sublocation_id__sublocation_state}</Typography> : null}
            {pharmaDetail.sublocation_id__sublocation_mobile ? <Typography fontSize={'0.8vw'} color={'gray'}>{pharmaDetail.sublocation_id__sublocation_mobile}</Typography> : null}
          </Box>
          <Box sx={{ flex: 0.25 }}>
            <Typography id="eMed_PharmaVendor_headTxt">{t("TotalOutstanding")}</Typography>
            <Typography id="eMed_PharmaVendor_Txt">{pharmaDetail.bill_amount ? AmountFormat(pharmaDetail.bill_amount) : `${CurrencySymbol} 0.00`}</Typography>
          </Box>
        </Box>
        <Box id="eMed_subLocCrdt_viewTbl">
          <DataGrid
            rows={billDataList}
            columns={billColumn}
            getRowId={(row) => row.sno}
            disableColumnMenu
            hideFooter
            components={{
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            headerHeight={40}
          />
        </Box>
      </Box>
    )
  }

  printViewBillList = () => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_CREDIT_INDIVIDUAL_REPORT_GET + `?ip_admission_id=${this.state.pharmaDetail?.ip_admission_id ? this.state.pharmaDetail?.ip_admission_id : ""}` + `&appointment_id=${this.state.pharmaDetail?.appointment_id ? this.state.pharmaDetail?.appointment_id : ""}`+`&export=pdf`).
          then((response) => {
              const file = new Blob(
                  [response.data],
                  { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
          }).catch((error) => {
              this.errorMessage(error.response?.data?.message)
          })
  }
  catch (e) {
      this.errorMessage(e.message)
  }
  }

  // view pop up end

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: this.state.filterDatas.fromDate },
      { label: "To Date", value: this.state.filterDatas.toDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  renderTable = () => {
    const { t } = this.props
    let states = this.state;
    const columns = [
      {
        field: 'patient_name', headerName: t(`Patient Details`), flex: 0.20,
        renderCell: ({ row }) => (
          <Box>
            {row?.patient_name ? row?.patient_name?.length > 25 ?
              <Tooltip placement='top' title={`${row?.patient_name} ${row?.patient_age}`}>
                <Typography fontWeight={600} fontSize={'0.9vw'}>{`${row?.patient_name?.slice(0, 25) + "..."} ${row?.patient_age}`}</Typography>
              </Tooltip> :
              <Typography fontWeight={600} fontSize={'0.9vw'}>{`${row?.patient_name} ${row?.patient_age}`}</Typography>
              : '-'}
            <Typography fontSize={'0.8vw'}>{`${row?.patient_id__patient_account_number} | ${row?.patient_id__mobile_number}`}</Typography>
          </Box>)
      },
      {
        field: 'ip_admission__ip_number', headerName: t(`IP / OP No`), flex: 0.20, headerAlign: "center", align: "center",
        renderCell: ({ row }) => {
          let BillID = row?.ip_admission__ip_number ? row?.ip_admission__ip_number : (row?.appointment__op_number ? row?.appointment__op_number : "-");
          return(
            BillID.length > 28 ?
          <Tooltip placement="top" title={BillID} arrow><div>{BillID.slice(0, 26) + "..."}</div></Tooltip>
          : <Box component={'div'}>{BillID ? BillID : "-"}</Box>)
        }
      },
      {
        field: 'total_bill', headerName: t(`TotalBill`), flex: 0.12, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row.total_bill ? row.total_bill : 0}</Box>)
      },
      {
        field: 'bill_amount', headerName: t(`BillAmount(${CurrencySymbol})`), flex: 0.13, type: "number",
        renderCell: ({ row }) => (<div>{row.bill_amount ? AmountFormat(row.bill_amount).replace(`${CurrencySymbol}`, "") : 0}</div>)
      },
      {
        field: 'action', headerName: t(`Action`), flex: 0.10, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Tooltip title={t("View")} placement="top" arrow>
          <IconButton emed_tid='subLoc_crdrpt_view' className='eMed_usrconf_btn' onClick={() => {
            this.setState({
              isView: true,
              pharmaDetail: row
            },
              () => this.getSubLocCreditIndividualRpt(row))
          }}>
            <img src={ImagePaths.LabViewButton.default} alt="View" className='eMed_action_img' />
          </IconButton>
        </Tooltip>)
      }
    ]
    return (
      <Box component={'div'}>
        <Paper sx={{ height: '63vh' }}>
          <DataGrid
            rows={states.DataList}
            columns={columns}
            getRowId={(row) => row.sno}
            page={states.page}
            pageSize={states.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => this.setState({ page: newPage })}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
        </Paper>
      </Box>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard(t("TotalBill"), this.state.totalBill)}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <IconButton emed_tid={"subLoc_crdrpt_flt"} className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </IconButton>
            <IconButton emed_tid={"subLoc_crdrpt_print"} className='eMed_Pharma_rts_btn' onClick={() => { this.getSubLocCreditRptPrint() }}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
            </IconButton>
            <IconButton emed_tid={"subLoc_crdrpt_export"} className='eMed_Pharma_rts_btn' onClick={() => { this.getSubLocCreditRptExcel() }}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </IconButton>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          {this.renderTable()}
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <SubLocReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>

        {this.state.isView ?
          <CommonPopUp
            width={"75vw"}
            title={t("Billed Report")}
            popUpClose={this.billViewClose.bind(this)}
            component={this._showBillDetails.bind(this)}
            testID={"subLoc_crd_viewPop"}
            backgroundColor={Colors.white}
            iconTitle={t("print")}
            titleIconImg={ImagePaths.LabPrintIcon.default}
            IconAction={this.printViewBillList.bind(this)}
          />
          : null
        }

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(SubLocCreditReport);

import { Autocomplete, Box, Button, Grid, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

const data = [
    { id: 1, insurance_name: 'Star Health Insurance Private limited and Corporation' },
    { id: 2, insurance_name: 'LIC Health Insurance Private limited and Corporation' },
]
class InsuranceConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            insuranceData: [],
            deletePopup: false,
            rowValue: null,
            selectedId: null,
            insuranceName: null,
            delSelectedId: null,
            insuranceMasterData: []
        }
    }

    componentDidMount() {
        this.getInsuranceData()
        this.getInsuranceMasterData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }


    onEditHandler = (value) => {
        const { t } = this.props
        try {
            this.setState({
                isEdit: true,
                insuranceName: value?.row,
                selectedId: value?.row?.id,
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (value) => {
        if (!this.state.isEdit) {
            this.setState({
                deletePopup: true,
                rowValue: value?.row,
                delSelectedId: value?.row?.id
            })
        }else{
            this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
        }
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    onClearHandler = () => {
        this.setState({
            insuranceName: null,
            selectedId: null,
            selectedId: null,
            delSelectedId: null,

        })
    }

    getInsuranceMasterData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.INSURANCE_MASTER).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            insuranceMasterData: response.data.data
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getInsuranceData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            insuranceData: response.data.data
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    postInsuranceData = () => {
        const { t } = this.props
        try {
            let data = {
                "insurance_id": this.state.insuranceName?.id
            }
            if (this.state.isEdit) {
                data['id'] = this.state.selectedId
            }

            if (!this.state.insuranceName) {
                this.errorMessage("Please Select Insurance Type")
            }
            else {
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.FO_INSURANCE_LIST).
                    then((response) => {
                        if (response.data.status === 'success') {
                            this.onClearHandler()
                            this.getInsuranceData()
                            this.setState({ isEdit: false })
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteReferrralDoctorData = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.FO_INSURANCE_LIST + "?id=" + this.state.delSelectedId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.getInsuranceData()
                        this.setState({ deletePopup: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderInsuranceLeft = () => {
        this.state.insuranceData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            { field: "sno", sortable: false, flex: 0.10, headerName: t("SNo") },
            {
                field: "insurance_name", flex: 0.75, headerName: t("Insurance Company Name"), type: 'string', sortable: false, hideable: false,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.insurance_name ? params?.row?.insurance_name : '-'}</Box>)
            },
            {
                field: "action", flex: 0.15, headerName: t("Action"), headerAlign: 'center', align: 'center', sortable: false, hideable: false,
                renderCell: (params) => <div>
                    <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Insurance Configuration", "Insurance Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
                    <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Insurance Configuration", "Insurance Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
                </div>
            }
        ]
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_left' >
                    <DataGrid
                        rows={this.state.insuranceData}
                        columns={columns}
                        getRowId={(row) => row['id']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    clinic_name: false
                                }
                            }
                        }}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Paper>
            </Stack>
        )
    }

    renderInsuranceRight = () => {
        const { t } = this.props
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_right' elevation={1}>
                    <Typography className='eMed_refdoc_title'><strong>{t("Insurance Configuration")}</strong></Typography>
                    <Box component={'div'} height={"57.8vh"}>
                        <Grid container className='eMed_refdoc_txt_card'>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo-ins"
                                options={this.state.insuranceMasterData}
                                getOptionLabel={(options) => options.insurance_name}
                                onChange={(e, value) => {
                                    this.setState({
                                        insuranceName: value,
                                    })
                                }}
                                value={this.state.insuranceName}
                                sx={{ width: '23vw' }}
                                renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
                            />
                        </Grid>
                    </Box>
                    <Stack spacing={2} direction="row" className='eMed_ref_btn' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                        <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.onClearHandler() }}>{t("Clear")}</Button>
                        <Button variant='contained' size="small" id="eMed_Config_btn"
                            disabled={!CheckAccessFunc("front_office", "Settings", "Insurance Configuration", "Insurance Configuration", "SubTab")?.editAccess}
                            onClick={() => { this.postInsuranceData() }}>{this.state.isEdit ? t("Update") : t("Add")}</Button>
                    </Stack>
                </Paper>
            </Stack>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    renderMessageFields = () => {
        const { t } = this.props
        return (
            <>
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")}  ${this.state.rowValue?.insurance_name ? this.state.rowValue?.insurance_name : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteReferrralDoctorData.bind(this)}
                    DeleteNotify={t("effectEntireConfig")}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </>
        )
    }

    render() {
        console.log(this.state.insuranceName, 'asdf');
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_refdoc_container'>
                <Grid container spacing={2} className='eMed_refdoc_content' >
                    <Grid item xs={8}>
                        {this.renderInsuranceLeft()}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderInsuranceRight()}
                    </Grid>
                </Grid>
                {this.renderMessageFields()}
            </Box>
        )
    }
}

export default withTranslation()(InsuranceConfig)

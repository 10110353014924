import React, { Component } from 'react';
import './reports.css';
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { DateTime } from 'luxon';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { t } from "i18next";
import { Trans, withTranslation } from 'react-i18next';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from '../../../Components/Common Components/CommonComponents';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { AmountFormat, timeOnlyConvert } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from '../../../Utility/Constants';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';



class BillReport extends Component {
  state = {
    billData: [],
    pageSize: 10,
    isErrorMsg: false,
    isErrorMsgText: '',
    refReportList: [],
    totalBillAmt: 0,
    QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    dateFilter: 1,
    page: 0,
    total: 0,
    searchkey: '',
    labType: "",
    bill_summary_id_Print: "",
    disableBtn: false,

    // Filter State
    FilterOpen: false,
    fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    startTime: null,
    endTime: null,
    patientName: '',
    accountNumber: '',
    mobileNo: '',
    billFrom: '',
    billTo: '',
    selectedInPatient: true,
    selectedOutPatient: true,
    selectedLab: true,
    completed: true,
    credit: true,
    return: true,
    cancelled: false,
    onError: false,
    timeError: false,
    dateIndex: 1,
    moduleType: ['op', 'ip', 'lab'],
    billType: ['regular', 'credit', 'cancelled'],
    totalPendingAmt: 0,
    totalPaidAmt: 0
  }

  componentDidMount() {
    this.getBillReport()
    if (localGetItem("isLoggedIn")) {
      let ProfileName = localGetItem("loggedInUserInfo")
      let myUser = JSON.parse(ProfileName)
      this.setState({
        labType: myUser.laboratory_type
      })
    }
  }

  // componentWillReceiveProps(props) {
  //   if (props.search_key != this.state.searchkey) {
  //     this.setState({
  //       searchkey: props.search_key ? props.search_key : ''
  //     }, () => {
  //       this.getBillReport()
  //     })
  //   }
  // }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      isSuccessMsg: false,
      isSuccessMsgText: "",
    })
  }
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  getBillReport = () => {
    try {
      this.LoaderFunction(true)
      let states = this.state
      let start_time = this.state.startTime ? new Date(this.state.startTime) : ''
      let end_time = this.state.endTime ? new Date(this.state.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
      let billType = []; let moduleType = [];
      if (states.selectedInPatient) {
        moduleType?.push('ip')
      }
      if (states.selectedOutPatient) {
        moduleType?.push('op')
      }
      if (states.selectedLab) {
        moduleType?.push('lab')
      }
      if (states.completed) {
        billType?.push('regular')
      }
      if (states.credit) {
        billType?.push('credit')
      }
      if (states.cancelled) {
        billType?.push('cancelled')
      }

      RestAPIService.getAll(Serviceurls.LAB_BILL_REPORT +
        `?page_number=${this.state.page + 1}&page_size=${this.state.pageSize}` +
        "&from_date=" + this.state.fromDate +
        "&to_date=" + this.state.toDate +
        "&search_key=" + this.state.searchkey +
        "&moduel_type=" + moduleType +
        '&start_time=' + setStartTime +
        '&end_time=' + setEndTime +
        '&bill_type=' + billType +
        '&bill_no_from=' + this.state.billFrom +
        '&bill_no_to=' + this.state.billTo +
        "&patient_name=" + this.state.patientName +
        "&account_number=" + this.state.accountNumber +
        "&mobile_number=" + this.state.mobileNo
      )
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              refReportList: response.data.data.result,
              totalBillAmt: response.data.data.total_bill_amount,
              paidPayment: response.data.data.total_paid_bill,
              pendingPayent: response.data.data.total_pending_bill,
              newPageCount: response.data.data.no_of_pages,
              total: response.data.data.total_count,
              cancel_amt: response.data.data.total_cancel_amount,
              totalBillCount: response.data.data.total_bill_count,
              cancel_count: response.data.data.cancel_bill_count,
              totalPendingAmt: response.data.data.total_pending_amount,
              totalPaidAmt: response.data.data.total_paid_amount
            }, () => {
              this.LoaderFunction(false)
            })
          }
        }).catch(e => {
          this.LoaderFunction(false)
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message
          })
        })

    } catch (e) {
      this.LoaderFunction(false)
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  // CustomDates = (FromDate, ToDate) => {
  //   this.setState({
  //     QueryFromDate: FromDate,
  //     QueryToDate: ToDate,
  //     datePickerPop: false
  //   }, () => this.getBillReport())
  // }
  // PopClose = () => {
  //   this.setState({
  //     datePickerPop: false,
  //     QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
  //     QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
  //     dateFilter: 1,
  //   }, () => this.getBillReport())
  // }

  // DateHelperText = () => {
  //   if (this.state.dateFilter === 0) {
  //     return ""
  //   }
  //   else if (this.state.dateFilter === 1) {
  //     return DateTime.now().toFormat('dd-MM-yyyy')
  //   }
  //   else if (this.state.dateFilter === 2) {
  //     return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
  //   }
  //   else if (this.state.dateFilter === 3) {
  //     // return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
  //     const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
  //     const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
  //     return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`

  //   }
  //   else if (this.state.dateFilter === 4) {
  //     // return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
  //     const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
  //     const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
  //     return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`
  //   }
  //   else if (this.state.dateFilter === 5) {
  //     return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
  //   }
  // }

  // handleDate = () => {
  //   var today = DateTime.now().toFormat('yyyy-MM-dd')
  //   var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
  //   var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
  //   var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
  //   var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
  //   var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
  //   const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
  //   const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');


  //   if (this.state.dateFilter === 0) {
  //     this.setState({
  //       QueryFromDate: "",
  //       QueryToDate: "",
  //     }, () => this.getBillReport())
  //   }
  //   else if (this.state.dateFilter === 1) {
  //     this.setState({
  //       QueryFromDate: today,
  //       QueryToDate: today,
  //     }, () => this.getBillReport()
  //     )

  //   }
  //   else if (this.state.dateFilter === 2) {
  //     this.setState({
  //       QueryFromDate: yesterday,
  //       QueryToDate: yesterday,
  //     }, () => this.getBillReport())
  //   }
  //   else if (this.state.dateFilter === 3) {
  //     this.setState({
  //       QueryFromDate: startOfPreviousWeek,
  //       QueryToDate: endOfPreviousWeek,
  //     }, () => this.getBillReport())
  //   }
  //   else if (this.state.dateFilter === 4) {
  //     this.setState({
  //       QueryFromDate: startOfPreviousMonth,
  //       QueryToDate: endOfPreviousMonth,
  //     }, () => this.getBillReport())
  //   }
  //   else if (this.state.dateFilter === 5) {
  //     this.setState({ datePickerPop: true })
  //   }
  // }
  formatDate(input) {
    let datePart = input.match(/\d+/g),
      year = datePart[0].substring(0),
      month = datePart[1], day = datePart[2];

    return day + '-' + month + '-' + year;
  }

  getBillReportPrint = () => {
    try {
      let states = this.state
      let start_time = this.state.startTime ? new Date(this.state.startTime) : ''
      let end_time = this.state.endTime ? new Date(this.state.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
      let billType = []; let moduleType = [];
      if (states.selectedInPatient) {
        moduleType?.push('ip')
      }
      if (states.selectedOutPatient) {
        moduleType?.push('op')
      }
      if (states.selectedLab) {
        moduleType?.push('lab')
      }
      if (states.completed) {
        billType?.push('regular')
      }
      if (states.credit) {
        billType?.push('credit')
      }
      if (states.cancelled) {
        billType?.push('cancelled')
      }
      this.setState({ disableBtn: true })
      RestAPIService.getAll(
        Serviceurls.LAB_BILL_REPORT_PRINT + "?export_type=pdf" +
        `&page_number=${this.state.page + 1}&page_size=${this.state.pageSize}` +
        "&from_date=" + this.state.fromDate +
        "&to_date=" + this.state.toDate +
        "&search_key=" + this.state.searchkey +
        "&moduel_type=" + moduleType +
        '&start_time=' + setStartTime +
        '&end_time=' + setEndTime +
        '&bill_type=' + billType +
        '&bill_no_from=' + this.state.billFrom +
        '&bill_no_to=' + this.state.billTo +
        "&search_key=" + this.state.searchkey +
        "&patient_name=" + this.state.patientName +
        "&account_number=" + this.state.accountNumber +
        "&mobile_number=" + this.state.mobileNo
      ).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ disableBtn: false })
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      })
    }
  }

  getBillReportexcel = () => {
    try {
      let states = this.state
      let start_time = this.state.startTime ? new Date(this.state.startTime) : ''
      let end_time = this.state.endTime ? new Date(this.state.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
      let billType = []; let moduleType = [];
      if (states.selectedInPatient) {
        moduleType?.push('ip')
      }
      if (states.selectedOutPatient) {
        moduleType?.push('op')
      }
      if (states.selectedLab) {
        moduleType?.push('lab')
      }
      if (states.completed) {
        billType?.push('regular')
      }
      if (states.credit) {
        billType?.push('credit')
      }
      if (states.cancelled) {
        billType?.push('cancelled')
      }
      this.setState({ disableBtn: true })
      RestAPIService.excelGet(
        Serviceurls.LAB_BILL_REPORT_PRINT +
        "?export_type=excel" +
        "&from_date=" + this.state.fromDate +
        "&to_date=" + this.state.toDate +
        "&search_key=" + this.state.searchkey +
        "&moduel_type=" + moduleType +
        '&start_time=' + setStartTime +
        '&end_time=' + setEndTime +
        '&bill_type=' + billType +
        '&bill_no_from=' + this.state.billFrom +
        '&bill_no_to=' + this.state.billTo +
        "&search_key=" + this.state.searchkey +
        "&patient_name=" + this.state.patientName +
        "&account_number=" + this.state.accountNumber +
        "&mobile_number=" + this.state.mobileNo
      ).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      })
    }
  }

  getIndividualBillPrint = () => {
    const { t } = this.props
    try {
      let url;
      // if (this.state.is_completed) {
      url = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT
      // } else {
      //   url = Serviceurls.LAB_CREATE_BILL_PARTIAL_PRINT
      // }
      RestAPIService.getAll(url + "?bill_summary_id=" + this.state.bill_summary_id_Print + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError")
      })
    }
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    }, () => {
      this.setState({ endTime: null })
    })
  }

  selectedTime = (start, end, error) => {
    this.setState({
      startTime: start,
      endTime: end,
      timeError: error
    })
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      switch (names) {
        case 'patientName':
          if (CommonValidation.alphabetOnly(value) || value === '') {
            this.setState({ patientName: value })
          }
          break;
        case 'accountNumber':
          this.setState({ accountNumber: value })
          break;
        case 'mobileNo':
          if (CommonValidation.numberOnly(value) && value?.length <= 10 || value === '') {
            this.setState({ mobileNo: value })
          }
          break;
        case 'billFrom':
          // if (CommonValidation.numberOnly(value) || value === '') {
            this.setState({ billFrom: value },() =>{
              this.setState({ billTo: '' })
            })
          // }
          break;
        case 'billTo':
          // if (CommonValidation.numberOnly(value) || value === '') {
            this.setState({ billTo: value })
          // }
          break;
        default:
          this.setState({ [names]: value })
          break;
      }
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error.message,
        disableBtn: false,
      })
    }
  }

  onCheckBoxHandler = (e, names) => {
    try {
      let value = e.target.checked

      this.setState({ [names]: value })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error.message,
        disableBtn: false,
      })
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        selectedInPatient: true,
        selectedOutPatient: true,
        selectedLab: true,
        completed: true,
        credit: true,
        cancelled: false,
        billFrom: '',
        billTo: '',
        patientName: '',
        accountNumber: '',
        mobileNo: '',
        FilterOpen: false,
        fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        startTime: null,
        endTime: null,
        onError: false,
        timeError: false,
        dateIndex: 1,
      }, () => this.getBillReport())
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error.message,
        disableBtn: false,
      })
    }
  }

  checkSearch = () => {
    try {
      let message = null
      let states = this.state
      if (states.billFrom !== '' && states.billTo === '') {
        message = 'Enter Bill To Value'
      } else if (+states.billTo < states.billFrom) {
        message = 'Bill To should be greater than bill From'
      } else if (
        (!states.selectedInPatient && !states.selectedOutPatient && !states.selectedLab) ||
        (!states.completed && !states.credit && !states.cancelled)
      ) {
        message = 'Select atleast on CheckBox'
      }
      return message;
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error.message,
        disableBtn: false,
      })
    }
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container_New'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle_New'>
          <Box component={'div'} width={'29vw'} sx={{ mt: '0.5vw', display: 'flex', justifyContent: "flex-end" }}>
            <Box className='' width={'12vw'}>
              <CommonDatePicker
                FromDate={this.state.fromDate}
                ToDate={this.state.toDate}
                DateIndex={this.state.dateIndex}
                SelectedDates={this.FilterDate.bind(this)}
                HideAllMenu={true} />
            </Box>
          </Box>
          <Box sx={{ width: '29vw' }}>
            <CommonTimeRangePicker
              startTime={this.state.startTime}
              endTime={this.state.endTime}
              selectedTime={this.selectedTime.bind(this)}
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              onError={this.state.onError}
            />
          </Box>
          <Box component={'div'} m={'0.5vw'}>
            <Box component={'div'} mb={'1vw'} mt={'1vw'} display={'flex'}>
              <TextField
                size='small'
                sx={{ width: '13.5vw', mr: '0.5vw' }}
                value={this.state.patientName}
                onChange={(e) => this.onChangeHandler(e, 'patientName')}
                label='Patient Name'
                autoComplete='off'
              />
              <TextField
                size='small'
                sx={{ width: '13.5vw', mr: '0.5vw' }}
                value={this.state.accountNumber}
                onChange={(e) => this.onChangeHandler(e, 'accountNumber')}
                label='UHID'
                autoComplete='off'
              />
            </Box>
            <Box component={'div'}>
              <TextField
                size='small'
                sx={{ width: '27.5vw' }}
                value={this.state.mobileNo}
                onChange={(e) => this.onChangeHandler(e, 'mobileNo')}
                label='Mobile Number'
                autoComplete='off'
              />
            </Box>
            <Box component={'div'} display={'flex'} mb={'1vw'} mt={'1vw'} >
              <TextField
                size='small'
                sx={{ width: '13.5vw', mr: '0.5vw' }}
                value={this.state.billFrom}
                onChange={(e) => this.onChangeHandler(e, 'billFrom')}
                label='Bill From'
                autoComplete='off'
              />
              <TextField
                size='small'
                disabled={this.state.billFrom !== '' ? false : true}
                sx={{ width: '13.5vw', mr: '0.5vw' }}
                value={this.state.billTo}
                onChange={(e) => this.onChangeHandler(e, 'billTo')}
                label='Bill To'
                autoComplete='off'
              />
            </Box>
            <Box component={'div'}>
              <Typography fontWeight={600}>Module Type</Typography>
              <Box component={'div'} display={'flex'}>
                <FormControlLabel checked={this.state.selectedOutPatient} control={<Checkbox />} onClick={(e) => this.onCheckBoxHandler(e, 'selectedOutPatient')} label="Out Patient" />
                <FormControlLabel checked={this.state.selectedInPatient} control={<Checkbox />} onClick={(e) => this.onCheckBoxHandler(e, 'selectedInPatient')} label="Inpatient" />
                <FormControlLabel checked={this.state.selectedLab} control={<Checkbox />} onClick={(e) => this.onCheckBoxHandler(e, 'selectedLab')} label="Laboratory" />
              </Box>
            </Box>
            <Box component={'div'}>
              <Typography fontWeight={600}>Bill Type</Typography>
              <Box component={'div'} display={'flex'}>
                <FormControlLabel checked={this.state.completed} control={<Checkbox />} onClick={(e) => this.onCheckBoxHandler(e, 'completed')} label="Completed" />
                <FormControlLabel checked={this.state.credit} control={<Checkbox />} onClick={(e) => this.onCheckBoxHandler(e, 'credit')} label="Credit" />
                <FormControlLabel checked={this.state.cancelled} control={<Checkbox />} onClick={(e) => this.onCheckBoxHandler(e, 'cancelled')} label="Cancelled" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component={'div'} display={'flex'} justifyContent={'center'}>
          <Button variant='outlined'
            sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
            onClick={() => this.onClearHandler()}
          >Clear</Button>
          <Button variant='contained'
            sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
            onClick={() => {
              let checkValidation = this.checkSearch()
              if (checkValidation === null) {
                this.setState({ FilterOpen: false }, () => this.getBillReport())
              } else {
                this.setState({
                  isErrorMsg: true,
                  isErrorMsgText: checkValidation,
                  disableBtn: false,
                })
              }
            }}
          >Search</Button>
        </Box>
      </Box>
    )
  }

  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.startTime ? new Date(this.state.startTime) : ''
    let end_time = this.state.endTime ? new Date(this.state.endTime) : ''
    let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let TextArray = [
        {label:"From Date", value: this.formatDate(this.state.fromDate)},
        {label:"To Date", value:this.formatDate(this.state.toDate)},
    ]
    if (this.state.startTime !== null && this.state.endTime !== null) {
        TextArray.push(
            { label: "Start Time", value: timeOnlyConvert(setStartTime) },
            { label: "End Time", value: timeOnlyConvert(setEndTime) }
        )
    }
    return(
      <Box>
         <CommonGridToolBarWithFilterText FilterTextArray={TextArray}/>
      </Box>

    )
  }

  render() {
    const { t } = this.props
    this.state.refReportList.forEach((element, index) => {
      element.sno = index + 1
    })
    const columns = [
      {
        field: 'sno', headerName: t("SNo"), sortable: false, flex: 0.05,
      },
      {
        field: 'invoice_number', headerName: t('BillNo'), sortable: false, flex: 0.13,
        renderCell: (params) => {
          let BillNumber = params?.row?.invoice_number ? params?.row?.invoice_number : params?.row?.temp_bill_number ? params?.row?.temp_bill_number : "";
          const billArr = BillNumber ? BillNumber?.split("/") : [];
          return (
            <Tooltip placement='top' title={BillNumber} arrow>
              <div>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</div>
            </Tooltip>
          )
        }
      },
      {
        field: 'invoice_date', headerName: t('Date'), sortable: false, flex: 0.145, headerAlign: "center", align: "center",
        renderCell: (params) => (<div>{params.row.invoice_date ? params.row.invoice_date : "-"}</div>)
      },
      {
        field: 'patient_name', headerName: t('PatientName'), sortable: false, flex: 0.125,
        renderCell: (parmas => (<div>{(parmas?.row?.patient_name + parmas?.row?.surname)?.length > 15 ? <Tooltip title={parmas.row.patient_name + parmas?.row?.surname} arrow><div>{(parmas?.row?.patient_name + parmas?.row?.surname).slice(0, 15) + "..."}</div></Tooltip> : parmas?.row?.patient_name ? parmas?.row?.patient_name + " " + parmas?.row?.surname : "-"}</div>))
      },
      {
        field: 'patient_account_number', headerName: t("UHID"), sortable: false, flex: 0.12, headerAlign: "center", align: "center",
        renderCell: (params) => (<div>{params?.row?.patient_account_number ? params?.row?.patient_account_number : "-"}</div>)
      },
      {
        field: 'total_amount', headerName: t('TotalAmount') + `(${CurrencySymbol})`, sortable: false, flex: 0.125, type: "number",
        renderCell: (params) => (<div>{params.row.total_amount ? AmountFormat(params.row.total_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</div>)
      },
      {
        field: 'bill_type', headerName: t('BillType'), sortable: false, flex: 0.12, headerAlign: "center", align: "center",
        renderCell: (params) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.9vw"} fontWeight={600}>{params?.row?.bill_type ? params?.row?.bill_type : "-"}</Typography>
            <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8vw"} >{(params?.row?.bill_type === "Bill" || params?.row?.bill_type === "Regular") ? "Paid" : (params?.row?.payment_status ? params?.row?.payment_status : "-")}</Typography>
          </Box>)
      },
      {
        field: "paid_in", flex: 0.1, headerName: t("Paid In"), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let data = params?.row?.paid_in ? params?.row?.paid_in.split(",") : []
          let isFo = data.includes("FRONT OFFICE")
          let ispharma = data.includes("PHARMACY")
          let isLab = data.includes("LABORATORY")
          if (isFo || ispharma || isLab) {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography display={isFo ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Front Office</Typography>
                <Typography display={ispharma ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Pharmacy</Typography>
                <Typography display={isLab ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Lab</Typography>
              </Box>
            )
          } else {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>-</Typography>
              </Box>
            )
          }
        }
      },
      {
        field: 'report_status', headerName: t('rtsStatus'), sortable: false, flex: 0.150,
        renderCell: (params) => (<div>{params.row.report_status ? params.row.report_status : "-"}</div>)
      },
      {
        field: 'action', headerName: t('Action'), sortable: false, flex: 0.08, headerAlign: "center", align: "center",
        renderCell: (params) => (<div>
          {params.row.bill_type === "saved" ?
            <IconButton className="eMed_bill_print" disabled={params.row.bill_type === "saved" ? true : false} onClick={() => { this.setState({ bill_summary_id_Print: params?.row?.bill_summary_id }, () => this.getIndividualBillPrint()) }}>
              <img src={ImagePaths.LabPrintIcon.default} alt='print' />
            </IconButton> :
            <IconButton className="eMed_bill_print" disabled={params.row.bill_type === "saved" ? true : false} onClick={() => { this.setState({ bill_summary_id_Print: params?.row?.bill_summary_id }, () => this.getIndividualBillPrint()) }}>
              <img src={ImagePaths.PrintIcons.default} alt='print' />
            </IconButton>
          }
        </div>)
      }

    ]
    return (
      <div className='reports_container'>
        <div className='reports_header_card'>
          <div className='reports_bill_left'>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("Total Bill Count"), this.state.totalBillCount, true)}
              </div>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("TotalBillAmount"), this.state.totalBillAmt)}
              </div>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("Total Pending Amount"), this.state.totalPendingAmt)}
              </div>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("Total Paid Amount"), this.state.totalPaidAmt)}
              </div>

            </div>
            {/* flow changes in bill Report so commented   */}
            {/* <div className='reports_bill_flex_two'>
              <div className='reports_bill_box_one bill_background_pending'>
                <label className='reports_font_size'>{this.state.pendingPayent}</label>
                <label>{t("pendingPayment")}</label>
              </div>
            </div>
            <div className='reports_bill_flex_three'>
              <div className='reports_bill_box_one bill_background_paid'>
                <label className='reports_font_size'>{this.state.paidPayment}</label>
                <label>{t("paidPayment")}</label>
              </div>
            </div> */}
            {this.state.labType === "Inhouse Laboratory" ?
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className='reports_bill_box_one'>
                  {AmountsCard(t("Cancelled Bill Count"), this.state.cancel_count, true)}
                </div>
                <div className='reports_bill_box_one'>
                  {AmountsCard(t("Cancelled Bill Amount"), this.state.cancel_amt)}
                </div>
              </div> : null
            }
          </div>
          <div className='reports_right'>
            {/* 
                      <div className='report_date_picker'>
              <div className='eMed_rts_date_pick'>
                <FormControl >
                  <InputLabel>{t("SelectDate")}</InputLabel>
                  <Select sx={{ height: '2.2vw', cursor: "pointer", marginRight: "1vw", width: "10vw" }}
                    size='small'
                    onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                    value={this.state.dateFilter}
                    labelId="demo-simple-select-required-label"
                    label={t("SelectDate")}
                  >
                    {/* <MenuItem value={0}>{t("All")}</MenuItem> 
                    <MenuItem value={1}>{t("Today")}</MenuItem>
                    <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                    <MenuItem value={3}>{t("Lastweek")}</MenuItem>
                    <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                    <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
                  </Select>
                  <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                </FormControl>
              </div>
              {/* {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null} 
            </div>
          */}
            <img className='reports_print' src={ImagePaths.Filter.default} onClick={() => { this.setState({ FilterOpen: true }) }} alt='Filter' />
            <img className='eMed_rts_upload' src={ImagePaths.LabPrintIcon.default} onClick={() => {
              if (!this.state.disableBtn) {
                this.getBillReportPrint()
              }
            }} alt='print' />
            <img className='eMed_rts_upload' src={ImagePaths.LabUploadButton.default} onClick={() => {
              if (!this.state.disableBtn) {
                this.getBillReportexcel()
              }
            }} alt='upload' />

          </div>
        </div>

        <div className='reports_table_card'>
          <DataGrid
            rows={this.state.refReportList}
            rowCount={this.state.total}
            page={this.state.page}
            columns={columns}
            hideFooterSelectedRowCount
            getRowId={(row) => row.bill_summary_id}
            pageSize={this.state.pageSize}
            onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getBillReport()) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getBillReport())}
            rowsPerPageOptions={[10, 20, 30]}
            loading={this.state.isLoader}
            pagination
            paginationMode='server'
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              // Toolbar: CommonGridHeader.CommonToolBar,
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            headerHeight={40}
          />
        </div>
        <Drawer
          open={this.state.FilterOpen}
          anchor={'right'}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : this.state.isSuccessMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.isSuccessMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
      </div>
    )
  }

}
export default withTranslation()(BillReport);

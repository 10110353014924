import { Box, Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, colors } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../Components/Loader';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'


const Unit_List = [
  { label: 'Mg', value: 'mg' },
  { label: 'Volt', value: 'volt' }
]
export default class DocBiothesometry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      biothesometryData: {},
      unitList: Unit_List,
      loading: false,
      postLoading: false,
      ankle_left: '',
      ankle_normal_value: 20,
      ankle_right: '',
      impression: '',
      toe_left: '',
      toe_normal_value: 20,
      toe_right: '',
      unit: 'mg',
      wrist_left: '',
      wrist_normal_value: 20,
      wrist_right: '',
      selectedId: null
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getBiothesometryData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getBiothesometryData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_BIOTHESOMETRY + '?appointment_id=' + this.state.appointmentId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if (response?.data?.data?.length > 0) {
              this.setState({
                biothesometryData: response?.data?.data[0],
                loading: false,
                postLoading: true
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postBiothesometryData = () => {
    try {
      let states = this.state
      let data = {
        ankle_left: states.ankle_left,
        ankle_normal_value: states.ankle_normal_value,
        ankle_right: states.ankle_right,
        appointment_id: states.appointmentId,
        impression: states.impression,
        toe_left: states.toe_left,
        toe_normal_value: states.toe_normal_value,
        toe_right: states.toe_right,
        unit: states.unit,
        wrist_left: states.wrist_left,
        wrist_normal_value: states.wrist_normal_value,
        wrist_right: states.wrist_right,
        id: states.selectedId ? states.selectedId : null
      }
      let check = this.checkValidation()
      if (check === null) {
        RestAPIService.create(data, Serviceurls.DOC_BIOTHESOMETRY)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.onClearHandler()
              this.getBiothesometryData()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(check)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  printBiothesometryData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_BIOTHESOMETRY + `?appointment_id=${this.state.appointmentId}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onClearHandler = () => {
    try {
      this.setState({
        ankle_left: '',
        ankle_normal_value: 20,
        ankle_right: '',
        impression: '',
        toe_left: '',
        toe_normal_value: 20,
        toe_right: '',
        unit: 'mg',
        wrist_left: '',
        wrist_normal_value: 20,
        wrist_right: '',
        selectedId: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  calculateValuesforIndex = () => {
    try {
      var states = this.state;
      var rightImpression = "";
      var leftImpression = "";

      //Normal Value Section
      // Right Normal
      if (((+(states.ankle_right)) + (+(states.toe_right))) / 2 < 20) {
        rightImpression = "Normal";
      }
      // Left Normal
      if ((+(states.ankle_left) + (+(states.toe_left))) / 2 < 20) {
        leftImpression = "Normal";
      }
      // Both Normal
      else if (((+(states.ankle_right)) + +(states.ankle_left)) / 2 < 20 && ((+(states.toe_right)) + (+(states.toe_left))) / 2 < 20) {
        rightImpression = "Normal";
        leftImpression = "Normal";
      }
      // value 20 - 25
      // Right side
      if (
        (((+(states.ankle_right)) + (+(states.toe_right))) / 2 >= 20 && ((+(states.ankle_right)) + (+(states.toe_right))) / 2 <= 25)
      ) {
        rightImpression = "Right Early sensory neuropathy"
      }
      // Left Side
      if (
        ((+(states.ankle_left) + (+(states.toe_left))) / 2 >= 20 && (+(states.ankle_left) + (+(states.toe_left))) / 2 <= 25)
      ) {
        leftImpression = "Left Early sensory neuropathy";
      }
      // Both
      if (
        ((+(states.ankle_right)) + (+(states.toe_right))) / 2 >= 20 && ((+(states.ankle_right)) + (+(states.toe_right))) / 2 <= 25 &&
        (+(states.ankle_left) + (+(states.toe_left))) / 2 >= 20 && (+(states.ankle_left) + (+(states.toe_left))) / 2 <= 25
      ) {
        rightImpression = "Bilateral Early sensory neuropathy";
        leftImpression = "Bilateral Early sensory neuropathy";
      }
      // 26 to 40
      // Right
      if (((+(states.ankle_right)) + (+(states.toe_right))) / 2 > 25 && ((+(states.ankle_right)) + (+(states.toe_right))) / 2 <= 40
      ) {
        rightImpression = "Right sensory neuropathy";
      }
      // Left
      if ((+(states.ankle_left) + (+(states.toe_left))) / 2 > 25 && (+(states.ankle_left) + (+(states.toe_left))) / 2 <= 40) {
        leftImpression = "Left sensory neuropathy";
      }
      if (
        (((+(states.ankle_right)) + (+(states.toe_right))) / 2 > 25 && ((+(states.ankle_right)) + (+(states.toe_right))) / 2 <= 40) &&
        ((+(states.ankle_left) + (+(states.toe_left))) / 2 > 25 && (+(states.ankle_left) + (+(states.toe_left))) / 2 <= 40)
      ) {
        rightImpression = "Bilateral sensory neuropathy";
        leftImpression = "Bilateral sensory neuropathy";
      }
      // Above 40 
      if (
        (+(states.ankle_left) + (+(states.toe_left))) / 2 > 40
      ) {
        leftImpression = "Left Severe sensory neuropathy";
      } if (
        ((+(states.ankle_right)) + (+(states.toe_right))) / 2 > 40
      ) {
        rightImpression = "Right Severe sensory neuropathy";
      } if ((+(states.ankle_left) + (+(states.toe_left))) / 2 > 40 &&
        ((+(states.ankle_right)) + (+(states.toe_right))) / 2 > 40) {
        rightImpression = "Bilateral Severe sensory neuropathy";
        leftImpression = "Bilateral Severe sensory neuropathy";
      }
      if (rightImpression == "Bilateral Early sensory neuropathy") {
        this.setState({
          comments: "Bilateral Early sensory neuropathy"
        })
      } else if (rightImpression == "Bilateral sensory neuropathy") {
        this.setState({
          impression: "Bilateral sensory neuropathy"
        })
      } else if (rightImpression == "Bilateral Severe sensory neuropathy") {
        this.setState({
          impression: "Bilateral Severe sensory neuropathy"
        })
      } else {
        this.setState({
          impression: "Right Impression : " + rightImpression + "\n" + "Left Impression : " + leftImpression
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      if ((CommonValidation.DecimalNumber(value) && value <= 100) || value === '') {
        this.setState({
          [names]: value
        }, () => {
          this.calculateValuesforIndex()
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, label, width, mr, comments, disable, multiline, rows) => {
    try {
      return (
        <TextField
          size='small'
          disabled={disable}
          sx={{ width: width, mr: mr, mt: '0.5vw' }}
          multiline={multiline}
          rows={rows}
          label={label}
          name={names}
          value={value}
          onChange={(e) => this.onChangeHandler(e, names)}
          InputProps={comments ? {
            endAdornment: <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
              {this.state.unit === 'mg' ? 'Mg' : 'Volt'}</Typography>
          } : null}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      let states = this.state
      if ((states.ankle_left === '') || (states.ankle_right === '') ||
        (states.toe_left === '') || (states.toe_right === '') ||
        (states.unit === '') || (states.wrist_left === '') || (states.wrist_right === '')) {
        message = 'Enter All Mandatory Fields'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = () => {
    try {
      let { biothesometryData } = this.state
      this.setState({
        ankle_left: biothesometryData?.ankle_left,
        ankle_normal_value: 20,
        ankle_right: biothesometryData?.ankle_right,
        impression: biothesometryData?.impression,
        toe_left: biothesometryData?.toe_left,
        toe_normal_value: 20,
        toe_right: biothesometryData?.toe_right,
        unit: 'mg',
        wrist_left: biothesometryData?.wrist_left,
        wrist_normal_value: 20,
        wrist_right: biothesometryData?.wrist_right,
        selectedId: biothesometryData?.id
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      let { biothesometryData } = this.state
      console.log(biothesometryData);
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Diabetic Foot Biothesiometry Logs</Typography>
            <Box>
              {this.state.postLoading ?
                <Button className='Common_Btn_Min_Width'
                  onClick={() => {
                    this.setState({
                      isEdit: true,
                      postLoading: false
                    }, () => this.onEditHandler())
                  }}
                >
                  <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
                </Button> : null}
              <Button className='Common_Btn_Min_Width'
                disabled={Object.keys(this.state.biothesometryData)?.length > 0 ? false : true}
                onClick={() => this.printBiothesometryData()}
              >
                <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
              </Button>
            </Box>
          </Box>
          <Box component={'div'}>
            <Box border={'1px solid lightgray'} height={'13vw'} sx={{ backgroundColor: Colors.white }} p={'0.5vw'}>
              <Box border={'1px solid lightgray'}>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-around'} p={'1vw'} alignItems={'center'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} width={'25%'}>Site</Typography>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} width={'25%'}>Right</Typography>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} width={'25%'}>Left</Typography>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} width={'25%'}>Normal Values</Typography>
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'space-around'} p={'1vw'}>
                  <Box width={'25%'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>Wrist</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>Ankle (Medical Mallaous)</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>Great Toe</Typography>
                  </Box>
                  <Box width={'25%'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.wrist_right ? biothesometryData?.wrist_right : 0}</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.ankle_right ? biothesometryData?.ankle_right : 0}</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.toe_right ? biothesometryData?.toe_right : 0}</Typography>
                  </Box>
                  <Box width={'25%'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.wrist_left ? biothesometryData?.wrist_left : 0}</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.ankle_left ? biothesometryData?.ankle_left : 0}</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.toe_left ? biothesometryData?.toe_left : 0}</Typography>
                  </Box>
                  <Box width={'25%'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.wrist_normal_value ? biothesometryData?.wrist_normal_value : 20}</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.ankle_normal_value ? biothesometryData?.ankle_normal_value : 20}</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.toe_normal_value ? biothesometryData?.toe_normal_value : 20}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box border={'1px solid lightgray'} height={'10vw'} sx={{ backgroundColor: Colors.white }}>
              <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} p={'1vw'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Impression</Typography>
              </Box>
              <Box p={'1vw'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.impression ? biothesometryData?.impression?.split('\n')[0] : null}</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>{biothesometryData?.impression ? biothesometryData?.impression?.split('\n')[1] : null}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Diabetic Foot Biothesiometry Logs</Typography>
          </Box>
          <Box component={'div'} height={'23vw'} p={'0.5vw'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'}>
            <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mt={'0.5vw'}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Unit</Typography>
              <FormControl size='small' sx={{ Width: '10vw', ml: '1vw' }}>
                <InputLabel>Select Unit *</InputLabel>
                <Select
                  label={'Select Unit *'}
                  sx={{ width: '10vw' }}
                  value={this.state.unit}
                  onChange={(e) => this.setState({ unit: e.target.value })}
                >
                  {this.state.unitList?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Hand</Typography>
              </Box>
              <Box component={'div'}>
                {this.renderTextBox('wrist_normal_value', this.state.wrist_normal_value, 'Wrist *', '31vw', null, true, true)}
                {this.renderTextBox('ankle_normal_value', this.state.ankle_normal_value, 'Ankle(Medical Malleous) *', '31vw', null, true, true)}
                {this.renderTextBox('toe_normal_value', this.state.toe_normal_value, 'Great Toe *', '31vw', null, true, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Wrist</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('wrist_right', this.state.wrist_right, 'wrist Right *', '15vw', '1vw', true)}
                {this.renderTextBox('wrist_left', this.state.wrist_left, 'wrist Left *', '15vw', null, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Ankle (Medial Malleous)</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('ankle_right', this.state.ankle_right, 'Ankle Right *', '15vw', '1vw', true)}
                {this.renderTextBox('ankle_left', this.state.ankle_left, 'Ankle Left *', '15vw', null, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Great Toe</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('toe_right', this.state.toe_right, 'Great Toe Right *', '15vw', '1vw', true)}
                {this.renderTextBox('toe_left', this.state.toe_left, 'Great Toe Left *', '15vw', null, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Comments</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('impression', this.state.impression, 'Comments *', '31vw', null, false, true, true, 3)}
              </Box>
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.onClearHandler()}
            >Clear</Button>
            <Button
              disabled={(this.state.postLoading)}
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              onClick={() => this.postBiothesometryData()}
            >Add</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          <Box component={'div'} flex={0.65} overflow={'scroll'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Select, TextField, Typography, FormControl,
    InputLabel, FormControlLabel, Checkbox, Autocomplete, RadioGroup, Radio, Switch
} from '@mui/material';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { CommonTimeRangePicker } from '../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../Utility/Constants';

class BillingsFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            ref: props.location?.pathname,
            FilterOpen: false,
            DortorList: [],
            FilterDatas:
            {
                "FromDate": this.props.FilterDatas.FromDate,
                "ToDate": this.props.FilterDatas.ToDate,
                "DateIndex": this.props.FilterDatas.DateIndex,
                "PatientName": this.props.FilterDatas.PatientName,
                "AccNo": this.props.FilterDatas.AccNo,
                "MobNo": this.props.FilterDatas.MobNo,
                "DoctorName": this.props.FilterDatas.DoctorName,
                "BillNumber": this.props.FilterDatas.BillNumber,
                "uhidNo": this.props.FilterDatas.uhidNo,
                "PaidAmount": this.props.FilterDatas.PaidAmount,
                "InPatientSelected": this.props.FilterDatas.InPatientSelected,
                "OutPatientSelected": this.props.FilterDatas.OutPatientSelected,
                "PharmacySelected": this.props.FilterDatas.PharmacySelected,
                "LaboratorySelected": this.props.FilterDatas.LaboratorySelected,
                "ImageLabSelected": this.props.FilterDatas.ImageLabSelected,
                "OTSelected": this.props.FilterDatas.OTSelected,
                "CardSelected": this.props.FilterDatas.CardSelected,
                "CashSelected": this.props.FilterDatas.CashSelected,
                "UPISelected": this.props.FilterDatas.UPISelected,
                "BankTransferSelected": this.props.FilterDatas.BankTransferSelected,
                "InsuranceSelected": this.props.FilterDatas.InsuranceSelected,
                "ChequeSelected": this.props.FilterDatas.ChequeSelected,
                "BillType": this.props.FilterDatas.BillType,
                "PayStatus": this.props.FilterDatas.PayStatus,
                "PayMethods": this.props.FilterDatas.PayMethods,
                "CompletedSelected": this.props.FilterDatas.CompletedSelected,
                "CreditSelected": this.props.FilterDatas.CreditSelected,
                "ReturnSelected": this.props.FilterDatas.ReturnSelected,
                "CancelledSelected": this.props.FilterDatas.CancelledSelected,
                "combined":this.props.FilterDatas.combined,
                "BillStatus":this.props.FilterDatas.BillStatus,
                "BillReceiptSelected": this.props.FilterDatas.BillReceiptSelected,
                "AdvanceTypeSelected": this.props.FilterDatas.AdvanceTypeSelected,
                "AdvanceRefundTypeSelected": this.props.FilterDatas.AdvanceRefundTypeSelected,
                "RefundTypeSelected": this.props.FilterDatas.RefundTypeSelected,
                "CancelledTypeSelected":this.props.FilterDatas.CancelledTypeSelected,
                "RecieptType": this.props.FilterDatas.RecieptType,
                "CreditStatus":this.props.FilterDatas.CreditStatus,
                "Insurance":this.props.FilterDatas.Insurance,
                "CorporateCredit":this.props.FilterDatas.CorporateCredit,
                "PatientCredit":this.props.FilterDatas.PatientCredit,
                "startTime":this.props.FilterDatas.startTime,
                "endTime":this.props.FilterDatas.endTime,
                "UserList" : this.props.FilterDatas.UserList,
                "UserDetails" : this.props.FilterDatas.UserDetails,
                "is_user" : this.props.FilterDatas.is_user,
                'credit':this.props.FilterDatas.credit,
                'completed':this.props.FilterDatas.completed,
                'return':this.props.FilterDatas.return,
                'billType':this.props.FilterDatas.billType,
                "OPBills" : this.props.FilterDatas.OPBills,
                "IPBills" : this.props.FilterDatas.IPBills,
                "LabBills" : this.props.FilterDatas.LabBills,
                "PharmaBills" : this.props.FilterDatas.PharmaBills,
                "new_Receipt_Type" : this.props.FilterDatas.new_Receipt_Type,
                'AgainstCredit':this.props.FilterDatas?.AgainstCredit,
                'includeFObills':this.props.FilterDatas?.includeFObills ? this.props.FilterDatas?.includeFObills : false,
                "BillModuleType" : this.props.FilterDatas.BillModuleType,
                "is_bill_wise" : this.props.FilterDatas?.is_bill_wise ? this.props.FilterDatas?.is_bill_wise : false,
                "opCreditBills" : this.props.FilterDatas?.opCreditBills ? this.props.FilterDatas?.opCreditBills : false,
                "ipCreditBills" : this.props.FilterDatas?.ipCreditBills ? this.props.FilterDatas?.ipCreditBills : false,
                "pharmaCreditBills" : this.props.FilterDatas?.pharmaCreditBills ? this.props.FilterDatas?.pharmaCreditBills : false,
                "labCreditBills" : this.props.FilterDatas?.labCreditBills ? this.props.FilterDatas?.labCreditBills : false,
                "RadiologySelected":this.props.FilterDatas?.RadiologySelected ? this.props.FilterDatas?.RadiologySelected : false,
                "RadiologyBills":this.props.FilterDatas?.RadiologyBills ? this.props.FilterDatas?.RadiologyBills : false,
                "radiologyCreditBills":this.props.FilterDatas?.radiologyCreditBills ? this.props.FilterDatas?.radiologyCreditBills : false,
                "otCreditBills" : this.props.FilterDatas?.otCreditBills ? this.props.FilterDatas?.otCreditBills : false,
                "billTo": this.props.FilterDatas?.billTo,
                "billFrom": this.props.FilterDatas?.billFrom ? this.props.FilterDatas?.billFrom : '',
                "ReceiptFrom" : this.props.FilterDatas?.ReceiptFrom ? this.props.FilterDatas?.ReceiptFrom : '',
                "ReceiptTo" : this.props.FilterDatas?.ReceiptTo,
                "phoneNumber": this.props.FilterDatas?.phoneNumber,
                "patient_Name": this.props.FilterDatas?.patient_Name,
                "UHID_No": this.props.FilterDatas?.UHID_No,
                'returnFrom':this.props.FilterDatas?.returnFrom,
                'returnTo':this.props.FilterDatas?.returnTo,
                'cancelFrom':this.props.FilterDatas?.cancelFrom,
                'cancelTo':this.props.FilterDatas?.cancelTo,
                "refundNoFrom":this.props.FilterDatas?.refundNoFrom,
                "refundNoTo":this.props.FilterDatas?.refundNoTo,
                "IncludePaidBills": this.props.FilterDatas?.IncludePaidBills,
                "Bill_No": this.props.FilterDatas?.Bill_No
            },
            error:false
        }
    }
    componentDidMount() {
        this.getDoctorsList()
    }
    getDoctorsList = () => {
        try {
            if(this.props.labReceipt){

            }else{
                var Url = ""
                if( this.props.Pharmacy ){
                    Url = Serviceurls.PHARMA_DOCTOR_LIST
                }else{
                    Url = Serviceurls.FO_CONFIG_OPDOCTORNAME
                }
                RestAPIService.getAll(Url)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            DortorList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }

                })
            }
        } catch (error) {
            this.errorMessage(error)
        }
    }
    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    FilterDate = (From, To, Index) => {
        this.state.FilterDatas.FromDate = From
        this.state.FilterDatas.ToDate = To
        this.state.FilterDatas.DateIndex = Index
        this.setState({ FilterDatas: this.state.FilterDatas },()=>{
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.state.error = error
        this.setState({ FilterDatas: this.state.FilterDatas })
    }

    handleCheckBox = (e, key) => {
        this.state.FilterDatas[key] = e.target.checked
        if(!this.state.FilterDatas.InPatientSelected){
            this.state.FilterDatas.ipCreditBills = false
        }
        if(!this.state.FilterDatas.OutPatientSelected){
            this.state.FilterDatas.opCreditBills = false
        }
        if(!this.state.FilterDatas.PharmacySelected && (!this.state.FilterDatas.InPatientSelected && !this.state.FilterDatas.OutPatientSelected && !this.state.FilterDatas.RadiologySelected)){
            this.state.FilterDatas.ipCreditBills = false
            this.state.FilterDatas.opCreditBills = false
        }
        if(!this.state.FilterDatas.LaboratorySelected && (!this.state.FilterDatas.InPatientSelected && !this.state.FilterDatas.OutPatientSelected && !this.state.FilterDatas.RadiologySelected)){
            this.state.FilterDatas.ipCreditBills = false
            this.state.FilterDatas.opCreditBills = false
        }
        if(!this.state.FilterDatas.PharmacySelected && !this.state.FilterDatas.InPatientSelected && !this.state.FilterDatas.OutPatientSelected && !this.state.FilterDatas.RadiologySelected){
            this.state.FilterDatas.pharmaCreditBills = false
        }
        if(!this.state.FilterDatas.LaboratorySelected && !this.state.FilterDatas.InPatientSelected && !this.state.FilterDatas.OutPatientSelected && !this.state.FilterDatas.RadiologySelected){
            this.state.FilterDatas.labCreditBills = false
        }
        if(!this.state.FilterDatas.RadiologySelected && !this.state.FilterDatas.InPatientSelected && !this.state.FilterDatas.OutPatientSelected && !this.state.FilterDatas.PharmacySelected){
            this.state.FilterDatas.radiologyCreditBills = false
        }
        if(!this.state.FilterDatas.RadiologySelected && (!this.state.FilterDatas.InPatientSelected && !this.state.FilterDatas.OutPatientSelected && !this.state.FilterDatas.PharmacySelected)){
            this.state.FilterDatas.ipCreditBills = false
            this.state.FilterDatas.opCreditBills = false
        }
        if((this.state.FilterDatas.LaboratorySelected || this.state.FilterDatas.OutPatientSelected || this.state.FilterDatas.PharmacySelected) && (!this.state.FilterDatas.OTSelected && !this.state.FilterDatas.InPatientSelected)){
            this.state.FilterDatas.otCreditBills = false
        }
        if(!this.state.FilterDatas.OTSelected){
            this.state.FilterDatas.otCreditBills = false
        }
        this.setState({ FilterDatas: this.state.FilterDatas }, () => {
            if (!this.state.FilterDatas.BillReceiptSelected) {
                this.state.FilterDatas.AgainstCredit = 'both'
                this.setState({ FilterDatas: this.state.FilterDatas })
            }
            let BillType = [];
            let BillStatus = [];
            let PayMethods = [];
            let ReceiptType = [];
            let NewReceiptType = [];
            let CreditStatus = [];
            let billTypes = [];
            let CreditBills = []
            var states = this.state
            if (states.FilterDatas.InPatientSelected) {
                BillType.push("ip")
            }
            if (states.FilterDatas.OutPatientSelected) {
                BillType.push("op")
            }
            if (states.FilterDatas.PharmacySelected) {
                BillType.push("pharmacy")
            }
            if (states.FilterDatas.LaboratorySelected) {
                BillType.push('lab')
            }
            if (states.FilterDatas.RadiologySelected) {
                BillType.push('radiology')
            }
            if (states.FilterDatas.OTSelected) {
                BillType.push('ot')
            }
            if (states.FilterDatas.IPBills) {
                NewReceiptType.push("ip")
            }
            if (states.FilterDatas.OPBills) {
                NewReceiptType.push("op")
            }
            if (states.FilterDatas.PharmaBills) {
                NewReceiptType.push("pharmacy")
            }
            if (states.FilterDatas.LabBills) {
                NewReceiptType.push('lab')
            }
            if (states.FilterDatas.RadiologyBills) {
                NewReceiptType.push('radiology')
            }

            if (states.FilterDatas.ipCreditBills) {
                CreditBills.push("ip")
            }
            if (states.FilterDatas.opCreditBills) {
                CreditBills.push("op")
            }
            if (states.FilterDatas.pharmaCreditBills) {
                CreditBills.push("pharmacy")
            }
            if (states.FilterDatas.labCreditBills) {
                CreditBills.push('lab')
            }
            if (states.FilterDatas.radiologyCreditBills) {
                CreditBills.push('radiology')
            }
            if (states.FilterDatas.otCreditBills) {
                CreditBills.push('ot')
            }
            // if (states.FilterDatas.ImageLabSelected) {
            //     BillType.push(5)
            // }
            if (states.FilterDatas.CompletedSelected) {
                BillStatus.push("regular")
            }
            if (states.FilterDatas.CreditSelected) {
                BillStatus.push('credit')
            }
            if (states.FilterDatas.ReturnSelected) {
                BillStatus.push('return')
            }
            if (states.FilterDatas.CancelledSelected) {
                BillStatus.push('cancelled')
            }


            if (states.FilterDatas.CashSelected) {
                PayMethods.push(1)
            }
            if (states.FilterDatas.CardSelected) {
                PayMethods.push(2)
            }
            if (states.FilterDatas.UPISelected) {
                PayMethods.push(3)
            }
            if (states.FilterDatas.BankTransferSelected) {
                PayMethods.push(5)
            }
            if (states.FilterDatas.ChequeSelected) {
                PayMethods.push(4)
            }
            if (states.FilterDatas.InsuranceSelected) {
                PayMethods.push(6)
            }

            if(states.FilterDatas.BillReceiptSelected) {
                ReceiptType.push('bill')
            }
            if(states.FilterDatas.AdvanceTypeSelected && !this.props.fromLab) {
                ReceiptType.push('advance')
            }
            if(states.FilterDatas.AdvanceRefundTypeSelected && !this.props.fromLab){
                ReceiptType.push('advance refund')
            }
            if(states.FilterDatas.RefundTypeSelected){
                ReceiptType.push('refund')
            }
            if(states.FilterDatas.CorporateCredit){
                CreditStatus.push('Corprate credit')
            }
            if(states.FilterDatas.PatientCredit){
                CreditStatus.push('Patient credit')
            }
            if(states.FilterDatas.Insurance){
                CreditStatus.push('Insurance credit')
            }
            if(states.FilterDatas.CancelledTypeSelected){
                ReceiptType.push('cancelled')
            }

            // if(states.FilterDatas.AgainstCredit){
            //     ReceiptType.push('against credit') 
            // }

            if(states.FilterDatas.credit){
                billTypes.push('Credit')
            }
            if(states.FilterDatas.completed){
                billTypes.push('Bill')
            }
            if(states.FilterDatas.return){
                billTypes.push('Return')
            }
            
            this.state.FilterDatas.BillType = BillType
            this.state.FilterDatas.BillStatus = BillStatus
            this.state.FilterDatas.PayMethods = PayMethods
            this.state.FilterDatas.RecieptType = ReceiptType
            this.state.FilterDatas.CreditStatus = CreditStatus
            this.state.FilterDatas.combined = PayMethods?.length >= 2 ? true : false
            this.state.FilterDatas.billType = billTypes
            this.state.FilterDatas.new_Receipt_Type = NewReceiptType
            this.state.FilterDatas.BillModuleType = CreditBills
            this.setState({ FilterDatas: this.state.FilterDatas })
        })
    }

    ClearFilterData() {
        this.setState({
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PatientName": "",
                "AccNo": "",
                "MobNo": "",
                "DoctorName": [],
                "BillNumber": "",
                "uhidNo":"",
                "PaidAmount": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "ImageLabSelected": true,
                "OTSelected": true,
                "PaidSelected": true,
                "PartialPaidSelected": true,
                "CreditSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "BillType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "PayStatus": [],
                "BillStatus":["regular","credit","return"],
                "CompletedSelected": true,
                "CreditSelected": true,
                "ReturnSelected": true,
                "CancelledSelected": false,
                "PayMethods":[1,2,3,4,5,6],
                "combined":true,
                "CreditStatus":['Corprate credit', 'Insurance credit', 'Patient credit'],
                "Insurance":true,
                "PatientCredit":true,
                "CorporateCredit":true,
                "BillReceiptSelected": true,
                "AdvanceTypeSelected": true,
                "AdvanceRefundTypeSelected":true,
                "RefundTypeSelected": true,
                "CancelledTypeSelected": true,
                "RecieptType": ['bill', 'advance', 'refund', 'cancelled', 'advance refund' ],
                "startTime":null,
                "endTime":null,
                "UserDetails" : [],
                "UserList" : this.props.FilterDatas.UserList,
                "is_user" : this.props.FilterDatas.is_user,
                "RecieptType": this.props.fromLab ? ['bill', 'refund', 'cancelled'] : ['bill', 'advance', 'refund', 'cancelled', 'advance refund'],
                "onError":false,
                'credit':true,
                'completed':true,
                'return':true,
                'billType':['Credit','Bill','Return'],
                "OPBills" : true,
                "IPBills" : true,
                "LabBills" : true,
                "PharmaBills" : localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "new_Receipt_Type" : ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "AgainstCredit":'both',
                "BillModuleType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "includeFObills" : false,
                "is_bill_wise" : false,
                "opCreditBills" : true,
                "ipCreditBills" : true,
                "pharmaCreditBills" : localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "labCreditBills" :  true,
                "RadiologyBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "RadiologySelected":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "radiologyCreditBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "labCreditBills" : true,
                "otCreditBills" : true,
                "billTo": "",
                "billFrom": "",
                "ReceiptFrom": '',
                "ReceiptTo": '',
                "phoneNumber": '',
                "patient_Name": '',
                "UHID_No": '',
                'returnFrom':'',
                'returnTo':'',
                'cancelFrom':'',
                'cancelTo':'',
                "refundNoFrom":"",
                "refundNoTo":"",
                "IncludePaidBills": false,
                "Bill_No": "",
            }
        }, () => {
            // if (this.props.PageName === "DayEndStatus") {
                this.props.ClearData(this.state.FilterDatas)
                this.FilterSearch()
            // }
        })
    }


    closeFilter() {
        this.setState({
            FilterOpen: false
        })
    }

    FilterSearch() {
        let states = this.state
        if (this.props.PageName === "OverallBills") {
            if (states.FilterDatas.billTo === '' && states.FilterDatas.billFrom !== '') {
                this.errorMessage("Please Enter Bill To Number");
            } else if (states.FilterDatas.billFrom !== '' && states.FilterDatas.billTo !== '' && +states.FilterDatas.billTo <= +states.FilterDatas.billFrom) {
                this.errorMessage("Bill To Number Should Greater Than Bill From Number");
            } else if (states.FilterDatas.returnFrom !== '' && states.FilterDatas.returnTo === '') {
                this.errorMessage("Please Enter Return To Number")
            } else if (states.FilterDatas.returnFrom !== '' && states.FilterDatas.returnTo !== '' && +states.FilterDatas.returnTo <= +states.FilterDatas.returnFrom) {
                this.errorMessage("Return To Number Should Greater Than Return From Number")
            } else if(states.FilterDatas.cancelTo === '' && states.FilterDatas.cancelFrom !== '' && !this.props.Pharmacy){
                this.errorMessage("Please Enter Cancel To Number")
            } else if (states.FilterDatas.cancelFrom !== '' && states.FilterDatas.cancelTo !== '' && +states.FilterDatas.cancelTo <= +states.FilterDatas.cancelFrom && !this.props.Pharmacy) {
                this.errorMessage("Bill To Number Should Greater Than Bill From Number");
            } else {
                this.setState({ FilterOpen: false });
                this.props.FilteredData(this.state.FilterDatas);
            }
        } else if (this.props.PageName === "ReceiptBills") {
            if (states.FilterDatas.ReceiptFrom !== '' && states.FilterDatas.ReceiptTo === '') {
                this.errorMessage("Please Enter Receipt To Number");
            } else if (states.FilterDatas.ReceiptFrom !== '' && states.FilterDatas.ReceiptTo !== '' && +states.FilterDatas.ReceiptTo <= +states.FilterDatas.ReceiptFrom) {
                this.errorMessage("Receipt To Number Should Greater Than Receipt From Number");
            } else if(states.FilterDatas.refundNoFrom !== '' && states.FilterDatas.refundNoTo === ''){
                this.errorMessage("Please Enter Refund To Number");
            } else if(states.FilterDatas.refundNoFrom !== '' && states.FilterDatas.refundNoTo !== '' && +states.FilterDatas.refundNoTo <= +states.FilterDatas.refundNoFrom){
                this.errorMessage("Refund To Number Should Greater Than Refund From Number")
            } else if (states.FilterDatas.returnFrom !== '' && states.FilterDatas.returnTo === '') {
                this.errorMessage("Please Enter Return To Number")
            } else if (states.FilterDatas.returnFrom !== '' && states.FilterDatas.returnTo !== '' && +states.FilterDatas.returnTo <= +states.FilterDatas.returnFrom) {
                this.errorMessage("Return To Number Should Greater Than Return From Number")
            } else {
                this.setState({ FilterOpen: false });
                this.props.FilteredData(this.state.FilterDatas);
            }
        } else if ((this.props.PageName === 'DayEndStatus') || (this.props.PageName === 'LabDayEnd')){
            if (states.FilterDatas.ReceiptFrom !== '' && states.FilterDatas.ReceiptTo === '') {
                this.errorMessage("Please Enter Receipt To Number");
            } else if (states.FilterDatas.ReceiptFrom !== '' && states.FilterDatas.ReceiptTo !== '' && +states.FilterDatas.ReceiptTo <= +states.FilterDatas.ReceiptFrom) {
                this.errorMessage("Receipt To Number Should Greater Than Receipt From Number");
            }else {
                this.setState({ FilterOpen: false });
                this.props.FilteredData(this.state.FilterDatas);
            }
        }else {
            this.setState({ FilterOpen: false });
            this.props.FilteredData(this.state.FilterDatas);
        }  
    }
    PrintDetails(){
        this.setState({ FilterOpen: false })
        this.props.PrintData(this.state.FilterDatas)
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    disableSearchBtn(){
        try {
            if ((this.props.PageName === "OverallBills") || this.props?.PageName === "CancelledBills" || this.props?.PageName === "SavedBills") {
                if (this.state.FilterDatas?.BillModuleType.length === 0 || this.state.FilterDatas?.BillType.length === 0) {
                    return true
                } else {
                    return false
                }
            } else if (this.props?.PageName === "CreditBills") {
                if (this.state.FilterDatas.is_bill_wise) {
                    if (this.state.FilterDatas?.BillModuleType.length === 0 || this.state.FilterDatas?.BillType.length === 0) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    if (this.state.FilterDatas?.BillType.length === 0 || this.state.FilterDatas.CreditStatus.length === 0) {
                        return true
                    } else {
                        return false
                    }
                }
            }
          else{
            return false
          }
        }catch(e){
            console.log(e?.message);
            return false
        }
    }
    render() {
        const { t } = this.props
        let states = this.state
        return (
            <Box component={"div"} className='eMed_Filter_Billing_Container'>
                <Box component={"div"} className='eMed_Filter_Top'>
                    <Typography variant='h6' fontWeight={"600"}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }, () => { this.props.CloseFilter() }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
               <Box maxHeight={'73vh'} overflow={'scroll'}>
                 <Box component={"div"} className='eMed_Filter_DateFilter'>
                    <div style={{width:"60%"}}>
                        {((this.props.PageName === "DayEndStatus") || (this.props.PageName === 'LabDayEnd') || (this.props.PageName === 'OverallBills' && !this.props.Pharmacy) || (this.props.PageName === 'ReceiptBills' && !this.props.Pharmacy && !this.props.labReceipt)) ?
                        <Autocomplete
                        multiple
                        limitTags={1}
                        size='small'
                        options={this.state.FilterDatas?.UserList}
                        getOptionLabel={(options) => options?.user_name}
                        onChange={(e, value) => {
                            states.FilterDatas.UserDetails = value 
                            this.setState({
                                FilterDatas: states.FilterDatas
                            })
                        }}
                        disableCloseOnSelect
                        disablePortal
                        autoComplete='off'
                        value={states.FilterDatas.UserDetails}
                        id="combo-box-demo"
                        sx={{ width: "16vw" }}
                        renderInput={(params) => <TextField {...params} label={t('UserName')} size='small' />}
                    />
                        : null}
                    </div>
                    <Box style={{width:"40%", }} component={"div"}>
                        <CommonDatePicker
                            FromDate={this.state.FilterDatas.FromDate}
                            ToDate={this.state.FilterDatas.ToDate}
                            DateIndex={this.state.FilterDatas.DateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={this.props?.PageName !== "CreditBills"} />
                    </Box>
                </Box>
                    {this.props.PageName === "CreditBills" ?
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', paddingY:'0.5vw', paddingLeft:"1vw"}}>
                            <label style={{ paddingRight: '0.8vw', paddingTop: '0.1vw' }}>Admission Wise</label>
                            <FormControlLabel
                                value="end"
                                control={
                                    <Switch
                                        size='small'
                                        color="primary"
                                        checked={this.state.FilterDatas?.is_bill_wise ? true : false}
                                        onChange={(e) => {
                                            states.FilterDatas.is_bill_wise = e.target.checked
                                            if (!states.FilterDatas.is_bill_wise) {
                                                states.FilterDatas.BillModuleType = localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? ['op', 'ip', 'lab'] : ['op', 'ip', 'pharmacy', 'lab']
                                                states.FilterDatas.opCreditBills = true
                                                states.FilterDatas.ipCreditBills = true
                                                states.FilterDatas.pharmaCreditBills = localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true
                                                states.FilterDatas.labCreditBills = true
                                                states.FilterDatas.otCreditBills = true
                                                states.FilterDatas.Bill_No = ''
                                            }
                                            this.setState({
                                                FilterDatas: states.FilterDatas
                                            })
                                        }} />}
                                label="Bill Wise"
                                labelPlacement="end"
                            />
                        </Box>
                        : null}
                {this.props.PageName !== "CreditBills" ?
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        FromDate={this.state.FilterDatas.FromDate}
                        ToDate={this.state.FilterDatas.ToDate}
                        onError={this.state.FilterDatas.onError}
                        disable={( (this.props.PageName === "OverallBills" || this.props.PageName ===  "DayEndStatus" || this.props.PageName === 'LabDayEnd' || this.props.PageName === "ReceiptBills") && !this.props.Pharmacy && (this.state.FilterDatas.billFrom !== '' || this.state.FilterDatas.ReceiptFrom !== '')) ? true : false}
                    /> : null
                }
                {((this.props.PageName === 'DayEndStatus') || (this.props.PageName === 'LabDayEnd')) ?
                    <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                            <TextField
                                className="eMed_Bill_Filter_TextFeild_small"
                                inputProps={{ maxLength: 20 }}
                                sx={{ marginRight: '1vw' }}
                                size='small'
                                label={`Receipt No From`}
                                value={this.state.FilterDatas.ReceiptFrom}
                                disabled={(this.state.FilterDatas.startTime !== '' && this.state.FilterDatas.startTime != null ) ? true : false}
                                autoComplete='off'
                                onChange={(e) => {
                                    let value = CommonValidation.billNo(e.target.value);
                                    if (value || e.target.value === "") {
                                        this.state.FilterDatas.ReceiptFrom = e.target.value
                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                    }
                                }}
                            />
                            <TextField
                                inputProps={{ maxLength: 20 }}
                                className="eMed_Bill_Filter_TextFeild_small"
                                size='small'
                                label={`Receipt No To`}
                                value={this.state.FilterDatas.ReceiptTo}
                                disabled={!this.state.FilterDatas.ReceiptFrom}
                                autoComplete='off'
                                onChange={(e) => {
                                    let value = CommonValidation.billNo(e.target.value);
                                    if (value || e.target.value === "") {
                                        this.state.FilterDatas.ReceiptTo = e.target.value
                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                    }
                                }}
                            />
                    </Box>
                : null}

                {
                    this.props.PageName !== "DayEndStatus" &&  this.props.PageName !== "LabDayEnd" && this.props.PageName !== "CreditBills" ?
                        <Box component={'div'}>
                            <Box component={"div"} className='eMed_Filter_TextFields'>
                                <Box component={'div'} display="flex" flexDirection={"row"} justifyContent={"space-between"} width={"100%"}>
                                    <TextField
                                        inputProps={{ maxLength: 30 }}
                                        className="eMed_Bill_Filter_TextFeild_small"
                                        size='small'
                                        label={t("PatientName")}
                                        name={"FilterKey"}
                                        value={this.state.FilterDatas.PatientName}
                                        autoComplete='off'
                                        onChange={(e) => {
                                            let number = CommonValidation.alphabetOnly(e.target.value);
                                            if (number || e.target.value === "") {
                                                this.state.FilterDatas.PatientName = e.target.value
                                                this.setState({ FilterDatas: this.state.FilterDatas })
                                            }

                                        }}
                                    />
                                    {this.props.PageName != "ReceiptBills" ?
                                        <TextField
                                            inputProps={{ maxLength: 13 }}
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            label={t("UHID")}
                                            name={"FilterKey"}
                                            value={this.state.FilterDatas.AccNo}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.gstnumberValidation(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.AccNo = e.target.value.toUpperCase()
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }}
                                        />
                                        : this.props.labReceipt || !this.props.Pharmacy ? <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            inputProps={{ maxLength: 13 }}
                                            size='small'
                                            label={this.props.labReceipt || !this.props.Pharmacy ? t('RNo') : t("BillNumber")}
                                            value={this.state.FilterDatas.BillNumber}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.BillNumber = e.target.value.toUpperCase()
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }}
                                        /> : <TextField
                                        inputProps={{ maxLength: 20 }}
                                        className="eMed_Bill_Filter_TextFeild_small"
                                        size='small'
                                        label={this.props.PageName === 'ReceiptBills' ? t(`Amount${CurrencySymbol}`) : t('PaidAmount')+`(${CurrencySymbol})`}
                                        value={this.state.FilterDatas.PaidAmount}
                                        autoComplete='off'
                                        onChange={(e) => {
                                            let number = CommonValidation.NumberWithDot(e.target.value);
                                            if (number || e.target.value === "") {
                                                this.state.FilterDatas.PaidAmount = e.target.value
                                                this.setState({ FilterDatas: this.state.FilterDatas })
                                            }
                                        }
                                        }
                                    />}
                                </Box>
                                {this.props.PageName != "ReceiptBills" ?
                                    <Box component={'div'} display="flex" flexDirection={"row"} justifyContent={"space-between"} width={"100%"}>
                                        <TextField
                                            inputProps={{ maxLength: 10 }}
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            label={t("MobNo")}
                                            name={"FilterKey"}
                                            value={this.state.FilterDatas.MobNo}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.numberOnly(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.MobNo = e.target.value
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }}
                                        />
                                        <Box  className='eMed_Dropdown'>
                                        <Autocomplete
                                            multiple
                                            limitTags={1}
                                            size='small'
                                            options={this.state.DortorList?.map((item) => item)}
                                            getOptionLabel={(option) => (this.props.Pharmacy ? option?.name : option?.doctor_name)}
                                            value={this.state.FilterDatas?.DoctorName}
                                            onChange={(event, newValue) => {
                                                this.state.FilterDatas.DoctorName = newValue
                                                this.setState({
                                                    FilterDatas:this.state.FilterDatas
                                                })
                                            }}
                                            autoComplete='off'
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('selectDoctor')}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props} key={this.props.Pharmacy ? option?.id : option?.doctor_id}>
                                                  {this.props.Pharmacy ? option?.name : option?.doctor_name}
                                                </li>
                                            )}
                                        />
                                        </Box>
                                        {/* <FormControl size='small' className='eMed_Dropdown'>
                                            <InputLabel>{t("SelectDoctor")}</InputLabel>
                                            <Select
                                                value={this.state.FilterDatas.DoctorName}
                                                label={t("SelectDoctor")}
                                                onChange={(e) => {
                                                    this.state.FilterDatas.DoctorName = e.target.value
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {
                                                    this.state.DortorList?.length > 0 ?
                                                        this.state.DortorList.map((list, index) => (
                                                            <MenuItem key={index} value={list.doctor_name}>{list.doctor_name}</MenuItem>
                                                        )) : null
                                                }
                                            </Select>
                                        </FormControl> */}
                                    </Box> : null}
                                {this.props.PageName != 'ReceiptBills' && this.props.PageName != 'OverallBills' ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            inputProps={{ maxLength: 20 }}
                                            sx={{ marginRight: '1vw' }}
                                            size='small'
                                            label={t("BillNumber")}
                                            value={this.state.FilterDatas.BillNumber}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.BillNumber = e.target.value.toUpperCase()
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }}
                                        />
                                        {/* <TextField
                                            inputProps={{ maxLength: 10 }}
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            label={t("PaidAmount₹")}
                                            value={this.state.FilterDatas.PaidAmount}
                                            onChange={(e) => {
                                                let number = CommonValidation.NumberWithDot(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.PaidAmount = e.target.value
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }
                                            }
                                        /> */}
                                    </Box> : null}
                                    {this.props.PageName === 'OverallBills' ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            inputProps={{ maxLength: 20 }}
                                            sx={{ marginRight: '1vw' }}
                                            size='small'
                                            name={'billFrom'}
                                            label={"Bill No From"}
                                            value={this.state.FilterDatas.billFrom}
                                            disabled={(this.state.FilterDatas.startTime !== '' && this.state.FilterDatas.startTime != null && !this.props.Pharmacy) ? true : false}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if(this.props.Pharmacy){
                                                    if (CommonValidation.numberOnly(value) || value === '') {
                                                        this.state.FilterDatas['billFrom'] = value
                                                        this.setState({
                                                            FilterDatas: this.state.FilterDatas
                                                        })
                                                    }
                                                }else{
                                                    if (CommonValidation.billNo(value) || value === '') {
                                                        this.state.FilterDatas['billFrom'] = value
                                                        this.setState({
                                                            FilterDatas: this.state.FilterDatas
                                                        })
                                                    }
                                                }

                                            }}
                                        />
                                        <TextField
                                            inputProps={{ maxLength: 20 }}
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            name={'billTo'}
                                            label={"Bill No To"}
                                            value={this.state.FilterDatas.billTo}
                                            disabled={!this.state.FilterDatas.billFrom}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if(this.props.Pharmacy){
                                                    if (CommonValidation.numberOnly(value) || value === '') {
                                                        this.state.FilterDatas['billTo'] = value
                                                        this.setState({
                                                            FilterDatas: this.state.FilterDatas
                                                        })
                                                    }
                                                }else{ 
                                                    if (CommonValidation.billNo(value) || value === '') {
                                                        this.state.FilterDatas['billTo'] = value
                                                        this.setState({
                                                            FilterDatas: this.state.FilterDatas
                                                        })
                                                    }
                                                }

                                            }
                                            }
                                        />
                                    </Box> : null}
                                    {/* Commented for this Filter Not Needed */}
                                    {/* {this.props.PageName === 'OverallBills' ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            inputProps={{ maxLength: 20 }}
                                            sx={{ marginRight: '1vw' }}
                                            size='small'
                                            name={'returnFrom'}
                                            label={"Return No From"}
                                            value={this.state.FilterDatas.returnFrom}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (CommonValidation.numberOnly(value) || value === '') {
                                                    this.state.FilterDatas['returnFrom'] = value
                                                    this.setState({
                                                        FilterDatas: this.state.FilterDatas
                                                    })
                                                }
                                            }}
                                        />
                                        <TextField
                                            inputProps={{ maxLength: 10 }}
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            name={'returnNo'}
                                            label={"Return No To"}
                                            value={this.state.FilterDatas.returnTo}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (CommonValidation.numberOnly(value) || value === '') {
                                                    this.state.FilterDatas['returnTo'] = value
                                                    this.setState({
                                                        FilterDatas: this.state.FilterDatas
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </Box> : null}
                                    {this.props.PageName === 'OverallBills' && !this.props.Pharmacy ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            inputProps={{ maxLength: 20 }}
                                            sx={{ marginRight: '1vw' }}
                                            size='small'
                                            name={'cancelFrom'}
                                            label={"Cancel No From"}
                                            value={this.state.FilterDatas.cancelFrom}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (CommonValidation.numberOnly(value) || value === '') {
                                                    this.state.FilterDatas['cancelFrom'] = value
                                                    this.setState({
                                                        FilterDatas: this.state.FilterDatas
                                                    })
                                                }
                                            }}
                                        />
                                        <TextField
                                            inputProps={{ maxLength: 10 }}
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            name={'cancelTo'}
                                            label={"Cancel No To"}
                                            value={this.state.FilterDatas.cancelTo}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (CommonValidation.numberOnly(value) || value === '') {
                                                    this.state.FilterDatas['cancelTo'] = value
                                                    this.setState({
                                                        FilterDatas: this.state.FilterDatas
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </Box> : null} */}

                            </Box>
                            {this.props.PageName === 'ReceiptBills' && !this.props.Pharmacy ?
                                    <Box>
                                        <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                            <TextField
                                                className="eMed_Bill_Filter_TextFeild_small"
                                                inputProps={{ maxLength: 20 }}
                                                sx={{ marginRight: '0.7vw' }}
                                                size='small'
                                                disabled={(this.state.FilterDatas.startTime !== '' && this.state.FilterDatas.startTime != null ) ? true : false}
                                                label={`Receipt No From`}
                                                value={this.state.FilterDatas.ReceiptFrom}
                                                autoComplete='off'
                                                onChange={(e) => {
                                                    let value = CommonValidation.billNo(e.target.value);
                                                    if (value || e.target.value === "") {
                                                        this.state.FilterDatas.ReceiptFrom = e.target.value
                                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                                    }
                                                }}
                                            />
                                            <TextField
                                                inputProps={{ maxLength: 20 }}
                                                className="eMed_Bill_Filter_TextFeild_small"
                                                size='small'
                                                label={`Receipt No To`}
                                                value={this.state.FilterDatas.ReceiptTo}
                                                disabled={!this.state.FilterDatas.ReceiptFrom}
                                                autoComplete='off'
                                                onChange={(e) => {
                                                    let value = CommonValidation.billNo(e.target.value);
                                                    if (value || e.target.value === "") {
                                                        this.state.FilterDatas.ReceiptTo = e.target.value
                                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                            <TextField
                                                inputProps={{ maxLength: 20 }}
                                                className="eMed_Bill_Filter_TextFeild_small"
                                                size='small'
                                                label={this.props.PageName === 'ReceiptBills' ? t(`Amount${CurrencySymbol}`) : t('PaidAmount')+`(${CurrencySymbol})`}
                                                value={this.state.FilterDatas.PaidAmount}
                                                autoComplete='off'
                                                onChange={(e) => {
                                                    let number = CommonValidation.NumberWithDot(e.target.value);
                                                    if (number || e.target.value === "") {
                                                        this.state.FilterDatas.PaidAmount = e.target.value
                                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                                    }
                                                }
                                                }
                                            />
                                            {this.props.labReceipt ?
                                                <TextField
                                                    className="eMed_Bill_Filter_TextFeild_small"
                                                    inputProps={{ maxLength: 10 }}
                                                    size='small'
                                                    sx={{ marginLeft: '1vw' }}
                                                    label={t('UHID')}
                                                    value={this.state.FilterDatas.uhidNo}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                        if (number || e.target.value === "") {
                                                            this.state.FilterDatas.uhidNo = e.target.value.toUpperCase()
                                                            this.setState({ FilterDatas: this.state.FilterDatas })
                                                        }
                                                    }}
                                                />: null}
                                        </Box>
                                    </Box> : null
                            }
                            {this.props.PageName === 'ReceiptBills' && this.props.Pharmacy ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                    <TextField
                                        inputProps={{ maxLength: 20 }}
                                        className="eMed_Bill_Filter_TextFeild_small"
                                        size='small'
                                        label={`Receipt No From`}
                                        value={this.state.FilterDatas.ReceiptFrom}
                                        autoComplete='off'
                                        onChange={(e) => {
                                            let number = CommonValidation.numberOnly(e.target.value);
                                            if (number || e.target.value === "") {
                                                this.state.FilterDatas.ReceiptFrom = e.target.value
                                                this.setState({ FilterDatas: this.state.FilterDatas })
                                            }
                                        }
                                        }
                                    /> 
                                    <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            inputProps={{ maxLength: 20 }}
                                            size='small'
                                            sx={{ marginLeft: '1vw' }}
                                            label={`Receipt No To`}
                                            value={this.state.FilterDatas.ReceiptTo}
                                            disabled={!this.state.FilterDatas.ReceiptFrom}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.numberOnly(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.ReceiptTo = e.target.value.toUpperCase()
                                                    this.setState({ FilterDatas: this.state.FilterDatas },() => {
                                                        if ((this.state.FilterDatas.ReceiptTo === '' && this.state.FilterDatas?.returnTo === '') || (this.state.FilterDatas.ReceiptTo !== '' && this.state.FilterDatas?.returnTo !== '')) {
                                                            this.state.FilterDatas.RefundTypeSelected = true
                                                            this.state.FilterDatas.BillReceiptSelected = true
                                                            this.state.FilterDatas.RecieptType = ['bill', 'advance', 'refund', 'cancelled', 'advance refund']
                                                            this.setState({ FilterDatas: this.state.FilterDatas })
                                                        } else if((this.state.FilterDatas.ReceiptTo !== '' && this.state.FilterDatas?.returnTo === '')) {
                                                            this.state.FilterDatas.RefundTypeSelected = false
                                                            this.state.FilterDatas.BillReceiptSelected = true
                                                            this.state.FilterDatas.RecieptType = ['bill', 'advance', 'cancelled', 'advance refund']
                                                            this.setState({ FilterDatas: this.state.FilterDatas })
                                                        } else if(this.state.FilterDatas.ReceiptTo === '' && this.state.FilterDatas?.returnTo !== ''){
                                                            this.state.FilterDatas.RefundTypeSelected = true
                                                            this.state.FilterDatas.BillReceiptSelected = false
                                                            this.state.FilterDatas.RecieptType = ['advance', 'refund', 'cancelled', 'advance refund']
                                                            this.setState({ FilterDatas: this.state.FilterDatas })
                                                        }
                                                    })
                                                }
                                            }}
                                        />
                                </Box> : null
                            }
                            {this.props.PageName === 'ReceiptBills' && this.props.Pharmacy ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                    <TextField
                                        inputProps={{ maxLength: 20 }}
                                        className="eMed_Bill_Filter_TextFeild_small"
                                        size='small'
                                        label={`Return No From`}
                                        value={this.state.FilterDatas.returnFrom}
                                        autoComplete='off'
                                        onChange={(e) => {
                                            let number = CommonValidation.numberOnly(e.target.value);
                                            if (number || e.target.value === "") {
                                                this.state.FilterDatas.returnFrom = e.target.value
                                                this.setState({ FilterDatas: this.state.FilterDatas })
                                            }
                                        }
                                        }
                                    /> 
                                    <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            inputProps={{ maxLength: 20 }}
                                            size='small'
                                            sx={{ marginLeft: '1vw' }}
                                            label={`Return No To`}
                                            value={this.state.FilterDatas.returnTo}
                                            disabled={!this.state.FilterDatas.returnFrom}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.numberOnly(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.returnTo = e.target.value.toUpperCase()
                                                    this.setState({ FilterDatas: this.state.FilterDatas },() => {
                                                        if ((this.state.FilterDatas.ReceiptTo === '' && this.state.FilterDatas?.returnTo === '') || (this.state.FilterDatas.ReceiptTo !== '' && this.state.FilterDatas?.returnTo !== '')) {
                                                            this.state.FilterDatas.RefundTypeSelected = true
                                                            this.state.FilterDatas.BillReceiptSelected = true
                                                            this.state.FilterDatas.RecieptType = ['bill', 'advance', 'refund', 'cancelled', 'advance refund']
                                                            this.setState({ FilterDatas: this.state.FilterDatas })
                                                        } else if((this.state.FilterDatas.returnTo !== '' && this.state.FilterDatas?.ReceiptTo === '')) {
                                                            this.state.FilterDatas.RefundTypeSelected = true
                                                            this.state.FilterDatas.BillReceiptSelected = false
                                                            this.state.FilterDatas.RecieptType = ['advance', 'refund', 'cancelled', 'advance refund']
                                                            this.setState({ FilterDatas: this.state.FilterDatas })
                                                        } else if(this.state.FilterDatas.returnTo === '' && this.state.FilterDatas?.ReceiptTo !== ''){
                                                            this.state.FilterDatas.RefundTypeSelected = false
                                                            this.state.FilterDatas.BillReceiptSelected = true
                                                            this.state.FilterDatas.RecieptType = ['bill', 'advance', 'cancelled', 'advance refund']
                                                            this.setState({ FilterDatas: this.state.FilterDatas })
                                                        }
                                                    })
                                                }
                                            }}
                                        />
                                </Box> : null
                            }
                        </Box> : 
                        this.props.PageName === 'CreditBills' ?
                                <Box>
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            label={t('Patient Name')}
                                            value={this.state.FilterDatas.patient_Name}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.patient_Name = e.target.value.toUpperCase()
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }}
                                        />
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            sx={{ marginLeft: '1vw' }}
                                            label={`UHID`}
                                            value={this.state.FilterDatas.UHID_No}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.UHID_No = e.target.value
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }
                                            }
                                        />
                                    </Box>
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                    <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            label={`Mobile No`}
                                            value={this.state.FilterDatas.phoneNumber}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.numberOnly(e.target.value);
                                                if ((number && e.target.value.length < 11) || e.target.value === "") {
                                                    this.state.FilterDatas.phoneNumber = e.target.value
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }
                                            }
                                        />
                                        {this.state.FilterDatas?.is_bill_wise ?
                                            <TextField
                                                className="eMed_Bill_Filter_TextFeild_small"
                                                size='small'
                                                sx={{ marginLeft: '1vw' }}
                                                label={t("BillNumber")}
                                                value={this.state.FilterDatas.Bill_No}
                                                autoComplete='off'
                                                onChange={(e) => {
                                                    let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                    if (number || e.target.value === "") {
                                                        this.state.FilterDatas.Bill_No = e.target.value.toUpperCase()
                                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                                    }
                                                }}
                                            /> : null}
                                    </Box>
                                </Box>
                        
                         : null
                    
                }
                {this.props.PageName === 'ReceiptBills' && !this.props?.Pharmacy && !this.props?.labReceipt ?
                <Box component={"div"}>
                    <Box className="eMed_Filter_Header">
                        <Typography marginLeft={"1vw"}>{t("Bill Type")}</Typography>
                    </Box>
                    <Box className="eMed_CheckBox_Group">
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.FilterDatas.IPBills} onChange={(e) => { this.handleCheckBox(e, "IPBills",) }} size='small' />} label={t("InPatient")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.OPBills} onChange={(e) => { this.handleCheckBox(e, "OPBills") }} size='small' />} label={t("OutPatient")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled={localGetItem("Show_Pharmacy_Bills_In_FO") === "false"} checked={this.state.FilterDatas.PharmaBills} onChange={(e) => { this.handleCheckBox(e, "PharmaBills") }} size='small' />} label={t("Pharmacy")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.LabBills} onChange={(e) => { this.handleCheckBox(e, "LabBills") }} size='small' />} label={t("Laboratory")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled={localGetItem('Show_Radiology_Bills_In_FO') === 'false'} checked={this.state.FilterDatas.RadiologyBills} onChange={(e) => { this.handleCheckBox(e, "RadiologyBills") }} size='small' />} label={t("Radiology")} />
                        {/* <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.ImageLabSelected} onChange={(e) => { this.handleCheckBox(e, "ImageLabSelected") }} size='small' />} label={t("ImageLab")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.OTSelected} onChange={(e) => { this.handleCheckBox(e, "OTSelected") }} size='small' />} label={t("OT")} /> */}
                        {/* <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.ImageLabSelected} onChange={(e) => { this.handleCheckBox(e, "ImageLabSelected") }} size='small' />} label={t("ImageLab")} />*/}
                        {this.props?.PageName === "CancelledBills" || this.props?.PageName === "SavedBills" || this.props.PageName === 'ReceiptBills' ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.OTSelected} onChange={(e) => { this.handleCheckBox(e, "OTSelected") }} size='small' />} label={t("OT")} />} 
                    </Box>
                </Box> : null}
                {this.props.PageName === 'CreditBills' && !this.props?.Pharmacy ?
                <Box component={"div"}>
                    <Box className="eMed_CheckBox_Group">
                        <FormControlLabel sx={{width: '12vw'}} control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.FilterDatas.IncludePaidBills} onChange={(e) => { this.handleCheckBox(e, "IncludePaidBills",) }} size='small' />} label={t("Include Paid Bills")} />
                        </Box>
                </Box> : null}
                {this.props.labReceipt || this.props.Pharmacy || this.props.PageName === 'LabDayEnd' ? null : 
                <Box component={"div"}>
                    <Box className="eMed_Filter_Header">
                        <Typography marginLeft={"1vw"}>{this.props.PageName === 'ReceiptBills' ? t("Paid In") : t("ModuleType")}</Typography>
                    </Box>
                    <Box className="eMed_CheckBox_Group">
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.FilterDatas.InPatientSelected} onChange={(e) => { this.handleCheckBox(e, "InPatientSelected",) }} size='small' />} label={t("InPatient")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.OutPatientSelected} onChange={(e) => { this.handleCheckBox(e, "OutPatientSelected") }} size='small' />} label={t("OutPatient")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled={localGetItem("Show_Pharmacy_Bills_In_FO") === "false"} checked={this.state.FilterDatas.PharmacySelected} onChange={(e) => { this.handleCheckBox(e, "PharmacySelected") }} size='small' />} label={t("Pharmacy")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.LaboratorySelected} onChange={(e) => { this.handleCheckBox(e, "LaboratorySelected") }} size='small' />} label={t("Laboratory")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled={localGetItem("Show_Radiology_Bills_In_FO") === "false"} checked={this.state.FilterDatas.RadiologySelected} onChange={(e) => { this.handleCheckBox(e, "RadiologySelected") }} size='small' />} label={t("Radiology")} />
                        {/* <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.ImageLabSelected} onChange={(e) => { this.handleCheckBox(e, "ImageLabSelected") }} size='small' />} label={t("ImageLab")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.OTSelected} onChange={(e) => { this.handleCheckBox(e, "OTSelected") }} size='small' />} label={t("OT")} /> */}
                        {/* <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.ImageLabSelected} onChange={(e) => { this.handleCheckBox(e, "ImageLabSelected") }} size='small' />} label={t("ImageLab")} />*/}
                        {this.props?.PageName === "CancelledBills" || this.props?.PageName === "SavedBills" || this.props.PageName === 'ReceiptBills' ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.OTSelected} onChange={(e) => { this.handleCheckBox(e, "OTSelected") }} size='small' />} label={t("OT")} />} 
                    </Box>
                </Box>}
                {this.props.labReceipt || this.props.Pharmacy || (!this.state.FilterDatas?.is_bill_wise && this.props.PageName === "CreditBills") || this.props.PageName === 'ReceiptBills' || (this.props.PageName === "DayEndStatus") || (this.props.PageName === 'LabDayEnd') ? null : 
                <Box component={"div"}>
                    <Box className="eMed_Filter_Header">
                        <Typography marginLeft={"1vw"}>{t("Bills")}</Typography>
                    </Box>
                    <Box className="eMed_CheckBox_Group">
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.FilterDatas.ipCreditBills} onChange={(e) => { this.handleCheckBox(e, "ipCreditBills",) }} size='small' />} label={t("InPatient")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.opCreditBills} onChange={(e) => { this.handleCheckBox(e, "opCreditBills") }} size='small' />} label={t("OutPatient")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled={localGetItem("Show_Pharmacy_Bills_In_FO") === "false"} checked={this.state.FilterDatas.pharmaCreditBills} onChange={(e) => { this.handleCheckBox(e, "pharmaCreditBills") }} size='small' />} label={t("Pharmacy")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.labCreditBills} onChange={(e) => { this.handleCheckBox(e, "labCreditBills") }} size='small' />} label={t("Laboratory")} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled={localGetItem("Show_Radiology_Bills_In_FO") === "false"}  checked={this.state.FilterDatas.radiologyCreditBills} onChange={(e) => { this.handleCheckBox(e, "radiologyCreditBills") }} size='small' />} label={t("Radiology")} />
                        {this.props?.PageName === "CancelledBills" || this.props?.PageName === "SavedBills" || this.props.PageName === 'ReceiptBills' ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.otCreditBills} onChange={(e) => { this.handleCheckBox(e, "otCreditBills") }} size='small' />} label={t("OT")} />}
                    </Box>
                </Box>}
                    {(this.props.labReceipt || (this.props.Pharmacy && this.props.PageName === 'ReceiptBills') )?
                        <Box component={'div'} marginTop={"0.5vw"}>
                            <Box className="eMed_CheckBox_Group">
                                <FormControlLabel sx={{fontSize:"0.8vw"}} control={<Checkbox checked={this.state.FilterDatas.includeFObills} onChange={(e) => { this.handleCheckBox(e, "includeFObills") }} size='small' />} label={`Include ${this.props.labReceipt ? "Lab" : "Pharmacy"} Receipts from FO`} />
                            </Box>
                        </Box> : null
                }
                {this.props.PageName === 'ReceiptBills' ? 
                 <Box component={"div"}>
                    <Box className="eMed_Filter_Header">
                        <Typography marginLeft={"1vw"}>{t("Receipt For") }</Typography>
                    </Box>
                    <Box className="eMed_CheckBox_Group">
                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.BillReceiptSelected} disabled={(this.state.FilterDatas.returnFrom && this.state.FilterDatas.returnTo ) || (this.state.FilterDatas.ReceiptFrom && this.state.FilterDatas.ReceiptTo) ? true : false} onChange={(e) => { this.handleCheckBox(e, "BillReceiptSelected") }} size='small' />} label={t("Bill")} />
                    { this.props.fromLab || this.props.Pharmacy ? null:
                     <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.AdvanceTypeSelected} onChange={(e) => { this.handleCheckBox(e, "AdvanceTypeSelected") }} size='small' />} label={t("Advance")} /> }
                    { this.props.fromLab || this.props.Pharmacy ? null:
                     <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.AdvanceRefundTypeSelected} onChange={(e) => { this.handleCheckBox(e, "AdvanceRefundTypeSelected") }} size='small' />} label={t("Advance Refund")} /> }
                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.RefundTypeSelected} disabled={(this.state.FilterDatas.ReceiptFrom && this.state.FilterDatas.ReceiptTo ) || (this.state.FilterDatas.returnFrom && this.state.FilterDatas.returnTo) ? true : false} onChange={(e) => { this.handleCheckBox(e, "RefundTypeSelected") }} size='small' />} label={t("Refund")} />
                    {this.props.Pharmacy ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CancelledTypeSelected} onChange={(e) => { this.handleCheckBox(e, "CancelledTypeSelected") }} size='small' />} label={t("Cancelled")} /> }
                    </Box>
                 </Box>
                : null}
                    {this.props.PageName === 'ReceiptBills' ?
                        <Box component={'div'}>
                            <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"}>{t("Against Credit")}</Typography>
                            </Box>
                            <Box component={'div'} className="eMed_CheckBox_Group">
                                <RadioGroup
                                    value={this.state.FilterDatas.AgainstCredit}
                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                    onChange={(e) => {
                                        this.state.FilterDatas.AgainstCredit = e.target.value
                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                    }}
                                >
                                    <FormControlLabel value={'true'} control={<Radio size='small' disabled={!this.state.FilterDatas.BillReceiptSelected} />} label="Yes" />
                                    <FormControlLabel value={'false'} control={<Radio size='small' disabled={!this.state.FilterDatas.BillReceiptSelected}/>} label="No" />
                                    <FormControlLabel value={'both'} control={<Radio size='small' disabled={!this.state.FilterDatas.BillReceiptSelected} />} label="Both" />
                                </RadioGroup>
                            </Box>
                        </Box> : null}
                {
                    ((this.props.PageName === "OverallBills") && !this.props.Pharmacy) ?
                        <Box component={"div"}>
                            <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"} >Bill Type</Typography>
                            </Box>
                            <Box className="eMed_CheckBox_Group">
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CompletedSelected} onChange={(e) => { this.handleCheckBox(e, "CompletedSelected") }} size='small' />} label={t("Completed")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CreditSelected} onChange={(e) => { this.handleCheckBox(e, "CreditSelected") }} size='small' />} label={t("Credit")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.ReturnSelected} onChange={(e) => { this.handleCheckBox(e, "ReturnSelected") }} size='small' />} label={t("Return")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CancelledSelected} onChange={(e) => { this.handleCheckBox(e, "CancelledSelected") }} size='small' />} label={t("Cancelled")} />
                            </Box>
                        </Box> : null
                }
                {
                    ((this.props.PageName === "ReceiptBills") || (this.props.PageName === "DayEndStatus") || (this.props.PageName === "LabDayEnd")) ?
                        <Box component={"div"}>
                            <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"} >Payment Method</Typography>
                            </Box>
                            <Box className="eMed_CheckBox_Group">
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CashSelected} onChange={(e) => { this.handleCheckBox(e, "CashSelected") }} size='small' />} label={t("Cash")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CardSelected} onChange={(e) => { this.handleCheckBox(e, "CardSelected") }} size='small' />} label={t("Card")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.UPISelected} onChange={(e) => { this.handleCheckBox(e, "UPISelected") }} size='small' />} label={t("UPI")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.BankTransferSelected} onChange={(e) => { this.handleCheckBox(e, "BankTransferSelected") }} size='small' />} label={t("BankTransfer")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.ChequeSelected} onChange={(e) => { this.handleCheckBox(e, "ChequeSelected") }} size='small' />} label={t("Cheque")} />
                                {/* {this.props.Pharmacy ? null :<FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.InsuranceSelected} onChange={(e) => { this.handleCheckBox(e, "InsuranceSelected") }} size='small' />} label={t("Insurance")} />} */}
                                {this.props.fromReceipt ? 
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled = {this.state.FilterDatas.PayMethods?.length >= 2 ? false : true} checked={this.state.FilterDatas.combined} onChange={(e) => { this.handleCheckBox(e, "combined") }} size='small' />} label={t("Combined")} /> : null}
                                
                            </Box>
                        </Box> : null
                }

                {
                    ((this.props.PageName === "CreditBills")) ?
                        <Box component={"div"}>
                            <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"} >Credit Type</Typography>
                            </Box>
                            <Box className="eMed_CheckBox_Group">
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CorporateCredit} onChange={(e) => { this.handleCheckBox(e, "CorporateCredit") }} size='small' />} label={t("Corporate")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.Insurance} onChange={(e) => { this.handleCheckBox(e, "Insurance") }} size='small' />} label={t("Insurance")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.PatientCredit} onChange={(e) => { this.handleCheckBox(e, "PatientCredit") }} size='small' />} label={t("Patient")} />
                            </Box>
                        </Box> : null
                }
                {this.props.Pharmacy && this.props.PageName === 'OverallBills' ?
                    <Box component={'div'}>
                        <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"} >Bill Type</Typography>
                            </Box>
                        <Box className="eMed_CheckBox_Group">
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.credit} onChange={(e) => { this.handleCheckBox(e, "credit") }} size='small' />} label={t("Credit")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.completed} onChange={(e) => { this.handleCheckBox(e, "completed") }} size='small' />} label={t("Completed")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.return} onChange={(e) => { this.handleCheckBox(e, "return") }} size='small' />} label={t("Return")} />
                        </Box>
                    </Box> : null
                }
               </Box>
                <Box component={"div"} className="eMed_Filter_Btn_Div">
                    <Button size="small" className='eMed_Filter_Btns' emed_tid={`${this.props.PageName}_Filter_Clear_Btn`} disabled={this.state.disableBtn} onClick={() => { this.ClearFilterData() }} variant='outlined'>{t("Clear")}</Button>
                    <Button size="small" className='eMed_Filter_Btns' emed_tid={`${this.props.PageName}_Filter_Clear_Btn`} disabled={this.state.disableBtn ? true : ((this.state.FilterDatas?.startTime !== null && this.state.FilterDatas?.endTime === null) || (this.state.FilterDatas?.startTime !== null && this.state.error)) ? true : this.disableSearchBtn()} onClick={() => { this.FilterSearch() }} variant='contained'>{t("Search")}</Button>
                    {this.props.PageName === "DayEndStatus" ?
                        <Button size="small" className='eMed_Filter_Btns' onClick={() => { this.PrintDetails() }} variant='contained'>{t("Print")}</Button>
                        : null}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

            </Box>
        )
    }
}

export default withTranslation()(BillingsFilter)



import { Autocomplete, Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, Select, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { localGetItem } from '../../../../Utility/Services/CacheProviderService'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'

class AddandEditSubLocation extends Component {

  constructor(props) {
    super(props)
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    this.state = {
      selectType: UserData?.pharmacy_type === 'Inhouse Pharmacy' ? 1 : 2,
      subLocationName: '',
      disAccess: 'dis_no',
      billAccess: 'bill_no',
      selectStatus: true,
      editSubLoction: this.props.subLocation,
      selectedId: null,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      WardList: [],
      selectWard: null
    }
  }

  componentDidMount() {
    if (this.state.editSubLoction != {} && this.props.isEdit) {
      var states = this.state
      this.setState({
        selectType: states.editSubLoction.sublocation_type === 'Ward' ? 1 : 2,
        subLocationName: states.editSubLoction.sublocation_name,
        selectWard: states.editSubLoction.sublocation_name,
        billAccess: states.editSubLoction.is_allow_bill === true ? 'bill_yes' : 'bill_no',
        disAccess: states.editSubLoction.is_allow_discount === true ? 'dis_yes' : 'dis_no',
        selectStatus: states.editSubLoction.is_active,
        selectedId: states.editSubLoction.id
      })
    }
    this.getWardList()
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getWardList = () => {
    try {
      RestAPIService.getAll(Serviceurls.WARD_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              WardList: response.data.data
            })
          }
          else {
            this.props.errorMsg(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.props.errorMsg(error.response.data.message)
          } else {
            this.props.errorMsg(error.message)
          }
        })
    }
    catch (error) {
      this.props.errorMsg(error.message)
    }
  }

  postSublocation = () => {
    try {
      var subName = this.state.selectWard
      var states = this.state
      let data = {
        "sublocation_name": this.state.selectWard ? (typeof(this.state.selectWard) === 'string' ? this.state.selectWard :  subName.ward_name) : this.state.subLocationName ? this.state.subLocationName : '-',
        "sublocation_type": states.selectType,
        "is_allow_bill": this.state.billAccess === 'bill_yes' ? true : this.state.billAccess === 'bill_no' ? false : false,
        "is_allow_discount": this.state.disAccess === 'dis_yes' ? true : this.state.disAccess === 'dis_no' ? false : false,
        "is_active": this.state.selectStatus
      }
      if (states.selectedId){
        data['id'] = states.selectedId
      }
      this.setState({disableBtn: true})
      RestAPIService.create(data, Serviceurls.PHARMA_SUB_LOCATION).
        then((response) => {
          if (response.data.status === "success") {
            this.props.addPopupClose()
            this.props.successMsg(response.data.message)
            this.setState({disableBtn: false})
          }
          else {
            this.props.errorMsg(response.data.message)
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.props.errorMsg(error.response.data.message)
          } else {
            this.props.errorMsg(error.message)
          }
          this.setState({disableBtn: false})
        })
    }
    catch (error) {
      this.props.errorMsg(error.message)
      this.setState({disableBtn: false})
    }
  }

  closePopup = () => {
    this.props.addPopupClose()
  }

  onCreateSubloc = () => {
    try {
      if (this.state.selectType === '') {
        this.props.errorMsg('Please Select Type')
      } else if (this.state.selectType === 1 && this.state.selectWard === '') {
        this.props.errorMsg('Please Select Ward')
      } else if (this.state.selectType === 2 && this.state.subLocationName === '') {
        this.props.errorMsg('Please Enter the Sub-Location Name')
      } else {
        this.postSublocation()
      }
    } catch (e) {
      this.props.errorMsg(e.message)
    }
  }
  render() {
    const { t } = this.props
    return (
      <Box className='eMed_sublocation_add'>
        <Box className='eMed_subloc_con'>
          <Typography fontSize={'1vw'}>{this.props.isEdit ? t('EditSubLoc') : t('AddSubLoc')}</Typography>
          <IconButton onClick={() => { this.closePopup() }}>
            <img className='stock_edit_img_close' src={ImagePaths.LabCloseButton.default} alt='close' />
          </IconButton>
        </Box>
        <Box className='eMed_subloc_con'>
          <FormControl size='small' sx={{ width: '13vw' }}>
            <InputLabel id="sub-loc-type">{t('Type')}</InputLabel>
            <Select
              disabled
              labelId="sub-loc-type"
              id="type-sub-location"
              value={this.state.selectType}
              label={t('Type')}
              onChange={(event) => {
                this.setState({
                  selectType: event.target.value
                })
              }}
            >
              <MenuItem value={1}>Ward</MenuItem>
              <MenuItem value={2}>Outlet</MenuItem>
            </Select>
          </FormControl>
          {this.state.selectType === 1 ?
            <Autocomplete
              size='small'
              sx={{ width: '17vw' }}
              options={this.state.WardList}
              getOptionLabel={(item) => (typeof (item) === "string" ? item : item.ward_name)}
              value={this.state.selectWard}
              onChange={(event, newValue) => {
                this.setState({
                  selectWard: newValue
                })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Ward')}
                />
              )}
            /> : <TextField
              id="sun-location"
              size='small'
              label={t('SubName')}
              name={'subLocationName'}
              variant="outlined"
              sx={{ width: '17vw' }}
              value={this.state.subLocationName}
              onChange={(e) => {
                let value = e.target.value
                let alphanum = CommonValidation.ALPHA_NUMARIC(value)
                if (alphanum || value === '') {
                  this.setState({
                    subLocationName: value
                  })
                } else {
                  this.setState({
                    subLocationName: value
                  })
                }
              }} />
          }
        </Box>
        <Box className='eMed_subloc_conSub'>
          <Box>
            <Typography fontSize={'0.8vw'}>{t('PatientBill')}</Typography>
            <Box className='eMed_SubLoc_patBill'>
              <FormControlLabel control={<Radio size='small'
                value={"bill_yes"}
                checked={this.state.billAccess === "bill_yes"}
                onChange={(event) => {
                  this.setState({
                    billAccess: event.target.value,
                  })
                }} />} sx={{ marginLeft: '0.3vw' }} label={t('Yes')} />
              <Box className='eMed_SubLoc_VRLine'></Box>
              <FormControlLabel control={<Radio size='small'
                value={"bill_no"}
                checked={this.state.billAccess === "bill_no"}
                onChange={(event) => {
                  this.setState({
                    billAccess: event.target.value,
                  })
                }} />} label={t('No')} />
            </Box>
          </Box>
          <Box>
            <Typography fontSize={'0.8vw'}>{t('DisAllow')}</Typography>
            <Box className='eMed_SubLoc_patBill'>
              <FormControlLabel control={<Radio size='small'
                value={"dis_yes"}
                checked={this.state.disAccess === "dis_yes"}
                onChange={(event) => {
                  this.setState({
                    disAccess: event.target.value,
                  })
                }} />} sx={{ marginLeft: '0.3vw' }} label={t('Yes')} />
              <Box className='eMed_SubLoc_VRLine'></Box>
              <FormControlLabel control={<Radio size='small'
                value={"dis_no"}
                checked={this.state.disAccess === "dis_no"}
                onChange={(event) => {
                  this.setState({
                    disAccess: event.target.value,
                  })
                }} />} label={t('No')} />
            </Box>
          </Box>
        </Box>
        {this.props.isEdit ?
          <Box className='eMed_subloc_con'>
            <FormControl size='small' sx={{ width: '15vw' }}>
              <InputLabel id="sub-loc-status">{t('status')}</InputLabel>
              <Select
                labelId="sub-loc-status"
                id="status-sub-location"
                value={this.state.selectStatus}
                label={t('status')}
                onChange={(event) => {
                  this.setState({
                    selectStatus: event.target.value
                  })
                }}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box> : null}
        <Box margin={'1vw'} marginTop={'4vw'}>
          <Stack spacing={2} direction="row" display={'flex'} justifyContent={'flex-end'}>
            <Button sx={{ textTransform: 'capitalize' }} variant="outlined" onClick={() => { this.closePopup() }}>{t('Cancel')}</Button>
            <Button sx={{ textTransform: 'capitalize' }} variant="contained" emed_tid='SubLocation_Edit_Create_Btn' disabled={this.state.disableBtn} onClick={() => { this.onCreateSubloc() }}>{t('Create')}</Button>
          </Stack>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(AddandEditSubLocation)
import { Autocomplete, Box, Button, Chip, FormHelperText, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { AddCircle } from '@material-ui/icons'
import { Afternoon_Meal_type, FoodType, MealType, Morning_Meal_type, Night_Meal_Type, UnitList } from '../../../../Utility/Constants'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DateTime } from 'luxon'
import { timeOnlyConvert, ConvertTimeToDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { ImagePaths } from '../../../../Utility/ImagePaths'
export default class DocNutritionChart extends Component {

  constructor(props) {
    super(props)
    this.state = {
      TemplateList: [],
      selectedTemplateName: null,
      mealType: MealType,
      foodType: FoodType,
      unitList: UnitList,
      morningMealType: Morning_Meal_type,
      afterMealType: Afternoon_Meal_type,
      nightMealType: Night_Meal_Type,
      dupMealType: MealType,

      planName: '',
      ageFrom: '',
      ageTo: '',
      patientType: '',
      selectedDay: '',
      selectedMealType: null,
      mealDaysData: [{
        list: [
          { session: "morning", times: [] },
          { session: "afternoon", times: [] },
          { session: "night", times: [] }
        ]
      }],
      selectedMealTime: null,
      selectedIndex: null,
      foodProducts: '',
      foodQuantity: '',
      selectedFoodType: FoodType[0]?.value,
      selectedFoodUnit: UnitList[0]?.value,
      mealTime: '',
      nutritionChartData: [],
      duplicateNutritionChart: [],
      selectedMealDay: '',
      selectedMealListData: [],
      getLoad: false,
      loading: false,
      clkOpen: false,
      selectedTemplateId: null,
      planNameList: [],
      pointToRemember: '',
      alwaysAvoid: '',
      mealDay: '',
      mealListData: [],

      selectedTimeIndex: null,
      selectedListIndex: null,
      selectedIndex: null,
      enableFoodLiquid: false,
      selectedPlanIndex: null,
      selectedPlanListIndex: null,
      selectedPlanTimeIndex: null,
      selectedPlanPlanIndex: null,
      isPlanEdit: false,
      createMealPlan: false,
      selectedId: null,
      openSave: false
    }
  }

  componentDidMount() {
    this.getNutritionChartData()
  }

  getNutritionChartData = () => {
    try {
      this.setState({ getLoad: true })
      RestAPIService.getAll(Serviceurls.DOC_NUTRITION_CHART)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              nutritionChartData: response.data.data,
              duplicateNutritionChart: response?.data?.data,
              selectedTemplateName: response?.data?.data[0]?.data,
              selectedTemplateId: response?.data?.data[0]?.id,
              getLoad: false
            }, () => {
              let planNameList = []
              this.state.nutritionChartData?.forEach(element => {
                element.data.id = element.id
                planNameList?.push(element?.data?.planName)
              })
              this.setState({
                selectedMealDay: this.state.selectedTemplateName?.Days[0]?.day_count,
                selectedMealListData: this.state.selectedTemplateName?.Days[0]?.list,
                nutritionChartData: this.state.nutritionChartData,
                planNameList: planNameList
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ getLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ getLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ getLoad: false })
      this.errorMessage(error.message)
    }
  }

  postCheck = () => {
    try {
      let message = null;
      let states = this.state
      if (states.planName === '') {
        message = 'Enter Plan Name'
      } else {
        let mealData = states.mealDaysData
        outerloop: for (let i = 0; i < mealData?.length; i++) {
          for (let j = 0; j < mealData[i]?.list?.length; j++) {
            if (mealData[i]?.list[j]?.times?.length > 0) {
              for (let k = 0; k < mealData[i]?.list[j]?.times?.length; k++) {
                if (mealData[i]?.list[j]?.times[k]?.plan_List?.length !== 0) {
                  message = null
                }
                else {
                  message = `Meal Plan List is empty for ${mealData[i]?.day_count} -- ${mealData[i]?.list[j]?.times[k]?.time}`
                  break outerloop;  // Exits From Entire Loop
                }
              }
            }
          }
        }
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postNutritionChartData = () => {
    try {
      let data = {
        AlwaysAvoid: this.state.alwaysAvoid,
        patientType: this.state.patientType,
        planAgeFrom: this.state.ageFrom,
        planAgeTo: this.state.ageTo,
        planName: this.state.planName,
        pointToRemember: this.state.pointToRemember,
        Days: this.state.mealDaysData,
        id: this.state.selectedId ? this.state.selectedId : null
      }
      let check = this.postCheck()
      if (check === null) {
        this.setState({ loading: true })
        RestAPIService.create(data, Serviceurls.DOC_NUTRITION_CHART)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response.data.message)
              this.onClearMealPlanData()
              this.getNutritionChartData()
              this.setState({ loading: false, createMealPlan: false, selectedId: null, openSave: false })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ loading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ loading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(check)
      }
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  printPatientNutritionChart = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_NUTRITION_CHART_PRINT + `?export=pdf&id=${this.state.selectedTemplateId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onEditHandler = () => {
    try {
      this.setState({
        createMealPlan: true,
        planName: this.state.selectedTemplateName?.planName,
        ageFrom: this.state.selectedTemplateName?.planAgeFrom,
        ageTo: this.state.selectedTemplateName?.planAgeTo,
        patientType: this.state.selectedTemplateName?.patientType,
        pointToRemember: this.state.selectedTemplateName?.pointToRemember,
        alwaysAvoid: this.state.selectedTemplateName?.AlwaysAvoid,
        mealDaysData: this.state.selectedTemplateName?.Days,
        selectedDay: 'Day 1',
        selectedId: this.state.selectedTemplateName?.id,
        mealDay: 'Day 1',
        openSave: true
      }, () => {
        let selectMealTime = ''
        this.state.mealDaysData?.forEach(element => {
          if (element?.day_count === this.state.selectedDay) {
            if ((element?.list[0]?.times[0]?.time) || (element?.list[1]?.times[0]?.time) || (element?.list[2]?.times[0]?.time)) {
              selectMealTime = element?.list[0]?.times[0]?.time ? element?.list[0]?.times[0]?.time :
                element?.list[1]?.times[0]?.time ? element?.list[1]?.times[0]?.time : element?.list[2]?.times[0]?.time
            }
          }
        })
        this.setState({
          mealTime: selectMealTime,
        })
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      let states = this.state
      return (
        <Box component={'div'} height={'80vh'} p={'0.5vw'}>
          <Box component={'div'} border={'1px solid lightgray'}>
            {states.createMealPlan ?
              <Box component={'div'} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                <Box component={'div'} display={'flex'} alignItems={'center'}>
                  <Button className='Common_Btn_Min_Width'
                    onClick={() => {
                      let meal = [{
                        list: [
                          { session: "morning", times: [] },
                          { session: "afternoon", times: [] },
                          { session: "night", times: [] }
                        ]
                      }]
                      this.setState({
                        createMealPlan: false,
                        mealDaysData: meal,
                        planName: '', ageFrom: '',
                        ageTo: '', patientType: '',
                        selectedDay: 'Day 1', selectedMealTime: null, selectedMealType: '',
                        mealTime: '', foodProducts: '', foodQuantity: '',
                        pointToRemember: '', alwaysAvoid: ''
                      }, () => this.getNutritionChartData())
                    }}
                  >
                    <Box component={'img'} src={ImagePaths.LabBackButton.default} height={'1.2vw'} width={'1.2vw'} mr={'1vw'} />
                  </Button>
                  <Typography fontSize={'0.9vw'} fontWeight={600}>Meal Plan Preview</Typography>
                </Box>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Template Name : <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600} mr={'1vw'}>{this.state.planName}</Typography></Typography>
              </Box> :
              <Box component={'div'} height={'3.3vw'} display={'flex'} justifyContent={'flex-end'} pl={'0.5vw'} alignItems={'center'}>
                <Autocomplete
                  clearIcon
                  size='small'
                  sx={{ width: '20vw', mr: '0.5vw', mt: '0.5vw', backgroundColor: Colors.white }}
                  options={this.state.nutritionChartData?.map((item) => item?.data)}
                  getOptionLabel={(option) => option?.planName}
                  value={this.state.selectedTemplateName}
                  limitTags={3}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({
                      selectedTemplateName: newValue
                    }, () => this.setState({
                      selectedTemplateId: this.state.selectedTemplateName?.id,
                      selectedMealDay: this.state.selectedTemplateName?.Days[0]?.day_count,
                      selectedMealListData: this.state.selectedTemplateName?.Days[0]?.list
                    }))
                  }}
                  renderInput={(params) => <TextField {...params} label={'Select Template'} />}
                />
                <Button variant='contained'
                  sx={{ width: '5vw', height: '2.5vw', textTransform: 'capitalize', mr: '0.5vw', mt: '0.5vw' }}
                  onClick={() => this.printPatientNutritionChart()}
                  disabled={this.state.nutritionChartData?.length > 0 ? false : true}
                >Print</Button>
                <Button
                  className='Common_Btn_Min_Width'
                  disabled={this.state.nutritionChartData?.length > 0 ? false : true}
                  onClick={() => this.onEditHandler()}
                >
                  <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Box>
            }
            <Box component={'div'} mt={'0.5vw'} display={'flex'} height={'70vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
              <Box component={'div'} flex={2}>
                <Box component={'div'} p={'0.5vw'} textAlign={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                  <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Meal Plan</Typography>
                </Box>
                {states.createMealPlan ?
                  <Box component={'div'} maxHeight={"64vh"} overflow={'scroll'}>
                    {states.mealDaysData?.map((item, index) => {
                      return (
                        <Button className='Common_Btn_Min_Width'
                          key={index}
                          variant={((this.state.mealDay === item?.day_count)) ? 'contained' : 'outlined'}
                          sx={{ width: '10vw', height: '4vw', display: 'flex', justifyContent: 'center', m: '0.5vw' }}
                        >{item?.day_count}
                        </Button>
                      )
                    })}
                  </Box> :
                  <Box component={'div'} maxHeight={"64vh"} overflow={'scroll'}>
                    {states.selectedTemplateName?.Days?.map((item, index) => {
                      return (
                        <Button className='Common_Btn_Min_Width'
                          key={index}
                          variant={((this.state.selectedMealDay === item?.day_count)) ? 'contained' : 'outlined'}
                          sx={{ width: '10vw', height: '4vw', display: 'flex', justifyContent: 'center', m: '0.5vw' }}
                          onClick={() => {
                            this.setState({
                              selectedMealDay: item?.day_count,
                              selectedMealListData: item?.list,
                            })
                          }}
                        >{item?.day_count}
                        </Button>
                      )
                    })}
                  </Box>
                }

              </Box>
              <Box component={'div'} flex={8} borderLeft={'1px solid lightgray'}>
                <Box component={'div'} display={'flex'} p={'0.5vw'} justifyContent={'space-around'} alignItems={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                  <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Morning</Typography>
                  <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Afternoon</Typography>
                  <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Night</Typography>
                </Box>
                <Box display={'flex'} component={'div'} maxHeight={'62.5vh'} overflow={'scroll'} p={'0.5vw'} justifyContent={'space-around'} mt={'0.5vw'} borderBottom={'1px solid lightgray'}>
                  {/* Morning Meal Data */}

                  <Box component={'div'}>
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              list?.session === 'morning' ?
                                <>
                                  {list?.times[0]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[0]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[0]?.plan_List?.map((plan) => (
                                    <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                      <Box flex={0.2} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={plan?.type}>
                                          <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                        <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                      </Box>
                                    </Box></>
                                  ))}</> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          item?.session === 'morning' ?
                            <>
                              {item?.times[0]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[0]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[0]?.plan_List?.map((plan) => (
                                <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                  <Box flex={0.2} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={plan?.type}>
                                      <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                    <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                  </Box>
                                </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              list?.session === 'morning' ?
                                <>
                                  {list?.times[1]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[1]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[1]?.plan_List?.map((plan) => (
                                    <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                      <Box flex={0.2} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={plan?.type}>
                                          <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                        <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                      </Box>
                                    </Box>
                                    </>
                                  ))}</> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          item?.session === 'morning' ?
                            <>
                              {item?.times[1]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[1]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[1]?.plan_List?.map((plan) => (
                                <>
                                  <Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                    <Box flex={0.2} mr={'0.5vw'}>
                                      <Tooltip placement='top' title={plan?.type}>
                                        <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                      </Tooltip>
                                    </Box>
                                    <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                      <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              list?.session === 'morning' ?
                                <>
                                  {list?.times[2]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[2]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[2]?.plan_List?.map((plan) => (
                                    <>
                                      <Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                        <Box flex={0.2} mr={'0.5vw'}>
                                          <Tooltip placement='top' title={plan?.type}>
                                            <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                          </Tooltip>
                                        </Box>
                                        <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                          <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                          <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                          <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                        </Box>
                                      </Box>
                                    </>
                                  ))}</> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          item?.session === 'morning' ?
                            <>
                              {item?.times[2]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[2]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[2]?.plan_List?.map((plan) => (
                                <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                  <Box flex={0.2} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={plan?.type}>
                                      <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                    <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                  </Box>
                                </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                  </Box>

                  {/* Afternoon Meal Data */}
                  <Box component={'div'}>
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item, index) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              ((list?.session === 'afternoon') || (list?.session === 'afernoon')) ?
                                <Box key={index}>
                                  {list?.times[0]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[0]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[0]?.plan_List?.map((plan) => (
                                    <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                      <Box flex={0.2} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={plan?.type}>
                                          <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                        <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                      </Box>
                                    </Box>
                                    </>
                                  ))}</Box> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item, index) => (
                          ((item?.session === 'afernoon') || (item?.session === 'afternoon')) ?
                            <Box key={index}>
                              {item?.times[0]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[0]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[0]?.plan_List?.map((plan, planIndex) => (
                                <>
                                  <Box display={'flex'} key={planIndex} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                    <Box flex={0.2} mr={'0.5vw'}>
                                      <Tooltip placement='top' title={plan?.type}>
                                        <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                      </Tooltip>
                                    </Box>
                                    <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                      <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              ))}</Box>
                            : null
                        ))}
                      </Box>
                    }
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item, index) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              ((list?.session === 'afternoon') || (list?.session === 'afernoon')) ?
                                <Box key={index}>
                                  {list?.times[1]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[1]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[1]?.plan_List?.map((plan, planIndex) => (
                                    <>
                                      <Box display={'flex'} key={planIndex} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                        <Box flex={0.2} mr={'0.5vw'}>
                                          <Tooltip placement='top' title={plan?.type}>
                                            <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                          </Tooltip>
                                        </Box>
                                        <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                          <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                          <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                          <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                        </Box>
                                      </Box>
                                    </>
                                  ))}</Box> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          ((item?.session === 'afernoon') || (item?.session === 'afternoon')) ?
                            <>
                              {item?.times[1]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[1]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[1]?.plan_List?.map((plan) => (
                                <> <Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                  <Box flex={0.2} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={plan?.type}>
                                      <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                    <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                  </Box>
                                </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item, itemIndex) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              ((list?.session === 'afternoon') || (list?.session === 'afernoon')) ?
                                <Box key={itemIndex}>
                                  {list?.times[2]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[2]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[2]?.plan_List?.map((plan) => (
                                    <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                      <Box flex={0.2} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={plan?.type}>
                                          <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                        <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                      </Box>
                                    </Box>
                                    </>
                                  ))}</Box> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          ((item?.session === 'afernoon') || (item?.session === 'afternoon')) ?
                            <>
                              {item?.times[2]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[2]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[2]?.plan_List?.map((plan) => (
                                <>
                                  <Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                    <Box flex={0.2} mr={'0.5vw'}>
                                      <Tooltip placement='top' title={plan?.type}>
                                        <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                      </Tooltip>
                                    </Box>
                                    <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                      <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                  </Box>

                  {/* Night Meal Data */}
                  <Box component={'div'}>
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              list?.session === 'night' ?
                                <>
                                  {list?.times[0]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[0]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[0]?.plan_List?.map((plan) => (
                                    <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                      <Box flex={0.2} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={plan?.type}>
                                          <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                        <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                      </Box>
                                    </Box>
                                    </>
                                  ))}</> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          ((item?.session === 'night')) ?
                            <>
                              {item?.times[0]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[0]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[0]?.plan_List?.map((plan) => (
                                <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                  <Box flex={0.2} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={plan?.type}>
                                      <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                    <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                    <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                  </Box>
                                </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              list?.session === 'night' ?
                                <>
                                  {list?.times[1]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[1]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[1]?.plan_List?.map((plan) => (
                                    <>
                                      <Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                        <Box flex={0.2} mr={'0.5vw'}>
                                          <Tooltip placement='top' title={plan?.type}>
                                            <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                          </Tooltip>
                                        </Box>
                                        <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                          <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                          <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                          <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                        </Box>
                                      </Box>
                                    </>
                                  ))}</> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          ((item?.session === 'night')) ?
                            <>
                              {item?.times[1]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[1]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[1]?.plan_List?.map((plan) => (
                                <>
                                  <Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                    <Box flex={0.2} mr={'0.5vw'}>
                                      <Tooltip placement='top' title={plan?.type}>
                                        <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                      </Tooltip>
                                    </Box>
                                    <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                      <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                    {states.createMealPlan ?
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {states.mealDaysData?.map((item) => (
                          item?.day_count === this.state.selectedDay ?
                            item?.list?.map((list) => (
                              list?.session === 'night' ?
                                <>
                                  {list?.times[2]?.plan_List?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                      <Box component={'div'} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={'Time'}>
                                          <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Typography fontSize={'0.8vw'} fontWeight={600}>{list?.times[2]?.time}</Typography>
                                    </Box> : null
                                  }
                                  {list?.times[2]?.plan_List?.map((plan) => (
                                    <><Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                      <Box flex={0.2} mr={'0.5vw'}>
                                        <Tooltip placement='top' title={plan?.type}>
                                          <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                        </Tooltip>
                                      </Box>
                                      <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                        <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                        <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                      </Box>
                                    </Box>
                                    </>
                                  ))}</> : null)) : null))
                        }
                      </Box> :
                      <Box border={'1px solid lightgray'} mb={'0.5vw'} boxShadow={'1px 1px 2px lightgray'} borderRadius={'10px'} width={'12vw'} height={'10vw'} overflow={'auto'}>
                        {this.state.selectedMealListData?.map((item) => (
                          ((item?.session === 'night')) ?
                            <>
                              {item?.times[2]?.plan_List?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                  <Box component={'div'} mr={'0.5vw'}>
                                    <Tooltip placement='top' title={'Time'}>
                                      <Box component={'img'} src={ImagePaths.MealTime.default} height={'1vw'} width={'1vw'} />
                                    </Tooltip>
                                  </Box>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.times[2]?.time}</Typography>
                                </Box> : null
                              }
                              {item?.times[2]?.plan_List?.map((plan) => (
                                <>
                                  <Box display={'flex'} p={'0.5vw 0.5vw 0vw 0.5vw'}>
                                    <Box flex={0.2} mr={'0.5vw'}>
                                      <Tooltip placement='top' title={plan?.type}>
                                        <Box component={'img'} src={plan?.type === 'Food' ? ImagePaths.MealFood.default : ImagePaths.MealJuice.default} height={'1vw'} width={'1vw'} />
                                      </Tooltip>
                                    </Box>
                                    <Box flex={0.8} display={'flex'} justifyContent={'space-between'}>
                                      <Typography fontSize={'0.8vw'}>{plan?.product}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.quantity}</Typography>
                                      <Typography fontSize={'0.8vw'}>{plan?.unit}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              ))}</>
                            : null
                        ))}
                      </Box>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      switch (names) {
        case 'planName':
          this.setState({ planName: value }, () => this.setState({ selectedDay: 'Day 1', mealDay: 'Day 1' }))
          break;
        case 'ageFrom':
          this.setState({ ageFrom: value })
          break;
        case 'ageTo':
          this.setState({ ageTo: value })
          break;
        case 'patientType':
          this.setState({ patientType: value })
          break;
        case 'foodProducts':
          this.setState({ foodProducts: value }, () => {
            if (this.state.foodProducts && this.state.foodQuantity) {
              this.setState({
                enableFoodLiquid: true
              })
            }
          })
          break;
        case 'foodQuantity':
          if ((CommonValidation.numberOnly(value) && +value < 999) || value === '') {
            this.setState({ foodQuantity: value }, () => {
              if (this.state.foodProducts && this.state.foodQuantity) {
                this.setState({
                  enableFoodLiquid: true
                })
              }
            })
          }
          break;
        case 'alwaysAvoid':
          if (value?.length <= 250 || value === '') {
            this.setState({ alwaysAvoid: value })
          } else {
            this.errorMessage('Allowed only 250 characters')
          }
          break;
        case 'pointToRemember':
          if (value?.length <= 250 || value === '') {
            this.setState({ pointToRemember: value })
          } else {
            this.errorMessage('Allowed only 250 characters')
          }
          break;
        default: break;
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, label, width, mr, mb, multiline, rows) => {
    try {
      return (
        <TextField
          size='small'
          sx={{ width: width, mr: mr, mb: mb }}
          label={label}
          multiline={multiline}
          rows={rows}
          name={names}
          autoComplete='off'
          value={value}
          onChange={(e) => this.onChangeHandler(e, names)}
          onBlur={() => {
            if (names === 'planName') {
              this.state.planNameList?.forEach(element => {
                if ((element).toLowerCase() === CommonValidation.removeSpace((this.state.planName).toLowerCase())) {
                  this.errorMessage('Plan name already exists')
                }
              })
            }
          }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  AddNutritionDays = () => {
    try {
      let validation = this.checkValidation()
      if (validation === null) {
        let duplicateDays = {
          list: [
            { session: 'morning', times: [] },
            { session: 'afternoon', times: [] },
            { session: 'night', times: [] }
          ]
        }
        if (this.state.planName === '') {
          this.errorMessage('Enter Plane Name')
        } else {
          this.state.mealDaysData.push(duplicateDays)
          this.setState({
            mealDaysData: this.state.mealDaysData
          }, () => {
            let states = this.state
            this.setState({
              selectedDay: states.mealDaysData[states.mealDaysData?.length - 1]?.day_count,
              mealDay: states.mealDaysData[states.mealDaysData?.length - 1]?.day_count
            })
          })
        }
      } else {
        this.errorMessage(validation)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      let states = this.state
      if (states.planName === '') {
        message = 'Enter Plan Name'
      } else if (states.selectedDay === '') {
        message = 'Select Day'
      } else if (states.selectedDay !== '' && states.planName !== '') {
        if ((this.state.mealDaysData[this.state.mealDaysData?.length - 1]?.list[0]?.times?.length != 0) || (this.state.mealDaysData[this.state.mealDaysData?.length - 1]?.list[1]?.times?.length != 0) || (this.state.mealDaysData[this.state.mealDaysData?.length - 1]?.list[2]?.times?.length != 0)) {
          message = null
        } else {
          message = `Please Select Values on Day ${this.state.mealDaysData.length}`
        }
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidationTwo = () => {
    try {
      let message = null;
      let states = this.state
      if (states.planName === '') {
        message = 'Select Plan Name'
      } else if (states.selectedDay === '') {
        message = 'Select Day'
      } else if (states.selectedMealTime === null) {
        message = 'Select Meal Time'
      } else if ((states.selectedMealType === null) || (states.selectedMealType === '')) {
        message = 'Select Meal Type'
      } else {
        let startDate = new Date(this.state.selectedMealTime)
        let select = this.state.selectedMealTime ? DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS) : ""
        let convert = timeOnlyConvert(select)
        let timeSplit = convert?.split(':')
        let time = timeSplit[0] <= 9 ? `0${convert?.slice(0, 1)}` : convert?.slice(0, 2)
        let ampm = convert?.split(' ')[1]

        states.mealDaysData?.forEach((element) => {
          if (element?.day_count === this.state.selectedDay) {
            element?.list?.forEach(list => {
              if (+time >= 5 && +time < 12 && ampm === 'AM') {
                if (states.isEdit) {
                  if ((list?.session === 'morning')) {
                    list?.times?.forEach((times, timeIndex) => {
                      if (states.selectedTimeIndex !== timeIndex) {
                        let time = timeSplit[0] <= 9 ? `0${convert}` : convert
                        if (times?.time === time) {
                          message = 'Time already exist'
                        }
                      }
                    })
                  }
                } else {
                  if ((list?.session === 'morning')) {
                    if (list?.times?.length >= 3) {
                      message = 'Max Time Slot allowed three'
                    } else {
                      list?.times?.forEach((times, timeIndex) => {
                        if (states.selectedTimeIndex !== timeIndex) {
                          let time = timeSplit[0] <= 9 ? `0${convert}` : convert
                          if (times?.time === time) {
                            message = 'Time already exist'
                          }
                        }
                      })
                    }
                  }
                }
              } else if (+time <= 4 && ampm === 'PM') {
                if (states.isEdit) {
                  if ((list?.session === 'afternoon') || (list?.session === 'afernoon')) {
                    list?.times?.forEach((times, timeIndex) => {
                      if (states.selectedTimeIndex !== timeIndex) {
                        let time = timeSplit[0] <= 9 ? `0${convert}` : convert
                        if (times?.time === time) {
                          message = 'Time already exist'
                        }
                      }
                    })
                  }
                } else {
                  if ((list?.session === 'afternoon') || (list?.session === 'afernoon')) {
                    if (list?.times?.length >= 3) {
                      message = 'Max Time Slot allowed three'
                    } else {
                      list?.times?.forEach((times) => {
                        let time = timeSplit[0] <= 9 ? `0${convert}` : convert
                        if (times?.time === time) {
                          message = 'Time already exist'
                        }
                      })
                    }
                  }
                }
              } else if (+time > 4 && +time <= 10 && ampm === 'PM') {
                if (states.isEdit) {
                  if ((list?.session === 'night')) {
                    list?.times?.forEach((times) => {
                      let time = timeSplit[0] <= 9 ? `0${convert}` : convert
                      if (times?.time === time) {
                        message = 'Time already exist'
                      }
                    })
                  }
                } else {
                  if ((list?.session === 'night')) {
                    if (list?.times?.length >= 3) {
                      message = 'Max Time Slot allowed three'
                    } else {
                      list?.times?.forEach((times) => {
                        let time = timeSplit[0] <= 9 ? `0${convert}` : convert
                        if (times?.time === time) {
                          message = 'Time already exist'
                        }
                      })
                    }
                  }
                }
              }
            })
          }
        })
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidationThree = () => {
    try {
      let message = null;
      let states = this.state
      if (states.planName === '') {
        message = 'Enter Plan Name'
      } else if (states.planName !== '') {
        if (states.mealTime === null) {
          message = 'Select MealTime'
        } else if (states.foodProducts === '' || states.foodQuantity === '') {
          message = 'Enter Products or Quantity'
        } else if (states.foodProducts !== '' && states.foodQuantity !== '') {
          let mealData = states.mealDaysData
          for (let i = 0; i <= mealData?.length; i++) {
            for (let j = 0; j <= mealData[i]?.list?.length; j++) {
              for (let k = 0; k <= mealData[i]?.list[j]?.times?.length; k++) {
                for (let l = 0; l <= mealData[i]?.list[j]?.times[k]?.plan_List?.length; l++) {
                  if (states.selectedPlanTimeIndex !== l) {
                    if ((mealData[i]?.list[j]?.times[k]?.plan_List[l]?.type === states.selectedFoodType) &&
                      (mealData[i]?.list[j]?.times[k]?.plan_List[l]?.unit === states.selectedFoodUnit) &&
                      (mealData[i]?.list[j]?.times[k]?.plan_List[l]?.quantity === states.foodQuantity) &&
                      (mealData[i]?.list[j]?.times[k]?.plan_List[l]?.product === states.foodProducts)) {
                      message = 'Already exist'
                    }
                  }
                }
              }
            }
          }
        }
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addMealTypeList = () => {
    try {
      let validation = this.checkValidationTwo()
      if (validation === null) {
        let states = this.state
        let startDate = new Date(this.state.selectedMealTime)
        let select = this.state.selectedMealTime ? DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS) : ""
        let convert = timeOnlyConvert(select)
        let timeSplit = convert?.split(':')
        let time = timeSplit[0] <= 9 ? `0${convert?.slice(0, 1)}` : convert?.slice(0, 2)
        let ampm = convert?.split(' ')[1]

        if (+time >= 5 && +time < 12 && ampm === 'AM') {
          if (this.state.isEdit) {
            if (states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['session'] === 'morning') {
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['time'] = timeSplit[0] <= 9 ? `0${convert}` : convert
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['meal_type'] = this.state.selectedMealType
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['plan_List'] = []
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['session'] = 'morning'
              this.setState({ states })
            } else {
              this.errorMessage('You can only edit in morning session')
            }
          } else {
            states.mealDaysData?.forEach(element => {
              if (element.day_count === this.state.selectedDay) {
                element.list?.forEach(list => {
                  if (list.session === 'morning') {
                    list.times?.push({
                      time: timeSplit[0] <= 9 ? `0${convert}` : convert,
                      meal_type: this.state.selectedMealType,
                      plan_List: []
                    })
                  }
                })
              }
            })
          }
        } else if (+time <= 4 && ampm === 'PM') {
          if (states.isEdit) {
            if ((states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['session'] === 'afternoon') || states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['session'] === 'afernoon') {
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['time'] = timeSplit[0] <= 9 ? `0${convert}` : convert
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['meal_type'] = this.state.selectedMealType
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['plan_List'] = []
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['session'] = 'afternoon'
              this.setState({ states })
            } else {
              this.errorMessage('You can only edit in afternoon session')
            }
          } else {
            states.mealDaysData?.forEach(element => {
              if (element.day_count === this.state.selectedDay) {
                element.list?.forEach(list => {
                  if ((list.session === 'afternoon') || (list.session === 'afernoon')) {
                    list.times?.push({
                      time: timeSplit[0] <= 9 ? `0${convert}` : convert,
                      meal_type: this.state.selectedMealType,
                      plan_List: []
                    })
                  }
                })
              }
            })
          }
        } else if (+time > 4 && +time < 10 && ampm === 'PM') {
          if (states.isEdit) {
            if (states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['session'] === 'night') {
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['time'] = timeSplit[0] <= 9 ? `0${convert}` : convert
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['meal_type'] = this.state.selectedMealType
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['times'][states.selectedTimeIndex]['plan_List'] = []
              states.mealDaysData[this.state.selectedIndex]['list'][states.selectedListIndex]['session'] = 'night'
              this.setState({ states })
            } else {
              this.errorMessage('You can only edit in night session')
            }
          } else {
            states.mealDaysData?.forEach(element => {
              if (element.day_count === this.state.selectedDay) {
                element.list?.forEach(list => {
                  if (list.session === 'night') {
                    list.times?.push({
                      time: timeSplit[0] <= 9 ? `0${convert}` : convert,
                      meal_type: this.state.selectedMealType,
                      plan_List: []
                    })
                  }
                })
              }
            })
          }
        } else {
          this.errorMessage('Invalid Time - Choose Time Between 5.00 AM - 10.00 PM')
        }
        this.setState({ states, enableFoodLiquid: true }, () => {
          let selectMealTime = ''
          states.mealDaysData?.forEach(element => {
            if (element?.day_count === this.state.selectedDay) {
              if ((element?.list[0]?.times[0]?.time) || (element?.list[1]?.times[0]?.time) || (element?.list[2]?.times[0]?.time)) {
                selectMealTime = element?.list[0]?.times[0]?.time ? element?.list[0]?.times[0]?.time :
                  element?.list[1]?.times[0]?.time ? element?.list[1]?.times[0]?.time : element?.list[2]?.times[0]?.time
              }
            }
          })
          this.setState({
            mealTime: selectMealTime
          })
          this.onClearMealData()
        })
      } else {
        this.errorMessage(validation)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addFoodProductListData = () => {
    try {
      let validation = this.checkValidationThree()
      if (validation === null) {
        let states = this.state
        if (states.isPlanEdit) {
          states.mealDaysData[states.selectedPlanIndex]['list'][states.selectedPlanListIndex]['times'][states.selectedPlanTimeIndex]['plan_List'][states.selectedPlanPlanIndex]['type'] = this.state.selectedFoodType ? this.state.selectedFoodType : this.state.selectedFoodType?.value
          states.mealDaysData[states.selectedPlanIndex]['list'][states.selectedPlanListIndex]['times'][states.selectedPlanTimeIndex]['plan_List'][states.selectedPlanPlanIndex]['unit'] = this.state.selectedFoodUnit ? this.state.selectedFoodUnit : this.state.selectedFoodUnit?.value
          states.mealDaysData[states.selectedPlanIndex]['list'][states.selectedPlanListIndex]['times'][states.selectedPlanTimeIndex]['plan_List'][states.selectedPlanPlanIndex]['product'] = this.state.foodProducts
          states.mealDaysData[states.selectedPlanIndex]['list'][states.selectedPlanListIndex]['times'][states.selectedPlanTimeIndex]['plan_List'][states.selectedPlanPlanIndex]['quantity'] = this.state.foodQuantity
        } else {
          states.mealDaysData?.forEach(element => {
            if (element?.day_count === this.state.selectedDay) {
              element?.list?.forEach(list => {
                if (list?.session === 'morning') {
                  list?.times?.forEach(time => {
                    if (time?.time === this.state.mealTime) {
                      time.plan_List?.push({
                        product: this.state.foodProducts,
                        quantity: this.state.foodQuantity,
                        unit: this.state.selectedFoodUnit ? this.state.selectedFoodUnit : this.state.selectedFoodUnit?.value,
                        type: this.state.selectedFoodType ? this.state.selectedFoodType : this.state.selectedFoodType?.value
                      })
                    }
                  })
                } else if ((list?.session === 'afternoon') || (list?.session === 'afernoon')) {
                  list?.times?.forEach(time => {
                    if (time?.time === this.state.mealTime) {
                      time.plan_List?.push({
                        product: this.state.foodProducts,
                        quantity: this.state.foodQuantity,
                        unit: this.state.selectedFoodUnit ? this.state.selectedFoodUnit : this.state.selectedFoodUnit?.value,
                        type: this.state.selectedFoodType ? this.state.selectedFoodType : this.state.selectedFoodType?.value
                      })
                    }
                  })
                } else {
                  list?.times?.forEach(time => {
                    if (time?.time === this.state.mealTime) {
                      time.plan_List?.push({
                        product: this.state.foodProducts,
                        quantity: this.state.foodQuantity,
                        unit: this.state.selectedFoodUnit ? this.state.selectedFoodUnit : this.state.selectedFoodUnit?.value,
                        type: this.state.selectedFoodType ? this.state.selectedFoodType : this.state.selectedFoodType?.value
                      })
                    }
                  })
                }
              })
            }
          })
        }
        this.setState({
          states
        }, () => {
          this.onClearFoodData()
        })
      } else {
        this.errorMessage(validation)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearMealData = () => {
    try {
      this.setState({
        selectedMealTime: null,
        selectedMealType: '',
        selectedIndex: null,
        selectedListIndex: null,
        selectedTimeIndex: null,
        isEdit: false,
        openSave: true
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearFoodData = () => {
    try {
      this.setState({
        foodProducts: '',
        foodQuantity: '',
        selectedPlanIndex: null,
        selectedPlanListIndex: null,
        selectedPlanTimeIndex: null,
        selectedPlanPlanIndex: null,
        isPlanEdit: false,
        enableFoodLiquid: false,
        selectedFoodType: 'Food',
        selectedFoodUnit: 'Nos'
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearMealPlanData = () => {
    try {
      this.setState({
        planName: '',
        ageFrom: '', ageTo: '',
        patientType: '',
        mealDaysData: [{
          list: [
            { session: "morning", times: [] },
            { session: "afternoon", times: [] },
            { session: "night", times: [] }
          ]
        }],
        selectedDay: '',
        alwaysAvoid: '',
        pointToRemember: '',
        selectedMealType: '', selectedMealTime: null,
        mealType: this.state.dupMealType
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  handleTimeClk = (key) => {
    this.setState({
      clkOpen: key
    })
  }

  renderRightSide = () => {
    try {
      this.state.mealDaysData?.forEach((element, index) => element.day_count = `Day ${index + 1}`)
      return (
        <Box component={'div'} height={'80vh'} p={'0.5vw'}>
          <Box border={'1px solid lightgray'}>
            <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Meal Plan Template Generator</Typography>
            </Box>
            {this.state.createMealPlan ?
              <Box component={'div'} height={'30vw'} sx={{ backgroundColor: Colors.white }} overflow={'auto'}>
                <Box component={'div'} pl={'0.5vw'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Template Name * / Category</Typography>
                  </Box>
                  <Box>
                    {this.renderTextBox('planName', this.state.planName, 'Enter Template Name *', '30vw')}
                  </Box>
                  <Box component={'div'} mt={'0.5vw'}>
                    {this.renderTextBox('ageFrom', this.state.ageFrom, 'Age From', '8.5vw', '0.5vw')}
                    {this.renderTextBox('ageTo', this.state.ageTo, 'Age To', '8.5vw', '0.5vw')}
                    {this.renderTextBox('patientType', this.state.patientType, 'Patient Type', '10vw',)}
                  </Box>
                </Box>
                <Box component={'div'} pl={'0.5vw'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Time and Meal Schedule *</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                    {this.state.mealDaysData?.length > 0 ? this.state.mealDaysData?.map((item, index) => (
                      <Box key={index}>
                        <Box>
                          <Chip
                            className={`eMed_chip ${(item.day_count === this.state.selectedDay) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                            label={item.day_count}
                            variant={((item.day_count === this.state.selectedDay)) ? 'contained' : 'outlined'}
                            key={index}
                            onClick={() => {
                              this.setState({
                                selectedDay: item?.day_count,
                                selectedIndex: index,
                                mealDay: item?.day_count
                              }, () => {
                                let states = this.state
                                let selectMealTime = ''
                                states.mealDaysData?.forEach(element => {
                                  if (element?.day_count === this.state.selectedDay) {
                                    if ((element?.list[0]?.times[0]?.time) || (element?.list[1]?.times[0]?.time) || (element?.list[2]?.times[0]?.time)) {
                                      selectMealTime = element?.list[0]?.times[0]?.time ? element?.list[0]?.times[0]?.time :
                                        element?.list[1]?.times[0]?.time ? element?.list[1]?.times[0]?.time : element?.list[2]?.times[0]?.time
                                    }
                                  }
                                })
                                this.setState({
                                  mealTime: selectMealTime
                                })
                              })
                            }}
                            // color={'primary'}
                            clickable
                          />
                        </Box>
                      </Box>
                    )) : null}
                    <Box display={'flex'} alignItems={'center'}>
                      <Button variant='contained'
                        size='small'
                        sx={{ ml: '0.5vw', height: '2vw', textTransform: 'capitalize' }}
                        onClick={() => this.AddNutritionDays()}
                        endIcon={<AddCircle />}>Add</Button>
                    </Box>
                  </Box>
                  <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        inputFormat='hh:mm A'
                        value={this.state.selectedMealTime}
                        autoComplete="off"
                        open={this.state.clkOpen}
                        onOpen={() => this.handleTimeClk(true)}
                        onClose={() => this.handleTimeClk(false)}
                        onChange={(value) => this.setState({ selectedMealTime: value }, () => {
                          let stateDate = new Date(this.state.selectedMealTime)
                          let time = DateTime.fromJSDate(stateDate).toFormat('hh:mm a')
                          let hours = time?.split(':')[0]
                          let ampm = time?.split(' ')[1]
                          if (+hours >= 5 && +hours < 12 && ampm === 'AM') {
                            this.setState({ mealType: this.state.morningMealType })
                          } else if (+hours <= 4 && ampm === 'PM') {
                            this.setState({ mealType: this.state.afterMealType })
                          } else if (+hours > 4 && +hours < 10 && ampm === 'PM') {
                            this.setState({ mealType: this.state.nightMealType })
                          } else {
                            this.setState({ mealType: this.state.dupMealType })
                          }
                        })}
                        renderInput={(props) => <TextField {...props} sx={{ width: '12vw' }} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)" }} size="small"
                          onKeyDown={(e) => e.preventDefault()} onClick={() => this.handleTimeClk(true)} />}

                      />
                    </LocalizationProvider>
                    <Autocomplete
                      clearIcon
                      size='small'
                      sx={{ width: '12vw', ml: '0.5vw' }}
                      options={this.state.mealType.map((item) => item?.value)}
                      value={this.state.selectedMealType}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          selectedMealType: newValue
                        })
                      }}
                      renderInput={(params) => <TextField {...params} label={'Select Meal Schedule *'} />}
                    />
                    <Button variant='contained'
                      size='small'
                      sx={{ ml: '0.5vw', width: '5vw', textTransform: 'capitalize' }}
                      onClick={() => this.addMealTypeList()}
                      endIcon={<AddCircle />}>Add</Button>
                  </Box>
                  <Box component={'div'} display={'flex'} mt={'1vw'} flexWrap={'wrap'}>
                    {this.state.mealDaysData?.map((item, index) => (
                      item?.day_count === this.state.selectedDay ?
                        item?.list?.map((list, listIndex) => (
                          list?.times?.map((time, timeIndex) => {
                            let formatedTime = time?.time;
                            let formattedDate = ConvertTimeToDate(formatedTime ? formatedTime : "")
                            return (
                              <Box border={'1px solid lightgray'} borderRadius={'10px'} ml={'0.5vw'} mb={'0.5vw'} boxShadow={'0px 0px 3px lightgray'} key={listIndex}>
                                <Chip
                                  className={`eMed_chip ${(time.time === this.state.mealTime) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                  label={time.time}
                                  size='small'
                                  variant={((time.time === this.state.mealTime)) ? 'contained' : 'outlined'}
                                  key={timeIndex}
                                  onClick={() => { this.setState({ mealTime: time?.time, enableFoodLiquid: true }) }}
                                  // color={'primary'}
                                  clickable
                                />

                                <Button className='Common_Btn_Min_Width'
                                  sx={{ width: '1.5vw', height: '1.5vw' }}
                                  onClick={() => {
                                    this.setState({
                                      selectedMealTime: formattedDate ? formattedDate : null,
                                      selectedMealType: time?.meal_type,
                                      editedTime: time?.time,
                                      selectedTimeIndex: timeIndex,
                                      selectedListIndex: listIndex,
                                      selectedIndex: index,
                                      isEdit: true
                                    })
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                                <Button className='Common_Btn_Min_Width'
                                  sx={{ width: '1.5vw', height: '1.5vw' }}
                                  onClick={() => {
                                    this.state.mealDaysData[index]['list'][listIndex]['times']?.splice(timeIndex, 1)
                                    this.setState({
                                      mealDaysData: this.state.mealDaysData
                                    })
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                                <FormHelperText sx={{ display: 'flex', justifyContent: 'center' }}>{list?.session}</FormHelperText>
                              </Box>
                            )
                          })
                        )) : null
                    ))}
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Food / Liquid Products and Quantity *</Typography>
                    </Box>
                    <Box component={'div'} display={'flex'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '5vw', mr: '0.5vw' }}
                        options={this.state.foodType.map((item) => item?.value)}
                        value={this.state.selectedFoodType}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedFoodType: newValue
                          })
                        }}
                        renderInput={(params) => <TextField {...params} label={'Select Food Type'} />}
                      />
                      {this.renderTextBox('foodProducts', this.state.foodProducts, 'Products', '8vw', '0.5vw')}
                      {this.renderTextBox('foodQuantity', this.state.foodQuantity, 'Qty', '5vw', '0.5vw')}
                      <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '5vw', ml: '0.5vw' }}
                        options={this.state.unitList.map((item) => item?.value)}
                        value={this.state.selectedFoodUnit}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            selectedFoodUnit: newValue
                          })
                        }}
                        renderInput={(params) => <TextField {...params} label={'Unit'} />}
                      />
                      <Button variant='contained'
                        sx={{ ml: '0.5vw', textTransform: 'capitalize' }}
                        disabled={this.state.enableFoodLiquid ? false : true}
                        onClick={() => {
                          this.addFoodProductListData()
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                    <Box component={'div'} mt={'1vw'} border={'1px solid lightgray'}>
                      <Table stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>
                              Type</TableCell>
                            <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>
                              Products</TableCell>
                            <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>
                              Quantity</TableCell>
                            <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>
                              Unit</TableCell>
                            <TableCell align='center' sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>
                              Action</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {this.state.mealDaysData?.length > 0 ? this.state.mealDaysData?.map((item, index) => (
                            item?.day_count === this.state.selectedDay ?
                              item?.list?.map((list, listIndex) => (
                                list?.times?.map((time, timeIndex) => (
                                  time?.time === this.state.mealTime ?
                                    time?.plan_List?.map((planList, planIndex) => (
                                      <TableRow key={planIndex}>
                                        <TableCell>{planList?.type}</TableCell>
                                        <TableCell>{planList?.product ? planList?.product?.length > 15 ?
                                          <Tooltip placement='top' title={planList?.product}><div>{planList?.product?.slice(0, 15) + '...'}</div></Tooltip> :
                                          planList?.product : '-'
                                        }</TableCell>
                                        <TableCell>{planList?.quantity}</TableCell>
                                        <TableCell>{planList?.unit}</TableCell>
                                        <TableCell align='center'>
                                          <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                            <Button className='Common_Btn_Min_Width'
                                              onClick={() => {
                                                this.setState({
                                                  foodProducts: planList?.product,
                                                  foodQuantity: planList?.quantity,
                                                  selectedFoodType: planList?.type,
                                                  selectedFoodUnit: planList?.unit,
                                                  selectedPlanIndex: index,
                                                  selectedPlanListIndex: listIndex,
                                                  selectedPlanTimeIndex: timeIndex,
                                                  selectedPlanPlanIndex: planIndex,
                                                  enableFoodLiquid: true,
                                                  isPlanEdit: true
                                                })
                                              }}
                                            >
                                              <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                            <Button className='Common_Btn_Min_Width'
                                              sx={{ width: '1.5vw', height: '1.5vw' }}
                                              onClick={() => {
                                                if (this.state.isPlanEdit) {
                                                  this.errorMessage('You clicked edit button.So cannot able to delete')
                                                } else {
                                                  this.state.mealDaysData[index]['list'][listIndex]['times'][timeIndex]['plan_List']?.splice(planIndex, 1)
                                                  this.setState({
                                                    mealDaysData: this.state.mealDaysData
                                                  })
                                                }
                                              }}
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                    )) : null
                                ))
                              )) : null
                          )) : null}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                  <Box component={'div'} mt={'1vw'}>
                    <Box component={'div'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>Points To Remember</Typography>
                      {this.renderTextBox('pointToRemember', this.state.pointToRemember, 'Points To Remember', '30vw', '0.5vw', '1vw', true, 3)}
                    </Box>
                    <Box component={'div'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} mb={'0.5vw'}>Always Avoid</Typography>
                      {this.renderTextBox('alwaysAvoid', this.state.alwaysAvoid, 'Always Avoid', '30vw', '0.5vw', '1vw', true, 3)}
                    </Box>
                  </Box>
                </Box>
              </Box> :
              <Box component={'div'} height={'30vw'} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ backgroundColor: Colors.white }} overflow={'auto'}>
                <Box component={'div'}>
                  <Button className='Common_Btn_Min_Width'
                    onClick={() => {
                      let meal = [{
                        list: [
                          { session: "morning", times: [] },
                          { session: "afternoon", times: [] },
                          { session: "night", times: [] }
                        ]
                      }]
                      this.setState({
                        createMealPlan: true,
                        mealDaysData: meal,
                        planName: '', ageFrom: '',
                        ageTo: '', patientType: '',
                        selectedDay: 'Day 1', selectedMealTime: null, selectedMealType: '',
                        mealTime: '', foodProducts: '', foodQuantity: '',
                        pointToRemember: '', alwaysAvoid: ''
                      })
                    }}
                  >
                    <Box component={'img'} src={ImagePaths.CreateMealPlan.default} height={'10vw'} width={'10vw'} />
                  </Button>
                </Box>
              </Box>
            }

            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
              <Button
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='outlined'
                onClick={() => this.onClearMealPlanData()}
              >Clear</Button>
              <Button
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                variant='contained'
                disabled={!this.state.openSave || this.state.loading}
                onClick={() => this.postNutritionChartData()}
              >Add</Button>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  render() {
    return (
      <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background }} height={'80vh'}>
        <Box flex={0.65}>
          {this.renderLeftSide()}
        </Box>
        <Box flex={0.35}>
          {this.renderRightSide()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

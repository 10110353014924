import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import Loader from '../../../../Components/Loader';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { Yes_Or_No, Birth_Control_List, GYN_History_List } from '../../../../Utility/Constants';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { DateTime } from 'luxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

class DocOBGYNHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientDetails: [],
            clinicId: "",
            appointmentId: "",
            patientId: "",

            yesorNoList: Yes_Or_No,
            Periods_regular: "No",
            Period_Starts: "",
            Periods_Last: "",
            Bleeding_Spotting: 'No',
            Bleeding_Spotting_Cmd: "",
            pain_associated: "No",
            current_treatment: 'No',
            current_treatment_cmd: "",
            MenstrualEdit: false,

            ObstetricalData: [],
            year: "",
            placeofDelivery: "",
            durationOfPregnancyInWeek: "",
            hoursOfDelivery: "",
            typeOfDelivery: "",
            complicationsForMotherOrBaby: "",
            gender: "",
            birthWeight: "",
            presentHealth: "",

            BirthControlList: Birth_Control_List,
            depressOnPreg: "No",
            PastBirthControl: [],
            Infertility_Therapy: "",
            birthControlBox: false,

            GYNHistoryList: GYN_History_List,
            yeast_infection: "No",
            diagnosed_fibroids: "No",
            overian_cyst: "No",
            Bacterial_vaginosis: "No",
            Bacterial_vaginosis_Cmd: "",
            Intercourse: "No",
            Intercourse_Cmd: "",
            urinary_problems: "No",
            urinary_problems_cmd: "",
            Menopause_Age: "",
            Hormone_Replacement: "",
            Other_History: [],
            GynaecologicalBox: false,

            open: false,
            openMammogram: false,
            LastPapSmearDate: null,
            Abnormal_papsmear: "No",
            Abnormal_papsmear_cmd: "",
            Cryotherapy: "No",
            Cryotherapy_cmd: "",
            Laser: "No",
            Laser_Cmd: "",
            Cone_Biopsy: "No",
            Cone_Biopsy_Cmd: "",
            Loop_Excision: "No",
            Loop_Excision_cmd: "",
            LastmammogramDate: null,
            Abnormal_Mammogram: "No",
            Abnormal_Mammogram_cmd: "",
            PapSmearEdit: false,

            surgeries: [
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Infertility-Surgery", surgery_id: 1 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Ovarian Surgery", surgery_id: 2 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Dilation & Curettage", surgery_id: 3 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Cystocele Repair", surgery_id: 4 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Tuboplasty", surgery_id: 5 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Pelvic Floor Repair", surgery_id: 6 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Tubal Ligation", surgery_id: 7 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Uterine Prolapse Repair", surgery_id: 8 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Laparoscopy", surgery_id: 9 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Hysteroscopy", surgery_id: 10 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Hysterectomy", surgery_id: 11 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Caesarean Section", surgery_id: 12 },
                { is_surgery_done: 'No', surgery_year: '', surgery_name: "Others", surgery_id: 13 },
            ],
            addtional_Surgeries: [],
            obgynSurgerieEdit: false,
            New_Surgery: "",
            isTextBoxOpen: false,
            NewSurgeryYear: null,
            Delete_Id: []
        };
        this.chidComponentIds = ['menstrual', 'obstetrical', 'birthControl', 'gynaecological', 'papSmearHistory', 'obgynSurgery']
    }

    componentDidMount() {
        let clinicId = getCachevalue('SelectedDoctorClinic')
        let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        this.setState({
            clinicId: clinicId,
            appointmentId: appointmentId?.appointment_id,
            patientId: appointmentId?.patient_id
        }, () => {
            this.getPatientDetailData()
        })
    }

    setupIntersectionObserver = () => {
        try {
            const option = {
                threshold: 0.25
            }
            this.observer = new IntersectionObserver(this.InterSerctionHandler, option)
            this.chidComponentIds?.forEach(element => {
                const childElement = document.getElementById(element)
                if (childElement) {
                    this.observer?.observe(childElement)
                }
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    componentWillUnmount() {
        this.observer?.disconnect()
    }

    InterSerctionHandler = (entry) => {
        try {
            entry?.forEach(element => {
                if (element.isIntersecting) {
                    const childId = element.target.id
                    this.diagnosisHistoryAPICall(childId)
                    this.observer?.unobserve(element.target)
                }
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    diagnosisHistoryAPICall = (item) => {
        try {
            switch (item) {
                case 'menstrual':
                    this.getMenstrualHistoryData()
                    break;
                case 'obstetrical':
                    this.getObstetricalHistoryData()
                    break;
                case 'birthControl':
                    this.getBirthControlHistoryData()
                    break;
                case 'gynaecological':
                    this.getGynaecologicalHistoryData()
                    break;
                case 'papSmearHistory':
                    this.getPapSmearHistoryData()
                    break;
                case 'obgynSurgery':
                    this.getOBGYNSurgeryData()
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data
                        }, () => {
                            this.setupIntersectionObserver()
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getMenstrualHistoryData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.MENSTRUAL_HISTORY + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ loading: false })
                        let data = response?.data?.data?.menstrual_history
                        if (Object.keys(data)?.length > 0) {
                            this.setState({
                                Periods_regular: data?.is_period_regular ? "Yes" : "No",
                                Period_Starts: data?.period_start_duration,
                                Periods_Last: data?.period_last_duration,
                                Bleeding_Spotting: data?.is_bleeding_btw_periods ? "Yes" : "No",
                                Bleeding_Spotting_Cmd: data?.bleeding_btw_periods_desc,
                                pain_associated: data?.pain_with_periods == "yes" ? "Yes" : "No",
                                current_treatment: data?.on_current_treatment ? "Yes" : "No",
                                current_treatment_cmd: data?.current_treatment_desc,
                                MenstrualEdit: true
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getObstetricalHistoryData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.OBSTETRICAL_HISTORY_GET + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ loading: false })
                        this.setState({
                            ObstetricalData: response?.data?.data?.obstetrical_history
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getBirthControlHistoryData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.BIRTH_CONTROL_GET + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ loading: false })
                        let data = response?.data?.data
                        if (Object.keys(data)?.length > 0) {
                            this.setState({
                                depressOnPreg: data?.is_depression_before_after_pregnancy ? "Yes" : "No",
                                Infertility_Therapy: data?.previous_infertility_therapy_desc,
                                PastBirthControl: typeof data?.past_birth_control_methods === 'string' ? data?.past_birth_control_methods.split(',') : data?.past_birth_control_methods,
                                birthControlBox: true,
                                birth_control_id: data?.id
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getGynaecologicalHistoryData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.GYNAECOLOGICAL_HISTORY + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ loading: false })
                        let data = response?.data?.data?.gynaecology_history
                        if (Object.keys(data)?.length > 0) {
                            this.setState({
                                yeast_infection: data?.had_yeast_infection ? "Yes" : "No",
                                diagnosed_fibroids: data?.had_fibroids_uterus ? "Yes" : "No",
                                overian_cyst: data?.had_ovarian_cysts ? "Yes" : "No",
                                Bacterial_vaginosis: data?.had_bacterial_vaginosis ? "Yes" : "No",
                                Bacterial_vaginosis_Cmd: data?.bacterial_vaginosis_desc,
                                Intercourse: data?.is_bleeding_after_intercourse ? "Yes" : "No",
                                Intercourse_Cmd: data?.bleeding_intercourse_desc,
                                urinary_problems: data?.had_urinary_problems ? "Yes" : "No",
                                urinary_problems_cmd: data?.urinary_problems_desc,
                                Menopause_Age: data?.menopause_occured_age,
                                Hormone_Replacement: data?.used_hormone_replacement,
                                Other_History: typeof data?.other_gyn_history === 'string' ? data?.other_gyn_history.split(',') : data?.other_gyn_history,
                                GynaecologicalBox: true
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getPapSmearHistoryData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.PAP_HISTORY + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ loading: false })
                        let data = response?.data?.data?.pap_mammogram_history
                        if (Object.keys(data)?.length > 0) {
                            this.setState({
                                LastPapSmearDate: data?.last_pap_smear ? data?.last_pap_smear : null,
                                Abnormal_papsmear: data?.had_abnormal_pap_smears_treatment ? "Yes" : "No",
                                Abnormal_papsmear_cmd: data?.abnormal_treatment_desc,
                                Cryotherapy: data?.treatment_cryotherapy ? "Yes" : "No",
                                Cryotherapy_cmd: data?.cryotherapy_desc,
                                Laser: data?.treatment_laser ? "Yes" : "No",
                                Laser_Cmd: data?.laser_desc,
                                Cone_Biopsy: data?.treatment_cone_biopsy ? "Yes" : "No",
                                Cone_Biopsy_Cmd: data?.cone_biopsy_desc,
                                Loop_Excision: data?.treatment_leep ? "Yes" : "No",
                                Loop_Excision_cmd: data?.leep_desc,
                                LastmammogramDate: data?.last_mammogram_date ? data?.last_mammogram_date : null,
                                Abnormal_Mammogram: data?.had_abnormal_mammogram ? "Yes" : "No",
                                Abnormal_Mammogram_cmd: data?.abnormal_mammogram_desc,
                                PapSmearEdit: (data?.last_pap_smear || data?.last_mammogram_date) ? true : false
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getOBGYNSurgeryData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.OBGYN_SURGERY_GET_POST + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ loading: false })
                        let data = response?.data?.data
                        if (Object.keys(data)?.length > 0) {
                            const payload = data?.obstetrical_surgery_history.map(surgery => ({
                                ...surgery,
                                is_surgery_done: surgery.is_surgery_done ? "Yes" : "No"
                            }));
                            const additional_payload = data?.obstetrical_additional_surgery_history.map(surgery => ({
                                ...surgery,
                                is_surgery_done: surgery.is_surgery_done ? "Yes" : "No"
                            }));
                            this.setState({
                                surgeries: payload,
                                addtional_Surgeries: additional_payload,
                                obgynSurgerieEdit: (payload.some((list) => (list.is_surgery_done === "Yes")) || additional_payload.some((list) => (list.is_surgery_done === "Yes")))
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    postMenstrualData = () => {
        try {
            var states = this.state
            var is_period_regular = states?.Periods_regular == "Yes" ? true : false
            var is_bleeding_btw_periods = states.Bleeding_Spotting == "Yes" ? true : false
            var on_current_treatment = states.current_treatment == "Yes" ? true : false
            var pain_with_periods = states.pain_associated ? "yes" : "no"
            var data = {
                "patient_id": this.state.patientId,
                "appointment_id": this.state.appointmentId,
                "menstrual_history": {
                    "is_period_regular": is_period_regular,
                    "period_start_duration": states?.Period_Starts == "" ? 0 : +states?.Period_Starts,
                    "period_last_duration": states?.Periods_Last == "" ? 0 : +states?.Periods_Last,
                    "is_bleeding_btw_periods": is_bleeding_btw_periods,
                    "bleeding_btw_periods_desc": states.Bleeding_Spotting_Cmd,
                    "pain_with_periods": pain_with_periods,
                    "on_current_treatment": on_current_treatment,
                    "current_treatment_desc": states?.current_treatment_cmd,
                    "is_menstural_history_frozen": false,
                    "menstural_history_frozen_date": ""
                }
            }
            RestAPIService.create(data, Serviceurls.MENSTRUAL_HISTORY)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getMenstrualHistoryData()
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postObstetricalData = () => {
        try {
            var states = this.state
            if (states.year == "") {
                this.errorMessage("select year of delivery or abortion")
            } else {
                var data = {
                    "patient_id": states.patientId,
                    "appointment_id": states.appointmentId,
                    "year_of_delivery_or_abortion": states.year,
                    "place_of_delivery_or_abortion": states.placeofDelivery,
                    "duration_of_pregnancy": states.durationOfPregnancyInWeek,
                    "hours_of_Labor": states.hoursOfDelivery,
                    "type_of_delivery": states.typeOfDelivery,
                    "complications_for_mother_or_baby": states.complicationsForMotherOrBaby,
                    "child_gender": states.gender,
                    "child_birth_weight": states.birthWeight,
                    "child_present_health": states.presentHealth
                }
                if (states?.obstetrical_id) {
                    data["obstetrical_id"] = states?.obstetrical_id
                }
                RestAPIService.create(data, Serviceurls.OBSTETRICAL_HISTORY_POST_AND_UPDATE)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.clearObstetrical()
                            this.getObstetricalHistoryData()
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postBirthControlData = () => {
        try {
            var states = this.state
            var data = {
                "patient_id": states.patientId,
                "appointment_id": states.appointmentId,
                "is_depression_before_after_pregnancy": states.depressOnPreg == "Yes" ? true : false,
                "past_birth_control_methods": states.PastBirthControl.toString(),
                "previous_infertility_therapy_desc": states.Infertility_Therapy,
            }
            if (states?.birth_control_id) {
                data["birth_control_id"] = states?.birth_control_id
            }
            RestAPIService.create(data, Serviceurls.BIRTH_CONTROL_POST_AND_UPDATE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getBirthControlHistoryData()
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postGynaecologicalData = () => {
        try {
            var states = this.state

            var had_ovarian_cysts = states.overian_cyst == "Yes" ? true : false
            var had_fibroids_uterus = states.diagnosed_fibroids == "Yes" ? true : false
            var had_yeast_infection = states.yeast_infection == "Yes" ? true : false
            var had_bacterial_vaginosis = states.Bacterial_vaginosis == "Yes" ? true : false
            var is_bleeding_after_intercourse = states.Intercourse == "Yes" ? true : false
            var had_urinary_problems = states.urinary_problems == "Yes" ? true : false

            var data = {
                "patient_id": states.patientId,
                "appointment_id": states.appointmentId,
                "gynaecology_history": {
                    "had_ovarian_cysts": had_ovarian_cysts,
                    "had_fibroids_uterus": had_fibroids_uterus,
                    "had_yeast_infection": had_yeast_infection,
                    "had_bacterial_vaginosis": had_bacterial_vaginosis,
                    "bacterial_vaginosis_desc": states.Bacterial_vaginosis_Cmd,
                    "is_bleeding_after_intercourse": is_bleeding_after_intercourse,
                    "bleeding_intercourse_desc": states.Intercourse_Cmd,
                    "had_urinary_problems": had_urinary_problems,
                    "urinary_problems_desc": states.urinary_problems_cmd,
                    "menopause_occured_age": states.Menopause_Age,
                    "used_hormone_replacement": states.Hormone_Replacement,
                    "other_gyn_history": states?.Other_History.toString(),
                    "others_comments": "",
                    "is_gynaecology_history_frozen": false,
                    "gynaecology_history_frozen_date": null
                },
                "additional_notes": {
                    "additional_notes": ""
                }
            }
            RestAPIService.create(data, Serviceurls.GYNAECOLOGICAL_HISTORY)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getGynaecologicalHistoryData()
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postPapSmearData = () => {
        try {
            var states = this.state

            if ((this.state.LastPapSmearDate == null || new Date(this.state.LastPapSmearDate) == "Invalid Date") && (this.state.LastmammogramDate == null || new Date(this.state.LastmammogramDate) == "Invalid Date")) {
                // if (this.state.LastPapSmearDate == null || new Date(this.state.LastPapSmearDate) == "Invalid Date") {
                //     this.errorMessage("Date of Last Pap Smear is Mandatory")
                // }
                // else if (this.state.LastmammogramDate == null || new Date(this.state.LastmammogramDate) == "Invalid Date") {
                //     this.errorMessage("Date of Last Mammogram is Mandatory")
                // }
                this.errorMessage("Please Select Last Pap Smear / Last Mammogram date")
            }
            else {
                let date = new Date(this.state.LastPapSmearDate)
                let PapSmearDate = this.state.LastPapSmearDate !== null ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : null
                let dates = new Date(this.state.LastmammogramDate)
                let MammogramDate = this.state.LastmammogramDate !== null ? DateTime.fromJSDate(dates).toFormat('yyyy-MM-dd') : null
                var data = {
                    "patient_id": states.patientId,
                    "appointment_id": states.appointmentId,
                    "pap_mammogram_history": {
                        "had_abnormal_pap_smears_treatment": states.Abnormal_papsmear == "Yes" ? true : false,
                        "treatment_cryotherapy": states.Cryotherapy == "Yes" ? true : false,
                        "cryotherapy_desc": states.Cryotherapy_cmd,
                        "treatment_laser": states.Laser == "Yes" ? true : false,
                        "laser_desc": states.Laser_Cmd,
                        "abnormal_treatment_desc": states.Abnormal_papsmear_cmd,
                        "treatment_cone_biopsy": states.Cone_Biopsy == "Yes" ? true : false,
                        "cone_biopsy_desc": states.Cone_Biopsy_Cmd,
                        "treatment_leep": states.Loop_Excision == "Yes" ? true : false,
                        "leep_desc": states.Loop_Excision_cmd,
                        "last_mammogram_date": MammogramDate,
                        "had_abnormal_mammogram": states.Abnormal_Mammogram == "Yes" ? true : false,
                        "abnormal_mammogram_desc": states.Abnormal_Mammogram_cmd,
                        "last_pap_smear": PapSmearDate
                    }
                }
                RestAPIService.create(data, Serviceurls.PAP_HISTORY)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.getPapSmearHistoryData()
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postObgynSurgeryData = () => {
        try {
            var states = this.state

            const invalidSurgeries = states.surgeries.filter(surgery => surgery.is_surgery_done === 'Yes' && (!surgery.surgery_year || new Date(surgery.surgery_year) == "Invalid Date"));
            if (invalidSurgeries.length > 0) {
                this.errorMessage(`Please Select the Year for ${invalidSurgeries[0]?.surgery_name}`)
            } else {
                const payload = states.surgeries.map(surgery => ({
                    ...surgery,
                    is_surgery_done: surgery.is_surgery_done === 'Yes'
                }));

                const additional_payload = states.addtional_Surgeries.map(surgery => ({
                    ...surgery,
                    is_surgery_done: surgery.is_surgery_done === 'Yes'
                }));

                var data = {
                    "patient_id": states.patientId,
                    "appointment_id": states.appointmentId,
                    "deleted_surgeries": states.Delete_Id,
                    "obstetrical_surgery_history": payload,
                    "obstetrical_additional_surgery_history": additional_payload
                }
                RestAPIService.create(data, Serviceurls.OBGYN_SURGERY_GET_POST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.getOBGYNSurgeryData()
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    handleStatusChange = (index, status) => {
        this.setState(prevState => {
            const surgeries = [...prevState.surgeries];
            surgeries[index].is_surgery_done = status;
            return { surgeries };
        });
    }

    handleDateChange = (index, date) => {
        let NewDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
        this.setState(prevState => {
            const surgeries = [...prevState.surgeries];
            surgeries[index].surgery_year = NewDate;
            return { surgeries };
        });
    }

    clearObstetrical = () => {
        this.setState({
            year: "",
            placeofDelivery: "",
            durationOfPregnancyInWeek: "",
            hoursOfDelivery: "",
            typeOfDelivery: "",
            complicationsForMotherOrBaby: "",
            gender: "",
            birthWeight: "",
            presentHealth: "",
            obstetrical_id: ""
        })
    }

    ObstetricalEdit = (data) => {
        this.setState({
            year: data?.year_of_delivery_or_abortion,
            placeofDelivery: data?.place_of_delivery_or_abortion,
            durationOfPregnancyInWeek: data?.duration_of_pregnancy,
            hoursOfDelivery: data?.hours_of_Labor,
            typeOfDelivery: data?.type_of_delivery,
            complicationsForMotherOrBaby: data?.complications_for_mother_or_baby,
            gender: data?.child_gender,
            birthWeight: data?.child_birth_weight,
            presentHealth: data?.child_present_health,
            obstetrical_id: data?.id
        })
    }

    popupClose = () => {
        this.setState({
            deleteID: "", deletePop: false
        })
    }

    ObstetricalDelete = () => {
        let states = this.state
        try {
            var data = {
                "obstetrical_id": states.deleteID
            }
            RestAPIService.create(data, Serviceurls.OBSTETRICAL_HISTORY_DELETE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.setState({
                            deleteID: "", deletePop: false
                        }, () => {
                            this.getObstetricalHistoryData()
                            this.clearObstetrical()
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ObstetricalPopup = (data) => {
        this.setState({
            deletePop: true,
            deleteID: data?.id
        })
    }

    getYearList = () => {
        try {
            let getYear = [];
            let today = new Date();
            let year = today.getFullYear();
            for (let i = year; i > 1979; i--) {
                getYear.push(i);
            };
            return getYear;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderMenstrualHistoryBox = () => {
        try {
            return (
                <Box id='menstrual' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Menstrual History'}</Typography>
                        {this.state.MenstrualEdit ?
                            <Button sx={{ mr: "1vw" }} className='Common_Btn_Min_Width' onClick={() => this.setState({ MenstrualEdit: false })}>
                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.8vw'} width={'1.9vw'} />
                            </Button> :
                            <Box display={'flex'}>
                                <Button variant='contained'
                                    disabled={this.state.loading}
                                    sx={{ textTransform: 'capitalize', height: '1.85vw', width: '4vw', mr: '1vw' }}
                                    onClick={() => this.postMenstrualData()}
                                >Save</Button>
                            </Box>
                        }
                    </Box>
                    {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
                        <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
                            <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'38vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Are your periods regular ?'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.MenstrualEdit}
                                                        className={`eMed_chip ${item.label === this.state.Periods_regular ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Periods_regular ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Periods_regular: item?.value })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    <Box component={'div'} width={'28vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Your Periods starts every'}</Typography>
                                        <Box component={'div'}>
                                            <TextField
                                                size='small'
                                                disabled={this.state.MenstrualEdit}
                                                sx={{ width: "10vw", marginTop: "0.4vw", marginLeft: "0.5vw" }}
                                                InputProps={{ endAdornment: <Box component={'div'}><Typography fontWeight={600}>{'Days'}</Typography></Box> }}
                                                value={this.state.Period_Starts}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    if ((CommonValidation.numberOnly(value) || value === '') && (value.length <= 3)) {
                                                        this.setState({
                                                            Period_Starts: e.target.value
                                                        })
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'How many days your periods last'}</Typography>
                                        <Box component={'div'}>
                                            <TextField
                                                size='small'
                                                disabled={this.state.MenstrualEdit}
                                                sx={{ width: "10vw", marginTop: "0.4vw", marginLeft: "0.5vw" }}
                                                InputProps={{ endAdornment: <Box component={'div'}><Typography fontWeight={600}>{'Days'}</Typography></Box> }}
                                                value={this.state.Periods_Last}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    if ((CommonValidation.numberOnly(value) || value === '') && (value.length <= 3)) {
                                                        this.setState({
                                                            Periods_Last: e.target.value
                                                        })
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} mt={"1vw"}>
                                <Box component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Does Bleeding / Spotting occur between periods ?'}</Typography>
                                    <Box component={'div'}>
                                        {this.state.yesorNoList?.map((item, index) => {
                                            return (
                                                <Chip
                                                    disabled={this.state.MenstrualEdit}
                                                    className={`eMed_chip ${item.label === this.state.Bleeding_Spotting ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.label === this.state.Bleeding_Spotting ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        this.setState({ Bleeding_Spotting: item?.value }, () => {
                                                            if (item?.value == "No") {
                                                                this.setState({
                                                                    Bleeding_Spotting_Cmd: ""
                                                                })
                                                            }
                                                        })
                                                    }}
                                                    clickable
                                                />
                                            )
                                        })}
                                    </Box>
                                    <TextField
                                        disabled={this.state.MenstrualEdit ? true : false}
                                        multiline
                                        rows={2}
                                        label='Reason'
                                        sx={{ width: '25vw', mt: '0.5vw' }}
                                        value={this.state.Bleeding_Spotting_Cmd}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length <= 250) {
                                                this.setState({ Bleeding_Spotting_Cmd: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed Only 250 Characters')
                                            }
                                        }}
                                    />
                                </Box>
                                <Box component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Is the pain associated with periods ?'}</Typography>
                                    <Box component={'div'}>
                                        {this.state.yesorNoList?.map((item, index) => {
                                            return (
                                                <Chip
                                                    disabled={this.state.MenstrualEdit}
                                                    className={`eMed_chip ${item.label === this.state.pain_associated ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.label === this.state.pain_associated ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        this.setState({ pain_associated: item?.label })
                                                    }}
                                                    clickable
                                                />
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Are you on any current treatment ?'}</Typography>
                                    <Box component={'div'}>
                                        {this.state.yesorNoList?.map((item, index) => {
                                            return (
                                                <Chip
                                                    disabled={this.state.MenstrualEdit}
                                                    className={`eMed_chip ${item.label === this.state.current_treatment ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.label === this.state.current_treatment ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        this.setState({ current_treatment: item?.label }, () => {
                                                            if (item?.value == "No") {
                                                                this.setState({
                                                                    current_treatment_cmd: ""
                                                                })
                                                            }
                                                        })
                                                    }}
                                                    clickable
                                                />
                                            )
                                        })}
                                    </Box>
                                    <TextField
                                        disabled={this.state.current_treatment === 'No' || this.state.MenstrualEdit ? true : false}
                                        multiline
                                        rows={2}
                                        label='Reason'
                                        sx={{ width: '25vw', mt: '0.5vw' }}
                                        value={this.state.current_treatment_cmd}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length <= 250) {
                                                this.setState({ current_treatment_cmd: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed Only 250 Characters')
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderObstetricalHistoryBox = () => {
        let states = this.state
        let Years = this.getYearList()
        try {
            return (
                <Box id='obstetrical' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Obstetrical History'}</Typography>
                        <Button variant='contained' sx={{ marginTop: '0.5vw', marginRight: "1vw", height: '1.85vw', width: '4vw' }} className='eMed_usrconf_btn' onClick={() => { this.postObstetricalData() }}>
                            {this.state?.obstetrical_id ? null : <div style={{ fontSize: "0.95vw", fontWeight: 800 }}>+</div>}
                            <span style={{ textTransform: "capitalize", fontSize: "0.85vw", marginLeft: "0.5vw" }}> {this.state?.obstetrical_id ? "Save" : "Add"}</span>
                        </Button>
                    </Box>
                    {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
                        <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
                            <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                                <TableContainer>
                                    <Table stickyHeader size='small'>
                                        <TableHead sx={{ height: '2vw' }}>
                                            <TableRow>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Year'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Place of Delivery'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Duration of Pregnancy (Weeks)'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Hours of Labor'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Type of Delivery'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Complication For Mother /Baby'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Gender'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Birth Weight (Kgs)'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{'Present Health'}</TableCell>
                                                <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{'Action'}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {states.ObstetricalData.length > 0 ? states.ObstetricalData.map((data, index) => {
                                            return (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align='left'>
                                                            <div>{data.year_of_delivery_or_abortion ? data.year_of_delivery_or_abortion : '-'}</div>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {data.place_of_delivery_or_abortion.length > 15 ?
                                                                <Tooltip title={data.place_of_delivery_or_abortion} arrow>
                                                                    <div>{data.place_of_delivery_or_abortion ? data.place_of_delivery_or_abortion.slice(0, 15) + "..." : '-'}</div>
                                                                </Tooltip> : <div>{data.place_of_delivery_or_abortion ? data.place_of_delivery_or_abortion : '-'}</div>}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {data.duration_of_pregnancy.length > 15 ?
                                                                <Tooltip title={data.duration_of_pregnancy} arrow>
                                                                    <div>{data.duration_of_pregnancy ? data.duration_of_pregnancy.slice(0, 15) + "..." : '-'}</div>
                                                                </Tooltip> : <div>{data.duration_of_pregnancy ? data.duration_of_pregnancy : (data.duration_of_pregnancy === 0 ? 0 : '-')}</div>}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {data.hours_of_Labor.length > 15 ?
                                                                <Tooltip title={data.hours_of_Labor} arrow>
                                                                    <div>{data.hours_of_Labor ? data.hours_of_Labor.slice(0, 15) + "..." : '-'}</div>
                                                                </Tooltip> : <div>{data.hours_of_Labor ? data.hours_of_Labor : (data.hours_of_Labor === 0 ? 0 : '-')}</div>}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {data.type_of_delivery.length > 15 ?
                                                                <Tooltip title={data.type_of_delivery} arrow>
                                                                    <div>{data.type_of_delivery ? data.type_of_delivery.slice(0, 15) + "..." : '-'}</div>
                                                                </Tooltip> : <div>{data.type_of_delivery ? data.type_of_delivery : '-'}</div>}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {data.complications_for_mother_or_baby.length > 15 ?
                                                                <Tooltip title={data.complications_for_mother_or_baby} arrow>
                                                                    <div>{data.complications_for_mother_or_baby ? data.complications_for_mother_or_baby.slice(0, 15) + "..." : '-'}</div>
                                                                </Tooltip> : <div>{data.complications_for_mother_or_baby ? data.complications_for_mother_or_baby : '-'}</div>}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <div>{data.year_of_delivery_or_abortion ? (data.child_gender === "m" ? "Male" : data.child_gender === "f" ? "Female" : "-") : '-'}</div>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {data.child_birth_weight.length > 15 ?
                                                                <Tooltip title={data.child_birth_weight} arrow>
                                                                    <div>{data.child_birth_weight ? data.child_birth_weight.slice(0, 15) + "..." : '-'}</div>
                                                                </Tooltip> : <div>{data.child_birth_weight ? data.child_birth_weight : (data.child_birth_weight === 0 ? 0 : '-')}</div>}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {data.child_present_health.length > 15 ?
                                                                <Tooltip title={data.child_present_health} arrow>
                                                                    <div>{data.child_present_health ? data.child_present_health.slice(0, 15) + "..." : '-'}</div>
                                                                </Tooltip> : <div>{data.child_present_health ? data.child_present_health : '-'}</div>}
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                                                <CommonEditButton size={"1.5vw"} onClick={this.ObstetricalEdit.bind(this, data)} />
                                                                <CommonDeleteButton size={"1.5vw"} onClick={this.ObstetricalPopup.bind(this, data)} />
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )
                                        }) : null
                                        }
                                    </Table>
                                </TableContainer>
                                <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", borderTop: "1px solid gray", width: "100%", marginTop: "1vw" }}>
                                    <div style={{ width: "5.5%", borderRight: "1px solid gray" }}>
                                        <FormControl style={{ width: "95%", marginTop: "0.2vw" }}>
                                            <Select
                                                value={this.state.year}
                                                onChange={(e) => { this.setState({ year: e.target.value }) }}
                                                style={{ height: "2vw", fontSize: "0.9vw" }}
                                            >
                                                {Years.map((year) => (
                                                    <MenuItem key={year} value={year} style={{ fontSize: "0.9vw" }}>
                                                        {year}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ width: "10.5%", borderRight: "1px solid gray" }}>
                                        <OutlinedInput
                                            value={this.state.placeofDelivery}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 50) {
                                                    this.setState({ placeofDelivery: e.target.value })
                                                }
                                            }}
                                            style={{ width: "95%", height: "2vw", fontSize: "0.9vw", marginLeft: "0.2vw", marginTop: "0.2vw" }}
                                        />
                                    </div>
                                    <div style={{ width: "16%", borderRight: "1px solid gray" }}>
                                        <OutlinedInput
                                            value={this.state.durationOfPregnancyInWeek}
                                            onChange={(e) => {
                                                if ((CommonValidation.numberOnly(e.target.value) && +e.target.value <= 100 && +e.target.value > 0 && e.target.value.length <= 3) || (+e.target.value == "")) {
                                                    this.setState({ durationOfPregnancyInWeek: e.target.value })
                                                }
                                            }}
                                            style={{ width: "95%", height: "2vw", fontSize: "0.9vw", marginLeft: "0.2vw", marginTop: "0.2vw" }}
                                        />
                                    </div>
                                    <div style={{ width: "10%", borderRight: "1px solid gray" }}>
                                        <OutlinedInput
                                            value={this.state.hoursOfDelivery}
                                            onChange={(e) => {
                                                if ((CommonValidation.numberOnly(e.target.value) && +e.target.value <= 100 && +e.target.value > 0 && e.target.value.length <= 3) || (+e.target.value == "")) {
                                                    this.setState({ hoursOfDelivery: e.target.value })
                                                }
                                            }}
                                            style={{ width: "95%", height: "2vw", fontSize: "0.9vw", marginLeft: "0.2vw", marginTop: "0.2vw" }}
                                        />
                                    </div>
                                    <div style={{ width: "10%", borderRight: "1px solid gray" }}>
                                        <FormControl style={{ width: "95%", marginTop: "0.2vw" }}>
                                            <Select
                                                value={this.state.typeOfDelivery}
                                                onChange={(e) => { this.setState({ typeOfDelivery: e.target.value }) }}
                                                style={{ height: "2vw", fontSize: "0.9vw", marginLeft: "0.3vw" }}
                                            >
                                                <MenuItem value={"Normal"}>{"Normal"}</MenuItem>
                                                <MenuItem value={"C - Section"}>{"C - Section"}</MenuItem>
                                                <MenuItem value={"Vacuum Extraction"}>{"Vaccum Extraction"}</MenuItem>
                                                <MenuItem value={"Forceps"}>{"Forceps"}</MenuItem>
                                                <MenuItem value={"VBAC (Vaginal birth after cesarean)"}>{"VBAC (Vaginal Birth After Cesarean)"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ width: "16%", borderRight: "1px solid gray" }}>
                                        <OutlinedInput
                                            value={this.state.complicationsForMotherOrBaby}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 100) {
                                                    this.setState({ complicationsForMotherOrBaby: e.target.value })
                                                }
                                            }}
                                            style={{ width: "95%", height: "2vw", fontSize: "0.9vw", marginLeft: "0.2vw", marginTop: "0.2vw" }}
                                        />
                                    </div>
                                    <div style={{ width: "7%", borderRight: "1px solid gray" }}>
                                        <FormControl style={{ width: "95%", marginTop: "0.2vw" }}>
                                            <Select
                                                value={this.state.gender}
                                                onChange={(e) => { this.setState({ gender: e.target.value }) }}
                                                style={{ height: "2vw", fontSize: "0.9vw", marginLeft: "0.3vw" }}
                                            >
                                                <MenuItem value={""}>{"Select"}</MenuItem>
                                                <MenuItem value={"m"}>{"Male"}</MenuItem>
                                                <MenuItem value={"f"}>{"Female"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ width: "9%", borderRight: "1px solid gray" }}>
                                        <OutlinedInput
                                            value={this.state.birthWeight}
                                            onChange={(e) => {
                                                if ((CommonValidation.DecimalNumber(e.target.value) && +e.target.value <= 10 || +e.target.value == "")) {
                                                    this.setState({ birthWeight: e.target.value })
                                                }
                                            }}
                                            style={{ width: "95%", height: "2vw", fontSize: "0.9vw", marginLeft: "0.2vw", marginTop: "0.2vw" }}
                                        />
                                    </div>
                                    <div style={{ width: "14%" }}>
                                        <OutlinedInput
                                            value={this.state.presentHealth}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 100) {
                                                    this.setState({ presentHealth: e.target.value })
                                                }
                                            }}
                                            style={{ width: "95%", height: "2vw", fontSize: "0.9vw", marginLeft: "0.2vw", marginTop: "0.2vw" }}
                                        />
                                    </div>
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderBirthControlMethodsBox = () => {
        try {
            return (
                <Box id='birthControl' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Birth Control Methods'}</Typography>
                        {this.state.birthControlBox ?
                            <Button sx={{ mr: '1vw' }} className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ birthControlBox: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.8vw'} width={'1.9vw'} />
                            </Button> :
                            <Box display={'flex'}>
                                <Button variant='contained'
                                    disabled={this.state.loading}
                                    sx={{ textTransform: 'capitalize', height: '1.85vw', width: '4vw', mr: '1vw' }}
                                    onClick={() => this.postBirthControlData()}
                                >save</Button>
                            </Box>
                        }
                    </Box>
                    {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
                        <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
                            <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'25vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Did you experiance depression before or after pregnancy'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.birthControlBox}
                                                        className={`eMed_chip ${item.label === this.state.depressOnPreg ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.depressOnPreg ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ depressOnPreg: item?.value })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you used and birth control in the past ?'}</Typography>
                                        <Box component={'div'} style={{ width: "25vw" }}>
                                            {this.state.BirthControlList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.birthControlBox}
                                                        className={`eMed_chip ${this.state.PastBirthControl.includes(item.label) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={this.state.PastBirthControl.includes(item.label) ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            let updatedPastBirthControl = [...this.state.PastBirthControl];
                                                            if (updatedPastBirthControl.includes(item.value)) {
                                                                updatedPastBirthControl = updatedPastBirthControl.filter(val => val !== item.value);
                                                            } else {
                                                                updatedPastBirthControl.push(item.value);
                                                            }
                                                            this.setState({ PastBirthControl: updatedPastBirthControl });
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you used any infertility therapy previously'}</Typography>
                                        <TextField
                                            disabled={this.state.birthControlBox}
                                            multiline
                                            rows={2}
                                            label='Description'
                                            sx={{ width: '25vw', mt: '0.5vw', ml: "0.5vw" }}
                                            value={this.state.Infertility_Therapy}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Infertility_Therapy: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderGynaecologicalBox = () => {
        try {
            return (
                <Box id='gynaecological' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Gynaecological History'}</Typography>
                        {this.state.GynaecologicalBox ?
                            <Button sx={{ mr: "1vw" }} className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ GynaecologicalBox: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.8vw'} width={'1.9vw'} />
                            </Button> :
                            <Box display={'flex'}>
                                <Button variant='contained'
                                    disabled={this.state.loading}
                                    sx={{ textTransform: 'capitalize', height: '1.85vw', width: '4vw', mr: '1vw' }}
                                    onClick={() => this.postGynaecologicalData()}
                                >save</Button>
                            </Box>
                        }
                    </Box>
                    {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
                        <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
                            <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'30.5vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you ever had a yeast infection'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.GynaecologicalBox}
                                                        className={`eMed_chip ${item.label === this.state.yeast_infection ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.yeast_infection ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ yeast_infection: item?.value })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    <Box component={'div'} width={'31vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you ever been diagnosed with any fibroids in uterus'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.GynaecologicalBox}
                                                        className={`eMed_chip ${item.label === this.state.diagnosed_fibroids ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.diagnosed_fibroids ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ diagnosed_fibroids: item?.value })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you ever had an overian cyst'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.GynaecologicalBox}
                                                        className={`eMed_chip ${item.label === this.state.overian_cyst ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.overian_cyst ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ overian_cyst: item?.value })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} mt={'1vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'25vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you ever been treated for vaginal bacterial infection (bacterial vaginosis)?'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.GynaecologicalBox}
                                                        className={`eMed_chip ${item.label === this.state.Bacterial_vaginosis ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Bacterial_vaginosis ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Bacterial_vaginosis: item?.value }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Bacterial_vaginosis_Cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Bacterial_vaginosis === 'No' || this.state.GynaecologicalBox ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Bacterial_vaginosis_Cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Bacterial_vaginosis_Cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'} width={'25vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Does bleeding / spotting occur after intercourse?'}</Typography>
                                        <Box component={'div'} mt={"1.3vw"}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.GynaecologicalBox}
                                                        className={`eMed_chip ${item.label === this.state.Intercourse ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Intercourse ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Intercourse: item?.label }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Intercourse_Cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Intercourse === 'No' || this.state.GynaecologicalBox ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Intercourse_Cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Intercourse_Cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'} width={'30vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Did you have any urinary problems such as UTI(Urinary Tract Infection), Urinary Frequency, Loss of bladder control, blood in urine, etc..'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.GynaecologicalBox}
                                                        className={`eMed_chip ${item.label === this.state.urinary_problems ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.urinary_problems ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ urinary_problems: item?.label }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        urinary_problems_cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.urinary_problems === 'No' || this.state.GynaecologicalBox ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.urinary_problems_cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ urinary_problems_cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} mt={'1vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'If in menopause, at what age did it occur?'}</Typography>
                                        <Box component={'div'} width={"30.75vw"}>
                                            <TextField
                                                size='small'
                                                disabled={this.state.GynaecologicalBox}
                                                sx={{ width: "10vw", mt: "0.5vw", ml: "0.5vw" }}
                                                InputProps={{ endAdornment: <Box component={'div'}><Typography fontWeight={600}>{'Age'}</Typography></Box> }}
                                                value={this.state.Menopause_Age}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    if ((CommonValidation.numberOnly(value) || value === '') && (value <= 200) && (value.length <= 3)) {
                                                        this.setState({
                                                            Menopause_Age: e.target.value
                                                        })
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box component={'div'} width={"30.5vw"}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you used any hormone replacement?'}</Typography>
                                        <TextField
                                            multiline
                                            disabled={this.state.GynaecologicalBox}
                                            rows={2}
                                            label='Description'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Hormone_Replacement}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Hormone_Replacement: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Other Gynaecological history'}</Typography>
                                        <Box component={'div'} style={{ width: "25vw" }}>
                                            {this.state.GYNHistoryList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.GynaecologicalBox}
                                                        className={`eMed_chip ${this.state.Other_History.includes(item.label) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={this.state.Other_History.includes(item.label) ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            let updatedOtherHistory = [...this.state.Other_History];
                                                            if (updatedOtherHistory.includes(item.value)) {
                                                                updatedOtherHistory = updatedOtherHistory.filter(val => val !== item.value);
                                                            } else {
                                                                updatedOtherHistory.push(item.value);
                                                            }
                                                            this.setState({ Other_History: updatedOtherHistory });
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPapSmearHistoryBox = () => {
        try {
            return (
                <Box id='papSmearHistory' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Pap smear / Mammogram History'}</Typography>
                        {this.state.PapSmearEdit ?
                            <Button sx={{ mr: "1vw" }} className='Common_Btn_Min_Width' onClick={() => this.setState({ PapSmearEdit: false })}>
                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.8vw'} width={'1.9vw'} />
                            </Button> :
                            <Box display={'flex'}>
                                <Button variant='contained'
                                    disabled={this.state.loading}
                                    sx={{ textTransform: 'capitalize', height: '1.85vw', width: '4vw', mr: '1vw' }}
                                    onClick={() => this.postPapSmearData()}
                                >Save</Button>
                            </Box>
                        }
                    </Box>
                    {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
                        <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
                            <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'30.5vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{`Date of last pap smear ${(this.state.LastmammogramDate == null || new Date(this.state.LastmammogramDate) == "Invalid Date") ? "*" : ""} ?`}</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                disabled={this.state.PapSmearEdit}
                                                open={this.state.open}
                                                onOpen={() => this.setState({ open: true })}
                                                onClose={() => { this.setState({ open: false }) }}
                                                inputFormat={"DD-MM-YYYY"}
                                                autoComplete="off"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                maxDate={DateTime.now()}
                                                value={this.state.LastPapSmearDate}
                                                onChange={(newDate) => {
                                                    this.setState({
                                                        LastPapSmearDate: newDate
                                                    })
                                                }}
                                                renderInput={(params) => <TextField {...params} placeholder='DD-MM-YYYY'
                                                    sx={{ width: '10vw', ml: '0.5vw', mt: "0.5vw" }} size='small'
                                                    onClick={() => { this.setState({ open: true }) }}
                                                />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box component={'div'} width={'30.5vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you ever had any treatment for an abnormal pap smear?'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.PapSmearEdit}
                                                        className={`eMed_chip ${item.label === this.state.Abnormal_papsmear ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Abnormal_papsmear ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Abnormal_papsmear: item?.label }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Abnormal_papsmear_cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Abnormal_papsmear === 'No' || this.state.PapSmearEdit ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Abnormal_papsmear_cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Abnormal_papsmear_cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Cryotherapy'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.PapSmearEdit}
                                                        className={`eMed_chip ${item.label === this.state.Cryotherapy ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Cryotherapy ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Cryotherapy: item?.label }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Cryotherapy_cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Cryotherapy === 'No' || this.state.PapSmearEdit ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Cryotherapy_cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Cryotherapy_cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} mt={'1vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'25vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Laser'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.PapSmearEdit}
                                                        className={`eMed_chip ${item.label === this.state.Laser ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Laser ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Laser: item?.value }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Laser_Cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Laser === 'No' || this.state.PapSmearEdit ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Laser_Cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Laser_Cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'} width={'25vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Cone Biopsy'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.PapSmearEdit}
                                                        className={`eMed_chip ${item.label === this.state.Cone_Biopsy ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Cone_Biopsy ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Cone_Biopsy: item?.label }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Cone_Biopsy_Cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Cone_Biopsy === 'No' || this.state.PapSmearEdit ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Cone_Biopsy_Cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Cone_Biopsy_Cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'} width={'30vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Loop Excision(LEEP)'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.PapSmearEdit}
                                                        className={`eMed_chip ${item.label === this.state.Loop_Excision ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Loop_Excision ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Loop_Excision: item?.label }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Loop_Excision_cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Loop_Excision === 'No' || this.state.PapSmearEdit ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Loop_Excision_cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Loop_Excision_cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} mt={'1vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'30.5vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{`Date of last mammogram ${(this.state.LastPapSmearDate == null || new Date(this.state.LastPapSmearDate) == "Invalid Date") ? "*" : ""} ?`}</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                disabled={this.state.PapSmearEdit}
                                                open={this.state.openMammogram}
                                                onOpen={() => this.setState({ openMammogram: true })}
                                                onClose={() => { this.setState({ openMammogram: false }) }}
                                                inputFormat={"DD-MM-YYYY"}
                                                autoComplete="off"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                maxDate={DateTime.now()}
                                                value={this.state.LastmammogramDate}
                                                onChange={(newDate) => {
                                                    this.setState({
                                                        LastmammogramDate: newDate
                                                    })
                                                }}
                                                renderInput={(params) => <TextField {...params} placeholder='DD-MM-YYYY'
                                                    sx={{ width: '10vw', ml: '0.5vw', marginTop: "0.5vw" }} size='small'
                                                    onClick={() => { this.setState({ openMammogram: true }) }}
                                                />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you had an abnormal mammogram?'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.PapSmearEdit}
                                                        className={`eMed_chip ${item.label === this.state.Abnormal_Mammogram ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.Abnormal_Mammogram ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ Abnormal_Mammogram: item?.label }, () => {
                                                                if (item?.value == "No") {
                                                                    this.setState({
                                                                        Abnormal_Mammogram_cmd: ""
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.Abnormal_Mammogram === 'No' || this.state.PapSmearEdit ? true : false}
                                            multiline
                                            rows={2}
                                            label='Reason'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.Abnormal_Mammogram_cmd}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ Abnormal_Mammogram_cmd: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'} width={'25vw'}>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderOBGYNSurgeriesBox = () => {
        const { surgeries, addtional_Surgeries } = this.state;
        try {
            return (
                <Box id='obgynSurgery' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'OBGYN Surgeries'}</Typography>
                        {this.state.obgynSurgerieEdit ?
                            <Button sx={{ mr: "1vw" }} className='Common_Btn_Min_Width' onClick={() => this.setState({ obgynSurgerieEdit: false })}>
                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.8vw'} width={'1.9vw'} />
                            </Button> :
                            <Box display={'flex'}>
                                <Button variant='contained'
                                    disabled={this.state.loading}
                                    sx={{ textTransform: 'capitalize', height: '1.85vw', width: '4vw', mr: '1vw' }}
                                    onClick={() => this.postObgynSurgeryData()}
                                >Save</Button>
                            </Box>
                        }
                    </Box>
                    {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
                        <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
                            <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} flexWrap={'wrap'} ml={"0.5vw"}>
                                    {surgeries.map((name, indexs) => (
                                        <Box key={indexs} component={'div'} width={"22vw"} mt={name.surgery_name === "Infertility-Surgery" || name.surgery_name === "Ovarian Surgery" || name.surgery_name === "Dilation & Curettage" || name.surgery_name === "Cystocele Repair" ? null : "1vw"}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{name.surgery_name}</Typography>
                                            <Box component={'div'}>
                                                {this.state.yesorNoList?.map((item, index) => {
                                                    return (
                                                        <Chip
                                                            disabled={this.state.obgynSurgerieEdit}
                                                            className={`eMed_chip ${item.label === name.is_surgery_done ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                            label={item.label}
                                                            variant={item.label === name.is_surgery_done ? 'contained' : 'outlined'}
                                                            key={index}
                                                            sx={{ mt: "0.5vw" }}
                                                            onClick={() => { this.handleStatusChange(indexs, item?.value) }}
                                                            clickable
                                                        />
                                                    )
                                                })}
                                            </Box>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={name.surgery_year ? name.surgery_year : null}
                                                    inputFormat='DD-MM-YYYY'
                                                    maxDate={new Date()}
                                                    disabled={name.is_surgery_done == "No" || this.state.obgynSurgerieEdit}
                                                    onChange={(newDate) => { this.handleDateChange(indexs, new Date(newDate)) }}
                                                    renderInput={(params) => <TextField size='small' {...params} placeholder='DD-MM-YYYY'
                                                        sx={{ width: '10vw', marginTop: "0.5vw" }} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    ))}
                                    {addtional_Surgeries.map((name, indexs) => (
                                        <Box key={indexs} component={'div'} width={"20vw"}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{name.surgery_name}</Typography>
                                                <Button onClick={() => {
                                                    if (!this.state.obgynSurgerieEdit) {
                                                        let DeletedIds = this.state.Delete_Id;
                                                        DeletedIds.push(name.id)
                                                        let current_additional = this.state.addtional_Surgeries.filter((_, index) => index !== indexs);
                                                        this.setState({ Delete_Id: DeletedIds, addtional_Surgeries: current_additional })
                                                    }
                                                }} className='eMed_usrconf_btn'>
                                                    <img src={ImagePaths.LabDeleteButton.default} alt="close" className='eMed_action_img_small' />
                                                </Button>
                                            </div>
                                            <Box component={'div'}>
                                                {this.state.yesorNoList?.map((item, index) => {
                                                    return (
                                                        <Chip
                                                            disabled={true}
                                                            className={`eMed_chip ${item.label === name.is_surgery_done ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                            label={item.label}
                                                            variant={item.label === name.is_surgery_done ? 'contained' : 'outlined'}
                                                            key={index}
                                                            clickable
                                                        />
                                                    )
                                                })}
                                            </Box>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={name.surgery_year ? name.surgery_year : null}
                                                    inputFormat='DD-MM-YYYY'
                                                    maxDate={new Date()}
                                                    disabled={true}
                                                    renderInput={(params) => <TextField size='small' {...params} placeholder='DD-MM-YYYY'
                                                        sx={{ width: '10vw', ml: '0.5vw', marginTop: "0.5vw" }} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    ))}
                                    {this.state.isTextBoxOpen ?
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: "1vw" }}>
                                            <TextField
                                                label='Enter Surgery Name'
                                                size='small'
                                                sx={{ width: '15vw', mt: '0.5vw' }}
                                                value={this.state.New_Surgery}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    if (value?.length <= 50) {
                                                        this.setState({ New_Surgery: e.target.value })
                                                    }
                                                }}
                                            />
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={this.state.NewSurgeryYear}
                                                        inputFormat='DD-MM-YYYY'
                                                        maxDate={new Date()}
                                                        onChange={(newDate) => {
                                                            this.setState({
                                                                NewSurgeryYear: newDate
                                                            })
                                                        }}
                                                        renderInput={(params) => <TextField size='small' {...params} placeholder='DD-MM-YYYY'
                                                            sx={{ width: '10vw', marginTop: "0.5vw" }} />}
                                                    />
                                                </LocalizationProvider>
                                                <Button variant='contained' sx={{ ml: "0.5vw", mt: "0.5vw" }}
                                                    disabled={this.state.New_Surgery == "" || !this.state.NewSurgeryYear || new Date(this.state.NewSurgeryYear) == "Invalid Date"}
                                                    onClick={() => {
                                                        if (this.state.New_Surgery !== "" && this.state.NewSurgeryYear && new Date(this.state.NewSurgeryYear) != "Invalid Date") {
                                                            let List = this.state.addtional_Surgeries
                                                            let data = { is_surgery_done: 'Yes', surgery_year: DateTime.fromJSDate(new Date(this.state.NewSurgeryYear)).toFormat("yyyy-MM-dd") , surgery_name: this.state.New_Surgery }
                                                            if ((this.state.addtional_Surgeries.filter((item) => (item.surgery_name === this.state.New_Surgery && item?.surgery_year === DateTime.fromJSDate(new Date(this.state.NewSurgeryYear)).toFormat("yyyy-MM-dd")))?.length > 0) || ((this.state.surgeries.filter((item) => (item.surgery_name === this.state.New_Surgery && item?.surgery_year === DateTime.fromJSDate(new Date(this.state.NewSurgeryYear)).toFormat("yyyy-MM-dd")))?.length > 0))) {
                                                                this.errorMessage("Surgery Already Exists with same date")
                                                            } else {
                                                                List.push(data)
                                                                this.setState({
                                                                    addtional_Surgeries: List,
                                                                    New_Surgery: "",
                                                                    isTextBoxOpen: false,
                                                                    NewSurgeryYear: null
                                                                })
                                                            }

                                                        } else {
                                                            this.errorMessage("Enter Correct Details")
                                                        }
                                                    }}> Add
                                                </Button>
                                            </div>
                                        </Box> :
                                        <Box component={'div'} sx={{ width: "13vw", height: "7vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", border: "1px solid gray", borderStyle: "dashed", padding: "1vw", cursor: 'pointer', marginTop: "1vw" }}
                                            onClick={() => { if (!this.state.obgynSurgerieEdit) { this.setState({ isTextBoxOpen: true }) } }}>
                                            <img src={ImagePaths.AddCircle.default} alt="Add" className='eMed_action_img_small' style={{ width: '2vw', height: '2vw' }} />
                                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Add New Surgery'}</Typography>
                                        </Box>}
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
                <Box component={'div'} display={'flex'}>
                    <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
                    </Box>
                </Box>
                <Box component={'div'} mt={'0.5vw'} height={'61.5vh'} overflow={'scroll'}>
                    {this.renderMenstrualHistoryBox()}
                    {this.renderObstetricalHistoryBox()}
                    {this.renderBirthControlMethodsBox()}
                    {this.renderGynaecologicalBox()}
                    {this.renderPapSmearHistoryBox()}
                    {this.renderOBGYNSurgeriesBox()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePop ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.ObstetricalDelete.bind(this)}
                    disable={this.state.removeData}
                /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default DocOBGYNHistory;
import React, { Component } from "react";
import '../IpPatientDetails.css';
import { Box, Paper, TextField, Button, Stack, Grid, Autocomplete, InputAdornment } from "@mui/material";
import { withTranslation } from 'react-i18next';
import { CommonPatientDetails, CommonPatientCard, ConsultantPatientCard } from "../../../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../../../Utility/ImagePaths";
import { Colors } from "../../../../../Styles/Colors";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../../Utility/API/Serviceurls";
import { getCachevalue, localGetItem } from "../../../../../Utility/Services/CacheProviderService";
import { t } from "i18next";
import { CheckAccessFunc } from "../../../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../../../Components/Loader';
import VoiceToText from '../../../../../Components/Common Components/VoiceToText';
import MicIcon from '@mui/icons-material/Mic';
import { grey } from '@mui/material/colors';

const enableList = ['early_morning', 'before_morning', 'mid_morning', 'lunch', 'early_evening', 'dinner', 'avoid_these', 'instruction'];
class NutritionChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientData: {},
            attenderData: {},
            selected_template: null,
            is_template_slted: false,
            template_list: [],
            id: "",
            template_name: "",
            early_morning: "",
            before_morning: "",
            mid_morning: "",
            lunch: "",
            early_evening: "",
            dinner: "",
            avoid_these: "",
            instruction: "",
            clkedFrom: "",
            is_template_save: false,
            is_save: false,
            withOutAppointment: {},
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
        }
    }
    componentDidMount() {
        let patientDetails = getCachevalue("IppatientData")
        let patients = JSON.parse(patientDetails)
        let appointmentDetail = getCachevalue("withoutAppointment");
        let details = JSON.parse(appointmentDetail);
        if (details?.patient_id === patients?.patient_id) {
            this.setState({
                withOutAppointment: details
            }, () => {
                this.getNutriChartDetails();
                this.getPatientData();
            })
        }
        this.setState({
            patientData: patients
        }, () => {
            this.getNutriTemplateDetails();
            this.getNutriChartDetails();
            this.getPatientData();
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getPatientData = () => {
        this.setState({
            attenderData: {}
        })
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${admission_id}`;
        if (this.props.is_From_IpNotes && this.state.clinic_id) {
            url = `${url}&clinic_id=${this.state.clinic_id}`
        }
        try {
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            attenderData: response.data.data[0]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMsgFunc(error.response.data.message)
                    } else {
                        this.errorMsgFunc(error.message)
                    }
                })
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    getNutriChartDetails = () => {
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        try {
            RestAPIService.getAll(`${Serviceurls.IP_DOC_NUTRITION_CHART}?ip_admission_id=${admission_id}`)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response.data.data[0];
                        this.setState({
                            id: data?.id,
                            early_morning: data?.early_morning,
                            before_morning: data?.before_morning,
                            mid_morning: data?.mid_morning,
                            lunch: data?.lunch,
                            early_evening: data?.early_evening,
                            dinner: data?.dinner,
                            avoid_these: data?.avoid_these,
                            instruction: data?.instruction,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    } else {
                        this.errorMsgFunc(error.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    getNutriTemplateDetails = () => {
        const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
        try {
            RestAPIService.getAll(`${Serviceurls.IP_DOC_NUTRITION_CHART_TEMPLATE}?doctor_id=${myUser?.doctor_id || this.state.patientData?.doctor_id}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            template_list: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    } else {
                        this.errorMsgFunc(error.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    getNutriPrint = () => {
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        try {
            RestAPIService.getAll(`${Serviceurls.IP_DOC_NUTRITION_CHART}?ip_admission_id=${admission_id}&export_type=${"pdf"}`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMsgFunc(error.response.data.message)
                    } else {
                        this.errorMsgFunc(error.message)
                    }
                })
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            is_template_save: false,
            is_save: false,
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            is_template_save: false,
            is_save: false,
        })
    }

    handleClear = () => {
        this.setState(
            {
                template_name: "",
                selected_template: null,
                is_template_slted: false,
                early_morning: "",
                before_morning: "",
                mid_morning: "",
                lunch: "",
                early_evening: "",
                dinner: "",
                avoid_these: "",
                instruction: "",
                is_template_save: false,
                is_save: false,
                clkedFrom: ""
            }
        )
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        value = value.trimStart();
        if ((name !== "template_name" && value.length > 250) || value === "") {
            value = value.substring(0, 250);
        }
        this.setState({
            [name]: value
        })
    }

    listenPopUp = () => {
        this.setState({
            listenPopUp: false
        })
    }

    voiceToTextgetter = (textStorage) => {
        if (textStorage) {
            const { clkedFrom } = this.state;
            if (clkedFrom) {
                this.setState(prevState => {
                    const newValue = prevState[clkedFrom]
                        ? prevState[clkedFrom] + " " + textStorage
                        : textStorage;
                    const finalValue = newValue?.length > 250 ? newValue.substring(0, 250) : newValue;
                    return {
                        [clkedFrom]: finalValue
                    };
                });
            }
        } else {
            this.setState({ listenPopUp: false });
        }
    }

    renderVoiceTexbox = (label, statekey) => {
        const { t } = this.props
        let states = this.state;
        return (
            <TextField
                sx={{ width: '100%' }}
                multiline
                rows={2}
                label={t(label)}
                name={statekey}
                value={states[statekey] ? states[statekey] : ""}
                variant="outlined"
                onChange={this.handleChange}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <MicIcon sx={{ color: grey[1000], cursor: "pointer" }}
                            onClick={() => { this.setState({ listenPopUp: true, clkedFrom: statekey }) }} />
                    </InputAdornment>
                }}
            />)
    }

    renderTextBox(label, statekey, isDisabled = false) {
        const { t } = this.props;
        let { selected_template, template_list } = this.state;
        let states = this.state;
        return (
            <div id="eMed_srvConfig_divs">
                {statekey === "selected_template" ?
                    <Autocomplete
                        disableClearable
                        value={selected_template ? selected_template : null}
                        sx={{ width: "18dvw", fontSize: "0.9vw" }}
                        size="small"
                        options={template_list?.length > 0 ? [...template_list] : []}
                        getOptionLabel={option => typeof (option) === "string" ? option : option.template_name}
                        onChange={(e, value) => {
                            this.setState({
                                selected_template: value,
                                is_template_slted: true,
                                template_name: value.template_name,
                                early_morning: value.early_morning,
                                before_morning: value.before_morning,
                                mid_morning: value.mid_morning,
                                lunch:value.lunch,
                                early_evening: value.early_evening,
                                dinner: value.dinner,
                                avoid_these: value.avoid_these,
                                instruction: value.instruction,
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(label)}
                                autoComplete="off"
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                    />
                    : <TextField
                        sx={{ width: "18dvw" }}
                        size="small"
                        autoComplete="off"
                        label={t(label)}
                        name={statekey}
                        value={states[statekey] ? states[statekey] : ""}
                        disabled={isDisabled}
                        onChange={this.handleChange}
                    />}
            </div>
        )
    }

    handleTemplateSave = () => {
        try {
            let states = this.state;
            let isDataAvailable = enableList.some(key => states[key]);
            if (isDataAvailable && states.template_name) {
                let data = {
                    "doctor_id": states?.patientData?.doctor_id,
                    'template_name': CommonValidation.removeSpace(states.template_name),
                    'early_morning': CommonValidation.removeSpace(states.early_morning),
                    'before_morning': CommonValidation.removeSpace(states.before_morning),
                    'mid_morning': CommonValidation.removeSpace(states.mid_morning),
                    'lunch': CommonValidation.removeSpace(states.lunch),
                    'early_evening': CommonValidation.removeSpace(states.early_evening),
                    'dinner': CommonValidation.removeSpace(states.dinner),
                    'avoid_these': CommonValidation.removeSpace(states.avoid_these),
                    'instruction': CommonValidation.removeSpace(states.instruction),
                }
                if (states.is_template_slted) { data["id"] = states?.selected_template?.id }
                RestAPIService.create(data, Serviceurls.IP_DOC_NUTRITION_CHART_TEMPLATE).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.getNutriTemplateDetails()
                            this.successMsgFunc(response.data.message)
                            this.setState({
                                // template_name: "",
                                is_template_slted: false,
                                selected_template: null
                            }
                            )
                        } else {
                            this.errorMsgFunc(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMsgFunc(error.response.data.message)
                        } else {
                            this.errorMsgFunc(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    handleSave = () => {
        try {
            let states = this.state;
            let isDataAvailable = enableList.some(key => states[key]);
            if (isDataAvailable) {
                let data = {
                    "id": states?.id,
                    'ip_admission_id': states?.patientData?.id,
                    "doctor_id": states?.patientData?.doctor_id,
                    'early_morning': CommonValidation.removeSpace(states.early_morning),
                    'before_morning': CommonValidation.removeSpace(states.before_morning),
                    'mid_morning': CommonValidation.removeSpace(states.mid_morning),
                    'lunch': CommonValidation.removeSpace(states.lunch),
                    'early_evening': CommonValidation.removeSpace(states.early_evening),
                    'dinner': CommonValidation.removeSpace(states.dinner),
                    'avoid_these': CommonValidation.removeSpace(states.avoid_these),
                    'instruction': CommonValidation.removeSpace(states.instruction),
                }
                RestAPIService.create(data, Serviceurls.IP_DOC_NUTRITION_CHART).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.handleClear();
                            this.setState({id: ""})
                            this.getNutriChartDetails();
                            this.successMsgFunc(response.data.message)
                        } else {
                            this.errorMsgFunc(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMsgFunc(error.response.data.message)
                        } else {
                            this.errorMsgFunc(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        const checkuserAccess = this.props.is_From_IpNotes ? false :
            CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Nutrition Chart", "SubTab")?.editAccess ?
                this.state.withOutAppointment?.is_ip : true;

        let data = {
            lineOne: t("AttenderName"),
            lineTwo: `${states["attenderData"]["attender_name"] ? states["attenderData"]["attender_name"] : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${states["attenderData"]["attender_mobile"] ? states["attenderData"]["attender_mobile"] : "-"}`
        }

        let Consultant = {
            image: ImagePaths.Doctor_Icon.default,
            width: "2.3vw",
            height: "2.3vw",
            lineOne: t("ConsultantName"),
            lineTwo: `${states["attenderData"]["doctor_name"] ? states["attenderData"]["doctor_name"] : "-"}`
        }
        let isTemplateEnable = enableList?.some(key => states[key]);
        return (
            <Box id="eMed_medication_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container className="eMed_medication_Box" >
                    <Stack className="eMed_medicationCard_header" direction="row" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
                        <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
                            <CommonPatientDetails data={this.state.patientData} showDetailed={true} />
                        </Stack>
                        <CommonPatientCard details={data} showDetailed={true} />
                        <ConsultantPatientCard name={Consultant} width={"20dvw"}/>
                        </Stack>
                        <Button className='eMed_usrconf_btn' onClick={() => {this.getNutriPrint()}}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Stack>
                    <Paper className="eMed_medication_Div" elevation={2}>
                        <div className="eMed_lab_subDiv">
                            <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_recPayment_rightHead_txt'>{t("Nutrition Chart")}</p>
                                {this.renderTextBox("Select Template", "selected_template")}
                            </div>
                            <Stack className="emedhub_medication_RightContentBox" direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" >
                                    {this.renderTextBox("Template Name", "template_name", !isTemplateEnable)}
                                    <Button
                                        disabled={(checkuserAccess || this.state.is_template_save || !isTemplateEnable || !states.template_name)}
                                        variant='contained' size="small" id="eMed_recPayment_btn"
                                        onClick={() => {
                                            this.setState({ is_template_save: true, },
                                                () => { this.handleTemplateSave() })
                                        }}>{t("Save")}</Button>
                                </Stack>
                                <div className="eMed_nutri_txtDiv">
                                    {this.renderVoiceTexbox("Early Morning", "early_morning")}
                                    {this.renderVoiceTexbox("Before Morning", "before_morning")}
                                    {this.renderVoiceTexbox("Mid Morning", "mid_morning")}
                                    {this.renderVoiceTexbox("Lunch", "lunch")}
                                    {this.renderVoiceTexbox("Early Evening", "early_evening")}
                                    {this.renderVoiceTexbox("Dinner", "dinner")}
                                    {this.renderVoiceTexbox("Avoid These", "avoid_these")}
                                    {this.renderVoiceTexbox("Instruction", "instruction")}
                                </div>
                            </Stack>
                        </div>
                        <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                            <Button variant='outlined' disabled={this.state.withOutAppointment?.is_ip} size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                            <Button
                                disabled={checkuserAccess || this.state.is_save || !isTemplateEnable}
                                variant='contained' size="small" id="eMed_recPayment_btn"
                                onClick={() => {
                                    this.setState({ is_save: true, },
                                        () => { this.handleSave() })
                                }}>{t("Save")}</Button>
                        </Stack>
                    </Paper>
                </Grid>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.listenPopUp ?
                    <VoiceToText
                        voiceTextConverter={this.voiceToTextgetter.bind(this)}
                        listenPopUp={this.listenPopUp.bind(this)}
                    /> : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(NutritionChart);
import { Box, Button, Drawer, IconButton, Paper, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Colors } from '../../../Styles/Colors';
import PharmaCreditBilling from './PharmaCreditPendingBilling';
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CommonGridToolBarWithFilterText, CommonPatientCard, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import InvoiceFilter from './InvoiceFilter';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { returnPaymentMode } from '../../../Components/Common Components/PharmaCommonFunctions';
class PharmaCreditBillsSplit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompletedBillList: [],
      SelectedTab: "pending",
      SelectedCreditBill: this.props.history?.location?.state?.SelectedCreditBill ? this.props.history?.location?.state?.SelectedCreditBill : {},
      TotalAmount: 0,
      PaidAmount: 0,
      PendingAmount: 0,
      PaymentMode: "",
      FilterDatas: this.props.history?.location?.state?.FilterDatas ? this.props.history?.location?.state?.FilterDatas : {},
      isAPIcall: false,
      PatientDetails: []
    }
  }

  componentDidMount() {
    this.GetPatientDetails()
  }

  GetPatientDetails() {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_PATIENT_DETAILS_GET + "?patient_id=" + this.state?.SelectedCreditBill?.patient_id )
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              PatientDetails: response.data.data
            })
          }
          else {
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  render() {
    let { history } = this.props
    let { t } = this.props
    return (
      <div>
        <div className='eMed_tabWrapper' style={{ alignItems: "center" }}>
          <Tooltip title="Back" placement='top' arrow>
            <Button emed_tid="back_credit_bills" className='eMed_Filter_Btns' onClick={() => { history.push({ pathname: "/PharmacyInvoice/CreditBills" }) }} startIcon={<ChevronLeftIcon />}>
              Back to Credit Bill
            </Button>
          </Tooltip>
        </div>
        <div>
           <PharmaCreditBilling history={this.props?.history} SelectedCreditBill={this.state?.SelectedCreditBill} PatientDetails={this.state?.PatientDetails} FilterDatas={this.state?.FilterDatas}/>
        </div>
      </div>
    )
  }
}
export default withTranslation()(PharmaCreditBillsSplit)
import { Box } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import NurseNotes from '../../../OutPatients/PatientDetails/NurseNotes'
import '../IpPatientDetails.css'

class Vitals extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Box>
        <NurseNotes
          fromVitals={true}
          is_From_IpNotes = {this.props.is_From_IpNotes }
        />
      </Box>
    )
  }
}

export default withTranslation()(Vitals)
import React, { Component } from 'react'
import './Config.css';
import { Button, Stack, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Autocomplete, IconButton, Tooltip, Box, InputAdornment } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import SearchIcon from '@mui/icons-material/Search';
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
class CultureTestConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      selectedIndex: '',
      SearchText: "",
      AntibioticList: [],
      AntibioticData: null,
      BacteriaList: [],
      BacteriaData: null,
      CulturalTestList: [],
      ZoneSize: {
        Z_severity: "",
        Z_intermediate: "",
        Z_resistant: ""
      },
      MICPoint: {
        M_severity: "",
        M_intermediate: "",
        M_resistant: ""
      }
    }
  }

  componentDidMount() {
    this.getCultureTestData()
    this.getBacteriaList()
    this.getAntiBacteriaList()
  }

  getCultureTestData = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_CULTURE_TEST_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ CulturalTestList: response.data.data })
          }
        }).catch((e) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        })
    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  getBacteriaList = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_BACTERIA_GET).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ BacteriaList: response.data.data })
          }
        }).catch((e) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        })
    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  getAntiBacteriaList = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_ANTI_BACTERIA_GET).
        then((response) => {
          this.setState({ AntibioticList: response.data.data })
        }).catch((error) =>
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.message
          }))
    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  postCultureTestData = () => {
    var states = this.state
    try {
      var data = {
        "bacteria_id": states.BacteriaData.id,
        "antibiotic_id": states.AntibioticData.id,
        "zone_size_s": states.ZoneSize.Z_severity,
        "zone_size_i": states.ZoneSize.Z_intermediate,
        "zone_size_r": states.ZoneSize.Z_resistant,
        "mic_s": states.MICPoint.M_severity,
        "mic_i": states.MICPoint.M_intermediate,
        "mic_r": states.MICPoint.M_resistant
      }
      if (this.state.selectedEditID) {
        data['id'] = this.state.selectedEditID
      }
      RestAPIService.create(data, Serviceurls.LAB_CULTURE_TEST_CONFIG)
        .then(response => {
          if (response.data.status == "success") {
            this.setState({
              successMsg: true,
              successMsgText: response.data.message
            }, () => {
              this.getCultureTestData()
              this.clearValue()
            })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message
          })
        })
    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  renderTextBox = (label, width, value, name) => {
    let states = this.state
    return (
      <div>
        <TextField
          size={'small'}
          sx={{ width: width }}
          label={label}
          name={name}
          value={value}
          variant="outlined"
          onChange={(e) => {
            let value = e.target.value
            let names = e.target.name
            if ((names == "Z_severity" || names == "Z_intermediate" || names == "Z_resistant") && (value.length <= 10 && !(/\s/.test(value)))) {
              states.ZoneSize[name] = value
              this.setState({ states })
            } else if ((names == "M_severity" || names == "M_intermediate" || names == "M_resistant") && (value.length <= 10 && !(/\s/.test(value)))) {
              states.MICPoint[name] = value
              this.setState({ states })
            }
          }}
        />
      </div>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
    })
  }

  onSave = () => {
    var inValid = this.validCheck()
    if (inValid) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: inValid
      })
    } else {
      this.postCultureTestData()
    }
  }

  validCheck = () => {
    const { t } = this.props
    let { ZoneSize, MICPoint } = this.state
    var saveErrorMsg = ''
    var states = this.state
    const isDuplicate = states.CulturalTestList.some(
      (detail) => detail.bacteria === states.BacteriaData.id && detail.antibiotic === states.AntibioticData.id
    );
    if (!isDuplicate || states.selectedIndex != "") {
      if (states.BacteriaData == null) {
        saveErrorMsg = t('Select Bacteria Name')
      } else if (states.AntibioticData == null) {
        saveErrorMsg = t('Select Anti-Biotic Name')
      } else if ((states.BacteriaData != null && states.AntibioticData != null) &&
        ((ZoneSize.Z_severity == "" && ZoneSize.Z_intermediate == "" && ZoneSize.Z_resistant == "" &&
          MICPoint.M_severity == "" && MICPoint.M_intermediate == "" && MICPoint.M_resistant == ""))) {
        saveErrorMsg = t('Enter Zone Size Value or MIC Break Point Value')
      }
    } else {
      saveErrorMsg = t('This Bacteria and Anti-Biotic Combination Already Exist')
    }
    return saveErrorMsg
  }

  editCultureTestData = (item, index) => {
    var bacteriaData = {}; this.state.BacteriaList.forEach((element) => { if (element.id == item.bacteria) { bacteriaData = element } })
    var antibioticData = {}; this.state.AntibioticList.forEach((element) => { if (element.id == item.antibiotic) { antibioticData = element } })
    this.setState({
      selectedEditID: item.id,
      selectedIndex: index,
      AntibioticData: antibioticData,
      BacteriaData: bacteriaData,
      ZoneSize: {
        Z_severity: item.zone_size_s,
        Z_intermediate: item.zone_size_i,
        Z_resistant: item.zone_size_r
      },
      MICPoint: {
        M_severity: item.mic_s,
        M_intermediate: item.mic_i,
        M_resistant: item.mic_r
      }
    })
  }

  deleteCultureData = (ID) => {
    try {
      RestAPIService.deleteAll(Serviceurls.LAB_CULTURE_TEST_CONFIG + "?id=" + ID)
        .then(response => {
          if (response.data.status == "success") {
            this.setState({
              successMsg: true,
              successMsgText: response.data.message
            }, () => {
              this.getCultureTestData()
            })
          }
        }).catch((e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message
          })
        }))

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  clearValue = () => {
    this.setState({
      AntibioticData: null,
      BacteriaData: null,
      ZoneSize: {
        Z_severity: "",
        Z_intermediate: "",
        Z_resistant: ""
      },
      MICPoint: {
        M_severity: "",
        M_intermediate: "",
        M_resistant: ""
      },
      selectedEditID: "",
      selectedIndex: ""
    })
  }

  PopupClose = () => {
    this.setState({
      deletedPopup: false
    })
  }
  removeDataList = () => {
    this.deleteCultureData(this.state.deletedapiID)
    this.setState({
      deletedPopup: false
    })
  }

  renderLeftTable = () => {
    var states = this.state
    const { t } = this.props
    const filtereddata = this.state.CulturalTestList?.filter((data) => {
      var bacteriaName = ""; this.state.BacteriaList.forEach((element) => { if (element.id == data.bacteria) { bacteriaName = element.name } })
      var antibioticName = ""; this.state.AntibioticList.forEach((element) => { if (element.id == data.antibiotic) { antibioticName = element.name } })
      return (
        (bacteriaName === null ? null : bacteriaName.toLowerCase().includes(this.state.SearchText?.toLowerCase())) ||
        (antibioticName === null ? null : antibioticName.toLowerCase().includes(this.state.SearchText?.toLowerCase()))
      );
    });
    return (
      <div>
        <Paper elevation={0}>
          <Box className="eMed_Search_Box_">
            <TextField
              variant='standard'
              placeholder='Search'
              value={this.state.SearchText}
              onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '15vw' }} />
          </Box>
          <TableContainer className='eMed_ConfigTable_Paper'>
            <Table stickyHeader size='small'>
              <TableHead sx={{ height: '7.3vh' }}>
                <TableRow>
                  <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("Bacteria")}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("Antibiotic")}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>
                    <div style={{ alignItems: "center", justifyContent: "center" }}>
                      <div>{"Zone size"}</div>
                      <div>{"S | I | R"}</div>
                    </div>
                  </TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>
                    <div style={{ alignItems: "center", justifyContent: "center" }}>
                      <div>{"MIC"}</div>
                      <div>{"S | I | R"}</div>
                    </div>
                  </TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                .map((data, index) => {
                  var bacteriaName = ""; this.state.BacteriaList.forEach((element) => { if (element.id == data.bacteria) { bacteriaName = element.name } })
                  var antibioticName = ""; this.state.AntibioticList.forEach((element) => { if (element.id == data.antibiotic) { antibioticName = element.name } })
                  var sno = (states.page * states.rowsPerPage) + (index + 1)
                  return (
                    <TableBody>
                      <TableRow sx={{ bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white' }}>
                        <TableCell align='left'><div>{sno}</div></TableCell>
                        <TableCell align='left'>
                          {bacteriaName?.length > 13 ?
                            <Tooltip placement="top" title={bacteriaName} arrow>
                              <div >{(bacteriaName ? bacteriaName.slice(0, 13) + '..' : '-')}</div>
                            </Tooltip> : <div> {(bacteriaName ? bacteriaName : '-') + "-"}</div>}</TableCell>
                        <TableCell align='left'>
                          {antibioticName?.length > 13 ?
                            <Tooltip placement="top" title={antibioticName} arrow>
                              <div >{(antibioticName ? antibioticName.slice(0, 13) + '..' : '-')}</div>
                            </Tooltip> : <div> {antibioticName ? antibioticName : '-'}</div>}</TableCell>
                        <TableCell align='center'>
                          <div>
                            <span>{data.zone_size_s?.length > 5 ? <Tooltip placement="top" title={data.zone_size_s} arrow>{data.zone_size_s ? data.zone_size_s.slice(0, 4) + '..' : "-"}</Tooltip> : (data.zone_size_s ? data.zone_size_s : "-")}</span> |
                            <span>{data.zone_size_i?.length > 5 ? <Tooltip placement="top" title={data.zone_size_i} arrow>{data.zone_size_i ? data.zone_size_i.slice(0, 4) + '..' : "-"}</Tooltip> : (data.zone_size_i ? data.zone_size_i : "-")}</span> |
                            <span>{data.zone_size_r?.length > 5 ? <Tooltip placement="top" title={data.zone_size_r} arrow>{data.zone_size_r ? data.zone_size_r.slice(0, 4) + '..' : "-"}</Tooltip> : (data.zone_size_r ? data.zone_size_r : "-")}</span>
                          </div>
                        </TableCell>
                        <TableCell align='center'>
                          <div>
                            <span>{data.mic_s?.length > 5 ? <Tooltip placement="top" title={data.mic_s} arrow>{data.mic_s ? data.mic_s.slice(0, 4) + '..' : "-"}</Tooltip> : (data.mic_s ? data.mic_s : "-")}</span> |
                            <span>{data.mic_i?.length > 5 ? <Tooltip placement="top" title={data.mic_i} arrow>{data.mic_i ? data.mic_i.slice(0, 4) + '..' : "-"}</Tooltip> : (data.mic_i ? data.mic_i : "-")}</span> |
                            <span>{data.mic_r?.length > 5 ? <Tooltip placement="top" title={data.mic_r} arrow>{data.mic_r ? data.mic_r.slice(0, 4) + '..' : "-"}</Tooltip> : (data.mic_r ? data.mic_r : "-")}</span>
                          </div>
                        </TableCell>
                        <TableCell align='center'>
                          <div className='eMed_TestConfig_Con'>
                            <IconButton size='small'>
                              <img src={ImagePaths.Edit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                onClick={() => { this.editCultureTestData(data, index) }}
                              />
                            </IconButton>
                            <IconButton size='small'>
                              <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                onClick={() => {
                                  this.setState({
                                    deletedPopup: true,
                                    deletedapiID: data.id
                                  })
                                }}
                              />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                })
              }
            </Table>
            {filtereddata.length === 0 ?
              <div className='eMed_TestConfig_NoRecords'>
                {t('NoRecordsShown')}
              </div> : null}
          </TableContainer>
        </Paper>
        <div className='eMed_TestConfig_LeftPageination'>
          <TablePagination
            component='div'
            id="eMed_tbl_pagination"
            rowsPerPageOptions={[10, 20, 30]}
            count={filtereddata.length}
            rowsPerPage={states.rowsPerPage}
            page={states.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    )
  }


  render() {
    const { t } = this.props
    let { ZoneSize, MICPoint } = this.state
    return (
      <div className='eMed_TestConfig_MainContainer'>
        <div className='eMed_TestConfig_Con'>
          <div className='eMed_TestConfig_leftSide'>
            {this.renderLeftTable()}
          </div>
          <div className='eMed_TestConfig_RightSide'>
            <div className='eMed_TestConfig_RightHead'>
              <p className='eMed_Testconfig_RightHead_text'>{t('Combination')}</p>
            </div>
            <div className='eMed_CulturalConfig_RightContentBox'>
              <div style={{ display: "flex", flexDirection: "row", padding: "1vw" }}>
                <Autocomplete
                  size='small'
                  options={this.state.BacteriaList}
                  getOptionLabel={(option) => (option?.name)}
                  value={this.state.BacteriaData}
                  onChange={(event, newValue) => {
                    this.setState({ BacteriaData: newValue })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
                      label={t('Bacteria Name*')}
                    />
                  )}
                />
                <Autocomplete
                  size='small'
                  options={this.state.AntibioticList}
                  getOptionLabel={(option) => (option?.name)}
                  value={this.state.AntibioticData}
                  onChange={(event, newValue) => {
                    this.setState({ AntibioticData: newValue })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "15vw" }}
                      label={t('Antibiotic Name*')}
                    />
                  )}
                />
              </div>
            </div>
            <div className='eMed_TestConfig_RightHead'>
              <p className='eMed_Testconfig_RightHead_text'>{t('Zone Size (mm)')}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1vw" }}>
              {this.renderTextBox(t("S"), '8.5vw', ZoneSize.Z_severity, 'Z_severity')}
              {this.renderTextBox(t("I"), '8.5vw', ZoneSize.Z_intermediate, 'Z_intermediate')}
              {this.renderTextBox(t("R"), '8.5vw', ZoneSize.Z_resistant, 'Z_resistant')}
            </div>
            <div className='eMed_TestConfig_RightHead'>
              <p className='eMed_Testconfig_RightHead_text'>{t('MIC Break Point')}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", padding: "1vw", height: "26vh" }}>
              {this.renderTextBox(t("S"), '8.5vw', MICPoint.M_severity, 'M_severity')}
              {this.renderTextBox(t("I"), '8.5vw', MICPoint.M_intermediate, 'M_intermediate')}
              {this.renderTextBox(t("R"), '8.5vw', MICPoint.M_resistant, 'M_resistant')}
            </div>
            <div className='eMed_TestConfig_RightBtnCon'>
              <Stack spacing={2} direction="row">
                <Button id='eMed_Btn_Text' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                <Button id='eMed_Btn_Text' variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
              </Stack>
            </div>
          </div>
        </div>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletedPopup ?
          <DeletePopup DeletTitle={t("Are You Sure? Do you want to remove the Culture Test")}
            DeleteNotify={t("effectEntireConfig")}
            DeletContent={t("The Culture Test is linked with various Configuration")}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
          />
          : null}
      </div>
    )
  }
}
export default withTranslation()(CultureTestConfig)
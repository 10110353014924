import React, { Component } from "react";
import './IpDemograhics.css';
import { Box, Grid, Paper, Stack, Tooltip, IconButton, Typography, Button, Menu, MenuItem } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { CommonPatientDetails, CommonPatientCard } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Colors } from "../../../../Styles/Colors";
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import { getCachevalue, setCachevalue } from "../../../../Utility/Services/CacheProviderService";
import { t } from "i18next";
import MoreVertIcon from '@mui/icons-material/MoreVert';

class AdmissionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientData: {},
      admissionDetail: [],
      isErrorMsg: false,
      isErrorMsgText: "",
      successMsg: false,
      successMsgText: "",
      anchorEl: null,
    }
  }
  componentDidMount() {
    let patientDetails = getCachevalue("IppatientData")
    let patients = JSON.parse(patientDetails)
    this.setState({
      patientData: patients
    }, () => {
      this.getAdmissionDetails();
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  // Api calls start

  getAdmissionDetails = () => {
    this.setState({
      admissionDetail: []
    })
    try {
      RestAPIService.getAll(`${Serviceurls.ADMISSION_HISTORY_GET}?patient_id=${this.state.patientData["patient_id"]}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              admissionDetail: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  // Api calls End

  handleView = (data) => {
    let { history } = this.props;
    let detail = `${data["service_type"]}-${data["status"]}`;
    switch (detail) {
      case "IP-Admitted":
        setCachevalue(JSON.stringify(data), "withoutAppointment");
        history.push({ pathname: "/IPHome/PatientDetails/PatientDetails" });
        break;
      case "IP-Checked Out":
        setCachevalue(JSON.stringify(data), "withoutAppointment");
        history.push({ pathname: "/IPHome/Billing/BillingDetails" });
        break;
      case "OP-Completed":
        setCachevalue(JSON.stringify(data), "withoutAppointment");
        history.push({ pathname: "/OPHome/PatientDetails/DoctorVisitReports" });
        break;
    }
  }

  getPatientBillDetailedPrint = (params) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_PATIENT_BILL_DETAILED_PRINT + "?ip_admission_id=" + params?.ip_admission_id + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.reponse.data.message
          })
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  printDisSum(data) {
    try {
      var states = this.state
      var patientID = data.patient_id ? data.patient_id : null
      var admissionID = data.ip_admission_id ? data.ip_admission_id : null
      RestAPIService.PrintPDF(Serviceurls.IP_DIS_SUM_PRINT + "?ip_admission_id=" + admissionID + "&patient_id=" + patientID + "&export=pdf")
        .then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((e) => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  selectedListName = (item, data) => {
    try {
      switch (item.value) {
        case "DischargeSummary":
          this.printDisSum(data)
          break;
        case "BillDetails":
          this.getPatientBillDetailedPrint(data)
          break;
        default:
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  MenuItem = (data) => {
    const { t } = this.props
    let rowID = data.service_type === "OP"  ? data.appointment_id : data?.ip_admission_id
    const list = [
      { value: "DischargeSummary", label: t("DischargeSummary") },
      { value: "BillDetails", label: t("BillDetails") },
    ]
    return (
      <div>
        {data.service_type !== "OP" ? 
        <Tooltip title={t("More")} placement='top' arrow>
        <Button
          className='eMed_vital_more'
          onClick={(e) => {
            this.setState({
              anchorEl: e.currentTarget,
              selectedRow: data.service_type === "OP"  ? data.appointment_id : data?.ip_admission_id,
              isSelected: true
            })
          }}
        >
          <MoreVertIcon />
        </Button>
      </Tooltip> : null}
        
        {(this.state.isSelected && this.state.selectedRow == rowID) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {list.map((item, index) => (
              <MenuItem key={item}
                disabled={data.service_type === "OP" && data.status !== "Discharged" ? true : false}
                onClick={() => {
                  this.setState({ anchorEl: null }, () => {
                    this.selectedListName(item, data)
                  })
                }}>{item.label}</MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  render() {
    const { t } = this.props;
    let states = this.state;
    let attenderData = states["admissionDetail"] && states["admissionDetail"][0];
    let data = {
      lineOne: t("Emergency Contact"),
      lineTwo: `${attenderData?.attender_name ? attenderData?.attender_name : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${attenderData?.attender_mobile ? attenderData?.attender_mobile : "-"}`
    }
    states.admissionDetail.length > 0 && states.admissionDetail.forEach((element, index) => element.sno = index + 1);
    const columns = [
      {
        field: 'date', headerAlign: "center", headerName: `${t('Appointment')} ${t('Date')} & ${t('Time')}`, flex: 0.18, align: "center", renderCell: ({ row }) => {
          let date = `${row.appointment_date ? row.appointment_date : "-"} | ${row.appointment_start_time ? row.appointment_start_time : "-"}`
          return (<Typography>{date ? date : "-"}</Typography>)
        }
      },
      {
        field: 'service_type', headerName: t('ServiceType'), flex: 0.18, renderCell: ({ row }) => {
          let service_type = `${row.service_type === "OP" ? t("Out Patient") : t("In Patient")}`
          return (
            <div>{service_type?.length > 18 ? <Tooltip placement="top" title={service_type} arrow><div>{service_type.slice(0, 16) + "..."}</div></Tooltip> : service_type ? service_type : "-"}</div>
          )
        }
      },
      {
        field: 'doctor_name', headerName: `${t('Consultant')} ${t('Doctor')}`, flex: 0.20, renderCell: ({ row }) => {
          return (<div>{row.doctor_name?.length > 18 ? <Tooltip placement="top" title={row.doctor_name} arrow><div>{row.doctor_name.slice(0, 16) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}</div>)
        }
      },
      {
        field: 'nurse_name', headerName: `${t('Consultant')} ${t('Nurse')}`, flex: 0.18, renderCell: ({ row }) => (
          <div>{row.nurse_name?.length > 18 ? <Tooltip placement="top" title={row.nurse_name} arrow><div>{row.nurse_name.slice(0, 16) + "..."}</div></Tooltip> : row.nurse_name ? row.nurse_name : "-"}</div>)
      },
      {
        field: 'status', headerName: t('Status'), flex: 0.14, renderCell: ({ row }) => {
          let status = row["status"] === "Admitted" ? t("In IP Care") : row["status"] === 'Checked Out' ? t("Discharged") : row["status"] === 'Completed' ? 'Completed' : row["status"] ? row["status"] : "-";
          return (<Typography sx={{ color: row["status"] === "Admitted" ? Colors.BillInProcessTxt : (row["status"] === 'Checked Out' || row["status"] === 'Completed') ? Colors.BillCompleteTxt : null, fontSize: "0.9vw", fontWeight: 600 }}>{status ? status : "-"}</Typography>)
        }
      },
      {
        field: 'actions', headerAlign: "center", headerName: t('Action'), flex: 0.12, sortable: false, hideable: false, align: "center", renderCell: ({ row }) => {
          let disable = (row["status"] !== "Admitted" && row["status"] !== 'Checked Out' && row["status"] !== 'Completed');
          return (<><Tooltip placement="top" title={t("View")} arrow>
            <IconButton size="small" disabled={disable} onClick={() => this.handleView(row)}><img className="eMed_medication_Img" src={disable ? ImagePaths.ViewButtonOff.default : ImagePaths.LabViewButton.default} alt="View" /></IconButton>
          </Tooltip>
            {this.MenuItem(row)}
          </>
          )
        }
      }
    ]

    return (
      <Box id="eMed_AdHistory_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container className="eMed_AdHistory_Box" >
          <Stack className="eMed_AdHistory_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
            <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
              <CommonPatientDetails data={this.state.patientData} />
            </Stack>
            <CommonPatientCard details={data} showDetailed={true} />
          </Stack>
          <Paper className="eMed_AdHistory_Div" elevation={2}>
            <DataGrid
              rows={states["admissionDetail"]}
              columns={columns}
              getRowId={(row) => row.sno}
              components={{
                NoRowsOverlay: () => {
                  return (
                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                      {t("NoRecordsFound")}
                    </Stack>
                  )
                }
              }}
              headerHeight={40}
            />
          </Paper>
        </Grid>

        {this.state.isErrorMsg ? <ToastMsg severity={'error'} msg={this.state.isErrorMsgText} msgPop={this.msgClose.bind(this)} /> : null}

      </Box>
    )
  }
}
export default withTranslation()(AdmissionHistory);

import React, { Component } from 'react';
import '../../pharmaReports.css';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Drawer, IconButton,Button, Paper } from '@mui/material';
import { Colors } from '../../../../../Styles/Colors';
import { AmountsCard } from '../../../../../Components/CommonCards/CommonCards';
import { CommonGridToolBarWithFilterText } from '../../../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate } from '../../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../../Utility/Constants';

class DayWiseGSTSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DataList: [],
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
      },
      totalTaxAmt: 0,
      totalGstAmt: 0,
      totalDiscAmt: 0,
      pageName: 'DayWiseGSTSummary',
      status: this.props.status ? this.props.status : "gst_summary",
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    }
  }

  componentDidMount() {
    this.getDayWiseGSTSummary()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      totalCount:0,
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
      page: 0,
    }, () => {
      this.getDayWiseGSTSummary()
    })
  }

  getDayWiseGSTSummary = () => {
    let { status, filterDatas, page, pageSize } = this.state;
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(`${Serviceurls.DAY_HSN_GSTSUMMARY}?status=${status}&page=${page + 1}&page_size=${pageSize}&from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}`)
        .then((response) => {
          if (response.data.status === "success") {
            let list = "data" in response.data && response.data?.data?.length > 0 ? response.data.data : [];
            list.length > 0 && list?.forEach((item, i) => {
              item["sno"] = i + 1;
            })
            this.setState({
              DataList: list,
              totalTaxAmt: response.data?.total_taxable_amount ? response.data.total_taxable_amount : 0,
              totalGstAmt: response.data?.total_gst_amount ? response.data.total_gst_amount : 0,
              totalDiscAmt: response.data?.total_discount_amount ? response.data.total_discount_amount : 0,
              totalCount: response.data?.total_count
            }, ()=>{ this.LoaderFunction(false) })
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDayWisePrint = () => {
    let { status, filterDatas, page, pageSize } = this.state;
    try {
      this.setState({disabled: true}) 
      RestAPIService.getAll(`${Serviceurls.DAY_HSN_GSTSUMMARY}?status=${status}&page=${page + 1}&page_size=${pageSize}&from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&export_type=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disabled:false}) 
        }).catch((error) => {
          if(error?.response?.data?.message){
            this.errorMessage(error?.response?.data?.message)
          }else{
            this.errorMessage(error.message)
          }
          this.setState({disabled:false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disabled:false})
    }
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  renderTable = () => {
    const { t } = this.props
    let states = this.state;
    const columns = [
      {
        field: 'gst_percentage', headerName: states.status === "hsn_summary" ? t('HSNCode') : t('GST(%)'), flex: 0.2, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.gst_percentage ? row?.gst_percentage : states.status === "hsn_summary" ? "-" : 0}</div>)
      },
      {
        field: 'total_taxable_amount', headerName: t('TaxableAmount')+` (${CurrencySymbol})`, flex: 0.2, type: "number",
        renderCell: ({ row }) => (<div>{row?.total_taxable_amount ? AmountFormat(row?.total_taxable_amount).replace(`${CurrencySymbol}`, "") : 0}</div>)
      },
      {
        field: 'total_gst_amount', headerName: t('GSTAmount')+` (${CurrencySymbol})`, flex: 0.2, type: "number",
        renderCell: ({ row }) => (<div>{row?.total_gst_amount ? AmountFormat(row?.total_gst_amount).replace(`${CurrencySymbol}`, "") : 0}</div>)
      },
      {
        field: 'discount_amount', headerName: t('DiscountAmount')+` (${CurrencySymbol})`, flex: 0.2, type: "number",
        renderCell: ({ row }) => (<div>{row.discount_amount ? AmountFormat(row.discount_amount).replace(`${CurrencySymbol}`, "") : 0}</div>)
      }, {
        field: 'total_amount', headerName: t('TotalAmount')+` (${CurrencySymbol})`, flex: 0.2, type: "number",
        renderCell: ({ row }) => (<div>{row.total_amount ? AmountFormat(row.total_amount).replace(`${CurrencySymbol}`, "") : 0}</div>)
      },
    ]
    return (
      <Box component={'div'}>
        <Paper sx={{ height: '63vh' }}>
          <DataGrid
            rows={states.DataList}
            columns={columns}
            getRowId={(row) => row.sno}
            page={states.page}
            pageSize={states.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            paginationMode="server"
            onPageChange={(newPage) => this.setState({ page: newPage }, () => this.getDayWiseGSTSummary())}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getDayWiseGSTSummary())}
            rowsPerPageOptions={[10, 20, 30]}
            rowCount={this.state.totalCount}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />
        </Paper>
      </Box>
    )
  }

  render() {
    const { t } = this.props
    let states =this.state;
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard("Total Taxable Amount", this.state.totalTaxAmt, false, "white", false, null, states.status === "hsn_summary" ? "dayHsn_sum_tax_amt" : "dayGst_sum_tax_amt")}
            {AmountsCard("Total GST Amount", this.state.totalGstAmt, false, "white", false, null, states.status === "hsn_summary" ? "dayHsn_sum_gst_amt" : "dayGst_sum_gst_amt")}
            {AmountsCard("Total Discount Amount", this.state.totalDiscAmt, false, "white", false, null, states.status === "hsn_summary" ? "dayHsn_sum_dis_amt" : "dayGst_sum_dis_amt")}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <IconButton emed_tid = {states.status === "hsn_summary" ? "dayHsn_sum_flt" : "dayGst_sum_flt"} className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </IconButton>
            <Button emed_tid="DayWise_print" className='eMed_Pharma_rts_btn'  disabled={this.state.disabled} onClick={() => {this.getDayWisePrint()}}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            {/*<IconButton emed_tid = {states.status === "hsn_summary" ? "dayHsn_sum_print" : "dayGst_sum_print"} className='eMed_Pharma_rts_btn' onClick={() => { }}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
            </IconButton>
            <IconButton emed_tid = {states.status === "hsn_summary" ? "dayHsn_sum_export" : "dayGst_sum_export"} className='eMed_Pharma_rts_btn' onClick={() => { }}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </IconButton> */}
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          {this.renderTable()}
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(DayWiseGSTSummary);
import React, { Component } from 'react';
import { Typography, Button, TextField, OutlinedInput, InputAdornment, Tooltip, TablePagination } from '@mui/material';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import './home.css';
import { DateTime } from "luxon";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CircularProgress from '@mui/material/CircularProgress';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
class LabIPList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: "",
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            rowsPerPage: 5,
            page: 0,
            total_count: 0,
            IPAdmissionData: [],
            disableBtn: false,
            loginData: {}
        }
    }
    componentDidMount() {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        this.setState({
            loginData: UserData
        })
        this.getIpAdmission()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getIpAdmission() {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_LIST_LABHOME + "?search_key=" + this.state.searchkey + "&page_size=" + this.state.rowsPerPage + "&page=" + (this.state.page + 1))
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            IPAdmissionData: response.data.data,
                            total_count: response.data.total_count,
                        }, () => {
                            this.props.IPListLabCount(true)
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(e => {
                    if (e?.reponse?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.reponse.data.status)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.message)
                    }
                });
        }
        catch (e) {
            this.errorMsgFunc(e.message)
        }
    }

    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }


    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props
        const { history } = this.props.properties
        let renderList = this.state.IPAdmissionData;
        return (
            <div>
                <div style={{ display: "flex", margin: "0.5vw" }}>
                    <label style={{ flex: "0.5" }}><b>{t("IPAdmissionList")}</b></label>
                    <div style={{ flex: "0.5", display: "flex", justifyContent: "end" }}
                        onClick={() => { this.props.onClickIPListClose(false) }}>
                        <img
                            src={ImagePaths.LabCloseButton.default}
                            style={{ cursor: "pointer", height: "1vw", width: "1vw" }}
                            alt=""
                        />
                    </div>
                </div>

                <div className='eMed_Pres_Header'>
                    <OutlinedInput
                        placeholder="Search Patient..."
                        onKeyDown={(event) => {
                            if (event.key === "Enter" && (event.target.value?.length > 0 || event.target.value === '')) {
                                this.getIpAdmission()
                            }
                        }}
                        onChange={(e) => {
                            this.setState({
                                searchkey: e.target.value
                            })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "13vw", marginRight: "0.5vw" }}
                        endAdornment={
                            <InputAdornment emed_tid={"IpAdmission_patient_search_btn"} position="end" sx={{ cursor: 'pointer' }} onClick={() => { if (this.state.searchkey) { this.getIpAdmission() } }}>
                                <i class="fas fa-search"></i>
                            </InputAdornment>
                        }
                        inputProps={{ emed_tid: "IpAdmission_patient_search" }}
                    />
                </div>
                {renderList && renderList.length > 0 ?
                    <div>
                        <div className='eMed_App_DataView' style={{ width: '36vw' }}>
                            {renderList && renderList?.map((item, index) => {
                                var sno = (this.state.page * this.state.rowsPerPage) + (index + 1)
                                let RoomDetails = `${item?.bed_number ? item?.bed_number : '-'}/${item?.room_number ? item?.room_number : '-'}/${item?.ward_name ? item?.ward_name : '-'}/${item?.block_name ? item?.block_name : '-'}`
                                return (
                                    <div className="Lab_Home_Saved_Bill">
                                        <div style={{ flex: 0.10 }}>{sno}</div>
                                        <div style={{ flex: 0.15 }}><img src={ImagePaths.Male.default} alt='Avatar' /></div>
                                        <div style={{ flex: 0.33 }}>
                                            {item?.patient_name.length > 15 ?
                                                <Tooltip title={item?.patient_name} placement='top'>
                                                    <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name.slice(0, 15) + '...' : '-'}</Typography>
                                                </Tooltip> : <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name : '-'}</Typography>}
                                            <Typography sx={{ fontSize: "0.8vw", color: '#a5a7a8' }}>{item?.patient_mobile_number ? item?.patient_mobile_number : '-'}</Typography>
                                        </div>
                                        <div style={{ flex: 0.32 }}>
                                            {item?.doctor_name.length > 15 ?
                                                <Tooltip title={item?.doctor_name} placement='top'>
                                                    <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name.slice(0, 15) + '...' : '-'}</Typography>
                                                </Tooltip> : <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name : '-'}</Typography>}
                                            {RoomDetails.length > 15 ?
                                                <Tooltip title={RoomDetails} placement='top'>
                                                    <Typography sx={{ fontSize: "0.8vw", color: '#a5a7a8' }}>{RoomDetails ? RoomDetails.slice(0, 15) + '...' : '-'}</Typography>
                                                </Tooltip> : <Typography sx={{ fontSize: "0.8vw", color: '#a5a7a8' }}>{RoomDetails ? RoomDetails : '-'}</Typography>}
                                        </div>
                                        <div style={{ flex: 0.10 }}>
                                            <Button emed_tid="Appoint_to_bill" size='small' variant='contained' sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                                                disabled={this.state.disableBtn}
                                                onClick={() => {
                                                    if (this.state.loginData.module_name === 'canteen') {
                                                        this.setState({ disableBtn: true })
                                                        this.props.properties.history.push({ pathname: "/CanteenHome", state: { patientData: item } })
                                                        this.props.onClickIPListClose(false)
                                                    } else {
                                                        this.setState({ disableBtn: true })
                                                        this.props.properties.history.push({ pathname: "/MainHome", state: { Patient_ID: item?.patient_id } })
                                                        this.props.onClickIPListClose(false)
                                                    }
                                                }}
                                            >{(t('CreateBill'))}</Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <TablePagination
                            component='div'
                            id="eMed_tbl_pagination"
                            rowsPerPageOptions={[5, 10, 15]}
                            count={this.state.total_count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={(event, newPage) => { this.setState({ page: newPage }, () => { this.getIpAdmission() }); }}
                            onRowsPerPageChange={(event) => { this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => { this.getIpAdmission() }); }}
                        />
                    </div> :
                    this.state.isLoader ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36vw' }} className='eMed_App_DataView'><CircularProgress /></div> : <div><Typography id='eMed_Pres_pop_NORec'>{t("No records found")}</Typography></div>}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

            </div>
        );
    }
}
export default withTranslation()(LabIPList)

import React, { Component } from "react";
import ShowComponents from "./ShowComponent";
import { Colors } from "../../../../Styles/Colors";
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, Tooltip, Modal, Autocomplete } from "@mui/material";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import { ImagePaths } from '../../../../Utility/ImagePaths'

export default class DocAnteriorSegTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            anteriorDetails: [],
            LidsRightEye: null,
            ConjunctivaRightEye: null,
            ScleraRightEye: null,
            CorneaRightEye: null,
            AnteriorChambRightEye: null,
            PupilRightEye: null,
            IrisRightEye: null,
            LensRightEye: null,
            LidsLeftEye: null,
            ConjunctivaLeftEye: null,
            ScleraLeftEye: null,
            CorneaLeftEye: null,
            AnteriorChambLeftEye: null,
            PupilLeftEye: null,
            IrisLeftEye: null,
            LensLeftEye: null,
            ExtraocularRightEye: null,
            ExtraocularLeftEye: null,
            rightEye: false,
            lefytEye: false,
            normal: false,
            AnteriorData: [],
            comparePopup: false,
            LidsSuggRE: [],
            LidsSuggLE: [],
            ConjunctivaSuggRE: [],
            ConjunctivaSuggLE: [],
            ScleraSuggRE: [],
            ScleraSuggLE: [],
            CorneaSuggRE: [],
            CorneaSuggLE: [],
            AnteriorChambSuggRE: [],
            AnteriorChambSuggLE: [],
            PupilSuggRE: [],
            PupilSuggLE: [],
            IrisSuggRE: [],
            IrisSuggLE: [],
            LensSuggRE: [],
            LensSuggLE: [],
            ExtraocularSuggRE: [],
            ExtraocularSuggLE: []
        }
    }

    apiCalls = () => {
        this.getAnteriorSegValues();
        this.getPreviousAnteriorSegment();
        this.getAntPerviousValue();
    }

    componentDidUpdate(prevProps) {
        if (this.props.triggerAnteriorTable) {
            this.AnteriorPost()
        }
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getAnteriorSegValues = () => {
        var { LidsRightEye, ConjunctivaRightEye, ScleraRightEye, CorneaRightEye, AnteriorChambRightEye, PupilRightEye, IrisRightEye, LensRightEye, LidsLeftEye, ConjunctivaLeftEye,
            ScleraLeftEye, CorneaLeftEye, AnteriorChambLeftEye, PupilLeftEye, IrisLeftEye, ExtraocularLeftEye, ExtraocularRightEye, LensLeftEye } = this.state
        try {
            RestAPIService.getAll(Serviceurls.DOC_ANTERIOR_SEGMENT_API + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            anteriorDetails: response?.data?.data,
                            currentData: response?.data?.data
                        }, () => {
                            var left = []; var Right = [];
                            if (response?.data?.data[0] && response?.data?.data[1]) {
                                if (response?.data?.data[0]?.eye_type == "LE") {
                                    left.push(response?.data?.data[0])
                                } else {
                                    Right.push(response?.data?.data[0])
                                }
                                if (response?.data?.data[1]?.eye_type == "RE") {
                                    Right.push(response?.data?.data[1])
                                } else {
                                    left.push(response?.data?.data[1])
                                }
                            } else if (response?.data?.data[0]) {
                                if (response?.data?.data[0]?.eye_type == "LE") {
                                    left.push(response?.data?.data[0])
                                } else {
                                    Right.push(response?.data?.data[0])
                                }
                            }
                            if (Right.length > 0) {
                                LidsRightEye = Right[0]?.lids;
                                ConjunctivaRightEye = Right[0]?.conjuctiva;
                                ScleraRightEye = Right[0]?.sclera;
                                CorneaRightEye = Right[0]?.cornea;
                                AnteriorChambRightEye = Right[0]?.anterior_chamber;
                                PupilRightEye = Right[0]?.pupil;
                                IrisRightEye = Right[0]?.iris;
                                LensRightEye = Right[0]?.lens;
                                ExtraocularRightEye = Right[0]?.extra_ocular;
                            }
                            if (left.length > 0) {
                                LidsLeftEye = left[0]?.lids;
                                ConjunctivaLeftEye = left[0]?.conjuctiva;
                                ScleraLeftEye = left[0]?.sclera;
                                CorneaLeftEye = left[0]?.cornea;
                                AnteriorChambLeftEye = left[0]?.anterior_chamber;
                                PupilLeftEye = left[0]?.pupil;
                                IrisLeftEye = left[0]?.iris;
                                LensLeftEye = left[0]?.lens;
                                ExtraocularLeftEye = left[0]?.extra_ocular;
                            }
                            this.setState({
                                LidsRightEye, ConjunctivaRightEye, ScleraRightEye, CorneaRightEye, AnteriorChambRightEye, PupilRightEye, IrisRightEye, LensRightEye, ExtraocularRightEye,
                                ScleraLeftEye, CorneaLeftEye, AnteriorChambLeftEye, PupilLeftEye, IrisLeftEye, ExtraocularLeftEye, LensLeftEye, LidsLeftEye, ConjunctivaLeftEye
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPreviousAnteriorSegment = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_ANTERIOR_SEGMENT + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let AnteriorData = response.data?.data ? response.data?.data : []
                        if (AnteriorData.length > 0) {
                            for (let i = 0; i < AnteriorData.length; i++) {
                                if (AnteriorData[i].eye_type == "RE") {
                                    var states = this.state
                                    states["PreLidsRightEye"] = AnteriorData[i].lids;
                                    states["PreConjunctivaRightEye"] = AnteriorData[i].conjuctiva;
                                    states["PreScleraRightEye"] = AnteriorData[i].sclera;
                                    states["PreCorneaRightEye"] = AnteriorData[i].cornea;
                                    states["PreAnteriorChambRightEye"] = AnteriorData[i].anterior_chamber;
                                    states["PrePupilRightEye"] = AnteriorData[i].pupil;
                                    states["PreIrisRightEye"] = AnteriorData[i].iris;
                                    states["PreLensRightEye"] = AnteriorData[i].lens;
                                    states["PreExtraocularRightEye"] = AnteriorData[i].extra_ocular;
                                    this.setState({ states })
                                } else if (AnteriorData[i].eye_type == "LE") {
                                    var states = this.state
                                    states["PreLidsLeftEye"] = AnteriorData[i].lids;
                                    states["PreConjunctivaLeftEye"] = AnteriorData[i].conjuctiva;
                                    states["PreScleraLeftEye"] = AnteriorData[i].sclera;
                                    states["PreCorneaLeftEye"] = AnteriorData[i].cornea;
                                    states["PreAnteriorChambLeftEye"] = AnteriorData[i].anterior_chamber;
                                    states["PrePupilLeftEye"] = AnteriorData[i].pupil;
                                    states["PreIrisLeftEye"] = AnteriorData[i].iris;
                                    states["PreLensLeftEye"] = AnteriorData[i].lens;
                                    states["PreExtraocularLeftEye"] = AnteriorData[i].extra_ocular;
                                    this.setState({ states })
                                }
                            }
                            this.setState({
                                AnteriorData: AnteriorData
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    getAntPerviousValue = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_SUGGESTION_ANTERIOR_SEGMENT)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const Data = response.data?.data;
                        const newState = {
                            LidsSuggLE: new Set(),
                            LidsSuggRE: new Set(),
                            ConjunctivaSuggRE: new Set(),
                            ConjunctivaSuggLE: new Set(),
                            ScleraSuggRE: new Set(),
                            ScleraSuggLE: new Set(),
                            CorneaSuggRE: new Set(),
                            CorneaSuggLE: new Set(),
                            AnteriorChambSuggRE: new Set(),
                            AnteriorChambSuggLE: new Set(),
                            PupilSuggRE: new Set(),
                            PupilSuggLE: new Set(),
                            IrisSuggRE: new Set(),
                            IrisSuggLE: new Set(),
                            LensSuggRE: new Set(),
                            LensSuggLE: new Set(),
                            ExtraocularSuggRE: new Set(),
                            ExtraocularSuggLE: new Set(),
                        };

                        Data.forEach(item => {
                            if (item.eye_type === 'RE') {
                                newState.LidsSuggRE.add(item.lids);
                                newState.ConjunctivaSuggRE.add(item.conjuctiva);
                                newState.ScleraSuggRE.add(item.sclera);
                                newState.CorneaSuggRE.add(item.cornea);
                                newState.AnteriorChambSuggRE.add(item.anterior_chamber);
                                newState.PupilSuggRE.add(item.pupil);
                                newState.IrisSuggRE.add(item.iris);
                                newState.LensSuggRE.add(item.lens);
                                newState.ExtraocularSuggRE.add(item.extra_ocular);
                            } else if (item.eye_type === 'LE') {
                                newState.LidsSuggLE.add(item.lids);
                                newState.ConjunctivaSuggLE.add(item.conjuctiva);
                                newState.ScleraSuggLE.add(item.sclera);
                                newState.CorneaSuggLE.add(item.cornea);
                                newState.AnteriorChambSuggLE.add(item.anterior_chamber);
                                newState.PupilSuggLE.add(item.pupil);
                                newState.IrisSuggLE.add(item.iris);
                                newState.LensSuggLE.add(item.lens);
                                newState.ExtraocularSuggLE.add(item.extra_ocular);
                            }
                        });

                        Object.keys(newState).forEach(key => {
                            newState[key] = Array.from(newState[key]);
                        });

                        this.setState(newState);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    AnteriorPost = () => {
        try {
            var states = this.state
            const data = new FormData();

            data.append('appointment_id', states?.appointmentId);
            data.append('patient_id', states.patientId);
            data.append('le_lids', states.LidsLeftEye ? states.LidsLeftEye : "");
            data.append('le_anterior_chamber', states.AnteriorChambLeftEye ? states.AnteriorChambLeftEye : "");
            data.append('le_sclera', states.ScleraLeftEye ? states.ScleraLeftEye : "");
            data.append('le_pupil', states.PupilLeftEye ? states.PupilLeftEye : "");
            data.append('le_cornea', states.CorneaLeftEye ? states.CorneaLeftEye : "");
            data.append('le_iris', states.IrisLeftEye ? states.IrisLeftEye : "");
            data.append('le_lens', states.LensLeftEye ? states.LensLeftEye : "");
            data.append('le_conjuctiva', states.ConjunctivaLeftEye ? states.ConjunctivaLeftEye : "");
            data.append('le_extra_ocular', states.ExtraocularLeftEye ? states.ExtraocularLeftEye : "");

            data.append('re_lids', states.LidsRightEye ? states.LidsRightEye : "");
            data.append('re_anterior_chamber', states.AnteriorChambRightEye ? states.AnteriorChambRightEye : "");
            data.append('re_sclera', states.ScleraRightEye ? states.ScleraRightEye : "");
            data.append('re_pupil', states.PupilRightEye ? states.PupilRightEye : "");
            data.append('re_cornea', states.CorneaRightEye ? states.CorneaRightEye : "");
            data.append('re_iris', states.IrisRightEye ? states.IrisRightEye : "");
            data.append('re_extra_ocular', states.ExtraocularRightEye ? states.ExtraocularRightEye : "");
            data.append('re_lens', states.LensRightEye ? states.LensRightEye : "");
            data.append('re_conjuctiva', states.ConjunctivaRightEye ? states.ConjunctivaRightEye : "");
            RestAPIService.create(data, Serviceurls.DOC_ANTERIOR_SEGMENT_API)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.setState({
                            comparePopup: false
                        }, () => {
                            this.getAnteriorSegValues()
                            this.getAntPerviousValue()
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    copyCheckBoxValues(key) {
        if (key == "rightEye") {
            if (this.state.lefytEye) {
                this.setState({ rightEye: !this.state.rightEye, normal: true }, () => {
                    if (!this.state.rightEye) {
                        this.setState({ normal: false }, () => { this.copyValues("rightEye") })
                    } else {
                        this.copyValues("rightEye")
                    }
                })
            } else {
                this.setState({ rightEye: !this.state.rightEye }, () => { this.copyValues("rightEye") })
            }
        } else if (key == "leftEye") {
            if (this.state.rightEye) {
                this.setState({ lefytEye: !this.state.lefytEye, normal: true }, () => {
                    if (!this.state.lefytEye) {
                        this.setState({ normal: false }, () => { this.copyValues("leftEye") })
                    } else {
                        this.copyValues("leftEye")
                    }
                })
            } else {
                this.setState({ lefytEye: !this.state.lefytEye }, () => { this.copyValues("leftEye") })
            }
        } else if (key == "normal") {
            if (this.state.rightEye && this.state.lefytEye) {
                this.setState({
                    rightEye: false,
                    lefytEye: false,
                    normal: false
                }, () => { this.copyValues("normal") })
            } else {
                this.setState({
                    rightEye: true,
                    lefytEye: true,
                    normal: true
                }, () => { this.copyValues("normal") })
            }
        }
    }

    copyValues = (type) => {
        if (type == "leftEye") {
            if (this.state.lefytEye) {
                var states = this.state
                states["LidsLeftEye"] = "Normal";
                states["ConjunctivaLeftEye"] = "Normal";
                states["ScleraLeftEye"] = "Normal";
                states["CorneaLeftEye"] = "Clear";
                states["AnteriorChambLeftEye"] = "Normal Depth";
                states["PupilLeftEye"] = "Normal Size reacting to light";
                states["IrisLeftEye"] = "Normal Color & Pattern";
                states["LensLeftEye"] = "Clear";
                states["ExtraocularLeftEye"] = "Normal";
                this.setState({ states })
            } else {
                var states = this.state
                states["LidsLeftEye"] = null;
                states["ConjunctivaLeftEye"] = null;
                states["ScleraLeftEye"] = null;
                states["CorneaLeftEye"] = null;
                states["AnteriorChambLeftEye"] = null;
                states["PupilLeftEye"] = null;
                states["IrisLeftEye"] = null;
                states["LensLeftEye"] = null;
                states["ExtraocularLeftEye"] = null;
                this.setState({ states })
            }
        } else if (type == "rightEye") {
            if (this.state.rightEye) {
                var states = this.state
                states["LidsRightEye"] = "Normal";
                states["ConjunctivaRightEye"] = "Normal";
                states["ScleraRightEye"] = "Normal";
                states["CorneaRightEye"] = "Clear";
                states["AnteriorChambRightEye"] = "Normal Depth";
                states["PupilRightEye"] = "Normal Size reacting to light";
                states["IrisRightEye"] = "Normal Color & Pattern";
                states["LensRightEye"] = "Clear";
                states["ExtraocularRightEye"] = "Normal";
                this.setState({ states })
            } else {
                var states = this.state
                states["LidsRightEye"] = null;
                states["ConjunctivaRightEye"] = null;
                states["ScleraRightEye"] = null;
                states["CorneaRightEye"] = null;
                states["AnteriorChambRightEye"] = null;
                states["PupilRightEye"] = null;
                states["IrisRightEye"] = null;
                states["LensRightEye"] = null;
                states["ExtraocularRightEye"] = null;
                this.setState({ states })
            }
        } else if (type == "normal") {
            if (this.state.normal) {
                var states = this.state;
                states["LidsLeftEye"] = "Normal";
                states["ConjunctivaLeftEye"] = "Normal";
                states["ScleraLeftEye"] = "Normal";
                states["CorneaLeftEye"] = "Clear";
                states["AnteriorChambLeftEye"] = "Normal Depth";
                states["PupilLeftEye"] = "Normal Size reacting to light";
                states["IrisLeftEye"] = "Normal Color & Pattern";
                states["LensLeftEye"] = "Clear";
                states["ExtraocularLeftEye"] = "Normal";

                states["LidsRightEye"] = "Normal";
                states["ConjunctivaRightEye"] = "Normal";
                states["ScleraRightEye"] = "Normal";
                states["CorneaRightEye"] = "Clear";
                states["AnteriorChambRightEye"] = "Normal Depth";
                states["PupilRightEye"] = "Normal Size reacting to light";
                states["IrisRightEye"] = "Normal Color & Pattern";
                states["LensRightEye"] = "Clear";
                states["ExtraocularRightEye"] = "Normal";
                this.setState({ states })
            } else {
                var states = this.state;
                states["LidsLeftEye"] = null;
                states["ConjunctivaLeftEye"] = null;
                states["ScleraLeftEye"] = null;
                states["CorneaLeftEye"] = null;
                states["AnteriorChambLeftEye"] = null;
                states["PupilLeftEye"] = null;
                states["IrisLeftEye"] = null;
                states["LensLeftEye"] = null;
                states["ExtraocularLeftEye"] = null;

                states["LidsRightEye"] = null;
                states["ConjunctivaRightEye"] = null;
                states["ScleraRightEye"] = null;
                states["CorneaRightEye"] = null;
                states["AnteriorChambRightEye"] = null;
                states["PupilRightEye"] = null;
                states["IrisRightEye"] = null;
                states["LensRightEye"] = null;
                states["ExtraocularRightEye"] = null;
                this.setState({ states })
            }
        }
    }

    CopyPreviousData = () => {
        var states = this.state
        states["LidsLeftEye"] = states.PreLidsLeftEye ? states.PreLidsLeftEye : "";
        states["ConjunctivaLeftEye"] = states.PreConjunctivaLeftEye ? states.PreConjunctivaLeftEye : "";
        states["ScleraLeftEye"] = states.PreScleraLeftEye ? states.PreScleraLeftEye : "";
        states["CorneaLeftEye"] = states.PreCorneaLeftEye ? states.PreCorneaLeftEye : "";
        states["AnteriorChambLeftEye"] = states.PreAnteriorChambLeftEye ? states.PreAnteriorChambLeftEye : "";
        states["PupilLeftEye"] = states.PrePupilLeftEye ? states.PrePupilLeftEye : "";
        states["IrisLeftEye"] = states.PreIrisLeftEye ? states.PreIrisLeftEye : "";
        states["LensLeftEye"] = states.PreLensLeftEye ? states.PreLensLeftEye : "";
        states["ExtraocularLeftEye"] = states.PreExtraocularLeftEye ? states.PreExtraocularLeftEye : "";

        states["LidsRightEye"] = states.PreLidsRightEye ? states.PreLidsRightEye : "";
        states["ConjunctivaRightEye"] = states.PreConjunctivaRightEye ? states.PreConjunctivaRightEye : "";
        states["ScleraRightEye"] = states.PreScleraRightEye ? states.PreScleraRightEye : "";
        states["CorneaRightEye"] = states.PreCorneaRightEye ? states.PreCorneaRightEye : "";
        states["AnteriorChambRightEye"] = states.PreAnteriorChambRightEye ? states.PreAnteriorChambRightEye : "";
        states["PupilRightEye"] = states.PrePupilRightEye ? states.PrePupilRightEye : "";
        states["IrisRightEye"] = states.PreIrisRightEye ? states.PreIrisRightEye : "";
        states["LensRightEye"] = states.PreLensRightEye ? states.PreLensRightEye : "";
        states["ExtraocularRightEye"] = states.PreExtraocularRightEye ? states.PreExtraocularRightEye : "";
        this.setState({ states }, () => { this.AnteriorPost() })
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "95vw", height: "95vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Posterior Segment</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw", height: "85vh", overflow: "scroll", display: "flex", flexDirection: "row" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Posterior Segment</Typography>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData() }}>
                                            <Tooltip placement='top' title='Copy'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {/* <div>{this.renderOldPowerTable()}</div> */}
                                </div>
                            </Box>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ marginTop: "0.65vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Posterior Segment</Typography>
                                        {/* {this.AnteriorSegTable()} */}
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    // renderOldPowerTable = () => {
    //     return (
    //         <Box width={"50vw"} marginLeft={"3vw"}>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
    //                 <Typography fontWeight={600} flex={0.2}></Typography>
    //                 <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Right Eye</Typography>
    //                 <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Left Eye</Typography>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Lids</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreLidsRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreLidsLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Conjunctiva</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreConjunctivaRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreConjunctivaLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Sclera</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreScleraRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreScleraLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Cornea</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreCorneaRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreCorneaLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Anterior Chamber</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreAnteriorChambRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreAnteriorChambLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Pupil</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PrePupilRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PrePupilLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Iris</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreIrisRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreIrisLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Extra Ocular</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreExtraocularRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreExtraocularLeftEye")}</Box>
    //             </Box>
    //             <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
    //                 <Typography fontWeight={600} flex={0.2}>Lens</Typography>
    //                 <Box flex={0.4}>{this.renderTextBox("PreLensRightEye")}</Box>
    //                 <Box flex={0.4}>{this.renderTextBox("PreLensLeftEye")}</Box>
    //             </Box>
    //         </Box>
    //     )
    // }

    AnteriorSegTable = () => {
        return (
            <Box width={"50vw"} marginLeft={"3vw"}>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.2}></Typography>}
                    <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Right Eye</Typography>
                    <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Left Eye</Typography>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Lids</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("LidsRightEye", this.state.LidsSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("LidsLeftEye", this.state.LidsSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Extra Ocular Movement</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("ExtraocularRightEye", this.state.ExtraocularSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("ExtraocularLeftEye", this.state.ExtraocularSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Conjunctiva</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("ConjunctivaRightEye", this.state.ConjunctivaSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("ConjunctivaLeftEye", this.state.ConjunctivaSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Sclera</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("ScleraRightEye", this.state.ScleraSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("ScleraLeftEye", this.state.ScleraSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Cornea</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("CorneaRightEye", this.state.CorneaSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("CorneaLeftEye", this.state.CorneaSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Anterior Chamber</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("AnteriorChambRightEye", this.state.AnteriorChambSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("AnteriorChambLeftEye", this.state.AnteriorChambSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Pupil</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("PupilRightEye", this.state.PupilSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("PupilLeftEye", this.state.PupilSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Iris</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("IrisRightEye", this.state.IrisSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("IrisLeftEye", this.state.IrisSuggLE)}</Box>
                </Box>
                <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
                    {this.state?.comparePopup ? null : <Typography fontWeight={600} flex={0.3}>Lens</Typography>}
                    <Box flex={0.4}>{this.renderTextBox("LensRightEye", this.state.LensSuggRE)}</Box>
                    <Box flex={0.4}>{this.renderTextBox("LensLeftEye", this.state.LensSuggLE)}</Box>
                </Box>
            </Box>
        )
    }

    renderTextBox = (key, suggestion) => {
        let states = this.state
        const filteredOptions = suggestion.filter(option => option !== '');
        const autoCompleteOptions = filteredOptions.map((option) => ({ label: option }));
        return (
            <Autocomplete
                freeSolo
                size='small'
                sx={{ width: '80%' }}
                options={autoCompleteOptions}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : option.label}
                value={states[key] ? states[key] : ""}
                onChange={(e, newValue) => {
                    states[key] = newValue?.label
                    this.setState({
                        states
                    })
                }}
                clearIcon={false}
                renderInput={(params) => <TextField
                    {...params}
                    autoComplete="off"
                    sx={{ width: '80%' }}
                    size='small'
                    placeholder='Type Remark'
                    onChange={(e) => {
                        states[key] = e.target.value
                        this.setState({ states })
                    }}
                />}
            />
        )
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} p={'0.5vw'} sx={{ backgroundColor: Colors.Background, paddingTop: "0.65vw", display: "flex", flexDirection: "row", alignItems: "center" }} height={'3.5vw'}>
                        <Typography fontWeight={600}>Anterior Segment</Typography>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1vw" }}>
                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.AnteriorData?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.AnteriorData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                                <Tooltip placement='top' title='Copy Previous Anterior Segment'>
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                            {/* <div className="eMed_icon_styles" style={{ backgroundColor: this.state.AnteriorData?.length > 0 && this.state.currentData?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.AnteriorData?.length > 0 && this.state.currentData?.length > 0 && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                {this.state.AnteriorData?.length > 0 && this.state.currentData?.length > 0 ?
                                    <Tooltip placement='top' title='Compare'>
                                        <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                    </Tooltip> :
                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                }
                            </div> */}
                        </div>
                    </Box>
                </Box>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }} p={'0.5vw'}>
                    <Box component={'div'} m={'0.5vw'}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={"space-between"} mb={"1vw"}>
                            <Box marginLeft={'2.5vw'}>
                                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="cash_checkbox" checked={this.state.rightEye} onChange={(e) => { this.copyCheckBoxValues("rightEye") }} size='small' />} label={"Right Eye"} />
                                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="upi_checkbox" checked={this.state.lefytEye} onChange={(e) => { this.copyCheckBoxValues("leftEye") }} size='small' />} label={"Left Eye"} />
                                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.normal} onChange={(e) => { this.copyCheckBoxValues("normal") }} size='small' />} label={"Normal"} />
                            </Box>
                            <Box>
                                <Button variant='outlined' size='small'
                                    sx={{ textTransform: 'capitalize', marginRight: '1vw' }}
                                    onClick={() => {
                                        this.setState({
                                            rightEye: false, lefytEye: false, normal: false
                                        }, () => { this.copyValues("normal") })
                                    }}>Clear</Button>
                                <Button variant='contained' size='small' disabled={this.state.isLocked || this.props.isLocked} sx={{ textTransform: 'capitalize' }} onClick={() => {
                                    this.AnteriorPost()
                                }}>Save</Button>
                            </Box>
                        </Box>
                        <Box>
                            {this.AnteriorSegTable()}
                        </Box>
                    </Box>
                </Box>
                {this.PreviousPowerPopup()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </ShowComponents>
        )
    }
}
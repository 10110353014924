import { Box, Button, Drawer, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import './RadBillTrans.css'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { AmountFormat, CheckAccessFunc, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions'
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import RadBillingFilter from './RadBillingFilter'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import CreditBillCompletePoP from '../../FrontOffice/BillingTransaction/CreditBillCompletePoP'

export default class RadCreditBills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creditbillData: [],
      totalBillAmount: 0,
      totalPayableAmount: 0,
      totalPaidAmount: 0,
      page: 0,
      pageSize: 10,
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "DateIndex": 1,
        "PatientName": "",
        "AccNo": "",
        "MobNo": "",
        "DoctorName": [],
        "BillNumber": "",
        "PaidAmount": "",
        "InPatientSelected": true,
        "OutPatientSelected": true,
        "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
        "LaboratorySelected": true,
        "ImageLabSelected": true,
        "OTSelected": true,
        "PaidSelected": true,
        "PartialPaidSelected": true,
        "CreditSelected": true,
        "CardSelected": true,
        "CashSelected": true,
        "UPISelected": true,
        "BankTransferSelected": true,
        "InsuranceSelected": true,
        "ChequeSelected": true,
        "BillType": ['op', 'ip', 'radiology'],
        "PayStatus": [],
        "PayMethods": [],
        "CreditStatus": [],
        "Insurance": true,
        "CorporateCredit": true,
        "PatientCredit": true,
        "startTime": null,
        "endTime": null,
        "is_bill_wise": false,
        "opCreditBills": true,
        "ipCreditBills": true,
        'radiologyCreditBills': true,
        'RadiologyBills': true,
        "RadiologySelected": true,
        "BillModuleType": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? ['op', 'ip', 'lab'] : ['op', 'ip', 'pharmacy', 'lab'],
      },
    }
  }

  componentDidMount() {
    this.getRadCreditBillDatas()
  }

  getRadCreditBillDatas = () => {
    try {
      let states = this.state
      RestAPIService.getAll(Serviceurls.RAD_CREDIT_BILL_TRANS_GET +
        "?from_date=" + states.FilterDatas.FromDate +
        "&to_date=" + states.FilterDatas.ToDate +
        "&moduel_type=" + states.FilterDatas.BillType +
        "&credit_type=" + states.FilterDatas.CreditStatus +
        "&is_bill_wise=" + states.FilterDatas.is_bill_wise
      )
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              creditbillData: response.data.data,
              totalBillAmount: response.data.total_bill_amount,
              totalPaidAmount: response.data.total_paid_amount,
              totalPayableAmount: response.data.total_payable_amount
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getCreditBillsPrint = () => {
    try {
      let states = this.state

      this.setState({ disableBtn: true })
      RestAPIService.getAll(Serviceurls.RAD_CREDIT_BILL_TRANS_GET +
        "?from_date=" + states.FilterDatas.FromDate +
        "&to_date=" + states.FilterDatas.ToDate +
        "&moduel_type=" + states.FilterDatas.BillType +
        "&credit_type=" + states.FilterDatas.CreditStatus +
        "&is_bill_wise=" + states.FilterDatas.is_bill_wise + "&export_type=pdf"
      ).then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({ disableBtn: false })
      }).catch((error) => {
        this.setState({ disableBtn: false })
        if (error?.response?.data?.message) {
          this.errorMessage(error.response.data.message)
        } else {
          this.errorMessage(error.message)
        }
      })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
    let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
    let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

    let TextArray = [
      { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
      { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
    ]
    if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
      TextArray.push(
        { label: "Start Time", value: timeOnlyConvert(setStartTime) },
        { label: "End Time", value: timeOnlyConvert(setEndTime) }
      )
    }
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      FilterOpen: false,
      BillPreviewPop: false,
      creditBillPopUp: false
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
      }
    })
  }

  renderFilter = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen: false,
    }, () => { this.getRadCreditBillDatas()})
  }

  clearFilter = (data) => {
    this.setState({
      FilterDatas: data
    })
  }

  creditBillPost = (message = "", receiptNo = null, phrReceipt = null, error = false) => {
    this.setState({
      creditBillPopUp: false
    }, () => {
      if (error) {
        this.errorMessage(message)
      } else {
        this.successMessage(message)
      }
      this.getRadCreditBillDatas()
    })
  }


  render() {
    this.state.creditbillData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'sno', headerName: 'S.no', flex: 0.05, align: 'center', headerAlign: 'center',
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_sno_cre'>{params?.row?.sno}</Box>)
      },
      {
        field: 'patient_names', headerName: 'Patient Details', flex: 0.2,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pat_name_cre'>
        <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.patient_names ?
          params?.row?.patient_names?.length > 10 ?
            <Tooltip placement='top' title={params?.row?.patient_names + " " + (params?.row?.patient_age ? params?.row?.patient_age : '-') + '/' + (params?.row?.patient_gender ? params?.row?.patient_gender?.toUpperCase() : '-')}>
              <div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.patient_names?.slice(0, 10) + '...' + (params?.row?.patient_age ? params?.row?.patient_age : '-') + '/' + (params?.row?.patient_gender ? params?.row?.patient_gender?.toUpperCase() : '-')}</div>
              </Tooltip> :
            (params?.row?.patient_names + " " + (params?.row?.patient_age ? params?.row?.patient_age : '-') + '/' + (params?.row?.patient_gender ? params?.row?.patient_gender?.toUpperCase() : '-')) : '-'}</Typography>
        <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.patient_acc_number ? params?.row?.patient_acc_number : '-'}</Typography>
      </Box>)
      },
      {
        field: 'invoice_date', headerName: 'Date And Time', flex: 0.125,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_date_cre'>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>)
      },
      {
        field: 'bill_types', headerName: 'OP/IP No', flex: 0.125,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_opip_no_cre'>
          <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.bill_types ? params?.row?.bill_types : '-'}</Typography>
          <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.module_number ? params?.row?.module_number : '-'}</Typography>
          </Box>)
      },
      {
        field: 'credit_type', headerName: 'Credit Type', flex: 0.125,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_crd_type_cre'>{params?.row?.credit_type ? params?.row?.credit_type : '-'}</Box>)
      },
      {
        field: 'total_amounts', headerName: 'Total Amount', flex: 0.125,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_tlt_amt_cre'>{AmountFormat(params?.row?.total_amounts ? params?.row?.total_amounts : 0)}</Box>)
      },
      {
        field: 'pending_amount', headerName: 'Pending Amount', flex: 0.125,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pnd_amt_cre'>{AmountFormat(params?.row?.pending_amount ? params?.row?.pending_amount : 0)}</Box>)
      },
      {
        field: 'Action', headerName: 'Action', flex: 0.125, align: 'center', headerAlign: 'center',
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_act_cre'>
          {/* <Tooltip placement='top' title={'Credit'}>
            <Button className='Common_Btn_Min_Width' emed_tid='rad_bill_trns_crd_cre'>
              <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
            </Button>
          </Tooltip> */}
          <Tooltip placement='top' title={'View'}>
            <Button className='Common_Btn_Min_Width' emed_tid='rad_bill_trns_view_cre'
              onClick={() => this.setState({ opCreditBill: params?.row }, () => this.setState({ creditBillPopUp: true }))}
            >
              <Box component={'img'} src={ImagePaths.CreateBill.default} height={'2vw'} width={'2vw'} />
            </Button>
          </Tooltip>
          {!CheckAccessFunc('Radiology', "Billing Transaction", 'Credit Bills', null, "Tab")?.editAccess ?
            '' : null}
        </Box>)
      }
    ]
    let Module_ID_Key_Name = ''; let Module_ID_Key_Value = '';
    if (this.state.opCreditBill?.bill_types === 'In Patient') {
      Module_ID_Key_Name = "ip_admission_id"
      Module_ID_Key_Value = this.state.opCreditBill?.module_id
    } else if (this.state.opCreditBill?.bill_types === 'Out Patient') {
      Module_ID_Key_Name = "appointment_id"
      Module_ID_Key_Value = this.state.opCreditBill?.module_id
    } else {
      Module_ID_Key_Name = "bill_id"
      Module_ID_Key_Value = this.state.opCreditBill?.module_id
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='rad_bill_trns_container'>
          <Box component={'div'} className='rad_bill_trns_header_card'>
            <Box component={'div'} className='rad_bill_trns_cards'>
              {AmountsCard('Total Bill Amount', this.state.totalBillAmount, false, "white", false, null, 'rad_bill_trns_tlt_bill_cre')}
              {AmountsCard('Total Paid Amount', this.state.totalPaidAmount, false, "white", false, null, 'rad_bill_trns_tlt_amt_cre')}
              {AmountsCard('Total Payable Amount', this.state.totalPayableAmount, false, "white", false, null, 'rad_bill_trns_tlt_amt_cre')}
            </Box>
            <Box component={'div'} className='rad_bill_trns_btns'>
              <Tooltip placement='top' title={'Filter'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_flt_cre'
                  onClick={() => this.setState({ FilterOpen: true })}>
                  <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              <Tooltip placement='top' title={'Print'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_prt_cre'
                  disabled={this.state.disableBtn}
                  onClick={() => this.getCreditBillsPrint()}
                >
                  <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              {/* <Tooltip placement='top' title={'Export'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_exp_cre'>
                  <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip> */}
            </Box>
          </Box>
          <Box component={'div'} className='rad_bill_trns_content_card'>
            <DataGrid
              sx={{ backgroundColor: Colors.white }}
              columns={columns}
              rows={this.state.creditbillData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <RadBillingFilter PageName={"CreditBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.creditBillPopUp ?
          <CreditBillCompletePoP
            Module_ID_Key_Name={Module_ID_Key_Name}
            Module_ID_Key_Value={Module_ID_Key_Value}
            modules={['radiology']}
            CrebillPosted={this.creditBillPost}
            BillData={this.state.opCreditBill}
            ClosePopUp={this.ClosePopUp.bind(this)}
            CollectedIn={"RADIOLOGY"}
          /> : null}
      </Box>
    )
  }
}

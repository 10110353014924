import React, { Component } from 'react';
import {
  Badge,
  Box, Button, IconButton, MenuItem, Paper, Stack, Tooltip, Menu, Typography, Grow
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import '../../FrontOffice/BillingTransaction/Billings.css';
import Drawer from '@mui/material/Drawer';
import { DateTime } from 'luxon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { width } from '@mui/system';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { setCachevalue } from '../../../Utility/Services/CacheProviderService';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Colors } from '../../../Styles/Colors';
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import InvoiceFilter from './InvoiceFilter';
import { returnPaymentMode } from '../../../Components/Common Components/PharmaCommonFunctions';
import { CurrencySymbol } from '../../../Utility/Constants';

class PharmaInvoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      BillList: [],
      ref: props.location?.pathname,
      FilterOpen: false,
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "DateIndex": 1,
        "PayMethods": [],
        "PayMethodsisCombained": false,
        "PayMethodsisReturn": false,
      },
      AmountDetails: {
        "TotalReturn": 0,
        "TotalAmount": 0,
        "BankTransfer": 0,
        "Card": 0,
        "Cash": 0,
        "insurance": 0,
        "upi": 0,
      },
      SelectedBill: {},
      AppointmentDetails: {},
      CreditBillPopUp: false,
      SelectedBill: {},
      page: 0,
      pageSize: 10,
      rowCount: 0,
    }
  }

  componentDidMount() {
    this.GetBillList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  GetBillList() {
    try {
      var states = this.state

      this.LoaderFunction(true)
      let PayMode = "";
      let PayMode1 = "";
      if (states?.FilterDatas?.PayMethodsisCombained) {
        PayMode = returnPaymentMode(states?.FilterDatas?.PayMethods[0] + states?.FilterDatas?.PayMethods[1])?.paymentMode
      } else {
        PayMode = returnPaymentMode(states?.FilterDatas?.PayMethods[0])?.paymentMode;
        PayMode1 = returnPaymentMode(states?.FilterDatas?.PayMethods[1])?.paymentMode;
      }

      RestAPIService.getAll(Serviceurls.PHARMACY_INVOICE_LIST +
        "?from_date=" + states.FilterDatas.FromDate +
        "&to_date=" + states.FilterDatas.ToDate +
        "&sort_by=" + 2 +
        "&page=" + (this.state.page + 1) +
        "&page_size=" + this.state.pageSize +
        "&bill_status=" + (states?.FilterDatas?.PayMethodsisReturn ? "Returned" : "") +
        "&payment_mode=" + PayMode +
        "&payment_mode1=" + PayMode1
      ).
        then((response) => {
          if (response.data.status === "success") {
            states.AmountDetails.TotalReturn = +response?.data?.data?.return_amount;
            states.AmountDetails.TotalAmount = +response?.data?.data?.total_amount;
            states.AmountDetails.Cash = +response?.data?.data?.cash_amount;
            states.AmountDetails.Card = +response?.data?.data?.card_amount;
            states.AmountDetails.BankTransfer = +response?.data?.data?.bank_transfer_amount;
            states.AmountDetails.upi = +response?.data?.data?.upi_amount;
            states.AmountDetails.insurance = +response?.data?.data?.insurance_amount;
            this.setState({
              BillList: response.data.data?.result,
              AmountDetails: states.AmountDetails,
              rowCount: response.data?.data?.total_count
            }, () => { this.LoaderFunction(false) })
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderFilter = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen: false,
    }, () => { this.GetBillList() })
  }

  MenuItem = (data) => {
    const { t } = this.props

    const multi_option = [
      { value: "CancelBill", label: t("CancelBill") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            sx={{ width: '2vw' }}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true,
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (

              <MenuItem key={option} onClick={() => {
                this.MenuClick(data)

              }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  MenuClick = (data) => {
    let { history } = this.props
    try {
      let url = ""
      let id = ""
      let ApiCall = false
      if (data.row.bill_types === "Out Patient") {
        url = Serviceurls.FO_GET_APPOINMENT_DETAILS
        id = "?appointment_id=" + data.row.appointment_id
        ApiCall = true
      } else if (data.row.bill_types === "In Patient") {
        url = Serviceurls.FO_GET_IP_ADMISSION_DETAILS
        id = "?ip_admission_id=" + data.row.ip_number_id
        ApiCall = true
      } else if (data.row.bill_types === "laboratory") {
        history.push({ pathname: "/Billing/LabBilling", state: { ForCancel: true, cancelBillData: data.row } })
      }
      if (ApiCall) {
        RestAPIService.getAll(url + id).
          then((response) => {
            if (response.data.status === "success") {
              this.setState({
                AppointmentDetails: response.data.data,
                anchorEl: null
              }, () => {
                let path = data.row.bill_types === "Out Patient" ? "/OPHome/PatientDetails/Billing" : data.row.bill_types === "In Patient" ? "/IPHome/PatientDetails/ServiceBilling" : false
                let ID = data.row.bill_types === "Out Patient" ? data.row.appointment_id : data.row.bill_types === "In Patient" ? data.row.ip_number_id : null
                let Amt = data.row.amount_net
                if (path) {
                  if (data.row.bill_types === "Out Patient") {
                    setCachevalue(JSON.stringify(this.state.AppointmentDetails), "patientData")
                  } else if (data.row.bill_types === "In Patient") {
                    setCachevalue(JSON.stringify(this.state.AppointmentDetails), "IppatientData")
                  }
                  history.push({ pathname: path, state: { AppointmentID: ID, InvoiceID: data.row.id, ForCancel: true, AmountNet: Amt } })
                }
              })
            }
            else {
              this.errorMessage(response.data.message)
            }
          }).catch((error) => {
            this.errorMessage(error.message)
          })
      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }

  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      FilterOpen: false,
      BillPreviewPop: false,
      CreditBillPopUp: false
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
        this.GetBillList()
      }
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
      { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }

  ClearFilterData = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen : false
    }, () => { this.GetBillList() })
  }

  PrintInvoice(BillId) {
    try {
      RestAPIService.PrintPDF(Serviceurls.PHARMA_BILLS_GET + "?id=" + BillId + "&export=pdf").
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          console.log(error.message)
        })
    } catch (e) {
      console.log(e.message)
    }
  }

  render() {
    this.state.BillList.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props
    const { history } = this.props;
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "invoice_number", flex: 0.12, headerName: t("Invoice No"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.invoice_number ? params?.row?.invoice_number?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{"..." + params?.row?.invoice_number.slice(9)}</div></Tooltip>
            : params?.row?.invoice_number : "-"}</Box>)
      },
      {
        field: "invoice_date", flex: 0.07, headerName: t("Invoice Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.invoice_date ? params?.row?.invoice_date?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.invoice_date} arrow><div>{params?.row?.invoice_date.slice(0, 15) + "..."}</div></Tooltip>
            : params?.row?.invoice_date : "-"}</Box>)
      },
      {
        field: "invoice_time", flex: 0.07, headerName: t("Invoice Time"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.invoice_time ? params?.row?.invoice_time?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.invoice_time} arrow><div>{params?.row?.invoice_time.slice(0, 15) + "..."}</div></Tooltip>
            : params?.row?.invoice_time : "-"}</Box>)
      },
      {
        field: "patient_account_number", flex: 0.10, headerName: t("Patient Acc.No"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.patient_account_number ? params?.row?.patient_account_number?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.patient_account_number} arrow><div>{params?.row?.patient_account_number.slice(0, 15) + "..."}</div></Tooltip>
            : params?.row?.patient_account_number === "null" ? "N/A" : params?.row?.patient_account_number : "-"}</Box>)
      },
      {
        field: "patient_name", flex: 0.16, headerName: t("PatientName"), headerAlign: "left", align: "left",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.patient_name ? params?.row?.patient_name?.length > 20 ?
            <Tooltip placement='top' title={params?.row?.patient_name} arrow><div>{params?.row?.patient_name.slice(0, 20) + "..."}</div></Tooltip>
            : params?.row?.patient_name : "-"}</Box>)
      },
      {
        field: "customer_mobile_no", flex: 0.10, headerName: t("MobileNumber"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.customer_mobile_no ? params?.row?.customer_mobile_no?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.customer_mobile_no} arrow><div>{params?.row?.customer_mobile_no.slice(0, 15) + "..."}</div></Tooltip>
            : params?.row?.customer_mobile_no : "-"}</Box>)
      },
      {
        field: "amount", flex: 0.10, headerName: t(`Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.amount ? params?.row?.amount?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.amount} arrow><div>{params?.row?.amount.slice(0, 15) + "..."}</div></Tooltip>
            : params?.row?.amount : "-"}</Box>)
      },
      {
        field: "payment_type", flex: 0.10, headerName: t("PaymentType"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.payment_type ? params?.row?.payment_type?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.payment_type} arrow><div>{params?.row?.payment_type.slice(0, 15) + "..."}</div></Tooltip>
            : params?.row?.payment_type : "-"}</Box>)
      },
      {
        field: "billing_status", flex: 0.1, headerName: t("Status"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.billing_status ? params?.row?.billing_status?.length > 20 ?
            <Tooltip placement='top' title={params?.row?.billing_status} arrow><div>{params?.row?.billing_status.slice(0, 20) + "..."}</div></Tooltip>
            : params?.row?.billing_status : "-"}</Box>)
      },
      {
        field: "action", flex: 0.1, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={t("Print Invoice")} placement="top" arrow>
            <Button emed_tid="Print_invoice" className='eMed_usrconf_btn' onClick={() => { this.PrintInvoice(params.row?.id) }}>
              <img src={ImagePaths.PrintIcons.default} alt="cart" className='eMed_action_img' />
            </Button>
          </Tooltip>
          <Tooltip title={t("Update Invoice")} placement="top" arrow>
            <Button emed_tid="Edit_invoice" className='eMed_usrconf_btn' disabled={params?.row?.billing_status === "Returned"} onClick={() => {
              this.props.history.push({ pathname: "/PharmacyBilling", state: { Bill_ID: params.row?.id, isForInvoiceEdit: true, billDetails: params.row } })
            }}>
              <img src={params?.row?.billing_status === "Returned" ? ImagePaths.DisabledEdit.default : ImagePaths.LabEditButton.default} alt="cart" className='eMed_action_img' />
            </Button>
          </Tooltip>
        </Box>
      }
    ]
    return (
      <Box component={"div"} className='eMed_Main_container'>
        <Box component={"div"} className='eMed_Top_Container'>
          <Box component={"div"} className='eMed_Cards_Container'>
            <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ showBox: !this.state.showBox, showRFcard: false }) }}>{AmountsCard(t("TotalAmount"), this.state.AmountDetails?.TotalAmount, false, this.state.showBox ? Colors.Primary : "white", true, this.state.showBox ? "white" : Colors.Primary)}</Button>
            {AmountsCard(t("Return"), this.state.AmountDetails?.TotalReturn)}
            <Box component={'div'} sx={{ width: '50vw', overflow: 'scroll', marginLeft: '1vw', height: "5.5vw", display: 'flex', alignItems: 'center', }} >
              {this.state.showBox ?
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.AmountDetails?.Cash)}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard(t("Card"), this.state.AmountDetails?.Card)}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard(t("UPI"), this.state.AmountDetails?.upi)}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 2000 } : {})}>{AmountsCard(t("Bank"), this.state.AmountDetails?.BankTransfer)}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 2500 } : {})}>{AmountsCard(t("Insurance"), this.state.AmountDetails?.insurance)}</Grow>
                </Box> : null}
            </Box>
          </Box>
          <Box component={"div"} className='eMed_Action_Container'>
            <Box component={"div"} className='eMed_Filter_Icon_div'>
              <Tooltip title="Filter" placement='top' arrow>
                <Button emed_tid="invoice_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                  <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                </Button>
              </Tooltip>
            </Box>
            {/* <Tooltip title="Print" placement='top' arrow>
              <Button className='eMed_usrconf_btn'>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
              </Button></Tooltip> */}
          </Box>
        </Box>
        <Box component={"div"} className='eMed_Table_Container'>
          <Stack component={"div"}>
            <Paper className='eMed_Table_Wrapper' >
              <DataGrid
                rows={this.state.BillList}
                columns={columns}
                getRowId={(row) => row['sno']}
                page={this.state.page}
                pageSize={this.state.pageSize}
                hideFooterSelectedRowCount
                localeText={{
                  toolbarColumns: "",
                  toolbarDensity: "",
                  toolbarExport: "",
                  toolbarFilters: "",
                  toolbarExportPrint: ""
                }}
                headerHeight={40}
                components={{
                  Toolbar: this.gridToolBar,
                  NoRowsOverlay: () => (
                    <Stack className='eMed_conf_nodata'>
                      {t("NoRecordsFound")}
                    </Stack>
                  )
                }}
                rowsPerPageOptions={[10, 20, 30]}
                pagination
                paginationMode='server'
                onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.GetBillList()) }}
                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.GetBillList())}
                loading={this.state.isLoader}
                onCellClick={(params) => {
                  if (params.field !== "action") {
                    this.props.history.push({ pathname: "/PharmacyBilling", state: { Bill_ID: params.row?.id, isFromInvoice: true, billDetails: params.row } })
                  }
                }}
                rowCount={this.state.rowCount}
              />
            </Paper>
          </Stack>
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <InvoiceFilter PageName={"InvoiceList"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearFilterData={this.ClearFilterData.bind(this)}/>
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(PharmaInvoice)

import React, { Component } from 'react'
import { Box } from "@mui/material";
import { withTranslation } from 'react-i18next';
import IpPatientDetails from "../../../IPPatients/PatientsDetails/PatientDetails";
import { getCachevalue } from "../../../../../Utility/Services/CacheProviderService";

class BillingDetails extends Component {
constructor(props) {
    super(props)
    this.state = {
      ip_id: null
    }
}
componentDidMount() {
  let patientDetails = getCachevalue("withoutAppointment");
  let patients = JSON.parse(patientDetails);
  this.setState({
    ip_id: patients?.ip_admission_id
  })
}
  render() {
    return (
        <Box>
        <IpPatientDetails fromBillingDetails={true} ip_admission_id = {this.state?.ip_id}/>
      </Box>
    )
  }
}
export default withTranslation()(BillingDetails)
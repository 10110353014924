import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, CommonPopUp, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../../src/Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../src/Utility/API/Serviceurls';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RoomTypeAddPop from './RoomTypeAddPop';
import { CurrencySymbol, RoomHoursList } from '../../../Utility/Constants';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

class WardConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      WardList: [],
      ref: props.location?.pathname,

      WardName: "",
      RoomType: "",
      isEditCliked: false,
      isDeleteCliked: false,
      SelectedList: null,
      AddnewRoomType: false,
      AcType: "AC",
      isWardForAttender: false,
      AttenderCheck: false,
      TableType: 'hour',
      HourSplitEditMainIndex: null,
      HourSplitEditSplitIndex: null,
      DaySplitEditMainIndex: null,
      RoomTypeList: [],
      UserRoomTypeSelected: false,
      isHistoryClicked: false,
      SelectedHistory: [],
      wardTotalAmount:"",

      Hourly: [
        {
          hourType: "",
          total: 0,
          splitUps: [],
          nameLabel: "",
          amountLabel: "",
          nameLabelerror: false,
          amountLabelerror: false,
        },
      ],

      Day: {
        hourType: "upto 24 Hrs",
        total: 0,
        splitUps: [],
        nameLabel: "",
        amountLabel: "",
        nameLabelerror: false,
        amountLabelerror: false,
      },
    }
  }

  componentDidMount() {
    this.GetWardList()
    this.GetRoomType()
  }

  GetWardList() {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.WARD_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              WardList: response.data.data
            },()=>{this.LoaderFunction(false)})
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      this.LoaderFunction(false)
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }
  GetRoomType() {
    try {
      RestAPIService.getAll(Serviceurls.USER_ROOM_TYPE).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              RoomTypeList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  PostWardData = () => {
    try {
      var states = this.state
      let AttenderObj = {hourType: "upto 24 Hrs", total: +states.wardTotalAmount }
      var data = {
        "ward_name": CommonValidation.removeSpace(states.WardName),
        "room_type": states.RoomType,
        "day_rent": states.Day?.splitUps.length === 0 ? null : states.Day,
        "hourly_rent": this.CheckRentValues() ? states.Hourly : null,
        "is_ac": states.AcType,
        "is_inpatient": true,
        "is_attender": states.isWardForAttender,
        "attender_rent": states.isWardForAttender ? AttenderObj : null
      }
      if (states.isEditCliked) { data["ward_id"] = states.SelectedList.id }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.WARD_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.GetWardList()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  ClearData() {
    this.setState({
      WardName: "",
      RoomType: "",
      isEditCliked: false,
      isDeleteCliked: false,
      SelectedList: null,
      AddnewRoomType: false,
      AcType: "AC",
      isWardForAttender: false,
      AttenderCheck: false,
      TableType: 'hour',
      HourSplitEditMainIndex: null,
      HourSplitEditSplitIndex: null,
      DaySplitEditMainIndex: null,
      isHistoryClicked: false,
      SelectedHistory: [],
      wardTotalAmount:"",
      Hourly: [
        {
          hourType: "",
          total: 0,
          splitUps: [],
          nameLabel: "",
          amountLabel: "",
          nameLabelerror: false,
          amountLabelerror: false,
        },
      ],

      Day: {
        hourType: "upto 24 Hrs",
        total: 0,
        splitUps: [],
        nameLabel: "",
        amountLabel: "",
        nameLabelerror: false,
        amountLabelerror: false,
      },
    })
  }

  onEditHandler = (value) => {
    try {
      this.GetWardList()
      let EmptyDayRent = {
        hourType: "upto 24 Hrs",
        total: 0,
        splitUps: [],
        nameLabel: "",
        amountLabel: "",
        nameLabelerror: false,
        amountLabelerror: false,
      }
      let EmptyHourRent = [
        {
          hourType: "",
          total: 0,
          splitUps: [],
          nameLabel: "",
          amountLabel: "",
          nameLabelerror: false,
          amountLabelerror: false,
        },
      ]
      this.setState({
        isEditCliked: true,
        SelectedList: value.row,
        WardName: value.row.ward_name,
        RoomType: value.row.room_type,
        AcType: value.row.is_ac,
        AttenderCheck: value.row.attender,
        isWardForAttender: value.row.is_inpatient,
        TableType: 'hour',
        Hourly: value.row.hourly_rent === null ? EmptyHourRent : value.row.hourly_rent,
        Day: value.row.day_rent === null ? EmptyDayRent : value.row.day_rent,
        isWardForAttender : value.row.attender ? value.row.attender : false,
        wardTotalAmount : value.row?.attender_rent?.total ?  value.row?.attender_rent?.total : ""
      }, () => {
        if (this.state.RoomType !== "Single Bed Room" && this.state.RoomType !== "Double Bed Room") {
          this.setState({
            UserRoomTypeSelected: true,
            SelectedUserRoomType: this.state.RoomTypeList.filter(list => list['id'] === this.state.RoomType)
          })
        } else {
          this.setState({
            UserRoomTypeSelected: false
          })
        }
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (value) => {
    try {
      if (!this.state.isEditCliked) {
        this.setState({
          isDeleteCliked: true,
          SelectedList: value.row,
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetHistoryData = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.HISTORY_DATA + "?condition=ward" + "&history_id=" + id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SelectedHistory: response.data.data,
              isHistoryClicked: true,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }


  _getHistory(historydata) {
    historydata.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);
    const historyColumn = [
      { field: "sno", sortable: false, width: 100, headerName: t("SNo") },
      {
        field: "user", width: 150, headerName: t("User"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.user ? params?.row?.user : "-"}</Box>)
      },
      {
        field: "modified_date", width: 200, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date : "-"}</Box>)
      },
      {
        field: "ward_name_from", width: 150, headerName: t("PreviousWard"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.ward_name_from ? params?.row?.ward_name_from : "-"}</Box>)
      },
      {
        field: "ward_name_to", width: 150, headerName: t("ChangedWard"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.ward_name_to ? params?.row?.ward_name_to : "-"}</Box>)
      },
      {
        field: "day_rent_from", width: 150, headerName: t("PreviousDayRent")+(`(${CurrencySymbol})`),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.day_rent_from.total ? params?.row?.day_rent_from.total : "-"}</Box>)
      },
      {
        field: "day_rent_to", width: 150, headerName: t("ChangedDayRent")+(`(${CurrencySymbol})`), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.day_rent_to.total ? params?.row?.day_rent_to.total : "-"}</Box>)
      },
      {
        field: "hourly_rent_from", width: 300, headerName: t("PreviousHourlyRent")+(`(${CurrencySymbol})`), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let HoursSplitTotal = "";
          params?.row?.hourly_rent_from === "" ? HoursSplitTotal = "" : params?.row?.hourly_rent_from.forEach((item) => (
            HoursSplitTotal += `${item.hourType} - ${CurrencySymbol} ${item.total} , `
          ))
          return (
            <Box component={'div'}>{
              params?.row?.hourly_rent_from ?
                HoursSplitTotal?.length > 30 ?
                  <Tooltip placement='top' title={HoursSplitTotal} arrow>
                    <div>{HoursSplitTotal.slice(0, 30) + "..."}</div></Tooltip> :
                  HoursSplitTotal : "-"}</Box>
          )
        }
      },
      {
        field: "hourly_rent_to", width: 300, headerName: t("ChangedHourlyRent")+(`(${CurrencySymbol})`), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let HoursSplitTotal = "";
          params?.row?.hourly_rent_to === "" ? HoursSplitTotal = "" : params?.row?.hourly_rent_to.forEach((item) => (
            HoursSplitTotal += `${item.hourType} - ${CurrencySymbol} ${item.total} , `
          ))
          return (
            <Box component={'div'}>{
              params?.row?.hourly_rent_to ?
                HoursSplitTotal?.length > 30 ?
                  <Tooltip placement='top' title={HoursSplitTotal} arrow>
                    <div>{HoursSplitTotal.slice(0, 30) + "..."}</div></Tooltip> :
                  HoursSplitTotal : "-"}</Box>
          )
        }
      },
    ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          disableSelectionOnClick
        />
      </Box>
    )
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option} onClick={() => { this.setState({ anchorEl: null }, () => { this.GetHistoryData(data.row.id) }) }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  renderCorporateLeft = () => {
    try {
      this.state.WardList.forEach((element, index) => { element.sno = index + 1 })

      const { t } = this.props
      const columns = [
        { field: "sno", sortable: false, flex: 0.05, headerName: t("SNo") },
        {
          field: "ward_name", flex: 0.21, headerName: t("WardName"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.ward_name ? params?.row?.ward_name?.length > 20 ?
              <Tooltip placement='top' title={params?.row?.ward_name}>
                <div>{params?.row?.ward_name.slice(0, 20) + "..."}</div></Tooltip> :
              params?.row?.ward_name : "-"}</Box>)
        },
        {
          field: "day_rent", flex: 0.15, headerName: t('RentPerDay')+`(${CurrencySymbol})`, type: "number",
          renderCell: (params) => (<Box component={'div'}>{params?.row?.day_rent ?
            params?.row?.day_rent?.total
            : "-"}</Box>)
        },
        {
          field: "hourly_rent", flex: 0.3, headerName: t('RentHourly')+`(${CurrencySymbol})`, type: "number",
          renderCell: (params) => {
            let HoursSplitTotal = "";
            if(params?.row?.hourly_rent){
              params?.row?.hourly_rent.forEach((item) => (
                HoursSplitTotal += `${item.hourType} - ${CurrencySymbol} ${item.total} , `
              ))
            }else{
              HoursSplitTotal = "-"
            }
            return (
              <Box component={'div'}>{
                params?.row?.hourly_rent ?
                  HoursSplitTotal?.length > 25 ?
                    <Tooltip placement='top' title={HoursSplitTotal} arrow>
                      <div>{HoursSplitTotal.slice(0, 25) + "..."}</div></Tooltip> :
                    HoursSplitTotal : "-"}</Box>
            )
          }
        },
        {
          field: "attender_rent", flex: 0.2, headerName: t(`Attender Rent/Day(${CurrencySymbol})`), type: "number",
          renderCell: (params) => {
            let AttenderRent = params?.row?.attender ? params.row?.attender_rent?.total : "Only for Patient"
            return  <Box component={'div'}>{AttenderRent}</Box>
          }
        },
        {
          field: "action", flex: 0.15, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Ward Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
            <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Ward Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
            {this.MenuItem(params)}
          </Box>
        }
      ]
      return (
        <Stack component={"div"}>
          <Paper className='eMed_common_Tbl_left' >
            <DataGrid
              rows={this.state.WardList}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Paper>
        </Stack>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  CheckRentValues() {
    let Flag = true;
    let SplitupLength = 0;
    this.state.Hourly.forEach((list, index) => {
      SplitupLength = SplitupLength + list.splitUps.length
      if (list.SplitupLength === 0) {
        Flag = false
      }
    }
    )
    return (Flag && SplitupLength !== 0)
  }

  CheckSplitupValues(){
    let Flag = true
    this.state.Hourly.forEach((list, index) => {
      if (list.splitUps.length === 0 && list.hourType !== "") {
        this.errorMessage(`${list.hourType} Value is Empty`)
         Flag = false
         return false
      }
    })
    return Flag
  }

  validation() {
    try {
      var states = this.state;
      let SameWardName = [];
      SameWardName = states.WardList.filter(list => list['ward_name'].toLowerCase() === states.WardName.toLowerCase());
      let Have24Hrs = this.state.Hourly.find((item) => (item?.hourType === "Upto 24 Hrs"))
      var { t } = this.props;
      if (
        CommonValidation.removeSpace(states.WardName) !== "" &&
        states.RoomType !== "" &&
        (this.CheckSplitupValues()) &&
        ((this.CheckRentValues() && (Have24Hrs !== undefined)) || (states.Day.splitUps.length !== 0 && !this.CheckRentValues())) &&
        (SameWardName.length === 0 || (SameWardName.length == 1 && SameWardName[0].id === states.SelectedList?.id)) &&
        ((!states.isWardForAttender) || (states.wardTotalAmount !== ""))
        ) {
        this.PostWardData()
      }
      else {
        if (CommonValidation.removeSpace(states.WardName) === "") {
          this.errorMessage(t("EnterWardName"))
        }
        else if (SameWardName.length > 0 && (SameWardName.length == 1 && SameWardName[0].id !== states.SelectedList?.id)) {
          this.errorMessage(t("WardExists"))
        }
        else if (states.RoomType === "") {
          this.errorMessage(t("SelectRoomType"))
        }
        else if((states.isWardForAttender) && (states.wardTotalAmount == "")){
          this.errorMessage(t("Enter Ward Charges for Attender"))
        }
        else if (((!this.CheckRentValues()) && states.Day.splitUps.length === 0)) {
          this.errorMessage(t("RentValueEmpty"))
        }
        else if (((this.CheckRentValues()) && Have24Hrs === undefined)) {
          this.errorMessage(t(`Enter 24 Hours Value in Hourly Rent`))
        }
        // else if (this.state.Day.total === 0) {
        //   this.errorMessage(t("Enter Per Day Rent Value"))
        // }
        // else if (Have24Hrs === undefined) {
        //   this.errorMessage(t(`Enter 24 Hours Value in Hourly Rent`))
        // }
      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  addsplitups(index) {
    let states = this.state
    let ServiceExists = false;
    let SameServiceId = false;
    states.Hourly[index].splitUps.forEach((list, Forindex) => {
      if (list.service === states.Hourly[index].nameLabel) {
        ServiceExists = true;
        SameServiceId = Forindex;
      }
    })


    if (CommonValidation.removeSpace(states.Hourly[index].nameLabel) !== "" && states.Hourly[index].amountLabel !== "") {
      let data = { service: CommonValidation.removeSpace(states.Hourly[index].nameLabel), amount: +states.Hourly[index].amountLabel, }


      if (!ServiceExists || SameServiceId === states.HourSplitEditSplitIndex) {
        if (index === states.HourSplitEditMainIndex) {
          states.Hourly[index].splitUps[states.HourSplitEditSplitIndex] = data
          this.setState({
            HourSplitEditMainIndex: null,
            HourSplitEditSplitIndex: null,
          })
        }
        else {
          states.Hourly[index].splitUps.push(data)
        }
        states.Hourly[index].nameLabel = ""
        states.Hourly[index].amountLabel = ""
        states.Hourly[index].amountLabelerror = false
        states.Hourly[index].nameLabelerror = false
        this.setState({
          states
        }, () => {
          this.TotalHourAmount(index)
        })
      }
      else {
        this.errorMessage("Service Name Already Exists !")
      }

    }
    else {
      if (states.Hourly[index].amountLabel === "") {
        states.Hourly[index].amountLabelerror = true
        this.setState({ states })
      }
      if (CommonValidation.removeSpace(states.Hourly[index].nameLabel) === "") {
        states.Hourly[index].nameLabelerror = true
        this.setState({ states })
      }
    }

  }
  TotalHourAmount(index) {
    let states = this.state
    let Amount = 0;
    for (let i = 0; i < states.Hourly[index].splitUps.length; i++) {
      Amount += states.Hourly[index].splitUps[i].amount
    }
    this.state.Hourly[index].total = Amount
    this.setState({ Hourly: this.state.Hourly })
  }
  HourDataDelete(index) {
    if(index === 0 && this.state.Hourly.length === 1){
      this.state.Hourly =  [
        {
          hourType: "",
          total: 0,
          splitUps: [],
          nameLabel: "",
          amountLabel: "",
          nameLabelerror: false,
          amountLabelerror: false,
        },
      ]
    }else{
      this.state.Hourly.splice(index, 1)
    }
    this.setState({ Hourly: this.state.Hourly })
  }
  HoursplitupDelete(index, SplitUpindex) {
    this.state.Hourly[index].splitUps.splice(SplitUpindex, 1)
    this.setState({ Hourly: this.state.Hourly }, () => { this.TotalHourAmount(index) })
  }
  HoursplitupEdit(index, SplitUpindex) {
    let states = this.state
    states.Hourly[index].nameLabel = states.Hourly[index].splitUps[SplitUpindex].service
    states.Hourly[index].amountLabel = states.Hourly[index].splitUps[SplitUpindex].amount
    this.setState({
      states,
      HourSplitEditMainIndex: index,
      HourSplitEditSplitIndex: SplitUpindex,
    })
  }
  AddNewhourdata(index) {
    let obj = { hourType: "", total: 0, splitUps: [], nameLabel: "", amountLabel: "" }
    if (this.state.Hourly[index].hourType !== "" && this.state.Hourly[index]?.splitUps.length !== 0) {
      this.state.Hourly.push(obj)
      this.setState({ Hourly: this.state.Hourly })
    } else {
      this.errorMessage("Enter Previous Hourly Datas")
    }

  }
  renderHourlyTable() {
    const { t } = this.props
    let states = this.state;
    return (
      <Box component={'div'} className="eMed_Room_Accord">
        {
          this.state.Hourly.map((item, index) => (
            <Box>
              <Accordion sx={{ marginTop: "1.3vw", backgroundColor: Colors.themeShade }} elevation={2}>
                <AccordionSummary
                  sx={{ height: '3.7vw' }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box component={'div'} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <FormControl size='small' sx={{ width: "10vw" }}>
                      <InputLabel>{t("SelectHours")}</InputLabel>
                      <Select
                        size='small'
                        value={states.Hourly[index].hourType}
                        label={t("SelectHours")}
                        onChange={(e) => {
                          let SameHour = false;
                          states.Hourly.forEach((list, index) => {
                            if (list.hourType === e.target.value) {
                              SameHour = true
                            }
                          })
                          if (!SameHour) {
                            states.Hourly[index].hourType = e.target.value
                            this.setState({ states })
                          } else {
                            this.errorMessage("Same Hour is Already Selected")
                          }

                        }}
                        MenuProps={{
                          style: { maxHeight: 200 }
                        }}
                      >
                        {
                          RoomHoursList?.length > 0 ?
                            RoomHoursList.map((list, index) => (
                              <MenuItem key={index} value={list.label}>{list.label === "Upto 24 Hrs" ? `${list.label} *` : list.label}</MenuItem>
                            )) : null
                        }
                      </Select>
                    </FormControl>
                    <TextField
                      disabled
                      inputProps={{ maxLength: 30 }}
                      sx={{ width: "10vw", marginLeft: '2vw' }}
                      size='small'
                      label={t("Amount")}
                      value={states.Hourly[index].total}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {CurrencySymbol}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box marginLeft={"2vw"}><CommonDeleteButton size={"1.5vw"} onClick={this.HourDataDelete.bind(this, index)} /></Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails className='eMed_Accord_Div'>
                  <TableContainer>
                    <Table stickyHeader size='small'>
                      <TableHead sx={{ height: '2vw' }}>
                        <TableRow>
                          <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('ServiceName')}</TableCell>
                          <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Amount')}</TableCell>
                          <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                        </TableRow>
                      </TableHead>
                      {item.splitUps.length > 0 ? item.splitUps.map((data, SplitUpindex) => {
                        return (
                          <TableBody>
                            <TableRow >
                              <TableCell align='left'>
                                {data.service.length > 15 ?
                                  <Tooltip title={data.service} arrow>
                                    <div>{data.service ? data.service.slice(0, 15) + "..." : '-'}</div>
                                  </Tooltip> : <div>{data.service ? data.service : '-'}</div>}
                              </TableCell>
                              <TableCell align='left'>
                                {data.amount.length > 15 ?
                                  <Tooltip title={data.amount} arrow>
                                    <div>{data.amount ? data.amount.slice(0, 15) + "..." : '-'}</div>
                                  </Tooltip> : <div>{data.amount ? data.amount : (data.amount === 0 ? 0 : '-')}</div>}
                              </TableCell>
                              <TableCell align='left'>
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                  <CommonEditButton size={"1.5vw"} onClick={this.HoursplitupEdit.bind(this, index, SplitUpindex)} />
                                  <CommonDeleteButton size={"1.5vw"} onClick={this.HoursplitupDelete.bind(this, index, SplitUpindex)} />
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )
                      }) : null
                      }
                    </Table>
                  </TableContainer>
                  <Box className='eMed_SplitUp_TextFields_div'>
                    <TextField
                      inputProps={{ maxLength: 30 }}
                      sx={{ width: "10vw" }}
                      size='small'
                      label={t("ServiceName")}
                      value={states.Hourly[index].nameLabel}
                      autoComplete="off"
                      onChange={(e) => {
                        states.Hourly[index].nameLabel = e.target.value
                        states.Hourly[index].nameLabelerror = false
                        this.setState({ states })
                      }}
                      error={states.Hourly[index].nameLabelerror}
                    />
                    <TextField
                      inputProps={{ maxLength: 30 }}
                      sx={{ width: "10vw" }}
                      size='small'
                      label={t("Amount")}
                      value={states.Hourly[index].amountLabel}
                      autoComplete="off"
                      onChange={(e) => {
                        let number = CommonValidation.NumberWithDot(e.target.value);
                        if (number && e.target.value >= 0 || e.target.value === "") {
                          states.Hourly[index].amountLabel = e.target.value
                          states.Hourly[index].amountLabelerror = false
                          this.setState({ states })
                        }
                      }}
                      error={states.Hourly[index].amountLabelerror}
                    />
                    <Tooltip placement='top' title={states.Hourly[index].hourType === "" ? "Select the Hour" : "Add Split-ups"} arrow>
                      <span>
                        <Button
                          disabled={states.Hourly[index].hourType === ""}
                          size='small'
                          variant='contained'
                          onClick={() => { this.addsplitups(index) }}>
                          {t("Add")}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </AccordionDetails>
              </Accordion>
              {
                index === states.Hourly.length - 1 ?
                  <Button sx={{ marginTop: '0.5vw' }} className='eMed_usrconf_btn' onClick={() => { this.AddNewhourdata(index) }}>
                    <img src={ImagePaths.AddIcon.default} alt="add" className='eMed_action_img' />
                  </Button>
                  : null
              }
            </Box>
          ))
        }
      </Box>
    )
  }

  Dayaddsplitups() {
    let states = this.state;
    if (CommonValidation.removeSpace(states.Day.nameLabel) !== "" && states.Day.amountLabel !== "") {
      let ServiceExists = false;
      let SameServiceId = false;
      states.Day.splitUps.forEach((list, Forindex) => {
        if (list.service === states.Day.nameLabel) {
          ServiceExists = true;
          SameServiceId = Forindex;
        }
      })
      if (!ServiceExists || SameServiceId === states.DaySplitEditMainIndex) {
        let data = { service: CommonValidation.removeSpace(states.Day.nameLabel), amount: +states.Day.amountLabel, }
        if (states.DaySplitEditMainIndex != null) {
          states.Day.splitUps[states.DaySplitEditMainIndex] = data
          this.setState({
            DaySplitEditMainIndex: null
          })
        }
        else {
          states.Day.splitUps.push(data)
        }

        states.Day.amountLabel = ""
        states.Day.nameLabel = ""

        this.setState({ states }, () => {
          let Amount = 0;
          for (let i = 0; i < states.Day.splitUps.length; i++) {
            Amount += states.Day.splitUps[i].amount
          }
          this.state.Day.total = Amount
          this.setState({ states })
        })
      }
      else {
        this.errorMessage("Service Name Already Exists !")
      }
    }
    else {
      if (states.Day.amountLabel === "") {
        states.Day.amountLabelerror = true
        this.setState({ states })
      }
      if (CommonValidation.removeSpace(states.Day.nameLabel) === "") {
        states.Day.nameLabelerror = true
        this.setState({ states })
      }
    }
  }
  DaysplitupEdit(index) {
    let states = this.state
    states.Day.nameLabel = CommonValidation.removeSpace(states.Day.splitUps[index].service)
    states.Day.amountLabel = states.Day.splitUps[index].amount
    this.setState({
      states,
      DaySplitEditMainIndex: index,
    })
  }
  DaysplitupDelete(index) {
    let states = this.state
    states.Day.splitUps.splice(index, 1)
    this.setState({
      states
    },()=>{
      let Amount = 0;
          for (let i = 0; i < states.Day.splitUps.length; i++) {
            Amount += states.Day.splitUps[i].amount
          }
          this.state.Day.total = Amount
          this.setState({ states })
    })
  }

  renderDayTable = () => {
    const { t } = this.props
    let states = this.state;
    return (
      <Box className="eMed_Room_Accord">
        <Box>
          <Accordion sx={{ marginTop: "1vw", backgroundColor: Colors.themeShade }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Box component={'div'} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <FormControl size='small' sx={{ width: "10vw" }}>
                  <InputLabel>{t("SelectHours")}</InputLabel>
                  <Select
                    value={states.Day.hourType}
                    label={t("SelectHours")}
                    disabled
                  >
                    <MenuItem value={states.Day.hourType}>{states.Day.hourType}</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  disabled
                  inputProps={{ maxLength: 30 }}
                  sx={{ width: "10vw", marginLeft: '2vw' }}
                  size='small'
                  label={t("Amount")}
                  value={states.Day.total}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {CurrencySymbol}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ height: "14vw" }} className='eMed_Accord_Div'>
              <TableContainer>
                <Table stickyHeader size='small'>
                  <TableHead sx={{ height: '2vw' }}>
                    <TableRow>
                      <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('ServiceName')}</TableCell>
                      <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Amount')}</TableCell>
                      <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {states.Day.splitUps.length > 0 ? states.Day.splitUps.map((data, index) => {
                    return (
                      <TableBody>
                        <TableRow >
                          <TableCell align='left'>
                            {data.service.length > 15 ?
                              <Tooltip title={data.service} arrow>
                                <div>{data.service ? data.service.slice(0, 15) + "..." : '-'}</div>
                              </Tooltip> : <div>{data.service ? data.service : '-'}</div>}
                          </TableCell>
                          <TableCell align='left'>
                            {data.amount.length > 15 ?
                              <Tooltip title={data.amount} arrow>
                                <div>{data.amount ? data.amount.slice(0, 15) + "..." : '-'}</div>
                              </Tooltip> : <div>{data.amount ? data.amount : (data.amount === 0 ? 0 : '-')}</div>}
                          </TableCell>
                          <TableCell align='left'>
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                              <CommonEditButton size={"1.5vw"} onClick={this.DaysplitupEdit.bind(this, index)} />
                              <CommonDeleteButton size={"1.5vw"} onClick={this.DaysplitupDelete.bind(this, index)} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  }) : null
                  }
                </Table>
              </TableContainer>
              <Box className='eMed_SplitUp_TextFields_div'>
                <TextField
                  inputProps={{ maxLength: 30 }}
                  sx={{ width: "10vw" }}
                  size='small'
                  label={t("ServiceName")}
                  value={states.Day.nameLabel}
                  autoComplete="off"
                  onChange={(e) => {
                    states.Day.nameLabel = e.target.value
                    states.Day.nameLabelerror = false
                    this.setState({ states })
                  }}
                  error={states.Day.nameLabelerror}
                />
                <TextField
                  inputProps={{ maxLength: 30 }}
                  sx={{ width: "10vw" }}
                  size='small'
                  label={t("Amount")}
                  value={states.Day.amountLabel}
                  autoComplete="off"
                  onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if (number && e.target.value >= 0 || e.target.value === "") {
                      states.Day.amountLabel = e.target.value
                      states.Day.amountLabelerror = false
                      this.setState({ states })
                    }
                  }}
                  error={states.Day.amountLabelerror}
                />
                <Button disabled={states.Day.hourType === ""} size='small' variant='contained' onClick={() => { this.Dayaddsplitups() }}>
                  Add
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    )
  }

  renderCorporateRight = () => {
    try {
      const { t } = this.props
      var states = this.state
      return (
        <Stack component={"div"}>
          <Paper className='eMed_common_Tbl_right' elevation={2}>
            <Typography className='eMed_refdoc_title'>{t("WardConfig")}</Typography>
            <Box>
              <Grid container className='eMed_Right_contents'>
                <Grid item xs={6}>
                  <TextField
                    inputProps={{ maxLength: 30 }}
                    sx={{ width: "13.5vw", }}
                    size='small'
                    label={t("WardName")+ " *"}
                    value={this.state.WardName}
                    autoComplete="off"
                    onChange={(e) => {
                      this.setState({
                        WardName: e.target.value
                      })

                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl size='small' sx={{ width: "13.5vw", marginLeft: '2vw' }}>
                    <InputLabel>{t("RoomType")+ " *"}</InputLabel>
                    <Select
                      MenuProps={{ style: { maxHeight: 350 } }}
                      value={this.state.RoomType}
                      label={t("RoomType")}
                      onChange={(e) => {
                        this.setState({ RoomType: e.target.value }, () => {
                          if (this.state.RoomType !== "Single Bed Room" && this.state.RoomType !== "Double Bed Room") {
                            this.setState({
                              UserRoomTypeSelected: true,
                              SelectedUserRoomType: this.state.RoomTypeList.filter(list => list['id'] === this.state.RoomType)
                            })
                          } else {
                            this.setState({
                              UserRoomTypeSelected: false
                            })
                          }
                        })
                      }}
                    >
                      {
                        this.state.RoomTypeList?.length > 0 ?
                          this.state.RoomTypeList.map((list, index) => (
                            <MenuItem key={index} value={list.id}>{list.room_type}</MenuItem>
                          )) : null
                      }
                      <Divider />
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton onClick={() => { this.setState({ AddnewRoomType: true }) }}>
                            <ListItemText primary="Add New Room Type" />
                          </ListItemButton>
                        </ListItem>
                        {
                          this.state.UserRoomTypeSelected ?
                            <ListItem disablePadding>
                              <ListItemButton onClick={() => { this.setState({ AddnewRoomType: true, RenameRoomClicked: true }) }}>
                                <ListItemText primary="Rename" />
                              </ListItemButton>
                            </ListItem> : null
                        }
                      </List>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} sx={{ marginTop: "0.5vw" }}>
                  <FormControl sx={{ marginLeft: "1vw" }}>
                    <RadioGroup
                      value={this.state.AcType}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                      onChange={(e) => { this.setState({ AcType: e.target.value }) }}
                    >
                      <FormControlLabel value="AC" control={<Radio size='small' />} label="AC" />
                      <FormControlLabel value="NON AC" control={<Radio size='small' />} label="Non AC" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={7} sx={{ marginTop: "0.5vw" }}>
                  <Box display={"flex"} flexDirection={"row"} marginLeft={"3vw"}>
                    <FormControlLabel control={<Checkbox size='small' checked={this.state.isWardForAttender} onChange={(e) => { 
                      this.setState({isWardForAttender : e.target.checked}, ()=>{
                        if(!this.state.isWardForAttender){
                          this.setState({wardTotalAmount:""})
                        }
                      }) 
                      }} />} label={t("Add this Ward for Attender")} />
                  </Box>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <Box className="eMed_Filter_Header">
                    <Typography marginLeft={"1vw"}>{t("Attender Configuration")}</Typography>
                  </Box>
                  <Box component={'div'} display={"flex"} flexDirection={"row"} justifyContent={'flex-start'} alignItems={"center"} margin={"1vw"}>
                    <FormControl size='small' sx={{ width: "15vw" }}>
                      <InputLabel>{t("SelectHours")}</InputLabel>
                      <Select
                        disabled
                        size='small'
                        value={"Upto 24 Hrs"}
                        label={t("SelectHours")}
                        MenuProps={{
                          style: { maxHeight: 200 }
                        }}
                      >
                        <MenuItem  value={"Upto 24 Hrs"}>{"Upto 24 Hrs"}</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      disabled={!this.state.isWardForAttender}
                      inputProps={{ maxLength: 30 }}
                      sx={{ width: "15vw", marginLeft: '2vw' }}
                      size='small'
                      label={t("Amount")}
                      onChange={(e) => {
                        let number = CommonValidation.NumberWithDot(e.target.value);
                        if (number && e.target.value >= 0 || e.target.value === "") {
                          states.wardTotalAmount = e.target.value
                          this.setState({ states })
                        }
                      }}
                      value={this.state.wardTotalAmount}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {CurrencySymbol}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} >
                  <Box className="eMed_Filter_Header" sx={{ marginTop: "0vw" }}>
                    <Typography marginLeft={"1vw"}>{t("Patient Configuration")}</Typography>
                  </Box>
                  <ButtonGroup sx={{ marginTop: "0.5vw" }}>
                    <Button variant={this.state.TableType === "hour" ? 'contained' : 'outlined'} sx={{ width: "15.7vw" }} onClick={() => { this.setState({ TableType: "hour" }) }}>{t("Hourly")}</Button>
                    <Button variant={this.state.TableType === "day" ? 'contained' : 'outlined'} sx={{ width: "15.7vw" }} onClick={() => { this.setState({ TableType: "day" }) }}>{t("PerDay")}</Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Box className="emed_split_tableDiv">
                    {this.state.TableType === "hour" ? this.renderHourlyTable() : this.renderDayTable()}
                  </Box>
                </Grid>

              </Grid>
            </Box>
            <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
              <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
              <Button disabled={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Ward Configuration", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.validation() }}>{t("CreateWard")}</Button>
            </Stack>
          </Paper>
        </Stack>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  popupClose() {
    this.setState({
      isDeleteCliked: false
    })
  }
  DeleteWardData() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.WARD_CONFIG + "?ward_id=" + states.SelectedList.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.ClearData()
            this.GetWardList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }
  historyPopUpClose() {
    this.setState({
      isHistoryClicked: false,
      SelectedHistory: []
    })
  }
  ClosePopUp() {
    this.setState({
      AddnewRoomType: false
    }, () => { this.GetRoomType() })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    let { t } = this.props
    try {
      return (
        <Box component={"div"} className='eMed_common_container'>
          <Grid container className='eMed_refdoc_content' >
            <Grid item xs={7.5}>
              {this.renderCorporateLeft()}
            </Grid>
            <Grid item xs={4.5}>
              {this.renderCorporateRight()}
            </Grid>
          </Grid>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} Ward ${this.state.SelectedList ? this.state.SelectedList?.ward_name : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteWardData.bind(this)}
            DeleteNotify={t("effectEntireSetting")}
          /> : null}
          {(this.state.isHistoryClicked) ?
            <CommonPopUp width={"75vw"}
              backgroundColor={Colors.white}
              popUpClose={this.historyPopUpClose.bind(this)}
              title={t("History")}
              component={this._getHistory.bind(this, this.state.SelectedHistory)} />
            : null
          }
          {
            this.state.AddnewRoomType ?
              <RoomTypeAddPop ClosePopUp={this.ClosePopUp.bind(this)} RenameRoomType={this.state.RenameRoomClicked ? this.state.SelectedUserRoomType : null} />
              : null
          }
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
}

export default withTranslation()(WardConfig)
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Box, Button, FormControlLabel, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../../../Components/Common Components/CommonComponents'
import { getCachevalue } from '../../../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../../../Components/Loader'
import { Colors } from '../../../../../../Styles/Colors'
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls'

class PreAuth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ipPatientData: {},
            Approval_Status: true,
            auth_Amount: "",
            comments: "",
            CreditDetails: []
        }
    }

    componentDidMount() {
        let ipPatientData = getCachevalue("IppatientData")
        let patientData = JSON.parse(ipPatientData)
        this.setState({
            ipPatientData: patientData
        }, () => { this.getPatientData() })
    }

    getPatientData = () => {
        try {
            let AdmissionId = this.state?.ipPatientData?.id ? this.state?.ipPatientData?.id : ""
            RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + AdmissionId)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            Approval_Status: response.data.data[0].policy_status === "Not Approved" ? false : true,
                            auth_Amount: response.data.data[0].policy_amount ? response.data.data[0].policy_amount : "",
                            comments: response.data.data[0].comments ? response.data.data[0].comments : "",
                            isDisabled: response.data.data[0].policy_status == null ? false : true,
                            CreditDetails: response.data.data[0]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key = false) => {
        this.setState({
            isLoader: key
        })
    }

    handleClear = () => {
        this.setState({
            auth_Amount: "",
            comments: ""
        })
    }

    handleSave = () => {
        let Company_Name = this.state?.CreditDetails?.credit_type === "Insurance credit" ? this.state?.CreditDetails?.insurance_name : this.state?.CreditDetails?.credit_type === "Corprate credit" ? this.state?.CreditDetails?.employer_name : null;
        let ID_No = this.state?.CreditDetails?.credit_type === "Insurance credit" ? this.state?.CreditDetails?.policy_no : this.state?.CreditDetails?.credit_type === "Corprate credit" ? this.state?.CreditDetails?.id_no : null;
        try {
            let states = this.state;
            var data = {
                "ip_admission_id": states?.ipPatientData?.id,
                "is_approved": +states?.Approval_Status,
                "claim_amount": 0,
                "policy_amount": +states?.auth_Amount,
                "comments": states?.comments,
                "primary_payment_type": this.state?.CreditDetails?.credit_type === "Corprate credit" ? 'Corprate credit' : this.state?.CreditDetails?.credit_type === "Insurance credit" ? 'Insurance credit' : null,
                "policy_company_name": Company_Name,
                "policy_number": ID_No,
            }
            RestAPIService.create(data, Serviceurls.PRE_AUTHORIZATION_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.getPatientData()
                        this.successMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderLeftContent = () => {
        const { t } = this.props
        return (
            <div>
                <Box component={'div'} className='eMed_IP_Discharge_Header'>
                    <Box>
                        <Typography paddingLeft={"0.5vw"} fontWeight={600}>{t('Pre Authorization')}</Typography>
                    </Box>
                    {this.state?.CreditDetails?.credit_type === "Corprate credit" ? null :
                        <Box component={'div'}>
                            <RadioGroup value={this.state.Approval_Status}
                                sx={{ display: 'flex', flexDirection: 'row', width: '18vw' }}
                                onClick={(e) => this.setState({ Approval_Status: e.target.value })}
                            >
                                <FormControlLabel value={true} control={<Radio disabled={this.state.isDisabled} size='small' />} label='Approved' />
                                <FormControlLabel value={false} control={<Radio disabled={this.state.isDisabled} size='small' />} label='Not Approved' />
                            </RadioGroup>
                        </Box>}
                </Box>
                {this.state?.CreditDetails?.credit_type === "Corprate credit" ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
                        There was no insurance linked with this patient
                    </div> :
                    <div>
                        <Box component={'div'} style={{ padding: "0.5vw", display: "flex", flexDirection: "column", marginLeft: "1.5vw" }}>
                            <Typography mt={"0.5vw"} fontSize={"0.85vw"} fontWeight={600}>{t('Pre Authorization Details')}</Typography>
                            <TextField
                                inputProps={{ maxLength: 30 }}
                                sx={{ width: "20vw", marginTop: "1.5vw" }}
                                size='small'
                                autoComplete="off"
                                label={"Amount"}
                                disabled={this.state.isDisabled}
                                value={this.state.auth_Amount}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    let isValid = false;
                                    isValid = CommonValidation.NumberWithDot(value);
                                    if (value === "" || isValid && value > 0) {
                                        this.setState({
                                            auth_Amount: e.target.value
                                        })
                                    }
                                }}
                            />
                            <TextField
                                label={"Comments"}
                                sx={{ width: '30vw', marginTop: "1.5vw" }}
                                multiline={true}
                                disabled={this.state.isDisabled}
                                onChange={(e) => {
                                    this.setState({
                                        comments: e.target.value
                                    })
                                }}
                                inputProps={{ maxLength: 250 }}
                                value={this.state.comments}
                                rows={3} />
                        </Box>
                        <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ marginTop: "8vw", marginRight: "1vw" }} alignItems="center" justifyContent="flex-end">
                            <Button disabled={this.state.isDisabled} variant='outlined' size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                            <Button disabled={this.state.isDisabled || (this.state?.auth_Amount <= 0 && this.state?.auth_Amount === "")} variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                        </Stack>
                    </div>}
            </div>
        )
    }

    render() {
        const { t } = this.props
        var { ipPatientData } = this.state
        let attender = {
            lineOne: t("AttenderName"),
            lineTwo: `${ipPatientData.attender_name ? ipPatientData.attender_name : '-'}`,
            lineFour: t('ContactNumber'),
            LineFive: `${ipPatientData.attender_mobile ? ipPatientData.attender_mobile : '-'}`
        }
        let consultant = {
            lineOne: t("ConsultantName"),
            lineTwo: `${ipPatientData.doctor_name ? ipPatientData.doctor_name : '-'}`,
        }
        return (
            <Box sx={{ backgroundColor: Colors.ComponentBgColor }}>
                <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header'>
                    <Box display={'flex'} alignItems='center'>
                        <Box className='eMed_Patient_Details_Long eMed_patient_box'>
                            <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                        </Box>
                        <CommonPatientCard details={attender} showDetailed={true} />
                        <ConsultantPatientCard name={consultant} />
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
                    <Paper elevation={3} className='eMed_DocNotes_Left_Main'>
                        {this.renderLeftContent()}
                    </Paper>
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PreAuth);
import { Box } from "@mui/material";
import React, { Component } from "react";
import { CommonNavigationHeader } from "../../../Components/Common Components/CommonComponents";
import TestConfig from "./TestConfig";
import TestGroup from "./TestGroup";
import TestPackage from "./TestPackage";
import ConsumableMap from "./ConsumableMap";
import PatientDemographics from "./PatientDemographics";
import DoctorDemographics from "./DoctorDemographics";
import OutsourceConfig from "./OutsourceConfig";
import OutsourceMapping from "./OutsourceMapping";
import AmountConfig from "./AmountConfig";

const navigationHeaderList = [
    { id: 0, TabName: "Test Configuration", value: "TestConfig", content: <TestConfig /> },
    { id: 1, TabName: "Test Group", value: "TestGroup", content: <TestGroup /> },
    { id: 2, TabName: "Consumable Mapping", value: "ConsumableMap", content: <ConsumableMap /> },
    { id: 3, TabName: "Patient Demographics", value: "PatientDemographics", content: <PatientDemographics /> },
    { id: 4, TabName: "Referred Doctors", value: "DoctorDemographics", content: <DoctorDemographics /> },
    { id: 5, TabName: "Outsource Configuration", value: "OutsourceConfig", content: <OutsourceConfig/>},
    { id: 6, TabName: "Outsource Mapping", value: "OutsourceMapping", content: <OutsourceMapping/>},
    { id: 7, TabName: "Amount Configuration", value: "AmountConfig", content: <AmountConfig/>}
]
class LabConfig extends Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: "TestConfig", 
            common_search: '',
            test_search_key: '',
            set_search_key: '',
            consumableMap_search_key: '',
            patient_demo_search_key: '',
            doc_demo_search_key: '',
            outsource_config_search_key: '',
            outsource_map_search_key: '',
        }
    }

    componentDidMount(){
        this.props.changeScreen('5')
    }

    componentWillReceiveProps(props){
        var {selectedScreen} = this.state
        if (props.search != this.state.common_search) {
            this.setState({
                common_search:props.search
            })
            if(selectedScreen === "TestConfig"){
                this.setState({
                    test_search_key: props.search
                })
            }else if(selectedScreen === "TestGroup"){
                this.setState({
                    set_search_key: props.search
                })
            }else if(selectedScreen === "ConsumableMap"){
                this.setState({
                    consumableMap_search_key: props.search
                })
            }else if(selectedScreen === "PatientDemographics"){
                this.setState({
                    patient_demo_search_key: props.search
                })
            }else if(selectedScreen === "DoctorDemographics"){
                this.setState({
                    doc_demo_search_key: props.search
                })
            }else if(selectedScreen === "OutsourceConfig"){
                this.setState({
                    outsource_config_search_key: props.search
                })
            }else if(selectedScreen === "OutsourceMapping"){
                this.setState({
                    outsource_map_search_key: props.search
                })
            }
        }

    }

    // Navigation Function start
    NavigateScreen(id) {
        this.setState({
            selectedScreen: navigationHeaderList[id].value
        })
        this.props.clrSearch()
        this.props.subScreenChange(navigationHeaderList[id].value)
    }
    // Navigation Function end

    render() {
        return (
            <Box>
               <CommonNavigationHeader menu={navigationHeaderList} NavigateScreen={this.NavigateScreen.bind(this)} />
               <div>
                {this.state.selectedScreen === 'TestConfig' ? <TestConfig search_key={this.state.test_search_key} /> : 
                    this.state.selectedScreen === 'TestGroup' ? <TestGroup search_key={this.state.set_search_key} /> :
                    this.state.selectedScreen === 'ConsumableMap' ? <ConsumableMap search_key={this.state.consumableMap_search_key} /> : 
                    this.state.selectedScreen === 'PatientDemographics' ? <PatientDemographics search_key={this.state.patient_demo_search_key} /> :
                    this.state.selectedScreen === 'DoctorDemographics' ? <DoctorDemographics search_key={this.state.doc_demo_search_key} /> : 
                    this.state.selectedScreen === 'OutsourceConfig' ? <OutsourceConfig search_key={this.state.outsource_config_search_key} /> :
                    this.state.selectedScreen === 'OutsourceMapping' ? <OutsourceMapping search_key={this.state.outsource_map_search_key} /> :
                    this.state.selectedScreen === 'AmountConfig' ? <AmountConfig search_key={this.state.amount_search_key} /> : null}
               </div>
            </Box>
        )
    }
}

export default LabConfig;
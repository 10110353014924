import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Autocomplete, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Colors } from '../../../../Styles/Colors'
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import { Gestational_Age, Made_Of_Delivery } from '../../../../Utility/Constants'
import { DataGrid } from '@mui/x-data-grid'
export default class DocCollabHistory extends Component {

  constructor(props) {
    super(props)
    this.state = {
      clinicId: '',
      patientId: '',
      appointmentId: '',
      getLoad: false,
      bg_history: {},
      general_history: [],
      nutrition_history: [],
      obgyn_history: [],
      opt_history: {},
      pediatric_history: {},
      physical_history: {},
      mensuralHistory: {},
      psy_history: [],
      isOPT: false,
      isPDHis: false,
      modeofDelivery: Made_Of_Delivery,
      gestationalAge: Gestational_Age,
      medicalHistory: [],
      isGPModule: false,

    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    let ServiceType = localGetItem("loggedInUserInfo")
    let myUser = JSON.parse(ServiceType)
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id,
      isOPT: myUser.service_type === "OPT" ? true : false,
      isPDHis: myUser.service_type === "PD" ? true : false,
      isGPModule: myUser.service_type === "GP" ? true : false,
    }, () => {
      this.getPatientDetailData()
      this.getCollabHistoryData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getCollabHistoryData = () => {
    try {
      this.setState({ getLoad: true })
      RestAPIService.getAll(Serviceurls.DOC_COLLAB_HISTORY_REPORT + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              bg_history: response?.data?.data?.bg_history,
              diabetic_history: response?.data?.data?.diabetic_history,
              general_history: response?.data?.data?.general_history,
              nutrition_history: response?.data?.data?.nutrition_history,
              obgyn_history: response?.data?.data?.obgyn_history,
              opt_history: response?.data?.data?.opt_history,
              pediatric_history: response?.data?.data?.pediatric_history,
              physical_history: response?.data?.data?.physical_history,
              psy_history: response?.data?.data?.psy_history,
              getLoad: false,
              medicalHistory: response?.data?.data?.pediatric_history?.pd_medical_history,
              mensuralHistory: response?.data?.data?.menstrual_history ? response?.data?.data?.menstrual_history : {},
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ getLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ getLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ getLoad: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  generalHistory = () => {
    try {
      let states = this.state
      let { general_history } = this.state
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>General History</Typography>
          </Box>
          {general_history?.length > 0 ? general_history?.map((list, index) => (
            <Box component={'div'} key={index} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'} borderBottom={'2px solid lightgray'}>
              <Box component={'div'}>
                <Box component={'div'} ml={'1vw'} display={'flex'} justifyContent={'center'}>
                  <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Appointment Date :
                    <Typography component={'span'} mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>{` ${formatDate(list?.appointment_date?.slice(0, 10))}`}</Typography>
                  </Typography>
                </Box>
              </Box>
              <Box component={'div'}>
                <Box component={'div'} ml={'1vw'}>
                  <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>History of Allergies</Typography>
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Allergy Type</Typography>
                    {list?.allergy?.length > 0 ? list?.allergy?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.allergy_type ? `${item?.allergy_type === 1 ? 'Medical Allergy' : 'Non Medical Allergy'}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Allergy Name</Typography>
                    {list?.allergy?.length > 0 ? list?.allergy?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.name ? `${item?.name}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Diagnosed Date</Typography>
                    {list?.allergy?.length > 0 ? list?.allergy?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.diagnosed_date ? `${formatDate(item?.diagnosed_date)}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                </Box>
              </Box>

              <Box component={'div'}>
                <Box component={'div'} ml={'1vw'}>
                  <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Medical History</Typography>
                </Box>
                <Box component={'div'} display={'flex'} mb={'1vw'}>
                  <Typography mb={'0.5vw'} pl={'5vw'} pr={'1vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Problems</Typography>
                  {list?.medical_history?.length > 0 ? list?.medical_history?.map((item, index) => (
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.name ? `${item?.name}${index === list?.medical_history?.length - 1 ? '' : ','}` : '-'}</Typography>
                  )) : '-'}
                </Box>
              </Box>
              <Box component={'div'}>
                <Box component={'div'} ml={'1vw'}>
                  <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Social History</Typography>
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
                  <Box component={'div'} width={'18vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Alochol</Typography>
                    {list?.social_history?.length > 0 ? list?.social_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.alcohol ? `${item?.alcohol}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'18vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Smoking</Typography>
                    {list?.social_history?.length > 0 ? list?.social_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.smoking ? `${item?.smoking}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'18vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Drugs</Typography>
                    {list?.social_history?.length > 0 ? list?.social_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.drugs ? item?.drugs?.length > 5 ?
                        <Tooltip placement='top' title={item?.drugs}><div>{item?.drugs?.slice(0, 5)?.join(',') + '...'}</div></Tooltip> :
                        item?.drugs?.join(',') : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'18vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Daily Activity</Typography>
                    {list?.social_history?.length > 0 ? list?.social_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.daily_activity ? `${item?.daily_activity}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
                  <Box component={'div'} width={'18vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Is married</Typography>
                    {list?.social_history?.length > 0 ? list?.social_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{`${item?.is_married ? 'Yes' : 'No'}`}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'18vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Is exercise done daily</Typography>
                    {list?.social_history?.length > 0 ? list?.social_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{`${item?.is_exercise_done_daily ? 'Yes' : 'No'}`}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'18vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Occupation</Typography>
                    {list?.social_history?.length > 0 ? list?.social_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.occupation ? item?.occupation?.length > 50 ?
                        <Tooltip placement='top' title={item?.occupation}><div>{item?.occupation?.slice(0, 50) + '...'}</div></Tooltip> :
                        item?.occupation : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'18vw'}></Box>
                </Box>
              </Box>

              <Box component={'div'}>
                <Box component={'div'} ml={'1vw'}>
                  <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Family History</Typography>
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Family Member</Typography>
                    {list?.family_history?.length > 0 ? list?.family_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.family_member ? `${item?.family_member}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Disease Name</Typography>
                    {list?.family_history?.length > 0 ? list?.family_history?.map((item) => (
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.disease_names ? `${item?.disease_names}` : '-'}</Typography>
                    )) : '-'}
                  </Box>
                  <Box component={'div'} width={'25vw'}></Box>
                </Box>
              </Box>
            </Box>
          )) : <Box component={'div'} sx={{ backgroundColor: Colors.white, display: 'flex', justifyContent: 'center', alignItems: 'center' }} height={'20vw'} pt={'0.5vw'} pb={'0.5vw'}>No Records To Be Shown</Box>}
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  diabeticHistory = () => {
    try {
      let { diabetic_history } = this.state
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Diagnosis History</Typography>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'}>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>New to diagnosis ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.new_diabetes_diagnosed ? 'Yes' : 'No'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Do you check the blood glucouse level ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.blood_glucose_check ? 'Yes' : 'No'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Do you get low blood glucouse ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.low_blood_glucose ? 'Yes' : 'No'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>How many years have you had diabeties ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.diabetes_duration ? `${diabetic_history?.diabetes_duration} Year` : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Who is your family has/had diabeties ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.diabetes_family_history ? diabetic_history?.diabetes_family_history : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>What type of diabeties do you have ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.diabetes_type ? (diabetic_history?.diabetes_type === 1) ? 'Type 1' : (diabetic_history?.diabetes_type === 2) ? 'Type 2' : 'Not Sure' : '-'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>How is your diabeties treatment ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.treatment_method ? JSON.parse(diabetic_history?.treatment_method)?.join(',') : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Name of the blood glucouse meter</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.bg_check_meter ? diabetic_history?.bg_check_meter : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Usual Result</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.bg_usual_result ? `${diabetic_history?.bg_usual_result} mg` : '-'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Last A1c Result</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{diabetic_history?.last_a1c_result ? `${diabetic_history?.last_a1c_result} %` : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}></Box>
              <Box component={'div'} width={'25vw'}></Box>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNutritionType = (nutrition) => {
    let type = ''
    switch (nutrition) {
      case 1:
        type = 'Vegetarian'
        break;
      case 2:
        type = 'Vegan'
        break;
      case 3:
        type = 'Eggetarian'
        break;
      case 4:
        type = 'Non Vegetarian'
        break;
      case 5:
        type = 'Mixed'
        break;
      default:
        type = ''
        break;
    }
    return type
  }

  NutritionHistory = () => {
    let { nutrition_history } = this.state
    try {
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Nutrition History</Typography>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'}>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Have you ever had nutritional counselling ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.nutritional_counselling ? 'Yes' : 'No'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Type of nutrition</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.nutrition_type ? this.renderNutritionType(nutrition_history?.nutrition_type) : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}></Box>
            </Box>
            <Box component={'div'} ml={'1vw'} mb={'0.5vw'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>Please list the typical foods and amount that yoy eat and drink on a typical day</Typography>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Early Morning</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.food_early_morning_amount ? `${nutrition_history?.food_early_morning_amount}` : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Breakfast</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.food_breakfast_amount ? nutrition_history?.food_breakfast_amount : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Mid Morning</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.food_mid_morning_amount ? nutrition_history?.food_mid_morning_amount : '-'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Lunch</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.food_lunch_amount ? nutrition_history?.food_lunch_amount : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Evening</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.food_evening_amount ? nutrition_history?.food_evening_amount : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Dinner</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.food_dinner_amount ? `${nutrition_history?.food_dinner_amount} mg` : '-'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Late Night</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.food_late_night_amount ? `${nutrition_history?.food_late_night_amount} %` : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Water consumption (In Liters)</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{nutrition_history?.water_consumption ? `${+nutrition_history?.water_consumption}` : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}></Box>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  bloodGlucouseHistory = () => {
    try {
      let { bg_history } = this.state
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Blood Glucouse Monitoring</Typography>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'}>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>How often do you test you blood glucouse per month ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{bg_history?.bg_check_frequency ? `${bg_history?.bg_check_frequency} times` : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Any recent blood glucose above 300 ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{bg_history?.bg_above_300 ? 'Yes' : 'No'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Any recent blood glucose below 80 ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{bg_history?.bg_below_70 ? 'Yes' : 'No'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Have you had any of the following signs of high blood glucose in the past 3 months ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{bg_history?.high_blood_glucose ? bg_history?.high_blood_glucose?.length > 5 ?
                  <Tooltip placement='top' title={bg_history?.high_blood_glucose?.join(',')}><div>{bg_history?.high_blood_glucose?.slice(0, 5)?.join(',') + '...'}</div></Tooltip> :
                  bg_history?.high_blood_glucose?.join(',') : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Have you had any of the following signs of low blood glucose in the past 3 months ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{bg_history?.low_blood_glucose ? bg_history?.low_blood_glucose?.length > 5 ?
                  <Tooltip placement='top' title={bg_history?.low_blood_glucose?.join(',')}><div>{bg_history?.low_blood_glucose?.slice(0, 5)?.join(',') + '...'}</div></Tooltip> :
                  bg_history?.low_blood_glucose?.join(',') : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Do you check your feet ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{bg_history?.check_your_feet ? 'Yes' : 'No'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Do you have any problem in your vision ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{bg_history?.vision_problem ? 'Yes' : 'No'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}></Box>
              <Box component={'div'} width={'25vw'}></Box>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  MensuralHistory = () => {
    try {
      let { mensuralHistory } = this.state
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Menstrual History</Typography>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'}>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Age at Menarche</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{mensuralHistory?.age_at_menarche ? mensuralHistory?.age_at_menarche : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Menstrual cycle</Typography>
                {mensuralHistory?.mensural_cycle ? mensuralHistory?.mensural_cycle?.length > 55 ?
                  <Tooltip placement='top' title={mensuralHistory?.mensural_cycle} arrow>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{mensuralHistory?.mensural_cycle.slice(0, 55) + "..."}</Typography>
                  </Tooltip> :
                  <Typography fontSize={'0.9vw'} fontWeight={600}>{mensuralHistory?.mensural_cycle}</Typography> : "-"}
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Child birth/abortion</Typography>
                {mensuralHistory?.child_birth ? mensuralHistory?.child_birth?.length > 55 ?
                  <Tooltip placement='top' title={mensuralHistory?.child_birth} arrow>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{mensuralHistory?.child_birth.slice(0, 55) + "..."}</Typography>
                  </Tooltip> :
                  <Typography fontSize={'0.9vw'} fontWeight={600}>{mensuralHistory?.child_birth}</Typography> : "-"}              </Box>
            </Box>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'}>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Age at menopause</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{mensuralHistory?.age_at_menopause ? mensuralHistory?.age_at_menopause : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}></Box>
              <Box component={'div'} width={'25vw'}></Box>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  OcularHistory = () => {
    try {
      let { opt_history } = this.state
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Ocular History</Typography>
          </Box>
          {Object.keys(opt_history)?.length > 0 ?
            <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'} borderBottom={'2px solid lightgray'} pl={"1.5vw"}>
              {opt_history?.ocular_history?.length > 0 ? opt_history?.ocular_history?.map((item, oculrKey) => (
                <Box component={'div'} key={oculrKey} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Created Date</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.created_date ? (item?.created_date.split("T")[0]).split("-").reverse().join("-") : '-'}</Typography>
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Drug Name</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.drug_name ? `${item?.drug_name}` : '-'}</Typography>
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Years</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.years ? item?.years : '-'}</Typography>
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Months</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.months ? item?.months : '-'}</Typography>
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>comments</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.comments ? item?.comments : '-'}</Typography>
                  </Box>
                </Box>
              )) : <Box component={'div'} sx={{ backgroundColor: Colors.white, display: 'flex', justifyContent: 'center', alignItems: 'center' }} height={'20vw'} pt={'0.5vw'} pb={'0.5vw'}>No Records To Be Shown</Box>}
            </Box>
            : <Box component={'div'} sx={{ backgroundColor: Colors.white, display: 'flex', justifyContent: 'center', alignItems: 'center' }} height={'20vw'} pt={'0.5vw'} pb={'0.5vw'}>No Records To Be Shown</Box>}
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  SurgeryHistory = () => {
    try {
      let { opt_history } = this.state
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Surgery History</Typography>
          </Box>
          {Object.keys(opt_history)?.length > 0 ?
            <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'} borderBottom={'2px solid lightgray'}>
              {opt_history?.surgery_history?.length > 0 ? opt_history?.surgery_history?.map((item, surgeryKey) => (
                <Box component={'div'} key={surgeryKey} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Created Date</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.created_date ? (item?.created_date.split("T")[0]).split("-").reverse().join("-") : '-'}</Typography>
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Surgery Name</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.surgery_name ? `${item?.surgery_name}` : '-'}</Typography>
                  </Box>
                  <Box component={'div'} width={'25vw'}>
                    <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Years</Typography>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.no_of_years ? item?.no_of_years : '-'}</Typography>
                  </Box>
                </Box>
              )) : <Box component={'div'} sx={{ backgroundColor: Colors.white, display: 'flex', justifyContent: 'center', alignItems: 'center' }} height={'20vw'} pt={'0.5vw'} pb={'0.5vw'}>No Records To Be Shown</Box>}
            </Box>
            : <Box component={'div'} sx={{ backgroundColor: Colors.white, display: 'flex', justifyContent: 'center', alignItems: 'center' }} height={'20vw'} pt={'0.5vw'} pb={'0.5vw'}>No Records To Be Shown</Box>}
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PDHistory = () => {
    try {
      let { pediatric_history, modeofDelivery, gestationalAge } = this.state
      let ModeDelivery = ''
      let ModeofDel = modeofDelivery.find(option => option.value === pediatric_history?.pd_birth_history?.mode_of_delivery);
      if (ModeofDel) {
        ModeDelivery = ModeofDel.label
      }
      let gestational_Age = ''
      let gestationAge = gestationalAge.find(option => option.value === pediatric_history?.pd_birth_history?.gestational_age);
      if (gestationAge) {
        gestational_Age = gestationAge.label
      }
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'1vw'} sx={{ backgroundColor: Colors.Background }}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>PD History</Typography>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'}>
            <Box component={'div'} ml={'1vw'}>
              <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Birth History</Typography>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Pregnancy Complication ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{pediatric_history?.pd_birth_history?.pregnancy_complication ? 'Yes' : 'No'}</Typography>
                <Typography mb={'0vw'}>{
                  pediatric_history?.pd_birth_history?.pregnancy_comp_desc?.length > 30 ?
                    <Tooltip title={pediatric_history?.pd_birth_history?.pregnancy_comp_desc} placement='top'><Box fontSize={'0.8vw'}>{'Comment: ' + pediatric_history?.pd_birth_history?.pregnancy_comp_desc.slice(0, 30) + '...'}</Box></Tooltip> :
                    <Box fontSize={'0.8vw'}>{pediatric_history?.pd_birth_history?.pregnancy_comp_desc ? 'Comment: ' + pediatric_history?.pd_birth_history?.pregnancy_comp_desc : '-'}</Box>}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>After Birth Complication ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{pediatric_history?.pd_birth_history?.after_birth_complication ? 'Yes' : 'No'}</Typography>
                <Typography mb={'0vw'}>{
                  pediatric_history?.pd_birth_history?.after_birth_comp_desc?.length > 30 ?
                    <Tooltip title={pediatric_history?.pd_birth_history?.after_birth_comp_desc} placement='top'><Box fontSize={'0.8vw'}>{'Comment: ' + pediatric_history?.pd_birth_history?.after_birth_comp_desc.slice(0, 30) + '...'}</Box></Tooltip> :
                    <Box fontSize={'0.8vw'}>{pediatric_history?.pd_birth_history?.after_birth_comp_desc ? 'Comment: ' + pediatric_history?.pd_birth_history?.after_birth_comp_desc : '-'}</Box>}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Neonatal Period ?</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{pediatric_history?.pd_birth_history?.neonatal_period ? 'Yes' : 'No'}</Typography>
                <Typography mb={'0vw'}>{
                  pediatric_history?.pd_birth_history?.neonatal_period_reason?.length > 30 ?
                    <Tooltip title={pediatric_history?.pd_birth_history?.neonatal_period_reason} placement='top'><Box fontSize={'0.8vw'}>{'Comment: ' + pediatric_history?.pd_birth_history?.neonatal_period_reason.slice(0, 30) + '...'}</Box></Tooltip> :
                    <Box fontSize={'0.8vw'}>{pediatric_history?.pd_birth_history?.neonatal_period_reason ? 'Comment: ' + pediatric_history?.pd_birth_history?.neonatal_period_reason : '-'}</Box>}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Mode of Delivery</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{ModeDelivery ? ModeDelivery : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Gestational Age</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{gestational_Age ? gestational_Age : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Birth Weight</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{pediatric_history?.pd_birth_history?.birth_weight ? `${pediatric_history?.pd_birth_history?.birth_weight}kg` : '-'}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} mb={'1vw'}>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Development</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{pediatric_history?.pd_birth_history?.development ? pediatric_history?.pd_birth_history?.development : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Feeding History</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{pediatric_history?.pd_birth_history?.feeding_history ? pediatric_history?.pd_birth_history?.feeding_history : '-'}</Typography>
              </Box>
              <Box component={'div'} width={'25vw'}></Box>
            </Box>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'} pb={'0.5vw'}>
            <Box component={'div'} ml={'1vw'}>
              <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Medical History</Typography>
            </Box>
            <Box component={'div'} ml={'0.5vw'} mt={'0.5vw'} mb={'.5vw'} width={'90vw'} height={'20vw'}>
              {this.renderTbl()}
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTbl = () => {
    try {
      return (
        <Box border={'1px solid lightgray'}>
          <TableContainer sx={{ maxHeight: '20vw' }}>
            <Table size='small' stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '10vw' }}>{"S.No"}</TableCell>
                  <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '20vw' }}>{"Created Date"}</TableCell>
                  <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '35vw' }}>{"Surgery"}</TableCell>
                  <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '35vw' }}>{"Description"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.medicalHistory ? this.state.medicalHistory.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: '10vw' }}>{index + 1}</TableCell>
                    <TableCell sx={{ width: '20vw' }}>{item.created_date ? item.created_date : '-'}</TableCell>
                    <TableCell sx={{ width: '35vw' }}>{item?.surgery?.length > 25 ?
                      <Tooltip title={item?.surgery} placement='top'><Box>{(item.surgery).slice(0, 25) + '...'}</Box></Tooltip> :
                      item?.surgery ? item?.surgery : '-'}</TableCell>
                    <TableCell sx={{ width: '35vw' }}>{item?.description?.length > 25 ?
                      <Tooltip title={item?.description} placement='top'><Box>{(item.description).slice(0, 25) + '...'}</Box></Tooltip> :
                      item?.description ? item?.description : '-'}</TableCell>
                  </TableRow>
                )) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderHistory = () => {
    try {
      return (
        <Box component={'div'} width={'100%'}>
          {this.state.isOPT ?
            <Box component={'div'} width={'100%'}>
              <Box component={'div'} mb={'1vw'}>
                {this.generalHistory()}
              </Box>
              <Box component={'div'} mb={'1vw'}>
                {this.OcularHistory()}
              </Box>
              <Box component={'div'} mb={'1vw'}>
                {this.SurgeryHistory()}
              </Box>
            </Box>
            :
            <Box>
              <Box component={'div'} mb={'1vw'}>
                {this.generalHistory()}
              </Box>
              <Box component={'div'} mb={'1vw'}>
                {this.state.isPDHis ? this.PDHistory() : this.state.isGPModule ? null : this.diabeticHistory()}
              </Box>
              <Box component={'div'} mb={'1vw'}>
                {(this.state.isPDHis || this.state.isGPModule) ? null : this.NutritionHistory()}
              </Box>
              <Box component={'div'} mb={'1vw'}>
                {(this.state.isPDHis || this.state.isGPModule) ? null : this.bloodGlucouseHistory()}
              </Box>
              <Box component={'div'} mb={'1vw'}>
                {(this.state.isPDHis || this.state.isGPModule) ? null : this.MensuralHistory()}
              </Box>
            </Box>
          }
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'} overflow={'scroll'}>
          {this.renderHistory()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

import React, { Component } from 'react';
import './Config.css';
import {
    Button,
    Stack,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    IconButton,
    Tooltip,
    Autocomplete,
    Box,
    InputAdornment
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import SearchIcon from '@mui/icons-material/Search';
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from '../../../Utility/Constants';

var default_para = {
    title: '',
    data: ['']
}
var default_table = {
    title: '',
    noRows: ['']
}

class TestConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            selectMethod: '',
            testConfigData: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            impressionText: [''],
            addParagraph: [default_para],
            addTabledata: [default_table],
            testName: '',
            testSpecimen: '',
            testAmount: '',
            testDescription: '',
            ageForm: '',
            ageTO: '',
            selectGender: '',
            referenceFrom: '',
            referenceTo: '',
            unit: '',
            method1TableValue: [],
            successMsg: false,
            successMsgText: '',
            selectedEditID: '',
            deletedPopup: false,
            deletedID: '',
            selectedIndex: '',
            searchKey: '',
            methodList:[],
            unitList:[],
            testDepartment: '',
            editIndex: null,
            SearchText:""
        }
    }

    componentDidMount() {
        this.getTestConfigData()
        this.getMethodList()
        this.getUnitList()
    }

    // componentWillReceiveProps(props) {
    //     if (props.search_key != this.state.searchKey) {
    //         this.setState({
    //             searchKey: props.search_key
    //         }, () => {
    //             this.getTestConfigData()
    //         })
    //     }
    // }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: "",
        })
      }
      
    getTestConfigData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?search_key=" + this.state.searchKey)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testConfigData: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    postTestConfigDetails = () => {
        try {
            let default_para = {
                title: '',
                data: ['']
            }
            let default_table = {
                title: '',
                noRows: ['']
            }
            var range = {}

            if (this.state.selectMethod === 1) {
                range = {
                    "range": this.state.method1TableValue
                }
            } else if (this.state.selectMethod === 2) {
                range = {
                    "range": "Negative/Positive"
                }
            } else if (this.state.selectMethod === 3) {
                range = {
                    "range": "-"
                }
            } else if (this.state.selectMethod === 4) {
                range = {
                    "range":
                    {
                        "impressions": this.state.impressionText,
                        "paragraph": this.state.addParagraph,
                        "table": this.state.addTabledata
                    }
                }
            }

            var data = {
                "test_name": this.state.testName.replace(/\s+/g, ' ').trim(),
                "method": this.state.selectMethod,
                "specimen": this.state.testSpecimen.replace(/\s+/g, ' ').trim(),
                "department": this.state.testDepartment ? this.state.testDepartment.replace(/\s+/g, ' ').trim() : '',
                "amount": +this.state.testAmount,
                "description": this.state.testDescription ? this.state.testDescription.replace(/\s+/g, ' ').trim() : '',
                "test_report_line": range

            }
            if (this.state.selectedEditID) {
                data['test_id'] = this.state.selectedEditID
            }
            RestAPIService.create(data, Serviceurls.LAB_TEST_CONFIG_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getTestConfigData();
                            this.setState({
                                testName: '',
                                testSpecimen: '',
                                testAmount: '',
                                testDescription: '',
                                selectMethod: '',
                                unit:"",
                                method1TableValue:[],
                                addParagraph:[default_para],
                                addTabledata:[default_table],
                                impressionText:[''],
                                testDepartment: '',
                            })
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    deleteTestConfigData = (ID) => {
        try {
            var data = {
                "test_id": ID
            }
            RestAPIService.delete(Serviceurls.LAB_TEST_CONFIG_GET, data)
                .then(response => {
                    if (response.data.status == "success") {
                        this.getTestConfigData()
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        })
                    }
                }).catch((e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                }))

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    editTestConfigData = (item, index) => {
        try{
            var default_paras = {
                title: '',
                data: ['']
            }
            var default_tables = {
                title: '',
                noRows: [''],
            }
            this.setState({
                selectedEditID: item?.id,
                testName: item?.name,
                testSpecimen: item?.specimen,
                selectMethod: item?.method_id,
                testAmount: item?.amount,
                testDescription: item?.remarks,
                method1TableValue: item?.method_id === 1 ? (item?.Test_report_config).range : [],
                impressionText: item?.method_id === 4 ? (item?.Test_report_config.range).impressions : [''],
                addParagraph: item?.method_id === 4 ? (item?.Test_report_config.range).paragraph : [default_paras],
                addTabledata: item?.method_id === 4 ? (item?.Test_report_config.range).table : [default_tables],
                selectedIndex: index,
                testDepartment: item?.department
    
            })
        }
        catch(e){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    methodSelect = (event) => {
        var defaultpara = {
            title: '',
            data: ['']
        }
        var defaulttable = {
            title: '',
            noRows: ['']
        }
        this.setState({
            selectMethod: event.target.value,
            ageForm: '',
            ageTO: '',
            referenceFrom: '',
            referenceTo: '',
            selectGender: '',
            unit: '',
            impressionText: [''],
            addParagraph: [defaultpara],
            addTabledata: [defaulttable],
        })
    }
    genderSelect = (event) => {
        this.setState({
            selectGender: event.target.value
        })
    }

    renderTextBox = (label, width, value, name) => {
        return (
            <div>
                <TextField
                    disabled={(name === "testSpecimen" && this.state.selectMethod === 4)? true : false}
                    size={label === "Description" ? 'large' : 'small'}
                    sx={{ width: width }}
                    multiline={label === "Description" ? true : false}
                    label={label}
                    rows={3}
                    value={value}
                    name={name}
                    variant="outlined"
                    inputProps={name === "ageForm" || name === "ageTO" ? { maxLength: 6 } : ''}
                    onChange={
                        name === "testAmount" ||
                            name === "ageForm" ||
                            name === "ageTO" ? this.numInputcheck :
                            name === "referenceFrom" || name === "referenceTo" ? this.decimalNumInput :
                                this.textInputcheck}
                />
            </div>
        )
    }

    decimalNumInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const re = /^(\d*)((\.(\d{0,3})?)?)$/;
        if ((value === "" || re.test(value) && value.length <= 10)) {
            this.setState({
                [name]: value
            });
        }

    };

    textInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(value.length <= 150){
            this.setState({
                [name]: value
            });
        }
    };

    numInputcheck = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        const re = /^[0-9\b]+$/;
        let splits = value.includes(".") 
        let decimalValue = splits ? value.split(".")[1] : null
        let Lvalue = splits ? value.split(".")[0] : null
        if(decimalValue != null && decimalValue >11){
            value = Lvalue;
        }else if(decimalValue == null){
            value = value;
        }else{
            value = Lvalue+ "." +decimalValue;
        }
        if (value === "" || (CommonValidation.DecimalNumber(value) && value <= 130 && (name === "ageForm" || name === "ageTO")) ) {
            this.setState({
                [name]: value,
            });
        }else if(value === "" || CommonValidation.NumberWithDot(value) && value >= 0 && name === "testAmount"){
            this.setState({
                [name]: value,
            }); 
        }
    };

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        //  filter table data based on search Text
        const filtereddata = this.state.testConfigData.filter((data) => {
            return (
                (data.name === null ? null : data.name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.department === null ? null : data.department.toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });

        return (
            <div>
                <Paper elevation={0}>
                    <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{width:'15vw'}} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText, width: '5vw' }} >{t('SerialNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText, width: '20vw' }}>{t('TestName')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText, width: '15vw' }}>{t('Department')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText, width: '20vw' }}>{t('Method')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText, width: '20vw' }}>{t('Description')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText, width: '10vw' }}>{t('Amount') + ` (${CurrencySymbol})`}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText, width: '10vw' }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    return (
                                        <TableBody>
                                            <TableRow sx={{ bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white' }}>
                                                <TableCell align='left' sx={{width: '5vw'}}><div>{sno}</div></TableCell>
                                                <TableCell align='left' sx={{width: '20vw'}}>
                                                    {data?.name?.length > 12 ?
                                                        <Tooltip title={data?.name} arrow>
                                                            <div>{data.name ? data.name.slice(0, 12) + "..." : '-'}</div>
                                                        </Tooltip> : <div>{data.name ? data.name : '-'}</div>}
                                                </TableCell>
                                                <TableCell align='left' sx={{width: '15vw'}}>
                                                    {data?.department?.length > 10 ?
                                                        <Tooltip title={data?.department} arrow>
                                                            <div>{data.department ? data.department.slice(0, 10) + "..." : '-'}</div>
                                                        </Tooltip> : <div>{data.department ? data.department : '-'}</div>}
                                                </TableCell>
                                                <TableCell align='left' sx={{width: '20vw'}}><div>{data.method_id ? data.method_id === 1 ? 
                                                ("Range Based Result").length > 10 ? ("Range Based Result").slice(0, 10) + '...': "Range Based Result" :
                                                    data.method_id === 2 ? ("Positive and Negative Results").length > 10 ? ("Positive and Negative Results").slice(0, 10) + '...' : '-' :
                                                        data.method_id === 3 ? ("Culpture Test Result").length > 10 ? ("Culpture Test Result").slice(0, 10) + '...' : "-" :
                                                            data.method_id === 4 ? "Radiology" : '': '-'}</div></TableCell>
                                                <TableCell align='left' sx={{width: '20vw'}}>
                                                    {data?.remarks?.length > 15 ?
                                                        <Tooltip placement="top" title={data?.remarks} arrow>
                                                            <div >{data.remarks ? data.remarks.slice(0, 15) + '...' : '-'}</div>
                                                        </Tooltip> : <div> {data.remarks ? data.remarks : '-'}</div>}
                                                </TableCell>
                                                <TableCell align='left' sx={{width: '10vw'}}>
                                                <div className="eMed_warning">
                                                    {data.amount ? AmountFormat(data.amount).replace(`${CurrencySymbol}`,"") : '0'}
                                                    {data?.is_mapped != true ? 
                                                    <Tooltip title={t("ConsumablenotMapping")} placement='top' arrow>
                                                        <img src={ImagePaths.Warning.default} className='eMed_TestConfig_DeleteIcon' alt='warning' />
                                                    </Tooltip>
                                                    : null }
                                                </div>
                                                </TableCell>
                                                <TableCell align='left' sx={{width: '10vw'}}>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton size='small'>
                                                            <img src={ImagePaths.Edit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editTestConfigData(data, index) }} />
                                                        </IconButton>
                                                        <IconButton size='small'>
                                                            <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        deletedPopup: true,
                                                                        deletedID: data.id
                                                                    })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t("NoRecordsFound")}
                            </div> : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata.length}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }
    renderRightTable = () => {
        var { method1TableValue } = this.state
        const { t } = this.props
        return (
            <div>
                <Paper elevation={0}>
                    <TableContainer className='eMed_ConfigRightTable_Paper' sx={{ width: '32.7vw' }}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Age/Gender')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Ref.Range')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Units')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {method1TableValue && method1TableValue.length > 0 ? method1TableValue.map((item, index) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='left'>{index + 1}</TableCell>
                                            <TableCell align='left'>
                                                {item.age_from + ' - ' + item.age_to + " / " + item.gender}
                                            </TableCell>
                                            <TableCell align='left'>{item.range_from + ' - ' + item.range_to}</TableCell>
                                            <TableCell align='left'>{item.unit}</TableCell>
                                            <TableCell align="center">
                                                <IconButton size='small'>
                                                    <img src={ImagePaths.Edit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                        onClick={() => {
                                                            this.setState({
                                                                selectGender: item.gender,
                                                                ageForm: item.age_from,
                                                                ageTO: item.age_to,
                                                                referenceFrom: item.range_from,
                                                                referenceTo: item.range_to,
                                                                unit: item.unit,
                                                                editIndex: index
                                                            })
                                                        }} />
                                                </IconButton>
                                                <IconButton size='small'>
                                                   <img className='eMed_TestConfig_DeleteIcon' src={ImagePaths.Delete.default} onClick={() => {
                                                     method1TableValue.splice(index, 1)
                                                     this.setState({
                                                        method1TableValue
                                                     })
                                                    }}
                                                    alt="Delete"
                                                   />
                                                </IconButton>   
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }) : null}
                        </Table>
                        {method1TableValue.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t("NoRecordsFound")}
                            </div> : null}
                    </TableContainer>
                </Paper>
            </div>
        )
    }
    rangeValidCheck = () => {
        const {t} = this.props
        var saveErrorMsg = ''
        var states = this.state
        if (!states.referenceFrom ||
            !states.referenceTo) {
            saveErrorMsg = t('EnterRangeDetails')
        } else if (parseInt(states.ageTO) <= parseInt(states.ageForm)) {
            saveErrorMsg = t('AgeTogreaterthenAgeFrom')
        } else if (parseFloat(states.referenceTo) <= parseFloat(states.referenceFrom)) {
            saveErrorMsg = t('RefTogreaterthenRefFrom')
        } else if (!states.unit) {
            saveErrorMsg = t('EnterUnit')
        }
        return saveErrorMsg
    };
    duplicateCheck = () => {
        var { ageForm, ageTO, referenceFrom, referenceTo, selectGender, method1TableValue } = this.state
        var duplicate = false
        if (ageForm || ageTO || referenceFrom || referenceTo) {
            for (let i = 0; i < method1TableValue.length; i++) {
                if (method1TableValue[i].age_from == parseInt(ageForm) && method1TableValue[i].age_to == parseInt(ageTO) &&
                    method1TableValue[i].range_from == parseInt(referenceFrom) && method1TableValue[i].range_to == parseInt(referenceTo) &&
                    method1TableValue[i].gender == selectGender) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }
    addRangeValues = () => {
        const {t} = this.props
        var inValid = this.rangeValidCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            var states = this.state
            var duplicate = this.duplicateCheck()
            if (!duplicate) {
               if(states.editIndex != null) {
                 states.method1TableValue[states.editIndex] = {
                    "age_from": +states.ageForm,
                    "age_to": +states.ageTO,
                    "gender": states.selectGender,
                    "range_from": +states.referenceFrom,
                    "range_to": +states.referenceTo,
                    "unit": states.unit
                }
                this.setState({
                    states
                }, () => {
                    this.setState({
                        ageForm: "",
                        ageTO: "",
                        selectGender: "",
                        referenceFrom: "",
                        referenceTo: "",
                        unit: "",
                        editIndex: null
                    })
                })
               } else { 
                states['method1TableValue'].push({
                    "age_from": +states.ageForm,
                    "age_to": +states.ageTO,
                    "gender": states.selectGender === "both" ? "t" : states.selectGender === "f" ? "f" :states.selectGender === "m" ? "m" : "-",
                    "range_from": +states.referenceFrom,
                    "range_to": +states.referenceTo,
                    "unit": states.unit
                })
                this.setState({
                    states
                }, () => {
                    this.setState({
                        ageForm: "",
                        ageTO: "",
                        selectGender: "",
                        referenceFrom: "",
                        referenceTo: "",
                        unit: ""
                    })
                })
              }
            } else {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t('RangeDetailsAlreadyExist')
                })
            }
        }
    }

    getUnitList = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_PRODUCT_UNIT)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        unitList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    renderMethod1Config = () => {
        const { t } = this.props
        return (
            <div>
                <div className='eMed_TestConfig_RightHead2'>
                    <p className='eMed_Testconfig_RightHead_text'>{t('TestReportConfiguration')}</p>
                </div>
                <div className='eMed_TestConfig_RightMethod'>
                    {this.renderTextBox(t("AgeFrom"), '9.3vw', this.state.ageForm, "ageForm")}
                    {this.renderTextBox(t("AgeTo"), '9.3vw', this.state.ageTO, "ageTO")}
                    <FormControl sx={{ width: '9.3vw' }} size='small'>
                        <InputLabel id="demo-simple-select-label">{t('Gender')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectGender}
                            name={'selectGender'}
                            label={t('Gender')}
                            onChange={this.genderSelect}
                        >
                            <MenuItem value={'m'}>Male</MenuItem>
                            <MenuItem value={'f'}>Female</MenuItem>
                            <MenuItem value={'both'}>Transgender</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='eMed_TestConfig_RightMethod'>
                    {this.renderTextBox(t("ReferenceFrom" + '*'), '9.3vw', this.state.referenceFrom, 'referenceFrom')}
                    {this.renderTextBox(t("ReferenceTo" + '*'), '9.3vw', this.state.referenceTo, 'referenceTo')}
                    <Autocomplete
                    freeSolo
                    disableClearable
                    value = {this.state.unit}
                    options={this.state.unitList?.length != 0 ? this.state.unitList : []}
                    getOptionLabel={option => typeof(option) === "string" ? option : option.unit}
                    onChange={(event, newVendor) => {  this.setState({unit : newVendor.unit}) }}
                    sx={{width:"9.3vw"}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Unit") + "*"}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    )} 
                    />
                </div>
                <div className='eMed_TestConfig_RightMethodAddBtn'>
                    <Button id='eMed_Btn_Text' variant='contained'
                        onClick={() => { this.addRangeValues() }
                        }
                    >{t('Add')}</Button>
                </div>
                <div className='eMed_TestConfig_Right_SideTable'>
                    {this.renderRightTable()}
                </div>
            </div>
        )
    }

    impressionTextBox = (label, width, index) => {
        var { impressionText } = this.state
        var len = impressionText.length
        return (
            <div className='eMed_imp_txt'>
                {index != 0 && (len - 1) === index ?
                    <img src={ImagePaths.CrossIcon.default} className='eMed_TestConfig_CrossIcon' alt="Corss"
                        onClick={() => {
                            // var Data = impressionText[index]
                            impressionText.splice(index, 1)
                            this.setState({
                                impressionText
                            })
                        }} />
                    : null}
                <TextField
                    size={'small'}
                    sx={{ width: width }}
                    multiline={label === "Description" ? true : false}
                    label={label}
                    rows={3}
                    value={this.state.impressionText[index]}
                    variant="outlined"
                    onChange={(text) => {
                        this.state.impressionText[index] = text.target.value
                        this.setState({
                            impressionText: this.state.impressionText
                        })
                    }}
                />
            </div>
        )
    }

    tableContainer = (item, index) => {
        var states = this.state
        var { addTabledata } = this.state
        var datalen = addTabledata.length
        const {t} = this.props
        return (
            <div>
                <div className='eMed_TestConfig_ImpressionCon'>
                    <div className='eMed_TestConfig_ParaHeadCon'>
                        <p className='eMed_TestConfig_ImpressionText'>Table {index + 1}</p>
                        {(index != 0 && (datalen - 1) === index) ?
                            <img src={ImagePaths.CrossIcon.default} className='eMed_TestConfig_HeaderCross' alt="Corss"
                                onClick={() => {
                                    addTabledata.splice(index, 1)
                                    this.setState({
                                        addTabledata
                                    })
                                }} />
                            : null}
                    </div>
                    <div>
                        <div className='eMed_TestConfig_RightTestName'>
                            <TextField
                                size={'small'}
                                sx={{ width: '27vw' }}
                                label={"Heading"}
                                value={addTabledata[index].title}
                                variant="outlined"
                                onChange={(text) => {
                                    addTabledata[index]['title'] = text.target.value
                                    this.setState({
                                        addTabledata
                                    })
                                }}
                            />
                        </div>
                        <div>
                            {addTabledata[index].noRows.length > 0 && (addTabledata[index].noRows).map((item, i) => {
                                var len = (addTabledata[index].noRows).length
                                return (
                                    <div className='eMed_TestConfig_RightTestName'>
                                        {i != 0 && (len - 1) === i ?
                                            <img src={ImagePaths.CrossIcon.default} className='eMed_TestConfig_CrossIcon' alt="Corss"
                                                onClick={() => {
                                                    var Data = addTabledata[index].noRows
                                                    Data.splice(i, 1)
                                                    this.setState({
                                                        addTabledata
                                                    })
                                                }} />
                                            : null}
                                        <TextField
                                            size={'small'}
                                            sx={{ width: '27vw' }}
                                            label={`Rows ${i + 1}`}
                                            value={addTabledata[index].noRows[i]}
                                            variant="outlined"
                                            onChange={(text) => {
                                                addTabledata[index]['noRows'][i] = text.target.value
                                                this.setState({
                                                    addTabledata
                                                })
                                            }}
                                        />
                                    </div>
                                )
                            })}
                            <div className='eMed_TestConfig_ImpressionAdd'>
                                <img className='eMed_Testconfig_AddIcon' src={ImagePaths.AddIcon.default} onClick={() => {
                                    var valid = this.checkParaField(addTabledata[index]['noRows'])
                                    if (valid) {
                                        addTabledata[index]['noRows'].push('')
                                        this.setState({
                                            addTabledata
                                        })
                                    } else {
                                        this.setState({
                                            isErrorMsg: true,
                                            isErrorMsgText: t("EnterRowValue")
                                        })
                                    }
                                }
                                }
                                    alt={'Add'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    paragraphContainer = (list, index) => {
        var states = this.state
        var { addParagraph } = this.state
        var datalen = addParagraph.length
        const { t } = this.props
        return (
            <div>
                <div className='eMed_TestConfig_ImpressionCon'>
                    <div className='eMed_TestConfig_ParaHeadCon'>
                        <p className='eMed_TestConfig_ImpressionText'>{t('Paragraph')} {index + 1}</p>
                        {(index != 0 && (datalen - 1) === index) ?
                            <img src={ImagePaths.CrossIcon.default} className='eMed_TestConfig_HeaderCross' alt="Corss"
                                onClick={() => {
                                    addParagraph.splice(index, 1)
                                    this.setState({
                                        addParagraph
                                    })
                                }} />
                            : null}
                    </div>
                    <div>
                        <div className='eMed_TestConfig_RightTestName'>
                            <TextField
                                size={'small'}
                                sx={{ width: '27vw' }}
                                label={t("Heading")}
                                value={addParagraph[index].title}
                                variant="outlined"
                                onChange={(text) => {
                                    addParagraph[index]['title'] = text.target.value
                                    this.setState({
                                        addParagraph
                                    })
                                }}
                            />
                        </div>
                        {addParagraph[index].data.length > 0 && (addParagraph[index].data).map((item, i) => {
                            var len = (addParagraph[index].data).length
                            return (
                                <div className='eMed_TestConfig_RightTestName'>
                                    {i != 0 && (len - 1) === i ?
                                        <img src={ImagePaths.CrossIcon.default} className='eMed_TestConfig_CrossIcon' alt="Corss"
                                            onClick={() => {
                                                var Data = addParagraph[index].data
                                                Data.splice(i, 1)
                                                this.setState({
                                                    addParagraph
                                                })
                                            }} />
                                        : null}
                                    <TextField
                                        size={'small'}
                                        sx={{ width: '27vw', zIndex: 1200 }}
                                        label={`Field ${i + 1}`}
                                        value={addParagraph[index].data[i]}
                                        variant="outlined"
                                        onChange={(text) => {
                                            addParagraph[index]['data'][i] = text.target.value
                                            this.setState({
                                                addParagraph
                                            })
                                        }}
                                    />


                                </div>
                            )
                        })}
                    </div>
                    <div className='eMed_TestConfig_ImpressionAdd'>
                        <img className='eMed_Testconfig_AddIcon' src={ImagePaths.AddIcon.default} onClick={() => {
                            var valid = this.checkParaField(addParagraph[index]['data'])
                            if (valid) {
                                addParagraph[index]['data'].push('')
                                this.setState({
                                    addParagraph
                                })
                            } else {
                                this.setState({
                                    isErrorMsg: true,
                                    isErrorMsgText: t("EnterField")
                                })
                            }
                        }
                        }
                            alt={'Add'} />
                    </div>
                </div>
            </div>
        )
    }

    checkParaField = (data) => {
        var returnValue = true
        for (const item of data) {
            if (item) {

            } else {
                returnValue = false
                return returnValue
            }
        }
        return returnValue
    }

    checkparaContainer = (data) => {
        var defaultValue = true
        for (const item of data) {
            var valid = this.checkParaField(item.data)
            if (item.title && valid) {

            } else {
                defaultValue = false
                return defaultValue
            }
        }
        return defaultValue
    }

    checktableContainer = (data) => {
        var defaultValue = true
        for (const item of data) {
            var validRows = this.checkParaField(item.noRows)
            if (item.title && validRows) {

            } else {
                defaultValue = false
                return defaultValue
            }
        }
        return defaultValue
    }

    impressionContainer = () => {
        var states = this.state
        const { t } = this.props
        return (
            <div>
                <div className='eMed_TestConfig_RightHead2'>
                    <p className='eMed_Testconfig_RightHead_text' >{t('TestReportConfiguration')}</p>
                </div>
                <div className='eMed_TestConfig_ImpressionCon'>
                    <div className='eMed_TestConfig_ImpressionHeadCon'>
                        <p className='eMed_TestConfig_ImpressionText'>{t('Impression')}</p>
                    </div>
                    <div>
                        {states.impressionText?.map((list, index) => {
                            return (
                                <div className='eMed_TestConfig_RightTestName'>
                                    {this.impressionTextBox(`Impression Line ${index + 1}`, '27vw', index)}
                                </div>
                            )
                        })}
                    </div>
                    <div className='eMed_TestConfig_ImpressionAdd'>
                        <img className='eMed_Testconfig_AddIcon' src={ImagePaths.AddIcon.default} onClick={() => {
                            var valid = this.checkParaField(states.impressionText)
                            if (valid) {
                                states.impressionText.push('')
                                this.setState({
                                    states
                                })
                            } else {
                                this.setState({
                                    isErrorMsg: true,
                                    isErrorMsgText: t("EnterImpression")
                                })
                            }

                        }
                        }
                            alt={'Add'} />
                    </div>
                </div>
            </div>
        )
    }
    renderMethod4Config = () => {
        var states = this.state
        const { t } = this.props
        return (
            <div>
                <div>
                    {this.impressionContainer()}
                    {states.addParagraph.length > 0 ? states.addParagraph.map((list, index) => {
                        return (
                            this.paragraphContainer(list, index)
                        )
                    }) : null}
                    {states.addTabledata.length > 0 ? states.addTabledata.map((list, index) => (
                        this.tableContainer(list, index)
                    )) : null}

                </div>
                <div className='eMed_TestConfig_Addpara'>
                    <Stack spacing={2} direction='row'>
                        <Button
                            variant='contained'
                            id='eMed_Btn_Text'
                            sx={{ width: '12vw' }}
                            onClick={() => {
                                var valid = this.checkparaContainer(states.addParagraph)
                                if (valid) {
                                    states.addParagraph.push({ title: "", data: [''] })
                                    this.setState({
                                        states
                                    })
                                } else {
                                    this.setState({
                                        isErrorMsg: true,
                                        isErrorMsgText: t("EnterParagraphDetails")
                                    })
                                }
                            }}
                        >{t("AddParagraph")}</Button>
                        <Button
                            variant='contained'
                            id='eMed_Btn_Text'
                            sx={{ width: '12vw' }}
                            onClick={() => {
                                var valid = this.checktableContainer(states.addTabledata)
                                if (valid) {
                                    states.addTabledata.push({ title: "", noRows: [''] })
                                    this.setState({
                                        states
                                    })
                                } else {
                                    this.setState({
                                        isErrorMsg: true,
                                        isErrorMsgText: t("EnterTableDetails")
                                    })
                                }
                            }}
                        >{t('AddTable')}</Button>
                    </Stack>
                </div>
            </div>
        )

    }

    clearValues = () => {
        var paraClear = {
            title: '',
            data: ['']
        }
        var tableClear = {
            title: '',
            noRows: ['']
        }
        this.setState({
            selectMethod: '',
            testDescription: '',
            ageForm: '',
            ageTO: '',
            selectGender: '',
            referenceFrom: '',
            referenceTo: '',
            unit: '',
            impressionText: [''],
            addParagraph: [paraClear],
            addTabledata: [tableClear],
            method1TableValue: [],
            selectedEditID: '',
            selectedIndex: '',
        })
    };

    validCheck = () => {
        const {t} = this.props;
        var saveErrorMsg = ''
        var states = this.state
        if (!states.testName ||
            !states.selectMethod ||
            !states.testAmount) {
            saveErrorMsg = t('EnterTestDetail')
        } else if(!states.testSpecimen && states.selectMethod !== 4){
            saveErrorMsg = t('Enter Specimen')
        } else if (states.selectMethod === 1 && (states.method1TableValue).length == 0) {
            saveErrorMsg = t('EnterRange')
        } else if (states.selectMethod === 4) {
            var impressionvalid = this.checkParaField(states.impressionText)
            var addParagraphvalid = this.checkparaContainer(states.addParagraph)
            var addTabledatavalid = this.checktableContainer(states.addTabledata)
            if (!impressionvalid) {
                saveErrorMsg = t('EnterImpressionDetail')
            } else if (!addParagraphvalid) {
                saveErrorMsg = t('EnterParagraphDetails')
            } else if (!addTabledatavalid) {
                saveErrorMsg = t('EnterTableDetails')
            }
        }
        return saveErrorMsg
    };

    onSave = () => {
        var inValid = this.validCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            this.postTestConfigDetails()
        }
    }
    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }
    removeDataList = () => {
        this.deleteTestConfigData(this.state.deletedID)
        this.setState({
            deletedPopup: false
        })
    }

    getMethodList = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_METHOD_LIST)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        methodList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }
    render() {
        const { t } = this.props
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead'>
                            <p className='eMed_Testconfig_RightHead_text'>{t("LaboratoryTestAndReportConfiguration")}</p>
                        </div>
                        <div className='eMed_TestConfig_RightContentBox'>
                            <div className='eMed_TestConfig_RightTestName'>
                                {this.renderTextBox(t('TestName') + "*", '29vw', this.state.testName, 'testName')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                            {this.renderTextBox(t("Department"), '14vw', this.state.testDepartment, 'testDepartment')}
                            <FormControl sx={{ width: '14vw' }} size='small'>
                                    <InputLabel id="demo-simple-select-label">{t('Method') + "*"}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.selectMethod}
                                        name={'selectMethod'}
                                        label={t("Method") + "*"}
                                        onChange={(e)=>{
                                            this.setState({
                                                selectMethod:e.target.value
                                            })
                                        }}
                                    >
                                        {this.state.methodList?.length > 0 ? this.state.methodList.map((item)=>{
                                           return <MenuItem value={item.id} key={item.id}>{item.method_name}</MenuItem>
                                        }) : "No data"}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Specimen") + "*", '14vw', this.state.testSpecimen, 'testSpecimen')}
                                {this.renderTextBox(t("TestAmount")+ "*", '14vw', this.state.testAmount, 'testAmount')}
                            </div>
                            <div className='eMed_TestConfig_RightTestName'>
                                {this.renderTextBox(t("Description"), '29vw', this.state.testDescription, 'testDescription')}
                            </div>
                            {this.state.selectMethod === 1 ? this.renderMethod1Config() :
                                this.state.selectMethod == 4 ? this.renderMethod4Config() : null}
                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text' variant='outlined' onClick={() => { this.clearValues() }}>{t('Clear')}</Button>
                                <Button id='eMed_Btn_Text' variant='contained' onClick={() => { this.onSave() }}>{t('Save')}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)} />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("confirmRemoveTestName")}
                        DeletContent={t("linkedTestGrp")}
                        DeleteNotify={t("effectEntireConfig")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
            </div>
        );
    }
}

export default withTranslation()(TestConfig);
import React, { Component } from 'react';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import { Box, Button, Typography } from '@mui/material';

const defaultTxt = {
    "blocks": [{
        "key": "1",
        "text": "Dear Doctor,\n\nThis is with reference To [patientName], [uhid] [age] has diagnosed with [eye_problem] in [eye_type]. He / She is a known cases of [systemic_illness] under your care. We noticed that He / She needs [surgery] surgery to be undergone. Kindly evaluate Him / Her regarding the same.\n\nRegards,\n[clinic_doctor]",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    }],
    "entityMap": {}
}

let currentDate = new Date();

let day = currentDate.getDate();
let month = currentDate.getMonth() + 1;
let year = currentDate.getFullYear();

let formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;

export default class DocMedicalCertific extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referral_id: "",
            refferalText: JSON.stringify(defaultTxt),
            dynamicValues: {
                patientName: "",
                uhid: "",
                age: "",
                eye_problem: "",
                eye_type: "",
                systemic_illness: "",
                surgery: "",
                clinic_doctor: "",
            },
            patientDetails: {},
            clinicId: '',
            appointmentId: '',
            patientId: '',
            doctorData: [],
            isSaveSuccess: false,
            doctor_details: {}
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key, flag) => {
        let states = this.state;
        states[key] = flag;
        this.setState({
            states
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleReferralSave = () => {

    }

    handleReferralPrint = () => {

    }

    render() {
        let states = this.state;
        let attender = {
            lineOne: "Attender Name",
            lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
                this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
            lineFour: 'Contact Number',
            LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
                this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
        }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
                <Box component={'div'} display={'flex'}>
                    <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
                    </Box>
                    <CommonPatientCard details={attender} showDetailed={true} />
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
                    <Box component={'div'} flex={0.65} >
                        <Box border={'1px solid lightgray'} height={"100%"} p={"1vw"}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", height: "10vh" }}>
                                <div>
                                    <div>To</div>
                                    {this.state?.doctor_details?.id != "" ?
                                        <div style={{ marginLeft: "0.65vw" }}>
                                            <div>{`${states.doctor_details?.doctor_name} ${states.doctor_details?.qualifications}`}</div>
                                            <div>{`${states.doctor_details?.designation}`}</div>
                                            <div>{`${states.doctor_details?.clinic_name}`}</div>
                                            <div>{`${states.doctor_details?.city_name}`}</div>
                                        </div> : null
                                    }
                                </div>
                                <div>
                                    <div>{`${states.doctor_details?.city_name}`}</div>
                                    <div>{formattedDate}</div>
                                </div>
                            </div>
                            <div style={{ fontSize: "1.1vw", fontWeight: "600", textAlign: "center", padding: "1vw" }}>{`MEDICAL CERTIFICATE`}</div>
                            <div>{`Dr ${states.doctor_details?.doctor_name} after careful person examination of the case, hereby certify that ${states?.patientDetails?.first_name} ${states?.patientDetails?.last_name ? states?.patientDetails?.last_name : ""}, ${`(${states?.patientDetails?.patient_account_number})`} ${states?.patientDetails?.patient_age_str} whose signature is given above is suffering from ${(states?.eye_problem ? states?.eye_problem?.eye_problem_name : "_____")} and i consider that period of absense from duty of ${states?.systemic_illness ? states?.systemic_illness?.systemic_illness_name : "_____"} with effect from ${states?.surgery ? states?.surgery?.surgery_name : "_____"} is absolutely necessary for the restoration of his / her health.`}
                            </div>
                            <div style={{ marginTop: "1.25vw" }}>
                                <div>Signature of Medical Officer</div>
                                <div>{`Dr ${this.state?.DrName}`}</div>
                            </div>
                        </Box>
                    </Box>
                    <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
                        <Box component={'div'} height={'5.15vh'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                            <Typography fontWeight={600}>{"Referral Doctor"}</Typography>
                        </Box>
                        {/* <Box sx={{ backgroundColor: Colors.white, height: "50.17vh", overflowY: "auto" }}>
                            {this.renderChip("Doctor", states.doctorData, "doctor_details", "doctor_name", "id", "is_add_doctor")}
                            {this.renderChip("Eye Problem", states.eye_problem_data, "eye_problem", "eye_problem_name", "eye_problem_id", "is_add_eye_pbrlm")}
                            {this.renderChip("Eye Type", states.eye_type_data, "eye_type")}
                            {this.renderChip("Systemic Illness", states.systemic_illness_data, "systemic_illness", "systemic_illness_name", "systemic_illness_id", "is_add_illness")}
                            {this.renderChip("Sugery", states.surgery_data, "surgery", "surgery_name", "surgery_id", "is_add_surgery")}
                        </Box> */}
                        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'6.18vh'}>
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                variant='outlined'
                                onClick={() => this.handleReferralSave()}
                            >{"Save"}</Button>
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                variant='contained' disabled={!this.state.isSaveSuccess}
                                onClick={() => this.handleReferralPrint()}
                            >{"Print"}</Button>
                        </Box>
                    </Box>
                </Box>
                <div>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}

                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </div>
            </Box>
        )
    }
}
export const returnPaymentMode = (type , amount_1, amount_2) =>{
    var Data = {
      "paymentMode" : "",
      "paymentAmt1" : 0,
      "paymentAmt2" : 0
    }
    switch (type) {
      case "Cash":
       return Data = {
          "paymentMode" : 1,
          "paymentAmt1" : amount_1,
          "paymentAmt2" : amount_2
      }
      case "Card":
        return Data ={
          paymentMode: 2,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "CardCash":
        return Data ={
          paymentMode: 3,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CashCard":
        return Data ={
          paymentMode: 3,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "UPI":
        return Data ={
          paymentMode: 4,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "Bank Transfer":
        return Data ={
          paymentMode: 5,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "Insurance":
        return Data ={
          paymentMode: 6,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "Cheque":
        return Data ={
          paymentMode: 7,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "CashUPI":
        return Data ={
          paymentMode: 8,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "UPICash":
        return Data ={
          paymentMode: 8,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CashBank Transfer":
        return Data ={
          paymentMode: 9,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "Bank TransferCash":
        return Data ={
          paymentMode: 9,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CashInsurance":
        return Data ={
          paymentMode: 10,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "InsuranceCash":
        return Data ={
          paymentMode: 10,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CashCheque":
        return Data ={
          paymentMode: 11,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "ChequeCash":
        return Data ={
          paymentMode: 11,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CardUPI":
        return Data ={
          paymentMode: 12,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "UPICard":
        return Data ={
          paymentMode: 12,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CardBank Transfer":
        return Data ={
          paymentMode: 13,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "Bank TransferCard":
        return Data ={
          paymentMode: 13,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CardInsurance":
        return Data ={
          paymentMode: 14,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "InsuranceCard":
        return Data ={
          paymentMode: 14,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "CardCheque":
        return Data ={
          paymentMode: 15,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "ChequeCard":
        return Data ={
          paymentMode: 15,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "UPIBank Transfer":
        return Data ={
          paymentMode: 16,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "Bank TransferUPI":
        return Data ={
          paymentMode: 16,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "InsuranceUPI":
        return Data ={
          paymentMode: 17,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "UPIInsurance":
        return Data ={
          paymentMode: 17,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "ChequeUPI":
        return Data ={
          paymentMode: 18,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "UPICheque":
        return Data ={
          paymentMode: 18,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "InsuranceBank Transfer":
        return Data ={
          paymentMode: 19,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "Bank TransferInsurance":
        return Data ={
          paymentMode: 19,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "Bank TransferCheque":
        return Data ={
          paymentMode: 20,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "ChequeBank Transfer":
        return Data ={
          paymentMode: 20,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      case "InsuranceCheque":
        return Data ={
          paymentMode: 21,
          paymentAmt1: amount_1,
          paymentAmt2: amount_2
        }
      case "ChequeInsurance":
        return Data ={
          paymentMode: 21,
          paymentAmt1: amount_2,
          paymentAmt2: amount_1
        }
      default:
        return Data 
    }
  }

  export const paymentReturnType = (type) =>{
    var Data = {
      paymentType1 : "",
      paymentType2 : ""
    }
    switch(type){
      case parseInt("1") : return Data = {paymentType1 : "Cash" , paymentType2 : ""} 
      case parseInt("2") : return Data = {paymentType1 : "Card" , paymentType2 : ""}
      case parseInt("3") : return Data = {paymentType1 : "Cash" , paymentType2 : "Card"}
      case parseInt("4") : return Data = {paymentType1 : "UPI" , paymentType2 : ""}
      case parseInt("5") : return Data = {paymentType1 : "Bank Transfer" , paymentType2 : ""}
      case parseInt("6") : return Data = {paymentType1 : "Insurance" , paymentType2 : ""}
      case parseInt("7") : return Data = {paymentType1 : "Cheque" , paymentType2 : ""}
      case parseInt("8") : return Data = {paymentType1 : "Cash" , paymentType2 : "UPI"}
      case parseInt("9") : return Data = {paymentType1 : "Cash" , paymentType2 : "Bank Transfer"}
      case parseInt("10") : return Data = {paymentType1 : "Cash" , paymentType2 : "Insurance"}
      case parseInt("11") : return Data = {paymentType1 : "Cash" , paymentType2 : "Cheque"}
      case parseInt("12") : return Data = {paymentType1 : "Card" , paymentType2 : "UPI"}
      case parseInt("13") : return Data = {paymentType1 : "Card" , paymentType2 : "Bank Transfer"}
      case parseInt("14") : return Data = {paymentType1 : "Card" , paymentType2 : "Insurance"} 
      case parseInt("15") : return Data = {paymentType1 : "Card" , paymentType2 : "Cheque"}
      case parseInt("16") : return Data = {paymentType1 : "UPI" , paymentType2 : "Bank Transfer"}
      case parseInt("17") : return Data = {paymentType1 : "UPI" , paymentType2 : "Insurance"}
      case parseInt("18") : return Data = {paymentType1 : "UPI" , paymentType2 : "Cheque"}
      case parseInt("19") : return Data = {paymentType1 : "Bank Transfer" , paymentType2 : "Insurance"} 
      case parseInt("20") : return Data = {paymentType1 : "Bank Transfer" , paymentType2 : "Cheque"} 
      case parseInt("21") : return Data = {paymentType1 : "Insurance" , paymentType2 : "Cheque"}
      default : return Data
    }
  }
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { Autocomplete, Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Colors } from '../../../Styles/Colors';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { City, Country, State } from 'country-state-city';

const VendorcategoryList = [
    { value: 1, label: "Consumable" },
    { value: 2, label: "Non Consumable" },
    { value: 3, label: "Both" }
]

class PharmaAddvendor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            VendorCategory: 1,
            VendorName: "",
            GSTIN: "",
            DLno01: "",
            DLno02: "",
            DLno03: "",
            DLno04: "",
            DLno05: "",
            DLno06: "",
            Address1: "",
            Address2: "",
            AreaName: "",
            City: "",
            cityName: City.getCitiesOfState("IN", "TN"),
            country_code: 'IN',
            state_code: 'TN',
            AllCountry: Country.getAllCountries(),
            AllStates: State.getStatesOfCountry("IN"),
            // State: "Tamil Nadu",
            // Country: "India",
            Pincode: "",
            Mobile: "",
            Landline: "",
            mailID: "",
            ErrorShow: false,
            VendorId: this.props.history?.location?.state?.VendorID ? this.props.history?.location?.state?.VendorID : null,
            OnlyView: this.props.history?.location?.state?.OnlyView ? this.props.history?.location?.state?.OnlyView : null,
            addVendorSuccessMsg : '',
            disableBtn: false
        }
    }

    componentDidMount() {

        if (this.props.history?.location?.state?.VendorID !== null && this.state.VendorId === this.props.history?.location?.state?.VendorID) {
            this.getVendorDetails()
        }
    }

    getVendorDetails() {
        try {
            RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_ADD + "?vendor_id=" + this.state.VendorId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            VendorCategory: response.data?.data?.vendor_type ? response.data?.data?.vendor_type : "",
                            VendorName: response.data?.data?.name ? response.data?.data?.name : "",
                            GSTIN: response.data?.data?.gstin_number ? response.data?.data?.gstin_number : "",
                            DLno01: response.data?.data?.dl_no ? response.data?.data?.dl_no : "",
                            DLno02: response.data?.data?.dl_no_2 ? response.data?.data?.dl_no_2 : "",
                            DLno03: response.data?.data?.dl_no_3 ? response.data?.data?.dl_no_3 : "",
                            DLno04: response.data?.data?.dl_no_4 ? response.data?.data?.dl_no_4 : "",
                            DLno05: response.data?.data?.dl_no_5 ? response.data?.data?.dl_no_5 : "",
                            DLno06: response.data?.data?.dl_no_6 ? response.data?.data?.dl_no_6 : "",
                            Address1: response.data?.data?.address_line_1 ? response.data?.data?.address_line_1 : "",
                            Address2: response.data?.data?.address_line_2 ? response.data?.data?.address_line_2 : "",
                            AreaName: response.data?.data?.area_name ? response.data?.data?.area_name : "",
                            City: response.data?.data?.city ? response.data?.data?.city : "",
                            country_code: this.state.AllCountry.find((item)=>(item.name === response.data?.data?.country))?.isoCode,
                            AllStates: State.getStatesOfCountry(this.state.AllCountry.find((item)=>(item.name === response.data?.data?.country))?.isoCode),
                            state_code: State.getStatesOfCountry(this.state.AllCountry.find((item)=>(item.name === response.data?.data?.country))?.isoCode).find((item)=>(item.name === response.data?.data?.state))?.isoCode,
                            // State: response.data?.data?.state ? response.data?.data?.state : "",
                            // Country: response.data?.data?.country ? response.data?.data?.country : "",
                            Pincode: response.data?.data?.pincode ? response.data?.data?.pincode : "",
                            Mobile: response.data?.data?.mobile_number ? response.data?.data?.mobile_number : "",
                            Landline: response.data?.data?.phone_number ? response.data?.data?.phone_number : "",
                            mailID: response.data?.data?.email ? response.data?.data?.email : "",
                        },()=>{
                            this.setState({cityName: City.getCitiesOfState(this.state.country_code , this.state.state_code)})
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }


    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message, MoveToHome = false) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        }, () => {
            if (MoveToHome) {
                this.props.history.push({ pathname: '/PharmacyPurchase/VendorList', state: {addVendorSuccessMsg : message } })
            }
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    errorshowflag(key, value) {
        switch (key) {
            case ("VendorName"):
                return value === "" ? true : false
            case ("DLno01"):
                return value === "" ? true : false
            case ("Address1"):
                return value === "" ? true : false
            case ("AreaName"):
                return value === "" ? true : false
            case ("City"):
                return value === "" ? true : false
            case "GSTIN":
                return value.length !== 15 ? true : false
            case "Pincode":
                return value.length !== 6 ? true : false
            case "Mobile":
                return value.length !== 10 ? true : false
            case "mailID":
                return (value === "" || CommonValidation.emailValidation(this.state.mailID)) ? false : true
            default:
                return false
        }
    }
    errorshowhelptext(key, value) {
        switch (key) {
            case ("VendorName"):
                return value === "" ? "Enter Vendor Name" : ""
            case ("DLno01"):
                return value === "" ? "Enter DL No" : ""
            case ("Address1"):
                return value === "" ? "Enter Address Line 1" : ""
            case ("AreaName"):
                return value === "" ? "Enter Area Name" : ""
            case ("City"):
                return value === "" ? "Enter City Name" : ""
            case "GSTIN":
                return value.length !== 15 ? "GSTIN must be 15 Characters" : ""
            case "Pincode":
                return value.length !== 6 ? "Pincode must be 6 Digits" : ""
            case "Mobile":
                return value.length !== 10 ? "Mobile must be 10 Digits" : ""
            case "mailID":
                return (value === "" || CommonValidation.emailValidation(this.state.mailID)) ? "" : "Invalid Email ID"
            default:
                return ""
        }
    }


    renderTextBox(label, value, Key, maxLength, width, multiline, disable, mandatory = false) {
        let states = this.state;
        return (
            <TextField
                disabled={disable}
                inputProps={{ maxLength: maxLength, emed_tid: label }}
                sx={{ width: width }}
                size='small'
                label={`${label}${mandatory ? " *" : ""}`}
                value={value}
                multiline={multiline}
                rows={4}
                error={this.state.ErrorShow ? this.errorshowflag(Key, value) : false}
                helperText={this.state.ErrorShow ? this.errorshowhelptext(Key, value) : ""}
                onChange={(e) => {
                    if (Key === "Pincode" || Key === "Mobile" || Key === "Landline") {
                        let number = CommonValidation.numberOnly(e.target.value);
                        if (number || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({ states })
                        }
                    }
                    else if (Key === "VendorName" || label === "DL No") {
                        let alpha = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                        if (alpha || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({ states })
                        }
                    }
                    else if (Key === "GSTIN") {
                        let GST = CommonValidation.gstnumberValidation(e.target.value);
                        if (GST || e.target.value === "") {
                            states[Key] = e.target.value.toUpperCase()
                            this.setState({ states })
                        }
                    }
                    else {
                            states[Key] = e.target.value
                            this.setState({ states })
                    }

                }}
            />
        )
    }

    renderFormOne = () => {
        const { t } = this.props
        return (
            <Box>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Vendor Name")}`, this.state.VendorName, "VendorName", 50, "97%", false, this.state.OnlyView, true)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("GSTIN")}`, this.state.GSTIN, "GSTIN", 15, "95%", false, this.state.OnlyView, true)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No")}`, this.state.DLno01, "DLno01", 50, "95%", false, this.state.OnlyView, true)}
                    </Grid>
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No")}`, this.state.DLno02, "DLno02", 50, "97%", false, this.state.OnlyView)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No")}`, this.state.DLno03, "DLno03", 50, "95%", false, this.state.OnlyView)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No")}`, this.state.DLno04, "DLno04", 50, "95%", false, this.state.OnlyView)}
                    </Grid>
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No")}`, this.state.DLno05, "DLno05", 50, "97%", false, this.state.OnlyView)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No")}`, this.state.DLno06, "DLno06", 50, "95%", false, this.state.OnlyView)}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    renderFormTwo = () => {
        const { t } = this.props
        let states = this.state
        return (
            <Box marginTop={"0.5vw"}>
                <Divider textAlign='center'><Typography className='eMEd_Add_Vendor_Form_Headtext'>Address</Typography></Divider>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Address Line 1")}`, this.state.Address1, "Address1", 50, "97%", false, this.state.OnlyView, true)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Address Line 2")}`, this.state.Address2, "Address2", 50, "95%", false, this.state.OnlyView)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Area Name")}`, this.state.AreaName, "AreaName", 50, "95%", false, this.state.OnlyView, true)}
                    </Grid>
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {/* {this.renderTextBox(`${t("City")}`, this.state.City, "City", 50, "97%", false, this.state.OnlyView, true)} */}
                            <FormControl size='small' sx={{ width: '100%' }}>
                              <InputLabel id="title-select-label">{t("City *")}</InputLabel>
                              <Select
                                sx={{ width: '97%' }}
                                MenuProps={{ style: { maxHeight: 250, maxWidth: '97%' }}}
                                labelId='gender-select-label'
                                size='small'
                                label={"City *"}
                                value={states.City}
                                disabled={this.state.OnlyView}
                                onChange={(e) => {
                                  this.setState({City: e.target.value, Pincode:''})
                                }}
                              >
                                {
                                  states.cityName ? states.cityName?.map((list) => (
                                    CommonValidation.alphabetOnly(list.name) ? 
                                        <MenuItem key={list.name} value={list.name}>{list.name}</MenuItem>
                                    : null
                                  )) : null
                                }
                              </Select>
                            </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        {/* <RegionDropdown disabled={this.state.OnlyView} className='eMed_add_vendor_dropdown_inputs'
                            country={this.state.Country}
                            value={this.state.State}
                            onChange={(val) => { this.setState({ State: val }) }} /> */}
                            <FormControl size='small' sx={{ width: '98%' }}>
                              <InputLabel id="title-select-label">{t("State")}</InputLabel>
                              <Select
                                sx={{ width: '97%' }}
                                MenuProps={{ style: { maxHeight: 250, maxWidth: '97%' }}}
                                labelId='gender-select-label'
                                size='small'
                                label={"State"}
                                value={states.state_code}
                                disabled={this.state.OnlyView}
                                onChange={(e) => {
                                  this.setState({state_code: e.target.value},
                                    ()=>{
                                    this.setState({cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code),City:'',Pincode:''})
                                  })
                                }}
                              >
                                {
                                  states.AllStates.map((list) => (
                                    CommonValidation.alphabetOnly(list.name) && (City.getCitiesOfState(this.state.country_code, list.isoCode))?.filter(item=> CommonValidation.alphabetOnly(item.name) === true)?.length ? 
                                      <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    : null
                                    ))
                                }
                              </Select>
                            </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        {/* <CountryDropdown disabled={this.state.OnlyView} className='eMed_add_vendor_dropdown_inputs'
                            value={this.state.Country}
                            onChange={(val) => this.setState({ Country: val })}
                            priorityOptions={["IN"]}
                            whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                        /> */}
                        <FormControl size='small' sx={{ width: '98%'}}>
                              <InputLabel id="title-select-label">{t("Country")}</InputLabel>
                              <Select
                                sx={{ width: '97%' }}
                                MenuProps={{ style: { maxHeight: 250,maxWidth: '97%' } }}
                                labelId='gender-select-label'
                                label={"Country"}
                                size='small'
                                value={states.country_code}
                                disabled={this.state.OnlyView}
                                onChange={(e) => {
                                  this.setState({country_code: e.target.value, state_code: "",City:''},()=>{
                                    this.setState({AllStates: State.getStatesOfCountry(this.state.country_code),cityName:'',Pincode:''})
                                  })
                                }}
                              >
                                {
                                  states.AllCountry.map((list) => (
                                    CommonValidation.alphabetOnly(list.name) ? 
                                        <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    : null
                                  ))
                                }
                              </Select>
                            </FormControl>
                    </Grid>
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Pincode")}`, this.state.Pincode, "Pincode", 6, "97%", false, this.state.OnlyView, true)}
                    </Grid>
                </Grid>
            </Box>
        )
    }
    renderFormThree = () => {
        const { t } = this.props
        return (
            <Box marginTop={"0.5vw"}>
                <Divider textAlign='center'><Typography className='eMEd_Add_Vendor_Form_Headtext'>Contact Details</Typography></Divider>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Mobile Number")}`, this.state.Mobile, "Mobile", 10, "97%", false, this.state.OnlyView, true)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Landline")}`, this.state.Landline, "Landline", 10, "95%", false, this.state.OnlyView)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Email ID")}`, this.state.mailID, "mailID", 50, "95%", false, this.state.OnlyView)}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    Addvendor() {
        try {
            var states = this.state
            var data = {
                "address_line_1": CommonValidation.removeSpace(states.Address1),
                "address_line_2": CommonValidation.removeSpace(states.Address2),
                "area_name": CommonValidation.removeSpace(states.AreaName),
                "city": CommonValidation.removeSpace(states.City),
                "country": CommonValidation.removeSpace(states.AllCountry.find((item)=>( item.isoCode === states.country_code))?.name),
                "dl_no": CommonValidation.removeSpace(states.DLno01),
                "dl_no_2": CommonValidation.removeSpace(states.DLno02),
                "dl_no_3": CommonValidation.removeSpace(states.DLno03),
                "dl_no_4": CommonValidation.removeSpace(states.DLno04),
                "dl_no_5": CommonValidation.removeSpace(states.DLno05),
                "dl_no_6": CommonValidation.removeSpace(states.DLno06),
                "email": CommonValidation.removeSpace(states.mailID),
                "gstin_number": CommonValidation.removeSpace(states.GSTIN),
                "name": CommonValidation.removeSpace(states.VendorName),
                "mobile_number": CommonValidation.removeSpace(states.Mobile),
                "phone_number": CommonValidation.removeSpace(states.Landline),
                "pincode": CommonValidation.removeSpace(states.Pincode),
                "state": CommonValidation.removeSpace(states.AllStates.find((item)=>( item.isoCode === states.state_code))?.name),
                "vendor_type": states.VendorCategory,
            }
            this.LoaderFunction(true)
            if (states.VendorId === null) {
                RestAPIService.create(data, Serviceurls.PURCHASE_VENDOR_ADD).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message, true)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(response.data.message)
                        }
                        this.setState({disableBtn: false})
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                        this.setState({disableBtn: false})
                    })
            } else {
                data["vendor_id"] = states?.VendorId
                RestAPIService.updateWithOutId(data, Serviceurls.PURCHASE_VENDOR_ADD).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message, true)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(response.data.message)
                        }
                        this.setState({disableBtn: false})
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                        this.setState({disableBtn: false})
                    })
            }
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({disableBtn: false})
        }

    }

    ClearData() {
        this.setState({
            VendorCategory: 1,
            VendorName: "",
            GSTIN: "",
            DLno01: "",
            DLno02: "",
            DLno03: "",
            DLno04: "",
            DLno05: "",
            DLno06: "",
            Address1: "",
            Address2: "",
            AreaName: "",
            City: "",
            cityName: City.getCitiesOfState("IN", "TN"),
            country_code: 'IN',
            state_code: 'TN',
            AllCountry: Country.getAllCountries(),
            AllStates: State.getStatesOfCountry("IN"),
            State: "Tamil Nadu",
            Country: "India",
            Pincode: "",
            Mobile: "",
            Landline: "",
            mailID: "",
            ErrorShow: false
        })
    }
    Validation = () => {
        try {
            let states = this.state
            const { t } = this.props
            let Email = CommonValidation.emailValidation(this.state.mailID)
            if (
                CommonValidation.removeSpace(states?.VendorName) !== "" &&
                CommonValidation.removeSpace(states?.GSTIN) !== "" &&
                CommonValidation.removeSpace(states?.DLno01) !== "" &&
                CommonValidation.removeSpace(states?.Address1) !== "" &&
                CommonValidation.removeSpace(states?.AreaName) !== "" &&
                CommonValidation.removeSpace(states?.City) !== "" &&
                states?.Pincode.length === 6 &&
                states?.Mobile !== "" &&
                (states?.mailID === "" || Email)
            ) {
                this.Addvendor()
            } else {
                this.setState({
                    ErrorShow: true,
                    disableBtn: false
                })
            }
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ disableBtn: false })
        }
    }
    render() {
        const { t } = this.props
        console.log(this.state.City)
        return (
            <div>
                <Box className="eMed_Add_vendor_page_top">
                    <Box className="eMed_Add_vendor_page_top2">
                        <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" emed_tid = "addvnd_bk" onClick={() => {
                            this.props.history.push({ pathname: '/PharmacyPurchase/VendorList' })
                        }} >
                            <img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {t("Back To Vendor List")}</Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <FormControl size='small' sx={{ width: "14vw", marginRight: '1vw' }}>
                            <InputLabel>{t("Vendor Category")}</InputLabel>
                            <Select
                                disabled={this.state.OnlyView}
                                value={this.state.VendorCategory}
                                label={t("Vendor Category")}
                                onChange={(e) => { this.setState({ VendorCategory: e.target.value }) }}
                                MenuProps={{
                                    style: { maxHeight: 350 }
                                }}
                                inputProps={{ emed_tid: "Vendor Category" }}
                            >
                                {VendorcategoryList.map((list, index) => (<MenuItem key={index} value={list?.value}>{list?.label}</MenuItem>))}
                            </Select>
                        </FormControl>
                        <Button emed_tid= "addvnd_clear" onClick={() => { this.ClearData() }} id="eMed_Config_btn" disabled={this.state.OnlyView} sx={{ marginRight: "1vw" }} variant='outlined'>{t("Clear")}</Button>
                        <Button emed_tid= "addvnd_save" onClick={() => { this.setState({ disableBtn: true }, () => { this.Validation() })}} id="eMed_Config_btn" disabled={this.state.disableBtn || this.state.OnlyView} variant='contained'>{t("Save Vendor")}</Button>
                    </Box>
                </Box>
                <Box className="eMed_Add_vendor_form_box">
                    {this.renderFormOne()}
                    {this.renderFormTwo()}
                    {this.renderFormThree()}
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}
export default withTranslation()(PharmaAddvendor);
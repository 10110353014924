import React, { Component } from "react";
import { Box, Button, Stack, Paper, Typography, Modal, IconButton, TextField, Divider, RadioGroup, FormControlLabel, Radio, Tooltip, Dialog, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, InputAdornment, Checkbox, TablePagination } from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AmountFormat, formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { CommonPopUp } from '../../../Components/Common Components/CommonComponents';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { Colors } from "../../../Styles/Colors";
import SearchIcon from '@mui/icons-material/Search';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CurrencySymbol } from "../../../Utility/Constants";
let RoleData = null;
let userAccess = null;
let today = new Date();
class PharmaVendorMonthly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor_id: null,
            vendorMonthlyData: [],
            bill_date_year: today,
            vendor_detail: {
                total_bill_amount: 0,
                total_paid_amount: 0,
                total_balance_amount: 0,
                vendor_name: "",
                vendor_gstin_number: null,
                vendor_city: "",
                vendor_state: "",
                vendor_pincode: null
            },
            paymentDetail: {
                payable_amount: 0,
                payment_date: null,
                payment_made_by: "",
                comment: "",
                selectedPaymentType: null,
            },
            amountDetail: {},
            paymentHistory: [],
            transaction_id: "",
            card_number: "",
            cheque_number: "",
            isReceivedClicked: false,
            isViewClicked: false,
            historyId: null,
            pageSize: 10,
            page: 0,
            open: false,
            openYear: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            isProceedclked: false,
            //New Vendor Payment States ======>
            paymentData: [],
            receiptData: [],
            dupliPaymentData: '[]',
            dupliPaymentRecepitData: '[]',
            value: 'one',
            paymentDate: new Date(),
            paymentMadeBy: '',
            payableAmount: '',
            cashAmt: '',
            comment: '',
            poDetailSearch: '',
            transNumber: '',
            balanceAmount: '',
            paymentMethod: null,
            searchDate: null,
            tblPage: 0,
            tblPageSize: 10,
            page: 0,
            pageSize: 10,
            openDate: false,
            openDateSearch: false,
            postLoad: false,
            getData: false,
            openTrans: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
        userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Vendor Payment', null, "Tab");
        if (this.state.vendor_id) {
            this.GetVendorPaymentMonth();
        }
    }

    componentDidUpdate(prevProps) {
        let states = this.state;
        let history = prevProps.history?.location?.state;
        let vendor_id = history?.vendor_id;

        if (vendor_id !== undefined && vendor_id !== states.vendor_id) {
            this.setState({
                vendor_id: vendor_id ? vendor_id : null
            }, () => {
                this.GetVendorPaymentMonth();
            })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetVendorPaymentMonth = () => {
        let { vendor_id, bill_date_year, vendor_detail } = this.state;
        let billyear = bill_date_year ? new Date(bill_date_year).getFullYear() : today.getFullYear();
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_MONTHLY}?vendor_id=${vendor_id}&bill_date_year=${billyear}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        let list = "data" in response.data && response.data?.data?.length > 0 ? response.data.data : [];
                        list.length > 0 && list?.forEach((item, i) => {
                            item["sno"] = i + 1;
                        })

                        vendor_detail.total_bill_amount = response.data?.total_bill_amount;
                        vendor_detail.total_paid_amount = response.data?.total_paid_amount;
                        vendor_detail.total_balance_amount = response.data?.total_balance_amount;
                        vendor_detail.vendor_name = response.data?.vendor_name;
                        vendor_detail.vendor_gstin_number = response.data?.gstin_no;
                        vendor_detail.vendor_city = response.data?.vendor_city;
                        vendor_detail.vendor_state = response.data?.vendor_state;
                        vendor_detail.vendor_pincode = response.data?.pincode;

                        this.setState({
                            vendorMonthlyData: list,
                            vendor_detail
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status === "fail") {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    _proceedtoPay = () => {
        const { t } = this.props;
        try {
            this.LoaderFunction(true);
            let { paymentDetail, amountDetail, transaction_id, card_number, cheque_number } = this.state;
            let datas = {
                id: amountDetail.id,
                payment_type: paymentDetail.selectedPaymentType,
                payment_amount: parseFloat(paymentDetail.payable_amount),
                payment_date: paymentDetail.payment_date,
                payment_made_by: paymentDetail.payment_made_by,
                transaction_id: transaction_id ? transaction_id : "",
                cheque_number: cheque_number ? (cheque_number).toString() : "",
                card_number: card_number ? (card_number).toString() : "",
                comments: paymentDetail.comment ? paymentDetail.comment : ""
            }
            if (datas.id && datas.payment_type && datas.payment_amount) {
                RestAPIService.create(datas, Serviceurls.PURCHASE_VENDOR_MONTHLY_POST)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.LoaderFunction(false);
                            this.successMessage(response.data?.message);
                            this.handleClose();
                            this.GetVendorPaymentMonth();
                        }
                    })
                    .catch(error => {
                        if (error.response.data.status === "fail") {
                            this.LoaderFunction(false);
                            this.errorMessage(error.response.data.message);
                            this.setState({
                                isProceedclked: false
                            })
                        }
                    })
            } else {
                this.errorMessage(t("KindlyEnterModeAmt"));
            }
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    printVendorMonthList = (history, id) => {
        let { vendor_id, bill_date_year } = this.state;
        let url = "";
        let billyear = bill_date_year ? new Date(bill_date_year).getFullYear() : today.getFullYear();
        if (history && id) {
            url = `${Serviceurls.PURCHASE_VENDOR_MONTHLY_POST}?id=${id}&vendor_id=${vendor_id}&export_type=${"pdf"}`
        } else {
            url = `${Serviceurls.PURCHASE_VENDOR_MONTHLY}?vendor_id=${vendor_id}&export_type=${"pdf"}&bill_date_year=${billyear}`
        }
        if (url) {
            try {
                this.setState({ disableBtn: true })
                RestAPIService.getAll(url).
                    then((response) => {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                        this.setState({ disableBtn: false })
                    }).catch((error) => {
                        if (error.response?.data?.message) {
                            this.errorMessage(error.response?.data?.message);
                        } else {
                            this.errorMessage(error.message);
                        }
                        this.setState({ disableBtn: false })
                    })
            } catch (error) {
                this.errorMessage(error.message)
                this.setState({ disableBtn: false })
            }
        }
    }

    getVendorMonthListExcel = () => {
        const { t } = this.props;
        let { vendor_id, bill_date_year } = this.state;
        let billyear = bill_date_year ? new Date(bill_date_year).getFullYear() : today.getFullYear();
        try {
            this.setState({ disableBtn: true })
            RestAPIService.excelGet(`${Serviceurls.PURCHASE_VENDOR_MONTHLY}?vendor_id=${vendor_id}&export_type=${"excel"}&bill_date_year=${billyear}`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    this.errorMessage(error.response.data?.message)
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(t("PrintError"))
            this.setState({ disableBtn: false })
        }
    }

    handleChange = (event) => {
        let { paymentDetail, amountDetail } = this.state
        let name = event.target.name;
        let value = event.target.value;
        if (name === "payable_amount") {
            if (value == "" || CommonValidation.NumberWithDot(value) && (value <= +(amountDetail.total_balance_amount))) {
                paymentDetail["payable_amount"] = value;
            }
        } else {
            paymentDetail[name] = value;
        }
        this.setState({
            paymentDetail
        })
    }

    handleClick = (statekey, value) => {
        let states = this.state;
        states[statekey] = value;
        this.setState({
            states
        })
    }

    renderYearPicker = (label, statekey, openMenu, width) => {
        const { t } = this.props;
        let states = this.state;
        return (
            <div id="eMed_pharmadrctpo_txtbox">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        open={states[openMenu]}
                        onOpen={() => this.handleClick(openMenu, true)}
                        onClose={() => this.handleClick(openMenu, false)}
                        views={['year']}
                        inputFormat="yyyy"
                        label={t(label)}
                        name={statekey}
                        value={states[statekey] ? states[statekey] : new Date()}
                        autoComplete="off"
                        onChange={
                            (value) => {
                                states[statekey] = new Date(value);
                                this.setState({
                                    states
                                }, () => this.GetVendorPaymentMonth())
                            }
                        }
                        emed_tid={label}
                        renderInput={(params) =>
                            <TextField
                                onKeyDown={(e) => e.preventDefault()}
                                autoComplete="off"
                                size="small"
                                sx={{ width: width ? width : "18.5vw" }}
                                {...params}
                                onClick={() => this.handleClick(openMenu, true)} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    renderTextBox(label, stateKey, value = '') {
        let { paymentDetail, open } = this.state;
        const { t } = this.props
        return (
            <div>
                {stateKey === "payment_date" ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            open={open}
                            onOpen={() => { this.setState({ open: true }) }}
                            onClose={() => { this.setState({ open: false }) }}
                            label={t(label) + "*"}
                            name={"payment_date"}
                            autoComplete="off"
                            size="small"
                            value={value}
                            inputFormat='dd-MM-yyyy'
                            emed_tid={label}
                            maxDate={new Date()}
                            onChange={
                                (value) => {
                                    paymentDetail["payment_date"] = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                                    this.setState({
                                        paymentDetail
                                    })
                                }
                            }
                            renderInput={(params) => <TextField
                                sx={{ width: "18.5vw" }}
                                size="small" {...params}
                                autoComplete="off"
                                onClick={() => { this.setState({ open: true }) }}
                                onKeyDown={(e) => e.preventDefault()} />}
                        />
                    </LocalizationProvider>
                    :
                    <TextField
                        className="emedhub_vendor_textBox"
                        required={stateKey === "payable_amount" ? true : false}
                        size="small"
                        sx={{ width: "18.5vw" }}
                        autoComplete="off"
                        multiline={stateKey === "comment" ? true : false}
                        rows={stateKey === "comment" ? 2.5 : null}
                        label={t(label)}
                        name={stateKey}
                        value={value ? value : ""}
                        inputProps={{ emed_tid: label }}
                        onChange={this.handleChange}
                    />}
            </div>
        )
    }
    handleView = (id) => {
        let { vendor_id, paymentHistory } = this.state;
        if (id && vendor_id) {
            try {
                RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_MONTHLY_POST}?id=${id}&vendor_id=${vendor_id}`)
                    .then(response => {
                        if (response.data.status == "success") {
                            paymentHistory = response.data.data ? response.data.data : [];
                            paymentHistory.length > 0 && paymentHistory?.forEach((item, i) => {
                                item["sno"] = i + 1;
                            })
                            this.setState({
                                paymentHistory
                            });
                        }
                    })
                    .catch(error => {
                        if (error.response.data.status == "fail") {
                            this.errorMessage(error.response.data?.message, false);
                        }
                    });
            } catch (e) {
                this.errorMessage(e.message);
            }
        }
    }

    renderPayment = (stateKey, label) => {
        let states = this.state;
        return (
            <Stack direction="row" spacing={3} justifyContent="space-evenly" alignItems="center" >
                <p>{label}</p>
                <TextField
                    className="emedhub_vendor_textBox"
                    size="small"
                    sx={{ width: "14.5vw" }}
                    autoComplete="off"
                    name={stateKey}
                    value={states[stateKey] ? states[stateKey] : ""}
                    inputProps={{ emed_tid: label }}
                    onChange={(e) => {
                        let { name, value } = e.target;
                        switch (name) {
                            case "card_number":
                            case "cheque_number":
                                let isnum = CommonValidation.numberOnly(value)
                                if (value === "" || isnum) {
                                    states[name] = value.trimStart();
                                }
                                break;
                            default:
                                states[name] = value.trimStart();
                                break;
                        }
                        this.setState({
                            states
                        })
                    }}
                />
            </Stack>
        )
    }

    handleClose = () => {
        this.setState({
            isReceivedClicked: false,
            transaction_id: "",
            card_number: "",
            amountDetail: {},
            paymentDetail: {
                payable_amount: 0,
                payment_date: null,
                payment_made_by: "",
                comment: "",
                selectedPaymentType: null
            },
            isProceedclked: false
        })
    }

    // history popup start
    historyClose = () => {
        this.setState({
            isViewClicked: false,
            historyId: null,
            paymentHistory: []
        }, () => {
            this.GetVendorPaymentMonth();
        })
    }

    showPaymentHistory = (historydata) => {
        const { t } = this.props;
        const historyColumn = [
            { field: 'sno', headerName: t('SNo'), sortable: false, hideable: false, flex: 0.10 },
            {
                field: 'payment_date', headerName: t("Payment Date"), headerAlign: "center", align: "center", sortable: false, flex: 0.16,
                renderCell: ({ row }) => (<div>{row?.payment_date ? formatDate(row?.payment_date) : "-"}</div>)
            },
            {
                field: "paid_amount", headerName: t("PaidAmount") + ` (${CurrencySymbol})`, flex: 0.16, type: "number", sortable: false,
                renderCell: ({ row }) => (<div>{row?.paid_amount ? AmountFormat(row?.paid_amount).replace(`${CurrencySymbol}`, "") : "-"}</div>)
            },
            {
                field: "payment_mode", headerName: t("Payment Type"), flex: 0.16, sortable: false, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<div>{row?.payment_mode ? row?.payment_mode : "-"}</div>)
            },
            {
                field: "payment_made_by", headerName: t("Payment Made By"), flex: 0.18, sortable: false,
                renderCell: ({ row }) => (<div>{row?.payment_made_by ? row?.payment_made_by : "-"}</div>)
            },
            {
                field: "comments", headerName: t("Comments"), flex: 0.24, sortable: false,
                renderCell: ({ row }) => (<div>{row?.comments?.length > 25 ?
                    <Tooltip placement="top" title={row?.comments} arrow><div>{row?.comments.slice(0, 23) + "..."}</div></Tooltip>
                    : row?.comments ? row?.comments : "-"}</div>)
            }
        ];
        return (
            <Box id="eMed_ipsrvConfig_accTbl">
                <DataGrid
                    classes={{ main: 'vndmnth_HstTbl' }}
                    rows={historydata}
                    columns={historyColumn}
                    getRowId={(row) => row.sno}
                    disableColumnMenu
                    hideFooter
                    components={{
                        NoRowsOverlay: () => {
                            return (
                                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }
                    }}
                    headerHeight={40}
                />
            </Box>
        )
    }

    // history popup end

    check_Mandatory_Fields = () => {
        let { paymentDetail, card_number, cheque_number, transaction_id } = this.state;

        if (paymentDetail.payable_amount && paymentDetail.selectedPaymentType && paymentDetail.payment_date !== "Invalid DateTime") {
            switch (paymentDetail.selectedPaymentType) {
                case "2":
                    if (card_number) {
                        return false;
                    }
                    break;
                case "7":
                    if (cheque_number) {
                        return false;
                    }
                    break;
                case "4":
                case "5":
                    if (transaction_id) {
                        return false;
                    }
                    break;
                default:
                    return false;
            }
        }
        return true;
    }

    // ======> Vendor New Payment Popup Start ======>

    getNewVendorPaymentDetails = () => {
        try {
            var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
            let month = this.state.amountDetail?.month?.split(',')[0]?.toLowerCase()
            let year = CommonValidation.removeSpace(this.state.amountDetail?.month?.split(',')[1])
            let monthNum = months.indexOf(month) + 1;
            let Pending = this.state.value === 'one' ? true : false
            this.setState({ getData: true })
            RestAPIService.getAll(Serviceurls.PHARMA_NEW_VENDOR_PAYMENT + `?vendor_id=${this.state.vendor_id}&po_month=${year}-${monthNum}&only_pending=${Pending}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        let data = response?.data?.data
                        data?.forEach((element, index) => {
                            element.is_checked = false
                            element.deduct_amount = 0
                        })
                        this.setState({
                            paymentData: data,
                            dupliPaymentData: JSON.stringify(data),
                            getData: false,
                            paymentMethod: null
                        })
                    }
                }).catch((error) => {
                    this.setState({ getData: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ getData: false })
            this.errorMessage(error.message)
        }
    }

    getNewVendorPaymentReceipt = () => {
        try {
            var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
            let month = this.state.amountDetail?.month?.split(',')[0]?.toLowerCase()
            let year = CommonValidation.removeSpace(this.state.amountDetail?.month?.split(',')[1])
            let monthNum = months.indexOf(month) + 1;
            RestAPIService.getAll(Serviceurls.PHARMA_NEW_VENDOR_RECEIPT + `?vendor_id=${this.state.vendor_id}&po_month=${year}-${monthNum}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            receiptData: response.data.data,
                            dupliPaymentRecepitData: JSON.stringify(response.data.data)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getCompletedBillPrint = () => {
        try {
            var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
            let month = this.state.amountDetail?.month?.split(',')[0]?.toLowerCase()
            let year = CommonValidation.removeSpace(this.state.amountDetail?.month?.split(',')[1])
            let monthNum = months.indexOf(month) + 1;
            let Pending = this.state.value === 'one' ? true : false;
            let startDate = new Date(this.state.searchDate)
            let fromDate = this.state.searchDate ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd') : ''
            RestAPIService.getAll(Serviceurls.PHARMA_NEW_VENDOR_PAYMENT + `?vendor_id=${this.state.vendor_id}&po_month=${year}-${monthNum}&only_pending=${Pending}&from_date=${fromDate}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTransactionHistoryPrint = () => {
        try {
            var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
            let month = this.state.amountDetail?.month?.split(',')[0]?.toLowerCase()
            let year = CommonValidation.removeSpace(this.state.amountDetail?.month?.split(',')[1])
            let monthNum = months.indexOf(month) + 1;
            let startDate = new Date(this.state.searchDate)
            let fromDate = this.state.searchDate ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd') : ''
            RestAPIService.getAll(Serviceurls.PHARMA_NEW_VENDOR_RECEIPT + `?vendor_id=${this.state.vendor_id}&po_month=${year}-${monthNum}&from_date=${fromDate}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkValidate = () => {
        try {
            let message = null
            let datas = this.state.paymentData?.some(item => item?.is_checked)
            if (!datas) {
                message = 'Select atleast one data'
            } else if (this.state.payableAmount === '') {
                message = 'Enter payable amount'
            } else if (this.state.paymentMethod === null) {
                message = 'Select payment method'
            } else if (this.state.paymentMethod !== null) {
                if (this.state.paymentMethod !== "1" && this.state.transNumber === '') {
                    message = 'Enter transaction number'
                }
            }
            return message;
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    postNewVendorPayment = () => {
        try {
            let paymentList = {}
            this.state.paymentData?.forEach((element, index) => {
                if (element?.is_checked) {
                    paymentList[index] = element?.id
                }
            })
            let startDate = new Date(this.state.paymentDate)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let date = this.state.paymentDate ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : ""
            let data = {
                payment_made_by: this.state.paymentMadeBy,
                payable_amt: +this.state.payableAmount,
                payment_mode: +this.state.paymentMethod,
                payment_date: date,
                comment: this.state.comment,
                vendor_id: +this.state.vendor_id,
                ids: paymentList,
                transaction_id: this.state.transNumber
            }
            let checkValidate = this.checkValidate()
            if (checkValidate === null) {
                RestAPIService.create(data, Serviceurls.PHARMA_NEW_VENDOR_PAYMENT)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.GetVendorPaymentMonth()
                            this.getNewVendorPaymentDetails()
                            this.onCancelHandler()
                            this.setState({ postLoad: false })
                        }
                    }).catch((error) => {
                        this.setState({ postLoad: false })
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.setState({ postLoad: false })
                this.errorMessage(checkValidate)
            }
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ postLoad: false })
        }
    }

    onCancelHandler = () => {
        try {
            this.setState({
                cashAmt: '',
                payableAmount: '',
                paymentMadeBy: '',
                paymentDate: new Date(),
                searchDate: null,
                poDetailSearch: '',
                isReceivedClicked: false,
                transNumber: '',
                postLoad: false,
                getData: false,
                comment: '',
                paymentMethod: null,
                value: 'one',
                openTrans: false,
                balanceAmount: ''
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, names) => {
        try {
            let value = e.target.value
            switch (names) {
                case 'paymentMadeBy':
                    if (CommonValidation.ALPHA_NUM_SPLCHAR(value) && value?.length <= 50 || value === '') {
                        this.setState({ paymentMadeBy: value })
                    }
                    break;
                case 'payableAmount':
                    let totalAmount = 0
                    this.state.paymentData?.forEach(element => {
                        if (element?.is_checked) {
                            totalAmount = CommonValidation.formatAmount(totalAmount + element?.outstand_amt)
                        }
                    })
                    if (CommonValidation.NumberWithDot(value) && +value <= totalAmount || value === '') {
                        this.setState({ payableAmount: value }, () => {
                            if (this.state.payableAmount !== '') {
                                this.setState({
                                    cashAmt: this.state.payableAmount,
                                    balanceAmount: +value !== 0 ? CommonValidation.formatAmount(+(totalAmount - value)) : 0
                                })
                            }
                            this.CalculateDeductAmt()
                        })
                    }
                    break;
                case 'poDetailSearch':
                    this.setState({ poDetailSearch: value })
                    break;
                case 'transNumber':
                    this.setState({ transNumber: value })
                    break;
                default:
                    this.setState({ [names]: value })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (names, value, label, width, mr, disable) => {
        try {
            return (
                <TextField
                    size="small"
                    sx={{ width: width, mr: mr }}
                    disabled={disable}
                    label={label}
                    name={names}
                    value={value}
                    onChange={(e) => this.onChangeHandler(e, names)}
                    inputProps={{ emed_tid: names }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeRadioHandler = (e, key) => {
        try {
            this.setState({
                [key]: e.target.value
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    CalculateDeductAmt = () => {
        try {
            let Amount = this.state.payableAmount ? this.state.payableAmount : 0
            let List = []
            this.state.paymentData.forEach((item, index) => {
                if (item?.is_checked) {
                    if (item?.outstand_amt <= Amount) {
                        item.deduct_amount = +item?.outstand_amt
                        Amount = Amount - item?.outstand_amt
                    } else if (Amount > 0) {
                        item.deduct_amount = +Amount
                        Amount = 0
                    } else {
                        item.deduct_amount = 0
                    }
                }
                List.push(item)
            })
            this.setState({ paymentData: List })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleCheckBox = (e, key, index = null) => {
        var states = this.state
        if (key === "bill_Check") {
            if (index !== null) {
                states.payableAmount = ""
                states.balanceAmount = ''
                states.paymentData[index].is_checked = e.target.checked
                states.paymentData[index].deduct_amount = e.target.checked ? +states.paymentData[index].deduct_amount : 0
            } else {
                states.payableAmount = ""
                states.balanceAmount = ''
                states.paymentData?.map((item) => (
                    item.is_checked = e.target.checked,
                    item.deduct_amount = e.target.checked ? +item.deduct_amount : 0
                ))
            }
        } else {
            states[key] = e.target.checked
        }
        this.setState({ states }, () => {
            if (key === "bill_Check") {
                this.CalculateDeductAmt()
            }
        })
    }

    renderNewPaymentPopup = () => {
        return (
            <Modal open={true}>
                <Box component={'div'} id="emedhub_vendorMonthly_popupBox_new">
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'} borderBottom={'1px solid lightgray'}>
                        <Typography fontWeight={600}>Payment</Typography>
                        <Button className="Common_Btn_Min_Width"
                            onClick={() => this.setState({ isReceivedClicked: false }, () => this.onCancelHandler())}
                            emed_tid="vndmnth_pay_close"
                        >
                            <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                        </Button>
                    </Box>
                    <Box component={'div'} height={'5vw'} borderBottom={'1px solid lightgray'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'}>
                        <Box component={'div'}>
                            <Typography fontWeight={600}>{this.state.vendor_detail?.vendor_name}</Typography>
                            <Typography fontWeight={600} fontSize={'0.8vw'}>{`GST IN: ${this.state.vendor_detail?.vendor_gstin_number}`}</Typography>
                            <Typography fontSize={'0.8vw'} color={'gray'}>{`${this.state.vendor_detail?.vendor_city ? this.state.vendor_detail?.vendor_city : '-'},
                        ${this.state.vendor_detail?.vendor_state ? this.state.vendor_detail?.vendor_state : '-'},
                        ${this.state.vendor_detail?.vendor_pincode ? this.state.vendor_detail?.vendor_pincode : '-'}.`}
                            </Typography>
                        </Box>
                        <Box component={'div'} display={'flex'} alignItems={'center'}>
                            {this.state.value === 'three' ?
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        open={this.state.openDateSearch}
                                        onOpen={() => this.setState({ openDateSearch: true })}
                                        onClose={() => this.setState({ openDateSearch: false })}
                                        inputFormat="DD-MM-YYYY & hh:mm A"
                                        label={'Select Date and Time'}
                                        value={this.state.searchDate}
                                        maxDate={new Date()}
                                        autoComplete="off"
                                        onChange={(value) => { this.setState({ searchDate: value }) }}
                                        emed_tid={'pay_date'}
                                        renderInput={(params) =>
                                            <TextField
                                                onKeyDown={(e) => e.preventDefault()}
                                                autoComplete="off"
                                                size="small"
                                                sx={{ width: '15vw', mr: '1vw' }}
                                                {...params}
                                                onClick={() => this.setState({ openDateSearch: true })} />}
                                    />
                                </LocalizationProvider>
                                :
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        open={this.state.openDateSearch}
                                        onOpen={() => this.setState({ openDateSearch: true })}
                                        onClose={() => this.setState({ openDateSearch: false })}
                                        inputFormat="dd-MM-yyyy"
                                        label={'Select Date'}
                                        value={this.state.searchDate}
                                        maxDate={new Date()}
                                        autoComplete="off"
                                        onChange={(value) => { this.setState({ searchDate: value }) }}
                                        emed_tid={'pay_date'}
                                        renderInput={(params) =>
                                            <TextField
                                                onKeyDown={(e) => e.preventDefault()}
                                                autoComplete="off"
                                                size="small"
                                                sx={{ width: '12vw', mr: '1vw' }}
                                                {...params}
                                                onClick={() => this.setState({ openDateSearch: true })} />}
                                    />
                                </LocalizationProvider>
                            }
                            {this.inputTextBox('poDetailSearch', this.state.poDetailSearch, 'Search PO Number', '15vw', '1vw')}
                            <Tooltip title={'Search'} placement='top' arrow>
                                <IconButton
                                    sx={{ border: "2px solid gray", borderRadius: "0.3vw", width: "2.3vw", height: "2.3vw", mr: '0.5vw' }}
                                    onClick={() => {
                                        let startDate = new Date(this.state.searchDate)
                                        let date = null;
                                        if (this.state.value === 'three') {
                                            // let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
                                            date = this.state.searchDate ? `${DateTime.fromJSDate(startDate).toFormat("dd-MM-yyyy hh:mm a")}` : ""
                                        } else {
                                            date = this.state.searchDate === null ? null : DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
                                        }
                                        let dupliPaymentData = JSON.parse(this.state.dupliPaymentData)
                                        let dupliPaymentRecepitData = JSON.parse(this.state.dupliPaymentRecepitData)
                                        let filterData = [];
                                        if (this.state.value === 'three') {
                                            if (this.state.poDetailSearch && this.state.searchDate !== null) {
                                                filterData = dupliPaymentRecepitData?.filter(item => item?.po_number?.toLowerCase()?.includes(this.state.poDetailSearch?.toLowerCase()) && item?.payment_dt?.toLowerCase()?.includes(date))
                                            } else if (this.state.poDetailSearch) {
                                                filterData = dupliPaymentRecepitData?.filter(item => item?.po_number?.toLowerCase()?.includes(this.state.poDetailSearch?.toLowerCase()))
                                            } else if (this.state.searchDate !== null) {
                                                filterData = dupliPaymentRecepitData?.filter(item => item?.payment_dt?.toLowerCase()?.includes(date))
                                            } else {
                                                filterData = dupliPaymentRecepitData?.filter(item => item)
                                            }
                                            this.setState({ receiptData: filterData })
                                        } else {
                                            if (this.state.poDetailSearch && this.state.searchDate !== null) {
                                                filterData = dupliPaymentData?.filter(item => item?.po_number?.toLowerCase()?.includes(this.state.poDetailSearch?.toLowerCase()) && item?.bill_date?.toLowerCase()?.includes(date))
                                            } else if (this.state.poDetailSearch) {
                                                filterData = dupliPaymentData?.filter(item => item?.po_number?.toLowerCase()?.includes(this.state.poDetailSearch?.toLowerCase()))
                                            } else if (this.state.searchDate !== null) {
                                                filterData = dupliPaymentData?.filter(item => item?.bill_date?.toLowerCase()?.includes(date))
                                            } else {
                                                filterData = dupliPaymentData?.filter(item => item)
                                            }
                                            this.setState({ paymentData: filterData })
                                        }
                                    }}
                                >
                                    <SearchIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Clear'} placement='top' arrow>
                                <IconButton
                                    sx={{ border: "2px solid gray", borderRadius: "0.3vw", width: "2.3vw", height: "2.3vw", mr: '0.5vw' }}
                                    onClick={() => {
                                        this.setState({
                                            searchDate: null,
                                            poDetailSearch: '',
                                            paymentData: JSON.parse(this.state.dupliPaymentData),
                                            receiptData: JSON.parse(this.state.dupliPaymentRecepitData)
                                        })
                                    }}
                                >
                                    <ClearSharpIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            {this.state.value === 'one' ? null :
                                <Tooltip title='Print' placement="top" arrow>
                                    <IconButton
                                        sx={{ width: "2.3vw", height: "2.3vw", mr: '0.5vw' }}
                                        onClick={() => {
                                            if (this.state.value === 'two') this.getCompletedBillPrint()
                                            else if (this.state.value === 'three') this.getTransactionHistoryPrint()
                                        }}
                                    >
                                        <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt="" />
                                    </IconButton>
                                </Tooltip>}
                        </Box>
                    </Box>
                    <Box component={'div'}>
                        <Tabs
                            value={this.state.value}
                            onChange={(e, newValue) => {
                                this.setState({
                                    value: newValue,
                                    searchDate: null,
                                    poDetailSearch: '',
                                    transNumber: '',
                                    comment: '',
                                    paymentMethod: '',
                                    payableAmount: '',
                                    openTrans: false
                                }, () => {
                                    if ((this.state.value === 'one') || (this.state.value === 'two')) {
                                        this.getNewVendorPaymentDetails()
                                    } else {
                                        this.getNewVendorPaymentReceipt()
                                    }
                                })
                            }}
                            sx={{ backgroundColor: Colors.DataTblHeaderbg }}
                            className='eMed_bill_Tab'
                        >
                            <Tab value="one" label="Outstanding Bills" className='eMed_tab_header eMed_bill_Tab' />
                            <Tab value="two" label="Completed Bills" className='eMed_tab_header eMed_bill_Tab' />
                            <Tab value="three" label="Transaction History" className='eMed_tab_header eMed_bill_Tab' />
                        </Tabs>
                        {this.state.value === "one" ? this.outstandingScreen() : this.state.value === "two" ? this.completedScreen() : this.transactionScreen()}
                    </Box>
                    {this.state.value === 'one' ? <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} height={'3vw'} borderTop={'1px solid lightgray'} p={'0.5vw'}>
                        <Button
                            sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                            variant="outlined"
                            onClick={() => this.onCancelHandler()}
                            disabled={((this.state.amountDetail?.payment_status === 1) || (this.state.amountDetail?.payment_status === null) || !userAccess?.editAccess) ? true : false}
                            emed_tid="vndmnth_pay_clsBtn"
                        >Cancel</Button>
                        <Button
                            sx={{ height: '2vw', textTransform: 'capitalize' }}
                            variant="contained"
                            onClick={() => this.setState({ postLoad: true }, () => { this.postNewVendorPayment() })}
                            emed_tid="vndmnth_pay_proBtn"
                            disabled={this.state.postLoad || (((this.state.amountDetail?.payment_status === 1) || (this.state.amountDetail?.payment_status === null) || !userAccess?.editAccess) ? true : false)}
                        >Receive Payment</Button>
                    </Box> : <Box component={'div'}></Box>}
                </Box>
            </Modal>
        )
    }

    outstandingScreen = () => {
        try {
            let isAllBillChecked = this.state.paymentData?.every((item) => (item.is_checked === true))
            let disable = this.state.paymentData?.some((item) => item?.is_checked)
            let totalPayableAmount = 0;
            this.state.paymentData?.forEach(element => element.is_checked ? totalPayableAmount = totalPayableAmount + element?.outstand_amt : 0)
            return (
                <>{(this.state.amountDetail?.payment_status === 1) || (this.state.amountDetail?.payment_status === null) || !userAccess?.editAccess ?
                    <Box component={'div'} height={'31vw'} p={'0.5vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography fontWeight={600}>{!userAccess?.editAccess ? "No Access To this user" : "No Outstanding Bills To Be Shown"}</Typography>
                    </Box> :
                    <Box component={'div'} height={'31vw'} p={'0.5vw'} display={'flex'}>
                        <Box component={'div'} flex={0.65} border={'1px solid lightgray'} mr={'0.5vw'} overflow={'auto'}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'10.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw", paddingY: "0vw" }}>
                                            <Checkbox size='small' checked={isAllBillChecked} onChange={(e) => { this.handleCheckBox(e, "bill_Check") }} />
                                        </TableCell>
                                        <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>PO Date</TableCell>
                                        <TableCell width={'16.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>PO Details</TableCell>
                                        <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Bill Amt</TableCell>
                                        <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Paid Amt</TableCell>
                                        <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Outstanding</TableCell>
                                        <TableCell width={'16.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Detected</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.paymentData?.length > 0 ? this.state.paymentData?.map((item, index) => {
                                        let billNo = `Bill No.: ${item?.bill_no}`
                                        return (
                                            <TableRow key={index}>
                                                <TableCell sx={{ paddingY: "0vw" }}>
                                                    <Checkbox size='small' checked={item?.is_checked} onChange={(e) => { this.handleCheckBox(e, "bill_Check", index) }} />
                                                </TableCell>
                                                <TableCell>{formatDate(item?.bill_date)}</TableCell>
                                                <TableCell>
                                                    <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.po_number ? item?.po_number : 0}</Typography>
                                                    {billNo ? billNo?.length > 15 ?
                                                        <Tooltip placement="top" title={billNo}><div style={{fontSize:'0.8vw'}}>{billNo?.slice(0, 15) + '...'}</div></Tooltip> :
                                                        billNo : '-'}
                                                    {/* <Typography fontSize={'0.8vw'} component={'div'}>Bill No.:
                                                    <Typography fontSize={'0.8vw'} component={'div'}>{item?.bill_no ? item?.bill_no?.length > 8 ?
                                                        <Tooltip placement="top" title={item?.bill_no}><span style={{ fontSize: '0.7vw' }}>{`Bill No.:${item?.bill_no?.slice(0, 8) + '...'}`}</span></Tooltip> : `Bill No.:${item?.bill_no}` : 0}</Typography>
                                                    </Typography> */}
                                                </TableCell>
                                                <TableCell>{item?.total_amount ? CommonValidation.formatAmount(item?.total_amount) : 0}</TableCell>
                                                <TableCell>{item?.paid_amount ? CommonValidation.formatAmount(item?.paid_amount) : 0}</TableCell>
                                                <TableCell>{item?.outstand_amt ? CommonValidation.formatAmount(item?.outstand_amt) : 0}</TableCell>
                                                <TableCell>{item?.deduct_amount ? CommonValidation.formatAmount(item?.deduct_amount) : 0}</TableCell>
                                            </TableRow>
                                        )
                                    }) : <TableRow><TableCell colSpan={7} align="center">No Records To Be Shown</TableCell></TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box component={'div'} flex={0.35} border={'1px solid lightgray'} overflow={'auto'}>
                            <Box component={'div'} display={'flex'} alignItems={'center'} sx={{ backgroundColor: Colors.Background }} p={'0.5vw'} borderBottom={'1px solid lightgray'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Billing Information</Typography>
                            </Box>
                            <Box component={'div'} mt={'0.5vw'}>
                                <Box component={'div'} p={'0.5vw'}>
                                    {this.inputTextBox('paymentMadeBy', this.state.paymentMadeBy, 'Payment Made By', '10vw', '0.5vw')}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            open={this.state.openDate}
                                            onOpen={() => this.setState({ openDate: true })}
                                            onClose={() => this.setState({ openDate: false })}
                                            inputFormat="DD-MM-YYYY & hh:mm A"
                                            label={'Payment Date & Time'}
                                            value={this.state.paymentDate}
                                            autoComplete="off"
                                            maxDate={new Date()}
                                            onChange={(value) => { this.setState({ paymentDate: value }) }}
                                            emed_tid={'pay_date'}
                                            renderInput={(params) =>
                                                <TextField
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    autoComplete="off"
                                                    size="small"
                                                    sx={{ width: '15vw' }}
                                                    {...params}
                                                    onClick={() => this.setState({ openDate: true })} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box component={'div'} p={'0.5vw'} borderTop={'1px solid lightgray'}>
                                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'0.5vw'}>
                                        <Typography fontWeight={600} fontSize={'0.9vw'}>Total Payable Amount</Typography>
                                        <Typography fontWeight={600} fontSize={'0.9vw'} emed_tid="vndmnth_pay_TtlBillAmt">{`${CurrencySymbol} ${totalPayableAmount ? CommonValidation.formatAmount(totalPayableAmount) : 0}`}</Typography>
                                    </Box>
                                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'0.5vw'}>
                                        <Typography fontWeight={600} fontSize={'0.9vw'}>Payable Amount</Typography>
                                        {this.inputTextBox('payableAmount', this.state.payableAmount, '', '10vw', '', !disable)}
                                    </Box>
                                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'0.5vw'}>
                                        <Typography fontWeight={600} fontSize={'0.9vw'}>Balance Amount</Typography>
                                        <Typography fontWeight={600} fontSize={'0.9vw'} emed_tid="vndmnth_pay_tBalAmt">{`${CurrencySymbol} ${this.state.balanceAmount ? this.state.balanceAmount : 0}`}</Typography>
                                    </Box>
                                </Box>
                                <Box component={'div'} borderTop={'1px solid lightgray'} p={'0.5vw'}>
                                    <Box component={'div'}>
                                        <Typography fontWeight={600} fontSize={'0.9vw'}>Select Payment Mode</Typography>
                                    </Box>
                                    <Box component={'div'}>
                                        <RadioGroup
                                            row
                                            value={this.state.paymentMethod}
                                            onClick={(e) => {
                                                let { value } = e.target
                                                if (value) {
                                                    if (value === this.state.paymentMethod) {
                                                        this.state.paymentMethod = null
                                                        this.setState({
                                                            paymentMethod: this.state.paymentMethod,
                                                            cardNumber: "",
                                                            bankNumber: "",
                                                            chequeNumber: "",
                                                            upiNumber: '',
                                                            cashAmt: '',
                                                            openTrans: false
                                                        })
                                                    } else {
                                                        this.state.paymentMethod = e.target.value
                                                        this.setState({
                                                            paymentMethod: this.state.paymentMethod,
                                                            cardNumber: "",
                                                            bankNumber: "",
                                                            chequeNumber: "",
                                                            upiNumber: '',
                                                            openTrans: true
                                                        })
                                                    }
                                                }
                                            }}
                                        >
                                            <FormControlLabel value={1} control={<Radio size="small" emed_tid="vndmnth_pay_rad1" />} label={"Cash"} />
                                            <FormControlLabel value={2} control={<Radio size="small" emed_tid="vndmnth_pay_rad2" />} label={"Card"} />
                                            <FormControlLabel value={7} control={<Radio size="small" emed_tid="vndmnth_pay_rad3" />} label={"Cheque"} />
                                            <FormControlLabel value={4} control={<Radio size="small" emed_tid="vndmnth_pay_rad4" />} label={"UPI"} />
                                            <FormControlLabel value={5} control={<Radio size="small" emed_tid="vndmnth_pay_rad5" />} label={"Bank Transfer"} />
                                        </RadioGroup>
                                        <Box component={'div'}>
                                            {this.state.paymentMethod !== "1" && this.state.openTrans ?
                                                this.inputTextBox('transNumber', this.state.transNumber, 'Transaction Number', '15vw') : null}
                                        </Box>
                                        <Box component={'div'} mt={'0.5vw'}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={2}
                                                label='comments'
                                                value={this.state.comment}
                                                onChange={(e) => this.setState({ comment: e.target.value })}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }</>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    completedScreen = () => {
        try {
            this.state.paymentData?.slice(
                this.state.tblPage * this.state.tblPageSize,
                this.state.tblPage * this.state.tblPageSize +
                this.state.tblPageSize)?.forEach((element, index) => {
                    let page = this.state.tblPage
                    let pageSize = this.state.tblPageSize
                    let serial = (page * pageSize) + (index + 1)
                    element.serial = serial
                })
            return (
                <Box component={'div'} p={'0.5vw'} height={'34vw'}>
                    <Box component={'div'} height={'27vw'} border={'1px solid lightgray'} overflow={'auto'}>
                        <Table stickyHeader size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'20%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>S.No</TableCell>
                                    <TableCell width={'20%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>PO Date</TableCell>
                                    <TableCell width={'20%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>PO No.</TableCell>
                                    <TableCell width={'20%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Bill No.</TableCell>
                                    <TableCell width={'20%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Bill Amt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.paymentData?.length > 0 ? this.state.paymentData?.slice(
                                    this.state.tblPage * this.state.tblPageSize,
                                    this.state.tblPage * this.state.tblPageSize +
                                    this.state.tblPageSize)?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item?.serial}</TableCell>
                                            <TableCell>{item?.bill_date ? formatDate(item?.bill_date) : '-'}</TableCell>
                                            <TableCell>{item?.po_number ? item?.po_number : '-'}</TableCell>
                                            <TableCell>{item?.bill_no ? item?.bill_no : '-'}</TableCell>
                                            <TableCell>{item?.total_amount ? item?.total_amount : '-'}</TableCell>
                                        </TableRow>
                                    )) : <TableRow><TableCell colSpan={5} align="center">No Records To Be Shown</TableCell></TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box component={'div'} position={'relative'}>
                        <TablePagination
                            className='eMed_tbl_pagination'
                            sx={{ border: '1px solid lightgray' }}
                            component='div'
                            rowsPerPageOptions={[10, 20, 30]}
                            count={this.state.paymentData?.length}
                            rowsPerPage={this.state.tblPageSize}
                            page={this.state.tblPage}
                            onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                            onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                        />
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    transactionScreen = () => {
        try {
            this.state.receiptData?.slice(
                this.state.page * this.state.pageSize,
                this.state.page * this.state.pageSize +
                this.state.pageSize)?.forEach((element, index) => {
                    let page = this.state.page
                    let pageSize = this.state.pageSize
                    let serial = (page * pageSize) + (index + 1)
                    element.serial = serial
                })
            return (
                <Box component={'div'} p={'0.5vw'} height={'34vw'}>
                    <Box component={'div'} height={'27vw'} border={'1px solid lightgray'} overflow={'auto'}>
                        <Table stickyHeader size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'10.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>S.No</TableCell>
                                    <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Payment Date</TableCell>
                                    <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Receipt No.</TableCell>
                                    <TableCell width={'18.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>PO No.</TableCell>
                                    <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Payment Mode</TableCell>
                                    <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Paid Amount</TableCell>
                                    <TableCell width={'14.28%'} sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: "0.9vw" }}>Payment Made By</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.receiptData?.length > 0 ? this.state.receiptData?.slice(
                                    this.state.page * this.state.pageSize,
                                    this.state.page * this.state.pageSize +
                                    this.state.pageSize)?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item?.serial}</TableCell>
                                            <TableCell>{item?.payment_dt ? item?.payment_dt : '-'}</TableCell>
                                            <TableCell>{item?.receipt_no ? item?.receipt_no : '-'}</TableCell>
                                            <TableCell>{item?.po_number ? item?.po_number?.length > 20 ?
                                                <Tooltip placement="top" title={item?.po_number}><div>{item?.po_number?.slice(0, 20) + '...'}</div></Tooltip> :
                                                item?.po_number : '-'}</TableCell>
                                            <TableCell>
                                                {item?.payment_mode ?
                                                    item?.payment_mode === 1 ? 'Cash' :
                                                        item?.payment_mode === 2 ? 'Card' :
                                                            item?.payment_mode === 4 ? 'UPI' :
                                                                item?.payment_mode === 5 ? 'Bank' :
                                                                    item?.payment_mode === 7 ? 'Cheque' : null
                                                    : '-'}
                                            </TableCell>
                                            <TableCell>{item?.received_amount ? item?.received_amount : '-'}</TableCell>
                                            <TableCell>{item?.payment_made_by ? item?.payment_made_by?.length > 15 ?
                                                <Tooltip placement="top" title={item?.payment_made_by}><div>{item?.payment_made_by?.slice(0, 15) + '...'}</div></Tooltip> :
                                                item?.payment_made_by : '-'}</TableCell>
                                        </TableRow>
                                    )) : <TableRow><TableCell colSpan={7} align="center">No Records To Be Shown</TableCell></TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box component={'div'} position={'relative'}>
                        <TablePagination
                            className='eMed_tbl_pagination'
                            sx={{ border: '1px solid lightgray' }}
                            component='div'
                            rowsPerPageOptions={[10, 20, 30]}
                            count={this.state.receiptData?.length}
                            rowsPerPage={this.state.pageSize}
                            page={this.state.page}
                            onPageChange={(event, newPage) => { this.setState({ page: newPage }) }}
                            onRowsPerPageChange={(event) => { this.setState({ pageSize: event.target.value, page: 0 }) }}
                        />
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    // ===========> Vendor New Payment Popup Ends =============>

    render() {
        const { t } = this.props
        let { vendorMonthlyData, vendor_detail, isViewClicked, paymentHistory, paymentDetail, amountDetail } = this.state;
        let balanceAmount = ((amountDetail.total_balance_amount) - (paymentDetail.payable_amount));
        const columns = [
            { field: 'sno', headerName: 'S.No', flex: 0.04, sortable: false, hideable: false },
            {
                field: "month", headerAlign: "center", headerName: 'Month', flex: 0.142, hideable: false, align: "center",
                renderCell: ({ row }) => (<div>{row?.month ? row?.month : "-"}</div>)
            },
            {
                field: 'total_po', headerName: 'Total Invoice', flex: 0.142, type: "number",
                renderCell: ({ row }) => (<div>{row?.total_po ? row?.total_po : "-"}</div>)
            },
            {
                field: 'total_bill_amount', headerName: `Bill Amount (${CurrencySymbol})`, flex: 0.142, type: "number",
                renderCell: ({ row }) => (<div>{row?.total_bill_amount ? AmountFormat(row?.total_bill_amount).replace(`${CurrencySymbol}`, "") : "-"}</div>)
            },
            {
                field: 'total_paid_amount', headerName: `Paid Amount (${CurrencySymbol})`, flex: 0.142, type: "number",
                renderCell: ({ row }) => (<div>{row?.total_paid_amount ? AmountFormat(row?.total_paid_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</div>)
            },
            {
                field: 'total_balance_amount', headerName: `OutStanding Amount (${CurrencySymbol})`, flex: 0.142, type: "number", renderCell: ({ row }) => (
                    <Typography sx={{ color: row["total_balance_amount"] == 0 ? "#1E972A" : "#B82121" }}>
                        {row.total_balance_amount ? AmountFormat(row.total_balance_amount).replace(`${CurrencySymbol}`, "") : "0.00"}
                    </Typography>)
            },
            {
                field: 'payment_status', headerAlign: 'center', headerName: 'Status', flex: 0.125, hideable: false, align: "center", renderCell: ({ row }) => (
                    <Tooltip placement="top" title={row.payment_status === 1 ? "Completed" : "Pending"} arrow><div>
                        <img className="emedhub_popupIcon_img"
                            src={row.payment_status === 1 ? ImagePaths.checkCircle.default : ImagePaths.pendingAction.default}
                            alt={"status"} />
                    </div></Tooltip>)
            },
            {
                field: 'actions', headerName: 'Action', type: 'actions', flex: 0.125, sortable: false, hideable: false,
                renderCell: ({ row }) => (
                    // <Stack direction="row" justifyContent="space-around" alignItems="center">
                    //     {userAccess?.editAccess ? <Button emed_tid = "vndmnth_paybk" variant="contained" size="small" id="eMed_Config_btn"
                    //         disabled={row.payment_status === 1}
                    //         onClick={() => {
                    //             this.setState(
                    //                 {
                    //                     isReceivedClicked: true,
                    //                     amountDetail: row
                    //                 })
                    //         }}>{t("Payback")}</Button>: null}

                    //     <Tooltip title={"View"} placement="top" arrow>
                    //         <IconButton emed_tid = "vndmnth_view" size="small" sx={{ borderRadius: "0.3vw" }}
                    //             onClick={() => {
                    //                 this.setState(
                    //                     { isViewClicked: true, historyId: row.id },
                    //                     () => { this.handleView(row.id) })
                    //             }} >
                    //             <img className="emedhub_directPo_img" src={ImagePaths.LabViewButton.default} alt={"view"} />
                    //         </IconButton>
                    //     </Tooltip>
                    // </Stack>
                    <><Button
                        variant="contained"
                        sx={{ textTransform: 'capitalize', height: '2vw' }}
                        onClick={() => {
                            this.setState(
                                {
                                    isReceivedClicked: true,
                                    amountDetail: row,
                                    value: ((row.payment_status === 1) || (row.payment_status === null)) ? 'two' : 'one'
                                }, () => {
                                    this.getNewVendorPaymentDetails()
                                })
                        }}
                    >{((row.payment_status === 1) || (row.payment_status === null)) ? 'View' : "Payback"}</Button></>
                )
            }
        ]
        return (
            <Box className="eMed_PharmaVendor_mainBox">
                <Paper className="eMed_PharmaVendor_paper" elevation={2}>
                    <Stack direction="row" sx={{ padding: "0.625vw" }} justifyContent="space-evenly" spacing={2} alignItems="start">
                        <IconButton emed_tid="vndmnth_bk" onClick={() => { { this.props.history.push({ pathname: "/PharmacyPurchase/VendorPayment" }) } }} sx={{ marginRight: "0.5vw" }}>
                            <img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} />
                        </IconButton>
                        <Stack spacing={2} direction="column">
                            <div>
                                <Typography id="eMed_PharmaVendor_headTxt">{t("VendorName")}</Typography>
                                <Typography id="eMed_PharmaVendor_Txt">{vendor_detail.vendor_name}</Typography>
                                <Typography id="eMed_PharmaVendor_headTxt">{`${vendor_detail.vendor_city} , ${vendor_detail.vendor_state}`}</Typography>
                                <Typography id="eMed_PharmaVendor_headTxt">{vendor_detail.vendor_pincode}</Typography>
                            </div>
                            <div>
                                <Typography id="eMed_PharmaVendor_headTxt">{t("GSTNumber")}</Typography>
                                <Typography id="eMed_PharmaVendor_Txt">{vendor_detail.vendor_gstin_number}</Typography>
                            </div>
                            <div>
                                <Typography id="eMed_PharmaVendor_headTxt">{t("BillAmount")}</Typography>
                                <Typography id="eMed_PharmaVendor_Txt">{`${CurrencySymbol}  ${vendor_detail.total_bill_amount ? AmountFormat(vendor_detail.total_bill_amount).replace(CurrencySymbol, "") : 0}`}</Typography>
                            </div>
                        </Stack>
                        <Stack spacing={2} direction="column" justifyContent="flex-end">
                            <div>
                                <Typography id="eMed_PharmaVendor_headTxt">{t("OutstandingAmount")}</Typography>
                                <Typography id="eMed_PharmaVendor_Txt">{`${CurrencySymbol}  ${vendor_detail.total_balance_amount ? AmountFormat(vendor_detail.total_balance_amount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                            </div>
                        </Stack>
                    </Stack>
                </Paper>
                <Stack sx={{ margin: "0.625vw" }} spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                    {this.renderYearPicker("", "bill_date_year", "openYear", "8vw")}
                    <Tooltip placement="top" title={t("print")} arrow>
                        <IconButton emed_tid="vndmnth_print" size="small" disabled={this.state.disableBtn} onClick={() => this.printVendorMonthList()}>
                            <img className="emedhub_directPo_img" src={ImagePaths.LabPrintIcon.default} alt='print' />
                        </IconButton></Tooltip>
                    <Tooltip placement="top" title={t("Export")} arrow>
                        <IconButton emed_tid="vndmnth_export" size="small" disabled={this.state.disableBtn} onClick={() => this.getVendorMonthListExcel()}>
                            <img className="emedhub_directPo_img" src={ImagePaths.LabUploadButton.default} alt='upload' />
                        </IconButton></Tooltip>
                </Stack>
                <div id="eMed_PharmaVendor_Table">
                    <DataGrid
                        rows={vendorMonthlyData}
                        columns={columns}
                        disableColumnFilter
                        getRowId={(row) => row.sno}
                        hideFooterSelectedRowCount
                        components={{
                            NoRowsOverlay: () => {
                                return (
                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "30vh" }}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        rowCount={vendorMonthlyData.length}
                        page={this.state.page}
                        onPageChange={(newPage) => this.setState({ page: newPage })}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                    />
                </div>
                {/* Commented for new Popup */}
                {/* {this.state.isReceivedClicked && this.state.amountDetail ?
                    <Modal open={true}>
                        <Paper elevation={3} id="emedhub_vendorMonthly_popupBox">
                            <div id="emedhub_vendorDetails_header" className="eMed_pur_back_color">
                                <Stack direction="row" spacing={2}>
                                    <Typography sx={{ fontWeight: "bold" }}>{t("Payment")}</Typography>
                                    <IconButton emed_tid = "vndmnth_pay_alt" size="small">
                                        <img className="emedhub_popupIcon_img" src={ImagePaths.vector.default} alt={"Alert"} />
                                    </IconButton>
                                </Stack>
                                <IconButton emed_tid = "vndmnth_pay_close" size="small" onClick={() => { this.handleClose() }}><img className="emedhub_popupIcon_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                            </div>
                            <div>
                                <div className="emedhub_vendor_subDiv eMed_pur_txt_field">
                                    {this.renderTextBox("PayableAmount", "payable_amount", paymentDetail.payable_amount)}
                                    {this.renderTextBox("PaymentDate", "payment_date", paymentDetail.payment_date)}
                                </div>
                                <div className="emedhub_vendor_subDiv eMed_pur_txt_field">
                                    {this.renderTextBox("PaymentMadeBy", "payment_made_by", paymentDetail.payment_made_by)}
                                    {this.renderTextBox("Comment", "comment", paymentDetail.comment)}
                                </div>
                                <Divider />
                                <div style={{ padding: "0.5vw" }}>
                                    <Typography className='eMed_pur_txt_one'>{t("PaymentMethod")}</Typography>
                                    <Typography className='eMed_pur_txt_two'>{t("SelectPaymentMode")}</Typography>
                                    <Stack direction="row" spacing={3} alignItems="center">
                                        <RadioGroup
                                            row
                                            value={paymentDetail.selectedPaymentType}
                                            onClick={(e) => {
                                                let { value } = e.target
                                                if (value === paymentDetail.selectedPaymentType) {
                                                    paymentDetail.selectedPaymentType = null
                                                    this.setState({
                                                        paymentDetail,
                                                        card_number: "",
                                                        transaction_id: "",
                                                        cheque_number: "",
                                                    })
                                                } else {
                                                    paymentDetail.selectedPaymentType = e.target.value
                                                    this.setState({
                                                        paymentDetail,
                                                        card_number: "",
                                                        transaction_id: "",
                                                        cheque_number: "",
                                                    })
                                                }
                                            }
                                            }
                                        >
                                            <FormControlLabel value={1} control={<Radio size="small" emed_tid = "vndmnth_pay_rad1"/>} label={t("Cash")} />
                                            <FormControlLabel value={2} control={<Radio size="small" emed_tid = "vndmnth_pay_rad2"/>} label={t("Card")} />
                                            <FormControlLabel value={7} control={<Radio size="small" emed_tid = "vndmnth_pay_rad3"/>} label={t("Cheque")} />
                                            <FormControlLabel value={4} control={<Radio size="small" emed_tid = "vndmnth_pay_rad4"/>} label={t("UPI")} />
                                            <FormControlLabel value={5} control={<Radio size="small" emed_tid = "vndmnth_pay_rad5"/>} label={t("BankTransfer")} />
                                        </RadioGroup>
                                        <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                            {
                                                paymentDetail["selectedPaymentType"] == 1 ? null :
                                                    paymentDetail["selectedPaymentType"] == 2 ? this.renderPayment("card_number", "Card Transaction No") :
                                                        paymentDetail["selectedPaymentType"] == 7 ? this.renderPayment("cheque_number", "Cheque Number") :
                                                            paymentDetail["selectedPaymentType"] == 4 ? this.renderPayment("transaction_id", "Transaction ID") :
                                                                paymentDetail["selectedPaymentType"] == 5 ? this.renderPayment("transaction_id", "Transaction ID") : null
                                            }
                                        </Stack>
                                    </Stack>
                                    <Divider />
                                    <Stack direction="column" spacing={2} alignItems="flex-start">
                                        <Typography className="eMed_pur_bill_txt">{t("Billing")}</Typography>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography id="eMed_PharmaVendor_popNorTxt">{t("TotalBillingAmount")}</Typography>
                                            <Typography emed_tid = "vndmnth_pay_TtlBillAmt" id="eMed_PharmaVendor_popNorTxt">{`${CurrencySymbol} ${amountDetail.total_bill_amount ? AmountFormat(amountDetail.total_bill_amount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography id="eMed_PharmaVendor_popNorTxt">{t("TotalPaidAmount")}</Typography>
                                            <Typography emed_tid = "vndmnth_pay_TtlpdAmt" id="eMed_PharmaVendor_popNorTxt">{`${CurrencySymbol} ${amountDetail.total_paid_amount ? AmountFormat(amountDetail.total_paid_amount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography id="eMed_PharmaVendor_popNorTxt">{t("OutstandingAmount")}</Typography>
                                            <Typography emed_tid = "vndmnth_pay_balAmt" id="eMed_PharmaVendor_popNorTxt">{`${CurrencySymbol} ${amountDetail.total_balance_amount ? AmountFormat(amountDetail.total_balance_amount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography id="eMed_PharmaVendor_popHeadTxt">{t("PayableAmount")}</Typography>
                                            <Typography emed_tid = "vndmnth_pay_ablAmt" id="eMed_PharmaVendor_popHeadTxt">{`${CurrencySymbol} ${paymentDetail.payable_amount ? AmountFormat(paymentDetail.payable_amount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography id="eMed_PharmaVendor_popNorTxt">{t("BalanceAmount")}</Typography>
                                            <Typography emed_tid = "vndmnth_pay_tBalAmt" id="eMed_PharmaVendor_popNorTxt">{`${CurrencySymbol} ${balanceAmount ? AmountFormat(balanceAmount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                                        </Stack>
                                    </Stack>
                                </div>
                                <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ marginBottom: "0.5vw" }}>
                                    <Button emed_tid = "vndmnth_pay_clsBtn" size="small" variant="outlined" id="eMed_Config_btn" onClick={() => { this.handleClose() }} >{t('Cancel')}</Button>
                                    <Button emed_tid = "vndmnth_pay_proBtn" size="small" variant="contained" id="eMed_Config_btn" disabled = {this.check_Mandatory_Fields() || this.state.isProceedclked} onClick={() => {this.setState({isProceedclked: true}, () => this._proceedtoPay())}}>{t('Proceed')}</Button>
                                </Stack>
                            </div>
                        </Paper>
                    </Modal>
                    : null} */}
                {this.state.isReceivedClicked && this.state.amountDetail ? this.renderNewPaymentPopup() : null}
                {
                    isViewClicked && paymentHistory.length > 0 ?
                        <CommonPopUp width={"75vw"}
                            testID={"vndmnth_hst"}
                            title={t("History")}
                            iconTitle={t("print")}
                            titleIconImg={ImagePaths.PrintIcons.default}
                            IconAction={this.printVendorMonthList.bind(this, isViewClicked, this.state.historyId)}
                            popUpClose={this.historyClose.bind(this)}
                            component={this.showPaymentHistory.bind(this, paymentHistory)} />
                        : null
                }
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaVendorMonthly);
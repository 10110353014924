import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next'
import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, Stack, TextField, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CommonGridHeader, { CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";

class AssetBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BlockList: [],
            BlockCode: "",
            BlockName: "",
            City: "",
            Notes: "",
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            isActive: true,
            isLoader: false
        }
    }

    componentDidMount() {
        this.getBlockList()
    }

    getBlockList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_BLOCK_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            BlockList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    handleSave = () => {
        const { t } = this.props
        let { BlockCode, BlockName, City, Notes, isActive, edit_id } = this.state
        let data = {};
        if (BlockName != "") {
            data = {
                "block_code": BlockCode,
                "block_name": BlockName,
                "city": City,
                "notes": Notes,
                "block_id": edit_id ? edit_id : null,
                "active_status": isActive
            }
            let RestAPI = edit_id ? RestAPIService.updateWithOutId(data, Serviceurls.ASSET_BLOCK_LIST_GET_POST) : RestAPIService.create(data, Serviceurls.ASSET_BLOCK_LIST_GET_POST)
            try {
                if (City === '') {
                    this.responseSuccessErrorMessage('Enter City', false)
                } else {
                    this.setState({ isLoader: true })
                    RestAPI.then(response => {
                        if (response.data.status === "success") {
                            this.setState({ isLoader: false }, () => {
                                this.handleClear();
                                this.getBlockList();
                            })
                            this.responseSuccessErrorMessage(response.data.message, true);
                        }
                    })
                        .catch(error => {
                            if (error?.response?.data?.status === "fail") {
                                this.setState({ isLoader: false })
                                this.responseSuccessErrorMessage(error.response.data.message, false);
                            }
                        });
                }
            } catch (e) {
                this.setState({ isLoader: false })
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage("Block Name Should not be empty", false);
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    msgClose() {
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
    }

    handleEdit = (item) => {
        let states = this.state
        states["BlockCode"] = item?.block_code ? item?.block_code : ""
        states["BlockName"] = item?.block_name
        states['City'] = item?.city ? item?.city : ""
        states['Notes'] = item?.notes ? item?.notes : ""
        states["isEdit"] = true
        states["edit_id"] = item?.id
        states["isActive"] = item?.active_status
        this.setState({ states })
    }

    handleClear = () => {
        this.setState({
            BlockCode: "",
            BlockName: "",
            City: "",
            Notes: "",
            isActive: true,
            edit_id: null,
            isEdit: false
        })
    }

    renderTextField = (value, statekey, Label, required) => {
        const { t } = this.props;
        let states = this.state
        return (
            <TextField
                sx={{ width: "50%", marginX: '0.65vw', marginTop: "1vw" }}
                size="small"
                inputProps={{ maxLength: 100 }}
                autoComplete="off"
                label={t(Label)}
                required={required}
                value={value}
                onChange={(e) => {
                    states[statekey] = e.target.value
                    this.setState({ states })
                }}
            />
        )
    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, BlockList } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.06, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            {
                field: 'block_code', headerName: t('Block Code'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.block_code?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.block_code} arrow><div>{cellvalues?.row?.block_code.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.block_code ? cellvalues?.row?.block_code : "-"}</div>
                    )
                }
            },
            {
                field: 'block_name', headerName: t('Block Name'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.block_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.block_name} arrow><div>{cellvalues?.row?.block_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.block_name ? cellvalues?.row?.block_name : "-"}</div>
                    )
                }
            },
            {
                field: 'city', headerName: t('City'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.city?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.city} arrow><div>{cellvalues?.row?.city.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.city ? cellvalues?.row?.city : "-"}</div>
                    )
                }
            },
            {
                field: 'active_status', headerAlign: 'center', headerName: t('Status'), flex: 0.21, align: "center", renderCell: (cellvalues) => {
                    return (
                        <div style={{ color: cellvalues?.row?.active_status ? Colors.themeDark : Colors.red, backgroundColor: "#e2faf9", width: "4.5vw", padding: "0.5vw", borderRadius: "0.3vw", textAlign: "center", fontSize: "0.9vw", fontWeight: "600" }}>
                            {cellvalues?.row?.active_status ? "Active" : "InActive"}
                        </div>
                    )
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.15, sortable: false, hideable: false, align: "center", renderCell: (cellvalue) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <CommonEditButton size="1.5vw" onClick={this.handleEdit.bind(this, cellvalue.row)} />
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={BlockList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={BlockList.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t("Add Block")}</p>
                            </div>
                            <div className="eMed_ipPackage_Div" style={{ overflow: "scroll" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                                    {this.renderTextField(this.state.BlockCode, "BlockCode", "Block Code", false)}
                                    {this.renderTextField(this.state.BlockName, "BlockName", "Block Name", true)}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                                    {this.renderTextField(this.state.City, "City", "City", true)}
                                    {this.renderTextField(this.state.Notes, "Notes", "Notes", false)}
                                </div>
                                <div style={{ paddingTop: "1vw", paddingLeft: "1vw", borderTop: "1px solid #E3E2E2", marginTop: "2vw" }}>
                                    <FormControlLabel checked={this.state.isActive} control={<Checkbox />} onClick={(e) => {
                                        this.setState({ isActive: e.target.checked })
                                    }} label="Active" />
                                </div>
                            </div>
                            <Stack direction="column" sx={{ backgroundColor: Colors.DataTblHeaderbg, height: "7vh" }} alignItems="center" justifyContent="center">
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button disabled={this.state.isLoader} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.handleSave() }}>{t("Add")}</Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(AssetBlock)
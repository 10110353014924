import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next'
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { Autocomplete, Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";


class AssetAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetAuditData: [],
            page: 0,
            pageSize: 10,
            Category_Name: null,
            Sub_Category_Name: null,
            Asset_no: null,
            assetNumber: [],
            CategoryList: [],
            subCategoryList: [],
            successMsg: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsgText: '',
            rowCount: 0,
            isLoader: false
        }
    }

    componentDidMount = () => {
        this.getAssetAudit()
        this.getAssetNumberList()
        this.getAssetCatAndSubCatData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getAssetAudit = () => {
        let categoryId = this.state.Category_Name ? this.state.Category_Name?.category_name : ''
        let subCategoryId = this.state.Sub_Category_Name ? this.state.Sub_Category_Name?.sub_category_name : ''
        let assetNo = this.state.Asset_no ? this.state.Asset_no.asset_no : ''
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.ASSET_AUDIT_GET + `?category=${categoryId}&sub_category=${subCategoryId}&asset_no=${assetNo}`).
                then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            assetAuditData: response.data.data,
                            isLoader: false
                        })
                    }
                }).catch((error) => {
                    this.setState({ isLoader: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.setState({ isLoader: false })
            this.errorMessage(e.message)
        }
    }

    getAssetAuditPrint = () => {
        let categoryId = this.state.Category_Name ? this.state.Category_Name?.category_name : ''
        let subCategoryId = this.state.Sub_Category_Name ? this.state.Sub_Category_Name?.sub_category_name : ''
        let assetNo = this.state.Asset_no ? this.state.Asset_no.asset_no : ''
        try {
            RestAPIService.getAll(Serviceurls.ASSET_AUDIT_GET + `?export=pdf&category=${categoryId}&sub_category=${subCategoryId}&asset_no=${assetNo}`).
                then((response) => {
                    if (response) {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getAssetNumberList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_AUDIT_SEARCH_ASSETNO)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            assetNumber: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    getAssetCatAndSubCatData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSER_NEW_CAT_SUB_CAT_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            CategoryList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        this.state.assetAuditData?.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.01, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "asset_no", headerName: t("Asset ID"), flex: 0.05, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let asset_no = params?.row?.asset_no
                    return (
                        <Box component={'div'}>
                            {asset_no ? asset_no?.length > 15 ?
                                <Tooltip placement="top" title={asset_no}><Typography fontSize={'0.9vw'}>{asset_no?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{asset_no}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "asset_name", headerName: t("Asset Name"), flex: 0.10, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let assetName = params?.row?.asset_name
                    return (
                        <Box component={'div'}>
                            {assetName ? assetName?.length > 15 ?
                                <Tooltip placement="top" title={assetName}><Typography fontSize={'0.9vw'}>{assetName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{assetName}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "category__category_name", headerName: t("Category"), flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let catName = params?.row?.category__category_name
                    return (
                        <Box component={'div'}>
                            {catName ? catName?.length > 15 ?
                                <Tooltip placement="top" title={catName}><Typography fontSize={'0.9vw'}>{catName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{catName}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "sub_category__sub_category_name", headerName: t("Sub Category"), flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let subCatName = params?.row?.sub_category__sub_category_name
                    return (
                        <Box component={'div'}>
                            {subCatName ? subCatName?.length > 15 ?
                                <Tooltip placement="top" title={subCatName}><Typography fontSize={'0.9vw'}>{subCatName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{subCatName}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "purchase_date", headerName: t("Purchase Date"), flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let purDate = params?.row?.purchase_date
                    return (
                        <Box component={'div'}>
                            {purDate ? purDate?.length > 15 ?
                                <Tooltip placement="top" title={purDate}><Typography fontSize={'0.9vw'}>{purDate?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{purDate}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "vendor_name", headerName: t("Vendor Name"), flex: 0.10, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let vendorName = params?.row?.vendor_name
                    return (
                        <Box component={'div'}>
                            {vendorName ? vendorName?.length > 15 ?
                                <Tooltip placement="top" title={vendorName}><Typography fontSize={'0.9vw'}>{vendorName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{vendorName}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "warranty_status", headerName: t("Warranty Status"), flex: 0.08, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let WarrantyStatus = params?.row?.warranty_status
                    return (
                        <Box component={'div'}>
                            {WarrantyStatus ? WarrantyStatus?.length > 15 ?
                                <Tooltip placement="top" title={WarrantyStatus}><Typography fontSize={'0.9vw'}>{WarrantyStatus?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{WarrantyStatus}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "condition", headerName: t("Condition"), flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let condition = params?.row?.condition
                    return (
                        <Box component={'div'}>
                            {condition ? condition?.length > 15 ?
                                <Tooltip placement="top" title={condition}><Typography fontSize={'0.9vw'}>{condition?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{condition}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "statuss", headerName: t("Status"), flex: 0.05, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let status = params?.row?.statuss
                    return (
                        <Box component={'div'}>
                            {status ? status?.length > 15 ?
                                <Tooltip placement="top" title={status}><Typography fontSize={'0.9vw'}>{status?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{status}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "next_service_date", headerName: t("Next Service"), flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let nxtSrvDate = params?.row?.next_service_date
                    return (
                        <Box component={'div'}>
                            {nxtSrvDate ? nxtSrvDate?.length > 15 ?
                                <Tooltip placement="top" title={nxtSrvDate}><Typography fontSize={'0.9vw'}>{nxtSrvDate?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{nxtSrvDate}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "total_service", headerName: t("Total Service"), flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let tltSrv = params?.row?.total_service
                    return (
                        <Box component={'div'}>
                            {tltSrv ? tltSrv?.length > 15 ?
                                <Tooltip placement="top" title={tltSrv}><Typography fontSize={'0.9vw'}>{tltSrv?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{tltSrv}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "total_complaints", headerName: t("Total Complaints"), flex: 0.08, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let tltCmp = params?.row?.total_complaints
                    return (
                        <Box component={'div'}>
                            {tltCmp ? tltCmp?.length > 15 ?
                                <Tooltip placement="top" title={tltCmp}><Typography fontSize={'0.9vw'}>{tltCmp?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{tltCmp}</Typography> : '-'}
                        </Box>
                    )
                }
            }
        ]
        return (
            <Box className="emedhub__purchase_page" sx={{ '& .grid--header': { backgroundColor: '#FAF9F9' } }}>
                <Box className="emedhub__purchase_page" sx={{ margin: "1vw", width: '92vw' }}>
                    <Paper elevation={3} sx={{ padding: '0.5vw' }}>
                        <div>Search Asset</div>
                        <Box sx={{ display: 'flex', padding: '0.5vw', gap: '1vw' }}>
                            <Autocomplete
                                sx={{ width: '14vw' }}
                                disableClearable
                                size='small'
                                value={this.state.Category_Name}
                                options={this.state.CategoryList}
                                getOptionLabel={(option) => (option?.category_name)}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        Category_Name: newValue,
                                        Sub_Category_Name: null,
                                        Asset_no: null,
                                    }, () => {
                                        this.setState({
                                            subCategoryList: this.state.Category_Name?.sub_category_data
                                        })
                                    })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('Category Name')} />
                                )}
                            />
                            <Autocomplete
                                sx={{ width: '14vw' }}
                                disableClearable
                                size='small'
                                disabled={this.state.Category_Name === null}
                                value={this.state.Sub_Category_Name}
                                options={this.state.subCategoryList}
                                getOptionLabel={(option) => (option?.sub_category_name)}
                                onChange={(event, newValue) => {
                                    let categoryName = this.state.CategoryList.filter((item) => item.id === newValue.category_id)
                                    this.setState({
                                        Sub_Category_Name: newValue,
                                        Asset_no: null,
                                    })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('Sub Category')} />
                                )}
                            />
                            <Autocomplete
                                sx={{ width: '14vw' }}
                                disableClearable
                                size='small'
                                value={this.state.Asset_no}
                                options={this.state.assetNumber}
                                getOptionLabel={(option) => (option?.asset_no)}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        Asset_no: newValue,
                                    })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('Asset')} />
                                )}
                            />
                            <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.getAssetAudit() }} sx={{ width: '7vw' }}>Search</Button>
                            <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.getAssetAuditPrint() }} sx={{ width: '7vw' }}>Print</Button>
                            <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.setState({ Category_Name: null, Sub_Category_Name: null, Asset_no: null }, () => this.getAssetAudit()) }} sx={{ width: '7vw' }}>Clear</Button>
                        </Box>
                    </Paper>
                </Box>
                <Box component={'div'} className='eMed_rts_table' sx={{ height: '60vh' }}>
                    <DataGrid
                        rows={this.state.assetAuditData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}
export default withTranslation()(AssetAudit)
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { ImagePaths } from '../../../Utility/ImagePaths'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import { DateTime } from 'luxon'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { Complaint_Status, Complaint_Type_List } from '../../../Utility/Constants'

export default class AssetFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      filterOpen: false,
      filterDatas: {
        "fromDate": this.props.filterDatas.fromDate,
        "toDate": this.props.filterDatas.toDate,
        "dateIndex": this.props.filterDatas.dateIndex,
        'assetTagNo': this.props.filterDatas.assetTagNo,
        'selectedCategory': this.props.filterDatas.selectedCategory,
        'selectedSubCategory': this.props.filterDatas.selectedSubCategory,
        'desSelectedBlock': this.props.filterDatas.desSelectedBlock,
        'desSelectedFloor': this.props.filterDatas.desSelectedFloor,
        'desSelectedSpot': this.props.filterDatas.desSelectedSpot,
        'selectedBlock': this.props.filterDatas.selectedBlock,
        'selectedFloor': this.props.filterDatas.selectedFloor,
        'selectedSpot': this.props.filterDatas.selectedSpot,
        'selectedComplaintType': this.props.filterDatas.selectedComplaintType,
        'selectedStatus': this.props.filterDatas.selectedStatus,
        'vendorName': this.props.filterDatas.vendorName
      },
      disableBtn: false,
      categoryList: [],
      subCategoryList: [],
      desBlockList: [],
      desFloorList: [],
      desSpotList: [],
      blockList: [],
      floorList: [],
      spotList: [],
      comStatusList: Complaint_Status,
      complaintTypeList: Complaint_Type_List
    }
  }

  componentDidMount() {
    this.getCategoryList()
    this.getBlockFloorSpotList()
  }

  getCategoryList = () => {
    try {
      RestAPIService.getAll(Serviceurls.ASSER_NEW_CAT_SUB_CAT_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              categoryList: response.data.data
            }, () => {
              if (this.state.filterDatas?.selectedCategory !== null) {
                let data = this.state.categoryList?.find(item => item?.category_id === this.state.filterDatas?.selectedCategory?.category_id)
                this.setState({
                  subCategoryList: data?.sub_category_data
                })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getBlockFloorSpotList = () => {
    try {
      RestAPIService.getAll(Serviceurls.ASSET_NEW_BLOCK_FLOOR_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              desBlockList: response.data.data,
              blockList: response.data.data
            }, () => {
              if (this.state.filterDatas?.selectedBlock !== null) {
                let data = this.state.blockList?.find(item => item?.block_id === this.state.filterDatas?.selectedBlock?.block_id)
                this.setState({ floorList: data?.floor_data })
              }
              if (this.state.filterDatas?.selectedBlock !== null && this.state.selectedSpot !== null) {
                let data = this.state.blockList?.find(item => item?.block_id === this.state.filterDatas?.selectedBlock?.block_id)
                let spotData = data?.floor_data?.find(spot => spot?.floor_id === this.state.filterDatas?.selectedFloor?.floor_id)
                this.setState({ spotList: spotData?.spot_data })
              }
              if (this.state.filterDatas?.desSelectedBlock !== null) {
                let data = this.state.desBlockList?.find(item => item?.block_id === this.state.filterDatas?.desSelectedBlock?.block_id)
                this.setState({ desFloorList: data?.floor_data })
              }
              if (this.state.filterDatas?.desSelectedBlock !== null && this.state.filterDatas?.desSelectedFloor !== null) {
                let data = this.state.desBlockList?.find(item => item?.block_id === this.state.filterDatas?.desSelectedBlock?.block_id)
                let spotData = data?.floor_data?.find(spot => spot?.floor_id === this.state.filterDatas?.desSelectedFloor?.floor_id)
                this.setState({ desSpotList: spotData?.spot_data })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }

  filterDate = (From, To, Index) => {
    this.state.filterDatas.fromDate = From
    this.state.filterDatas.toDate = To
    this.state.filterDatas.dateIndex = Index
    this.setState({ filterDatas: this.state.filterDatas })
  }

  closeFilter() {
    this.setState({
      filterOpen: false
    })
  }

  clearFilterData = () => {
    try {
      this.setState({
        filterDatas: {
          fromDate: null,
          toDate: null,
          dateIndex: 1,
          desSelectedBlock: null,
          desSelectedFloor: null,
          desSelectedSpot: null,
          selectedBlock: null,
          selectedFloor: null,
          selectedSpot: null,
          selectedComplaintType: null,
          selectedStatus: null,
          vendorName: ''
        }, disableBtn: true,
      }, () => {
        this.props.ClearData(this.state.filterDatas)
        this.filterSearch()
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  filterSearch() {
    let states = this.state
    this.setState({ filterOpen: false, disableBtn: true });
    this.props.filteredData(this.state.filterDatas);
  }

  renderTextBox = (names, values, labels, width, mr) => {
    try {
      return (
        <TextField
          size='small'
          sx={{ width: width, mr: mr }}
          value={values}
          label={labels}
          onChange={(e) => this.onChangeHandler(e, names)}
          name={names}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      switch (names) {
        default:
          this.state.filterDatas[names] = value
          this.setState({ filterDatas: this.state.filterDatas })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderAutoComplete = (options, names, key, labels, width, mr, disable) => {
    try {
      return (
        <Autocomplete
          clearIcon
          disabled={disable}
          sx={{ width: width, mr: mr }}
          options={options}
          value={this.state.filterDatas[key]}
          getOptionLabel={(option) => typeof (option) === 'string' ? option : option[names]}
          onChange={(e, value) => { this.onChangeAutoHandler(value, key) }}
          renderInput={(params) => <TextField {...params} size='small' label={labels} />}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeAutoHandler = (value, key) => {
    try {
      switch (key) {
        case 'desSelectedBlock':
          this.state.filterDatas[key] = value
          this.setState({ filterDatas: this.state.filterDatas }, () => {
            this.state.filterDatas.desSelectedFloor = null
            this.state.filterDatas.desSelectedSpot = null
            this.setState({
              desFloorList: value?.floor_data,
              filterDatas: this.state.filterDatas
            })
          })
          break;
        case 'desSelectedFloor':
          this.state.filterDatas[key] = value
          this.setState({ filterDatas: this.state.filterDatas }, () => {
            this.state.filterDatas.desSelectedSpot = null
            this.setState({
              desSpotList: value?.spot_data,
              filterDatas: this.state.filterDatas
            })
          })
          break;
        case 'selectedBlock':
          this.state.filterDatas[key] = value
          this.setState({ filterDatas: this.state.filterDatas }, () => {
            this.state.filterDatas.selectedFloor = null
            this.state.filterDatas.selectedSpot = null
            this.setState({
              floorList: value?.floor_data,
              filterDatas: this.state.filterDatas
            })
          })
          break;
        case 'selectedFloor':
          this.state.filterDatas[key] = value
          this.setState({ filterDatas: this.state.filterDatas }, () => {
            this.state.filterDatas.selectedSpot = null
            this.setState({
              spotList: value?.spot_data,
              filterDatas: this.state.filterDatas
            })
          })
          break;
        case 'selectedCategory':
          this.state.filterDatas[key] = value
          this.setState({ filterDatas: this.state.filterDatas }, () => {
            this.state.filterDatas.selectedSubCategory = null
            this.setState({
              subCategoryList: value?.sub_category_data,
              filterDatas: this.state.filterDatas
            })
          })
          break;
        default:
          this.state.filterDatas[key] = value
          this.setState({ filterDatas: this.state.filterDatas })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let subCat = this.state.filterDatas.selectedCategory === null ? true : false
    let floor = this.state.filterDatas.desSelectedBlock === null ? true : false
    let spot = this.state.filterDatas.desSelectedFloor === null ? true : false
    let floor1 = this.state.filterDatas.selectedBlock === null ? true : false
    let spot1 = this.state.filterDatas.selectedFloor === null ? true : false
    return (
      <Box component={'div'}>
        <Box component={'div'} className='eMed_Filter_Billing_Container'>
          <Box component={"div"} className='eMed_Filter_Top'>
            <Typography variant='h6' fontWeight={"600"}>{("Filter")}</Typography>
            <Button emed_tid="rpt_flt_close" onClick={() => { this.setState({ filterOpen: false }, () => { this.props.CloseFilter() }) }} className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
            </Button>
          </Box>
          <Box component={'div'}>
            <Box style={{ width: "40%", margin: "1vw", marginLeft: "17.5vw" }} component={"div"}>
              <CommonDatePicker
                FromDate={this.state.filterDatas.fromDate}
                ToDate={this.state.filterDatas.toDate}
                DateIndex={this.state.filterDatas.dateIndex}
                SelectedDates={this.filterDate.bind(this)}
                HideAllMenu={true}
                days={this.state.filterDatas.days}
              />
            </Box>
            <Box component={'div'} p={'1vw'}>
              {this.renderTextBox('assetTagNo', this.state.filterDatas?.assetTagNo, 'Asset Tag No', '25.5vw')}
              <Box component={'div'} display={'flex'} mt={'1vw'}>
                {this.renderAutoComplete(this.state.categoryList, 'category_name', 'selectedCategory', 'Select Category', '12.5vw', '0.5vw')}
                {this.renderAutoComplete(this.state.subCategoryList, 'sub_category_name', 'selectedSubCategory', 'Select Sub Category', '12.5vw', '0.5vw', subCat)}
              </Box>
              {this.props.PageName === 'AssetTransfer' ?
                <Box component={'div'} mt={'0.5vw'}>
                  <Typography fontWeight={600}>Destination</Typography>
                  <Box component={'div'} display={'flex'} mt={'1vw'}>
                    {this.renderAutoComplete(this.state.desBlockList, 'block_name', 'desSelectedBlock', 'Selet Block Name', '12.5vw', '0.5vw')}
                    {this.renderAutoComplete(this.state.desFloorList, 'floor_name', 'desSelectedFloor', 'Select Floor Name', '12.5vw', '0.5vw', floor)}
                  </Box>
                  <Box component={'div'} display={'flex'} mt={'1vw'}>
                    {this.renderAutoComplete(this.state.desSpotList, 'spot_name', 'desSelectedSpot', 'Select Spot Name', '12.5vw', '0.5vw', spot)}
                  </Box>
                </Box>
                : this.props.PageName === 'AssetHome' ?
                  <Box component={'div'}>
                    <Box component={'div'} display={'flex'} mt={'1vw'}>
                      {this.renderAutoComplete(this.state.blockList, 'block_name', 'selectedBlock', 'Selet Block Name', '12.5vw', '0.5vw')}
                      {this.renderAutoComplete(this.state.floorList, 'floor_name', 'selectedFloor', 'Select Floor Name', '12.5vw', '0.5vw', floor1)}
                    </Box>
                    <Box component={'div'} display={'flex'} mt={'1vw'}>
                      {this.renderAutoComplete(this.state.spotList, 'spot_name', 'selectedSpot', 'Select Spot Name', '12.5vw', '0.5vw', spot1)}
                    </Box>
                  </Box>
                  : this.props.PageName === 'AssetComplaint' ?
                    <Box component={'div'}>
                      <Box component={'div'} display={'flex'} mt={'1vw'}>
                        {this.renderAutoComplete(this.state.complaintTypeList, 'label', 'selectedComplaintType', 'Selet Complaint Type', '12.5vw', '0.5vw')}
                        {this.renderAutoComplete(this.state.comStatusList, '', 'selectedStatus', 'Select Complaint Status', '12.5vw', '0.5vw', floor1)}
                      </Box>
                      <Box component={'div'} display={'flex'} mt={'1vw'}>
                        {this.renderTextBox('vendorName', this.state.filterDatas?.vendorName, 'Vendor Name', '25.5vw')}
                      </Box>
                    </Box> : null
              }
            </Box>
          </Box>
          <Box component={"div"} className="eMed_Filter_Btn_Div">
            <Button emed_tid="rpt_flt_clear" size="small" className='eMed_Filter_Btns' disabled={this.state.disableBtn} onClick={() => { this.clearFilterData() }} variant='outlined'>{("Clear")}</Button>
            <Button emed_tid="rpt_flt_search" size="small" className='eMed_Filter_Btns' disabled={this.state.disableBtn} onClick={() => { this.filterSearch() }} variant='contained'>{("Search")}</Button>
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

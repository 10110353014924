import React, { Component } from 'react'
import '../Canteen.css'
import { Box, Button, Paper, Tooltip, Stack, Typography, Drawer, TextField } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';


class CanteenCanceledBill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cancelledBillData: [],
      totalBill: '',
      totalCancellAmt: '',
      page: 0,
      pageSize: 10,
      isLoader: false,
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      BillNumber: '',
      isErrorMsg: false,
      isErrorMsgText: ''
    }
  }

  componentDidMount() {
    this.getCanteenCancelledBill()
  }

  getCanteenCancelledBill = () => {
    try {
      let states = this.state
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_CANCEL + `?from_date=${states.fromDate}&to_date=${states.toDate}&bill_number=${states.BillNumber}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              cancelledBillData: response.data.data,
              totalBill: response.data?.no_of_bills,
              totalCancellAmt: response.data?.total_amount,
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  PrintCancelBill(id) {
    try {
      RestAPIService.PrintPDF(Serviceurls.CANTEEN_BILLING + `?id=${id ? id : null}&export_type=pdf`).
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.fromDate) },
      { label: "To Date", value: formatDate(this.state.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  clearFilter = () => {
    this.setState({
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      BillNumber: '',
    }, () => {
      this.getCanteenCancelledBill()
    })
  }

  searchFilter = () => {
    this.getCanteenCancelledBill()
    this.setState({ filterOpen: false })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box className='eMed_Canteen_FilterBox' >
        <Box className='eMed_CanteenFilter_Head'>
          <Typography variant='h6' fontWeight={600}>Filter</Typography>
          <Tooltip>
            <Button onClick={() => { this.setState({ filterOpen: false }) }}>
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
            </Button>
          </Tooltip>
        </Box>
        <Box className='eMed_Canteen_fil_content'>
          <Box className='eMed_Canteen_Date'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box className='eMed_Canteen_fil_text'>
            <TextField
              className="eMed_Bill_Filter_TextFeild_small"
              inputProps={{ maxLength: 13 }}
              size='small'
              label={t("BillNumber")}
              value={this.state.BillNumber}
              onChange={(e) => {
                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                if (number || e.target.value === "") {
                  this.setState({ BillNumber: e.target.value.toUpperCase() })
                }
              }}
            />
          </Box>
        </Box>
        <Box>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => { this.clearFilter() }}>Clear</Button>
            <Button size='small' variant='contained' className='eMed_Canteen_BtnText' onClick={() => { this.searchFilter() }}>Search</Button>
          </Stack>
        </Box>
      </Box>
    )
  }
  closeFilter = () => {
    this.setState({ filterOpen: false })
  }
  renderTbl = () => {
    const { t } = this.props

    this.state.cancelledBillData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "invoice_date", headerName: "Date", flex: 0.20, align: "center", headerAlign: "center",
        renderCell: (params) => (params?.row?.invoice_date ? params?.row?.invoice_date : '-')
      },
      {
        field: "bill_number", headerName: "Bill Number", flex: 0.15, type: 'number',
        renderCell: (params) => (params?.row?.bill_number ? params?.row?.bill_number : '-')
      },
      {
        field: "total_qty", headerName: "Total Item", flex: 0.15, type: "number",
        renderCell: (params) => (params?.row?.total_qty ? params?.row?.total_qty : '0')
      },
      {
        field: "total_bill", headerName: "Total Bill", flex: 0.15, type: "number",
        renderCell: (params) => (params?.row?.net_total_amount ? params?.row?.net_total_amount : '0')
      },
      {
        field: "reason", headerName: "Reason", flex: 0.20, type: "string",
        renderCell: (params) => (params?.row?.reason?.length > 18 ?
          <Tooltip title={params?.row?.reason} placement='top' arrow><Typography>{params?.row?.reason.slice(0, 18) + '...'}</Typography></Tooltip> :
          <Typography>{params?.row?.reason ? params?.row?.reason : '-'}</Typography>)
      },
      {
        field: "action", headerName: "Action", flex: 0.15, align: "center", headerAlign: "center",
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={"Complete Bill"} placement="top" arrow>
            <Button onClick={() => { this.PrintCancelBill(params.row.id) }} className='eMed_usrconf_btn'>
              <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
        </Box>
      },
    ]
    return (
      <Box height={'64vh'}>
        <DataGrid
          rows={this.state.cancelledBillData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          hideFooterSelectedRowCount
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          headerHeight={40}
          components={{
            Toolbar: this.gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          rowsPerPageOptions={[10, 20, 30]}
          disableSelectionOnClick
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          loading={this.state.isLoader}
        />
      </Box>
    )
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  render() {
    return (
      <Box className='eMed_Canteen_Menuitem_Con' sx={{ backgroundColor: Colors.Background }}>
        <Box className='eMed_Canteen_TopCon'>
          <Box className='eMed_Canteen_CardCon'>
            {AmountsCard('Total Bill', this.state.totalBill ? this.state.totalBill : 0, true, "white", false, null, 'camteen_no_bill')}
            {AmountsCard('Total Cancelled Amount', this.state.totalCancellAmt ? this.state.totalCancellAmt : 0, false, "white", false, null, 'canteen_no_cancellamount')}
          </Box>
          <Box className='eMed_Canteen_FilterCon'>
            <Tooltip title="Filter" placement='top' arrow>
              <Button onClick={() => { this.setState({ filterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_Canteen_img' />
              </Button>
            </Tooltip>
            {/* <Box sx={{ borderRight: '1px solid #616161', height: '2vw', marginBottom: '.4vw' }}></Box>
            <Tooltip title="Print" placement='top' arrow>
              <Button>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_Canteen_img' />
              </Button>
            </Tooltip> */}
          </Box>
        </Box>
        <Paper className='eMed_Canteen_SecondCon' elevation={2}>
          {this.renderTbl()}
        </Paper>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          /> : null}
      </Box>
    )
  }
}
export default withTranslation()(CanteenCanceledBill);
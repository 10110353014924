import { Box, Button, Drawer, Stack, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../../pharmaReports.css';
import { Colors } from '../../../../../Styles/Colors';
import { AmountsCard } from '../../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { formatDate, splitDate } from '../../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../../PharmaFilter';
import { DateTime } from 'luxon';
import Loader from '../../../../../Components/Loader';
import { CurrencySymbol } from '../../../../../Utility/Constants';
class HSNwiseReturnGST extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
            },
            page: 0,
            rowsPerPage: 10,
            hsnreturnGSTData: [],
            taxAmt: 0,
            gstAmt: 0,
            totalCount: 0,
        }
    }

    componentDidMount() {
        this.gethsnreturnGSTReport()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    gethsnreturnGSTReport = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PHARMA_GST_SALES + `?status=hsn_return&from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.rowsPerPage}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            hsnreturnGSTData: response.data.data,
                            taxAmt: response.data.total_taxable_amount,
                            gstAmt: response.data.total_gst_amount,
                            totalCount: response.data.total_count
                        }, ()=>{ this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    gethsnreturnGSTExport = () => {
        try {
            this.setState({disableBtn: true})
            RestAPIService.excelGet(Serviceurls.PHARMA_GST_SALES + `?status=hsn_return&from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.rowsPerPage}&export_type=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response?.headers["content-disposition"]?.split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({disableBtn: false})
        }
    }

    getDaywiseReturnPrint = () => {
        try {
          this.setState({disabled: true}) 
          RestAPIService.getAll(Serviceurls.PHARMA_GST_SALES + `?status=hsn_return&from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.rowsPerPage}&export_type=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disabled: false}) 
            }).catch((error) => {
              if(error?.response?.data?.message){
                this.errorMessage(error?.response?.data?.message)
              }else{
                this.errorMessage(error.message)
              }
              this.setState({disabled: false}) 
            })
        } catch (error) {
          this.errorMessage(error.message)
          this.setState({disabled: false}) 
        }
      }
    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
            page: 0,
        }, () => {
            this.gethsnreturnGSTReport()
        })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }
    renderTable = () => {
        const { t } = this.props
        return (
            <Box Component={'div'}>
                <Paper sx={{ width: '100%', height: '100%' }}>
                    <TableContainer sx={{ height: '55vh' }}>
                        <Table stickyHeader size='small' aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' sx={{ borderRight: '1px solid #CBCBCB' }} rowSpan={2}>{t('HSNcode')}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>0%</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>5%</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>12%</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>18%</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='center' sx={{ borderRight: '1px solid #CBCBCB' }} colSpan={2}>{"Others"}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' rowSpan={2}>{`${t('Totaltacamo') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' rowSpan={2}>{`${t('TotalGSTAmount') + `(${CurrencySymbol})`}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }}>{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }}>{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }} >{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }}>{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }}>{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                    <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.hsnreturnGSTData
                                    // .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((data) => {
                                        return (
                                            <TableRow>
                                                <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{data?.invoice_date?.length > 7 ?
                                                <Tooltip title={data?.invoice_date} placement='top'><Typography>{data.invoice_date ? data.invoice_date.slice(0, 5)+"..." : '-'}</Typography></Tooltip> : 
                                               <Typography>{data.invoice_date ? data.invoice_date : '-'}</Typography>}</TableCell>
                                                <TableCell align='right'>{data.zero_dict.net_amount ? data.zero_dict.net_amount : '-'}</TableCell>
                                                <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{data.zero_dict.gst_amount ? data.zero_dict.gst_amount : '-'}</TableCell>
                                                <TableCell align='right'>{data.five_dict.net_amount ? data.five_dict.net_amount : '-'}</TableCell>
                                                <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{data.five_dict.gst_amount ? data.five_dict.gst_amount : '-'}</TableCell>
                                                <TableCell align='right'>{data.twelve_dict.net_amount ? data.twelve_dict.net_amount : '-'}</TableCell>
                                                <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{data.twelve_dict.gst_amount ? data.twelve_dict.gst_amount : '-'}</TableCell>
                                                <TableCell align='right'>{data.eighteen_dict.net_amount ? data.eighteen_dict.net_amount : '-'}</TableCell>
                                                <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{data.eighteen_dict.gst_amount ? data.eighteen_dict.gst_amount : '-'}</TableCell>
                                                <TableCell align='right'>{data.other_dict?.net_amount ? data.other_dict?.net_amount : '-'}</TableCell>
                                                <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{data.other_dict?.gst_amount ? data.other_dict?.gst_amount : '-'}</TableCell>
                                                <TableCell align='right' >{data.total_taxable_amount ? data.total_taxable_amount : '-'}</TableCell>
                                                <TableCell align='right'>{data.total_gst_amount ? data.total_gst_amount : '-'}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {this.state.hsnreturnGSTData.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={this.state.totalCount}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={(event, newPage) => { this.setState({ page: newPage }, () => { this.gethsnreturnGSTReport() }) }}
                        onRowsPerPageChange={(event) => { this.setState({ rowsPerPage: event.target.value, page: 0 }, () => { this.gethsnreturnGSTReport() }) }}
                    />
                </Paper>
            </Box>
        )
    }

    render() {
        const { t } = this.props
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Total Taxable Amount", this.state.taxAmt, false, "white", false, null, 'hsn_rtn_tax_amt')}
                        {AmountsCard("Total GST Amount", this.state.gstAmt, false, "white", false, null, 'hsn_rtn_gst_amt')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid = "hsn_rtn_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid = "hsn_rtn_print" className='eMed_Pharma_rts_btn' disabled={this.state.disabled} onClick={() => {this.getDaywiseReturnPrint() }}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'}/>
                        </Button>
                        <Button disabled={this.state.disableBtn ? true : this.state.hsnreturnGSTData.length > 0 ? false : true} emed_tid = "hsn_rtn_export" className='eMed_Pharma_rts_btn' onClick={() => { this.gethsnreturnGSTExport()}}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    {this.renderTable()}
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                    <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}

export default withTranslation()(HSNwiseReturnGST)

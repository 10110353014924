import React, { Component } from 'react';
import "./PharmaInventory.css";
import { Box, Button, Stack, Tooltip, Drawer, Typography, TextField, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText, CommonDeleteButton, CommonEditButton, DeletePopup, CommonPopUp, ReasonPopup } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CheckAccessFunc, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import { DrugApproveList } from '../../../Utility/Constants';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import PharmaAddNewDrug from './PharmaAddNewDrug';
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

class PharmaRequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestList: [],
            regStatus: "Pending",
            FromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            ToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            DateIndex: 1,
            drug_name: "",
            showAction: this.props.from === "NewDrugApproval" ? false : true,
            showComments: false,
            deletedPopup: false,
            deleteId: null,
            dltDrug_name: "",
            isEdit: false,
            EditData: {},
            listFor: this.props.from ? this.props.from : "",
            isDecline: false,
            selectedRow: {},
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        }
    }

    componentDidMount() {
        this._getRequestList();
    }

    _getRequestList = () => {
        let states = this.state;
        try {
            this.LoaderFunction(true);
            let drugName = states.drug_name?.replaceAll('&','%26')
            RestAPIService.getAll(`${Serviceurls.PHARMA_ADD_NEW_DRUG}?status=${states.regStatus === "All" ? "" : states.regStatus}&from_date=${states.FromDate}&to_date=${states.ToDate}&drug_name=${drugName}`)
                .then(response => {
                    if (response.data.status === "Success") {

                        this.LoaderFunction(false);
                        let list = "data" in response.data && response.data?.data?.length > 0 ? response.data.data : [];
                        list.length > 0 && list?.forEach((item, i) => {
                            item["s.no"] = i + 1;
                        })

                        this.setState({
                            requestList: list
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status === "Failed") {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FromDate) },
            { label: "To Date", value: formatDate(this.state.ToDate) }
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    // Drawer start

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    FilterDate = (From, To, Index) => {
        this.setState({
            FromDate: From,
            ToDate: To,
            DateIndex: Index,
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button emed_tid = "reqDrug_pop_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter' component={"div"}>
                        <CommonDatePicker
                            FromDate={states.FromDate}
                            ToDate={states.ToDate}
                            DateIndex={states.DateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={t("BrandName")}
                            sx={{ width: "20vw" }}
                            size="small"
                            autoComplete="off"
                            value={states.drug_name ? states.drug_name : ""}
                            inputProps={{emed_tid: "BrandName"}}
                            onChange={(e) => {
                                this.setState({
                                    drug_name: e.target.value
                                })
                            }}
                        />
                    </Box>
                    {states.listFor === "NewDrugApproval" ? null : 
                    <Box className='eMed_rts_dropdown'>
                        <FormControl sx={{ width: "20vw" }} size="small">
                            <InputLabel>{t("SelectStatus")}</InputLabel>
                            <Select
                                value={states.regStatus ? states.regStatus : ""}
                                label={t("SelectStatus")}
                                onChange={(e) => {
                                    let { value } = e.target;
                                    this.setState({
                                        regStatus: value,
                                        showAction: value === "Pending" ? true : false,
                                        showComments: value === "Declined" ? true : false,
                                    })
                                }}
                            >
                                {
                                    DrugApproveList?.length > 0 ? DrugApproveList.map((list, index) => (
                                        <MenuItem emed_tid = {`newDrug_${list.label}`} key={index} value={list.value} >{list.label}</MenuItem>
                                    ))
                                        : null
                                }
                            </Select>
                        </FormControl>
                    </Box>}
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid = "flt_clear"
                        onClick={() => {
                            this.setState({
                                drug_name: "",
                                regStatus: "Pending",
                                showAction: true,
                                showComments: false,
                                FilterOpen: false,
                            }, () => this._getRequestList())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid = "flt_search"
                        onClick={() => {
                            this._getRequestList()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    // Drawer end

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            deleteId: null,
            dltDrug_name: "",
        })
    }

    removeDataList = () => {
        if (this.state.deleteId) {
            let datas = {
                id: this.state.deleteId
            }
            try {
                this.LoaderFunction(true);
                RestAPIService.delete(Serviceurls.PHARMA_ADD_NEW_DRUG, datas)
                    .then(response => {
                        if (response.data.status === "Success") {
                            this.LoaderFunction(false);
                            this.PopupClose();
                            this.successMessage(response.data.message, true);
                            this._getRequestList();
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "Failed") {
                            this.LoaderFunction(false);
                            this.errorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.errorMessage(e.message, false);
            }
        }
    }

    handleEdit = (data) => {
        this.setState({
            isEdit: true,
            EditData: data,
        })
    }

    handleDelete = (data) => {
        if (!this.state.isEdit) {
            this.setState({
                deletedPopup: true,
                deleteId: data.id,
                dltDrug_name: data.drug_name,
            })
        }
    }

    // Update popup start
    updatePopUpClose = () => {
        this.setState({
            isEdit: false,
            EditData: {},
        })
    }

    showAddDrugComp = () => {
        if (Object.keys(this.state.EditData).length > 0) {
            return (
                <PharmaAddNewDrug
                    UpdateData={this.state.EditData}
                    isEdit={this.state.isEdit}
                    popupClose={this.updatePopUpClose.bind(this)}
                    _getRequestList = {this._getRequestList.bind(this)} />
            )
        }
    }
    // Update popup end 

    addCancelComments = (comments) => {
        this.handleDrugApproval('Declined', this.state.selectedRow, comments)
        this.reasonPopupClose();
    }

    handleDrugApproval = (status, row, comments) => {
        if (row) {
            let datas = {
                dosage_strength: row.dosage_strength,
                dosage_type: row.dosage_type,
                drug_name: row.drug_name,
                generic_name: row.generic_name,
                grouping: row.grouping,
                id: row.id,
                schedule: row.schedule,
                status: status,
            }

            if (status === "Declined") {
                datas["comments"] = comments ? comments : "";
            }

            try {
                this.LoaderFunction(true);
                RestAPIService.patch(datas, Serviceurls.PHARMA_ADD_NEW_DRUG)
                    .then(response => {
                        if (response.data.status === "Success") {
                            this.LoaderFunction(false);
                            this.successMessage(response.data.message);
                            this._getRequestList();
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "Failed") {
                            this.LoaderFunction(false);
                            this.errorMessage(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.errorMessage(e.message);
            }
        }
    }

    reasonPopupClose = () => {
        this.setState({
            isDecline: false,
            selectedRow: {},
            dltDrug_name: "",
        })
    }

    generateColumns = () => {
        const { t } = this.props;
        let { showAction, showComments, listFor } = this.state;
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
        const columns = [
            { field: 's.no', headerName: t('SNo'), flex: 0.02, sortable: false, hideable: false },
            {
                field: 'created_date', headerAlign: 'center', headerName: t('Date'), flex: 0.06, hideable: false, align: "center", renderCell: ({ row }) => (
                    row?.created_date?.length > 10 ? <Tooltip placement="top" title={row?.created_date} arrow><div>{row?.created_date.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.created_date ? row.created_date : "-"}</div>
                )
            },
            {
                field: 'drug_name', headerName: t('BrandName'), flex: 0.06, hideable: false, renderCell: ({ row }) => (
                    row?.drug_name?.length > 13 ? <Tooltip placement="top" title={row?.drug_name} arrow><div>{row?.drug_name.slice(0, 12) + "..."}</div></Tooltip>
                        : <div>{row.drug_name ? row.drug_name : "-"}</div>
                )
            },
            {
                field: 'generic_name', headerName: t('GenericName'), flex: 0.07, hideable: false, renderCell: ({ row }) => (
                    row?.generic_name?.length > 13 ? <Tooltip placement="top" title={row?.generic_name} arrow><div>{row?.generic_name.slice(0, 12) + "..."}</div></Tooltip>
                        : <div>{row.generic_name ? row.generic_name : "-"}</div>
                )
            },
            {
                field: 'dosage_strength', headerName: t('DosageStrength'), flex: 0.07, hideable: false, headerAlign: "center", align: "center", renderCell: ({ row }) => (
                    row?.dosage_strength?.length > 13 ? <Tooltip placement="top" title={row?.dosage_strength} arrow><div>{row?.dosage_strength.slice(0, 12) + "..."}</div></Tooltip>
                        : <div>{row.dosage_strength ? row.dosage_strength : "-"}</div>
                )
            },
            {
                field: 'dosage_type', headerName: t('DosageType'), flex: 0.07, hideable: false, renderCell: ({ row }) => (
                    row?.dosage_type?.length > 13 ? <Tooltip placement="top" title={row?.dosage_type} arrow><div>{row?.dosage_type.slice(0, 12) + "..."}</div></Tooltip>
                        : <div>{row.dosage_type ? row.dosage_type : "-"}</div>
                )
            },
            {
                field: 'gst_percentage', headerName: t('GST%'), flex: 0.04, hideable: false, renderCell: ({ row }) => (<div>{row.gst_percentage ? row.gst_percentage : "-"}</div>)
            },
            {
                field: 'manufacturer_name', headerName: t('ManufactureName'), flex: 0.06, hideable: false, renderCell: ({ row }) => (
                    row?.manufacturer_name?.length > 13 ? <Tooltip placement="top" title={row?.manufacturer_name} arrow><div>{row?.manufacturer_name.slice(0, 12) + "..."}</div></Tooltip>
                        : <div>{row.manufacturer_name ? row.manufacturer_name : "-"}</div>
                )
            },
            {
                field: 'hsn_code', headerName: t('HSNCode'), flex: 0.05, hideable: false, renderCell: ({ row }) => (
                    row?.hsn_code?.length > 10 ? <Tooltip placement="top" title={row?.hsn_code} arrow><div>{row?.hsn_code.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.hsn_code ? row.hsn_code : "-"}</div>
                )
            },
            {
                field: 'physical_box_no', headerName: t('PhyBoxNo'), flex: 0.05, renderCell: ({ row }) => (
                    row?.physical_box_no?.length > 10 ? <Tooltip placement="top" title={row?.physical_box_no} arrow><div>{row?.physical_box_no.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.physical_box_no ? row.physical_box_no : "-"}</div>
                )
            },
            {
                field: 'reorder_threshold', headerName: t('ReorderThreshold'), flex: 0.05, renderCell: ({ row }) => (
                    row?.reorder_threshold?.length > 10 ? <Tooltip placement="top" title={row?.reorder_threshold} arrow><div>{row?.reorder_threshold.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.reorder_threshold ? row.reorder_threshold : "-"}</div>
                )
            },
            {
                field: 'expiry_alert_period', headerName: t('ExpiryPeriod'), flex: 0.05, renderCell: ({ row }) => {
                    let expiry_alert_period = row?.expiry_alert_period !== 0 ? row?.expiry_alert_period === 1 ? `${row?.expiry_alert_period} month` : `${row?.expiry_alert_period } months` : "";
                    return (
                        expiry_alert_period?.length > 10 ? <Tooltip placement="top" title={expiry_alert_period} arrow><div>{expiry_alert_period.slice(0, 8) + "..."}</div></Tooltip>
                            : <div>{expiry_alert_period ? expiry_alert_period : "-"}</div>)
                }
            },
            {
                field: 'recommended_by', headerName: t('RecommendedBy'), flex: 0.05, hideable: false, renderCell: ({ row }) => (
                    row?.recommended_by?.length > 10 ? <Tooltip placement="top" title={row?.recommended_by} arrow><div>{row?.recommended_by.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.recommended_by ? row.recommended_by : "-"}</div>)
            },
            {
                field: 'bar_code', headerName: t('UPC'), flex: 0.05, renderCell: ({ row }) => (
                    row?.bar_code?.length > 10 ? <Tooltip placement="top" title={row?.bar_code} arrow><div>{row?.bar_code.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.bar_code ? row.bar_code : "-"}</div>)
            },
            {
                field: 'grouping', headerName: t('Group'), flex: 0.05, renderCell: ({ row }) => (
                    row?.grouping?.length > 10 ? <Tooltip placement="top" title={row?.grouping} arrow><div>{row?.grouping.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.grouping ? row.grouping : "-"}</div>)
            },
            {
                field: 'schedule', headerName: t('Schedule'), flex: 0.05, renderCell: ({ row }) => (
                    row?.schedule?.length > 10 ? <Tooltip placement="top" title={row?.schedule} arrow><div>{row?.schedule.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.schedule ? row.schedule : "-"}</div>)
            }
        ]

        if (showAction || listFor === "NewDrugApproval") {
            columns.push(
                {
                    field: 'actions', headerName: t('Action'), type: 'actions', flex: 0.05, sortable: false, hideable: false,
                    renderCell: ({ row }) => {
                        return (
                            showAction ?
                                <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
                                    <CommonEditButton testID = {"drugReg_edit"} size="1.5vw" onClick={this.handleEdit.bind(this, row)} />
                                    <CommonDeleteButton testID = {"drugReg_dlt"} size="1.5vw" onClick={this.handleDelete.bind(this, row)} />
                                </Stack> :
                                listFor === "NewDrugApproval" ?
                                    <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
                                        <Tooltip title={t("Approve")} placement='top' arrow>
                                            <IconButton disabled={!CheckAccessFunc(pharmacy_type, "Settings", "New Drug Approval",  null, "Tab")?.editAccess} emed_tid = "newDrug_approve" size="small" onClick={() => this.handleDrugApproval("Accepted", row)}> <img className="eMed_IP_Exp_printimg" src={ImagePaths.Check.default} alt="Approve" /></IconButton>
                                        </Tooltip>
                                        <Tooltip title={t("Decline")} placement='top' arrow>
                                            <IconButton disabled={!CheckAccessFunc(pharmacy_type, "Settings", "New Drug Approval",  null, "Tab")?.editAccess} emed_tid = "newDrug_decline" size="small" onClick={() => this.setState(
                                                {
                                                    isDecline: true,
                                                    selectedRow: row,
                                                    dltDrug_name: row.drug_name
                                                })}> <img className="eMed_IP_Exp_printimg" src={ImagePaths.CrossIcon.default} alt="Decline" /></IconButton>
                                        </Tooltip>
                                    </Stack>
                                    : null
                        );
                    }
                }
            );
        }

        if (showComments) {
            columns.push(
                {
                    field: 'comments', headerName: t('Comments'), flex: 0.05, sortable: false, hideable: false,
                    renderCell: ({ row }) => (row?.comments?.length > 10 ? <Tooltip placement="top" title={row?.comments} arrow><div>{row?.comments.slice(0, 8) + "..."}</div></Tooltip>
                        : <div>{row.comments ? row.comments : "-"}</div>)
                }
            );
        }

        return columns;
    };

    render() {

        const { t } = this.props;
        let states = this.state;

        return (
            <Box component={'div'} className='eMed_rts_container'>
                <div className='eMed_Purchase_Table_top'>
                    <Tooltip title="Filter" placement='top' arrow>
                        <Button emed_tid = "reqDrug_flt" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                </div>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={states.requestList}
                        columns={this.generateColumns()}
                        getRowId={(row) => row.id}
                        page={states.page}
                        pageSize={states.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    physical_box_no: false,
                                    reorder_threshold: false,
                                    expiry_alert_period: false,
                                    recommended_by: false,
                                    bar_code: false,
                                    grouping: false,
                                    schedule: false
                                },
                            },
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {
                    this.state.isEdit ?
                        <CommonPopUp width={"80vw"}
                            testID = {"uptDrug"}
                            popUpClose={this.updatePopUpClose.bind(this)}
                            title={t("Update Drug")}
                            component={this.showAddDrugComp.bind(this)} />
                        : null
                }
                {this.state.deletedPopup ?
                    <DeletePopup testID = {"rmvDrug"}
                        DeletTitle={`${t("deleteMsg")}  ${states.dltDrug_name ? states.dltDrug_name : "-"} ?`}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {
                    this.state.isDecline && Object.keys(this.state.selectedRow).length > 0 ? <ReasonPopup
                        testID = {"DeclineDrug"}
                        title={`${t("DeclineDrug")} ${this.state.dltDrug_name}`}
                        AlertPopupClose={this.reasonPopupClose.bind(this)}
                        label={"Enter the Reason"}
                        btntext={'Cancel'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        sendCmt={this.addCancelComments.bind(this)} /> : null
                }
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaRequestList)

import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, IconButton, Grid, Autocomplete } from "@mui/material";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
class Specialization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specDetails: [],
            spcInfo: [],
            specialization_id: null,
            specialization: "",
            selectedIndex: -1,
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            disableBtn: false,
        }
    }
    componentDidMount() {
        this._getSpecializationDetails();
        this._getSpecInfo();
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getSpecializationDetails = () => {
        this.setState({
            specDetails: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            specDetails: response.data.data,
                        }, () => {
                            this.state.specDetails.forEach((item, i) => {
                                item.index = i
                            })
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    _postActiveStatus(spcl_id, active_status) {
        try {
            this.setState({disableBtn: true})
            RestAPIService.delete(`${Serviceurls.FO_CONFIG_OPSPECIALIZATION}?spcl_id=${spcl_id}&active_status=${active_status}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.responseSuccessErrorMessage(response.data.message, true);
                        this._getSpecializationDetails();
                        this.setState({disableBtn: false})
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.setState({disableBtn: false})
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                    this.setState({disableBtn: false})
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    _getSpecInfo = () => {
        this.setState({
            spcInfo: []
        })
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPLGET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            spcInfo: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    renderTextBox(label, statekey, value, width) {
        const { t } = this.props;
        let {spcInfo} = this.state;

        return (
            <div id="eMed_srvConfig_divs">
                <Autocomplete
                    disableClearable
                    value={value ? value : ""}
                    sx={{ width: width ? width : "29.8vw" }}
                    size = "small"
                    options={spcInfo.length != 0 ? spcInfo : []}
                    getOptionLabel={option => typeof (option) === "string" ? option : option.name}
                    onChange={(event, newSpec) => {
                        this.setState({
                            specialization: newSpec.name,
                            specialization_id: newSpec.id
                        })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label) + " *"}
                            name={statekey}
                            InputProps={{
                                ...params.InputProps
                            }}
                        />
                    )}
                />
            </div>
        )
    }
    handleClear = () => {
        this.setState({
            specialization: "",
            specialization_id: null
        })
    }
    handleSave = () => {
        const { t } = this.props
        let { specialization, specialization_id } = this.state;
        let isAvl = true;
        let Message = "";
        if (!specialization) { isAvl = false; Message = "Fillmandatory" }
        if (isAvl) {
            // api call
            let datas = {
                "id": specialization_id,
                "name": specialization
            }
            try {
                this.LoaderFunction(true)
                this.setState({disableBtn: true})
                RestAPIService.create(datas, Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.handleClear();
                            this._getSpecializationDetails();
                            this.LoaderFunction(false)
                            this.setState({disableBtn: false})
                            this.responseSuccessErrorMessage(response.data.message, true);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.setState({disableBtn: false})
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage(t(Message), false)
        }
    }
    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
      }
    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, specDetails, specialization } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.12, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'name', headerName: t('Specialization'), flex: 0.68 },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.20, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
                    let value = cellvalues.row;
                    return (
                        <Tooltip placement="top" title={value.is_active ? t("Active") : t("InActive")} arrow>
                            <IconButton size="small"
                            disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Specialization", "SubTab")?.editAccess}
                            onClick={() => {
                                specDetails[value.index]["is_active"] = !(specDetails[value.index]["is_active"])
                                this.setState({ specDetails }, () => { this._postActiveStatus(this.state.specDetails[value.index]["id"], this.state.specDetails[value.index]["is_active"]) })
                            }}><img id="eMed_activeBtn" src={
                                CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Specialization", "SubTab")?.editAccess ?
                                (value.is_active ? ImagePaths.ActiveBox.default : ImagePaths.InActiveBox.default) : ImagePaths.DisabledActiveBox.default} alt= {value.is_active ? t("Active") : t("InActive")} /></IconButton>
                        </Tooltip>
                    )
                }
            }
        ]
        return (
            <Box  id="eMed_srvConfig_Container" sx={{backgroundColor: Colors.configHomeBg}}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={specDetails}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                headerHeight = {40}
                                rowCount={specDetails.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t('Specialization')}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv">
                                {this.renderTextBox('Specialization', "specialization", specialization)}
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Specialization", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" emed_tid="Specializaton_Save_Btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(Specialization);
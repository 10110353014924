import React, { Component } from 'react'
import { CommonDeleteButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, TextField, Tooltip } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';

class DocDiagnosisLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            DiagnosisData: [],
            isDeleteCliked: false,
            SelectedList: {},
            PatientData: this.props?.PatientData,
            ProvDiagonsis : ""
        }
    }

    componentDidUpdate(prevprops) {
        if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetDiagnosisData() }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    GetDiagnosisData() {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT + `?patient_id=${this.state.PatientData?.patient_id}&appointment_id=${this.state.PatientData?.appointment_id}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DiagnosisData: response.data.data?.icd_codes ? response.data.data?.icd_codes : [],
                            ProvDiagonsis : response.data.data?.provisional_diagnosis ? response.data.data?.provisional_diagnosis : ""
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    onDeleteHandler(data) {
        try {
            this.setState({
                isDeleteCliked: true,
                SelectedList: data.row,
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose() {
        this.setState({ isDeleteCliked: false })
    }
    DeleteExamination(fromprovDiag = false) {
        try {
            var states = this.state
            let ApiUrl = ''
            if(fromprovDiag){
                ApiUrl = Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT + `?provisional_diagnosis=true&patient_id=${this.state.PatientData?.patient_id}&appointment_id=${this.state.PatientData?.appointment_id}`
            }else{
                ApiUrl = Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT + "?id=" + states.SelectedList.id
            }
            RestAPIService.delete(ApiUrl).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({ isDeleteCliked: false }, () => { this.GetDiagnosisData() })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        try {
            const { t } = this.props
            this.state.DiagnosisData.forEach((element, index) => { element.sno = index + 1 })
            const columns = [
                {
                    field: "icd_code", flex: 0.10, headerName: t("ICDcode"),
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.icd_code ? params?.row?.icd_code?.length > 18 ?
                            <Tooltip placement='top' title={params?.row?.icd_code} arrow>
                                <div>{params?.row?.icd_code.slice(0, 18) + "..."}</div></Tooltip> :
                            params?.row?.icd_code : "-"}
                    </Box>)
                },
                {
                    field: "short_desc", flex: 0.25, headerName: t("ShortDescription"),
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.short_desc ? params?.row?.short_desc?.length > 30 ?
                            <Tooltip placement='top' title={params?.row?.short_desc} arrow>
                                <div>{params?.row?.short_desc.slice(0, 30) + "..."}</div></Tooltip> :
                            params?.row?.short_desc : "-"}
                    </Box>)
                },
                {
                    field: "desc", flex: 0.25, headerName: t("LongDescription"),
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.desc ? params?.row?.desc?.length > 30 ?
                            <Tooltip placement='top' title={params?.row?.desc} arrow>
                                <div>{params?.row?.desc.slice(0, 30) + "..."}</div></Tooltip> :
                            params?.row?.desc : "-"}
                    </Box>)
                },
                {
                    field: "action", flex: 0.10, headerName: t("Action"), headerAlign: "center", align: "center",
                    renderCell: (params) => <div>
                        <CommonDeleteButton size={"1.2vw"} disable={this.props?.isLocked} onClick={this.onDeleteHandler.bind(this, params)} />
                    </div>
                }
            ]
            return (
                <ShowComponents onIntersection={this.GetDiagnosisData.bind(this)}>
                    <Box height={'20vh'} overflow={'auto'}>
                        <DataGrid
                            density='compact'
                            rows={this.state.DiagnosisData}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            headerHeight={40}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_DocNotes_nodata'>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            disableSelectionOnClick
                        />
                        {this.state.isErrorMsg ?
                            <ToastMsg
                                severity={'error'}
                                msg={this.state.isErrorMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null}
                        {this.state.successMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.successMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null}
                        {this.state.isDeleteCliked ? <DeletePopup
                            DeletTitle={`${t("deleteMsg")}  ${t("Diagnosis")} ${this.state.SelectedList ? this.state.SelectedList?.icd_code : "-"} ?`}
                            deleteAlertPopupClose={this.popupClose.bind(this)}
                            removeData={this.DeleteExamination.bind(this)}
                        // DeleteNotify={t("effectEntireSetting")}
                        /> : null}
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <TextField
                            size='small'
                            sx={{ width: '54vw', margin: '1vw' }}
                            label={t("Provisional Diagnosis")}
                            value={this.state.ProvDiagonsis}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <CommonDeleteButton size={"1.5vw"} disable={this.props?.isLocked || !this.state.ProvDiagonsis} onClick={this.DeleteExamination.bind(this, true)} />
                    </Box>
                </ShowComponents>
            )
        }
        catch (e) {
            console.log(e.message);
        }
    }
}
export default withTranslation()(DocDiagnosisLeft)


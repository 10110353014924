import { Box, Button, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, CommonPopUp, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../../src/Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../src/Utility/API/Serviceurls';
import { FloorCountDropdown } from '../../../Utility/Constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

const FloorCountList = ["0",];
for (let i = 1; i <= FloorCountDropdown; i++) { FloorCountList.push(i) }
class HospitalConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      BlocksList: [],
      ref: props.location?.pathname,

      BlockName: "",
      FloorCount: "",
      isEditCliked: false,
      isDeleteCliked: false,
      SelectedList: null,
      isHistoryClicked: false,
      SelectedHistory: []
    }
  }

  componentDidMount() {
    this.GetBlocksList()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  GetBlocksList() {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.HOSPITAL_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              BlocksList: response.data.data
            },()=>{this.LoaderFunction(false)})
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      this.LoaderFunction(false)
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }


  PostBlock = () => {
    try {
      var states = this.state
      var data = {
        "block_name": CommonValidation.removeSpace(states.BlockName),
        "floor": states.FloorCount,
      }
      if (states.isEditCliked) { data["config_id"] = states.SelectedList.id }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.HOSPITAL_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.GetBlocksList()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  ClearData() {
    this.setState({
      BlockName: "",
      FloorCount: "",
      isEditCliked: false,
      isDeleteCliked: false,
      SelectedList: {},
    })
  }

  onEditHandler = (value) => {
    try {
      this.setState({
        isEditCliked: true,
        BlockName: value.row.block_name,
        FloorCount: value.row.floor_number,
        SelectedList: value.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (value) => {
    try {
      if (!this.state.isEditCliked) {
        this.setState({
          isDeleteCliked: true,
          SelectedList: value.row,
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetHistoryData = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.HISTORY_DATA + "?condition=hospital" + "&history_id=" + id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SelectedHistory: response.data.data,
              isHistoryClicked: true,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => { this.GetHistoryData(data.row.id) }) }}>
              {t("History")}
            </MenuItem>
          </Menu> : null}
      </div>
    )
  }

  _getHistory(historydata) {
    historydata.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);
    const historyColumn = [
      { field: "sno", sortable: false, flex: 0.08, headerName: t("SNo") },
      {
        field: "user", flex: 0.21, headerName: t("User"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.user ? params?.row?.user : "-"}</Box>)
      },
      {
        field: "modified_date", flex: 0.166, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date?.length > 20 ? 
        <Tooltip placement="top" title={params?.row?.modified_date} arrow><div>{params?.row?.modified_date.slice(0, 18) + "..."}</div></Tooltip> 
        : params?.row?.modified_date ? params?.row?.modified_date : "-"}</Box>)
      },
      {
        field: "block_name_from", flex: 0.21, headerName: t("PreviousBlock"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.block_name_from ? params?.row?.block_name_from : "-"}</Box>)
      },
      {
        field: "block_name_to", flex: 0.166, headerName: t("ChangedBlock"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.block_name_to ? params?.row?.block_name_to : "-"}</Box>)
      },
      {
        field: "floor_number_from", flex: 0.21, headerName: t("PreviousFloor"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.floor_number_from ? params?.row?.floor_number_from : "-"}</Box>)
      },
      {
        field: "floor_number_to", flex: 0.166, headerName: t("ChangedFloor"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.floor_number_to ? params?.row?.floor_number_to : "-"}</Box>)
      },
    ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          disableSelectionOnClick
        />
      </Box>
    )
  }

  renderCorporateLeft = () => {
    try {
      this.state.BlocksList.forEach((element, index) => { element.sno = index + 1 })
      const { t } = this.props
      const columns = [
        { field: "sno", sortable: false, flex: 0.08, headerName: t("SNo") },
        {
          field: "block_name", flex: 0.21, headerName: t("BlockName"),
          renderCell: (params) => (<Box component={'div'}>{params?.row?.block_name ? params?.row?.block_name : "-"}</Box>)
        },
        {
          field: "floor_number", flex: 0.166, headerName: t("NoofFloor"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>{(params?.row?.floor_number || params?.row?.floor_number === 0 ) ? params?.row?.floor_number : "-"}</Box>)
        },
        {
          field: "action", flex: 0.166, headerName: t("Action"), headerAlign: "center", align: "center",  sortable: false, hideable: false,
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Block & Floor Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
            <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Block & Floor Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
            {this.MenuItem(params)}
          </Box>
        }
      ]
      return (
        <Stack component={"div"}>
          <Paper className='eMed_common_Tbl_left' >
            <DataGrid
              rows={this.state.BlocksList}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Paper>
        </Stack>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  validation() {
    try {
      var states = this.state;
      let SameBlockName = [];
      SameBlockName = states.BlocksList.filter(list => list['block_name'].toLowerCase() === states.BlockName.toLowerCase());
      var { t } = this.props;
      if (
        CommonValidation.removeSpace(states.BlockName) !== "" &&
        states.FloorCount !== "" &&
        (SameBlockName.length === 0 || (SameBlockName.length == 1 && SameBlockName[0].id === states.SelectedList?.id))) {
        this.PostBlock()
      }
      else {
        if (CommonValidation.removeSpace(states.BlockName) === "") {
          this.errorMessage(t("EnterBlockName"))
        }
        else if (states.FloorCount === "") {
          this.errorMessage(t("SelectFloorCount"))
        }
        else if (SameBlockName.length > 0) {
          this.errorMessage(t("BlockExists"))
        }

      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderCorporateRight = () => {
    try {
      const { t } = this.props
      return (
        <Stack component={"div"}>
          <Paper className='eMed_common_Tbl_right' elevation={2}>
            <Typography className='eMed_refdoc_title'>{t("HospConfig")}</Typography>
            <Grid container className='eMed_corpconfig_txt_card' sx={{ height: "61vh" }}>
              <Grid item xs={6}>
                <TextField
                  inputProps={{ maxLength: 30 }}
                  className='eMed_small_textFields'
                  size='small'
                  label={t("BlockName") + " *"}
                  value={this.state.BlockName}
                  autoComplete="off"
                  onChange={(e) => {
                    this.setState({
                      BlockName: e.target.value
                    })

                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl size='small' className='eMed_small_textFields'>
                  <InputLabel>{t("NoofFloor")}</InputLabel>
                  <Select
                    value={this.state.FloorCount}
                    label={t("NoofFloor")}
                    onChange={(e) => { this.setState({ FloorCount: e.target.value }) }}
                    MenuProps={{
                      style: { maxHeight: 350 }
                    }}
                  >
                    {
                      FloorCountList?.length > 0 ?
                        FloorCountList.map((list, index) => (
                          <MenuItem key={index} value={list}>{list}</MenuItem>
                        )) : null
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"} marginTop={"1.2vw"}>
              <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
              <Button disabled={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Block & Floor Configuration", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.validation() }}>{t("AddBlock")}</Button>
            </Stack>
          </Paper>
        </Stack>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  popupClose() {
    this.setState({
      isDeleteCliked: false
    })
  }
  DelectBlocks() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.HOSPITAL_CONFIG + "?config_id=" + states.SelectedList.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.ClearData()
            this.GetBlocksList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }
  historyPopUpClose() {
    this.setState({
      isHistoryClicked: false,
      SelectedHistory: []
    })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    let { t } = this.props
    try {
      return (
        <Box component={"div"} className='eMed_common_container'>
          <Grid container className='eMed_refdoc_content' >
            <Grid item xs={7.5}>
              {this.renderCorporateLeft()}
            </Grid>
            <Grid item xs={4.5}>
              {this.renderCorporateRight()}
            </Grid>
          </Grid>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} Block ${this.state.SelectedList ? this.state.SelectedList?.block_name : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DelectBlocks.bind(this)}
            DeleteNotify={t("effectEntireSetting")}
          /> : null}
          {(this.state.isHistoryClicked) ?
            <CommonPopUp width={"75vw"}
              backgroundColor={Colors.white}
              popUpClose={this.historyPopUpClose.bind(this)}
              title={t("History")}
              component={this._getHistory.bind(this, this.state.SelectedHistory)} />
            : null
          }
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
}

export default withTranslation()(HospitalConfig)
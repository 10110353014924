import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import './purchase.css';
import { CommonNavigationHeader } from "../../../Components/Common Components/CommonComponents";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import WantedList from "./WantedList";
import PurchaseOrder from "./PurchaseOrder";
import Vendor from "./Vendor";
import VendorPayment from "./VendorPayment";
import DirectPo from "./DirectPo";
import OrderList from "./OrderList";
import VendorMonthly from "./VendorMonthly";
import ConfirmOrderList from "./ConfirmOrderList";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";

const navigationHeaderList = [
    { id: 0, TabName: "Wanted List", value: "wanted_list" },
    { id: 1, TabName: "Purchase Order", value: "purchase_order" },
    { id: 2, TabName: "Vendor Payment", value: "vendorPayment"},
    { id: 3, TabName: "Vendor List", value: "vendor" },
    
]

class LabPurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: "wanted_list",
            vendor_id: null,
            purchase_order_id: null,
            purchase_status: "",
            common_search: '',
            wantedList_search_key: '',
            orderList_search_key: '',
            purchaseOrder_search_key: '',
            vendorPayment_search_key: '',
            vendorList_search_key: '',
            screenType:'',
            showSuccessResponse: false,
            showErrorResponse:false ,
            responseMessage: ""
        }
    }

    componentDidMount(){
        this.props.changeScreen('4')
        this.props.subScreenChange("wanted_list")
    }

    componentWillReceiveProps(props) {
        var { selectedScreen } = this.state
        if (props.search != this.state.common_search) {
            this.setState({
                common_search: props.search
            })
            if (selectedScreen === "wanted_list") {
                this.setState({
                    wantedList_search_key: props.search
                })
            } else if (selectedScreen === "order_List") {
                this.setState({
                    orderList_search_key: props.search
                })
            } else if (selectedScreen === "purchase_order") {
                this.setState({
                    purchaseOrder_search_key: props.search
                })
            } else if (selectedScreen === "vendor") {
                this.setState({
                    vendorList_search_key: props.search
                })
            } else if (selectedScreen === "vendorPayment") {
                this.setState({
                    vendorPayment_search_key: props.search
                })
            }
        }
    }

    NavigateScreen(id) {
        this.setState({
            selectedScreen: navigationHeaderList[id].value
        })
        this.props.clrSearch()
        this.props.subScreenChange(navigationHeaderList[id].value)
    }

    goBack(pagename, id, purchase_order_id, purchase_status, pageType){
        this.props.subScreenChange(pageType === "directPo" ? "directPo" : "");
        this.setState({
            selectedScreen: pagename,
            vendor_id: id,
            purchase_order_id: purchase_order_id ,
            purchase_status: purchase_status,
            screenType: pageType,
        })
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse:false ,
            responseMessage: ""
        })
    }

    render() {
        let {showSuccessResponse, showErrorResponse, responseMessage } = this.state
        return (
            <Box className = "emedhub_Purchasecontainer">
                <CommonNavigationHeader menu={navigationHeaderList} NavigateScreen={this.NavigateScreen.bind(this) } />
                <div className = "emedhub_purchase_contentContainer">

                    {
                        (this.state.selectedScreen === "wanted_list" && CheckAccessFunc("Laboratory", "Purchase", "Wanted List", null, "Tab")?.viewAccess) ? <WantedList NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} search_key={this.state.wantedList_search_key} /> :
                            this.state.selectedScreen === "order_List" ? <OrderList NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} search_key={this.state.orderList_search_key} /> :
                                this.state.selectedScreen === "confirmOrder_list" ? <ConfirmOrderList NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} vendor_id={this.state.vendor_id} /> :
                                    (this.state.selectedScreen === "purchase_order"&& CheckAccessFunc("Laboratory", "Purchase", "Purchase Order" , null, "Tab")?.viewAccess) ? <PurchaseOrder NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} search_key={this.state.purchaseOrder_search_key} /> :
                                        this.state.selectedScreen === "directPo" ? <DirectPo NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} purchase_order_id={this.state.purchase_order_id} purchase_status={this.state.purchase_status} screenType={this.state.screenType} /> :
                                            (this.state.selectedScreen === "vendor" && CheckAccessFunc("Laboratory", "Purchase", "Vender List", null, "Tab")?.viewAccess) ? <Vendor NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} search_key={this.state.vendorList_search_key} /> :
                                                (this.state.selectedScreen === "vendorPayment" && CheckAccessFunc("Laboratory", "Purchase", "Vendor Payment", null, "Tab")?.viewAccess) ? <VendorPayment NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)}  search_key={this.state.vendorPayment_search_key} /> :
                                                    (this.state.selectedScreen === "vendorMonthly") ? <VendorMonthly NavigateScreen={this.NavigateScreen.bind(this)} goBack={this.goBack.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} vendor_id={this.state.vendor_id} /> :
                                                    <Typography className='eMed_NoAccess_Text' sx={{paddingTop:"13vw"}}>No Access For This Screen</Typography>}
                </div>
                {
                    showSuccessResponse ? <ToastMsg severity = {"success"} msg = {responseMessage}  msgPop={this.msgClose.bind(this)}/> : null
                }
                { 
                    showErrorResponse ? <ToastMsg severity = {"error"} msg = {responseMessage}  msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}

export default LabPurchase;
import { Autocomplete, Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions';

const genderList = [
    { label: "Male", value: "m" },
    { label: "Female", value: "f" },
    { label: "TransGender", value: "t" }
]
class DemographicsReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            patientName: "",
            gender: [],
            appointmentDate: null,
            dateIndex: 1,
            patientData: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
            },
            timeError: false,
        }
    }

    componentDidMount() {
        this.getPatientData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    getPatientData = () => {
        try {
            this.LoaderFunction(true)
            let appointmentDate = this.state.appointmentDate === null ? "" : this.state.appointmentDate
            let genderSearch = [];
            this.state.gender.forEach(element => { genderSearch.push(element.value) });
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_DEMOGRAPHICS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_name=${this.state.patientName}&appointment_date=${appointmentDate}&gender=${genderSearch}&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            patientData: response.data.data,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    printReports = () => {
        try {
            let appointmentDate = this.state.appointmentDate === null ? "" : this.state.appointmentDate
            let genderSearch = [];
            this.state.gender.forEach(element => { genderSearch.push(element.value) });
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_DEMOGRAPHICS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_name=${this.state.patientName}&appointment_date=${appointmentDate}&gender=${genderSearch}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }
    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} 
                            HideAllMenu={true}/>
                    </Box>
                    <Box sx={{width: '22vw'}}>
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        FromDate={this.state.fromDate}
                        ToDate={this.state.toDate}
                        onError={this.state.FilterDatas.onError}
                    />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            size='small'
                            fullWidth
                            label="Patient Name"
                            value={this.state.patientName}
                            name="patientName"
                            onChange={(e) => { this.onChangeHandler(e) }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                inputFormat='DD-MM-YYYY'
                                label={t('SelectAppointmentDate')}
                                value={this.state.appointmentDate}
                                name="appointmentDate"
                                onChange={(newDate) => { 
                                    if (newDate == null) {
                                        this.setState({
                                            appointmentDate: null
                                        });
                                    } else {
                                        this.setState({
                                            appointmentDate: DateTime.fromJSDate(newDate?.$d).toFormat('yyyy-MM-dd')
                                        });
                                    }
                                 }}
                                renderInput={(params) => <TextField style={{ width: '20vw' }} size='small' {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={genderList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.label)}
                            value={this.state.gender}
                            onChange={(event, newValue) => {
                                this.setState({ gender: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("selectGender")}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                patientName: "", appointmentDate: null,
                                gender: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime":null,
                                    "endTime":null,
                                    "onError": false
                                },
                                timeError: false,
                            }, () => this.getPatientData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                    disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getPatientData()
                            this.setState({
                                FilterOpen: false
                            })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
            {label:"To Date", value:formatDate(this.state.FilteredToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return(
          <Box>
             <CommonGridToolBarWithFilterText FilterTextArray={TextArray}/>
          </Box>
    
        )
      }
    render() {
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("PatientName"), flex: 0.1,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_name && params?.row?.patient_name?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.patient_name} arrow><div>{params?.row?.patient_name.slice(0, 13) + "..."}</div></Tooltip> : params?.row?.patient_name ? params?.row?.patient_name: "-"}</Box>)
            },
            {
                field: "patient_account_number", headerName: t("UHID"), flex: 0.1, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_account_number && params?.row?.patient_account_number?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.patient_account_number} arrow><div>{params?.row?.patient_account_number.slice(0, 13) + "..."}</div></Tooltip> : params?.row?.patient_account_number ? params?.row?.patient_account_number : "-"}</Box>)
            },
            {
                field: "patient_age", headerName: t("Age/Gender"), flex: 0.1, align: "center", headerAlign: "center",
                renderCell: (params) => {
                    let details = `${params?.row?.patient_age ? params?.row?.patient_age : "-"} / ${params?.row?.gender ? params?.row?.gender : "-"}`;
                    return(<Box component={'div'}>{details?.length > 15 ? <Tooltip placement='top' title={details} arrow><div>{details.slice(0, 13) + "..."}</div></Tooltip> : details ? details : "-"}</Box>)}
            },
            {
                field: "dob", headerName: t("DOB"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.dob ? formatDate(params?.row?.dob) : "-"}</Box>)
            },
            {
                field: "patient_mobile_number", headerName: t("MobileNumber"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_mobile_number ? params?.row?.patient_mobile_number : "-"}</Box>)
            },
            {
                field: "register_date", headerName: t("AppointmentDate"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.register_date ? formatDate(params?.row?.register_date) : "-"}</Box>)
            },
            {
                field: "marital_status", headerName: t("MaritalStatus"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.marital_status?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.marital_status} arrow><div>{params?.row?.marital_status.slice(0, 13) + "..."}</div></Tooltip>
                    : params?.row?.marital_status ? params?.row?.marital_status : "-"}</Box>)
            },
            {
                field: "email", headerName: t("Emailid"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.email?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.email} arrow><div>{params?.row?.email.slice(0, 13) + "..."}</div></Tooltip>
                    : params?.row?.email ? params?.row?.email : "-"}</Box>)
            },
            {
                field: "address", headerName: t("Address"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let address = `${params?.row?.address_line_1 ? params?.row?.address_line_1 : "-"} | ${params?.row?.address_line_2 ? params?.row?.address_line_2 : "-"}`
                    return (<Box component={'div'}>{address?.length > 15 ?
                        <Tooltip placement='top' title={address} arrow><div>{address.slice(0, 10) + "..."}</div></Tooltip>
                        : address ? address : "-"}</Box>)
                }
            },
            {
                field: "occupation", headerName: t("Occupation"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.occupation?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.occupation} arrow><div>{params?.row?.occupation.slice(0, 13) + "..."}</div></Tooltip>
                    : params?.row?.occupation ? params?.row?.occupation : "-"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'></Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.patientData}
                        columns={columns}
                        getRowId={(row) => row.patient_id}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(DemographicsReport)
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Colors } from '../../../../Styles/Colors'
import { CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import VisibilityIcon from '@mui/icons-material/Visibility';
import {localGetItem } from '../../../../Utility/Services/CacheProviderService';

class CopyDisSumPop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            AdmDiagSearch: "",
            DisDiagSearch: "",
            PatientKeySearch: "",
            DisSumDataList: [],
            SelectedDisSum: [],
            allChecked: true,
            disableBtn: false,
            isLoader: false,
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    GetDissumDatas() {
        try {
            const clinic_id = JSON.parse(localGetItem('SelectedDoctorClinicId'));
            let url = `${Serviceurls.IP_DIS_SUM_COPY_DATA}?search_key=${this.state.PatientKeySearch}&admission_diagnosis=${this.state.AdmDiagSearch}&discharge_diagnosis=${this.state.DisDiagSearch}`;
            if (clinic_id) {
                url = `${url}&clinic_id=${clinic_id}`
            }
            this.setState({ disableBtn: true, isLoader: true })
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DisSumDataList: response.data.data,
                            disableBtn: false,
                            isLoader: false,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                        this.setState({ disableBtn: false, isLoader: false })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({ disableBtn: false, isLoader: false })
                })
        }
        catch (e) {
            this.errorMessage(e.message)
            this.setState({ disableBtn: false, isLoader: false })
        }
    }

    restructureData(Data = {}) {
        try {
            let Keys = Object.keys(Data?.template_data ? Data?.template_data : {})
            let DisSumLayout = this.props?.disSumLayout ? this.props?.disSumLayout : {}
            if ('admission_diagnosis' in DisSumLayout) {
                DisSumLayout["provisional_diagnosis"] = ""
            }
            if ("discharge_diagnosis" in DisSumLayout) {
                DisSumLayout["discharge_provisional_diagnosis"] = ""
            }
            let newData = []
            if (Keys.length > 0) {
                Keys.forEach((key) => {
                    if (key in DisSumLayout) {
                        newData.push({
                            key_name: key,
                            key_value: Data?.template_data[key],
                            isChecked: true,
                        })
                    }
                })
            }
            this.setState({
                SelectedDisSum: newData
            })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleChecked = (item, e) => {
        let states = this.state
        item["isChecked"] = e.target.checked
        let isAllChecked = this.state.SelectedDisSum?.every((item) => item?.noValue || item?.isChecked)
        if (isAllChecked) {
            states["allChecked"] = true
        } else {
            states["allChecked"] = false
        }
        this.setState({ states })
    }

    renderMedicationTable = (item) => {
        return (
            <TableContainer sx={{ minHeight: '23vh', marginTop: '1vw', mb: '1.5vw' }} component={Paper}>
                <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
                    <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                        <TableRow>
                            <TableCell width={'5vw'}>S.No</TableCell>
                            <TableCell width={'18vw'}>Medicine Name</TableCell>
                            <TableCell width={'18vw'}>Dosage Type / Strength</TableCell>
                            <TableCell width={'18vw'}>Timing</TableCell>
                            <TableCell width={'18vw'}>Route</TableCell>
                            <TableCell width={'18vw'}>Instruction</TableCell>
                        </TableRow>
                    </TableHead>
                    {item.key_value?.length > 0 ? item.key_value?.map((list, index) => (
                        <TableBody>
                            <TableRow>
                                <TableCell width={'5vw'}>
                                    {index + 1}
                                </TableCell>
                                <TableCell width={'18vw'}>
                                    {list?.medicinename?.length > 15 ?
                                        <Tooltip title={list.medicinename} placement='top' arrow>
                                            <Typography>{list.medicinename.slice(0, 15) + '...'}</Typography>
                                        </Tooltip> : <Typography>{list.medicinename}</Typography>}
                                </TableCell>
                                <TableCell width={'18vw'}>
                                    {list?.dosage?.length > 15 ?
                                        <Tooltip title={list.dosage} placement='top' arrow>
                                            <Typography>{list.dosage.slice(0, 15) + '...'}</Typography>
                                        </Tooltip> : <Typography>{list.dosage}</Typography>}
                                </TableCell>
                                <TableCell width={'18vw'}>{list.maen}</TableCell>
                                <TableCell width={'18vw'}>
                                    {list?.route?.length > 15 ?
                                        <Tooltip title={list.route} placement='top' arrow>
                                            <Typography>{list.route.slice(0, 15) + '...'}</Typography>
                                        </Tooltip> : <Typography>{list.route}</Typography>}
                                </TableCell>
                                <TableCell width={'18vw'}>
                                    {list?.instruction?.length > 20 ?
                                        <Tooltip title={list.instruction} placement='top' arrow>
                                            <Typography>{list.instruction.slice(0, 20) + '...'}</Typography>
                                        </Tooltip> : <Typography>{list.instruction}</Typography>}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )) : null}
                </Table>
            </TableContainer>
        )
    }

    renderDiagnosisTable = (item) => {
        return (
            <TableContainer sx={{ minHeight: '23vh' }}>
                <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
                    <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                        <TableRow>
                            <TableCell width={'5vw'}>S.No</TableCell>
                            <TableCell width={'20vw'}>ICD 10 Codes</TableCell>
                            <TableCell width={'35vw'}>Short Description</TableCell>
                            <TableCell width={'35vw'}>Long Description</TableCell>
                        </TableRow>
                    </TableHead>
                    {item.key_value?.length > 0 ? item.key_value?.map((list, index) => (
                        <TableBody>
                            <TableRow>
                                <TableCell width={'5vw'}>{index + 1}</TableCell>
                                <TableCell width={'20vw'}>{list.icd_code ? list.icd_code : list.icd_codes}</TableCell>
                                <TableCell width={'35vw'}>
                                    {list.icd_short_desc?.length > 40 ?
                                        <Tooltip title={list.icd_short_desc} placement='top' arrow>
                                            <Typography>{list.icd_short_desc?.slice(0, 40) + '...'}</Typography>
                                        </Tooltip> : <Typography>{list.icd_short_desc}</Typography>}
                                </TableCell>
                                <TableCell width={'35vw'}>
                                    {list.icd_desc?.length > 40 ?
                                        <Tooltip title={list.icd_desc} placement='top' arrow>
                                            <Typography>{list.icd_desc?.slice(0, 40) + '...'}</Typography>
                                        </Tooltip> : <Typography>{list.icd_desc}</Typography>}</TableCell>
                            </TableRow>
                        </TableBody>
                    )) : null}
                </Table>
            </TableContainer>

        )
    }

    handleAllChecked = (e) => {
        this.state.SelectedDisSum.forEach((item) => {
            item["isChecked"] = e.target.checked
        })
        this.setState({ allChecked: e.target.checked })
    }

    clearDissumDatas = () => {
        this.setState({
            AdmDiagSearch: "",
            DisDiagSearch: "",
            PatientKeySearch: '',
            DisSumDataList: [],
        })
    }

    render() {
        return (
            <div>
                <Modal open={true}>
                    <Box component={'div'} className="eMed_DisSum_Copy_popMain">
                        <Box component={'div'} className="eMed_DisSum_Copy_head_Box" position={'sticky'}>
                            <Box component={'div'} className="eMed__DisSum_Copy_BtnGr">
                                <Typography>{'Discharge Summary'}</Typography>
                            </Box>
                            <Box component={'div'} className="eMed__DisSum_Copy_BtnGr">
                                <IconButton onClick={() => { this.props.CopyPopClose() }} emed_tid="disSummary_Copy_paste_Close">
                                    <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' />
                                </IconButton>
                            </Box>
                        </Box>
                        {
                            this.state.SelectedDisSum.length === 0 ?
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", marginX: "1vw", marginY: '1.2vw' }}>
                                    <TextField
                                        label={"Admission Diagnosis"}
                                        sx={{ width: "18vw" }}
                                        size='small'
                                        value={this.state.AdmDiagSearch}
                                        onChange={(e) => {
                                            this.setState({
                                                AdmDiagSearch: e.target.value
                                            })
                                        }}
                                        inputProps={{ emed_tid: "disSummary_Copy_paste_Admission_testID" }}
                                    />
                                    <TextField
                                        label={"Discharge Diagnosis"}
                                        sx={{ width: "18vw" }}
                                        size='small'
                                        value={this.state.DisDiagSearch}
                                        onChange={(e) => {
                                            this.setState({
                                                DisDiagSearch: e.target.value
                                            })
                                        }}
                                        inputProps={{ emed_tid: "disSummary_Copy_paste_Discharge_testID" }}
                                    />
                                    <TextField
                                        label={"Patient Name / IP No / UHID"}
                                        sx={{ width: "18vw" }}
                                        size='small'
                                        value={this.state.PatientKeySearch}
                                        onChange={(e) => {
                                            this.setState({
                                                PatientKeySearch: e.target.value
                                            })
                                        }}
                                        inputProps={{ emed_tid: "disSummary_Copy_paste_Patient_testID" }}
                                    />
                                    <Button sx={{ width: "4vw" }} variant="outlined" emed_tid={"disSummary_Copy_paste_Clear_testID"} onClick={() => { this.clearDissumDatas() }}>Clear</Button>
                                    <Button sx={{ width: "4vw" }} variant="contained" emed_tid={"disSummary_Copy_paste_Search_testID"} disabled={this.state.disableBtn} onClick={() => { this.GetDissumDatas() }}>Search</Button>
                                </Box> :
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", padding: '1vw' }}>

                                    <Button sx={{ width: "10vw" }} variant="contained" emed_tid={"disSummary_Copy_paste_BackToSearch_testID"} onClick={() => {
                                        this.setState({
                                            SelectedDisSum: []
                                        })
                                    }}>Back to Search</Button>
                                    <Button sx={{ width: "10vw" }} variant="contained" emed_tid={"disSummary_Copy_paste_CopyDatas_testID"} onClick={() => { this.props.PasteDisSumData(this.state.SelectedDisSum) }}>Copy Datas</Button>
                                </Box>
                        }
                        {
                            this.state.SelectedDisSum.length === 0 ?
                                <TableContainer sx={{ height: '63vh', marginTop: '1vw' }}>
                                    <Table size='small' aria-label="simple table">
                                        <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                            <TableRow>
                                                <TableCell>S.No</TableCell>
                                                <TableCell>Patient Details</TableCell>
                                                <TableCell>Admission Diagnosis</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.state.DisSumDataList?.length > 0 ? this.state.DisSumDataList.map((item, index) => (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width={'5vw'}>{index + 1}</TableCell>
                                                    <TableCell width={'15vw'}>
                                                        <CommonPatientDetails data={item} />
                                                    </TableCell>
                                                    <TableCell width={'20vw'}>
                                                        {item?.admission_diagnosis?.length > 25 ?
                                                            <Tooltip title={item.admission_diagnosis} placement='top' arrow>
                                                                <Typography>{item.admission_diagnosis.slice(0, 25) + '...'}</Typography>
                                                            </Tooltip> : <Typography>{item.admission_diagnosis}</Typography>}
                                                    </TableCell>
                                                    <TableCell width={'5vw'}>
                                                        <Tooltip title={'View'} placement='top' arrow>
                                                            <IconButton emed_tid={"disSummary_Copy_paste_View_testID"} onClick={() => { this.restructureData(item) }}>
                                                                <VisibilityIcon color='primary' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )) : null}
                                    </Table>
                                    {this.state.DisSumDataList?.length === 0 ?
                                        <Box className='eMed_Dis_Template_NoRecord' sx={{ mt: '20vh' }} >
                                            {this.state.isLoader ? <CircularProgress /> : <Typography >{'No Records Found'}</Typography>}
                                        </Box> : null}

                                </TableContainer> :
                                <Box sx={{ padding: '1vw' }}>
                                    <FormControlLabel control={<Checkbox size='small' checked={(this.state.allChecked || (this.state.SelectedDisSum?.every((item) => item?.noValue || item?.isChecked)))} onChange={(e) => { this.handleAllChecked(e) }} emed_tid={"disSummary_Copy_paste_SelectAll_testID"} />} label={<Typography fontWeight={'bold'}>Select All</Typography>} />
                                    {this.state.SelectedDisSum?.map((item) => {
                                        let name = item?.key_name ? (item?.key_name)?.replaceAll("_", " ") : ''
                                        let disableChecked = false
                                        switch (item.key_name) {
                                            case "investigation_and_test_report":
                                                if (item.key_value?.investigation === "") {
                                                    disableChecked = true
                                                    item.noValue = true
                                                    item.isChecked = false
                                                }
                                                break;
                                            case "discharge_instruction":
                                                if (item.key_value?.diet === "" && item?.key_value.acivity === "" && item.key_value?.advice_discharge === "") {
                                                    disableChecked = true
                                                    item.noValue = true
                                                    item.isChecked = false
                                                }
                                                break;
                                            case "name_anaesthetist_and_type_of_anaesthesia":
                                                if (item.key_value?.type_of_anaesthesia === "" && item?.key_value.name_of_anaesthetist === "") {
                                                    disableChecked = true
                                                    item.noValue = true
                                                    item.isChecked = false
                                                }
                                                break;
                                            case ("admission_diagnosis" || "discharge_diagnosis" || "medication" || "in_hospital_medication"):
                                                if (item.key_value?.length === 0) {
                                                    disableChecked = true
                                                    item.noValue = true
                                                    item.isChecked = false
                                                }
                                            default:
                                                if (typeof (item.key_value) === "string" && item.key_value === "") {
                                                    disableChecked = true
                                                    item.noValue = true
                                                    item.isChecked = false
                                                }
                                                break;
                                        }

                                        return (
                                            <Box>
                                                <FormControlLabel control={<Checkbox size='small' checked={item.isChecked} disabled={disableChecked} onChange={(e) => { this.handleChecked(item, e) }} emed_tid={`disSummary_Copy_${item?.key_name}`} />} label={<Typography sx={{ textTransform: "capitalize", fontWeight: 'bold' }}>{name}</Typography>} />
                                                {
                                                    (item.key_name === "admission_diagnosis" || item.key_name === "discharge_diagnosis") ? this.renderDiagnosisTable(item)
                                                        : (item.key_name === "medication" || item.key_name === "in_hospital_medication") ? this.renderMedicationTable(item)
                                                            : item.key_name === "investigation_and_test_report" ? <Typography ml={'2vw'} mb={'1.5vw'}>{item.key_value?.investigation ? item.key_value?.investigation : ''}</Typography>
                                                                : item.key_name === "discharge_instruction" ?
                                                                    <Box ml={'2vw'} mb={'1.5vw'}>
                                                                        <Box display={'flex'}><Typography fontWeight={'bold'}>Diet: </Typography><Typography>{item.key_value?.diet ? `  ${item.key_value?.diet}` : ''}</Typography></Box>
                                                                        <Box display={'flex'}><Typography fontWeight={'bold'}> Activity: </Typography><Typography>{" " + item.key_value?.acivity ? item.key_value?.acivity : ''}</Typography></Box>
                                                                        <Box display={'flex'}><Typography fontWeight={'bold'}> Advice on Discharge: </Typography><Typography>{" " + item.key_value?.advice_discharge ? item.key_value?.advice_discharge : ''}</Typography></Box>
                                                                    </Box>
                                                                    : item.key_name === "name_anaesthetist_and_type_of_anaesthesia" ?
                                                                        <Box ml={'2vw'} mb={'1.5vw'}>
                                                                            <Typography> Type of Anaesthesia: {item.key_value?.type_of_anaesthesia ? item.key_value?.type_of_anaesthesia : ''}</Typography>
                                                                            <Typography> Name of Anaesthesia: {item.key_value?.name_of_anaesthetist ? item.key_value?.name_of_anaesthetist : ''}</Typography>
                                                                        </Box>
                                                                        : <Typography ml={'2vw'} mb={'1.5vw'}>{typeof (item.key_value) === "string" ? item.key_value : ''}</Typography>
                                                }
                                            </Box>
                                        )
                                    })}
                                </Box>
                        }
                    </Box>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}
export default CopyDisSumPop

import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Tooltip } from '@mui/material';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../../Utility/Services/CacheProviderService';
import { DateTime } from 'luxon';

class DiagnosisLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DiagnosisData: [],
      isDeleteCliked: false,
      SelectedList: {},
      IpPatientData: this.props?.IpPatientData,
      withOutAppointment : this.props?.withOutAppointment,
    }
  }

  componentDidMount() {
    // this.GetDiagnosisData()
  }
  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetDiagnosisData() }
    if (prevprops.IpPatientData !== this.props.IpPatientData) { this.setState({ IpPatientData: this.props.IpPatientData }, () => { this.GetDiagnosisData() }) }
    if (prevprops.withOutAppointment?.patient_id !== this.props.withOutAppointment?.patient_id) { this.setState({ withOutAppointment: this.props.withOutAppointment }, () => { this.GetDiagnosisData() }) }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  GetDiagnosisData() {
    let admission_id =   this.state.IpPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    const {from_date, to_date, doctorIds} = this.props;
    let url = Serviceurls.IP_DOCNOTES_DIAGNOSIS + "?ip_admission_id=" + admission_id +
      "&from_date=" + (from_date ? from_date : null) +
      "&to_date=" + (to_date ? to_date : null);
    if (doctorIds?.length > 0) {
      url = `${url}&doctor_id=${doctorIds}`
    }
    try {
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              DiagnosisData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    } finally {
      this.props.onApiTrigger();
    }
  }

  onDeleteHandler(data) {
    try {
      this.setState({
        isDeleteCliked: true,
        SelectedList: data.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }
  DeleteExamination() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.IP_DOCNOTES_DIAGNOSIS + "?diag_id=" + states.SelectedList.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false }, () => { this.GetDiagnosisData() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  formatDateTo12hr = (dateStr) => {
    if (!dateStr) return "";
    const dateTime = DateTime.fromISO(dateStr);
    return dateTime.toFormat('yyyy-MM-dd hh:mm:ss a');
  }

  render() {
    try {
      const { t } = this.props
      let myUser = JSON.parse(localGetItem("loggedInUserInfo"));
      this.state.DiagnosisData.forEach((element, index) => { element.sno = index + 1 })
      const columns = [
        {
          field: "diagnosis_dte", flex: 0.15, headerName: t("Date"),
          renderCell: (params) => (
            <div>
              {params?.row?.doctor_name?.length > 10 ?
                <Tooltip title={params?.row?.doctor_name} placement='top' arrow>
                  <div className='eMed_DocNotes_Txt'>{params?.row?.doctor_name ? params.row.doctor_name.slice(0, 10) + '...' : '-'}</div>
                </Tooltip> : <div className='eMed_DocNotes_Txt'>{params?.row?.doctor_name ? params.row.doctor_name : '-'}</div>}
              {params?.row?.diagnosis_dte?.length > 10 ?
                <Tooltip title={this.formatDateTo12hr(params?.row?.diagnosis_dte || "")} placement='top' arrow>
                  <div className='eMed_DocNotes_DateTxt'>{params?.row?.diagnosis_dte ? this.formatDateTo12hr(params.row.diagnosis_dte).slice(0, 10) + '...' : '-'}</div>
                </Tooltip> : <div className='eMed_DocNotes_DateTxt'>{params?.row?.diagnosis_dte ? this.formatDateTo12hr(params.row.diagnosis_dte) : '-'}</div>}
            </div>
          )
        },
        {
          field: "icd_code_id", flex: 0.10, headerName: t("ICDcode"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.icd_code_id ? params?.row?.icd_code_id?.length > 18 ?
              <Tooltip placement='top' title={params?.row?.icd_code_id} arrow>
                <div>{params?.row?.icd_code_id.slice(0, 18) + "..."}</div></Tooltip> :
              params?.row?.icd_code_id : "-"}
          </Box>)
        },
        {
          field: "icd_short_desc", flex: 0.22, headerName: t("ShortDescription"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.icd_short_desc ? params?.row?.icd_short_desc?.length > 30 ?
              <Tooltip placement='top' title={params?.row?.icd_short_desc} arrow>
                <div>{params?.row?.icd_short_desc.slice(0, 30) + "..."}</div></Tooltip> :
              params?.row?.icd_short_desc : "-"}
          </Box>)
        },
        {
          field: "icd_desc", flex: 0.23, headerName: t("LongDescription"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.icd_desc ? params?.row?.icd_desc?.length > 30 ?
              <Tooltip placement='top' title={params?.row?.icd_desc} arrow>
                <div>{params?.row?.icd_desc.slice(0, 30) + "..."}</div></Tooltip> :
              params?.row?.icd_desc : "-"}
          </Box>)
        },
        {
          field: "action", flex: 0.10, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => <div>
            <CommonDeleteButton disable={this.props.is_From_IpNotes ? !(myUser?.doctor_id === params.row.doctor_id) : CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ? this.state.withOutAppointment.is_ip : true} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
          </div>
        }
      ]
      return (
        <Box height={"35vh"}>
          <DataGrid
            rows={this.state.DiagnosisData}
            columns={columns}
            getRowId={(row) => row['sno']}
            headerHeight={40}
            hideFooter={true}
            components={{
              NoRowsOverlay: () => (
                <Stack className='eMed_DocNotes_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            disableSelectionOnClick
          />
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")}  ${t("Diagnosis")} ${this.state.SelectedList ? this.state.SelectedList?.icd_code_id : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteExamination.bind(this)}
          // DeleteNotify={t("effectEntireSetting")}
          /> : null}
        </Box>
      )
    }
    catch (e) {

    }
  }
}
export default withTranslation()(DiagnosisLeft)


import React, { Component } from 'react'
import { CommonDeleteButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, Divider, IconButton, Modal, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ShowComponents from '../Common/ShowComponent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ImagePaths } from '../../../../Utility/ImagePaths';
class DocExaminationLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      examinationData: [],
      isDeleteCliked: false,
      SelectedList: {},
      PatientData: this.props?.PatientData,
      SelectedNotesItem: {},
      EditNotes: "",
      templateList: [],
      selectedTemplateName: null,
      openPopupAddNew: false,
      templateName: '',
      tempType: '',
      template: null,
      templateData: [],
    }
  }

  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetIntialData() }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }



  GetIntialData() {
    this.GetexaminationData()
    this.getExamTemplateData()
  }

  GetexaminationData() {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_EXAMINATION_GET + `?appointment_id=${this.state.PatientData?.appointment_id}&new_system=true`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              examinationData: response?.data?.data?.examinations ? response?.data?.data?.examinations : [],
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  onEditHandler(data) {
    this.setState({
      SelectedNotesItem: data.row,
      EditNotes: data?.row?.value ? data?.row?.value : ""
    })
    // this.props.EditAllergyData(data.row)
  }
  onDeleteHandler(data) {
    try {
      this.setState({
        isDeleteCliked: true,
        SelectedList: data.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }
  DeleteAllergy() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES + `?id=${states.SelectedList?.id}`, {}).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false }, () => { this.GetexaminationData() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  deleteExaminationData = () => {
    try {
      let data = {
        appointment_id: this.props.PatientData?.appointment_id ? this.props.PatientData?.appointment_id : "",
        patient_examination_id: this.state.SelectedList?.id ? this.state.SelectedList?.id : ''
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_DELETE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.setState({ isDeleteCliked: false, SelectedList: {} })
            this.GetexaminationData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  UpdateExaminationData = (index) => {
    try {
      let states = this.state
      let examinationData = states.examinationData[index]
      // let removedIds = this.deletedIds()
      let updatedata = {
        display_order: examinationData?.display_order,
        examination_id: examinationData?.examination_id,
        examination_name: examinationData?.examination_name,
        group_id: examinationData?.group_id,
        value: states?.EditNotes ? states?.EditNotes : ''
      }
      let data = {
        examination_data: [updatedata],
        appointment_id: this.props.PatientData?.appointment_id,
        deleted_ids: []
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_POST)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              SelectedNotesItem: {},
              EditNotes: ""
            })
            this.GetexaminationData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  getExamTemplateData = (favorite_id) => {
    try {
      let params = ''
      if (favorite_id) {
        params = `?favorite_id=${favorite_id}`
      } else {
        params = ''
      }
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_EXAM_TEMPLATE_LIST + params)
        .then((response) => {
          if (response.data.status === 'success') {
            if (favorite_id) {
              this.setState({
                templateList: response.data.data
              }, () => this.postFavListData())
            } else {
              this.setState({
                templateList: response.data.data
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postFavListData = () => {
    try {
      let examination = []
      this.state.templateList?.forEach(element => {
        examination?.push({
          display_order: element?.display_order,
          examination_id: element?.examination_id,
          value: element?.value
        })
      })
      let data = {
        appointment_id: this.props.PatientData?.appointment_id,
        examinations: examination
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAMINATION_GET)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.GetexaminationData()
            this.getExamTemplateData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  postNewTemplateData = () => {
    try {
      let templateData = []
      this.state.examinationData?.forEach(element => {
        templateData?.push({
          display_order: element?.display_order,
          examination_id: element?.examination_id,
          value: element?.value
        })
      })
      let data = {}
      if (this.state.tempType === 'rename') {
        data = {
          favorite_name: this.state.templateName,
          id: this.state.selectedTemplateName?.id
        }
      } else {
        data = {
          examinations: templateData,
          favorite_name: this.state.templateName
        }
      }
      this.setState({ templateLoad: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_TEMPLATE_LIST)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ templateLoad: false })
            this.setState({ openPopupAddNew: false, templateName: '', selectedTemplateName: '', isEdit: false })
            this.getExamTemplateData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ templateLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ templateLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ templateLoad: false })
      this.errorMessage(error.message)
    }
  }

  renderTemplatePopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPopupAddNew}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} p={'0.5vw'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>
                  {this.state.tempType === 'Add' ? 'Add New Template' : this.state.tempType === 'rename' ? 'Rename Template' : 'Delete Template'}
                </Typography>
                <Button className='Common_Btn_Min_Width'
                  onClick={() => this.setState({ openPopupAddNew: false }, () => this.setState({ templateName: '' }))}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} ml={'2.5vw'}>
                <TextField
                  size='small'
                  sx={{ width: '20vw' }}
                  label='Enter Template Name'
                  value={this.state.templateName}
                  onChange={(e) => this.setState({ templateName: e.target.value })}
                />
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => this.setState({ templateName: '' })}
                >Clear</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => this.postNewTemplateData()}
                  disabled={this.state.templateLoad}
                >Add</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteTemplate = () => {
    try {
      let data = {
        favorite_id: this.state.selectedTemplateName?.id
      }
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_EXAM_TEMPLATE_LIST, data)
        .then((response) => {
          this.successMessage(response.data.message)
          this.setState({
            openPopupAddNew: false,
            templateName: '',
            selectedTemplateName: '',
            deletePopup: false,
            removeData: false,
            isEdit: false
          })
          this.getExamTemplateData()
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  render() {
    try {
      const { t } = this.props
      this.state.examinationData.forEach((element, index) => { element.sno = index + 1 })
      const columns = [
        {
          field: "group_name", flex: 0.14, headerName: t("Systematic Examination"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.group_name ? params?.row?.group_name?.length > 18 ?
              <Tooltip placement='top' title={params?.row?.group_name} arrow>
                <div>{params?.row?.group_name.slice(0, 18) + "..."}</div></Tooltip> :
              params?.row?.group_name : "-"}
          </Box>)
        },
        {
          field: "examination_name", flex: 0.14, headerName: t("Signs / Symptoms"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.examination_name ? params?.row?.examination_name?.length > 18 ?
              <Tooltip placement='top' title={params?.row?.examination_name} arrow>
                <div>{params?.row?.examination_name.slice(0, 18) + "..."}</div></Tooltip> :
              params?.row?.examination_name : "-"}
          </Box>)
        },
        {
          field: "value", flex: 0.28, headerName: t("Notes"),
          renderCell: (params) => (
            <Box component={'div'}>
              {
                this.state?.SelectedNotesItem?.id === params?.row?.id ?
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      focused={true}
                      variant='standard'
                      size='small'
                      value={this.state.EditNotes}
                      onChange={(e) => {
                        e.preventDefault()
                        this.setState({ EditNotes: e.target.value })
                      }}
                      inputProps={{ maxLength: 100, style: { height: "0.8vw" } }}
                      sx={{ width: "19vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.stopPropagation()
                        }
                        if (e.key === 'Enter' && this.state.NewGroupName !== "") {
                          this.UpdateExaminationData(params.row.sno - 1)
                        }
                      }} />
                    <IconButton
                      disabled={this.state.NewGroupName === ""}
                      onClick={() => {
                        this.UpdateExaminationData(params.row.sno - 1)
                      }}>
                      <CheckCircleOutlineIcon color={this.state.NewGroupName === "" ? 'grey' : 'primary'} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          SelectedNotesItem: {},
                          EditNotes: ""
                        })
                      }}>
                      <HighlightOffIcon color={'error'} />
                    </IconButton>
                  </Box>
                  :
                  params?.row?.value ? params?.row?.value?.length > 45 ?
                    <Tooltip placement='top' title={params?.row?.value} arrow>
                      <div>{params?.row?.value.slice(0, 45) + "..."}</div></Tooltip> :
                    params?.row?.value : "-"}
            </Box>)
        },
        {
          field: "action", flex: 0.08, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={params?.row?.value ? "Edit Notes" : "Add Notes"} placement="top" arrow>
              <IconButton disabled={this.props?.isLocked} size='small' onClick={() => { this.onEditHandler(params) }}>
                <PostAddIcon color={this.props?.isLocked ? 'disabled' : 'primary'} className="eMed_usrconf_btn" />
              </IconButton>
            </Tooltip>
            <CommonDeleteButton size={"1.2vw"} disable={this.props?.isLocked} onClick={this.onDeleteHandler.bind(this, params)} />
          </Box>
        }
      ]
      return (
        <ShowComponents onIntersection={this.GetIntialData.bind(this)}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '1vw' }}>
            <Autocomplete
              clearIcon
              size='small'
              disabled={this.props?.isLocked}
              sx={{ width: '20vw', }}
              options={this.state.templateList}
              getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.favorite_name}
              value={this.state.selectedTemplateName}
              onChange={(e, value) => {
                this.setState({
                  selectedTemplateName: value
                }, () => {
                  if (this.state.selectedTemplateName) {
                    this.getExamTemplateData(this.state.selectedTemplateName?.id)
                  } else {
                    this.getExamTemplateData()
                  }
                })
              }}
              renderInput={(params) => <TextField {...params} label='Select Template Name' />}
              PaperComponent={({ children }) => {
                return (
                  <Paper sx={{ maxHeight: '25vw', overflow: 'scroll' }}>
                    {children}
                    <Divider />
                    <Button
                      color="primary"
                      fullWidth
                      sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                      onMouseDown={(e) => {
                        this.setState({ openPopupAddNew: true, tempType: 'Add' })
                      }}
                    >
                      Add Template
                    </Button>
                    {this.state.selectedTemplateName ?
                      <><Button
                        color="primary"
                        fullWidth
                        sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                        onMouseDown={(e) => {
                          this.setState({ openPopupAddNew: true, tempType: 'rename', templateName: this.state.selectedTemplateName?.favorite_name })
                        }}
                      >
                        Rename
                      </Button>
                        <Button
                          color="primary"
                          fullWidth
                          sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                          onMouseDown={(e) => {
                            this.setState({
                              // openPopupAddNew: true,
                              tempType: 'delete',
                              templateName: this.state.selectedTemplateName?.favorite_name,
                              deletePopup: true
                            })
                          }}
                        >
                          Delete
                        </Button>
                      </> : null
                    }

                  </Paper>
                )
              }}
            />
          </Box>
          <Box height={'30vh'} overflow={'auto'}>
            <DataGrid
              density="compact"
              rows={this.state.examinationData}
              columns={columns}
              getRowId={(row) => row['sno']}
              headerHeight={40}
              hideFooter={true}
              components={{
                NoRowsOverlay: () => (
                  <Stack className='eMed_DocNotes_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              disableSelectionOnClick
            />
          </Box>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.renderTemplatePopup()}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} ${t("Examination")} ${this.state.SelectedList?.examination_name ? this.state.SelectedList?.examination_name : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.deleteExaminationData.bind(this)}
          /> : null}
          {this.state.deletePopup ? <DeletePopup
            DeletTitle={`Are you sure you want to delete ${this.state.tempType === 'delete' ? this.state.templateName : ''} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.deleteTemplate.bind(this)}
            disable={this.state.removeData}
          /> : null}
        </ShowComponents>
      )
    }
    catch (e) {
      console.log(e.message);
      return null
    }
  }
}
export default withTranslation()(DocExaminationLeft)

import React, { Component } from "react";
import {
    Box, Button, Stack, InputLabel,
    MenuItem, Select, TextField, Modal,
    FormControl, RadioGroup, Radio, FormControlLabel, Tooltip, Autocomplete, IconButton
} from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Trans, withTranslation } from 'react-i18next';
import NewProductRegistry from "./NewProductRegistry";
import { DataGrid } from "@mui/x-data-grid";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import './inventory.css'
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";


const Expiry_period = [
    { label: "", value: 0 },
    { label: "1 month", value: 1 },
    { label: "2 months", value: 2 },
    { label: "3 months", value: 3 },
    { label: "6 months", value: 6 },
]

const gstPercentage = [
    { label: 0, value: 0 },
    { label: 0.25, value: 0.25 },
    { label: 3, value: 3 },
    { label: 5, value: 5 },
    { label: 12, value: 12 },
    { label: 18, value: 18 },
    { label: 28, value: 28 },
]
class StockList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "AddProduct_Flag": false,
            pageSize: 10,
            page: 0,
            stockListData: [],
            stockViewId: "",
            newStockList: [],
            editStockList: {},
            forceUpdate: false,
            stockListRadio: 0,
            stockListReason: "",
            total: 0,
            searchkey : '',
            correction : "",
            materialTypeList:[],
            productTypeList:[],
            unitList:[],
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getStockList()
        this.getMaterialType()
        this.getProductTypeList()
        this.getUnitList()
    }

    componentWillReceiveProps(props){
        if(props.search_key != this.state.searchkey){
            this.setState({
                searchkey: props.search_key
            },() => {
                this.getStockList()
            })
        }
    }

    onChangeHandler = (event, key) => {
        if (key === "adjusted_quantity") {
            [key] = this.numberValidation(event.target.value)
        } else {
            this.setState({
                [key]: event.target.value
            })
        }

    }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          isSuccessMsg: false,
          isSuccessMsgText: "",
        })
    }

    handleClose = () => {
        this.setState({
            AddProduct_Flag: false
        })
    }
    SuccessErrorBox = (message, type) => {
        if(type === "error"){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: message
            })
        }else{
            this.setState({
                isSuccessMsg: true,
                isSuccessMsgText: message
            })
        }
    }

    stockViewOpen = (e, values) => {
        this.setState({
            stockView: true
        })
    }

    onStockEditOpen = (e, values) => {
        this.setState({
            stockEditOpen: true,
            id: values.row.list_id,
            editStockList: values.row,
            orginalGetStock : values
        })
    }

    onStockEditClose = () => {
        this.setState({
            stockEditOpen: false,
            stockListRadio : 0,
            stockListReason : "",
            correction : ""
        })
        this.getStockList()
    }

    onChangeStock = (e) => {
        let { editStockList,correction } = this.state
        let isAlpha = this.alphaValidation(e.target.value)
        let isNum = this.numberValidation(e.target.value)
        let name = e.target.name
        let value = e.target.value
        if (name === "gst_percentage") {
            if (isNum || e.target.value !== 0) {
                    editStockList[name] = e.target.value

            }
        } else if (name === "correction") {
            if (isNum || e.target.value === "") {
                correction = e.target.value
            }
        } else {
            editStockList[name] = value
        }
        this.setState({ editStockList , correction })
    }
    
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
    getStockListItems = () => {
        try {
            this.LoaderFunction(true)
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.INVENTORY_STOCK_LIST_DETAILS_GET + "?list_id=" + this.state.stockViewId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            newStockList: response.data.data,
                            disableBtn: false,
                        },()=>{ this.LoaderFunction(false)})
                    } else if (response.data.status === 'fail') {
                        this.LoaderFunction(false)
                        this.SuccessErrorBox(response.message, false)
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.SuccessErrorBox(error.message, false)
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error,
                disableBtn: false,
            })
        }
    }

    StockViewClose = () => {
        this.setState({
            stockView: false
        })
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    alphaValidation = (num) => {
        return !/[^A-Z a-z]+/.test(num)
    }
    

    getStockList = () => {
        try {
            this.LoaderFunction(true)
            this.setState({
                stockListData: [],
                disableBtn: true,
            })
            RestAPIService.getAll(Serviceurls.INVENTORY_STOCK_LIST_GET + `?page_number=${this.state.page + 1}&page_size=${this.state.pageSize}&search=${this.state.searchkey}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            stockListData: response.data.data.result,
                            total: response.data.data.total_count,
                            newPageCount: response.data.data.no_of_pages,
                            disableBtn: false,
                        },()=>{
                            this.LoaderFunction(false)
                            if(this.state.isReset){
                            for(let i=0;i<this.state.stockListData.length;i++){
                                if(this.state.editStockList?.list_id === this.state.stockListData[i]?.list_id){
                                    this.setState({
                                        editStockList : this.state.stockListData[i],
                                        stockListReason : "",
                                        stockListRadio : 0,
                                        correction : "",
                                        isReset : false
                                    })
                                }
                            }
                        }
                        })
                    } else if (response.data.status === 'fail') {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message,
                            disableBtn: false,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message,
                    disableBtn: false,
                }),()=>{this.LoaderFunction(false)})
        } catch (error) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message,
                disableBtn: false,
            })
        }
    }

    getMaterialType = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_MATERIAL_TYPE)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        materialTypeList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getProductTypeList = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_PRODUCT_TYPE)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        productTypeList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getUnitList = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_PRODUCT_UNIT)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        unitList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    onOpenHandler = () => {
        this.setState({ open : true })
    }

    onCloseHandler = () => {
        this.setState({ open : false })
    }

    mandatoryFieldCheck = () => {
        const {t} = this.props
        return(
            <>
            <Modal open={this.state.open} onClose={this.onCloseHandler}>
            <Box className="mandatory_card">
                <div className="mandatory_card_header">
                <label>UnChange Mandatory Fields</label>
                </div>
                <div className="mandatory_middle_card">
                <label className="mandatory_middle_card_text">{t("mandatoryProductExist")}</label>
                </div>
                <div className="mandatory_card_footer">
                <Button 
                variant="contained" 
                sx={{textTransform:"capitalize"}}
                onClick={()=>{
                    if(this.state.open){
                        this.putStockList()
                        this.setState({open : false})
                    }
                }}
                >{t("SaveChanges")}</Button>
                </div>
            </Box>
            </Modal>
            </>
        )
    }

    putStockList = () => {
        const {t} = this.props
        try {
            let { editStockList, forceUpdate } = this.state
            let data = {
                "list_id": editStockList.list_id,
                "reason": +this.state.stockListRadio,
                "comments": this.state.stockListReason,
                "adjusted_quantity": this.state.correction !== "" ? +this.state.correction : +editStockList.stock_in_quantity,
                "force_update": forceUpdate ? forceUpdate : false,
                "brand_name": editStockList.brand_name,
                "product_name": editStockList.product_name,
                "material_type": editStockList.material_type,
                "product_type": editStockList.product_type,
                "unit": editStockList.unit,
                "batch_no": editStockList.batch_no,
                "reorder_threshold": +editStockList.reorder_threshold,
                "expiry_alert_period": +editStockList.expiry_alert_period,
                "physical_box_no": +editStockList.physical_box_no,
                "hsn_code": editStockList.hsn_code,
                "cgst_percentage": +editStockList.gst_percentage / 2,
                "sgst_percentage": +editStockList.gst_percentage / 2
            }
            if (data["unit"] === "" && data["product_name"] === "" && data["material_type"] === "" && data["product_type"] === "") {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("ManitoryFields"),
                    saveLoad:false
                })
            } else if (data["comments"] == "" || data["reason"] === 0) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("ReasonComments"),
                    saveLoad:false
                })
            } else {
                this.setState({disableBtn: true})
                RestAPIService.updateWithOutId(data, Serviceurls.INVENTORY_STOCK_LIST_PUT).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.setState({
                                isSuccessMsg: true,
                                isSuccessMsgText: response.data.message,
                                saveLoad:false
                            })
                            this.setState({
                                correction: "",
                                stockListReason: "",
                                stockEditOpen: false,
                                stockListRadio : 0,
                                forceUpdate : false,
                                disableBtn: false,
                            })
                            this.getStockList()
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.status === "fail" && error.response.data.message === "Product already exists") {
                            this.getStockList()
                            this.setState({
                                forceUpdate: true,
                                disableBtn: false,
                                saveLoad:false
                            }, () => {
                                // this.setState({
                                //     isSuccessMsg: true,
                                //     isSuccessMsgText: "Product already exists are you willing to update non mandatory field"
                                // }, () => {
                                //     setTimeout(() => {
                                //         this.setState({ isSuccessMsg: false })
                                //     }, 5000);
                                // })
                                this.onOpenHandler()
                            })
                        }
                    })
            }
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message,
                disableBtn: false,
                saveLoad:false
            })
        }
    }

    getStockListExcel = () => {
        const { t } = this.props;
        try {
            this.setState({disableBtn: true})
            RestAPIService.excelGet(`${Serviceurls.INVENTORY_STOCK_LIST_GET}?export=${"excel"}`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data?.message,
                        disableBtn: false,
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError"),
                disableBtn:false,
            })
        }
    }

    cleareditStockList = () => {
        // let { editStockList } = this.state;
        // editStockList["material_type"] = ""
        // editStockList["product_name"] = ""
        // editStockList["brand_name"] = ""
        // editStockList["product_type"] = ""
        // editStockList["unit"] = ""
        // editStockList["physical_box_no"] = ""
        // editStockList["reorder_threshold"] = ""
        // editStockList["expiry_alert_period"] = ""
        // editStockList["hsn_code"] = ""
        // editStockList["gst_percentage"] = ""
        // editStockList['stock_in_quantity'] = ""
        // this.state.correction = ""
        // this.state.stockListReason = ""
        // this.state.stockListRadio = ""
        this.setState({ editStockList : this.state.orginalGetStock.row})
    }

    renderStockViewpopUp = () => {
        const { t } = this.props
        return (
            <>
                <Modal open={this.state.stockView} onClose={this.StockViewClose} >
                    <Box className='active_view_popup'>
                        <div className='active_view_header'>
                            <label className='active_view_header_text'>{t('productDetail')}</label>
                        </div>
                        {this.state.newStockList.map((item, index) => (
                            <div className='active_view_middle_card' key={index}>
                                <div className='active_view_textfield'>
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('MaterialType') + "*"}
                                        value={item?.material_type}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('BrandName') + "*"}
                                        value={item?.brand_name}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('ProductName') + "*"}
                                        value={item?.product_name}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('ProductType') + "*"}
                                        value={item?.product_type}
                                        disabled
                                    />
                                </div>
                                <div className='active_view_textfield'>
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('Unit') + "*"}
                                        value={item?.unit}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('LatestvendorName')}
                                        value={item?.vendor_name}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('LatestManufName')}
                                        value={item?.manufacturer_name}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('PhyBox')}
                                        value={item?.physical_box_no}
                                        disabled
                                    />
                                </div>
                                <div className='active_view_textfield'>
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('HSNCode')}
                                        value={item?.hsn_code}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('GST') + "%"}
                                        value={item?.cgst_percentage + item?.sgst_percentage}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('ReorderThreshold')}
                                        value={item?.reorder_threshold}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('ExpiryPeriod')}
                                        value={item?.expiry_alert_period}
                                        disabled
                                    />
                                </div>
                                <div className='active_view_textfield'>
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('UPC')}
                                        value={item?.bar_code}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('BatchNumbers')}
                                        value={item?.batch_no}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('ExpiresDates')}
                                        value={this.formatDate(item?.expiry_date)}
                                        disabled
                                    />
                                    <TextField
                                        size='small'
                                        sx={{ marginRight: '1vw', width: "14.2vw" }}
                                        label={t('EPRValue')}
                                        value={item?.epr_value}
                                        disabled
                                    />
                                </div>
                            </div>
                        ))}
                        <div className='active_view_footer'>
                            <div className='active_view_left'>
                                <label className='active_view_text'>
                                    <div className='inactive_bulletin'></div>
                                    <span className='inactive_span'>{t('Close')}| </span>
                                    {t('Returnstockpage')}
                                </label>
                            </div>
                            <div className='active_view_right'>
                                <Button
                                    sx={{ textTransform: "capitalize" }}
                                    variant='contained'
                                    onClick={this.StockViewClose}
                                >{t('Close')}</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </>
        )
    }

    renderStockEditPopUp = () => {
        const { t } = this.props
        let { editStockList } = this.state
        return (
            <Modal open={this.state.stockEditOpen} onClose={this.onStockEditClose}>
                <div className='stock_edit_popup'>
                    <div className="stock_edit_header">
                        <label className="stock_edit_header_text">{t('Stock Correction')}</label>
                        <div className='stock_edit_close'>
                            <img className='stock_edit_img_close' src={ImagePaths.LabCloseButton.default} onClick={this.onStockEditClose} alt='close' />
                        </div>
                    </div>
                    <div className="stock_edit_middle_card">
                        <div className='active_view_textfield'>
                            {/* <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('Material Type *')}
                                name="material_type"
                                value={editStockList["material_type"]}
                                onChange={(e) => { this.onChangeStock(e) }}

                            /> */}
                            <FormControl size='small' sx={{ marginRight: '1vw', width: "14.2vw" }}>
                                <InputLabel>{t("MaterialType") + "*"}</InputLabel>
                                <Select
                                    label={t('MaterialType') + "*"}
                                    value={editStockList["material_type"]}
                                    name={"material_type"}
                                    onChange={(e) => { this.onChangeStock(e) }}
                                >
                                    {this.state.materialTypeList?.length > 0 ? this.state.materialTypeList.map((list, index) => (
                                            <MenuItem key={index} value={list.material_type} >{list.material_type}</MenuItem>
                                        )):"No data"}
                                </Select>
                            </FormControl>
                            <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('BrandName') + "*"}
                                name="brand_name"
                                value={editStockList["brand_name"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            />
                            <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('ProductName') + "*"}
                                name="product_name"
                                value={editStockList["product_name"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            />
                            {/* <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('Product Type *')}
                                name="product_type"
                                value={editStockList["product_type"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            /> */}
                            <FormControl size='small' sx={{ marginRight: '1vw', width: "14.2vw" }}>
                                <InputLabel>{t('ProductType') + "*"}</InputLabel>
                                <Select
                                    label={t('ProductType') + "*"}
                                    name="product_type"
                                    value={editStockList["product_type"]}
                                    onChange={(e) => { this.onChangeStock(e) }}
                                >
                                    {this.state.productTypeList?.length > 0 ? this.state.productTypeList.map((list, index) => (
                                        <MenuItem key={index} value={list.product_type} >{list.product_type}</MenuItem>
                                    )):"No data"}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='active_view_textfield'>
                            {/* <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('Unit *')}
                                name="unit"
                                value={editStockList["unit"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            /> */}
                            <FormControl size='small' sx={{ marginRight: '1vw', width: "14.2vw" }}>
                                <InputLabel>{t('Unit') + "*"}</InputLabel>
                                <Select
                                    label={t('Unit') + "*"}
                                    name="unit"
                                    value={editStockList["unit"]}
                                    onChange={(e) => { this.onChangeStock(e) }}
                                >
                                    {this.state.unitList?.length > 0 ? this.state.unitList.map((list, index) => (
                                        <MenuItem key={index} value={list.unit} >{list.unit}</MenuItem>
                                    )) : "No Data"}
                                </Select>
                            </FormControl>
                            <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('PhyBox')}
                                name="physical_box_no"
                                value={editStockList["physical_box_no"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            />
                            <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('ReorderThreshold')}
                                name="reorder_threshold"
                                value={editStockList["reorder_threshold"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            />
                            {/* <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('Expiry Alert Period')}
                                name="expiry_alert_period"
                                value={editStockList["expiry_alert_period"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            /> */}
                            <FormControl size='small' sx={{ marginRight: '1vw', width: "14.2vw" }}>
                                <InputLabel>{t('ExpiryAlertPeriod')}</InputLabel>
                                <Select
                                    label={t('ExpiryPeriod')}
                                    name="expiry_alert_period"
                                    value={editStockList["expiry_alert_period"]}
                                    onChange={(e) => { this.onChangeStock(e) }}
                                >
                                    {Expiry_period.map((list, index) => (
                                        <MenuItem key={index} value={list.value} >{list.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='active_view_textfield'>

                            <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('HSNCode')}
                                name="hsn_code"
                                value={editStockList["hsn_code"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            />
                            {/* <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('GST') + "%"}
                                name="gst_percentage"
                                value={editStockList["gst_percentage"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            /> */}
                            <FormControl >
                                <Autocomplete 
                                    disableClearable
                                    value={editStockList["gst_percentage"]}
                                    options={gstPercentage.map((item)=>item.label)}
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    onChange={(e, newValue) => {
                                        editStockList["gst_percentage"] = newValue
                                        this.setState({ editStockList })
                                    }}
                                    onInputChange={(event, newPercent) => {
                                        let check = CommonValidation.DecimalNumber(newPercent)
                                        if((newPercent) < 100 ){
                                         editStockList["gst_percentage"] = newPercent;
                                         this.setState({ editStockList })
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" label="GST (%)" inputProps={{ ...params.inputProps, type: "search" }}/>}
                                />
                            </FormControl>
                            <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                label={t('CurrentStock')}
                                value={editStockList.stock_in_quantity}
                                disabled

                            />
                            <TextField
                                size='small'
                                sx={{ marginRight: '1vw', width: "14.2vw"}}
                                label={t('StockCorrection')}
                                name="correction"
                                value={this.state.correction}
                                onChange={(e) => this.onChangeStock(e)}
                            />
                        </div>
                        {/* <img src={ImagePaths.LabLessButton.default} className='add_button'/>
                        <img src={ImagePaths.LabAddButton.default} className='minus_button' /> */}
                    </div>
                    <div className="stock_edit_radio_card">
                        <label className="stock_edit_radio_text">{t('EnterReason')}</label>
                        <div className="stock_edit_radio">
                            <FormControl>
                                <RadioGroup row
                                    name="row-radio-buttons-group"
                                    value={this.state.stockListRadio}
                                    onChange={(e) => this.onChangeHandler(e, "stockListRadio")}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label={t('Breakage')} />
                                    <FormControlLabel value={2} control={<Radio />} label={t('Loss')} />
                                    <FormControlLabel value={3} control={<Radio />} label={t('Expired')} />
                                    <FormControlLabel value={4} control={<Radio />} label={t('PhysicallyAvailable')} />
                                    <FormControlLabel value={5} control={<Radio />} label={t('SpellingCorrection')} />
                                    <FormControlLabel value={6} control={<Radio />} label={t('Miscellaneous')} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="stock_edit_input_card">
                        <TextField
                            sx={{ width: "61vw", marginLeft: "1vw", marginTop: "1vw" }}
                            multiline
                            maxRows={2}
                            label={t("correction")}
                            value={this.state.stockListReason}
                            onChange={(e) => this.onChangeHandler(e, "stockListReason")}
                        />
                    </div>
                    <div className="stock_edit_footer_card">
                        <div className='active_view_footer'>
                            <div className='active_view_left'>
                                <label className='active_view_text font'>
                                    <div className='inactive_bulletin'></div>
                                    <span className='inactive_span span_font'>{t('SaveChanges')} | </span>
                                    {t('ChangesSave')}
                                </label>
                                <label className='active_view_text font'>
                                    <div className='inactive_bulletin'></div>
                                    <span className='inactive_span span_font'>{t('Clear')} | </span>
                                    {t('notSaveChanges')}
                                </label>
                            </div>
                            <div className='active_view_right'>
                                <Button
                                    sx={{ marginRight: "0.5vw", textTransform: "capitalize" }}
                                    variant='outlined'
                                    emed_tid='StockList_Edit_ResetBtn'
                                    disabled={this.state.disableBtn}
                                    onClick={() => {
                                        this.setState({
                                            isReset : true,
                                            disableBtn:true
                                        },()=>{
                                            this.getStockList()
                                        })
                                        }}
                                >{t('Reset')}</Button>
                                <Button
                                    sx={{ textTransform: "capitalize" }}
                                    emed_tid='StockList_SaveChanges_ResetBtn'
                                    disabled={this.state.disableBtn}
                                    variant='contained'
                                    onClick={()=>{
                                        this.setState({
                                            saveLoad:true
                                        },() => this.putStockList())
                                        
                                    }}
                                >{t('SaveChanges')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    formatDate (input) {
        let datePart = input.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1], day = datePart[2];
      
        return month+'-'+year;
      }

    render() {
        this.state.stockListData.forEach((element, index) => { element.sno = index + 1 })
        const { t } = this.props
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.07 },
            { field: 'material_type', headerName: t("MaterialType"), sortable: false, flex: 0.111 },
            {
                field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0,14) +"..."}</div></Tooltip> : params?.row?.brand_name}</div>)
            },
            {
                field: 'product_name', headerName: t('ProductName'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.product_name?.length > 14 ?  <Tooltip placement="top" title={params?.row?.product_name} arrow><div>{params?.row?.product_name.slice(0,14) + "..."}</div></Tooltip> : params?.row?.product_name}</div>)
            },
            { field: 'product_type', headerName: t('ProductType'), sortable: false, flex: 0.111 },
            { field: 'stock_in_quantity', headerName: t("AvailableQuantity"), sortable: false, flex: 0.111,type:"number"},
            { field: 'batch_no', headerName: t('BatchNumber'), sortable: false, flex: 0.111 ,type:"number"},
            {
                field: 'expiry_date', headerName: t('ShortExpiryDate'), sortable: false, flex: 0.121,headerAlign:"center",align:"center",
                renderCell: (params) => (<div>{this.formatDate(params.row.expiry_date)}</div>)
            },
            {
                field: 'action', headerName: 'Action', type: 'actions', flex: 0.131, sortable: false,
                renderCell: (cellvalues) => {
                    return (
                        <Stack direction="row">
                            <Tooltip title="Edit" placement="top" arrow><div className="eMed_pause">
                                <IconButton  onClick={(e) => this.onStockEditOpen(e, cellvalues)} disabled={!CheckAccessFunc("Laboratory", "Inventory", "Stock List", null, "Tab")?.editAccess}>
                                <img className="eMed_upload"  src={ImagePaths.LabEditButton.default}  alt='edit' />
                                </IconButton>
                                </div></Tooltip>
                            <Tooltip title="View" placement="top" arrow><div>
                                <IconButton emed_tid='StockList_Table_View_Btn' disabled={this.state.disableBtn}
                                onClick={(e) => {
                                this.setState({ stockViewId: cellvalues.row.list_id },
                                    () => this.getStockListItems())
                                this.stockViewOpen(e, cellvalues)
                            }}>
                                <img className="eMed_upload"  src={ImagePaths.LabViewButton.default}  alt='view' />
                                </IconButton>
                            </div></Tooltip>
                        </Stack>
                    )
                }
            }]
        return (
            <div className="stock_card">
            <div>
                <div role={'button'}  className="active_tab_header_card">
                    <Button disabled={!CheckAccessFunc("Laboratory", "Inventory", "Stock List", null, "Tab")?.editAccess} variant="contained" className="checkbox" onClick={() => { this.setState({ AddProduct_Flag: true }) }}  >
                    {t("AddProduct")}
                    </Button>

                    <Tooltip title="Export" placement='top' arrow>
                        <Button  className='eMed_usrconf_btn' emed_tid='StockList_Export_Btn' disabled={this.state.disableBtn} onClick={() => this.getStockListExcel()}>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='stock_print_design' />
                    </Button></Tooltip>

                </div>
                {this.state.AddProduct_Flag ? <NewProductRegistry popUpClose={this.handleClose.bind(this)} SuccessErrorBox={this.SuccessErrorBox.bind(this)} _getStockList={this.getStockList.bind(this)} /> : null}
                <div className="stock_table_container">
                    <DataGrid
                        rows={this.state.stockListData}
                        rowCount={this.state.total}
                        page={this.state.page}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.list_id}
                        pageSize={this.state.pageSize}
                        loading={this.state.isLoader}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getStockList()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getStockList())}
                        rowsPerPageOptions={[10, 20, 30]}
                        paginationMode="server"
                        pagination
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                          }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay:()=>(
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                {t("NoRecordsFound")}
                              </Stack>
                            )
                          }}
                    />
                </div>
                {this.renderStockViewpopUp()}{this.renderStockEditPopUp()}{this.mandatoryFieldCheck()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.isSuccessMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
            </div>
            </div>
        )
    }
}

export default withTranslation()(StockList);
import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next'
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, Paper, Stack, TextField, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CommonGridHeader, { CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import { Colors } from "../../../Styles/Colors";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import RestAPIService from "../../../Utility/Services/RestAPIService";

class AssetSpotArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Block_List: [],
            Floor_List: [],
            SpotAreaList: [],
            SpotAreaCode: "",
            SpotAreaName: "",
            Block_Name: null,
            Floor_Name: null,
            City_Name: null,
            Notes: "",
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            isActive: true,
            isLoader: false,
            dupFloorList: '[]',
            Floor_List_New: [],
            cityList: [],
            dupBlockList: []
        }
    }

    componentDidMount() {
        this.getBlockList()
        this.getFloorList()
        this.getSpotAreaList()
    }

    getBlockList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_BLOCK_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        let activeData = data.filter((item) => item?.active_status == true)
                        this.setState({
                            Block_List: activeData,
                            dupBlockList: activeData
                        }, () => {
                            let cityList = this.state.Block_List?.map(item => item?.city)
                            let uniqueCityList = [...new Set(cityList)]
                            this.setState({ cityList: uniqueCityList })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    getFloorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_FLOOR_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        let activeData = data.filter((item) => item?.active_status == true)
                        this.setState({
                            Floor_List_New: activeData,
                            dupFloorList: JSON.stringify(activeData)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    getSpotAreaList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_SPOT_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            SpotAreaList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    handleSave = () => {
        const { t } = this.props
        let { SpotAreaCode, SpotAreaName, Notes, isActive, edit_id, Floor_Name, Block_Name, City_Name } = this.state
        let data = {};
        if (SpotAreaName != "" && Block_Name != null && Floor_Name != null && City_Name != null) {
            data = {
                "spot_code": SpotAreaCode,
                "spot_name": SpotAreaName,
                "notes": Notes,
                "block_id": Block_Name?.id,
                "floor_id": Floor_Name?.id,
                "spot_id": edit_id ? edit_id : null,
                "active_status": isActive
            }
            let RestAPI = edit_id ? RestAPIService.updateWithOutId(data, Serviceurls.ASSET_SPOT_LIST_GET_POST) : RestAPIService.create(data, Serviceurls.ASSET_SPOT_LIST_GET_POST)
            try {
                this.setState({ isLoader: true })
                RestAPI.then(response => {
                    if (response.data.status === "success") {
                        this.setState({ isLoader: false }, () => {
                            this.handleClear();
                            this.getSpotAreaList();
                        })
                        this.responseSuccessErrorMessage(response.data.message, true);
                    }
                })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.setState({ isLoader: false })
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.setState({ isLoader: false })
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage("Mandatory Fields Should not be empty", false);
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    msgClose() {
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
    }

    handleEdit = (item) => {
        let states = this.state
        let BlockData = this.state.Block_List.filter((list) => list?.id == item?.block_id && list?.city === item?.block__city)
        let FloorData = this.state.Floor_List_New.filter((list) => list?.id == item?.floor_id && BlockData[0]?.id === item?.block_id)
        states["SpotAreaCode"] = item?.spot_code ? item?.spot_code : ""
        states["SpotAreaName"] = item?.spot_name
        states['Block_Name'] = BlockData[0]
        states['Floor_Name'] = FloorData[0]
        states['City_Name'] = BlockData[0]?.city
        states['Notes'] = item?.notes ? item?.notes : ""
        states["isEdit"] = true
        states["edit_id"] = item?.id
        states["isActive"] = item?.active_status
        console.log(FloorData, "sdf");
        this.setState({ states })
    }

    handleClear = () => {
        this.setState({
            SpotAreaCode: "",
            SpotAreaName: "",
            Block_Name: null,
            Floor_Name: null,
            City_Name: null,
            Notes: "",
            isActive: true,
            edit_id: null,
            isEdit: false
        })
    }

    renderTextField = (value, statekey, Label, required) => {
        const { t } = this.props;
        let states = this.state
        return (
            <TextField
                sx={{ width: "50%", marginX: '0.65vw', marginTop: "1vw" }}
                size="small"
                inputProps={{ maxLength: 100 }}
                autoComplete="off"
                label={t(Label)}
                required={required}
                value={value}
                onChange={(e) => {
                    states[statekey] = e.target.value
                    this.setState({ states })
                }}
            />
        )
    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, SpotAreaList } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.06, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            {
                field: 'spot_code', headerName: t('Spot/Area Code'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.spot_code?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.spot_code} arrow><div>{cellvalues?.row?.spot_code.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.spot_code ? cellvalues?.row?.spot_code : "-"}</div>
                    )
                }
            },
            {
                field: 'spot_name', headerName: t('Spot/Area Name'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.spot_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.spot_name} arrow><div>{cellvalues?.row?.spot_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.spot_name ? cellvalues?.row?.spot_name : "-"}</div>
                    )
                }
            },
            {
                field: 'block__block_name', headerName: t('Block'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.block__block_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.block__block_name} arrow><div>{cellvalues?.row?.block__block_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.block__block_name ? cellvalues?.row?.block__block_name : "-"}</div>
                    )
                }
            },
            {
                field: 'floor__floor_name', headerName: t('Floor'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.floor__floor_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.floor__floor_name} arrow><div>{cellvalues?.row?.floor__floor_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.floor__floor_name ? cellvalues?.row?.floor__floor_name : "-"}</div>
                    )
                }
            },
            {
                field: 'block__city', headerName: t('City'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.block__city?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.block__city} arrow><div>{cellvalues?.row?.block__city.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.block__city ? cellvalues?.row?.block__city : "-"}</div>
                    )
                }
            },
            {
                field: 'active_status', headerAlign: 'center', headerName: t('Status'), flex: 0.21, align: "center", renderCell: (cellvalues) => {
                    return (
                        <div style={{ color: cellvalues?.row?.active_status ? Colors.themeDark : Colors.red, backgroundColor: "#e2faf9", width: "4.5vw", padding: "0.5vw", borderRadius: "0.3vw", textAlign: "center", fontSize: "0.9vw", fontWeight: "600" }}>
                            {cellvalues?.row?.active_status ? "Active" : "InActive"}
                        </div>
                    )
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.15, sortable: false, hideable: false, align: "center", renderCell: (cellvalue) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <CommonEditButton size="1.5vw" onClick={this.handleEdit.bind(this, cellvalue.row)} />
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={SpotAreaList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={SpotAreaList.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t("Add Floor")}</p>
                            </div>
                            <div className="eMed_ipPackage_Div" style={{ overflow: "scroll" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                                    {this.renderTextField(this.state.SpotAreaCode, "SpotAreaCode", "Spot/Area Code", false)}
                                    {this.renderTextField(this.state.SpotAreaName, "SpotAreaName", "Spot/Area Name", true)}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                                    <div style={{ width: "50%", marginLeft: '0.65vw', marginRight: "0.65vw", marginTop: "1vw" }}>
                                        <Autocomplete
                                            disableClearable
                                            size='small'
                                            value={this.state.City_Name}
                                            options={this.state.cityList}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option}
                                            onChange={(event, newValue) => {
                                                this.setState({ City_Name: newValue }, () => {
                                                    let list = this.state.dupBlockList?.filter(item => item?.city === this.state.City_Name)
                                                    this.setState({ Block_List: list, Block_Name: list[0] }, () => {
                                                        if (this.state.Block_Name !== null) {
                                                            let data = this.state.Floor_List_New?.filter(item => (item?.block_id === this.state.Block_Name?.id && item?.block__city === this.state.City_Name))
                                                            this.setState({ Floor_List: data, Floor_Name: data[0] })
                                                        } else {
                                                            this.setState({ Floor_List: this.state.Floor_List_New, Floor_Name: this.state.Floor_List_New[0] })
                                                        }
                                                    })
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} required label={t('Select City')} />
                                            )}
                                        />
                                    </div>
                                    <div style={{ width: "50%", marginLeft: '0.65vw', marginRight: "0.65vw", marginTop: "1vw" }}>
                                        <Autocomplete
                                            disableClearable
                                            size='small'
                                            value={this.state.Block_Name}
                                            options={this.state.Block_List}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : (option?.block_name)}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    Block_Name: newValue,
                                                    City_Name: newValue?.city
                                                }, () => {
                                                    if (this.state.Block_Name !== null) {
                                                        let data = this.state.Floor_List_New?.filter(item => (item?.block_id === this.state.Block_Name?.id && item?.block__city === this.state.City_Name))
                                                        this.setState({ Floor_List: data, Floor_Name: data[0] })
                                                    } else {
                                                        this.setState({ Floor_List: this.state.Floor_List_New, Floor_Name: this.state.Floor_List_New[0] })
                                                    }
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} required label={t('Select Block')} />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                                    <div style={{ width: "50%", marginLeft: '0.65vw', marginRight: "0.65vw", marginTop: "1vw" }}>
                                        <Autocomplete
                                            disabled={this.state.Block_Name === null}
                                            disableClearable
                                            size='small'
                                            value={this.state.Floor_Name}
                                            options={this.state.Floor_List}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : (option?.floor_name)}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    Floor_Name: newValue,
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} required label={t('Select Floor')} />
                                            )}
                                        />
                                    </div>
                                    {this.renderTextField(this.state.Notes, "Notes", "Notes", false)}
                                </div>
                                <div style={{ paddingTop: "1vw", paddingLeft: "1vw", borderTop: "1px solid #E3E2E2", marginTop: "2vw" }}>
                                    <FormControlLabel checked={this.state.isActive} control={<Checkbox />} onClick={(e) => {
                                        this.setState({ isActive: e.target.checked })
                                    }} label="Active" />
                                </div>
                            </div>
                            <Stack direction="column" sx={{ backgroundColor: Colors.DataTblHeaderbg, height: "7vh" }} alignItems="center" justifyContent="center">
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button variant='contained' size="small" id="eMed_Config_btn" disabled={this.state.isLoader} onClick={() => { this.handleSave() }}>{t("Add")}</Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(AssetSpotArea)
import {
  Button, Modal, Box, TextField, Stack, Tooltip, IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import './inventory.css';
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

class InActiveInventory extends Component {

  constructor(props) {
    super(props)
    this.state = {
      inActiveList: [],
      popUpInActiveList: [],
      activeReason: "",
      activeStatus: false,
      activeBulkIds: [],
      activeOneId: "",
      count: [],
      checkboxSelection: [],
      popUpActiveList: [],
      page: 0,
      total: 0,
      pageSize: 10,
      count: 0,
      searchkey : '',
      disableBtn: false,
    }
  }

  componentDidMount() {
    this.getInActiveInventory()
  }

  componentWillReceiveProps(props){
    if(props.search_key != this.state.searchkey){
        this.setState({
            searchkey: props.search_key
        },() => {
            this.getInActiveInventory()
        })
    }
  }

  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      isSuccessMsg: false,
      isSuccessMsgText: "",
    })
  }

  onChangeHandler = (event, key) => {
    this.setState({
      [key]: event.target.value
    })
  }
  LoaderFunction=(key)=>{
    this.setState({
      isLoader:key
    })
 }

  getInActiveInventory = () => {
    try {
      this.LoaderFunction(true)
      this.setState({
        inActiveList: [],
        activeStatus: false
      })
      RestAPIService.getAll(Serviceurls.INVENTORY_ACTIVE_INVENTORY_GET + `?page_number=${this.state.page + 1}&page_size=${this.state.pageSize}&active_status=${false}&search_key=${this.state.searchkey}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              inActiveList: response.data.result,
              total: response.data.page_count,
              newPageCount: response.data.no_of_page
            },()=>{this.LoaderFunction(false)})
          }
        }).catch((error) =>{
          this.LoaderFunction(false)
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message
          })})
    } catch (error) {
      this.LoaderFunction(false)
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error
      })
    }
  }

  inActivePopUpOpen = (e, values) => {
    const { t } = this.props
    try {
      let bulkHash = {};
      if (this.state.checkboxSelection.length > 0) {
        this.state.checkboxSelection.forEach(element => {
          bulkHash[element] = element;
          if (bulkHash[values.row.product_ms_id]) {
            this.setState({ inActive: false })
            this.setState({
              isErrorMsg: true,
              isErrorMsgText: t("activeProducts")
            })
          } else {
            this.setState({
              inActive: true,
              activeOneId: values.row.product_ms_id,
              activeStatus: true,
              selectedBrandName: values.row.product_name
            })
          }
        })
      } else {
        this.setState({
          inActive: true,
          activeOneId: values.row.product_ms_id,
          activeStatus: true,
          selectedBrandName: values.row.product_name
        })
      }
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error
      })
    }
  }

  inActivePopUpClose = () => {
    this.setState({
      inActive: false,
      activeStatus: false
    })
  }

  InActiveViewOpen = (e, values) => {
    this.setState({
      InActiveView: true,
      id: values.row.product_ms_id,
      popUpActiveList: values.row

    })
  }

  InActiveViewClose = () => {
    this.setState({
      InActiveView: false
    })
  }

  postOneActiveList = () => {
    const { t } = this.props
    try {
      let data = {
        "product_ms_id": this.state.activeOneId,
        "active_status": this.state.activeStatus,
        "comment": this.state.activeReason
      }
      if (!data.comment) {
        let message = t("EnterReason")
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: message,
          disableBtn:false
        })
      } else {
        // this.setState({disableBtn: true})
        RestAPIService.create(data, Serviceurls.INVENTORY_ACTIVE_INVENTORY_POST).
          then((response) => {
            if (response.data.status === "success") {
              this.setState({
                isSuccessMsg: true,
                isSuccessMsgText: response.data.message,
                disableBtn:false
              })
              this.getInActiveInventory()
              this.setState({
                activeReason: "",
                activeStatus: false,
                inActive: false,
                checkboxSelection: false,
                disableBtn: false,
                activeOneId:''
              })
            } else {
              this.setState({
                isErrorMsg: true,
                isErrorMsgText: response.data.message,
                disableBtn: false,
              })
            }
          }).catch((error) => this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          }))
      }
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error,
        disableBtn: false,
      })
    }
  }

  postBulkActiveList = () => {
    const { t } = this.props
    try {
      let { checkboxSelection, activeBulkIds } = this.state;
      checkboxSelection.forEach(element => {
        activeBulkIds.push(element)
      })
      let data = {
        "product_ms_id": activeBulkIds,
        "active_status": this.state.activeStatus,
        "comment": this.state.activeReason
      }
      if (!data.comment) {
        let message = t("EnterReason")
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: message,
          disableBtn:false
        })
      } else {
        // this.setState({disableBtn: true})
        RestAPIService.create(data, Serviceurls.INVENTORY_ACTIVE_INVENTORY_BULK_POST).
          then((response) => {
            if (response.data.status === "success") {
              this.setState({
                isSuccessMsg: true,
                isSuccessMsgText: response.data.message,
                disableBtn:false
              })

              this.getInActiveInventory()
              this.setState({
                activeReason: "",
                activeStatus: false,
                inActive: false,
                disableBtn: false,
                activeBulkIds:[]
              })
            } else {
              this.setState({
                isErrorMsg: true,
                isErrorMsgText: response.data.message,
                disableBtn: false,
              })
            }
          }).catch((error) => this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          }))
      }
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error,
        disableBtn: false,
      })
    }
  }



  renderInActivePopUp = () => {
    const { t } = this.props
    return (
      <>
        <Modal open={this.state.inActive} onClose={this.inActivePopUpClose} >
          <Box className='inactive_popup'>
            <div className='inactive_popup_header'>
              <label className='inactive_popup_header_text'>{t('Do you want to Active Stock ?')}
              {this.state.count === 0 ? 
              <label style={{ marginLeft: "0.5vw" }}>{t("ProductName")} : <span style={{ color: "green" }}>
              <b>{this.state.selectedBrandName}</b></span></label> : null }
              </label>
              <div className='inactive_popup_close_card'>
                <img className='inactive_popup_close' src={ImagePaths.LabCloseButton.default}
                  onClick={this.inActivePopUpClose} alt='close' />
              </div>
            </div>
            <div className='inactive_popup_textfield'>
              <TextField
                sx={{ width: "57.5vw" }}
                multiline
                maxRows={6}
                label={t('Enter Reason')}
                value={this.state.activeReason}
                onChange={(e) => { this.onChangeHandler(e, "activeReason") }}
              />
            </div>
            <div className='inactive_popup_footer'>
              <div className='inactive_popup_left'>
                <label className='inactive_popup_text'>
                  <div className='inactive_bulletin'></div>
                  <span className='inactive_span'>{t('Clear')} | </span>
                  {t('Clear the form')}
                </label>
                <label className='inactive_popup_text'>
                  <div className='inactive_bulletin'></div>
                  <span className='inactive_span'>{t('Active')} | </span>
                  {t('To Active the Stock')}
                </label>
              </div>
              <div className='inactive_popup_right'>
                <Button
                  sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                  variant='outlined'
                  onClick={() => this.setState({ activeReason: "" })}
                >{t("Clear")}</Button>
                <Button
                  variant='contained'
                  emed_tid='InActive_Inventory_ActiveStock_Btn'
                  disabled={this.state.disableBtn}
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    this.setState({disableBtn:true} ,() => {
                      this.state.activeOneId !== "" ? this.postOneActiveList() : this.postBulkActiveList()
                    })
                  }}
                >{('Active Stock')}</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    )
  }


  renderInActiveViewpopUp = () => {
    const { t } = this.props
    let { popUpActiveList } = this.state
    return (
      <>
        <Modal open={this.state.InActiveView} onClose={this.InActiveViewClose} >
          <Box className='active_view_popup'>
            <div className='active_view_header'>
              <label className='active_view_header_text'>{t('View Product Detail')}</label>
            </div>
            <div className='active_view_middle_card'>
              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('MaterialType') + "*"}
                  value={popUpActiveList?.material_type}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('BrandName') + "*"}
                  value={popUpActiveList?.brand_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ProductName') + "*"}
                  value={popUpActiveList?.product_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ProductType') + "*"}
                  value={popUpActiveList?.product_type}
                  disabled
                />
              </div>
              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('Unit') + "*"}
                  value={popUpActiveList?.unit}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('LatestvendorName')}
                  value={popUpActiveList?.vendor_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('LatestManufName')}
                  value={popUpActiveList?.manufacturer_name}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('PhyBox')}
                  value={popUpActiveList?.physical_box_no}
                  disabled
                />
              </div>
              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('HSNCode')}
                  value={popUpActiveList?.hsn_code}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('GST') + "%"}
                  value={popUpActiveList?.gst_percentage}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ReorderThreshold')}
                  value={popUpActiveList?.reorder_threshold}
                  disabled
                />
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('ExpiryPeriod')}
                  value={popUpActiveList?.expiry_alert_period}
                  disabled
                />
              </div>
              <div className='active_view_textfield'>
                <TextField
                  size='small'
                  sx={{ marginRight: '1vw', width: "14.2vw" }}
                  label={t('UPC')}
                  value={popUpActiveList?.bar_code}
                  disabled
                />
              </div>
            </div>
            <div className='active_view_footer'>
              <div className='active_view_left'>
                <label className='active_view_text'>
                  <div className='inactive_bulletin'></div>
                  <span className='inactive_span'>{t('Close')}| </span>
                  {t('Returnstockpage')}
                </label>
              </div>
              <div className='active_view_right'>
                <Button
                  sx={{textTransform:"capitalize"}}
                  variant='contained'
                  onClick={this.InActiveViewClose}
                >{t('Close')}</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  activeButton = () => {
    const { t } = this.props
    try {
      let count = this.state.count === 0 ? "" : this.state.count
      if (count !== "") {
        this.setState({
          inActive: true,
          activeStatus: true
        })
      } else {
        let message = t("active")
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: message
        })
      }
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error
      })
    }
  }

  getInactiveInventoryexcel = () => {
    const {t} = this.props
    try {
      this.setState({disableBtn: true})
      RestAPIService.excelGet(Serviceurls.INVENTORY_ACTIVE_INVENTORY_GET + "?export_type=excel" +"&search_key=" + this.state.searchkey + "&active_status=false").
        then((response) => {
          if(response.data){
          var pom = document.createElement('a');
          var csvContent = response.data; //here we load our csv data 
    
          let filename = response.headers["content-disposition"].split("filename=")[1]
    
          var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', filename ? filename : 'registry.xlsx');
          pom.click();
          this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      })
    }
  }

  render() {
    this.state.inActiveList.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props
    const columns = [
      { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.06 },
      {
        field: 'material_type', headerName: t("MaterialType"), sortable: false, flex: 0.142,
        renderCell: (params) => (<div>{params?.row?.material_type?.length > 14 ? <Tooltip placement="top" title={params?.row?.material_type} arrow><div>{params?.row?.material_type}</div></Tooltip> : params?.row?.material_type ? params?.row?.material_type : "-"}</div>)
      },
      {
        field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.183,
        renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0,14) +"..."}</div></Tooltip> : params?.row?.brand_name ? params?.row?.brand_name : "-"}</div>)
      },
      {
        field: 'product_name', headerName: t('ProductName'), sortable: false, flex: 0.183,
        renderCell: (params) => (<div>{params?.row?.product_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.product_name} arrow><div>{params?.row?.product_name.slice(0,14) + "..." }</div></Tooltip>: params?.row?.product_name ? params?.row?.product_name : "-"}</div>)
      },
      { field: 'product_type', headerName: t('ProductType'), sortable: false, flex: 0.142,
      renderCell: (params) => (<div>{params?.row?.product_type?.length > 14 ? <Tooltip placement="top" title={params?.row?.product_type} arrow><div>{params?.row?.product_type.slice(0,14) + "..." }</div></Tooltip>: params?.row?.product_type ? params?.row?.product_type : "-"}</div>) },
      { field: 'stock_in_quantity', headerName: t('AvailableQuantity'), sortable: false, flex: 0.142,type:'number',
      renderCell: (params) => (<div>{params?.row?.stock_in_quantity?.length > 14 ? <Tooltip placement="top" title={params?.row?.stock_in_quantity} arrow><div>{params?.row?.stock_in_quantity.slice(0,14) + "..." }</div></Tooltip>: params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity : "0"}</div>) },
      {
        field: 'action', headerName: t("Action"), type: 'actions', sortable: false, flex: 0.142,
        renderCell: (cellvalues) => {
          return (
            <Stack direction="row">
              <Tooltip placement="top" title="Active"><div style={{ marginRight: "1vw", cursor: "pointer" }}>
                <IconButton onClick={(e) => this.inActivePopUpOpen(e, cellvalues)} disabled={!CheckAccessFunc("Laboratory", "Inventory", "Inactive Inventory", null, "Tab")?.editAccess}>
                <img style={{ height: '2.2vw', cursor: "pointer" }} src={ImagePaths.LabPlayButton.default}  alt='active' />
                </IconButton>
                </div></Tooltip>
              <Tooltip placement="top" title="View"><div style={{ cursor: "pointer" }}>
                <IconButton onClick={(e) => this.InActiveViewOpen(e, cellvalues)}>
                <img style={{ height: '2.2vw', cursor: "pointer" }} src={ImagePaths.LabViewButton.default}  alt='view' />
                </IconButton>
                </div></Tooltip>
            </Stack>
          )
        }
      }]
    return (
      <div className='active_tab_container'>
        <div className='active_tab_header_card'>
          <Button variant="contained" className="checkbox" onClick={this.activeButton} disabled={!CheckAccessFunc("Laboratory", "Inventory", "Inactive Inventory", null, "Tab")?.editAccess} >
            {`Active (${this.state.count})`}
          </Button>
          {/* <img className='mov_image_card' src={ImagePaths.LabPrintIcon.default} alt='print' /> */}
          <img style={{ height: '2.2vw', cursor: "pointer" }} emed_tid='Inactive_Inventory_Export_Btn' src={ImagePaths.LabUploadButton.default} onClick={()=>{
            if(!this.state.disableBtn){
              this.getInactiveInventoryexcel()
            }}} alt='upload' />
        </div>
        <div className='active_tab_table_container'>
          <DataGrid
            checkboxSelection={CheckAccessFunc("Laboratory", "Inventory", "Inactive Inventory", null, "Tab")?.editAccess}
            onSelectionModelChange={(checkbox) => {
              this.setState({
                checkboxSelection: checkbox,
                count: checkbox.length
              })
            }}
            hideFooterSelectedRowCount
            rows={this.state.inActiveList}
            rowCount={this.state.total}
            page={this.state.page}
            columns={columns}
            getRowId={(row) => row.product_ms_id}
            pageSize={this.state.pageSize}
            loading={this.state.isLoader}
            onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getInActiveInventory()) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getInActiveInventory())}
            rowsPerPageOptions={[10, 20, 30]}
            paginationMode="server"
            pagination
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay:()=>(
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
          />
        </div>
        {this.renderInActivePopUp()}
        {this.renderInActiveViewpopUp()}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : this.state.isSuccessMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.isSuccessMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
      </div>
    )
  }
}

export default withTranslation()(InActiveInventory)
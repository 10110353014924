import React, { Component } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Colors } from '../Styles/Colors';

export class AppBarMenus extends React.Component {
    constructor(props) {
        super(props)
    }
    static Menus = [
        // { id: 1, menuName: 'Settings', icon: <SettingsIcon sx={{ color: Colors.ThemeColor }} fontSize='medium' /> }
    ];
}
import React, { Component } from 'react'
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, FormGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import Loader from '../../../Components/Loader';

export default class AmountConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amountConfigData: [],
      departmentName: null,
      isEdit: false,
      selectedGroupId: null,
      selectedTestId: null,
      selectedDeptId: null,
      departmentList: [],
      newAmountConfigData: '[]',
      duplicateAmountConfig: []
    }
  }

  componentDidMount() {
    this.getDeptList()
  }

  getDeptList = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_DEPT_LIST + "?dept_name")
        .then((response) => {
          if (response.data.status === 'success') {
            var DeptData = response.data?.data ? response.data?.data : []
            this.setState({
              departmentList: DeptData,
              departmentName: DeptData[0] ? DeptData[0] : null,
              selectedDeptId: DeptData[0]?.id ? DeptData[0]?.id : null,
            },() => {
              var {departmentName} = this.state
              if(departmentName.dept_name){
                this.getAmountConfigurationData()
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  onChangeCheckbox = (e, key, index, depId, grpIndex, testIndex, id) => {
    try {
      let { amountConfigData, departmentList } = this.state
      let newAmountConfig = JSON.parse(this.state.newAmountConfigData)
      let oldAmount = null; let oldTestAmount = null;
      let grpId = null; let testId = null;
      let dep_index = newAmountConfig.findIndex((ele) => ele.dept_id === depId)
      switch (key) {
        case 'deptId':
          amountConfigData[index]['is_active'] = e.target.checked
          newAmountConfig[dep_index]['grp_data'].map((prevVal) => {
            oldAmount = prevVal.grp_amt
            grpId = prevVal.grp_id
            prevVal.test_data.map((prevtest) => {
              oldTestAmount = prevtest.test_amt
              testId = prevVal.test_id
            })
          })
          amountConfigData[index]['grp_data'].map((item) => {
            item.is_active = false
            item.grp_edit = false
            if (grpId === item.grp_id) {
              item.grp_amt = oldAmount
            }
            item.test_data.map((test) => {
              test.is_active = false
              test.test_edit = false
              if (testId === test.test_id) {
                test.test_amt = oldTestAmount
              }
            })
          })
          this.setState({ amountConfigData })
          break;
        case 'grpCheckBox':
          amountConfigData[index]['grp_data'][grpIndex]['is_active'] = e.target.checked
          amountConfigData[index]['grp_data'][grpIndex]['grp_edit'] = false
          if (!amountConfigData[index]['grp_data'][grpIndex]['is_active']) {
            newAmountConfig[dep_index]['grp_data'][grpIndex]['test_data'].map((prevVal) => {
              oldAmount = prevVal.test_amt
              testId = prevVal.test_id
            })
            if (amountConfigData[index]['grp_data'][grpIndex]['grp_id'] === newAmountConfig[dep_index]['grp_data'][grpIndex]['grp_id']) {
              amountConfigData[index]['grp_data'][grpIndex]['grp_amt'] = newAmountConfig[dep_index]['grp_data'][grpIndex]['grp_amt']
            }
            amountConfigData[index]['grp_data'][grpIndex]['test_data'].map((item) => {
              item.is_active = false
              item.test_edit = false
              if (item.test_id === testId) {
                item.test_amt = oldAmount
              }
            })
          }
          this.setState({ amountConfigData })
          break;
        case 'testCheckBox':
          amountConfigData[index]['grp_data'][grpIndex]['test_data'][testIndex]['is_active'] = e.target.checked
          amountConfigData[index]['grp_data'][grpIndex]['test_data'][testIndex]['test_edit'] = true
          if (!amountConfigData[index]['grp_data'][grpIndex]['test_data'][testIndex]['is_active']) {
            if (amountConfigData[index]['grp_data'][grpIndex]['test_data'][testIndex]['test_id'] === newAmountConfig[dep_index]['grp_data'][grpIndex]['test_data'][testIndex]['test_id']) {
              amountConfigData[index]['grp_data'][grpIndex]['test_data'][testIndex]['test_amt'] = newAmountConfig[dep_index]['grp_data'][grpIndex]['test_data'][testIndex]['test_amt']
            }
          }
          this.setState({ amountConfigData })
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeEditHandler = (key, deptIndex, grpIndex) => {
    try {
      let { amountConfigData } = this.state
      switch (key) {
        case 'grpEdit':
          amountConfigData[deptIndex]['grp_data'][grpIndex]['grp_edit'] = true
          this.setState({ amountConfigData })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getAmountConfigurationData = () => {
    try {
      var {departmentName} = this.state
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.NEW_LAB_AMOUNT_CONFIGURATION_API + "?dept_name=" + departmentName.dept_name) 
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              amountConfigData: response.data.data,
              newAmountConfigData: JSON.stringify(response.data.data),
              duplicateAmountConfig: response.data.data
            })
            this.LoaderFunction(false)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
            this.LoaderFunction(false)
          } else {
            this.errorMessage(error.message)
            this.LoaderFunction(false)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postAmountConfigData = (item) => {
    try {
      let grpActive = false
      let testactive = true
      item.grp_data.forEach(element => {
        if (element.is_active) {
          grpActive = true
          let valid = element.test_data.some(test => test.is_active === true) 
          if(!valid){
            testactive = false 
          }
        }})
      if (grpActive && testactive) {
        this.LoaderFunction(true)
        RestAPIService.create(item, Serviceurls.NEW_LAB_AMOUNT_CONFIGURATION_API)
          .then((response) => {
            if (response.data.status === 'success') {
              this.LoaderFunction(false)
              this.successMessage(response.data.message)
              this.getAmountConfigurationData()
            }
          }).catch((error) => {
            if (error?.response?.data.message) {
              this.LoaderFunction(false)
              this.errorMessage(error.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(error.message)
            }
          })
      } else if(!grpActive) {
        this.errorMessage('Select Atleast one Group')
      }else{
        this.errorMessage('Select Atleast one Test For Selected Group')
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderAmountConfigTableData = () => {
    return (
      <>{
        this.state.amountConfigData?.length > 0 ? this.state.amountConfigData?.map((item, deptIndex) => (
          <Box component={'div'} key={deptIndex} width={'91.5vw'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <FormGroup sx={{ marginLeft: '0.5vw' }}>
                <FormControlLabel control={<Checkbox checked={item?.is_active} onChange={(e) => this.onChangeCheckbox(e, 'deptId', deptIndex, item.dept_id)} size='small' />} label={item?.dept_name} />
              </FormGroup>
              <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mr={'1vw'}>
                <Button variant='contained' sx={{ width: '5vw' }}
                  disabled={!item.is_active}
                  className='eMed_Lab_act_btn'
                  size='small'
                  onClick={() => this.postAmountConfigData(item)}
                >Save</Button>
              </Box>
            </Box>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '20vw' }}>Group</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '20vw' }}>Amount</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '30vw' }}>Test Name</TableCell>
                <TableCell className='eMed_Main_Tbl_head'>Test Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
              <TableCell colSpan={4} key={deptIndex} sx={{ padding: '0vw' }}>
              {item?.grp_data?.length > 0 ? item?.grp_data?.map((grpData, grpIndex) => (
                <Table key={grpIndex}>
                  <TableCell sx={{ borderRight: '1px solid lightgray', width: '20vw', verticalAlign: 'top' }}>
                    <Box display={'flex'}>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox disabled={!item?.is_active} checked={grpData?.is_active}
                          onChange={(e) => { this.onChangeCheckbox(e, 'grpCheckBox', deptIndex, item.dept_id, grpIndex, grpData?.grp_id) }}
                          size='small' sx={{ padding: '0vw' }}
                        />} />
                      </FormGroup>
                      <Typography>{grpData?.grp_name}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ borderRight: '1px solid lightgray', width: '20vw', verticalAlign: 'top' }}>
                    <TextField
                      sx={{ width: '5.5vw' }}
                      inputProps={{ style: { height: '2vh' } }}
                      size='small'
                      disabled={grpData?.is_active ? false : true}
                      value={grpData?.grp_amt}
                      onChange={(e) => {
                        let { amountConfigData } = this.state
                        let value = e.target.value
                        if (CommonValidation.NumberWithDot(value) || value === '') {
                          amountConfigData[deptIndex]['grp_data'][grpIndex]['grp_amt'] = +value
                          this.setState({ amountConfigData })
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: '0vw' }}>
                  {grpData?.test_data?.map((testData, testIndex) => (
                    <Table key={testIndex}>
                      <TableCell sx={{ borderRight: '1px solid lightgray', width: '30vw', verticalAlign: 'top' }}>
                        <Box display={'flex'}>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox
                              checked={testData?.is_active}
                              disabled={!grpData?.is_active}
                              onChange={(e) => { this.onChangeCheckbox(e, 'testCheckBox', deptIndex, item.dept_id, grpIndex, testIndex) }}
                              size='small' sx={{ padding: '0vw' }}
                            />} />
                          </FormGroup>
                          <Typography>{testData?.test_name}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <TextField
                            disabled={testData?.is_active && grpData?.is_active ? false : true}
                            sx={{ width: '5.5vw', marginLeft: '0vw' }}
                            inputProps={{ style: { height: '2vh' } }}
                            size='small'
                            value={testData?.test_amt}
                            onChange={(e) => {
                              let { amountConfigData } = this.state
                              let value = e.target.value
                              if (CommonValidation.NumberWithDot(value) || value === '') {
                                amountConfigData[deptIndex]['grp_data'][grpIndex]['test_data'][testIndex]['test_amt'] = +value
                                this.setState({ amountConfigData })
                              }
                            }}
                          />
                        </Box>
                      </TableCell>
                    </Table>
                  ))}
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </Table>
              )) : null }
              </TableCell>
            </TableRow>
            
            </TableBody>
            </Table>
            
          </Box>
        )) : null
      }</>
    )
  }

  render() {
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }} padding={'0.5vw'}>
        <Box component={'div'} height={'79vh'} border={'1px solid lightgray'} sx={{ backgroundColor: 'white' }} overflow={'scroll'}>
          <Box component={'div'} borderBottom={'1px solid lightgray'} overflow={'scroll'}>
            <Autocomplete
              size='small'
              clearIcon
              options={this.state.departmentList}
              getOptionLabel={(option) => option?.dept_name}
              value={this.state.departmentName}
              onChange={(e, newValue) => {
                this.setState({ departmentName: newValue },() => {
                  this.getAmountConfigurationData()
                })               
              }}
              sx={{ width: '23vw', marginTop: '1vh', marginLeft: '0.5vw' }}
              renderInput={(params) => <TextField {...params} label='Select Department' onChange={(e) => {
                if(e.target.value === ""){
                  this.setState({
                    departmentName: this.state.departmentName
                  })
                }
              }}  size='small' />}
            />
          </Box>
          <Box component={'div'} height={'71vh'} overflow={'scroll'}>
            {this.renderAmountConfigTableData()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

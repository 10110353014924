import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import ShowComponents from "./ShowComponent";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";

export default class DocOcularResponser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            appointmentDate: this.props.appointmentDate,
            doctorID: this.props.doctorID,
            templateData: [],
            value: "One",
            LeftEye: {
                IPOcc: "",
                CH: "",
                IPOg: "",
                WS: "",
            },
            RightEye: {
                IPOcc: "",
                CH: "",
                IPOg: "",
                WS: "",
            },
            Leftchecked: false,
            Rightchecked: false,
            postLoad: false,
            templateDataLeft: [],
            templateDataRight: [],
            PreviousData: [],
            currentData: [],
            comparePopup: false,
            isEdit: false,
            disableLeft: false,
            disableRight: false,
        }
    }

    apiCalls = () => {
        this.getOcularResponseAnalyzer()
        this.getPreviousOcularResponse()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getOcularResponseAnalyzer = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DOC_OCULAR_RESPONSER + `?doctor_id=${states?.doctorID}&appointment_id=${states?.appointmentId}&patient_id=${states?.patientId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data ? response.data.data : [];

                        this.setState({
                            disableLeft: false,
                            disableRight: false,
                            value: "One",
                            Leftchecked: false,
                            Rightchecked: false,
                            LeftEye: {
                                IPOcc: "",
                                CH: "",
                                IPOg: "",
                                WS: "",
                            },
                            RightEye: {
                                IPOcc: "",
                                CH: "",
                                IPOg: "",
                                WS: "",
                            }
                        }, () => {
                            var left = []; var Right = [];
                            if (data[0] && data[1]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                                if (data[1]?.eye_type == "RE") {
                                    Right.push(data[1])
                                } else {
                                    left.push(data[1])
                                }
                            } else if (data[0]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                            }
                            this.setState({
                                templateDataLeft: left,
                                templateDataRight: Right,
                                currentData: data
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPreviousOcularResponse = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_OCULAR_RESPONSER + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}&previous=true`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.previous_data ? response.data.previous_data : [];
                        this.setState({
                            PreviousData: data
                        }, () => {
                            var left = []; var Right = []; var data = this.state.PreviousData
                            if (data[0] && data[1]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                                if (data[1]?.eye_type == "RE") {
                                    Right.push(data[1])
                                } else {
                                    left.push(data[1])
                                }
                            } else if (data[0]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                            }
                            this.setState({
                                oldDataLeft: left,
                                oldDataRight: Right
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    onEditHandler = (data) => {
        if (data.eye_type === "RE") {
            if (this.state.templateDataLeft?.length > 0 && !this.state.templateDataLeft[0]?.id) {
                let item = this.state.templateDataLeft[0]
                this.setState({
                    LeftEye: {
                        IPOcc: item.ipocc,
                        CH: item?.ch,
                        IPOg: item?.ipog,
                        WS: item?.ws
                    }
                })
            }
            this.setState({
                value: "One",
                openPopUp: true,
                RightEye: {
                    IPOcc: data.ipocc,
                    CH: data?.ch,
                    IPOg: data?.ipog,
                    WS: data?.ws,
                    id: data.id
                },
                disableLeft: true
            })
        } else {
            if (this.state.templateDataRight?.length > 0 && !this.state.templateDataRight[0]?.id) {
                let item = this.state.templateDataRight[0]
                this.setState({
                    RightEye: {
                        IPOcc: item.ipocc,
                        CH: item?.ch,
                        IPOg: item?.ipog,
                        WS: item?.ws
                    }
                })
            }
            this.setState({
                value: "Two",
                openPopUp: true,
                LeftEye: {
                    IPOcc: data.ipocc,
                    CH: data?.ch,
                    IPOg: data?.ipog,
                    WS: data?.ws,
                    id: data.id
                },
                disableRight: true
            })
        }
    }

    renderOldGlassPowerTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>IPOcc</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>IPOg</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>WS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.oldDataRight?.length > 0 ? this.state.oldDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipocc ? item?.ipocc : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ch ? item?.ch : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipog ? item?.ipog : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ws ? item?.ws : '-'}</TableCell>
                                </TableRow>
                            )) : null}
                            {this.state.oldDataLeft?.length > 0 ? this.state.oldDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipocc ? item?.ipocc : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ch ? item?.ch : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipog ? item?.ipog : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ws ? item?.ws : '-'}</TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRetinoscopicRefractionTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>IPOcc</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>IPOg</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>WS</TableCell>
                                {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.templateDataRight?.length > 0 ? this.state.templateDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipocc ? item?.ipocc : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ch ? item?.ch : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipog ? item?.ipog : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ws ? item?.ws : '-'}</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                            {this.state.templateDataLeft?.length > 0 ? this.state.templateDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipocc ? item?.ipocc : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ch ? item?.ch : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ipog ? item?.ipog : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.ws ? item?.ws : '-'}</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        var { LeftEye, RightEye } = this.state
        if ((LeftEye.IPOcc == "" && LeftEye.CH == "" && LeftEye.IPOg == "" && LeftEye.WS == "") && (RightEye.IPOcc == "" && RightEye.CH == "" && RightEye.IPOg == "" && RightEye.WS == "")) {
            return "Please Enter Any Value"
        } else {
            return null
        }
    }

    PostOcularResponse = () => {
        var { LeftEye, RightEye, appointmentId, patientId, doctorID } = this.state
        let data = {}
        try {
            if (
                (LeftEye.IPOcc != "" || LeftEye.CH != "" || LeftEye.IPOg != "" || LeftEye.WS != "") &&
                (RightEye.IPOcc != "" || RightEye.CH != "" || RightEye.IPOg != "" || RightEye.WS != "")
            ) {
                data = {
                    "doctor_id": doctorID,
                    "appointment_id": appointmentId,
                    "patient_id": patientId,
                    "ocular_response": [
                        {
                            id: LeftEye?.id ? LeftEye.id : null,
                            eye_type: "LE",
                            ipocc: LeftEye.IPOcc ? LeftEye.IPOcc : null,
                            ch: LeftEye.CH ? LeftEye.CH : null,
                            ipog: LeftEye.IPOg ? LeftEye.IPOg : null,
                            ws: LeftEye.WS ? LeftEye.WS : null,
                            comments: ""
                        },
                        {
                            id: RightEye?.id ? RightEye.id : null,
                            eye_type: "RE",
                            ipocc: RightEye.IPOcc ? RightEye.IPOcc : null,
                            ch: RightEye.CH ? RightEye.CH : null,
                            ipog: RightEye.IPOg ? RightEye.IPOg : null,
                            ws: RightEye.WS ? RightEye.WS : null,
                            comments: ""
                        }
                    ]
                }
            }
            else if (LeftEye.IPOcc != "" || LeftEye.CH != "" || LeftEye.IPOg != "" || LeftEye.WS != "") {
                data = {
                    "doctor_id": doctorID,
                    "appointment_id": appointmentId,
                    "patient_id": patientId,
                    "ocular_response": [
                        {
                            id: LeftEye?.id ? LeftEye.id : null,
                            eye_type: "LE",
                            ipocc: LeftEye.IPOcc ? LeftEye.IPOcc : null,
                            ch: LeftEye.CH ? LeftEye.CH : null,
                            ipog: LeftEye.IPOg ? LeftEye.IPOg : null,
                            ws: LeftEye.WS ? LeftEye.WS : null,
                            comments: ""
                        }
                    ]
                }
            }
            else if (RightEye.IPOcc != "" || RightEye.CH != "" || RightEye.IPOg != "" || RightEye.WS != "") {
                data = {
                    "doctor_id": doctorID,
                    "appointment_id": appointmentId,
                    "patient_id": patientId,
                    "ocular_response": [
                        {
                            id: RightEye?.id ? RightEye.id : null,
                            eye_type: "RE",
                            ipocc: RightEye.IPOcc ? RightEye.IPOcc : null,
                            ch: RightEye.CH ? RightEye.CH : null,
                            ipog: RightEye.IPOg ? RightEye.IPOg : null,
                            ws: RightEye.WS ? RightEye.WS : null,
                            comments: ""
                        }
                    ]
                }
            }
            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_OCULAR_RESPONSER)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.handleClear()
                            this.setState({ postLoad: false, openPopUp: false, comparePopup: false, isEdit: false }, () => {
                                this.getOcularResponseAnalyzer()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    CopyPreviousData = () => {
        var left = []; var Right = []; var data = this.state.PreviousData
        if (data[0] && data[1]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
            if (data[1]?.eye_type == "RE") {
                Right.push(data[1])
            } else {
                left.push(data[1])
            }
        } else if (data[0]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
        }

        this.setState({
            LeftEye: {
                IPOcc: left[0]?.ipocc ? left[0]?.ipocc : "",
                CH: left[0]?.ch ? left[0]?.ch : "",
                IPOg: left[0]?.ipog ? left[0]?.ipog : "",
                WS: left[0]?.ws ? left[0]?.ws : "",
                id: this.state?.templateDataLeft[0]?.id
            },
            RightEye: {
                IPOcc: Right[0]?.ipocc ? Right[0]?.ipocc : "",
                CH: Right[0]?.ch ? Right[0]?.ch : "",
                IPOg: Right[0]?.ipog ? Right[0]?.ipog : "",
                WS: Right[0]?.ws ? Right[0]?.ws : "",
                id: this.state?.templateDataRight[0]?.id
            }
        }, () => { this.PostOcularResponse() })
    }

    handleClear = () => {
        if (this.state.isEdit) {
            this.setState({
                Leftchecked: false,
                Rightchecked: false,
                LeftEye: {
                    IPOcc: "",
                    CH: "",
                    IPOg: "",
                    WS: "",
                    id: this.state.LeftEye?.id
                },
                RightEye: {
                    IPOcc: "",
                    CH: "",
                    IPOg: "",
                    WS: "",
                    id: this.state.RightEye?.id
                },
                disableRight : false,
                disableLeft :false
            })
        } else {
            this.setState({
                LeftEye: {
                    IPOcc: "",
                    CH: "",
                    IPOg: "",
                    WS: "",
                },
                RightEye: {
                    IPOcc: "",
                    CH: "",
                    IPOg: "",
                    WS: "",
                },
                Leftchecked: false,
                Rightchecked: false,
                postLoad: false,
                value: "One",
                disableLeft: false,
                disableRight: false,
                isEdit: false
            })
        }
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "55vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Ocular Response Analyzer</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Ocular Response</Typography>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData() }}>
                                            <Tooltip placement='top' title='Copy'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>{this.renderOldGlassPowerTable()}</div>
                                </div>
                            </Box>
                            <Box component={'div'} sx={{ marginTop: "0.65vw" }}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Ocular Response Analyzer</Typography>
                                    {this.renderRetinoscopicRefractionTable()}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    scrollToRef = (ref) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    CopyLefttoRight = (value) => {
        let { LeftEye } = this.state
        let states = this.state
        if (value) {
            if (this.state.templateDataRight?.length > 0) {
                states.RightEye["id"] = this.state.templateDataRight[0]?.id
            }
            states.RightEye["IPOcc"] = LeftEye.IPOcc  
            states.RightEye["CH"] = LeftEye.CH
            states.RightEye["IPOg"] = LeftEye.IPOg
            states.RightEye["WS"] = LeftEye.WS
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                value: this.state.isEdit ? "Two" : "One",
                states
            })
        } 
        else {
            states.RightEye["IPOcc"] = ""
            states.RightEye["CH"] = ""
            states.RightEye["IPOg"] = ""
            states.RightEye["WS"] = ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                states
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let { RightEye } = this.state
        let states = this.state
        if (value) {
            if (this.state.templateDataLeft?.length > 0) {
                states.LeftEye["id"] = this.state.templateDataLeft[0]?.id
            }
            states.LeftEye["IPOcc"] = RightEye.IPOcc   
            states.LeftEye["CH"] = RightEye.CH
            states.LeftEye["IPOg"] = RightEye.IPOg
            states.LeftEye["WS"] = RightEye.WS
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                value: this.state.isEdit ? "One" : "Two",
                states
            })
        } 
        else {
            states.LeftEye["IPOcc"] = ""
            states.LeftEye["CH"] = ""
            states.LeftEye["IPOg"] = ""
            states.LeftEye["WS"] = ""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                states
            })
        }
    }

    onChangeHandler = (e, names, eyeType) => {
        try {
            let value = e.target.value
            let states = this.state
            switch (names) {
                case 'IPOcc':
                    if ((CommonValidation.DecimalNumberThreeDigit(value) && value <= 10000 && (value != "00")) || value === '') {
                        if(eyeType === "leftEye"){
                            states["LeftEye"][names] = value
                            this.setState({ states })   
                        }else{
                            states["RightEye"][names] = value
                            this.setState({ states })  
                        }
                    }
                    break;
                case 'CH':
                    if ((CommonValidation.DecimalNumberThreeDigit(value) && value <= 10000 && (value != "00")) || value === '') {
                        if(eyeType === "leftEye"){
                            states["LeftEye"][names] = value
                            this.setState({ states })   
                        }else{
                            states["RightEye"][names] = value
                            this.setState({ states })  
                        }
                    }
                    break;
                case 'IPOg':
                    if ((CommonValidation.DecimalNumberThreeDigit(value) && value <= 10000 && (value != "00")) || value === '') {
                        if(eyeType === "leftEye"){
                            states["LeftEye"][names] = value
                            this.setState({ states })   
                        }else{
                            states["RightEye"][names] = value
                            this.setState({ states })  
                        }
                    }
                    break;
                case 'WS':
                    if ((CommonValidation.DecimalNumberThreeDigit(value) && value <= 10000 && (value != "00")) || value === '') {
                        if(eyeType === "leftEye"){
                            states["LeftEye"][names] = value
                            this.setState({ states })   
                        }else{
                            states["RightEye"][names] = value
                            this.setState({ states })  
                        }
                    }
                    break;
                default:
                    states[names] = value
                    this.setState({ states })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTextField = (name, value, marginLeft, marginTop, eyeType, disabled) => {
        let disable = disabled ? true : false
        return (
            <Box sx={{ marginLeft: marginLeft, marginTop: marginTop }}>
                <TextField
                    size='small'
                    sx={{ width: "15vw" }}
                    autoComplete='off'
                    value={value}
                    // label ={name}
                    disabled={disable}
                    onChange={(e) => {
                        this.onChangeHandler(e, name, eyeType)
                        this.setState({ Leftchecked: false, Rightchecked: false})
                    }}
                    InputProps={{
                        endAdornment: <Typography style={{ backgroundColor: "#f0f0f0", padding:  "1vh 0.5vw" , marginLeft: "11.3vw", position: "absolute", borderRadius: "0vw 0.25vw 0.25vw 0vw", width: '3.6vw' }}>{name}</Typography>,
                        emed_tid: `Field_${name}`
                    }}
                />
            </Box>
        )
    }

    OcularResponserPopup = () => {
        let disableLeft = this.state.disableLeft ? true : Object.values(this.state.RightEye).every(value => value === "") ? true : false
        let disableRight = this.state.disableRight ? true : Object.values(this.state.LeftEye).every(value => value === "") ? true : false
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{padding :"1vw", width: "42vw", height: "72vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Ocular Response Analyzer</Typography>
                            <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ openPopUp: false }, () => { this.handleClear() })} >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <hr />
                        <Box component={'div'} style={{display: "flex", flexDirection : "row"}}>
                            {/* <Tabs
                                value={this.state.value}
                                onChange={(e, newValue) =>
                                    this.setState({
                                        value: newValue
                                    })}
                            >
                                <Tab disabled={this.state.disableRight} value="One" label={"Right Eye"} className='eMed_tab_header' />
                                <Tab disabled={this.state.disableLeft} value="Two" label={"Left Eye"} className='eMed_tab_header' />
                            </Tabs> */}
                          
                            <Box component={'div'} style={{ display: "flex", flexDirection :"column", alignItems: "center", padding: "1.5vw", width: "100%", justifyContent: "center" }}>
                                <Typography  fontWeight={"bold"}>{"Right Eye"}</Typography>
                                {this.renderTextField('IPOcc',   this.state.RightEye.IPOcc , "1vw","1vw","rightEye",this.state.disableRight)}
                                {this.renderTextField('CH',  this.state.RightEye.CH , "1vw","1vw","rightEye",this.state.disableRight)}
                                {this.renderTextField('IPOg',  this.state.RightEye.IPOg  , "1vw", "1vw","rightEye",this.state.disableRight)}
                                {this.renderTextField('WS',   this.state.RightEye.WS  , "1vw", "1vw","rightEye",this.state.disableRight)}
                            </Box>   
                            <Box component={'div'} style={{ display: "flex", flexDirection :"column", alignItems: "center", padding: "1.5vw", width: "100%", justifyContent: "center" }}>
                                <Typography fontWeight={"bold"}>{"Left Eye"}</Typography>
                                {this.renderTextField('IPOcc',  this.state.LeftEye.IPOcc, "1vw","1vw", "leftEye", this.state.disableLeft)}
                                {this.renderTextField('CH',  this.state.LeftEye.CH, "1vw","1vw", "leftEye",this.state.disableLeft)}
                                {this.renderTextField('IPOg',  this.state.LeftEye.IPOg, "1vw", "1vw", "leftEye",this.state.disableLeft)}
                                {this.renderTextField('WS',   this.state.LeftEye.WS, "1vw", "1vw", "leftEye",this.state.disableLeft)}
                            </Box>
                           
                        </Box>
                          <Box component={'div'} sx={{ marginLeft: "3.6vw" }} style={{ display: "flex", flexDirection: "row",justifyContent:"space-between" }}>
                                <FormControlLabel  control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} disabled ={disableLeft || this.state.isEdit} />} label="Copy to Left Eye (LE)" />
                                <FormControlLabel sx={{marginRight :"5vw"}} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} disabled ={disableRight || this.state.isEdit} />} label="Copy to Right Eye (RE)" /> 

                        </Box>
                        <Box>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.handleClear() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.PostOcularResponse() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                  
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: Colors.Background, height: "3.5vw" }}
                        >
                            <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} width={"100%"}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Ocular Response Analyzer</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mb: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                                            <Tooltip placement='top' title='Copy Previous Ocular Response'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0) && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                            <Tooltip placement='top' title='Compare'>
                                                <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                        <Button
                                            disabled={this.state.isLocked || this.props.isLocked || this.state?.currentData?.length > 1}
                                            variant='contained'
                                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                            onClick={() => {
                                                this.setState({ openPopUp: true })
                                            }}
                                        >Add</Button>
                                    </div>
                                </Box>
                                <Box component={'div'}>
                                    {this.renderRetinoscopicRefractionTable()}
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {this.OcularResponserPopup()}
                    {this.PreviousPowerPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents >
        )
    }

}
import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, Grid, IconButton } from "@mui/material";
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
class AppointmentConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AppointmentDetails: [],
            selectedItem: {},
            current_page: 0,
            rowsPerPage: 10,
            deletedPopup: false,
            deleteId: null,
            appointment_type: "",
            isEdit: false,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ""
        }
    }
    componentDidMount() {
        this._getAppointmentDetails();
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getAppointmentDetails = () => {
        this.setState({
            AppointmentDetails: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentDetails: response.data.data,
                        }, () => {
                            this.state.AppointmentDetails.forEach((item, i) => {
                                item.index = i
                            })
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    _postActiveStatus(type_id, active_status) {
        try {
            RestAPIService.delete((`${Serviceurls.FO_CONFIG_OPAPPOINTMENT}?type_id=${type_id}&active_status=${active_status}`))
                .then(response => {
                    if (response.data.status === "successs") {
                        this.responseSuccessErrorMessage(response.data.message, true);
                        this._getSpecializationDetails();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    handleEdit = (value) => {
        let { selectedItem } = this.state
        selectedItem = JSON.parse(JSON.stringify(value));
        delete selectedItem.index;
        this.setState({
            selectedItem,
            isEdit: true
        })
    }

    handleDelete = (value) => {
        if(!this.state.isEdit){
        this.setState({
            deletedPopup: true,
            deleteId: value.id,
            appointment_type: value.appoinment_type
        })
    }}

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            deleteId: null,
            appointment_type: ""
        })
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.FO_CONFIG_OPAPPOINTMENT}?type_id=${this.state.deleteId ? this.state.deleteId : 0}&active_status=false`)
                .then(response => {
                    if (response.data.status === "successs") {
                        this.setState({
                            deletedPopup: false,
                            deleteId: null,
                            appointment_type: ""
                        }, () => {
                            this.responseSuccessErrorMessage(response.data.message, true);
                            this._getAppointmentDetails();
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    renderTextBox(label, statekey, value, width) {
        const { t } = this.props;
        let { selectedItem } = this.state;
        return (
            <div id="eMed_srvConfig_divs">
                {
                    <TextField
                        sx={{ width: width ? width : "29.8vw" }}
                        autoComplete="off"
                        size = "small"
                        label={t(label)}
                        name={statekey}
                        value={value ? value : ""}
                        required
                        onChange={(event) => {
                            let value = event.target.value.trimStart();
                            let isValid = CommonValidation.alphabetOnly(value)
                            if (value === "" || isValid && value.length <= 60) {
                                selectedItem[event.target.name] = value;
                            }
                            this.setState({
                                selectedItem
                            })
                        }}
                    />
                }
            </div>
        )
    }
    handleClear = () => {
        this.setState({
            selectedItem: {},
            isEdit: false
        })
    }
    handleSave = () => {
        const { t } = this.props
        let { selectedItem, AppointmentDetails} = this.state;
        let isAvl = true;
        let Message = "";
        let exist = AppointmentDetails?.find(item => item["appoinment_type"] === selectedItem.appoinment_type) !== undefined;
        if (!selectedItem.appoinment_type) { isAvl = false; Message = t("Fillmandatory") }
        else if(exist) {isAvl = false; Message = `${t('AppointmentName')} is ${t("AlreadyExist")}`}
        if (isAvl) {
            selectedItem["type_id"] = selectedItem["id"];
            selectedItem["appointment_type"] = selectedItem["appoinment_type"]
            delete selectedItem.id;
            delete selectedItem.appoinment_type;
            delete selectedItem.is_active;
            // api call
            let datas = JSON.parse(JSON.stringify(selectedItem))
            try {
                this.LoaderFunction(true)
                RestAPIService.create(datas, Serviceurls.FO_CONFIG_OPAPPOINTMENT)
                    .then(response => {
                        if (response.data.status === "successs") {
                            this.handleClear();
                            this._getAppointmentDetails();
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(response.data.message, true);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.LoaderFunction(false)
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage(t(Message), false)
        }
    }
    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
      }
    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, AppointmentDetails, selectedItem } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.10, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'appoinment_type', headerName: t('AppointmentTypes'), flex: 0.70 },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.20 ,sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
                    let value = cellvalues.row;
                    return (
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            {
                                 value["is_mandatory"] ?
                                    <Tooltip placement="top" title={value.is_active ? t("Active") : t("InActive")} arrow>
                                        <IconButton size="small"
                                        disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Appointment Type", "SubTab")?.editAccess}
                                        onClick={() => {
                                            AppointmentDetails[value.index]["is_active"] = !(AppointmentDetails[value.index]["is_active"])
                                            this.setState({ AppointmentDetails }, () => { this._postActiveStatus(this.state.AppointmentDetails[value.index]["id"], this.state.AppointmentDetails[value.index]["is_active"]) })
                                        }}><img id="eMed_activeBtn" src={
                                            CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Appointment Type", "SubTab")?.editAccess ?
                                            (value.is_active ? ImagePaths.ActiveBox.default : ImagePaths.InActiveBox.default) : ImagePaths.DisabledActiveBox.default} alt = {value.is_active ? t("Active") : t("InActive")} /></IconButton>
                                    </Tooltip>
                                    :
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                                        <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Appointment Type", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleEdit.bind(this, value)} />
                                        <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Appointment Type", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleDelete.bind(this, value)} />
                                    </Stack>
                            }
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container" sx={{backgroundColor: Colors.configHomeBg}}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={AppointmentDetails}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={AppointmentDetails.length}
                                page={current_page}
                                headerHeight = {40}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                                />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t('AppointmentConfiguration')}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv">
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    {this.renderTextBox('AppointmentType', "appoinment_type", selectedItem.appoinment_type)}
                                </Stack>
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Appointment Type", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.appointment_type ? this.state.appointment_type : "-"} ?`}
                        DeleteNotify={t("effectEntireSetting")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(AppointmentConfig);
import { Autocomplete, Box, Button, Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import '../../ClinicalNotes/DoctorNotes.css'
import { Colors } from '../../../../Styles/Colors';
import { DurationCounts, DurationDays, DurationsList } from '../../../../Utility/Constants';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';

class DocSymptomsRight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SymptomsText: "",
            DurationType: "1",
            DurationCount: null,
            Description: "",
            FrequentData: [],
            SymptomsSuggList: [],
            SelectedID: null,
            SymSearchedKey: "",
            SymptomsList: [],
            isDeleteClicked: false,
            SelectedFreq: {},
            SelectedFreqIndex: null,
            HistorySymptom: "",
            presentComplaint: "",
            TouchedHistoryText : false
        }
    }

    componentDidMount() {
        if (this.props.EditSymptomsData && Object.keys(this.props.EditSymptomsData).length !== 0) {
            this.setState({
                SymptomsText: this.props.EditSymptomsData?.symptom,
                DurationType: this.props.EditSymptomsData?.duration_mode,
                DurationCount: +this.props.EditSymptomsData?.duration,
                Description: this.props.EditSymptomsData?.symptom_desc,
                SelectedID: this.props.EditSymptomsData?.id,
                HistorySymptom: this.props.EditSymptomsData?.presenting_complaints_history,
                presentComplaint: this.props.EditSymptomsData?.presenting_complaints,
            }, () => {
                this.props.ClearPropsData()
            })
        }
        this.GetSymptomsFrequents(true)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.EditSymptomsData !== this.props.EditSymptomsData && this.props.EditSymptomsData && Object.keys(this.props.EditSymptomsData).length !== 0) {
            let Filter = this.state.FrequentData.find((list) => (list === CommonValidation.removeSpace(this.props.EditSymptomsData?.symptom)));
            if (Filter === undefined) {
                this.state.FrequentData.push(this.props.EditSymptomsData?.symptom)
                this.setState({ FrequentData: this.state.FrequentData })
            } else {
                this.setState({ SymptomsText: this.props.EditSymptomsData?.symptom })
            }
            this.setState({
                SymptomsText: this.props.EditSymptomsData?.symptom,
                DurationType: this.props.EditSymptomsData?.duration_mode,
                DurationCount: +this.props.EditSymptomsData?.duration,
                Description: this.props.EditSymptomsData?.symptom_desc,
                SelectedID: this.props.EditSymptomsData?.id,
                HistorySymptom: this.props.EditSymptomsData?.presenting_complaints_history,
                presentComplaint: this.props.EditSymptomsData?.presenting_complaints,
            }, () => { this.props.ClearPropsData() })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    ClearData() {
        if(this.state.SelectedID !== null){
            this.props.CallgetApi('symptoms')
        }
        this.setState({
            SymptomsText: "",
            DurationType: "1",
            DurationCount: null,
            Description: "",
            SelectedID: null,
            SymSearchedKey: "",
            isDeleteClicked: false,
            SelectedFreq: {},
            SelectedFreqIndex: null,
            HistorySymptom: "",
            presentComplaint: "",
            TouchedHistoryText : false
        })
    }

    PostSymtoms = () => {
        try {
            var states = this.state
            var data = {
                "symptom": CommonValidation.removeSpace(states.SymptomsText),
                "appointment_id": this.props?.PatientData?.appointment_id,
                "patient_id": this.props?.PatientData?.patient_id,
                "duration": states.DurationCount,
                "duration_mode": states.DurationType,
                "symptom_desc": CommonValidation.removeSpace(states.Description),
                
            }
            if(this.state.TouchedHistoryText){
                data["presenting_complaints_history"] = CommonValidation.removeSpace(states.HistorySymptom)
                data["presenting_complaints"] = CommonValidation.removeSpace(states.presentComplaint)
            }
            if (this.state.SelectedID !== null) { data["id"] = this.state.SelectedID }

            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMS).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.ClearData()
                        this.GetSymptomsFrequents()
                        this.props.CallgetApi('symptoms')
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "failure") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    GetSymptomsSuggestions(value) {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMNS_FREQ + '?symptom=' + value).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            SymptomsSuggList: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    GetSymptomsFrequents(AddItemIfNot = false) {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMNS_FREQ).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            FrequentData: response.data.data
                        }, () => {
                            if (AddItemIfNot && this.state.SymptomsText) {
                                let Filter = this.state.FrequentData.find((list) => (list === this.state.SymptomsText));
                                if (Filter === undefined) {
                                    this.state.FrequentData.push(this.state.SymptomsText)
                                    this.setState({ FrequentData: this.state.FrequentData })
                                }
                            }
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    Validation() {
        var states = this.state
        if (CommonValidation.removeSpace(states.SymptomsText) !== "" && states?.DurationCount) {
            this.PostSymtoms()
        } else {
            if (CommonValidation.removeSpace(states.SymptomsText) === "") {
                this.errorMessage("Enter Symptom Name")
            } else if (!states?.DurationCount) {
                this.errorMessage("Select Duration of Symptom")
            }
        }
    }

    popupClose() {
        this.setState({ isDeleteClicked: false })
    }
    DelectSymptomFreq() {
        try {
            var states = this.state
            RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMNS_FREQ + "?symptom=" + states.SelectedFreq).
                then((response) => {
                    if (response.data.status === "success") {
                        this.state.FrequentData.splice(states.SelectedFreqIndex, 1)
                        this.setState({ FrequentData: this.state.FrequentData, isDeleteClicked: false })
                        if (this.state.SymptomsText === states.SelectedFreq) { this.setState({ SymptomsText: "" }) }
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props
        let states = this.state
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{t("Symptoms")}</Typography>
                <Box height={"56.3vh"} sx={{ overflowY: 'scroll' }}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Autocomplete
                            noOptionsText={states.SymSearchedKey === '' ? 'Type to Search...' : 'No Data Found...'}
                            options={states.SymptomsSuggList}
                            getOptionLabel={(options) => options}
                            onChange={(e, value) => {
                                let Filter = this.state.FrequentData.find((list) => (list === value));
                                if (Filter === undefined && value) {
                                    this.state.FrequentData.push(value)
                                    this.setState({ FrequentData: this.state.FrequentData, SymptomsText: value, SymSearchedKey: "" , SymptomsSuggList : []})
                                } else {
                                    this.setState({
                                        SymptomsText: value,
                                        SymSearchedKey: "",
                                        SymptomsSuggList : []
                                    })
                                }
                            }}
                            clearIcon={false}
                            size='small'
                            sx={{ width: "26vw" }}
                            className='eMed_DocNotes_TextFields'
                            disablePortal
                            id="combobox-demo"
                            value={states.SymSearchedKey}
                            renderInput={(params) => <TextField sx={{ width: "26vw" }} onChange={(event) => {
                                this.setState({
                                    SymSearchedKey: event.target.value
                                }, ()=>{ this.GetSymptomsSuggestions(event.target.value)})
                            }}
                                {...params}
                                placeholder="Search Symptoms"
                            />}
                        />
                        <Button sx={{ minWidth: '2vw', marginLeft: '-1.5vw' }} disabled={CommonValidation.removeSpace(this.state.SymSearchedKey) === "" || this.state.withOutAppointment?.is_ip} variant='contained' size='small' color='primary' onClick={() => {
                            let Filter = this.state.FrequentData.find((list) => (list === CommonValidation.removeSpace(this.state.SymSearchedKey)));
                            if (Filter === undefined) {
                                this.state.FrequentData.push(this.state.SymSearchedKey)
                                this.setState({ FrequentData: this.state.FrequentData, SymptomsText: CommonValidation.removeSpace(this.state.SymSearchedKey), SymSearchedKey: "" })
                            } else {
                                this.setState({ SymptomsText: CommonValidation.removeSpace(this.state.SymSearchedKey), SymSearchedKey: "" })
                            }
                        }} >
                            <AddIcon />
                        </Button>
                    </Box>

                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        {
                            this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                    size='small'
                                    className={`eMed_chip ${this.state.SymptomsText === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                    key={index}
                                    label={item}
                                    variant={this.state.SymptomsText === item ? 'contained' : "outlined"}
                                    onClick={() => { this.setState({ SymptomsText: item }) }}
                                    onDelete={() => { this.setState({ isDeleteClicked: true, SelectedFreq: item, SelectedFreqIndex: index }) }}
                                />
                            )) : null
                        }

                    </Box>

                    <Box className='emed_Duration_head'>
                        <Typography fontWeight={600} color={Colors.SecondaryText}>{t("Duration")}</Typography>
                        <FormControl size='small' sx={{ width: '8vw' }}>
                            <InputLabel>{t("Duration")}</InputLabel>
                            <Select
                                sx={{ backgroundColor: Colors.ThemeLightColor }}
                                size='small'
                                value={this.state.DurationType}
                                label={t("Duration")}
                                onChange={(e) => {
                                    this.setState({ DurationType: e.target.value })
                                }}
                            >
                                {
                                    DurationDays?.length > 0 ?
                                        DurationDays.map((list, index) => (
                                            <MenuItem key={index} value={list.value}>{list.label}</MenuItem>
                                        )) : null
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='eMed_DocNotes_Duration_Warpper'>
                        {
                            DurationCounts.map((list, index) => (
                                <Box
                                    key={index}
                                    sx={{ backgroundColor: this.state.DurationCount === list ? Colors.themeDark : "#FAF9F9", color: this.state.DurationCount === list ? Colors.white : Colors.black, borderColor: this.state.DurationCount === list ? Colors.themeDark : Colors.lightGray }}
                                    className='eMed_DocNotes_Duration_Counts' onClick={() => { this.setState({ DurationCount: list }) }}>{list}</Box>
                            ))
                        }
                    </Box>
                    <Box>
                        <Typography className='eMed_refdoc_title'>{t("Description")}</Typography>
                        <TextField
                            size='small'
                            className='eMed_DocNotes_TextFields'
                            multiline={true}
                            rows={3}
                            label={t("Description ( Max 150 characters)")}
                            inputProps={{ maxLength: 150 }}
                            value={this.state.Description}
                            onChange={(e) => {
                                if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                                    this.setState({ Description: e.target.value })
                                }
                            }}
                        />
                        {this.props?.UserInfo?.service_type === "PD" ?
                            <Box>
                                <Box>
                                    <Typography className='eMed_refdoc_title'>{t("Presenting Complaints")}</Typography>
                                    <TextField
                                        size='small'
                                        className='eMed_DocNotes_TextFields'
                                        multiline={true}
                                        rows={3}
                                        label={t("Presenting Complaints ( Max 50 characters)")}
                                        inputProps={{ maxLength: 50 }}
                                        value={this.state.presentComplaint}
                                        onChange={(e) => {
                                            if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                                                this.setState({ presentComplaint: e.target.value, TouchedHistoryText : true })
                                            }
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography className='eMed_refdoc_title'>{t("History of Presenting Complaints")}</Typography>
                                    <TextField
                                        size='small'
                                        className='eMed_DocNotes_TextFields'
                                        multiline={true}
                                        rows={3}
                                        label={t("History of Presenting Complaints ( Max 50 characters)")}
                                        inputProps={{ maxLength: 50 }}
                                        value={this.state.HistorySymptom}
                                        onChange={(e) => {
                                            if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                                                this.setState({ HistorySymptom: e.target.value, TouchedHistoryText : true })
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> : null}

                    </Box>
                </Box>
                <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.Validation() }}>{t("Add")}</Button>
                </Stack>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isDeleteClicked ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")}  ${t("Symptom")} ${this.state.SelectedFreq ? this.state.SelectedFreq : "-"} From Suggestion List ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.DelectSymptomFreq.bind(this)}
                /> : null}
            </Box>
        )
    }
}
export default withTranslation()(DocSymptomsRight)

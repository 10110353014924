import { QuestionMark } from '@mui/icons-material'
import { Box, Button, Fab, Grid, Modal, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import '../../FrontOffice/FOReports/reports.css'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'

class ReportsHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            newReport: ""
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    reportsList = (item) => {
        return (
            <>
                <Box component={'div'} className='eMed_rts_card'>
                    <Paper className='eMed_rts_content'>
                        <Grid container className='eMed_rts_content_top'>
                            <Grid item xs={12} className='eMed_rts_txt_one'>
                                <Stack display={'flex'} flexDirection='row'>
                                    <img src={item?.icon} alt="" className='eMed_rts_icon' />
                                    <Typography fontWeight={'600'}>{item?.title}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} className='eMed_rts_content_btm'>
                                {item?.reports.map((list, index) => {
                                    return <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                        <Typography display={index === 0 ? "none" : 'inline'} color={Colors.themeDark}>|</Typography>
                                        <Button key={index} className='eMed_reports_text_btn' emed_tid = {`Phm_rpt_${list.report_names.replace(/ /g, "")}`}
                                            onClick={() => {
                                                this.props.history.push({ pathname: list.path })
                                            }}>{list.report_names}</Button>
                                    </Box>
                                })}
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </>
        )
    }

    handleOpen = () => {
        this.setState({ openPopUp: true })
    }

    handleClose = () => {
        this.setState({ openPopUp: false, newReport: "" })
    }

    PostExtraReportText = () => {
        try {
            let data = { "new_report": this.state.newReport }
            RestAPIService.create(data, Serviceurls.FO_REPORTS_POST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({
                            openPopUp: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderReportsPopup = () => {
        const { t } = this.props
        return (
            <>
                <Modal open={this.state.openPopUp} >
                    <Box className='eMed_rts_popup'>
                        <Box className='eMed_rts_home_header'>
                            <Typography component={'label'} variant='h6' fontWeight={600}>{t("extraReports")}</Typography>
                            <Typography component={'p'} className='eMed_rts_home_txt_one'>{t("reportsNeedType")}</Typography>
                        </Box>
                        <Box component={'div'} margin="1vw" >
                            <TextField
                                variant='standard'
                                rows={6}
                                multiline
                                fullWidth
                                placeholder='Write what type of report you need'
                                className='eMed_rts_home_txt'
                                value={this.state.newReport}
                                name='newReport'
                                inputProps={{ emed_tid: "Phm_rpt_newReport" }}
                                onChange={(e) => this.onChangeHandler(e)}
                            />
                        </Box>
                        <Box component='div' margin={'1vw'} display={'flex'} justifyContent='flex-end'>
                            <Button variant='outlined' emed_tid= "Phm_rpt_bck"
                                className='eMed_rts_home_btn'
                                size='small'
                                onClick={() => { this.handleClose() }} >{t("Back")}</Button>
                            <Button
                                variant='contained' emed_tid= "Phm_rpt_send"
                                className='eMed_rts_home_btn_two'
                                size='small'
                                onClick={() => { this.PostExtraReportText() }}
                            >{t("Send")}</Button>
                        </Box>
                    </Box>
                </Modal>
            </>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        let rejectionAccess = myUser?.purchase_rejection ? true : false
        const reportsList = myUser.pharmacy_type === 'Inhouse Pharmacy' ? [
            {
                title: 'Sales Reports', icon: ImagePaths.opConfig.default, Access : CheckAccessFunc("Pharmacy", "Reports", "Sales", null, "Tab"),
                reports: [
                    { report_names: 'Sales Overview', path: '/PharmacyReports/SalesOverview' },
                    { report_names: 'Sales Detailed', path: '/PharmacyReports/SalesDetailed' },
                    { report_names: 'Sales Return', path: "/PharmacyReports/SalesReturn" },
                    { report_names: 'Day End', path: "/PharmacyReports/SalesDayEnd" },
                    { report_names: 'Sales Monthly', path: "/PharmacyReports/SalesMonthly" },
                    { report_names: 'Product Wise Sales', path: "/PharmacyReports/ProductWiseSales" },
                    { report_names: "HSN Wise", path: "/PharmacyReports/HsnWise"},
                    { report_names: "Sales Tax Wise", path: "/PharmacyReports/TaxWise"},
                    { report_names: "Sales Return HSN Wise", path: "/PharmacyReports/ReturnHSNWise"},
                    { report_names: "Sales Return Tax Wise", path: "/PharmacyReports/ReturnTaxWise"},
                    { report_names: "Prescription Register", path: "/PharmacyReports/PrescriptionRegister"},
                    { report_names: "Profit Analysis", path: "/PharmacyReports/ProfitAnalysis"},
                    { report_names: "Sales Bill Tax Wise", path: "/PharmacyReports/billTaxWise"},
                ]
            },
            {
                title: 'Purchase Reports', icon: ImagePaths.opConfig.default, Access : CheckAccessFunc("Pharmacy", "Reports", "Purchase", null, "Tab"),
                reports: [
                    { report_names: 'Purchase Overview', path: '/PharmacyReports/PurchaseOverview' },
                    { report_names: 'Purchase Detailed', path: '/PharmacyReports/PurchsaeDetailed' },
                    { report_names: 'Purchase GST', path: '/PharmacyReports/PurchaseGST' },
                    { report_names: 'Purchase Return Overview', path: '/PharmacyReports/PurchaseReturnOverview' },
                    { report_names: 'Purchase Return Detailed', path: '/PharmacyReports/PurchaseReturnDetailed' },
                    { report_names: 'Purchase Monthly', path: '/PharmacyReports/PurchaseMonthly' },
                    { report_names: 'Consolidated Sales', path: '/PharmacyReports/ConsolidatedSales' },
                    { report_names: 'Purchase Vendor Wise', path: '/PharmacyReports/VendorBill' },
                    { report_names: 'Purchase HSN Wise', path: '/PharmacyReports/PurchaseHSNWise' },
                    { report_names: 'Purchase Tax Wise', path: '/PharmacyReports/PurchaseTaxWise' },
                    { report_names: 'Purchase Return HSN Wise', path: '/PharmacyReports/PurchaseReturnHSNWise' },
                    { report_names: 'Purchase Return Vendor Wise', path: '/PharmacyReports/ReturnVendorWise' },
                    { report_names: 'Purchase Return Tax Wise', path: '/PharmacyReports/PurchaseReturnTaxWise' },
                    { report_names: 'Purchase Wise Enquiry', path: '/PharmacyReports/PurchaseWiseEnquiry' },
                    { report_names: 'Purchase Rejection', path: '/PharmacyReports/PurchaseRejection' },
                    { report_names: 'Credit Note Report', path: '/PharmacyReports/CreditNoteReport' },
                    { report_names: 'Purchase Bill Tax Wise', path: '/PharmacyReports/PurchaseBillTaxWise' }
                ]
            },
            {
                title: 'Inventory Reports', icon: ImagePaths.corporateConfig.default, Access : CheckAccessFunc("Pharmacy", "Reports", "Inventory", null, "Tab"),
                reports: [
                    { report_names: 'Stock Overview', path: '/PharmacyReports/StockOverview' },
                    { report_names: 'MIS Report', path: '/PharmacyReports/MISReport' },
                    { report_names: 'Expiry Stock', path: '/PharmacyReports/ExpiryStock' },
                    { report_names: 'Low Stock Drugs', path: '/PharmacyReports/LowStockDrugs' },
                    { report_names: 'Slow Movement Drugs', path: '/PharmacyReports/SlowMovementDrugs' },
                    { report_names: 'No Movement Drugs', path: '/PharmacyReports/NoMovementDrugs' },
                    { report_names: 'Stock Correction Log', path: '/PharmacyReports/StockCorrectionLog' },
                    { report_names: 'Stock Value Detail', path: '/PharmacyReports/StockValueReport' },
                    { report_names: 'Stock And Profit Value', path: '/PharmacyReports/StockAndProfitValue' },
                ]
            },
            {
                title: 'GST Reports', icon: ImagePaths.corporateConfig.default, Access : CheckAccessFunc("Pharmacy", "Reports", "GST", null, "Tab"),
                reports: [
                    { report_names: 'Sales GST', path: '/PharmacyReports/DayWiseSalesGST' },
                    { report_names: 'Return GST', path: '/PharmacyReports/DayWiseReturnGST' },
                    { report_names: 'HSN Sales GST', path: '/PharmacyReports/HSNwiseSalesGST' },
                    { report_names: 'HSN Return GST', path: '/PharmacyReports/HSNwiseReturnGST' },
                    { report_names: 'GST Summary', path: '/PharmacyReports/DayWiseGSTSummary' },
                    { report_names: 'HSN GST Summary', path: '/PharmacyReports/HSNWiseSummaryGST' },
                ]
            }
        ] :
            [
                {
                    title: 'Sales Reports', icon: ImagePaths.opConfig.default, Access : CheckAccessFunc("Standalone Pharmacy", "Reports", "Sales", null, "Tab"),
                    reports: [
                        { report_names: 'Sales Overview', path: '/PharmacyReports/SalesOverview' },
                        { report_names: 'Sales Detailed', path: '/PharmacyReports/SalesDetailed' },
                        { report_names: 'Sales GST', path: "/PharmacyReports/SalesGST" },
                        { report_names: 'Sales Return', path: "/PharmacyReports/SalesReturn" },
                        { report_names: 'Day End', path: "/PharmacyReports/SalesDayEnd" },
                        { report_names: 'Sales Monthly', path: "/PharmacyReports/SalesMonthly" },
                        { report_names: 'Product Wise Sales', path: "/PharmacyReports/ProductWiseSales" },
                        { report_names: "HSN Wise", path: "/PharmacyReports/HsnWise"},
                        { report_names: "Profit Analysis", path: "/PharmacyReports/ProfitAnalysis"},
                    ]
                },
                {
                    title: 'Purchase Reports', icon: ImagePaths.opConfig.default, Access : CheckAccessFunc("Standalone Pharmacy", "Reports", "Purchase", null, "Tab"),
                    reports: [
                        { report_names: 'Purchase Overview', path: '/PharmacyReports/PurchaseOverview' },
                        { report_names: 'Purchase Detailed', path: '/PharmacyReports/PurchsaeDetailed' },
                        { report_names: 'Purchase GST', path: '/PharmacyReports/PurchaseGST' },
                        { report_names: 'Purchase Return Overview', path: '/PharmacyReports/PurchaseReturnOverview' },
                        { report_names: 'Purchase Return Detailed', path: '/PharmacyReports/PurchaseReturnDetailed' },
                        { report_names: 'Purchase Monthly', path: '/PharmacyReports/PurchaseMonthly' },
                        { report_names: 'Consolidated Sales', path: '/PharmacyReports/ConsolidatedSales' },
                        { report_names: 'Purchase Wise Enquiry', path: '/PharmacyReports/PurchaseWiseEnquiry' },
                    ]
                },
                {
                    title: 'Inventory Reports', icon: ImagePaths.corporateConfig.default, Access : CheckAccessFunc("Standalone Pharmacy", "Reports", "Inventory", null, "Tab"),
                    reports: [
                        { report_names: 'Stock Overview', path: '/PharmacyReports/StockOverview' },
                        { report_names: 'MIS Report', path: '/PharmacyReports/MISReport' },
                        { report_names: 'Expiry Stock', path: '/PharmacyReports/ExpiryStock' },
                        { report_names: 'Low Stock Drugs', path: '/PharmacyReports/LowStockDrugs' },
                        { report_names: 'Slow Movement Drugs', path: '/PharmacyReports/SlowMovementDrugs' },
                        { report_names: 'No Movement Drugs', path: '/PharmacyReports/NoMovementDrugs' },
                        { report_names: 'Stock Correction Log', path: '/PharmacyReports/StockCorrectionLog' },
                        { report_names: 'Stock Value Detail', path: '/PharmacyReports/StockValueReport' },
                        { report_names: 'Stock And Profit Value', path: '/PharmacyReports/StockAndProfitValue' },
                    ]
                }, 
                {
                    title: 'GST Reports', icon: ImagePaths.corporateConfig.default, Access : CheckAccessFunc("Standalone Pharmacy", "Reports", "GST", null, "Tab"),
                    reports: [
                        { report_names: 'Sales GST', path: '/PharmacyReports/DayWiseSalesGST' },
                        { report_names: 'Return GST', path: '/PharmacyReports/DayWiseReturnGST' },
                        { report_names: 'HSN Sales GST', path: '/PharmacyReports/HSNwiseSalesGST' },
                        { report_names: 'HSN Return GST', path: '/PharmacyReports/HSNwiseReturnGST' },
                        { report_names: 'GST Summary', path: '/PharmacyReports/DayWiseGSTSummary' },
                        { report_names: 'HSN GST Summary', path: '/PharmacyReports/HSNWiseSummaryGST' },
                    ]
                },
                {
                    title: 'Pharmacy Reports', icon: ImagePaths.corporateConfig.default, Access : CheckAccessFunc("Standalone Pharmacy", "Reports", "Pharmacy", null, "Tab"),
                    reports: [
                        { report_names: 'Referral Doctor Report', path: '/PharmacyReports/RefDoctorList' },
                        { report_names: 'Patient Report', path: '/PharmacyReports/PatientReport' },
                    ]
                }
            ]

        return (
            <>
                <Box component={'div'} className='eMed_rts_home_container' overflow={'scroll'}>
                    <Box position={'static'}>
                        <Typography fontWeight={'600'} padding='1vw' borderBottom={'1px solid lightgray'}>{t("Reports")}</Typography>
                    </Box>
                    <Box>
                        {reportsList.map((item, index) => {
                            if (CheckAccessFunc(myUser.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Reports", item?.title, null, "Tab").viewAccess) {
                                return (<Stack key={index}>{this.reportsList(item)}</Stack>)
                            }
                        })}
                    </Box>
                    <Tooltip placement='top' title='Raise New Reports' arrow><Fab color="primary"
                        size='medium'
                        className='eMed_rts_fab'
                        onClick={() => { this.handleOpen() }}
                    >
                        <QuestionMark />
                    </Fab></Tooltip>
                    {this.renderReportsPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </>
        )
    }
}

export default withTranslation()(ReportsHome)
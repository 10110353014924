import { Box, Button, Chip, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
class DocPatientCetegoryRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CategoryText: "",
      CategoryAddText: "",
      CategoryList: [],
      AddCategory: false,
      SelectedCategoryId: null,
      isDeleteClicked: false,
      SelectedCetegory: {},
      SelectedCateID: null,
    }
  }

  componentDidMount() {
      this.getPatientCategoryList()
  }

  getPatientCategoryList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              CategoryList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClearData() {
    this.setState({
      CategoryText: "",
      CategoryAddText: "",
      SelectedCategoryId: null,
      AddCategory: false,
      isDeleteClicked: false,
      SelectedCetegory: {},
      SelectedCateID: null,
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      isDeleteClicked: false,
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  DeleteCategory() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE + "?id=" + states.SelectedCateID).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.getPatientCategoryList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  PostGenAdvice() {
    try {
      var states = this.state
      if (CommonValidation.removeSpace(states.CategoryText) !== "") {
        let data = {
          patient_category: this.state.CategoryText ? this.state.CategoryText : "",
          appointment_id: this.props?.PatientData?.appointment_id,
          patient_id: this.props?.PatientData?.patient_id,
          category_id: this.state.SelectedCategoryId,
        }

        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY).
          then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response.data.message)
              this.ClearData()
              this.props.CallgetApi('patient_category')
            } else {
              this.errorMessage(response.data.message)
            }
          }).catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Select Category")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  postTemplateData = () => {
    try {
      let data = {
        doctor_category: this.state.CategoryAddText
      }
      this.setState({ loading: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.ClearData()
            this.getPatientCategoryList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteClicked: false })
  }

  render() {
    const { t } = this.props
    return (
      <Box>
        <Box height={"61.3vh"}>
          <Typography className='eMed_refdoc_title'>{t("Patient Category")}</Typography>
          <Box component={'div'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <TextField
                size='small'
                className='eMed_DocNotes_TextFields'
                label={t("Add Category")}
                value={this.state.CategoryAddText}
                onChange={(e) => {
                  if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                    this.setState({ CategoryAddText: e.target.value })
                  }
                }}
              />
              <Button sx={{ textTransform: 'capitalize', marginRight: '1vw' }} variant='contained' onClick={() => this.postTemplateData()} disabled={this.state.CategoryAddText === ""}>Add</Button>
            </Box>
          </Box>
          <Box className="eMed_DocNotes_FreqChips_Wrapp">
            {
              this.state.CategoryList?.length > 0 ? this.state.CategoryList.map((item, index) => (
                <Chip
                  size='small'
                  className={`eMed_chip ${this.state.CategoryText === item?.category ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                  key={index}
                  label={item?.category}
                  variant={this.state.CategoryText === item?.category ? 'contained' : "outlined"}
                  onClick={() => { this.setState({ CategoryText: item?.category, SelectedCategoryId: item?.id }) }}
                  onDelete={() => { this.setState({ isDeleteClicked: true, SelectedCetegory: item?.category, SelectedCateID: item?.id }) }}
                />
              )) : null
            }

          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" _id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.PostGenAdvice() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Cetegory")} ${this.state.SelectedCetegory ? this.state.SelectedCetegory : "-"} From Suggestion List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DeleteCategory.bind(this)}
        /> : null}
      </Box>
    )
  }
}
export default withTranslation()(DocPatientCetegoryRight)

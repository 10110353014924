import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import Loader from '../../../Components/Loader';
import { DataGrid } from '@mui/x-data-grid';
import CommonGridHeader, { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';


export default class IPSurgeryConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            surgeryName: '',
            surgeryNameData: [],
            page: 0,
            pageSize: 10,
            isEdit: false,
            deletePopup: false,
            deletedValue: '',
            surgeryId: ''
        }
    }

    componentDidMount() {
        this.getSurgeryListData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getSurgeryListData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_SURGERY_NAME_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            surgeryNameData: response.data.data
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    postSurgeryName = () => {
        try {
            let data = {
                'surgery_name': CommonValidation.removeSpace(this.state.surgeryName)
            }
            if (this.state.isEdit) { data.surgery_id = this.state.surgeryId }

            if (this.state.surgeryName === '') {
                this.errorMessage("Enter Surgery Name")
            } else {
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.IP_SURGERY_NAME_CONFIG)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            this.onClearHandler()
                        }
                        this.getSurgeryListData()
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error?.response?.data?.message)
                        }
                    })
            }
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    deleteSurgeryData = () => {
        try {
            let data = {}
            this.LoaderFunction(true)
            RestAPIService.delete(Serviceurls.IP_SURGERY_NAME_CONFIG + `?surgery_id=${this.state.deletedValue}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.getSurgeryListData()
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
            this.setState({ deletePopup: false })
            this.onClearHandler()
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        try {
            this.setState({
                isEdit: true,
                surgeryName: item?.surgery_name,
                surgeryId: item?.id
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (item) => {
        try {
            if (!this.state.isEdit) {
                this.setState({
                    deletePopup: true,
                    rowValue: item,
                    deletedValue: item?.id
                })
            } else {
                this.errorMessage("Please Update or clear the surgery Name")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    onClearHandler = () => {
        try {
            this.setState({
                surgeryName: '',
                isEdit: false,
                surgeryId: ''
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderLeftComponent = () => {
        try {
            this.state.surgeryNameData?.forEach((element, index) => element.sno = index + 1)
            const columns = [
                { field: 'sno', headerName: 'S.No', flex: 0.33 },
                { field: 'surgery_name', headerName: 'Surery Name', flex: 0.33 },
                {
                    field: 'action', headerName: 'Action', flex: 0.33,
                    renderCell: ({ row }) => (<Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                        <Button className='eMed_Config_Btn'
                            disabled={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration",  "Surgery Configuration", "SubTab")?.editAccess}
                            onClick={() => { this.onEditHandler(row) }}
                        >
                            <Box component={'img'} src={CheckAccessFunc("front_office", "Settings", "IP Service Configuration",  "Surgery Configuration", "SubTab")?.editAccess ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                        <Button className='eMed_Config_Btn'
                            disabled={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration",  "Surgery Configuration", "SubTab")?.editAccess}
                            onClick={() => { this.onDeleteHandler(row) }}
                        >
                            <Box component={'img'} src={CheckAccessFunc("front_office", "Settings", "IP Service Configuration",  "Surgery Configuration", "SubTab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                    </Box>)
                }
            ]
            return (
                <Box component={'div'} sx={{ backgroundColor: 'white', height: '75vh' }}>
                    <DataGrid
                        rows={this.state.surgeryNameData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {"No Records To Be Shown"}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRightComponent = () => {
        try {
            return (
                <Box component={'div'} sx={{ backgroundColor: 'white', height: '75vh' }} border={'1px solid lightgray'}>
                    <Box mb={'1vw'} display={'flex'} alignItems={'center'} height={'5vh'} sx={{ backgroundColor: Colors.Background }} paddingLeft={'1vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Surgery Name</Typography>
                    </Box>
                    <Box mb={'1vw'} sx={{paddingX:"0.75vw"}}>
                        <TextField
                            size='small'
                            fullWidth
                            label='Sugery Name'
                            value={this.state.surgeryName}
                            onChange={(e) => {
                                let value = e.target.value
                                if (CommonValidation.alphabetOnly(value) || value === '') {
                                    this.setState({
                                        surgeryName: value
                                    })
                                }
                            }}
                        />
                    </Box>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button variant='outlined' sx={{ width: '5vw', height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
                            onClick={() => this.onClearHandler()}
                        >Clear</Button>
                        <Button variant='contained' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                            disabled={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration",  "Surgery Configuration", "SubTab")?.editAccess}
                            onClick={() => this.postSurgeryName()}
                        >{this.state.isEdit ? 'Update' : 'Add'}</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    render() {
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'80vh'}>
                <Box component={'div'} padding={'0.5vw'} display={'flex'} justifyContent={'space-evenly'}>
                    <Box component={'div'} width={'60%'} height={'78vh'} padding={'0.5vw'}>
                        {this.renderLeftComponent()}
                    </Box>
                    <Box component={'div'} width={'40%'} height={'78vh'} padding={'0.5vw'}>
                        {this.renderRightComponent()}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete  ${this.state.rowValue?.surgery_name ? this.state.rowValue?.surgery_name : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteSurgeryData.bind(this)}
                    DeleteNotify={"It will affect the entire configuration"}
                /> : null}
            </Box>
        )
    }
}

import React, { Component } from 'react'
import { CommonDeleteButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';
import RepeatIcon from '@mui/icons-material/Repeat';
import { ImagePaths } from '../../../../Utility/ImagePaths';

class DocTestLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Testdata: [],
      isDeleteCliked: false,
      SelectedList: {},
      PatientData: this.props?.PatientData,
      TestTransmitted: false
    }
  }

  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetTestdata() }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  GetTestdata() {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_GET + `${this.state.PatientData?.patient_id}/?appointment_id=${this.state.PatientData?.appointment_id}`).
        then((response) => {
          if (response.data.status === "success") {
            let TestList = []
            response.data.data?.forEach((item) => {
              item.display_label = item?.lab_test_names ? item?.lab_test_names : item?.lab_set_names ? item?.lab_set_names : item?.special_test_name ? item?.special_test_name : ""
              TestList.push(item)
            })
            this.setState({
              Testdata: TestList,
              TestTransmitted: response.data?.is_transmitted ? response.data?.is_transmitted : false
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  onEditHandler(data) {
    this.props.EditAllergyData(data.row)
  }
  onDeleteHandler(data) {
    try {
      this.setState({
        isDeleteCliked: true,
        SelectedList: data.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }
  DeleteAllergy() {
    try {
      let data = {
        appointment_id: this.props?.PatientData?.appointment_id,
        tests_id: this.state.SelectedList?.id
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_DELETE).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false, SelectedList: {} }, () => { this.GetTestdata() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  postTestTransmitData = () => {
    try {
      let testIds = [];
      this.state.Testdata?.forEach(element => {
        testIds?.push(element?.id)
      });
      let data = {
        appointment_id: this.props?.PatientData?.appointment_id,
        // laboratory_id: +this.state.laboratory_id,
        patient_id: this.props?.PatientData?.patient_id,
        line_item_ids: this.state.Testdata
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_TRANSMIT)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.GetTestdata()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  printTestData = () => {
    try {
        RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_PRINT + `?appointment_id=${this.props?.PatientData?.appointment_id}&patient_id=${this.props?.PatientData?.patient_id}`).
            then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
    } catch (error) {
        this.errorMessage(error.message)
    }
}

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    try {
      const { t } = this.props
      this.state.Testdata.forEach((element, index) => { element.sno = index + 1 })
      const columns = [
        {
          field: "sno", flex: 0.1, headerName: t("S No"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.sno ? params?.row?.sno : "-"}
          </Box>)
        },
        {
          field: "display_label", flex: 0.50, headerName: t("Comments"),
          renderCell: (params) => {
            return (
              <Box component={'div'}>
                {params?.row?.display_label ? params?.row?.display_label?.length > 18 ?
                  <Tooltip placement='top' title={params?.row?.display_label} arrow>
                    <div>{params?.row?.display_label.slice(0, 18) + "..."}</div></Tooltip> :
                  params?.row?.display_label : "-"}
              </Box>
            )
          }
        },
        {
          field: "status", flex: 0.2, headerName: t("Status"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.status ? params?.row?.status : "In Progress"}
          </Box>)
        },
        {
          field: "action", flex: 0.2, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonDeleteButton size={"1.2vw"} disable={this.props?.isLocked || this.state.TestTransmitted} onClick={this.onDeleteHandler.bind(this, params)} />
          </Box>
        }
      ]
      return (
        <ShowComponents onIntersection={this.GetTestdata.bind(this)}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button
              className='Common_Btn_Min_Width'
              onClick={() => this.printTestData()}
            >
              <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
            </Button>
            <Tooltip title={"Transmit to Laboratory"} arrow placement='top'>
              <Button
                disabled={this.state.TestTransmitted || this.props.isLocked || this.state.Testdata?.length === 0}
                onClick={() => { this.postTestTransmitData() }}
                startIcon={<RepeatIcon />}
                variant='outlined'
                size='small'
                sx={{ marginX: '0.5vw', marginBottom: '0.5vw', textTransform: 'capitalize' }}
              >Transmit</Button>
            </Tooltip>
          </Box>
          <Box height={'20vh'} overflow={'auto'}>
            <DataGrid
              density='compact'
              rows={this.state.Testdata}
              columns={columns}
              getRowId={(row) => row['sno']}
              headerHeight={40}
              hideFooter={true}
              components={{
                NoRowsOverlay: () => (
                  <Stack className='eMed_DocNotes_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              disableSelectionOnClick
            />
          </Box>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} ${t("Test")} ${this.state.SelectedList ? this.state.SelectedList?.display_label : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteAllergy.bind(this)}
          /> : null}
        </ShowComponents>
      )
    }
    catch (e) {

    }
  }
}
export default withTranslation()(DocTestLeft)


import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next'
import {
    Box, Button, Stack, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Checkbox, IconButton, Tooltip,
    Drawer,
    Typography
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ImagePaths } from "../../../Utility/ImagePaths";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import CommonGridHeader, { CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import AssetFilter from "../Filter/AssetFilter";
import { DateTime } from "luxon";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../../../Components/CommonFunctions/CommonFunctions";

class AssetHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AssetList: [],
            current_page: 0,
            rowsPerPage: 10,
            selectedIds: [],
            isAllChecked: false,
            filterDatas: {
                'selectedCategory': null,
                'selectedSubCategory': null,
                'selectedBlock': null,
                'selectedFloor': null,
                'selectedSpot': null,
                "fromDate": null,
                "toDate": null,
                "dateIndex": 1,
                'assetTagNo': '',
            },
            printRowData: [],
            rowCount: 0,
            page: 0,
            pageSize: 10,
            getLoad: false
        }
    }

    componentDidMount = () => {
        this.getAssetList()
    }

    getAssetList = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let block = states.filterDatas.selectedBlock ? states.filterDatas.selectedBlock?.block_id : ''
            let floor = states.filterDatas.selectedFloor ? states.filterDatas.selectedFloor?.floor_id : ''
            let spot = states.filterDatas.selectedSpot ? states.filterDatas.selectedSpot?.id : ''
            let purFrm = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let purTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''
            this.setState({ getLoad: true })
            RestAPIService.getAll(Serviceurls.ASSET_LIST_GET_POST +
                '?category_id=' + category +
                '&sub_category_id=' + subCat +
                '&asset_no=' + assetNo +
                '&block=' + block +
                '&floor=' + floor +
                '&spot=' + spot +
                '&purchase_from_date=' + purFrm +
                '&purchase_to_date=' + purTo +
                '&page_no=' + (states.page + 1) +
                '&page_size=' + states.pageSize
            ).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AssetList: response.data.data,
                            rowCount: response.data.total_count,
                            getLoad: false
                        })
                    }
                }).catch((error) => {
                    this.setState({ getLoad: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.setState({ getLoad: false })
            this.errorMessage(e.message)
        }
    }

    getAssetListPrint = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let block = states.filterDatas.selectedBlock ? states.filterDatas.selectedBlock?.block_id : ''
            let floor = states.filterDatas.selectedFloor ? states.filterDatas.selectedFloor?.floor_id : ''
            let spot = states.filterDatas.selectedSpot ? states.filterDatas.selectedSpot?.id : ''
            let purFrm = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let purTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            RestAPIService.getAll(Serviceurls.ASSET_LIST_GET_POST +
                '?category_id=' + category +
                '&sub_category_id=' + subCat +
                '&asset_no=' + assetNo +
                '&block=' + block +
                '&floor=' + floor +
                '&spot=' + spot +
                '&purchase_from_date=' + purFrm +
                '&purchase_to_date=' + purTo +
                "&export_type=pdf").
                then((response) => {
                    if (response) {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getAssetListExcel = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let block = states.filterDatas.selectedBlock ? states.filterDatas.selectedBlock?.block_id : ''
            let floor = states.filterDatas.selectedFloor ? states.filterDatas.selectedFloor?.floor_id : ''
            let spot = states.filterDatas.selectedSpot ? states.filterDatas.selectedSpot?.id : ''
            let purFrm = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let purTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            RestAPIService.excelGet(Serviceurls.ASSET_LIST_GET_POST +
                '?category_id=' + category +
                '&sub_category_id=' + subCat +
                '&asset_no=' + assetNo +
                '&block=' + block +
                '&floor=' + floor +
                '&spot=' + spot +
                '&purchase_from_date=' + purFrm +
                '&purchase_to_date=' + purTo +
                "&export_type=excel").
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    getBarcode = (id) => {
        try {
            let query = '';
            if (this.state.printRowData?.length > 0) {
                let ids = [];
                this.state.printRowData?.forEach(element => ids?.push(element?.id))
                query = '?id=' + ids
            } else {
                query = '?id=' + id
            }
            RestAPIService.getAll(Serviceurls.ASSET_LIST_BAR_CODE + query).
                then((response) => {
                    if (response) {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ current_page: newPage }, () => { this.getAssetList() });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, current_page: 0 }, () => { this.getAssetList() });
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            CreditBillPopUp: false
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.getAssetList()
            }
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            FilterOpen: false,
        }, () => { this.getAssetList() })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    render() {
        const { t } = this.props;
        this.state.AssetList?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'asset_no', headerName: 'Asset/Tag No', flex: 0.083,
                renderCell: (params) => {
                    let assetNo = params?.row?.asset_no
                    return (
                        <Box component={'div'}>
                            {assetNo ? assetNo?.length > 12 ?
                                <Tooltip placement="top" title={assetNo}><Typography fontSize={'0.9vw'}>{assetNo?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{assetNo}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'asset_name', headerName: 'Asset Name', flex: 0.083,
                renderCell: (params) => {
                    let assetName = params?.row?.asset_name
                    return (
                        <Box component={'div'}>
                            {assetName ? assetName?.length > 12 ?
                                <Tooltip placement="top" title={assetName}><Typography fontSize={'0.9vw'}>{assetName?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{assetName}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'model_name', headerName: 'Model Name', flex: 0.083,
                renderCell: (params) => {
                    let model = params?.row?.model_name
                    return (
                        <Box component={'div'}>
                            {model ? model?.length > 12 ?
                                <Tooltip placement="top" title={model}><Typography fontSize={'0.9vw'}>{model?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{model}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'category__category_name', headerName: 'Category', flex: 0.083,
                renderCell: (params) => {
                    let category = params?.row?.category__category_name
                    return (
                        <Box component={'div'}>
                            {category ? category?.length > 12 ?
                                <Tooltip placement="top" title={category}><Typography fontSize={'0.9vw'}>{category?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{category}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'sub_category__sub_category_name', headerName: 'Sub Category', flex: 0.083,
                renderCell: (params) => {
                    let subCat = params?.row?.sub_category__sub_category_name
                    return (
                        <Box component={'div'}>
                            {subCat ? subCat?.length > 12 ?
                                <Tooltip placement="top" title={subCat}><Typography fontSize={'0.9vw'}>{subCat?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{subCat}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'block__block_name', headerName: 'Block', flex: 0.083,
                renderCell: (params) => {
                    let block = params?.row?.block__block_name
                    return (
                        <Box component={'div'}>
                            {block ? block?.length > 12 ?
                                <Tooltip placement="top" title={block}><Typography fontSize={'0.9vw'}>{block?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{block}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'floor__floor_name', headerName: 'Floor', flex: 0.083,
                renderCell: (params) => {
                    let floor = params?.row?.floor__floor_name
                    return (
                        <Box component={'div'}>
                            {floor ? floor?.length > 12 ?
                                <Tooltip placement="top" title={floor}><Typography fontSize={'0.9vw'}>{floor?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{floor}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'spot__spot_name', headerName: 'Spot/Area', flex: 0.083,
                renderCell: (params) => {
                    let spot = params?.row?.spot__spot_name
                    return (
                        <Box component={'div'}>
                            {spot ? spot?.length > 12 ?
                                <Tooltip placement="top" title={spot}><Typography fontSize={'0.9vw'}>{spot?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{spot}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'purchase_date', headerName: 'Purchase Date', flex: 0.083,
                renderCell: (params) => {
                    let purDate = formatDate(params?.row?.purchase_date)
                    return (
                        <Box component={'div'}>
                            {purDate ? purDate?.length > 12 ?
                                <Tooltip placement="top" title={purDate}><Typography fontSize={'0.9vw'}>{purDate?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{purDate}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'next_service_date', headerName: 'Next Service Date', flex: 0.083,
                renderCell: (params) => {
                    let nstSrv = formatDate(params?.row?.next_service_date)
                    return (
                        <Box component={'div'}>
                            {nstSrv ? nstSrv?.length > 12 ?
                                <Tooltip placement="top" title={nstSrv}><Typography fontSize={'0.9vw'}>{nstSrv?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{nstSrv}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'status', headerName: 'Status', flex: 0.083,
                renderCell: (params) => {
                    let status = params?.row?.status
                    return (
                        <Box component={'div'}>
                            {status ? status?.length > 12 ?
                                <Tooltip placement="top" title={status}><Typography fontSize={'0.9vw'}>{status?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{status}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'action', headerName: 'Action', flex: 0.083,
                renderCell: (params) => {
                    return (
                        <Box component={'div'}>
                            <Button className="Common_Btn_Min_Width"
                                onClick={() => this.props.history?.push({ pathname: '/AssetHome/NewAsset', state: { data: params.row } })}
                            >
                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className="Common_Btn_Min_Width"
                                onClick={() => this.getBarcode(params?.row?.id)}
                            >
                                <Box component={'img'} src={ImagePaths.BarCodeIcons.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                        </Box>
                    )
                }
            },
        ]
        return (
            <Box className="emedhub__purchase_page" sx={{ '& .grid--header': { backgroundColor: '#FAF9F9' } }}>
                <Stack sx={{ marginY: "1vw" }} direction="row" justifyContent='flex-end'>
                    <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="flex-end">
                        <div className="emedhub_vendor_btnDiv">
                            <Button variant="contained" size="small" disabled={this.state.printRowData?.length === 0} sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} onClick={() => { this.getBarcode() }}>{t("Print Barcode")}</Button>
                            <Button variant="contained" size="small" sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} onClick={() => {
                                this.props.history.push({ pathname: "/AssetHome/NewAsset", state: { page: "asset_list" } })
                            }}>{t("Add New Asset")}</Button>
                        </div>
                        <Stack direction="row" spacing={1} alignItems="flex-end">
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.Filter.default} onClick={() => { this.setState({ FilterOpen: true }) }} alt='filter' /></IconButton>
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabPrintIcon.default} alt='print' onClick={() => { this.getAssetListPrint() }} /></IconButton>
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabUploadButton.default} onClick={() => { this.getAssetListExcel() }} alt='upload' /></IconButton>
                            {/* <Tooltip title={t("More")} placement='top' arrow>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip> */}
                        </Stack>
                    </Stack>
                </Stack>
                <Box component={'div'} height={'68vh'}>
                    <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRows = this.state.AssetList?.filter((row) => selectedIds.has(row.sno))
                            this.setState({ printRowData: selectedRows })
                        }}
                        rows={this.state.AssetList}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            // Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    {'No Records To Be Shown'}
                                </Box>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getAssetList()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getAssetList())}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        paginationMode='server'
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.getLoad}
                        rowCount={this.state.rowCount}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <AssetFilter PageName={"AssetHome"} filterDatas={this.state.filterDatas} CloseFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}
export default withTranslation()(AssetHome)
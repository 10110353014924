import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Box, InputAdornment, Typography } from '@mui/material';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { Colors } from '../../../Styles/Colors';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';

class RoomTypeAddPop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Open: true,
            RoomTypeName :this.props.RenameRoomType === null ? "" : this.props.RenameRoomType[0].room_type
        }
    }
    handleClose = () => {
        this.setState({
            Open: false,
        })
        this.props.ClosePopUp()
    }
  

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }


    PostAPI() {
        try {
            var states = this.state
            if (
                states.RoomTypeName !== "" 
            ) {
                let data = {
                    "room_type": states.RoomTypeName,
                }
                if(this.props.RenameRoomType !== null){data["type_id"] = this.props.RenameRoomType[0].id}
                RestAPIService.create(data, Serviceurls.USER_ROOM_TYPE).
                    then((response) => {
                        if (response.data.status === "success") {
                             this.successMessage(response.data.message)
                             this.handleClose()
                        }
                        else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch((error) => {
                        this.errorMessage(error.message)
                    })
            }
            else {
                if (states.RoomTypeName === "") {
                    this.errorMessage("Please Enter the Room Type")
                }
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
    render() {
        const { t } = this.props
        return (
            <div>
                <Dialog open={this.state.Open} >
                    <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
                        <Typography marginLeft={"1vw"}>{t("AddRoomType")}</Typography>
                        <Button onClick={() => { this.handleClose() }} >
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </Button>
                    </DialogTitle>
                    <DialogContent sx={{ padding: "2vw" }}>
                        <TextField
                            inputProps={{ maxLength: 30 }}
                            sx={{ width: "20vw", marginTop:"2vw" }}
                            size='small'
                            label={t("AddRoomType")}
                            value={this.state.RoomTypeName}
                            onChange={(e) => {
                                this.setState({
                                    RoomTypeName: e.target.value
                                })

                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.setState({RoomTypeName:""}) }}>{t("Clear")}</Button>
                        <Button variant='contained' size='small' className='eMed_Filter_Btns' onClick={() => { this.PostAPI() }}>{this.props.RenameRoomType === null ? t("Add") : t("Rename") }</Button>
                    </DialogActions>
                </Dialog>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}

export default withTranslation()(RoomTypeAddPop)

import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { formatDate } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';

class PrescriptionReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            PrescriptionData: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            doctorNameList: [],
            doctorName: [],
            TotalCount: 0,
            TotalGlassCount: 0,
            TotalMedicationCount: 0
        }
    }

    componentDidMount() {
        this.getPrescriptionData()
        this.getDoctorNameList()
    }

    getDoctorNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorNameList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPrescriptionData = () => {
        let doctorId = [];
        this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
        this.setState({ isLoader: true })
        try {
            RestAPIService.getAll(Serviceurls.GET_PRESCRIPTION_REPORT_DATA + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&doctor_id=${doctorId}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            PrescriptionData: response?.data?.data,
                            TotalCount: response?.data?.total,
                            TotalGlassCount: response?.data?.total_gp,
                            TotalMedicationCount: response?.data?.total_prescription
                        }, () => {
                            this.setState({ isLoader: false })
                        })
                    }
                }).catch((error) => {
                    this.setState({ isLoader: false })
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ isLoader: false })
            this.errorMessage(error.message)
        }
    }

    printReports = () => {
        let doctorId = [];
        this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
        this.setState({ isLoader: true })
        try {
            RestAPIService.getAll(Serviceurls.GET_PRESCRIPTION_REPORT_DATA + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&doctor_id=${doctorId}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isLoader: false })
                }).catch((error) => {
                    this.setState({ isLoader: false })
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.setState({ isLoader: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }


    handleCheckBox = (e, key) => {
        let states = this.state
        states[key] = e.target.checked
        this.setState({ states })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.doctorNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectDoctor')}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                    {option?.doctor_name}
                                </li>
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                doctorName: []
                            })
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getPrescriptionData()
                            this.closeFilter()
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }
    render() {
        this.state.PrescriptionData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "created_date", headerName: t("Date"), flex: 0.125, headerAlign: "center", align: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.created_date ? (params?.row?.created_date).split("-").reverse().join("/") : "-"}</Box>)
            },
            {
                field: "doctor_name", headerName: t("DoctorName"), flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name ? params?.row?.doctor_name?.length > 25 ?
                    <div><Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 25) + "..."}</div></Tooltip></div> : params?.row?.doctor_name : "-"}</Box>)
            },
            {
                field: "total_gp", headerName: t("Glass Prescription"), flex: 0.166, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_gp ? params?.row?.total_gp : "-"}</Box>)
            },
            {
                field: "total_prescription", headerName: t("Medication Prescription"), flex: 0.166, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_prescription ? params?.row?.total_prescription : "-"}</Box>)
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Prescription"), this.state.TotalCount, true)}
                        {AmountsCard(t("Glass Prescription"), this.state.TotalGlassCount, true)}
                        {AmountsCard(t("Medicine Prescription"), this.state.TotalMedicationCount, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button onClick={() => {this.printReports()}} className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.PrescriptionData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        rowHeight={70}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(PrescriptionReport)
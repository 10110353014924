import React, { Component } from "react";
import "./charts.css";
import { Box, Typography, Modal, IconButton, Stack, Tooltip } from "@mui/material";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { withTranslation } from 'react-i18next';
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import TableChartIcon from '@mui/icons-material/TableChart';
class ExpandPopUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChartView: true,
            title: props.title ? props.title : "",
            tblData: props.tblData?.length > 0 ? props.tblData : [],
            tblColumn: props.tblColumn,
            chart_view: props.chartView,
            page: 0,
            pageSize: 10,
        }
    }

    renderChart = () => {
        return (
            <Box>
                {(this.props?.Tlt1 || this.props?.Tlt2 || this.props?.Tlt3) ?
                    <Box className="eMed_barChart_chartTltDiv_expand">
                        {this.props?.Tlt1 ? <Box className="eMed_barChart_imgDiv">
                            <img className="eMed_revenueCard_profit_expand" src={ImagePaths.Graph1.default} />
                            <Typography id="eMed_barChart_Txt_expand">{this.props?.Tlt1}</Typography>
                        </Box> : null}
                        {this.props?.Tlt2 ? <Box className="eMed_barChart_imgDiv">
                            <img className="eMed_revenueCard_profit_expand" src={ImagePaths.Graph2.default} />
                            <Typography id="eMed_barChart_Txt_expand">{this.props?.Tlt2}</Typography>
                        </Box> : null}
                        {this.props?.Tlt3 ? <Box className="eMed_barChart_imgDiv">
                            <img className="eMed_revenueCard_profit_expand" src={this.props?.title === "Patient Demographics" ? ImagePaths.Graph4.default : ImagePaths.Graph3.default} />
                            <Typography id="eMed_barChart_Txt_expand">{this.props?.Tlt3}</Typography>
                        </Box> : null}
                    </Box> : null}
                <Box component={'div'} className="eMed_expandPop_wrapper" height={(this.props?.Tlt1 || this.props?.Tlt2 || this.props?.Tlt3) ? "28vw" : "31vw"}>
                    {this.state.chart_view}
                </Box>
            </Box>)
    }

    toolbar = () => {
        return (
            <GridToolbarContainer className={'eMed_refdoc_grid'} >
                <div>
                    <Tooltip placement='top' title='Export' arrow><GridToolbarExportContainer>
                        <GridCsvExportMenuItem />
                    </GridToolbarExportContainer></Tooltip>
                    <GridToolbarQuickFilter />
                </div>
            </GridToolbarContainer>
        )
    }
    renderTable = () => {
        const { t } = this.props;
        let { tblColumn, tblData } = this.state;
        tblData?.length > 0 && tblData?.forEach((item, i) => item["sno"] = i + 1)



        return (
            <Box component={'div'} className="eMed_expandPop_wrapper" >
                <DataGrid
                    rows={tblData}
                    columns={tblColumn}
                    getRowId={(row) => row.sno}
                    headerHeight={40}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    pagination
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        Toolbar: this.toolbar,
                        NoRowsOverlay: () => {
                            return (
                                <Box className="eMed_noDataDiv">
                                    <p id="eMed_revenueCard_Txt">{t("NoRecordsFound")}</p>
                                </Box>
                            )
                        }
                    }}
                />
            </Box>
        )
    }

    handleView = () => {
        this.setState({
            isChartView: !this.state.isChartView
        })
    }

    render() {
        let { title, isChartView } = this.state;
        return (
            <Modal open={true}>
                <Box className="eMed_expandPop_Main">
                    <Box className="eMed_revenueTbl_titleDiv" sx={{ width: "100%", height: "3.5vw" }}>
                        <Typography id="eMed_chart_headTxt">{title ? title : ""}</Typography>
                        <Stack direction="row" spacing={0.8}>
                            {/* <IconButton>
                                <img className='emedhub_expand_img' src={ImagePaths.DownloadIcon.default} alt="Download" />
                            </IconButton> */}
                            <Tooltip title={this.state.isChartView ? "Table View" : "Chart View"} placement="top" arrow>
                                <IconButton size="small" onClick={() => this.handleView()}>
                                    {this.state.isChartView ? <TableChartIcon sx={{fontSize : "1.2vw"}}/> : <PieChartOutlineIcon sx={{fontSize : "1.2vw"}}/>} 
                                </IconButton>
                            </Tooltip>
                            <IconButton size="small" onClick={() => this.props.popUpClose()}>
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_DeletePopup_CrossIcon' />
                            </IconButton>
                        </Stack>
                    </Box>
                    {isChartView ? this.renderChart() : this.renderTable()}
                </Box>
            </Modal>
        )
    }
}
export default withTranslation()(ExpandPopUp);

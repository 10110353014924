import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { formatDate } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';

class OptometristList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            OptometristData: [],
            PatientName: "",
            Optometrist: "",
            Doctor: "",
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            doctorNameList: [],
            optometristNameList: [],
            doctorName: [],
            optometristName: []
        }
    }

    componentDidMount() {
        this.getOptometristDetails()
        this.getDoctorNameList()
        this.getOptometristNameList()
    }

    getDoctorNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorNameList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getOptometristNameList = () => {
        let clinicId = getCachevalue('SelectedDoctorClinic')
        try {
            RestAPIService.getAll(Serviceurls.GET_OPTOMETRIST_NAME_LIST + `?clinic_id=${clinicId}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            optometristNameList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getOptometristDetails = () => {
        let doctorId = []; let optometrist_id = [];
        let clinicId = getCachevalue('SelectedDoctorClinic')
        this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
        this.state.optometristName.forEach(element => optometrist_id.push(element.optometrist_id))
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.GET_OPTOMETRIST_DETAILS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_name=${this.state.PatientName}&optometrist_id=${optometrist_id}&doctor_id=${doctorId}&clinic_id=${clinicId}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                        this.setState({
                            OptometristData: response.data.data ? response.data.data : [],
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate
                        })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    handleCheckBox = (e, key) => {
        let states = this.state
        states[key] = e.target.checked
        this.setState({ states })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        let fromDoctor = myUser?.service_type === "OPT"
        let fromOptometrist = myUser?.service_type === "OPTOMETRY"
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 15 }}
                            size='small'
                            label={t("Patient Name")}
                            name="Patient Name"
                            value={this.state.PatientName}
                            onChange={(e) => {
                                var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                                if (e.target.value === "" || isSpecial) {
                                    this.setState({ PatientName: e.target.value })
                                }
                            }}
                        />
                    </Box>
                    {fromDoctor ? null :<Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.doctorNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectDoctor')}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                    {option?.doctor_name}
                                </li>
                            )}
                        />
                    </Box>}
                    {fromOptometrist ? null : <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.optometristNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.optometrist_name)}
                            value={this.state.optometristName}
                            onChange={(event, newValue) => {
                                this.setState({ optometristName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Opthometrist Name')}
                                />
                            )}
                        />
                    </Box>}
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                doctorName: [],
                                optometristName: [],
                                PatientName: ""
                            })
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getOptometristDetails()
                            this.closeFilter()
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }
    render() {
        this.state.OptometristData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "appointment_date__date", headerName: t("Date"), flex: 0.125, headerAlign: "center", align: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.appointment_date__date ? (params?.row?.appointment_date__date).split("-").reverse().join("/") : "-"}</Box>)
            },
            {
                field: "purpose_of_visit", headerName: t("Appointment Details"), flex: 0.13,
                renderCell: (params) => (<Box component={'div'} display={'flex'} flexDirection={'column'}>
                    <Typography fontSize={'0.85vw'} fontWeight={600} color={'black'}>{`Token : ${params?.row?.token_number ? params?.row?.token_number : "-"}`}</Typography>
                    <Typography fontSize={'0.8vw'}>{`OP : ${params?.row?.op_number ? params?.row?.op_number : "-"}`}</Typography>
                    <Typography fontSize={'0.8vw'}>{`POV : ${params?.row?.purpose_of_visit ? params?.row?.purpose_of_visit : "-"}`}</Typography>
                </Box>)
            },
            {
                field: "patient_name", headerName: t("Patient Details"), flex: 0.125,
                renderCell: (params) => (<CommonPatientDetails data={params?.row} showDetailed={true} />)
            },
            {
                field: "optometrist_name", headerName: t("Optometrist Name"), flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.optometrist_name ? params?.row?.optometrist_name?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.optometrist_name} arrow><div>{params?.row?.optometrist_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.optometrist_name : "-"}</Box>)
            },
            {
                field: "doctor_name", headerName: t("DoctorName"), flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name ? params?.row?.doctor_name?.length > 25 ?
                    <div><Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 25) + "..."}</div></Tooltip></div> : params?.row?.doctor_name : "-"}</Box>)
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {/* {AmountsCard(t("TotalAmount"), this.state.refDoctorAmount)}
                        {AmountsCard(t("TotalPatients"), this.state.refDoctorPatientCount, true)} */}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.OptometristData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        rowHeight={70}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(OptometristList)
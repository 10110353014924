import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Paper, Tooltip, Menu, IconButton, Stack, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BillingsFilter from './BillingsFilter';
import CreditBillPopUp from './CreditBillPopUp';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { AmountFormat, CheckAccessFunc, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
import CommonGridHeader, { BillSuccessPoPUp, CommonCorporateAndInsuranceDetails, CommonGridToolBarWithFilterTextCustom, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CreditTypeChangePop from './CreditTypeChangePop';
import CreditBillCompletePoP from './CreditBillCompletePoP';
import { CurrencySymbol } from '../../../Utility/Constants';

class CreditBills extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            ref: props.location?.pathname,
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PatientName": "",
                "AccNo": "",
                "MobNo": "",
                "DoctorName": [],
                "BillNumber": "",
                "PaidAmount": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "ImageLabSelected": true,
                "OTSelected": true,
                "PaidSelected": true,
                "PartialPaidSelected": true,
                "CreditSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "BillType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "PayStatus": [],
                "PayMethods": [],
                "CreditStatus": ['Corprate credit', 'Insurance credit', 'Patient credit'],
                "Insurance": true,
                "CorporateCredit": true,
                "PatientCredit": true,
                "startTime":null,
                "endTime":null,
                "is_bill_wise" : false,
                "opCreditBills" : true,
                "ipCreditBills" : true,
                "pharmaCreditBills" : localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "labCreditBills" : true,
                "BillModuleType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "RadiologyBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "RadiologySelected":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "radiologyCreditBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "phoneNumber": '',
                "patient_Name": '',
                "UHID_No": '',
                "IncludePaidBills": false,
                "Bill_No": '',
            },
            AmountDetails: {
                "Total": 0,
                "Paid": 0,
                "Pending": 0,
            },
            CreditBillPopUp: false,
            SelectedBill: {},
            BillPreviewPop: false,
            CreditTypeChangePopup: false,
            pharmareceipt: null,
            receiptID: null,
        }
    }


    componentDidMount() {
        this.GetBillList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetBillList() {
        try {
            var states = this.state

            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

            let doctorId = [];
            states.FilterDatas?.DoctorName.forEach(element => doctorId.push(element?.doctor_id))
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.CREDIT_BILLS_GET +
                "?from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&moduel_type=" + states.FilterDatas.BillType +
                "&credit_type=" + states.FilterDatas.CreditStatus + 
                "&is_bill_wise=" + states.FilterDatas.is_bill_wise + 
                "&bills=" + (states.FilterDatas.is_bill_wise ? states?.FilterDatas?.BillModuleType : "") +
                "&start_time=" + setStartTime + 
                "&end_time=" + setEndTime + 
                "&patient_name=" + states.FilterDatas.patient_Name +
                "&uhid=" + states.FilterDatas.UHID_No + 
                "&mob_no=" + states.FilterDatas.phoneNumber +
                "&bill_number=" + states.FilterDatas.Bill_No +
                "&include_paid_bill=" + states.FilterDatas.IncludePaidBills 
            ).
                then((response) => {
                    if (response.data.status === "success") {
                        states.AmountDetails.Total = response?.data?.total_amount;
                        states.AmountDetails.Paid = (response?.data?.total_amount ? response?.data?.total_amount : 0) - (response?.data?.pending_amount ? response?.data?.pending_amount : 0);
                        states.AmountDetails.Pending = response?.data?.pending_amount;
                        this.setState({
                            BillList: response.data.data,
                            AmountDetails: states.AmountDetails,
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    printCreditBills = () => {
        let states = this.state

        let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
        let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        try {
            RestAPIService.getAll(Serviceurls.CREDIT_BILLS_GET +
                "?from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&moduel_type=" + states.FilterDatas.BillType +
                "&credit_type=" + states.FilterDatas.CreditStatus +
                "&start_time=" + setStartTime +
                "&end_time=" + setEndTime +
                "&is_bill_wise=" + states.FilterDatas.is_bill_wise + 
                "&bills=" + (states.FilterDatas.is_bill_wise ? states?.FilterDatas?.BillModuleType : "") +
                "&patient_name=" + states.FilterDatas.patient_Name +
                "&uhid=" + states.FilterDatas.UHID_No + 
                "&mob_no=" + states.FilterDatas.phoneNumber +
                "&bill_number=" + states.FilterDatas.Bill_No +
                "&include_paid_bill=" + states.FilterDatas.IncludePaidBills +
                "&export=" + "pdf"
            )
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    printIndividualPrint = (billData) => {
        try {
            let url = ''
            let lab_id = billData?.laboratory_id ? billData?.laboratory_id : null
            if (billData?.bill_types === "OT") {
                url = Serviceurls.GET_PRINT_PREVIEW + `?export=pdf&id=` + billData?.id
            }
            else if (billData?.bill_types === "In Patient") {
                url = Serviceurls.IP_INVOICE_PRINT + `?export=pdf&ip_bill_summary_id=` + billData?.id
            }
            else if (billData?.bill_types === "Out Patient") {
                url = Serviceurls.OP_INVOICE_PRINT + "?export=pdf&invoce_id=" + billData?.id
            }
            else if (billData?.bill_types === "Pharmacy") {
                url = Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + billData?.id + "&export=pdf"
            }
            else if (billData?.bill_types === "Laboratory") {
                url = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT + "?bill_summary_id=" + billData?.id + "&export_type=pdf" + "&laboratory_id=" + lab_id
            }
            else if (billData?.bill_types === "Radiology") {
                url = Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + billData?.id + "&export_type=pdf"
            }
            else if (billData?.billing_status) {
                url = Serviceurls.PHARMA_BILLS_GET + "?id=" + billData?.id + "&export=pdf"
            }
            RestAPIService.getAll(url).
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    console.log(error.message)
                })
        } catch (e) {
            console.log(e.message)
        }
    }

    closeFilter() {
        this.setState({
            FilterOpen: false
        })
    }

    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "ChangeCreditType", label: t("Change Credit Type") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={data.row.pending_amount === 0}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option}
                            disabled={this.state.CreditTypeChangePopup}
                            onClick={() => { 
                                this.setState({ anchorEl: null }, ()=>{
                                    if(option.value === "ChangeCreditType"){
                                        data.row.employee_id = data?.row?.employee_id_no ? data?.row?.employee_id_no : "";
                                        this.setState({ CreditTypeChangePopup : true, SelectedBill: data?.row })
                                    }
                                }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }
    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            CreditBillPopUp: false,
            BillPreviewPop: false,
            CreditTypeChangePopup : false,
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.GetBillList()
            }
        })
    }
    CrebillPosted(message = "", rec = null, phrrec = null, error = false) {
        this.setState({
            CreditBillPopUp: false,
            CreditTypeChangePopup : false,
            receiptID: rec,
            pharmareceipt: phrrec,
        }, () => {
            if(error){
                this.errorMessage(message)
            }else{
                this.successMessage(message)
            }
            this.GetBillList()
        })
    }
    
    CrebillTypeChaged(message) {
        this.setState({
            CreditBillPopUp: false,
            CreditTypeChangePopup : false,
        }, () => {
            this.successMessage(message)
            this.GetBillList()
        })
    }
    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
            SearchedFilters: data
        }, () => { this.GetBillList() })
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    gridToolBar = () => {
        var { t } = this.props

        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilterDatas.FromDate)},
            {label:"To Date", value:formatDate(this.state.FilterDatas.ToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        var ExportData = []
        this.state.BillList?.length > 0 && this.state.BillList.map((item) => 
        ExportData.push({
            "Patient Name": item.patient_names, 
            "Patient UHID":item.uhid, 
            "Date":item.appointment_dates, 
            "Module Number":item.module_number, 
            "Module Type": item.bill_types, 
            "Credit Type": item.credit_type, 
            "Total Bill": item.total_amount,
            "Pending Amount": item.pending_amount })
        )
        return(
          <Box>
             <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"FO Credit Bills"}  FilterTextArray={TextArray} filename={"CreditBills"}/>
          </Box>
    
        )
      }

      clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    ClosesuccessPopUp = () => {
        this.setState({
            receiptID: null,
            pharmareceipt: null
        })
    }

    render() {
        const { t } = this.props
        this.state.BillList.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: "patient_names", flex: 0.17, headerName: t("PatientDetails"),
                renderCell: (params) => (
                    <Box>
                        <CommonPatientDetails disablePhoto={true} data={params?.row} fromCreditBill={true} />
                    </Box>
                )
            },
            {
                field: "appointment_date", flex: 0.1, headerName: t("Date"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.appointment_dates ? formatDate(params?.row?.appointment_dates) : "-"}</Box>)
            },
            {
                field: 'module_number', headerName: t('OP/IP/LAB/Pharmacy No'), sortable: false, flex: 0.17, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let BillNumber = params?.row?.module_number ? (params?.row?.module_number) : "-";
                    const billArr = BillNumber ? BillNumber.split("/") : [];
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr.slice(-2).join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: "bill_types", flex: 0.11, headerName: this.state?.FilterDatas?.is_bill_wise ? t("BillType") : t("Module Type"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_types ? params?.row?.bill_types : "-"}</Box>)
            },
            {
                field: "credit_type", flex: 0.11, headerName: t("CreditType"),
                renderCell: (params) => (
                <Box component={'div'}>
                    {params?.row?.credit_type ? <CommonCorporateAndInsuranceDetails data={params?.row} /> : "-"}
                    
                </Box>)
            },
            {
                field: "total_amount", flex: 0.11, headerName:  t('TotalBill')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_amount ? AmountFormat(params?.row?.total_amount) : params?.row?.total_amount === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "pending_amount", flex: 0.11, headerName: t('PendingAmount')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.pending_amount ? AmountFormat(params?.row?.pending_amount) : params?.row?.pending_amount === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "action", flex: 0.12, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("Receive Payment")} placement="top" arrow>
                        <Button disabled={!CheckAccessFunc("front_office", "Billing Transaction", 'Credit Bills', null, "Tab")?.editAccess ? true : params?.row?.pending_amount === 0 ? true : !(params?.row?.bill_types === "In Patient" || params?.row?.bill_types === "Out Patient" || params?.row?.bill_types === "Laboratory" || params?.row?.bill_types === "Radiology" || (params?.row?.bill_types === "Pharmacy" && localGetItem("Allow_Pharma_CreditBill_In_FO") === "true") )} className='eMed_usrconf_btn' onClick={() => { this.setState({ CreditBillPopUp: true, SelectedBill: params.row }) }}>
                            <img src={(CheckAccessFunc("front_office", "Billing Transaction", 'Credit Bills', null, "Tab")?.editAccess && (params?.row?.bill_types === "In Patient" || params?.row?.bill_types === "Out Patient" || params?.row?.bill_types === "Laboratory" || params?.row?.bill_types === "Radiology"  || (params?.row?.bill_types === "Pharmacy" && localGetItem("Allow_Pharma_CreditBill_In_FO") === "true")) && params?.row?.pending_amount !== 0) ? ImagePaths.CreateBill.default : ImagePaths.DisabledCreateBill.default} alt="Credit Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    {this.state.FilterDatas.is_bill_wise ? 
                        <Tooltip title={t("PrintBill")} placement="top" arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.printIndividualPrint(params.row) }}>
                                <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                            </Button>
                        </Tooltip> : null}
                    {this.MenuItem(params)}
                </Box>
            }
        ]
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Bill Amount"), this.state.AmountDetails.Total ? this.state.AmountDetails.Total : 0)}
                        {AmountsCard(t("Total Paid Amount"), this.state.AmountDetails.Paid ? this.state.AmountDetails.Paid : 0)}
                        {AmountsCard(t("Total Pending Amount"), this.state.AmountDetails.Pending ? this.state.AmountDetails.Pending : 0)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => this.printCreditBills()}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        {/* <Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                            </Button></Tooltip> */}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                columns={columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter.bind(this) }}
                >
                    <BillingsFilter PageName={"CreditBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.closeFilter.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)}/>
                </Drawer>
                {
                    this.state.CreditBillPopUp ?
                        <CreditBillCompletePoP
                            modules={this.state.SelectedBill?.bill_types === "Out Patient" ? ['op', 'lab', 'pharmacy', 'radiology'] : this.state.SelectedBill?.bill_types === "In Patient" ? ['ip', 'lab', 'pharmacy','radiology', 'ot'] : this.state.SelectedBill?.bill_types === "Pharmacy" ? ['pharmacy'] : this.state.SelectedBill?.bill_types === "Laboratory" ? ['lab'] : this.state.SelectedBill?.bill_types === "Radiology" ? ['radiology'] : []}
                            Module_ID_Key_Name={this.state.SelectedBill?.bill_types === "Out Patient" ? "appointment_id" : this.state.SelectedBill?.bill_types === "In Patient" ? "ip_admission_id" : "bill_id"}
                            Module_ID_Key_Value={this.state.SelectedBill?.module_id}
                            BillData={this.state.SelectedBill}
                            CrebillPosted={this.CrebillPosted.bind(this)}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            CollectedIn={this.state.SelectedBill?.bill_types === "Out Patient" ? "OP" : this.state.SelectedBill?.bill_types === "In Patient" ? "IP" : this.state.SelectedBill?.bill_types === "Pharmacy" ? "FO PH" : this.state.SelectedBill?.bill_types === "Laboratory" ? "FO LAB" : this.state.SelectedBill?.bill_types === "Radiology" ? "FO RAD" : ""}                          
                            Bill_Id={this.state.FilterDatas?.is_bill_wise ? ((this.state.SelectedBill?.bill_types === "Out Patient" || this.state.SelectedBill?.bill_types === "In Patient") ? this.state.SelectedBill?.id : null) : null}
                            from={this.state.FilterDatas?.is_bill_wise ? "" : 'admission_wise'}
                            />
                        : null
                }
                {
                    this.state.CreditTypeChangePopup ? <CreditTypeChangePop BillData={this.state.SelectedBill} CrebillTypeChaged={this.CrebillTypeChaged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
                }
                {
                    this.state.BillPreviewPop ?
                        <PrintPreviewPop
                            URL={Serviceurls.INVOICE_PRINT}
                            Title={t("BillPreview")}
                            BillData={this.state.SelectedBill}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            ModifyBillButton={false}
                            cancelBillButton={CheckAccessFunc("front_office", "Billing Transaction", 'Credit Bills', null, "Tab")?.editAccess && (this.state.SelectedBill?.bill_types === "In Patient" || this.state.SelectedBill?.bill_types === "Out Patient")}
                            PrintButton={false}
                            history={this.props.history} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {/* <Loader loaderOpen={this.state.isLoader} /> */}
                { this.state.receiptID !== null || this.state.pharmareceipt !== null ? 
                <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmareceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)}/> : null}
            </Box>
        )
    }
}

export default withTranslation()(CreditBills)

import React, { Component } from 'react'
import { Box, Button, Stack, Typography, InputLabel, Select, FormControl, MenuItem, TextField, Autocomplete } from '@mui/material'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { Speciality_Item } from '../../../Utility/Constants';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
class SurgeryTemplateAddConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectLayout: '',
      temName: '',
      surgeryNoteDesign: [],
      postSurNoteData: {},
      temID: null,
      updataLayoutData: [],
      splItem: Speciality_Item,
      selectSpeciality: null,
      specTxt: '',

    }
  }

  componentDidMount() {
    this.getLayoutList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history?.location?.state?.selectedID !== this.state.temID && prevProps.history?.location?.state?.selectedID !== null && prevProps.history?.location?.state?.selectedID !== undefined) {
      this.setState({
        temID: prevProps.history?.location?.state?.selectedID
      }, () => {
        this.props.history.push({ state: { selectedID: null } })
        this.getSurgeryNoteTemplate()
      })
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getLayoutList = () => {
    try {
      RestAPIService.getAll(Serviceurls.SUR_NOTES_LAYOUT)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              surgeryNoteDesign: response.data ? response.data.data : []
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  postSurgeryNotesTemplate = () => {
    try {
      var states = this.state
      var data = {
        "layout_name_id": states.selectLayout ? states.selectLayout : null,
        "template_name": states.temName ? states.temName : '',
        "template_data": states.postSurNoteData ? states.postSurNoteData : {}
      }
      if (states.temID) {
        data["template_id"] = states.temID
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.IP_TEMPLATE_SUR_NOTE)
        .then(response => {
          if (response.data.status === "success") {
            this.LoaderFunction(false)
            this.successMessage(response?.data?.message)
            setTimeout(() => {
              this.props.history.push('/Settings/TemplateConfig/SurgeryTemplateConfig')
            }, 1000);

          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(e.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getSurgeryNoteTemplate = () => {
    try {
      RestAPIService.getAll(Serviceurls.IP_TEMPLATE_SUR_NOTE + "?template_id=" + this.state.temID)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              updataLayoutData: response.data ? response.data.data : []
            }, () => { this.editSurgeryLayoutLayout(response.data.data) })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  editSurgeryLayoutLayout = (data) => {
    var tem_Data = data.template_data
    this.setState({
      selectLayout: data.layout_name_id,
      temName: data.template_name,
    }, () => {
      var states = this.state
      var data = states.surgeryNoteDesign.filter((item) => (item.id == states.selectLayout))
      var keys = {}
      data[0]?.surgery_json?.forEach((element) => {
        keys[element.field_name] = tem_Data[element.field_name]

      })
      this.setState({
        postSurNoteData: keys,
      })
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  selectLayoutCon = () => {
    var states = this.state
    const { t } = this.props
    return (
      <Box className='eMed_Dis_Layout_selectCon'>
        <Autocomplete
          noOptionsText={states.specTxt === '' ? t('PLS') : t('NDF')}
          options={states.splItem}
          getOptionLabel={(options) => typeof (options) === 'string' ? options : options.label}
          onChange={(e, value) => {
            this.setState({
              selectSpeciality: value,
            }, () => {
              if (value != null) {
                var states = this.state
                var filterLayout = states.surgeryNoteDesign ? states.surgeryNoteDesign.filter((item) => item.speciality_name === value.value) : null
                this.setState({
                  surgeryNoteDesign: filterLayout
                })
              } else {
                this.getLayoutList()
                this.setState({ selectLayout: '' })
              }
            })
          }}
          value={states.selectSpeciality}
          size='small'
          className='eMed_refdoc_txtbox'
          disablePortal
          id="Spec"
          renderInput={(params) => <TextField onChange={(event) => {
            const value = event.target.value
            if (value === "" || value != "") { this.setState({ specTxt: value }) }
          }} {...params} label={t('selectSpecial')} />}
        />
        <FormControl size='small' className='eMed_refdoc_txtbox'>
          <InputLabel id="demo-simple-select-label">{t('SelecttheLayout')}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disabled={this.state.selectSpeciality ? false : true}
            value={states.selectLayout}
            label={t('SelecttheLayout')}
            onChange={this.handleSelcLayout}
          >
            {states.surgeryNoteDesign?.length > 0 ? states.surgeryNoteDesign.map((item) => (
              <MenuItem value={item.id}>{item.layout_name}</MenuItem>
            )) : <Typography align='center'>{t('NDF')}</Typography>}

          </Select>
        </FormControl>
        <TextField
          size='small'
          className='eMed_DisSum_TxtBox'
          id="outlined-basic"
          label={t('TemplateName')}
          variant="outlined"
          value={states.temName}
          onChange={(event) => {
            var alphanum = CommonValidation.alphanumValidation(event.target.value)
            if (alphanum || event.target.value === '') {
              this.setState({
                temName: event.target.value
              })
            }
          }} />
      </Box>
    )
  }
  handleSelcLayout = (event) => {
    this.setState({
      selectLayout: event.target.value
    }, () => {
      var states = this.state
      var data = states.surgeryNoteDesign.filter((item) => (item.id == states.selectLayout))
      var keys = {}
      data[0]?.surgery_json?.forEach((element) => {
        keys[element.field_name] = ""

      })
      this.setState({
        postSurNoteData: keys
      })
    })
    this.renderSurgeryNotesTemLayout()
  }

  renderSurgeryNotesTemLayout = () => {
    var states = this.state
    var data = states.surgeryNoteDesign
    var data1 = data.filter((item) => (item.id == states.selectLayout))

    return (
      <Box className='eMed_Dis_Sum_Tem_lay_Scroll'>
        {data1.length === 1 ? data1[0].surgery_json.map((item) => {
          if (item.label === "Date/Time, Name of Doctor, Signature") {

          } else {
            return (
              this.renderTextBoxs(item.label, '90vw', item.field_name, item.is_mantatory, true, item.is_active)
            )
          }
        }) : <Box className='eMed_DisSum_Tem_Nolayout'>
          <Typography color={'#888888'}>{'Please Select the Layout'}</Typography>
        </Box>}


      </Box>
    )
  }
  renderTextBoxs = (label, width, key, mantatory, isrow, is_active) => {
    var states = this.state
    if (is_active) {
      return (
        <Box className='eMed_DisSum_Lay_CommonBox1'>
          <TextField
            sx={{ width: width }}
            id="icd-code"
            multiline={isrow}
            rows={3}
            name={key}
            label={mantatory ? label + "*" : label}
            variant="outlined"
            value={states.postSurNoteData[key] ? states.postSurNoteData[key] : ''}
            onChange={(event) => {
              states.postSurNoteData[key] = event.target.value
              this.setState({
                states
              })
            }}
          />
        </Box>
      )
    }
  }

  saveTemplate = () => {
    if (this.mantatoryFeilds()) {
      this.postSurgeryNotesTemplate()
    }
  }
  mantatoryFeilds = () => {
    var states = this.state
    var data = states.surgeryNoteDesign.filter((item) => (item.id == states.selectLayout))
    var ManFeild = data[0].surgery_json

    let flag = false;
    for (let i = 0; i < ManFeild.length; i++) {
      if (ManFeild[i].is_mantatory === true) {
        if (states.temName === '') {
          flag = false
          this.errorMessage('Please Enter the Template Name')
          break
        } else if (states.postSurNoteData[ManFeild[i].field_name] === '') {
          flag = false
          this.errorMessage(`${ManFeild[i].label} is Mantatory`)
          break
        } else {
          flag = true
        }
      }
    }
    return flag
  }

  ClearTemplateData = () => {
    var states = this.state
    var data = states.surgeryNoteDesign.filter((item) => (item.id == states.selectLayout))
    var keys = {}
    data[0]?.surgery_json?.forEach((element) => {
      keys[element.field_name] = ''

    })
    this.setState({
      postSurNoteData: keys,
    })
    this.renderSurgeryNotesTemLayout()
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t } = this.props
    return (
      <Box Component='div' paddingTop={'1vw'} className='eMed_usrconf_container'>
        <Box className='eMed_Dis_Template_MainCon'>
          <Box className='eMed_Dis_Tem_HeadCon'>
            <Typography padding={'0.5vw'}>{t('SurgeryNotes')}</Typography>
          </Box>
          {this.selectLayoutCon()}
          <hr />
          {this.renderSurgeryNotesTemLayout()}
          <Box className='eMed_Dis_Sum_Tem_BtnGroup'>
            <Stack spacing={2} direction="row">
              {this.state.temID ? <Button id='eMed_Config_btn'
              disabled={!CheckAccessFunc("front_office", "Settings", "Template Configuration",  "Surgery Notes Template Configuration", "SubTab")?.editAccess}
               className='eMed_DisSum_Lay_select2' variant='contained' onClick={() => {
                this.props.history.push({ pathname: '/Settings/TemplateConfig/SurgeryTemplateConfig/', state: { deleteID: this.state.temID } })
              }} >{t('Delete')}</Button> : null}
              <Button id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' onClick={() => { this.ClearTemplateData() }} variant="outlined">{t('Clear')}</Button>
              <Button id='eMed_Config_btn' disabled={this.state.selectLayout === '' || !CheckAccessFunc("front_office", "Settings", "Template Configuration",  "Surgery Notes Template Configuration", "SubTab")?.editAccess  ? true : false} className='eMed_DisSum_Lay_select2' onClick={() => { this.saveTemplate() }} variant="contained">{t('Save')}</Button>
            </Stack>
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
           <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

export default withTranslation()(SurgeryTemplateAddConfig);
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment, Menu, MenuItem, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, Tabs, TableRow, TextField, Tooltip, Typography, responsiveFontSizes, CircularProgress } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import Loader from '../../../Components/Loader';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'
import { CommonCorporateAndInsuranceDetails } from '../../../Components/Common Components/CommonComponents'
import { DateTime } from 'luxon'
import { CurrencySymbol } from '../../../Utility/Constants'



class PharmaRejection extends Component {

    constructor(props) {
        super(props)
        this.QuantityRef = React.createRef();
        this.DrugNameRef = React.createRef();
        this.state = {
            pharmacyTblData: [
                {
                    drug_name: '',
                    dosage_strength: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    return_quantity: '',
                    total_cost: '',
                    total_price: '',
                    stock_in_quantity: '',
                    discount_amount: "",
                    discount_percentage: "",
                    Drug_sugg_list: [],
                    gst_percentage: null,
                }
            ],
            isErrorMsg: false,
            successMsg: false,
            successMsgText: '',
            isErrorMsgText: '',
            DateTimerOpen: false,
            returnDateTime: new Date(),
            vendor_list: [],
            vendor_id: '',
            vendor_name: '',
            netAmount: 0,
            invoiceNumber: '',
            disableBtn: false
        }
    }

    componentDidMount = () => {
        this._getVendorList()
    }

    BatchearchLoader = (index) => {
        this.setState({
            SearchingBatchIndex: index
        })
    }

    DrugSearchLoader = (index) => {
        this.setState({
            SearchingDrugIndex: index
        })
    }

    _getVendorList = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_LIST}?from=purchase_order`)
                .then(response => {
                    if (response?.data?.status == "success") {
                        this.setState({
                            vendor_list: response?.data?.data,
                        });
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.status == "fail") {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getBrandListData = (index) => {
        try {
            let { pharmacyTblData } = this.state
            if (pharmacyTblData[index]['drug_name'] !== "") {
                this.DrugSearchLoader(index)
                let brandName = pharmacyTblData[index]['drug_name']?.replaceAll('&', '%26')
                RestAPIService.getAll(Serviceurls.PHARMA_BILLING_BRAND_SEARCH + `?search=${CommonValidation.removeSpace(brandName)}&return_type=Against Rejection`)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            if (response.data?.data?.length > 0) {
                                pharmacyTblData[index]['Drug_sugg_list'] = response.data.data
                                this.setState({ pharmacyTblData }, () => {
                                    this.DrugSearchLoader(null)
                                })
                            } else if (response.data?.data?.length === 0) {
                                this.DrugSearchLoader(null)
                                this.errorMessage("No Drug Found")
                            } else {
                                this.DrugSearchLoader(null)
                                this.errorMessage(response?.data?.message)
                            }
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.message)
                        }
                    })
            }
            else {
                this.errorMessage("Enter Brand Name")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDrugDetailsData = (index, FromDrug = false) => {
        try {
            let { pharmacyTblData } = this.state
            this.BatchearchLoader(index)
            let QueryParams = ""
            QueryParams = `?drug_ms_id=${this.state.pharmacyTblData[index]?.drug_ms_id ? this.state.pharmacyTblData[index]?.drug_ms_id : this.state.pharmacyTblData[index]?.drug_id}`
            RestAPIService.getAll(Serviceurls.PURCHASE_BATCHNO_GET + QueryParams)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({ selectedBatchListData: response.data.data })
                        if (response.data.data?.length > 0) {
                            pharmacyTblData[index]['Batch_Sugg_list'] = response.data.data
                            if (pharmacyTblData[index]['batch_no'] === "" || !pharmacyTblData[index]['batch_no']) {
                                let paraData = response.data.data
                                pharmacyTblData[index]['drug_id'] = response.data.data[0]?.drug_id
                                if (pharmacyTblData[index]?.drug_id === pharmacyTblData[index - 1]?.drug_id && paraData?.length > 1) {
                                    let sameBatch = pharmacyTblData?.some(item => item?.drug_id === response.data.data[1]?.drug_id && item?.batch_no === response.data.data[1]?.batch_no)
                                    if (sameBatch) {
                                        for (let i = 1; i < paraData?.length; i++) {
                                            if (!pharmacyTblData?.some(item => item?.drug_id === response.data.data[i]?.drug_id && item?.batch_no === response.data.data[i]?.batch_no)) {
                                                let totalCost = (pharmacyTblData[index]['return_quantity'] ? +(pharmacyTblData[index]['return_quantity']) : 0) * (response.data.data[i]?.buying_price_per_piece ? +(response.data.data[i]?.buying_price_per_piece) : 0)
                                                pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                                                pharmacyTblData[index]['stock_id'] = response.data.data[i]?.stock_id
                                                pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                                                pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                                                pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                                                pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                                                pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                                                pharmacyTblData[index]['cgst_percentage'] = response.data.data[i]?.cgst_percentage
                                                pharmacyTblData[index]['sgst_percentage'] = response.data.data[i]?.sgst_percentage
                                                pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                                pharmacyTblData[index]['discount_percentage'] = response.data.data[i]?.discount_percentage
                                                pharmacyTblData[index]['total_price'] = totalCost ? totalCost : 0
                                                break;
                                            }
                                        }
                                    } else {
                                        let totalCost = (pharmacyTblData[index]['return_quantity'] ? +(pharmacyTblData[index]['return_quantity']) : 0) * (response.data.data[1]?.buying_price_per_piece ? +(response.data.data[1]?.buying_price_per_piece) : 0)
                                        pharmacyTblData[index]['batch_no'] = paraData[1]?.batch_no
                                        pharmacyTblData[index]['stock_id'] = response.data.data[1]?.stock_id
                                        pharmacyTblData[index]['cost_per_quantity'] = response.data.data[1]?.cost_per_quantity
                                        pharmacyTblData[index]['expiry_date'] = response.data.data[1]?.expiry_date
                                        pharmacyTblData[index]['physical_box_no'] = response.data.data[1]?.physical_box_no
                                        pharmacyTblData[index]['stock_in_quantity'] = response.data.data[1]?.stock_in_quantity
                                        pharmacyTblData[index]['hsn_code'] = response.data.data[1]?.hsn_code
                                        pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[1]?.buying_price_per_piece
                                        pharmacyTblData[index]['cgst_percentage'] = response.data.data[1]?.cgst_percentage
                                        pharmacyTblData[index]['sgst_percentage'] = response.data.data[1]?.sgst_percentage
                                        pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                        pharmacyTblData[index]['discount_percentage'] = response.data.data[1]?.discount_percentage
                                        pharmacyTblData[index]['total_price'] = totalCost ? totalCost : 0
                                    }
                                } else {
                                    let sameBatchNo = pharmacyTblData?.some(item => item?.drug_id === response.data.data[0]?.drug_id && item?.batch_no === response.data.data[0]?.batch_no)
                                    if (sameBatchNo) {
                                        for (let i = 1; i < paraData?.length; i++) {
                                            if (!pharmacyTblData?.some(item => item?.drug_id === response.data.data[i]?.drug_id && item?.batch_no === response.data.data[i]?.batch_no)) {
                                                let totalCost = (pharmacyTblData[index]['return_quantity'] ? +(pharmacyTblData[index]['return_quantity']) : 0) * (response.data.data[i]?.buying_price_per_piece ? +(response.data.data[i]?.buying_price_per_piece) : 0)
                                                pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                                                pharmacyTblData[index]['stock_id'] = response.data.data[i]?.stock_id
                                                pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                                                pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                                                pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                                                pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                                                pharmacyTblData[index]['hsn_code'] = response.data.data[i]?.hsn_code
                                                pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                                                pharmacyTblData[index]['cgst_percentage'] = response.data.data[i]?.cgst_percentage
                                                pharmacyTblData[index]['sgst_percentage'] = response.data.data[i]?.sgst_percentage
                                                pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                                pharmacyTblData[index]['discount_percentage'] = response.data.data[i]?.discount_percentage
                                                pharmacyTblData[index]['total_price'] = totalCost ? totalCost : 0
                                                break;
                                            }
                                        }
                                    } else {
                                        let totalCost = (pharmacyTblData[index]['return_quantity'] ? +(pharmacyTblData[index]['return_quantity']) : 0) * (response.data.data[0]?.buying_price_per_piece ? +(response.data.data[0]?.buying_price_per_piece) : 0)
                                        pharmacyTblData[index]['batch_no'] = paraData[0]?.batch_no
                                        pharmacyTblData[index]['stock_id'] = response.data.data[0]?.stock_id
                                        pharmacyTblData[index]['cost_per_quantity'] = response.data.data[0]?.cost_per_quantity
                                        pharmacyTblData[index]['expiry_date'] = response.data.data[0]?.expiry_date
                                        pharmacyTblData[index]['physical_box_no'] = response.data.data[0]?.physical_box_no
                                        pharmacyTblData[index]['stock_in_quantity'] = response.data.data[0]?.stock_in_quantity
                                        pharmacyTblData[index]['hsn_code'] = response.data.data[0]?.hsn_code
                                        pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[0]?.buying_price_per_piece
                                        pharmacyTblData[index]['cgst_percentage'] = response.data.data[0]?.cgst_percentage
                                        pharmacyTblData[index]['sgst_percentage'] = response.data.data[0]?.sgst_percentage
                                        pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                        pharmacyTblData[index]['discount_percentage'] = response.data.data[0]?.discount_percentage
                                        pharmacyTblData[index]['total_price'] = totalCost ? totalCost : 0
                                    }
                                }
                            }
                            this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => {
                                this.BatchearchLoader(null)
                            })
                            if (FromDrug && !this.state.isFromIpRequest) {
                                this.QuantityRef?.current?.focus();
                            }
                        } else {
                            pharmacyTblData[index]['Batch_Sugg_list'] = []
                            this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => { this.BatchearchLoader(null) })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.BatchearchLoader(null)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.BatchearchLoader(null)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    billingCalculation = (params = [], type) => {
        try {
            let amount = 0
            let payableAmount = 0
            switch (type) {
                case 'totalServiceAmount':
                    params.forEach(element => {
                        amount = amount + (element.total_price ? element.total_price : 0)
                    })
                    if (this.state.netAmount !== +amount) {
                        this.setState({
                            netAmount: +amount,
                            RoundOffednetAmount: Math.round(+amount)
                        })
                    }
                    return AmountFormat(this.state.RoundOffednetAmount)
                case 'roundOff': return this.state.netAmount ? (Math.round(this.state.netAmount) - this.state.netAmount).toFixed(2) : ""
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    customPharmaFooter = (params) => {
        try {
            const { t } = this.props
            let states = this.state
            let disable = this.state.pharmacyTblData[0].return_quantity ? false : true
            return (
                <Box component={'div'} height={'8vw'} className='eMed_Phrama_Table_Container'>
                    <Box component={'div'} display={'flex'} marginLeft={'69vw'}>
                        <Box component={'div'}>
                            <Box component={'div'} className='eMed_bill_tbl_footer' marginBottom={'1vw'}>
                                <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{t("Round Off")}</Typography>
                                <Typography emed_tid="total_net_amount" mt={0.5} mr={'0.8vw'} fontWeight='600'>{this.billingCalculation(params, 'roundOff')}</Typography>
                            </Box>
                            <Box component={'div'} className='eMed_bill_tbl_footer'>
                                <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{t("Net Amount")}</Typography>
                                <Typography emed_tid="total_net_amount" mt={0.5} mr={'0.8vw'} fontWeight='600'>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background, marginTop: '0.5vw' }}>
                        <Box display={'flex'} marginLeft={'75vw'}>
                            <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                                size='small'
                                variant='outlined'
                                onClick={() => { this.discardAction() }}
                            >Clear</Button>
                            <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: "10vw" }}
                                size='small'
                                variant='contained'
                                disabled={disable || this.state.disableBtn}
                                onClick={() => { this.setState({ disableBtn: true }, () => { this.postPurchaseReturn() }) }}
                            >Finish</Button>
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderDrugColumn = (index, item) => {
        let frequent = "Frequently Brought Medicine"
        let { pharmacyTblData } = this.state
        return (
            <Box>
                <Tooltip title={pharmacyTblData[index]['drug_name']?.length > 18 ? (pharmacyTblData[index]['drug_name'] ? pharmacyTblData[index]['drug_name'] : "") : ""} placement="top" arrow>
                    <TextField
                        inputRef={this.DrugNameRef}
                        size='small'
                        variant='standard'
                        autoComplete='off'
                        value={item.drug_name}
                        onChange={(e) => {
                            if (e.target.value !== "") {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                pharmacyTblData[index]['dosage_strength'] = ""
                                pharmacyTblData[index]['batch_no'] = ""
                                pharmacyTblData[index]['stock_id'] = ""
                                pharmacyTblData[index]['dosage_type'] = ""
                                pharmacyTblData[index]['physical_box_no'] = ""
                                pharmacyTblData[index]['cost_per_quantity'] = ""
                                pharmacyTblData[index]['total_cost'] = ""
                                pharmacyTblData[index]['return_quantity'] = ""
                                pharmacyTblData[index]['total_price'] = ""
                                pharmacyTblData[index]['hsn_code'] = ""
                                pharmacyTblData[index]['stock_in_quantity'] = ""
                                pharmacyTblData[index]['expiry_date'] = ""
                                pharmacyTblData[index]['Drug_sugg_list'] = []
                                pharmacyTblData[index]['discount_percentage'] = ""
                                pharmacyTblData[index]['discount_amount'] = ""
                                delete pharmacyTblData[index]['Batch_Sugg_list']
                                this.setState({ pharmacyTblData }, () => {
                                    if (CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])?.length > 2) {
                                        this.setState({
                                            DurgNameCurrentIndex: index
                                        }, () => { this.getBrandListData(index) })
                                    }
                                })
                            } else if (e.target.value === "" && index !== (pharmacyTblData.length - 1)) {
                                this.state.pharmacyTblData.splice(index, 1)
                                this.setState({ pharmacyTblData: this.state.pharmacyTblData })
                            } else {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                this.setState({ pharmacyTblData })
                            }
                        }}
                        sx={{ width: '11vw' }}
                        onKeyDown={(e) => {
                            this.setState({
                                DrugNameAnchor: e.currentTarget,
                                FocusDrugList: e.key === "ArrowDown" ? true : false
                            }, () => {
                                if (e.key === 'Enter' && pharmacyTblData[index]['drug_name']?.length > 2) {
                                    this.setState({
                                        DurgNameCurrentIndex: index
                                    }, () => { this.getBrandListData(index) })
                                }
                            })
                        }}
                        inputProps={{ emed_tid: "bill_drug_name", paddingLeft: '0.4vw' }}
                        InputProps={{
                            endAdornment: this.state.SearchingDrugIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : null,
                        }}

                    />
                </Tooltip>


                <Menu
                    open={this.state.DurgNameCurrentIndex === index && item.Drug_sugg_list && item.Drug_sugg_list?.length > 0 && item.drug_name !== ""}
                    className="eMed_Drug_Sugg_Pop"
                    anchorEl={this.state.DrugNameAnchor}
                    onClose={() => {
                        this.setState({ DurgNameCurrentIndex: null })
                    }}
                    autoFocus={false}
                    disableAutoFocusItem
                    disableAutoFocus
                >
                    <Box width={"26vw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.3vw' }}>
                            <Tooltip placement='top' title={frequent}><Typography width={"70%"} fontWeight={'600'} fontSize={"0.8vw"} paddingLeft={'1vw'}>{frequent}</Typography></Tooltip>
                            <Typography width={"30%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>Quantity</Typography>
                        </Box>
                        <Box maxHeight={'14vw'} overflow={'scroll'}>
                            {
                                (item.Drug_sugg_list && item.Drug_sugg_list.length > 0) ?
                                    item.Drug_sugg_list.map((list, Drugindex) => (
                                        <MenuItem
                                            onKeyDown={(e) => {
                                                if (Drugindex === item.Drug_sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                    e.stopPropagation()
                                                    this.setState({ DurgNameCurrentIndex: index })
                                                }
                                            }}
                                            emed_tid="bill_drug_sugg_li"
                                            key={Drugindex}
                                            autoFocus={this.state.FocusDrugList && Drugindex === 0}
                                            onClick={() => {
                                                this.setState({
                                                    DurgNameCurrentIndex: null
                                                }, () => {
                                                    this.onAddHandler("return_quantity", index)
                                                    this.autocompleteOnChangeHandler(list, "drug_name", index)
                                                })
                                            }}
                                            sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box width={"70%"}>
                                                <CommonCorporateAndInsuranceDetails data={list} fromPharmaBilling={true} />
                                            </Box>
                                            <Typography width={"30%"} fontSize={"0.8vw"} textAlign={"center"}>{list?.in_stock}</Typography>
                                        </MenuItem>
                                    )) : null
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }
    onAddHandler = (names, index) => {
        try {
            if (this.state.pharmacyTblData[index]['drug_name']) {
                if (names === 'return_quantity' || names === 'discount') {
                    if (index === this.state.pharmacyTblData?.length - 1) {
                        let duplicateTbl = {
                            drug_name: '',
                            dosage_strength: '',
                            dosage_type: '',
                            batch_no: '',
                            stock_id: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            return_quantity: '',
                            total_cost: '',
                            discount: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            Drug_sugg_list: [],
                            Batch_Sugg_list: [],
                        }
                        this.state.pharmacyTblData = [...this.state.pharmacyTblData, duplicateTbl]
                        this.setState({
                            pharmacyTblData: this.state.pharmacyTblData
                        }, () => {
                            this.setState({
                                drugDetailsData: []
                            }, () => {
                                // this.DrugNameRef?.current.focus()
                            })
                        })
                    }
                }
            } else {
                this.errorMessage('Enter All Fields')
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteLineItems = (index = null) => {
        try {
            let BillingLineItems = []
            this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
            if (index === null || (index === 0 && BillingLineItems.length === 1)) {
                this.setState({
                    pharmacyTblData: [
                        {
                            drug_name: '',
                            dosage_strength: '',
                            dosage_type: '',
                            batch_no: '',
                            stock_id: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            return_quantity: '',
                            total_cost: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            Drug_sugg_list: [],
                        }
                    ],
                    netAmount: 0,
                    ReturnedNetAmount: 0,
                }, () => {
                })
            } else {
                if (index !== this.state.pharmacyTblData.length - 1) {
                    this.state.pharmacyTblData.splice(index, 1)
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData })
                } else {
                    this.state.pharmacyTblData[index] = {
                        drug_name: '',
                        dosage_strength: '',
                        dosage_type: '',
                        batch_no: '',
                        stock_id: '',
                        expiry_date: '',
                        physical_box_no: '',
                        cost_per_quantity: '',
                        return_quantity: '',
                        total_cost: '',
                        total_price: '',
                        hsn_code: '',
                        stock_in_quantity: '',
                        Drug_sugg_list: [],
                    }
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData }, () => {
                    })
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    calculateDiscountTotalPrice = (index, fromAmount = false) => {
        try {
            let { pharmacyTblData } = this.state
            let totalCost = pharmacyTblData[index]['total_cost']
            if (fromAmount) {
                let discountValue = +pharmacyTblData[index]['discount_amount']
                pharmacyTblData[index]['discount_percentage'] = CommonValidation.amountPercentage(+pharmacyTblData[index]['discount_amount'], totalCost)
                pharmacyTblData[index]['total_price'] = totalCost - discountValue
            } else {
                let discount = pharmacyTblData[index]['discount_percentage']
                let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
                pharmacyTblData[index]['discount_amount'] = discountValue
                pharmacyTblData[index]['total_price'] = totalCost - discountValue
            }

            this.setState({ pharmacyTblData }, () => {

            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateCostAndTotal = (value, index, FromReturn = false) => {
        try {
            let { pharmacyTblData } = this.state
            pharmacyTblData[index]['total_cost'] = +value * +(pharmacyTblData[index]['buying_price_per_piece'].toFixed(2))
            let totalCost = pharmacyTblData[index]['total_cost']?.toFixed(2)
            let FinalPrice = +totalCost
            pharmacyTblData[index]['total_return_price'] = FinalPrice
            pharmacyTblData[index]['total_price'] = FinalPrice
            this.setState({ pharmacyTblData }, () => { this.calculateDiscountTotalPrice(index) })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, index) => {
        try {
            const { name, value } = e.target
            switch (name) {
                case 'return_quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if ((CommonValidation.numberOnly(value) && value?.length < 6) && (value != 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateCostAndTotal(value, index, true)
                            })
                        } else if ((value === '') || (value === 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = ''
                            this.state.pharmacyTblData[index]['total_cost'] = ''
                            this.state.pharmacyTblData[index]['total_return_price'] = ''
                            this.state.pharmacyTblData[index]['total_price'] = ''
                            this.setState({
                                totalDiscount: 0,
                                pharmacyTblData: this.state.pharmacyTblData
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'discount_percentage':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if ((CommonValidation.DecimalNumber(value) && +value <= 100) || (value === "") || (value === 0)) {
                            this.state.pharmacyTblData[index]['discount_percentage'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateDiscountTotalPrice(index)
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'discount_amount':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if ((CommonValidation.NumberWithDot(value) && +value <= this.state.pharmacyTblData[index]['total_cost']) || (value === "") || (value === 0)) {
                            this.state.pharmacyTblData[index]['discount_amount'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateDiscountTotalPrice(index, true)
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                default:
                    this.setState({
                        [name]: value
                    })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }


    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    inputTextBox = (values, names, labels, width, index, height, disable) => {
        return (
            <TextField
                inputRef={this.state.isFromIpRequest ? null : ((names === "return_quantity" && this.state.QuantityCurrentIndex === index) ? this.QuantityRef : null)}
                autoFocus
                size='small'
                variant='standard'
                sx={{ width: width }}
                placeholder={labels}
                disabled={this.state.pharmacyTblData[index]['drug_name'] && this.state.pharmacyTblData[index]['dosage_strength'] && this.state.pharmacyTblData[index]['dosage_type'] && this.state.pharmacyTblData[index]['batch_no'] != "" ? false : true}
                onKeyDown={(e) => { if (e.key === "Enter") { this.DrugNameRef?.current.focus() } }}
                name={names}
                autoComplete='off'
                inputProps={{
                    emed_tid: labels,
                    style: {
                        height: height ? height : '2vh', marginTop: '0.5vw', textAlign: 'center',
                        color: (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["return_quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"]) ? "red" : null) : null
                    }
                }}
                value={values}
                onChange={(e) => { this.onChangeHandler(e, index) }}
                error={(names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["return_quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"])) : null}
            />
        )
    }

    autocompleteOnChangeHandler = (newValue, names, index) => {
        try {
            let { pharmacyTblData } = this.state
            switch (names) {
                case 'drug_name':
                    pharmacyTblData[index][names] = newValue['drug_name']
                    pharmacyTblData[index]['dosage_strength'] = newValue['dosage_strength']
                    pharmacyTblData[index]['dosage_type'] = newValue['dosage_type']
                    pharmacyTblData[index]['drug_ms_id'] = newValue['drug_ms_id']
                    pharmacyTblData[index]['return_quantity'] = ""
                    pharmacyTblData[index]['total_cost'] = ""
                    pharmacyTblData[index]['total_price'] = ""
                    this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => this.getDrugDetailsData(index, true))
                    break;
                case 'batch_no':
                    let batchSelected = false
                    pharmacyTblData?.forEach(element => {
                        if ((element?.batch_no === newValue['batch_no']) && (element?.drug_id === newValue['drug_id'] && (element?.expiry_date === newValue['expiry_date']) && (element?.cost_per_quantity === newValue['cost_per_quantity']))) {
                            batchSelected = true
                        }
                    })
                    if (batchSelected) {
                        this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
                    } else {
                        pharmacyTblData[index][names] = newValue['batch_no']
                        pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
                        pharmacyTblData[index]['stock_id'] = newValue['stock_id']
                        pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
                        pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
                        pharmacyTblData[index]['drug_id'] = newValue['drug_id']
                        pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
                        pharmacyTblData[index]['total_cost'] = ""
                        pharmacyTblData[index]['total_price'] = ""
                        pharmacyTblData[index]['schedule'] = newValue['schedule']
                        pharmacyTblData[index]['cgst_percentage'] = newValue['cgst_percentage']
                        pharmacyTblData[index]['sgst_percentage'] = newValue['sgst_percentage']
                        pharmacyTblData[index]['discount_percentage'] = newValue['discount_percentage']

                        this.setState({ pharmacyTblData, QuantityCurrentIndex: index, BatchAnchor: null, CurrentBatchIndex: null }, () => {
                            this.calculateCostAndTotal(pharmacyTblData[index]['return_quantity'], index, false)
                            if (pharmacyTblData[index]['return_quantity'] === "") { this.QuantityRef?.current?.focus() }
                        })
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderBatchColumn = (index, item) => {
        return (
            <Box>
                <TextField
                    inputRef={this.BatchNameRef}
                    disabled={this.state.pharmacyTblData[index]['drug_name'] && this.state.pharmacyTblData[index]['dosage_strength'] && this.state.pharmacyTblData[index]['dosage_type'] ? false : true}
                    size='small'
                    variant='standard'
                    autoComplete='off'
                    value={item.batch_no}
                    onKeyDown={(e) => {
                        e.preventDefault()
                        // this.setState({ BatchAnchor: e.currentTarget })
                    }}
                    onFocus={(e) => {
                        this.setState({
                            BatchAnchor: e.currentTarget,
                            CurrentBatchIndex: index
                        }, () => {
                            e.preventDefault()
                            if ((!this.state.pharmacyTblData[index]['Batch_Sugg_list'])) {
                                this.getDrugDetailsData(index, false)
                            }
                        })
                    }}
                    inputProps={{ emed_tid: "bill_batch_name", style: { paddingLeft: '0.4vw' } }}
                    InputProps={{
                        endAdornment: this.state.SearchingBatchIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : <InputAdornment position="end"><ArrowDropDownIcon size={15} /></InputAdornment>,
                    }}

                />
                <Menu
                    open={this.state.CurrentBatchIndex === index}
                    className="eMed_Drug_Sugg_Pop"
                    anchorEl={this.state.BatchAnchor}
                    onClose={() => {
                        this.setState({ BatchAnchor: null, CurrentBatchIndex: null })
                    }}
                    autoFocus={true}
                    disableAutoFocusItem
                    disableAutoFocus
                    disableRestoreFocus
                >
                    <Box width={"30vw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.4vw' }} emed_tid={"bill_batch_no_items"}>
                            <Typography width={"35%"} fontWeight={600} fontSize={'0.8vw'} paddingLeft={'1vw'}>Batch No</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'} >Expiry Date</Typography>
                            <Typography width={"25%"} fontWeight={600} fontSize={'0.8vw'}>Avl/Qty</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'} >Cost/Qty</Typography>
                        </Box>
                        <Box maxHeight={'14vw'} overflow={'scroll'}>
                            {
                                (item.Batch_Sugg_list && item.Batch_Sugg_list.length > 0) ?
                                    item.Batch_Sugg_list.map((list, Drugindex) => {
                                        var datePart = list?.expiry_date ? list?.expiry_date?.match(/\d+/g) : []
                                        var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                                        return (
                                            <MenuItem
                                                onKeyDown={(e) => {
                                                    if (Drugindex === item.Batch_Sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                    else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                        e.stopPropagation()
                                                        this.setState({ DurgNameCurrentIndex: index })
                                                    }
                                                }}
                                                emed_tid={`batch_suggestion`}
                                                key={Drugindex}
                                                autoFocus={true}
                                                onClick={() => { this.autocompleteOnChangeHandler(list, 'batch_no', index) }}
                                                sx={[{ display: 'flex', flexDirection: 'row', backgroundColor: list.batch_expiry_alert ? '#EE9E4F' : 'white', '&:hover': list.batch_expiry_alert ? { backgroundColor: '#f0bc89' } : null }]}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                                                    {list?.batch_no ? list?.batch_no?.length > 12 ?
                                                        <Tooltip placement='top' title={list?.batch_no} arrow>
                                                            <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_no?.slice(0, 10) + "..."}</Typography>
                                                        </Tooltip>
                                                        : <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_no}</Typography> : "-"}
                                                    <Typography width={"20%"} fontSize={'0.8vw'}>{ExpiryDate}</Typography>
                                                    <Typography width={"25%"} fontSize={'0.8vw'}>{list?.stock_in_quantity}</Typography>
                                                    <Typography width={"20%"} fontSize={'0.8vw'} textAlign={'center'}>{list?.buying_price_per_piece}</Typography>
                                                </Box>
                                            </MenuItem>
                                        )
                                    }) : <Typography textAlign={"center"} paddingY={"0.5vw"} width={"100%"} fontSize={'0.8vw'}>{"No Batches Found"}</Typography>
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }

    postPurchaseReturn = () => {
        try {
            let Lineitems = []
            let states = this.state
            let flag = false
            this.state.pharmacyTblData.forEach((item, index) => {
                if (item.drug_name !== "" && item?.return_quantity !== "") {
                    let BillItem = {
                        "stock_id": item.stock_id,
                        "purchase_rate_per_qty": item?.buying_price_per_piece ? item.buying_price_per_piece : 0,
                        "net_amount": item?.total_price ? item.total_price : 0,
                        "cgst_percentage": item?.cgst_percentage ? item.cgst_percentage : 0,
                        "sgst_percentage": item?.sgst_percentage ? item.sgst_percentage : 0,
                        "return_quantity": +item.return_quantity,
                        "discount_percentage": item?.discount_percentage ? +item.discount_percentage : 0,
                        "discount_amount": item?.discount_amount ? +item.discount_amount : 0,
                    }
                    Lineitems.push(BillItem)
                } else if ((index != this.state.pharmacyTblData.length - 1) && (item?.return_quantity === "" || item?.return_quantity === 0)) {
                    flag = true
                    this.errorMessage("Enter Return Qty")
                    this.setState({ disableBtn: false })
                }
            })

            let Datetime = new Date(this.state.returnDateTime)
            let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
            let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_WITH_SECONDS)

            let data = {
                "vendor_id": states.vendor_id,
                "po_return_date": `${date}`,
                "invoice_number": states.invoiceNumber,
                "net_amount": states.RoundOffednetAmount ? +(states.RoundOffednetAmount)?.toFixed(2) : 0,
                "line_items": Lineitems
            }
            if (this.state.vendor_name === "") {
                this.errorMessage("Select Vendor Name")
                this.setState({ disableBtn: false })
            } else if (this.state.invoiceNumber === "") {
                this.errorMessage("Enter Invoice Number")
                this.setState({ disableBtn: false })
            } else if (!flag) {
                RestAPIService.create(data, Serviceurls.PURCHASE_RETURN_POST)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.discardAction(true, response.data.message)
                            this.setState({ disableBtn: false })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                        this.setState({ disableBtn: false })
                    })

            }
        } catch (e) {
            this.errorMessage(e.message);
            this.setState({ disableBtn: false })
        }
    }

    discardAction = (key, msg) => {
        const EmptyBillItem = [
            {
                drug_name: '',
                dosage_strength: '',
                dosage_type: '',
                batch_no: '',
                expiry_date: '',
                physical_box_no: '',
                cost_per_quantity: '',
                quantity: '',
                total_cost: '',
                discount: '',
                total_price: '',
                hsn_code: '',
                stock_in_quantity: '',
                Drug_sugg_list: [],
            }
        ]
        this.setState({
            pharmacyTblData: EmptyBillItem,
            drugDetailsData: [],
            vendor_id: '',
            vendor_name: "",
            returnDateTime: new Date(),
            invoiceNumber: ""

        }, () => {
            if (key) {
                this.props.history.push({ pathname: '/PharmacyPurchase/PurchaseRejection', state: { successMsg: msg } })
            }
        })

    }
    render() {
        const { t } = this.props
        let states = this.state
        let nowdate = new Date();
        return (
            <Box component={'div'} className='eMed_Pharma_Billing_container'>
                <Box component={'div'} className='eMed_Pharma_Header_Card'>
                    <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" onClick={() => {
                        this.props.history.push({ pathname: '/PharmacyPurchase/PurchaseRejection' })
                    }} ><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {t("Purchase Rejection")}</Typography></Stack>
                </Box>
                <Box component={'div'} height={'7vw'} margin={'0.8vw'} padding={'0.5vw'} sx={{ backgroundColor: 'white', border: '1px solid lightgray' }}>
                    <div style={{ borderBottom: '1px solid lightgray' }}>Rejection Details</div>
                    <Box sx={{ display: 'flex', padding: '1vw', gap: '1vw' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                disabled={true}
                                emed_tid="Rejection_DateTime"
                                open={this.state.DateTimerOpen}
                                onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                                onClose={() => { this.setState({ DateTimerOpen: false }) }}
                                maxDateTime={new Date(Date.now())}
                                minDate={new Date(nowdate.getFullYear(), nowdate.getMonth(), nowdate.getDate() - 1)}
                                label={t("Rejection Date & Time")}
                                value={states.returnDateTime}
                                inputFormat='DD-MM-YYYY & hh:mmA'
                                onChange={(value) => {
                                    if (value === null) {
                                        states.returnDateTime = new Date()
                                    } else {
                                        states.returnDateTime = value
                                    }
                                    this.setState({ states })
                                }}
                                renderInput={(params) => <TextField
                                    size="small" {...params}
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={() => { this.setState({ DateTimerOpen: true }) }}
                                    sx={{ width: "15vw" }}
                                />}
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            size='small'
                            clearIcon={false}
                            sx={{ width: '20vw' }}
                            options={states.vendor_list}
                            getOptionLabel={(option) => (typeof (option?.name) === "string" ? option?.name : option)}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option?.vendor_id}>
                                        {option?.name}
                                    </li>
                                )
                            }}
                            value={states.vendor_name ? states.vendor_name : ""}
                            ListboxProps={{ style: { maxHeight: 300 } }}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    states.vendor_name = newValue?.name // value to be shown
                                    states.vendor_id = newValue?.vendor_id // actual value
                                    this.setState({ states })
                                }

                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Vendor Name") + " *"}
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        emed_tid: "vendor_name",
                                    }}
                                />
                            )}
                        />
                        <TextField
                            size='small'
                            sx={{ width: '15vw' }}
                            label='Invoice Number *'
                            value={this.state.invoiceNumber}
                            onChange={(e) => {
                                if (e.target.value?.length <= 15) {
                                    this.setState({
                                        invoiceNumber: e.target.value
                                    })
                                }
                            }}
                            autoComplete='off'
                        />
                    </Box>


                </Box>
                <Box component={'div'}>
                    <Box component={'div'}>
                        <TableContainer className='eMed_Phrama_Table_Container' sx={{ height: '41vh' }}>
                            <Table stickyHeader size='small' sx={{ overflowY: 'scroll' }}>
                                <TableHead>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ flex: 0.01 }} className='eMed_Pharma_tbl_header'>
                                            <Button emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems()}>
                                                <Box component={'img'} mt={'0.2vw'} src={ImagePaths.Delete.default} alt='del' height={'1.3vw'} width={'1.3vw'} />
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.01 }} className='eMed_Pharma_tbl_header'>S.No</TableCell>
                                        <TableCell sx={{ flex: 0.15 }} className='eMed_Pharma_tbl_header'>Brand Name</TableCell>
                                        <TableCell sx={{ flex: 0.078 }} align='center' className='eMed_Pharma_tbl_header'>Strength</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.083 }} className='eMed_Pharma_tbl_header'>Type</TableCell>
                                        <TableCell sx={{ flex: 0.15 }} className='eMed_Pharma_tbl_header'>Batch</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.10 }} className='eMed_Pharma_tbl_header'>Expiry Date</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.08 }} className='eMed_Pharma_tbl_header'>Box No.</TableCell>
                                        <TableCell sx={{ flex: 0.08 }} align='center' className='eMed_Pharma_tbl_header'>Cost/Qty</TableCell>
                                        <TableCell sx={{ flex: 0.08 }} align='center' className='eMed_Pharma_tbl_header'>Avl Qty</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.09 }} className='eMed_Pharma_tbl_header'>
                                            <Tooltip placement='top' title='Quantity'><div>Qty</div></Tooltip>
                                        </TableCell>
                                        <TableCell align='center' sx={{ flex: 0.09 }} className='eMed_Pharma_tbl_header'>
                                            <Tooltip placement='top' title='Dicount in Percent'><div>{`Disc ( % )`}</div></Tooltip>
                                        </TableCell>
                                        <TableCell align='center' sx={{ flex: 0.09 }} className='eMed_Pharma_tbl_header'>
                                            <Tooltip placement='top' title='Dicount in Amount'><div>{`Disc ( ${CurrencySymbol} )`}</div></Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.09 }} align='center' className='eMed_Pharma_tbl_header'>{`Total Price`}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.pharmacyTblData.map((item, index) => {
                                        var datePart = item?.expiry_date ? item?.expiry_date.match(/\d+/g) : []
                                        var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                                        return (
                                            <Tooltip title={item?.stock_in_quantity === 0 ? "No Stock Available" : ""} placement='top' arrow>
                                                <TableRow sx={{ display: 'flex', backgroundColor: item?.stock_in_quantity === 0 ? Colors.disableComponentColor : null }} key={index}>
                                                    <TableCell sx={{ flex: 0.01 }}>
                                                        <Button emed_tid={"bill_delete_item"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems(index)}>
                                                            <Box component={'img'} mt={'0.5vw'} src={ImagePaths.Delete.default} alt='del' height={'1.1vw'} width={'1.1vw'} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.01, paddingTop: '0.8vw' }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ flex: 0.15 }}>{this.renderDrugColumn(index, item)}</TableCell>
                                                    <TableCell align='center' emed_tid="bill_dosage_strength" sx={{ flex: 0.078, paddingTop: '0.8vw' }} className='eMed_Pharma_tbl_Body'>{item?.dosage_strength ? item?.dosage_strength : '-'}</TableCell>
                                                    <TableCell align='center' emed_tid="bill_dosage_type" sx={{ flex: 0.083, paddingTop: '0.8vw' }}>
                                                        {item?.dosage_type ? item?.dosage_type?.length > 10 ?
                                                            <Tooltip placement='top' title={item?.dosage_type} arrow>
                                                                <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type?.slice(0, 10) + "..."}</div></Tooltip> :
                                                            item?.dosage_type : "-"}
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.15 }}>
                                                        {this.renderBatchColumn(index, item)}
                                                    </TableCell>
                                                    <TableCell align='center' emed_tid="bill_expiry_date" sx={{ flex: 0.10, paddingTop: '0.8vw' }}>{ExpiryDate}</TableCell>
                                                    <TableCell align='center' emed_tid="bill_physical_box_no" sx={{ flex: 0.08, paddingTop: '0.8vw' }}>{item?.physical_box_no ? item?.physical_box_no : '-'}</TableCell>
                                                    <TableCell emed_tid="buying_price_per_piece" align='center' sx={{ flex: 0.08, paddingTop: '0.8vw' }}>{item?.buying_price_per_piece ? (item?.buying_price_per_piece).toFixed(2) : '0'}</TableCell>
                                                    <TableCell emed_tid="bill_cost_per_quantity" align='center' sx={{ flex: 0.08, paddingTop: '0.8vw' }}>{item?.stock_in_quantity ? item?.stock_in_quantity : '0'}</TableCell>
                                                    <TableCell sx={{ flex: 0.09 }} align='center'>
                                                        {this.inputTextBox(item?.return_quantity, 'return_quantity', 'Qty', '3vw', index)}
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.09 }} align='center'>
                                                        {this.inputTextBox(item?.discount_percentage, 'discount_percentage', 'Discount', '3vw', index)}
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.09 }} align='center'>
                                                        {this.inputTextBox(item?.discount_amount, 'discount_amount', 'Discount', '3vw', index)}
                                                    </TableCell>
                                                    <TableCell emed_tid="bill_total_price" align='center' sx={{ flex: 0.09, paddingTop: '0.8vw' }}>{item?.total_price ? (item?.total_price).toFixed(2) : '0'}</TableCell>
                                                </TableRow>
                                            </Tooltip>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {this.customPharmaFooter(this.state.pharmacyTblData)}
                        {this.state.isErrorMsg ?
                            <ToastMsg
                                severity={'error'}
                                msg={this.state.isErrorMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null}
                        {this.state.successMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.successMsgText}
                                msgPop={this.msgClose.bind(this)}
                                testId={this.state.invoiceNumber}
                            />
                            : null}
                        <Loader loaderOpen={this.state.isLoader} />
                    </Box>
                </Box>
            </Box>
        )
    }
}
export default withTranslation()(PharmaRejection)

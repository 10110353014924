import { Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Colors } from '../../../../../Styles/Colors'
import CommonGridHeader, { CommonPatientDetails } from '../../../../../Components/Common Components/CommonComponents'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'
import CommonDatePicker from '../../../../../Components/Common Components/CommonDatePicker'

class PharmaUserAccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newUserData: [],
            isEdit: false,
            FilterOpen: false,
            fromDate: "",
            toDate: "",
            UserStatus: "",
            dateIndex: "",
            Username: "",
        }
    }

    componentDidMount() {
        this.getUserData()
        if (this.props.history?.location?.state?.NewUserCreated) {
            this.successMessgae(this.props.history?.location?.state?.Message)
            this.props.history.push({ pathname: "/PharmacySetting/PharmaUserAccess", state: { NewUserCreated: undefined, Message: undefined } })
        }
    }

    errorMessgae = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessgae = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getUserData = () => {
        try {
            let AccessList = ['sublocation']
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.PHARMA_USER_ACCESS_GET + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&search_key=${this.state.Username}&user_modules=${AccessList}&active_status=${this.state.UserStatus}`).
                then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            newUserData: response.data.data,
                            disableBtn: false,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessgae(error.message)
            this.setState({disableBtn: false})
        }
    }


    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
    ClearData = () => {
        this.setState({
            fromDate: "",
            toDate: "",
            UserStatus: "",
            dateIndex: "",
            Username: "",
            FoAccess: true,
            LabAccess: true,
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>

                    {/* <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} />
                    </Box> */}
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            size='small'
                            fullWidth
                            label="User Name"
                            value={this.state.Username}
                            name="Username"
                            onChange={(e) => { this.setState({ Username: e.target.value }) }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <FormControl size='small' fullWidth>
                            <InputLabel>{t("Status")}</InputLabel>
                            <Select
                                label={t("Status")}
                                name='status'
                                value={this.state.UserStatus}
                                onChange={(e) => { this.setState({ UserStatus: e.target.value }) }}
                            >
                                <MenuItem value={true} >{"Active"}</MenuItem>
                                <MenuItem value={false} >{"Inactive"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => { this.ClearData() }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='UserAccess_Filter_Save_Btn' disabled={this.state.disableBtn}
                        onClick={() => {
                            this.getUserData()
                            this.setState({
                                FilterOpen: false
                            })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        const { t } = this.props

        this.state.newUserData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: "login_fname", headerName: "User Information", flex: 0.166,
                renderCell: (params) => (
                    <Box component={'div'}>
                        <CommonPatientDetails data={params?.row} fromUserAccess={true} />
                    </Box>
                )
            },
            { field: "user_name", headerName: "UserName", flex: 0.16 },
            {
                field: "modules_type", headerName: "Modules", flex: 0.16,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.modules_type ? params?.row?.modules_type?.length > 30 ?
                        <Tooltip placement='top' title={params?.row?.modules_type} arrow>
                            <div>{params?.row?.modules_type.slice(0, 30) + "..."}</div></Tooltip> :
                        params?.row?.modules_type : "-"}</Box>)
            },
            {
                field: "status", headerName: "Status", flex: 0.16, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.status === false ? <Typography fontWeight={600} color={'green'}>{"Active"}</Typography> : <Typography fontWeight={600} color={Colors.red}>{"Inactive"}</Typography>}</Box>)
            },
            {
                field: "created_date", headerName: "Created By", flex: 0.20,
                renderCell: (params) => <Box component={'div'}>{params?.row?.created_date ? `${params?.row?.created_by} - ${params?.row?.created_date.split(" ")[0]}` : "-"}</Box>
            },
            {
                field: "action", headerName: "Action", flex: 0.12, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>
                    <Tooltip placement='top' title='Edit'>
                        <Button className='eMed_user_btn'
                            onClick={() => {
                                this.setState({
                                    isEdit: true
                                }, () => {
                                    this.props.history.push({
                                        pathname: "/PharmacySetting/PharmaUserSettings",
                                        state: { userData: params?.row, isEdit: this.state.isEdit, showEdit: false }
                                    })
                                })
                            }}>
                            <img src={ImagePaths.Edit.default} className='eMed_edit_img' alt='edit' />
                        </Button>
                    </Tooltip>
                </Box>)
            }
        ]

        return (
            <Box component={'div'} className='eMed_user_conf_container' sx={{ backgroundColor: Colors.Background }}>
                <><Box component={'div'} className='eMed_user_conf_header'>
                    <Box component={"div"} className='eMed_Filter_Icon_div'>
                        <Tooltip title="Filter" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                this.props.history.push({ pathname: "/PharmacySetting/PharmaUserSettings", state: { showEdit: true } })
                            }}
                            sx={{ textTransform: "capitalize" }}
                        >{t('AddUser')}</Button>
                    </Box>
                </Box>
                    <Box component={'div'} className='eMed_user_conf_content'>
                        <DataGrid
                            className='eMed_user_conf_grid'
                            rows={this.state.newUserData}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            headerHeight={40}
                            components={{
                                Toolbar: CommonGridHeader.CommonToolBar,
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_conf_nodata'>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            disableSelectionOnClick
                            density='comfortable'
                        />
                    </Box></>
                {/* } */}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
            </Box>
        )
    }
}

export default withTranslation()(PharmaUserAccess)

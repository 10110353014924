import { Autocomplete, Box, Button, Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../ClinicalNotes/DoctorNotes.css'
import { Colors } from '../../../../../../Styles/Colors';
import { DurationCounts, DurationsList } from '../../../../../../Utility/Constants';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import { DeletePopup } from '../../../../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../../Utility/Services/CacheProviderService';

class ExaminationRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ExaminationText: "",
      ExaminationDate: new Date(),
      Description: "",
      FrequentData: [],
      SelectedID: null,
      ExamSearchedKey: "",
      ExaminationsList: [],
      isDeleteClicked: false,
      SelectedFreq: {},
      SelectedFreqIndex: null,
      withOutAppointment : this.props?.withOutAppointment,
    }
  }

  componentDidMount() {
    if (this.props.EditExaminationsData && Object.keys(this.props.EditExaminationsData).length !== 0) {
      let Data = { "exam_name": CommonValidation.removeSpace(this.props.EditExaminationsData?.exam_name), "exam_count": "none" }
      let Filter = this.state.FrequentData.find((list) => (list.exam_name === CommonValidation.removeSpace(this.props.EditExaminationsData?.exam_name)));
      if (Filter === undefined) {
        this.state.FrequentData.push(Data)
        this.setState({ FrequentData: this.state.FrequentData })
      } else {
        this.setState({ ExaminationText: this.props.EditExaminationsData?.exam_name })
      }
      this.setState({
        ExaminationText: this.props.EditExaminationsData?.exam_name,
        ExaminationDate: this.props.EditExaminationsData?.examination_date ? new Date(this.props.EditExaminationsData?.examination_date) : null,
        Description: this.props.EditExaminationsData?.description,
        SelectedID: this.props.EditExaminationsData?.id,
      })
    }
    this.GetExamsSuggestions()
    this.GetExamsFrequents()
    this.props.ClearPropsData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.EditExaminationsData !== this.props.EditExaminationsData && this.props.EditExaminationsData && Object.keys(this.props.EditExaminationsData).length !== 0) {
      let Data = { "exam_name": CommonValidation.removeSpace(this.props?.EditExaminationsData?.exam_name), "exam_count": "none" }
      let Filter = this.state.FrequentData.find((list) => (list.exam_name === CommonValidation.removeSpace(this.props.EditExaminationsData?.exam_name)));
      if (Filter === undefined) {
        this.state.FrequentData.push(Data)
        this.setState({ FrequentData: this.state.FrequentData })
      } else {
        this.setState({ ExaminationText: this.props.EditExaminationsData?.exam_name })
      }
      this.setState({
        ExaminationText: this.props.EditExaminationsData?.exam_name,
        ExaminationDate:  this.props.EditExaminationsData?.examination_date ? new Date(this.props.EditExaminationsData?.examination_date) : null,
        Description: this.props.EditExaminationsData?.description,
        SelectedID: this.props.EditExaminationsData?.id,
      })
      this.props.ClearPropsData()
    }
  }

  GetExamsSuggestions() {
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_EXAMINATION_SUGGEST).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ExaminationsList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  GetExamsFrequents() {
    let url = `${Serviceurls.IP_DOCNOTES_EXAMINATION_FREQUENT}`;
    if (this.props.is_From_IpNotes && this.props.clinic_id) {
      url = `${url}?clinic_id=${this.props.clinic_id}`
    }
    try {
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              FrequentData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  handleDelete(item, index) {
    this.state.FrequentData.splice(index, 1)
    this.setState({ FrequentData: this.state.FrequentData })
    if (this.state.ExaminationText === item.exam_name) { this.setState({ ExaminationText: "" }) }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  ClearData() {
    this.setState({
      ExaminationText: "",
      ExaminationDate: new Date(),
      DurationType: "days",
      DurationCount: "",
      Description: "",
      SelectedID: null,
      ExamSearchedKey: "",
      isDeleteClicked: false,
      SelectedFreq: {},
      SelectedFreqIndex: null
    })
  }

  PostExamination = () => {
    try {
      var states = this.state
      let date = new Date(states.ExaminationDate)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      let newDate =  `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
      var data = {
        "exam_name": CommonValidation.removeSpace(states.ExaminationText),
        "clinic_id": this.props.clinic_id,
        "doctor_id": this.props.is_From_IpNotes ? myUser?.doctor_id : this.props?.IPadmissionDetails?.doctor_id,
        "ip_admission_id": this.props?.IpPatientData?.id,
        "exm_date": newDate,
        "description": CommonValidation.removeSpace(states.Description)
      }
      if (this.state.SelectedID) { data["exam_id"] = this.state.SelectedID }

      RestAPIService.create(data, Serviceurls.IP_DOCNOTES_EXAMINATION).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.ClearData()
            this.GetExamsFrequents()
            this.props.CallgetApi('isExamination')
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  Validation() {
    var states = this.state
    let ExaminationDate = new Date(states.ExaminationDate)
    let date = states.ExaminationDate ? DateTime.fromJSDate(ExaminationDate).toFormat("yyyy-MM-dd hh:mm:ss") : ""
    if (CommonValidation.removeSpace(states.ExaminationText) !== "" && date !== "Invalid DateTime") {
      this.PostExamination()
    } else {
      if (CommonValidation.removeSpace(states.ExaminationText) === "") {
        this.errorMessage("Enter Examination Name")
      }
      else if (date === "Invalid DateTime") {
        this.errorMessage("Invalid Examination Date")
      }
    }
  }

  popupClose() {
    this.setState({ isDeleteClicked: false })
  }
  DeleteExamination() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.IP_DOCNOTES_EXAMINATION_FREQUENT + "?exam_name=" + states.SelectedFreq.exam_name + "&clinic_id=" + this.props.clinic_id).
        then((response) => {
          if (response.data.status === "success") {
            this.state.FrequentData.splice(states.SelectedFreqIndex, 1)
            this.setState({ FrequentData: this.state.FrequentData, isDeleteClicked: false })
            if (this.state.ExaminationText === states.SelectedFreq.exam_name) { this.setState({ ExaminationText: "" }) }
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t, IpPatientData, IPadmissionDetails } = this.props
    let states = this.state;
    const checkuserAccess = this.props.is_From_IpNotes ? false :
      CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ?
        this.state.withOutAppointment?.is_ip : true;

    const currentDate = new Date();
    const admissionDate = IPadmissionDetails?.admitted_date ? new Date(IPadmissionDetails?.admitted_date)
      : IpPatientData?.admission_date ? new Date(IpPatientData?.admission_date)
        : currentDate;
    const dischargeDate = IpPatientData?.discharge_date && (new Date(IpPatientData?.discharge_date) <= currentDate) ?
      new Date(IpPatientData?.discharge_date) : currentDate;

    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Examinations")}</Typography>
        <Box height={"52dvh"} sx={{ overflowY: 'scroll' }}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Autocomplete
              noOptionsText={states.ExamSearchedKey === '' ? 'Please Search...' : 'No Data Found...'}
              options={states.ExaminationsList}
              getOptionLabel={(options) => options}
              onChange={(e, value) => {
                let Data = { "exam_name": value, "exam_count": "none" }
                let Filter = this.state.FrequentData.find((list) => (list.exam_name === value));
                if (Filter === undefined && value) {
                  this.state.FrequentData.push(Data)
                  this.setState({ FrequentData: this.state.FrequentData, ExaminationText: value, ExamSearchedKey: "" })
                } else {
                  this.setState({
                    ExaminationText: value,
                    ExamSearchedKey: ""
                  })
                }
              }}
              clearIcon={false}
              size='small'
              sx={{ width: "26vw" }}
              className='eMed_DocNotes_TextFields'
              disablePortal
              id="combobox-demo"
              value={states.ExamSearchedKey.slice(0, 60)}
              renderInput={(params) => <TextField sx={{ width: "26vw" }}
                value={states.ExamSearchedKey.slice(0, 60)}
                onChange={(event) => {
                  if (event.target.value.length < 60 && (CommonValidation.ALPHA_NUM_SPLCHAR(event.target.value) || event.target.value === "")) {
                    this.setState({
                      ExamSearchedKey: event.target.value.slice(0, 60)
                    })
                  }
                }}
                {...params}
                placeholder="Search Examination"
              />}
            />
            <Button sx={{ minWidth: '2vw', marginLeft: '-1.5vw' }} disabled={CommonValidation.removeSpace(this.state.ExamSearchedKey) === "" || this.state.withOutAppointment?.is_ip} variant='contained' size='small' color='primary' onClick={() => {
              let Data = { "exam_name": CommonValidation.removeSpace(this.state.ExamSearchedKey), "exam_count": "none" }
              let Filter = this.state.FrequentData.find((list) => (list.exam_name === CommonValidation.removeSpace(this.state.ExamSearchedKey)));
              if (Filter === undefined) {
                this.state.FrequentData.push(Data)
                this.setState({ FrequentData: this.state.FrequentData, ExaminationText: CommonValidation.removeSpace(this.state.ExamSearchedKey), ExamSearchedKey: "" })
              } else {
                this.setState({ ExaminationText: CommonValidation.removeSpace(this.state.ExamSearchedKey), ExamSearchedKey: "" })
              }
            }} >
              <AddIcon />
            </Button>
          </Box>

          <Box className="eMed_DocNotes_FreqChips_Wrapp">
            {
              this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                <Chip
                  size='small'
                  className="eMed_DocNotes_FreqChip"
                  key={index}
                  label={item.exam_name}
                  color={'primary'}
                  variant={this.state.ExaminationText === item.exam_name ? 'contained' : "outlined"}
                  onClick={() => { this.setState({ ExaminationText: item.exam_name }) }}
                  onDelete={() => { item.exam_count === 'none' ? this.handleDelete(item, index) : this.setState({ isDeleteClicked: true, SelectedFreq: item, SelectedFreqIndex: index }) }}
                />
              )) : null
            }
          </Box>

          <Box>
            <Typography className='eMed_refdoc_title'>{t("Description")}</Typography>
            <TextField
              size='small'
              className='eMed_DocNotes_TextFields'
              multiline={true}
              rows={5}
              label={t("Description")}
              inputProps={{ maxLength: 1000 }}
              value={this.state.Description}
              onChange={(e) => {
                if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                  this.setState({ Description: e.target.value })
                }
              }}
            />
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor, marginLeft: "1.5vw", marginBottom: '0.5vw' }} className='eMed_DocNotes_DateTime' >
            <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{t("ExaminationsDate")}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                inputFormat='DD-MM-YYYY & hh:mm A'
                value={this.state.ExaminationDate}
                onChange={(newDate) => {
                  if (newDate === null) {
                    this.setState({ ExaminationDate: new Date() })
                  }
                  else if (newDate > new Date()) {
                    this.setState({ ExaminationDate: new Date() })
                  }
                  else {
                    this.setState({ ExaminationDate: newDate })
                  }
                }}
                // minDate={this.props?.IPadmissionDetails?.admitted_date}
                minDate={admissionDate}
                maxDate={dischargeDate}
                renderInput={(params) => <TextField size='small'
                  {...params}
                  fullWidth
                  variant='standard'
                  className='eMed_DocNotes_datePic_Textfield'
                />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" id="eMed_Config_btn" disabled = {checkuserAccess} onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" disabled = {checkuserAccess} onClick={() => { this.Validation() }}>{this.state.SelectedID === null ? t("Add") : t("Update")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Examination")} ${this.state.SelectedFreq ? this.state.SelectedFreq?.exam_name : "-"} From Suggestion List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DeleteExamination.bind(this)}
        /> : null}
      </Box>
    )
  }
}
export default withTranslation()(ExaminationRight)

import React, { Component } from "react";
import '../../Configurations/Configuration.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, Grid, FormControl, InputLabel, Select, MenuItem, Autocomplete } from "@mui/material";
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from "../../../Utility/Services/CacheProviderService";

class PharmacyRefDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docDetails: [],
      spcInfo: [],
      current_page: 0,
      rowsPerPage: 10,
      deletedPopup: false,
      doctor_name: "",
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: "",
      doctorName: '',
      specialization: null,
      qualification: '',
      reg_no: '',
      hospitalName: '',
      selectedID: null,
      delete_Id: null,
    }
  }
  componentDidMount() {
    this._getDoctorDetails();
    this._getSpecInfo();
  }

  _getDoctorDetails = () => {
    this.setState({
      docDetails: [],
      current_page: 0,
      rowsPerPage: 10
    })
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_ADD_DOCTOR)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              docDetails: response.data.data,
            });
          }
        })
        .catch(error => {
          if (error.response?.data.status === "fail") {
            this.responseSuccessErrorMessage(error.response?.data?.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  _getSpecInfo = () => {
    this.setState({
      spcInfo: []
    })
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPLGET)
        .then(response => {
          if (response.data.status === "success") {
            let datas = response.data.data
            this.setState({
              spcInfo: datas,
            });
          }
          
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  responseSuccessErrorMessage(message, type) {
    this.setState({
      showSuccessResponse: message ? type : false,
      showErrorResponse: message ? !type : false,
      responseMessage: message
    })
  }

  handleEdit = (value) => {
    this.setState({
      selectedID: value.id,
      doctorName: value.doctor_name,
      reg_no: value.reg_no,
      qualification: value.qualification,
      specialization: value.specialization,
      hospitalName: value.hospital_name

    })
  }

  handleDelete = (value) => {
      this.setState({
        deletedPopup: true,
        doctor_name: value.doctor_name,
        delete_Id: value.id
      })
  }

  PopupClose = () => {
    this.setState({
      deletedPopup: false,
      doctor_name: "",
      delete_Id: null,
    })
  }

  removeDataList = () => {
    try {
      RestAPIService.delete(`${Serviceurls.PHARMA_ADD_DOCTOR}?id=${this.state.delete_Id ? this.state.delete_Id : 0}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              deletedPopup: false,
              doctor_name: "",
              delete_Id: null
            }, () => {
              this.responseSuccessErrorMessage(response.data.message, true);
              this._getDoctorDetails();
            })
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  handleChange = (event) => {
    let { doctorName, reg_no, qualification, specialization, hospitalName } = this.state;
    let name = event.target.name;
    let value = event.target.value.trimStart();
    let isValid = false;
    switch (name) {
      case "reg_no":
        isValid = CommonValidation.alphanumValidation(value);
        if (value === "" || isValid && value.length <= 50) {
          reg_no = value;
        }
        break;
      case "doctorName":
        isValid = CommonValidation.alphabetOnly(value);
        if (value === "" || isValid && value.length <= 50) {
          doctorName = value;
        }
        break;
        case "hospitalName":
        isValid = CommonValidation.alphabetOnly(value);
        if (value === "" || isValid && value.length <= 50) {
          hospitalName = value;
        }
        break;
      case "qualification":
        isValid = CommonValidation.AlphaSpl(value);
        if (value === "" || isValid) {
          qualification = value;
        }
        break;
      default:
        isValid = CommonValidation.alphabetOnly(value);
        if (value === "" || isValid) {
          [name] = value;
        }
        break;
    }
    this.setState({
      doctorName, reg_no, qualification, specialization, hospitalName
    })
  }
  renderTextBox(label, statekey, value, width) {
    let { specialization, spcInfo } = this.state;
    const { t } = this.props;
    const defaultdata = [
      { name: 'No options Found' }
    ]
    return (
      <div id="eMed_srvConfig_divs">
        {
          statekey === "specialization" ?
            <Autocomplete
              disableClearable
              value={value ? value : ""}
              sx={{ width: width ? width : "14vw" }}
              size="small"
              options={spcInfo.length != 0 ? spcInfo : defaultdata}
              getOptionLabel={option => typeof (option) === "string" ? option : option.name}
              onChange={(event, newSpec) => {
                specialization = newSpec;
                this.setState({
                  specialization
                })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(label) + " *"}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    'emed_tid': `phar_refDoc_${statekey}`,
                  }}
                />
              )}
            /> :
            <TextField
              sx={{ width: width ? width : "14vw" }}
              autoComplete="off"
              required
              size="small"
              label={t(label)}
              name={statekey}
              value={value ? value : ""}
              onChange={this.handleChange}
              inputProps={{ emed_tid : `phar_refDoc_${statekey}`}}
            />
        }
      </div>
    )
  }
  handleClear = () => {
    this.setState({
      doctorName: '',
      specialization: null,
      reg_no: '',
      selectedID: null,
      hospitalName: '',
      qualification: '',
      delete_Id: null,
    })
  }
  handleSave = () => {
    const { t } = this.props
    var states = this.state;
    let isAvl = true;
    let Message = "";
    if (states.doctorName === '' || states.specialization === '' || states.qualification === '' || states.reg_no === '' || states.hospitalName === '') { isAvl = false; Message = t("Fillmandatory") }
    else if (!states.doctorName) { isAvl = false; Message = `${t("Enter")} ${t("DoctorName")}` }
    else if (!states.specialization) { isAvl = false; Message = `${t("Enter")} ${t("Specialization")}` }
    else if (!states.qualification) { isAvl = false; Message = `${t("Enter")} ${t("Qualification")}` }
    else if (!states.reg_no) { isAvl = false; Message = `${t("Enter")} ${t("RegistrationNo")}` }
    else if (!states.hospitalName) { isAvl = false; Message = `${t("Enter")} ${t("Hospital/ClinicName")}` }
    if (isAvl) {
      // api call
      let datas = {
        'doctor_name': states.doctorName,
        'hospital_name': states.hospitalName,
        'qualification': states.qualification,
        'reg_no': states.reg_no,
        'specialization': states.specialization.name ? states.specialization.name : states.specialization ? states.specialization : ''
      }
      if(states.selectedID){
        datas['id'] = states.selectedID
      }
      try {
        RestAPIService.create(datas, Serviceurls.PHARMA_ADD_DOCTOR)
          .then(response => {
            if (response.data.status === "success") {
              this.handleClear();
              this._getDoctorDetails();
              this.responseSuccessErrorMessage(response.data.message, true);
            }else{
              this.responseSuccessErrorMessage(response.data.message, false);
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }else{
              this.responseSuccessErrorMessage(error.message, false);
            }
          });
      } catch (e) {
        this.responseSuccessErrorMessage(e.message, false);
      }
    } else {
      this.responseSuccessErrorMessage(Message, false)
    }
  }
  msgClose() {
    this.setState({
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: ''
    })
  }
  render() {
    const { t } = this.props;
    let { rowsPerPage, current_page, docDetails } = this.state;
    docDetails.forEach((element, index) => {element.sno = index + 1})
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
    const columns = [
      { field: "sno", sortable: false, flex: 0.06, headerName: t("SNo") },
      {
        field: 'doctor_name', headerName: t('DoctorName'), flex: 0.18, renderCell: (params) => {
          return (
            <div>{params?.row?.doctor_name?.length > 15 ? <Tooltip placement="top" title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.doctor_name}</div>
          )
        }
      },
      {
        field: 'reg_no', headerAlign: "right", headerName: t('RegistrationNo'), flex: 0.13, align: "right", renderCell: (params) => {
          return (
            <div>{params?.row?.reg_no?.length > 15 ? <Tooltip placement="top" title={params?.row?.reg_no} arrow><div>{params?.row?.reg_no ? params.row.reg_no.slice(0, 10) + "..." : '-'}</div></Tooltip> : params?.row?.reg_no ? params.row.reg_no : '-'}</div>
          )
        }
      },
      {
        field: 'qualification', headerName: t('Qualification'), flex: 0.16, renderCell: (params) => {
          return (
            <div>{params?.row?.qualification?.length > 15 ? <Tooltip placement="top" title={params?.row?.qualification} arrow><div>{params?.row?.qualification.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.qualification}</div>
          )
        }
      },
      {
        field: 'specialization', headerName: t('Specialization'), flex: 0.18, renderCell: (params) => {
          return (
            <div>{params?.row?.specialization?.length > 15 ? <Tooltip placement="top" title={params?.row?.specialization} arrow><div>{params?.row?.specialization.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.specialization}</div>
          )
        }
      },
      {
        field: 'hospital_name',  headerName: t("Hospital/ClinicName"),  flex: 0.18,
        renderCell: (params) => (<div>{params?.row?.hospital_name?.length > 12 ? <Tooltip placement="top" title={params?.row?.hospital_name} arrow><div>{params?.row?.hospital_name.slice(0, 12) + "..."}</div></Tooltip> : params?.row?.hospital_name}</div>)
      },
      {
        field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.11, sortable: false, hideable: false, align: "center", renderCell: (params) => {
          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <CommonEditButton disable={!CheckAccessFunc(pharmacy_type, "Settings", "Referral Doctors",  null, "Tab")?.editAccess} testID="phar_refDoc_edit" size="1.5vw" onClick={this.handleEdit.bind(this, params.row)} />
              <CommonDeleteButton disable={!CheckAccessFunc(pharmacy_type, "Settings", "Referral Doctors",  null, "Tab")?.editAccess} testID="phar_refDoc_delete"  size="1.5vw" onClick={this.handleDelete.bind(this, params.row)} />
            </Stack>
          )
        }
      }
    ]
    return (
      <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container spacing={3} className="eMed_srvConfig_Box">
          <Grid item xs={7.6}>
            <Paper className="eMed_srvConfig_Div" >
              <DataGrid
                rows={docDetails}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={{
                  toolbarColumns: "",
                  toolbarDensity: "",
                  toolbarExport: "",
                  toolbarFilters: "",
                  toolbarExportPrint: ""
                }}
                disableSelectionOnClick
                components={{
                  Toolbar: CommonGridHeader.CommonToolBar,
                  NoRowsOverlay: () => {
                    return (
                      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                        {t("NoRecordsFound")}
                      </Stack>
                    )
                  }
                }}
                headerHeight={40}
                rowCount={docDetails.length}
                page={current_page}
                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                rowsPerPageOptions={[10, 20, 30]}
                pagination
              />
            </Paper>
          </Grid>
          <Grid item xs={4.4}>
            <Paper className="eMed_srvConfig_Div">
              <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <p className='eMed_srvConfig_rightHead_txt'>{t('DoctorConfiguration')}</p>
              </div>
              <div className="eMed_srvConfig_subDiv">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {this.renderTextBox('DoctorName', "doctorName", this.state.doctorName)}
                  {this.renderTextBox('Specialization', "specialization", this.state.specialization)}
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {this.renderTextBox('Qualification', "qualification", this.state.qualification)}
                  {this.renderTextBox('RegistrationNo', "reg_no", this.state.reg_no)}
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {this.renderTextBox('Hospital/ClinicName', "hospitalName", this.state.hospitalName)}
                </Stack>
              </div>
              <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                <Button emed_tid="phar_refDoc_clear" variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                <Button emed_tid="phar_refDoc_save" disabled={!CheckAccessFunc(pharmacy_type, "Settings", "Referral Doctors",  null, "Tab")?.editAccess}  variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        {this.state.deletedPopup ?
          <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.doctor_name ? this.state.doctor_name : "-"} ?`}
            DeleteNotify={t("effectEntireSetting")}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
          />
          : null}
        {
          this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
        {
          this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
      </Box>
    )
  }
}
export default withTranslation()(PharmacyRefDoctor);
import React, { Component } from 'react'
import './inventory.css'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { Trans, withTranslation } from 'react-i18next'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import FutureDatePicker from '../../../Components/FutureDatepicker/FutureDatePicker'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents'


class StockExpiry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: 10,
      stockExpiryList: [],
      dateFilter: 0,
      QueryFromDate: "",
      QueryToDate: "",
      searchkey : '',
      disableBtn: false,
    }
  }

  componentDidMount() {
    this.getStockExpiryList()
  }
  componentWillReceiveProps(props){
    if(props.search_key != this.state.searchkey){
        this.setState({
            searchkey: props.search_key
        },() => {
            this.getStockExpiryList()
        })
    }
  }
  CustomDates = (FromDate, ToDate) => {
    this.setState({
      QueryFromDate: FromDate,
      QueryToDate: ToDate,
      datePickerPop: false
    }, () => this.getStockExpiryList())
  }
  PopClose = () => {
    this.setState({
      datePickerPop: false,
      QueryFromDate: "",
      QueryToDate: "",
      dateFilter: 0,
    })
  }
  handleDate = () => {
    let date = new Date();
    var today = DateTime.now().toFormat('yyyy-MM-dd')
    var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
    var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
    var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let nextMonthStart = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let nextMonthEnd = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    let nextThreeMonthStart = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let nextThreeMonthEnd = new Date(date.getFullYear(), date.getMonth() + 4, 0);
    let nextSixMonthStart = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let nextSixMonthEnd = new Date(date.getFullYear(), date.getMonth() + 7, 0);


    if (this.state.dateFilter === 0) {
      this.setState({
        QueryFromDate: "",
        QueryToDate: "",
      }, () => this.getStockExpiryList())
    }
    else if (this.state.dateFilter === 1) {
      this.setState({
        QueryFromDate: (DateTime.fromJSDate(firstDay).toFormat("yyyy-MM-dd")),
        QueryToDate: (DateTime.fromJSDate(lastDay).toFormat("yyyy-MM-dd")),
      }, () => this.getStockExpiryList()
      )

    }
    else if (this.state.dateFilter === 2) {
      this.setState({
        QueryFromDate: (DateTime.fromJSDate(nextMonthStart).toFormat("yyyy-MM-dd")),
        QueryToDate: (DateTime.fromJSDate(nextMonthEnd).toFormat("yyyy-MM-dd")),
      }, () => this.getStockExpiryList())
    }
    else if (this.state.dateFilter === 3) {
      this.setState({
        QueryFromDate: (DateTime.fromJSDate(nextThreeMonthStart).toFormat("yyyy-MM-dd")),
        QueryToDate: (DateTime.fromJSDate(nextThreeMonthEnd).toFormat("yyyy-MM-dd")),
      }, () => this.getStockExpiryList())
    }
    else if (this.state.dateFilter === 4) {
      this.setState({
        QueryFromDate: (DateTime.fromJSDate(nextSixMonthStart).toFormat("yyyy-MM-dd")),
        QueryToDate: (DateTime.fromJSDate(nextSixMonthEnd).toFormat("yyyy-MM-dd")),
      }, () => this.getStockExpiryList())
    }
    else if (this.state.dateFilter === 5) {
      this.setState({ datePickerPop: true })
    }
  }
  DateHelperText = () => {
    if (this.state.dateFilter === 0) {
      return ""
    }
    else if (this.state.dateFilter === 1) {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return `${DateTime.fromJSDate(firstDay).toFormat("dd-MM-yyyy")}  to  ${DateTime.fromJSDate(lastDay).toFormat("dd-MM-yyyy")}`
    }
    else if (this.state.dateFilter === 2) {
      let date = new Date();
      let nextMonthStart = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      let nextMonthEnd = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      return `${DateTime.fromJSDate(nextMonthStart).toFormat("dd-MM-yyyy")}  to  ${DateTime.fromJSDate(nextMonthEnd).toFormat("dd-MM-yyyy")}`
    }
    else if (this.state.dateFilter === 3) {
      let date = new Date();
      let nextThreeMonthStart = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      let nextThreeMonthEnd = new Date(date.getFullYear(), date.getMonth() + 4, 0);
      return `${DateTime.fromJSDate(nextThreeMonthStart).toFormat("dd-MM-yyyy")}  to  ${DateTime.fromJSDate(nextThreeMonthEnd).toFormat("dd-MM-yyyy")}`
    }
    else if (this.state.dateFilter === 4) {
      let date = new Date();
      let nextSixMonthStart = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      let nextSixMonthEnd = new Date(date.getFullYear(), date.getMonth() + 7, 0);
      return `${DateTime.fromJSDate(nextSixMonthStart).toFormat("dd-MM-yyyy")}  to  ${DateTime.fromJSDate(nextSixMonthEnd).toFormat("dd-MM-yyyy")}`
    }
    else if (this.state.dateFilter === 5) {
      return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
    }
  }
 
LoaderFunction=(key)=>{
  this.setState({
    isLoader:key
  })
}
  getStockExpiryList = () => {
    this.LoaderFunction(true)
    RestAPIService.getAll(Serviceurls.INVENTORY_STOCK_EXPIRY_GET + "?drug_status=expiry" + "&from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate + `&search_key=${this.state.searchkey}`).
      then((response) => {
        if (response.data.status === "success") {
          this.setState({
            stockExpiryList: response.data.expiry_data
          },()=>{this.LoaderFunction(false)})
        } else {
          this.LoaderFunction(true)
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: response.data.message
          }, () => {
            setTimeout(() => {
              this.setState({ isErrorMsg: false })
            }, 2000);
          })
        }
      }).catch((error) => {
        this.LoaderFunction(false)
        this.setState({
        isErrorMsg: true,
        isErrorMsgText: error.response.data.message
      }, () => {
        setTimeout(() => {
          this.setState({ isErrorMsg: false })
        }, 2000);
      })})
  }

  formatDate(input) {
    let datePart = input.match(/\d+/g),
      year = datePart[0].substring(0),
      month = datePart[1], day = datePart[2];

    return month + '-' + year;
  }

  getStockExpiryInventoryexcel = () => {
    const {t} = this.props
    try {
      this.setState({disableBtn: true})
      RestAPIService.excelGet(Serviceurls.INVENTORY_STOCK_EXPIRY_GET + "?export_type=excel" + "&from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate +"&search_key=" + this.state.searchkey + "&drug_status=expiry").
        then((response) => {
          if(response.data){
          var pom = document.createElement('a');
          var csvContent = response.data; //here we load our csv data 
    
          let filename = response.headers["content-disposition"].split("filename=")[1]
    
          var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', filename ? filename : 'registry.xlsx');
          pom.click();
          this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message
          }, () => {
            setTimeout(() => {
              this.setState({ isErrorMsg: false,disableBtn: false })
            }, 2000);
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError")
      }, () => {
        setTimeout(() => {
          this.setState({ isErrorMsg: false, disableBtn: false, })
        }, 2000);
      })
    }
  }

  render() {
    this.state.stockExpiryList.forEach((element, index) => element.sno = index + 1)
    const { t } = this.props
    const columns = [
      { field: 'sno', headerName: t("SNo"), sortable: false, flex: 0.07 },
      { field: 'vendor_name', headerName: t('VendorName'), sortable: false, flex: 0.167,
      renderCell:(params) => (<div>{params?.row?.vendor_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.vendor_name} arrow><div>{params.row.vendor_name.slice(0,10) + "..."}</div></Tooltip> : params.row.vendor_name ? params.row.vendor_name :"-"}</div>)} ,
      { field: 'batch_no', headerName: t('BatchNumber'), sortable: false, flex: 0.167,
      renderCell:(params) => (<div>{params?.row?.batch_no?.length > 14 ? <Tooltip placement="top" title={params?.row?.batch_no} arrow><div>{params.row.batch_no.slice(0,10) + "..."}</div></Tooltip> : params.row.batch_no ? params.row.batch_no :"-"}</div>)} ,
      { field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.167,
      renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0,14) +"..."}</div></Tooltip> : params?.row?.brand_name ? params.row.brand_name :"-"}</div>) },
      {
        field: 'expiry_date', headerName: t('ExpiryDate'), sortable: false, flex: 0.167,headerAlign:"center",align:"center",
        renderCell: (params) => (<div>{params.row.expiry_date ? this.formatDate(params.row.expiry_date) : "-"}</div>)
      },
      { field: 'stock_in_quantity', headerName: t('AvailableQuantity'), sortable: false, flex: 0.167,type:"number",
      renderCell: (params) => (<div>{params.row.stock_in_quantity ? params.row.stock_in_quantity  : "-"}</div>) },
    ]

    return (
      <div className='mov_container_card'>
        <div className='mov_header_card'>
          <div className='eMed_date_picker'>
            <FormControl >
              <InputLabel>{t("SelectDate")}</InputLabel>
              <Select sx={{  height: '2.2vw', cursor: "pointer",marginRight:"1vw",width:"10vw"}}
                size='small'
                onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                value={this.state.dateFilter}
                labelId="demo-simple-select-required-label"
                label={t("SelectDate")}
              >
                <MenuItem value={0}>{t("All")}</MenuItem>
                <MenuItem value={1}>{t("CurrentMonth")}</MenuItem>
                <MenuItem value={2}>{t("NextMonth")}</MenuItem>
                <MenuItem value={3}>{t("Next3Month")}</MenuItem>
                <MenuItem value={4}>{t("Next6Month")}</MenuItem>
                <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
              </Select>
              <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
            </FormControl>
          </div>
          {this.state.datePickerPop ? <FutureDatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
          {/* <img className='mov_image_card' src={ImagePaths.LabPrintIcon.default} alt='print' /> */}
          <img className='eMed_upload' emed_tid='StockExpiry_Export_Btn' src={ImagePaths.LabUploadButton.default} onClick={()=>{
            if(!this.state.disableBtn){
              this.getStockExpiryInventoryexcel()
            }}} alt='upload' />

        </div>
        <div className='mov_table_card'>
          <DataGrid
            rows={this.state.stockExpiryList}
            columns={columns}
            pageSize={this.state.pageSize}
            onPageSizeChange={(newPageSize) => {
              this.setState({ pageSize: newPageSize })
            }}
            hideFooterSelectedRowCount
            rowsPerPageOptions={[10, 20, 30]}
            getRowId={(row) => row.id}
            loading={this.state.isLoader}
            pagination
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay:()=>(
                <Stack height="100%" alignItems="center" justifyContent="center">
                 {t("NoRecordsFound")}
                </Stack>
              )
            }}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation()(StockExpiry)
import { Box, Button, FormControlLabel, InputAdornment, Menu, MenuItem, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { withTranslation } from 'react-i18next'
import { Colors } from '../../../Styles/Colors'
import { BillSuccessPoPUp, CommonCorporateAndInsuranceDetails, PharmaDiscardPop } from '../../../Components/Common Components/CommonComponents'
import Loader from "../../../Components/Loader"
import CircularProgress from '@mui/material/CircularProgress';
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CurrencySymbol } from '../../../Utility/Constants'
import { DateTime } from 'luxon'
import PrescriptionHistoryPop from '../../Pharmacy/PharmacyHome/PrescriptionHistoryPop'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'
import moment from 'moment/moment';

let RoleData = null;

class OTStockList extends Component {
    constructor(props) {
        super(props)
        this.QuantityRef = React.createRef();
        this.DrugNameRef = React.createRef();
        this.state = {
            discount: '',
            batchNo: '',
            drugDetailsData: [],
            pharmacyTblData: [
                {
                    drug_name: '',
                    dosage_strength: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    quantity: '',
                    total_cost: '',
                    discount: '',
                    total_price: '',
                    hsn_code: '',
                    stock_in_quantity: '',
                    Drug_sugg_list: [],
                    gst_percentage: null,
                }
            ],
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            isInsurance: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            InsuranceAmount: "",
            InsuCompanyName: "",
            InsuPolicyNumber: "",
            InsuTransactionNumber: "",
            InsuValidity: '',
            subAmount: 0,
            totalPercentage: '',
            totalDiscount: '',
            roundOff: 0,
            netAmount: 0,
            creditPayment: false,
            receivePayment: false,
            savedBill: false,
            isCredit: false,
            DurgNameCurrentIndex: null,
            QuantityCurrentIndex: null,
            PatientDetails: {},
            BillDetails: {},
            PatientSearchClicked: false,
            SearchedPatientList: [],
            DoctorsList: [],
            doctordetails: {},
            PatientMobileNumber: "",
            PatientName: "",
            invoiceDateTime: new Date(),
            Patient_Id: null,
            Bill_ID: null,
            DateTimerOpen: false,
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            isFromInvoice: false,
            isForReturn: false,
            isFromSavedBill: false,
            isForInvoiceEdit: false,
            isFromIpRequest: false,
            isFromIpReturn: false,
            isFromPrescription: false,
            ShowPrescriptonHistory: false,
            CompletedBillID: null,
            ip_transmit_id: null,
            ConfigSetting: {},
            LoggedRole: null,
            totalBuyingPrice: 0,
            ShowHistory: false,
            SearchingDrugIndex: false,
            SearchingBatchIndex: false,
            isBillsFetched: false,
            InvoiceNumber: null,
            CorpCompanyName: "",
            CorpIDNumber: "",
            PrescHistoryData: null,
            invoiceNumber: '',
            OutStandingAmount: 0,
            BillReceivedAmount: 0,
            outstandingAmt: 0,
            ReturnedNetAmount: 0,
            totalBillNetAmount: 0,
            ReceiptAmt: 0,
            AdvanceAmt: 0,
            isCurrentIP: false,
            addToAdvance: false,
            isReturnClicked: false,
            prescription_transmitted_id: null,
            typeDocName: '',
            filteredArray: [],
            stockDate: moment(new Date()).format("YYYY-MM-DD"),
            isOPT: false,
            selected_method: "Drug"
        }
    }

    componentDidMount = () => {
        let logginedData = localGetItem('loggedInUserInfo')
        let loggedInUserInfo = JSON.parse(logginedData)
        this.setState({
            isOPT: loggedInUserInfo?.is_opt
        })
    }

    getBrandListData = (index) => {
        try {
            let { pharmacyTblData } = this.state
            if (pharmacyTblData[index]['drug_name'] !== "") {
                this.DrugSearchLoader(index)
                let ServiceURL = this.state.selected_method === "IOL" ? Serviceurls.OT_BILLING_IOL_DRUG_SUGESSION : Serviceurls.OT_BILLING_DRUG_SUGESSION
                RestAPIService.getAll(ServiceURL + `?brand_name=${CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])}`)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            if (response.data?.data.length > 0) {
                                pharmacyTblData[index]['Drug_sugg_list'] = response.data.data
                                this.setState({ pharmacyTblData }, () => {
                                    this.DrugSearchLoader(null)
                                })
                            } else if (response.data?.data?.length === 0) {
                                this.DrugSearchLoader(null)
                                this.errorMessage("No Drug Found")
                            } else {
                                this.DrugSearchLoader(null)
                                this.errorMessage(response?.data?.message)
                            }
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.message)
                        }
                    })
            }
            else {
                this.errorMessage("Enter Brand Name")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDrugDetailsData = (index, FromDrug = false) => {
        try {
            let { pharmacyTblData, PatientDetails } = this.state
            let avoid_drugDiscount = this.state.ConfigSetting;
            let avoidDiscountIP = (avoid_drugDiscount?.show_discount_ip && PatientDetails?.ip_admission_id);
            let avoidDiscountOP = (avoid_drugDiscount?.show_discount_op && PatientDetails?.appointment_id);

            this.BatchearchLoader(index)
            let ServiceURL = this.state.isOPT ? Serviceurls.IOL_MODEL_SUGGEST : Serviceurls.GET_STOCK_USAGE_BRAND_DETAILS
            RestAPIService.getAll(ServiceURL + `?brand_name=${this.state.pharmacyTblData[index]?.drug_name}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        if (response.data.data?.length > 0) {
                            pharmacyTblData[index]['Batch_Sugg_list'] = response.data.data
                            if (pharmacyTblData[index]['batch_no'] === "" || !pharmacyTblData[index]['batch_no']) {
                                let paraData = response.data.data
                                pharmacyTblData[index]['drug_id'] = response.data.data[0]?.drug_id
                                if (pharmacyTblData[index]?.drug_id === pharmacyTblData[index - 1]?.drug_id && paraData?.length > 1) {
                                    let sameBatch = pharmacyTblData?.some(item => item?.drug_id === response.data.data[1]?.drug_id && item?.batch_no === response.data.data[1]?.batch_no)
                                    if (sameBatch) {
                                        for (let i = 1; i < paraData?.length; i++) {
                                            if (!pharmacyTblData?.some(item => item?.drug_id === response.data.data[i]?.drug_id && item?.batch_no === response.data.data[i]?.batch_no)) {
                                                let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[i]?.cost_per_quantity ? +(response.data.data[i]?.cost_per_quantity) : 0)
                                                pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                                                pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                                                pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                                                pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                                                pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                                                pharmacyTblData[index]['hsn_code'] = response.data.data[i]?.hsn_code
                                                pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                                                if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                                                else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                                                else { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                pharmacyTblData[index]['schedule'] = response.data.data[i]?.schedule
                                                pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                                break;
                                            }
                                        }
                                        // this.errorMessage(`${paraData[1]?.batch_no} Batch No is Already Exists`)
                                    } else {
                                        let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[1]?.cost_per_quantity ? +(response.data.data[1]?.cost_per_quantity) : 0)
                                        pharmacyTblData[index]['batch_no'] = paraData[1]?.batch_no
                                        pharmacyTblData[index]['cost_per_quantity'] = response.data.data[1]?.cost_per_quantity
                                        pharmacyTblData[index]['expiry_date'] = response.data.data[1]?.expiry_date
                                        pharmacyTblData[index]['physical_box_no'] = response.data.data[1]?.physical_box_no
                                        pharmacyTblData[index]['stock_in_quantity'] = response.data.data[1]?.stock_in_quantity
                                        pharmacyTblData[index]['hsn_code'] = response.data.data[1]?.hsn_code
                                        pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[1]?.buying_price_per_piece
                                        if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : response.data.data[1]?.sales_discount_for_bill }
                                        else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : response.data.data[1]?.sales_discount_for_bill }
                                        else { pharmacyTblData[index]['discount'] = response.data.data[1]?.sales_discount_for_bill }
                                        pharmacyTblData[index]['schedule'] = response.data.data[1]?.schedule
                                        pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                    }
                                } else {
                                    let sameBatchNo = pharmacyTblData?.some(item => item?.drug_id === response.data.data[0]?.drug_id && item?.batch_no === response.data.data[0]?.batch_no)
                                    if (sameBatchNo) {
                                        for (let i = 1; i < paraData?.length; i++) {
                                            if (!pharmacyTblData?.some(item => item?.drug_id === response.data.data[i]?.drug_id && item?.batch_no === response.data.data[i]?.batch_no)) {
                                                let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[i]?.cost_per_quantity ? +(response.data.data[i]?.cost_per_quantity) : 0)
                                                pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                                                pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                                                pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                                                pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                                                pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                                                pharmacyTblData[index]['hsn_code'] = response.data.data[i]?.hsn_code
                                                pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                                                if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                                                else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                                                else { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                pharmacyTblData[index]['schedule'] = response.data.data[i]?.schedule
                                                pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                                break;
                                            }
                                        }
                                    } else {
                                        pharmacyTblData[index]['batch_no'] = paraData[0]?.batch_no
                                        pharmacyTblData[index]['dosage_strength'] = response.data.data[0]?.dosage_strength
                                        pharmacyTblData[index]['dosage_type'] = response.data.data[0]?.dosage_type
                                        pharmacyTblData[index]['avail_qty'] = response.data.data[0]?.avaliable_quantity
                                        pharmacyTblData[index]['generic_name'] = response.data.data[0]?.generic_name
                                        pharmacyTblData[index]['id'] = response.data.data[0]?.id
                                    }
                                }
                            }
                            this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => {
                                this.BatchearchLoader(null)
                                this.calculateDiscountTotalPrice(index)
                            })
                            if (FromDrug && !this.state.isFromIpRequest) {
                                this.QuantityRef?.current?.focus();
                            }
                        } else {
                            pharmacyTblData[index]['Batch_Sugg_list'] = []
                            this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => { this.BatchearchLoader(null) })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.BatchearchLoader(null)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.BatchearchLoader(null)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postPharmacyBillData = () => {
        try {
            this.LoaderFunction(true)
            let states = this.state
            let url = ''
            if (this.state.receivePayment) {
                if (this.state.isForReturn || this.state.isFromIpReturn) {
                    url = Serviceurls.PHARMA_RETURN_BILL
                }
                else {
                    url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
                }
            } else if (states.addToAdvance) {
                url = Serviceurls.PHARMA_RETURN_BILL
            } else if (this.state.creditPayment) {
                url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
            } else if (this.state.savedBill) {
                url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
            }
            let Lineitems = []
            let SumOfDiscount = 0
            this.state.pharmacyTblData.forEach((item) => {
                if (item.drug_name !== "" && (this.state.isFromIpRequest ? item?.stock_in_quantity !== 0 : true) && (this.state.isForReturn ? item?.return_quantity !== "" : true)) {
                    let BillItem = {
                        "drug_id": item.drug_id,
                        "hsn_code": item.hsn_code,
                        "batch_no": item.batch_no,
                        "expiry_date": item.expiry_date,
                        "physical_box_no": item.physical_box_no,
                        "cost_per_quantity": item.cost_per_quantity,
                        "total_cost": item.total_cost,
                        "discount_percentage": +item.discount,
                        "gst_percentage": item.gst_percentage ? item.gst_percentage : 0,
                        "quantity": (this.state.isForReturn || this.state.isFromIpReturn) ? +item.return_quantity : +item.quantity,
                        "net_total_amount": (this.state.isForReturn || this.state.isFromIpReturn) ? +item.total_return_price : item.total_price,
                    }
                    if (this.state.isFromIpRequest) { BillItem["ip_line_item_id"] = item?.line_item_id }
                    if (this.state.isFromIpReturn) { BillItem["return_id"] = item?.return_id }
                    if (item?.line_item_id) { BillItem["line_item_id"] = item?.line_item_id }
                    Lineitems.push(BillItem)
                    var dis_amt = (+item.total_cost) * (+item.discount / 100)
                    SumOfDiscount += (+dis_amt)
                }
            })

            let Datetime = new Date(this.state.invoiceDateTime)
            let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
            let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_WITH_SECONDS)

            let PaymentTypeData = [];
            if (this.state.isCash) {
                PaymentTypeData.push(1)
            }
            if (this.state.isCard) {
                PaymentTypeData.push(2)
            }
            if (this.state.isUPI) {
                PaymentTypeData.push(3)
            }
            if (this.state.isBankTranfer) {
                PaymentTypeData.push(5)
            }

            let ReceivedAmt = 0

            if (states.isCredit || states.savedBill) {
                ReceivedAmt = 0
            } else if (states?.isForReturn || states?.isFromIpReturn) {
                if ((states.BillReceivedAmount != states.totalBillNetAmount) && states.BillReceivedAmount != 0) {
                    ReceivedAmt = states.AdvanceAmt ? states.AdvanceAmt : states.ReceiptAmt
                } else if ((states.BillReceivedAmount == states.totalBillNetAmount) && states.BillReceivedAmount != 0) {
                    ReceivedAmt = states.ReturnedNetAmount
                } else {
                    ReceivedAmt = states.BillReceivedAmount
                }
            } else {
                ReceivedAmt = states.netAmount ? states.netAmount : 0
            }
            let creditType = (states.isCredit && !(states?.PatientDetails?.primary_payment_type)) ? "Patient credit" : (states.isCredit && states?.PatientDetails?.primary_payment_type == "Insurance") ? "Insurance credit" : (states.isCredit && states?.PatientDetails?.primary_payment_type == "Corporate") ? "Corprate credit" : null
            let data = {
                "invoice_date": `${date} ${time}`,
                "billing_customer_name": states.PatientName,
                "add_to_advance": (states.AdvanceAmt || states.addToAdvance) ? true : false,
                "billing_customer_mobile_no": states?.PatientMobileNumber,
                "doctor_name": (states.doctordetails?.name && states.doctordetails?.doctor_id) ? states.doctordetails?.name : states?.PatientDetails?.doctor_name,
                "patient_id": states.PatientDetails?.patient_id,
                "patient_account_number": states?.PatientDetails?.patient_account_number,
                "ip_number": states?.PatientDetails?.ip_number,
                "ip_admission_id": states?.PatientDetails?.ip_admission_id ? states?.PatientDetails?.ip_admission_id : null,
                "appointment_id": states?.PatientDetails?.ip_admission_id ? null : (states?.PatientDetails?.appointment_id ? states?.PatientDetails?.appointment_id : null),
                "discount_amount": SumOfDiscount ? (SumOfDiscount).toFixed(2) : 0,
                "taxable_amount": (states?.isForReturn || states?.isFromIpReturn) ? states.ReturnedNetAmount : states.netAmount,
                "general_discount": +states.totalDiscount,
                "cgst_amount": 0,
                "sgst_amount": 0,
                "igst_amount": 0,
                "tax_amount": 0,
                "round_off": states.roundOff,
                "delivery_charge": 0,
                "gross_amount": states.subAmount,
                "grand_total": states.subAmount,
                "net_amount": (states?.isForReturn || states?.isFromIpReturn) ? states.ReturnedNetAmount : states.netAmount,
                "received_amount": ReceivedAmt ? ReceivedAmt : 0,
                "balance_amount": 0,
                "bill_line_items": Lineitems,
                "employee_id": states?.PatientDetails?.primary_payment_type == "Corporate" ? states.CorpIDNumber : "",
                "company_name": states?.PatientDetails?.primary_payment_type == "Corporate" ? states.CorpCompanyName : "",
                "credit_type": creditType ? creditType : null,
                "validity": states.InsuValidity,
                "bill_type": this.state.creditPayment ? "Credit" : this.state.savedBill ? "Saved" : "Bill",
                "doctor_reg_no": states.doctordetails?.doctor_id ? states.doctordetails?.reg_no : null,
                "line_item": (states.isFromIpRequest || states.isFromIpReturn) ? Lineitems : [],
                "ip_transmit_id": (states.isFromIpRequest || states?.isFromIpReturn) ? states?.ip_transmit_id : null,
                "cash_amount": +states.CashAmount,
                "card_amount": +states.CardAmount,
                "upi_amount": +states.UPIAmount,
                "bank_amount": +states.BankTfrAmount,
                "cheque_amount": +states.ChequeAmount,
                "insurance_amount": +states.InsuranceAmount,
                "general_discount_percentage": +states?.totalPercentage,
                "payment_modes": PaymentTypeData ? PaymentTypeData : [],
                "doctor_id": states.doctordetails?.doctor_id ? states.doctordetails?.doctor_id : null,
                "doctor_reg_no": states.doctordetails?.doctor_id ? states.doctordetails?.doctor_reg_no : null,
                "card_number": states.CardTransactionNumber,
                "reference_no": states.BankTfrNumber,
                "transaction_id": states.UPINumber,
                "insurance_company_name": states?.PatientDetails?.primary_payment_type == "Insurance" ? states.InsuCompanyName : "",
                "transaction_number": states?.InsuTransactionNumber,
                "policy_number": states?.PatientDetails?.primary_payment_type == "Insurance" ? states?.InsuPolicyNumber : "",
                "billing_status": (this.state.savedBill || this.state.isCredit) ? 'Partially Saved' : "Payment Received",
                "payment_status": (this.state.savedBill || this.state.isCredit) ? 'not paid' : "paid",
                "billing_doctor_name": !states.doctordetails?.doctor_id ? states.doctordetails?.name : null,
            }
            if (states.Bill_ID) { data["bill_summary_id"] = states.Bill_ID }
            if (states.isForReturn) {
                data["invoice_number"] = states.BillDetails?.invoice_numbers
                data["invoice_sequence"] = states.BillDetails?.invoice_sequence
            }
            if (states.isFromIpReturn) {
                data["invoice_number"] = states.BillDetails?.invoice_number
            }
            if (states.isFromSavedBill) {
                data["temp_invoice_number"] = states.BillDetails?.temp_invoice_number
            }
            if (states.isFromPrescription) {
                data['transmitted_id'] = states.PrescriptionId
            }

            if (this.state?.isForInvoiceEdit) { // For Update Invoice
                RestAPIService.updateWithOutId(data, url)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            }, () => {
                                this.props?.getPharmaSavedBillCount(true)
                                this.props?.getPharmaPrescriptionCount(true)
                                this.DiscardAction(true, true)
                            })
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        }
                    })
            } else {
                RestAPIService.create(data, url)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            if (this.state.receivePayment) {
                                this.LoaderFunction(false)
                                if (this.state.isForReturn) {
                                    this.setState({
                                        CompletedBillID: response.data.data?.id,
                                    })
                                }
                                else if (this.state.isFromIpReturn) {
                                    this.setState({
                                        CompletedBillID: response.data.data?.id,
                                    })
                                }
                                else {
                                    this.setState({
                                        CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                                        creditPayment: false,
                                        receivePayment: false,
                                        savedBill: false,
                                        invoiceNumber: response?.data?.data?.invoice_number
                                    })
                                }
                            } else {
                                if (this.state.creditPayment) {
                                    this.setState({
                                        CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                                        creditPayment: false,
                                        receivePayment: false,
                                        savedBill: false,
                                    })
                                    this.LoaderFunction(false)
                                } else {
                                    this.setState({
                                        invoiceNumber: response?.data?.data?.temp_invoice_number,
                                        creditPayment: false,
                                        receivePayment: false,
                                        savedBill: false,
                                    })
                                    this.props?.getPharmaSavedBillCount(true)
                                    this.props?.getPharmaPrescriptionCount(true)
                                    this.LoaderFunction(false)
                                    this.DiscardAction(true, this.state.isFromInvoice)
                                }
                            }
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        }
                    })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostStockList = () => {
        try {
            let current_row = this.state?.filteredArray.length > 0 ? this.state?.filteredArray.length : this.state.pharmacyTblData.length;
            let bill_line_items = [];
            let Array = this.state?.filteredArray.length > 0 ? this.state?.filteredArray : this.state.pharmacyTblData
            for (let i = 0; i < current_row; i++) {
                if (Array[i].drug_name) {
                    bill_line_items.push({ "id": Array[i].drug_id, "usage_quantity": parseInt(Array[i].quantity) });
                }
            }
            var data = this.state.selected_method === "IOL" ? {
                "ot_iol_stock_usage_date": this.state.stockDate,
                "ot_iol_stock_usage": bill_line_items
            } : {
                "ot_stock_usage_date": this.state.stockDate,
                "ot_stock_usage": bill_line_items
            }
            let serviceURL = this.state.selected_method === "IOL" ? Serviceurls.STOCK_DRUG_PUT_IOL : Serviceurls.STOCK_DRUG_PUT
            this.setState({ disablePost : true})
            RestAPIService.updateWithOutId(data, serviceURL)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.DiscardAction(true)
                        this.setState({ disablePost : false})
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disablePost : false})
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateDiscountTotalPrice = (index) => {
        try {
            let { pharmacyTblData } = this.state
            let totalCost = pharmacyTblData[index]['total_cost']
            let discount = pharmacyTblData[index]['discount']
            let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
            pharmacyTblData[index]['total_price'] = totalCost - discountValue
            this.setState({ pharmacyTblData }, () => {
                this.calculateTotalDiscount()
                this.SetFullCashAmount()
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    autocompleteOnChangeHandler = (newValue, names, index) => {
        try {
            let { pharmacyTblData, PatientDetails } = this.state
            let avoid_drugDiscount = this.state.ConfigSetting;
            let avoidDiscountIP = (avoid_drugDiscount?.show_discount_ip && PatientDetails?.ip_admission_id);
            let avoidDiscountOP = (avoid_drugDiscount?.show_discount_op && PatientDetails?.appointment_id);

            if (pharmacyTblData[index][names]?.length > 0 || names === "batch_no") {
                switch (names) {
                    case 'drug_name':
                        if (this.state.selected_method !== "IOL") {
                            pharmacyTblData[index][names] = newValue['brand_name']
                            pharmacyTblData[index]['generic_name'] = newValue['generic_name']
                            pharmacyTblData[index]['dosage_strength'] = newValue['dosage_strength']
                            pharmacyTblData[index]['dosage_type'] = newValue['dosage_type']
                            pharmacyTblData[index]['drug_ms_id'] = newValue['drug_id']
                            pharmacyTblData[index]['Batch_Sugg_list'] = newValue['data']
                            pharmacyTblData[index]['quantity'] = ""
                            pharmacyTblData[index]['total_cost'] = ""
                            pharmacyTblData[index]['total_price'] = ""
                            pharmacyTblData[index]['discount'] = ""
                            this.setState({ pharmacyTblData, QuantityCurrentIndex: index })
                            // this.QuantityRef?.current?.focus();
                        } else {
                            pharmacyTblData[index][names] = newValue['brand_name']
                            pharmacyTblData[index]['generic_name'] = newValue['model_name']
                            pharmacyTblData[index]['dosage_strength'] = newValue['iol_power']
                            pharmacyTblData[index]['dosage_type'] = newValue['iol_type']
                            pharmacyTblData[index]['drug_ms_id'] = newValue['iol_product_id']
                            pharmacyTblData[index]['Batch_Sugg_list'] = newValue['data']
                            pharmacyTblData[index]['quantity'] = ""
                            pharmacyTblData[index]['total_cost'] = ""
                            pharmacyTblData[index]['total_price'] = ""
                            pharmacyTblData[index]['discount'] = ""
                            this.setState({ pharmacyTblData, QuantityCurrentIndex: index })
                        }
                        break;
                    case 'batch_no':
                        let batchSelected = false
                        pharmacyTblData?.forEach(element => {
                            if ((element?.batch_no === newValue['batch_no']) && (element?.drug_id === newValue['drug_id'])) {
                                batchSelected = true
                            }
                        })
                        if (this.state.isFromIpRequest) {
                            if (!batchSelected) {
                                pharmacyTblData[index][names] = newValue['batch_no']
                                pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
                                pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
                                pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
                                pharmacyTblData[index]['drug_id'] = newValue['drug_id']
                                pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
                                pharmacyTblData[index]['total_cost'] = newValue['cost_per_quantity'] * pharmacyTblData[index]['quantity']
                                pharmacyTblData[index]['total_price'] = newValue['cost_per_quantity'] * pharmacyTblData[index]['quantity']
                                if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
                                else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
                                else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
                                pharmacyTblData[index]['schedule'] = newValue['schedule']
                            } else {
                                this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
                            }
                        } else {
                            if (batchSelected) {
                                this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
                            } else {
                                if (this.state.selected_method !== "IOL") {
                                    pharmacyTblData[index][names] = newValue['batch_no']
                                    pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
                                    pharmacyTblData[index]['cost_per_quantity'] = newValue['mrp_per_quantity']
                                    pharmacyTblData[index]['stock_in_quantity'] = newValue['avaliable_quantity']
                                    pharmacyTblData[index]['drug_id'] = newValue['id']
                                    pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
                                    pharmacyTblData[index]['total_cost'] = ""
                                    pharmacyTblData[index]['total_price'] = ""
                                    if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
                                    else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
                                    else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
                                    pharmacyTblData[index]['schedule'] = newValue['schedule']
                                } else {
                                    pharmacyTblData[index][names] = newValue['batch_number']
                                    pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
                                    pharmacyTblData[index]['cost_per_quantity'] = newValue['mrp_price_per_qty']
                                    pharmacyTblData[index]['stock_in_quantity'] = newValue['avaliable_quantity']
                                    pharmacyTblData[index]['drug_id'] = newValue['id']
                                    pharmacyTblData[index]['physical_box_no'] = newValue['physical_box_number']
                                    pharmacyTblData[index]['total_cost'] = ""
                                    pharmacyTblData[index]['total_price'] = ""
                                    if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
                                    else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
                                    else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
                                    pharmacyTblData[index]['schedule'] = newValue['schedule']
                                }
                            }
                        }
                        this.setState({ pharmacyTblData, QuantityCurrentIndex: this.state.isFromIpRequest ? null : index, BatchAnchor: null, CurrentBatchIndex: null }, () => {
                            this.calculateCostAndTotal(pharmacyTblData[index]['quantity'], index, false)
                            this.calculateMarginProfit()
                            if (pharmacyTblData[index]['quantity'] === "") { this.QuantityRef?.current?.focus() }
                        })
                        break;
                    default: break;
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateMarginProfit = () => {
        var { pharmacyTblData } = this.state
        // calculate margin cumulative sum
        for (let i = 0; i < pharmacyTblData.length; i++) {
            let total_buying_price_drug = 0
            if (pharmacyTblData[i].batch_no != "") {
                let profit = parseFloat(pharmacyTblData[i]["cost_per_quantity"]) - parseFloat(pharmacyTblData[i]["buying_price_per_piece"]).toFixed(2)
                pharmacyTblData[i]["margin_profit"] = (profit * (+(pharmacyTblData[i]["quantity"])))

                total_buying_price_drug = (parseFloat(pharmacyTblData[i]["buying_price_per_piece"] * (+(pharmacyTblData[i]["quantity"]))))
                pharmacyTblData[i]["total_buying_price_per_drug"] = total_buying_price_drug

                this.setState({
                    pharmacyTblData
                }, () => {
                    // calculate total margin profit
                    const margin_profit = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map((item) => (item.batch_no === "" ? 0 : +(item.margin_profit))).reduce((prev, curr) => (prev + curr));
                    // calculate total buying price
                    const total_buying_price = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map(item => (item.batch_no === "" ? 0 : +(item.total_buying_price_per_drug))).reduce((prev, curr) => (prev + curr));

                    this.setState({
                        totalMarginProfit: margin_profit,
                        totalBuyingPrice: total_buying_price
                    })
                })
            }
        }

    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    DrugSearchLoader = (index) => {
        this.setState({
            SearchingDrugIndex: index
        })
    }
    BatchearchLoader = (index) => {
        this.setState({
            SearchingBatchIndex: index
        })
    }

    SetFullCashAmount = () => {
        let states = this.state
        if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && (!states?.isFromInvoice || states?.isForReturn)) {
            if (states?.isForReturn || states?.isFromIpReturn) {
                this.setState({ CashAmount: this.state.ReturnedNetAmount })  // Fetch Full amount in Cash Amount, when only the cash is selected
            } else {
                this.setState({ CashAmount: this.state.netAmount })  // Fetch Full amount in Cash Amount, when only the cash is selected
            }
        }
        if (!states?.subAmount) {
            this.setState({
                isCard: false,
                isCash: false,
                isUPI: false,
                isBankTranfer: false,
                isCheque: false,
                isInsurance: false,
                CashAmount: "",
                CardAmount: "",
                CardTransactionNumber: "",
                UPINumber: "",
                UPIAmount: "",
                BankTfrAmount: "",
                BankTfrNumber: "",
                ChequeNumber: "",
                ChequeAmount: "",
                InsuranceAmount: "",
                InsuCompanyName: "",
                InsuPolicyNumber: "",
                InsuTransactionNumber: "",
                InsuValidity: '',
            })
        }
    }

    calculateTotalDiscount = () => {
        try {
            this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(this.state.subAmount, this.state.totalPercentage)
            if (!this.state.isForReturn) {
                this.state.totalPercentage = CommonValidation.amountPercentage(this.state.totalDiscount, this.state.subAmount)
            }
            this.setState({
                totalDiscount: this.state.totalDiscount,
                totalPercentage: this.state.totalPercentage
            }, () => { this.SetFullCashAmount() })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onAddHandler = (names, index) => {
        try {
            if (this.state.pharmacyTblData[index]['drug_name']) {
                if (names === 'quantity' || names === 'discount') {
                    if (index === this.state.pharmacyTblData?.length - 1) {
                        let duplicateTbl = {
                            drug_name: '',
                            dosage_strength: '',
                            dosage_type: '',
                            batch_no: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            quantity: '',
                            total_cost: '',
                            discount: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            Drug_sugg_list: [],
                            Batch_Sugg_list: [],
                        }
                        this.state.pharmacyTblData = [...this.state.pharmacyTblData, duplicateTbl]
                        this.setState({
                            pharmacyTblData: this.state.pharmacyTblData
                        }, () => {
                            this.setState({
                                drugDetailsData: []
                            }, () => {
                                // this.DrugNameRef?.current.focus()
                            })
                        })
                    }
                }
            } else {
                this.errorMessage('Enter All Fields')
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderDrugColumn = (index, item) => {
        let frequent = "Frequently Brought Medicine"
        let { pharmacyTblData } = this.state
        return (
            <Box>
                <Tooltip title={pharmacyTblData[index]['drug_name']?.length > 18 ? (pharmacyTblData[index]['drug_name'] ? pharmacyTblData[index]['drug_name'] : "") : ""} placement="top" arrow>
                    <TextField
                        inputRef={this.DrugNameRef}
                        disabled={this.state.isFromInvoice || this.state.isFromIpRequest || this.state.isFromIpReturn || ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (!RoleData?.permission_access?.pharma?.isEditSave) : false)}
                        size='small'
                        variant='standard'
                        autoComplete='off'
                        value={item.drug_name}
                        onChange={(e) => {
                            if (e.target.value !== "") {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                pharmacyTblData[index]['dosage_strength'] = ""
                                pharmacyTblData[index]['batch_no'] = ""
                                pharmacyTblData[index]['dosage_type'] = ""
                                pharmacyTblData[index]['physical_box_no'] = ""
                                pharmacyTblData[index]['cost_per_quantity'] = ""
                                pharmacyTblData[index]['total_cost'] = ""
                                pharmacyTblData[index]['quantity'] = ""
                                pharmacyTblData[index]['discount'] = ""
                                pharmacyTblData[index]['total_price'] = ""
                                pharmacyTblData[index]['hsn_code'] = ""
                                pharmacyTblData[index]['stock_in_quantity'] = ""
                                pharmacyTblData[index]['expiry_date'] = ""
                                pharmacyTblData[index]['Drug_sugg_list'] = []
                                delete pharmacyTblData[index]['Batch_Sugg_list']
                                this.setState({ pharmacyTblData }, () => {
                                    if (CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])?.length > 2) {
                                        this.setState({
                                            DurgNameCurrentIndex: index
                                        }, () => { this.getBrandListData(index) })
                                    }
                                })
                            } else if (e.target.value === "" && index !== (pharmacyTblData.length - 1)) {
                                this.state.pharmacyTblData.splice(index, 1)
                                this.setState({ pharmacyTblData: this.state.pharmacyTblData })
                            } else {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                this.setState({ pharmacyTblData })
                            }
                        }}
                        sx={{ width: '12.5vw' }}
                        className={this.isScheduledDrug(index) ? 'eMed_DrugBox_Scheduled' : null}
                        onKeyDown={(e) => {
                            this.setState({
                                DrugNameAnchor: e.currentTarget,
                                FocusDrugList: e.key === "ArrowDown" ? true : false
                            }, () => {
                                if (e.key === 'Enter' && pharmacyTblData[index]['drug_name']?.length > 2) {
                                    this.setState({
                                        DurgNameCurrentIndex: index
                                    }, () => { this.getBrandListData(index) })
                                }
                            })
                        }}
                        inputProps={{ emed_tid: "bill_drug_name", style: { color: this.isScheduledDrug(index) ? 'rgb(150, 13, 11)' : null, paddingLeft: '0.4vw' } }}
                        InputProps={{
                            endAdornment: this.state.SearchingDrugIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : null,
                        }}

                    />
                </Tooltip>

                <Menu
                    open={this.state.DurgNameCurrentIndex === index && item.Drug_sugg_list && item.Drug_sugg_list?.length > 0 && item.drug_name !== ""}
                    className="eMed_Drug_Sugg_Pop"
                    sx={{ maxHeight: "45vh" }}
                    anchorEl={this.state.DrugNameAnchor}
                    onClose={() => {
                        this.setState({ DurgNameCurrentIndex: null })
                    }}
                    autoFocus={false}
                    disableAutoFocusItem
                    disableAutoFocus
                >
                    <Box width={"20vw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.3vw' }}>
                            <Tooltip placement='top' title={frequent}><Typography width={"100%"} fontWeight={'600'} fontSize={"0.8vw"} paddingLeft={'1vw'}>{frequent}</Typography></Tooltip>
                        </Box>
                        <Box maxHeight={'12vw'} overflow={"auto"}>
                            {
                                (item.Drug_sugg_list && item.Drug_sugg_list.length > 0) ?
                                    item.Drug_sugg_list.map((list, Drugindex) => (
                                        <MenuItem
                                            onKeyDown={(e) => {
                                                if (Drugindex === item.Drug_sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                    e.stopPropagation()
                                                    this.setState({ DurgNameCurrentIndex: index })
                                                }
                                            }}
                                            emed_tid="bill_drug_sugg_li"
                                            key={Drugindex}
                                            autoFocus={this.state.FocusDrugList && Drugindex === 0}
                                            onClick={() => {
                                                this.setState({
                                                    DurgNameCurrentIndex: null
                                                }, () => {
                                                    this.onAddHandler("quantity", index)
                                                    this.autocompleteOnChangeHandler(list, "drug_name", index)
                                                })
                                            }}
                                            sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box width={"100%"}>
                                                <CommonCorporateAndInsuranceDetails data={list} fromPharmaBilling={true} />
                                            </Box>
                                        </MenuItem>
                                    )) : null
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }

    calculateCostAndTotal = (value, index, FromReturn = false) => {
        try {
            if (FromReturn) {
                let { pharmacyTblData } = this.state
                pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
                let totalCost = pharmacyTblData[index]['total_cost']
                let discount = pharmacyTblData[index]['discount']
                let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
                let FinalPrice = totalCost - discountValue
                pharmacyTblData[index]['total_return_price'] = FinalPrice
                this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
            } else {
                let { pharmacyTblData } = this.state
                pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
                let totalCost = pharmacyTblData[index]['total_cost']
                let discount = pharmacyTblData[index]['discount']
                let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
                let FinalPrice = totalCost - discountValue
                pharmacyTblData[index]['total_price'] = FinalPrice
                this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateTotalDiscount = () => {
        try {
            this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(this.state.subAmount, this.state.totalPercentage)
            if (!this.state.isForReturn) {
                this.state.totalPercentage = CommonValidation.amountPercentage(this.state.totalDiscount, this.state.subAmount)
            }
            this.setState({
                totalDiscount: this.state.totalDiscount,
                totalPercentage: this.state.totalPercentage
            }, () => { this.SetFullCashAmount() })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderBatchColumn = (index, item) => {
        return (
            <Box>
                <TextField
                    inputRef={this.BatchNameRef}
                    disabled={this.disabled('batch_no', index)}
                    size='small'
                    variant='standard'
                    autoComplete='off'
                    value={item.batch_no}
                    onKeyDown={(e) => {
                        e.preventDefault()
                        // this.setState({ BatchAnchor: e.currentTarget })
                    }}
                    onFocus={(e) => {
                        this.setState({
                            BatchAnchor: e.currentTarget,
                            CurrentBatchIndex: index
                        }, () => {
                            e.preventDefault()
                            if ((!this.state.pharmacyTblData[index]['Batch_Sugg_list'])) {
                                this.getDrugDetailsData(index, false)
                            }
                        })
                    }}
                    inputProps={{ emed_tid: "bill_batch_name", style: { paddingLeft: '0.4vw' } }}
                    InputProps={{
                        endAdornment: this.state.SearchingBatchIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : <InputAdornment position="end"><ArrowDropDownIcon size={15} /></InputAdornment>,
                    }}
                />
                <Menu
                    open={this.state.CurrentBatchIndex === index}
                    className="eMed_Drug_Sugg_Pop"
                    anchorEl={this.state.BatchAnchor}
                    onClose={() => {
                        this.setState({ BatchAnchor: null, CurrentBatchIndex: null })
                    }}
                    autoFocus={true}
                    disableAutoFocusItem
                    disableAutoFocus
                    disableRestoreFocus
                >
                    <Box width={"30vw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.4vw' }} emed_tid={"bill_batch_no_items"}>
                            <Typography width={"35%"} fontWeight={600} fontSize={'0.8vw'} paddingLeft={'1vw'}>Batch No</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'} >Expiry Date</Typography>
                            <Typography width={"25%"} fontWeight={600} fontSize={'0.8vw'}>Avl/Qty</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'} >Cost/Qty</Typography>
                        </Box>
                        <Box maxHeight={'14vw'} overflow={'scroll'}>
                            {
                                (item.Batch_Sugg_list && item.Batch_Sugg_list.length > 0) ?
                                    item.Batch_Sugg_list.map((list, Drugindex) => {
                                        var datePart = list?.expiry_date ? list?.expiry_date.match(/\d+/g) : []
                                        var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                                        return (
                                            <MenuItem
                                                onKeyDown={(e) => {
                                                    if (Drugindex === item.Batch_Sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                    else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                        e.stopPropagation()
                                                        this.setState({ DurgNameCurrentIndex: index })
                                                    }
                                                }}
                                                emed_tid={`batch_suggestion`}
                                                key={Drugindex}
                                                autoFocus={true}
                                                onClick={() => { this.autocompleteOnChangeHandler(list, 'batch_no', index) }}
                                                sx={[{ display: 'flex', flexDirection: 'row', backgroundColor: list.batch_expiry_alert ? '#EE9E4F' : 'white', '&:hover': list.batch_expiry_alert ? { backgroundColor: '#f0bc89' } : null }]}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                                                    {this.state?.selected_method === "IOL" ?
                                                        list?.batch_number ? list?.batch_number?.length > 12 ?
                                                            <Tooltip placement='top' title={list?.batch_number} arrow>
                                                                <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_number.slice(0, 10) + "..."}</Typography>
                                                            </Tooltip>
                                                            : <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_number}</Typography> : "-"
                                                        : list?.batch_no ? list?.batch_no?.length > 12 ?
                                                            <Tooltip placement='top' title={list?.batch_no} arrow>
                                                                <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_no.slice(0, 10) + "..."}</Typography>
                                                            </Tooltip>
                                                            : <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_no}</Typography> : "-"}
                                                    <Typography width={"20%"} fontSize={'0.8vw'}>{ExpiryDate}</Typography>
                                                    <Typography width={"25%"} fontSize={'0.8vw'}>{list?.avaliable_quantity}</Typography>
                                                    <Typography width={"20%"} fontSize={'0.8vw'} textAlign={'center'}>{this.state.selected_method === "IOL" ? list?.mrp_price_per_qty : list?.mrp_per_quantity}</Typography>
                                                </Box>
                                            </MenuItem>
                                        )
                                    }) : <Typography textAlign={"center"} paddingY={"0.5vw"} width={"100%"} fontSize={'0.8vw'}>{"No Batches Found"}</Typography>
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }

    onChangeHandler = (e, index) => {
        try {
            const { name, value } = e.target
            switch (name) {
                case 'quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if (((CommonValidation.numberOnly(value) && value?.length < 7) || value === '')) {
                            this.state.pharmacyTblData[index]['quantity'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateCostAndTotal(value, index, false)
                                this.calculateMarginProfit()
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'return_quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if ((CommonValidation.numberOnly(value) && value?.length < 7) && (value != 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateCostAndTotal(value, index, true)
                                this.calculateMarginProfit()
                                if ((this.state?.isForReturn || this.state?.isFromIpReturn) && +this.state.netAmount === 0 && this.state.subAmount && +this.state.subAmount > 0) {
                                    this.setState({
                                        isCard: false,
                                        isCash: false,
                                        isUPI: false,
                                        isBankTranfer: false,
                                        isCheque: false,
                                        isInsurance: false,
                                        CashAmount: "",
                                        CardAmount: "",
                                        CardTransactionNumber: "",
                                        UPINumber: "",
                                        UPIAmount: "",
                                        BankTfrAmount: "",
                                        BankTfrNumber: "",
                                        ChequeNumber: "",
                                        ChequeAmount: "",
                                        InsuranceAmount: "",
                                        InsuCompanyName: "",
                                        InsuPolicyNumber: "",
                                        InsuTransactionNumber: "",
                                        InsuValidity: '',
                                    })
                                }
                            })
                        } else if ((value === '') || (value === 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = ''
                            this.state.pharmacyTblData[index]['total_cost'] = ''
                            this.state.pharmacyTblData[index]['total_return_price'] = ''
                            this.setState({
                                totalDiscount: 0,
                                pharmacyTblData: this.state.pharmacyTblData
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'discount':
                    if (this.state.pharmacyTblData[index]['quantity']) {
                        if ((CommonValidation.DecimalNumber(value) && value <= 100) || value === '') {
                            this.state.pharmacyTblData[index]['discount'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateDiscountTotalPrice(index)
                                this.calculateMarginProfit()
                            })
                        }
                    } else {
                        this.errorMessage('Enter Quantity')
                    }
                    break;
                case 'totalDiscount':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= this.state.subAmount) || value === "") {
                        this.state.totalPercentage = CommonValidation.amountPercentage(value, this.state.subAmount)
                        this.setState({
                            totalDiscount: value
                        }, () => {
                            this.calculateMarginProfit()
                        })
                    }
                    break;
                case 'totalPercentage':
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= 100) || value === "") {
                        this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.subAmount)
                        this.setState({
                            totalPercentage: value
                        }, () => {
                            this.calculateMarginProfit()
                        })
                    }
                    break;
                default:
                    this.setState({
                        [name]: value
                    })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    disabled = (names, index) => {
        try {
            let disable = true
            switch (names) {
                case 'quantity':
                    if (this.state.pharmacyTblData[index]['drug_name'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (RoleData?.permission_access?.pharma?.isEditSave) : true)) {
                        disable = false
                    }
                    break;
                case 'discount':
                    if (this.state.pharmacyTblData[index]['quantity'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && (this.state.LoggedRole !== "user" || (RoleData?.permission_access?.pharma?.isDiscountEdit && RoleData?.permission_access?.pharma?.isEditSave))) {
                        disable = false
                    }
                    break;
                case 'batch_no':
                    if (this.state.pharmacyTblData[index]['drug_name'] && this.state.pharmacyTblData[index]['dosage_strength'] && this.state.pharmacyTblData[index]['dosage_type'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (RoleData?.permission_access?.pharma?.isEditSave) : true)) {
                        disable = false
                    }
                    break;
                case 'drug_name':
                    disable = false;
                    break;
                case 'return_quantity':
                    disable = false;
                    break;
                default:
                    disable = true;
                    break;
            }
            return disable;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (values, names, labels, width, index, height, disable) => {
        return (
            <TextField
                inputRef={this.state.isFromIpRequest ? null : ((names === "quantity" && this.state.QuantityCurrentIndex === index) ? this.QuantityRef : null)}
                autoFocus
                size='small'
                disabled={disable ? disable : this.disabled(names, index)}
                variant='standard'
                sx={{ width: width }}
                placeholder={labels}
                onKeyDown={(e) => { if (e.key === "Enter") { this.DrugNameRef?.current.focus() } }}
                name={names}
                autoComplete='off'
                inputProps={{
                    emed_tid: labels,
                    style: {
                        height: height ? height : '2vh', marginTop: '0.5vw', textAlign: 'center',
                        color: (names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"]) ? "red" : null) :
                            (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["return_quantity"] > this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["quantity"]) ? "red" : null) : null
                    }
                }}
                value={values}
                onChange={(e) => { this.onChangeHandler(e, index) }}
                error={(names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"])) :
                    (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["return_quantity"] > this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["quantity"])) : false}
            />
        )
    }

    ClosePopUp() {
        this.setState({ CompletedBillID: null }, () => {
            if (this.state.isFromSavedBill) { this.props?.getPharmaSavedBillCount(true) }
            if (this.state.isFromPrescription) { this.props?.getPharmaPrescriptionCount(true) }
            this.DiscardAction(true, this.state.isFromInvoice)
        })
    }

    HistoryClosePopUp(DrugList = []) {
        this.setState({ ShowPrescriptonHistory: false }, () => {
            if (DrugList.length > 0) {
                let BillingLineItems = []
                this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
                BillingLineItems.push({
                    drug_name: '',
                    dosage_strength: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    quantity: '',
                    total_cost: '',
                    discount: '',
                    total_price: '',
                    hsn_code: '',
                    stock_in_quantity: '',
                    Drug_sugg_list: [],
                })
                DrugList.forEach((item) => {
                    item["dosage_strength"] = item?.drug_strength
                    item["dosage_type"] = item?.dosagetype
                    item["discount"] = 0
                    item["quantity"] = ""
                    item["is_from_history"] = true
                })
                this.setState({ pharmacyTblData: [...DrugList, ...BillingLineItems] })
            }
        })
    }

    DiscardAction = (flag, MovetoInvoice = false) => {
        if (flag) {
            const EmptyBillItem = [
                {
                    drug_name: '',
                    dosage_strength: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    quantity: '',
                    total_cost: '',
                    discount: '',
                    total_price: '',
                    hsn_code: '',
                    stock_in_quantity: '',
                    Drug_sugg_list: [],
                }
            ]
            try {
                this.setState({
                    pharmacyTblData: EmptyBillItem,
                    drugDetailsData: [],
                    isCard: false,
                    isCash: false,
                    isUPI: false,
                    isBankTranfer: false,
                    isCheque: false,
                    isInsurance: false,
                    ChequeAmount: '',
                    CashAmount: '',
                    CardAmount: '',
                    BankTfrAmount: '',
                    UPIAmount: '',
                    CardTransactionNumber: '',
                    ChequeNumber: '',
                    BankTfrNumber: '',
                    UPINumber: '',
                    netAmount: 0,
                    subAmount: 0,
                    roundOff: 0,
                    totalDiscount: '',
                    totalPercentage: '',
                    DurgNameCurrentIndex: null,
                    PatientDetails: {},
                    BillDetails: {},
                    PatientSearchClicked: false,
                    SearchedPatientList: [],
                    doctordetails: {},
                    PatientMobileNumber: "",
                    PatientName: "",
                    invoiceDateTime: new Date(),
                    ShowDiscard: false,
                    Patient_Id: null,
                    ReceivedCashAmount: "",
                    CashBalanceAmount: "",
                    isFromInvoice: false,
                    isForReturn: false,
                    isFromSavedBill: false,
                    isForInvoiceEdit: false,
                    isFromIpRequest: false,
                    isFromIpReturn: false,
                    isFromPrescription: false,
                    ShowHistory: false,
                    ShowPrescriptonHistory: false,
                    CompletedBillID: null,
                    Bill_ID: null,
                    SearchingDrugIndex: null,
                    SearchingBatchIndex: null,
                    isBillsFetched: false,
                    InvoiceNumber: null,
                    isCredit: false,
                    savedBill: false,
                    PrescHistoryData: null,
                    PrescriptionId: null,
                    OutStandingAmount: 0,
                    BillReceivedAmount: 0,
                    ReturnedNetAmount: 0,
                }, () => {
                    if (MovetoInvoice) {
                        this.props.history.push({ pathname: '/PharmacyInvoice/Invoice' })
                    } else {
                        this.props.history.push({ pathname: "/OTStocks", state: {} })
                    }
                })
            } catch (error) {
                this.errorMessage(error.message)
            }
        } else {
            this.setState({ ShowDiscard: false })
        }
    }

    onClearTableData = () => {
        let TableHasValues = false
        this.state.pharmacyTblData.forEach((list) => {
            if (list.drug_name !== "") { TableHasValues = true }
        })
        if (TableHasValues) {
            this.setState({ ShowDiscard: true })
        } else {
            this.DiscardAction(true)
        }
    }

    isScheduledDrug(index) {
        try {
            if (
                this.state.pharmacyTblData[index]?.schedule &&
                this.state.pharmacyTblData[index]?.schedule !== null &&
                this.state.pharmacyTblData[index]?.schedule !== "" &&
                this.state.pharmacyTblData[index]?.drug_name &&
                this.state.pharmacyTblData[index]?.drug_name !== "" &&
                this.state.pharmacyTblData[index]?.batch_no &&
                this.state.pharmacyTblData[index]?.batch_no !== "") {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }

    BillItemsIsValid = () => {
        try {
            let Flag = false
            let BillingLineItems = []
            this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "" && (this.state.isFromIpRequest ? item?.stock_in_quantity !== 0 : true)) { BillingLineItems.push(item) } })
            if (BillingLineItems.length > 0) {
                for (let i = 0; i < BillingLineItems.length; i++) {
                    if ((BillingLineItems[i]["drug_name"] !== "" && BillingLineItems[i]["batch_no"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0") && BillingLineItems.length > 0) {
                        if (this.state.isForReturn || this.state.isFromIpReturn) {
                            if ((BillingLineItems[i]["return_quantity"] <= BillingLineItems[i]["quantity"]) && BillingLineItems[i]["return_quantity"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0") {
                                Flag = true
                            } else {
                                if ((BillingLineItems[i]["return_quantity"] > BillingLineItems[i]["quantity"])) {
                                    Flag = false
                                    this.errorMessage(`${BillingLineItems[i]["drug_name"]} Return Quantity is More then Actual Quantity`)
                                    break
                                }
                            }
                        } else {
                            if ((BillingLineItems[i]["quantity"] <= BillingLineItems[i]["stock_in_quantity"] || !BillingLineItems[i]["stock_in_quantity"]) && BillingLineItems[i]["quantity"] !== "") {
                                Flag = true
                            } else {
                                if (BillingLineItems[i]["quantity"] > BillingLineItems[i]["stock_in_quantity"]) {
                                    Flag = false
                                    this.errorMessage(`${BillingLineItems[i]["drug_name"]} Quantity is More then Stock Quantity`)
                                    break
                                }
                            }
                        }
                    } else {
                        if (BillingLineItems[i]["drug_name"] === "") { this.errorMessage("No drug were Entered") }
                        else if (BillingLineItems[i]["batch_no"] === "") { this.errorMessage(`${BillingLineItems[i]["drug_name"]} "Batch were Not Selected`) }
                        else if (BillingLineItems[i]["quantity"] === "" || BillingLineItems[i]["quantity"] === "0") { this.errorMessage(`${BillingLineItems[i]["drug_name"]} Quantity is Not Entered`) }
                        else { this.errorMessage("Invalid Bill Items") }
                        Flag = false
                        break
                    }
                }
                return Flag
            } else {
                Flag = false
                this.errorMessage("No Drug were Added")
                return Flag
            }

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    Validation = () => {
        try {
            const { pharmacyTblData } = this.state;
            const filteredArray = pharmacyTblData.filter(item => item.drug_name.trim() !== '');

            if (filteredArray.length !== pharmacyTblData.length) {
                this.setState({ filteredArray: filteredArray });
            }
            const isValidQuantity = filteredArray.every(item => item.quantity > 0);

            if (!isValidQuantity) {
                this.errorMessage('Quantity is Not Entered');
            } else {
                this.PostStockList();
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderPaymentButton = () => {
        try {
            return (
                <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background, width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
                    <Box display={'flex'} alignItems={"center"}>
                        <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                            disabled={this.state.isForReturn ? false : this.state.isFromInvoice}
                            size='small'
                            variant='outlined'
                            onClick={() => this.onClearTableData()}
                        >Clear</Button>

                        <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: "10vw" }}
                            size='small'
                            variant='contained'
                            onClick={() => { this.Validation() }}
                            disabled={this.state.disablePost}
                        >{'Save'}</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTextBox = (names, value, height, diasble) => {
        try {
            return (
                <TextField
                    autoFocus
                    variant='standard'
                    autoComplete='off'
                    size='small'
                    InputProps={{
                        startAdornment: names === "totalDiscount" ? <InputAdornment position="start">{CurrencySymbol}</InputAdornment> : null,
                        endAdornment: names === "totalPercentage" ? <InputAdornment position="start">%</InputAdornment> : null
                    }}
                    className={'eMed_discount_txt'}
                    disabled={diasble || this.state.isForReturn || this.state.isFromIpReturn || (this.state.isFromInvoice)}
                    inputProps={{ style: { height: height }, emed_tid: names }}
                    name={names}
                    value={value}
                    onChange={(e) => { this.onChangeHandler(e, names) }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    billingCalculation = (params = [], type) => {
        try {
            let amount = 0; let payableAmount = 0; let OutStandingValue = 0;
            switch (type) {
                case 'totalServiceAmount':
                    if (this.state.isForReturn || this.state.isFromIpReturn) {
                        params.forEach(element => {
                            amount = amount + (element.total_return_price ? element.total_return_price : 0)
                        })
                        if (this.state.subAmount !== +amount) {
                            this.setState({
                                subAmount: +amount
                            }, () => {
                                this.calculateTotalDiscount()
                            })
                        }
                    } else {
                        params.forEach(element => {
                            amount = amount + (element.total_price ? element.total_price : 0)
                        })
                        if (this.state.subAmount !== +amount) {
                            this.setState({
                                subAmount: +amount
                            }, () => {
                                this.calculateTotalDiscount()
                            })
                        }
                    }
                    return AmountFormat(this.state.subAmount)
                case 'marginProfit':
                    let finalMarginProfit = this.state.netAmount - this.state.totalBuyingPrice
                    return AmountFormat(this.state.netAmount == 0 ? 0 : (finalMarginProfit ? parseFloat(finalMarginProfit).toFixed(2) : 0))
                case 'roundOff':
                    this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.totalDiscount)
                    return this.state.roundOff == 0 ? 0 : (this.state.roundOff > 0 ? `+ ${this.state.roundOff}` : `- ${this.state.roundOff * -1}`);
                case 'payableAmount':
                    if (this.state.isForReturn || this.state.isFromIpReturn) {
                        let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
                        let netpayableAmount = this.state.subAmount - discount
                        if (this.state.ReturnedNetAmount !== (this.state.subAmount ? Math.round(+netpayableAmount) : 0)) {
                            this.setState({
                                ReturnedNetAmount: this.state.subAmount ? Math.round(+netpayableAmount) : 0
                            })
                        }
                        return AmountFormat(this.state.ReturnedNetAmount)
                        // OutStandingValue = netpayableAmount ? (Math.round(+netpayableAmount) - this.state.OutStandingAmount) : 0
                        // if (this.state.netAmount !== (Math.round(+OutStandingValue) > 0 ? Math.round(+OutStandingValue) : 0)) {
                        //   this.setState({
                        //     netAmount: Math.round(+OutStandingValue) > 0 ? Math.round(+OutStandingValue) : 0
                        //   }, () => { this.SetFullCashAmount() })
                        // }
                        // return (this.state.netAmount && this.state.netAmount > 0) ? AmountFormat(this.state.netAmount) : 0
                    } else {
                        let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
                        payableAmount = this.state.subAmount - discount
                        if (this.state.netAmount !== (Math.round(+payableAmount))) {
                            this.setState({
                                netAmount: Math.round(+payableAmount)
                            }, () => { this.SetFullCashAmount() })
                        }
                        return AmountFormat(this.state.netAmount)
                    }
                case 'ReceivedAmount':
                    return (this.state.BillReceivedAmount && this.state.BillReceivedAmount > 0) ? AmountFormat(this.state.BillReceivedAmount) : 0
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteLineItems = (index = null) => {
        try {
            let BillingLineItems = []
            this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
            if (index === null || (index === 0 && BillingLineItems.length === 1)) {
                this.setState({
                    pharmacyTblData: [
                        {
                            drug_name: '',
                            dosage_strength: '',
                            dosage_type: '',
                            batch_no: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            quantity: '',
                            total_cost: '',
                            discount: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            Drug_sugg_list: [],
                        }
                    ],
                    isCard: false,
                    isCash: false,
                    isUPI: false,
                    isBankTranfer: false,
                    isCheque: false,
                    isInsurance: false,
                    CashAmount: "",
                    CardAmount: "",
                    CardTransactionNumber: "",
                    UPINumber: "",
                    UPIAmount: "",
                    BankTfrAmount: "",
                    BankTfrNumber: "",
                    ChequeNumber: "",
                    ChequeAmount: "",
                    InsuranceAmount: "",
                    InsuCompanyName: "",
                    InsuPolicyNumber: "",
                    InsuTransactionNumber: "",
                    InsuValidity: '',
                    subAmount: 0,
                    totalPercentage: '',
                    totalDiscount: '',
                    roundOff: 0,
                    netAmount: 0,
                    ReturnedNetAmount: 0,
                })
            } else {
                if (index !== this.state.pharmacyTblData.length - 1) {
                    this.state.pharmacyTblData.splice(index, 1)
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => this.calculateTotalDiscount())
                } else {
                    this.state.pharmacyTblData[index] = {
                        drug_name: '',
                        dosage_strength: '',
                        dosage_type: '',
                        batch_no: '',
                        expiry_date: '',
                        physical_box_no: '',
                        cost_per_quantity: '',
                        quantity: '',
                        total_cost: '',
                        discount: '',
                        total_price: '',
                        hsn_code: '',
                        stock_in_quantity: '',
                        Drug_sugg_list: [],
                    }
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => this.calculateTotalDiscount())
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        var { BillReceivedAmount, outstandingAmt, totalBillNetAmount, selected_method } = this.state
        let PendingAmt = outstandingAmt
        if (!PendingAmt && BillReceivedAmount && (BillReceivedAmount != totalBillNetAmount)) {
            PendingAmt = totalBillNetAmount - BillReceivedAmount
        }
        return (
            <Box component={'div'} className='eMed_Pharma_Billing_container'>
                <Box component={'div'} height={'5vw'} className='eMEd_pharma_Home_header'>
                    {this.state.isOPT ? <RadioGroup value={selected_method}
                        sx={{ display: 'flex', flexDirection: 'row', width: '18vw' }}
                        onClick={(e) => this.setState({ selected_method: e.target.value }, () => { this.onClearTableData() })}
                    >
                        <FormControlLabel value={'Drug'} control={<Radio size='small' />} label='Drug' />
                        <FormControlLabel value={'IOL'} control={<Radio size='small' />} label='IOL' />
                    </RadioGroup> : null}
                </Box>
                <Box component={'div'}>
                    <Box component={'div'}>
                        <TableContainer className='eMed_Phrama_Table_Container' style={{ height: "60vh" }}>
                            <Table stickyHeader size='small' sx={{ overflowY: 'scroll' }}>
                                <TableHead>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ flex: 0.05 }} className='eMed_Pharma_tbl_header'>
                                            <Button emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems()}>
                                                <Box component={'img'} mt={'0.2vw'} src={ImagePaths.Delete.default} alt='del' height={'1.3vw'} width={'1.3vw'} />
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.05 }} className='eMed_Pharma_tbl_header'>S.No</TableCell>
                                        <TableCell sx={{ flex: 0.17 }} className='eMed_Pharma_tbl_header'>Brand Name</TableCell>
                                        <TableCell sx={{ flex: 0.17 }} className='eMed_Pharma_tbl_header'>{this.state.selected_method === "IOL" ? "Model Name" : "Generic Name"}</TableCell>
                                        <TableCell sx={{ flex: 0.1 }} align='center' className='eMed_Pharma_tbl_header'>{this.state.selected_method === "IOL" ? "IOL Type" : "Strength"}</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.1 }} className='eMed_Pharma_tbl_header'>{this.state.selected_method === "IOL" ? "IOL Power" : "Type"}</TableCell>
                                        <TableCell sx={{ flex: 0.12 }} className='eMed_Pharma_tbl_header'>Batch No</TableCell>
                                        <TableCell sx={{ flex: 0.13 }} align='left' className='eMed_Pharma_tbl_header'>Available Qty</TableCell>
                                        <TableCell align='left' sx={{ flex: 0.13 }} className='eMed_Pharma_tbl_header'>
                                            <Tooltip placement='top' title='Quantity'><div>Qty</div></Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.pharmacyTblData.map((item, index) => {
                                        return (
                                            <Tooltip title={this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? "No Stock Available" : ""} placement='top' arrow>
                                                <TableRow sx={{ display: 'flex', backgroundColor: this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? Colors.disableComponentColor : null }} key={index}>
                                                    <TableCell sx={{ flex: 0.05 }}>
                                                        <Button emed_tid={"bill_delete_item"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems(index)}>
                                                            <Box component={'img'} mt={'0.5vw'} src={ImagePaths.Delete.default} alt='del' height={'1.1vw'} width={'1.1vw'} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.05, paddingTop: '0.8vw' }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ flex: 0.17 }}>{this.renderDrugColumn(index, item)}</TableCell>
                                                    <TableCell emed_tid="bill_dosage_strength" sx={{ flex: 0.17, paddingTop: '0.8vw' }} className='eMed_Pharma_tbl_Body'>{item?.generic_name ? item?.generic_name : '-'}</TableCell>
                                                    <TableCell align='center' emed_tid="bill_dosage_strength" sx={{ flex: 0.1, paddingTop: '0.8vw' }} className='eMed_Pharma_tbl_Body'>{item?.dosage_strength ? item?.dosage_strength : '-'}</TableCell>
                                                    <TableCell align='center' emed_tid="bill_dosage_type" sx={{ flex: 0.1, paddingTop: '0.8vw' }}>
                                                        {item?.dosage_type ? item?.dosage_type?.length > 10 ?
                                                            <Tooltip placement='top' title={item?.dosage_type} arrow>
                                                                <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type.slice(0, 10) + "..."}</div></Tooltip> :
                                                            item?.dosage_type : "-"}
                                                    </TableCell>
                                                    <TableCell align='center' emed_tid="bill_physical_box_no" sx={{ flex: 0.12, paddingTop: '0.8vw' }}>
                                                        {this.renderBatchColumn(index, item)}
                                                    </TableCell>
                                                    <TableCell emed_tid="bill_cost_per_quantity" align='center' sx={{ flex: 0.13, paddingTop: '0.8vw' }}>{item?.stock_in_quantity ? item?.stock_in_quantity : '-'}</TableCell>
                                                    <TableCell sx={{ flex: 0.13 }}>
                                                        {this.inputTextBox(item?.quantity, 'quantity', 'Quantity', '5vw', index, '2vh', false)}
                                                    </TableCell>
                                                </TableRow>
                                            </Tooltip>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {this.renderPaymentButton()}
                        {this.state.isErrorMsg ?
                            <ToastMsg
                                severity={'error'}
                                msg={this.state.isErrorMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null}
                        {this.state.successMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.successMsgText}
                                msgPop={this.msgClose.bind(this)}
                                testId={this.state.invoiceNumber}
                            />
                            : null}
                        <Loader loaderOpen={this.state.isLoader} />
                        {this.state.ShowDiscard ? <PharmaDiscardPop SendAction={this.DiscardAction.bind(this)} /> : null}
                        {this.state.CompletedBillID !== null ? <BillSuccessPoPUp InvoiceId={this.state.CompletedBillID} isFromPharmacy={true} ClosePopUp={this.ClosePopUp.bind(this)} testID={"Ph_BillSuc"} CloseBtnText={(this.state.isForReturn || this.state.isFromIpReturn) ? "Back" : null} /> : null}
                        {this.state.ShowPrescriptonHistory ? <PrescriptionHistoryPop PatientDetails={this.state.PatientDetails} HistoryClosePopUp={this.HistoryClosePopUp.bind(this)} BillingList={this.state.pharmacyTblData} PrescHistoryData={this.state.PrescHistoryData} /> : null}

                    </Box>
                </Box>
            </Box>
        )
    }

}
export default withTranslation()(OTStockList)
import React, { Component } from 'react'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { Autocomplete, Box, Button, Card, CardActionArea, CardContent, CircularProgress, Modal, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';

export default class ReservationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ReservationData: [],
            BedList: [],
            Att_BedList: [],
            isLoding: false,
            wardType: null,
            BlockList: [],
            blockType: null,
            FloorList: [],
            floorType: null,
            roomList: [],
            roomType: null,
            bedData: [],
            bedType: null,
            BedList_filter: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            dupBedList: '[]',
            BlockList_filter: null,
            floorList_filter: null,
            roomList_filter: null,
            bedList_filter: null
        }
    }

    componentDidMount() {
        this.reserseData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    reserseData = () => {
        try {
            this.setState({
                isLoding: true
            })
            let UserData = localGetItem("loggedInUserInfo")
            let UserDetails = JSON.parse(UserData)
            let APIURL = ""
            if (UserDetails?.module_name === "ot_management") {
                APIURL = Serviceurls.IP_BED_AVAILABLE + `?clinic_id=${UserDetails?.clinic_id}`
            } else {
                APIURL = Serviceurls.IP_BED_AVAILABLE
            }
            RestAPIService.getAll(APIURL).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BedList: this.RemoveDuplicates(response.data.data, 'ward_id'),
                            Att_BedList: this.RemoveDuplicates(response.data.data, 'ward_id'),
                            BedList_dup: response.data.data,
                            dupBedList: JSON.stringify(response.data.data),
                            isLoding: false
                        })
                    }
                    else {
                        this.setState({
                            isLoding: false
                        })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({
                        isLoding: false
                    })
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            this.setState({
                isLoding: false
            })
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }
    RemoveDuplicates(Array, key) {
        const FilteredArray = Array.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item[key] === current[key])) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        return FilteredArray
    }
    renderRoomData = () => {
        var states = this.state
        // let AttenderData = states.BedList_dup?.filter((item) => (item.is_attender === true))
        // let Att_FilterData = states.BedList_filter?.filter((item) => (item.is_attender === true))
        // let Att_RoomData = Att_FilterData.length > 0 ? Att_FilterData : states.BedList_filter.length > 0 ? [] : AttenderData
        // let RoomDetails = states?.BedList_filter?.length > 0 ? states.BedList_filter : states?.BedList_dup?.length > 0 ? states?.BedList_dup : []
        // let finalData = this.props.ListType === 'patient' ? RoomDetails : Att_RoomData
        return (
            <Box id=''>
                {states.isLoding ?
                    <Box id='eMed_Bed_Circle'>
                        <CircularProgress color='primary' />
                    </Box>
                    // : finalData?.length > 0 ? finalData?.map((list, index) => {
                    //     return (
                    //         <Card id='eMed_Bed_Reserve_CardView' sx={{ cursor: list.is_available ? 'pointer' : 'auto', border: '0.2vw solid', borderColor: list.is_available ? '#00b300' : 'white', backgroundColor: list.is_available ? '#e6ffe6' : 'white' }}
                    //             onClick={() => {
                    //                 if (list.is_available) {
                    //                     this.props.sendRommDetail(list)
                    //                     this.props.PopClose()
                    //                 }else{
                    //                     this.errorMessage("Room is Already Booked")
                    //                 }

                    //             }}>
                    //             <CardContent sx={{ width: '6vw' }}>
                    //                 <Box style={{ display: 'flex', flexDirection: 'row', width: '5vw', marginTop: '0vw' }}>
                    //                     {list.room_number.length > 3 ?
                    //                         <Tooltip title={list.room_number} placement='top'>
                    //                             <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                    //                                 {list.room_number ? '..' + list.room_number.slice(-3) + ' / ' : '-'}
                    //                             </Typography>
                    //                         </Tooltip> : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                    //                             {`${list.room_number ? list.room_number + ' / ' : '-'}`}
                    //                         </Typography>}
                    //                     <Box sx={{ marginLeft: '0.2vw' }}>
                    //                         {list.bed_number.length > 3 ?
                    //                             <Tooltip title={list.bed_number} placement='top'>
                    //                                 <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                    //                                     {list.bed_number ? '..' + list.bed_number.slice(-3) : '-'}
                    //                                 </Typography>
                    //                             </Tooltip> : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                    //                                 {`${list.bed_number ? list.bed_number : '-'}`}
                    //                             </Typography>}
                    //                     </Box>
                    //                 </Box>
                    //                 <Box sx={{ display: 'flex', flexDirection: 'row', width: '5.3vw' }}>
                    //                     <Typography sx={{ fontSize: '0.6vw' }}>
                    //                         {"Room / Bed No"}
                    //                     </Typography>
                    //                     {list.ward_type === "NON AC" ? null : <img style={{ width: '0.5vw', height: '0.5vw' }} src={ImagePaths.AC_Icon.default} alt='AC' />}
                    //                 </Box>
                    //                 <Box id='eMed_Bed_Reserve_wardText'>
                    //                     <img src={ImagePaths.WardIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                    //                     {list?.ward__ward_name?.length > 6 ?
                    //                         <Tooltip title={list?.ward__ward_name} placement='top'>
                    //                             <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                    //                                 {list.ward__ward_name ? list.ward__ward_name.slice(0, 6) + '...' : '-'}
                    //                             </Typography>
                    //                         </Tooltip> :
                    //                         <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                    //                             {list.ward__ward_name ? list.ward__ward_name : '-'}
                    //                         </Typography>}

                    //                 </Box>
                    //                 <Box id='eMed_Bed_Reserve_FloorText' sx={{}}>
                    //                     <img src={ImagePaths.FloorIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                    //                     {list?.block?.length > 6 ?
                    //                         <Tooltip title={list?.block + " Floor"} placement='top'>
                    //                             <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                    //                                 {list.block ? list.block.slice(0, 6) + '...' : '-'}
                    //                             </Typography>
                    //                         </Tooltip> :
                    //                         <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                    //                             {list?.block ? list?.block : '-'}
                    //                         </Typography>}
                    //                 </Box>
                    //             </CardContent>
                    //             <Box id='eMed_Bed_Reserve_FloorCon' sx={{ backgroundColor: Colors.themeBackgroud }}>
                    //                 <Typography sx={{ fontSize: '0.6vw', color: 'white', fontWeight: 'bold' }}>
                    //                     {`${list?.floor ? list?.floor : '-'}F`}
                    //                 </Typography>
                    //             </Box>
                    //         </Card>
                    //     )
                    // }) :
                    //     <Box id='eMed_Bed_Circle'>
                    //         <Typography>{"No Records To Be Shown"}</Typography>
                    //     </Box>
                    :
                    <Box component={'div'} height={'56vh'} overflow={'auto'}>
                        {states.BedList_dup?.length > 0 ? states?.BedList_dup?.map((blockList, blockIndex) => (
                            <Box component={'div'} key={blockIndex}>
                                {blockList?.block_data?.length > 0 ? blockList?.block_data?.map((wardList, wardIndex) => (
                                    <Box component={'div'} key={wardIndex} ml={'1.5vw'}>
                                        {wardList?.ward_data?.length > 0 ? wardList?.ward_data?.map((floorList, floorIndex) => (
                                            <><Box component={'div'} key={floorIndex} display={'flex'} flexDirection={'column'}>
                                                <Box component={'div'} display={'flex'}>
                                                    <Box component={'div'} display={'flex'} ml={'0.5vw'}>
                                                        <Typography color={Colors.grayShade}>{'Block Name : '}</Typography>
                                                        <Typography fontWeight={600}>{`${blockList?.block ? blockList?.block : '-'} /`}</Typography>
                                                    </Box>
                                                    <Box component={'div'} display={'flex'}>
                                                        <Typography color={Colors.grayShade}>Ward Name :</Typography>
                                                        <Typography fontWeight={600}>{` ${wardList?.ward_name ? wardList?.ward_name : '-'} /`}</Typography>
                                                    </Box>
                                                    <Box component={'div'} display={'flex'} ml={'0.5vw'}>
                                                        <Typography color={Colors.grayShade}>{"Floor :"}</Typography>
                                                        <Typography fontWeight={600}>{`${floorList?.floor ? floorList?.floor : '-'}`}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box component={'div'} display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                                                    {floorList?.floor_data?.length > 0 ? floorList?.floor_data?.map((list, index) => {
                                                        return (
                                                            <Box component={'div'} display={'flex'} flexDirection={'row'} key={index}>
                                                                <Card id='eMed_Bed_Reserve_CardView' sx={{ cursor: list?.is_available ? 'pointer' : 'auto', border: '0.2vw solid', borderColor: list?.is_available ? '#00b300' : 'white', backgroundColor: list?.is_available ? '#e6ffe6' : 'white' }}
                                                                    onClick={() => {
                                                                        if (list?.is_available) {
                                                                            this.props.sendRommDetail(list)
                                                                            this.props.PopClose()
                                                                        } else {
                                                                            this.errorMessage("Room is Already Booked")
                                                                        }
                                                                    }}>
                                                                    <CardContent sx={{ width: '6vw' }}>
                                                                        <Box style={{ display: 'flex', flexDirection: 'row', width: '5vw', marginTop: '0vw' }}>
                                                                            {list?.room_number?.length > 3 ?
                                                                                <Tooltip title={list?.room_number} placement='top'>
                                                                                    <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                                                                                        {list?.room_number ? '..' + list?.room_number.slice(-3) + ' / ' : '-'}
                                                                                    </Typography>
                                                                                </Tooltip> : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                                                                                    {`${list?.room_number ? list?.room_number + ' / ' : '-'}`}
                                                                                </Typography>}
                                                                            <Box sx={{ marginLeft: '0.2vw' }}>
                                                                                {list?.bed_number?.length > 3 ?
                                                                                    <Tooltip title={list.bed_number} placement='top'>
                                                                                        <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                                                                                            {list?.bed_number ? '..' + list?.bed_number.slice(-3) : '-'}
                                                                                        </Typography>
                                                                                    </Tooltip> : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>
                                                                                        {`${list?.bed_number ? list?.bed_number : '-'}`}
                                                                                    </Typography>}
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '5.3vw' }}>
                                                                            <Typography sx={{ fontSize: '0.6vw' }}>
                                                                                {"Room / Bed No"}
                                                                            </Typography>
                                                                            {list?.ward_type === "NON AC" ? null : <img style={{ width: '0.5vw', height: '0.5vw' }} src={ImagePaths.AC_Icon.default} alt='AC' />}
                                                                        </Box>
                                                                        <Box id='eMed_Bed_Reserve_wardText'>
                                                                            <img src={ImagePaths.WardIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                                                                            {list?.ward__ward_name?.length > 6 ?
                                                                                <Tooltip title={list?.ward__ward_name} placement='top'>
                                                                                    <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                                                                                        {list?.ward__ward_name ? list?.ward__ward_name.slice(0, 6) + '...' : '-'}
                                                                                    </Typography>
                                                                                </Tooltip> :
                                                                                <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                                                                                    {list?.ward__ward_name ? list?.ward__ward_name : '-'}
                                                                                </Typography>}

                                                                        </Box>
                                                                        <Box id='eMed_Bed_Reserve_FloorText' sx={{}}>
                                                                            <img src={ImagePaths.FloorIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                                                                            {list?.block?.length > 6 ?
                                                                                <Tooltip title={list?.block + " Floor"} placement='top'>
                                                                                    <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                                                                                        {list?.block ? list?.block.slice(0, 6) + '...' : '-'}
                                                                                    </Typography>
                                                                                </Tooltip> :
                                                                                <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.8vw' }}>
                                                                                    {list?.block ? list?.block : '-'}
                                                                                </Typography>}
                                                                        </Box>
                                                                    </CardContent>
                                                                    <Box id='eMed_Bed_Reserve_FloorCon' sx={{ backgroundColor: Colors.themeBackgroud }}>
                                                                        <Typography sx={{ fontSize: '0.6vw', color: 'white', fontWeight: 'bold' }}>
                                                                            {`${list?.floor ? list?.floor : '-'}F`}
                                                                        </Typography>
                                                                    </Box>
                                                                </Card>

                                                            </Box>
                                                        )
                                                    }) : null}
                                                </Box>
                                            </Box>
                                            </>
                                        )) : null}
                                    </Box>
                                )) : null}
                            </Box>
                        )) : <Box id='eMed_Bed_Circle'>
                            <Typography>{"No Records To Be Shown"}</Typography>
                        </Box>}
                    </Box>
                }
            </Box>
        )
    }

    renderFilter = () => {
        let wardData = []
        // wardData = this.props.ListType === 'patient' ? this.state.BedList : this.state.Att_BedList
        wardData = this.props.ListType === 'patient' ? this.state.BedList_dup : this.state.BedList_dup
        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box className='eMed_reserve_dropdown'>
                    <Autocomplete
                        clearIcon
                        size='small'
                        options={wardData}
                        getOptionLabel={(option) => (option?.block)}
                        value={this.state?.wardType}
                        onChange={(event, newValue) => {
                            this.setState({
                                wardType: newValue,
                                blockType: null,
                                floorType: null,
                                roomType: null,
                                FloorList: [],
                                roomList: [],
                            }, () => {
                                var states = this.state
                                let dupBedListData = JSON.parse(this.state.dupBedList)
                                if (states.wardType) {
                                    // let block = this.RemoveDuplicates(states.BedList_dup.filter((item) => item.ward_id === states?.wardType?.ward_id), 'block_no_id')
                                    // let block_view = states.BedList_dup.filter((item) => item.ward_id === states?.wardType?.ward_id)
                                    this.setState({
                                        BlockList: states.wardType?.block_data,
                                        BlockList_filter: states.wardType?.block,
                                    }, () => {
                                        let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_filter))
                                        this.setState({ BedList_dup: [data] })
                                    })
                                } else {
                                    this.setState({
                                        BlockList: [],
                                        BlockList_filter: null,
                                        BedList_dup: dupBedListData
                                    })
                                }
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Select Block'}
                            />
                        )}
                    />
                </Box>
                <Box className='eMed_reserve_dropdown'>
                    <Autocomplete
                        clearIcon
                        size='small'
                        disabled={this.state.BlockList.length === 0}
                        options={this.state?.BlockList}
                        getOptionLabel={(option) => (option?.ward_name)}
                        value={this.state.BlockList.length > 0 ? this.state?.blockType : null}
                        onChange={(event, newValue) => {
                            this.setState({
                                blockType: newValue,
                                floorType: null,
                                roomType: null,
                                roomList: [],
                            }, () => {
                                var states = this.state
                                let dupBedListData = JSON.parse(this.state.dupBedList)
                                if (states.blockType) {
                                    // let floor = this.RemoveDuplicates(states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType?.block_no_id)), 'floor')
                                    // let floor_View = states.blockType ? states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType?.block_no_id)) :
                                    //     states.BedList_dup.filter((item) => item.ward_id === states?.wardType?.ward_id)
                                    this.setState({
                                        FloorList: states.blockType?.ward_data,
                                        floorList_filter: states.blockType?.ward_name
                                    }, () => {
                                        let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_filter))
                                        let blockData = data?.block_data?.find(item => item?.ward_name === this.state.floorList_filter)
                                        data.block_data = [blockData]

                                        this.setState({ BedList_dup: [data] })
                                    })
                                } else {
                                    this.setState({
                                        FloorList: [],
                                        floorList_filter: null,
                                        BedList_dup: dupBedListData
                                    })
                                }

                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Select Ward'}
                            />
                        )}
                    />
                </Box>
                <Box className='eMed_reserve_dropdown'>
                    <Autocomplete
                        clearIcon
                        size='small'
                        disabled={this.state.FloorList.length === 0}
                        options={this.state?.FloorList}
                        getOptionLabel={(option) => (option?.floor)}
                        value={this.state.FloorList.length > 0 ? this.state?.floorType : null}
                        onChange={(event, newValue) => {
                            this.setState({
                                floorType: newValue,
                                roomType: null,
                            }, () => {
                                var states = this.state
                                let dupBedListData = JSON.parse(this.state.dupBedList)
                                if (states.floorType) {
                                    // let room = this.RemoveDuplicates(states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor)), 'room_number')
                                    // let room_View = states.floorType ? states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor)) :
                                    //     states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType?.block_no_id))
                                    this.setState({
                                        roomList: states.floorType?.floor_data,
                                        roomList_filter: states.floorType?.floor,
                                    }, () => {
                                        let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_filter))
                                        let blockData = data?.block_data?.find(item => item?.ward_name === this.state.floorList_filter)
                                        let wardData = blockData?.ward_data?.find(item => item?.floor === this.state.roomList_filter)

                                        blockData.ward_data = [wardData]
                                        data.block_data = [blockData]

                                        this.setState({ BedList_dup: [data] })
                                    })
                                } else {
                                    this.setState({
                                        roomList: [],
                                        roomList_filter: null,
                                        BedList_dup: dupBedListData
                                    })
                                }

                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Select Floor'}
                            />
                        )}
                    />
                </Box>
                <Box className='eMed_reserve_dropdown'>
                    <Autocomplete
                        clearIcon
                        size='small'
                        disabled={this.state.roomList.length === 0}
                        options={this.state.roomList.length > 0 ? this.state?.roomList : null}
                        getOptionLabel={(option) => (option?.bed_number)}
                        value={this.state?.roomType}
                        onChange={(event, newValue) => {
                            this.setState({
                                roomType: newValue
                            }, () => {
                                var states = this.state
                                let dupBedListData = JSON.parse(this.state.dupBedList)
                                if (states.roomType) {
                                    // let bedItem = this.RemoveDuplicates(states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor && item.room_number === states.roomType?.room_number)), 'bed_id')
                                    // let bedItem_View = states.roomType ? states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor && item.room_number === states.roomType?.room_number)) :
                                    //     states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor))
                                    this.setState({
                                        bedData: states.roomType,
                                        BedList_filter: states.roomType?.bed_number,
                                    }, () => {
                                        let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_filter))
                                        let blockData = data?.block_data?.find(item => item?.ward_name === this.state.floorList_filter)
                                        let wardData = blockData?.ward_data?.find(item => item?.floor === this.state.roomList_filter)
                                        let roomData = wardData?.floor_data?.find(item => item?.bed_number === this.state.BedList_filter)

                                        wardData.floor_data = [roomData]
                                        blockData.ward_data = [wardData]
                                        data.block_data = [blockData]

                                        console.log(data);

                                        this.setState({ BedList_dup: [data] })
                                    })
                                } else {
                                    this.setState({
                                        bedData: [],
                                        BedList_filter: null,
                                        BedList_dup: dupBedListData
                                    })
                                }

                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Select Bed'}
                            />
                        )}
                    />
                </Box>
                <Box className='eMed_reserve_dropdown'>
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                BlockList: [],
                                FloorList: [],
                                roomList: [],
                                wardType: null,
                                blockType: null,
                                floorType: null,
                                roomType: null,
                                FilterOpen: false,
                                BedList_filter: [],
                                bedData: [],
                            }, () => {
                                this.reserseData()
                                this.setState({
                                    BedList: this.state.BedList_dup,
                                    Att_BedList: this.state.BedList_dup,
                                    BedList_dup: JSON.parse(this.state.dupBedList)
                                })
                            })
                        }}
                        id="eMed_Config_btn">{"Clear"}</Button>
                </Box>

            </Box>
        )
    }

    render() {
        return (
            <Box>
                <Modal open={true}>
                    <Box className="eMed_Reservation_Modal_main">
                        <Box component={'div'} className="eMed_Reservation_Modal_Header">
                            <Typography>{`${this.props?.ListType === "patient" ? "Patient" : "Attender"} Bed Availability List - ${formatDate(this.props?.AdmissionDate)}`}</Typography>
                            <Button onClick={() => { this.props.PopClose() }} >
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                            </Button>
                        </Box>
                        <Box>
                            {this.renderFilter()}
                        </Box>
                        <Paper className='eMed_Reserve_Table_Wrapper' >
                            {this.renderRoomData()}
                        </Paper>
                    </Box>
                </Modal>
            </Box>
        )
    }
}

import React, { Component } from 'react'
import '../Configuration.css';
import { Box, Button, Stack, Tooltip, IconButton } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { DataGrid } from '@mui/x-data-grid';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
class SurgeryTemplateConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      surgeryTemData: [],
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      deletedID: null,
    }
  }

  componentDidMount() {
    this.getSurgeryNoteDetails()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history?.location?.state?.deleteID !== this.state.deletedID && prevProps.history?.location?.state?.deleteID !== null && prevProps.history?.location?.state?.deleteID !== undefined) {
      this.setState({
        deletedID: prevProps.history?.location?.state?.deleteID
      }, () => {
        this.props.history.push({ state: { deleteID: null } })
        this.deleteSurgeryNoteDetails()
      })
    }
  }

  LoaderFunction = (key) => {
    this.setState({
        isLoader: key
    })
}

  getSurgeryNoteDetails = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.IP_TEMPLATE_SUR_NOTE)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              surgeryTemData: response.data ? response.data.data : []
            },()=>{this.LoaderFunction(false)})
          }
        }).catch(e => {
          this.LoaderFunction(false)
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  deleteSurgeryNoteDetails = () => {
    try {
      var states = this.state
      var selectId = "";
      states.surgeryTemData.map((item) => selectId = item.id)
      var ID = selectId ? selectId : states.deletedID
      RestAPIService.delete(Serviceurls.IP_TEMPLATE_SUR_NOTE + '?template_id=' + ID)
        .then(response => {
          if (response.data.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getSurgeryNoteDetails()
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  renderSurgeryTemTbl = () => {
    const { t } = this.props
    var states = this.state
    const columns = [
      {
        field: 'template_name', headerName: t('TemplateName'), flex: 0.25, headerClassName: 'eMed_Dis_Tem_TblHead',
        renderCell: (params) => (
          <div>
            {params?.row?.template_name ? params.row.template_name : '-'}
          </div>
        )
      },
      {
        field: 'layout_names', headerName: t('UsedLayout'), flex: 0.25, headerClassName: 'eMed_Dis_Tem_TblHead',
        renderCell: (params) => (
          <div>
            {params?.row?.layout_names ? params.row.layout_names : '-'}
          </div>
        )
      },
      {
        field: 'created_by', headerName: t('CreatedBy&Date'), flex: 0.25, headerClassName: 'eMed_Dis_Tem_TblHead',
        renderCell: (params) => (
          <div>
            {params?.row?.created_by && params?.row?.created_date ? params.row.created_by + ' On ' + params.row.created_date : '-'}
          </div>
        )
      },
      {
        field: 'modified_by', headerName: t('LastModifiedBy&Date'), flex: 0.25, headerClassName: 'eMed_Dis_Tem_TblHead',
        renderCell: (params) => (
          <div>
            {params?.row?.modified_by && params?.row?.modified_date ? params.row.modified_by + ' On ' + params.row.modified_date : '-'}
          </div>
        )
      },
      {
        field: 'action', headerName: t('Action'), flex: 0.10, headerClassName: 'eMed_Dis_Tem_TblHead', headerAlign: 'center', align: 'center',
        renderCell: (params) => (
          <Tooltip title={'Delete'} placement='top' arrow>
            <IconButton  disabled={!CheckAccessFunc("front_office", "Settings", "Template Configuration",  "Surgery Notes Template Configuration", "SubTab")?.editAccess} className='eMed_Dis_Sum_Tem_BtnGroup' onClick={() => { this.deleteSurgeryNoteDetails() }}>
              <img className='eMed_DisSum_Lay_TblDel' src={CheckAccessFunc("front_office", "Settings", "Template Configuration",  "Surgery Notes Template Configuration", "SubTab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} alt='Delete' />
            </IconButton>
          </Tooltip>
        )
      },
    ]
    return (
      <Box className='eMed_Dis_Tem_Tbl'>
        <DataGrid
          rows={states.surgeryTemData}
          columns={columns}
          getRowId={(row) => row['id']}
          pageSize={states.pageSize}
          page={states.page}
          rowsPerPageOptions={[10, 20, 30]}
          components={{
            Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          density={'Standard'}
          onCellClick={(params) => params.field === 'action' ? null : this.routeToLayout()}
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
          getRowClassName={(params) => params.row.appointment_status === "Cancelled" ? 'eMed_OP_CancelPatient' : null}
          loading={this.state.isLoader}
        />
      </Box>
    )
  }

  routeToLayout = () => {
    var states = this.state
    var selectId = "";
    states.surgeryTemData.map((item) => selectId = item.id)
    this.props.history.push({ pathname: '/Settings/TemplateConfig/SurgeryTemplateConfig/Add', state: { selectedID: selectId } })
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t } = this.props
    return (
      <Box Component='div' className='eMed_usrconf_container'>
        <Box className='eMed_usrconf_header'>
          <Button disabled={!CheckAccessFunc("front_office", "Settings", "Template Configuration",  "Surgery Notes Template Configuration", "SubTab")?.editAccess} variant='contained' onClick={() => { this.props.history.push('/Settings/TemplateConfig/SurgeryTemplateConfig/Add') }}>{t('AddNewTemplate')}</Button>
        </Box>
        <Box>
          {this.renderSurgeryTemTbl()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(SurgeryTemplateConfig);

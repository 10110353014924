import React, { Component } from "react";
import { Box, Button ,Stack, Typography, IconButton, Tooltip} from "@mui/material";
import './purchase.css';
import { ImagePaths } from '../../../Utility/ImagePaths';
import {  DataGrid } from '@mui/x-data-grid';
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { AmountFormat, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { CurrencySymbol } from "../../../Utility/Constants";

class VendorPayment extends Component{
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isViewClicked: false,
            pageSize: 10,
            current_page: 0,
            searchKey: '',
        }
    }
    componentDidMount() {
        this._getVendorPaymentList();
    }

    componentWillReceiveProps(props){
        if(props.search_key != this.state.searchKey){
            this.setState({
                searchKey: props.search_key
            },() => {
                this._getVendorPaymentList()
            })
        }
    }
    LoaderFunction=(key)=>{
        this.setState({
            isLoader:key
        })
     }

    _getVendorPaymentList = () =>{
        this.LoaderFunction(true)
        this.setState({
            data: [],
            pageSize:10,
            current_page:0
        })
        RestAPIService.getAll(`${Serviceurls.LAB_VENDOR_PAYMENT_LIST}?search=${this.state.searchKey}`)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        data: response.data.data,
                    },()=>{this.LoaderFunction(false)});
                }
            })
            .catch(error => {
                this.LoaderFunction(false)
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }

    SuccessErrorBox = (message,type) => {
        this.props.responseSuccessErrorMessage(message,type)
    }

    handleView = (event,values) => {
        this.setState({
            isViewClicked: true
        },()=>{this.props.goBack("vendorMonthly" , values.row.vendor_id)})
    }

    handleCellClick = (param, event) => { event.stopPropagation() }

    getVendorPaymentexcel = () => {
        try {
          RestAPIService.excelGet(Serviceurls.LAB_VENDOR_PAYMENT_LIST + "?export_type=excel" + "&search_key=" + this.state.searchKey).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              }
            }).catch((error) => {
                this.SuccessErrorBox(error.response.data.message, false);
            })
        } catch (error) {
            this.SuccessErrorBox(error.message, false);
        }
      }

    render() {
        const {t} = this.props
        let {data, pageSize, current_page} = this.state
        const columns = [
            { field: 's.no', headerClassName: 'grid--header', headerName: t('SNo'), flex: 0.04, sortable: false, hideable: false,renderCell: (index) => index.api.getRowIndex(index.row.vendor_id) + 1},
            { field: 'vendor_name', headerClassName: 'grid--header', headerName: t('VendorName'), flex: 0.142, sortable: false,
            renderCell:(params)=> (<div>{params?.row?.vendor_name?.length > 20 ? <Tooltip placement="top" title={params?.row?.vendor_name} arrow>{params?.row?.vendor_name.slice(0,20)+"..."}</Tooltip> : params?.row?.vendor_name ? params?.row?.vendor_name : "-"}</div>)},
            { field: 'vendor_gstin_number', headerClassName: 'grid--header', headerAlign:"center", headerName: t('GSTNumber'), flex: 0.142, sortable: false, align:"center",
            renderCell:(params)=> (<div>{params?.row?.vendor_gstin_number?.length > 20 ? <Tooltip placement="top" title={params?.row?.vendor_gstin_number} arrow>{params?.row?.vendor_gstin_number.slice(0,20)+"..."}</Tooltip> : params?.row?.vendor_gstin_number ? params?.row?.vendor_gstin_number : "-"}</div>)},
            { field: 'total_po_count', headerClassName: 'grid--header', headerName: t('TotalPO'), flex: 0.1, sortable: false, type:"number",
            renderCell:(params)=> (<div>{params?.row?.total_po_count?.length > 20 ? <Tooltip placement="top" title={params?.row?.total_po_count} arrow>{params?.row?.total_po_count.slice(0,20)+"..."}</Tooltip> : params?.row?.total_po_count ? params?.row?.total_po_count : "-"}</div>)},
            { field: 'total_bill_amount', headerClassName: 'grid--header', headerName: t("BillAmount")+ `(${CurrencySymbol})`, flex: 0.142, sortable: false, type:"number",
            renderCell:(params)=> (<div>{params?.row?.total_bill_amount?.length > 20 ? <Tooltip placement="top" title={params?.row?.total_bill_amount} arrow>{params?.row?.total_bill_amount.slice(0,20)+"..."}</Tooltip> : params?.row?.total_bill_amount ? AmountFormat(params?.row?.total_bill_amount).replace(`${CurrencySymbol}`,"") : "-"}</div>)},
            { field: 'total_paid_amount', headerClassName: 'grid--header', headerName: t("PaidAmount") + `(${CurrencySymbol})`, flex: 0.125, sortable: false, type:"number", renderCell: ({row}) =>(<div>{row?.total_paid_amount ? AmountFormat(row?.total_paid_amount).replace(`${CurrencySymbol}`,"") : "-"}</div>)},
            { field: 'total_balance_amount', headerClassName: 'grid--header', headerName: t("OutStandingAmount") + `(${CurrencySymbol})`, flex: 0.142, sortable: false , type:"number",renderCell: (cellvalues)=>{
                let total_balance_amount = AmountFormat(cellvalues.row["total_balance_amount"])
                return(
                        <Typography sx={{ color: cellvalues.row["total_balance_amount"] == 0 ? "#1E972A" : "#B82121" }}>
                            {total_balance_amount.replace(`${CurrencySymbol}`,"")}
                        </Typography>
                );
            }},
            { field: 'actions', headerClassName: 'grid--header', headerName: t('Action'),type :'actions', flex: 0.125, sortable: false, hideable: false,
           renderCell : (cellvalues)=>{
            return(
                <Tooltip title={t("Payback")} placement="top" arrow>
                    <IconButton disabled={!CheckAccessFunc("Laboratory", "Purchase", "Vendor Payment", null, "Tab")?.editAccess} size="small" sx={{ borderRadius:"0.3vw"}} onClick = {(event)=>{this.handleView(event,cellvalues)}} ><img className="emedhub_vendor_img2" src={ImagePaths.Wallet.default} alt={"Wallet"} /></IconButton>
                </Tooltip>
            );
           }}
        ]
        return (
            <Box className = "emedhub__purchase_page" sx={{'& .grid--header': {backgroundColor: '#FAF9F9'}}}>
                <Stack sx={{margin: "1vw"}} spacing={2} direction="row"  justifyContent="flex-end" alignItems="center">
                    {/* <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabPrintIcon.default} alt='print' /></IconButton> */}
                    <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabUploadButton.default} onClick={()=>this.getVendorPaymentexcel()} alt='upload' /></IconButton>
                </Stack>
                <div id = "emedhub_ventor_Table">
                <DataGrid 
                        rows={data}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.vendor_id}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: ()=>{
                                return(
                                    <Stack direction="row" justifyContent = "center" alignItems = "center" sx={{height:"53vh"}}>
                                    {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                          }}
                          localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                          }}
                        onCellClick = {this.handleCellClick}
                        onRowClick = {this.handleCellClick}
                        rowCount={data.length}
                        page={current_page}
                        loading={this.state.isLoader}
                        onPageChange={(newPage)=>this.setState({ current_page: newPage })}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination />
                </div>
            </Box>
        )
    }
}

export default withTranslation()(VendorPayment);

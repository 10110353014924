import { Box, Button, Collapse, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import './IpPatientBillDetails.css';
import { Colors } from '../../../../../Styles/Colors';
import { CommonPatientCard, CommonPatientDetails } from '../../../../../Components/Common Components/CommonComponents';
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import { AmountFormat, CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../../../Components/Loader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChangePaymentMode from '../../../BillingTransaction/ChangePaymentMode';
import { CurrencySymbol } from '../../../../../Utility/Constants';

class IpPatientSavedBill extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ipPatientData: {},
            ipPatientReceiptBill: [],
            service_amount: "",
            attender_mobile: "",
            attender_name: "",
            paid_amount: "",
            balance_amount: "",
            advance_amount: "",
            ApproxAmount: 0,
            selectedIndex: null,
            ipSavedBillId: '',
            ApprovedAmt: 0,
            withOutAppointment: {},
        }
    }
    componentDidMount() {
        let ipPatientData = getCachevalue("IppatientData")
        let patientData = JSON.parse(ipPatientData)
        let appointmentDetail =  JSON.parse(getCachevalue("withoutAppointment"));
        this.setState({
            ipPatientData: patientData,
            withOutAppointment: appointmentDetail?.patient_id === patientData?.patient_id ? appointmentDetail : {}
        }, () => {
            this.getPatientData()
            this.getApproxCharges()
            this.getIpPatientReceiptBillDetails()
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getPatientData = () => {
        try {
            const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
            RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + admission_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            service_amount: response.data.data[0].service_amount,
                            balance_amount: response.data.data[0].balance_amount,
                            paid_amount: response.data.data[0].paid_amount,
                            advance_amount: response.data.data[0].advance_amount,
                            attender_mobile: response.data.data[0].attender_mobile,
                            attender_name: response.data.data[0].attender_name,
                            ApprovedAmt: response.data.data[0].claimed_amount,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getApproxCharges = () => {
        try {
            const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
            RestAPIService.getAll(Serviceurls.IP_APPROX_AMOUNT + "?ip_admission_id=" + admission_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ApproxAmount: response.data.data?.approx_room_charge
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getIpPatientReceiptBillDetails = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_PATIENT_INDIVIDUAL_RECEIPT_Bill + "?patient_id=" + this.state.ipPatientData?.patient_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ipPatientReceiptBill: response.data.data
                        }, ()=>{ this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getIpPatientReceiptPrint = () => {
        try {
            RestAPIService.getAll(Serviceurls.RECEIPT_INDIV_PRINT + "?receipt_id=" + this.state.ipBillId).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderTable = () => {
        const { t } = this.props
        return (
            <TableContainer component={'div'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' />
                            <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Admission And Date")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{t("NoOfBill")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{overflow:'scroll'}}>
                        {this.state.ipPatientReceiptBill?.length > 0 ? this.state.ipPatientReceiptBill.map((item, index) => (
                            <><TableRow
                                sx={{ backgroundColor: this.state.selectedIndex === index ? Colors?.ThemeLightColor : 'white', cursor: "pointer" }}
                                key={index}
                                onClick={() => {
                                    this.setState({
                                        selectedIndex: this.state.selectedIndex === index ? null : index
                                    })
                                }}>
                                <TableCell className='eMed_Pat_Tbl_one_style'>
                                    <IconButton
                                        size='small'
                                        onClick={() => {
                                            this.setState({
                                                selectedIndex: this.state.selectedIndex === index ? null : index
                                            })
                                        }}
                                    >
                                        {this.state.selectedIndex === index ?
                                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style'>{`${item?.ip_number ? item?.ip_number : "-"}
                                     | ${item?.admission_date ? item?.admission_date : "-"}`}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item?.no_of_bills ? item?.no_of_bills : "-"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.total_bill_amount ? AmountFormat(item?.total_bill_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</TableCell>
                            </TableRow>
                                <TableRow>
                                    {this.renderTableOne(item, index)}
                                </TableRow></>
                        )) : <TableRow>
                            <TableCell />
                            <TableCell /><TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                            <TableCell />
                            
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    MenuItem = (data) => {
        const { t } = this.props
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let PaymentChangeAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.change_payment_mode ? RoleData?.permission_access?.common?.change_payment_mode : false) : true
        const multi_option = [
            { value: "ChangePaymentMode", label: t("Change Payment Mode") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} 
                            disabled={option.value === "ChangePaymentMode" ? ((data?.amount_received && PaymentChangeAccess) ? false : true) : false}
                            onClick={() => { 
                                this.setState({ anchorEl: null }, ()=>{
                                    if(option.value === "ChangePaymentMode"){
                                        data.patient_age = this.state.ipPatientData?.patient_age
                                        data.patient_uhid = this.state.ipPatientData?.patient_account_number
                                        data.mobile_number = this.state.ipPatientData?.patient__mobile_number
                                        this.setState({ ChangePaymentPopup : true, SelectedBill: data })
                                    }
                                }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderTableOne = (item, index) => {
        const { t } = this.props
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedIndex === index}
                    timeout={'auto'}
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two' sx={{overflow:"scroll"}}>
                        <Table>
                            <TableHead sx={{ backgroundColor: Colors.Background }}>
                                <TableRow>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Receipt Number")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("PaymentModeType")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Receipt Type")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Paid In")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("CreatedBy")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Description")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalPaidAmount")} (${CurrencySymbol})`}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.bill_detail?.length > 0 ? item?.bill_detail.map((subItem, subIndex) => {
                                    return (
                                        <TableRow key={subIndex}>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.receipt_date ? subItem.receipt_date : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.receipt_number ? subItem.receipt_number : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.payment_mode_types ? subItem?.payment_mode_types : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.receipt_for ? subItem.receipt_for : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.collected_in ? subItem.collected_in : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.created_by ? subItem.created_by : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{subItem?.description ?
                                                subItem?.description ? subItem?.description?.length > 30 ?
                                                    <Tooltip placement='top' title={subItem?.description} arrow>
                                                        <div style={{ fontSize : "0.875rem"}}>{subItem?.description.slice(0, 28) + "..."}</div></Tooltip> :
                                                    <div style={{ fontSize : "0.875rem"}}>{subItem?.description}</div> : "-"
                                                : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{subItem?.amount_received ? AmountFormat((subItem?.receipt_for === "Cancelled" || subItem?.receipt_for === "Refund" || subItem?.receipt_for === "Advance refund") ? (subItem.amount_received * -1) : subItem.amount_received)?.replace(`${CurrencySymbol}`, "") : '0'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'  sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                <Button disabled={this.state.withOutAppointment?.is_op} className='eMed_Pat_tbl_two_btn'>
                                                    <Tooltip placement='top' title={'Print'} arrow>
                                                        <img
                                                            onClick={() => {
                                                                this.setState({
                                                                    ipBillId: subItem?.id
                                                                }
                                                                    , () => { this.getIpPatientReceiptPrint() }
                                                                )
                                                            }}
                                                            src={ImagePaths.PrintIcons.default} className='eMed_Pat_Bill_img' alt='credit' />
                                                    </Tooltip>
                                                </Button>
                                                {getCachevalue("isForCancelBill") === "true" ? null : this.MenuItem(subItem, subIndex)}
                                            </TableCell>
                                        </TableRow>)
                                }) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell />
                                    <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                                    <TableCell /><TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>

                </Collapse>
            </TableCell>
            </>
        )
    }

    PaymentModeChanged(message) {
        this.setState({
            ChangePaymentPopup : false,
        }, () => {
            this.successMessage(message)
            this.getIpPatientReceiptBillDetails()
        })
    }

    ClosePopUp() {
        this.setState({
            creditBillPopUp: false,
            ChangePaymentPopup: false,
        })
    }

    render() {
        const { t } = this.props
        let serviceAmt = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("BillAmount"),
            lineTwo: this.state.service_amount ? AmountFormat(this.state.service_amount) : `${CurrencySymbol} 0`
        }
        let PaidDetails = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("Paid Amount"),
            lineTwo: this.state.paid_amount ? `${AmountFormat(this.state.paid_amount)}` : `${CurrencySymbol} 0`
        }

        let PendingDetails = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("BalAmt"),
            lineTwo: this.state.balance_amount ? AmountFormat(this.state.balance_amount) : `${CurrencySymbol} 0`,
            colorCode: this.state.balance_amount > 0 ? 'green' : this.state.balance_amount == 0 ? "balck" : 'red'
        }

        let ApproxDetails = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t('ApproxAmount'),
            lineTwo: this.state.ApproxAmount ? `${AmountFormat(this.state.ApproxAmount)}` : `${CurrencySymbol} 0`
        }
        let AdvanceDetails = {
            image:  CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t('AdvanceAmount'),
            lineTwo: this.state.advance_amount ? `${AmountFormat(this.state.advance_amount)}` : `${CurrencySymbol} 0`
        }
        let attender = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
        }
        let ClaimDetails = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            width: "1.3vw",
            height: "1.3vw",
            lineOne: t('Claimed Amount'),
            lineTwo: `${this.state.ApprovedAmt ? AmountFormat(this.state.ApprovedAmt) : `${CurrencySymbol} 0`}`
          }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: "74vh" }}>
                <Box component={'div'} className='' height={'9.5vh'}>
                    <Box component={'div'} >
                        <Box component={'div'} display={'flex'}>
                            <Box component={'div'} className='eMed_nur_notes_details eMed_bill_header_one'>
                                <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                            </Box>
                            {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
                            <CommonPatientCard details={AdvanceDetails}/>
                            <CommonPatientCard details={serviceAmt} showOnecard={true} smallWidth={true}/>
                            <CommonPatientCard details={PaidDetails} showOnecard={true} smallWidth={true}/>
                            <CommonPatientCard details={PendingDetails} showOnecard={true} smallWidth={true}/>
                            <CommonPatientCard details={ApproxDetails} smallWidth={true}/>
                            <CommonPatientCard details={ClaimDetails} smallWidth={true} />
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'}>
                    <Box component={'div'} bgcolor={"white"} border={"1px solid lightgray"} margin={'0.2vw 0.8vw'} height={"64vh"} overflow={'scroll'}>
                        {this.renderTable()}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                    <Loader loaderOpen={this.state.isLoader} />
                    {
                    this.state.ChangePaymentPopup ? <ChangePaymentMode BillData={this.state.SelectedBill} PaymentModeChanged={this.PaymentModeChanged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
                }
            </Box>
        )
    }
}

export default withTranslation()(IpPatientSavedBill)
import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { AmountFormat, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../../../Utility/Constants';

class ProductWiseSales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ProductWiseData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days": null,
                "brandName": '',
                "drugId": '',
                "dosageType": [],
            },
            pageName: 'Product Wise Sales',
            filterOpen: false,
            noofQty: 0,
            sales_amount: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getProductwiseReportData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
            page: 0,
        }, () => { this.getProductwiseReportData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    onChangetrigger(value) {
        this.state.filterDatas.brandName = value
        this.setState({
            filterDatas: this.state.filterDatas
        }, () => {
                this.getProductwiseReportData();
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} searchHolder={"Search Brand Name"} onChangetrigger={this.onChangetrigger.bind(this)} value={this.state.filterDatas.brandName} onChangingSearch={true} />
            </Box>

        )
    }

    getProductwiseReportData = () => {
        try {
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.PHARMA_PRODUCT_WISE_SALE}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${brandName}&drug_ids=${this.state.filterDatas.drugId}&dosage_type=${this.state.filterDatas.dosageType}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            ProductWiseData: response.data.data?.result,
                            noofQty: response.data.data?.overall_sales_quanty,
                            sales_amount: response.data.data?.overall_sales_amount,
                            rowCount: response.data.data?.total_count
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getProductwiseExcel = () => {
        try {
            this.setState({disableBtn: true})
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            RestAPIService.excelGet(`${Serviceurls.PHARMA_PRODUCT_WISE_SALE}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${brandName}&drug_ids=${this.state.filterDatas.drugId}&dosage_type=${this.state.filterDatas.dosageType}&export_type=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({disableBtn: false})
        }
    }
    PurchaeWiseSalesPrint = () => {
        try {
            this.setState({disabled: true})
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            RestAPIService.getAll(`${Serviceurls.PHARMA_PRODUCT_WISE_SALE}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${brandName}&drug_ids=${this.state.filterDatas.drugId}&dosage_type=${this.state.filterDatas.dosageType}&export_type=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disabled: false})
            }).catch((error) => {
              if(error?.response?.data?.message){
                this.errorMessage(error?.response?.data?.message)
              }else{
                this.errorMessage(error.message)
              }
              this.setState({disabled: false})
            })
        } catch (error) {
          this.errorMessage(error.message)
            this.setState({disabled: false})
        }
      }


    render() {
        const { t } = this.props
        this.state.ProductWiseData.forEach((element, index) => {
            let page = this.state.page
            let pageSize = this.state.pageSize
            let serial = (page * pageSize) + (index + 1)
            element.sno = serial
        })

        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.05 },
            { field: 'drug_id', headerName: t("Drug ID"), sortable: false, flex: 0.120, headerAlign: "center", align: "center" },
            {
                field: 'drug_name', headerName: t('BrandName'), sortable: false, flex: 0.145,
                renderCell: (params) => (<div>{params?.row?.drug_name?.length > 14 ?
                    <Tooltip placement="top" title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name.slice(0, 10) + "..."}</div></Tooltip>
                    : params?.row?.drug_name ? params?.row?.drug_name : "-"}</div>)
            },
            {
                field: 'generic_name', headerName: t('GenericName'), sortable: false, flex: 0.145,
                renderCell: (params) => (<div>{params?.row?.generic_name?.length > 14 ?
                    <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 10) + "..."}</div></Tooltip>
                    : params?.row?.generic_name ? params?.row?.generic_name : "-"}</div>)
            },
            {
                field: 'dosage_type', headerName: t('DosageType'), sortable: false, flex: 0.130,
                renderCell: (params) => <div>{params?.row?.dosage_type?.length > 14 ?
                    <Tooltip placement="top" title={params?.row?.dosage_type} arrow><div>{params?.row?.dosage_type.slice(0, 10) + "..."}</div></Tooltip>
                    : params?.row?.dosage_type ? params?.row?.dosage_type : "-"}</div>
            },
            {
                field: 'drug_strength', headerName: t('DosageStrength'), sortable: false, flex: 0.125, headerAlign: "center", align: "center",
                renderCell: (params) => <div>{params?.row?.drug_strength?.length > 14 ?
                    <Tooltip placement="top" title={params?.row?.drug_strength} arrow><div>{params?.row?.drug_strength.slice(0, 10) + "..."}</div></Tooltip>
                    : params?.row?.drug_strength ? params?.row?.drug_strength : "-"}</div>
            },
            {
                field: 'stock_in_quantity', headerName: t('AvailableQuantity'), type: "number", sortable: false, flex: 0.125,
                renderCell: (params) => <div>{params?.row?.stock_in_quantity?.length > 14 ?
                    <Tooltip placement="top" title={params?.row?.stock_in_quantity} arrow><div>{params?.row?.stock_in_quantity.slice(0, 10) + "..."}</div></Tooltip>
                    : params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity : "0"}</div>
            },
            {
                field: 'sales_quanty', headerName: t('Sales Quantity'), type: "number", sortable: false, flex: 0.125,
                renderCell: (params) => <div>{params?.row?.sales_quanty ? params?.row?.sales_quanty : "0"}</div>
            },
            {
                field: 'drug_amount', headerName: t(`Sales Amount (${CurrencySymbol})`), type: "number", sortable: false, flex: 0.125,
                renderCell: (params) => <div>{params?.row?.drug_amount ? AmountFormat(params?.row?.drug_amount).replace(`${CurrencySymbol}`, "") : "0"}</div>
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("No Of Quantity", this.state.noofQty, true, "white", false, null, 'rpt_productWise_qty')}
                        {AmountsCard("Net Amount", this.state.sales_amount, false, "white", false, null, 'rpt_productWise_sale_amt')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_productWise_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid ="eMed_Pharma_rts_btn" disabled={this.state.disabled}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt="print" height={'2.3vw'} width ={'2.3vw'} onClick={()=>this.PurchaeWiseSalesPrint()}/>
                        </Button>
                        <Button emed_tid="rpt_productWise_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getProductwiseExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.ProductWiseData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getProductwiseReportData()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getProductwiseReportData())}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        paginationMode='server'
                        loading={this.state.isLoader}
                        filterMode="server"
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(ProductWiseSales)
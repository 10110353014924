import { Box} from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import PharmaNoMovement from './PharmaNoMovement';

class SlowMovementDrug extends Component {
    render() {
        return (
            <Box><PharmaNoMovement history={this.props?.history} SlowMovement={true} /></Box>
        )
    }
}

export default withTranslation()(SlowMovementDrug)
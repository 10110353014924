import React, { Component } from 'react'
import { Box, Button, ButtonGroup, Drawer, Stack, Tooltip, Typography } from '@mui/material'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import PharmaFilter from '../PharmaFilter';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';

class ProfitAnalysis extends Component {

    constructor(props) {
        super(props)
        this.state = {
            profitData: [],
            page: 0,
            pageSize: 10,
            filterOpen: false,
            isLoader: false,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days": 30,
                'drugName': '',
                'isAccrual': false,
                'withGstCheck':false
            },
            pageName: 'profitAnalysis',
            mode_type: 'sales',
            TotalPurQty: 0,
            TotalSalesQty: 0,
            TotalProfvalue: 0,
            TotalReturnvalue: 0
        }
    }

    componentDidMount() {
        this.getProfitAnalysisData()
    }

    getProfitAnalysisData = () => {
        try {
            let brandName = this.state.filterDatas.drugName ? this.state.filterDatas.drugName.replaceAll('&','%26') : ''
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.SALES_REPORTS_PROFITANALYSIS}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&drug_name=${brandName}&mode=${this.state.mode_type}&exclude_gst=${this.state.filterDatas.withGstCheck}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            profitData: response?.data?.data ? response.data.data : [],
                            TotalPurQty : response?.data?.total_purchase_amt ? response.data.total_purchase_amt : 0,
                            TotalSalesQty : response?.data?.total_sale_amt ? response.data.total_sale_amt : 0,
                            TotalProfvalue : response?.data?.total_profit_amt ? response.data.total_profit_amt : 0,
                            TotalReturnvalue: response?.data?.total_return_amount ? response.data.total_return_amount : 0
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response?.data?.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
        }
    }

    getProfitAnalysisPrint = () => {
        try {
            this.setState({disableBtn: true})
            let brandName = this.state.filterDatas.drugName ? this.state.filterDatas.drugName.replaceAll('&','%26') : ''
            RestAPIService.getAll(`${Serviceurls.SALES_REPORTS_PROFITANALYSIS}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&drug_name=${brandName}&mode=${this.state.mode_type}&exclude_gst=${this.state.filterDatas.withGstCheck}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getProfitAnalysisData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ filterOpen: false })
    }

    render() {
        const { t } = this.props
        this.state.profitData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.05, density: 'compact' },
            {
                field: 'brand_name', headerName: t("Drug Details"), flex: 0.15, density: 'compact',
                renderCell: ({ row }) => {
                    let brandName = row?.brand_name ? row?.brand_name : '-';
                    let genericName = row?.generic_name ? row?.generic_name : '-';
                    let dosageType = row?.dosage_type ? row?.dosage_type : '-';
                    let hsnCode = row?.hsn_code ? row?.hsn_code : '-';
                    return (<Box component={'div'}>
                        <Box component={'div'}>
                            <Box component={'div'}>
                                {(brandName?.length) > 20 ?
                                    <Tooltip title={brandName} placement={'top'} arrow>
                                        <Typography id='eMed_Com_Patient_Name'>{(brandName ? ((brandName?.slice(0, 20) + '...')) : '-')}</Typography>
                                    </Tooltip> : <Typography id='eMed_Com_Patient_Name'>{(brandName ? brandName : '-')}</Typography>}
                            </Box>
                            {genericName?.length > 13 || dosageType?.length > 13 ?
                                <Box display={"flex"}>
                                    <Tooltip placement="top" title={genericName}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{genericName.slice(0, 13) + '...'}</Typography></Tooltip> |
                                    <Tooltip placement="top" title={dosageType}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{dosageType.slice(0, 13)}</Typography></Tooltip>
                                </Box> :
                                <Typography id='eMed_Com_Patient_UhidText' component={'div'}>{`${genericName} | ${dosageType}`}</Typography>}
                            <Typography id='eMed_Com_Patient_UhidText'>{"HSN: " + (hsnCode ? hsnCode : '-')}</Typography>
                        </Box>
                    </Box>)
                }
            },
            {
                field: 'batch_no', headerName: t("Batch No"), flex: 0.13 ,headerAlign: "center", align: "center", density: 'compact',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.batch_no ? (params?.row?.batch_no?.length > 16 ? 
                <Tooltip placement="top" title={params?.row?.batch_no}><div>{params?.row?.batch_no.slice(0, 16)}</div></Tooltip> : params?.row?.batch_no) : '-'}
                </Box>)
            },
            {
                field: this.state.mode_type === 'accural' ? 'purchase_qty' : 'sales_qty', headerName: this.state.mode_type ==='accural' ? "Purchase Qty" : "Sales Qty", flex: 0.13, type: "number", density: 'compact',
                renderCell: (params) => (<Box component={'div'}> {this.state.mode_type ==='accural' ? (params?.row?.purchase_qty ? params?.row?.purchase_qty : '0') : (params?.row?.sales_qty ? params?.row?.sales_qty : '0')}</Box>)
            },
            {
                field: 'purchase_value_per_unit', headerName: t("Purchase Price / Qty"), flex: 0.13, type: "number", density: 'compact',
                renderCell: (params) => (<Box>{params?.row?.purchase_value_per_unit ? params?.row?.purchase_value_per_unit : '0'}</Box>)
            },
            {
                field: 'sales_value_per_unit', headerName: this.state.mode_type === 'accural' ? "MRP Price / Qty" : "Sales Price / Qty", flex: 0.13, type: "number", density: 'compact',
                renderCell: (params) => (<Box>{params?.row?.sales_value_per_unit ? params?.row?.sales_value_per_unit : '0'}</Box>)
            },
            {
                field: 'profit_value', headerName: t("Profit Margin Value"), flex: 0.13, type: "number", density: 'compact',
                renderCell: (params) => (<Box>{params?.row?.profit_value ? AmountFormat(params?.row?.profit_value).replace(`${CurrencySymbol}`, "") : '0'}</Box>)
            },
            {
                field: 'profit_percentage', headerName: t("Profit Margin (%)"), flex: 0.13, type: "number", density: 'compact',
                renderCell: (params) => (<Box>{(params.row?.profit_percentage) ? params.row?.profit_percentage : '0'}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        <Box component={'div'} flex={0.8} display={'flex'}>
                            {AmountsCard("Total Purchase Value", this.state.TotalPurQty, false,"white",false,null,"Total_pur_qty_testID")}
                            {AmountsCard(this.state.mode_type === "sales" ? "Total Sales value" : "Total MRP value", this.state.TotalSalesQty, false,"white",false,null,"Total_sal_price_testID")}
                            {AmountsCard("Total Profit Margin", this.state.TotalProfvalue, false,"white",false,null,"Total_pro_mar_testID")}
                            {AmountsCard("Total Return value", this.state.TotalReturnvalue, false,"white",false,null,"Total_pro_rtn_testID")}
                        </Box>
                    </Box>

                    <Box flex={0.8} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <ButtonGroup sx={{marginRight:'1vw'}}>
                            <Button variant={this.state.mode_type === "sales" ? 'contained' : 'outlined'} sx={{ width: "8.5vw", textTransform: 'capitalize' }} onClick={() => {
                                this.state.filterDatas.withGstCheck = false
                                this.setState({ mode_type: "sales",filterDatas:this.state.filterDatas },()=>{this.getProfitAnalysisData()}) }}>{t("Sales Wise")}</Button>
                            <Button variant={this.state.mode_type === "accural" ? 'contained' : 'outlined'} sx={{ width: "8.5vw", textTransform: 'capitalize' }} onClick={() => { 
                                this.state.filterDatas.withGstCheck = false
                                this.setState({ mode_type: "accural",filterDatas:this.state.filterDatas },()=>{this.getProfitAnalysisData()}) }}>{t("Accrual Wise")}</Button>
                        </ButtonGroup>
                        <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_saleOver_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => { this.getProfitAnalysisPrint() }}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.profitData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        density={'compact'}
                        rowHeight={100}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    <PharmaFilter
                        pageName={this.state.pageName}
                        filterDatas={this.state.filterDatas}
                        closeFilter={this.ClosePopUp.bind(this)}
                        filteredData={this.renderFilter.bind(this)}
                        ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(ProfitAnalysis)
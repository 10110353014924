import { Box, Button, Drawer, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { withTranslation } from 'react-i18next'
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { formatDateAndTimeMeth2 } from "../../../Components/CommonFunctions/CommonFunctions";
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ImagePaths } from "../../../Utility/ImagePaths";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import AssetFilter from "../Filter/AssetFilter";
import { DateTime } from "luxon";
import { Colors } from "../../../Styles/Colors";


class AssetTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetTransferData: [],
            page: 0,
            pageSize: 10,
            FilterOpen: false,
            filterDatas: {
                "fromDate": null,
                "toDate": null,
                "dateIndex": 1,
                'assetTagNo': '',
                'selectedCategory': null,
                'selectedSubCategory': null,
                'desSelectedBlock': null,
                'desSelectedFloor': null,
                'desSelectedSpot': null
            },
            isLoader: false,
            rowCount: 0
        }
    }

    componentDidMount = () => {
        this.getAssetTransfer()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getAssetTransfer = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let block = states.filterDatas.desSelectedBlock ? states.filterDatas.desSelectedBlock?.block_name : ''
            let floor = states.filterDatas.desSelectedFloor ? states.filterDatas.desSelectedFloor?.floor_name : ''
            let spot = states.filterDatas.desSelectedSpot ? states.filterDatas.desSelectedSpot?.spot_name : ''
            let tfrFrom = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let tfrTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.ASSET_TRANSFER_GET_POST +
                '?asset_no=' + assetNo +
                '&category_id=' + category +
                '&sub_category_id=' + subCat +
                '&block=' + block +
                '&floor=' + floor +
                '&spot=' + spot +
                '&transfer_from_date=' + tfrFrom +
                '&transfer_to_date=' + tfrTo +
                '&page_no=' + (states.page + 1) +
                '&page_size=' + states.pageSize
            ).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            assetTransferData: response.data.data,
                            isLoader: false,
                            rowCount: response.data.total_count
                        })
                    }
                }).catch((error) => {
                    this.setState({ isLoader: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.setState({ isLoader: false })
            this.errorMessage(e.message)
        }
    }

    getAssetTransferPrint = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let block = states.filterDatas.desSelectedBlock ? states.filterDatas.desSelectedBlock?.block_name : ''
            let floor = states.filterDatas.desSelectedFloor ? states.filterDatas.desSelectedFloor?.floor_name : ''
            let spot = states.filterDatas.desSelectedSpot ? states.filterDatas.desSelectedSpot?.spot_name : ''
            let tfrFrom = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let tfrTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            RestAPIService.getAll(Serviceurls.ASSET_TRANSFER_GET_POST +
                '?asset_no=' + assetNo +
                '&category_id=' + category +
                '&sub_category_id=' + subCat +
                '&block=' + block +
                '&floor=' + floor +
                '&spot=' + spot +
                '&transfer_from_date=' + tfrFrom +
                '&transfer_to_date=' + tfrTo +
                "&export_type=pdf").
                then((response) => {
                    if (response) {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getAssetTransferExcel = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let block = states.filterDatas.desSelectedBlock ? states.filterDatas.desSelectedBlock?.block_name : ''
            let floor = states.filterDatas.desSelectedFloor ? states.filterDatas.desSelectedFloor?.floor_name : ''
            let spot = states.filterDatas.desSelectedSpot ? states.filterDatas.desSelectedSpot?.spot_name : ''
            let tfrFrom = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let tfrTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            RestAPIService.excelGet(Serviceurls.ASSET_TRANSFER_GET_POST +
                '?asset_no=' + assetNo +
                '&category_id=' + category +
                '&sub_category_id=' + subCat +
                '&block=' + block +
                '&floor=' + floor +
                '&spot=' + spot +
                '&transfer_from_date=' + tfrFrom +
                '&transfer_to_date=' + tfrTo +
                "&export_type=excel").
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error?.response.data?.message) {
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            CreditBillPopUp: false
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.getAssetTransfer()
            }
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            FilterOpen: false,
        }, () => { this.getAssetTransfer() })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    render() {
        this.state.assetTransferData?.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.01, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "transfer_date", headerName: t("Date and Time"), flex: 0.18, align: "center", headerAlign: "center",
                renderCell: (params) => {
                    let tfrDate = formatDateAndTimeMeth2(params?.row?.transfer_date)
                    return (
                        <Box component={'div'}>
                            {tfrDate ? tfrDate?.length > 23 ?
                                <Tooltip placement="top" title={tfrDate}><Typography fontSize={'0.9vw'}>{tfrDate?.slice(0, 23) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{tfrDate}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "category_name", headerName: t("Category"), flex: 0.12, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let catName = params?.row?.category_name
                    return (
                        <Box component={'div'}>
                            {catName ? catName?.length > 15 ?
                                <Tooltip placement="top" title={catName}><Typography fontSize={'0.9vw'}>{catName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{catName}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "sub_category_name", headerName: t("Sub-Category"), flex: 0.12, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let subCat = params?.row?.sub_category_name
                    return (
                        <Box component={'div'}>
                            {subCat ? subCat?.length > 15 ?
                                <Tooltip placement="top" title={subCat}><Typography fontSize={'0.9vw'}>{subCat?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{subCat}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "asset_no", headerName: t("Asset/Tag No"), flex: 0.12, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let assetNo = params?.row?.asset_no
                    return (
                        <Box component={'div'}>
                            {assetNo ? assetNo?.length > 15 ?
                                <Tooltip placement="top" title={assetNo}><Typography fontSize={'0.9vw'}>{assetNo?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{assetNo}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "source_detail", headerName: t("Source Details"), flex: 0.12, headerAlign: "center", align: "Left",
                renderCell: (params) => {
                    let block = params?.row?.source_detail?.block_name
                    let floorSpot = `${params?.row?.source_detail?.floor_name} | ${params?.row?.source_detail?.spot_name}`
                    return (
                        <Box component={'div'}>
                            {block ? block?.length > 15 ?
                                <Tooltip placement="top" title={block}><Typography fontSize={'0.9vw'} fontWeight={600}>{block?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{block}</Typography> : '-'}
                            {floorSpot ? floorSpot?.length > 15 ?
                                <Tooltip placement="top" title={floorSpot}><Typography fontSize={'0.9vw'} color={Colors.SecondaryText}>{floorSpot?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} color={Colors.SecondaryText}>{floorSpot}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "transfer_employee_name", headerName: t("Transfer by"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let tfrEmp = params?.row?.transfer_employee_name
                    return (
                        <Box component={'div'}>
                            {tfrEmp ? tfrEmp?.length > 15 ?
                                <Tooltip placement="top" title={tfrEmp}><Typography fontSize={'0.9vw'}>{tfrEmp?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{tfrEmp}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "designation_detail", headerName: t("Destination Details"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let block = params?.row?.designation_detail?.block_name
                    let floorSpot = `${params?.row?.designation_detail?.floor_name} | ${params?.row?.designation_detail?.spot_name}`
                    return (
                        <Box component={'div'}>
                            {block ? block?.length > 15 ?
                                <Tooltip placement="top" title={block}><Typography fontSize={'0.9vw'} fontWeight={600} >{block?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{block}</Typography> : '-'}
                            {floorSpot ? floorSpot?.length > 15 ?
                                <Tooltip placement="top" title={floorSpot}><Typography fontSize={'0.9vw'} color={Colors.SecondaryText}>{floorSpot?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} color={Colors.SecondaryText}>{floorSpot}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "handover_employee__employee_name", headerName: t("Handover To"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let handEmpl = params?.row?.handover_employee__employee_name
                    return (
                        <Box component={'div'}>
                            {handEmpl ? handEmpl?.length > 15 ?
                                <Tooltip placement="top" title={handEmpl}><Typography fontSize={'0.9vw'}>{handEmpl?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{handEmpl}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "approved_employee__employee_name", headerName: t("Approved by"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let appEmp = params?.row?.approved_employee__employee_name
                    return (
                        <Box component={'div'}>
                            {appEmp ? appEmp?.length > 15 ?
                                <Tooltip placement="top" title={appEmp}><Typography fontSize={'0.9vw'}>{appEmp?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{appEmp}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "remark", headerName: t("Remarks"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let remark = params?.row?.remark
                    return (
                        <Box component={'div'}>
                            {remark ? remark?.length > 15 ?
                                <Tooltip placement="top" title={remark}><Typography fontSize={'0.9vw'}>{remark?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{remark}</Typography> : '-'}
                        </Box>
                    )
                }
            }
        ]
        return (
            <Box className="emedhub__purchase_page" sx={{ '& .grid--header': { backgroundColor: '#FAF9F9' } }}>
                <Stack sx={{ marginY: "1vw" }} direction="row" justifyContent='flex-end'>
                    <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="flex-end">
                        <div className="emedhub_vendor_btnDiv">
                            <Button variant="contained" size="small" sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} onClick={() => {
                                this.props.history.push({ pathname: "/AssetHome/NewAssetTransfer", state: { page: "asset_transfer" } })
                            }}>{t("Transfer Asset")}</Button>
                        </div>
                        <Stack direction="row" spacing={1} alignItems="flex-end">
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.Filter.default} onClick={() => { this.setState({ FilterOpen: true }) }} alt='filter' /></IconButton>
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabPrintIcon.default} alt='print' onClick={() => { this.getAssetTransferPrint() }} /></IconButton>
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabUploadButton.default} onClick={() => { this.getAssetTransferExcel() }} alt='upload' /></IconButton>
                            {/* <Tooltip title={t("More")} placement='top' arrow>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip> */}
                        </Stack>
                    </Stack>
                </Stack>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.assetTransferData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            // Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        paginationMode="server"
                        rowCount={this.state.rowCount}
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <AssetFilter PageName={"AssetTransfer"} filterDatas={this.state.filterDatas} CloseFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}
export default withTranslation()(AssetTransfer)
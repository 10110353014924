import { Box, Table, TableCell, TableHead, Stack, TableRow, TableBody, TableContainer, Paper, Button, Checkbox } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../Styles/Colors';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';

class BranchCustomConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            successMsg: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsgText: '',
            isLoader: false,
            customConfigData: [
                { name: "Amoung branches, Common UHID", checked: false },
            ],
            common_uhid: false,
        }
    }

    componentDidMount() {
        this.getCustomConfig()
    }

    getCustomConfig = () => {
        try {
            RestAPIService.getAll(Serviceurls.BRANCH_MOD_CUSTOM_CONFIG).
                then((response) => {
                    if (response.data?.status === "success") {
                        this.state.customConfigData[0]["checked"] = response.data.data[0]?.common_uhid
                        this.setState({
                            customConfigData: this.state.customConfigData
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    postCustomConfig = () => {
        try {
            let data = {
                common_uhid: this.state.customConfigData[0].checked
            }
            RestAPIService.updateWithOutId(data, Serviceurls.BRANCH_MOD_CUSTOM_CONFIG).
                then((response) => {
                    if (response.data?.status === "success") {
                        this.setState({
                            common_uhid: response.data?.data[0]?.common_uhid
                        })
                        this.successMessage(response?.data?.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }

    }

    switchFunction = (name, newValue = null) => {
        let { customConfigData } = this.state
        switch (name) {
            case "Amoung branches, Common UHID":
                this.state.customConfigData[0]["checked"] = newValue
                this.setState({ customConfigData })
                break;
            default:
                break;
        }
    }

    BlockBills = (item) => {
        let ItemData = this.state.customConfigData?.find((list) => list.name === item.name)
        const checked = ItemData?.checked
        return (
            <Checkbox onChange={(e) => { this.switchFunction(item.name, e.target.checked) }} checked={checked} />
        )
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        const { t } = this.props
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.Background }}>
                <Paper className="eMed_srvConfig_Div" >
                    <TableContainer component={'div'} className="eMed_ABill_Tbl">
                        <Table aria-label="collapsible table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '10vw' }} align='center' className="eMed_ABill_Head">{t('S.No')}</TableCell>
                                    <TableCell sx={{ width: '60vw' }} className="eMed_ABill_Head">{t('Type')}</TableCell>
                                    <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Head">{t("Allow")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.customConfigData?.map((item, index) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ width: '10vw' }} align='center' className="eMed_ABill_Tblcell">{index + 1}</TableCell>
                                            <TableCell sx={{ width: '60vw' }} className="eMed_ABill_Tblcell">{item.name}</TableCell>
                                            <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Tblcell">
                                                {this.BlockBills(item)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }
                            )}
                        </Table>
                    </TableContainer>
                    <div className='eMed_TestConfig_RightBtnCon'>
                        <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Button size='small' id='eMed_Btn_Text' variant='contained' emed_tid="CustomConfig_Save_Btn"
                                disabled={this.state.disableBtn}
                                onClick={() => { this.postCustomConfig() }}>{t("Save")}</Button>
                        </Stack>
                    </div>
                </Paper>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

            </Box>

        )
    }
}
export default withTranslation()(BranchCustomConfig)

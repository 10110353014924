import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Box, Chip, Typography } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../Components/Loader';
import { Line } from 'react-chartjs-2'
import { Growth_chart } from '../../../../Utility/Constants'

const GROWTH_CHART_XAXIS_AGE_UPTO_FIVE = ["Birth", "", "", "3", "", "", "6", "", "", "9", "", "", "1 year", "", "", "3", "", "", "6", "", "", "9", "", "", "2 year", "", "", "3", "", "", "6", "", "", "9", "", "", "3 year", "", "", "3", "", "", "6", "", "", "9", "", "", "4 year", "", "", "3", "", "", "6", "", "", "9", "", "", "5 year"];
const GROWTH_CHART_XAXIS_AGE_FROM_FIVE_EXTRA_LABEL = [
    "5", "", "", "", "", "", "6months", "", "", "", "", "", "6", "", "", "", "", "", "6months", "", "", "", "", "",
    "7", "", "", "", "", "", "6months", "", "", "", "", "", "8", "", "", "", "", "", "6months", "", "", "", "", "",
    "9", "", "", "", "", "", "6months", "", "", "", "", "", "10", "", "", "", "", "", "6months", "", "", "", "", "",
    "11", "", "", "", "", "", "6months", "", "", "", "", "", "12", "", "", "", "", "", "6months", "", "", "", "", "",
    "13", "", "", "", "", "", "6months", "", "", "", "", "", "14", "", "", "", "", "", "6months", "", "", "", "", "",
    "15", "", "", "", "", "", "6months", "", "", "", "", "", "16", "", "", "", "", "", "6months", "", "", "", "", "",
    "17", "", "", "", "", "", "6months", "", "", "", "", "", "18", "", "", "", "", "", "6months", "", "", "", "", "", "19"];
const AGE_DIGITS_UPTO_FIVE = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.10, 0.11,
    1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 1.10, 1.11,
    2.0, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 2.10, 2.11,
    3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 3.10, 3.11,
    4.0, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 4.10, 4.11,
    5.0
];
const AGE_DIGITS_FROM_FIVE_EXTRA_POINTS = [
    5.0, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 5.10, 5.11,
    6.0, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 6.10, 6.11,
    7.0, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 7.10, 7.11,
    8.0, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 8.10, 8.11,
    9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 9.10, 9.11,
    10.0, 10.1, 10.2, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.9, 10.10, 10.11,
    11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 11.10, 11.11,
    12.0, 12.1, 12.2, 12.3, 12.4, 12.5, 12.6, 12.7, 12.8, 12.9, 12.10, 12.11,
    13.0, 13.1, 13.2, 13.3, 13.4, 13.5, 13.6, 13.7, 13.8, 13.9, 13.10, 13.11,
    14.0, 14.1, 14.2, 14.3, 14.4, 14.5, 14.6, 14.7, 14.8, 14.9, 14.10, 14.11,
    15.0, 15.1, 15.2, 15.3, 15.4, 15.5, 15.6, 15.7, 15.8, 15.9, 15.10, 15.11,
    16.0, 16.1, 16.2, 16.3, 16.4, 16.5, 16.6, 16.7, 16.8, 16.9, 16.10, 16.11,
    17.0, 17.1, 17.2, 17.3, 17.4, 17.5, 17.6, 17.7, 17.8, 17.9, 17.10, 17.11,
    18.0, 18.1, 18.2, 18.3, 18.4, 18.5, 18.6, 18.7, 18.8, 18.9, 18.10, 18.11, 19.0
];

export default class DocGrowthChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientDetails: {},
            filterItem: Growth_chart,
            filter_data: 'height',
            growthChart: [],
            data: {
                labels: [],
                datasets: []
            },
        }
    }

    componentDidMount() {
        let clinicId = getCachevalue('SelectedDoctorClinic')
        let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        this.setState({
            clinicId: clinicId,
            appointmentId: appointmentId?.appointment_id,
            patientId: appointmentId?.patient_id
        }, () => {
            this.getPatientDetailData()
            this.getGrowthchart()
        })
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGrowthchart = () => {
        try {
            RestAPIService.getAll(Serviceurls.PD_GROWTH_CHART + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            growthChart: response?.data?.data
                        }, () => {
                            this.setPlotHeightChart(response?.data?.data, this.state.filter_data);
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    setPlotHeightChart(response, type) {

        let patientGrowth = response.patient_growth;
        let patient = response.patient;

        let patientData;
        let standardData;

        var standard_growth_chart = Object.keys(response).length > 0 && Object.keys(response.standard_growth_chart).length > 0 ?

            response.standard_growth_chart : {}

        if (type === "height") {
            patientData = patientGrowth && patientGrowth.height ? patientGrowth.height : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.height ? standard_growth_chart.height : 0;
        }
        else if (type === "weight") {
            patientData = patientGrowth && patientGrowth.weight ? patientGrowth.weight : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.weight ? standard_growth_chart.weight : 0;
        }
        else if (type === "bmi") {
            patientData = patientGrowth && patientGrowth.bmi ? patientGrowth.bmi : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.bmi ? standard_growth_chart.bmi : 0;
        }
        else if (type === "head circumference") {
            patientData = patientGrowth && patientGrowth.head_circumference ? patientGrowth.head_circumference : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.head_circumference ? standard_growth_chart.head_circumference : 0;
        }

        //var fields = this.state.groupData;
        var dataFields = this.state.data;

        var heightList = standardData;

        let age = "";

        if (patient) {

            let dataArr = (patient.age).split(",")
            let lenArr = dataArr.length;

            if (lenArr > 0) {

                age = parseInt(dataArr[0]);

                let m = parseInt(dataArr[1]);
                let d = parseInt(dataArr[2]);

                if (m > 0 || d > 0) {
                    age = age + 1;
                }
            }
        }

        let patientAge = age;
        //let digitsAge = parseInt(patientAge) <= 5 ? Constants.AGE_DIGITS_UPTO_FIVE : Constants.AGE_DIGITS_FROM_FIVE_EXTRA_POINTS;

        dataFields.labels = parseInt(patientAge) <= 5 ? GROWTH_CHART_XAXIS_AGE_UPTO_FIVE : GROWTH_CHART_XAXIS_AGE_FROM_FIVE_EXTRA_LABEL;

        var colorCode = [
            "rgba(255, 0, 0, 0.75)",
            "rgba(0, 255, 0, 0.75)",
            "rgba(0, 0, 255, 0.75)"
        ];


        if (parseInt(patientAge) > 5) {


            let len = heightList.length;
            // console.log(" len - " + len);
            // console.log(JSON.stringify(heightList));

            for (var outer = 0; outer < len; outer++) {

                let newHeights = [];
                let item = heightList[outer];
                let innerLen = item.value.length;

                // console.log(" innerLen - " + innerLen);
                // console.log(JSON.stringify(item));

                for (var n = 0; n < innerLen; n++) {

                    if (n === 0 || n === (innerLen - 1)) {

                        newHeights.push(heightList[outer]["value"][n]);
                    } else {

                        let diff = heightList[outer]["value"][n] - heightList[outer]["value"][n - 1];
                        let colDiff = diff / 6;

                        for (let nC = 1; nC <= 5; nC++) {

                            newHeights.push(heightList[outer]["value"][n - 1] + colDiff * nC)
                        }

                        newHeights.push(heightList[outer]["value"][n]);
                    }

                }

                heightList[outer]["value"] = newHeights;

            }

        }

        var sample =
            heightList &&
            heightList.length > 0 &&
            heightList.map((item, i) => {

                let color = "";
                if (i === 0 || i === 4) { color = "#cc33ff"; }
                if (i === 1 || i === 3) { color = "#00ccff"; }
                if (i === 2) { color = "#00ff99"; }
                if (i === 6) { color = "#ff0066"; }


                return {
                    label: item.label,
                    data: item.value,
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    borderColor: color,  // colorCode[i % 3]
                    fill: false,
                    lineTension: 0.5,
                    //highlightEnabled: false,
                    axisY: {
                        stripLines: {}
                    },
                    pointRadius: 0,
                };
            });

        if (sample) {
            sample.pop();


            var stringOne = "Length / height for age";
            var stringTwo = patient.gender === "m" ? " Boys " : " Girls ";

            var stringThree = patientAge <= 5 ? "0-5 years" : "5-19 years";
            var stringFour = " (Percentile)";
            dataFields.datasets = sample;

            var patient_record_count = patientGrowth && patientGrowth.age ? patientGrowth.age.length : 0;
            var patient_rec = [];


            // var lables_count = dataFields.labels ? dataFields.labels.length : 0;

            var extra_digits = parseInt(patientAge) <= 5 ? AGE_DIGITS_UPTO_FIVE : AGE_DIGITS_FROM_FIVE_EXTRA_POINTS
            var lables_count = extra_digits.length;

            for (var j = 0; j < lables_count; j++) {

                var is_captured = false;
                for (var i = 0; i < patient_record_count; i++) {

                    // console.log("chart value check - " + extra_digits[j] + " - " + dataFields.labels[j] + ' - ' + patientGrowth.age[i])

                    //if(dataFields.labels[j] == patientGrowth.age[i]) {

                    if (extra_digits[j] == patientGrowth.age[i]) {

                        patient_rec.push(patientData[i]);
                        is_captured = true;
                    }
                }

                if (!is_captured) {
                    patient_rec.push('');
                }
            }

            var patient_height_dataset = {
                label: type,
                data: patient_rec,
                backgroundColor: "rgba(255, 255, 255, 0)",
                type: 'bar',
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',  //'#71B37C',
                fill: false,
                lineTension: 0.5
            };

            dataFields.datasets.push(patient_height_dataset);


            // console.log(" dataFields.datasets - " + JSON.stringify(dataFields.datasets));

            this.setState({
                //      fields,
                dataFields,
                commonString: stringOne + stringTwo,
                ageString: stringThree + stringFour
            });
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    returnchartText(text){
        switch(text){
            case "height" : return "Height_(cms)"
            case "weight" : return "Weight_(Kgs)"
            case "bmi" : return "BMI"
            case "head circumference" : return "Head_Circumference_(cms)"
            default : return ""
        }
    }

    renderLeftSide = () => {
        let data = this.state.data
        
      let chartFont = "Open Sans";
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true
              },
              title: {
                display: false
              },
              tooltip: {
                titleFont: {
                  family: chartFont,
                },
                bodyFont: {
                  family: chartFont,
                },
              }
            },
            scales: {
              x: {
                ticks: {
                  align: 'center',
                  font: {
                    weight: 'bold',
                    family: chartFont,
                    size: 12
                  }
                }
              },
              y: {
                stepSize: 1,
                ticks: {
                  align: 'center',
                  font: {
                    weight: 'bold',
                    family: chartFont
                  },
                }
              },
            },
            animations: {
              tension: {
                duration: 1000,
                easing: 'linear',
                from: 0.2,
                to: 0,
                loop: true
              }
            }
          };
        try {
            let chartFont = "Open Sans";
            return (
                <Box component={'div'}>
                    <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Growth Chart</Typography>
                    </Box>
                    <Box sx={{ backgroundColor: Colors.white, display: 'flex', alignItems:'center' }}>
                        <Box sx={{width:'2vw'}}>
                            <Typography sx={{ rotate: "270deg" }} >{this.returnchartText(this.state.filter_data)}</Typography>
                        </Box>
                        <Box component={'div'}  p={'0.5vw'} sx={{ height: '57vh', width:'57vw' }}>
                        <Line
                            options={options}
                            data={data}
                        />

                        {/* <Line data={{
                            labels: this.state.listOfLabels || [],
                            datasets: [
                                {
                                    label: "",
                                    backgroundColor: "#04B7B1",
                                    borderColor: "#04B7B1",
                                    data: this.state.listOfValues || []
                                }
                            ],
                        }}
                            options={options}
                        /> */}
                    </Box>
                    </Box>
                    
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRightSide = () => {
        try {
            return (
                <Box component={'div'}>
                    <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Filter</Typography>
                    </Box>
                    <Box component={'div'} height={'25vw'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'} p={'0.5vw'}>
                        <Box component={'div'}>
                            {this.state.filterItem?.map((item, index) => {
                                return (
                                    <Chip
                                        className={`eMed_chip ${(item.label).toLowerCase() === this.state.filter_data ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                        label={item.label}
                                        variant={item.label === this.state.filter_data ? 'contained' : 'outlined'}
                                        key={index}
                                        onClick={() => {
                                            this.setState({ filter_data: item?.value }, () => {
                                                this.getGrowthchart()
                                            })
                                        }}
                                        // color={'primary'}
                                        clickable
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        let attender = {
            lineOne: "Attender Name",
            lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
                this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
            lineFour: 'Contact Number',
            LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
                this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
        }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'78vh'} p={'0.5vw'}>
                <Box component={'div'} display={'flex'}>
                    <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
                    </Box>
                    {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'62vh'}>
                    <Box component={'div'} flex={0.65} height={'66.5vh'} border={'1px solid lightgray'}>
                        {this.renderLeftSide()}
                    </Box>
                    <Box component={'div'} flex={0.35} border={'1px solid lightgray'} height={'66.5vh'} ml={'.5vw'}>
                        {this.renderRightSide()}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}

import React, { Component } from 'react'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import { Colors } from '../../../../Styles/Colors'
import { Autocomplete, Box, Button, Chip, TextField, Tooltip, Typography } from '@mui/material'
import Loader from '../../../../Components/Loader';
import { Family_Member_List, Medical_History_Problems } from '../../../../Utility/Constants'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";

export default class DocFamilyHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      familyHistoryData: [],
      familyList: Family_Member_List,
      medicalProblemsData: Medical_History_Problems,
      selectedProblem: [],
      selectedFamilyMember: '',
      description: '',
      comments: '',
      selectedId: null,
      deletePopUp: false,
      removeData: false,
      loading: false,
      duplicateDisease: '[]',
      patientDetails: {},
      patientId: '',
      appointmentId: '',
      clinicId: '',
      // for fo 
      withOutAppointment: {},
      doctor_id: "",
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    let ipPatientData = JSON.parse(getCachevalue("IppatientData"));
    let appointmentDetail = JSON.parse(getCachevalue("withoutAppointment"));
    const newState = {
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id || ipPatientData?.patient_id,
      doctor_id: ipPatientData?.doctor_id
    };
    if (appointmentDetail?.patient_id === ipPatientData?.patient_id) {
      newState.withOutAppointment = appointmentDetail;
    }
    this.setState({
      ...newState
    }, () => {
      this.getPatientDetailData()
      this.getFamilyHistoryData()
    })

  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getFamilyHistoryData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_FAMILY_HISTORY + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              familyHistoryData: response.data.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postFamilyHistoryData = () => {
    try {
      let removedDisease = this.deletedDiseaseName()
      let data = {
        appointment_id: this.state.appointmentId,
        comments: this.state.comments,
        deleted_disease_names: removedDisease,
        description: this.state.description,
        disease_names: this.state.selectedProblem,
        family_member: this.state.selectedFamilyMember,
        patient_id: this.state.patientId,
        id: this.state.selectedId ? this.state.selectedId : null
      }
      if(this.props.is_From_Fo){
        data['doctor_id'] = this.state.doctor_id
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DOC_FAMILY_HISTORY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.onClearFamilyHistory()
            this.getFamilyHistoryData()
            removedDisease = []
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  deleteFamilyHistoryData = () => {
    try {
      let data = {
        patient_id: this.state.patientId,
        id: this.state.selectedId
      }
      this.setState({ removeData: true })
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DOC_FAMILY_HISTORY_DELETE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.onClearFamilyHistory()
            this.getFamilyHistoryData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onClearFamilyHistory = () => {
    try {
      this.setState({
        selectedProblem: [],
        selectedFamilyMember: '',
        description: '',
        comments: '',
        selectedId: null,
        isEdit: false,
        deletePopUp: false,
        duplicateDisease: '[]'
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        selectedFamilyMember: item?.family_member,
        selectedProblem: item?.disease_names?.map((item) => item),
        description: item?.description,
        comments: item?.comments,
        selectedId: item?.id,
        isEdit: true,
        duplicateDisease: JSON.stringify(item?.disease_names)
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deletedDiseaseName = () => {
    try {
      let duplicate = JSON.parse(this.state.duplicateDisease)
      let removedDisease = []; let selectedProblemHash = {};
      this.state.selectedProblem?.forEach((element) => {
        selectedProblemHash[element] = element
      })
      duplicate?.forEach((element) => {
        if (selectedProblemHash[element] !== element) {
          removedDisease.push(element)
        }
      })
      return removedDisease;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (!this.state.isEdit) {
        this.setState({
          selectedId: item?.id,
          deletePopUp: true,
        })
      } else {
        this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopUp: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      const columns = [
        {
          field: 'family_member', headerName: 'Selected Family Member', flex: 0.2,
          renderCell: (params) => (<Box>{params?.row?.family_member ? params?.row?.family_member : '-'}</Box>)
        },
        {
          field: 'disease_names', headerName: 'Family History', flex: 0.2,
          renderCell: (params) => (
            <Box>{params?.row?.disease_names ? params?.row?.disease_names?.length > 3 ?
              <Tooltip placement='top' title={params?.row?.disease_names?.join(',')}><div>{params?.row?.disease_names?.slice(0, 2)?.join(',') + '...'}</div></Tooltip> :
              params?.row?.disease_names?.join(',') : '-'}</Box>
          )
        },
        {
          field: 'description', headerName: 'Description', flex: 0.2,
          renderCell: (params) => (<Box>{params?.row?.description ? params?.row?.description?.length > 20 ?
            <Tooltip placement='top' title={params?.row?.description}><div>{params?.row?.description?.slice(0, 20) + '...'}</div></Tooltip> :
            params?.row?.description : '-'}</Box>)
        },
        {
          field: 'comments', headerName: 'comments', flex: 0.2,
          renderCell: (params) => (<Box>{params?.row?.comments ? params?.row?.comments?.length > 20 ?
            <Tooltip placement='top' title={params?.row?.comments}><div>{params?.row?.comments?.slice(0, 20) + '...'}</div></Tooltip> :
            params?.row?.comments : '-'}</Box>)
        },
        {
          field: 'action', headerName: 'Action', flex: 0.2, align: 'center', headerAlign: 'center',
          renderCell: (params) => (<Box>
            <Button className='Common_Btn_Min_Width'
              onClick={() => this.onEditHandler(params?.row)}
            >
              <Box component={'img'} src={ImagePaths.Edit.default} height={'1.5vw'} width={'1.5vw'} />
            </Button>
            <Button className='Common_Btn_Min_Width'
              onClick={() => this.onDeleteHandler(params?.row)}
            >
              <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
            </Button>
          </Box>)
        }
      ]
      return (
        <DataGrid
          rows={this.state.familyHistoryData}
          sx={{ backgroundColor: Colors.white }}
          columns={columns}
          getRowId={(row) => row.id}
          page={this.state.page}
          pageSize={this.state.pageSize}
          // localeText={{
          //   toolbarColumns: "",
          //   toolbarDensity: "",
          //   toolbarExport: "",
          //   toolbarFilters: "",
          //   toolbarExportPrint: ""
          // }}
          components={{
            // Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata'>
                {'No Records To Be Shown'}
              </Box>
            )
          }}
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          hideFooter
          headerHeight={40}
          disableSelectionOnClick
          loading={this.state.loading}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    const enableAdd = ((this.state.selectedFamilyMember) && (this.state.selectedProblem?.length > 0));
    const checkuserAccess = (CheckAccessFunc("front_office", "In Patients", "History", "Family History", "SubTab")?.editAccess ?
      (this.state.withOutAppointment?.is_ip || true) && enableAdd
      : enableAdd);
    try {
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'2vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Family History</Typography>
          </Box>
          <Box component={'div'} height={'12vh'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Box component={'div'} p={'.5vw'} width={'70vw'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Autocomplete
                clearIcon
                size='small'
                sx={{ width: '15vw' }}
                freeSolo
                options={this.state.familyList.map((item) => item.label)}
                value={this.state.selectedFamilyMember}
                limitTags={3}
                autoComplete='off'
                onChange={(event, newValue) => {
                  this.setState({
                    selectedFamilyMember: newValue
                  })
                }}
                onInputChange={(event, newValue) => {
                  this.setState({ selectedFamilyMember: newValue })
                }}
                renderInput={(params) => <TextField {...params} label='Family Member' />}
              />
              <Autocomplete
                clearIcon
                size='small'
                sx={{ width: '20vw' }}
                multiple
                freeSolo
                options={this.state.medicalProblemsData.map((item) => item.label)}
                value={this.state.selectedProblem}
                limitTags={1}
                autoComplete='off'
                onChange={(event, newValue) => {
                  this.setState({ selectedProblem: newValue })
                }}
                renderTags={(value, getTagProps) => (
                  value.map((option, index) => (<Chip variant="outlined" size='small' label={option} {...getTagProps({ index })} />))
                )}
                renderInput={(params) => <TextField {...params} label='Family History' />}
              />
              <TextField
                size='small'
                sx={{ width: '16vw' }}
                label='Description'
                value={this.state.description}
                inputProps={{ maxLength: 250, emed_tid: `family_history_descp` }}
                onChange={(e) => { this.setState({ description: e.target.value }) }}
              />
              <TextField
                size='small'
                sx={{ width: '16vw' }}
                label='Comments'
                value={this.state.comments}
                inputProps={{ maxLength: 250, emed_tid: `family_history_comments` }}
                onChange={(e) => { this.setState({ comments: e.target.value }) }}
              />
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
              <Button
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='outlined'
                onClick={() => this.onClearFamilyHistory()}
              >Clear</Button>
              <Button
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                variant='contained'
                disabled={this.props.is_From_Fo ? !checkuserAccess : !enableAdd}
                onClick={() => this.postFamilyHistoryData()}
              >Add</Button>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} mt={'0.5vw'}>
          <Box component={'div'} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
          <Box component={'div'} mt={'.5vw'} height={'44vh'}>
            {this.renderLeftSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopUp ? <DeletePopup
          DeletTitle={`Are you sure you want to delete ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteFamilyHistoryData.bind(this)}
          disabled={this.state.removeData}
        /> : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

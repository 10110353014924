import React, { Component } from "react";
import { Box, Button, Stack, TextField, Autocomplete, Table, TableContainer, TableHead,
TableRow, TableCell, TableBody, TablePagination, Checkbox, IconButton, Tooltip, Typography } from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Trans, withTranslation } from 'react-i18next';
import './purchase.css';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../Components/Loader';


const Numeric = /^\d*$/ ;
class WantedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                total_count: 0,
                no_of_pages: 0,
                order_count: 0,
                result: []
            },
            selectedIds: [],
            vendor_list: [],
            isAllChecked: false,
            current_page: 0,
            rowsPerPage: 10,
            searchKey: '',
        }
    }
    componentDidMount() {
        this._getWantedList();
        this._getVendorList();
    }

    componentWillReceiveProps(props){
        if(props.search_key != this.state.searchKey){
            this.setState({
                searchKey: props.search_key
            },() => {
                this._getWantedList()
            })
        }
    }

    _getVendorList = () => {

        RestAPIService.getAll(Serviceurls.LIST_VENDOR)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        vendor_list: response.data.data,
                    });
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }
    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getWantedList = () => {
        let { data, current_page, rowsPerPage } = this.state
        let recentPage = current_page + 1;
        this.LoaderFunction(true)
        this.setState({
            data: {
                total_count: 0,
                no_of_pages: 0,
                order_count: 0,
                result: []
            },
            selectedIds: [],
            isAllChecked: false,
        })
        this.LoaderFunction(true)
        RestAPIService.getAll(`${Serviceurls.LAB_WANTED_LIST}?page_number=${recentPage}&page_size=${rowsPerPage}&search_key=${this.state.searchKey}`)
            .then(response => {
                if (response.data.status == "success") {
                    data['result'] = response.data.data.result ? response.data.data.result : [];
                    data['total_count'] = response.data.data.total_count ? response.data.data.total_count : 0;
                    data['no_of_pages'] = response.data.data.no_of_pages ? response.data.data.no_of_pages : 0;
                    data['order_count'] = response.data.data.order_count ? response.data.data.order_count : 0;
                    this.setState({
                        data
                    }, () => {
                        data.result.forEach(data => { data.isSelected = false })
                        this.LoaderFunction(false)
                    });
                }
            })
            .catch(error => {
                this.LoaderFunction(false)
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }

    getWantedListexcel = () => {
        try {
          RestAPIService.excelGet(Serviceurls.LAB_WANTED_LIST + "?export_type=excel" + "&search_key=" + this.state.searchkey).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              }
            }).catch((error) => {
                this.SuccessErrorBox(error.response.data.message, false);
            })
        } catch (error) {
            this.SuccessErrorBox(error.message, false);
        }
      }

    SuccessErrorBox = (message,type) => {
        this.props.responseSuccessErrorMessage(message,type)
    }

    checkAllDetailsAreAvailable(newEntry) {
        const {t} = this.props
        var defaultReturn = true
        for (const data of newEntry) {
            if (!data.order_quantity){
                this.SuccessErrorBox(t("KindlyaddQuantity"), false);
                defaultReturn = false
            } else if(!data.vendor_id) {
                this.SuccessErrorBox(t("KindlyaddVendor"), false);
                defaultReturn = false
            }
        }
        return defaultReturn
    }
    //Bulk Action
    moveOrderList = () => {
        let { selectedIds } = this.state
        let isValid = this.checkAllDetailsAreAvailable(selectedIds)
        let datas = {
            product_list: []
        }
        if(isValid){
            datas["product_list"] = selectedIds;
            RestAPIService.create(datas, Serviceurls.LAB_WANTED_LIST_POST)
            .then(response => {
                if (response.data.status == "success") {
                    this.SuccessErrorBox(response.data.message, true);
                    this._getWantedList();
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
            })
        }
    }

    //individual Action
    moveToOrder = (item) => {                                                               
        let tempdata
        let datas = {
            product_list: []
        }
        const {t} = this.props
        if (item.vendor_id && item.suggested_quantity) {
            tempdata = {
                "vendor_id": item.vendor_id,
                "product_ms_id": item.product_ms_id,
                "product_list_id": item.product_list_id ? item.product_list_id : null,
                "order_quantity": item.suggested_quantity ? item.suggested_quantity : 0,
                "price_per_quantity": item.price_per_quantity ? item.price_per_quantity : 0
            };
            datas['product_list'].push(tempdata)

            RestAPIService.create(datas, Serviceurls.LAB_WANTED_LIST_POST)
                .then(response => {
                    if (response.data.status == "success") {
                        this.SuccessErrorBox(response.data.message, true);
                        this._getWantedList();
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.SuccessErrorBox(error.responses.data.message, false);
                    }
                })
        } else {
            if (!item.suggested_quantity){
                this.SuccessErrorBox(t("KindlyaddQuantity"), false);
            } else if(!item.vendor_id) {
                this.SuccessErrorBox(t("KindlyaddVendor"), false);
            }
        }
    }

    onCheckBoxAll = () => {
        let selectedID = [];
        let { data } = this.state
        let tempObj = {};
        let temp = data.result && data.result.length > 0 && data.result.map((item) => {
            if (this.state.isAllChecked) {
                return { ...item, isSelected: true }
            } else {
                return { ...item, isSelected: false }
            }
        })
        data["result"] = []
        this.setState({
            data
        },()=>{
            data["result"] = temp
        })
        this.setState({
            data
        }, () => {
            if (data.result && data.result.length > 0 && this.state.isAllChecked) {
                data.result.forEach((item) => {
                    tempObj = {
                        "vendor_id": item.vendor_id,
                        "product_ms_id": item.product_ms_id,
                        "product_list_id": item.product_list_id ? item.product_list_id : null,
                        "order_quantity": item.suggested_quantity ? item.suggested_quantity : 0,
                        "price_per_quantity": item.price_per_quantity ? item.price_per_quantity : 0
                    }
                    selectedID.push(tempObj)
                })
                this.setState({
                    selectedIds: selectedID
                })
            } else {
                this.setState({
                    selectedIds: []
                })
            }
        })
    }

    onMultiCheck = (item, index) => {

        let { data, selectedIds } = this.state
        let selectedIDs = [];
        let tempObj1
        if (item.product_ms_id === data.result[index].product_ms_id && !this.state.isAllChecked) {
            data.result[index]["isSelected"] = !data.result[index]["isSelected"]
            this.setState({
                data
            }, () => {
                if (data.result && data.result.length > 0 && !this.state.isAllChecked) {
                    data.result.forEach((item) => {
                        if (item.isSelected == true) {
                            tempObj1 = {
                                "vendor_id": item.vendor_id,
                                "product_ms_id": item.product_ms_id,
                                "product_list_id": item.product_list_id ? item.product_list_id : null,
                                "order_quantity": item.suggested_quantity ? item.suggested_quantity : 0,
                                "price_per_quantity": item.price_per_quantity ? item.price_per_quantity : 0
                            }
                            selectedIDs.push(tempObj1)
                        }
                    })
                    this.setState({
                        selectedIds: selectedIDs
                    }, () => {
                        if (this.state.selectedIds.length == data.result.length) {
                            this.setState({
                                isAllChecked: true
                            })
                        }
                    })
                }
            })
        } else if (item.product_ms_id === data.result[index].product_ms_id && this.state.isAllChecked) {
            data.result[index]["isSelected"] = !data.result[index]["isSelected"]
            selectedIds.splice(index, 1)
            this.setState({
                data,
                selectedIds
            }, () => {
                if (this.state.selectedIds.length != this.state.rowsPerPage) {
                    this.setState({
                        isAllChecked: false
                    })
                }
            })
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ current_page: newPage },() =>{this._getWantedList()});
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, current_page: 0 },()=>{this._getWantedList()});
    }
    render() {
        const { t } = this.props;
        let { data, vendor_list, current_page, selectedIds, rowsPerPage } = this.state;
        const defaultdata = [
            { name: 'No options Found' }
        ]
        return (
            <Box className="emedhub__purchase_page" sx={{ '& .grid--header': { backgroundColor: '#FAF9F9' } }}>
                <Stack sx={{ margin: "1vw" }} direction="row" justifyContent="space-between">
                    <div>
                    <Button variant="contained" size="small" disabled={ CheckAccessFunc("Laboratory", "Purchase", "Wanted List", null, "Tab")?.editAccess && selectedIds.length > 0 ? false : true} sx={{ display: CheckAccessFunc("Laboratory", "Purchase", "Wanted List", null, "Tab")?.editAccess && selectedIds.length > 0 ? "block" : "none" ,fontSize: "0.8vw", textTransform:"capitalize" }} onClick={() => { this.moveOrderList() }}>{t('MoveToOrder')}</Button>
                    </div>
                    <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                        <div className="emedhub_vendor_btnDiv">
                            <Button disabled={!CheckAccessFunc("Laboratory", "Purchase", "Wanted List", null, "Tab")?.editAccess} variant="contained" size="small" sx={{ marginRight: "0.5vw", textTransform:"capitalize"}} onClick={() => { this.props.goBack("order_List") }}>{t("OrderList")+ "(" + data.order_count + ")"}</Button>
                        </div>
                        <Stack direction= "row" spacing={1} alignItems="center">
                        {/* <IconButton size="small"><img className="emedhub_directPo_img"  src={ImagePaths.LabPrintIcon.default} alt='print' /></IconButton> */}
                        <IconButton size="small"><img className="emedhub_directPo_img"  src={ImagePaths.LabUploadButton.default} onClick={()=>this.getWantedListexcel()} alt='upload' /></IconButton>
                        </Stack>
                        {/* <div className="emedhub_vendor_printBtn" ><img className="emedhub_vendor_img1" src={ImagePaths.PrintOff.default} alt={"print"} /></div>
                        <div className="emedhub_vendor_printBtn" ><img className="emedhub_vendor_img1" src={ImagePaths.ExportOff.default} alt={"export"} /></div> */}
                    </Stack>
                </Stack>
                <TableContainer className='emedhub_wantedListTable'>
                    <Table stickyHeader size='small'>
                        <TableHead className="emedhub_directpoTable_head" sx={{ height: "5vh" }}>
                            <TableRow>
                                <TableCell sx={{ flex: 0.03 }} id="emedhub_directpoTable_headTxt">
                                    <Checkbox
                                        disabled = {data["result"].length === 0 && CheckAccessFunc("Laboratory", "Purchase", "Wanted List", null, "Tab")?.editAccess ? true : false}
                                        checked = {this.state.isAllChecked}
                                        value = {this.state.isAllChecked}
                                        onChange={(e) => {
                                            this.setState({ isAllChecked: !this.state.isAllChecked }, () => {
                                                this.onCheckBoxAll()
                                            })
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ flex: 0.03 }} id="emedhub_directpoTable_headTxt">{t("SNo")}</TableCell>
                                <TableCell sx={{ flex: 0.07 }} id="emedhub_directpoTable_headTxt">{t("BrandName")}</TableCell>
                                <TableCell sx={{ flex: 0.09 }} id="emedhub_directpoTable_headTxt">{t("ProductType")}</TableCell>
                                <TableCell sx={{ flex: 0.09 }} id="emedhub_directpoTable_headTxt">{t("ProductName")}</TableCell>
                                <TableCell sx={{ flex: 0.08 }} id="emedhub_directpoTable_headTxt"> {t("AvailQty")}</TableCell>
                                <TableCell sx={{ flex: 0.08 }} id="emedhub_directpoTable_headTxt">{t("EPRValue")}</TableCell>
                                <TableCell sx={{ flex: 0.15 }} id="emedhub_directpoTable_headTxt">{t("VendorName")}</TableCell>
                                <TableCell sx={{ flex: 0.14 }} id="emedhub_directpoTable_headTxt">{t("OrderQty")}</TableCell>
                                <TableCell sx={{ flex: 0.08 }} id="emedhub_directpoTable_headTxt">{t("PurchasePrice/Quantity")}</TableCell>
                                <TableCell sx={{ flex: 0.08 }} id="emedhub_directpoTable_headTxt">{t("LatestMRPQty")}</TableCell>
                                <TableCell sx={{ flex: 0.08 }} id="emedhub_directpoTable_headTxt">{t("Action")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="emedhub_directpoTable_head">
                            { data["result"].length > 0 &&data["result"].map((item, index) => {
                                let sno = (index + 1)
                                return (
                                    <TableRow key = {index}>
                                        <TableCell sx={{ flex: 0.03 }}>
                                            <Checkbox
                                                disabled={!CheckAccessFunc("Laboratory", "Purchase", "Wanted List", null, "Tab")?.editAccess}
                                                checked={item.isSelected}
                                                onChange={() => {
                                                    this.onMultiCheck(item, index)
                                                }}
                                            /></TableCell>
                                        <TableCell sx={{ flex: 0.03 }}>{sno}</TableCell>
                                        <TableCell sx={{ flex: 0.07 }}>{
                                            item.brand_name.length > 10 ?
                                                <Tooltip placement="top" title={item.brand_name} arrow>
                                                    <div>{item.brand_name ? item.brand_name.slice(0, 8) + '...': '-'}</div>
                                                </Tooltip> : <div>{item.brand_name ? item.brand_name : '-'}</div>}
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.09 }}>{
                                            item.product_type.length > 10 ?
                                            <Tooltip placement="top" title={item.product_type} arrow>
                                                <div>{item.product_type ? item.product_type.slice(0, 8) + '...': '-'}</div>
                                            </Tooltip> : <div>{item.product_type ? item.product_type : '-'}</div>}
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.09 }}>{item.product_name.length > 10 ? 
                                        <Tooltip placement="top" title={item.product_name} arrow>
                                                <div>{item.product_name ? item.product_name.slice(0, 8) + '...': '-'}</div>
                                            </Tooltip> : <div>{item.product_name ? item.product_name : '-'}</div>}</TableCell>

                                        <TableCell sx={{ flex: 0.08 }}>{item.available_quantity}</TableCell>
                                        <TableCell sx={{ flex: 0.08 }}>{item.epr_value}</TableCell>
                                        <TableCell sx={{ flex: 0.15}}>
                                            <Autocomplete
                                                disableClearable
                                                value = {item.vendor_name ? item.vendor_name : "" }
                                                sx={{ width: "10.85vw" }}
                                                options={vendor_list.length != 0 ? vendor_list : defaultdata}
                                                getOptionLabel={option => typeof(option) === "string" ? option : option.name}
                                                onChange={(event, newVendor) => {
                                                    data['result'][index]["vendor_name"] = newVendor.name;
                                                    data['result'][index]["vendor_id"] = newVendor.vendor_id;
                                                    this.setState({
                                                        data
                                                    })
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        placeholder= {t("EnterVendor")}
                                                        {...params}
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                        }}
                                                    />)} />
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.14 }}>
                                            <Stack spacing={1} direction="row" justifyContent="space-evenly">
                                                <TextField
                                                    name="suggested_quantity"
                                                    value={item.suggested_quantity ? item.suggested_quantity : ""}
                                                    size="small"
                                                    sx={{ width: "5vw" }}
                                                    autocomplete="off"
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if ((Numeric.test(value))) {
                                                            data['result'][index]['suggested_quantity'] = value
                                                            let buyingPrice = (value ? parseInt(value) : 0) * parseFloat(data['result'][index].mrp_per_quantity)
                                                            data['result'][index]["price_per_quantity"] = Math.round(buyingPrice)
                                                        }
                                                        this.setState({
                                                            data
                                                        }, () => {
                                                            if(this.state.isAllChecked) {
                                                                this.onCheckBoxAll()
                                                            } 
                                                        })
                                                    }}
                                                />
                                                <TextField
                                                    name="unit"
                                                    value={item.unit}
                                                    size="small"
                                                    sx={{ width: "5vw" }}
                                                    autocomplete="off"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ flex:0.08 }}>
                                            <TextField
                                                value={item.price_per_quantity}
                                                size="small"
                                                sx={{ width: "6vw" }}
                                                autocomplete="off"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.08 }}>{item.mrp_per_quantity}</TableCell>
                                        <TableCell sx={{ flex: 0.08 }}>
                                        <Tooltip title={t("AddCart")} placement="top" arrow>
                                            <IconButton size="small" onClick={() => { this.moveToOrder(item) }} disabled={selectedIds.length > 0 && !CheckAccessFunc("Laboratory", "Purchase", "Wanted List", null, "Tab")?.editAccess ? true : false} ><img className="emedhub_vendor_img2" src={selectedIds.length > 0 ? ImagePaths.disabledCart.default : ImagePaths.shoppingCart.default} alt={"Add to Cart"} /></IconButton>
                                        </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            }   
                        </TableBody>
                    </Table>
                    {
                                data["result"].length === 0 ?
                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{height: "53vh" }}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                    : null
                    }
                </TableContainer>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={data["total_count"]}
                        rowsPerPage={rowsPerPage}
                        page = {current_page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}

export default withTranslation()(WantedList);
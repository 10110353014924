import { Autocomplete, Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, AmountFormat, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../../../Utility/Constants';

const paymentStatusList = [
  { label: "Payment Received", value: "Payment Received" },
  { label: "Payment Pending", value: "Payment Pending" }
]

class CopPaymentReport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      companyName: [],
      paymentStatus: [],
      startDate: null,
      endDate: null,
      dateIndex: 1,
      corporatePaymentData: [],
      corporatePaymentTotal: null,
      corporatePaymentPayable: null,
      corporateFilterList: [],
      FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      FilterDatas: {
        "startTime":null,
        "endTime":null,
        "onError":false,
    },
    timeError: false,
    }
  }

  componentDidMount() {
    this.getCorporatePaymentData()
    this.getCorportateFilterList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  LoaderFunction = (key) => {
    this.setState({
        isLoader: key
    })
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    }, ()=> {
      this.state.FilterDatas.endTime = null
      this.setState({
          FilterDatas:this.state.FilterDatas
      })
  })
  }

  getCorporatePaymentData = () => {
    try {
      this.LoaderFunction(true)
      let invoiceFrom = this.state.startDate ? DateTime.fromJSDate(this.state.startDate).toFormat('yyyy-MM-dd') : ""
      let invoiceTo = this.state.endDate ? DateTime.fromJSDate(this.state.endDate).toFormat('yyyy-MM-dd') : ""
      let companyName = [];let paymentStatus = [];
      this.state.companyName.forEach(element => companyName.push(element.company_name))
      this.state.paymentStatus.forEach(element => paymentStatus.push(element.value))
      var { FilterDatas } = this.state
      let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
      let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      RestAPIService.getAll(Serviceurls.FO_REPORTS_CORPORATE_PAYMENT + `?company_name=${companyName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&payment_status=${paymentStatus}&invoice_from=${invoiceFrom}&invoice_to=${invoiceTo}&start_time=${setStartTime}&end_time=${setEndTime}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              corporatePaymentData: response.data.data,
              corporatePaymentTotal: response.data.total_amount,
              corporatePaymentPayable: response.data.payable_amount,
              FilteredFromDate: this.state.fromDate,
              FilteredToDate: this.state.toDate,
            },()=>{this.LoaderFunction(false)})
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
        } else {
            this.errorMessage(error.message)
        }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  getCorportateFilterList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              corporateFilterList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
        } else {
            this.errorMessage(error.message)
        }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  selectedTime = (start, end,error) => {
    this.state.FilterDatas.startTime = start
    this.state.FilterDatas.endTime = end
    this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
}
  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box sx={{width: '22vw'}}>
            <CommonTimeRangePicker
              startTime={this.state.FilterDatas?.startTime}
              endTime={this.state.FilterDatas?.endTime}
              selectedTime={this.selectedTime.bind(this)}
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              onError={this.state.FilterDatas.onError}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              multiple
              limitTags={1}
              size='small'
              options={this.state.corporateFilterList?.map((item) => item)}
              getOptionLabel={(option) => (option?.company_name)}
              value={this.state.companyName}
              onChange={(event, newValue) => {
                this.setState({ companyName: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("selectCom")}
                />
              )}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker
                label={t('SelectFrom')}
                inputFormat='DD-MM-YYYY'
                value={this.state.startDate}
                name="startDate"
                onChange={(newDate) => { this.setState({ startDate: newDate.$d }) }}
                renderInput={(params) => <TextField style={{ width: '20vw' }} size='small' {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box className='eMed_rts_dropdown'>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker
                label={t('SelectTo')}
                inputFormat='DD-MM-YYYY'
                value={this.state.endDate}
                name="endDate"
                onChange={(newDate) => { this.setState({ endDate: newDate.$d }) }}
                renderInput={(params) => <TextField style={{ width: '20vw' }} size='small' {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              multiple
              limitTags={1}
              size='small'
              options={paymentStatusList?.map((item) => item)}
              getOptionLabel={(option) => (option?.label)}
              value={this.state.paymentStatus}
              onChange={(event, newValue) => {
                this.setState({ paymentStatus: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("selectPayment")}
                />
              )}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            onClick={() => {
              this.setState({
                companyName: [], startDate: null,
                endDate: null, paymentStatus: [],
                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                dateIndex: 1,
                FilterDatas: {
                  "startTime": null,
                  "endTime": null,
                  "onError": false
                },
                timeError: false,
              }, () => this.getCorporatePaymentData())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small"
          disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
            onClick={() => {
              this.getCorporatePaymentData()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let TextArray = [
        {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
        {label:"To Date", value:formatDate(this.state.FilteredToDate)},
    ]
    if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
      TextArray.push(
          { label: "Start Time", value: timeOnlyConvert(setStartTime) },
          { label: "End Time", value: timeOnlyConvert(setEndTime) }
      )
  }
    return(
      <Box>
         <CommonGridToolBarWithFilterText FilterTextArray={TextArray}/>
      </Box>

    )
  }
  render() {
    const { t } = this.props
    this.state.corporatePaymentData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "company_name", headerName: t("CorporateName"), flex: 0.166,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.company_name ? params?.row?.company_name?.length > 20 ?
          <Tooltip placement='top' title={params?.row?.company_name} arrow><div>{params?.row?.company_name.slice(0, 20)}</div></Tooltip> : params?.row?.company_name : "-"}</Box>)
      },
      {
        field: "invoice_from_date", headerName: t("FromDate"), flex: 0.166, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_from_date ? formatDate(params?.row?.invoice_from_date) : "-"}</Box>)
      },
      {
        field: "invoice_to_date", headerName: t("ToDate"), flex: 0.166, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_to_date ? formatDate(params?.row?.invoice_to_date) : "-"}</Box>)
      },
      {
        field: "patient_count", headerName: t("NoPatient"), flex: 0.166, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_count ? params?.row?.patient_count : "-"}</Box>)
      },
      {
        field: "amount", headerName: `${t("Amount")} (${CurrencySymbol})`, flex: 0.166, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.amount ? AmountFormat(params?.row?.amount).replace(`${CurrencySymbol}`, "") : "-"}</Box>)
      },
      {
        field: "status", headerName: t("PaymentStatus"), flex: 0.166,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.status ? params?.row?.status : "-"}</Box>)
      }
    ]
    return (
      <Box component={'div'} className='eMed_rts_container'>
        <Box component={"div"} className='eMed_Top_Container'>
          <Box component={"div"} className='eMed_Cards_Container'>
            {AmountsCard(t("TotalAmount"), this.state.corporatePaymentTotal)}
            {AmountsCard(t("CorporatePayable"), this.state.corporatePaymentPayable)}
          </Box>
          <Box component={"div"} className='eMed_Action_Container'>
            <Box component={"div"} className='eMed_Filter_Icon_div'>
              <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
              </Button>
            </Box>
            {/* <Button className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button>
            <Button className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
            </Button> */}
          </Box>
        </Box>
        <Box component={'div'} className='eMed_rts_table'>
          <DataGrid
            rows={this.state.corporatePaymentData}
            columns={columns}
            getRowId={(row) => row.sno}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }
}


export default withTranslation()(CopPaymentReport)
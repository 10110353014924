import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Box, Button, Checkbox, Chip, FormControlLabel, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../Components/Loader';
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Ages } from '../../../../Utility/Constants'


export default class DocDevelopmentAsses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      ages: Ages,
      selectAge: '6 Months',
      comments: '',
      devAssessData: {},
      gross_Motor: [],
      languages: [],
      personal_Social: [],
      fine_Motor: [],
      abnormal: [],
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getDevelopmentAssess()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDevelopmentAssess = () => {
    try {
      RestAPIService.getAll(Serviceurls.PD_DEVELOP_ASSESS + '?appointment_id=' + this.state.appointmentId + '&age_range=' + this.state.selectAge)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let data = response?.data?.data
            this.setState({
              devAssessData: data,
              gross_Motor: data?.developmental_assessment['Gross Motor'],
              languages: data?.developmental_assessment['Language'],
              personal_Social: data?.developmental_assessment['Personal - Social'],
              fine_Motor: data?.developmental_assessment['Fine Motor - Adaptive'],
              comments: data?.comments,
              abnormal: data?.developmental_assessment['abnormalities']

            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  postDevelopmentAssess = () => {
    try {
      var states = this.state;
      let developmental_assessment = [...states.gross_Motor, ...states.languages, ...states.personal_Social, ...(states.fine_Motor ? states.fine_Motor : [])];

      var data = {
        "appointment_id": states.appointmentId,
        "abnormalies": "",
        "age_range": states.selectAge,
        "comments": states.comments,
        "developmental_assessment": developmental_assessment
      };
      RestAPIService.create(data, Serviceurls.PD_DEVELOP_ASSESS_POST)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getDevelopmentAssess()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderImg = (List) => {
    var states = this.state
    try {
      return (
        <Box component={'div'} width={'60vw'} height={'61vh'} border={'1px solid lightgray'} backgroundColor={'white'} position="relative">
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Development Assessment</Typography>
          </Box>
          <Box component={'div'} display={'flex'} flexDirection={'column'} m={'.5vw'}>
            <Box component={'div'} style={{ flex: 1, position: 'relative' }}>
              <img style={{ width: '59vw', height: '53.5vh', objectFit: 'cover' }} src={ImagePaths.Assessment.default} alt='img' />
              <Box position="absolute" top={0} left={0} zIndex={1} display={'flex'} flexDirection={'column'}>
                {states.gross_Motor ? states.gross_Motor.map((item, index) => (
                  <FormControlLabel
                    sx={{ marginBottom: '-1vw' }}
                    control={<Checkbox size='small'
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '1vw' } }}
                      checked={item?.assessment_value}
                      onChange={(e) => {
                        var { gross_Motor } = this.state;
                        gross_Motor[index].assessment_value = !item.assessment_value
                        this.setState({ gross_Motor })
                      }} />}
                    label={<Typography sx={{ fontSize: '.8vw' }}>{item?.assessment}</Typography>} />
                )) : null}
              </Box>
              <Box position="absolute" top={0} right={0} zIndex={1} display={'flex'} flexDirection={'column'}>
                {states.languages ? states.languages.map((item, index) => (
                  <FormControlLabel
                    sx={{ marginBottom: '-1vw' }}
                    control={<Checkbox size='small'
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '1vw' } }}
                      checked={item?.assessment_value}
                      onChange={(e) => {
                        var { languages } = this.state;
                        languages[index].assessment_value = !item.assessment_value
                        this.setState({ languages })
                      }} />}
                    labelPlacement="start"
                    label={<Typography sx={{ fontSize: '.8vw' }}>{item?.assessment}</Typography>} />
                )) : null}
              </Box>
              <Box position="absolute" top="43%" left="47%" transform="translate(-43%, -47%)" zIndex={1} display={'flex'} flexDirection={'column'}>
                <Typography fontSize={'0.8vw'} display={'flex'} justifyContent={'center'}>Age</Typography>
                <Typography fontSize={'0.8vw'} fontWeight={600}>{this.state.selectAge ? this.state.selectAge : '-'}</Typography>
              </Box>
              <Box position="absolute" bottom={0} left={0} zIndex={1} display={'flex'} flexDirection={'column'}>
                {states.personal_Social ? states.personal_Social.map((item, index) => (
                  <FormControlLabel
                    sx={{ marginTop: '-1vw' }}
                    control={<Checkbox size='small'
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '1vw' } }}
                      checked={item?.assessment_value}
                      onChange={(e) => {
                        var { personal_Social } = this.state;
                        personal_Social[index].assessment_value = !item.assessment_value
                        this.setState({ personal_Social })
                      }} />}
                    label={<Typography sx={{ fontSize: '.8vw' }}>{item?.assessment}</Typography>} />
                )) : null}
              </Box>
              <Box position="absolute" bottom={0} right={0} zIndex={1} display={'flex'} flexDirection={'column'}>
                {states.fine_Motor ? states.fine_Motor.map((item, index) => (
                  <FormControlLabel
                    sx={{ marginTop: '-1vw' }}
                    control={<Checkbox size='small'
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '1vw' } }}
                      checked={item?.assessment_value}
                      onChange={(e) => {
                        var { fine_Motor } = this.state;
                        fine_Motor[index].assessment_value = !item.assessment_value
                        this.setState({ fine_Motor })
                      }} />}
                    labelPlacement="start"
                    label={<Typography sx={{ fontSize: '.8vw' }}>{item?.assessment}</Typography>} />
                )) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  checkIsAbnormalities(age) {
    var { abnormal } = this.state;
    if (abnormal && abnormal.length > 0) {
      var checkAge = abnormal.includes(age)
      return checkAge
    } else {
      return false
    }
  }
  renderRightSide = (list) => {
    try {
      return (
        <Box component={'div'} width={'32vw'} ml={'0.5vw'} height={'61vh'} border={'1px solid lightgray'} backgroundColor={'white'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Development Assessment</Typography>
          </Box>
          <Box component={'div'} height={"49.5vh"} overflow={'scroll'}>
            <Typography p={'.5vw'} fontSize={'o.9vw'} fontWeight={600}>Select Age</Typography>
            <Box component={'div'} p={'.5vw'}>
              {this.state.ages?.map((item, index) => {
                var checkIsAbnormalitiesFlag = false
                checkIsAbnormalitiesFlag = this.checkIsAbnormalities(item.value)
                return (
                  <Chip
                    className={`eMed_chip ${item.label === this.state.selectAge ? (checkIsAbnormalitiesFlag ? 'eMed_Chip_redOnClick' : 'eMed_Chip_OnClick') : (checkIsAbnormalitiesFlag ? 'eMed_Chip_redBorder' : 'eMed_Chip_Border')}`}
                    label={item.label}
                    variant={item.label === this.state.selectAge ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      this.setState({ selectAge: item?.value }, () => { this.getDevelopmentAssess() })

                    }}
                    // color={'primary'}
                    clickable
                  />
                )
              })}
            </Box>
            <Box border={'1px solid lightgray'}></Box>
            <Typography p={'.5vw'} fontSize={'o.9vw'} fontWeight={600}>Comments</Typography>
            <Box component={'div'} p={'.5vw'}>
              <TextField
                multiline
                rows={2}
                sx={{ width: '30vw' }}
                label='Comments'
                value={this.state.comments}
                onChange={(e) => {
                  let value = e.target.value
                  if (value?.length <= 250) {
                    this.setState({ comments: e.target.value })
                  } else {
                    this.errorMessage('Allowed Only 250 Characters')
                  }
                }}
              />
            </Box>
          </Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} justifyContent={'center'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
            <Stack spacing={2} direction="row">
              {/* <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined">Clear</Button> */}
              <Button size='small' sx={{ textTransform: 'capitalize' }} onClick={() => {
                this.postDevelopmentAssess()
              }} variant="contained">Add</Button>
            </Stack>
          </Box>
        </Box>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'78vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} >
          <Box component={'div'} >
            {this.renderImg()}
          </Box>
          <Box component={'div'} >
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}


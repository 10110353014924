import React, { Component } from 'react';
import "./PharmaSettings.css";
import { Box, IconButton, Grid, Switch, Stack, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import Loader from '../../../Components/Loader';

class PharmaUserConfig extends Component {
  constructor() {
    super()
    this.state = {
      active_status: "False",
      PharmauserConfigData: [],
      page: 0,
      pageSize: 10
    }
  }

  componentDidMount() {
    this._getUserConfigData()
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  _getUserConfigData = () => {
    const { t } = this.props
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.PHARMA_USER_CONFIG)
        .then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              PharmauserConfigData: response.data.data
            }, () => {
              this.state.PharmauserConfigData.forEach((element, index) => element["sno"] = index + 1)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postUserConfigData = (login_id) => {
    try {
      this.LoaderFunction(true);
      let datas = {
        "login_id": login_id,
        "active_status": this.state.active_status
      }
      RestAPIService.create(datas, Serviceurls.PHARMA_USER_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.successMessage(response.data.message)
            this._getUserConfigData()
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    const { t } = this.props
    const columns = [
      { field: "sno", sortable: false, headerName: t('SNo'), flex: 0.06 },
      {
        field: "role", headerName: t('role'), flex: 0.19,
        renderCell: ({ row }) => (row?.role?.length > 25 ?
          <Tooltip placement="top" title={row?.role} arrow><div>{row?.role.slice(0, 24) + "..."}</div></Tooltip>
          : <Box component={'div'}>{row?.role ? row?.role : "-"}</Box>)
      },
      {
        field: "mailid", headerName: t('Email'), flex: 0.17,
        renderCell: ({ row }) => (row?.mailid?.length > 20 ?
          <Tooltip placement="top" title={row?.mailid} arrow><div>{row?.mailid.slice(0, 18) + "..."}</div></Tooltip>
          : <Box component={'div'}>{row?.mailid ? row?.mailid : "-"}</Box>)
      },
      {
        field: 'mobile', headerName: t('MobileNumber'), flex: 0.17, align: "center", headerAlign: "center",
        renderCell: ({ row }) => (row?.mobile?.length > 20 ?
          <Tooltip placement="top" title={row?.mobile} arrow><div>{row?.mobile.slice(0, 18) + "..."}</div></Tooltip>
          : <Box component={'div'}>{row?.mobile ? row?.mobile : "-"}</Box>)
      },
      {
        field: "username", headerName: t('userName'), flex: 0.19,
        renderCell: ({ row }) => (row?.username?.length > 25 ?
          <Tooltip placement="top" title={row?.username} arrow><div>{row?.username.slice(0, 24) + "..."}</div></Tooltip>
          : <Box component={'div'}>{row?.username ? row?.username : "-"}</Box>)
      },
      {
        field: 'lock', headerName: t('lockunlock'), flex: 0.11,
        renderCell: ({ row }) => (<Stack component={'div'}>
          <Switch checked={row.login_count !== 6 ? true : false} disabled={false} />
        </Stack>)
      },
      {
        field: 'action', headerName: 'Action', flex: 0.09, headerAlign: "center", align: "center", sortable: false, hideable: false,
        renderCell: ({ row }) => (
          <Tooltip placement='top' title={row?.login_count !== 6 ? "Lock" : "Unlock"} arrow>
            <IconButton emed_tid="usrconf_lock_unlock" className='eMed_usrconf_img' size='small'
              onClick={() => {
                this.setState(
                  { active_status: row?.login_count !== 6 ? "False" : "True" },
                  () => { this.postUserConfigData(row?.id) })
              }}
            >
              <img src={ImagePaths.LockBtn.default} alt='lock' />
            </IconButton>
          </Tooltip>)
      }
    ]
    return (
      <Box component={"div"} className="eMed_pharma_usrconfig_container" >
        <Grid container>
          <Grid item xs={12} className="eMed_pharma_usrconfig_tbl">
            <DataGrid
              className="eMed_usrConf_tbl"
              rows={this.state.PharmauserConfigData}
              columns={columns}
              getRowId={(row) => row.id}
              page={this.state.page}
              pageSize={this.state.pageSize}
              onPageChange={(newPage) => this.setState({ page: newPage })}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
            />
          </Grid>
        </Grid>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(PharmaUserConfig);

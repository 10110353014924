import React, { Component } from 'react';
import './reports.css';
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { DateTime } from 'luxon';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Trans, withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from '../../../Utility/Constants';


class LabPatientReport extends Component {
  state = {
    pageSize: 10,
    isErrorMsg: false,
    isErrorMsgText: '',
    refReportList: [],
    totalBillAmount: 0,
    totalPatient: 0,
    fromDate: "",
    toDate: "",
    dateFilter: 1,
    QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    searchkey : '',
    disableBtn: false,
  }

  componentDidMount() {
    this.getPatientReport()
  }

  // componentWillReceiveProps(props){
  //   if(props.search_key != this.state.searchkey){
  //       this.setState({
  //           searchkey: props.search_key ? props.search_key : ''
  //       },() => {
  //           this.getPatientReport()
  //       })
  //   }
  // }

  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      isSuccessMsg: false,
      isSuccessMsgText: "",
    })
}

  CustomDates = (FromDate, ToDate) => {
    this.setState({
      QueryFromDate: FromDate,
      QueryToDate: ToDate,
      datePickerPop: false
    }, () => this.getPatientReport())
  }
  PopClose = () => {
    this.setState({
      datePickerPop: false,
      QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateFilter: 1,
    }, () => this.getPatientReport())
  }

  handleDate = () => {
    var today = DateTime.now().toFormat('yyyy-MM-dd')
    var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
    var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
    var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
    var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
    var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
    const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
    const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');



    if (this.state.dateFilter === 0) {
      this.setState({
        QueryFromDate: "",
        QueryToDate: "",
      }, () => this.getPatientReport())
    }
    else if (this.state.dateFilter === 1) {
      this.setState({
        QueryFromDate: today,
        QueryToDate: today,
      }, () => this.getPatientReport()
      )

    }
    else if (this.state.dateFilter === 2) {
      this.setState({
        QueryFromDate: yesterday,
        QueryToDate: yesterday,
      }, () => this.getPatientReport())
    }
    else if (this.state.dateFilter === 3) {
      this.setState({
        QueryFromDate: startOfPreviousWeek,
        QueryToDate: endOfPreviousWeek,
      }, () => this.getPatientReport())
    }
    else if (this.state.dateFilter === 4) {
      this.setState({
        QueryFromDate: startOfPreviousMonth,
        QueryToDate: endOfPreviousMonth,
      }, () => this.getPatientReport())
    }
    else if (this.state.dateFilter === 5) {
      this.setState({ datePickerPop: true })
    }
  }

  DateHelperText = () => {
    if (this.state.dateFilter === 0) {
      return ""
    }
    else if (this.state.dateFilter === 1) {
      return DateTime.now().toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 2) {
      return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 3) {
      // return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
      const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
       return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      
    }
    else if (this.state.dateFilter === 4) {
      // return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
      const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
      return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`

    }
    else if (this.state.dateFilter === 5) {
      return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
    }
  }
  LoaderFunction = (key) => {
    this.setState({
        isLoader: key
    })
}
  getPatientReport = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_REPORT + "?from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate + "&search_key=" + this.state.searchkey)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              refReportList: response.data.data,
              totalBillAmount: response.data.total_bill_amount,
              totalPatient: response.data.total_patient
            },()=>{this.LoaderFunction(false)})
          }
        }).catch(e => {
          this.LoaderFunction(false)
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message
          })
        })

    } catch (e) {
      this.LoaderFunction(false)
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  getPatientReportPrint = () => {
    const {t} = this.props
    try {
      this.setState({disableBtn: true})
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_REPORT_PRINT + "?from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate + "&search_key=" + this.state.searchkey + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.reponse.data.message,
            disableBtn: false,
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      })
    }
  }

  getPatientReportexcel = () => {
    try {
      this.setState({disableBtn: true})
      RestAPIService.excelGet(Serviceurls.LAB_PATIENT_REPORT_PRINT + "?export_type=excel" +  "&from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate + "&search_key=" + this.state.searchkey).
        then((response) => {
          if(response.data){
          var pom = document.createElement('a');
          var csvContent = response.data; //here we load our csv data 
    
          let filename = response.headers["content-disposition"].split("filename=")[1]
          // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});
    
          var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', filename ? filename : 'registry.xlsx');
          pom.click();
          this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      })
    }
  }

  render() {
    const {t} = this.props
    this.state.refReportList.forEach((element,index)=>element.sno = index + 1)
    let states = this.state
    const columns = [
      {field: 'sno', headerName: t("SNo"), sortable: false, flex: 0.05},
      { field: 'mobile_number', headerName: t('MobileNumber'), sortable: false, flex: 0.125,
      renderCell:(parmas => (<div>{parmas?.row?.mobile_number?.length > 20 ? <Tooltip title={parmas.row.mobile_number} arrow><div>{parmas?.row?.mobile_number.slice(0,20) + "..."}</div></Tooltip> : parmas?.row?.mobile_number ? parmas?.row?.mobile_number : "-"}</div>)) },
      { field: 'patient_name', headerName: t('PatientName'), sortable: false, flex: 0.125,
      renderCell:(parmas => (<div>{(parmas?.row?.patient_name + parmas?.row?.surname)?.length > 15 ? <Tooltip title={parmas.row.patient_name + parmas?.row?.surname} arrow><div>{(parmas?.row?.patient_name + parmas?.row?.surname).slice(0,15) + "..."}</div></Tooltip> : parmas?.row?.patient_name ? parmas?.row?.patient_name + " "+ parmas?.row?.surname : "-"}</div>)) },
      {field: 'patient_account_numbers', headerName: t("UHID"), sortable: false, flex: 0.143, headerAlign: "center", align: "center",
        renderCell: (params) => (<div>{params?.row?.patient_account_numbers ? params?.row?.patient_account_numbers : "-"}</div>)},
      { field: 'email', headerName: t('Email'), sortable: false, flex: 0.13,
       renderCell:(parmas => (<div>{parmas?.row?.email?.length > 20 ? <Tooltip title={parmas.row.email} arrow><div>{parmas?.row?.email.slice(0,20) + "..."}</div></Tooltip> : parmas?.row?.email ? parmas?.row?.email : "-"}</div>)) },
      { field: 'city_name', headerName: t('City'), sortable: false, flex: 0.125,
      renderCell:(parmas => (<div>{parmas?.row?.city_name?.length > 20 ? <Tooltip title={parmas.row.city_name} arrow><div>{parmas?.row?.city_name.slice(0,20) + "..."}</div></Tooltip> : parmas?.row?.city_name ? parmas?.row?.city_name : "-"}</div>)) },
      { field: 'reffered_by', headerName: t('Referred By'), sortable: false, flex: 0.125,
      renderCell:(parmas => (<div>{parmas?.row?.reffered_by?.length > 20 ? <Tooltip title={parmas.row.reffered_by} arrow><div>{parmas?.row?.reffered_by.slice(0,20) + "..."}</div></Tooltip> : parmas?.row?.reffered_by ? parmas?.row?.reffered_by : "-"}</div>)) },
      { field: 'bill_amount', headerName: t('Bill Amount') + ` (${CurrencySymbol})`, sortable: false, flex: 0.15 ,type:"number",
      renderCell:(parmas => (<div>{parmas?.row?.bill_amount?.length > 20 ? <Tooltip title={parmas.row.bill_amount} arrow><div>{parmas?.row?.bill_amount.slice(0,20) + "..."}</div></Tooltip> : parmas?.row?.bill_amount ? AmountFormat(parmas?.row?.bill_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</div>))}
    ]
    return (
      <div className='reports_container'>
        <div className='reports_header_card'>
          <div className='reports_left'>
            <div className=''>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("TotalBillAmount"),states.totalBillAmount)}
              </div>
            </div>
            <div className=''>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("Total Bills"),states.totalPatient,true)}
              </div>
            </div>
          </div>
          <div className='reports_right'>
            <div className='report_date_picker'>
              <div className='eMed_rts_date_pick'>
                <FormControl >
                  <InputLabel>{t("SelectDate")}</InputLabel>
                  <Select sx={{  height: '2.2vw', cursor: "pointer",marginRight:"1vw",width:"10vw"}}
                    size='small'
                    onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                    value={this.state.dateFilter}
                    labelId="demo-simple-select-required-label"
                    label={t("SelectDate")}
                  >
                    {/* <MenuItem value={0}>{t("All")}</MenuItem> */}
                    <MenuItem value={1}>{t("Today")}</MenuItem>
                    <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                    <MenuItem value={3}>{t("Lastweek")}</MenuItem>
                    <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                    <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
                  </Select>
                  <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                </FormControl>
              </div>
              {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}

            </div>
              <img className='reports_print' emed_tid='Patient_Report_Print_btn' src={ImagePaths.LabPrintIcon.default} onClick={()=>{
                if(!this.state.disableBtn){
                  this.getPatientReportPrint()
                }}} alt='print'/>
              <img className='eMed_rts_upload' emed_tid='Patient_Report_Print_btn' src={ImagePaths.LabUploadButton.default} onClick={()=>{
                if(!this.state.disableBtn){
                  this.getPatientReportexcel()
                }}} alt='upload' />
          </div>
        </div>

        <div className='reports_table_card'>
          <DataGrid
            rows={this.state.refReportList}
            columns={columns}
            hideFooterSelectedRowCount
            getRowId={(row) => row.sno}
            loading={this.state.isLoader}
            pageSize={this.state.pageSize}
            onPageSizeChange={(newPageSize) => {
              this.setState({ pageSize: newPageSize })
            }}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay:()=>(
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            headerHeight={40}
          />
        </div>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : this.state.isSuccessMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.isSuccessMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
      </div>
    )
  }
}

export default withTranslation()(LabPatientReport)
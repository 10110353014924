import React, { Component } from "react";
import {
    Box, Button, Stack, InputLabel,
    MenuItem, Select, TextField, Modal,
    FormControl, RadioGroup, Radio, FormControlLabel, Tooltip, Autocomplete, IconButton, Typography, InputAdornment
} from "@mui/material";
import { Trans, withTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import { Colors } from "../../../Styles/Colors";
import Loader from '../../../Components/Loader';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

class AddNewIOL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iolTypeSuggestion: [],
            BrandName: "",
            ModelName: "",
            IolType: null,
            Power_IOL: "",
            IOL_type: true,
            expiryDate: null,
            batchNo: "",
            Qty: "",
            boxNo: "",
            MrpQty: ""
        }
    }

    componentDidMount = () => {
        this.getStockList()
    }

    getStockList = () => {
        try {
            RestAPIService.getAll(Serviceurls.OT_INVENTORY_IOL_TYPE_SUGGESTION).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            iolTypeSuggestion: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    changeDateFormate = (date) => {
        const originalDate = new Date(date);

        const day = originalDate.getDate().toString().padStart(2, '0');
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
        const year = originalDate.getFullYear().toString();

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate
    }

    PostNewIOL = () => {
        try {
            var data = {
                products: [{
                    "brand_name": this.state.BrandName,
                    "model_name": this.state.ModelName,
                    "iol_type_id": parseInt(this.state.IolType),
                    "iol_power": this.state.Power_IOL ? this.state.IOL_type ? `+${this.state.Power_IOL}` : `-${this.state.Power_IOL}` : null,
                    "batch_number": this.state.batchNo,
                    "stock_in_quantity": this.state.Qty,
                    "expiry_date": this.changeDateFormate(this.state.expiryDate),
                    "physical_box_number": this.state.boxNo,
                    "mrp_price_per_qty": this.state.MrpQty ? this.state.MrpQty : 0,
                }]
            }
            this.setState({ disablePost : true})
            RestAPIService.create(data, Serviceurls.OT_IOL_STOCK_LIST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.onClearTableData()
                        this.setState({ disablePost : false})
                        this.props.history.push({ pathname: "/OTInventory" })
                        this.successMessage(response.data.message)
                    } else {
                        this.setState({ disablePost : false})
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disablePost : false})
                })
        } catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onClearTableData = () => {
        this.setState({
            BrandName: "",
            ModelName: "",
            IolType: null,
            Power_IOL: "",
            IOL_type: true
        })
    }

    render() {
        return (
            <Box component={'div'} height={'85vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} height={'5vh'} display={'flex'} alignItems={'center'} sx={{ backgroundColor: 'white', borderBottom: '1px solid lightgray' }}>
                    <IconButton onClick={() => {
                        this.props.history.push({ pathname: '/OTInventory' })
                    }}>
                        <ChevronLeftIcon color='primary' />
                    </IconButton>
                    <Typography color={Colors.SecondaryText} fontWeight={600}>{'Back To Intraocular Drug'}</Typography>
                </Box>
                <Box component={'div'} display={'flex'} height={'80vh'}>
                    <Box component={'div'} border={'1px solid lightgray'} style={{ width: "100%", padding: "1.5vw" }}>
                        <Box component={'div'} sx={{ backgroundColor: 'white', marginTop: "1vw" }} height={'5vh'} display={'flex'} alignItems={'center'} pl={"1vw"}>
                            <Typography fontWeight={600}>New Intraocular Drug</Typography>
                        </Box>
                        <Box component={'div'} sx={{ backgroundColor: 'white', paddingLeft: "1vw" }} p={'1vw'} height={'60vh'}>
                            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                <TextField
                                    inputProps={{ maxLength: 30 }}
                                    variant="standard"
                                    sx={{ width: "15vw" }}
                                    size='small'
                                    label={"Brand Name"}
                                    value={this.state.BrandName}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            BrandName: e.target.value
                                        })

                                    }}
                                />
                                <TextField
                                    inputProps={{ maxLength: 30 }}
                                    variant="standard"
                                    sx={{ width: "15vw", marginLeft: "1.5vw" }}
                                    size='small'
                                    label={"Model Name"}
                                    value={this.state.ModelName}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            ModelName: e.target.value
                                        })

                                    }}
                                />
                                <TextField
                                    inputProps={{ maxLength: 30 }}
                                    select
                                    variant="standard"
                                    sx={{ width: "15vw", marginLeft: "1.5vw" }}
                                    size='small'
                                    label={"IOL Type"}
                                    value={this.state.IolType}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            IolType: e.target.value
                                        })
                                    }}
                                >
                                    {this.state.iolTypeSuggestion.map((option) => (
                                        <MenuItem key={option.iol_type_id} value={option.iol_type_id}>
                                            {option.iol_type}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    placeholder={'IOL Power'}
                                    sx={{ width: "15vw", marginLeft: "1.5vw", marginTop: "1vw" }}
                                    size="small"
                                    name={'Power_IOL'}
                                    variant="standard"
                                    value={this.state.Power_IOL}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            IOL_type: !this.state.IOL_type
                                                        });
                                                    }}
                                                >
                                                    {this.state.IOL_type ? (
                                                        <img sx={{ width: '1.3vw', height: '1.3vw' }} src={ImagePaths.PlusIcon.default} alt="Plus Icon" />
                                                    ) : (
                                                        <img sx={{ width: '1.3vw', height: '1.3vw' }} src={ImagePaths.MinusIcon.default} alt="Minus Icon" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        const isValidDecimal = CommonValidation.DecimalNumber(value);
                                        if (isValidDecimal && (+value < 100 || value === '')) {
                                            this.setState({
                                                [name]: value === '' ? '' : value,
                                            });
                                        }
                                    }}
                                />
                                <TextField
                                    inputProps={{ maxLength: 30 }}
                                    variant="standard"
                                    sx={{ width: "15vw", marginLeft: "1.5vw" }}
                                    size='small'
                                    label={"Batch No"}
                                    value={this.state.batchNo}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            batchNo: e.target.value
                                        })
                                    }}
                                />
                                <TextField
                                    inputProps={{ maxLength: 30 }}
                                    variant="standard"
                                    sx={{ width: "15vw", marginTop: "1vw" }}
                                    size='small'
                                    label={"Quantity"}
                                    value={this.state.Qty}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let isValid = CommonValidation.numberOnly(e.target.value)
                                        if ((isValid) || e.target.value == "") {
                                            this.setState({
                                                Qty: e.target.value
                                            })
                                        }
                                    }}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={this.state.expiryDate ? this.state.expiryDate : null}
                                        inputFormat='DD-MM-YYYY'
                                        label={"Expiry Date"}
                                        minDate={new Date()}
                                        onChange={
                                            (newDate) => {
                                                this.setState({
                                                    expiryDate: newDate.$d
                                                })
                                            }}
                                        renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} variant="standard" style={{ width: "15vw", marginTop: "1vw", marginLeft: "1.5vw" }} size='small' {...params} />}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    inputProps={{ maxLength: 30 }}
                                    variant="standard"
                                    sx={{ width: "15vw", marginLeft: "1.5vw", marginTop: "1vw" }}
                                    size='small'
                                    label={"BOX"}
                                    value={this.state.boxNo}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            boxNo: e.target.value
                                        })

                                    }}
                                />
                                <TextField
                                    inputProps={{ maxLength: 8 }}
                                    variant="standard"
                                    sx={{ width: "15vw", marginLeft: "1.5vw", marginTop: "1vw" }}
                                    size='small'
                                    label={"MRP/Qty"}
                                    value={this.state.MrpQty}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let isValid = CommonValidation.numberOnly(e.target.value)
                                        if ((isValid) || e.target.value == "") {
                                            this.setState({
                                                MrpQty: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </Box>
                        <Box component={'div'} display={'flex'} sx={{ backgroundColor: "white", width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
                            <Box display={'flex'} alignItems={"center"}>
                                <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: "10vw" }}
                                    disabled={this.state.isForReturn ? false : this.state.isFromInvoice}
                                    size='small'
                                    variant='outlined'
                                    onClick={() => this.onClearTableData()}
                                >Clear</Button>
                                <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: "10vw" }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => { this.PostNewIOL() }}
                                    disabled={this.state.disablePost}
                                >{'Save'}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }

}
export default withTranslation()(AddNewIOL);
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Box, Button, Card, Paper, Stack, TextField, Typography, FormControlLabel,
  Switch, Drawer, Autocomplete, Menu, FormGroup, Checkbox
} from '@mui/material';
import './DoctorNotes.css';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../../Components/Common Components/CommonComponents';
import { Colors } from '../../../../../Styles/Colors';
import SymptomsLeft from './DocNotesComponents/SymptomsLeft';
import ExaminationLeft from './DocNotesComponents/ExaminationLeft';
import ExaminationRight from './DocNotesComponents/ExaminationRight';
import SymptomsRight from './DocNotesComponents/SymptomsRight'
import DiagnosisRight from './DocNotesComponents/DiagnosisRight';
import DiagnosisLeft from './DocNotesComponents/DiagnosisLeft';
import ProvDiagnosisLeft from './DocNotesComponents/ProvDiagnosisLeft';
import ProvDiagnosisRight from './DocNotesComponents/ProvDiagnosisRight';
import '../IpPatientDetails.css'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import WriteNotes from './DocNotesComponents/WriteNotes';
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions';
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import { Ip_Doc_PRINT_DEFAULT } from '../../../../../Utility/Constants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const IPdoctorNotesTabs = [
  { Label: "Symptoms", value: "isSymptoms" },
  { Label: "Examination", value: "isExamination" },
  { Label: "Provisional Diagnosis", value: "isProvDiagnosis" },
  { Label: "ICD 10 Code Diagnosis", value: "isDiagnosis" },
  { Label: "Discharge Notes", value: "isDischargeNotes" },
  { Label: "Family Counselling", value: "isFamilyCounselling" },
  { Label: "Medication & Instructions", value: "isMedicalInstruction" },
]

const buttonViewList = new Set(["isDischargeNotes","isFamilyCounselling","isMedicalInstruction", "isWriteNotes", "isProvDiagnosis"]);
class DoctorNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ipPatientData: {},
      SelectedComponent: IPdoctorNotesTabs[0]?.value,
      IPadmissionDetails: {},
      CallGetComponent: "",
      EditSymptomsData: {},
      EditExaminationsData: {},
      EditProvDiagnosisData: "",
      withOutAppointment : {},
      clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
      isNotesClrClked: false,
      isNotesSaveClked: false,
      isWriteNotes: false,
      // filter
      filterOpen: false,
      fromDate: null,
      toDate: null,
      selectedDoctor: [],
      DoctorsList: [],
      mapped_doc_list: [],
      write_notes_txt: {},
      write_notes: [],
      discharge_notes_txt: {},
      discharge_notes: [],
      family_counselling_txt: {},
      family_counselling: [],
      instructions_txt: {},
      instructions: [],
      doctorPrintList: JSON.parse(JSON.stringify(Ip_Doc_PRINT_DEFAULT)),
      isFltClrClked: false,
      isFltSrcClked: false,
    }
  }

  fetchData = () => {
    this.getPatientData();
    this.getNotesData();
    this.getMappedDoctorList();
  }

  componentDidMount() {
    let ipPatientData = getCachevalue("IppatientData")
    let patientData = JSON.parse(ipPatientData)
    let appointmentDetail = getCachevalue("withoutAppointment");

    const currentDate = new Date();
    const admissionDate = patientData?.admission_date ? new Date(patientData.admission_date) : currentDate;
    let dischargeDate = patientData?.discharge_date ? new Date(patientData.discharge_date) : currentDate;

    // Ensure discharge date is not in the future
    if (dischargeDate > currentDate) {
      dischargeDate = currentDate;
    }

    // Format the dates
    const formattedAdmissionDate = DateTime.fromJSDate(admissionDate).toFormat('yyyy-MM-dd');
    const formattedDischargeDate = DateTime.fromJSDate(dischargeDate).toFormat('yyyy-MM-dd');

    let details = JSON.parse(appointmentDetail);
    const newState = {
      ipPatientData: patientData,
      fromDate: formattedAdmissionDate,
      toDate: formattedDischargeDate
    };

    if (details?.patient_id === patientData?.patient_id) {
      newState.withOutAppointment = details;
    }

    this.setState(newState, () => {
      this.fetchData();
    });
  }

  getPatientData = () => {
    let admission_id =   this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${admission_id}`;
    if (this.props.is_From_IpNotes && this.state.clinic_id) {
      url = `${url}&clinic_id=${this.state.clinic_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              IPadmissionDetails: response.data.data[0]
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getNotesData() {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    let login_doctor_id = myUser?.doctor_id;
    try {
      RestAPIService.getAll(Serviceurls.IP_DOC_WRITE_NOTES + "?ip_admission_id=" + admission_id).
        then((response) => {
          if (response.data.status === "success") {
            let data = response.data.data?.length > 0 ? response.data.data : [];
            let result = data.reduce((acc, cur) => {
              const assignNote = (key) => {
                if (cur[key]) {
                  const note = {
                    id: cur.id,
                    notes: cur[key],
                    doctor_id: cur.doctor_id,
                    doctor__photo_url: cur.doctor__photo_url,
                    doctor_speciality: cur.doctor_speciality,
                    doctor_name: cur.doctor_name,
                    date: cur.date
                  };
                  const check_doc_id = this.props.is_From_IpNotes ? (cur?.doctor_id === login_doctor_id)
                  : (cur?.doctor_id === this.state.ipPatientData?.doctor_id);
                  if (check_doc_id) {
                    acc[`${key}_txt`] = note;
                  } else {
                    acc[key].push(note);
                  }
                }
              };

              assignNote('write_notes');
              assignNote('discharge_notes');
              assignNote('family_counselling');
              assignNote('instructions');

              return acc;
            }, {
              write_notes_txt: {},
              write_notes: [],
              discharge_notes_txt: {},
              discharge_notes: [],
              family_counselling_txt: {},
              family_counselling: [],
              instructions_txt: {},
              instructions: [],
            });

            this.setState({
              write_notes_txt: result.write_notes_txt,
              write_notes: result.write_notes,
              discharge_notes_txt: result.discharge_notes_txt,
              discharge_notes: result.discharge_notes,
              family_counselling_txt: result.family_counselling_txt,
              family_counselling: result.family_counselling,
              instructions_txt: result.instructions_txt,
              instructions: result.instructions,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getMappedDoctorList = () => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    let url = `${Serviceurls.IP_MAPPED_DOCTORS}?ip_admission_id=${admission_id}`;
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              mapped_doc_list: response.data.data
            }, () => {
              this.getDoctorsList();
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorsList() {
    try {
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"));
      let DoctorClinicId = localGetItem('SelectedDoctorClinicId');
      let { mapped_doc_list } = this.state;
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?clinic_id=${UserData?.clinic_id ? UserData?.clinic_id : DoctorClinicId}`).
        then((response) => {
          if (response.data.status === "success") {
            let listData = mapped_doc_list?.length > 0 ?
              response.data.data.filter((item) =>
                (item.specialization_active === true && mapped_doc_list.includes(item.doctor_id))) : [];
            this.setState({
              DoctorsList: listData
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((e) => {
          this.errorMessage(e.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  TriggerGetApi(component) {
    this.setState({
      CallGetComponent: component
    }, () => {
      setTimeout(() => { this.setState({ CallGetComponent: "" }) }, 500)
    })
  }

  TriggerGetApis() {
    this.setState({
      triggerApis: "triggerApiCalls"
    }, () => {
      setTimeout(() => { this.setState({ triggerApis: "" }) }, 500)
    })
  }

  EditSymptomData(data) {
    this.setState({
      EditSymptomsData: data
    })
  }
  EditExaminationsData(data) {
    this.setState({
      EditExaminationsData: data
    })
  }
  EditProvDiagnosisData(data) {
    this.setState({
      EditProvDiagnosisData: data
    })
  }
  ClearPropsData() {
    this.setState({
      EditSymptomsData: {},
      EditExaminationsData: {},
      EditProvDiagnosisData: "",
    })
  }

  handleSaveClick = () => {
    this.setState({
      isNotesSaveClked: false,
      isNotesClrClked: false,
      CallGetComponent: ""
    });
  };  

  renderComponent(Component) {
    const { isFltClrClked, isFltSrcClked, fromDate, toDate, selectedDoctor, triggerApis } = this.state;
    const triggerApiCall = isFltClrClked ? "isFltClrClked"
      : (isFltSrcClked ? "isFltSrcClked" : triggerApis ? triggerApis : null);
    const docIds = selectedDoctor.map(item => item.doctor_id);
    const from_date = DateTime.fromJSDate(new Date(fromDate)).toFormat('yyyy-MM-dd');
    const to_date = DateTime.fromJSDate(new Date(toDate)).toFormat('yyyy-MM-dd');
    switch (Component) {

      case "isSymptoms":
        return <SymptomsLeft
          IpPatientData={this.state.ipPatientData}
          EditSymptomData={this.EditSymptomData.bind(this)}
          GetAPiCall={this.state.CallGetComponent === 'isSymptoms' || triggerApiCall}
          IPadmissionDetails={this.state.IPadmissionDetails}
          withOutAppointment = {this.state.withOutAppointment}
          is_From_IpNotes = {this.props.is_From_IpNotes}
          from_date = {from_date}
          to_date = {to_date}
          doctorIds = {docIds}
          clinic_id = {this.state.clinic_id}
          onApiTrigger={this.handleCloseFlt}/>

      case "isExamination":
        return <ExaminationLeft
          IpPatientData={this.state.ipPatientData}
          EditExaminationsData={this.EditExaminationsData.bind(this)}
          GetAPiCall={this.state.CallGetComponent === 'isExamination'|| triggerApiCall}
          IPadmissionDetails={this.state.IPadmissionDetails} 
          withOutAppointment = {this.state.withOutAppointment}
          is_From_IpNotes={this.props.is_From_IpNotes}
          from_date = {from_date}
          to_date = {to_date}
          doctorIds={docIds}
          clinic_id = {this.state.clinic_id}
          onApiTrigger={this.handleCloseFlt}/>

      case "isProvDiagnosis":
        return <ProvDiagnosisLeft
          IpPatientData={this.state.ipPatientData}
          EditProvDiagnosisData={this.EditProvDiagnosisData.bind(this)}
          GetAPiCall={triggerApiCall}
          IPadmissionDetails={this.state.IPadmissionDetails}
          withOutAppointment = {this.state.withOutAppointment}
          is_From_IpNotes={this.props.is_From_IpNotes}
          from_date = {from_date}
          to_date = {to_date}
          doctorIds={docIds}
          clinic_id = {this.state.clinic_id}
          isClearClked = {this.state.SelectedComponent === 'isProvDiagnosis' && this.state.isNotesClrClked}
          isSaveClked = {this.state.SelectedComponent === 'isProvDiagnosis' && this.state.isNotesSaveClked}
          onApiTrigger={this.handleCloseFlt}
          onSaveComplete={this.handleSaveClick} />

      case "isDiagnosis":
        return <DiagnosisLeft
          IpPatientData={this.state.ipPatientData}
          GetAPiCall={this.state.CallGetComponent === 'isDiagnosis' || triggerApiCall}
          IPadmissionDetails={this.state.IPadmissionDetails} 
          withOutAppointment = {this.state.withOutAppointment}
          is_From_IpNotes={this.props.is_From_IpNotes}
          from_date = {from_date}
          to_date = {to_date}
          doctorIds={docIds}
          clinic_id = {this.state.clinic_id}
          onApiTrigger={this.handleCloseFlt}/>

      case "isDischargeNotes":
        return <WriteNotes
          key = {"isDischargeNotes"}
          IpPatientData={this.state.ipPatientData}
          CallgetApi={this.getNotesData.bind(this)}
          IPadmissionDetails={this.state.IPadmissionDetails}
          withOutAppointment={this.state.withOutAppointment}
          is_From_IpNotes={this.props.is_From_IpNotes}
          label = {"Discharge notes"}
          placeholder = {"Write Discharge notes here"}
          doctorsData = {this.state.discharge_notes}
          doctorTxt = {this.state.discharge_notes_txt}
          clinic_id = {this.state.clinic_id}
          isClearClked = {this.state.CallGetComponent === 'isDischargeNotes' && this.state.isNotesClrClked}
          isSaveClked = {this.state.CallGetComponent === 'isDischargeNotes' && this.state.isNotesSaveClked}
          onSaveComplete={this.handleSaveClick}/>

      case "isFamilyCounselling":
        return <WriteNotes
          key = {"isFamilyCounselling"}
          IpPatientData={this.state.ipPatientData}
          CallgetApi={this.getNotesData.bind(this)}
          IPadmissionDetails={this.state.IPadmissionDetails}
          withOutAppointment={this.state.withOutAppointment}
          is_From_IpNotes={this.props.is_From_IpNotes}
          label = {"Family Counselling"}
          placeholder = {"Write Family Counselling here"}
          doctorsData = {this.state.family_counselling}
          doctorTxt = {this.state.family_counselling_txt}
          clinic_id = {this.state.clinic_id}
          isClearClked = {this.state.CallGetComponent === 'isFamilyCounselling' && this.state.isNotesClrClked}
          isSaveClked = {this.state.CallGetComponent === 'isFamilyCounselling' && this.state.isNotesSaveClked}
          onSaveComplete={this.handleSaveClick}/>

      case "isMedicalInstruction":
        return <WriteNotes
          key = {"isMedicalInstruction"}
          IpPatientData={this.state.ipPatientData}
          CallgetApi={this.getNotesData.bind(this)}
          IPadmissionDetails={this.state.IPadmissionDetails}
          withOutAppointment={this.state.withOutAppointment}
          is_From_IpNotes={this.props.is_From_IpNotes}
          label = {"Medication & Instructions"}
          placeholder = {"Write Medication & Instructions here"}
          doctorsData = {this.state.instructions}
          doctorTxt = {this.state.instructions_txt}
          clinic_id = {this.state.clinic_id}
          isClearClked = {this.state.CallGetComponent === 'isMedicalInstruction' && this.state.isNotesClrClked}
          isSaveClked = {this.state.CallGetComponent === 'isMedicalInstruction' && this.state.isNotesSaveClked}
          onSaveComplete={this.handleSaveClick}/>
    }
  }

  renderRightComponent(Component) {
    switch (Component) {
      case "isSymptoms":
        return <SymptomsRight
          EditSymptomsData={this.state.EditSymptomsData}
          CallgetApi={this.TriggerGetApi.bind(this)}
          IpPatientData={this.state.ipPatientData}
          IPadmissionDetails={this.state.IPadmissionDetails}
          ClearPropsData={this.ClearPropsData.bind(this)} 
          withOutAppointment = {this.state.withOutAppointment}
          is_From_IpNotes = {this.props.is_From_IpNotes}
          clinic_id = {this.state.clinic_id}/>

      case "isExamination":
        return <ExaminationRight
          EditExaminationsData={this.state.EditExaminationsData}
          CallgetApi={this.TriggerGetApi.bind(this)}
          IpPatientData={this.state.ipPatientData}
          IPadmissionDetails={this.state.IPadmissionDetails}
          ClearPropsData={this.ClearPropsData.bind(this)}
          withOutAppointment = {this.state.withOutAppointment}
          is_From_IpNotes = {this.props.is_From_IpNotes}
          clinic_id = {this.state.clinic_id}/>

      // case "isProvDiagnosis":
      //   return <ProvDiagnosisRight
      //     EditProvDiagnosisData={this.state.EditProvDiagnosisData}
      //     CallgetApi={this.TriggerGetApi.bind(this)}
      //     IpPatientData={this.state.ipPatientData}
      //     IPadmissionDetails={this.state.IPadmissionDetails}
      //     ClearPropsData={this.ClearPropsData.bind(this)} 
      //     withOutAppointment = {this.state.withOutAppointment}
      //     is_From_IpNotes = {this.props.is_From_IpNotes}
      //     clinic_id = {this.state.clinic_id}/>

      case "isDiagnosis":
        return <DiagnosisRight
          CallgetApi={this.TriggerGetApi.bind(this)}
          IpPatientData={this.state.ipPatientData}
          IPadmissionDetails={this.state.IPadmissionDetails} 
          withOutAppointment = {this.state.withOutAppointment}
          is_From_IpNotes = {this.props.is_From_IpNotes}
          clinic_id = {this.state.clinic_id}/>
    }
  }

  // filter starts here 
  clearFilter = () => {
    let states = this.state;
    let patientDetails = getCachevalue("IppatientData");
    const currentDate = new Date();
    const admissionDate = states?.ipPatientData?.admitted_date ? new Date(states.ipPatientData.admitted_date)
      : states?.ipPatientData?.admission_date ? new Date(states.ipPatientData.admission_date) : currentDate;
    const dischargeDate = patientDetails?.discharge_date ? new Date(patientDetails.discharge_date) : currentDate;
    this.setState({
      fromDate: DateTime.fromJSDate(admissionDate).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(dischargeDate).toFormat('yyyy-MM-dd'),
      selectedDoctor: []
    })
  }

  searchFilter = () => {
    this.setState({ filterOpen: false })
  }

  handleCloseFlt = () => {
    this.setState({
      filterOpen: false,
      isFltClrClked: false,
      isFltSrcClked: false
    })
  }

  renderDatePickers = (label, statekey) => {
    const { t } = this.props;
    let states = this.state;
    const currentDate = new Date();
    const admissionDate = states?.ipPatientData?.admitted_date
      ? new Date(states.ipPatientData.admitted_date)
      : states?.ipPatientData?.admission_date
        ? new Date(states.ipPatientData.admission_date)
        : currentDate;
    const dischargeDate = states.ipPatientData?.discharge_date ? new Date(states.ipPatientData.discharge_date) : currentDate;

    return (
      <div id="eMed_srvConfig_divs">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t(label)}
            inputFormat='DD-MM-YYYY'
            value={states[statekey] ? states[statekey] : new Date()}
            onChange={(newDate) => {
              this.setState({
                [statekey]: newDate ? newDate : new Date()
              });
            }}
            minDate={admissionDate}
            maxDate={dischargeDate}
            renderInput={(params) =>
              <TextField size='small' {...params} sx={{ width: "12.5dvw" }} />}
          />
        </LocalizationProvider>
      </div>
    )
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box className='eMed_IPDoc_FilterBox' >
        <Box className='eMed_CanteenFilter_Head'>
          <Typography variant='h6' fontWeight={600}>Filter</Typography>
          <Button onClick={() => this.handleCloseFlt()}>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
          </Button>
        </Box>
        <Box className='eMed_IPDoc_fil_content'>
          <Box className='eMed_IPDoc_fil_text'>
            <Box className='eMed_IPDoc_Date'>
              {this.renderDatePickers("Select From Date", "fromDate")}
              {this.renderDatePickers("Select To Date", "toDate")}
            </Box>
            <Box className='eMed_IPDoc_fltTxtCont'>
              <Autocomplete
                multiple
                size='small'
                options={this.state?.DoctorsList}
                getOptionLabel={(option) => (option?.doctor_name)}
                value={this.state.selectedDoctor ? this.state?.selectedDoctor : []}
                onChange={(event, newValue) => {
                  this.setState({
                    selectedDoctor: newValue,
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Select Doctor')}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button size='small' variant='outlined' className='eMed_IPDoc_BtnText' onClick={() => {
              this.setState({ isFltClrClked: true },
                () => { this.clearFilter() })
            }}>Clear</Button>
            <Button size='small' variant='contained' className='eMed_IPDoc_BtnText' onClick={() => {
              this.setState({ isFltSrcClked: true },
                () => this.searchFilter())
            }}>Search</Button>
          </Stack>
        </Box>
      </Box>
    )
  }

  ClosePopUp = () => {
    this.setState({ filterOpen: false })
  }
  // filter ends here

  // doctor notes print starts
  handleNewCheckBoxHandler = (e, index) => {
    try {
      const { checked } = e.target;
      const doctorPrintList = [...this.state.doctorPrintList];
      const targetItem = doctorPrintList[index];

      // Update the checked status of the target item
      targetItem.is_checked = checked;

      // Determine the status of 'Full Report'
      if (targetItem.label === 'Full Report') {
        // If 'Full Report' is checked, set all items to checked
        const allChecked = checked;
        doctorPrintList.forEach(item => item.is_checked = allChecked);
      } else {
        // If any non-'Full Report' items are unchecked, uncheck 'Full Report'
        const nonFullReportChecked = doctorPrintList.filter(item => item.label !== 'Full Report').every(item => item.is_checked);
        doctorPrintList.forEach(item => {
          if (item.label === 'Full Report') {
            item.is_checked = nonFullReportChecked;
          }
        });
      }

      this.setState({ doctorPrintList });
    } catch (error) {
      this.errorMessage(error.message);
    }
  }  

  getDoctorNotesPrint = () => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    let doctorId = this.state.ipPatientData?.doctor_id;

    const from_date = DateTime.fromJSDate(new Date(this.state.fromDate)).toFormat('yyyy-MM-dd');
    const to_date = DateTime.fromJSDate(new Date(this.state.toDate)).toFormat('yyyy-MM-dd');
    const docIds = this.state.selectedDoctor.map(item => item.doctor_id);

    const filteredValues = this.state.doctorPrintList.reduce((acc, item) => {
      if (item.value === 'FullReport' && item.is_checked) {
        acc = this.state.doctorPrintList.filter(i => item.value !== 'FullReport' && i.value);
      } else if (item.is_checked && item.value !== 'FullReport') {
        acc.push(item.value);
      }
      return acc;
    }, []);

    let url = `${Serviceurls.IP_DOCTOR_NOTES_PRINT}?ip_admission_id=${admission_id}&doctor_notes=${filteredValues}&from_date=${from_date || null}&to_date=${to_date || null}`
    if (docIds?.length > 0) {
      url = `${url}&filter_doctor_id=${docIds}`
    }
    if (this.props.is_From_IpNotes && this.state.clinic_id) {
      url = `${url}&clinic_id=${this.state.clinic_id}`
    } else {
      url = `${url}&doctor_id=${doctorId}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  // doctor notes print ends

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }

  render() {
    const { t } = this.props
    var { ipPatientData } = this.state
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${ipPatientData.attender_name != null ? ipPatientData.attender_name : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${ipPatientData.attender_mobile != null ? ipPatientData.attender_mobile : '-'}`
    }
    let consultant = {
      lineOne: t("ConsultantName"),
      lineTwo: `${ipPatientData.doctor_name != null ? ipPatientData.doctor_name : '-'}`,
    }
    const checkuserAccess = this.props.is_From_IpNotes ? false :
        CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ?
          this.state.withOutAppointment?.is_ip : true;
    return (
      <Box sx={{ backgroundColor: Colors.ComponentBgColor }}>
        <Box component={'div'} marginLeft={'0.5dvw'} marginBottom = {"0.3dvw"} display={'flex'} justifyContent = "space-between" alignItems='center' className='eMed_patient_header'>
          <Box display={'flex'} alignItems='center'>
            <Box className='eMed_Patient_Details_Long eMed_patient_box'>
              <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
            </Box>
            <CommonPatientCard details={attender} showDetailed={true} />
            <ConsultantPatientCard name={consultant} width={"20dvw"} />
          </Box>
          <Box className="eMed_WrtNotes_lblCont">
          <Box className="eMed_pharma_settings_option_action" sx={{ marginLeft: "0.5dvw", marginTop: "0.3dvw" }}>
            <label style={{ paddingRight: '0.8vw', paddingTop: '0.1vw' }}>Doctor Notes</label>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  disabled={checkuserAccess}
                  size='small'
                  color="primary"
                  checked={this.state.isWriteNotes}
                  onChange={(e) => {
                    this.setState({ isWriteNotes: e.target.checked },
                    () => {
                    this.TriggerGetApis()
                    })
                  }} />}
              label="Write Notes"
              labelPlacement="end"
            />
          </Box>
          <Button className='eMed_usrconf_btn'
            sx={{ position: "relative" }}
            onClick={(e) => {
              this.setState({
                openDoctorNotesPrint: true,
                openDoctorNotesPrintAnchor: e.currentTarget
              })
            }}>
            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
          </Button>
          {<Menu
            anchorEl={this.state.openDoctorNotesPrintAnchor}
            sx={{ position: 'absolute', top: '11.85dvw', left: '0.1dvw', padding: "0px !important" }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
            open={this.state.openDoctorNotesPrint}
            onClose={() => {
              this.setState({ openDoctorNotesPrint: false })
            }}
          >
            <Box component={'div'} p={'0.625dvw'} maxHeight={'32dvw'} overflow={'auto'} >
              {this.state.doctorPrintList?.map((item, index) => {
                return (
                  <FormGroup>
                    <FormControlLabel key={index}
                      control={
                        <Checkbox checked={item?.is_checked}
                          onChange={(e) => { this.handleNewCheckBoxHandler(e, index) }} />}
                      label={item?.label} />
                  </FormGroup>
                )
              })
              }
            </Box>

            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Button variant='contained' className='eMed_IPDoc_printBtn'
               sx={{marginRight: "0.5dvw"}} onClick={() => this.getDoctorNotesPrint()}>
                Print
              </Button>
              {/* <Button variant='contained' className='eMed_IPDoc_printBtn'sx={{marginRight: "0.5dvw"}}>
                Default
              </Button> */}
              <Button variant='contained' className='eMed_IPDoc_printBtn'
                onClick={() =>
                  this.setState(
                    {
                      openDoctorNotesPrintAnchor: null,
                      openDoctorNotesPrint: false,
                      doctorPrintList: JSON.parse(JSON.stringify(Ip_Doc_PRINT_DEFAULT))
                    })}
              >
                cancel
              </Button>
            </Box>
          </Menu>}
          <Button onClick={() => { this.setState({ filterOpen: true }) }}>
            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
          </Button>
        </Box>
        </Box>
        {this.state.isWriteNotes ?
          <Card className='eMed_DocNotes_Left_Main' sx={{ width: "100%", overflowY: 'auto !important' }} elevation={3}>
            <div className='eMed_WrtNotes_lblCont' style={{ margin: "0.625dvw", borderBottom: "1px solid #cbbfbf", marginBottom: "0dvw" }}>
              <Typography color={Colors.SecondaryText} padding={"0.4dvw"} fontWeight={600} >Write Notes</Typography>
              {buttonViewList.has("isWriteNotes") ?
                <div className='eMed_WrtNotes_lblCont'>
                  <Button
                    variant='outlined' size="small"
                    id="eMed_recPayment_btn"
                    disabled = {this.state.withOutAppointment?.is_ip}
                    onClick={() => { this.setState({ isNotesClrClked: true, isNotesSaveClked: false, CallGetComponent: "isWriteNotes" }) }}
                  >
                    {t("Clear")}</Button>
                  <Button
                    sx={{ marginLeft: "0.5dvw" }}
                    variant='contained' size="small"
                    id="eMed_recPayment_btn"
                    disabled = {this.state.isNotesSaveClked || checkuserAccess}
                    onClick={() => { this.setState({ isNotesSaveClked: true, isNotesClrClked: false, CallGetComponent: "isWriteNotes" }) }}
                  >
                    {t("Save")}</Button>
                </div> : null}
            </div>
            <WriteNotes
              key={"isWriteNotes"}
              IpPatientData={this.state.ipPatientData}
              CallgetApi={this.getNotesData.bind(this)}
              IPadmissionDetails={this.state.IPadmissionDetails}
              withOutAppointment={this.state.withOutAppointment}
              is_From_IpNotes={this.props.is_From_IpNotes}
              label={"Doctor Notes"}
              placeholder={"Write doctor notes here"}
              rowlength={4}
              doctorsData = {this.state.write_notes}
              doctorTxt = {this.state.write_notes_txt}
              isClearClked={this.state.CallGetComponent === 'isWriteNotes' && this.state.isNotesClrClked}
              isSaveClked={this.state.CallGetComponent === 'isWriteNotes' && this.state.isNotesSaveClked}
              onSaveComplete={this.handleSaveClick} />
          </Card>
          : <Box display={'flex'} flexDirection={'row'} >
            <Paper elevation={3} className='eMed_DocNotes_Left_Main'>
            {
              IPdoctorNotesTabs.map((item, index) => (
                <Card key={index} onClick={() => { this.setState({ SelectedComponent: item.value }) }} elevation={3} sx={{ minHeight: '10dvw', margin: '1dvw', border: `0.15dvw solid ${item.value === this.state.SelectedComponent ? Colors.themeDark : Colors.white}`, }}>
                  <div className='eMed_WrtNotes_lblCont' style = {{marginRight: "1dvw"}}>
                    <Typography color={item.value === this.state.SelectedComponent ? Colors.themeDark : Colors.SecondaryText} padding={"0.4dvw"} fontWeight={600} marginLeft={"1dvw"}>{item.Label}</Typography>
                    {buttonViewList.has(item.value) ? 
                    <div className='eMed_WrtNotes_lblCont'>
                    <Button
                        variant='outlined' size="small"
                        id="eMed_recPayment_btn"
                        disabled = {this.state.withOutAppointment?.is_ip}
                        onClick={() => {this.setState({isNotesClrClked: true, isNotesSaveClked: false, CallGetComponent: item.value})}}
                        >
                        {t("Clear")}</Button>
                        <Button
                        sx={{ marginLeft: "0.5dvw" }}
                        variant='contained' size="small"
                        id="eMed_recPayment_btn"
                        disabled = {this.state.isNotesSaveClked || checkuserAccess}
                        onClick={() => {this.setState({isNotesSaveClked: true, isNotesClrClked: false, CallGetComponent: item.value})}}
                        >
                        {t("Save")}</Button>
                    </div> : null}
                  </div>
                  {this.renderComponent(item.value)}
                </Card>
              ))
            }

          </Paper>
          <Paper elevation={3} className='eMed_DocNotes_Right_Main'>
            {this.renderRightComponent(this.state.SelectedComponent)}
          </Paper>
        </Box>}
        {
          this.state.isErrorMsg ?
            <ToastMsg
              severity={"error"}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
        {
          this.state.filterOpen ?
            <Drawer
              anchor={'right'}
              open={this.state.filterOpen}
              ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
            >
              {this.renderFilterScreen()}
            </Drawer>
            : null
        }
      </Box>
    )
  }
}
export default withTranslation()(DoctorNotes)

import { Card, Tooltip, Typography } from '@mui/material';
import { AmountFormat } from '../CommonFunctions/CommonFunctions';
import './CommonCards.css';
import { Colors } from '../../Styles/Colors';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { CurrencySymbol } from '../../Utility/Constants';


const PaymentsCard = (label, amount, icon, bgcolor="white") => {
    return (
        <Card elevation={2} className='eMed_PayCard_div' sx={{backgroundColor:bgcolor}}>
                <div className='eMed_PayCard_Icon'>
                <img src={icon} className="eMed_Icon_Img" alt={label} />
                </div>
                <div className='eMed_PayCard_Content'>
                    <Typography sx={{color: bgcolor === "white" ? Colors.black :Colors.white}} className='eMed_Amt_card_label'>{label}</Typography>
                    <Typography variant="h6"  sx={{color: bgcolor === "white" ? Colors.black :Colors.white, fontWeight:"600"}} className='eMed_Amt_card_amount'>{ AmountFormat(amount)}</Typography>
                </div>
        </Card>
    )
}
const AmountsCard = (label, amount, count = false , bgcolor="white",arrow = false, Primary, testId,width) => {
    let Amt = amount ? amount?.toString() : "0"
    return (
        <Card elevation={2} className='eMed_Amt_card_div' sx={{backgroundColor:bgcolor,width:width ? width : '10vw'}} emed_tid={testId}>
                <div className='eMed_Amt_card'>
                    <Typography sx={{color: (bgcolor === "white" || bgcolor === "#b4ece4") ? Colors.black :Colors.white, fontWeight:"600", fontSize: Amt.length > 10 ? '0.9vw': '1vw' }} className='eMed_Amt_card_amount'>{ (amount === undefined || amount === null ) ? (count ? "0" : `${CurrencySymbol} 0`) : (count ? amount : AmountFormat(amount)) }</Typography>
                    <Typography sx={{color: (bgcolor === "white" || bgcolor === "#b4ece4") ? Colors.black :Colors.white}} className='eMed_Amt_card_label'>{label}</Typography>
                </div>
                {arrow ? <Tooltip placement='top' title="Click Here"><ArrowRightIcon sx={{color: Primary}} /></Tooltip> : null}
        </Card>
    )
}


export { PaymentsCard, AmountsCard }
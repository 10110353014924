import { Autocomplete, Box, Button, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { Colors } from '../../../Styles/Colors'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import { DateTime } from 'luxon'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'


class PharmaCreditNote extends Component {

    constructor(props) {
        super(props)
        this.state = {
            purchaseVendorList: [],
            vendorId: null,
            DateOpen: false,
            creditNumber: '',
            crtDateAndTime: new Date(),
            vendorRtnData: [],
            page: 0,
            pageSize: 10,
            rtnLineItem: [],
            postLoad: false,
            loading: false,
            checkBoxSelectId: [],
            fromEdit: false,
            credit_id: null,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getVendorListData()
    }

    componentDidUpdate = () =>{
        let states = this.state;
        let history = this.props?.history?.location?.state;
        if(history?.creditNoteData?.credit_id !== undefined && history?.creditNoteData?.credit_id !== this.state.credit_id && states.purchaseVendorList.length){
            states.credit_id = history.creditNoteData?.credit_id
            states.fromEdit = true
            states.vendorRtnData = history?.creditNoteData.line_items
            let vendor = states.purchaseVendorList ? states.purchaseVendorList.find((item)=> item.vendor_id === history?.creditNoteData?.vendor_id) : null
            states.vendorId = vendor 
            states.creditNumber = history?.creditNoteData?.credit_number
            states.totalGstAmt = history?.creditNoteData?.gst_amount
            states.totalNetAmt = history?.creditNoteData?.net_amount
            states.totalFinalAmt = history?.creditNoteData?.total_amount
            states.crtDateAndTime = new Date(history?.creditNoteData?.credit_date)
            states.credit_id = history?.creditNoteData?.credit_id

            this.setState({
                states
            },()=>{
                this.state.vendorRtnData?.forEach((element, index) => {
                    element.sno = index + 1
                    element.isChecked = true
                    element.credit_value = (history?.creditNoteData.line_items[index].credit_rate_quantity).toString()
                    element.credit_qty = history?.creditNoteData.line_items[index].credit_quantity
                    element.amtWithoutGst = history?.creditNoteData.line_items[index].net_amount
                    element.amtWithGst = history?.creditNoteData.line_items[index].total_amount
                    element.gstAmt = history?.creditNoteData.line_items[index].gst_amount
                    element.id = history?.creditNoteData.line_items[index].id
                })
            })
        }
    }

    getVendorListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_LIST + '?from=purchase_order')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            purchaseVendorList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getVendorReturnDataList = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.PHARMA_PO_CREDIT_NOTE_VENDOR_GET + `?vendor_id=${this.state.vendorId?.vendor_id}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            vendorRtnData: response.data.data,
                            loading: false
                        }, () => {
                            this.state.vendorRtnData?.forEach((element, index) => {
                                element.sno = index + 1
                                element.credit_value = ''
                                element.credit_qty = ''
                                element.isChecked = false
                            })
                        })
                    }
                }).catch((error) => {
                    this.setState({ loading: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    postCreditNoteData = () => {
        try {
            let Datetime = new Date(this.state.crtDateAndTime)
            let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
            let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_WITH_SECONDS)

            if (this.state.vendorId === null) {
                this.errorMessage("Select Vendor Name")
                this.setState({ disableBtn: false })
            } else if (this.state.vendorRtnData.length === 0) {
                this.errorMessage("No data available")
                this.setState({ disableBtn: false })
            } else if (this.state.rtnLineItem?.length === 0 ) {
                this.errorMessage("Enter Both Credit Quantity and Value")
                this.setState({ disableBtn: false })
            } else if (this.state.rtnLineItem?.some((item)=> item.credit_quantity === "" || item.credit_quantity === 0)) {
                this.errorMessage("Enter Credit Quantity")
                this.setState({ disableBtn: false })
            } else if (this.state.rtnLineItem?.some((item)=> item.credit_rate_quantity === "")) {
                this.errorMessage("Enter Credit Value")
                this.setState({ disableBtn: false })
            } else if (this.state.creditNumber === '') {
                this.errorMessage("Enter Credit Number")
                this.setState({ disableBtn: false })
            } else {
                let data = {
                    vendor_id: this.state.vendorId?.vendor_id,
                    credit_id: this.state.credit_id ? this.state.credit_id : null,
                    credit_number: this.state.creditNumber,
                    credit_date: `${date} ${time}`,
                    total_amount : this.state.totalFinalAmt,
                    net_amount: this.state.totalNetAmt,
                    gst_amount: this.state.totalGstAmt,
                    line_items: this.state.rtnLineItem
                }
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.PHARMA_PO_CREDIT_NOTE_POST)
                    .then((response => {
                        if (response.data.status === 'success') {
                            this.setState({
                                vendorRtnData: [],
                                vendorId: '',
                                crtDateAndTime: new Date(),
                                creditNumber: '',
                                postLoad: false,
                                totalNetAmt: 0,
                                totalGstAmt: 0,
                                totalFinalAmt: 0,
                                disableBtn: false,
                            })
                            this.props.history.push({ pathname :'/PharmacyPurchase/CreditNote', state: {successMsg : response.data.message}})
                        }
                    })).catch(error => {
                        this.setState({ postLoad: false, disableBtn: false })
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.setState({ postLoad: false, disableBtn: false })
            this.errorMessage(error.message)
        }
    }

    calAmtWithoutGST = (from, value, index) =>{
        let amt = 0
        let ratePerCost = 0
        let qty = 0
        if(from === "qty" && this.state.vendorRtnData[index].credit_value != ""){
            qty = value 
            ratePerCost = +this.state.vendorRtnData[index].credit_value 
            amt = (qty * ratePerCost).toFixed(2)
            this.state.vendorRtnData[index].amtWithoutGst = +amt
            this.setState({vendorRtnData: this.state.vendorRtnData})

        }else if(from === "amt" && this.state.vendorRtnData[index].credit_qty != ""){
            qty = this.state.vendorRtnData[index].credit_qty
            ratePerCost = value
            amt = (qty * ratePerCost).toFixed(2)
            this.state.vendorRtnData[index].amtWithoutGst = +amt
            this.setState({vendorRtnData: this.state.vendorRtnData})
        }
    }

    calGstAmt = (from, gstPercent, index) => {
        let gstAmount = 0
        let gstPercentage = gstPercent ? +gstPercent : 0
        if ((from === "qty" && this.state.vendorRtnData[index].credit_value != "") || (from === "amt" && this.state.vendorRtnData[index].credit_qty != "")) {
            // gstAmount = ((this.state.vendorRtnData[index].amtWithoutGst * gstPercentage ) / 100).toFixed(2)
            gstAmount = (this.state.vendorRtnData[index].amtWithoutGst - (this.state.vendorRtnData[index].amtWithoutGst * (100 / (100 + gstPercentage)))).toFixed(2)
            this.state.vendorRtnData[index].gstAmt = +gstAmount
            this.setState({ vendorRtnData: this.state.vendorRtnData })
        }
    }

    calAmtWithGST = (from, index) => {
        let totalAmount = 0
        
        if((from === "qty" && this.state.vendorRtnData[index].credit_value != "") || (from === "amt" && this.state.vendorRtnData[index].credit_qty != "")){
            totalAmount = (this.state.vendorRtnData[index].amtWithoutGst + this.state.vendorRtnData[index].gstAmt).toFixed(2)
            this.state.vendorRtnData[index].amtWithGst = +totalAmount
            this.setState({vendorRtnData: this.state.vendorRtnData},()=>{this.calCardAmt()})
        }
    }

    calCardAmt = () => {
        let totalFinalAmount = this.state.vendorRtnData.reduce((acc, num)=>{
            return acc + num.amtWithoutGst
        },0)
        
        let totalNetAmt = this.state.vendorRtnData.reduce((acc, num)=>{
            return acc + (num.amtWithoutGst - num.gstAmt)
        },0)

        let totalGstAmt = this.state.vendorRtnData.reduce((acc, num)=>{
            return acc + num.gstAmt
        },0)
        
        this.setState({
            totalFinalAmt: +(totalFinalAmount.toFixed(2)),
            totalNetAmt: +(totalNetAmt.toFixed(2)),
            totalGstAmt: +(totalGstAmt.toFixed(2)),
        })

    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onVendorChange = () => {
        try {
            this.setState({
                creditNumber: '',
                vendorRtnData: [],
                totalNetAmt: 0,
                totalGstAmt: 0,
                totalFinalAmt: 0,                
            },()=>{
                this.getVendorReturnDataList()
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        let columns = [
            {
                field: 'po_return_date', headerName: 'Rejection Date', flex: 0.09,
                renderCell: (params) => (<Box>{params?.row?.po_return_date ? params?.row?.po_return_date : '-'}</Box>)
            },
            {
                field: 'po_return_number', headerName: 'Rejection No', flex: 0.09,
                renderCell: (params) => (<Box>{params?.row?.po_return_number ? params?.row?.po_return_number : '-'}</Box>)
            },
            {
                field: 'hsn_code', headerName: 'HSN Code', flex: 0.09,
                renderCell: (params) => (<Box>{params?.row?.hsn_code ? params?.row?.hsn_code : '-'}</Box>)
            },
            {
                field: 'drug_name', headerName: 'Products Details', flex: 0.15,
                renderCell: (params) => {
                    let brandName = params?.row?.drug_name
                    let drugDetails = (params?.row?.generic_name ? params?.row?.generic_name : '-') + "|" + (params?.row?.dosage_type ? params?.row?.dosage_type : '-')
                    return (
                        <Box>
                            {brandName ? brandName?.length > 15 ?
                                <Tooltip placement='top' title={brandName}><Typography fontSize={'0.8vw'} fontWeight={600}>{brandName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.8vw'} fontWeight={600}>{brandName}</Typography> : '-'}
                            {drugDetails ? drugDetails?.length > 15 ?
                                <Tooltip placement='top' title={drugDetails}><Typography color={Colors.grayShade} fontSize={'0.8vw'}>{drugDetails?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography color={Colors.grayShade} fontSize={'0.8vw'}>{drugDetails ? drugDetails : '-'}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'batch_no', headerName: 'Batch No', flex: 0.09,
                renderCell: (params) => (<Box>{params?.row?.batch_no ? params?.row?.batch_no : '-'}</Box>)
            },
            {
                field: 'expiry_date', headerName: 'Expiry Date', flex: 0.09,
                renderCell: (params) => (<Box>{params?.row?.expiry_date ? params?.row?.expiry_date : '-'}</Box>)
            },
            {
                field: 'return_quantity', headerName: 'Return Qty', flex: 0.09, type: "number",
                renderCell: (params) => (<Box>{params?.row?.return_quantity ? params?.row?.return_quantity : '-'}</Box>)
            },
            {
                field: 'purchase_rate_per_qty', headerName: 'Purchase Value', flex: 0.09, type: "number",
                renderCell: (params) => (<Box>{params?.row?.purchase_rate_per_qty ? (params?.row?.purchase_rate_per_qty).toFixed(2) : '-'}</Box>)
            },
            {
                field: 'credit_qty', headerName: 'Credit Qty', flex: 0.09,
                renderCell: (params) => {
                    return (
                        <TextField
                            disabled={params?.row?.isChecked ? false : true}
                            inputProps={{ style: { height:'6vh',padding: '0 14px' } }}
                            autoComplete='off'
                            value={params.row?.credit_qty ? params.row?.credit_qty : ""}
                            onChange={(e) => {
                                let number = CommonValidation.numberOnly(e.target.value);

                                if ((number || e.target.value === "") && (+e.target.value <= params?.row?.return_quantity)) {
                                    this.state.vendorRtnData[params.row.sno - 1]["credit_qty"] = +e.target.value
                                    this.setState({
                                        vendorRtnData: this.state.vendorRtnData
                                    },()=>{
                                        this.calAmtWithoutGST("qty", +e.target.value, (params.row.sno-1))
                                        this.calGstAmt("qty", params.row.gst_percentage, (params.row.sno-1))
                                        this.calAmtWithGST("qty", (params.row.sno-1))
                                    })
                                }
                            }}
                            className='eMed_DataGrid_TextField' />
                    )
                }
            },
            {
                field: 'credit_value', headerName: 'Credit Value', flex: 0.09, 
                renderCell: (params) => (
                    <Box component={'div'}>
                        <TextField
                            disabled={params?.row?.isChecked ? false : true}
                            inputProps={{ style: { height:'6vh',padding: '0 14px' } }}
                            autoComplete='off'
                            value={params.row?.credit_value.length ? params.row?.credit_value : ""}
                            onChange={(e) => {
                                let number = CommonValidation.RangeDecimalNumber(e.target.value);
                                if ((number || e.target.value === "") && (+e.target.value <= params?.row?.purchase_rate_per_qty)) {
                                    this.state.vendorRtnData[params.row.sno - 1]["credit_value"] = e.target.value
                                    this.setState({ vendorRtnData: this.state.vendorRtnData },()=>{
                                        this.calAmtWithoutGST("amt", +e.target.value, (params.row.sno-1))
                                        this.calGstAmt("amt", params.row.gst_percentage, (params.row.sno-1))
                                        this.calAmtWithGST("amt", (params.row.sno-1))

                                    })
                                }
                            }}
                            className='eMed_DataGrid_TextField' />
                    </Box>
                )
            },
            {
                field: 'amtWithoutGst', headerName: 'Total (Excl)', flex: 0.09,  type: "number",
                renderCell: (params) => (
                // <Box>{params?.row?.amtWithGst ? params?.row?.amtWithGst : '0'}</Box>
                <Box>{params?.row?.amtWithoutGst ? +(params?.row?.amtWithoutGst - params?.row?.gstAmt).toFixed(2) : '0'}</Box>
            )
            },
            {
                field: 'gst_percentage', headerName: 'GST', flex: 0.09, headerAlign:'center', align:'center',
                renderCell: (params) => (
                    <Box> 
                        <Typography>{params?.row?.gstAmt ? params?.row?.gstAmt : 0}</Typography>
                        <Typography fontSize={'0.8vw'} align='right'>{params?.row?.gst_percentage ? params?.row?.gst_percentage + "%" : "0%"}</Typography>
                    </Box>)
            },
            {
                field: 'amtwithGst', headerName: 'Total', flex: 0.09, type: "number",
                renderCell: (params) => (<Box>{params?.row?.amtWithoutGst? params?.row?.amtWithoutGst : '0'}</Box>)
            },
        ]
        return (
            <Box component={'div'}>
                <Box component={'div'} className='eMed_Pharma_Header_Card'>
                    <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" onClick={() => {
                        this.props.history.push({ pathname: '/PharmacyPurchase/CreditNote' })
                    }} ><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {"Credit Note"}</Typography></Stack>
                </Box>
                <Box component={'div'} height={'79vh'} sx={{ backgroundColor: Colors.Background }}>
                    <Box component={'div'} height={'13vh'} p={'0.5vw'} display={'flex'} alignItems={'center'}>
                        <Box component={'div'} mr={'1vw'}>
                            <Autocomplete
                                disableClearable
                                size='small'
                                sx={{ width: '20vw' }}
                                options={this.state.purchaseVendorList}
                                disabled={this.state.fromEdit}
                                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.name}
                                value={this.state.vendorId}
                                onChange={(e, value) => {
                                    this.setState({
                                        vendorId: value
                                    }, () => {
                                        if (this.state.vendorId) {
                                            this.onVendorChange()
                                        }
                                    })
                                }}
                                renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.vendor_id}>
                                        {option.name}
                                      </li>
                                    )
                                  }}
                                renderInput={(params) => <TextField {...params} label='Select Vendor *' autoComplete='off' />}
                            />
                        </Box>
                        <Box component={'div'} mr={'1vw'}>
                            <TextField
                                size='small'
                                sx={{ width: '15vw' }}
                                label='Credit Number *'
                                value={this.state.creditNumber}
                                disabled={this.state.fromEdit}
                                onChange={(e) => {
                                    if(e.target.value?.length < 15){
                                        this.setState({
                                            creditNumber: e.target.value
                                        })
                                    }
                                }}
                                autoComplete='off'
                            />
                        </Box>
                        <Box component={'div'} mr={'1vw'}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    readOnly
                                    open={this.state.DateOpen}
                                    onClose={() => {
                                        this.setState({DateOpen: false})
                                    }}
                                    inputFormat='dd-MM-yyyy'
                                    label={"Date"}
                                    name={"Date"}
                                    value={this.state.crtDateAndTime}
                                    autoComplete="off"
                                    maxDate={DateTime.now()}
                                    onChange={(value) => {
                                        this.setState({
                                            crtDateAndTime: value
                                        })
                                    }}
                                    renderInput={(params) => <TextField
                                        size="small"
                                        onKeyDown={(e) => { e.preventDefault() }}
                                        sx={{ width: "9.8vw" }}
                                        autoComplete="off"
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            emed_tid: "date",
                                        }}
                                        label='Date*'
                                    />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box component={'div'} display={'flex'} width={'10vw'} justifyContent={'space-between'}>
                            {/* <Button variant='outlined' sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => { this.onClearHandler() }}
                            >Clear</Button> */}
                            <Button variant='contained' sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ disableBtn: true }, () => {
                                        let finalData = [];
                                        this.state.vendorRtnData?.forEach(element => {
                                            if (element.credit_qty || element.credit_value) {
                                                finalData?.push({
                                                    id: element?.id ? element.id : null,
                                                    return_line_id: element?.return_line_id,
                                                    credit_quantity: element?.credit_qty,
                                                    total_amount: element?.amtWithGst,
                                                    net_amount: element?.amtWithoutGst,
                                                    gst_amount: element?.gstAmt,
                                                    gst_percentage: element?.gst_percentage,
                                                    credit_rate_quantity: element?.credit_value === "" ? "" : +element?.credit_value,
                                                })
                                            }
                                        })
                                        this.setState({ rtnLineItem: finalData }, () => {
                                            this.postCreditNoteData()
                                        })
                                    })
                                }}
                                disabled={this.state.postLoad || this.state.disableBtn}
                            >{this.state.fromEdit ? "Update" : "Generate"}</Button>
                        </Box>
                        <Box component={'div'} display={'flex'}>
                            {AmountsCard("Total (Excluding GST)", this.state.totalNetAmt ? this.state.totalNetAmt : 0, false, "white", false, null, 'creditNote_totalnetAmt')}
                            {AmountsCard("Total GST", this.state.totalGstAmt ? this.state.totalGstAmt : 0, false, "white", false, null, 'creditNote_totalTaxableAmt')}
                            {AmountsCard("Total", this.state.totalFinalAmt ? this.state.totalFinalAmt : 0, false, "white", false, null, 'creditNote_total')}
                        </Box>
                    </Box>
                    <Box component={'div'} height={'66.5vh'} p={'0.5vw'}>
                        <DataGrid
                            checkboxSelection = {this.state.fromEdit ? false : true }
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                this.state.vendorRtnData.filter((row) => (
                                    selectedIDs.has(row.sno) ? row.isChecked = true : (row.isChecked = false, row.credit_qty = '', row.credit_value = '', row.amtWithGst = 0, row.amtWithoutGst= 0, row.gstAmt=0, this.calCardAmt())
                                ))
                                this.setState({ vendorRtnData: this.state.vendorRtnData })
                            }}
                            sx={{ background: Colors.white }}
                            rows={this.state.vendorRtnData}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            columns={columns}
                            getRowId={(row) => row.sno}
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            components={{
                                Toolbar: CommonGridHeader.CommonToolBar,
                                NoRowsOverlay: () => (
                                    <Box className='eMed_conf_nodata'>
                                        {("No Records To Be Shown")}
                                    </Box>
                                )
                            }}
                            headerHeight={40}
                            disableSelectionOnClick
                            loading={this.state.loading}
                        />
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default PharmaCreditNote

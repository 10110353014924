import React, { Component } from 'react';
import '../ChartDashboard/ChartHomeCss.css';
import { Box, Typography, Grid, Divider, Button, Drawer, Tooltip, Alert, Skeleton } from '@mui/material';
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import LoopIcon from '@mui/icons-material/Loop';
import RevenueCard from '../ChartDashboard/CommonCharts/RevenueCard';
import DoughnutChart from '../ChartDashboard/CommonCharts/DoughnutChart';
import {
    OverallTotalRevenue, OverallRevenueExpence, BranchOverallPaymentCollection,BranchWiseOverallRenvenue,
    BranchOverallCreditAmount, BranchOverallAppointmentType, BranchOverallRenvenue,
} from '../ChartDashboard/CommonCharts/TableColumns';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { ProfitLossPercent, formatDate } from '../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import ChartDashboardFilter from '../ChartDashboard/ChartDashboardFilter';
import Barchart from '../ChartDashboard/CommonCharts/Barchart';
import CommonDatePicker from '../../Components/Common Components/CommonDatePicker';
import { toDate } from 'date-fns';

class AdminDashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BranchId: localGetItem("BranchId") ? localGetItem("BranchId") : 166,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
            FilterOpen: false,
            AlertText: "",
            orgName: '',
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            OverallChartDatas: {
                TotalRevenueBranchWise: [],
                TotalRevenue: {},
                PaymentCollection: {},
                CreditAmount: {},
                AppointmentType: {},
            },
            BranchesData: [],
            apiLoaded : false,
        }
        this.childComponentIds = ["overallRef", "branches"];
    }

    componentDidMount() {
        let org_details = localGetItem("loggedInUserInfo")
        let UserData = JSON.parse(org_details)
        this.setState({ 
            orgName: UserData.display_name, 
            orgId: UserData?.org_id 
        },()=>{
                this.OverallApiCalls();
                this.branchesApiCalls()
            }
        )
    }

    RefreshAllChartsData() {
        this.setState({
            OverallChartDatas: {
                TotalRevenue: {},
                TotalRevenueBranchWise:[],
                PaymentCollection: {},
                CreditAmount: {},
                AppointmentType: {},
            },
            BranchesData: [],
            apiLoaded: false,
        }, () => { 
            this.OverallApiCalls() 
            this.branchesApiCalls()
        })
    }

    ShowGreeting() {
        const now = new Date();
        const currentHour = now.getHours();
        let greeting = "";
        if (currentHour >= 5 && currentHour < 12) {
            greeting = "Good Morning "
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = "Good Afternoon "
        } else if (currentHour >= 18 || currentHour < 5) {
            greeting = "Good Evening "
        }
        return greeting + this.state.orgName
    }

    branchesApiCalls = () => {
        try {
            let states = this.state
            let chart_data = []
            let data = []
            RestAPIService.getAll(Serviceurls.BRANCH_MOD_BRANCHES_GET + `?from_date=${states?.fromDate}&to_date=${states?.toDate}&org_id=${states?.orgId}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        data = response?.data?.data
                        data.forEach((item, i) => {
                            chart_data.push(item?.op_revenue, item?.ip_revenue, item?.lab_revenue, item?.pharmacy_revenue, item?.radiology_revenue, item?.ot_revenue)
                            data[i]["ChartValue"] = chart_data
                            data[i]["ChartLabel"] = ["OP", "IP", "Lab", "Pharmacy", "Radiology", "OT"]
                            chart_data = []
                        })
                        states["BranchesData"] = data
                        this.setState({
                            BranchesData: data,
                            apiLoaded: true
                        })
                    }
                    else {
                        this.setState({ apiLoaded : true })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ apiLoaded : true })
                    this.errorMessage(error.message)
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    OverallApiCalls() {
        try {
            let states = this.state
            let OverAllChartsAPIQuery = "?from_date=" + states?.fromDate +
                "&to_date=" + states?.toDate +
                "&org_id=" + states?.orgId

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_REVENUE + OverAllChartsAPIQuery + "&org_data=true" ).
                then((response) => {
                    if (response.data.status === "success" && response?.data?.data?.length > 0) {
                        states["OverallChartDatas"]["TotalRevenueBranchWise"] = response.data.data
                        this.setState({ states })
                    }
                }).catch((error)=>{
                    states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)                
                })

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_REVENUE + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success" && response?.data?.data?.length > 0) {
                        let initialObj = {
                            OP: 0,
                            IP: 0,
                            Lab: 0,
                            Pharmacy: 0,
                            Radiology: 0,
                            OT: 0,
                        }
                        let totalAmount = response?.data?.data.reduce((acc, cur, i) => {
                            acc["OP"] = parseFloat(acc.OP) + parseFloat(cur.op_revenue ? cur.op_revenue : 0);
                            acc["IP"] = parseFloat(acc.IP) + parseFloat(cur.ip_revenue ? cur.ip_revenue : 0);
                            acc["Lab"] = parseFloat(acc.Lab) + parseFloat(cur.lab_revenue ? cur.lab_revenue : 0);
                            acc["Pharmacy"] = parseFloat(acc.Pharmacy) + parseFloat(cur.pharmacy_revenue ? cur.pharmacy_revenue : 0);
                            acc["Radiology"] = parseFloat(acc.Radiology) + parseFloat(cur.radiology_revenue ? cur.radiology_revenue : 0);
                            acc["OT"] = parseFloat(acc.OT) + parseFloat(cur.ot_revenue ? cur.ot_revenue : 0);
                            return acc;
                        }, initialObj)
                        let tableDataArray = []
                        response?.data?.data?.forEach((element) => {
                            element.overall_profit = element?.overall_revenue - element?.overall_expense
                            tableDataArray.push(element)
                        })
                        states["OverallChartDatas"]["TotalRevenue"]["TableData"] = tableDataArray
                        states["OverallChartDatas"]["TotalRevenue"]["ChartLabel"] = Object.keys(totalAmount)
                        states["OverallChartDatas"]["TotalRevenue"]["ChartData"] = Object.values(totalAmount)
                        states["OverallChartDatas"]["TotalRevenue"]["TotalObject"] = totalAmount
                        states["OverallChartDatas"]["TotalRevenue"]["TotalRevenueAmount"] = response?.data?.overall_revenue ? response?.data?.overall_revenue : 0
                        states["OverallChartDatas"]["TotalRevenue"]["TotalExpenseAmount"] = response?.data?.overall_expense ? response?.data?.overall_expense : 0
                        states["OverallChartDatas"]["TotalRevenue"]["BranchName"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.branch_name : "-"
                        states["OverallChartDatas"]["TotalRevenue"]["ipPatientCount"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.ip_patients_count : "-"
                        states["OverallChartDatas"]["TotalRevenue"]["opPatientCount"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.op_patients_count : "-"
                        states["OverallChartDatas"]["TotalRevenue"]["labPatientCount"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.lab_patients_count : "-"
                        states["OverallChartDatas"]["TotalRevenue"]["pharmaPatientCount"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.phar_patients_count : "-"
                        states["OverallChartDatas"]["TotalRevenue"]["totalPatientCount"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.total_patient_count : "-"

                        let CompareIntailObj = {
                            CompareOP: 0,
                            CompareIP: 0,
                            Comparelab: 0,
                            ComparePharma: 0,
                            OP: 0,
                            IP: 0,
                            Lab: 0,
                            pharma: 0,
                            expence: 0,
                            CompareExpence: 0,
                        }
                        let CompareArray = response?.data?.data.reduce((acc, cur, i) => {
                            acc["CompareOP"] = parseFloat(acc.CompareOP) + parseFloat(cur.cmp_op_revenue ? cur.cmp_op_revenue : 0);
                            acc["CompareIP"] = parseFloat(acc.CompareIP) + parseFloat(cur.cmp_ip_revenue ? cur.cmp_ip_revenue : 0);
                            acc["Comparelab"] = parseFloat(acc.Comparelab) + parseFloat(cur.cmp_lab_revenue ? cur.cmp_lab_revenue : 0);
                            acc["ComparePharma"] = parseFloat(acc.ComparePharma) + parseFloat(cur.cmp_pharmacy_revenue ? cur.cmp_pharmacy_revenue : 0);
                            acc["OP"] = parseFloat(acc.OP) + parseFloat(cur.op_revenue ? cur.op_revenue : 0);
                            acc["IP"] = parseFloat(acc.IP) + parseFloat(cur.ip_revenue ? cur.ip_revenue : 0);
                            acc["Lab"] = parseFloat(acc.Lab) + parseFloat(cur.lab_revenue ? cur.lab_revenue : 0);
                            acc["pharma"] = parseFloat(acc.pharma) + parseFloat(cur.pharmacy_revenue ? cur.pharmacy_revenue : 0);
                            acc["expence"] = parseFloat(acc.expence) + parseFloat(cur.overall_expense ? cur.overall_expense : 0);
                            acc["CompareExpence"] = parseFloat(acc.CompareExpence) + parseFloat(cur.cmp_overall_expense ? cur.cmp_overall_expense : 0);
                            return acc;
                        }, CompareIntailObj)

                        states["OverallChartDatas"]["TotalRevenue"]["ComparePercent"] = {
                            OPComparePercent: ProfitLossPercent(CompareArray?.OP, CompareArray?.CompareOP),
                            IPComparePercent: ProfitLossPercent(CompareArray?.IP, CompareArray?.CompareIP),
                            PharmacyComparePercent: ProfitLossPercent(CompareArray?.pharma, CompareArray?.ComparePharma),
                            LabComparePercent: ProfitLossPercent(CompareArray?.Lab, CompareArray?.Comparelab),
                            ExpenceComparePercent: ProfitLossPercent(CompareArray?.expence, CompareArray?.CompareExpence),
                        }
                        states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_PAYMENTS + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        states["OverallChartDatas"]["PaymentCollection"]["TableData"] = response?.data?.data
                        states["OverallChartDatas"]["PaymentCollection"]["TotalCredit"] = response?.data?.data
                        states["OverallChartDatas"]["PaymentCollection"]["ChartData"] = [
                            response?.data?.total_cash,
                            response?.data?.total_card,
                            response?.data?.total_upi,
                            response?.data?.total_cheque,
                            response?.data?.total_bank_transfer,
                        ]
                        states["OverallChartDatas"]["PaymentCollection"]["ChartLabel"] = ["Cash", "Card", "UPI", "Cheque", "Bank Trans"]
                        states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_CREDIT_AMOUNT + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        states["OverallChartDatas"]["CreditAmount"]["TableData"] = response?.data?.data
                        states["OverallChartDatas"]["CreditAmount"]["ChartData"] = [
                            response?.data?.total_op_credit,
                            response?.data?.total_ip_credit,
                            response?.data?.total_lab_credit,
                            response?.data?.total_pharmacy_credit,
                            response?.data?.total_rad_credit,]
                        states["OverallChartDatas"]["CreditAmount"]["ChartLabel"] = ["OP", "IP", "Lab", "Pharmacy", "Radiology"]
                        states["OverallChartDatas"]["CreditAmount"]["TotalCreditAmount"] = response?.data?.total_op_credit + response?.data?.total_ip_credit + response?.data?.total_pharmacy_credit + response?.data?.total_lab_credit + response?.data?.total_rad_credit
                        states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

        RestAPIService.getAll(Serviceurls.CHART_OVERALL_APPOINTMENT_TYPE_COUNT + OverAllChartsAPIQuery).
            then((response) => {
                if (response.data.status === "success") {
                    let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "No Type", "des")
                    states["OverallChartDatas"]["AppointmentType"]["TableData"] = FinalData?.TableData
                    states["OverallChartDatas"]["AppointmentType"]["ChartData"] = FinalData?.ChartData
                    states["OverallChartDatas"]["AppointmentType"]["ChartLabel"] = FinalData?.ChartLabel
                    states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                    this.setState({ states })
                }
                else {
                    states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(response.data.message)
                }
            }).catch((error) => {
                states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                this.setState({ states })
                this.errorMessage(error.message)
            })

        }
        catch(e) {
            this.errorMessage(e?.message)
        }

        
    }

    renderNextScreen =(from,id) => {
        let branchData = this.state.BranchesData?.filter((item)=>(item?.branch_id === id ))
        this.props?.history?.push({pathname: '/AdminDashboard/BranchDashboard', state:{branchName: branchData[0]?.branch_name, branchId: branchData[0]?.branch_id, fromDate: this.state.fromDate, toDate: this.state.toDate , dateIndex: this.state.dateIndex}})
    }

    ChangeIntoSingleArray(APIdata, ElseKey = "", SortOrder = "") {
        try {
            let aggregatedData = {};
            APIdata.forEach(list => {
                list.type_data.forEach(type => {
                    const Type = type.title || ElseKey;
                    if (aggregatedData[Type]) {
                        aggregatedData[Type] += type.values;
                    } else {
                        aggregatedData[Type] = type.values;
                    }
                });
            });
            let FinalTotalArray = Object.entries(aggregatedData).map(([Type, values]) => ({ "title": Type, values }));
            if (SortOrder === "asc") {
                FinalTotalArray = FinalTotalArray.sort((a, b) => a.values - b.values);
            } else if (SortOrder === 'des') {
                FinalTotalArray = FinalTotalArray.sort((a, b) => b.values - a.values);
            }
            let ChartData = []
            FinalTotalArray.forEach((item) => (ChartData.push(item?.values)))
            let ChartLabel = []
            FinalTotalArray.forEach((item) => (ChartLabel.push(item?.title ? item?.title.replace(/Appointment/g, '') : ElseKey)))
            let rows = [];
            // Extracting Table datas
            APIdata?.forEach(item => {
                if (item?.type_data?.length > 0) {
                    rows = [...rows, ...item?.type_data?.map((type, index) => ({
                        title: type.title ? type.title : ElseKey,
                        values: type?.values,
                        branch_name: item?.branch_name ? item?.branch_name : item?.org_name ? item?.org_name : '',
                        branch_id: item?.branch_id,
                    }))]
                }
            })
            if (SortOrder === "asc") {
                rows = rows.sort((a, b) => a.values - b.values);
            } else if (SortOrder === 'des') {
                rows = rows.sort((a, b) => b.values - a.values);
            }
            return { "TableData": rows, "ChartData": ChartData, "ChartLabel": ChartLabel }
        }
        catch (e) {
            console.log(e?.message);
            return { "TableData": [], "ChartData": [], "ChartLabel": [] }
        }
    }

    FilterDate = (From, To, Index) => {
        this.setState({
            fromDate: From,
            toDate: To,
            dateIndex: Index
        },()=>{
            this.RefreshAllChartsData()
        })
    }

    returnFilteredDate() {
        let states = this.state;
        if (states?.fromDate === "" && states?.toDate === "") {
            return "All"
        } else if (states?.fromDate === states?.toDate) {
            return formatDate(states?.fromDate)
        } else {
            return `${formatDate(states?.fromDate)} - ${formatDate(states?.toDate)}`
        }
    }

    HeaderSegment = () => {
        return (
            <Box className="eMed_CharhPage_Header_div" height={'9vh'}>
                <Box flex={0.8}>
                    <Typography fontWeight={600} fontSize={"1.2vw"}>{this.ShowGreeting()}</Typography>
                    <Typography fontSize={"1vw"}>Here is a Overview of your Hospital</Typography>
                </Box>
                <Box flex={0.2} className="eMed_CharhPage_Header_filter">
                    <Box className='eMed_IP_AptDateFlt' mt={'1vh'}>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Tooltip title="Refresh All Charts" arrow placement='top'>
                        <Button onClick={() => {
                            this.RefreshAllChartsData()
                        }} variant='outlined' sx={{ backgroundColor: 'white' }}>
                            <LoopIcon />
                        </Button>
                    </Tooltip>
                </Box>

            </Box>
        )
    }

    OverallCharts = () => {
        let OPRevenueData = {
            image: ImagePaths.DashboardBed.default,
            title: "Total OP",
            branchName: "All Branches",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OP ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OP : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.opPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.opPatientCount : 0,
        }
        let IPRevenueData = {
            image: ImagePaths.DashboardBed.default,
            title: "Total IP",
            branchName: "All Branches",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.IP ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.IP : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.ipPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.ipPatientCount : 0,
        }
        let TotalRevenueData = {
            image: ImagePaths.DashboardOp.default,
            title: "Total Revenue",
            branchName: "All Modules",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.totalPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.totalPatientCount : 0,
        }
        let PharmaRevenueData = {
            image: ImagePaths.Product.default,
            title: "Total Pharmacy",
            branchName: "All Branches",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Pharmacy ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Pharmacy : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.pharmaPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.pharmaPatientCount : 0,
        }
        let LabRevenueData = {
            image: ImagePaths.DashboardTick.default,
            title: "Total Laboratory",
            branchName: "All Branches",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Lab ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Lab : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.labPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.labPatientCount : 0,
        }
        let TotalExpenseData = {
            image: ImagePaths.DashboardExpense.default,
            title: "Total Expense",
            branchName: "All Modules",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalExpenseAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalExpenseAmount : 0,
        }

        return (
            <Box id="overallRef" className="eMed_Ovarall_Chart_Wrapper">
                <Grid container spacing={1} >
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <DoughnutChart
                                    title={"Total Revenue"}
                                    chartLabel={this.state.OverallChartDatas?.TotalRevenue?.ChartLabel && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.TotalRevenue?.ChartData && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.TotalRevenue?.TableData && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TableData : []}
                                    subTitle={'Revenue'}
                                    totalAmt={this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount : 0}
                                    position={"bottom"}
                                    extractValue={"revenue"}
                                    extractLabel={"module"}
                                    showValueAtCenter={true}
                                    tblColumn={BranchOverallRenvenue}
                                /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} className="eMed_DashChart_CardView_Wrapper">
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={TotalRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={IPRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={PharmaRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={TotalExpenseData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={OPRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={LabRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.PaymentCollection?.APILoaded ?
                                <Barchart
                                    title={"Overall Payment Collection"}
                                    Tlt2={"Amount"}
                                    chartLabel={this.state.OverallChartDatas?.PaymentCollection?.ChartLabel ? this.state.OverallChartDatas?.PaymentCollection?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.PaymentCollection?.ChartData ? this.state.OverallChartDatas?.PaymentCollection?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.PaymentCollection?.TableData ? this.state.OverallChartDatas?.PaymentCollection?.TableData : []}
                                    tblColumn={BranchOverallPaymentCollection}
                                /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <Barchart
                                    title={"Branch-Wise Revenue and Expense"}
                                    Tlt2={"Revenue"}
                                    Tlt3={"Expense"}
                                    tblData={this.state.OverallChartDatas?.TotalRevenueBranchWise ? this.state.OverallChartDatas?.TotalRevenueBranchWise : []}
                                    valueToBeExtracted={["overall_revenue", "overall_expense"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={OverallRevenueExpence}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.CreditAmount?.APILoaded ?
                                <DoughnutChart
                                    title={"Credit Amount"}
                                    chartLabel={this.state.OverallChartDatas?.CreditAmount?.ChartLabel && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.CreditAmount?.ChartData && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.CreditAmount?.TableData && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.TableData : []}
                                    subTitle={'Credit Bill Value'}
                                    totalAmt={this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount : 0}
                                    position={"bottom"}
                                    extractValue="lab_bill_summary_id"
                                    extractLabel="lab_test__name"
                                    showValueAtCenter={true}
                                    tblColumn={BranchOverallCreditAmount}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.AppointmentType?.APILoaded ?
                                <Barchart
                                    title={"Appointment Type"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.OverallChartDatas?.AppointmentType?.ChartLabel ? this.state.OverallChartDatas?.AppointmentType?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.AppointmentType?.ChartData ? this.state.OverallChartDatas?.AppointmentType?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.AppointmentType?.TableData ? this.state.OverallChartDatas?.AppointmentType?.TableData : []}
                                    tblColumn={BranchOverallAppointmentType}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    branchCharts = () => {
        return (
            <Box id="branches" className="eMed_Ovarall_Chart_Wrapper" mt={"1vw"}>
                <Typography component={'div'} variant='h5'>Branches</Typography>
                <Grid container spacing={1} mt={'0.5vw'}>
                    {this.state.BranchesData?.length ? this.state.BranchesData?.map((item) => {
                        const array = []
                        array.push(item)
                        return(
                        <Grid item xs={3} >
                            <Box className="eMed_Individual_DashChart">
                                {this.state.apiLoaded ?
                                    <DoughnutChart
                                        title={item?.branch_name}
                                        branchId = {item?.branch_id}
                                        chartLabel={item?.ChartLabel ? item.ChartLabel : []}
                                        chartData={item?.ChartValue ? item.ChartValue : []}
                                        tblData={!item?.ChartValue?.every(item => item === 0) ? array : []}
                                        subTitle={'Revenue'}
                                        totalAmt={item?.overall_revenue ? item?.overall_revenue : 0}
                                        position={"bottom"}
                                        extractValue="revenue"
                                        extractLabel="module"
                                        showValueAtCenter={true}
                                        tblColumn={BranchWiseOverallRenvenue}
                                        allow={true}
                                        isFrom='branches'
                                        renderNextScreen={this.renderNextScreen.bind(this)}
                                    /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                            </Box>
                        </Grid>
                    )}) : <Box className="eMed_noDataDiv">
                            <p id="eMed_revenueCard_Txt">{"No Branches Available"}</p>
                         </Box>
                    }
                </Grid>
            </Box>
        )
    }

    ClosePopUp() {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        return (
            <Box id='eMed_BM_mainDiv'>
                {this.HeaderSegment()}
                {this.state.AlertText !== "" ? <Alert severity="error" onClose={() => { this.setState({ AlertText: "" }) }}>{this.state.AlertText}</Alert> : null}
                <Box className="eMed_Dashboard_Chart_wrapper" height={this.state.AlertText !== "" ? "69vh" : "75vh"}>
                    {this.OverallCharts()}
                    {this.branchCharts()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(AdminDashBoard)

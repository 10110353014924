import { Autocomplete, Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Bucket_Name, Complaint_Type_List, Priority_List } from '../../../Utility/Constants';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';
import DownloadIcon from '@mui/icons-material/Download';


let AWS = require('aws-sdk');
let s3 = new AWS.S3({
    signatureVersion: 'v4',
    accessKeyId: "AKIAT7TG52UHOEIYAJH4",
    secretAccessKey: "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",
    region: "ap-south-1",
    params: { Bucket: Bucket_Name },
});
export default class NewComplaints extends Component {

    constructor(props) {
        super(props)
        let viewData = this.props.history?.location?.state?.viewData
        this.state = {
            assetIdList: [],
            categoryList: [],
            subCategoryList: [],
            cityList: [],
            blockList: [],
            floorList: [],
            spotAreaList: [],
            documentList: viewData?.documents ? viewData?.documents : [{
                doc_name: '',
                remarks: '',
                pdf: ''
            }],
            blockListNew: [],
            complaintTypeList: Complaint_Type_List,
            complaintByList: [],
            priorityList: Priority_List,
            selectedCategory: viewData?.category_name ? viewData?.category_name : null,
            selectedSubCategory: viewData?.sub_category_name ? viewData?.sub_category_name : null,
            selectedAssetType: null,
            selectedCondition: null,
            selectedStatus: null,
            selectedCity: viewData?.block__city ? viewData?.block__city : null,
            selectedBlock: viewData?.block_name ? viewData?.block_name : null,
            selectedFloor: viewData?.floor_name ? viewData?.floor_name : null,
            selectedSportArea: viewData?.spot_name ? viewData?.spot_name : null,
            selectedComplaintType: viewData?.complain_type ? viewData?.complain_type : null,
            selectedComplaintBy: viewData?.complained_by ? viewData?.complained_by : null,
            selectedPriority: viewData?.complaint_priority ? viewData?.complaint_priority : null,
            selectedAssetId: null,
            assetName: viewData?.asset_name ? viewData?.asset_name : '',
            complaintId: viewData?.complaint_id ? viewData?.complaint_id : '',
            noOfDays: viewData?.no_of_days ? viewData?.no_of_days : '',
            vendorCode: viewData?.vendor_code ? viewData?.vendor_code : '',
            vendorName: viewData?.vendor_name ? viewData?.vendor_name : '',
            gstNo: viewData?.gst_no ? viewData?.gst_no : '',
            conPersonName: viewData?.contact_person ? viewData?.contact_person : '',
            venMobNo: viewData?.vendor_mobile_no ? viewData?.vendor_mobile_no : '',
            emailId: viewData?.vendor_email ? viewData?.vendor_email : '',
            notes: viewData?.notes ? viewData?.notes : '',
            addDescrip: viewData?.additional_description ? viewData?.additional_description : '',
            lstServDate: viewData ? viewData?.last_service_date : null,
            nxtSrvDate: viewData ? viewData?.next_service_date : null,
            complaintDateAndTime: viewData ? viewData?.complaint_date : null,
            comOpen: false,
            nxtSrvOpen: false,
            lstSrvOpen: false,
            dupAssetList: '[]',
            onlyView: viewData ? true : false,
            postLoad: false
        }
        this.fileUploadRef = React.createRef();
    }

    componentDidMount() {
        this.getcategorySubCategoryData()
        this.getAssetBlockFloorSpotData()
        this.getAssetIdList()
        this.getBlockListData()
        this.getEmployeeListData()
    }

    getAssetBlockFloorSpotData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_NEW_BLOCK_FLOOR_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            blockListNew: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getcategorySubCategoryData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSER_NEW_CAT_SUB_CAT_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            categoryList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getBlockListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_BLOCK_LIST_GET_POST).
                then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        this.setState({
                            cityList: data?.length > 0 ? data?.filter(item => item?.active_status) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getEmployeeListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_EMPLOYEE_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            complaintByList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAssetIdList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            assetIdList: response.data.data,
                            dupAssetList: JSON.stringify(response.data.data)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkAssetComplaintData = () => {
        try {
            let message = null;
            let states = this.state
            if (states.selectedAssetId === null || states.assetName === '' || states.selectedCategory === null || states.selectedSubCategory === null ||
                states.selectedComplaintBy === null || states.selectedComplaintType === null || states.selectedPriority === null ||
                states.complaintDateAndTime === null
            ) {
                message = 'Please enter all mandatory field'
            } else {
                message = null
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postAssetComplaintData = () => {
        try {
            let states = this.state
            let formData = new FormData()

            let startDate = new Date(states.complaintDateAndTime)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let dateTime = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}T${Aptime}`

            formData.append('assetlist_id', states.selectedAssetId?.id)
            formData.append('complaint_id', states.complaintId ? states.complaintId : '')
            formData.append('complaint_date', states.complaintDateAndTime ? dateTime : '')
            formData.append('complained_by', states.selectedComplaintBy ? states.selectedComplaintBy?.employee_name : '')
            formData.append('complain_status', states.selectedStatus ? states.selectedStatus : '')
            formData.append('complaint_priority', states.selectedPriority ? states.selectedPriority?.value : '')
            formData.append('complain_type', states.selectedComplaintType ? states.selectedComplaintType?.value : '')
            formData.append('additional_description', states.addDescrip ? states.addDescrip : '')
            formData.append('asset_name', states.assetName ? states.assetName : '')
            formData.append('doc_length', ((states.documentList[0]?.pdf || states.documentList[0]?.doc_name) ? states.documentList?.length : 0))

            states.documentList?.forEach((item, index) => {
                formData.append(`documentList[${index}].doc_name`, item.doc_name)
                formData.append(`documentList[${index}].remarks`, item.remarks)
                formData.append(`documentList[${index}].pdf `, item.pdf)
            })
            this.setState({ postLoad: true })
            RestAPIService.create(formData, Serviceurls.ASSET_COMPLAINT_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.setState({ postLoad: false })
                        setTimeout(() => {
                            this.props.history?.push('/AssetComplaints')
                        }, 500)
                    }
                }).catch((error) => {
                    this.setState({ postLoad: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    TextBox = (names, values, labels, width, mr, mt, onlyRead, placeholder, index) => {
        try {
            return (
                <TextField
                    autoComplete='off'
                    variant='standard'
                    size='small'
                    sx={{ width: width, mr: mr, mt: mt }}
                    value={values}
                    label={labels}
                    placeholder={placeholder}
                    name={names}
                    onChange={(e) => this.onChangeHandler(e, names, index)}
                    InputProps={{ readOnly: onlyRead }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, names, index) => {
        try {
            let value = e.target.value
            let states = this.state
            switch (names) {
                case 'doc_name':
                    states.documentList[index]['doc_name'] = value
                    this.setState({ states })
                    break;
                case 'remarks':
                    states.documentList[index]['remarks'] = value
                    this.setState({ states })
                    break;
                case 'vendorName':
                    if (CommonValidation.alphabetOnly(value) || value === '') {
                        this.setState({ vendorName: value })
                    }
                    break;
                case 'conPersonName':
                    if (CommonValidation.alphabetOnly(value) || value === '') {
                        this.setState({ conPersonName: value })
                    }
                    break;
                case 'venMobNo':
                    if (CommonValidation.numberOnly(value) || value === '') {
                        this.setState({ venMobNo: value })
                    }
                    break;
                case 'noOfDays':
                    if (CommonValidation.numberOnly(value) || value === '') {
                        this.setState({ noOfDays: value })
                    }
                    break;
                default:
                    states[names] = value
                    this.setState({ states })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AutoCompleteBox = (options, names, key, labels, width, mr, mt, disable) => {
        try {
            let states = this.state
            return (
                <Autocomplete
                    clearIcon
                    disabled={disable}
                    size='small'
                    sx={{ width: width, mr: mr, mt: mt }}
                    options={options}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option[names]}
                    value={states[key]}
                    onChange={(e, value) => { this.onChangeAutoCompletehandler(value, names, key) }}
                    renderInput={(params) => <TextField variant='standard' autoComplete='off' {...params} label={labels} />}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeAutoCompletehandler = (value, names, key) => {
        try {
            let states = this.state
            switch (names) {
                case 'block_name':
                    this.setState({ selectedBlock: value }, () => {
                        this.setState({
                            floorList: this.state.selectedBlock?.floor_data,
                            selectedFloor: null,
                            selectedSportArea: null,
                        })
                    })
                    break;
                case 'floor_name':
                    this.setState({ selectedFloor: value }, () => {
                        this.setState({
                            spotAreaList: this.state.selectedFloor?.spot_data,
                            selectedSportArea: null
                        })
                    })
                    break;
                case 'spot_name':
                    this.setState({ selectedSportArea: value })
                    break;
                case 'city':
                    this.setState({ selectedCity: value }, () => {
                        let filterData = this.state.blockListNew?.filter(item => item?.block_id === this.state.selectedCity?.id)
                        this.setState({
                            blockList: filterData,
                            selectedBlock: null,
                            selectedFloor: null,
                            selectedSportArea: null
                        })
                    })
                    break;
                case 'category_name':
                    this.setState({ selectedCategory: value }, () => {
                        this.setState({
                            subCategoryList: this.state.selectedCategory?.sub_category_data,
                            selectedSubCategory: null,
                            assetIdList: JSON.parse(this.state.dupAssetList),
                            assetId: null,
                            assetName: '',
                            selectedCity: null,
                            selectedBlock: null,
                            selectedFloor: null,
                            selectedSportArea: null,
                        })
                    })
                    break;
                case 'sub_category_name':
                    this.setState({ selectedSubCategory: value, assetId: null, assetIdList: JSON.parse(this.state.dupAssetList) }, () => {
                        let data = this.state.assetIdList?.filter(item => item?.category__category_name === this.state.selectedCategory?.category_name && item?.sub_category__sub_category_name === this.state.selectedSubCategory?.sub_category_name)
                        if (this.state.selectedCategory !== null && this.state.selectedSubCategory !== null) {
                            if (data?.length === 1) {
                                this.setState({
                                    selectedAssetId: data[0],
                                    assetName: data[0]?.asset_name,
                                    selectedCity: data[0]?.block__city,
                                    selectedBlock: data[0]?.block__block_name,
                                    selectedFloor: data[0]?.floor__floor_name,
                                    selectedSportArea: data[0]?.spot__spot_name,
                                    assetIdList: data,
                                    vendorCode: data[0]?.vendor_code,
                                    vendorName: data[0]?.vendor_name,
                                    gstNo: data[0]?.gst_no,
                                    conPersonName: data[0]?.contact_person,
                                    venMobNo: data[0]?.vendor_mobile_no,
                                    emailId: data[0]?.vendor_email,
                                    notes: data[0]?.notes,
                                    lstServDate: data[0]?.last_service_date,
                                    noOfDays: data[0]?.no_of_days,
                                    nxtSrvDate: data[0]?.next_service_date,
                                })
                            } else {
                                this.setState({ assetIdList: data })
                            }
                        }
                    })
                    break;
                case 'asset_no':
                    this.setState({ assetId: value }, () => {
                        this.setState({
                            assetName: this.state.assetId?.asset_name,
                            selectedCategory: this.state.assetId?.category__category_name,
                            selectedSubCategory: this.state.assetId?.sub_category__sub_category_name,
                            selectedCity: this.state.assetId?.block__city,
                            selectedBlock: this.state.assetId?.block__block_name,
                            selectedFloor: this.state.assetId?.floor__floor_name,
                            selectedSportArea: this.state.assetId?.spot__spot_name,
                            vendorCode: this.state.assetId?.vendor_code,
                            vendorName: this.state.assetId?.vendor_name,
                            gstNo: this.state.assetId?.gst_no,
                            conPersonName: this.state.assetId?.contact_person,
                            venMobNo: this.state.assetId?.vendor_mobile_no,
                            emailId: this.state.assetId?.vendor_email,
                            notes: this.state.assetId?.notes,
                            lstServDate: this.state.assetId?.last_service_date,
                            noOfDays: this.state.assetId?.no_of_days,
                            nxtSrvDate: this.state.assetId?.next_service_date,
                        })
                    })
                default:
                    states[key] = value
                    this.setState({ states })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    DateAndTimePicker = (date, dateSet, values, key, labels, width, mr, mt, disable) => {
        try {
            let states = this.state
            return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        disabled={disable}
                        open={date}
                        onOpen={() => {
                            let states = this.state
                            states[dateSet] = true
                            this.setState({ states })
                        }}
                        onClose={() => {
                            let states = this.state
                            states[dateSet] = false
                            this.setState({ states })
                        }}
                        inputFormat={key === 'complaintDateAndTime' ? 'DD-MM-YYYY hh:mm A' : 'DD-MM-YYYY'}
                        value={states[key]}
                        onChange={(value) => {
                            let states = this.state
                            states[key] = value
                            this.setState({ states })
                        }}
                        maxDate={key === 'nxtSrvDate' ? '' : new Date()}
                        label={labels}
                        renderInput={(props) => <TextField
                            {...props}
                            sx={{ width: width, mr: mr, mt: mt }}
                            variant='standard' size='small'
                            autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={() => {
                                let states = this.state
                                states[dateSet] = true
                                this.setState({ states })
                            }}
                        />}
                    />
                </LocalizationProvider>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                selectedCategory: null,
                selectedSubCategory: null,
                selectedAssetType: null,
                selectedCondition: null,
                selectedStatus: null,
                selectedCity: null,
                selectedBlock: null,
                selectedFloor: null,
                selectedSportArea: null,
                selectedComplaintType: null,
                selectedComplaintBy: null,
                selectedPriority: null,
                assetId: null,
                assetName: '',
                complaintId: '',
                noOfDays: '',
                vendorCode: '',
                vendorName: '',
                gstNo: '',
                conPersonName: '',
                venMobNo: '',
                emailId: '',
                notes: '',
                addDescrip: '',
                lstServDate: null,
                nxtSrvDate: null,
                complaintDateAndTime: null,
                comOpen: false,
                nxtSrvOpen: false,
                lstServOpen: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getS3Key(doc_key) {
        new Promise(resolve => {
            var parms = {
                Bucket: Bucket_Name,
                Key: doc_key, Expires: 300
            };
            s3.getSignedUrl("getObject", parms, (err, url) => {
                if (err) {
                    resolve(err)
                } else {
                    window.open(url)
                }
            })
        })
    }

    renderComplaintScreen = () => {
        try {
            let subCat = this.state.subCategoryList?.length > 0 || !this.state.onlyView ? false : true
            let block = this.state.selectedCity === null ? true : false
            let floor = this.state.selectedBlock === null ? true : false
            let spot = this.state.selectedFloor === null ? true : false
            return (
                <Box component={'div'}>
                    <Box component={'div'} maxHeight={'70vh'} overflow={'auto'}>
                        <Box component={'div'}>
                            <Box component={'div'} height={'4vh'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Asset Details</Typography>
                            </Box>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'}>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.AutoCompleteBox(this.state.assetIdList, 'asset_no', 'selectedAssetId', 'Asset Id *', '17vw', '0.8vw', '', this.state.onlyView)}
                                        {this.TextBox('assetName', this.state.assetName, 'Asset Name *', '17vw', '0.8vw', '', true)}
                                        {this.AutoCompleteBox(this.state.categoryList, 'category_name', 'selectedCategory', 'Catagory *', '17vw', '0.8vw', '', this.state.onlyView)}
                                        {this.AutoCompleteBox(this.state.subCategoryList, 'sub_category_name', 'selectedSubCategory', 'Sub Catagory *', '17vw', '0.8vw', '', subCat)}
                                        {this.AutoCompleteBox(this.state.cityList, 'city', 'selectedCity', 'City *', '17vw', '0.8vw', '', true)}
                                    </Box>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {/* {this.AutoCompleteBox(this.state.branchList, 'branch', 'selectedBranch', 'Branch', '17vw', '0.8vw')} */}
                                        {this.AutoCompleteBox(this.state.blockList, 'block_name', 'selectedBlock', 'Block *', '17vw', '0.8vw', '', true)}
                                        {this.AutoCompleteBox(this.state.floorList, 'floor_name', 'selectedFloor', 'Floor *', '17vw', '0.8vw', '', true)}
                                        {this.AutoCompleteBox(this.state.spotAreaList, 'spot_name', 'selectedSportArea', 'Spot/Area *', '17vw', '0.8vw', '', true)}                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'}>
                                    <Typography fontWeight={600}>Complaints Details</Typography>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.TextBox('complaintId', this.state.complaintId, 'Complaint ID (Read Only)', '17vw', '0.8vw', '', true)}
                                        {this.DateAndTimePicker(this.state.comOpen, 'comOpen', this.state.complaintDateAndTime, 'complaintDateAndTime', 'Date And Time *', '17vw', '0.8vw', '', this.state.onlyView)}
                                        {this.AutoCompleteBox(this.state.complaintByList, 'employee_name', 'selectedComplaintBy', 'Complaint By *', '17vw', '0.8vw', '', this.state.onlyView)}
                                        {this.AutoCompleteBox(this.state.complaintTypeList, 'label', 'selectedComplaintType', 'Complaint Type *', '17vw', '0.8vw', '', this.state.onlyView)}
                                        {this.AutoCompleteBox(this.state.priorityList, 'label', 'selectedPriority', 'Priority *', '17vw', '0.8vw', '', this.state.onlyView)}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} height={'3vh'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Vendor Details</Typography>
                            </Box>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                    {this.TextBox('vendorCode', this.state.vendorCode, 'Vendor Code', '17vw', '0.8vw', '', true)}
                                    {this.TextBox('vendorName', this.state.vendorName, 'Vendor Name *', '17vw', '0.8vw', '', true)}
                                    {this.TextBox('gstNo', this.state.gstNo, 'GST No', '17vw', '0.8vw', '', true)}
                                    {this.TextBox('conPersonName', this.state.conPersonName, 'Contact Person Name', '17vw', '0.8vw', '', true)}
                                    {this.TextBox('venMobNo', this.state.venMobNo, 'Vendor Mobile No', '17vw', '0.8vw', '', true)}
                                </Box>
                                <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                    {this.TextBox('emailId', this.state.emailId, 'Email ID', '17vw', '0.8vw', '', true)}
                                    {this.TextBox('notes', this.state.notes, 'Notes', '17vw', '0.8vw', '', true)}
                                    {this.DateAndTimePicker(this.state.lstSrvOpen, 'lstSrvOpen', this.state.lstServDate, 'lstServDate', 'Last Service Date', '17vw', '0.8vw', '', true)}
                                    {this.TextBox('noOfDays', this.state.noOfDays, 'No Of Days', '17vw', '0.8vw', '', true)}
                                    {this.DateAndTimePicker(this.state.nxtSrvOpen, 'nxtSrvOpen', this.state.nxtSrvDate, 'nxtSrvDate', 'Next Service Date', '17vw', '0.8vw', '', true)}
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Additional Description</Typography>
                            </Box>
                            <Box component={'div'} p={'1vw'}>
                                <TextField multiline rows={5} disabled={this.state.onlyView}
                                    placeholder='Enter Additional Description'
                                    fullWidth
                                    value={this.state.addDescrip}
                                    onChange={(e) => this.setState({ addDescrip: e.target.value })}
                                />
                            </Box>
                        </Box>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                            <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Document</Typography>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} display={'flex'} m={'0.5vw'} flexDirection={'column'}>
                                    {this.state.documentList?.map((item, index) => {
                                        return (
                                            <Box component={'div'} key={index} display={'flex'} mt={'0.5vw'}>
                                                {this.TextBox('doc_name', item?.doc_name, 'Document Name', '17vw', '0.8vw', '', this.state.onlyView, '', index)}
                                                {this.TextBox('remarks', item?.remarks, 'Remarks', '17vw', '0.8vw', '', this.state.onlyView, '', index)}
                                                {this.state.onlyView ?
                                                    <Box component={'div'} display={'flex'}>
                                                        <Typography fontWeight={600} fontSize={'0.9vw'} sx={{ position: 'relative', top: '1.7vw' }}>{item?.pdf ? item?.pdf?.substring(item?.pdf?.lastIndexOf('/') + 1) : ''}</Typography>
                                                        <Box component={'div'} sx={{ position: 'relative', top: '1.2vw' }}>
                                                            <Button
                                                                className='Common_Btn_Min_Width'
                                                                size='small'
                                                                onClick={() => { this.getS3Key(item?.pdf) }}
                                                            ><DownloadIcon color='primary' /></Button>
                                                        </Box>
                                                    </Box> :
                                                    <Box component={'div'} display={'flex'}>
                                                        <Typography fontWeight={600} fontSize={'0.9vw'} sx={{ position: 'relative', top: '1.7vw' }}>{item?.pdf ? item?.pdf?.name : ''}</Typography>
                                                        <Box component={'input'} type='file' sx={{ display: 'none' }}
                                                            // multiple
                                                            accept='application/pdf'
                                                            ref={this.fileUploadRef}
                                                            onChange={(e) => {
                                                                let selectedFile = e.target.files[0]
                                                                if (selectedFile && selectedFile?.type === 'application/pdf') {
                                                                    this.state.documentList[index]['pdf'] = e.target.files[0]
                                                                    this.setState({ docoumentList: this.state.documentList })
                                                                } else {
                                                                    this.errorMessage('Please select a PDF file')
                                                                }
                                                            }}
                                                        />
                                                        <Box component={'div'} sx={{ position: 'relative', top: '1vw' }}>
                                                            <Button
                                                                className='Common_Btn_Min_Width'
                                                                size='small'
                                                                disabled={this.state.onlyView}
                                                                onClick={() => this.fileUploadRef.current.click()}
                                                            ><UploadFileIcon color='primary' /></Button>
                                                            <Button
                                                                className='Common_Btn_Min_Width'
                                                                size='small'
                                                                disabled={this.state.onlyView}
                                                                onClick={() => {
                                                                    if (this.state.documentList?.length === 1) {
                                                                        let newDoc = [{
                                                                            doc_name: '',
                                                                            remarks: '',
                                                                            pdf: ''
                                                                        }]
                                                                        this.setState({ documentList: newDoc })
                                                                    } else {
                                                                        this.state.documentList?.splice(index, 1)
                                                                        this.setState({ documentList: this.state.documentList })
                                                                    }
                                                                }}
                                                            ><CloseIcon color='error' /></Button>
                                                        </Box>
                                                    </Box>}
                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Box component={'div'}>
                                    <Button
                                        sx={{ textTransform: 'capitalize' }}
                                        className='Common_Btn_Min_Width'
                                        startIcon={<AddCircleIcon />}
                                        size='small'
                                        disabled={this.state.onlyView}
                                        onClick={() => {
                                            let newDoc = {
                                                doc_name: '',
                                                remarks: '',
                                                pdf: ''
                                            }
                                            let states = this.state
                                            if (states.documentList[states.documentList?.length - 1]['pdf']?.length === 0) {
                                                this.errorMessage("Previous document is empty")
                                            } else {
                                                this.state.documentList?.push(newDoc)
                                                this.setState({ documentList: this.state.documentList })
                                            }
                                        }}
                                    >Add New Document</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'} height={'7vh'} visibility={this.state.onlyView ? 'hidden' : 'visible'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button
                            variant='outlined'
                            sx={{ height: '2vw', mr: '1vw' }}
                            onClick={() => { this.props.history.push('/AssetComplaints') }}
                        >Reject</Button>
                        <Button
                            variant='contained'
                            sx={{ height: '2vw', mr: '1vw' }}
                            disabled={this.state.postLoad}
                            onClick={() => {
                                let check = this.checkAssetComplaintData()
                                if (check === null) {
                                    this.postAssetComplaintData()
                                } else {
                                    this.errorMessage(check)
                                }
                            }}
                        >Register</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <Box component={'div'}>
                <Box component={'div'} height={'84vh'}>
                    <Box component={'div'} height={'6vh'} display={'flex'} borderBottom={'2px solid lightgray'} alignItems={'center'} p={'0.5vw'}>
                        <Button
                            emed_tid='back_home'
                            className='Common_Btn_Min_Width'
                            startIcon={<ArrowBackIosIcon />}
                            size='small'
                            onClick={() => { this.props.history.push('/AssetComplaints') }}
                        ></Button>
                        <Typography fontWeight={600}>Back to Complaints</Typography>
                    </Box>
                    <Paper sx={{ m: '0.8vw', borderRadius: '5px', maxHeight: '77vh' }} elevation={2}>
                        {this.renderComplaintScreen()}
                    </Paper>
                </Box>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}

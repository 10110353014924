import React, { Component } from "react";
import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from "../../../../Styles/Colors";
import ShowComponents from "./ShowComponent";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import DescriptionIcon from '@mui/icons-material/Description';
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Days, Months, Weeks, Year } from "../../../../Utility/Constants";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";
import { localGetItem } from "../../../../Utility/Services/CacheProviderService";

export default class DocInvestigations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.patientId,
            clinicId: this.props.clinicId,
            appointmentId: this.props.appointmentId,
            openPopUp: false,
            loading: false,
            deletePopup: false,
            removeData: false,
            postLoading: false,
            isLocked: false,
            FrequentData: [],
            SpecialTestData: [],
            Description: "",
            selectedTemplate: {},
            testSummaryID: "",
            SelectedDeleteChip: null,

            clinicId: localGetItem('SelectedDoctorClinicId'),
            labList: [],
            labTestName: [],
            labGroupName: [],
            specialData: [],
            specialTestList: [],
            selectedLabName: "",
            selectedTestName: [],
            selectedTestGroup: [],
            searchSetName: "",
            Testdata: [],
            SelectedList: {},
            TestTransmitted: false
        }
    }

    apiCalls = () => {
        this.getInvestigations()
        this.getLabList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getLabList = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_LAB_LIST + `?clinic_id=${this.state.clinicId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            labList: response.data.data?.laboratory_data ? response.data.data?.laboratory_data : [],
                            selectedLabName: response.data.data?.laboratory_data ? response.data.data?.laboratory_data[0]?.laboratory_id : ''
                        }, () => {
                            if (this.state.selectedLabName) {
                                this.getTestNameList()
                                this.getTestGroupList()
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTestNameList = () => {
        try {
            this.setState({ labNameLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_NAME_GET + `?laboratory_id=${this.state.selectedLabName}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            labTestName: response.data.data,
                            labNameLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ labNameLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ labNameLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ labNameLoad: false })
            this.errorMessage(error.message)
        }
    }

    getTestGroupList = () => {
        try {
            this.setState({ labGroupLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_GROUP_GET + `?laboratory_id=${this.state.selectedLabName}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            labGroupName: response.data.data,
                            labGroupLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ labGroupLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ labGroupLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ labGroupLoad: false })
            this.errorMessage(error.message)
        }
    }

    getSpecialTestList = () => {
        try {
            this.setState({ specialLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SPECIAL_TEST_SEARCH + `?search=${this.state.searchSetName}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            specialTestList: response.data.data,
                            specialLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ specialLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ specialLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ specialLoad: false })
            this.errorMessage(error.message)
        }
    }

    // getSpecialEyeTest = () => {
    //     try {
    //         this.setState({ loading: true })
    //         RestAPIService.getAll(Serviceurls.DOC_SPECIAL_EYE_TEST_GET + `?appointment_id=${this.state.appointmentId}`)
    //             .then((response) => {
    //                 if (response?.data?.status === 'success') {
    //                     this.setState({
    //                         SpecialTestData: response.data.data,
    //                         loading: false
    //                     }, () => {
    //                         const uniqueNames = {};
    //                         const uniqueArray = this.state.SpecialTestData.reduce((result, item) => {
    //                             if (!uniqueNames[item.special_test_name]) {
    //                                 uniqueNames[item.special_test_name] = true;
    //                                 result.push(item);
    //                             }
    //                             return result;
    //                         }, []);
    //                         this.setState({ SpecialTestData: uniqueArray });
    //                     })
    //                 }
    //             }).catch((error) => {
    //                 if (error?.response?.data?.message) {
    //                     this.setState({ loading: false })
    //                     this.errorMessage(error.response.data.message)
    //                 } else {
    //                     this.setState({ loading: false })
    //                     this.errorMessage(error.message)
    //                 }
    //             })
    //     } catch (error) {
    //         this.setState({ loading: false })
    //         this.errorMessage(error.message)
    //     }
    // }

    checkValidation = () => {
        try {
            let message = null;
            if (CommonValidation.removeSpace(this.state.searchSetName) !== '') {
                this.state.specialData?.forEach(element => {
                    if ((element?.name === this.state.searchSetName?.name && element?.name) || (element === this.state.searchSetName && element)) {
                        message = 'Special test already exists'
                    }
                })
            } else {
                message = 'Select or Enter Special Test'
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addSpecialTest = () => {
        try {
            let validation = this.checkValidation()
            if (validation === null) {
                let data = CommonValidation.updateOrInsertList(this.state.specialData, CommonValidation.removeSpace(this.state.searchSetName))
                this.setState({
                    specialData: data
                }, () => {
                    this.setState({ searchSetName: '', specialTestList: [] })
                })
            } else {
                this.errorMessage(validation)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getInvestigations = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_GET + `${this.state.patientId}/?appointment_id=${this.state.appointmentId}`).
                then((response) => {
                    if (response.data.status === "success") {
                        let TestList = []
                        response.data.data?.forEach((item) => {
                            item.display_label = item?.lab_test_names ? item?.lab_test_names : item?.lab_set_names ? item?.lab_set_names : item?.special_test_name ? item?.special_test_name : ""
                            TestList.push(item)
                        })
                        this.setState({
                            Testdata: TestList,
                            TestTransmitted: response.data?.is_transmitted ? response.data?.is_transmitted : false
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getInvestigationsPrint = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_PRINT + `?appointment_id=${this.state.appointmentId}&patient_id=${this.state.patientId}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postTestTransmitData = () => {
        try {
            let data = {
                appointment_id: this.state.appointmentId,
                patient_id: this.state.patientId,
                line_item_ids: this.state.Testdata
            }
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_TRANSMIT)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getInvestigations()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    // checkValidation = () => {
    //     if (Object.keys(this.state.selectedTemplate).length > 0) {
    //         return null
    //     } else {
    //         return "Select Special Test"
    //     }
    // }

    // addInvestigation = () => {
    //     let states = this.state
    //     try {
    //         var data = {
    //             "appointment_id": states?.appointmentId,
    //             "patient_id": states?.patientId,
    //             "comments": states.Description,
    //             "clinic_id": states.clinicId,
    //             "is_transmitted": false,
    //             "special_test_id": typeof states?.selectedTemplate?.special_test_id === "string" ? null : states?.selectedTemplate?.special_test_id,
    //             "special_test_name": typeof states?.selectedTemplate?.special_test_id === "string" ? states?.selectedTemplate?.special_test_name : null
    //         }
    //         let validate = this.checkValidation()
    //         if (validate === null) {
    //             this.setState({ postLoading: true })
    //             RestAPIService.create(data, Serviceurls.DOC_OPT_INVESTIGATION)
    //                 .then((response) => {
    //                     if (response?.data?.status === 'success') {
    //                         this.successMessage(response?.data?.message)
    //                         this.onClearHandler()
    //                         this.getInvestigations()
    //                         this.setState({ openPopUp: false, postLoading: false })
    //                     }
    //                 }).catch((error) => {
    //                     if (error?.response?.data?.message) {
    //                         this.setState({ postLoading: false })
    //                         this.errorMessage(error.response.data.message)
    //                     } else {
    //                         this.setState({ postLoading: false })
    //                         this.errorMessage(error.message)
    //                     }
    //                 })
    //         } else {
    //             this.errorMessage(validate)
    //         }
    //     } catch (error) {
    //         this.setState({ postLoading: false })
    //         this.errorMessage(error.message)
    //     }
    // }

    checkValidate = () => {
        try {
            let validate = null
            let states = this.state
            if (states.selectedLabName === '') {
                if ((states.specialData?.length > 0)) {
                    validate = null
                } else {
                    validate = 'Enter Special Test'
                }
            } else {
                if ((states.selectedTestName?.length > 0) || (states.selectedTestGroup?.length > 0) || (states.specialData?.length > 0)) {
                    validate = null
                } else {
                    validate = 'Select atleast one test'
                }
            }
            return validate;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addInvestigation = () => {
        try {
            let labTests = []; let labSets = []; let specialTests = [];
            this.state.selectedTestName?.forEach(element => {
                labTests?.push({
                    lab_tests_id: element?.id,
                    lab_test_name: element?.test_name
                })
            })
            this.state.selectedTestGroup?.forEach(element => {
                labSets?.push({
                    lab_set_id: element?.id,
                    lab_set_name: element?.name
                })
            })
            this.state.specialData?.forEach(element => {
                specialTests?.push({
                    special_test_id: element?.id ? element?.id : null,
                    special_test_name: element?.name ? element?.name : element
                })
            })
            let data = {
                laboratory_id: this.state.selectedLabName,
                patient_id: this.state.patientId,
                appointment_id: this.state.appointmentId,
                lab_sets: labSets,
                lab_tests: labTests,
                special_tests: specialTests
            }
            let validate = this.checkValidate()
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_POST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.setState({
                                openPopUp: false, postLoading: false
                            }, () => {
                                this.onClearHandler()
                                this.getInvestigations()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }


    DeleteComplaintsData = () => {
        try {
            let data = {
                appointment_id: this.state.appointmentId,
                tests_id: this.state.SelectedList?.id
            }
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_DELETE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({ deletePopup: false, SelectedList: {} }, () => { this.getInvestigations() })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }


    onClearHandler = () => {
        this.setState({
            Description: "",
            selectedTemplate: {},
            selectedText: false,
            NewText: "",
            postLoading: false,
            specialData: [],
            specialTestList: [],
            selectedTestName: [],
            selectedTestGroup: [],
            searchSetName: ""
        })
    }

    renderNewText = (Heading, values, Tab) => {
        let states = this.state
        return (
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
                <TextField
                    label={(`Add ${Heading}`)}
                    size="small"
                    style={{ marginTop: "0.5vw", width: "20vw" }}
                    variant="outlined"
                    value={states.NewText}
                    onChange={(event) => {
                        let value = event.target.value
                        this.setState({ NewText: value })
                    }}
                />
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        if (this.state.NewText) {
                            let data = { special_test_name: this.state.NewText, special_test_id: this.state.NewText }
                            let isHaveItem = values.find((item) => (item?.special_test_name?.toLowerCase().trim() === this.state.NewText?.toLowerCase().trim()))
                            if (!isHaveItem) {
                                values.push(data)
                                states[Tab] = data

                            } else {
                                states[Tab] = isHaveItem
                            }
                            this.setState({ states }, () => { this.setState({ selectedText: "", NewText: "" }) })
                        }
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                </div>
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        this.setState({ selectedText: "", NewText: "" })
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                </div>
            </div>
        )
    }

    renderPopUpScreen = () => {
        try {
            return (
                <Box>
                    <Modal open={this.state.openPopUp}>
                        <Box component={'div'} className='eMed_Doc_Notes_Pop_Up' style={{ width: "30vw", height: "72vh" }}>
                            <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Add Investigation</Typography>
                                <Button className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false }, () => { this.onClearHandler() })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box height={"52.3vh"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', }}>
                                <Box component={'div'} paddingTop={"1vw"}>
                                    <FormControl size='small'>
                                        <InputLabel>Select Laboratory</InputLabel>
                                        <Select
                                            sx={{ width: '25vw', ml: '0.5vw', mb: '0.5vw' }}
                                            label='Select Laboratory'
                                            value={this.state.selectedLabName}
                                            onChange={(e) => this.setState({ selectedLabName: e.target.value }, () => {
                                                this.getTestNameList()
                                                this.getTestGroupList()
                                            })}
                                        >
                                            {this.state.labList?.map((item, index) => (
                                                <MenuItem key={index} value={item?.laboratory_id}>{item?.laboratory__laboratory_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Test Name</Typography>
                                    </Box>
                                    <Box component={'div'} mt={'0.5vw'}>
                                        <Autocomplete
                                            size='small'
                                            clearIcon
                                            sx={{ width: '25vw' }}
                                            multiple
                                            limitTags={3}
                                            options={this.state.labTestName}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.test_name}
                                            value={this.state.selectedTestName}
                                            onChange={(e, value) => {
                                                this.setState({
                                                    selectedTestName: value
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select Test Name' />}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'} mt={'0.5vw'}>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Test Group</Typography>
                                    </Box>
                                    <Box component={'div'} mt={'0.5vw'}>
                                        <Autocomplete
                                            size='small'
                                            clearIcon
                                            sx={{ width: '25vw' }}
                                            multiple
                                            limitTags={3}
                                            options={this.state.labGroupName}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                                            value={this.state.selectedTestGroup}
                                            onChange={(e, value) => {
                                                this.setState({
                                                    selectedTestGroup: value
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select Group Name' />}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'} >
                                    <Box component={'div'} mt={'0.5vw'}>
                                        <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Special Test</Typography>
                                    </Box>
                                    <Box component={'div'} display={'flex'} mt={'0.5vw'}>
                                        <Autocomplete
                                            clearIcon
                                            size='small'
                                            freeSolo
                                            sx={{ width: '25vw' }}
                                            value={this.state.searchSetName}
                                            options={this.state.specialTestList}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                                            onChange={(e, newValue) => {
                                                this.setState({
                                                    searchSetName: newValue
                                                }, () => {
                                                    if (CommonValidation.removeSpace(this.state.searchSetName?.name)?.length > 0) {
                                                        this.addSpecialTest()
                                                    } else {
                                                        this.setState({ specialTestList: [] })
                                                    }
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                onChange={(e) => {
                                                    if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                                                        this.setState({
                                                            searchSetName: e.target.value
                                                        }, () => this.getSpecialTestList())
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                                                            this.addSpecialTest()
                                                        } else {
                                                            this.errorMessage('Search or Enter Special Test')
                                                        }
                                                    }
                                                }}
                                                label={'Search or Enter Special Test'} />}
                                        />
                                    </Box>
                                    <Box component={'div'} mr={'1vw'} mt={'1vw'} overflow={'scroll'} maxHeight={'15vw'}>
                                        {
                                            this.state.specialData?.map((item, index) => (
                                                <Chip
                                                    className='eMed_chip'
                                                    size='small'
                                                    label={((item.name?.length > 10) || (item?.length > 10)) ? <Tooltip placement='top' title={item?.name ? item?.name : item}><Typography fontSize={'0.8vw'} textTransform={'capitalize'}>{item?.name ? (item?.name?.slice(0, 10) + '...') : (item?.slice(0, 10) + '...')}</Typography></Tooltip> : <Typography fontSize={'0.8vw'} textTransform={'capitalize'}>{item?.name ? item?.name : item}</Typography>}
                                                    variant='outlined'
                                                    key={index}
                                                    color={'primary'}
                                                    clickable
                                                    onDelete={() => {
                                                        this.state.specialData?.splice(index, 1)
                                                        this.setState({
                                                            specialData: this.state.specialData
                                                        })
                                                    }}
                                                />
                                            ))}
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box component={'div'}>
                                <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "0.75vw", marginTop: "0.5vw" }}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Special Test</Typography>
                                </Box>
                                <Box component={'div'} style={{ padding: "1vw", height: "25vh" }}>
                                    <Box component={'div'} display={'flex'} flexWrap={'wrap'} overflow={'auto'}>
                                        {
                                            this.state.SpecialTestData?.length > 0 && this.state.SpecialTestData?.map((item, index) => (
                                                <Chip
                                                    className={`eMed_chip ${item?.special_test_id === this.state.selectedTemplate?.special_test_id ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    size='small'
                                                    label={item.special_test_name}
                                                    variant={item.special_test_id === this.state.selectedTemplate?.special_test_id ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        if (item.special_test_id === this.state.selectedTemplate?.special_test_id) {
                                                            this.setState({ selectedTemplate: '' })
                                                        } else {
                                                            this.setState({ selectedTemplate: item })
                                                        }
                                                    }}
                                                    clickable
                                                    sx={{ width: "8.5vw", display: 'flex', justifyContent: 'space-between', }}
                                                    onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                                                />
                                            ))}
                                    </Box>
                                    <div style={{ marginLeft: "0.35vw" }}>
                                        {!this.state.selectedText ?
                                            <Button
                                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', marginTop: "0.25vw" }}
                                                variant='contained'
                                                onClick={() => { this.setState({ selectedText: true, NewText: "" }) }}
                                            >Add</Button>
                                            : null}
                                        {this.state.selectedText ? this.renderNewText("Test", this.state.SpecialTestData, "selectedTemplate") : null}
                                    </div>
                                </Box>
                            </Box> */}
                            {/* <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "0.75vw", marginTop: "0.5vw" }}>
                                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Description</Typography>
                                </Box>
                                <Box component={'div'} style={{ marginLeft: "0.5vw" }}>
                                    <TextField
                                        multiline
                                        label='Description'
                                        rows={3}
                                        sx={{ width: '26.7vw', marginTop: "0.5vw", marginBottom: "0.5vw" }}
                                        value={this.state.Description}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length < 250) {
                                                this.setState({ Description: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed only 250 characters')
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> */}
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.onClearHandler()}
                                >Clear</Button>
                                <Button
                                    disabled={this.state.postLoading}
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => this.addInvestigation()}
                                >Add</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (item) => {
        try {
            this.setState({
                deletePopup: true,
                SelectedList: item
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'3vw'} display={'flex'} p={'0.5vw'} justifyContent={'space-between'} alignItems={'center'} >
                        <Box component={'div'} display={"flex"} flexDirection={'row'} alignItems={"center"}>
                            <Typography fontWeight={600}>Investigation</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                disabled={this.state.isLocked || this.props.isLocked || this.state.TestTransmitted || this.state.labList?.length === 0}
                                onClick={() => { this.postTestTransmitData() }}
                            >
                                <Tooltip placement='top' title='Transmit'>
                                    <Box component={'img'} src={(this.state.isLocked || this.props.isLocked || this.state.TestTransmitted || this.state.labList?.length === 0) ? ImagePaths.medicationTransmitOff.default : ImagePaths.medicationTransmitOn.default} height={'1.1vw'} width={'1.1vw'} />
                                </Tooltip>
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Box component={'div'}>
                                <Button
                                    variant='contained'
                                    sx={{ height: '2vw', width: '5vw', textTransform: "capitalize", marginRight: "0.65vw" }}
                                    onClick={() => { this.setState({ openPopUp: true }) }}
                                    disabled={this.state.isLocked || this.props.isLocked}
                                >Add</Button>
                            </Box>
                            <Box component={'div'}>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    disabled={this.state.Testdata.length > 0 ? false : true}
                                    onClick={() => this.getInvestigationsPrint()}
                                >
                                    <Tooltip placement='top' title='Print'>
                                        <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                                    </Tooltip>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'}>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '20vw', overflow: 'auto' }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Test</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Status</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.Testdata?.length > 0 ? this.state.Testdata?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                        <Button className='Common_Btn_Min_Width'
                                                            disabled={this.state.isLocked || this.props.isLocked || this.state.TestTransmitted}
                                                            onClick={() => this.onDeleteHandler(item)}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked || this.state.TestTransmitted ?
                                                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                        {item?.display_label ?
                                                            item?.display_label?.length > 15 ?
                                                                <Tooltip placement='top' title={item?.display_label}>
                                                                    <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.display_label?.slice(0, 15) + '...'}</Typography>
                                                                </Tooltip> :
                                                                <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.display_label}</Typography> : '-'
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.status ? item?.status : "In Progress"}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell align='right'>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    {this.renderPopUpScreen()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={`${"deleteMsg"} ${"Test"} ${this.state.SelectedList ? this.state.SelectedList?.display_label : "-"} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DeleteComplaintsData.bind(this)}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}
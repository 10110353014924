import { Box, Button, Card, Checkbox, Collapse, FormControlLabel, Grid, IconButton, InputAdornment, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../../src/Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../src/Utility/API/Serviceurls';
import { AmountFormat, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
import './CorpStyle.css';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import SearchIcon from '@mui/icons-material/Search';
import { CurrencySymbol } from '../../../Utility/Constants';


class EmployerBilling extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      BillListData: [],
      ref: props.location?.pathname,
      SelectedList: null,
      SelectedId: null,
      RegisteredName: "",
      SelectedIndex: "",
      SplitBillsType: 'service',
      FromDate: new Date(),
      ToDate: new Date(),
      Description: "",
      Value: "",
      cgst: "",
      igst: "",
      sgst: "",
      IsCompleteBillPopUp: false,
      BillMadeBy: "",
      isCashPayment: false,
      isCardPayment: false,
      isUPIPayment: false,
      isBankPayment: false,
      BillingSplitUps: [],
      AllBillingSplitUps: [],
      SelectedCompleteBill: {},
      CompletePaymentDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      CorpPaidAmount: 0,
      CorpPendingAmount: 0,
      CorpTotalAmount: 0,
      SelectedCompleteBillPayment: 1,
      CorporateSearchKey: ""
    }
  }

  componentDidMount() {
    this.GetBillListData()
  }

  GetBillListData() {
    try {
      RestAPIService.getAll(Serviceurls.CORP_LIST_GET + "?search_key=" + this.state.CorporateSearchKey).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              BillListData: response.data.data.emp_details,
              CorpPaidAmount: response.data.data.card_values.total_paid ? response.data.data.card_values.total_paid.toFixed() : 0,
              CorpPendingAmount: response.data.data.card_values.total_pending ? response.data.data.card_values.total_pending.toFixed() : 0,
              CorpTotalAmount: response.data.data.card_values.total ? response.data.data.card_values.total.toFixed() : 0,
              SelectedIndex: ""
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  onChangeHandler = (event) => {
    try {
      const { name, value } = event.target
      var states = this.state
      if ((name == "igst")) {
        var isValid = CommonValidation.DecimalNumber(value)
        if ((isValid || value === "") && (parseFloat(value) <= 100 || value === "")) {
          states[name] = value
          value != "" ? states["cgst"] = (parseFloat(value) / 2).toFixed(2) : states["cgst"] = ""
          value != "" ? states["sgst"] = (parseFloat(value) / 2).toFixed(2) : states["sgst"] = ""
          this.setState({ states })
        }
      }
      else if ((name == "cgst") || (name == "sgst")) {
        var isValid = CommonValidation.DecimalNumber(value)
        if ((isValid || value === "") && (parseFloat(value) <= 50 || value === "")) {
          states[name] = value
          if ((name == "cgst")) {
            states["sgst"] = value
            states["igst"] = Number.isNaN((parseFloat(states["cgst"])) * 2) ? "" : (parseFloat(states["cgst"])) * 2
          } else {
            states["cgst"] = value
            states["igst"] = Number.isNaN((parseFloat(states["sgst"])) * 2) ? "" : (parseFloat(states["sgst"])) * 2
          }
          this.setState({ states })
        }
      }
      else if ((name == "Value")) {
        var isValid = CommonValidation.NumberWithDot(value)
        if (isValid || value === "") {
          states[name] = value
          this.setState({ states })
        }
      }
      else {
        states[name] = value
        this.setState({
          states
        })
      }
    } catch (error) {
      this.errorMessage(error)
    }
  }

  PostCorporateBill = () => {
    try {
      var states = this.state
      var data = {
        invoice_from_date: DateTime.fromJSDate(states.FromDate).toFormat('yyyy-MM-dd'),
        invoice_to_date: DateTime.fromJSDate(states.ToDate).toFormat('yyyy-MM-dd'),
        igst_percent: isNaN(parseFloat(states.igst)) ? 0 : parseFloat(states.igst),
        sgst_percent: isNaN(parseFloat(states.sgst)) ? 0 : parseFloat(states.sgst),
        cgst_percent: isNaN(parseFloat(states.cgst)) ? 0 : parseFloat(states.cgst),
        employer_id: states.SelectedId,
        amount: parseFloat(states.Value),
        description: states.Description
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.CORPORATE_BILLING).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.GetBillListData()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClearData() {
    this.setState({
      SelectedId: null,
      RegisteredName: "",
      FromDate: new Date(),
      ToDate: new Date(),
      Description: "",
      Value: "",
      cgst: "",
      igst: "",
      sgst: "",
    })
  }

  getSplitUpBills = (emp_id) => {
    try {
      RestAPIService.getAll(Serviceurls.CORPORATE_BILLING + "?employer_id=" + emp_id).
        then((response) => {
          if (response.data.status === "success") {
            if (response.data.message === "No Data Available") {
              this.setState({
                AllBillingSplitUps: [],
                BillingSplitUps: []
              })
            } else {
              this.setState({
                AllBillingSplitUps: response.data.data,
                BillingSplitUps: response.data.data.patient_bills
              })
            }
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  onClickCorpRow(item, index) {
    this.setState({
      SelectedIndex: this.state.SelectedIndex === index ? "" : index,
      SplitBillsType: 'service',
      BillingSplitUps: [],
      AllBillingSplitUps: []
    }, () => {
      this.getSplitUpBills(item.id)
    })
  }

  PrintInvoice(emp_id, invoice_number) {
    try {
      RestAPIService.PrintPDF(Serviceurls.CORPORATE_BILLING + "?employer_id=" + emp_id + "&invoice_number=" + invoice_number + "&export=pdf").
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          console.log(error.message)
        })
    } catch (e) {
      console.log(e.message)
    }
  }

  renderCorporateLeft = () => {
    try {
      let { t } = this.props
      return (
        <Box className="eMed_Emp_billing_left" sx={{ backgroundColor: Colors.ComponentBgColor }}>
          <TableContainer component={'div'} className='eMed_Emp_Billing_Table'>
            <Table aria-label="collapsible table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "5%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("SNo")}</TableCell>
                  <TableCell sx={{ width: "20%" }} className='eMed_Pat_Bill_Tbl_Header' align='left'>{t("CompanyName")}</TableCell>
                  <TableCell sx={{ width: "12%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("LastBillDate")}</TableCell>
                  <TableCell sx={{ width: "15%" }} className='eMed_Pat_Bill_Tbl_Header' align='right'>{t(`Total Bill Amount (${CurrencySymbol})`)}</TableCell>
                  <TableCell sx={{ width: "15%" }} className='eMed_Pat_Bill_Tbl_Header' align='right'>{t(`Total Paid Amount (${CurrencySymbol})`)}</TableCell>
                  <TableCell sx={{ width: "18%" }} className='eMed_Pat_Bill_Tbl_Header' align='right'>{t(`Total Pending Amount (${CurrencySymbol})`)}</TableCell>
                  <TableCell sx={{ width: "15%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{`${t("Action")}`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.BillListData?.length > 0 ? this.state.BillListData.map((item, index) => (
                  <><TableRow key={index} sx={{ backgroundColor: this.state.SelectedIndex === index ? Colors.ThemeLightColor : Colors.white, cursor: 'pointer' }}>
                    <TableCell onClick={() => { this.onClickCorpRow(item, index) }} sx={{ width: "5%" }} className='eMed_Pat_Tbl_one_style' align='center'>{index + 1}</TableCell>
                    <TableCell onClick={() => { this.onClickCorpRow(item, index) }} sx={{ width: "20%" }} className='eMed_Pat_Tbl_one_style' align='left'>
                      <Box>
                        <Typography fontSize={"0.9vw"} fontWeight={600}>{item?.company_name ? item?.company_name : "-"}</Typography>
                        <Typography fontSize={"0.8vw"}>{item?.gst_no ? `GSTIN : ${item?.gst_no}` : "-"}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell onClick={() => { this.onClickCorpRow(item, index) }} sx={{ width: "12%" }} className='eMed_Pat_Tbl_one_style' align='center'>{((item?.last_billed_date == null) || (item?.last_billed_date == "")) ? "-" : item?.last_billed_date.split("T")[0]}</TableCell>
                    <TableCell onClick={() => { this.onClickCorpRow(item, index) }} sx={{ width: "15%" }} className='eMed_Pat_Tbl_one_style' align='right'>{item?.total ? AmountFormat(item?.total)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                    <TableCell onClick={() => { this.onClickCorpRow(item, index) }} sx={{ width: "15%" }} className='eMed_Pat_Tbl_one_style' align='right'>{item?.total_paid ? AmountFormat(item?.total_paid)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                    <TableCell onClick={() => { this.onClickCorpRow(item, index) }} sx={{ width: "18%" }} className='eMed_Pat_Tbl_one_style' align='right'>{item?.total_pending ? AmountFormat(item?.total_pending)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                    <TableCell sx={{ width: "15%" }} className='eMed_Pat_Tbl_one_style' align='center'>
                      <Tooltip title={t("CreateBill")} placement="top" arrow>
                        <Button onClick={() => {
                          this.setState({
                            RegisteredName: item.company_name,
                            SelectedId: item.id
                          })
                        }}
                          className='eMed_usrconf_btn'>
                          <img src={ImagePaths.CreateBill.default} alt="create Bill" className='eMed_action_img' />
                        </Button></Tooltip>
                      <IconButton
                        size='small'
                        onClick={() => { this.onClickCorpRow(item, index) }}
                      >
                        {this.state.SelectedIndex === index ?
                          <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                    <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                      <Collapse
                        in={this.state.SelectedIndex === index}
                        timeout={'auto'}
                      >
                        {/* <Button onClick={() => { this.setState({ SplitBillsType: "patient", BillingSplitUps: this.state.AllBillingSplitUps?.patient_bills ? this.state.AllBillingSplitUps?.patient_bills : [] }) }}
                          id="eMed_Config_btn" sx={{ color: this.state.SplitBillsType === 'patient' ? Colors.themeDark : Colors.black, borderBottom: this.state.SplitBillsType === 'patient' ? 2 : 0 , borderRadius:0}}>Patient Bills</Button> */}
                        <Button onClick={() => { this.setState({ SplitBillsType: "service", BillingSplitUps: this.state.AllBillingSplitUps?.service_bills ? this.state.AllBillingSplitUps?.service_bills : [] }) }}
                          id="eMed_Config_btn" sx={{ color: this.state.SplitBillsType === 'service' ? Colors.themeDark : Colors.black, borderBottom: this.state.SplitBillsType === 'service' ? 2 : 0 , borderRadius:0}}>Service Bills</Button>
                        <Box margin={1} className='eMed_Pat_Bill_Tbl_Two' flexDirection={'column'}>
                          <TableContainer className='eMed_emp_bill_split_tableBody'>
                            <Table stickyHeader>
                              <TableHead>
                                <TableCell sx={{ width: "12%" }} align='center' className='eMed_Pat_Bill_Tbl_Header'>{t("BillDate")}</TableCell>
                                <TableCell sx={{ width: "16%" }} align='center' className='eMed_Pat_Bill_Tbl_Header'>{t("BillNo")}</TableCell>
                                <TableCell sx={{ width: "18%" }} align='center' className='eMed_Pat_Bill_Tbl_Header'>{t("BillingPeriod")}</TableCell>
                                <TableCell sx={{ width: "18%" }} align='center' className='eMed_Pat_Bill_Tbl_Header'>{t("Description")}</TableCell>
                                <TableCell sx={{ width: "12%" }} align='right' className='eMed_Pat_Bill_Tbl_Header'>{t(`BillAmount(${CurrencySymbol})`)}</TableCell>
                                <TableCell sx={{ width: "12%" }} align='center' className='eMed_Pat_Bill_Tbl_Header'>{t("PaymentStatus")}</TableCell>
                                <TableCell sx={{ width: "10%" }} align='center' className='eMed_Pat_Bill_Tbl_Header'>{t("Action")}</TableCell>
                              </TableHead>
                              <TableBody>
                                {this.state.BillingSplitUps && this.state.BillingSplitUps.length > 0 ? this.state.BillingSplitUps.map((list, index) => (
                                  <TableRow key={index}>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{((list.bill_date == null) || (list.bill_date == "")) ? "-" : list.bill_date.split("T")[0]}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{list?.invoice_number ? list.invoice_number : "-"}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{`${list.invoice_from_date} - ${list.invoice_to_date}`}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='center'>
                                      {list.description.length > 20 ?
                                        <Tooltip title={list.description} placement="top" arrow>
                                          <Typography>{list.description.slice(0, 18) + "..."}</Typography>
                                        </Tooltip> : <Typography>{list.description}</Typography>}
                                    </TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{list?.amount ? AmountFormat(list?.amount)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='center'>
                                      {list.status == "Payment Pending" ?
                                        <Typography sx={{ color: Colors.orange }}>Pending</Typography> :
                                        <Typography sx={{ color: Colors.green }}>Completed</Typography>}
                                    </TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ display: 'flex', flexDirection: "row", justifyContent: "center" }}>
                                      {list.status == "Payment Pending" ?
                                        <Tooltip title={t("CompleteBill")} placement="top" arrow>
                                          <Button
                                            onClick={() => {
                                              this.setState({
                                                IsCompleteBillPopUp: true, SelectedCompleteBill: list
                                              })
                                            }}
                                            className='eMed_usrconf_btn'>
                                            <img src={ImagePaths.Check.default} alt="complete Bill" className='eMed_action_img' />
                                          </Button></Tooltip> : null}
                                      <Tooltip title={t("PrintBill")} placement="top" arrow>
                                        <Button className='eMed_usrconf_btn' onClick={() => { this.PrintInvoice(item?.id, list.id) }}>
                                          <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                                        </Button></Tooltip>
                                    </TableCell>
                                  </TableRow>
                                )) : null}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {this.state.BillingSplitUps && this.state.BillingSplitUps.length === 0 ?
                            <Stack margin={"1vw"}>
                              {t("NoRecordsFound")}
                            </Stack> : null}
                        </Box>
                      </Collapse>
                    </TableCell></> </>)) : null}
              </TableBody>
            </Table>
            {this.state.BillListData?.length === 0 ?
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack> : null}
          </TableContainer>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox(label, value, name, width, maxLength, Disable) {
    let states = this.state;
    return (
      (name === "FromDate" || name === "ToDate") ?
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={name === "ToDate" ? (this.state.FromDate == "Invalid Date" || Disable) : Disable}
              label={label}
              value={value}
              name={"datePicker"}
              inputFormat='DD-MM-YYYY'
              views={["year", "month", "day"]}
              onChange={
                (newDate) => {
                  if (newDate === null) {
                    states[name] = new Date()
                  } else {
                    states[name] = newDate.$d
                  }
                  this.setState({
                    states
                  })
                }}
              minDate={name === "ToDate" ? this.state.FromDate : null}
              maxDate={new Date()}
              renderInput={(params) => <TextField sx={{ width: width, marginTop: '0.8vw' }} size='small' {...params} />}
            />
          </LocalizationProvider>
        </Box> :
        <TextField
          disabled={Disable}
          multiline={name === "Description"}
          rows={3}
          inputProps={{ maxLength: maxLength }}
          sx={{ width: width, marginTop: '0.8vw' }}
          size='small'
          label={label}
          name={name}
          value={value}
          onChange={(e) => this.onChangeHandler(e)}
        />
    )
  }

  validation() {
    try {
      var states = this.state
      var { t } = this.props;
      if (
        CommonValidation.removeSpace(states.RegisteredName) !== "" &&
        CommonValidation.removeSpace(states.Description) !== "" &&
        CommonValidation.removeSpace(states.Value) !== "" &&
        states.FromDate != "Invalid Date" &&
        states.ToDate != "Invalid Date") {
        this.PostCorporateBill()
      }
      else {
        if (CommonValidation.removeSpace(states.RegisteredName) === "") {
          this.errorMessage(t("Select Company"))
        }
        else if (CommonValidation.removeSpace(states.Description) === "") {
          this.errorMessage(t("Enter Description"))
        }
        else if (CommonValidation.removeSpace(states.Value) === "") {
          this.errorMessage(t("Enter Value"))
        }
        else if (states.FromDate == "Invalid Date" || states.ToDate == "Invalid Date") {
          this.errorMessage(t("Invalid Date"))
        }
      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  handleCheckBox = (e, key) => {
    let states = this.state;
    states[key] = e.target.checked
    this.setState({ states })
  }

  CorpCompleteBill = () => {
    try {
      var data = {
        "id": this.state.SelectedCompleteBill.id,
        "invoice_number": this.state.SelectedCompleteBill.invoice_number,
        "bill_date": this.state.SelectedCompleteBill.bill_date__date,
        "payment_date": this.state.CompletePaymentDate,
        "payment_made_by": this.state.BillMadeBy,
        "payment_type": this.state.SelectedCompleteBillPayment
      }
      this.LoaderFunction(true)
      RestAPIService.updateWithOutId(data, Serviceurls.CORPORATE_BILLING).
        then((response) => {
          if (response.data.status === "success") {
            this.GetBillListData()
            this.setState({
              IsCompleteBillPopUp: false,
              SelectedCompleteBill: {},
              SelectedIndex: "",
              BillMadeBy: "",
              isCashPayment: false,
              isCardPayment: false,
              isUPIPayment: false,
              isBankPayment: false,
              CompletePaymentDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            }, () => {
              this.LoaderFunction(false)
              this.successMessage(response.data.message)
            })
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  CompleteBillPopup = () => {
    let { t } = this.props
    return (
      <Modal open={this.state.IsCompleteBillPopUp}>
        <Box component={'div'} className="eMed_emp_billing_Popup" >
          <Box component={'div'} className="eMed_emp_billing_Popup_Top">
            <Typography variant='h6'>{"Complete Bill"}</Typography>
            <Button onClick={() => {
              this.setState({
                IsCompleteBillPopUp: false,
                SelectedCompleteBill: {}
              })
            }} >
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
            </Button>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} margin={"1vw"} marginX={"2vw"}>
            {this.renderTextBox("Company Name", this.state.SelectedCompleteBill?.company_name, null, "15vw", 6, true)}
            {this.renderTextBox("Billing Period", `${this.state.SelectedCompleteBill?.invoice_from_date} - ${this.state.SelectedCompleteBill?.invoice_to_date}`, null, "15vw", 6, true)}
            {this.renderTextBox("Billing Value", this.state.SelectedCompleteBill?.amount, null, "15vw", 6, true)}
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} margin={"1vw"} marginX={"2vw"}>
            {this.renderTextBox("Description", this.state.SelectedCompleteBill?.description == "" ? "-" : this.state.SelectedCompleteBill?.description, null, "50vw", 6, true)}
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} margin={"1vw"} marginX={"2vw"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled
                label={'Billing Date'}
                value={new Date(this.state.SelectedCompleteBill?.bill_date__date)}
                inputFormat='DD-MM-YYYY'
                views={["year", "month", "day"]}
                renderInput={(params) => <TextField sx={{ width: "15vw", marginTop: '0.8vw' }} size='small' {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={'Payment Date'}
                value={new Date(this.state.CompletePaymentDate)}
                inputFormat='DD-MM-YYYY'
                views={["year", "month", "day"]}
                // minDate={new Date(this.state.SelectedCompleteBill?.bill_date__date)}
                maxDate={new Date()}
                onChange={(newDate) => { this.setState({ CompletePaymentDate: DateTime.fromJSDate(newDate.$d).toFormat('yyyy-MM-dd') }) }}
                renderInput={(params) => <TextField sx={{ width: "15vw", marginTop: '0.8vw' }} size='small' {...params} />}
              />
            </LocalizationProvider>
            {this.renderTextBox("Payment Made By", this.state.BillMadeBy, "BillMadeBy", "15vw", 6, false)}
          </Box>
          <Box>
            <Box component={"div"}>
              <Box className="eMed_Filter_Header">
                <Typography marginLeft={"2vw"}>{t("PaymentType")}</Typography>
              </Box>
              <Box className="eMed_CheckBox_Group" marginLeft={"2vw"} marginTop={"1vw"}>
                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isCashPayment} onChange={(e) => { this.handleCheckBox(e, "isCashPayment") }} size='small' />} label={t("Cash")} />
                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isCardPayment} onChange={(e) => { this.handleCheckBox(e, "isCardPayment") }} size='small' />} label={t("Card")} />
                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isUPIPayment} onChange={(e) => { this.handleCheckBox(e, "isUPIPayment") }} size='small' />} label={t("UPI")} />
                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isBankPayment} onChange={(e) => { this.handleCheckBox(e, "isBankPayment") }} size='small' />} label={t("Bank Transfer")} />
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'} margin={"1vw"} marginX={"2vw"}>
            <Button variant="outlined" size='small'
              onClick={() => {
                this.setState({
                  BillMadeBy: "",
                  isCashPayment: false,
                  isCardPayment: false,
                  isUPIPayment: false,
                  isBankPayment: false,
                })
              }}
            >{t("Clear")}</Button>
            <Button onClick={() => { this.CorpCompleteBill() }} variant="contained" size='small' sx={{ marginLeft: '2vw' }}>{t("Complete Bill")}</Button>
          </Box>

        </Box>
      </Modal>
    )
  }

  renderCorporateRight = () => {
    try {
      let { t } = this.props
      return (
        <Box className="eMed_Emp_billing_right">
          <Typography className='eMed_refdoc_title'>{t("Service Bill Generation to Corporate")}</Typography>
          <Grid container className='eMed_corpconfig_txt_card' sx={{ height: '40vh' }}>
            <Grid item xs={12}>
              {this.renderTextBox(`${t("RegisteredName")} *`, this.state.RegisteredName, "RegisteredName", "24.5vw", 50, true)}
            </Grid>
            <Grid item xs={6}>
              {this.renderTextBox(`${t("FromDate")} *`, this.state.FromDate, "FromDate", "11.5vw", 50, false)}
            </Grid>
            <Grid item xs={6}>
              {this.renderTextBox(`${t("To date")} *`, this.state.ToDate, "ToDate", "12.2vw", 50, false)}
            </Grid>
            <Grid item xs={12}>
              {this.renderTextBox(`${t("Description")} *`, this.state.Description, "Description", "24.5vw", 100, false)}
            </Grid>
            <Grid item xs={12}>
              {this.renderTextBox(`${t("Value")} *`, this.state.Value, "Value", "24.5vw", 9, false)}
            </Grid>
            <Grid item xs={4}>
              {this.renderTextBox(`${t("IGST %")}`, this.state.igst, "igst", "7.5vw", 6, false)}
            </Grid>
            <Grid item xs={4}>
              {this.renderTextBox(`${t("CGST %")}`, this.state.cgst, "cgst", "7.5vw", 6, false)}
            </Grid>
            <Grid item xs={4}>
              {this.renderTextBox(`${t("SGST %")}`, this.state.sgst, "sgst", "8vw", 6, false)}
            </Grid>
          </Grid>
          <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" marginTop={"8vh"} height={"9vh"}>
            <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
            <Button disabled={!CheckAccessFunc("front_office", "Settings", "Corporate Configuration", "Corporate", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.validation() }}>{t("Add")}</Button>
          </Stack>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({
      isDeleteCliked: false
    })
  }


  DeleteCorporate() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.FO_CONFIG_CORPORATE + "?id=" + states.SelectedList.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.ClearData()
            this.GetBillListData()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  AmountsCard = (label, amount) => {
    return (
      <Card elevation={2} className='eMed_Amt_card_div' sx={{ width: "13vw" }}>
        <div className='eMed_Amt_card'>
          <Typography variant="h6" sx={{ color: Colors.black, fontWeight: "600" }} className='eMed_Amt_card_amount'>{(AmountFormat(amount))}</Typography>
          <Typography sx={{ color: Colors.black, fontSize: '0.8vw' }} className='eMed_Amt_card_label'>{label}</Typography>
        </div>
      </Card>
    )
  }

  render() {
    let { t } = this.props
    try {
      return (
        <Box component={"div"} className='eMed_refdoc_container'>
          <Box component={"div"} className='eMed_Top_Container' sx={{ width: "70%" }}>
            <Box component={"div"} className='eMed_Cards_Container'>
              {this.AmountsCard(t("Total Corporate Bill Amount"), this.state.CorpTotalAmount)}
              {this.AmountsCard(t("Total Corporate Paid Amount"), this.state.CorpPaidAmount)}
              {this.AmountsCard(t("Total Corporate Pending Amount"), this.state.CorpPendingAmount)}
            </Box>
            <Box component={"div"} className='eMed_EmpBill_SearchTextBox'>
              <TextField
                variant='standard'
                placeholder='Search By Company Name'
                value={this.state.SearchText}
                onChange={(e) => {
                  this.setState({ CorporateSearchKey: e.target.value }, () => { this.GetBillListData() })
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "20vw" }} />
            </Box>
          </Box>
          <Grid container >
            <Grid item xs={8.5}>
              {this.renderCorporateLeft()}
            </Grid>
            <Grid item xs={3.5}>
              {this.renderCorporateRight()}
            </Grid>
          </Grid>
          {
            this.state.IsCompleteBillPopUp ? this.CompleteBillPopup() : null
          }
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          <Loader loaderOpen={this.state.isLoader} />
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
}

export default withTranslation()(EmployerBilling)

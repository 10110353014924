import { ImagePaths } from "../../../Utility/ImagePaths";
import { Box, Paper, Typography, Tooltip, Stack } from "@mui/material";
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from "../../../Utility/Constants";

// OverallCharts columns
export const OverallTotalRevenue = [
    {
        field: 'branch_name', headerName: 'Branch Name', hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'op_revenue', headerName: 'OP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.op_revenue ? AmountFormat(row?.op_revenue) : 0}</Typography>)
    },
    {
        field: 'ip_revenue', headerName: 'IP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ip_revenue ? AmountFormat(row?.ip_revenue) : 0}</Typography>)
    },
    {
        field: 'pharmacy_revenue', headerName: "Pharmacy", flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.pharmacy_revenue ? AmountFormat(row?.pharmacy_revenue) : 0}</Typography>)
    },
    {
        field: 'lab_revenue', headerName: 'Labarotory', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.lab_revenue ? AmountFormat(row?.lab_revenue) : 0}</Typography>)
    },
    {
        field: 'radiology_revenue', headerName: 'Radiology', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.radiology_revenue ? AmountFormat(row?.radiology_revenue) : 0}</Typography>)
    },
    {
        field: 'ot_revenue', headerName: 'OT', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ot_revenue ? AmountFormat(row?.ot_revenue) : 0}</Typography>)
    },
    {
        field: 'overall_revenue', headerName: 'Overall Revenue', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.overall_revenue ? AmountFormat(row?.overall_revenue) : 0}</Typography>)
    },
]
export const OverallRevenueExpence = [
    {
        field: 'branch_name', headerName: 'Branch Name', hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'overall_revenue', headerName: 'Total Revenue', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.overall_revenue ? AmountFormat(row?.overall_revenue) : 0}</Typography>)
    },
    {
        field: 'overall_expense', headerName: 'Total Expense', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.overall_expense ? AmountFormat(row?.overall_expense) : 0}</Typography>)
    },
    {
        field: 'overall_profit', headerName: "Profit", flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => {
            let Profit = ((row.overall_revenue && row.overall_expense) ? row.overall_revenue - row.overall_expense : 0)
            return (<Typography fontWeight={600} color={Profit ? (Profit > 0 ? "green" : "red"): "black"}>{Profit ? AmountFormat(Profit) : 0}</Typography>)
        }
    },
]

export const OverallPaymentCollection = [
    {
        field: 'branch_name', headerName: 'Branch Name', hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'cash', headerName: 'Cash', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.cash ? AmountFormat(row?.cash) : 0}</Typography>)
    },
    {
        field: 'card', headerName: 'Card', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.card ? AmountFormat(row?.card) : 0}</Typography>)
    },
    {
        field: 'upi', headerName: 'UPI', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.upi ? AmountFormat(row?.upi) : 0}</Typography>)
    },
    {
        field: 'cheque', headerName: 'Cheque', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.cheque ? AmountFormat(row?.cheque) : 0}</Typography>)
    },
    {
        field: 'bank_transfer', headerName: 'Bank Transfer', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.bank_transfer ? AmountFormat(row?.bank_transfer) : 0}</Typography>)
    },
]

export const OverallCreditAmount = [
    {
        field: 'branch_name', headerName: 'Branch Name', hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'op_credit', headerName: 'OP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.op_credit ? AmountFormat(row?.op_credit) : 0}</Typography>)
    },
    {
        field: 'ip_credit', headerName: 'IP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ip_credit ? AmountFormat(row?.ip_credit) : 0}</Typography>)
    },
    {
        field: 'pharmacy_credit', headerName: 'Pharmacy', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.pharmacy_credit ? AmountFormat(row?.pharmacy_credit) : 0}</Typography>)
    },
    {
        field: 'lab_credit', headerName: 'Labarotory', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.lab_credit ? AmountFormat(row?.lab_credit) : 0}</Typography>)
    },
    {
        field: 'rad_credit', headerName: 'Radiology', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.rad_credit ? AmountFormat(row?.rad_credit) : 0}</Typography>)
    },
]

// InPatientCharts columns
export const IPRevenueExpenseChart = [
    {
        field: 'branch_name', headerName: 'Branch', sortable: false, hideable: false, flex: 0.34,
        renderCell: ({ row }) => (
            <div>{ row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'ip_patients_count', headerName: 'Patient Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.ip_patients_count ? row?.ip_patients_count : 0}</Typography>)
    },
    {
        field: 'ip_revenue', headerAlign: "right", headerName: `Revenue (${CurrencySymbol})`, flex: 0.33, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.ip_revenue ? AmountFormat(row?.ip_revenue) : 0}</Typography>)
    }
  ]

  export const OverallAppointmentType = [
    {
        field: 'branch_name', headerName: 'Branch', flex: 0.34,
        renderCell: ({ row }) => (
            <div>{ row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'title', headerName: 'Appointment Type', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.title ? row?.title : 0}</Typography>)
    },
    {
        field: 'values', headerName: 'Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.values ? row?.values : 0}</Typography>)
    }
  ]

// OutPatientCharts columns

export const OPSpecialzationChart = [
    {
        field: 'branch_name', headerName: 'Branch', flex: 0.34,
        renderCell: ({ row }) => (
            <div>{ row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'title', headerName: 'Specialzation', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.title ? row?.title : 0}</Typography>)
    },
    {
        field: 'values', headerName: 'Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.values ? row?.values : 0}</Typography>)
    }
  ]

export const OPDoctorsChart = [
    {
        field: 'branch_name', headerName: 'Branch', flex: 0.34,
        renderCell: ({ row }) => (
            <div>{ row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'title', headerName: 'Doctor Name', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.title ? row?.title : 0}</Typography>)
    },
    {
        field: 'values', headerName: 'Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.values ? row?.values : 0}</Typography>)
    }
  ]

export const OPLocationChart = [
    {
        field: 'branch_name', headerName: 'Branch', flex: 0.34,
        renderCell: ({ row }) => (
            <div>{ row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'title', headerName: 'Location', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.title ? row?.title : 0}</Typography>)
    },
    {
        field: 'values', headerName: 'Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.values ? row?.values : 0}</Typography>)
    }
  ]

export const OPServicesChart = [
    {
        field: 'branch_name', headerName: 'Branch', flex: 0.3,
        renderCell: ({ row }) => (
            <div>{ row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'title', headerName: 'Service Name', flex: 0.5, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Box component={'div'}>
            {row.title ? row.title?.length > 35 ?
                <Tooltip placement='top' title={row.title} arrow>
                    <div>{row.title.slice(0, 35) + "..."}</div></Tooltip> :
                row.title : "-"}</Box>)
    },
    {
        field: 'values', headerName: 'Count', flex: 0.2, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.values ? row?.values : 0}</Typography>)
    }
  ]


  export const OPRevenueExpenseChart = [
    {
        field: 'branch_name', headerName: 'Branch', sortable: false, hideable: false, flex: 0.34,
        renderCell: ({ row }) => (
            <div>{ row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'op_patients_count', headerName: 'Patient Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.op_patients_count ? row?.op_patients_count : 0}</Typography>)
    },
    {
        field: 'op_revenue', headerAlign: "right", headerName: `Revenue (${CurrencySymbol})`, flex: 0.33, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.op_revenue ? AmountFormat(row?.op_revenue) : 0}</Typography>)
    }
  ]
// PharmacyCharts columns

export const vendorColumns = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'vendor__name', headerName: 'Vendor Name', flex: 0.33,
        renderCell: ({ row }) => (<div>{row?.vendor__name ? row?.vendor__name : "-"}</div>)
    },
    {
        field: 'total_paid_amount', headerName: 'Amount' + ` (${CurrencySymbol})`, flex: 0.33, type: "number",
        renderCell: ({ row }) => (<Typography>{row?.total_paid_amount ? AmountFormat(row?.total_paid_amount).replace(`${CurrencySymbol}`, "") : `${CurrencySymbol} 0.00`}</Typography>)
    }
]

export const PurchaseSales = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : "-"}</div>)
    },
    {
        field: 'sales_amount', headerAlign: "right", headerName: 'Sales Amount' + ` (${CurrencySymbol})`, flex: 0.33, align: "right",
        renderCell: ({ row }) => (<Typography>{row?.sales_amount ? AmountFormat(row?.sales_amount).replace(`${CurrencySymbol}`, "") : `${CurrencySymbol} 0.00`}</Typography>)
    },
    {
        field: 'purchase_amount', headerAlign: "right", headerName: 'Purchase Amount' + ` (${CurrencySymbol})`, flex: 0.33, align: "right",
        renderCell: ({ row }) => (<Typography>{row?.purchase_amount ? AmountFormat(row?.purchase_amount).replace(`${CurrencySymbol}`, "") : `${CurrencySymbol} 0.00`}</Typography>)
    }
]

export const PharmaPatientDemographics = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.16,
        renderCell: ({ row }) => (
            <div>{row?.branch_name?.length > 15 ?
                <Tooltip placement="top" title={row?.branch_name} arrow><div>{row?.branch_name.slice(0, 15) + "..."}</div></Tooltip>
                : row?.branch_name ? row?.branch_name : "-"}</div>
        )
    },
    {
        field: 'ip_bill_count', headerName: 'IP Count', flex: 0.14, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ip_bill_count ? row?.ip_bill_count : 0}</Typography>)
    },
    {
        field: 'total_inpatient_amount', headerName: 'IP Amount' +  ` (${CurrencySymbol})`, flex: 0.14, type: "number",
        renderCell: ({ row }) => (<Typography>{row.total_inpatient_amount ? AmountFormat(row?.total_inpatient_amount).replace(`${CurrencySymbol}`, "") : `${CurrencySymbol} 0.00`}</Typography>)
    },
    {
        field: 'op_bill_count', headerName: 'OP Count', flex: 0.14, type: "number",
        renderCell: ({ row }) => (<Typography>{row.op_bill_count ? row?.op_bill_count : 0}</Typography>)
    },
    {
        field: 'total_op_amount', headerName: 'OP Amount' +  ` (${CurrencySymbol})`, flex: 0.14, type: "number",
        renderCell: ({ row }) => (<Typography>{row.total_op_amount ? AmountFormat(row?.total_op_amount).replace(`${CurrencySymbol}`, "") : `${CurrencySymbol} 0.00`}</Typography>)
    },
    {
        field: 'self_bill_count', headerName: 'Self Count', flex: 0.14, type: "number",
        renderCell: ({ row }) => (<Typography>{row.self_bill_count ? row?.self_bill_count : 0}</Typography>)
    },
    {
        field: 'total_self_amount', headerName: 'Self Amount' + ` (${CurrencySymbol})`, flex: 0.14, type: "number",
        renderCell: ({ row }) => (<Typography>{row.total_self_amount ? AmountFormat(row?.total_self_amount).replace(`${CurrencySymbol}`, "") : `${CurrencySymbol} 0.00`}</Typography>)
    }
]

export const TopSellingDrugs = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.25,
        renderCell: ({ row }) => (
            <div>{row?.branch_name?.length > 25 ?
                <Tooltip placement="top" title={row?.branch_name} arrow><div>{row?.branch_name.slice(0, 25) + "..."}</div></Tooltip>
                : row?.branch_name ? row?.branch_name : ""}</div>
        )
    },
    {
        field: 'drug_ms__drug_name', headerName: 'Drug Name', flex: 0.25,
        renderCell: ({ row }) => (
            <div>{row?.drug_ms__drug_name?.length > 25 ?
                <Tooltip placement="top" title={row?.drug_ms__drug_name} arrow><div>{row?.drug_ms__drug_name.slice(0, 25) + "..."}</div></Tooltip>
                : row?.drug_ms__drug_name ? row?.drug_ms__drug_name : "-"}</div>
        )
    },
    {
        field: 'drug_counnt', headerName: 'Drug Count', flex: 0.25, type: "number",
        renderCell: ({ row }) =>(<div>{row?.drug_counnt ? row?.drug_counnt : "0"}</div>)
    },
    {
        field: 'drug_amount', headerName: 'Amount' + `(${CurrencySymbol})`, flex: 0.25, type: "number",
        renderCell: ({ row }) => (<Typography>{row.drug_amount ? AmountFormat(row?.drug_amount).replace(`${CurrencySymbol}`, "") : `${CurrencySymbol} 0.00`}</Typography>)
    }
]

// LaboratoryCharts columns

export const mostLeastTest = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'test_name', headerName: 'Test Name', flex: 0.33,
        renderCell: ({ row }) => (<div>{row?.test_name ? row?.test_name : "-"}</div>)
    },
    {
        field: 'count', headerName: 'Count', flex: 0.33, type: "number",
        renderCell: ({ row }) => (<Typography>{row?.count ? row?.count : "0"}</Typography>)
    }
]

export const labLocation = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name : ""}</div>)
    },
    {
        field: 'patient__city__city_name', headerName: 'City', flex: 0.33,
        renderCell: ({ row }) => (<div>{row?.patient__city__city_name ? row?.patient__city__city_name : "-"}</div>)
    },
    {
        field: 'city_count', headerName: 'Count', flex: 0.33, type: "number",
        renderCell: ({ row }) => (<Typography>{row?.city_count ? row?.city_count : "0"}</Typography>)
    }
]

// Branch Module 
export const BranchOverallRenvenue = [
    {
        field: 'org_name', headerName: "Organization", hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.org_name ? row?.org_name :  ""}</div>)
    },
    {
        field: 'op_revenue', headerName: 'OP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.op_revenue ? AmountFormat(row?.op_revenue) : 0}</Typography>)
    },
    {
        field: 'ip_revenue', headerName: 'IP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ip_revenue ? AmountFormat(row?.ip_revenue) : 0}</Typography>)
    },
    {
        field: 'pharmacy_revenue', headerName: 'Pharmacy', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.pharmacy_revenue ? AmountFormat(row?.pharmacy_revenue) : 0}</Typography>)
    },
    {
        field: 'lab_revenue', headerName: 'Labarotory', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.lab_revenue ? AmountFormat(row?.lab_revenue) : 0}</Typography>)
    },
    {
        field: 'radiology_revenue', headerName: 'Radiology', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.radiology_revenue ? AmountFormat(row?.radiology_revenue) : 0}</Typography>)
    },
    {
        field: 'ot_revenue', headerName: 'OT', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ot_revenue ? AmountFormat(row?.ot_revenue) : 0}</Typography>)
    },
    {
        field: 'overall_revenue', headerName: 'Overall Revenue', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.overall_revenue ? AmountFormat(row?.overall_revenue) : 0}</Typography>)
    },
]

export const BranchWiseOverallRenvenue = [
    {
        field: 'branch_name', headerName: "Branch Name", hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name :  ""}</div>)
    },
    {
        field: 'op_revenue', headerName: 'OP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.op_revenue ? AmountFormat(row?.op_revenue) : 0}</Typography>)
    },
    {
        field: 'ip_revenue', headerName: 'IP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ip_revenue ? AmountFormat(row?.ip_revenue) : 0}</Typography>)
    },
    {
        field: 'pharmacy_revenue', headerName: 'Pharmacy', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.pharmacy_revenue ? AmountFormat(row?.pharmacy_revenue) : 0}</Typography>)
    },
    {
        field: 'lab_revenue', headerName: 'Labarotory', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.lab_revenue ? AmountFormat(row?.lab_revenue) : 0}</Typography>)
    },
    {
        field: 'radiology_revenue', headerName: 'Radiology', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.radiology_revenue ? AmountFormat(row?.radiology_revenue) : 0}</Typography>)
    },
    {
        field: 'ot_revenue', headerName: 'OT', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ot_revenue ? AmountFormat(row?.ot_revenue) : 0}</Typography>)
    },
    {
        field: 'overall_revenue', headerName: 'Overall Revenue', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.overall_revenue ? AmountFormat(row?.overall_revenue) : 0}</Typography>)
    },
]

export const BranchOverallPaymentCollection = [
    {
        field: 'org_name', headerName: 'Organization', hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.org_name ? row?.org_name : ""}</div>)
    },
    {
        field: 'cash', headerName: 'Cash', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.cash ? AmountFormat(row?.cash) : 0}</Typography>)
    },
    {
        field: 'card', headerName: 'Card', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.card ? AmountFormat(row?.card) : 0}</Typography>)
    },
    {
        field: 'upi', headerName: 'UPI', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.upi ? AmountFormat(row?.upi) : 0}</Typography>)
    },
    {
        field: 'cheque', headerName: 'Cheque', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.cheque ? AmountFormat(row?.cheque) : 0}</Typography>)
    },
    {
        field: 'bank_transfer', headerName: 'Bank Transfer', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.bank_transfer ? AmountFormat(row?.bank_transfer) : 0}</Typography>)
    },
]

export const BranchPharmaSalesDemoChart = [
    {
        field: 'branch_name', headerName: 'Branch', sortable: false, hideable: false, flex: 0.26,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name :  row?.org_name ? row?.org_name :  ""}</div>)
    },
    {
        field: 'total_inpatient_amount', headerAlign: "right", headerName: `IP (${CurrencySymbol})`, flex: 0.24, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.total_inpatient_amount ? AmountFormat(row?.total_inpatient_amount) : 0}</Typography>)
    },
    {
        field: 'total_op_amount', headerAlign: "right", headerName: `OP (${CurrencySymbol})`, flex: 0.24, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.total_op_amount ? AmountFormat(row?.total_op_amount) : 0}</Typography>)
    },
    {
        field: 'total_self_amount', headerAlign: "right", headerName: `Self (${CurrencySymbol})`, flex: 0.24, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.total_self_amount ? AmountFormat(row?.total_self_amount) : 0}</Typography>)
    }
]

export const BranchLabSalesDemoChart = [
    {
        field: 'branch_name', headerName: 'Branch', sortable: false, hideable: false, flex: 0.26,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name :  row?.org_name ? row?.org_name :  ""}</div>)
    },
    {
        field: 'total_inpatient_amount', headerAlign: "right", headerName: `IP (${CurrencySymbol})`, flex: 0.24, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.total_inpatient_amount ? AmountFormat(row?.total_inpatient_amount) : 0}</Typography>)
    },
    {
        field: 'total_op_amount', headerAlign: "right", headerName: `OP (${CurrencySymbol})`, flex: 0.24, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.total_op_amount ? AmountFormat(row?.total_op_amount) : 0}</Typography>)
    },
    {
        field: 'total_self_amount', headerAlign: "right", headerName: `Self (${CurrencySymbol})`, flex: 0.24, sortable: false, hideable: false, align: "right",
        renderCell: ({ row }) => (<Typography>{row.total_self_amount ? AmountFormat(row?.total_self_amount) : 0}</Typography>)
    }
]

export const BranchOverallCreditAmount = [
    {
        field: 'org_name', headerName: 'Organization', hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.org_name ? row?.org_name :  ""}</div>)
    },
    {
        field: 'op_credit', headerName: 'OP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.op_credit ? AmountFormat(row?.op_credit) : 0}</Typography>)
    },
    {
        field: 'ip_credit', headerName: 'IP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ip_credit ? AmountFormat(row?.ip_credit) : 0}</Typography>)
    },
    {
        field: 'pharmacy_credit', headerName: 'Pharmacy', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.pharmacy_credit ? AmountFormat(row?.pharmacy_credit) : 0}</Typography>)
    },
    {
        field: 'lab_credit', headerName: 'Labarotory', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.lab_credit ? AmountFormat(row?.lab_credit) : 0}</Typography>)
    },
    {
        field: 'rad_credit', headerName: 'Radiology', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.rad_credit ? AmountFormat(row?.rad_credit) : 0}</Typography>)
    },
    {
        field: 'overall_credit', headerName: 'Overall Credit', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.overall_credit ? AmountFormat(row?.overall_credit) : 0}</Typography>)
    },
]

export const BranchWiseOverallCreditAmount = [
    {
        field: 'branch_name', headerName: 'Branch Name', hideable: false, flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name :  row?.org_name ? row?.org_name :  ""}</div>)
    },
    {
        field: 'op_credit', headerName: 'OP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.op_credit ? AmountFormat(row?.op_credit) : 0}</Typography>)
    },
    {
        field: 'ip_credit', headerName: 'IP', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.ip_credit ? AmountFormat(row?.ip_credit) : 0}</Typography>)
    },
    {
        field: 'pharmacy_credit', headerName: 'Pharmacy', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.pharmacy_credit ? AmountFormat(row?.pharmacy_credit) : 0}</Typography>)
    },
    {
        field: 'lab_credit', headerName: 'Labarotory', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.lab_credit ? AmountFormat(row?.lab_credit) : 0}</Typography>)
    },
    {
        field: 'rad_credit', headerName: 'Radiology', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.rad_credit ? AmountFormat(row?.rad_credit) : 0}</Typography>)
    },
    {
        field: 'overall_credit', headerName: 'Overall Credit', flex: 0.33, hideable: false, type: "number",
        renderCell: ({ row }) => (<Typography>{row.overall_credit ? AmountFormat(row?.overall_credit) : 0}</Typography>)
    },
]

export const BranchOverallAppointmentType = [
    {
        field: 'branch_name', headerName: 'Organization', flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name :  ""}</div>)
    },
    {
        field: 'title', headerName: 'Appointment Type', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.title ? row?.title : row.ChartLabel ? row?.ChartLabel :  0}</Typography>)
    },
    {
        field: 'values', headerName: 'Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.values ? row?.values : 0}</Typography>)
    }
]

export const BranchTotalPatientCount = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name :  ""}</div>)
    },
    {
        field: 'total_op_count', headerName: 'OP', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.total_op_count ? row.total_op_count :  0}</Typography>)
    },
    {
        field: 'total_ip_count', headerName: 'IP', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.total_ip_count ? row?.total_ip_count : 0}</Typography>)
    }
]

export const BranchTotalDoctorCount = [
    {
        field: 'branch_name', headerName: 'Branch Name', flex: 0.34,
        renderCell: ({ row }) => (<div>{row?.branch_name ? row?.branch_name :  ""}</div>)
    },
    {
        field: 'title', headerName: 'Specialzation', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.title ? row?.title : 0}</Typography>)
    },
    {
        field: 'values', headerName: 'Count', flex: 0.33, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Typography>{row.values ? row?.values : 0}</Typography>)
    }
]

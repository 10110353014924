import { Autocomplete, Box, Button, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CommonGridHeader, { DeletePopup } from "../../../Components/Common Components/CommonComponents";
import moment from "moment";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Colors } from "../../../Styles/Colors";
import "../CorporateConfigurations/CorpStyle.css"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";

const SchedulesDays = [
    { label: "Sunday", value: "1" },
    { label: "Monday", value: "2" },
    { label: "Tuesday", value: "3" },
    { label: "Wednesday", value: "4" },
    { label: "Thursday", value: "5" },
    { label: "Friday", value: "6" },
    { label: "Saturday", value: "7" }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SlotConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docDetails: [],
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            EHRStartTime1: "",
            EHRStartTime2: "",
            EHRStartTime3: "",
            EHREndTime1: "",
            EHREndTime2: "",
            EHREndTime3: "",
            IntervelTime: "",
            ScheduledSession1: [],
            ScheduledSession2: [],
            ScheduledSession3: [],
            EHRSession: 1,
            EditSlotPopup: false,
            docSchedule: [],
            isEdit: false,
            deletedPopup: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getDoctorDetails();
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getDoctorDetails = () => {
        this.setState({
            docDetails: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            docDetails: response.data.data,
                        },()=>{this.LoaderFunction(false)})
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error.response?.data.status === "fail") {
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    getDoctorSchedule = (id) => {
        try {
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.SCHEDULE_POST_API + "?doctor_id=" + id)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            docSchedule: response.data.data,
                        }, () => {
                            this.setState({ EditSlotPopup: true, disableBtn: false })
                            let session1 = []; this.state.docSchedule.forEach((element) => element?.session_id == 1 ? session1 = element : null)
                            let session2 = []; this.state.docSchedule.forEach((element) => element?.session_id == 2 ? session2 = element : null)
                            let session3 = []; this.state.docSchedule.forEach((element) => element?.session_id == 3 ? session3 = element : null)
                            if (session1?.session_id) {
                                this.getWeekDays(session1?.available_days, "ScheduledSession1")
                                this.setState({
                                    EHRStartTime1: session1?.start_time,
                                    EHREndTime1: session1?.end_time,
                                    IntervelTime: session1?.interval,
                                    ScheduleID1: session1?.schedule_id,
                                    isEdit: true
                                })
                            }
                            if (session2?.session_id) {
                                this.getWeekDays(session2?.available_days, "ScheduledSession2")
                                this.setState({
                                    EHRStartTime2: session2?.start_time,
                                    EHREndTime2: session2?.end_time,
                                    IntervelTime: session2?.interval,
                                    ScheduleID2: session2?.schedule_id,
                                    isEdit: true
                                })
                            }
                            if (session3?.session_id) {
                                this.getWeekDays(session3?.available_days, "ScheduledSession3")
                                this.setState({
                                    EHRStartTime3: session3?.start_time,
                                    EHREndTime3: session3?.end_time,
                                    IntervelTime: session3?.interval,
                                    ScheduleID3: session3?.schedule_id,
                                    isEdit: true
                                })
                            }
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.setState({disableBtn: false})
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    timeString = (time) => {
        const date = new Date(time);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const timeString = `${hours}:${minutes}:${seconds}`;

        return timeString
    }

    getWeekNumbers = (weekNames) => {
        const weekNamesDict = {
            'Sunday': 1, 'Monday': 2, 'Tuesday': 3, 'Wednesday': 4, 'Thursday': 5, 'Friday': 6, 'Saturday': 7
        };

        return weekNames.map(day => weekNamesDict[day]);
    }

    getWeekDays = (weekNames, key) => {
        let ScheduledSession = []
        let states = this.state
        const weekNamesDict = {
            1: 'Sunday', 2: 'Monday', 3: 'Tuesday', 4: 'Wednesday', 5: 'Thursday', 6: 'Friday', 7: 'Saturday'
        };

        ScheduledSession = weekNames.map(day => weekNamesDict[day]);
        states[key] = ScheduledSession
        this.setState({ states })
    }

    PostSlotConfiguration = () => {
        try {
            let { EHRSession, selectedData, IntervelTime } = this.state
            let states = this.state
            var EHRStartTime = EHRSession == 1 ? states?.EHRStartTime1 : EHRSession == 2 ? states?.EHRStartTime2 : states?.EHRStartTime3
            var EHREndTime = EHRSession == 1 ? states?.EHREndTime1 : EHRSession == 2 ? states?.EHREndTime2 : states?.EHREndTime3
            var ScheduledSession = EHRSession == 1 ? states?.ScheduledSession1 : EHRSession == 2 ? states?.ScheduledSession2 : states?.ScheduledSession3
            if (states.ScheduleID1 || states.ScheduleID2 || states.ScheduleID3) {
                var ScheduleID = EHRSession == 1 ? states?.ScheduleID1 : EHRSession == 2 ? states?.ScheduleID2 : states?.ScheduleID3
            }
            if (EHRStartTime != "" && EHRStartTime != "Invalid Date" && EHREndTime != "" && EHREndTime != "Invalid Date" && IntervelTime != "") {
                const newArray = this.getWeekNumbers(ScheduledSession)
                var startTime = ""; var endTime = "";
                if (typeof EHRStartTime === "string" && typeof EHREndTime !== "string") {
                    startTime = EHRStartTime; endTime = this.timeString(EHREndTime)
                } else if (typeof EHRStartTime !== "string" && typeof EHREndTime === "string") {
                    startTime = this.timeString(EHRStartTime); endTime = EHREndTime
                } else if (typeof EHRStartTime === "string" && typeof EHREndTime === "string") {
                    startTime = EHRStartTime; endTime = EHREndTime
                } else {
                    startTime = this.timeString(EHRStartTime); endTime = this.timeString(EHREndTime)
                }
                let data = {
                    "doctor_id": selectedData?.doctor_id,
                    "session_id": EHRSession,
                    "start_time": startTime,
                    "end_time": endTime,
                    "available_days": newArray,
                    "interval": IntervelTime
                }
                if (ScheduleID) {
                    data["schedule_id"] = ScheduleID
                }
                this.setState({disableBtn: true})
                RestAPIService.create(data, Serviceurls.SCHEDULE_POST_API)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.setState({ EditSlotPopup: false, isEdit: false }, () => {
                                this.SelectedTab(1)
                                this.responseSuccessErrorMessage(response.data.message, true)
                                this.setState({disableBtn: false})
                            })
                        }
                    }).catch((error) => {
                        this.setState({disableBtn: false})
                        if (error?.response?.data?.message) {
                            this.responseSuccessErrorMessage(error.response.data.message, false)
                        } else {
                            this.responseSuccessErrorMessage(error.message, false)
                        }
                    })
            } else {
                if (EHRStartTime == "Invalid Date" || EHREndTime == "Invalid Date") {
                    this.responseSuccessErrorMessage("Invalid Time Formate", false)
                } else if (EHRStartTime == "" || EHREndTime == "" || IntervelTime == "") {
                    this.responseSuccessErrorMessage("Mandatory fields should not be empty", false)
                }
            }
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    deleteSessionData = () => {
        let states = this.state
        try {
            if ((states.EHRSession == 1 && states.ScheduleID1) || (states.EHRSession == 2 && states.ScheduleID2) || (states.EHRSession == 3 && states.ScheduleID3)) {
                var ScheduleID = states.EHRSession == 1 ? states?.ScheduleID1 : states.EHRSession == 2 ? states?.ScheduleID2 : states?.ScheduleID3
                RestAPIService.create("", Serviceurls.SCHEDULE_POST_API + ScheduleID + "/delete/")
                    .then(response => {
                        if (response.data.status == "success") {
                            this.setState({ EditSlotPopup: false, isEdit: false }, () => {
                                this.SelectedTab(1)
                                this.responseSuccessErrorMessage(response.data.message, true)
                            })
                        }
                    }).catch((e => {
                        this.responseSuccessErrorMessage(e.response.data.message, false)
                    }))
            } else {
                this.responseSuccessErrorMessage("No Schedule for this Session ", false)
            }

        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false)
        }
    }

    msgClose() {
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
    }

    handleEdit = (item) => {
        this.setState({
            selectedData: item
        }, () => { this.getDoctorSchedule(item?.doctor_id); })
    }

    renderTimePicker = (key, value, label) => {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker label={label}
                    onChange={(newStartTime) => {
                        var states = this.state;
                        if (newStartTime == null) {
                            states[key] = new Date()
                            this.setState({ states })
                        } else {
                            states[key] = newStartTime.$d;
                            this.setState({ states })
                        }
                    }}
                    value={value ? moment(value, "hh:mm a") : ""}
                    renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)" }} size="small" />} />
            </LocalizationProvider>
        )
    }

    SelectedTab = (tab) => {
        this.setState({
            EHRStartTime1: "",
            EHRStartTime2: "",
            EHRStartTime3: "",
            EHREndTime1: "",
            EHREndTime2: "",
            EHREndTime3: "",
            IntervelTime: "",
            ScheduledSession1: [],
            ScheduledSession2: [],
            ScheduledSession3: [],
            EHRSession: tab,
            ScheduleID1: "",
            ScheduleID2: "",
            ScheduleID3: ""
        })
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }
    removeDataList = () => {
        this.deleteSessionData()
        this.setState({
            deletedPopup: false
        })
    }

    OpenAppointmentSlotPopUP = () => {
        let states = this.state
        return (
            <Box>
                <Modal open={true}>
                    <Box className="eMed_WebCamPop_Main" sx={{ width: "40vw", height: "55vh" }}>
                        <Box component={'div'} className="eMed_WebCam_Header">
                            <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                Slot Configuration
                            </Typography>
                            <Button onClick={() => { this.setState({ EditSlotPopup: false, isEdit: false }, () => { this.SelectedTab(1) }) }} >
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                            </Button>
                        </Box>
                        <hr />
                        <Box component={'div'} style={{ height: "35vh", margin: "1.5vw" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "1vw", paddingLeft: "2vw", paddingRight: "2vw", marginBottom: "1vw" }}>
                                <Button variant={this.state.EHRSession == 1 ? 'contained' : 'outlined'} size='small' style={{ width: "8vw" }} onClick={() => { if (!this.state.isEdit) { this.SelectedTab(1) } else { this.setState({ EHRSession: 1 }) } }}>Session 1</Button>
                                <Button variant={this.state.EHRSession == 2 ? 'contained' : 'outlined'} size='small' style={{ width: "8vw" }} onClick={() => { if (!this.state.isEdit) { this.SelectedTab(2) } else { this.setState({ EHRSession: 2 }) } }}>Session 2</Button>
                                <Button variant={this.state.EHRSession == 3 ? 'contained' : 'outlined'} size='small' style={{ width: "8vw" }} onClick={() => { if (!this.state.isEdit) { this.SelectedTab(3) } else { this.setState({ EHRSession: 3 }) } }}>Session 3</Button>
                            </div>
                            {this.state.EHRSession == 1 ?
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                        <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                            SESSION 1
                                        </Typography>
                                        <IconButton style={{ marginLeft: "0.5vw" }} size='small'>
                                            <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                onClick={() => { this.setState({ deletedPopup: true }) }}
                                            />
                                        </IconButton>
                                    </div>
                                    <hr />
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", justifyContent: "space-between" }}>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHRStartTime1", this.state.EHRStartTime1, "Start Time *")}
                                            </div>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHREndTime1", this.state.EHREndTime1, "End Time *")}
                                            </div>
                                            <div style={{ marginTop: "0.25vw", marginRight: "0.5vw" }}>
                                                <TextField
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 3 }}
                                                    label={"Enter Intervel in Minutes *"}
                                                    size="small"
                                                    value={this.state.IntervelTime}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        let isValid = CommonValidation.numberOnly(value)
                                                        if (value === "" || isValid && value <= 300) {
                                                            states["IntervelTime"] = value
                                                            this.setState({ states })
                                                        }
                                                    }}
                                                    style={{ width: "12vw" }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", marginBottom: "1vw" }}>
                                            <FormControl>
                                                <InputLabel style={{ width: "" }}>Select Week *</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={this.state.ScheduledSession1}
                                                    onChange={(e) => {
                                                        states["ScheduledSession1"] = e.target.value
                                                        this.setState({ states })
                                                    }}
                                                    style={{ width: "34.5vw" }}
                                                    input={<OutlinedInput label="Select Week *" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {SchedulesDays.map((name) => (
                                                        <MenuItem key={name.value} value={name.label}>
                                                            <Checkbox checked={this.state.ScheduledSession1.indexOf(name.label) > -1} />
                                                            <ListItemText primary={name.label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div></div> : null}
                            {this.state.EHRSession == 2 ?
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                        <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                            SESSION 2
                                        </Typography>
                                        <IconButton style={{ marginLeft: "0.5vw" }} size='small'>
                                            <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                onClick={() => { this.setState({ deletedPopup: true }) }}
                                            />
                                        </IconButton>
                                    </div>
                                    <hr />
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", justifyContent: "space-between" }}>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHRStartTime2", this.state.EHRStartTime2, "Start Time *")}
                                            </div>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHREndTime2", this.state.EHREndTime2, "End Time *")}
                                            </div>
                                            <div style={{ marginTop: "0.25vw", marginRight: "0.5vw" }}>
                                                <TextField
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 3 }}
                                                    label={"Enter Intervel in Minutes *"}
                                                    size="small"
                                                    value={this.state.IntervelTime}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        let isValid = CommonValidation.numberOnly(value)
                                                        if (value === "" || isValid && value <= 300) {
                                                            states["IntervelTime"] = value
                                                            this.setState({ states })
                                                        }
                                                    }}
                                                    style={{ width: "12vw" }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", marginBottom: "1vw" }}>
                                            <FormControl>
                                                <InputLabel style={{ width: "" }}>Select Week *</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={this.state.ScheduledSession2}
                                                    onChange={(e) => {
                                                        states["ScheduledSession2"] = e.target.value
                                                        this.setState({ states })
                                                    }}
                                                    style={{ width: "34.5vw" }}
                                                    input={<OutlinedInput label="Select Week *" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {SchedulesDays.map((name) => (
                                                        <MenuItem key={name.value} value={name.label}>
                                                            <Checkbox checked={this.state.ScheduledSession2.indexOf(name.label) > -1} />
                                                            <ListItemText primary={name.label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div></div> : null}
                            {this.state.EHRSession == 3 ? <div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                    <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                        SESSION 3
                                    </Typography>
                                    <IconButton style={{ marginLeft: "0.5vw" }} size='small'>
                                        <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                            onClick={() => { this.setState({ deletedPopup: true }) }}
                                        />
                                    </IconButton>
                                </div>
                                <hr />
                                <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", justifyContent: "space-between" }}>
                                        <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                            {this.renderTimePicker("EHRStartTime3", this.state.EHRStartTime3, "Start Time *")}
                                        </div>
                                        <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                            {this.renderTimePicker("EHREndTime3", this.state.EHREndTime3, "End Time *")}
                                        </div>
                                        <div style={{ marginTop: "0.25vw", marginRight: "0.5vw" }}>
                                            <TextField
                                                autoComplete="off"
                                                inputProps={{ maxLength: 3 }}
                                                label={"Enter Intervel in Minutes *"}
                                                size="small"
                                                value={this.state.IntervelTime}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    let isValid = CommonValidation.numberOnly(value)
                                                    if (value === "" || isValid && value <= 300) {
                                                        states["IntervelTime"] = value
                                                        this.setState({ states })
                                                    }
                                                }}
                                                style={{ width: "12vw" }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", marginBottom: "1vw" }}>
                                        <FormControl>
                                            <InputLabel style={{ width: "" }}>Select Week *</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={this.state.ScheduledSession3}
                                                onChange={(e) => {
                                                    states["ScheduledSession3"] = e.target.value
                                                    this.setState({ states })
                                                }}
                                                style={{ width: "34.5vw" }}
                                                input={<OutlinedInput label="Select Week *" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {SchedulesDays.map((name) => (
                                                    <MenuItem key={name.value} value={name.label}>
                                                        <Checkbox checked={this.state.ScheduledSession3.indexOf(name.label) > -1} />
                                                        <ListItemText primary={name.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div></div> : null}
                        </Box>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <Button variant='contained' size='small' emed_tid="SlotConfig_Save_Btn" id="eMed_Config_btn" disabled={this.state.disableBtn} onClick={() => { this.PostSlotConfiguration() }}>Save</Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        )
    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, docDetails } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.04, renderCell: (index) => index.api.getRowIndex(index.row.doctor_id) + 1 },
            {
                field: 'doctor_name', headerName: t('DoctorName'), flex: 0.11, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.doctor_name} arrow><div>{cellvalues?.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip> : cellvalues?.row?.doctor_name}</div>
                    )
                }
            },
            {
                field: 'registration_proof_number', headerName: t('RegistrationNo'), flex: 0.11, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.registration_proof_number?.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.registration_proof_number} arrow><div></div></Tooltip> : cellvalues?.row?.registration_proof_number}</div>
                    )
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.05, sortable: false, hideable: false, align: "center", renderCell: ({ row }) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <Tooltip title="Edit" placement="top" arrow>
                                    <Button className="eMed_Edit_btn eMed_usrconf_btn" emed_tid="SLotConfig_Edit_Btn" disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Slot Configuration", "SubTab")?.editAccess} size='small'>
                                        <img src={CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Slot Configuration", "SubTab")?.editAccess ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} style={{ height: "1.5vw", width: "1.5vw" }} alt='Edit'
                                            onClick={() => { this.handleEdit(row) }} />
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container">
                <Paper className="eMed_srvConfig_Div" >
                    <DataGrid
                        rows={docDetails}
                        columns={columns}
                        getRowId={(row) => row.doctor_id}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        disableSelectionOnClick
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => {
                                return (
                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        headerHeight={40}
                        rowCount={docDetails.length}
                        page={current_page}
                        onPageChange={(newPage) => this.setState({ current_page: newPage })}
                        pageSize={rowsPerPage}
                        onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        loading={this.state.isLoader}
                    />
                </Paper>
                {this.state.EditSlotPopup ?
                    <div>
                        {this.OpenAppointmentSlotPopUP()}
                    </div> : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("Confirm Delete this Session")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(SlotConfig);
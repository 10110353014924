import './App.css';
import './index.css';
import Muidrawer from './Shell/Muidrawer';
import './Utility/i18n/locales/config';
import { createTheme, ThemeProvider, experimental_sx as sx } from '@mui/material/styles';
import { Colors } from './Styles/Colors';
import { localSetItem, localGetItem } from "../src/Utility/Services/CacheProviderService";
import i18n from '../src/Utility/i18n/locales/config';


function App() {

  // setting the Theme And Language on Local Storage, if Not there on first execution on system --- starts

  if (localGetItem("theme") === null && localGetItem("language") === null) {
    localSetItem("theme", "default")
    localSetItem("language", "en")
    window.location.reload(false)
  }
  else if (localGetItem("theme") === null) {
    localSetItem("theme", "default")
    window.location.reload(false)
  }
  else if (localGetItem("language") === null) {
    localSetItem("language", "en")
    window.location.reload(false)
  }

  // setting the Theme And Language on Local Storage, if Not there on first execution on system --- ends

  //  Custom Theme and MUI override Rules --- starts
  const CustomTheme = createTheme({

    typography: {
      "fontFamily": `Open Sans`,
    },

    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.8vw",
            // color: "#ffffff",
            // backgroundColor: Colors.ThemeColor
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: Colors.pageTopBackground
          }
        }
      },
      //mui chip label style 
      MuiChip: {
        styleOverrides: {
          label: {
            fontSize: '0.7vw'
          }
        }
      }
    },

    palette: {
      background: {
        default: Colors.BgColor
      },
      primary: {
        main: Colors.ThemeColor,
        contrastText: Colors.ContrastText
      },
      secondary: {
        main: Colors.SecondaryColor,
        contrastText: Colors.ContrastText
      }
    }
  });

  //  Custom Theme and MUI override Rules --- ends 

  i18n.changeLanguage(localGetItem("language"))

  return (

    <ThemeProvider theme={CustomTheme}>
      <Muidrawer />
    </ThemeProvider>
  )
}
export default App;



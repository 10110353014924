import React, { Component } from "react";
import '../IpDemograhics.css';
import { Box, Paper, Grid, Stack, Typography, Accordion, AccordionDetails, AccordionSummary, Tooltip } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { CommonPatientDetails, CommonPatientCard } from "../../../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../../../Styles/Colors";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../../Utility/API/Serviceurls";
import { getCachevalue } from "../../../../../Utility/Services/CacheProviderService";
import { t } from "i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDateAndTime } from '../../../../../Components/CommonFunctions/CommonFunctions'

class ClinicNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientData: {},
      attenderData: {},
      vitalData: [],
      SymptomsData: [],
      ExaminationData: [],
      ProvDiagnosisText: "",
      clinicalNurseNotesData: [],
      labDetails: [],
      ip_id: null,
      isErrorMsg: false,
      isErrorMsgText: "",
      successMsg: false,
      successMsgText: ""
    }
  }
  componentDidMount() {
    let patientDetails = getCachevalue("IppatientData");
    let patients = JSON.parse(patientDetails);
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    this.setState({
      patientData: patients,
      ip_id: details
    }, () =>{
      this.getVitalData();
      this.getSymptomsData();
      this.getExaminationData();
      this.getProvDiagnosisData();
      this.getNurseNotesData();
      this.getIpLabDetails();
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  
  // Api calls start

  getVitalData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_IP_CLINICAL_NOTES_VITAL + `?ip_admission_id=${this.state?.ip_id?.ip_admission_id}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              vitalData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSymptomsData() {
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_SYMPTOMS + "?ip_admission_id=" + this.state?.ip_id?.ip_admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SymptomsData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getExaminationData() {
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_EXAMINATION + "?ip_admission_id=" + this.state?.ip_id?.ip_admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ExaminationData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getProvDiagnosisData() {
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_PROVISIONAL_DIAG + "?ip_admission_id=" + this.state?.ip_id?.ip_admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ProvDiagnosisText: response.data.data.notes
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getNurseNotesData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_IP_CLINICAL_NURSE_NOTES + "?ip_admission_id=" + this.state?.ip_id?.ip_admission_id)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              clinicalNurseNotesData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getIpLabDetails = () => {
    this.setState({
      labDetails: []
    })
    try {
      RestAPIService.getAll(`${Serviceurls.IP_LAB_GET}?ip_admission_id=${this.state?.ip_id?.ip_admission_id}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              labDetails: response.data.data,
            }, () => {
              this.state.labDetails.forEach((item, i) => item.index = i)
            });
          }
        })
        .catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        });
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  // Api calls End

  viewVitalsList = () => {
    const { t } = this.props;
    let states = this.state;
    this.state.vitalData.length > 0 && this.state.vitalData.forEach((element, index) => element.sno = index + 1);

    const columns = [
      {
        field: "vital_entered_time", headerName: t("Date"), flex: 0.090,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.vital_entered_time ? params?.row?.vital_entered_time?.length > 8 ?
          <Tooltip placement='top' title={formatDateAndTime(params?.row?.vital_entered_time)} arrow><div>
            {formatDateAndTime(params?.row?.vital_entered_time).slice(0, 8) + "..."}</div></Tooltip> : formatDateAndTime(params?.row?.vital_entered_time) : "-"}</Box>)
      },
      {
        field: "height_cm", headerName: `${t("Height")} (cm)`, flex: 0.090, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.height_cm ? params?.row?.height_cm : "-"}</Box>)
      },
      {
        field: "weight_kg", headerName: `${t("Weight")} (kg)`, flex: 0.090, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.weight_kg ? params?.row?.weight_kg : "-"}</Box>)
      },
      {
        field: "blood_pressure_systolic", headerName: `${t("BP")} (mm/Hg)`, flex: 0.090, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.blood_pressure_systolic ?
          `${params?.row?.blood_pressure_systolic}/${params?.row?.blood_pressure_diastolic}` : "-"}</Box>)
      },
      {
        field: "temperature", headerName: `${t("TEMP")}`, flex: 0.090, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.temperature ?
          `${params?.row?.temperature}  ${params?.row?.temperature_unit}` : "-"}</Box>)
      },
      {
        field: "pulse_oximetry", headerName: `${t("SpO2")}`, flex: 0.090, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.pulse_oximetry ? params?.row?.pulse_oximetry : "-"}</Box>)
      },
      {
        field: "pulse_rate", headerName: `${t("PulseRate")}/min`, flex: 0.090, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.pulse_rate ? params?.row?.pulse_rate : "-"}</Box>)
      },
      {
        field: "blood_sugar", headerName: `${t("BloodGlucose")} (mg/DL)`, flex: 0.090, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.blood_sugar ? params?.row?.blood_sugar : "-"}</Box>)
      },
      {
        field: "comments", headerName: t("Comments"), flex: 0.090,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.comments ? params?.row?.comments : "-"}</Box>)
      },
      {
        field: "nurse_name", headerName: t("NurseName"), flex: 0.090,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.nurse_name ? params?.row?.nurse_name?.length > 9 ?
          <Tooltip arrow><div>{params?.row?.nurse_name.slice(0, 9) + "..."}</div></Tooltip> : params?.row?.nurse_name : "-"}</Box>)
      }
    ]
    return (
      <Box className="eMed_AdHistory_Accord_MainTbl">
        <DataGrid
          rows={states["vitalData"]}
          columns={columns}
          getRowId={(row) => row.id}
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_AdHistory_AccordTbl'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          hideFooter
        />
      </Box>
    )
  }

  viewDoctorNotesList = () => {
    const { t } = this.props
    this.state.SymptomsData.forEach((element, index) => { element.sno = index + 1 })
    this.state.ExaminationData.forEach((element, index) => { element.sno = index + 1 })
    const columns = [
      {
        field: "sym_date", flex: 0.14, headerName: t("Date"),
        renderCell: (params) => (
          params?.row?.sym_date.length > 10 ?
            <Tooltip title={params?.row?.sym_date} placement='top' arrow>
              <div>{params?.row?.sym_date ? params.row.sym_date.slice(0, 10) + '...' : '-'}</div>
            </Tooltip> : <div>{params?.row?.sym_date ? params.row.sym_date : '-'}</div>
        )
      },
      {
        field: "symptoms_name", flex: 0.17, headerName: t("SymtomName"),
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.symptoms_name ? params?.row?.symptoms_name?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.symptoms_name} arrow>
              <div>{params?.row?.symptoms_name.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.symptoms_name : "-"}
        </Box>)
      },
      {
        field: "duration", flex: 0.14, headerName: t("Duration"), headerAlign: "center", align: 'center',
        renderCell: (params) => {
          let DurationText = params?.row?.duration ? `${params?.row?.duration}  ${params?.row?.duration_type}` : "-"
          return (DurationText)
        }
      },
      {
        field: "description", flex: 0.18, headerName: t("Description"),
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.description ? params?.row?.description?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.description} arrow>
              <div>{params?.row?.description.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.description : "-"}
        </Box>)
      },
      {
        field: "created_by", flex: 0.14, headerName: t("CreatedBy"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.created_by ? params?.row?.created_by : "-"}</Box>)
      },
      {
        field: "modified_by", flex: 0.14, headerName: t("UpdatedBy"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by ? params?.row?.modified_by : "-"}</Box>)
      }
    ]
    const Examinecolumn = [
      {
        field: "exm_date", flex: 0.14, headerName: t("Date"),
        renderCell: (params) => {
          let Date = params?.row?.exm_date ? params?.row?.exm_date.split(" ")[0] : "-"
          return (Date)
        }
      },
      {
        field: "exam_name", flex: 0.17, headerName: t("Name"),
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.exam_name ? params?.row?.exam_name?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.exam_name} arrow>
              <div>{params?.row?.exam_name.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.exam_name : "-"}
        </Box>)
      },
      {
        field: "description", flex: 0.18, headerName: t("Description"),
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.description ? params?.row?.description?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.description} arrow>
              <div>{params?.row?.description.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.description : "-"}
        </Box>)
      },
      {
        field: "created_by", flex: 0.14, headerName: t("CreatedBy"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.created_by ? params?.row?.created_by : "-"}</Box>)
      },
      {
        field: "modified_by", flex: 0.14, headerName: t("UpdatedBy"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by ? params?.row?.modified_by : "-"}</Box>)
      }
    ]
    return (
      <Stack sx={{ backgroundColor: Colors.configHomeBg, height: "90vh" }} direction = "column" spacing={3}>
        <Paper className="eMed_AdHistory_Accord_MainTbl" sx={{ backgroundColor: Colors.white, marginBottom: "0.5vw" }} elevation={1}>
          <Typography id="eMed_AdHistory_AccordTxt" sx={{ padding: "0.3vw" }}>{t("Symptom")}</Typography>
          <DataGrid
            sx={{height: "28.1vh"}}
            rows={this.state.SymptomsData}
            columns={columns}
            getRowId={(row) => row['sno']}
            headerHeight={40}
            hideFooter={true}
            components={{
              NoRowsOverlay: () => (
                <Stack className='eMed_AdHistory_AccordTbl'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            disableSelectionOnClick
          />
        </Paper>
        <Paper className="eMed_AdHistory_Accord_MainTbl" sx={{ backgroundColor: Colors.white, marginBottom: "0.5vw" }} elevation={1}>
          <Typography id="eMed_AdHistory_AccordTxt" sx={{ padding: "0.3vw" }}>{t("Examination")}</Typography>
          <DataGrid
            sx={{height: "28.1vh"}}
            rows={this.state.ExaminationData}
            columns={Examinecolumn}
            getRowId={(row) => row['sno']}
            headerHeight={40}
            hideFooter={true}
            components={{
              NoRowsOverlay: () => (
                <Stack className='eMed_AdHistory_AccordTbl'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            disableSelectionOnClick
          />
        </Paper>
        <Paper sx={{ backgroundColor: Colors.white, height: "20vh", marginBottom: "0.5vw" }} elevation={1}>
          <div id="eMed_AdHistory_AccordTxt" sx={{ padding: "0.3vw", height: "6vh", backgroundColor: Colors.configHomeBg }}>{t("ProvDiagnosis")}</div>
          <div>{this.state.ProvDiagnosisText}</div>
        </Paper>
      </Stack>
    )
  }

  viewNurseNotesList = () => {
    const { t } = this.props
    const columns = [
      {
        field: "date_time", headerName: "Date & Time", flex: 0.16, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.date_time ? params?.row?.date_time.length > 20 ?
          <Tooltip title={params?.row?.date_time} placement='top' arrow><div>{params?.row?.date_time.slice(0, 20) + '...'}</div></Tooltip> : params?.row?.date_time : "-"}</Box>)
      },
      {
        field: "nurse_notes", headerName: "Nurse Notes", flex: 0.47,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.nurse_notes ? (JSON.parse(params?.row?.nurse_notes).blocks[0].text.length > 35 ?
            <Tooltip placement='top' title={JSON.parse(params?.row?.nurse_notes).blocks[0].text} arrow>
              <div>{JSON.parse(params?.row?.nurse_notes).blocks[0].text.slice(0, 35) + '...'}</div></Tooltip>
            : JSON.parse(params?.row?.nurse_notes).blocks[0].text) : "-"}</Box>)
      },
      {
        field: "modified_by", headerName: "Modified By", flex: 0.37,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by ? params?.row?.modified_by : '-'}</Box>)
      }
    ]
    return (
      <Box component={'div'} className='eMed_AdHistory_Accord_MainTbl'>
        <DataGrid
          rows={this.state.clinicalNurseNotesData}
          columns={columns}
          getRowId={(row) => row?.id}
          components={{
            NoRowsOverlay: () => (
              <Box className='eMed_AdHistory_AccordTbl'>
                {t("NoRecordsFound")}
              </Box>
            )
          }}
          disableSelectionOnClick
          hideFooter
        />
      </Box>
    )
  }

  viewLabList = () => {
    const columns = [
      {
        field: 'lab_test_name', headerName: t('TestName'), flex: 0.18, renderCell: ({ row }) => {
          let test_name = row["lab_test_name"]? row["lab_test_name"]: row["lab_set_name"] ? row["lab_set_name"] : row["special_test"] ? row["special_test"] : "";
          return (
            <div>{test_name?.length > 25 ? <Tooltip placement="top" title={test_name} arrow><div>{test_name.slice(0, 20) + "..."}</div></Tooltip> : test_name ? test_name : "-"}</div>
          )
        }
      },
      {
        field: 'tested_on', headerAlign:"center", headerName: t('TestedOn'), flex: 0.18, align:"center", renderCell: ({ row }) => {
          return (
            <div>{row?.tested_on?.length > 25 ? <Tooltip placement="top" title={row?.tested_on} arrow><div>{row?.tested_on.slice(0, 20) + "..."}</div></Tooltip> : row?.tested_on ? row?.tested_on : "-"}</div>
          )
        }
      },
      {
        field: 'trun_over_time', headerAlign:"center", headerName: t('TurnOverTime'), flex: 0.17, align:"center", renderCell: ({ row }) => {
          return (<div>{row?.trun_over_time?.length > 25 ? <Tooltip placement="top" title={row?.trun_over_time} arrow><div>{row?.trun_over_time.slice(0, 20) + "..."}</div></Tooltip> : row?.trun_over_time ? row?.trun_over_time : "-"}</div>
          )
        }
      },
      {
        field: 'status', headerName: t('Status'), flex: 0.17, renderCell: ({ row }) => {
          let inProgress = row["status"] ? ((row["status"] !== "Result Completed" && row["status"] !== "Result Delivered" &&  row["status"] !== "OutSourced") ? "Processing" : row["status"] === "Result Delivered" ? "Result Completed" : row["status"]) : (row["special_test"]) ? "Special Test":"Awaiting Transmit";
          return (<Stack sx={{ padding: "0.4vw", borderRadius: "0.2vw", backgroundColor: row["status"] === "Result Completed" || row["status"] === "Result Delivered" ? Colors.BillCompleteBg : row["status"] === "OutSourced" ? Colors.BillPendingBg : (row["status"] !== "Result Completed" && row["status"] !== "Result Delivered" && row["status"] !== "OutSourced" &&  row["status"] !== "") ? Colors.BillInProcessBg : null }}>
            <Typography sx={{ color: (row["status"] === "Result Completed" || row["status"] === "Result Delivered") ? Colors.BillCompleteTxt : row["status"] === "OutSourced" ? Colors.BillPendingTxt : (row["status"] !== "Result Completed"&& row["status"] !== "Result Delivered" && row["status"] !== "OutSourced" &&  row["status"] !== "") ? Colors.BillInProcessTxt : null }}>
              {inProgress ? inProgress : "-"}
            </Typography>
          </Stack>)
        }
      },
      {
        field: 'modified_by', headerName: t('UpdatedBy'), flex: 0.18, renderCell: ({ row }) => {
          return (<div>{row?.modified_by?.length > 25 ? <Tooltip placement="top" title={row?.modified_by} arrow><div>{row?.modified_by.slice(0, 20) + "..."}</div></Tooltip> : row?.modified_by ? row?.modified_by : "-"}</div>)
        }
      }
    ]
    return (  
    <Box component={'div'} className='eMed_AdHistory_Accord_MainTbl'>
    <DataGrid
      rows={this.state.labDetails}
      columns={columns}
      getRowId={(row) => row?.id}
      components={{
        NoRowsOverlay: () => (
          <Box className='eMed_AdHistory_AccordTbl'>
            {t("NoRecordsFound")}
          </Box>
        )
      }}
      disableSelectionOnClick
      hideFooter
    />
  </Box>)
  }

  render() {
    const { t } = this.props;
    let states = this.state;

    let data = {
      lineOne: t("Emergency Contact"),
      lineTwo: `${states.ip_id?.attender_name ? states.ip_id?.attender_name : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${states.ip_id?.attender_mobile ? states.ip_id?.attender_mobile : "-"}`
    }

    const clinicList = [
      { id: 1, label: t("Vitals"), data: this.viewVitalsList() },
      { id: 2, label: t("DoctorNotes"), data: this.viewDoctorNotesList() },
      { id: 3, label: t("NurseNotes"), data: this.viewNurseNotesList() },
      { id: 4, label: t("Laboratory"), data: this.viewLabList() },
    ];

    return (
      <Box id="eMed_AdHistory_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container className="eMed_AdHistory_Box" >
          <Stack className="eMed_AdHistory_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
            <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
              <CommonPatientDetails data={this.state.patientData} />
            </Stack>
            <CommonPatientCard details={data} showDetailed={true} />
          </Stack>
          <Box className="eMed_AdHistory_Div" sx={{ backgroundColor: Colors.configHomeBg, marginTop: "0.625vw" }}>
            {
              clinicList.length > 0 && clinicList.map(list => (
                <Accordion id="eMed_AdHistory_Accord" key={list.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography id="eMed_AdHistory_AccordTxt">{list.label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails id="eMed_AdHistory_AccordDetails">
                    <Box>
                      {list.data}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
            }
          </Box>
        </Grid>
        { this.state.isErrorMsg ? <ToastMsg severity={'error'} msg={this.state.isErrorMsgText} msgPop={this.msgClose.bind(this)}/>: null }
      </Box>
    )
  }
}
export default withTranslation()(ClinicNotes)

import { Box, Button, Chip, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import AddIcon from '@mui/icons-material/Add';
import { defaultRouteList } from '../../../../Utility/Constants';

class DocPlanRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Comments: "",
      InhouseTreatment: "",
      NewInhouseTreatment: "",
      NewInhouseTreatmentAdd: false,
      InhouseTreatmentList: [],
      Route: "",
      NewRoute: "",
      NewRouteAdd: false,
      RouteList: JSON.parse(JSON.stringify(defaultRouteList)),
      Procedure: "",
      NewProcedure: "",
      NewProcedureAdd: false,
      ProcedureList: [],
      DosageStrength: "",
      Description: "",
      SelectedId: null,
      EditPlanDatas: {}
    }
  }

  componentDidMount() {
    if (this.props.EditPlanDatas && Object.keys(this.props.EditPlanDatas).length !== 0) {
      this.getPlanFrequentList(true, this.props.EditPlanDatas)
      this.props.ClearPropsData()
    } else {
      this.getPlanFrequentList()
    }
  }
  componentDidUpdate(PrevProps) {
    if (PrevProps?.EditPlanDatas !== this.props.EditPlanDatas && this.props.EditPlanDatas && Object.keys(this.props.EditPlanDatas).length !== 0) {
      let states = this.state
      let propData = this.props.EditPlanDatas
      states.InhouseTreatment = propData?.treatment_name
      states.DosageStrength = propData?.dosage
      states.Route = propData?.route
      states.Comments = propData?.comments
      states.Procedure = propData?.procedure_name
      states.Description = propData?.description
      states.EditPlanDatas = this.props.EditPlanDatas
      let ishaveTreatment = this.state.InhouseTreatmentList.some((list) => (list === this.state.InhouseTreatment))
      if (!ishaveTreatment) {
        let List = this.state.InhouseTreatmentList
        List.push(this.state.InhouseTreatment)
        states.InhouseTreatmentList = List
      }
      let isRoute = this.state.RouteList.some((list) => (list?.value === this.state.Route))
      if (!isRoute && this.state.Route) {
        let List = this.state.RouteList
        List.push({ label: this.state.Route, value: this.state.Route })
        states.RouteList = List
      }
      let isHaveProcedure = this.state.ProcedureList.some((list) => (list === this.state.Procedure))
      if (!isHaveProcedure && this.state.Procedure) {
        let List = this.state.ProcedureList
        List.push(this.state.Procedure)
        states.ProcedureList = List
      }
      this.setState({ states })
      this.props.ClearPropsData()
    }
  }

  getPlanFrequentList = (forEdit = false, PropData = {}) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PLAN_FREQ)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              InhouseTreatmentList: response.data.data?.inhouse_treatments ? response.data.data?.inhouse_treatments : [],
              ProcedureList: response.data.data?.procedures ? response.data.data?.procedures : [],
            }, () => {
              if (forEdit) {
                let states = this.state
                let ishaveTreatment = this.state.InhouseTreatmentList.some((list) => (list === PropData?.name))
                if (!ishaveTreatment) {
                  let List = this.state.InhouseTreatmentList
                  List.push(PropData?.name)
                  states.InhouseTreatmentList = List
                }
                let isRoute = this.state.RouteList.some((list) => (list?.value === PropData?.route))
                if (!isRoute) {
                  let List = this.state.RouteList
                  List.push({ label: PropData?.route, value: PropData?.route })
                  states.RouteList = List
                }
                let isHaveProcedure = this.state.ProcedureList.some((list) => (list === PropData?.procedure_name))
                if (!isHaveProcedure) {
                  let List = this.state.ProcedureList
                  List.push(PropData?.procedure_name)
                  states.ProcedureList = List
                }
                this.setState({ states })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClearData() {
    this.setState({
      Comments: "",
      InhouseTreatment: "",
      NewInhouseTreatment: "",
      NewInhouseTreatmentAdd: false,
      Route: "",
      NewRoute: "",
      NewRouteAdd: false,
      RouteList: defaultRouteList,
      Procedure: "",
      NewProcedure: "",
      NewProcedureAdd: false,
      DosageStrength: "",
      Description: "",
      SelectedId: null,
      EditPlanDatas: {}
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      deletePopup: false,
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  DeleteAllergy() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGY_FEQ + "?allergy=" + states.SelectedAllergy).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.getPlanFrequentList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  PostPlanData() {
    try {
      var states = this.state
        let data = {
          "patient_id": this.props?.PatientData?.patient_id,
          "appointment_id": this.props?.PatientData?.appointment_id,
          "treatment_name": states?.InhouseTreatment,
          "dosage": states?.DosageStrength,
          "route": states?.Route,
          "comments": states?.Comments,
          "procedure_name": states?.Procedure,
          "description": states?.Description
        }
        if( Object.keys(this.state.EditPlanDatas).length > 0){
          data["treatment_id"] = this.state.EditPlanDatas?.treatment_id ? this.state.EditPlanDatas?.treatment_id : null
          data["procedure_id"] = this.state.EditPlanDatas?.procedure_id ? this.state.EditPlanDatas?.procedure_id : null
        }
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PLAN_POST).
          then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response.data.message)
              this.ClearData()
              this.getPlanFrequentList()
              this.props.CallgetApi('plan')
            } else {
              this.errorMessage(response.data.message)
            }
          }).catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  postTemplateData = () => {
    try {
      let data = {
        doctor_category: this.state.CategoryAddText
      }
      this.setState({ loading: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.ClearData()
            this.getPatientCategoryList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  deleteFrequencysugg = () => {
    try {
      this.setState({ removeData: true })

      RestAPIService.delete(this.state.DeleteChipType === "treatement" ? `${Serviceurls.DOC_DOCTOR_NOTES_PLAN_FREQ}?treatement=${this.state.SelectedDeleteChip}` : `${Serviceurls.DOC_DOCTOR_NOTES_PLAN_FREQ}?procedure=${this.state.SelectedDeleteChip}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getPlanFrequentList()
            if (this.state.DeleteChipType === "treatement" && this.state.SelectedDeleteChip === this.state.InhouseTreatment) {
              this.setState({ InhouseTreatment: "" })
            }
            if (this.state.DeleteChipType === "procedure" && this.state.SelectedDeleteChip === this.state.Procedure) {
              this.setState({ Procedure: "" })
            }
            this.setState({ deletePopup: false, SelectedDeleteChip: null, removeData: false, DeleteChipType: "" }, () => {

            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ deletePopup: false })
  }

  render() {
    const { t } = this.props
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Plan")}</Typography>
        <Box height={"56.5vh"} overflow={'auto'}>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Inhouse Treatment")}</Typography>
            <Box className="eMed_DocNotes_FreqChips_Wrapp">
              {
                this.state.InhouseTreatmentList?.length > 0 ? this.state.InhouseTreatmentList.map((item, index) => (
                  <Chip
                    size='small'
                    className={`eMed_chip ${this.state.InhouseTreatment === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    key={index}
                    label={item}
                    variant={this.state.InhouseTreatment === item ? 'contained' : "outlined"}
                    onClick={() => { this.setState({ InhouseTreatment: item }) }}
                    onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item, DeleteChipType: "treatement" }) }}
                  />
                )) : null
              }
              {this.state.NewInhouseTreatmentAdd ?
                <TextField
                  size='small'
                  value={this.state.NewInhouseTreatment}
                  onChange={(e) => {
                    this.setState({ NewInhouseTreatment: e.target.value })
                  }}
                  inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                  sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.NewInhouseTreatment !== "") {
                      let List = this.state.InhouseTreatmentList
                      if (!List.includes(this.state.NewInhouseTreatment)) {
                        List.push(this.state.NewInhouseTreatment)
                      }
                      this.setState({
                        InhouseTreatmentList: List,
                        InhouseTreatment: this.state.NewInhouseTreatment,
                        NewInhouseTreatment: "",
                        NewInhouseTreatmentAdd: false
                      })
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={this.state.NewInhouseTreatment === ""}
                          onClick={() => {
                            let List = this.state.InhouseTreatmentList
                            if (!List.includes(this.state.NewInhouseTreatment)) {
                              List.push(this.state.NewInhouseTreatment)
                            }
                            this.setState({
                              InhouseTreatmentList: List,
                              InhouseTreatment: this.state.NewInhouseTreatment,
                              NewInhouseTreatment: "",
                              NewInhouseTreatmentAdd: false
                            })
                          }}>
                          <AddIcon color={this.state.NewInhouseTreatment === "" ? 'grey' : 'primary'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }} /> :
                <IconButton
                  onClick={() => {
                    this.setState({
                      NewInhouseTreatmentAdd: true
                    })
                  }}>
                  <AddIcon color={'primary'} />
                </IconButton>
              }
            </Box>
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Dosage Strength")}</Typography>
            <TextField
              size='small'
              className='eMed_DocNotes_TextFields'
              label={t("Dosage Strength")}
              inputProps={{ maxLength: 50 }}
              value={this.state.DosageStrength}
              onChange={(e) => {
                if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                  this.setState({ DosageStrength: e.target.value })
                }
              }}
            />
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Route")}</Typography>
            <Box className="eMed_DocNotes_FreqChips_Wrapp">
              {
                this.state.RouteList?.length > 0 ? this.state.RouteList.map((item, index) => (
                  <Chip
                    size='small'
                    className={`eMed_chip ${this.state.Route === item?.value ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    key={index}
                    label={item?.value}
                    variant={this.state.Route === item?.value ? 'contained' : "outlined"}
                    onClick={() => { this.setState({ Route: item?.value }) }}
                  />
                )) : null
              }
              {this.state.NewRouteAdd ?
                <TextField
                  size='small'
                  value={this.state.NewRoute}
                  onChange={(e) => {
                    this.setState({ NewRoute: e.target.value })
                  }}
                  inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                  sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.NewRoute !== "") {
                      let List = this.state.RouteList
                      let isHaveItem = List.find((item) => (item?.value?.toLowerCase().trim() === this.state.NewRoute?.toLowerCase().trim()))
                      if (!isHaveItem) {
                        List.push({ value: this.state.NewRoute, label: this.state.NewRoute })
                      }
                      this.setState({
                        RouteList: List,
                        Route: isHaveItem ? isHaveItem?.value : this.state.NewRoute,
                        NewRoute: "",
                        NewRouteAdd: false
                      })
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={this.state.NewRoute === ""}
                          onClick={() => {
                            let List = this.state.RouteList
                            let isHaveItem = List.find((item) => (item?.value?.toLowerCase().trim() === this.state.NewRoute?.toLowerCase().trim()))
                            if (!isHaveItem) {
                              List.push({ value: this.state.NewRoute, label: this.state.NewRoute })
                            }
                            this.setState({
                              RouteList: List,
                              Route: isHaveItem ? isHaveItem?.value : this.state.NewRoute,
                              NewRoute: "",
                              NewRouteAdd: false
                            })
                          }}>
                          <AddIcon color={this.state.NewRoute === "" ? 'grey' : 'primary'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }} /> :
                <IconButton
                  onClick={() => {
                    this.setState({
                      NewRouteAdd: true
                    })
                  }}>
                  <AddIcon color={'primary'} />
                </IconButton>
              }
            </Box>
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Comments")}</Typography>
            <TextField
              size='small'
              className='eMed_DocNotes_TextFields'
              multiline={true}
              rows={2}
              label={t("Comments")}
              inputProps={{ maxLength: 100 }}
              value={this.state.Comments}
              onChange={(e) => {
                if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                  this.setState({ Comments: e.target.value })
                }
              }}
            />
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Procedure")}</Typography>
            <Box className="eMed_DocNotes_FreqChips_Wrapp">
              {
                this.state.ProcedureList?.length > 0 ? this.state.ProcedureList.map((item, index) => (
                  <Chip
                    size='small'
                    className={`eMed_chip ${this.state.Procedure === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    key={index}
                    label={item}
                    variant={this.state.Procedure === item ? 'contained' : "outlined"}
                    onClick={() => { this.setState({ Procedure: item }) }}
                    onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item, DeleteChipType: "procedure" }) }}
                  />
                )) : null
              }
              {this.state.NewProcedureAdd ?
                <TextField
                  size='small'
                  value={this.state.NewProcedure}
                  onChange={(e) => {
                    this.setState({ NewProcedure: e.target.value })
                  }}
                  inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                  sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.NewProcedure !== "") {
                      let List = this.state.ProcedureList
                      if (!List.includes(this.state.NewProcedure)) {
                        List.push(this.state.NewProcedure)
                      }
                      this.setState({
                        ProcedureList: List,
                        Procedure: this.state.NewProcedure,
                        NewProcedure: "",
                        NewProcedureAdd: false
                      })
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={this.state.NewProcedure === ""}
                          onClick={() => {
                            let List = this.state.ProcedureList
                            if (!List.includes(this.state.NewProcedure)) {
                              List.push(this.state.NewProcedure)
                            }
                            this.setState({
                              ProcedureList: List,
                              Procedure: this.state.NewProcedure,
                              NewProcedure: "",
                              NewProcedureAdd: false
                            })
                          }}>
                          <AddIcon color={this.state.NewProcedure === "" ? 'grey' : 'primary'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }} /> :
                <IconButton
                  onClick={() => {
                    this.setState({
                      NewProcedureAdd: true
                    })
                  }}>
                  <AddIcon color={'primary'} />
                </IconButton>
              }
            </Box>
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Description")}</Typography>
            <TextField
              size='small'
              className='eMed_DocNotes_TextFields'
              multiline={true}
              rows={2}
              label={t("Description")}
              inputProps={{ maxLength: 100 }}
              value={this.state.Description}
              onChange={(e) => {
                if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                  this.setState({ Description: e.target.value })
                }
              }}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" _id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button disabled={!this.state.InhouseTreatment && !this.state.DosageStrength && !this.state.Route && !this.state.Comments && !this.state.Procedure && !this.state.Description} variant='contained' size="small" id="eMed_Config_btn" onClick={() => {this.PostPlanData() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`Are you sure ? Do you want to remove ${this.state.SelectedDeleteChip} from suggestion ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteFrequencysugg.bind(this)}
          disable={this.state.removeData}
        /> : null}
      </Box>
    )
  }
}
export default withTranslation()(DocPlanRight)

import React, { Component } from "react";
import "./charts.css";
import { Box, Paper, Typography, IconButton } from "@mui/material";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import ExpandPopUp from './ExpandPopUp';

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartItem: this.props,
            chartData: props.chartData?.length > 0 ? props.chartData : [], // single data
            chartLabel: props.chartLabel?.length > 0 ? props.chartLabel : [],
            tblData: props.tblData?.length > 0 ? props.tblData : [], // multiple data
            valueExtractList: props.valueToBeExtracted?.length > 0 ? props.valueToBeExtracted : [],
            extractLabel: props.extractLabel ? props.extractLabel : "",
            isExpandClked: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props) {
            this.setState({
                chartItem: this.props,
                chartData: this.props.chartData?.length > 0 ? this.props.chartData : [],
                chartLabel: this.props.chartLabel?.length > 0 ? this.props.chartLabel : [],
                tblData: this.props.tblData?.length > 0 ? this.props.tblData : [],
                valueExtractList: this.props.valueToBeExtracted?.length > 0 ? this.props.valueToBeExtracted : [],
                extractLabel: this.props.extractLabel ? this.props.extractLabel : "",
            })
        }
    }

    generateData = () => {
        let { chartItem, tblData, valueExtractList, extractLabel, chartData, chartLabel } = this.state;
        let chart_data1 = [], chart_data2 = [], chart_data3 = [], chart_label = [];
        let valueKey1 = "", valueKey2 = "", valueKey3 = "", obj = {}, firstFiveValue = [];

        // Assigning values to be extracted
        if(valueExtractList.length > 0){
            valueKey1 = chartItem.Tlt1 ? valueExtractList[0] : "";
            valueKey2 = chartItem.Tlt1 && chartItem.Tlt2 ? valueExtractList[1] : valueExtractList[0];
            valueKey3 = chartItem.Tlt1 && chartItem.Tlt2 && chartItem.Tlt3 ? valueExtractList[2] : valueExtractList[1];
        }

         // Extracting first five value
         let list = chartData?.length > 0 ? chartData : tblData?.length > 0 ? tblData : [];
         firstFiveValue = list?.length > 0 ? list?.slice(0, 5) : [];

         if (chartLabel?.length > 0) {
            chart_label = chartLabel?.length > 0 ? chartLabel?.slice(0, 5) : [];
         }

         // Assigning the respected values and labels to the corresponding list
         if(chartData?.length > 0 && firstFiveValue?.length > 0 && chartItem.Tlt2){
            chart_data2 = [...firstFiveValue];
         } else if (firstFiveValue?.length > 0 && tblData?.length > 0) {
             firstFiveValue.forEach(item => {

                // Extracting values
                 if (valueKey1 && chartItem.Tlt1) {
                    chart_data1.push(item[valueKey1])
                 } 
                 if(valueKey2 && chartItem.Tlt2){
                    chart_data2.push(item[valueKey2])
                 }
                 if(valueKey3 && chartItem.Tlt3){
                    chart_data3.push(item[valueKey3])
                 }

                 // Extracting labels
                 if (extractLabel) {
                     chart_label.push(item[extractLabel])
                 }
             })
         }
 
         // Adding sixth labels and values to the corresponding lists
         if (chartData?.length >= 6 && chartLabel?.length >= 6) {

            let remainingValue = 0
            remainingValue = chartData.slice(5).reduce((acc, cur) => acc + cur, 0);
            chart_data2.push(remainingValue);
            chart_label.push("Others");

         } else if (valueExtractList.length > 0 && tblData.length >= 6) {

            let InitalValues = {
                value1: 0,
                value2: 0,
                value3: 0 
             };
             
            let remainingValues = tblData.slice(5).reduce((acc, cur) => {
                acc["value1"] += cur[valueKey1] ? cur[valueKey1] : 0;
                acc["value2"] += cur[valueKey2] ? cur[valueKey2] : 0;
                acc["value3"] += cur[valueKey3] ? cur[valueKey3] : 0;
                return acc;
            }, InitalValues);

            if(Object.keys(remainingValues).length > 0){
                if(remainingValues["value1"] && chartItem.Tlt1){
                    chart_data1.push(remainingValues["value1"]);
                } 
                if (remainingValues["value2"] && chartItem.Tlt2){
                    chart_data2.push(remainingValues["value2"]);
                } 
                if (remainingValues["value3"] && chartItem.Tlt3){
                    chart_data3.push(remainingValues["value3"]);
                }
            } 
            chart_label.push("Others");
         } 

        const Data = {
            labels: chart_label,
            datasets: []
        };

        if (chart_data1?.length > 0) {
            obj = {
                label: '',
                data: chart_data1,
                backgroundColor: '#6987D5',
                borderColor: "transparent",
                barThickness: 25,
            }
            Data.datasets.push(obj)
        } 
        if (chart_data2?.length > 0) {
            obj = {
                label: '',
                data: chart_data2,
                backgroundColor: '#7BD8C2',
                borderColor: "transparent",
                barThickness: 25,
            }
            Data.datasets.push(obj)
        } 
        if (chart_data3?.length > 0) {
            obj = {
                label: '',
                data: chart_data3,
                backgroundColor: chartItem.title === "Patient Demographics" ? '#DE7D24':'#D87B7B',
                borderColor: "transparent",
                barThickness: 25,
            }
            Data.datasets.push(obj)
        }
        return Data
    }

    PopupClose = () => {
        this.setState({
            isExpandClked: false
        })
    }

    renderNextScreen = () => {
        if(this.props.isFrom === "branches"){
            this.props?.renderNextScreen("barChart", this.state.chartItem?.branchId, this.state.chartItem?.title, this.state.chartItem?.url, this.state.chartItem?.tableName)
        }
    }

    render() {
        const {t} = this.props;
        let { chartItem, tblData, chartData } = this.state;
        let data = this.generateData();
        let chartFont = 'Open Sans';

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                          return context.dataset.label + ': ' + context.parsed.y;
                        }
                      },
                    titleFont: {
                        family: chartFont,
                      },
                      bodyFont: {
                        family: chartFont,
                      },
                }
            },
            scales: {
                x: {
                    grid: {
                        color: 'rgba(0, 0, 0, 0)',
                        drawBorder: false,
                    },
                    ticks: {
                        align: 'center',
                        font: {
                            weight: 'bold',
                            family: chartFont,
                            size : 9
                        },
                        callback: function(value){
                            let label = value === 0 || value ? this.getLabelForValue(value) : "";
                            return label?.length > 10 ? label?.substring(0, 8) + '...' : label;
                        },
                    }
                },
                y: {
                    // min: 0,
                    // max: 100,
                    // stepSize: 10,
                    ticks: {
                        align: 'center',
                        font: {
                            weight: 'bold',
                            family: chartFont
                        },
                    }
                },
            },
        };

        let allow = ((tblData?.length > 0 && chartData?.length > 0) || (tblData?.length > 0));

        return (
            <Paper className='eMed_chart_wrapper'  elevation={2}>
                <Box className="eMed_revenueTbl_titleDiv">
                    <Typography id="eMed_chart_headTxt">{chartItem.title? chartItem.title : ""}</Typography>
                    { allow ? <IconButton size = "small" onClick={() => {this.setState({isExpandClked: true})}}>
                        <img className='emedhub_expand_img' src={ImagePaths.ExpandView.default} alt = "Expand"/>
                    </IconButton> : null }
                </Box>
                <Box className="eMed_doughnut_chartDiv">
                    <Box className="eMed_barChart_chartTltDiv">
                        {chartItem.Tlt1 ?
                            <Box className="eMed_barChart_imgDiv">
                                <img className="eMed_revenueCard_profit" src={ImagePaths.Graph1.default} />
                                <Typography id="eMed_barChart_Txt">{chartItem.Tlt1}</Typography>
                            </Box>
                            : null}
                        {chartItem.Tlt2 ? <Box className="eMed_barChart_imgDiv">
                            <img className="eMed_revenueCard_profit" src={ImagePaths.Graph2.default} />
                            <Typography id="eMed_barChart_Txt">{chartItem.Tlt2}</Typography>
                        </Box> : null}
                        {chartItem.Tlt3 ? <Box className="eMed_barChart_imgDiv">
                            <img className="eMed_revenueCard_profit" src={chartItem.title === "Patient Demographics" ? ImagePaths.Graph4.default : ImagePaths.Graph3.default} />
                            <Typography id="eMed_barChart_Txt">{chartItem.Tlt3}</Typography>
                        </Box> : null}
                    </Box>
                    <Box className="eMed_barChart_content" sx={{cursor: this.props?.isFrom === 'branches' ? "pointer" : "default"}}>
                        {allow ? <Bar options={options} data={data ? data : {}} onClick={()=>{this.renderNextScreen()}}/>
                            : <Box className="eMed_noDataDiv">
                                <p id="eMed_revenueCard_Txt">{t("NoRecordsFound")}</p>
                            </Box>}
                    </Box>
                </Box>
                {
                    this.state.isExpandClked ? 
                    <ExpandPopUp 
                    title = {chartItem.title} 
                    tblData = {tblData} 
                    tblColumn = {chartItem.tblColumn}
                    chartView = {<Bar options={options} data={data ? data : {}} />}
                    popUpClose = {this.PopupClose.bind(this)}
                    Tlt1={chartItem.Tlt1}
                    Tlt2={chartItem.Tlt2}
                    Tlt3={chartItem.Tlt3}
                    /> : null
                }
            </Paper>
        )
    }

}
export default withTranslation()(BarChart);
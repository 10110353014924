import { Autocomplete, Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../Styles/Colors'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { ToolsField } from '../../../Components/Common Components/CommonComponents'

export default class RadAddTemplate extends Component {
    constructor(props) {
        super(props)
        let propData = this.props.history?.location?.state?.templateData
        this.state = {
            serviceTypeList: [],
            serviceNameList: [],
            selectedServiceType: propData?.service_type ? propData?.service_type : null,
            selectedServiceName: propData?.service_name ? propData?.service_name : null,
            templateName: propData?.template_name ? propData?.template_name : '',
            resultData: propData?.template_data ? propData?.template_data : '',
            selectedId: propData?.id ? propData?.id : null,
            postLoad: false
        }
    }

    componentDidMount() {
        this.getServiceConfigData()
    }

    getServiceConfigData = () => {
        try {
            RestAPIService.getAll(Serviceurls.RAD_SERVICE_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            serviceTypeList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkValidation = (resultData) => {
        try {
            let message = null;

            if (this.state.selectedServiceName === null || this.state.selectedServiceType === null || this.state.templateName === '' || resultData.trim() === '') {
                message = 'Please,Enter all mandatory fields'
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postNewTemplateData = (resultData) => {
        try {
            let check = this.checkValidation(resultData)
            if (check === null) {
                let data = {
                    service_name: this.state.selectedServiceName?.service_name ? this.state.selectedServiceName?.service_name : this.state.selectedServiceName,
                    service_type: this.state.selectedServiceType?.service_type ? this.state.selectedServiceType?.service_type : this.state.selectedServiceType,
                    template_name: this.state.templateName,
                    template_data: resultData
                }
                if (this.state.selectedId) {
                    data['template_id'] = this.state.selectedId
                }
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.RAD_TEST_TEMPLATE_CONFIG)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.onClearHandler()
                            this.setState({ postLoad: false })
                            setTimeout(() => {
                                this.props.history?.push({ pathname: '/RadiologySettings/ServiceTemplateConfig' })
                            }, 800)
                        }
                    }).catch((error) => {
                        this.setState({ postLoad: false })
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.setState({ postLoad: false })
                this.errorMessage(check)
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onClearHandler = () => {
        try {
            this.setState({
                selectedServiceName: null,
                selectedServiceType: null,
                templateName: '',
                resultData: '',
                selectedId: null
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <Box component={'div'}>
                <Box component={'div'} className='rad_tst_container' sx={{ backgroundColor: Colors.Background }}>
                    <Box component={'div'} className='rad_tst_tmp_header'>
                        <Paper component={'div'} className='rad_srv_tmp_pap' elevation={1}>
                            <Autocomplete
                                size='small'
                                clearIcon
                                sx={{ width: '15vw', mr: '1vw' }}
                                options={this.state.serviceTypeList}
                                value={this.state.selectedServiceType}
                                onChange={(e, value) => {
                                    this.setState({
                                        selectedServiceType: value
                                    }, () => {
                                        this.setState({ serviceNameList: this.state.selectedServiceType?.data, selectedServiceName: null })
                                    })
                                }}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.service_type}
                                renderInput={(params) => <TextField {...params} label='Select Service Type *' />}
                            />
                            <Autocomplete
                                size='small'
                                clearIcon
                                sx={{ width: '15vw', mr: '1vw' }}
                                options={this.state.serviceNameList}
                                value={this.state.selectedServiceName}
                                onChange={(e, value) => {
                                    this.setState({ selectedServiceName: value })
                                }}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.service_name}
                                renderInput={(params) => <TextField {...params} label='Select Service Name *' />}
                            />
                            <TextField
                                sx={{ width: '15vw' }}
                                size='small'
                                value={this.state.templateName}
                                label='Template Name *'
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (CommonValidation.alphabetOnly(value)) {
                                        this.setState({ templateName: value })
                                    }
                                }}
                            />
                        </Paper>
                    </Box>
                    <ToolsField Title={"Result*"} EditorRef={this.state.resultData} handlePost={this.postNewTemplateData.bind(this)} height={"54vh"} overallHeight={"67vh"} EditorHeight={"53vh"} />
                    {/* <Paper component={'div'} className='rad_tst_tmp_content' elevation={1}>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} pl={'0.5vw'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                            <Typography fontWeight={600}>Result *</Typography>
                        </Box>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} height={'54vh'} p={'0.5vw'}>
                            <TextField
                                fullWidth
                                multiline
                                rows={14}
                                value={this.state.resultData}
                                onChange={(e) => this.setState({ resultData: e.target.value })}
                            />
                        </Box>
                        <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} height={'3vw'}>
                            <Button variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                onClick={() => this.onClearHandler()}
                            >Clear</Button>
                            <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                onClick={() => this.postNewTemplateData()}
                                disabled={this.state.postLoad}
                            >Complete</Button>
                        </Box>
                    </Paper> */}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

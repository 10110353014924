import React, { Component } from "react";
import "./charts.css";
import { Box, Paper, Typography, Tooltip, IconButton } from "@mui/material";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { withTranslation } from 'react-i18next';
import { DataGrid, GridCsvExportMenuItem, GridPrintExportMenuItem, GridToolbarContainer, GridToolbarExportContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterTextCustom, CommonGridToolBarWithFilterTextCustomRevenue } from "../../../Components/Common Components/CommonComponents";


class RevenueExpenseTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.title ? props.title : "",
            tblData: props.tblData?.length > 0 ? props.tblData : [],
            columns: props.columns?.length > 0 ? props.columns : []
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props) {
            this.setState({
                title: this.props.title ? this.props.title : "",
                tblData: this.props.tblData?.length > 0 ? this.props.tblData : [],
                columns: this.props.columns?.length > 0 ? this.props.columns : []
            })
        }
    }
    toolbar = () =>{
        let ExportValues =[]
        if(this.props.tblData?.length != "" ){
            this.props.tblData?.forEach((list, index)=>{
                let obj = {}
                this.props.columns?.forEach((item, colindex)=>{
                    const headerName = item.headerName === "Revenue (₹)" ? "Revenue" : item.headerName;
                    obj[headerName] = list[item.field];
                })
                ExportValues.push(obj)
            })    
        }
        return(
            <CommonGridToolBarWithFilterTextCustomRevenue data = {ExportValues} title={this.state.title ? this.state.title : "Total Patients With Revenue"} isFromRevenueTable={true} filename={"Revenue Expense Table"}/>
        )
    }
    render() {
        const { t } = this.props;
        let { tblData, title, columns } = this.state;
        tblData.forEach((element, index) => { element.sno = index + 1 })
        return (
            <Paper className='eMed_chart_wrapper' elevation={2}>
                {/* <Box className="eMed_revenueTbl_titleDiv">
                    <Typography id="eMed_revenueCard_headTxt">{title ? title : "Total Patients With Revenue"}</Typography>
                    <IconButton>
                        <img className='emedhub_expand_img' src={ImagePaths.DownloadIcon.default} alt="Download" />
                    </IconButton>
                </Box> */}
                <Box className="eMed_doughnut_chartDiv" sx={{ height:'100%'}}>
                    <DataGrid
                        rowHeight={40}
                        rows={tblData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        headerHeight={40}
                        hideFooter
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.toolbar,
                            NoRowsOverlay: () => {
                                return (
                                    <Box className="eMed_noDataDiv">
                                        <p id="eMed_revenueCard_Txt">{t("NoRecordsFound")}</p>
                                    </Box>
                                )
                            }
                        }}
                    />
                </Box>
            </Paper>
        );
    }
}
export default withTranslation()(RevenueExpenseTable);
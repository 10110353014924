import React, { Component } from "react";
import '../IpPatientDetails.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, Grid, Autocomplete, Typography } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import {
    CommonPatientDetails, CommonPatientCard, ConsultantPatientCard,
    CommonEditButton, CommonPopUp, CommonGridToolBarWithButton
} from "../../../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../../../Utility/ImagePaths";
import { Colors } from "../../../../../Styles/Colors";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../../Utility/API/Serviceurls";
import { getCachevalue, localGetItem } from "../../../../../Utility/Services/CacheProviderService";
import { t } from "i18next";
import { CheckAccessFunc, formatDateAndTimeMeth2 } from "../../../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../../../Components/Loader';
import { DateTime } from "luxon";
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const unitList = [
    { value: "ml", label: "ml" },
    { value: "gms", label: "gms" }
];

class InputOutChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tblData: [],
            patientData: {},
            attenderData: {},
            ip_date: null,
            intake: "",
            ip_quantity: null,
            ip_unit: "",
            op_date: null,
            mode: "",
            op_quantity: null,
            op_unit: "",
            comments: "",
            current_page: 0,
            rowsPerPage: 10,
            viewTotalPopup: false,
            viewTotalData: [],
            viewTltDate: new Date(),
            isAdd: false,
            isEdit: false,
            SelectedList: {},
            withOutAppointment: {},
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
        }
    }
    componentDidMount() {
        let patientDetails = getCachevalue("IppatientData")
        let patients = JSON.parse(patientDetails)
        let appointmentDetail = getCachevalue("withoutAppointment");
        let details = JSON.parse(appointmentDetail);
        if (details?.patient_id === patients?.patient_id) {
            this.setState({
                withOutAppointment: details
            }, () => {
                this.getIpOpChartDetails();
                this.getPatientData();
            })
        }
        this.setState({
            patientData: patients
        }, () => {
            this.getIpOpChartDetails();
            this.getPatientData();
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getPatientData = () => {
        this.setState({
            attenderData: {}
        })
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${admission_id}`;
        if (this.props.is_From_IpNotes && this.state.clinic_id) {
            url = `${url}&clinic_id=${this.state.clinic_id}`
        }
        try {
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            attenderData: response.data.data[0]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMsgFunc(error.response.data.message)
                    } else {
                        this.errorMsgFunc(error.message)
                    }
                })
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    getIpOpChartDetails = () => {
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        let url = `${Serviceurls.IP_DOC_IPOP_CHART}?ip_admission_id=${admission_id}`;
        try {
            RestAPIService.getAll(url)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response.data.data?.map((item, i) => {
                            return { ...item, index: i };
                        }) || [];
                        this.setState({
                            tblData: data || [],
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    } else {
                        this.errorMsgFunc(error.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    
    getIpOpChartPrint = () => {
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        try {
            RestAPIService.getAll(`${Serviceurls.IP_DOC_IPOP_CHART}?ip_admission_id=${admission_id}&export_type=${"pdf"}`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMsgFunc(error.response.data.message)
                    } else {
                        this.errorMsgFunc(error.message)
                    }
                })
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    getviewTotalDetails = () => {
        let { viewTltDate } = this.state;
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        const viewDate = viewTltDate ? DateTime.fromJSDate(new Date(viewTltDate)).toFormat("yyyy-MM-dd") : null;
        let url = `${Serviceurls.IP_DOC_IPOP_CHART}?ip_admission_id=${admission_id}&view_date=${viewDate}`;
        try {
            RestAPIService.getAll(url)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response.data.data?.map((item, i) => {
                            return { ...item, index: i };
                        }) || [];
                        this.setState({
                            viewTotalData: data || []
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    } else {
                        this.errorMsgFunc(error.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            isAdd: false,
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            isAdd: false,
        })
    }

    handleClear = () => {
        this.setState(
            {
                ip_date: null,
                intake: "",
                ip_quantity: null,
                ip_unit: "",
                op_date: null,
                mode: "",
                op_quantity: null,
                op_unit: "",
                comments: "",
                isAdd: false,
                isEdit: false,
                SelectedList: {},
            }
        )
    }

    handleEdit = (data) => {
        this.setState({
            isEdit: true,
            SelectedList: data,
            ip_date: data.input_date ? new Date(data.input_date) : null,
            intake: data.input,
            ip_quantity: data.input_quantity,
            ip_unit: data.input_unit,
            op_date: data.output_date ? new Date(data.output_date) : null,
            mode: data.output,
            op_quantity: data.output_quantity,
            op_unit: data.output_unit,
            comments: data.comments,
        })
    }

    formatDate = (date) => {
        const dt = DateTime.fromJSDate(new Date(date));
        return dt.toFormat(`yyyy-MM-dd HH:mm:ss`);
    };

    handleAdd = () => {
        try {
            let states = this.state;
            const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
            const ipDate = states.ip_date ? this.formatDate(states.ip_date) : null;
            const opDate = states.op_date ? this.formatDate(states.op_date) : null;

            const intake = CommonValidation.removeSpace(states.intake);
            const ip_quantity = CommonValidation.removeSpace(states.ip_quantity);
            const ip_unit = CommonValidation.removeSpace(states.ip_unit);

            const mode = CommonValidation.removeSpace(states.mode);
            const op_quantity = CommonValidation.removeSpace(states.op_quantity);
            const op_unit = CommonValidation.removeSpace(states.op_unit);

            const isIpDataIncomplete = (ipDate || intake || ip_quantity || ip_unit) && (!ipDate || !intake || !ip_quantity || !ip_unit);
            const isOpDataIncomplete = (opDate || mode || op_quantity || op_unit) && (!opDate || !mode || !op_quantity || !op_unit);
            let hasErrors = false;

            if (isIpDataIncomplete) {
                hasErrors = true;
                if (!ipDate) this.errorMsgFunc("Input Date is required");
                if (!intake) this.errorMsgFunc("Intake is required");
                if (!ip_quantity) this.errorMsgFunc("Input Quantity is required");
                if (!ip_unit) this.errorMsgFunc("Input Unit is required");
            } else if (isOpDataIncomplete) {
                hasErrors = true;
                if (!opDate) this.errorMsgFunc("Output Date is required");
                if (!mode) this.errorMsgFunc("Mode is required");
                if (!op_quantity) this.errorMsgFunc("Output Quantity is required");
                if (!op_unit) this.errorMsgFunc("Output Unit is required");
            } else if (ipDate && opDate) {
                const ipDateTime = DateTime.fromJSDate(states.ip_date);
                const opDateTime = DateTime.fromJSDate(states.op_date);
                if (opDateTime <= ipDateTime) {
                    hasErrors = true;
                    this.errorMsgFunc("Output Date must be greater than Input Date");
                }
            }
            if (!hasErrors) {
                let data = {
                    "ip_admission_id": states?.patientData?.id,
                    'doctor_id': myUser?.doctor_id || states?.patientData?.doctor_id,
                    "clinic_id": states.clinic_id,
                    'input_date': ipDate,
                    'input': intake,
                    'input_quantity': +ip_quantity,
                    'input_unit': ip_unit,
                    'output_date': opDate,
                    'output': mode,
                    'output_quantity': +op_quantity,
                    'output_unit': op_unit,
                    'comments': CommonValidation.removeSpace(states.comments),
                }
                if (states.isEdit) { data["id"] = states.SelectedList.id }
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.IP_DOC_IPOP_CHART).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.handleClear()
                            this.getIpOpChartDetails()
                            this.LoaderFunction(false)
                            this.successMsgFunc(response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMsgFunc(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMsgFunc(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMsgFunc(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const newValue = value.trimStart();
        let isValid = false;
        let updatedState = { ...this.state };

        switch (name) {
            case "ip_quantity":
            case "op_quantity":
                isValid = CommonValidation.NumberWithDot(newValue);
                if ((isValid && newValue.length <= 4) || newValue === "") {
                    updatedState[name] = newValue;
                }
                break;
            case "comments":
                if ((newValue.length <= 250) || newValue === "") {
                    updatedState[name] = newValue;
                }
                break;
            default:
                updatedState[name] = newValue;
                break;
        }
        this.setState(updatedState);
    }

    renderTextBox(label, statekey, isMultiLine = false, isDisabled = false) {
        const { t } = this.props;
        let states = this.state;
        return (
            <div id="eMed_srvConfig_divs">
                {statekey === "ip_unit" || statekey === "op_unit" ?
                    <Autocomplete
                        disableClearable
                        value={states[statekey] ? states[statekey] : ""}
                        sx={{ width: "100%", fontSize: "0.9vw" }}
                        size="small"
                        options={unitList?.length > 0 ? [...unitList] : []}
                        getOptionLabel={option => typeof (option) === "string" ? option : option.label}
                        onChange={(event, newUnit) => {
                            this.setState({
                                [statekey]: newUnit.value
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(label)}
                                autoComplete="off"
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                    />
                    : <TextField
                        sx={{ width: "100%" }}
                        multiline={isMultiLine}
                        rows={3}
                        size="small"
                        autoComplete="off"
                        label={t(label)}
                        name={statekey}
                        value={states[statekey] ? states[statekey] : ""}
                        placeholder={statekey === "comments" ? "Enter description with in 250 characters" : ""}
                        disabled={isDisabled}
                        onChange={this.handleChange}
                    />}
            </div>
        )
    }

    renderDatePickers = (label, statekey) => {
        const { t } = this.props;
        let states = this.state;
        let patientDetails = getCachevalue("IppatientData");
        const currentDate = new Date();
        const admissionDate = states?.patientData?.admitted_date ? new Date(states.patientData.admitted_date)
            : states?.patientData?.admission_date ? new Date(states.patientData.admission_date) : null;
        const dischargeDate = patientDetails?.discharge_date && (new Date(patientDetails?.discharge_date) <= currentDate)
            ? new Date(patientDetails.discharge_date) : currentDate;
        let minDate;

        if (statekey === 'ip_date') {
            minDate = admissionDate || null;
        } else if (statekey === 'op_date') {
            minDate = states.ip_date ? new Date(states.ip_date) : (admissionDate || null);
        }
        return (
            <div className="eMed_ipop_txtDiv" >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={t(label)}
                        inputFormat='DD-MM-YYYY & hh:mm A'
                        value={states[statekey]}
                        onChange={(newDate) => {
                            this.setState({ [statekey]: newDate });
                        }}
                        minDate={minDate}
                        maxDate={dischargeDate}
                        renderInput={(params) =>
                            <TextField size='small'
                                {...params}
                                fullWidth
                                variant='standard'
                            />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    // view log popup start
    viewTotalPopUpClose = () => {
        this.setState({
            viewTotalPopup: false,
            viewLogData: []
        })
    }

    _showViewTotal(logData) {
        const { t } = this.props;
        const states = this.state;
        let patientDetails = getCachevalue("IppatientData");

        const logColumn = [
            {
                field: 'type', headerName: t('Chart'), sortable: false, hideable: false, flex: 0.20, renderCell: ({ row }) => {
                    return (<div>{row?.type?.length > 28 ?
                        <Tooltip placement="top" title={row?.type} arrow>
                            <div>{row?.type.slice(0, 25) + "..."}</div></Tooltip>
                        : row?.type ? row?.type : "-"}</div>)
                }
            },
            {
                field: 'data', headerAlign: "center", headerName: t('Intake/Mode'), align: "center", sortable: false, hideable: false, flex: 0.50, renderCell: ({ row }) => {
                    return (<div>{row?.data?.length > 30 ?
                        <Tooltip placement="top" title={row?.data} arrow>
                            <div>{row?.data.slice(0, 26) + "..."}</div></Tooltip>
                        : row?.data ? row?.data : "-"}</div>)
                }
            },
            {
                field: 'total_quantity', headerName: `${t('Quantity')}`, flex: 0.30, sortable: false, hideable: false, renderCell: ({ row }) => {
                    let qty = `${row.total_quantity || ""} ${row.unit || ""}`
                    return (<div>{qty?.length > 30 ?
                        <Tooltip placement="top" title={qty} arrow>
                            <div>{qty.slice(0, 26) + "..."}</div></Tooltip>
                        : qty ? qty : "-"}</div>)
                }
            }
        ];

        const admissionDate = states?.patientData?.admitted_date ? new Date(states.patientData.admitted_date)
            : states?.patientData?.admission_date ? new Date(states.patientData.admission_date) : null;
        const dischargeDate = patientDetails?.discharge_date ? new Date(patientDetails.discharge_date) : null;
        const maxDate = dischargeDate || new Date();

        return (
            <Box id="eMed_ipsrvConfig_accTbl" sx={{ margin: "0dvw !important" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={this.state.viewTltDate ? this.state.viewTltDate : new Date()}
                        inputFormat='DD-MM-YYYY'
                        style={{ width: '20%' }}
                        label={t("Select Date")}
                        minDate={admissionDate}
                        maxDate={maxDate}
                        onChange={
                            (newDate) => {
                                this.setState({
                                    viewTltDate: newDate ? newDate : new Date()
                                }, () => {
                                    this.getviewTotalDetails()
                                });
                            }}
                        renderInput={(params) => <TextField size='small' {...params} />}
                    />
                </LocalizationProvider>
                <Box height={"40dvh"} marginTop={"0.5dvw"} marginBottom={"0.625dvw"}>
                    <DataGrid
                        rows={logData ? logData : []}
                        columns={logColumn}
                        getRowId={(row) => row.index}
                        disableColumnMenu
                        hideFooter
                        components={{
                            NoRowsOverlay: () => {
                                return (
                                    <Stack className="eMed_conf_nodata" height={"40dvh !important"}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        headerHeight={40}
                    />
                </Box>
            </Box>
        )
    }
    // view log popup end

    onPressViewButton = () => {
        this.setState({
            viewTotalPopup: true
        }, () => {
            this.getviewTotalDetails();
        })
    }

    gridToolBar = () => {
        let { t } = this.props
        return (
            <Box>
                <CommonGridToolBarWithButton
                    onClickCommonButton={this.onPressViewButton.bind(this)}
                    label={t("View Total")}
                    ViewBtn={true}
                />
            </Box>
        )
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        const checkuserAccess = CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Input/OutPut Chart", "SubTab")?.editAccess ?
                this.state.withOutAppointment?.is_ip : true;
        const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
        const columns = [
            {
                field: 'input_date', headerName: t('Input Date & Time'), flex: 0.18, renderCell: ({ row }) => {
                    return (
                        <div>{row?.input_date?.length > 15 ?
                            <Tooltip placement="top" title={formatDateAndTimeMeth2(row?.input_date || "")} arrow>
                                <div>{formatDateAndTimeMeth2(row?.input_date).slice(0, 15) + "..."}</div>
                            </Tooltip>
                            : row?.input_date ? formatDateAndTimeMeth2(row?.input_date) : "-"}</div>
                    )
                }
            },
            {
                field: 'input', headerName: t('Input'), flex: 0.17, renderCell: ({ row }) => {
                    return (
                        <div>{row?.input?.length > 10 ?
                            <Tooltip placement="top" title={row?.input} arrow>
                                <div>{row?.input.slice(0, 10) + "..."}</div>
                            </Tooltip>
                            : row?.input ? row?.input : "-"}</div>
                    )
                }
            },
            {
                field: 'input_quantity', headerName: t('Quantity'), flex: 0.16, renderCell: ({ row }) => {
                    let qty = `${row.input_quantity || ""} ${row.input_unit || ""}`
                    return (<div>{qty?.length > 10 ?
                        <Tooltip placement="top" title={qty} arrow>
                            <div>{qty.slice(0, 10) + "..."}</div></Tooltip>
                        : qty ? qty : "-"}</div>
                    )
                }
            },
            {
                field: 'output_date', headerName: t('Output Date & Time'), flex: 0.18, renderCell: ({ row }) => {
                    return (
                        <div>{row?.output_date?.length > 15?
                            <Tooltip placement="top" title={formatDateAndTimeMeth2(row?.output_date || "")} arrow>
                                <div>{formatDateAndTimeMeth2(row?.output_date).slice(0, 15) + "..."}</div>
                            </Tooltip>
                            : row?.output_date ? formatDateAndTimeMeth2(row?.output_date) : "-"}</div>
                    )
                }
            },
            {
                field: 'output', headerName: t('Output'), headerAlign: "center", align: "center", flex: 0.17,
                renderCell: ({ row }) => {
                    return (<div>{row?.output?.length > 10 ?
                        <Tooltip placement="top" title={row?.output} arrow>
                            <div>{row?.output.slice(0, 10) + "..."}</div></Tooltip>
                        : row?.output ? row?.output : "-"}</div>
                    )
                }
            },
            {
                field: 'output_quantity', headerName: t('Quantity'), flex: 0.16, renderCell: ({ row }) => {
                    let qty = `${row.output_quantity || ""} ${row.output_unit || ""}`
                    return (<div>{qty?.length > 10 ?
                        <Tooltip placement="top" title={qty} arrow>
                            <div>{qty.slice(0, 10) + "..."}</div></Tooltip>
                        : qty ? qty : "-"}</div>
                    )
                }
            },
            {
                field: "action", flex: 0.10, headerName: t("Action"), headerAlign: "center", align: "center",
                sortable: false, hideable: false,
                renderCell: ({ row }) => {
                    const isDocCanEdit = this.props.is_From_IpNotes ? !(myUser?.doctor_id === row?.doctor_id) : checkuserAccess;
                    return (<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <CommonEditButton testID = {"ipop_edit_btn"} size={"1.5vw"} disable={isDocCanEdit} onClick={this.handleEdit.bind(this, row)} />
                    </Box>)
                }
            }
        ]

        let data = {
            lineOne: t("AttenderName"),
            lineTwo: `${states["attenderData"]["attender_name"] ? states["attenderData"]["attender_name"] : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${states["attenderData"]["attender_mobile"] ? states["attenderData"]["attender_mobile"] : "-"}`
        }

        let Consultant = {
            image: ImagePaths.Doctor_Icon.default,
            width: "2.3vw",
            height: "2.3vw",
            lineOne: t("ConsultantName"),
            lineTwo: `${states["attenderData"]["doctor_name"] ? states["attenderData"]["doctor_name"] : "-"}`
        }
        return (
            <Box id="eMed_medication_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container className="eMed_medication_Box" >
                    <Stack className="eMed_medicationCard_header" direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
                            <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
                                <CommonPatientDetails data={this.state.patientData} showDetailed={true} />
                            </Stack>
                            <CommonPatientCard details={data} showDetailed={true} />
                            <ConsultantPatientCard name={Consultant} width={"20dvw"}/>
                        </Stack>
                        <Button className='eMed_usrconf_btn' onClick={() => {this.getIpOpChartPrint()}}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Stack>
                    <Grid container item spacing={2} className="eMed_medication_mainDiv">
                        <Grid item xs={8}>
                            <Paper className="eMed_medication_Div" elevation={2}>
                                <DataGrid
                                    rows={states["tblData"]}
                                    columns={columns}
                                    getRowId={(row) => row.id}
                                    disableSelectionOnClick = {true}
                                    localeText={{
                                        toolbarColumns: "",
                                        toolbarDensity: "",
                                        toolbarExport: "",
                                        toolbarFilters: "",
                                        toolbarExportPrint: ""
                                    }}
                                    components={{
                                        Toolbar: this.gridToolBar,
                                        NoRowsOverlay: () => (
                                            <Stack className='eMed_conf_nodata'>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }}
                                    headerHeight={40}
                                    rowCount={states["tblData"].length}
                                    page={states["current_page"]}
                                    onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                    pageSize={states["rowsPerPage"]}
                                    onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    pagination
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className="eMed_medication_Div" elevation={2}>
                                <div className="eMed_lab_subDiv">
                                    <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg, marginBottom: "0.3dvw" }}>
                                        <p className='eMed_recPayment_rightHead_txt'>{t("Input/Output Chart")}</p>
                                    </div>
                                    <Stack className="emedhub_medication_RightContentBox" direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
                                        {this.renderDatePickers("Input Date & Time", "ip_date")}
                                        <p className='eMed_ipop_right_txt'>{t("Input Data")}</p>
                                        <div className="eMed_drugRight_txtDiv">
                                            {this.renderTextBox("Intake", "intake")}
                                            <div className="eMed_ipop_qtyDiv">
                                                {this.renderTextBox("Quantity", "ip_quantity")}
                                                {this.renderTextBox("Unit", "ip_unit")}
                                            </div>
                                        </div>
                                        {this.renderDatePickers("Output Date & Time", "op_date")}
                                        <p className='eMed_ipop_right_txt'>{t("Output Data")}</p>
                                        <div className="eMed_drugRight_txtDiv">
                                            {this.renderTextBox("Mode", "mode")}
                                            <div className="eMed_ipop_qtyDiv">
                                                {this.renderTextBox("Quantity", "op_quantity")}
                                                {this.renderTextBox("Unit", "op_unit")}
                                            </div>
                                        </div>
                                        <p className='eMed_ipop_right_txt'>{t("Comments")}</p>
                                        <div style={{ width: "100%", margin: 0 }}>
                                            {this.renderTextBox("Enter Comments", "comments", true)}
                                        </div>
                                    </Stack>
                                </div>
                                <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                    <Button variant='outlined' disabled={this.state.withOutAppointment?.is_ip} size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button
                                        disabled={this.props.is_From_IpNotes ? false
                                            : (CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Input/OutPut Chart", "SubTab")?.editAccess ?
                                                this.state.withOutAppointment?.is_ip : true) || this.state.isAdd}
                                        variant='contained' size="small" id="eMed_recPayment_btn"
                                        onClick={() => {
                                            this.setState({ isAdd: true, },
                                                () => { this.handleAdd() })
                                        }}>{t("Add")}</Button>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {
                    (this.state.viewTotalPopup) ?
                        <CommonPopUp width={"45dvw"}
                            backgroundColor={Colors.white}
                            popUpClose={this.viewTotalPopUpClose.bind(this)}
                            title={t("View Total")}
                            component={this._showViewTotal.bind(this, states?.viewTotalData)} />
                        : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(InputOutChart);
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { Box, Button, Grid, Paper, Stack, Tab, Tabs, Autocomplete, TextField,
  FormControl, InputLabel, Select, MenuItem, Checkbox,} from '@mui/material'
import { Colors } from '../../../Styles/Colors'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const corporate_list = [
  { label: "MNS", no: 1 },
  { label: "Corporate is one", no: 2 },
  { label: "Namwe", no: 2 },
  { label: "Corporate is mdm vdvmvf", no: 2 }
]
class Reports extends Component {
  constructor() {
    super()
    this.state = {
      value: "One",
      isErrorMsg: false,
      isErrorMsgText: '',
      selectDate: null,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      selectGroupCE: 'corporate',
      selectGroupCB: 'corporate'
    }
  }

  ExportCorpReport = () => {
    const { t } = this.props
    try {
      RestAPIService.excelGet(Serviceurls.LAB_PATIENT_REPORT_PRINT + "?export_type=excel").
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError")
      })
    }
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  CorpEnrollListTab = () => {
    const { t } = this.props
    let states = this.state
    return (
      <Box sx={{ margin: '1vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '33vw' }}>
        <Box>
          <FormControl sx={{width:'15vw', }} size='small'>
            <InputLabel id="group-select">{t('GrpbyCor')}</InputLabel>
            <Select
              disabled
              labelId="group-select"
              id="demo-simple-select"
              value={states.selectGroupCE}
              label={t('GrpbyCor')}
              onChange={(event)=>{
                  this.setState({
                    selectGroupCE: event.target.value
                  })
              }}
            >
              <MenuItem value={"corporate"}>{"Corporate"}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: '14vw' }}>
          <CommonDatePicker
            FromDate={this.state.fromDate}
            ToDate={this.state.toDate}
            DateIndex={this.state.dateIndex}
            SelectedDates={this.FilterDate.bind(this)}
            HideAllMenu={false} />
        </Box>
      </Box>
    )
  }

  CorpBillsTab = () => {
    const { t } = this.props
    let states = this.state
    return (
      <Box sx={{ margin: '1vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '60vw' }}>
        <Box>
        <FormControl sx={{width:'15vw', }} size='small'>
            <InputLabel id="group-select">{t('GrpbyCor')}</InputLabel>
            <Select
              labelId="group-select"
              id="demo-simple-select"
              value={states.selectGroupCB}
              label={t('GrpbyCor')}
              onChange={(event)=>{
                  this.setState({
                    selectGroupCB: event.target.value
                  })
              }}
            >
              <MenuItem value={"corporate"}>{"Corporate"}</MenuItem>
              <MenuItem value={"billdate"}>{"Bill Date"}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Autocomplete
            multiple
            id="combo-box-demo"
            options={corporate_list}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={t("SelCor")} size='small' />}
          />
        </Box>
        <Box sx={{ width: '14vw' }}>
          <CommonDatePicker
            FromDate={this.state.fromDate}
            ToDate={this.state.toDate}
            DateIndex={this.state.dateIndex}
            SelectedDates={this.FilterDate.bind(this)}
            HideAllMenu={false} />
        </Box>
      </Box>
    )
  }

  render() {
    const { t } = this.props
    let states = this.state
    return (
      <div id='eMed_IP_Appointment' style={{ padding: "1vw" }}>
        <div className='eMed_Corp_Card_Con'>
          <div className='eMed_Corp_Card_Wrap'>
            {AmountsCard(t("CorpEnroll"), "0", true)}
            {AmountsCard(t("EmpBill"), "0", true)}
            {AmountsCard(t("CorpBill"), "0", true)}
          </div>
        </div>
        <div>
          <Paper className="eMed_recPayment_Div" style={{ height: "35vh" }} elevation={1}>
            <Grid xs={12} item className='eMed_recPayment_gridDiv' style={{ height: "27.5vh" }}>
              <Tabs
                value={this.state.value}
                onChange={(e, newValue) => this.setState({
                  value: newValue
                })}
                sx={{ backgroundColor: Colors.DataTblHeaderbg }}
              >
                <Tab value="One" label={t("CorpEnrolListRep")} className='eMed_tab_header' />
                <Tab value="Two" label={t("CorpBillRep")} className='eMed_tab_header' />
              </Tabs>
              {this.state.value === "One" ? this.CorpEnrollListTab() : this.state.value === "Two" ? this.CorpBillsTab() : ""}
            </Grid>
            <Stack id='eMed_recPayment_rightBtnCon' flexDirection="row" alignItems="center" justifyContent="flex-end" marginRight="2vw">
              <Button variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => { this.ExportCorpReport() }}>{t("ExportAsExcel")}</Button>
            </Stack>
          </Paper>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Reports)
import { Box, Button, Modal, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { DataGrid } from '@mui/x-data-grid'
import { withTranslation } from 'react-i18next'
import { formatDate, splitDate } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'

class PrescriptionHistoryPop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PrescriptionData: this.props?.PrescHistoryData ? this.props?.PrescHistoryData : [],
            SelectedDrugs: [],
            tab: 'one',
            prescriptionList: this.props?.prescriptionList ? this.props?.prescriptionList : [],
            drugDatas: [],
            page: 0,
            prePage: 0,
            pageSize: 10,
            prePageSize: 10
        }
    }

    getPrescriptionListData = () => {
        try {
            let prescripIds = [];
            this.state.SelectedDrugs?.forEach(element => { prescripIds?.push(element?.id) })
            RestAPIService.getAll(Serviceurls.PHARMA_PRESC_LIST_HISTORY_GET + '?patient_id=' + this.props.PatientDetails?.patient_id + '&prescription_id=' + prescripIds)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({ drugDatas: response.data.data }, () => {
                            this.props.HistoryClosePopUp(this.state.drugDatas)
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    handleTableCheckbox(id) {
        let selectedIDs = new Set(id);
        let selectedRows = this.state.PrescriptionData.filter((row) => selectedIDs.has(row.id));
        this.setState({
            SelectedDrugs: selectedRows
        })
    }

    handleTableCheckboxTwo = (id) => {
        let selectedIDs = new Set(id);
        let selectedRows = this.state.prescriptionList?.filter((row) => selectedIDs.has(row.sno))
        this.setState({ SelectedDrugs: selectedRows })
    }

    getPrescriptionPrintData = (id) => {
        try {
            let params = '';
            if (id?.is_opt_pharma) {
                params = Serviceurls.PRINT_PRESCRIPTION +
                    "?appointment_id=" + id?.appointment_id +
                    "&patient_id=" + this.props.PatientDetails?.patient_id +
                    "&doctor_id=" + id?.doctor_id +
                    '&prescription_id=' + id?.id +
                    "&export_type=pdf"
            } else {
                params = Serviceurls.OPT_PRESCRIPTION_PRINT +
                    "?appointment_id=" + id?.appointment_id +
                    "&doctor_id=" + id?.doctor_id +
                    "&prescription_id=" + id?.id +
                    "&language=english"
            }
            RestAPIService.getAll(params)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPrescriptionScreen = () => {
        try {
            const { t } = this.props
            this.state.prescriptionList?.forEach((element, index) => element.sno = index + 1)
            const columns = [
                { field: 'sno', headerName: 'S.No', flex: 0.05 },
                {
                    field: 'prescription_date', headerName: 'Date', flex: 0.35,
                    renderCell: (params) => (<Box>{params?.row?.prescription_date ? formatDate(params?.row?.prescription_date) : '-'}</Box>)
                },
                { field: 'doctor__first_name', headerName: 'Doctor Name', flex: 0.35 },
                {
                    field: 'action', headerName: 'Action', flex: 0.25,
                    renderCell: (params) => (<Box component={'div'}>
                        <Button className='Common_Btn_Min_Width'
                            sx={{ height: '2vw', width: '2vw' }}
                            onClick={() => this.getPrescriptionPrintData(params?.row)}
                        >
                            <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                        </Button>
                    </Box>)
                }
            ]
            let FetchItemsIds = []
            this.props?.BillingList?.forEach((list) => { if (list.is_from_history) { FetchItemsIds.push(list.prescription_id) } })
            return (
                <Box component={'div'} className='eMed_Presc_History_pop_table'>
                    <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(id) => this.handleTableCheckboxTwo(id)}
                        rows={this.state.prescriptionList}
                        isRowSelectable={(params) => {
                            if (!FetchItemsIds.includes(params?.row?.id)) { return true }
                        }}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        pageSize={this.state.prePageSize}
                        page={this.state.prePage}
                        onPageChange={(page) => this.setState({ prePage: page })}
                        onPageSizeChange={(pageSize) => this.setState({ prePageSize: pageSize })}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        this.state.PrescriptionData.forEach((element, index) => { element.sno = index + 1 })
        const { t } = this.props
        const columns = [
            {
                field: "sno", flex: 0.03, headerName: t("S No"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "invoice_date", flex: 0.15, headerName: t("Bill Date"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}> {params?.row?.invoice_date ? splitDate(params?.row?.invoice_date) : "-"} </Box>)
            },
            {
                field: "invoice_number", flex: 0.15, headerName: t("Bill Number"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number ? params?.row?.invoice_number?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{"..." + params?.row?.invoice_number.slice(9)}</div></Tooltip>
                        : params?.row?.invoice_number : "-"}</Box>)
            },
            {
                field: "drug_name", flex: 0.23, headerName: t("Brand Name"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.drug_name ? params?.row?.drug_name?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name.slice(0, 15) + "..."}</div></Tooltip>
                        : params?.row?.drug_name : "-"}</Box>)
            },
            {
                field: "batch_no", flex: 0.2, headerName: t("Batch No"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.batch_no ? params?.row?.batch_no?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no.slice(0, 15) + "..."}</div></Tooltip>
                        : params?.row?.batch_no === "null" ? "N/A" : params?.row?.batch_no : "-"}</Box>)
            },
            {
                field: "expiry_date", flex: 0.12, headerName: t("Expiry Date"), headerAlign: "left", align: "left",
                renderCell: (params) => {
                    var datePart = params?.row?.expiry_date ? params?.row?.expiry_date.match(/\d+/g) : []
                    var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                    return (<Box component={'div'}>{ExpiryDate}</Box>)
                }
            },
            {
                field: "quantity", flex: 0.12, headerName: t("Quantity"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.quantity ? params?.row?.quantity?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.quantity} arrow><div>{params?.row?.quantity.slice(0, 15) + "..."}</div></Tooltip>
                        : params?.row?.quantity : "-"}</Box>)
            },
        ]
        let FetchItemsIds = []
        this.props?.BillingList?.forEach((list) => { if (list.is_from_history) { FetchItemsIds.push(list.id) } })
        let gender = ((this.props?.PatientDetails?.patient_gender === 'Male' || this.props?.PatientDetails?.patient_gender === 'm' || this.props?.PatientDetails?.gender === "m" || this.props?.PatientDetails?.gender === "M" || this.props?.PatientDetails?.patient__gender === "m") ? "M" : (this.props?.PatientDetails?.patient_gender === 'Female' || this.props?.PatientDetails?.patient_gender === 'f' || this.props?.PatientDetails?.gender === "f" || this.props?.PatientDetails?.gender === "F" || this.props?.PatientDetails?.patient__gender === "f") ? "F" : (this.props?.PatientDetails?.patient_gender === "Trans" || this.props?.PatientDetails?.patient_gender === 't' || this.props?.PatientDetails?.gender === "t" || this.props?.PatientDetails?.gender === "T" || this.props?.PatientDetails?.patient__gender === "t") ? "T" : "-");
        let color = (this.props?.PatientDetails?.patient_type === "new_patient") ? "#F1584A" : (this.props?.PatientDetails?.patient_type === "old_patient") || (this.props?.PatientDetails?.patient_type === "establish_patient") ? "#800080" : '#29B6F6'
        return (
            <div>
                <Modal
                    open={true}
                >
                    <Box component={'div'} className="eMed_Presc_History_pop_main" >
                        {/* <Typography fontWeight={600} marginY={"0.5vw"}>{`${this.props?.PatientDetails?.first_name} ${this.props?.PatientDetails?.last_name ? this.props?.PatientDetails?.last_name : ""} ${this.props?.PatientDetails?.patient_account_number ? ` ( ${this.props?.PatientDetails?.patient_account_number} )` : ""} -  Drug Purchase History`}</Typography> */}
                        <Box component={'div'} height={'5vw'} p={'0.5vw'} display={'flex'} alignItems={'center'}>
                            <Box component={'div'}>
                                <Typography fontSize={'0.8vw'} color={Colors.grayShade}>Patient Details</Typography>
                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                    <Box component={'div'}>
                                        <Box component={'img'} src={((gender === 'F') || (gender === 'T')) ? ImagePaths.Female.default : ImagePaths.Male.default} height={'3vw'} width={'3vw'} sx={{ borderRadius: '50px', border: `2px solid ${color}` }} />
                                    </Box>
                                    <Box component={'div'} ml={'0.3vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{this.props?.PatientDetails?.first_name + " " + this.props?.PatientDetails?.last_name}</Typography>
                                        <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{`${this.props?.PatientDetails?.patient_account_number ? this.props?.PatientDetails?.patient_account_number : '-'} | ${this.props?.PatientDetails?.mobile_number ? this.props?.PatientDetails?.mobile_number : '-'}`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} ml={'3vw'}>
                                <Box component={'div'}>
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>Total Bills</Typography>
                                    <Typography fontWeight={600} fontSize={'0.9vw'}>{this.state.PrescriptionData?.length > 0 ? this.state.PrescriptionData?.length : 0}</Typography>
                                </Box>
                                <Box component={'div'} ml={'5vw'}>
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>Total Prescription</Typography>
                                    <Typography fontWeight={600} fontSize={'0.9vw'}>{this.state.prescriptionList?.length > 0 ? this.state.prescriptionList?.length : 0}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'} sx={{ m: '0.5vw' }}>
                            <Tabs
                                value={this.state.tab}
                                onChange={(e, value) => this.setState({ tab: value })}
                            >
                                <Tab label='Bill' value={'one'} sx={{ textTransform: 'capitalize' }} />
                                <Tab label='Prescription' value={'Two'} sx={{ textTransform: 'capitalize' }} />
                            </Tabs>
                            {this.state.tab === 'one' ?
                                <Box component={'div'} className='eMed_Presc_History_pop_table'>
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(id) => this.handleTableCheckbox(id)}
                                        rows={this.state.PrescriptionData}
                                        isRowSelectable={(params) => {
                                            if (!FetchItemsIds.includes(params?.row?.id)) { return true }
                                        }}
                                        columns={columns}
                                        getRowId={(row) => row['id']}
                                        pageSize={this.state.pageSize}
                                        page={this.state.page}
                                        onPageChange={(page) => this.setState({ page: page })}
                                        onPageSizeChange={(pageSize) => this.setState({ pageSize: pageSize })}
                                        localeText={{
                                            toolbarColumns: "",
                                            toolbarDensity: "",
                                            toolbarExport: "",
                                            toolbarFilters: "",
                                            toolbarExportPrint: ""
                                        }}
                                        components={{
                                            Toolbar: this.gridToolBar,
                                            NoRowsOverlay: () => (
                                                <Stack className='eMed_conf_nodata'>
                                                    {t("NoRecordsFound")}
                                                </Stack>
                                            )
                                        }}
                                        rowsPerPageOptions={[10, 20, 30]}
                                        headerHeight={40}
                                        disableSelectionOnClick
                                        loading={this.state.isLoader}
                                    />
                                </Box> : this.renderPrescriptionScreen()
                            }
                        </Box>
                        <Box marginY={"0.5vw"} display={'flex'} justifyContent={'flex-end'} mr={'1vw'}>
                            <Button sx={{ textTransform: 'capitalize', }} size="small" variant="outlined" onClick={() => { this.props.HistoryClosePopUp() }}>Close</Button>
                            <Button disabled={this.state.SelectedDrugs.length === 0} sx={{ textTransform: 'capitalize', marginLeft: '2vw' }} size="small" variant="contained" onClick={() => {
                                if (this.state.tab === 'one') {
                                    this.props.HistoryClosePopUp(this.state.SelectedDrugs)
                                } else {
                                    this.getPrescriptionListData()
                                }
                            }}>Move to Bill</Button>
                        </Box>
                    </Box>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}
export default withTranslation()(PrescriptionHistoryPop)

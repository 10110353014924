import { Autocomplete, Box, Button, Checkbox, FormControlLabel, MenuItem, Paper, Step, StepButton, Stepper, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Asset_Condition, Asset_Status, Asset_Type, Warranty_Status } from '../../../Utility/Constants';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { DateTime } from 'luxon';


const steps = ['Asset Details', 'Additional Info']

export default class AddNewAsset extends Component {
    constructor(props) {
        super(props)
        let editData = this.props.history?.location?.state?.data
        this.state = {
            activeStep: 0,
            completed: {},
            categoryList: [],
            subCategoryList: [],
            assetTypeList: Asset_Type,
            conditionList: Asset_Condition,
            statusList: Asset_Status,
            cityList: [],
            blockList: [],
            floorList: [],
            spotAreaList: [],
            documentList: editData?.documents?.length > 0 ? editData?.documents : [{
                doc_name: '',
                remarks: '',
                pdf: ''
            }],
            blockListNew: [],
            warrantyStausList: Warranty_Status,
            selectedCategory: editData ? editData?.category__category_name : null,
            selectedSubCategory: editData ? editData?.sub_category__sub_category_name : null,
            selectedAssetType: editData ? editData?.asset_type : null,
            selectedCondition: editData ? editData?.conditions : null,
            selectedStatus: editData ? editData?.status : 'Online',
            selectedCity: editData ? editData?.block__city : null,
            selectedBlock: editData ? editData?.block__block_name : null,
            selectedFloor: editData ? editData?.floor__floor_name : null,
            selectedSportArea: editData ? editData?.spot__spot_name : null,
            selectedWarrantyStatus: editData ? editData?.warranty_status : '',
            assetId: editData ? editData?.asset_no : '',
            assetName: editData ? editData?.asset_name : '',
            assetBarcodeNo: editData ? editData?.asset_barcode : '',
            makeName: editData ? editData?.make_name : '',
            modelName: editData ? editData?.model_name : '',
            assetSpec: editData ? editData?.asset_specifications : '',
            purchaseValue: editData ? editData?.purchase_value : '',
            equRefNo: editData ? editData?.equipment_ref_no : '',
            depvalue: editData ? editData?.depreciation_value_month : '',
            noOfDays: editData ? editData?.no_of_days : '',
            vendorCode: editData ? editData?.vendor_code : '',
            vendorName: editData ? editData?.vendor_name : '',
            gstNo: editData ? editData?.gst_no : '',
            conPersonName: editData ? editData?.contact_person : '',
            venMobNo: editData ? editData?.vendor_mobile_no : '',
            emailId: editData ? editData?.vendor_email : '',
            notes: editData ? editData?.notes : '',
            policyNo: editData ? editData?.policy_no : '',
            insuName: editData ? editData?.insured_name : '',
            insCmpyName: editData ? editData?.insurance_company_name : '',
            insValue: editData ? editData?.insurance_value : '',
            remainPeriod: editData ? editData?.remaining_period : '',
            branch: editData ? editData?.branch : '',
            insFromDate: editData ? editData?.insurance_from_date : null,
            insToDate: editData ? editData?.insurance_to_date : null,
            expDate: editData ? editData?.expiry_date : null,
            purDate: editData ? editData?.purchase_date : null,
            instalDate: editData ? editData?.insttallations_date : null,
            lstServDate: editData ? editData?.last_service_date : null,
            nxtSrvDate: editData ? editData?.next_service_date : null,
            warrantyDate: editData ? editData?.warranty_date : null,
            purOpen: false,
            expOpen: false,
            insfromOpen: false,
            insToOpen: false,
            warrantyOpen: false,
            instalOpen: false,
            lstSrvOpen: false,
            nxtSrvOpen: false,
            activeStatus: editData ? editData?.is_active : true,
            postLoad: false,
            isEdit: editData ? true : false,
            selectedId: editData ? editData?.id : null,
            categoryId: editData ? editData?.category_id : null,
            subCategoryId: editData ? editData?.sub_category_id : null,
            blockId: editData ? editData?.block_id : null,
            floorId: editData ? editData?.floor_id : null,
            spotId: editData ? editData?.spot_id : null
        }
        this.fileUploadRef = React.createRef();
    }

    componentDidMount() {
        this.getBlockListData()
        this.getAssetBlockFloorSpotData()
        this.getcategorySubCategoryData()
    }

    getBlockListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_BLOCK_LIST_GET_POST).
                then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        let activeCity = data?.filter(item => item?.active_status)
                        let cityOnly = activeCity?.map(item => item?.city)
                        this.setState({
                            cityList: [...new Set(cityOnly)]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAssetBlockFloorSpotData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_NEW_BLOCK_FLOOR_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            blockListNew: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getcategorySubCategoryData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSER_NEW_CAT_SUB_CAT_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            categoryList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postAssetNewData = () => {
        try {
            let states = this.state
            const formData = new FormData();
            let purStartDate = new Date(states.purDate)
            let instalDateStartDate = new Date(states.instalDate)
            let lstServDateStartDate = new Date(states.lstServDate)
            let nxtSrvDateStartDate = new Date(states.nxtSrvDate)
            let insFromDateStartDate = new Date(states.insFromDate)
            let insToDateStartDate = new Date(states.insToDate)
            let expDateStartDate = new Date(states.expDate)
            let warrantyDateStartDate = new Date(states.warrantyDate)

            let purDate = DateTime.fromJSDate(purStartDate).toFormat('yyyy-MM-dd')
            let instalDate = DateTime.fromJSDate(instalDateStartDate).toFormat('yyyy-MM-dd')
            let lstServDate = DateTime.fromJSDate(lstServDateStartDate).toFormat('yyyy-MM-dd')
            let nxtSrvDate = DateTime.fromJSDate(nxtSrvDateStartDate).toFormat('yyyy-MM-dd')
            let insFromDate = DateTime.fromJSDate(insFromDateStartDate).toFormat('yyyy-MM-dd')
            let insToDate = DateTime.fromJSDate(insToDateStartDate).toFormat('yyyy-MM-dd')
            let expDate = DateTime.fromJSDate(expDateStartDate).toFormat('yyyy-MM-dd')
            let warrantyDate = DateTime.fromJSDate(warrantyDateStartDate).toFormat('yyyy-MM-dd')

            formData.append("asset_no", states.assetId ? states.assetId : '')
            formData.append("asset_name", states.assetName ? states.assetName : '')
            formData.append("is_active", states.activeStatus ? states.activeStatus : false)
            formData.append("model_name", states.modelName ? states.modelName : '')
            formData.append("asset_type", states.selectedAssetType ? states.selectedAssetType : '')
            formData.append("asset_specifications", states.assetSpec ? states.assetSpec : '')
            formData.append("category_id", states.selectedCategory ? states.categoryId : '')
            formData.append("sub_category_id", states.selectedSubCategory ? states.subCategoryId : '')
            formData.append("block_id", states.selectedBlock ? states.blockId : '')
            formData.append("floor_id", states.selectedFloor ? states.floorId : '')
            formData.append("spot_id", states.selectedSportArea ? states.spotId : '')
            formData.append("purchase_date", states.purDate ? purDate : '')
            formData.append("purchase_value", states.purchaseValue ? states.purchaseValue : '')
            formData.append("insttallations_date", states.instalDate ? instalDate : '')
            formData.append("equipment_ref_no", states.equRefNo ? states.equRefNo : '')
            formData.append("depreciation_value_month", states.depvalue ? states.depvalue : '')
            formData.append("last_service_date", states.lstServDate ? lstServDate : '')
            formData.append("next_service_date", states.nxtSrvDate ? nxtSrvDate : '')
            formData.append("vendor_code", states.vendorCode ? states.vendorCode : '')
            formData.append("vendor_name", states.vendorName ? states.vendorName : '')
            formData.append("gst_no", states.gstNo ? states.gstNo : '')
            formData.append("contact_person", states.conPersonName ? states.conPersonName : '')
            formData.append("vendor_mobile_no", states.venMobNo ? states.venMobNo : '')
            formData.append("vendor_email", states.emailId ? states.emailId : '')
            formData.append("notes", states.notes ? states.notes : '')
            formData.append("policy_no", states.policyNo ? states.policyNo : '')
            formData.append("insured_name", states.insuName ? states.insuName : '')
            formData.append("insurance_from_date", states.insFromDate ? insFromDate : '')
            formData.append("insurance_to_date", states.insToDate ? insToDate : '')
            formData.append("insurance_company_name", states.insCmpyName ? states.insCmpyName : '')
            formData.append("expiry_date", states.expDate ? expDate : '')
            formData.append("remaining_period", states.remainPeriod ? states.remainPeriod : '')
            formData.append("warranty_status", states.selectedWarrantyStatus ? states.selectedWarrantyStatus : '')
            formData.append("vendor_email", states.emailId ? states.emailId : '')
            formData.append('insurance_value', states.insValue ? states.insValue : '')
            formData.append('warranty_date', states.warrantyDate ? warrantyDate : '')
            formData.append('branch', states.branch ? states.branch : '')
            formData.append('conditions', states.selectedCondition ? states.selectedCondition : '')
            formData.append('doc_length', ((states.documentList[0]?.pdf || states.documentList[0]?.doc_name) ? states.documentList?.length : 0))
            formData.append('status', states.selectedStatus ? states.selectedStatus : 'Online')
            formData.append('make_name', states.makeName ? states.makeName : '')
            formData.append('no_of_days', states.noOfDays ? states.noOfDays : '')


            states.documentList?.forEach((item, index) => {
                formData.append(`documentList[${index}].doc_name`, item.doc_name)
                formData.append(`documentList[${index}].remarks`, item.remarks)
                formData.append(`documentList[${index}].pdf `, item.pdf)
            })

            if (this.state.isEdit) {
                formData.append('id', states.selectedId)
            }
            this.setState({ postLoad: true })
            RestAPIService.create(formData, Serviceurls.ASSET_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.onClearHandler()
                        this.setState({ postLoad: false, selectedId: null })
                        setTimeout(() => {
                            this.props.history?.push('/AssetHome')
                        }, 500)
                    }
                }).catch((error) => {
                    this.setState({ postLoad: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    //Stepper Function Starts ===>

    totalSteps = () => {
        return steps.length;
    };

    completedSteps = () => {
        try {
            return Object.keys(this.state.completed).length;
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    isLastStep = () => {
        try {
            return this.state.activeStep === this.totalSteps() - 1;
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    allStepsCompleted = () => {
        try {
            return this.completedSteps() === this.totalSteps();
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    handleNext = () => {
        try {
            let checkNext = this.checkNextValidation()
            if (checkNext === null) {
                const newActiveStep =
                    this.isLastStep() && !this.allStepsCompleted()
                        ? steps.findIndex((step, i) => !(i in this.state.completed))
                        : this.state.activeStep + 1;
                this.setState({ activeStep: newActiveStep });
            } else {
                this.errorMessage(checkNext)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    //Stepper Function Ends ===>

    checkNextValidation = () => {
        try {
            let message = null;
            let states = this.state
            if (states.assetName === '' || states.selectedCategory === null || states.selectedSubCategory === '' ||
                states.selectedAssetType === null || states.selectedCity === null || states.selectedBlock === null ||
                states.selectedFloor === null || states.selectedSportArea === null || states.vendorName === ''
            ) {
                message = 'Enter all mandatory Field'
            } else if (states.emailId !== '' && !CommonValidation.emailValidation(states.emailId)) {
                message = 'Enter valid Email'
            } else if (states.gstNo !== '' && !CommonValidation.newGstValidation(states.gstNo)) {
                message = 'Enter Valid GST No (Eg. 22AAAAA0000A1Z5)'
            } else {
                message = null
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    TextBox = (names, values, labels, width, mr, mt, onlyRead, placeholder, index) => {
        try {
            return (
                <TextField
                    autoComplete='off'
                    variant='standard'
                    size='small'
                    sx={{ width: width, mr: mr, mt: mt }}
                    value={values}
                    label={labels}
                    placeholder={placeholder}
                    name={names}
                    onChange={(e) => this.onChangeHandler(e, names, index)}
                    InputProps={{ readOnly: onlyRead }}
                // inputProps={{"aria-readonly":onlyRead}}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, names, index) => {
        try {
            let value = e.target.value
            let states = this.state

            switch (names) {
                case 'doc_name':
                    states.documentList[index]['doc_name'] = value
                    this.setState({ states })
                    break;
                case 'remarks':
                    states.documentList[index]['remarks'] = value
                    this.setState({ states })
                    break;
                case 'assetName':
                    if (value?.length <= 200 || value === '') {
                        this.setState({ assetName: value })
                    } else {
                        this.errorMessage('Allow Only 200 Characters')
                    }
                    break;
                case 'vendorName':
                    if ((CommonValidation.alphabetOnly(value) && value?.length <= 200) || value === '') {
                        this.setState({ vendorName: value })
                    }
                    break;
                case 'conPersonName':
                    if ((CommonValidation.alphabetOnly(value) && value?.length <= 200) || value === '') {
                        this.setState({ conPersonName: value })
                    }
                    break;
                case 'venMobNo':
                    if ((CommonValidation.numberOnly(value) && value?.length <= 10) || value === '') {
                        this.setState({ venMobNo: value })
                    }
                    break;
                case 'purchaseValue':
                    if (value?.length <= 10 || value === '') {
                        this.setState({ purchaseValue: value })
                    }
                    break;
                case 'selectedWarrantyStatus':
                    if (states.warrantyDate !== null) {
                        this.setState({ selectedWarrantyStatus: value })
                    } else {
                        this.setState({ selectedWarrantyStatus: '' })
                    }
                    break;
                case 'notes':
                    if (value?.length <= 250 || value === '') {
                        this.setState({ notes: value })
                    } else {
                        this.errorMessage('Allow Only 250 Characters')
                    }
                    break;
                default:
                    if (value?.length <= 200 || value === '') {
                        states[names] = value
                        this.setState({ states })
                    } else {
                        this.errorMessage('Allow Only 200 Characters')
                    }
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AutoCompleteBox = (options, names, key, labels, width, mr, mt, disable) => {
        try {
            let states = this.state
            return (
                <Autocomplete
                    clearIcon
                    disabled={disable}
                    size='small'
                    sx={{ width: width, mr: mr, mt: mt }}
                    options={options}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option[names]}
                    value={states[key]}
                    onChange={(e, value) => { this.onChangeAutoCompletehandler(value, names, key) }}
                    renderInput={(params) => <TextField variant='standard' autoComplete='off' {...params} label={labels} />}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeAutoCompletehandler = (value, names, key) => {
        try {
            let states = this.state
            switch (names) {
                case 'block_name':
                    this.setState({ selectedBlock: value }, () => {
                        this.setState({
                            floorList: this.state.selectedBlock?.floor_data,
                            selectedFloor: null,
                            selectedSportArea: null,
                            blockId: value?.block_id
                        })
                    })
                    break;
                case 'floor_name':
                    this.setState({ selectedFloor: value }, () => {
                        this.setState({
                            spotAreaList: this.state.selectedFloor?.spot_data,
                            selectedSportArea: null,
                            floorId: value?.floor_id
                        })
                    })
                    break;
                case 'spot_name':
                    this.setState({ selectedSportArea: value, spotId: value?.id })
                    break;
                case 'city':
                    this.setState({ selectedCity: value }, () => {
                        let filterData = this.state.blockListNew?.filter(item => item?.city === this.state.selectedCity)
                        if (filterData?.length > 0) {
                            this.setState({
                                blockList: filterData,
                                selectedBlock: null,
                                selectedFloor: null,
                                selectedSportArea: null
                            })
                        } else {
                            this.errorMessage(`${this.state.selectedCity} doesn't have block`)
                            this.setState({
                                blockList: [],
                                selectedBlock: null,
                                selectedFloor: null,
                                selectedSportArea: null
                            })
                        }
                    })
                    break;
                case 'category_name':
                    this.setState({ selectedCategory: value }, () => {
                        this.setState({
                            subCategoryList: this.state.selectedCategory?.sub_category_data,
                            categoryId: value?.category_id,
                            selectedSubCategory: null
                        })
                    })
                    break;
                case 'sub_category_name':
                    this.setState({ selectedSubCategory: value, subCategoryId: value?.sub_category_id })
                    break;
                default:
                    states[key] = value
                    this.setState({ states })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    SelectBox = (list, key, labels, width, mr, mt) => {
        try {
            let states = this.state
            return (
                <TextField
                    select
                    size='small'
                    variant='standard'
                    label={labels}
                    sx={{ width: width, mr: mr, mt: mt }}
                    autoComplete="off"
                    value={states[key]}
                    onChange={(e) => {
                        let states = this.state
                        states[key] = e.target.value
                        this.setState({ states })
                    }}
                >
                    {list?.map((item, index) => (
                        <MenuItem key={index} value={item?.value ? item?.value : item}>{item?.name ? item?.name : item}</MenuItem>
                    ))}
                </TextField>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    DateAndTimePicker = (date, dateSet, values, key, labels, width, mr, mt) => {
        try {
            let states = this.state
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        open={date}
                        onOpen={() => {
                            let states = this.state
                            states[dateSet] = true
                            this.setState({ states })
                        }}
                        onClose={() => {
                            let states = this.state
                            states[dateSet] = false
                            this.setState({ states })
                        }}
                        inputFormat='dd-MM-yyyy'
                        value={states[key]}
                        onChange={(value) => {
                            let states = this.state
                            states[key] = value
                            this.setState({ states }, () => {
                                if (states.warrantyDate !== null) {
                                    if (key === 'warrantyDate') {
                                        if (new Date() > this.state.warrantyDate) {
                                            this.setState({ selectedWarrantyStatus: Warranty_Status[1] })
                                        } else {
                                            this.setState({ selectedWarrantyStatus: Warranty_Status[0] })
                                        }
                                    }
                                } else {
                                    this.setState({ selectedWarrantyStatus: '' })
                                }
                            })
                        }}
                        minDate={key === 'instalDate' ? (states.purDate ? new Date(states.purDate) : new Date()) :
                            key === 'nxtSrvDate' ? (states.lstServDate ? new Date(states.lstServDate) : new Date()) :
                                key === 'insToDate' ? (states.insFromDate ? new Date(states.insFromDate) : new Date()) : ''}
                        maxDate={key === 'insToDate' || key === 'expDate' || key === 'instalDate' || key === 'nxtSrvDate' || key === 'warrantyDate' ? '' :
                            (key === 'purDate' ? (states.instalDate ? new Date(states.instalDate) : new Date()) :
                                key === 'lstServDate' ? (states.nxtSrvDate ? new Date(states.nxtSrvDate) : new Date()) :
                                    key === 'insFromDate' ? (states.insToDate ? new Date(states.insToDate) : new Date()) :
                                        new Date())}
                        label={labels}
                        renderInput={(props) => <TextField
                            {...props}
                            sx={{ width: width, mr: mr, mt: mt }}
                            variant='standard' size='small'
                            autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={() => {
                                let states = this.state
                                states[dateSet] = true
                                this.setState({ states })
                            }}
                        />}
                    />
                </LocalizationProvider>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                activeStep: 0,
                completed: {},
                documentList: [{
                    doc_name: '',
                    remarks: '',
                    pdf: ''
                }],
                selectedCategory: null,
                selectedSubCategory: null,
                selectedAssetType: null,
                selectedCondition: null,
                selectedStatus: 'Online',
                selectedCity: null,
                selectedBlock: null,
                selectedFloor: null,
                selectedSportArea: null,
                selectedWarrantyStatus: '',
                assetId: '',
                assetName: '',
                assetBarcodeNo: '',
                makeName: '',
                modelName: '',
                assetSpec: '',
                purchaseValue: '',
                equRefNo: '',
                depvalue: '',
                noOfDays: '',
                vendorCode: '',
                vendorName: '',
                gstNo: '',
                conPersonName: '',
                venMobNo: '',
                emailId: '',
                notes: '',
                policyNo: '',
                insuName: '',
                insCmpyName: '',
                insValue: '',
                remainPeriod: '',
                branch: '',
                insFromDate: null,
                insToDate: null,
                expDate: null,
                purDate: null,
                instalDate: null,
                lstServDate: null,
                nxtSrvDate: null,
                warrantyDate: null,
                purOpen: false,
                expOpen: false,
                insfromOpen: false,
                insToOpen: false,
                warrantyOpen: false,
                instalOpen: false,
                lstSrvOpen: false,
                nxtSrvOpen: false,
                activeStatus: true,
                selectedId: null,
                postLoad: false,
                categoryId: null,
                spotId: null,
                floorId: null,
                subCategoryId: null,
                blockId: null
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAssetDetails = () => {
        try {
            let subCat = this.state.subCategoryList?.length > 0 ? false : true
            let block = this.state.selectedCity === null ? true : false
            let floor = this.state.selectedBlock === null ? true : false
            let spot = this.state.selectedFloor === null ? true : false
            return (
                <Box component={'div'}>
                    <Box component={'div'} borderBottom={'1px solid lightgray'}>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} p={'0.5vw'}>
                            <Typography fontWeight={600}>Asset Details</Typography>
                            <FormControlLabel control={<Checkbox size='small' checked={this.state.activeStatus} onChange={(e) => this.setState({ activeStatus: e.target.checked })} />} label="Active" />
                        </Box>
                        <Box component={'div'} p={'0.5vw'}>
                            <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                {this.TextBox('assetId', this.state.assetId, 'Asset ID (Read Only)', '17vw', '0.8vw', '', true)}
                                {this.TextBox('assetName', this.state.assetName, 'Asset Name *', '17vw', '0.8vw')}
                                {this.AutoCompleteBox(this.state.categoryList, 'category_name', 'selectedCategory', 'Catagory *', '17vw', '0.8vw')}
                                {this.AutoCompleteBox(this.state.subCategoryList, 'sub_category_name', 'selectedSubCategory', 'Sub Catagory *', '17vw', '0.8vw', '', subCat)}
                                {this.SelectBox(this.state.assetTypeList, 'selectedAssetType', 'Asset Type *', '17vw')}
                            </Box>
                            <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                {this.SelectBox(this.state.conditionList, 'selectedCondition', 'Condition', '17vw', '0.8vw', '1vw')}
                                {this.SelectBox(this.state.statusList, 'selectedStatus', 'Status', '17vw', '0.8vw', '1vw')}
                                {this.TextBox('assetBarcodeNo', this.state.assetBarcodeNo, 'Asset Barcode No (Read Only)', '17vw', '0.8vw', '1vw', true)}
                                {this.TextBox('makeName', this.state.makeName, 'Make Name', '17vw', '0.8vw', '1vw')}
                                {this.TextBox('modelName', this.state.modelName, 'Model Name', '17vw', '0.8vw', '1vw')}
                            </Box>
                            <Box display={'flex'} m={'0.5vw'}>
                                {this.AutoCompleteBox(this.state.cityList, 'city', 'selectedCity', 'City *', '17vw', '0.8vw')}
                                {this.AutoCompleteBox(this.state.blockList, 'block_name', 'selectedBlock', 'Block *', '17vw', '0.8vw', '', block)}
                                {this.AutoCompleteBox(this.state.floorList, 'floor_name', 'selectedFloor', 'Floor *', '17vw', '0.8vw', '', floor)}
                                {this.AutoCompleteBox(this.state.spotAreaList, 'spot_name', 'selectedSportArea', 'Spot/Area *', '17vw', '0.8vw', '', spot)}
                                {this.TextBox('branch', this.state.branch, 'Branch', '17vw', '0.8vw')}
                            </Box>
                            <Box display={'flex'} m={'0.5vw'}>
                                {this.TextBox('assetSpec', this.state.assetSpec, 'Asset Specifications', '17vw', '0.8vw')}
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                            <Typography fontWeight={600}>Purchase Details</Typography>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                {this.DateAndTimePicker(this.state.purOpen, 'purOpen', this.state.purDate, 'purDate', 'Purchase Date', '17vw', '0.8vw')}
                                {this.TextBox('purchaseValue', this.state.purchaseValue, 'Purchase Value', '17vw', '0.8vw')}
                                {this.DateAndTimePicker(this.state.instalOpen, 'instalOpen', this.state.instalDate, 'instalDate', 'Installation Date', '17vw', '0.8vw')}
                                {this.TextBox('equRefNo', this.state.equRefNo, 'Equipment Reference No', '17vw', '0.8vw')}
                                {this.TextBox('depvalue', this.state.depvalue, 'Depreciation Value/Month', '17vw', '0.8vw')}
                            </Box>
                            <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                {this.DateAndTimePicker(this.state.lstSrvOpen, 'lstSrvOpen', this.state.lstServDate, 'lstServDate', 'Last Service Date', '17vw', '0.8vw')}
                                {this.TextBox('noOfDays', this.state.noOfDays, 'No Of Days', '17vw', '0.8vw')}
                                {this.DateAndTimePicker(this.state.nxtSrvOpen, 'nxtSrvOpen', this.state.nxtSrvDate, 'nxtSrvDate', 'Next Service Date', '17vw', '0.8vw')}
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                            <Typography fontWeight={600}>Vendor Details</Typography>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                {this.TextBox('vendorCode', this.state.vendorCode, 'Vendor Code', '17vw', '0.8vw')}
                                {this.TextBox('vendorName', this.state.vendorName, 'Vendor Name *', '17vw', '0.8vw')}
                                {this.TextBox('gstNo', this.state.gstNo, 'GST No', '17vw', '0.8vw')}
                                {this.TextBox('conPersonName', this.state.conPersonName, 'Contact Person Name', '17vw', '0.8vw')}
                                {this.TextBox('venMobNo', this.state.venMobNo, 'Vendor Mobile No', '17vw', '0.8vw')}
                            </Box>
                            <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                {this.TextBox('emailId', this.state.emailId, 'Email ID', '17vw', '0.8vw')}
                                {this.TextBox('notes', this.state.notes, 'Notes', '17vw', '0.8vw')}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAdditionalDetails = () => {
        try {
            return (
                <Box component={'div'}>
                    <Box component={'div'}>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                            <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Insurance Details</Typography>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                    {this.TextBox('policyNo', this.state.policyNo, 'Policy No', '17vw', '0.8vw')}
                                    {this.TextBox('insuName', this.state.insuName, 'Insurance Name', '17vw', '0.8vw')}
                                    {this.DateAndTimePicker(this.state.insfromOpen, 'insfromOpen', this.state.insFromDate, 'insFromDate', 'Insurance From Date', '17vw', '0.8vw')}
                                    {this.DateAndTimePicker(this.state.insToOpen, 'insToOpen', this.state.insToDate, 'insToDate', 'Insurance To Date', '17vw', '0.8vw')}
                                    {this.TextBox('insCmpyName', this.state.insCmpyName, 'Insurance Company Name', '17vw', '0.8vw')}
                                </Box>
                                <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                    {this.TextBox('insValue', this.state.insValue, 'Insurance Value', '17vw', '0.8vw')}
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                            <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Warranty Details</Typography>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                    {this.DateAndTimePicker(this.state.warrantyOpen, 'warrantyOpen', this.state.warrantyDate, 'warrantyDate', 'Warranty Date', '17vw', '0.8vw')}
                                    {this.DateAndTimePicker(this.state.expOpen, 'expOpen', this.state.expDate, 'expDate', 'Expiry Date', '17vw', '0.8vw')}
                                    {this.TextBox('remainPeriod', this.state.remainPeriod, 'Remaining Period', '17vw', '0.8vw')}
                                    {/* {this.SelectBox(this.state.warrantyStausList, 'selectedWarrantyStatus', 'Warranty Status', '17vw', '0.8vw')} */}
                                    {this.TextBox('selectedWarrantyStatus', this.state.selectedWarrantyStatus, 'Warranty Status', '17vw', '0.8vw', '', true)}
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                            <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Document</Typography>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} display={'flex'} m={'0.5vw'} flexDirection={'column'}>
                                    {this.state.documentList?.map((item, index) => {
                                        return (
                                            <Box component={'div'} key={index} display={'flex'} mt={'0.5vw'}>
                                                {this.TextBox('doc_name', item?.doc_name, 'Document Name', '17vw', '0.8vw', '', '', '', index)}
                                                {this.TextBox('remarks', item?.remarks, 'Remarks', '17vw', '0.8vw', '', '', '', index)}
                                                <Typography fontWeight={600} fontSize={'0.9vw'} sx={{ position: 'relative', top: '1.7vw' }}>{item?.pdf ? item?.pdf?.name : ''}</Typography>
                                                <Box component={'input'} type='file' sx={{ display: 'none' }}
                                                    // multiple
                                                    accept="application/pdf"
                                                    ref={this.fileUploadRef}
                                                    onChange={(e) => {
                                                        let selectedFile = e.target.files[0]
                                                        if (selectedFile && selectedFile?.type === 'application/pdf') {
                                                            this.state.documentList[index]['pdf'] = e.target.files[0]
                                                            this.setState({ docoumentList: this.state.documentList })
                                                        } else {
                                                            this.errorMessage('Please select a PDF file')
                                                        }
                                                    }}
                                                />
                                                <Box component={'div'} sx={{ position: 'relative', top: '1vw' }}>
                                                    <Button
                                                        sx={{ textTransform: 'capitalize' }}
                                                        className='Common_Btn_Min_Width'
                                                        size='small'
                                                        onClick={() => this.fileUploadRef.current.click()}
                                                    ><UploadFileIcon color='primary' /></Button>
                                                    <Button
                                                        sx={{ textTransform: 'capitalize' }}
                                                        className='Common_Btn_Min_Width'
                                                        size='small'
                                                        onClick={() => {
                                                            if (this.state.documentList?.length === 1) {
                                                                let newDoc = [{
                                                                    doc_name: '',
                                                                    remarks: '',
                                                                    pdf: ''
                                                                }]
                                                                this.setState({ documentList: newDoc })
                                                            } else {
                                                                this.state.documentList?.splice(index, 1)
                                                                this.setState({ documentList: this.state.documentList })
                                                            }
                                                        }}
                                                    ><CloseIcon color='error' /></Button>
                                                </Box>

                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Box component={'div'}>
                                    <Button
                                        sx={{ textTransform: 'capitalize' }}
                                        className='Common_Btn_Min_Width'
                                        startIcon={<AddCircleIcon />}
                                        size='small'
                                        onClick={() => {
                                            let newDoc = {
                                                doc_name: '',
                                                remarks: '',
                                                pdf: ''
                                            }
                                            let states = this.state
                                            if (states.documentList[states.documentList?.length - 1]['pdf']?.length === 0) {
                                                this.errorMessage("Previous document is empty")
                                            } else {
                                                this.state.documentList?.push(newDoc)
                                                this.setState({ documentList: this.state.documentList })
                                            }
                                        }}
                                    >Add New Document</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <Box component={'div'}>
                <Box component={'div'} height={'84vh'}>
                    <Box component={'div'} height={'6vh'} display={'flex'} borderBottom={'2px solid lightgray'} alignItems={'center'} p={'0.5vw'}>
                        <Button
                            emed_tid='back_home'
                            className='Common_Btn_Min_Width'
                            startIcon={<ArrowBackIosIcon />}
                            size='small'
                            onClick={() => { this.props.history.push('/AssetHome') }}
                        ></Button>
                        <Typography fontWeight={600}>Asset List</Typography>
                    </Box>
                    <Paper sx={{ m: '0.8vw', borderRadius: '5px', maxHeight: '77vh' }} elevation={2}>
                        <Box component={'div'} height={'6vh'} borderBottom={'2px solid lightgray'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Box component={'div'} width={'25vw'}>
                                <Stepper nonLinear activeStep={this.state.activeStep}>
                                    {steps?.map((item, index) => (
                                        <Step key={item} completed={this.state.completed[index]}>
                                            <StepButton color='primary' onClick={() => this.setState({ activeStep: index })}>{item}</StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Box>
                        <Box component={'div'} height={'62vh'} overflow={'auto'} borderBottom={'2px solid lightgray'}>
                            {this.state.activeStep === 0 ? this.renderAssetDetails() : this.renderAdditionalDetails()}
                        </Box>
                        <Box component={'div'} height={'8vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                            {this.state.activeStep === 0 ?
                                <Button
                                    variant='outlined'
                                    onClick={() => this.props.history?.push("/AssetHome")}
                                    sx={{ mr: 1, textTransform: 'capitalize' }}
                                >
                                    Cancel
                                </Button>
                                : <Button
                                    variant='outlined'
                                    onClick={() => {
                                        this.setState({ activeStep: this.state.activeStep - 1 })
                                    }}
                                    sx={{ mr: 1, textTransform: 'capitalize' }}
                                >
                                    Back
                                </Button>
                            }
                            <Button
                                variant='contained'
                                sx={{ mr: 1, textTransform: 'capitalize' }}
                                disabled={this.state.activeStep === 1 ? this.state.postLoad : false}
                                onClick={() => this.state.activeStep === 0 ? this.handleNext() : this.postAssetNewData()}>
                                {this.state.activeStep === 1 ? 'Save' : (this.state.activeStep === 1 && this.state.isEdit) ? 'Update' : 'Next'}
                            </Button>
                        </Box>
                    </Paper>
                </Box>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}

import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, CommonPopUp, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Menu, MenuItem, Stack, TextField, Tooltip } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors } from '../../../../Styles/Colors';
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';
import ShowComponents from '../Common/ShowComponent';
class DocSymptomsLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SymptomsData: [],
            isDeleteCliked: false,
            SelectedList: {},
            PatientData: this.props?.PatientData,
            HistorySymptom: "",
            presentComplaint: "",
            EditItem : {}
        }
    }

    componentDidUpdate(prevprops) {
        if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetSymptomsData() }
        if (prevprops.SelectedComponent !== this.props.SelectedComponent && this.props.SelectedComponent) { 
            this.setState({ EditItem : {}})
         }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    GetSymptomsData(keepEditId = false) {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMS + `?appointment_id=${this.state.PatientData?.appointment_id}&patient_id=${this.state.PatientData?.patient_id}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            EditItem: keepEditId ? this.state.EditItem : {},
                            SymptomsData: response.data.data,
                            HistorySymptom: response.data?.paediatric_symptoms?.presenting_complaints_history ? response.data?.paediatric_symptoms?.presenting_complaints_history : "",
                            presentComplaint: response.data?.paediatric_symptoms?.presenting_complaints ? response.data?.paediatric_symptoms?.presenting_complaints : "",
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    onEditHandler(data) {
        this.setState({
            EditItem: data.row,
        }, ()=>{
            let Obj = {
                "presenting_complaints_history": this.state.HistorySymptom,
                "presenting_complaints": this.state.presentComplaint,
            }
            this.props.EditSymptomData({ ...data.row, ...Obj })
        })
    }
    onDeleteHandler(data) {
        try {
            this.setState({
                isDeleteCliked: true,
                SelectedList: data.row,
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose() {
        this.setState({ isDeleteCliked: false, SelectedList : {} })
    }
    DeleteSymptom() {
        try {
            var states = this.state
            let data = {
                appointment_id: states.PatientData?.appointment_id,
                symptoms_id: states.SelectedList?.id
            }
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SYMPTOM_DELETE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({ isDeleteCliked: false, }, () => { this.GetSymptomsData(true) })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        try {
            const { t } = this.props
            this.state.SymptomsData.forEach((element, index) => { element.sno = index + 1 })
            const columns = [
                {
                    field: "symptom", flex: 0.17, headerName: t("SymtomName"),
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.symptom ? params?.row?.symptom?.length > 25 ?
                            <Tooltip placement='top' title={params?.row?.symptom} arrow>
                                <div>{params?.row?.symptom.slice(0, 25) + "..."}</div></Tooltip> :
                            params?.row?.symptom : "-"}
                    </Box>)
                },
                {
                    field: "duration", flex: 0.14, headerName: t("Duration"), headerAlign: "center", align: 'center',
                    renderCell: (params) => {
                        let DurationText = params?.row?.duration ? `${params?.row?.duration} - ${params?.row?.duration_mode === '1' ? 'Days' : params?.row?.duration_mode === '2' ? 'Weeks' : params?.row?.duration_mode === '3' ? 'Months' : 'Years'}` : "-"
                        return (DurationText)
                    }
                },
                {
                    field: "symptom_desc", flex: 0.18, headerName: t("Description"),
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.symptom_desc ? params?.row?.symptom_desc?.length > 30 ?
                            <Tooltip placement='top' title={params?.row?.symptom_desc} arrow>
                                <div>{params?.row?.symptom_desc.slice(0, 30) + "..."}</div></Tooltip> :
                            params?.row?.symptom_desc : "-"}
                    </Box>)
                },
                {
                    field: "action", flex: 0.14, headerName: t("Action"), headerAlign: "center", align: "center",
                    renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <CommonEditButton size={"1.2vw"} disable={this.props.isLocked} onClick={this.onEditHandler.bind(this, params)} />
                        <CommonDeleteButton size={"1.2vw"} disable={this.props.isLocked || this.state.EditItem?.id === params?.row?.id} onClick={this.onDeleteHandler.bind(this, params)} />
                    </Box>
                }
            ]
            return (
                <ShowComponents onIntersection={this.GetSymptomsData.bind(this)}>
                    <Box height={'20vh'} overflow={'auto'}>
                        <DataGrid
                            density='compact'
                            rows={this.state.SymptomsData}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            headerHeight={40}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_DocNotes_no_records_text' height={'15vh'}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            disableSelectionOnClick
                        />
                    </Box>
                    <Box sx={{ display: this.props?.UserInfo?.service_type === "PD" ? 'flex' : "none", flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', paddingTop: '0.5vw' }}>
                        <TextField
                            size='small'
                            className='eMed_DocNotes_TextFields'
                            label={t("Presenting Complaints")}
                            value={this.state.presentComplaint}
                            disabled
                        />
                        <TextField
                            size='small'
                            className='eMed_DocNotes_TextFields'
                            label={t("History of Presenting Complaints")}
                            value={this.state.HistorySymptom}
                            disabled
                        />
                    </Box>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.isDeleteCliked ? <DeletePopup
                        DeletTitle={`${t("deleteMsg")} ${t("Symptom")} ${this.state.SelectedList ? this.state.SelectedList?.symptom : "-"} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DeleteSymptom.bind(this)}
                    /> : null}
                </ShowComponents>
            )
        }
        catch (e) {

        }
    }
}
export default withTranslation()(DocSymptomsLeft)

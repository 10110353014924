import { Box, Button, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from "../../../Utility/ImagePaths";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { Colors } from '../../../Styles/Colors';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";

class OTConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 10,
            OTRoomData: [],
            isEditCliked: false,
            isDeleteCliked: false,
            SelectedList: null,
            OTName: "",
            SlotTiming: [
                {
                    id: null,
                    slot_name: "",
                    slot_start_time: null,
                    slot_end_time: null
                }
            ],
            DeletedSlotID: []
        }
    }

    componentDidMount() {
        this.GetOTList()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetOTList() {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.OT_ROOM_CONFIG).
                then((response) => {
                    if (response.data.status === "success") {
                        let data = response.data.data
                        this.setState({
                            OTRoomData: data
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            this.LoaderFunction(false)
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    validation = () => {
        try {
            var states = this.state;
            if (states.OTName !== "" && states.SlotTiming[0]?.slot_name !== "" && ((this.state.SlotTiming[0].slot_start_time != null && this.state.SlotTiming[0].slot_start_time?.$d !== "Invalid Date") || (this.state.SlotTiming[0]?.slot_end_time != null && this.state.SlotTiming[0].slot_end_time?.$d !== "Invalid Date"))) {
                this.PostBlock()
            } else {
                if (states.OTName == "") {
                    this.errorMessage("Operation Name")
                } else if (states.SlotTiming[0]?.slot_name !== "" && ((this.state.SlotTiming[0].slot_start_time != null && this.state.SlotTiming[0].slot_start_time?.$d !== "Invalid Date") || (this.state.SlotTiming[0]?.slot_end_time != null && this.state.SlotTiming[0].slot_end_time?.$d !== "Invalid Date"))) {
                    this.errorMessage("Enter Slot Details")
                } 
                // else if(){
                //     this.errorMessage("The selected time overlaps with the previous slot")
                // }
            }
            // const { SlotTiming } = this.state;

            // for (let i = 0; i < SlotTiming.length - 1; i++) {
            //     const currentSlot = SlotTiming[i];
            //     const nextSlot = SlotTiming[i + 1];

            //     if ((currentSlot.slot_end_time >= nextSlot.slot_start_time) && nextSlot.slot_start_time != null) {
            //         alert(`Slot ${i + 1} overlaps with Slot ${i + 2}`);
            //         return
            //     }
            // }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    convertTimeFormatInArray = (array, keys) => {
        return array.map(obj => {
            const newObj = { ...obj };
            keys.forEach(key => {
                if (key === "" || newObj[key] === "") {
                    newObj[key] = null;
                } else if (newObj[key]) {
                    const date = new Date(newObj[key]);
                    const hours = ('0' + date.getHours()).slice(-2);
                    const minutes = ('0' + date.getMinutes()).slice(-2);
                    const seconds = ('0' + date.getSeconds()).slice(-2);
                    newObj[key] = `${hours}:${minutes}:${seconds}`;
                }
            });
            return newObj;
        });
    };

    isTimeAlreadyAdded(checkTime, index) {
        const modifiedArray = this.convertTimeFormatInArray(this.state?.SlotTiming, ["slot_start_time", "slot_end_time"]);
        for (var i = 0; i < modifiedArray.length; i++) {
            var startTime = modifiedArray[i].slot_start_time;
            var endTime = modifiedArray[i].slot_end_time;
            if (checkTime >= startTime && checkTime <= endTime && (index !== i)) {
                return true; // Time already exists
            }
        }
        return false; // Time does not exist
    }

    PostBlock = () => {
        var states = this.state
        const modifiedArray = this.convertTimeFormatInArray(states?.SlotTiming, ["slot_start_time", "slot_end_time"]);
        let flag = false
        for (var i = 0; i < modifiedArray.length; i++) {
            if (modifiedArray[i]?.slot_start_time >= modifiedArray[i]?.slot_end_time) {
                this.errorMessage(`Start time greater than end time in slot - ${modifiedArray[i].slot_name}`)
                flag = false
                break

            } else if (this.isTimeAlreadyAdded(modifiedArray[i]?.slot_start_time, i) || this.isTimeAlreadyAdded(modifiedArray[i]?.slot_end_time, i)) {
                this.errorMessage(`Given time in slot ${modifiedArray[i].slot_name} is already exist`)
                flag = false
                break
            }
            else {
                flag = true
            }
        }
        try {
            var data = {
                "ot_name": states?.OTName,
                "slot_data": modifiedArray,
                "deleted_ids": states?.DeletedSlotID
            }

            if (flag) {
                RestAPIService.create(data, Serviceurls.OT_ROOM_CONFIG).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.setState({
                                isEditCliked: false
                            }, () => {
                                this.ClearData()
                                this.GetOTList()
                            })
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    DelectBlocks = () => {
        try {
            var states = this.state
            RestAPIService.delete(Serviceurls.OT_ROOM_CONFIG + "?ot_name=" + states.SelectedList?.ot_name).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.ClearData()
                        this.GetOTList()
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    popupClose() {
        this.setState({
            isDeleteCliked: false
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClearData = () => {
        if (this.state.isEditCliked) {

        } else {
            this.setState({
                isEditCliked: false,
                isDeleteCliked: false,
                SelectedList: null,
                OTName: "",
                SlotTiming: [
                    {
                        slot_name: "",
                        slot_start_time: null,
                        slot_end_time: null,
                        id: 1
                    }
                ]
            })
        }
    }

    convertTimeFormat = (array, keys) => {
        return array.map(obj => {
            const newObj = { ...obj };
            keys.forEach(key => {
                if (newObj[key]) {
                    const [hours, minutes, seconds] = newObj[key].split(':');
                    const date = new Date(1970, 0, 1, hours, minutes, seconds);
                    newObj[key] = date.toISOString();
                }
            });
            return newObj;
        });
    }

    onEditHandler = (value) => {
        const modifiedArray = this.convertTimeFormat(value.row.slot_data, ["slot_start_time", "slot_end_time"]);
        try {
            this.setState({
                isEditCliked: true,
                OTName: value.row.ot_name,
                SelectedList: value.row,
                SlotTiming: modifiedArray
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (value) => {
        try {
            if (!this.state.isEditCliked) {
                this.setState({
                    isDeleteCliked: true,
                    SelectedList: value.row,
                })
            } else {
                this.errorMessage('You clicked edit button.So you cannot able to delete')
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderCorporateLeft = () => {
        try {
            this.state.OTRoomData.forEach((element, index) => { element.sno = index + 1 })
            const { t } = this.props
            const columns = [
                { field: "sno", sortable: false, flex: 0.08, headerName: t("SNo") },
                {
                    field: "ot_name", flex: 0.21, headerName: t("OT Name"),
                    renderCell: (params) => (<Box component={'div'}>{params?.row?.ot_name ? params?.row?.ot_name : "-"}</Box>)
                },
                {
                    field: "no_of_slots", flex: 0.166, headerName: t("No of Slots"), headerAlign: "center", align: "center",
                    renderCell: (params) => (<Box component={'div'}>{(params?.row?.no_of_slots || params?.row?.no_of_slots === 0) ? params?.row?.no_of_slots : "-"}</Box>)
                },
                {
                    field: "action", flex: 0.166, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
                    renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <CommonEditButton size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
                        <CommonDeleteButton size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
                    </Box>
                }
            ]
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_common_Tbl_left' >
                        <DataGrid
                            rows={this.state.OTRoomData}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            hideFooterSelectedRowCount
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            headerHeight={40}
                            components={{
                                Toolbar: CommonGridHeader.CommonToolBar,
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_conf_nodata'>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            disableSelectionOnClick
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            loading={this.state.isLoader}
                        />
                    </Paper>
                </Stack>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AddNewSlotdata(index) {
        let obj = { slot_name: "", slot_start_time: null, slot_end_time: null, id: null }
        if ((this.state.SlotTiming[index].slot_start_time != null && this.state.SlotTiming[index].slot_start_time?.$d !== "Invalid Date") || (this.state.SlotTiming[index]?.slot_end_time != null && this.state.SlotTiming[index].slot_end_time?.$d !== "Invalid Date")) {
            this.state.SlotTiming.push(obj)
            this.setState({ SlotTiming: this.state.SlotTiming })
        } else {
            this.errorMessage("Enter Previous Slot Datas")
        }
    }

    SlotTimingDelete(index, item) {
        let states = this.state
        if (index === 0 && this.state.SlotTiming.length === 1) {
            this.state.SlotTiming = [
                {
                    slot_name: "",
                    slot_start_time: null,
                    slot_end_time: null,
                    id: 1
                }
            ]
        } else {
            this.state.SlotTiming.splice(index, 1)
        }
        states?.DeletedSlotID.push(item?.id)
        this.setState({ SlotTiming: this.state.SlotTiming, states })
    }

    convertTimeFormate = (datetimeString) => {
        const date = new Date(datetimeString);

        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);

        const formattedTime = `${hours}:${minutes}:${seconds}`;

        return formattedTime;
    }

    renderCorporateRight = () => {
        var states = this.state
        try {
            const { t } = this.props
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_common_Tbl_right' elevation={2}>
                        <Typography className='eMed_refdoc_title'>{t("Operation Theater")}</Typography>
                        <div style={{ borderBottom: "1px solid #D3D3D3" }}>
                            <Grid container className='eMed_corpconfig_txt_card' sx={{ height: "15vh" }}>
                                <Grid item xs={10}>
                                    <TextField
                                        inputProps={{ maxLength: 50 }}
                                        className='eMed_small_textFields'
                                        style={{ width: "30vw" }}
                                        size='medium'
                                        label={t("Operation Theater Name") + " *"}
                                        value={this.state.OTName}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            this.setState({
                                                OTName: e.target.value
                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ height: "45vh", overflow: "scroll" }}>
                            <Typography style={{ height: "4vh", color: "#616161", paddingLeft: "1vw", fontWeight: "600", display: "flex", alignItems: "center", marginTop: "0.5vw" }}>{t("Slot Timing")}</Typography>
                            <div>
                                {
                                    this.state.SlotTiming.map((item, index) => (
                                        <Box key={index}>
                                            <div>
                                                <TextField
                                                    inputProps={{ maxLength: 30 }}
                                                    className='eMed_small_textFields'
                                                    style={{ width: "14vw", marginLeft: "1vw" }}
                                                    size='small'
                                                    label={`Slot ${index + 1}`}
                                                    value={states?.SlotTiming[index].slot_name}
                                                    onChange={(e) => {
                                                        states.SlotTiming[index].slot_name = e.target.value
                                                        this.setState({
                                                            states
                                                        })
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <Box component={'div'} display={'flex'} alignItems={'center'} margin={'0.65vw 1vw'}>
                                                    <Box>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                label='Start Time'
                                                                value={states?.SlotTiming[index].slot_start_time ? states?.SlotTiming[index].slot_start_time : null}
                                                                onChange={(newStartTime) => {
                                                                    const updatedStates = [...states.SlotTiming];
                                                                    updatedStates[index].slot_start_time = newStartTime;
                                                                    this.setState({ states: { ...states, SlotTiming: updatedStates } });
                                                                }}
                                                                renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)" }} style={{ width: "14vw" }} size="small" />}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                label='End Time'
                                                                value={states?.SlotTiming[index].slot_end_time ? states?.SlotTiming[index].slot_end_time : null}
                                                                onChange={(newEndTime) => {
                                                                    const updatedStates = [...states.SlotTiming];
                                                                    updatedStates[index].slot_end_time = newEndTime;
                                                                    this.setState({ states: { ...states, SlotTiming: updatedStates } });
                                                                }}
                                                                renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)" }} style={{ width: "14vw", marginLeft: "0.9vw" }} size="small" />}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box marginLeft={"0.65vw"}><CommonDeleteButton size={"1.5vw"} onClick={this.SlotTimingDelete.bind(this, index, item)} /></Box>
                                                </Box>
                                            </div>
                                            {
                                                index === states.SlotTiming.length - 1 &&
                                                <Button sx={{ marginTop: '0.5vw', marginLeft: "0.25vw" }} className='eMed_usrconf_btn' onClick={() => { this.AddNewSlotdata(index) }}>
                                                    <img src={ImagePaths.AddIcon.default} alt="add" className='eMed_action_img' />
                                                </Button>
                                            }
                                        </Box>
                                    ))
                                }
                            </div>
                        </div>
                        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"} marginTop={"1.2vw"}>
                            <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                            <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.validation() }}>{t("AddBlock")}</Button>
                        </Stack>
                    </Paper>
                </Stack>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        let { t } = this.props
        try {
            return (
                <Box component={"div"} className='eMed_common_container'>
                    <Grid container className='eMed_refdoc_content' >
                        <Grid item xs={7.5}>
                            {this.renderCorporateLeft()}
                        </Grid>
                        <Grid item xs={4.5}>
                            {this.renderCorporateRight()}
                        </Grid>
                    </Grid>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.isDeleteCliked ? <DeletePopup
                        DeletTitle={`${t("deleteMsg")} Operation Theatre ${this.state.SelectedList ? this.state.SelectedList?.ot_name : "-"} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DelectBlocks.bind(this)}
                        DeleteNotify={t("effectEntireSetting")}
                    /> : null}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
}
export default withTranslation()(OTConfiguration);
import React, { Component } from 'react'

export default class ShowComponents extends Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.intersectionObserver = null;
    }
    componentDidMount() {
        this.intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.props.onIntersection();
                        this.disconnectObserver();
                    }
                });
            },
            { threshold: 0.25 }
        );
        if (this.componentRef.current) {
            this.intersectionObserver.observe(this.componentRef.current);
          }
    }

    componentWillUnmount() {
        this.disconnectObserver();
    }

    disconnectObserver() {
        if (this.intersectionObserver && this.componentRef.current) {
            this.intersectionObserver.unobserve(this.componentRef.current);
        }
    }

    render() {
        return <div ref={this.componentRef}>{this.props.children}</div>;
    }
}
import React, { Component } from "react";
import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from "../../../../Styles/Colors";
import ShowComponents from "./ShowComponent";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { TwentyDays } from "../../../../Utility/Constants";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatDate } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 1);

export default class DocReviewPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.patientId,
            clinicId: this.props.clinicId,
            appointmentId: this.props.appointmentId,
            PatientDetails: "",
            openPopUp: false,
            loading: false,
            postLoading: false,
            isLocked: false,
            ReviewData: [],
            selectedDays: '1',
            selectedDuration: '',
            durationDays: TwentyDays,
            durationWeeks: TwentyDays,
            durationMonts: TwentyDays,
            durationYears: TwentyDays,
            updateId: "",
            AppointmentTypeList: [],
            selectedAppointTypeId: null,
            templateData: [],
            isDisabled: false,
            NewTemplate: "",
            appointmentDateTime: currentDate,
            selectedTemp: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    apiCalls = () => {
        this.getReviewPlan()
        this.getPatientDetailData()
        this.getAppointTypeList()
        this.getTemplateData()
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            PatientDetails: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAppointTypeList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentTypeList: response.data.data.filter((item) => (item.is_active === true)),
                        }, () => {
                            this.setState({
                                selectedAppointTypeId: response.data.data.find((item) => (item.appoinment_type === "Walk in Appointment"))
                            })
                        });
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getReviewPlan = () => {
        let states = this.state
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_OPT_REVIEW_PALN + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        let Data = [];
                        if (response.data.data?.review_plan) {
                            Data.push(response.data.data?.review_plan)
                        }
                        this.setState({
                            ReviewData: Data,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getTemplateData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_REVIEW_PLAN_COMMENTS_TEMPLATE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            templateData: response.data.data,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getTreatmentTemplateselect = (id) => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_REVIEW_PLAN_COMMENTS_TEMPLATE + `?template_id=${id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            comments: response.data.data.comments
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        if (this.state.Complaints != "" && this.state.eyeType != null) {
            return null
        } else {
            if (this.state.Complaints == "") {
                return "Enter Complaints"
            } else {
                return "Select Eye Type "
            }
        }
    }

    CreateReviewPlan = (has_followup_appointment) => {
        let states = this.state

        var AptDateTime = new Date(states.appointmentDateTime)
        var AptDate = DateTime.fromJSDate(AptDateTime).toFormat('yyyy-MM-dd')
        var Aptime = DateTime.fromJSDate(AptDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        try {
            let data = {
                "appointment_id": states.appointmentId,
                "duration_type": has_followup_appointment ? null : this.renderDurationType(states.selectedDays),
                "duration": has_followup_appointment ? null : states.selectedDuration,
                "review_date": has_followup_appointment ? null : AptDate,
                "appointment_time": has_followup_appointment ? null : Aptime,
                "comments": states.comments,
            };
            if (states.updateId) {
                data["id"] = states.updateId
            }
            let validate = null
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_OPT_REVIEW_PALN)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getReviewPlan()
                            this.setState({ openPopUp: false, postLoading: false })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    checkNewTempValidation = () => {
        if ((this.state.NewTemplate != "" || this.state?.selectedTemp?.template_name != null) && this.state.comments != "") {
            return null
        } else {
            if (this.state.NewTemplate == "" || this.state?.selectedTemp?.template_name == null) {
                return "Enter Template Name"
            } else {
                return "Entre Comments "
            }
        }
    }

    postNewTemplate = () => {
        let states = this.state
        try {
            let data = {
                "template_name": states.NewTemplate ? states?.NewTemplate : states?.selectedTemp ? states?.selectedTemp?.template_name : "",
                "comments": states.comments
            }
            let validate = this.checkNewTempValidation()
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_REVIEW_PLAN_COMMENTS_TEMPLATE)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.setState({ postLoading: false }, () => {
                                this.getTemplateData()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    renderChip = (values, key) => {
        let states = this.state
        return (
            <div>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: "8vw" }}
                                label={item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (item.label === key) {
                                        states.eyeType = ""
                                        this.setState({ states })
                                    } else {
                                        states.eyeType = item?.value
                                        this.setState({ states })
                                    }
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    onClearHandler = () => {
        this.setState({
            comments: "",
            selectedDays: '1',
            selectedDuration: '',
            openPopUp: false,
            isDisabled: false,
            NewTemplate: "",
            updateId: "",
            appointmentDateTime: currentDate,
            selectedTemp: null
        })
    }

    handleChipClick = (Number, Duration) => {
        const newDate = this.calculateNewDate(+Number, Duration);
        this.setState({ appointmentDateTime: newDate });
    };

    calculateNewDate = (number, duration) => {
        const NewDate = new Date();

        switch (duration) {
            case 'days':
                NewDate.setDate(NewDate.getDate() + number);
                break;
            case 'weeks':
                NewDate.setDate(NewDate.getDate() + 7 * number);
                break;
            case 'months':
                NewDate.setMonth(NewDate.getMonth() + number);
                break;
            case 'years':
                NewDate.setFullYear(NewDate.getFullYear() + number);
                break;
            default:
                break;
        }

        return NewDate;
    };


    renderDateTimeFilter = () => {
        try {
            return (
                <Box sx={{ mt: '1vw' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            open={this.state.DateOpen}
                            onOpen={() => { this.setState({ DateOpen: true }) }}
                            onClose={() => { this.setState({ DateOpen: false }) }}
                            inputFormat='dd-MM-yyyy & hh:mm a'
                            value={this.state.appointmentDateTime}
                            minDate={currentDate}
                            onChange={(newDate) => {
                                if (newDate === null) {
                                    this.setState({ appointmentDateTime: currentDate })
                                }
                                else {
                                    this.setState({ appointmentDateTime: newDate })
                                }
                            }}
                            renderInput={(params) => <TextField size='small'
                                onKeyDown={(e) => e.preventDefault()}
                                onClick={() => { this.setState({ DateOpen: true }) }}
                                {...params}
                                fullWidth
                                variant='outlined'
                                label="Appointment Date & Time *"
                                style={{ width: "15vw", marginRight: "0.5vw", marginLeft: "0.5vw" }}
                            />}
                        />
                    </LocalizationProvider>
                </Box>
            )
        } catch (e) {

        }
    }

    renderPopUpScreen = () => {
        let states = this.state
        let PatientAddress = `${(!this.state.PatientDetails?.address_line_1 || this.state.PatientDetails?.address_line_1 === "") ? "-" : this.state.PatientDetails?.address_line_1 + ","} ${(!this.state.PatientDetails?.address_line_2 || this.state.PatientDetails?.address_line_2 === "") ? "-" : this.state.PatientDetails?.address_line_2 + ","} ${(!this.state.PatientDetails?.city_name || this.state.PatientDetails?.city_name === "") ? "-" : this.state.PatientDetails?.city_name + ","} ${(!this.state.PatientDetails?.state_name || this.state.PatientDetails?.state_name === "") ? "-" : this.state.PatientDetails?.state_name + ","} ${!this.state.PatientDetails?.country || this.state.PatientDetails?.country === "" ? "-" : this.state.PatientDetails?.country + ","} ${!this.state.PatientDetails?.pincode || this.state.PatientDetails?.pincode === "" ? "-" : this.state.PatientDetails?.pincode + "."}`
        try {
            return (
                <Box>
                    <Modal open={this.state.openPopUp}>
                        <Box component={'div'} className='eMed_Doc_Notes_Pop_Up' style={{ width: "35vw", height: "45vw" }}>
                            <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Review Plan</Typography>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false }, () => { this.onClearHandler() })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} style={{ padding: "1.25vw" }}>
                                <div>
                                    <CommonPatientDetails icon={true} data={this.state.PatientDetails} showAdmission={false} />
                                    <Box component={"div"} className='eMed_NxtAppPop_TextDiv' sx={{ marginTop: '1.5vw' }} >
                                        <Box component={"div"} className="eMed_details_label">
                                            <img src={ImagePaths.LabDateImage.default} alt="close" className='eMed_nxtAppoint_img_small' />
                                            <Typography fontSize={"1vw"} marginLeft={"1vw"}>{this.state.PatientDetails?.dob ? formatDate(this.state.PatientDetails?.dob) : this.state.PatientDetails?.approx_dob ? formatDate(this.state.PatientDetails?.approx_dob) : "-"}</Typography>
                                        </Box>
                                        <Box component={"div"} className="eMed_details_label">
                                            <img src={ImagePaths.DisabledHome.default} alt="close" className='eMed_nxtAppoint_img_small' />
                                            {PatientAddress.length > 70 ?
                                                <Tooltip placement='top' title={PatientAddress}>
                                                    <Typography fontSize={"0.9vw"} marginLeft={"1vw"}>{PatientAddress.slice(0, 70) + "..."}</Typography>
                                                </Tooltip> :
                                                <Typography fontSize={"0.9vw"} marginLeft={"1vw"}>{PatientAddress}</Typography>
                                            }
                                        </Box>
                                    </Box>
                                </div>
                                <div><hr /></div>
                                <Box component={'div'} flex={0.5} pl={'0.5vw'}>
                                    <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration</Typography>
                                        <FormControl size='small' style={{ marginTop: "0.5vw", marginRight: "1.2vw" }}>
                                            <InputLabel>Select Duration</InputLabel>
                                            <Select
                                                sx={{ width: '10vw' }}
                                                label='Select Duration'
                                                value={this.state.selectedDays}
                                                disabled={this.state.isDisabled}
                                                onChange={(e) => this.setState({ selectedDays: e.target.value, selectedDuration: '' })}
                                            >
                                                <MenuItem value={'1'}>Days</MenuItem>
                                                <MenuItem value={'2'}>Weeks</MenuItem>
                                                <MenuItem value={'3'}>Months</MenuItem>
                                                <MenuItem value={'4'}>Years</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box component={'div'} mt={'0.5vw'}>
                                        {this.state.selectedDays === '1' ?
                                            this.state.durationDays?.map((item, index) => (
                                                <Chip
                                                    className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                    key={index}
                                                    disabled={this.state.isDisabled}
                                                    onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "days") }) }}
                                                    clickable
                                                />
                                            )) : this.state.selectedDays === '2' ?
                                                this.state.durationWeeks?.map((item, index) => (
                                                    <Chip
                                                        className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        disabled={this.state.isDisabled}
                                                        variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "weeks") }) }}
                                                        clickable
                                                    />
                                                )) : this.state.selectedDays === '3' ?
                                                    this.state.durationMonts?.map((item, index) => (
                                                        <Chip
                                                            className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                            label={item.label}
                                                            variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                            key={index}
                                                            disabled={this.state.isDisabled}
                                                            onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "months") }) }}
                                                            clickable
                                                        />
                                                    )) : this.state.durationYears?.map((item, index) => (
                                                        <Chip
                                                            className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                            label={item.label}
                                                            variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                            key={index}
                                                            disabled={this.state.isDisabled}
                                                            onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "years") }) }}
                                                            clickable
                                                        />
                                                    ))
                                        }
                                    </Box>
                                </Box>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    {this.renderDateTimeFilter()}
                                    <Autocomplete
                                        size='small'
                                        clearIcon={false}
                                        sx={{ width: '16vw', marginTop: "1vw" }}
                                        options={this.state.AppointmentTypeList}
                                        disabled
                                        getOptionLabel={(item) => (item.appoinment_type)}
                                        value={this.state.selectedAppointTypeId}
                                        onChange={(event, newValue) => {
                                            states["selectedAppointTypeId"] = newValue
                                            this.setState({ states })
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={"Appointment Type"}
                                                placeholder={"Appointment Type"}
                                            />
                                        )}
                                    />
                                </div>
                                <Box component={'div'} p={'0.5vw'}>
                                    <div style={{ fontWeight: 600 }}>Comments</div>
                                    <div style={{ marginLeft: "1vw", marginTop: "0.65vw" }}>
                                        <Autocomplete
                                            clearIcon
                                            freeSolo
                                            size='small'
                                            sx={{ width: '95%' }}
                                            placeholder="Select Template"
                                            options={this.state.templateData}
                                            getOptionLabel={(options) => options.template_name}
                                            value={this.state?.selectedTemp}
                                            onChange={(e, newValue) => {
                                                states["selectedTemp"] = newValue
                                                this.setState({
                                                    states
                                                }, () => { if (newValue) { this.getTreatmentTemplateselect(newValue?.id) } })
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params} placeholder="Select Template" sx={{ backgroundColor: 'white' }}
                                                onChange={(e) => {
                                                    this.setState({
                                                        NewTemplate: e.target.value
                                                    })
                                                }}
                                            />}
                                        />
                                    </div>
                                    <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
                                        <TextField
                                            style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                                            multiline={true}
                                            rows={4}
                                            variant="outlined"
                                            value={this.state.comments}
                                            onChange={(event) => {
                                                let value = event.target.value
                                                if (value?.length <= 350) {
                                                    this.setState({ comments: value })
                                                } else {
                                                    this.errorMessage('Allowed only 350 characters')
                                                }
                                            }}
                                        />
                                    </div>
                                </Box>
                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'2vw'}>
                                    <Button
                                        sx={{ height: '2vw', width: '8.5vw', textTransform: 'capitalize', mr: '1vw' }}
                                        variant='contained'
                                        disabled={this.state.postLoading}
                                        onClick={() => this.postNewTemplate()}
                                    >Save Template</Button>
                                    <Button
                                        disabled={this.state.postLoading}
                                        sx={{ height: '2vw', width: '8.5vw', textTransform: 'capitalize' }}
                                        variant='contained'
                                        onClick={() => this.CreateReviewPlan(this.state.isDisabled ? true : false)}
                                    >Add</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderDurationType = (type) => {
        switch (type) {
            case "1": return "Days"
            case "2": return "Weeks"
            case "3": return "Months"
            case "4": return "Years"
            case "Days": return "1"
            case "Weeks": return "2"
            case "Months": return "3"
            case "Years": return "4"
            default: return type
        }
    }

    onEditHandler = (item) => {
        this.setState({
            updateId: item?.id,
            selectedDays: this.renderDurationType(item?.duration_type),
            selectedDuration: item?.duration,
            comments: item?.comments,
            appointmentDateTime: new Date(`${item?.review_date} ${item?.appointment_time}`),
            isDisabled: true
        })
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'3vw'} display={'flex'} p={'0.5vw'} justifyContent={'space-between'} alignItems={'center'} >
                        <Box component={'div'}>
                            <Typography fontWeight={600}>Review Plan</Typography>
                            {/* <Typography fontSize={'0.8vw'} color={'gray'}>Review Plan added By
                                <Typography component={'span'} fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}> Hospital Nurse</Typography>
                            </Typography> */}
                        </Box>
                        <Box component={'div'}>
                            <Button
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: "capitalize" }}
                                onClick={() => { this.setState({ openPopUp: true }) }}
                                disabled={this.state.isLocked || this.props.isLocked}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'}>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '20vw', overflow: 'auto' }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Duration</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Review Date & Time</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Comments</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Action</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.ReviewData?.length > 0 ? this.state.ReviewData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ fontSize: "0.9vw" }}>{item?.duration ? `${item?.duration} ${item?.duration_type}` : '-'}</TableCell>
                                                <TableCell>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                                        <div style={{ fontSize: "0.9vw" }}>{item?.review_date ? item?.review_date : "-"}</div>
                                                        <div style={{ fontSize: "0.9vw" }}>{item?.appointment_time ? item?.appointment_time : "-"}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {item?.comments ? item?.comments?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.comments}>
                                                            <Typography fontSize={'0.9vw'}>{item?.comments?.slice(0, 15) + '...'}</Typography>
                                                        </Tooltip> :
                                                        <Typography fontSize={'0.9vw'}>{item?.comments}</Typography> : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                        <Button className='Common_Btn_Min_Width'
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                            onClick={() => { this.setState({ openPopUp: true }, () => { this.onEditHandler(item) }) }}
                                                        >
                                                            <Tooltip placement='top' title={'Edit'}>
                                                                {this.state.isLocked || this.props.isLocked ?
                                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                                    :
                                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                                }
                                                            </Tooltip>
                                                        </Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell />
                                            <TableCell align='center'>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    {this.renderPopUpScreen()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }
}
import { Box, Button, Drawer, Stack, Tooltip, Typography, ButtonGroup } from '@mui/material';
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { withTranslation } from 'react-i18next';
import { AmountFormat } from "../../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from '../../../../Utility/Constants';

export class DrugWiseStockValue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stockValueData: [],
            page: 0,
            pageSize: 10,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                'dsv_drugName': '',
                'dsv_genericName': '',
                'dsv_dosageType': '',
                'dsv_groupName': null,
                "days": 30,
                "dosageType": [],
            },
            pageName: 'drugWiseStockValue',
            filterOpen: false,
            purchaseValue: 0,
            salesValue: 0,
            mode_type: "drug"
        }
    }

    componentDidMount() {
        this.getDrugWiseStockValueData()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getDrugWiseStockValueData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    getDrugWiseStockValueData = () => {
        try {
            this.LoaderFunction(true)
            let brandName = this.state.filterDatas.dsv_drugName?.replaceAll('&', '%26')
            let group = this.state.filterDatas?.dsv_groupName?.group ? this.state.filterDatas?.dsv_groupName?.group : ''
            RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?from_date=${this.state.filterDatas?.fromDate}&to_date=${this.state.filterDatas?.toDate}&drug_name=${brandName}&generic_name=${this.state.filterDatas?.dsv_genericName}&dosage_type=${this.state.filterDatas?.dosageType}&group_name=${group}&mode=drug&profit=${this.state.mode_type==="profit"}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            stockValueData: response.data.data,
                            purchaseValue: response.data?.total_purchase_price,
                            salesValue: response.data?.total_mrp_value
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getStockValueExcel = () => {
        try {
            this.setState({ disableBtn: true })
            let brandName = this.state.filterDatas.dsv_drugName?.replaceAll('&', '%26')
            let group = this.state.filterDatas?.dsv_groupName?.group ? this.state.filterDatas?.dsv_groupName?.group : ''
            RestAPIService.excelGet(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?from_date=${this.state.filterDatas?.fromDate}&to_date=${this.state.filterDatas?.toDate}&drug_name=${brandName}&generic_name=${this.state.filterDatas?.dsv_genericName}&dosage_type=${this.state.filterDatas?.dosageType}&group_name=${group}&mode=drug&profit=${this.state.mode_type==="profit"}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"]?.split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({ disableBtn: false })
        }
    }


    StockValuePrint = () => {
        this.setState({ disabled: true })
        try {
            let brandName = this.state.filterDatas.dsv_drugName?.replaceAll('&', '%26')
            let group = this.state.filterDatas?.dsv_groupName?.group ? this.state.filterDatas?.dsv_groupName?.group : ''
            RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?from_date=${this.state.filterDatas?.fromDate}&to_date=${this.state.filterDatas?.toDate}&drug_name=${brandName}&generic_name=${this.state.filterDatas?.dsv_genericName}&dosage_type=${this.state.filterDatas?.dosageType}&group_name=${group}&mode=drug&profit=${this.state.mode_type==="profit"}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disabled: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disabled: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disabled: false })
        }
    }

    checkFilterValidation = () => {
        try {
            let message = null;
            let states = this.state

            if (states.filterDatas?.toDate < states.filterDatas?.fromDate) {
                message = 'To Date Should Be Greater Than From Date'
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        const { t } = this.props
        this.state.stockValueData.forEach((element, index) => element.sno = index + 1)

        const columns = [
            {
                field: 'brand_name', headerName: "Drug Details", flex: 0.155,
                renderCell: (params) => {
                    let dosageTypeAndStr = `${params?.row?.dosagetype ? params?.row?.dosagetype : '-'} | ${params?.row?.drug_strength ? params?.row?.drug_strength : '-'}`
                    return (
                        <Box component={'div'}>
                            {params?.row?.brand_name ? params?.row?.brand_name?.length > 40 ?
                                <Tooltip placement='top' title={params?.row?.brand_name}><Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.brand_name?.slice(0, 40) + '...'}</Typography></Tooltip>
                                : <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.brand_name ? params?.row?.brand_name : '-'}</Typography> : '-'}
                            {dosageTypeAndStr ? dosageTypeAndStr?.length > 40 ?
                                <Tooltip placement='top' title={dosageTypeAndStr}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{dosageTypeAndStr?.slice(0, 40) + '...'}</Typography></Tooltip>
                                : <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{dosageTypeAndStr ? dosageTypeAndStr : '-'}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            // {
            //     field: 'generic_name', headerName: 'Generic Name', flex: 0.125,
            //     renderCell: (params) => (
            //         <Box>{params?.row?.generic_name ? params?.row?.generic_name?.length > 18 ?
            //             <Tooltip placement='top' title={params?.row?.generic_name}><Typography>{params?.row?.generic_name?.slice(0, 18) + '...'}</Typography></Tooltip> :
            //             <Typography>{params?.row?.generic_name}</Typography> : "-"}</Box>
            //     )
            // },
            {
                field: 'group_name', headerName: 'Group Name', flex: 0.125,
                renderCell: (params) => (
                    <Box>{params?.row?.group_name ? params?.row?.group_name?.length > 18 ?
                        <Tooltip placement='top' title={params?.row?.group_name}><Typography>{params?.row?.group_name?.slice(0, 18) + '...'}</Typography></Tooltip> :
                        <Typography>{params?.row?.group_name}</Typography> : "-"}</Box>
                )
            },
            {
                field: 'closing_qty', headerName: 'Stock', flex: 0.125, type: 'number',
                renderCell: (params) => (
                    <Box>{params?.row?.closing_qty ? params?.row?.closing_qty?.length > 18 ?
                        <Tooltip placement='top' title={params?.row?.closing_qty}><Typography>{params?.row?.closing_qty?.slice(0, 18) + '...'}</Typography></Tooltip> :
                        <Typography>{params?.row?.closing_qty}</Typography> : 0}</Box>
                )
            },
            {
                field: 'total_purchase_value', headerName: 'Total Purchase Value', flex: 0.125, type: 'number',
                renderCell: (params) => (<Typography>{params?.row?.total_purchase_value ? AmountFormat(params?.row?.total_purchase_value).replace(`${CurrencySymbol}`, "") : 0}</Typography>)
            },
            {
                field: 'total_mrp_price', headerName: 'Total MRP Value', flex: 0.125, type: 'number',
                renderCell: (params) => (<Typography>{params?.row?.total_mrp_price ? AmountFormat(params?.row?.total_mrp_price).replace(`${CurrencySymbol}`, "") : 0}</Typography>)
            },
        ]

        if (this.state.mode_type === "profit") {
            columns.push(
                {
                    field: 'profit_value', headerName: t("Profit Margin Value"), flex: 0.125, type: "number", density: 'compact',
                    renderCell: (params) => (<Box>{params?.row?.profit_value ? AmountFormat(params?.row?.profit_value).replace(`${CurrencySymbol}`, "") : '0'}</Box>)
                },
                {
                    field: 'profit_percentage', headerName: t("Profit Margin (%)"), flex: 0.125, type: "number", density: 'compact',
                    renderCell: (params) => (<Box>{(params.row?.profit_percentage) ? params.row?.profit_percentage : '0'}</Box>)
                },
            )
        }

        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard('Total Purchase Value', this.state.purchaseValue, this.state.purchase, "white", false, null, 'rpt_drug_stkVlu_sal_value')}
                        {AmountsCard('Total MRP Value', this.state.salesValue, this.state.purchase, "white", false, null, 'rpt_drug_stkVlu_pur_value')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Box component={'div'} display={'flex'}>
                            <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.5vw'}>
                                <Tooltip placement='top' title='Expiry Drug And Inactive Drugs Are Not Included' arrow>
                                    <Box><ErrorOutlineIcon color='primary' /></Box>
                                </Tooltip>
                            </Box>
                        </Box>
                        <ButtonGroup sx={{ marginRight: '1vw' }}>
                            <Button variant={this.state.mode_type === "drug" ? 'contained' : 'outlined'} sx={{ width: "8.5vw" }} onClick={() => { this.setState({ mode_type: "drug" },()=>{this.getDrugWiseStockValueData()}) }}>{t("Drug Wise")}</Button>
                            <Button variant={this.state.mode_type === "profit" ? 'contained' : 'outlined'} sx={{ width: "8.5vw" }} onClick={() => { this.setState({ mode_type: "profit" },()=>{this.getDrugWiseStockValueData()}) }}>{t("Profit Wise")}</Button>
                        </ButtonGroup>
                        <Button emed_tid="rpt_drug_stkVlu_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button className='eMed_Pharma_rts_btn' disabled={this.state.disabled}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} onClick={() => this.StockValuePrint()} />
                        </Button>
                        <Button emed_tid="rpt_drug_stkVlu_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getStockValueExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.stockValueData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.stockValueData?.length}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(DrugWiseStockValue);
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DataGrid } from '@mui/x-data-grid';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, Drawer, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Colors } from '../../../Styles/Colors';
import Loader from '../../../Components/Loader';

class BedAvailable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            BedList: [],
            isLoding: false,
            wardType: null,
            BlockList: [],
            blockType: null,
            FloorList: [],
            floorType: null,
            roomList: [],
            roomType: null,
            bedData: [],
            bedType: null,
            BedList_filter: [],
            BlockList_view: [],
            FloorList_View: [],
            roomList_View: [],
            bedData_View: [],
            dupBedListData: '[]'
        }
    }
    componentDidMount() {
        this.GetBedList()
    }
    GetBedList = () => {
        try {
            this.setState({
                isLoding: true
            })
            RestAPIService.getAll(Serviceurls.IP_BED_AVAILABLE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BedList: this.RemoveDuplicates(response.data.data, 'ward_id'),
                            BedList_dup: response.data.data,
                            dupBedListData: JSON.stringify(response.data.data),
                            isLoding: false
                        })
                    }
                    else {
                        this.setState({
                            isLoding: false
                        })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({
                        isLoding: false
                    })
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            this.setState({
                isLoding: false
            })
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    closeFilter() {
        this.setState({
            FilterOpen: false
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    RemoveDuplicates(Array, key) {
        const FilteredArray = Array.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item[key] === current[key])) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        return FilteredArray
    }

    renderFilterScreen = () => {
        const { t } = this.props
        let wardData = []
        wardData = this.state.BedList_dup
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            options={wardData}
                            clearIcon
                            getOptionLabel={(option) => (option?.block)}
                            value={this.state?.wardType}
                            onChange={(event, newValue) => {
                                this.setState({
                                    wardType: newValue,
                                    blockType: null,
                                    floorType: null,
                                    roomType: null,
                                    FloorList: [],
                                    roomList: [],
                                }, () => {
                                    var states = this.state
                                    if (this.state.wardType) {
                                        // let block = this.RemoveDuplicates(states.filterDataDup.filter((item) => item.ward_id === states?.wardType?.ward_id), 'block_no_id')
                                        // let block_view = states.filterDataDup.filter((item) => item.ward_id === states?.wardType?.ward_id)
                                        this.setState({
                                            BlockList: states.wardType?.block_data,
                                            BlockList_view: states.wardType?.block
                                        })
                                    } else {
                                        this.setState({
                                            BlockList: [],
                                            BlockList_view: null,
                                            BedList_dup:JSON.parse(this.state.dupBedListData)
                                        })
                                    }
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Block')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            disabled={this.state.BlockList?.length === 0}
                            options={this.state?.BlockList}
                            getOptionLabel={(option) => (option?.ward_name)}
                            value={this.state.BlockList?.length > 0 ? this.state?.blockType : null}
                            onChange={(event, newValue) => {
                                this.setState({
                                    blockType: newValue,
                                    floorType: null,
                                    roomType: null,
                                    roomList: [],
                                }, () => {
                                    var states = this.state
                                    if (this.state.blockType) {
                                        // let floor = this.RemoveDuplicates(states.filterDataDup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType?.block_no_id)), 'floor')
                                        // let floor_view = states.filterDataDup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType?.block_no_id))
                                        this.setState({
                                            FloorList: states.blockType?.ward_data,
                                            FloorList_View: states.blockType?.ward_name
                                        })
                                    } else {
                                        this.setState({
                                            FloorList: [],
                                            FloorList_View: null,
                                            BedList_dup:JSON.parse(this.state.dupBedListData)
                                        })
                                    }
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Ward')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            clearIcon
                            size='small'
                            disabled={this.state.FloorList?.length === 0}
                            options={this.state?.FloorList}
                            getOptionLabel={(option) => (option?.floor)}
                            value={this.state.FloorList?.length > 0 ? this.state?.floorType : null}
                            onChange={(event, newValue) => {
                                this.setState({
                                    floorType: newValue,
                                    roomType: null,
                                }, () => {
                                    var states = this.state
                                    if (this.state.floorType) {
                                        // let room = this.RemoveDuplicates(states.filterDataDup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor)), 'room_number')
                                        // let room_View = states.filterDataDup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor))
                                        this.setState({
                                            roomList: states.floorType?.floor_data,
                                            roomList_View: states.floorType?.floor
                                        })
                                    } else {
                                        this.setState({
                                            roomList: [],
                                            roomList_View: null,
                                            BedList_dup:JSON.parse(this.state.dupBedListData)
                                        })
                                    }
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Floor')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            clearIcon
                            size='small'
                            disabled={this.state.roomList?.length === 0}
                            options={this.state.roomList?.length > 0 ? this.state?.roomList : null}
                            getOptionLabel={(option) => (option?.bed_number)}
                            value={this.state?.roomType}
                            onChange={(event, newValue) => {
                                this.setState({
                                    roomType: newValue
                                }, () => {
                                    var states = this.state
                                    if (this.state.roomType) {
                                        // let bedItem = this.RemoveDuplicates(states.filterDataDup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor && item.room_number === states.roomType?.room_number)), 'bed_id')
                                        // let bedItem_view = states.filterDataDup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType.block_no_id && item.floor === states?.floorType?.floor && item.room_number === states.roomType?.room_number))
                                        this.setState({
                                            bedData: states.roomType,
                                            bedData_View: states.roomType?.bed_number
                                        })
                                    } else {
                                        this.setState({
                                            bedData: [],
                                            bedData_View: null,
                                            BedList_dup:JSON.parse(this.state.dupBedListData)
                                        })
                                    }
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Bed')}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                BlockList: [],
                                FloorList: [],
                                roomList: [],
                                wardType: null,
                                blockType: null,
                                floorType: null,
                                roomType: null,
                                FilterOpen: false,
                                BedList_filter: [],
                                bedData: [],
                                BlockList_view: [],
                                FloorList_View: [],
                                roomList_View: [],
                                bedData_View: [],
                            }, () => {
                                this.GetBedList()
                                this.setState({
                                    BedList: this.state.BedList_dup
                                })
                            })
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        // onClick={() => {
                        //     if (this.state.wardType !== null &&
                        //         this.state.blockType === null &&
                        //         this.state.floorType === null &&
                        //         this.state.roomType === null) {
                        //         this.setState({
                        //             BedList_filter: this.state.BlockList_view
                        //         })
                        //     } else if (this.state.blockType !== null &&
                        //         this.state.floorType === null &&
                        //         this.state.roomType === null) {
                        //         this.setState({
                        //             BedList_filter: this.state.FloorList_View
                        //         })
                        //     } else if (this.state.floorType !== null &&
                        //         this.state.roomType === null) {
                        //         this.setState({
                        //             BedList_filter: this.state.roomList_View
                        //         })
                        //     } else if (this.state.roomType !== null) {
                        //         this.setState({
                        //             BedList_filter: this.state.bedData_View
                        //         })
                        //     } else {
                        //         this.setState({
                        //             BedList_filter: this.state.BedList_dup
                        //         })
                        //     }
                        //     this.setState({
                        //         FilterOpen: false
                        //     })
                        // }}
                        onClick={() => {
                            let dupBedListData = JSON.parse(this.state.dupBedListData)
                            if (this.state.wardType !== null &&
                                this.state.blockType === null &&
                                this.state.floorType === null &&
                                this.state.roomType === null) {
                                let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_view))

                                this.setState({ BedList_dup: [data] })
                            } else if (this.state.blockType !== null &&
                                this.state.floorType === null &&
                                this.state.roomType === null) {
                                let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_view))
                                let blockData = data?.block_data?.find(item => item?.ward_name === this.state.FloorList_View)
                                data.block_data = [blockData]

                                this.setState({ BedList_dup: [data] })
                            } else if (this.state.floorType !== null &&
                                this.state.roomType === null) {
                                let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_view))
                                let blockData = data?.block_data?.find(item => item?.ward_name === this.state.FloorList_View)
                                let wardData = blockData?.ward_data?.find(item => item?.floor === this.state.roomList_View)

                                blockData.ward_data = [wardData]
                                data.block_data = [blockData]

                                this.setState({ BedList_dup: [data] })
                            } else if (this.state.roomType !== null) {
                                let data = dupBedListData?.find(element => (element?.block === this.state.BlockList_view))
                                let blockData = data?.block_data?.find(item => item?.ward_name === this.state.FloorList_View)
                                let wardData = blockData?.ward_data?.find(item => item?.floor === this.state.roomList_View)
                                let roomData = wardData?.floor_data?.find(item => item?.bed_number === this.state.bedData_View)

                                wardData.floor_data = [roomData]
                                blockData.ward_data = [wardData]
                                data.block_data = [blockData]

                                this.setState({ BedList_dup: [data] })
                            } else {
                                this.setState({ BedList_dup: dupBedListData })
                            }
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderRoomData = () => {
        var states = this.state
        // let ViewData = states?.filterDataDup?.length > 0 ? states.filterDataDup : states?.BedList_dup?.length > 0 ? states?.BedList_dup : []
        return (
            <Box id=''>
                {states.isLoding ?
                    <Box id='eMed_Bed_Circle'>
                        <CircularProgress color='primary' />
                    </Box> :
                    // : ViewData?.length > 0 ? ViewData?.map((list, index) => {
                    //     return (
                    //         <Card id='eMed_Bed_CardView' sx={{ border: '0.2vw solid', borderColor: list.is_available ? '#00b300' : 'white', backgroundColor: list.is_available ? '#e6ffe6' : 'white' }}>
                    //             <CardContent sx={{ width: '8vw' }}>
                    //                 <Box style={{ display: 'flex', flexDirection: 'row', width: '7vw' }}>
                    //                     {list.room_number.length > 3 ?
                    //                         <Tooltip title={list.room_number} placement='top'>
                    //                             <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                    //                                 {list.room_number ? '..' + list.room_number.slice(-3) + ' / ' : '-'}
                    //                             </Typography>
                    //                         </Tooltip> : <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                    //                             {`${list.room_number ? list.room_number + ' / ' : '-'}`}
                    //                         </Typography>}
                    //                     <Box sx={{ marginLeft: '0.2vw' }}>
                    //                         {list.bed_number.length > 3 ?
                    //                             <Tooltip title={list.bed_number} placement='top'>
                    //                                 <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                    //                                     {list.bed_number ? '..' + list.bed_number.slice(-3) : '-'}
                    //                                 </Typography>
                    //                             </Tooltip> : <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                    //                                 {`${list.bed_number ? list.bed_number : '-'}`}
                    //                             </Typography>}
                    //                     </Box>
                    //                 </Box>
                    //                 <Box sx={{ display: 'flex', flexDirection: 'row', width: '7.3vw' }}>
                    //                     <Typography sx={{ fontSize: '0.8vw' }}>
                    //                         {"Room / Bed No"}
                    //                     </Typography>
                    //                     {list.ward_type === "NON AC" ? null : <img style={{ width: '0.7vw', height: '0.7vw' }} src={ImagePaths.AC_Icon.default} alt='AC' />}
                    //                 </Box>
                    //                 <Box id='eMed_Bed_wardText'>
                    //                     <img src={ImagePaths.WardIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                    //                     {list?.ward__ward_name?.length > 8 ?
                    //                         <Tooltip title={list?.ward__ward_name} placement='top'>
                    //                             <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                    //                                 {list.ward__ward_name ? list.ward__ward_name.slice(0, 8) + '...' : '-'}
                    //                             </Typography>
                    //                         </Tooltip> :
                    //                         <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                    //                             {list.ward__ward_name ? list.ward__ward_name : '-'}
                    //                         </Typography>}

                    //                 </Box>
                    //                 <Box id='eMed_Bed_FloorText' sx={{}}>
                    //                     <img src={ImagePaths.FloorIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                    //                     {list?.block?.length > 8 ?
                    //                         <Tooltip title={list?.block + " Floor"} placement='top'>
                    //                             <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                    //                                 {list.block ? list.block.slice(0, 8) + '...' : '-'}
                    //                             </Typography>
                    //                         </Tooltip> :
                    //                         <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                    //                             {list?.block ? list?.block : '-'}
                    //                         </Typography>}
                    //                 </Box>
                    //             </CardContent>
                    //             <Box id='eMed_Bed_FloorCon' sx={{ backgroundColor: Colors.themeBackgroud }}>
                    //                 <Typography sx={{ fontSize: '0.8vw', color: 'white', fontWeight: 'bold' }}>
                    //                     {`${list?.floor ? list?.floor : '-'}F`}
                    //                 </Typography>
                    //             </Box>
                    //         </Card>
                    //     )
                    // }) : null
                    <Box component={'div'} height={'70vh'} overflow={'auto'}>
                        {states.BedList_dup?.length > 0 ? states.BedList_dup?.map((blockItem, blockIndex) => (
                            <Box component={'div'} key={blockIndex}>
                                {blockItem?.block_data?.length > 0 ? blockItem?.block_data?.map((wardItem, wardIndex) => (
                                    <Box component={'div'} key={wardIndex} ml={'1.5vw'}>
                                        {wardItem?.ward_data?.length > 0 ? wardItem?.ward_data?.map((floorData, floorIndex) => (
                                            <><Box component={'div'} key={floorIndex} display={'flex'} flexDirection={'column'}>
                                                <Box component={'div'} display={'flex'}>
                                                    <Box component={'div'} display={'flex'} ml={'0.5vw'}>
                                                        <Typography color={Colors.grayShade}>{'Block Name : '}</Typography>
                                                        <Typography fontWeight={600}>{`${blockItem?.block ? blockItem?.block : '-'} /`}</Typography>
                                                    </Box>
                                                    <Box component={'div'} display={'flex'}>
                                                        <Typography color={Colors.grayShade}>Ward Name :</Typography>
                                                        <Typography fontWeight={600}>{` ${wardItem?.ward_name ? wardItem?.ward_name : '-'} /`}</Typography>
                                                    </Box>
                                                    <Box component={'div'} display={'flex'} ml={'0.5vw'}>
                                                        <Typography color={Colors.grayShade}>{"Floor :"}</Typography>
                                                        <Typography fontWeight={600}>{`${floorData?.floor ? floorData?.floor : '-'}`}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                                    {floorData?.floor_data?.length > 0 ? floorData?.floor_data?.map((list, index) => {
                                                        return (
                                                            <Box component={'div'} display={'flex'} key={index}>
                                                                <Card id='eMed_Bed_CardView' sx={{ border: '0.2vw solid', borderColor: list.is_available ? '#00b300' : 'white', backgroundColor: list.is_available ? '#e6ffe6' : 'white' }}>
                                                                    <CardContent sx={{ width: '8vw' }}>
                                                                        <Box style={{ display: 'flex', flexDirection: 'row', width: '7vw' }}>
                                                                            {list.room_number?.length > 3 ?
                                                                                <Tooltip title={list.room_number} placement='top'>
                                                                                    <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                                                                                        {list.room_number ? '..' + list.room_number.slice(-3) + ' / ' : '-'}
                                                                                    </Typography>
                                                                                </Tooltip> : <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                                                                                    {`${list.room_number ? list.room_number + ' / ' : '-'}`}
                                                                                </Typography>}
                                                                            <Box sx={{ marginLeft: '0.2vw' }}>
                                                                                {list.bed_number.length > 3 ?
                                                                                    <Tooltip title={list.bed_number} placement='top'>
                                                                                        <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                                                                                            {list.bed_number ? '..' + list.bed_number.slice(-3) : '-'}
                                                                                        </Typography>
                                                                                    </Tooltip> : <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>
                                                                                        {`${list.bed_number ? list.bed_number : '-'}`}
                                                                                    </Typography>}
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '7.3vw' }}>
                                                                            <Typography sx={{ fontSize: '0.8vw' }}>
                                                                                {"Room / Bed No"}
                                                                            </Typography>
                                                                            {list.ward_type === "NON AC" ? null : <img style={{ width: '0.7vw', height: '0.7vw' }} src={ImagePaths.AC_Icon.default} alt='AC' />}
                                                                        </Box>
                                                                        <Box id='eMed_Bed_wardText'>
                                                                            <img src={ImagePaths.WardIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                                                                            {list?.ward__ward_name?.length > 8 ?
                                                                                <Tooltip title={list?.ward__ward_name} placement='top'>
                                                                                    <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                                                                                        {list.ward__ward_name ? list.ward__ward_name.slice(0, 8) + '...' : '-'}
                                                                                    </Typography>
                                                                                </Tooltip> :
                                                                                <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                                                                                    {list.ward__ward_name ? list.ward__ward_name : '-'}
                                                                                </Typography>}

                                                                        </Box>
                                                                        <Box id='eMed_Bed_FloorText' sx={{}}>
                                                                            <img src={ImagePaths.FloorIcon.default} style={{ width: '1vw', height: '1vw' }} alt={'ward'} />
                                                                            {list?.block?.length > 8 ?
                                                                                <Tooltip title={list?.block + " Floor"} placement='top'>
                                                                                    <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                                                                                        {list.block ? list.block.slice(0, 8) + '...' : '-'}
                                                                                    </Typography>
                                                                                </Tooltip> :
                                                                                <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw' }}>
                                                                                    {list?.block ? list?.block : '-'}
                                                                                </Typography>}
                                                                        </Box>
                                                                    </CardContent>
                                                                    <Box id='eMed_Bed_FloorCon' sx={{ backgroundColor: Colors.themeBackgroud }}>
                                                                        <Typography sx={{ fontSize: '0.8vw', color: 'white', fontWeight: 'bold' }}>
                                                                            {`${list?.floor ? list?.floor : '-'}F`}
                                                                        </Typography>
                                                                    </Box>
                                                                </Card>
                                                            </Box>
                                                        )
                                                    }) : null}
                                                </Box>
                                            </Box>
                                            </>
                                        )) : null}
                                    </Box>
                                )) : null}
                            </Box>
                        )) : null}
                    </Box>
                }
            </Box>
        )
    }

    render() {
        return (
            <Box component={"div"} className='eMed_Main_container' sx={{ height: '77vh' }}>
                <Box component={"div"} className='eMed_Top_Container' style={{ height: "8vh" }}>
                    <Box component={"div"} className='eMed_Action_Container' sx={{ flex: 2.15, display: 'flex', alignItems: 'center' }}>
                        <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={ImagePaths.AvailabelRoom.default} alt="available" />
                            <Typography sx={{ fontSize: '1vw', marginLeft: '1vw' }}>{"Available Rooms"}</Typography>
                        </Box>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        {/* <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.GetBedListexcel() }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip> */}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    {this.renderRoomData()}
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter.bind(this) }}
                >{this.renderFilterScreen()}</Drawer>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(BedAvailable);
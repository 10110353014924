import React, { Component } from 'react';
import './Config.css';
import {
    Button,
    Stack,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Autocomplete,
    IconButton,
    Tooltip,
    Box,
    InputAdornment
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import SearchIcon from '@mui/icons-material/Search';
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from '../../../Utility/Constants';
class TestGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            selectTestName: null,
            testGroupData: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            testGroup: '',
            testAmount: '',
            testDescription: '',
            testNameTableValue: [],
            testConfigData: [],
            selectedEditID: '',
            deletedPopup: false,
            deletedID: '',
            searchKey: '',
            selectedIndex:'',
            SearchText:""
        }
    }

    componentDidMount() {
        this.getTestGroupConfig()
        this.getTestConfigData()
    }

    // componentWillReceiveProps(props){
    //     if(props.search_key != this.state.searchKey){
    //         this.setState({
    //             searchKey: props.search_key
    //         },() => {
    //             this.getTestGroupConfig()
    //         })
    //     }
    // }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: "",
        })
      }

    getTestGroupConfig = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET + "?search_key=" + this.state.searchKey)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testGroupData: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    getTestConfigData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testConfigData: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    postTestGroupData = () => {
        try {
            var states = this.state
            var lsbSetids = states.testNameTableValue && states.testNameTableValue.length > 0 ?
                states.testNameTableValue.map((item) => (item.id)) : null
            var data = {
                "labset_name": states.testGroup ? states.testGroup.replace(/\s+/g, ' ').trim() : '',
                "labset_amount": states.testAmount ? states.testAmount : '',
                "notes": states.testDescription ? states.testDescription.replace(/\s+/g, ' ').trim() : '',
                "labset_ids": lsbSetids
            }
            if (this.state.selectedEditID) {
                data['labset_id'] = this.state.selectedEditID
            }
            RestAPIService.create(data, Serviceurls.LAB_TEST_GROUP_GET)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getTestGroupConfig()
                            this.setState({
                                testGroup: '',
                                testAmount: '',
                                testDescription: '',
                                selectTestName: null,
                                testNameTableValue: [],
                                selectedEditID:'',
                            })
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    deleteTestGroupData = (ID) => {
        try {
            var data = {
                "labset_id": ID
            }
            RestAPIService.delete(Serviceurls.LAB_TEST_GROUP_GET, data)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getTestGroupConfig()
                        })
                    }
                }).catch((e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                }))

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    editTestGroupData = (item, index) => {
        this.setState({
            selectedEditID: item.id,
            testGroup: item.name,
            testAmount: item.amount,
            testNameTableValue: item.lab_test_details,
            selectedIndex: index,
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    textInputcheck = (event) => {
        let states = this.state;
        const name = event.target.name;
        const value = event.target.value;
        if(name === "testGroup" && value.length <=50 && CommonValidation.alphanumValidation(value) || value === ""){
            states["testGroup"] = value;
        } else if(name === "testDescription" && value.length <=150 || value === ""){
            states["testDescription"] = value;
        }
        this.setState({
           states
        });
    };

    numInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        // const re = /^[0-9\b]+$/;
        if (value === "" || CommonValidation.NumberWithDot(value) && value > 0) {
            this.setState({
                [name]: value,
            });
        }
    };

    renderTextBox = (label, width, value, name) => {
        return (
            <div>
                <TextField
                    size={label === "Description" ? 'large' : 'small'}
                    sx={{ width: width }}
                    multiline={label === "Description" ? true : false}
                    label={label}
                    rows={3}
                    name={name}
                    value={value}
                    variant="outlined"
                    onChange={name === 'testAmount' ? this.numInputcheck : this.textInputcheck}
                />
            </div>
        )
    }

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        //  filter table data based on search Text
        const filtereddata = this.state.testGroupData.filter((data) => {
            return (
                (data.name === null ? null : data.name.toLowerCase().includes(this.state.SearchText.toLowerCase())) || 
                (data.lab_test_details === null ? null : JSON.stringify(data.lab_test_details).toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });
        return (
            <div>
                <Paper elevation={0}>
                    <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '15vw' }} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('TestGroup')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('TestName')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('TestAmount') + ` (${CurrencySymbol})`}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    var FullData = data
                                    var testData = ''
                                    const Datas = data.lab_test_details
                                    Datas.forEach(element => {
                                        testData = testData ? (testData + ", " + element.name) : element.name
                                    });
                                    return (
                                        <TableBody>
                                            <TableRow sx={{bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white'}}>
                                                <TableCell align='left'><div>{sno}</div></TableCell>
                                                <TableCell align='left'>
                                                {data.name.length > 15 ?
                                                        <Tooltip placement="top" title={data.name} arrow>
                                                            <div >{data.name ? data.name.slice(0, 15) + '...': '-'}</div>
                                                        </Tooltip> : <div> {data.name ? data.name : '-'}</div>}</TableCell>
                                                <TableCell align='left'>
                                                    {testData.length > 15 ?
                                                        <Tooltip placement="top" title={testData} arrow>
                                                            <div >{testData ? testData.slice(0, 15) + '...': '-'}</div>
                                                        </Tooltip> : <div> {testData ? testData : '-'}</div>}
                                                </TableCell>
                                                <TableCell align='left' ><div className='eMed_warning'>
                                                    {data.amount ? AmountFormat(data.amount).replace(`${CurrencySymbol}`,"") : '-'}
                                                    {data.is_mapped != true ? 
                                                    <Tooltip title={t("ConsumablenotMapping")} placement='top' arrow>
                                                        <img src={ImagePaths.Warning.default} className='eMed_TestConfig_DeleteIcon' alt='warning' />
                                                    </Tooltip>
                                                    : null }
                                                </div>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton size='small'>
                                                            <img src={ImagePaths.Edit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editTestGroupData(FullData, index) }}
                                                            />
                                                        </IconButton>
                                                        <IconButton size='small'>
                                                            <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        deletedPopup: true,
                                                                        deletedID: data.id
                                                                    })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata.length}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }
    renderRightTable = () => {
        var { testNameTableValue } = this.state
        const { t } = this.props
        return (
            <div>
                <Paper elevation={0}>
                    <TableContainer className='eMed_ConfigRightTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ flex: 1, }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText, flex: 0.15, }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText, flex: 0.35, }}>{t('TestName')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText, flex: 0.35, }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {testNameTableValue && testNameTableValue.length > 0 ? testNameTableValue.map((item, index) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ flex: 0.15 }} align='left'><div>{index + 1}</div></TableCell>
                                            <TableCell sx={{ flex: 0.35 }} align='left'>
                                            {item.name.length > 10 ?
                                                        <Tooltip placement="top" title={item.name} arrow>
                                                            <div >{item.name ? item.name.slice(0, 10) + '...': '-'}</div>
                                                        </Tooltip> : <div> {item.name ? item.name : '-'}</div>}</TableCell>
                                            <TableCell sx={{ flex: 0.35 }} align='left'>
                                                <img className='eMed_TestConfig_DeleteIcon eMed_cursor' src={ImagePaths.Delete.default}
                                                    onClick={() => {
                                                        testNameTableValue.splice(index, 1)
                                                        this.setState({
                                                            testNameTableValue
                                                        })
                                                    }}
                                                    alt="Delete"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }) : null}
                        </Table>
                        {testNameTableValue.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Paper>
            </div>
        )
    }

    clearValue = () => {
        this.setState({
            testDescription: '',
            selectTestName: null,
            testNameTableValue: [],
            selectedEditID: '',
            selectedIndex: '',
        })
    }

    validCheck = () => {
        var saveErrorMsg = ''
        var states = this.state
        const {t} = this.props
        if ((states.testNameTableValue).length == 0 || !states.testGroup || !states.testAmount) {
            saveErrorMsg = t('Fillmandatory')
        }
        return saveErrorMsg
    }

    onSave = () => {
        var inValid = this.validCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            this.postTestGroupData()
        }
    }

    duplicateCheck = () => {
        var { selectTestName, testNameTableValue } = this.state
        var duplicate = false
        if (selectTestName?.name) {
            for (let i = 0; i < testNameTableValue.length; i++) {
                if (testNameTableValue[i].id == selectTestName.id) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }

    addTestName = () => {
        var states = this.state
        const {t} = this.props
        var duplicate = this.duplicateCheck()
        if (states.testGroup &&
            states.testAmount &&
            states.selectTestName && !duplicate) {

            states['testNameTableValue'].push({
                "name": states.selectTestName.name,
                "id": states.selectTestName.id,
            })
            this.setState({
                states
            }, () => {
                this.setState({
                    selectTestName: null
                })
            })
        } else if (duplicate) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t('TestExist')
            })
        } else {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t('Fillmandatory')
            })
        }
    }
    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }
    removeDataList = () => {
        this.deleteTestGroupData(this.state.deletedID)
        this.setState({
            deletedPopup: false
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead'>
                            <p className='eMed_Testconfig_RightHead_text' >{t('LaboratoryTestAndReportConfiguration')}</p>
                        </div>
                        <div className='eMed_TestConfig_RightContentBox'>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("TestGroup")+ "*", '14vw', this.state.testGroup, 'testGroup')}
                                {this.renderTextBox(t("TestAmount")+ "*", '14vw', this.state.testAmount, 'testAmount')}
                            </div>
                            <div className='eMed_TestConfig_RightTestName'>
                                {this.renderTextBox(t("Description"), '29vw', this.state.testDescription, 'testDescription')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <Autocomplete
                                    options={this.state.testConfigData}
                                    getOptionLabel={(options) => options.name}
                                    onChange={(e, value) => {

                                        this.setState({
                                            testRow: value,
                                            selectTestName: value
                                        })
                                    }}
                                    value={this.state.selectTestName}
                                    id="combo-box-demo"
                                    sx={{ width: "14vw" }}
                                    renderInput={(params) => <TextField {...params} label={t('TestName') + "*"} size='small' />}
                                />
                                <Button id='eMed_Btn_Text' variant='contained' sx={{ width: '14vw' }}
                                    onClick={() => { this.addTestName() }}
                                >{t('AddTestName')}</Button>
                            </div>
                            <div className='eMed_TestConfig_Right_SideTable'>
                                {this.renderRightTable()}
                            </div>
                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                                <Button id='eMed_Btn_Text' variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("confirmRemoveTestGrp")}
                        DeletContent={t("linkedTestGrp")}
                        DeleteNotify={t("effectEntireConfig")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)} /> : null}
            </div>
        );
    }
}

export default withTranslation()(TestGroup);
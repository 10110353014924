import React, { Component } from 'react';

export default class DocFitnessCertific extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div>Fitness Certific</div>
        )
    }
}
import React from "react";
import eMedHub from "../Assets/Logo/eMedHub.jpeg";
import { Box, Button, CircularProgress, Dialog, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Logo from "../Assets/Logo/Logo.svg";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from "../Utility/ImagePaths";
import CommonValidation from "../Components/CommonFunctions/CommonValidation";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestAPIService from "../Utility/Services/RestAPIService";
import { Serviceurls } from "../Utility/API/Serviceurls";
import ToastMsg from "../Components/ToastMsg/ToastMsg";


class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Mobile_or_email: '',
      is_OTPsend: false,
      otp_num1: '',
      otp_num2: '',
      otp_num3: '',
      otp_num4: '',
      otp_num5: '',
      otp_num6: '',
      is_Reset: false,
      userName: '',
      newPassword: '',
      confirmPass: '',
      showPassword: false,
      show_ConPassword: false,
      seconds: 60,
      timerRunning: false,
      userEmail: '',
      userPhone: '',
      is_Success: false,
      isLoader: false,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      user_Name: '',
    };
    this.inputRefs = {
      otp_num1: React.createRef(),
      otp_num2: React.createRef(),
      otp_num3: React.createRef(),
      otp_num4: React.createRef(),
      otp_num5: React.createRef(),
      otp_num6: React.createRef(),
    };
  }
  componentDidMount() {
    this.timerInterval = null;
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.timerInterval);
  } 

  ErrorMsg = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  SuccessMsg = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  startTimer = () => {
    this.setState({ seconds: 60, timerRunning: true });
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    this.timerInterval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState({ seconds: this.state.seconds - 1 });
      } else {
        clearInterval(this.timerInterval);
        this.setState({ timerRunning: false });
      }
    }, 1000);
  };

  sendOTP = () => {
    try {
      this.setState({
        isLoader: true,
      })
      let mobilenum = CommonValidation.mobileNumber(this.state.Mobile_or_email)
      let emailVal = CommonValidation.emailValidation(this.state.Mobile_or_email)
      if (this.state.Mobile_or_email && ((this.state.Mobile_or_email?.length < 11 && mobilenum) || (this.state.Mobile_or_email && emailVal))) {
        let data = {
          "otp_type": 2,
          'username': this.state.user_Name ? this.state.user_Name : ''
        }
        if (mobilenum && this.state.Mobile_or_email) {
          data["phone_number"] = this.state.Mobile_or_email ? this.state.Mobile_or_email : null
        } else if (emailVal && this.state.Mobile_or_email) {
          data["email"] = this.state.Mobile_or_email ? this.state.Mobile_or_email : null
        }
        RestAPIService.create(data, Serviceurls.SEND_OTP)
          .then((response) => {
            if (response.data.status === 'success') {
              this.SuccessMsg(response.data.message)
              this.startTimer()
              setTimeout(() => {
                this.setState({
                  is_OTPsend: true,
                  userName: response.data?.data?.username,
                  userEmail: response.data?.data?.email ? response.data?.data?.email : '',
                  userPhone: response.data?.data?.phone_number ? response.data?.data?.phone_number : '',
                  isLoader: false,
                })
              }, 2000);
            }
          }).catch(e => {
            this.setState({ isLoader: false, })
            if (e.response.data.status === 'fail') {
              this.ErrorMsg(e?.response?.data?.message)
            } else {
              this.ErrorMsg(e.message)
            }
          })
      } else {
        this.setState({ isLoader: false, })
        this.ErrorMsg('Please Enter Valid Mobile No or Mail ID')
      }
    } catch (e) {
      this.setState({ isLoader: false, })
      this.ErrorMsg(e.message)
    }
  }

  verifyOTP = () => {
    this.setState({
      isLoader: true,
    })
    try {
      let otp_code = `${this.state.otp_num1}${this.state.otp_num2}${this.state.otp_num3}${this.state.otp_num4}${this.state.otp_num5}${this.state.otp_num6}`
      if (this.state.otp_num1 &&
        this.state.otp_num2 &&
        this.state.otp_num3 &&
        this.state.otp_num4 &&
        this.state.otp_num5 &&
        this.state.otp_num6) {
        let data = {
          "username": this.state.userName ? this.state.userName : '',
          "otp": otp_code,
        }
        RestAPIService.create(data, Serviceurls.VERIFY_OTP)
          .then((response) => {
            if (response.data.status === 'success') {
              this.SuccessMsg(response.data.message)
              setTimeout(() => {
                this.setState({
                  is_Reset: true,
                  is_OTPsend: false,
                  userName: response?.data?.data?.username,
                  isLoader: false,
                })
              }, 2000);
            }
          }).catch(e => {
            this.setState({ isLoader: false, })
            if (e.response.data.status === 'fail') {
              this.ErrorMsg(e?.response?.data?.message)
            } else {
              this.ErrorMsg(e.message)
            }
            this.setState({
              otp_num1: '',
              otp_num2: '',
              otp_num3: '',
              otp_num4: '',
              otp_num5: '',
              otp_num6: '',
            })
          })
      } else {
        this.setState({ isLoader: false, })
        this.ErrorMsg('Please Enter the valid OTP')
      }
    } catch (e) {
      this.setState({ isLoader: false, })
      this.ErrorMsg(e.message)
      this.setState({
        otp_num1: '',
        otp_num2: '',
        otp_num3: '',
        otp_num4: '',
        otp_num5: '',
        otp_num6: '',
      })
    }
  }

  resendOTP = () => {
    var { timerRunning } = this.state
    this.setState({
      isLoader: true,
    })
    try {
      if (timerRunning === false) {
        let data = {
          "username": this.state.userName,
        }
        if (this.state.userPhone) {
          data["phone_number"] = this.state.userPhone ? this.state.userPhone : null
        } else if (this.state.userEmail) {
          data["email"] = this.state.userEmail ? this.state.userEmail : null
        }
        RestAPIService.create(data, Serviceurls.RESEND_OTP)
          .then((response) => {
            if (response.data.status === 'success') {
              this.SuccessMsg(response.data.message)
              this.setState({
                isLoader: false,
              })
            }
          }).catch(e => {
            this.setState({ isLoader: false, })
            if (e.response.data.status === 'fail') {
              this.ErrorMsg(e?.response?.data?.message)
            } else {
              this.ErrorMsg(e.message)
            }
          })
      } else {
        this.setState({ isLoader: false, })
      }

    } catch (e) {
      this.setState({ isLoader: false, })
      this.ErrorMsg(e.message)
    }
  }

  resetPassword = () => {
    try {
      this.setState({
        isLoader: true,
      })
      let pass_val = CommonValidation.checkPassword(this.state.newPassword)
      if (pass_val && this.state.newPassword === this.state.confirmPass) {
        let data = {
          "user_name": this.state.userName,
          "new_password": this.state.newPassword,
          "confirm_password": this.state.confirmPass
        }
        RestAPIService.create(data, Serviceurls.FORGOT_PASSWORD)
          .then((response) => {
            if (response.data.status === 'success') {
              this.SuccessMsg(response.data.message)
              this.setState({
                is_Success: true,
                isLoader: false,
              })
            }
          }).catch((e) => {
            this.setState({ isLoader: false, })
            if (e?.response?.data?.status === 'fail') {
              this.ErrorMsg(e?.response?.data?.message)
            } else {
              this.ErrorMsg(e.message)
            }
          })
      } else {
        this.setState({ isLoader: false, })
        if (!pass_val) {
          this.ErrorMsg("Password should be 6 characters, one UpperCase,Special Characters,LowerCase")
        } else {
          this.ErrorMsg("Password Mismatch")
        }
      }
    } catch (e) {
      this.setState({ isLoader: false, })
      this.ErrorMsg(e.message)
    }
  }


  handleInputChange = (event, fieldName) => {
    const value = event.target.value;
    var number = CommonValidation.numberOnly(value)
    if (number || value === '') {
      this.setState({ [fieldName]: value });
    }
    // Check if the value has a length of 1 and move to the next input
    if (value.length === 1 && this.inputRefs[fieldName].current) {
      const nextFieldName = this.getNextFieldName(fieldName);
      if (nextFieldName) {
        this.inputRefs[nextFieldName].current.focus();
      }
    }
  };

  getNextFieldName = (currentFieldName) => {
    // Define the order in which fields should be focused
    const fieldOrder = ['otp_num1', 'otp_num2', 'otp_num3', 'otp_num4', 'otp_num5', 'otp_num6'];
    const currentIndex = fieldOrder.indexOf(currentFieldName);

    if (currentIndex !== -1 && currentIndex < fieldOrder.length - 1) {
      return fieldOrder[currentIndex + 1];
    }

    return null;
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }


  render() {
    const { t } = this.props
    const { seconds, timerRunning } = this.state;
    return (
      <div class="total">
        <div class="left">
          <img src={eMedHub} width="100%" height="100%" alt="login-img"/>
        </div>
        
        <Box class="eMed_Forget_Con">
        <img src={Logo} alt="logo" style={{width: '6vw', height: '6vw'}}/>
          {this.state.is_OTPsend ?
            // OTP Container
            <Box className='eMed_Forget_Box'>
              <Box className='eMed_Forget_HeadCon'>
                <IconButton onClick={() => { this.setState({ is_OTPsend: false, otp_num1: '', otp_num2: '', otp_num3: '', otp_num4: '', otp_num5: '', otp_num6: '', timerRunning: false, seconds: 60 }) }}>
                  <img className="eMed_DisSum_Lay_StarIcon" src={ImagePaths.Back_Arrow.default} alt="Back" />
                </IconButton>
                <Typography id='eMed_Forget_HeaderText'>{'Verify Your OTP'}</Typography>
              </Box>
              <Typography id='eMed_Forget_HelpText'>Confirmation code has been sent to your <b>{this.state.userPhone ? this.state.userPhone : this.state.userEmail ? this.state.userEmail : '-'}</b></Typography>
              <Box margin={'1vw'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <TextField
                  size="small"
                  id="otp_num1"
                  value={this.state.otp_num1}
                  sx={{ width: '3vw' }}
                  name="otp_num1"
                  inputProps={{ maxLength: 1 }}
                  variant="outlined"
                  inputRef={this.inputRefs['otp_num1']}
                  onChange={(event) => this.handleInputChange(event, 'otp_num1')}
                />
                <TextField
                  size="small"
                  id="otp_num2"
                  value={this.state.otp_num2}
                  sx={{ width: '3vw' }}
                  name="otp_num2"
                  inputProps={{ maxLength: 1 }}
                  variant="outlined"
                  inputRef={this.inputRefs['otp_num2']}
                  onChange={(event) => this.handleInputChange(event, 'otp_num2')}
                />
                <TextField
                  size="small"
                  id="otp_num3"
                  value={this.state.otp_num3}
                  sx={{ width: '3vw' }}
                  name="otp_num3"
                  inputProps={{ maxLength: 1 }}
                  variant="outlined"
                  inputRef={this.inputRefs['otp_num3']}
                  onChange={(event) => this.handleInputChange(event, 'otp_num3')}
                />
                <TextField
                  size="small"
                  id="otp_num4"
                  value={this.state.otp_num4}
                  sx={{ width: '3vw' }}
                  name="otp_num4"
                  inputProps={{ maxLength: 1 }}
                  variant="outlined"
                  inputRef={this.inputRefs['otp_num4']}
                  onChange={(event) => this.handleInputChange(event, 'otp_num4')}
                />
                <TextField
                  size="small"
                  id="otp_num5"
                  value={this.state.otp_num5}
                  sx={{ width: '3vw' }}
                  name="otp_num5"
                  inputProps={{ maxLength: 1 }}
                  variant="outlined"
                  inputRef={this.inputRefs['otp_num5']}
                  onChange={(event) => this.handleInputChange(event, 'otp_num5')}
                />
                <TextField
                  size="small"
                  id="otp_num6"
                  value={this.state.otp_num6}
                  sx={{ width: '3vw' }}
                  name="otp_num6"
                  inputProps={{ maxLength: 1 }}
                  variant="outlined"
                  inputRef={this.inputRefs['otp_num6']}
                  onChange={(event) => this.handleInputChange(event, 'otp_num6')}
                />
              </Box>
              <Typography id="eMed_Verify_HelpText">{"Please enter the code for verification"}</Typography>
              <Box display={'flex'} justifyContent={'center'}>
                <Button
                  id="eMed_Forget_Btn"
                  variant='contained'
                  size='small'
                  disabled={this.state.otp_num6 === ''}
                  onClick={() => {
                    if (!this.state.isLoader) {
                      this.verifyOTP()
                    }
                  }}>{this.state.isLoader ? <CircularProgress sx={{ color: 'white' }} size={20} /> : "Verify OTP"}</Button>
              </Box>
              <Box className='eMed_Resent_CodeCon'>
                <Typography sx={{ color: '#616161', fontSize: '0.9vw', cursor: timerRunning ? 'not-allowed' : 'pointer', '&:hover': { color: 'blue', textDecoration: 'underline' } }}
                  onClick={() => { this.resendOTP() }}>{"Haven't Received Code? Send Again"}</Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} margin={'0.5vw'}>
                <AccessTimeIcon sx={{ width: '1.3vw', height: '1.3vw' }} color="disabled" />
                <Typography sx={{ color: '#616161', fontSize: '0.9vw', marginLeft: '0.5vw' }}>00 : {seconds} </Typography>
              </Box>
            </Box>
            :
            this.state.is_Reset ?
              // Reset Pass Container
              <Box className='eMed_Forget_Box'>
                <Box margin={'1vw'}>
                  <Typography id='eMed_Forget_HeaderText'>{'Reset Your Password'}</Typography>
                  <Typography id='eMed_Forget_HelpText'>{`Enter new password and confirm password here.`}</Typography>
                </Box>
                <TextField
                  size="small"
                  sx={{ margin: '0.5vw 1vw 0.5vw 1vw' }}
                  id="userName"
                  value={this.state.userName}
                  label="User Name"
                  name="userName"
                  variant="outlined"
                  onChange={(event) => {
                    var value = event.target.value;
                    this.setState({
                      [event.target.name]: value,
                    });
                  }} />
                <TextField
                  size="small"
                  sx={{ margin: '0.5vw 1vw 0.5vw 1vw' }}
                  id="newPassword"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.newPassword}
                  InputProps={{
                    endAdornment:
                      <InputAdornment>
                        <IconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                          {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                  }}
                  label="New Password"
                  name="newPassword"
                  variant="outlined"
                  onChange={(event) => {
                    var value = event.target.value;
                    let PasswordInput = CommonValidation.PasswordInput(value)
                    if ((PasswordInput && value.length <= 14) || value === "") {
                      this.setState({
                        [event.target.name]: value,
                      });
                    }
                  }} />
                <Typography sx={{ fontSize: '0.6vw', color: '#616161', marginLeft: '1vw', marginBottom: '0.5vw' }}>{'(Should be one UpperCase,Special Characters,LowerCase, Numaric)'}</Typography>
                <TextField
                  size="small"
                  sx={{ margin: '0.5vw 1vw 0.5vw 1vw' }}
                  id="confirmPass"
                  type={this.state.show_ConPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment>
                        <IconButton onClick={() => this.setState({ show_ConPassword: !this.state.show_ConPassword })}>
                          {this.state.show_ConPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                  }}
                  value={this.state.confirmPass}
                  label="Confirm Password"
                  name="confirmPass"
                  variant="outlined"
                  onChange={(event) => {
                    var value = event.target.value;
                    let PasswordInput = CommonValidation.PasswordInput(value)
                    if (((PasswordInput && value.length <= 14) || value === "") || value === this.state.newPassword) {
                      this.setState({
                        [event.target.name]: value,
                      });
                    }
                  }} />
                <Box display={'flex'} justifyContent={'center'}>
                  <Button
                    id="eMed_Forget_Btn"
                    variant='contained'
                    size='small'
                    disabled={this.state.confirmPass === ''}
                    onClick={() => {
                      if (!this.state.isLoader) {
                        this.resetPassword()
                      }
                    }}>{this.state.isLoader ? <CircularProgress sx={{ color: 'white' }} size={20} /> : "Reset Password"}</Button>
                </Box>
              </Box> :
              // Forget Password Container
              <Box className='eMed_Forget_Box'>
                <Box className='eMed_Forget_HeadCon'>
                  <IconButton onClick={() => { this.props.history.push('/') }}>
                    <img className="eMed_DisSum_Lay_StarIcon" src={ImagePaths.Back_Arrow.default} alt="Back" />
                  </IconButton>
                  <Typography id='eMed_Forget_HeaderText'>{'Forgot Password'}</Typography>
                </Box>
                <Typography id='eMed_Forget_HelpText'>{'Enter your registered email ID or Mobile number to send OTP.'}</Typography>
                <TextField
                  size="small"
                  sx={{ margin: '1vw' }}
                  id="Forget-user"
                  value={this.state.user_Name}
                  label="UserName"
                  name="user_Name"
                  variant="outlined"
                  onChange={(event) => {
                    var value = event.target.value;
                    this.setState({
                      [event.target.name]: value,
                    });
                  }} />
                <TextField
                  size="small"
                  sx={{ margin: '0.5vw 1vw 1vw 1vw' }}
                  id="Forget-password"
                  value={this.state.Mobile_or_email}
                  label="Mobile No / Mail ID"
                  placeholder="Mobile No or Mail ID"
                  name="Mobile_or_email"
                  variant="outlined"
                  onChange={(event) => {
                    var value = event.target.value;
                    this.setState({
                      [event.target.name]: value,
                    });
                  }} />
                <Box display={'flex'} justifyContent={'center'}>
                  <Button
                    id="eMed_Forget_Btn"
                    variant='contained'
                    size='small'
                    disabled={this.state.Mobile_or_email === ''}
                    onClick={() => {
                      if (!this.state.isLoader) {
                        this.sendOTP()
                      }
                    }}>{this.state.isLoader ? <CircularProgress sx={{ color: 'white' }} size={20} /> : "Send OTP"}</Button>
                </Box>
              </Box>}
        </Box>
        {this.state.is_Success ?
          <Dialog open={this.state.is_Success}>
            <Box sx={{ width: '25vw', height: '33vh', margin: '1vw' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                <img style={{ width: '6vw', height: '6vw' }} src={ImagePaths.SuccessAnimationGif.default} />
              </Box>
              <Box display={'flex'} justifySelf={'center'} marginTop={'1vw'} flexDirection={'column'}>
                <Typography sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Congratulation {this.state.userName}!</Typography>
                <Typography sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Your Password has been Changed Successfully</Typography>
              </Box>
              <Box display={'flex'} justifyContent={'center'} marginTop={'1.5vw'}>
                <Button variant="contained" sx={{ textTransform: 'capitalize' }} onClick={() => { this.props.history.push('/') }}>
                  Back to Login
                </Button>
              </Box>
            </Box>
          </Dialog>
          : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
            msgtop={true}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
            msgtop={true}
          />
          : null}
      </div>
    );
  }
}
export default withTranslation()(ForgotPassword);

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Box, Button, Divider, FormControlLabel, InputAdornment, OutlinedInput, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../../../Components/Common Components/CommonComponents'
import { getCachevalue } from '../../../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../../../Components/Loader'
import { Colors } from '../../../../../../Styles/Colors'
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls'
import { AmountFormat } from '../../../../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol } from '../../../../../../Utility/Constants'

class CorporateClaimCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ipPatientData: {},
            Approval_Status: true,
            auth_Amount: "",
            comments: "",
            CreditDetails: []
        }
    }

    componentDidMount() {
        let ipPatientData = getCachevalue("IppatientData") ? getCachevalue("IppatientData") : getCachevalue("patientData")
        let patientData = JSON.parse(ipPatientData)
        this.setState({
            ipPatientData: patientData
        }, () => {
            this.GetCorporateDeatils()
        })
    }

    GetCorporateDeatils = () => {
        try {
            let ApiUrl = ""
            if (this.props?.FromOp) {
                let AdmissionId = this.state?.ipPatientData?.appointment_id ? this.state?.ipPatientData?.appointment_id : ""
                ApiUrl = Serviceurls.GET_CORPORATE_CLAIM_DETAILS + '?appointment_id=' + AdmissionId
            } else {
                let AdmissionId = this.state?.ipPatientData?.id ? this.state?.ipPatientData?.id : ""
                ApiUrl = Serviceurls.GET_CORPORATE_CLAIM_DETAILS + '?ip_admission_id=' + AdmissionId
            }
            RestAPIService.getAll(ApiUrl)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            CreditDetails: response.data.data ? response.data.data : {}
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key = false) => {
        this.setState({
            isLoader: key
        })
    }


    renderLeftContent = () => {
        const { t } = this.props
        return (
            <div>
                <Box component={'div'}><Typography paddingLeft={"1vw"} marginY={"0.5vw"} fontWeight={600}>{t('Claim Details')}</Typography></Box>
                <Divider />
                {
                    this.state.CreditDetails?.company_name ?
                        <Box>
                            <Box>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                    <Box sx={{ margin: "1vw" }}>
                                        <Typography fontSize={"0.9vw"}>{"Corporate Name"}</Typography>
                                        <Typography fontSize={"0.9vw"} fontWeight={600}>{this.state.CreditDetails?.company_name ? this.state.CreditDetails?.company_name : "-"}</Typography>
                                    </Box>
                                    <Box sx={{ margin: "1vw" }}>
                                        <Typography fontSize={"0.9vw"}>{"Employee ID"}</Typography>
                                        <Typography fontSize={"0.9vw"} fontWeight={600}>{this.state.CreditDetails?.id_no ? this.state.CreditDetails?.id_no : "-"}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={{ margin: "1vw", }}>
                                <Typography sx={{ color: "gray" }}>BILL SUMMARY</Typography>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", marginY: "1vw" }}>
                                    <Typography>{"Total Bill Amount"}</Typography>
                                    <Typography sx={{ fontWeight: 600 }}>{` : ${AmountFormat(this.state.CreditDetails?.total_bill_amount ? this.state.CreditDetails?.total_bill_amount : 0)}`}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", marginY: "1vw" }}>
                                    <Typography>{`Allocated Amount ( ${this.state.CreditDetails?.discount_percent ? this.state.CreditDetails?.discount_percent : 0} % )`}</Typography>
                                    <Typography sx={{ fontWeight: 600 }}>{` : ${CurrencySymbol} ${this.state.CreditDetails?.total_bill_amount ? CommonValidation.calculatePercentage(this.state.CreditDetails?.discount_percent ? this.state.CreditDetails?.discount_percent : 100, this.state.CreditDetails?.total_bill_amount ? +this.state.CreditDetails?.total_bill_amount : 0) : 0}`}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", marginY: "1vw" }}>
                                    <Typography>{`Total Paid Amount`}</Typography>
                                    <Typography sx={{ fontWeight: 600 }}>{` : ${AmountFormat(this.state.CreditDetails?.total_paid_amount ? this.state.CreditDetails?.total_paid_amount : 0)}`}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", marginY: "1vw" }}>
                                    <Typography>{`Pending Amount`}</Typography>
                                    <Typography sx={{ fontWeight: 600 }}>{` : ${AmountFormat(this.state.CreditDetails?.pending_amount ? this.state.CreditDetails?.pending_amount : 0)}`}</Typography>
                                </Box>
                            </Box>
                        </Box> :
                        <Box sx={{ height: "30vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography fontSize={'0.8vw'} color={"gray"}>{'No Corporate were linked with this Patient'}</Typography>
                        </Box>
                }

            </div>
        )
    }

    render() {
        const { t } = this.props
        var { ipPatientData } = this.state
        let attender = {
            lineOne: t("AttenderName"),
            lineTwo: `${ipPatientData.attender_name ? ipPatientData.attender_name : '-'}`,
            lineFour: t('ContactNumber'),
            LineFive: `${ipPatientData.attender_mobile ? ipPatientData.attender_mobile : '-'}`
        }
        let consultant = {
            lineOne: t("ConsultantName"),
            lineTwo: `${ipPatientData.doctor_name ? ipPatientData.doctor_name : '-'}`,
        }
        return (
            <Box sx={{ backgroundColor: Colors.ComponentBgColor, height: "72vh" }}>
                <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header'>
                    <Box display={'flex'} alignItems='center'>
                        <Box className='eMed_Patient_Details_Long eMed_patient_box'>
                            <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                        </Box>
                        <CommonPatientCard details={attender} showDetailed={true} />
                        {this.props?.FromOp ? null : <ConsultantPatientCard name={consultant} />}
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
                    <Paper elevation={3} sx={{ width: "25vw", margin: "1vw" }}>
                        {this.renderLeftContent()}
                    </Paper>
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(CorporateClaimCard);
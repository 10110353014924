import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import './inventory.css';
import {
    Button, Modal, Box,
    TextField,
    Tooltip,Stack, IconButton
} from '@mui/material';
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

class ActiveInventory extends Component {

    constructor() {
        super()
        this.state = {
            pageSize: 10,
            checkboxSelection: [],
            count: [],
            page: 0,
            activeInventoryList: [],
            popUpActiveList: [],
            inActiveReason: "",
            activeStatus: true,
            inActiveBulkIds: [],
            InactiveOneId: "",
            count: 0,
            searchkey : '',
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getActiveInventoryList()
    }

    componentWillReceiveProps(props){
        if(props.search_key != this.state.searchkey){
            this.setState({
                searchkey: props.search_key
            },() => {
                this.getActiveInventoryList()
            })
        }
    }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          isSuccessMsg: false,
          isSuccessMsgText: "",
        })
    }

    SuccessErrorBox = (message, type) => {
        this.props.responseSuccessErrorMessage(message, type)
    }

    onChangeHandler = (event, key) => {
        this.setState({
            [key]: event.target.value
        })
    }

    updatePageSize = (newPageSize) => {
        this.setState({
            pageSize: newPageSize
        })
    }

    activePopUpOpen = (e, values) => {
        const {t} = this.props
        try {
            let bulkHash = {};
            if (this.state.checkboxSelection.length > 0) {
                this.state.checkboxSelection.forEach(element => {
                    bulkHash[element] = element;
                    if (bulkHash[values.row.product_ms_id]) {
                        this.setState({ active: false })
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: t("singleProduct")
                        })
                    } else {
                        this.setState({
                            active: true,
                            InactiveOneId: values.row.product_ms_id,
                            activeStatus: false,
                            selectedProductName : values.row.product_name
                        })
                    }
                })
            } else {
                this.setState({
                    active: true,
                    InactiveOneId: values.row.product_ms_id,
                    activeStatus: false,
                    selectedProductName : values.row.product_name
                })
            }
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error
            })
        }
    }
    activePopUpClose = () => {
        this.setState({
            active: false,
            activeStatus: true
        })
    }

    activeViewOpen = (e, values) => {
        this.setState({
            activeView: true,
            id: values.row.product_ms_id,
            popUpActiveList: values.row
        })
    }

    activeViewClose = () => {
        this.setState({
            activeView: false
        })
    }
LoaderFunction=(key)=>{
   this.setState({
    isLoader:key
   })
}
    getActiveInventoryList = () => {
        try {
            this.LoaderFunction(true)
            this.setState({
                activeInventoryList: [],
                activeStatus: true
            })
            RestAPIService.getAll(Serviceurls.INVENTORY_ACTIVE_INVENTORY_GET + `?page_number=${this.state.page + 1}&page_size=${this.state.pageSize}&active_status=${true}&search_key=${this.state.searchkey}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            activeInventoryList: response.data.result,
                            total: response.data.page_count,
                            newPageCount: response.data.no_of_page
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })})
        } catch (error) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error
            })
        }
    }


    postOneInActiveList = () => {
        const {t} = this.props
        try {
            let data = {
                "product_ms_id": this.state.InactiveOneId,
                "active_status": this.state.activeStatus,
                "comment": this.state.inActiveReason
            }
            if (!data.comment) {
                let message = t("EnterReason")
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: message,
                    disableBtn:false
                })
            } else {
                // this.setState({disableBtn: true})
                RestAPIService.create(data, Serviceurls.INVENTORY_ACTIVE_INVENTORY_POST).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.getActiveInventoryList()
                            this.setState({
                                inActiveReason: "",
                                activeStatus: true,
                                active: false,
                                InactiveOneId:''
                            })
                            this.setState({
                                isSuccessMsg: true,
                                isSuccessMsgText: response.data.message,
                                disableBtn: false,
                            })
                        } else {
                            this.setState({
                                isErrorMsg: true,
                                isErrorMsgText: response.data.message,
                                disableBtn: false,
                            })
                        }
                    }).catch((error) => this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message,
                        disableBtn: false,
                    }))
            }
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error,
                disableBtn: false,
            })
        }
    }

    postBulkInActiveList = () => {
        const {t} = this.props
        try {
            let { checkboxSelection, inActiveBulkIds } = this.state;
            checkboxSelection.forEach(element => {
                inActiveBulkIds.push(element)
            })
            let data = {
                "product_ms_id": inActiveBulkIds,
                "active_status": this.state.activeStatus,
                "comment": this.state.inActiveReason
            }
            if (!data.comment) {
                let message = t("EnterReason")
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: message,
                    disableBtn:false
                })
            } else {
                // this.setState({disableBtn: true})
                RestAPIService.create(data, Serviceurls.INVENTORY_ACTIVE_INVENTORY_BULK_POST).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.setState({
                                isSuccessMsg: true,
                                isSuccessMsgText: response.data.message
                            })
                            this.getActiveInventoryList()
                            this.setState({
                                inActiveReason: "",
                                activeStatus: true,
                                active: false,
                                disableBtn: false,
                                inActiveBulkIds:[]
                            })
                        } else {
                            this.setState({
                                isErrorMsg: true,
                                isErrorMsgText: response.data.message,
                                disableBtn: false,
                            })
                        }
                    }).catch((error) => (
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.response.data.message,
                            disableBtn: false,
                        })
                    ))
            }
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error,
                disableBtn: false,
            })
        }
    }

    getActiveInventoryexcel = () => {
        const {t} = this.props
        try {
          this.setState({disableBtn: true})
          RestAPIService.excelGet(Serviceurls.INVENTORY_ACTIVE_INVENTORY_GET + "?export_type=excel" +"&search_key=" + this.state.searchkey + "&active_status=true").
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              this.setState({disableBtn: false})
              }
            }).catch((error) => {
              this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.response.data.message,
                disableBtn: false,
              })
            })
        } catch (error) {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: t("PrintError"),
            disableBtn: false,
          })
        }
      }

    renderActivePopUp = () => {
        const { t } = this.props
        return (
            <>
                <Modal open={this.state.active} onClose={this.activePopUpClose} >
                    <Box className='inactive_popup'>
                        <div className='inactive_popup_header'>
                            <label className='inactive_popup_header_text'>{t('Do you want to Inactive Stock ?')}
                            {this.state.count === 0 ? 
                            <label className='eMed_pro_name'>{t("ProductName")} : <span style={{color:"red"}}>{this.state.selectedProductName}</span></label> 
                            : null}
                            </label>
                            <div className='inactive_popup_close_card'>
                                <img className='inactive_popup_close' src={ImagePaths.LabCloseButton.default}
                                    onClick={this.activePopUpClose} alt="close" />
                            </div>
                        </div>
                        <div className='inactive_popup_textfield'>
                            <TextField
                                sx={{ width: "57.5vw" }}
                                multiline maxRows={6}
                                label={t('Enter Reason')}
                                value={this.state.inActiveReason}
                                onChange={(e) => { this.onChangeHandler(e, "inActiveReason") }}
                            />
                        </div>
                        <div className='inactive_popup_footer'>
                            <div className='inactive_popup_left'>
                                <label className='inactive_popup_text'>
                                    <div className='inactive_bulletin'></div>
                                    <span
                                        className='inactive_span'>{t('Clear')} | </span>
                                    {t('Clear the form')}
                                </label>
                                <label className='inactive_popup_text'>
                                    <div className='inactive_bulletin'></div>
                                    <span className='inactive_span'>{t('Inactive')} | </span>
                                    {t('To inactive the Stock')}
                                </label>
                            </div>
                            <div className='inactive_popup_right'>
                                <Button
                                    sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                    variant='outlined'
                                    onClick={() => this.setState({ inActiveReason: "" })}
                                >{t('Clear')}</Button>
                                <Button
                                    emed_tid='ActiveInventory_Inactive_Btn'
                                    disabled={this.state.disableBtn}
                                    variant='contained'
                                    sx={{ textTransform: "capitalize" }}
                                    onClick={() => {
                                        this.setState({
                                            disableBtn:true
                                        },() => this.state.InactiveOneId !== "" ? this.postOneInActiveList() : this.postBulkInActiveList())
                                    }}
                                >{t('Inactive Stock')}</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </>
        )
    }

    renderActiveViewpopUp = () => {
        let { popUpActiveList } = this.state
        const { t } = this.props
        return (
            <>
                <Modal open={this.state.activeView} onClose={this.activeViewClose} >
                    <Box className='active_view_popup'>
                        <div className='active_view_header'>
                            <label className='active_view_header_text'>{t('productDetail')}</label>
                        </div>
                        <div className='active_view_middle_card'>
                            <div className='active_view_textfield'>
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('MaterialType') + "*"}
                                    value={popUpActiveList?.material_type}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('BrandName') + "*"}
                                    value={popUpActiveList?.brand_name}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('ProductName') + "*"}
                                    value={popUpActiveList?.product_name}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('ProductType') + "*"}
                                    value={popUpActiveList?.product_type}
                                    disabled
                                />
                            </div>
                            <div className='active_view_textfield'>
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('Unit') + "*"}
                                    value={popUpActiveList?.unit}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('LatestvendorName')}
                                    value={popUpActiveList?.vendor_name}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('LatestManufName')}
                                    value={popUpActiveList?.manufacturer_name}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('PhyBox')}
                                    value={popUpActiveList?.physical_box_no}
                                    disabled
                                />
                            </div>
                            <div className='active_view_textfield'>
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('HSNCode')}
                                    value={popUpActiveList?.hsn_code}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('GST') + "%"}
                                    value={popUpActiveList?.gst_percentage}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('ReorderThreshold')}
                                    value={popUpActiveList?.reorder_threshold}
                                    disabled
                                />
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('ExpiryPeriod')}
                                    value={popUpActiveList?.expiry_alert_period}
                                    disabled
                                />
                            </div>
                            <div className='active_view_textfield'>
                                <TextField
                                    size='small'
                                    sx={{ marginRight: '1vw', width: "14.2vw" }}
                                    label={t('UPC')}
                                    value={popUpActiveList?.bar_code}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='active_view_footer'>
                            <div className='active_view_left'>
                                <label className='active_view_text'>
                                    <div className='inactive_bulletin'></div>
                                    <span className='inactive_span'>{t('Close')} | </span>
                                    {t('Returnstockpage')}
                                </label>
                            </div>
                            <div className='active_view_right'>
                                <Button
                                 sx={{textTransform:"capitalize"}}
                                 variant='contained'
                                 onClick={()=>{this.activeViewClose()}}
                                 >{t('Close')}</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </>
        )
    }

    inActiveButton = () => {
        const {t} = this.props
        try {
            let count = this.state.count === 0 ? "" : this.state.count
            if (count !== "") {
                this.setState({
                    active: true,
                    activeStatus: false
                })
            } else {
                let message = t("inActiveProducts")
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: message
                })
            }
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error
            })
        }
    }

    render() {
        this.state.activeInventoryList.forEach((element, index) => {element.sno = index + 1})
        const { t } = this.props
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.142 },
            { field: 'material_type', headerName: t("MaterialType"), sortable: false, flex: 0.142,
            renderCell: (params) => (<div>{params?.row?.material_type?.length > 14 ? <Tooltip placement="top" title={params?.row?.material_type} arrow><div>{params?.row?.material_type.slice(0,10) +"..."}</div></Tooltip> : params?.row?.material_type ? params?.row?.material_type : "-"}</div>)},
            { field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.142,
            renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0,10) +"..."}</div></Tooltip> : params?.row?.brand_name ? params?.row?.brand_name : "-"}</div>)},
            { field: 'product_name', headerName: t('ProductName'), sortable: false, flex: 0.142,
            renderCell: (params) => (<div>{params?.row?.product_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.product_name} arrow><div>{params?.row?.product_name.slice(0,10) +"..."}</div></Tooltip> : params?.row?.product_name ? params?.row?.product_name : "-"}</div>)},
            { field: 'product_type', headerName: t('ProductType'), sortable: false, flex: 0.142,
            renderCell: (params) => <div>{params?.row?.product_type?.length > 14 ? <Tooltip placement="top" title={params?.row?.product_type} arrow><div>{params?.row?.product_type.slice(0,10) +"..."}</div></Tooltip> : params?.row?.product_type ? params?.row?.product_type : "-"}</div>},
            { field: 'stock_in_quantity', headerName: t('AvailableQuantity'),type:"number", sortable: false, flex: 0.142,
            renderCell: (params) => <div>{params?.row?.stock_in_quantity?.length > 14 ? <Tooltip placement="top" title={params?.row?.stock_in_quantity} arrow><div>{params?.row?.stock_in_quantity.slice(0,10) +"..."}</div></Tooltip> : params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity : "0"}</div> },
            { field: 'action', headerName: t("Action"), type: 'actions', sortable: false, flex: 0.142,
            renderCell: (cellvalues) => {return (<Stack direction="row">
            <Tooltip placement="top" title="InActive" arrow><div className='eMed_pause'>
                <IconButton disabled={!CheckAccessFunc("Laboratory", "Inventory", "Active Inventory", null, "Tab")?.editAccess} onClick={(e) => this.activePopUpOpen(e, cellvalues)}>
                    <img className='eMed_upload'  src={ImagePaths.LabPauseButton.default}  alt="" />
                </IconButton> </div></Tooltip>
            <Tooltip placement="top" title="View" arrow><div>
                <IconButton>
                <img className='eMed_upload'  src={ImagePaths.LabViewButton.default} onClick={(e) => this.activeViewOpen(e, cellvalues)} alt="" />
                </IconButton>
                </div></Tooltip>
             </Stack>)}
            }]
        return (
            <div className='active_tab_container'>
                <div className='active_tab_header_card'>
                    <Button variant="contained" className="checkbox" onClick={this.inActiveButton} disabled={!CheckAccessFunc("Laboratory", "Inventory", "Active Inventory", null, "Tab")?.editAccess}  >
                    {`Inactive (${this.state.count})`}
                    </Button>
                    {/* <img className='mov_image_card' src={ImagePaths.LabPrintIcon.default} alt='print' /> */}
                    <img className='eMed_upload' emed_tid='ActiveInventory_Export_Btn' src={ImagePaths.LabUploadButton.default} onClick={()=>{
                        if(!this.state.disableBtn){
                            this.getActiveInventoryexcel()
                        }}} alt="upload" />
                </div>
                <div className='active_tab_table_container'>
                    <DataGrid
                        checkboxSelection={CheckAccessFunc("Laboratory", "Inventory", "Active Inventory", null, "Tab")?.editAccess}
                        onSelectionModelChange={(checkbox) => {
                            this.setState({
                                checkboxSelection: checkbox,
                                count: checkbox.length,
                                activeStatus: false
                            })
                        }}
                        rows={this.state.activeInventoryList}
                        rowCount={this.state.total}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.product_ms_id}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getActiveInventoryList()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getActiveInventoryList())}
                        rowsPerPageOptions={[10, 20, 30]}
                        loading={this.state.isLoader}
                        pagination
                        paginationMode='server'
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                          }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay:()=>(
                              <Stack height="100%" alignItems="center" justifyContent="center">
                               {t("NoRecordsFound")}
                              </Stack>
                            )
                          }}
                    />
                </div>
                {this.renderActivePopUp()}
                {this.renderActiveViewpopUp()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.isSuccessMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
            </div>
        )
    }
}

export default withTranslation()(ActiveInventory)

import React, { Component } from 'react';
import { Box } from '@mui/material';
import PharmaRequestList from '../PharmacyInventory/PharmaRequestList';
import { withTranslation } from 'react-i18next';

class PharmaNewDrugApproval extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Box>
         <PharmaRequestList from = {"NewDrugApproval"}/>
      </Box>
    )
  }
}
export default withTranslation()(PharmaNewDrugApproval)

import { Box, Button, Chip, Divider, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import './OBGYNchartscss.css';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import { Colors } from '../../../../Styles/Colors';
import { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../../Components/Loader';
import { ImagePaths } from '../../../../Utility/ImagePaths';


class OvulationChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            PatientData: {},
            TherapyDatas: [],
            EndometriumDatas: [],
            ProcedureDatas: [],
            leftOvaryDatas: [],
            RightOvaryDatas: [],
            SelectedOvulation: {},
            LastMenstrualDate: null,
            Therapy: "",
            NewTherapy: "",
            NewTherapyAdd: false,
            Procedure: "",
            NewProcedure: "",
            NewProcedureAdd: false,
            Date: new Date(),
            RightOvary: "",
            NewRightOvaryAdd: false,
            NewRightOvary: "",
            LeftOvary: "",
            NewLeftOvary: "",
            NewLeftOvaryAdd: false,
            Endometrium: "",
            NewEndometrium: "",
            NewEndometriumAdd: false,
            PouchofDouglas: "",
            FollicularPhase: "",
            OvulatoryPhase: "",
            LutealPhase: "",
            Comments: "",
        }
    }

    componentDidMount() {
        let PatientData = getCachevalue("DoctorPatientData") ? JSON.parse(getCachevalue("DoctorPatientData")) : {}
        this.setState({
            PatientData: PatientData
        }, () => {
            this.GetOvulationData()
            this.PrevOvulationData()
            this.GetOvulationTemplates()
        })
    }

    GetOvulationTemplates() {
        try {
            RestAPIService.getAll(Serviceurls.OVULATION_INDUCTION_TEMPLATE_LIST + '?patient_id=' + this.state.PatientData?.patient_id).
                then((response) => {
                    if (response.data.status === "success") {
                        let Response = response.data.data ? response.data.data : {}
                        this.setState({
                            TherapyDatas: Response?.therapy ? Response?.therapy : [],
                            EndometriumDatas: Response?.endometrium ? Response?.endometrium : [],
                            ProcedureDatas: Response?.procedure ? Response?.procedure : [],
                            leftOvaryDatas: Response?.left_ovary ? Response?.left_ovary : [],
                            RightOvaryDatas: Response?.right_ovary ? Response?.right_ovary : [],
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    PrevOvulationData() {
        try {
            RestAPIService.getAll(Serviceurls.OVULATION_INDUCTION_LIST + '?patient_id=' + this.state.PatientData?.patient_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            PrevOvulationData: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetOvulationData(InductID = null) {
        try {
            let APIUrl = ""
            if (InductID) {
                APIUrl = Serviceurls.OVULATION_INDUCTION_DATA_GET + '?patient_id=' + this.state.PatientData?.patient_id + "&ovulation_induction_id=" + InductID
            } else {
                APIUrl = Serviceurls.OVULATION_INDUCTION_DATA_GET + '?patient_id=' + this.state.PatientData?.patient_id
            }
            this.setState({ isLoader: true })
            RestAPIService.getAll(APIUrl).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            OvulationHeader: response.data.data?.ovulation_induction_header ? response.data.data?.ovulation_induction_header : {},
                            OvulationTable: response.data.data?.ovulation_induction_values ? response.data.data?.ovulation_induction_values : [],
                            isLoader: false,
                        })
                    }
                    else {
                        this.setState({ isLoader: false })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ isLoader: false })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.setState({ isLoader: false })
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    TherapySelection = () => {
        const { t } = this.props
        return (
            <Box>
                <Typography className='eMed_charts_sub_title'>{t("Therapy")}</Typography>
                <Box className="eMed_DocNotes_FreqChips_Wrapp">
                    {
                        this.state.TherapyDatas?.length > 0 ? this.state.TherapyDatas.map((item, index) => (
                            <Chip
                                size='small'
                                className={`eMed_chip ${this.state.Therapy === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                key={index}
                                label={item}
                                variant={this.state.Therapy === item ? 'contained' : "outlined"}
                                onClick={() => { this.setState({ Therapy: item }) }}
                            />
                        )) : null
                    }
                    {this.state.NewTherapyAdd ?
                        <TextField
                            size='small'
                            value={this.state.NewTherapy}
                            onChange={(e) => {
                                this.setState({ NewTherapy: e.target.value })
                            }}
                            inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                            sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && this.state.NewTherapy !== "") {
                                    let List = this.state.TherapyDatas
                                    if (!List.includes(this.state.NewTherapy)) {
                                        List.push(this.state.NewTherapy)
                                    }
                                    this.setState({
                                        TherapyDatas: List,
                                        Therapy: this.state.NewTherapy,
                                        NewTherapy: "",
                                        NewTherapyAdd: false
                                    })
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={this.state.NewTherapy === ""}
                                            onClick={() => {
                                                let List = this.state.TherapyDatas
                                                if (!List.includes(this.state.NewTherapy)) {
                                                    List.push(this.state.NewTherapy)
                                                }
                                                this.setState({
                                                    TherapyDatas: List,
                                                    Therapy: this.state.NewTherapy,
                                                    NewTherapy: "",
                                                    NewTherapyAdd: false
                                                })
                                            }}>
                                            <AddIcon color={this.state.NewTherapy === "" ? 'grey' : 'primary'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} /> :
                        <IconButton
                            onClick={() => {
                                this.setState({
                                    NewTherapyAdd: true
                                })
                            }}>
                            <AddIcon color={'primary'} />
                        </IconButton>
                    }
                </Box>
            </Box>
        )
    }

    ProcedureSelection = () => {
        const { t } = this.props
        return (
            <Box>
                <Typography className='eMed_charts_sub_title'>{t("Procedure")}</Typography>
                <Box className="eMed_DocNotes_FreqChips_Wrapp">
                    {
                        this.state.ProcedureDatas?.length > 0 ? this.state.ProcedureDatas.map((item, index) => (
                            <Chip
                                size='small'
                                className={`eMed_chip ${this.state.Procedure === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                key={index}
                                label={item}
                                variant={this.state.Procedure === item ? 'contained' : "outlined"}
                                onClick={() => { this.setState({ Procedure: item }) }}
                            />
                        )) : null
                    }
                    {this.state.NewProcedureAdd ?
                        <TextField
                            size='small'
                            value={this.state.NewProcedure}
                            onChange={(e) => {
                                this.setState({ NewProcedure: e.target.value })
                            }}
                            inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                            sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && this.state.NewProcedure !== "") {
                                    let List = this.state.ProcedureDatas
                                    if (!List.includes(this.state.NewProcedure)) {
                                        List.push(this.state.NewProcedure)
                                    }
                                    this.setState({
                                        ProcedureDatas: List,
                                        Procedure: this.state.NewProcedure,
                                        NewProcedure: "",
                                        NewProcedureAdd: false
                                    })
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={this.state.NewProcedure === ""}
                                            onClick={() => {
                                                let List = this.state.ProcedureDatas
                                                if (!List.includes(this.state.NewProcedure)) {
                                                    List.push(this.state.NewProcedure)
                                                }
                                                this.setState({
                                                    ProcedureDatas: List,
                                                    Procedure: this.state.NewProcedure,
                                                    NewProcedure: "",
                                                    NewProcedureAdd: false
                                                })
                                            }}>
                                            <AddIcon color={this.state.NewProcedure === "" ? 'grey' : 'primary'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} /> :
                        <IconButton
                            onClick={() => {
                                this.setState({
                                    NewProcedureAdd: true
                                })
                            }}>
                            <AddIcon color={'primary'} />
                        </IconButton>
                    }
                </Box>
            </Box>
        )
    }

    RytOvarySelection = () => {
        const { t } = this.props
        return (
            <Box>
                <Typography className='eMed_charts_sub_title'>{t("Right Ovary")}</Typography>
                <Box className="eMed_DocNotes_FreqChips_Wrapp">
                    {
                        this.state.RightOvaryDatas?.length > 0 ? this.state.RightOvaryDatas.map((item, index) => (
                            <Chip
                                size='small'
                                className={`eMed_chip ${this.state.RightOvary === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                key={index}
                                label={item}
                                variant={this.state.RightOvary === item ? 'contained' : "outlined"}
                                onClick={() => { this.setState({ RightOvary: item }) }}
                            />
                        )) : null
                    }
                    {this.state.NewRightOvaryAdd ?
                        <TextField
                            size='small'
                            value={this.state.NewRightOvary}
                            onChange={(e) => {
                                this.setState({ NewRightOvary: e.target.value })
                            }}
                            inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                            sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && this.state.NewRightOvary !== "") {
                                    let List = this.state.RightOvaryDatas
                                    if (!List.includes(this.state.NewRightOvary)) {
                                        List.push(this.state.NewRightOvary)
                                    }
                                    this.setState({
                                        RightOvaryDatas: List,
                                        RightOvary: this.state.NewRightOvary,
                                        NewRightOvary: "",
                                        NewRightOvaryAdd: false
                                    })
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={this.state.NewRightOvary === ""}
                                            onClick={() => {
                                                let List = this.state.RightOvaryDatas
                                                if (!List.includes(this.state.NewRightOvary)) {
                                                    List.push(this.state.NewRightOvary)
                                                }
                                                this.setState({
                                                    RightOvaryDatas: List,
                                                    RightOvary: this.state.NewRightOvary,
                                                    NewRightOvary: "",
                                                    NewRightOvaryAdd: false
                                                })
                                            }}>
                                            <AddIcon color={this.state.NewRightOvary === "" ? 'grey' : 'primary'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} /> :
                        <IconButton
                            onClick={() => {
                                this.setState({
                                    NewRightOvaryAdd: true
                                })
                            }}>
                            <AddIcon color={'primary'} />
                        </IconButton>
                    }
                </Box>
            </Box>
        )
    }

    LftOvarySelection = () => {
        const { t } = this.props
        return (
            <Box>
                <Typography className='eMed_charts_sub_title'>{t("Left Ovary")}</Typography>
                <Box className="eMed_DocNotes_FreqChips_Wrapp">
                    {
                        this.state.leftOvaryDatas?.length > 0 ? this.state.leftOvaryDatas.map((item, index) => (
                            <Chip
                                size='small'
                                className={`eMed_chip ${this.state.LeftOvary === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                key={index}
                                label={item}
                                variant={this.state.LeftOvary === item ? 'contained' : "outlined"}
                                onClick={() => { this.setState({ LeftOvary: item }) }}
                            />
                        )) : null
                    }
                    {this.state.NewLeftOvaryAdd ?
                        <TextField
                            size='small'
                            value={this.state.NewLeftOvary}
                            onChange={(e) => {
                                this.setState({ NewLeftOvary: e.target.value })
                            }}
                            inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                            sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && this.state.NewLeftOvary !== "") {
                                    let List = this.state.leftOvaryDatas
                                    if (!List.includes(this.state.NewLeftOvary)) {
                                        List.push(this.state.NewLeftOvary)
                                    }
                                    this.setState({
                                        leftOvaryDatas: List,
                                        LeftOvary: this.state.NewLeftOvary,
                                        NewLeftOvary: "",
                                        NewLeftOvaryAdd: false
                                    })
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={this.state.NewLeftOvary === ""}
                                            onClick={() => {
                                                let List = this.state.leftOvaryDatas
                                                if (!List.includes(this.state.NewLeftOvary)) {
                                                    List.push(this.state.NewLeftOvary)
                                                }
                                                this.setState({
                                                    leftOvaryDatas: List,
                                                    LeftOvary: this.state.NewLeftOvary,
                                                    NewLeftOvary: "",
                                                    NewLeftOvaryAdd: false
                                                })
                                            }}>
                                            <AddIcon color={this.state.NewLeftOvary === "" ? 'grey' : 'primary'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} /> :
                        <IconButton
                            onClick={() => {
                                this.setState({
                                    NewLeftOvaryAdd: true
                                })
                            }}>
                            <AddIcon color={'primary'} />
                        </IconButton>
                    }
                </Box>
            </Box>
        )
    }

    EndometriumSelection = () => {
        const { t } = this.props
        return (
            <Box>
                <Typography className='eMed_charts_sub_title'>{t("Endometrium")}</Typography>
                <Box className="eMed_DocNotes_FreqChips_Wrapp">
                    {
                        this.state.EndometriumDatas?.length > 0 ? this.state.EndometriumDatas.map((item, index) => (
                            <Chip
                                size='small'
                                className={`eMed_chip ${this.state.Endometrium === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                key={index}
                                label={item}
                                variant={this.state.Endometrium === item ? 'contained' : "outlined"}
                                onClick={() => { this.setState({ Endometrium: item }) }}
                            />
                        )) : null
                    }
                    {this.state.NewEndometriumAdd ?
                        <TextField
                            size='small'
                            value={this.state.NewEndometrium}
                            onChange={(e) => {
                                this.setState({ NewEndometrium: e.target.value })
                            }}
                            inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                            sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && this.state.NewEndometrium !== "") {
                                    let List = this.state.EndometriumDatas
                                    if (!List.includes(this.state.NewEndometrium)) {
                                        List.push(this.state.NewEndometrium)
                                    }
                                    this.setState({
                                        EndometriumDatas: List,
                                        Endometrium: this.state.NewEndometrium,
                                        NewEndometrium: "",
                                        NewEndometriumAdd: false
                                    })
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={this.state.NewEndometrium === ""}
                                            onClick={() => {
                                                let List = this.state.EndometriumDatas
                                                if (!List.includes(this.state.NewEndometrium)) {
                                                    List.push(this.state.NewEndometrium)
                                                }
                                                this.setState({
                                                    EndometriumDatas: List,
                                                    Endometrium: this.state.NewEndometrium,
                                                    NewEndometrium: "",
                                                    NewEndometriumAdd: false
                                                })
                                            }}>
                                            <AddIcon color={this.state.NewEndometrium === "" ? 'grey' : 'primary'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} /> :
                        <IconButton
                            onClick={() => {
                                this.setState({
                                    NewEndometriumAdd: true
                                })
                            }}>
                            <AddIcon color={'primary'} />
                        </IconButton>
                    }
                </Box>
            </Box>
        )
    }

    renderMultiLineText = (label, value, stateKey) => {
        const { t } = this.props
        let states = this.state
        return (
            <TextField
                label={t(label)}
                sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "30vw" }}
                multiline={true}
                onChange={(e) => {
                    states[stateKey] = e.target.value
                    this.setState({ states })
                }}
                inputProps={{ maxLength: stateKey === "Comments" ? 300 : 100 }}
                value={value}
                rows={2} />
        )
    }

    PostOvulationData() {
        try {
            var states = this.state
            var countDays = Math.round((this.state.Date - this.state.LastMenstrualDate) / (1000 * 60 * 60 * 24))
            let data = {
                patient_id: this.state.PatientData.patient_id,
                last_menstrual_period: this.state.LastMenstrualDate ? DateTime.fromJSDate(new Date(this.state.LastMenstrualDate)).toFormat("yyyy-MM-dd") : "",
                therapy: this.state.Therapy,
                procedure: this.state.Procedure,
                follicular_phase: this.state.FollicularPhase,
                ovulatory_phase: this.state.OvulatoryPhase,
                luteal_phase: this.state.LutealPhase,
                comments: this.state.Comments,
                ovulation_induction_values: {
                    id: this.state.id ? this.state.id : "",
                    date_of_visit: this.state.Date ? DateTime.fromJSDate(new Date(this.state.Date)).toFormat("yyyy-MM-dd") : "",
                    day: countDays,
                    right_ovary: this.state.RightOvary,
                    left_ovary: this.state.LeftOvary,
                    endometrium: this.state.Endometrium,
                    pouch_of_douglas: this.state.PouchofDouglas,
                }
            }
            if (this.state.LastMenstrualDate) {
                RestAPIService.create(data, Serviceurls.OVULATION_INDUCTION_POST).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.PrevOvulationData()
                            this.GetOvulationData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Select the last menstrual date")
            }


        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClearData() {
        this.setState({
            SelectedOvulation: {},
            LastMenstrualDate: null,
            Therapy: "",
            NewTherapy: "",
            NewTherapyAdd: false,
            Procedure: "",
            NewProcedure: "",
            NewProcedureAdd: false,
            Date: new Date(),
            RightOvary: "",
            NewRightOvaryAdd: false,
            NewRightOvary: "",
            LeftOvary: "",
            NewLeftOvary: "",
            NewLeftOvaryAdd: false,
            Endometrium: "",
            NewEndometrium: "",
            NewEndometriumAdd: false,
            PouchofDouglas: "",
            FollicularPhase: "",
            OvulatoryPhase: "",
            LutealPhase: "",
            Comments: "",
            id: null
        })
    }

    onEditHandler(data) {
        this.setState({
            id: data.id ? data.id : "",
            LastMenstrualDate: this.state.OvulationHeader?.last_menstrual_period ? new Date(this.state.OvulationHeader?.last_menstrual_period) : null,
            Therapy: this.state.OvulationHeader?.therapy ? this.state.OvulationHeader?.therapy : "",
            Procedure: this.state.OvulationHeader?.procedure ? this.state.OvulationHeader?.procedure : "",
            Date: data.date_of_visit ? new Date(data.date_of_visit) : null,
            RightOvary: data.right_ovary ? data.right_ovary : "",
            LeftOvary: data.left_ovary ? data.left_ovary : "",
            Endometrium: data.endometrium ? data.endometrium : "",
            PouchofDouglas: data.pouch_of_douglas ? data.pouch_of_douglas : "",
            FollicularPhase: this.state.OvulationHeader?.follicular_phase ? this.state.OvulationHeader?.follicular_phase : "",
            OvulatoryPhase: this.state.OvulationHeader?.ovulatory_phase ? this.state.OvulationHeader?.ovulatory_phase : "",
            LutealPhase: this.state.OvulationHeader?.luteal_phase ? this.state.OvulationHeader?.luteal_phase : "",
            Comments: this.state.OvulationHeader?.comments ? this.state.OvulationHeader?.comments : "",
        })
    }

    onDeleteHandler(data) {
        try {
            this.setState({
                isDeleteCliked: true,
                DeletableData: data,
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose() {
        this.setState({ isDeleteCliked: false, DeletableData: null })
    }

    DeleteOvulation() {
        try {
            let data = {
                ovulation_induction_id: this.state?.DeletableData?.id,
            }
            RestAPIService.create(data, Serviceurls.OVULATION_INDUCTION_DELETE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({ isDeleteCliked: false, DeletableData: null }, () => { this.GetOvulationData() })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    PrintOvulationData = () => {
        try {
            RestAPIService.getAll(Serviceurls.OVULATION_INDUCTION_PRINT + `?patient_id=${this.state.PatientData.patient_id}`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        const { t } = this.props
        return (
            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                <Box className="eMed_Chart_Layout" sx={{}} flex={0.65}>
                    <Typography className='eMed_refdoc_title'>{t("Ovulation Chart")}
                        <Button
                            className='Common_Btn_Min_Width'
                            onClick={() => { this.PrintOvulationData() }}
                        >
                            <Tooltip placement='top' title='Print'>
                                <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                            </Tooltip>
                        </Button>
                    </Typography>
                    <Box sx={{ height: "65vh", overflow: "auto" }}>
                        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginY: "0.5vw" }}>
                            <Box sx={{ margin: "1vw" }}>
                                <Typography sx={{ fontSize: '0.9vw', fontWeight: 600 }}>Last Menstrual Period</Typography>
                                <Typography>{this.state.OvulationHeader?.last_menstrual_period ? formatDate(this.state.OvulationHeader?.last_menstrual_period) : "-"}</Typography>
                            </Box>
                            <Box sx={{ margin: "1vw" }}>
                                <Typography sx={{ fontSize: '0.9vw', fontWeight: 600 }}>Therapy</Typography>
                                <Typography>{this.state.OvulationHeader?.therapy ? this.state.OvulationHeader?.therapy : "-"}</Typography>
                            </Box>
                            <Box sx={{ margin: "1vw" }}>
                                <Typography sx={{ fontSize: '0.9vw', fontWeight: 600 }}>Procedure</Typography>
                                <Typography>{this.state.OvulationHeader?.procedure ? this.state.OvulationHeader?.procedure : "-"}</Typography>
                            </Box>
                        </Box>
                        <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background, paddingLeft: '3vw' }}>Date</TableCell>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Days</TableCell>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Right Ovary</TableCell>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Left Ovary</TableCell>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Endometrium</TableCell>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Pouch of Douglas</TableCell>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.OvulationTable?.length > 0 ? this.state.OvulationTable?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.date_of_visit ? formatDate(item?.date_of_visit) : "-"}</Typography></TableCell>
                                            <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.day ? item?.day : "-"}</Typography></TableCell>
                                            <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.right_ovary ? item?.right_ovary : "-"}</Typography></TableCell>
                                            <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.left_ovary ? item?.left_ovary : "-"}</Typography></TableCell>
                                            <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.endometrium ? item?.endometrium : "-"}</Typography></TableCell>
                                            <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.pouch_of_douglas ? item?.pouch_of_douglas : "-"}</Typography></TableCell>
                                            <TableCell>
                                                <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                                                    <CommonEditButton size={"1.2vw"} onClick={this.onEditHandler.bind(this, item)} />
                                                    <CommonDeleteButton disable={DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd") !== item.date_of_visit} size={"1.2vw"} onClick={this.onDeleteHandler.bind(this, item)} />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )) :
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginY: "0.5vw" }}>
                            <Box>
                                <TextField
                                    label={"Follicular Phase"}
                                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "30vw" }}
                                    multiline={true}
                                    InputProps={{ readOnly: true }}
                                    inputProps={{ maxLength: 250 }}
                                    value={this.state.OvulationHeader?.follicular_phase ? this.state.OvulationHeader?.follicular_phase : "-"}
                                    variant='standard'
                                    rows={4} />
                            </Box>
                            <Box>
                                <TextField
                                    label={"Ovulatory Phase"}
                                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "30vw" }}
                                    multiline={true}
                                    InputProps={{ readOnly: true }}
                                    inputProps={{ maxLength: 250 }}
                                    value={this.state.OvulationHeader?.ovulatory_phase ? this.state.OvulationHeader?.ovulatory_phase : "-"}
                                    variant='standard'
                                    rows={4} />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginY: "0.5vw" }}>
                            <Box>
                                <TextField
                                    label={"Luteal Phase"}
                                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "30vw" }}
                                    multiline={true}
                                    InputProps={{ readOnly: true }}
                                    inputProps={{ maxLength: 250 }}
                                    value={this.state.OvulationHeader?.luteal_phase ? this.state.OvulationHeader?.luteal_phase : "-"}
                                    variant='standard'
                                    rows={4} />
                            </Box>
                            <Box>
                                <TextField
                                    label={"Comments"}
                                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "30vw" }}
                                    multiline={true}
                                    InputProps={{ readOnly: true }}
                                    inputProps={{ maxLength: 250 }}
                                    value={this.state.OvulationHeader?.comments ? this.state.OvulationHeader?.comments : "-"}
                                    variant='standard'
                                    rows={4} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="eMed_Chart_Layout" sx={{}} flex={0.35}>
                    <Typography className='eMed_charts_sub_title'>
                        {t("Previous Ovulation Chart")}
                        {/* <Button onClick={() => { this.ClearData() }}>New</Button> */}
                    </Typography>
                    <Box sx={{ height: "65vh", overflow: "auto" }}>
                        <Box>
                            <Box className="eMed_DocNotes_FreqChips_Wrapp">
                                {
                                    this.state.PrevOvulationData?.length > 0 ? this.state.PrevOvulationData.map((item, index) => (
                                        <Chip
                                            size='small'
                                            className={`eMed_chip ${this.state.SelectedOvulation?.last_menstrual_period === item?.last_menstrual_period ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                            key={index}
                                            label={item?.last_menstrual_period}
                                            variant={this.state.SelectedOvulation?.last_menstrual_period === item?.last_menstrual_period ? 'contained' : "outlined"}
                                            onClick={() => { this.setState({ SelectedOvulation: item }, () => { this.GetOvulationData(item?.id) }) }}
                                        />
                                    )) : null
                                }
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '1vw', marginY: "0.5vw" }}>
                            <Typography >{t("Last Menstrual Date *")}</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    open={this.state.LastMensDatePickerOpen}
                                    onOpen={() => { this.setState({ LastMensDatePickerOpen: true }) }}
                                    onClose={() => { this.setState({ LastMensDatePickerOpen: false }) }}
                                    value={this.state.LastMenstrualDate}
                                    name={"datePicker"}
                                    inputFormat='DD-MM-YYYY'
                                    views={["year", "month", "day"]}
                                    maxDate={new Date()}
                                    disabled={this.state.id}
                                    onChange={
                                        (newDate) => {
                                            this.setState({
                                                LastMenstrualDate: newDate,
                                            });
                                        }}
                                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ marginLeft: '2vw' }} size='small' {...params} onClick={() => { this.setState({ LastMensDatePickerOpen: true }) }} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        {this.TherapySelection()}
                        {this.ProcedureSelection()}
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '1vw', marginY: "0.5vw" }}>
                            <Typography >{t("Date")}</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    open={this.state.DatePickerOpen}
                                    onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                                    onClose={() => { this.setState({ DatePickerOpen: false }) }}
                                    value={this.state.Date}
                                    name={"datePicker"}
                                    inputFormat='DD-MM-YYYY'
                                    views={["year", "month", "day"]}
                                    maxDate={new Date()}
                                    onChange={
                                        (newDate) => {
                                            this.setState({
                                                Date: newDate,
                                            });
                                        }}
                                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ marginLeft: '2vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        {this.RytOvarySelection()}
                        {this.LftOvarySelection()}
                        {this.EndometriumSelection()}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '1vw', marginTop: "0.5vw" }}>
                            <Typography >{t("Pouch of Douglas")}</Typography>
                            <TextField
                                label={t("Enter Pouch of Douglas")}
                                style={{ marginLeft: '2vw' }}
                                size='small'
                                value={this.state.PouchofDouglas}
                                onChange={(e) => { this.setState({ PouchofDouglas: e.target.value }) }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">ml</InputAdornment>
                                    ),
                                }} />
                        </Box>
                        {this.renderMultiLineText("Follicular Phase", this.state.FollicularPhase, "FollicularPhase")}
                        {this.renderMultiLineText("Ovulatory Phase", this.state.OvulatoryPhase, "OvulatoryPhase")}
                        {this.renderMultiLineText("Luteal Phase", this.state.LutealPhase, "LutealPhase")}
                        {this.renderMultiLineText("Comments", this.state.Comments, "Comments")}
                        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <Button variant='outlined' onClick={() => { this.ClearData() }}>Clear</Button>
                            <Button sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => { this.PostOvulationData() }}>Add</Button>
                        </Box>
                    </Box>
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={`Are you sure ? Do you want to delete this ovulation ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.DeleteOvulation.bind(this)}
                /> : null}
            </Box>
        )
    }
}
export default withTranslation()(OvulationChart)
import React, { Component } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
 TableRow, IconButton, TextField, Stack, Paper , Divider, Modal, Button, Tooltip} from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Trans, withTranslation } from 'react-i18next';
import './purchase.css';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { DateTime } from "luxon";
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from "../../../Utility/Constants";

const Numeric = /^\d*$/ ;

class ConfirmOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor_id : this.props.vendor_id ? this.props.vendor_id : null,
            data: {
                result:[],
                vendor_name:"",
                vendor_id: null,
                vendor_mobile: null,
                vendor_address: null,
                laboratory_name: "",
                laboratory_mobile: null,
                laboratory_address: "",
                total_amount: null
            },
            po_date: new Date(),
            open: true,
            isPlaceOrder: false,
        }
    }
    componentDidMount() {
        this._getConfirmOrderList();
    }

    _getConfirmOrderList = () =>{
        this.setState({
            data: {
                result:[],
                vendor_name:"",
                vendor_id: null,
                vendor_mobile: null,
                vendor_address: null,
                laboratory_name: "",
                laboratory_mobile: null,
                laboratory_address: "",
                total_amount: null
            }
        })
        let {data} = this.state;
        if(this.state.vendor_id){
        RestAPIService.getAll(`${Serviceurls.LAB_CONFIRM_ORDER_LIST}?vendor_id=${this.state.vendor_id}`)
            .then(response => {
                if (response.data.status == "sucess") {
                    data["result"] = response.data.data.result ? response.data.data.result : [];
                    data["vendor_name"]= response.data.data.vendor_name ? response.data.data.vendor_name : "";
                    data["vendor_id"]= response.data.data.vendor_id ? response.data.data.vendor_id : "";
                    data["vendor_mobile"]= response.data.data.vendor_mobile ? response.data.data.vendor_mobile : "";
                    data["vendor_address"]= response.data.data.vendor_address ? response.data.data.vendor_address : "";
                    data["laboratory_name"]= response.data.data.laboratory_name ? response.data.data.laboratory_name : "";
                    data["laboratory_mobile"]= response.data.data.laboratory_mobile ? response.data.data.laboratory_mobile : "";
                    data["laboratory_address"]= response.data.data.laboratory_address ? response.data.data.laboratory_address : "";
                    data["total_amount"]= response.data.data.total_amount ? response.data.data.total_amount : 0;

                    this.setState({
                        data
                    });
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
            });
        }
    }

    SuccessErrorBox = (message,type) => {
        this.props.responseSuccessErrorMessage(message,type)
    }

    handlePopUpClose = () => {
        this.setState({
            open: false,
            vendor_id: null,
            data:{
                result:[],
                vendor_name:"",
                vendor_id: null,
                vendor_mobile: null,
                vendor_address: null,
                laboratory_name: "",
                laboratory_mobile: null,
                laboratory_address: "",
                total_amount: null
            }
        },()=>{this.props.goBack("order_List")})
    }

    deleteItem = (id)=>{
        if(id){
            RestAPIService.delete(`${Serviceurls.LAB_CONFIRM_ORDER_LIST}?id=${id}`)
            .then(response => {
                if (response.data.status == "success") {
                    this.SuccessErrorBox(response.data.message, true);
                    this.handlePopUpClose();
                    this._getConfirmOrderList();
                }
              })
              .catch(error =>{
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
              })
        }
    }

    onClickPlaceOrder = () => {
        let {data,po_date} = this. state
        let tempdata = {}
        let datas = {
                vendor_id: data["vendor_id"],
                purchase_order_date: DateTime.fromJSDate(po_date).toFormat("yyyy-MM-dd"),
                line_items: []
        }
        data["result"].map((item,index)=>{
           tempdata =  {
                serial_no: index + 1,
                brand_name: item.brand_name,
                product_name: item.product_name,
                material_type: item.material_type,
                product_type: item.product_type,
                unit:item.unit,
                order_quantity: item.order_quantity,
                price_per_quantity: item.price_per_quantity,          
                id:item.id
            }
            datas["line_items"].push(tempdata)
        })
        RestAPIService.create(datas,Serviceurls.LAB_CONFIRM_PLACE_ORDER)
        .then(response => {
            if (response.data.status == "success") {
                this.SuccessErrorBox(response.data.message, true);
                this.setState({
                    isPlaceOrder: false,
                    data:{
                        result:[],
                        vendor_name:"",
                        vendor_id: null,
                        vendor_mobile: null,
                        vendor_address: null,
                        laboratory_name: "",
                        laboratory_mobile: null,
                        laboratory_address: "",
                        total_amount: null
                    }
                },()=>{this.props.goBack("order_List")});
            }
          })
          .catch(error =>{
            this.setState({isPlaceOrder: false,})
            if (error.response.data.status == "fail") {
                this.SuccessErrorBox(error.response.data.message, false);
            }
          })
    }

    render() {
        const { t } = this.props;
        let{ data, open } = this.state
        let vendor_name = data.vendor_name ? data.vendor_name : "";
        let vendor_address = data.vendor_address ? data.vendor_address : "";
        let vendor_mobile = data.vendor_mobile ? data.vendor_mobile : "-";
        let laboratory_name = data.laboratory_name ? data.laboratory_name : "";
        let laboratory_mobile = data.laboratory_mobile ? data.laboratory_mobile : "-";
        let laboratory_address = data.laboratory_address ? data.laboratory_address : "";
        let total_amt = data.total_amount ? data.total_amount : "";
        return (
            <Modal open={open && data["result"].length > 0}>
                <Paper elevation={3} id="emedhub_vendorDetails_mainBox" sx={{ width: "95vw", maxHeight:"60vw" }}>
                    <div id="emedhub_vendorDetails_header">
                        <Typography>{t("PurchaseOrder")}</Typography>
                        <IconButton size="small" onClick={() => { this.handlePopUpClose() }}><img className="emedhub_popupIcon_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                    </div>
                    <Box className="emedhub_confirmOrder_mainDiv">
                        <Paper id="emedhub_confirmOrder_subDiv1" elevation={0}>
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{t("VendorDetails")}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{vendor_name}</Typography>
                                <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>{vendor_address}</Typography>
                                <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>{vendor_mobile}</Typography>
                            </div>
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{t("PlaceOfSupply")}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{laboratory_name}</Typography>
                                <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>{laboratory_address}</Typography>
                                <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>{laboratory_mobile}</Typography>
                            </div>
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{t("OrderPlaced")}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{laboratory_name}</Typography>
                                <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>{laboratory_address}</Typography>
                            </div>
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{t("Totalvaluegst")}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{`${CurrencySymbol} ${total_amt}`}</Typography>
                            </div>
                        </Paper>
                        <Divider />
                        <div id="emedhub_confirmOrder_subDiv2">
                            <TableContainer className='emedhub_confirmOrder_Table'>
                                <Table stickyHeader size='small'>
                                    <TableHead className="emedhub_directpoTable_head" sx={{ height: "5vh" }}>
                                        <TableRow>
                                            <TableCell sx={{ flex: 0.03 }} id="emedhub_directpoTable_headTxt">{t("")}</TableCell>
                                            <TableCell sx={{ flex: 0.04 }} id="emedhub_directpoTable_headTxt">{t("SNo")}</TableCell>
                                            <TableCell sx={{ flex: 0.12 }} id="emedhub_directpoTable_headTxt">{t("BrandName")}</TableCell>
                                            <TableCell sx={{ flex: 0.11 }} id="emedhub_directpoTable_headTxt">{t("ProductType")}</TableCell>
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{t("ProductName")}</TableCell>
                                            <TableCell sx={{ flex: 0.3 }} id="emedhub_directpoTable_headTxt"> {t("OrderperQty")}</TableCell>
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{t("PurchasePrice/Quantity")}</TableCell>
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{t("Quantity")}</TableCell>
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{t("TotalValue")+ ` (${CurrencySymbol})`}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="emedhub_directpoTable_head">
                                        {data["result"] && data["result"].length > 0 && data["result"].map((item, index) => {
                                            let sno = (index + 1)
                                            let total_value = AmountFormat(item.total_value)
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ flex: 0.03 }}><Tooltip title={t("Delete")} placement="top" arrow><IconButton size='small'>
                                                    <img src={ImagePaths.Delete.default} alt='Delete'
                                                            onClick={() => { this.deleteItem(item.id) }} 
                                                            className='eMed_dir_del'/>
                                                    </IconButton></Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.04 }}>{sno}</TableCell>
                                                    <TableCell sx={{ flex: 0.12 }}>{item.brand_name}</TableCell>
                                                    <TableCell sx={{ flex: 0.11 }}>{item.product_type}</TableCell>
                                                    <TableCell sx={{ flex: 0.1 }}>{item.product_name}</TableCell>
                                                    <TableCell sx={{ flex: 0.3 }}>
                                                        <Stack spacing={1} direction="row" justifyContent="flex-start" >
                                                            <TextField
                                                                name="order_quantity"
                                                                value={item.order_quantity}
                                                                size="small"
                                                                onChange={(e) => {
                                                                    let value = parseInt(e.target.value);
                                                                    if ((Numeric.test(value))) {
                                                                        data["result"][index]['order_quantity'] = value
                                                                        let buyingPrice = parseInt(value) * parseFloat(item.mrp_per_quantity)
                                                                        data["result"][index]["price_per_quantity"] = Math.round(buyingPrice)
                                                                        this.setState({
                                                                            data
                                                                        })
                                                                    }
                                                                }}
                                                                sx={{ width: "6vw" }}
                                                            />
                                                            <TextField
                                                                name="unit"
                                                                value={item.unit ? item.unit : ""}
                                                                size="small"
                                                                sx={{ width: "6vw" }}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.1 }}>
                                                        <Stack>
                                                            <TextField
                                                                name="price_per_quantity"
                                                                value={item.price_per_quantity}
                                                                size="small"
                                                                sx={{ width: "8vw" }}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            /></Stack>
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.1 }}>{item.available_quantity}</TableCell>
                                                    <TableCell sx={{ flex: 0.1 }}>{total_value ? total_value.replace(`${CurrencySymbol}`,"") : 0}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Box>
                    <div id="emedhub_vendorDetails_footer" >
                        <div>
                            <div className="emedhub_vendorDetails_footer_subdiv">
                                <div className="emedhub_vendorDetails_bulletin"></div>
                                <p className="emedhub_vendorDetails_footer_Text1">{t("Cancel")}</p>
                                <p className="emedhub_vendorDetails_footer_Text2">{t("CancelProcess")}</p>
                            </div>
                            <div className="emedhub_vendorDetails_footer_subdiv">
                                <div className="emedhub_vendorDetails_bulletin"></div>
                                <p className="emedhub_vendorDetails_footer_Text1">{t("PlaceOrder")}</p>
                                <p className="emedhub_vendorDetails_footer_Text2">{t("Orderwillbeplaced")}</p>
                            </div>
                        </div>
                        <Stack direction="row" spacing={2}>
                            <Button size="small" sx={{ textTransform: "capitalize" }} variant={"outlined"} onClick={() => { this.handlePopUpClose() }}>{t("Cancel")}</Button>
                            <Button size="small" sx={{ textTransform: "capitalize" }} disabled={this.state.isPlaceOrder} variant="contained" onClick={() => { this.setState({isPlaceOrder: true},()=>{this.onClickPlaceOrder()}) }}>{t("PlaceOrder")}</Button>
                        </Stack>
                    </div>
                </Paper>
            </Modal>
        )
    }
}

export default withTranslation()(ConfirmOrderList);
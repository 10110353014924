import React, { Component } from 'react'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Colors } from '../../../../Styles/Colors'
import { Days, DurationDays, Months, Weeks, Year } from '../../../../Utility/Constants'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Search } from '@material-ui/icons'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import ShowComponents from './ShowComponent';

export default class DocSymptoms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientId: this.props.patientId,
      appointmentId: this.props.appointmentId,
      clinicId: this.props.clinicId,
      symptomNameList: [],
      symptomsData: [],
      durationDays: Days,
      durationWeeks: Weeks,
      durationMonts: Months,
      durationYears: Year,
      durationDay: DurationDays,
      selectedSymptomName: '',
      selectedDays: '1',
      selectedDuration: '',
      symptomDescrp: '',
      selectedId: null,
      presentComplaint: '',
      presentComplaintHistory: '',
      openPopUp: false,
      loading: false,
      postLoading: false,
      isEdit: false,
      deletePopup: false,
      removeData: false,
      isLocked: false
    }
  }

  componentDidMount() {
    // let clinicId = getCachevalue('SelectedDoctorClinic')
    // let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    // this.setState({
    //   clinicId: clinicId,
    //   appointmentId: appointmentId?.appointment_id,
    //   patientId: appointmentId?.patient_id,
    //   isLocked: appointmentId?.is_locked
    // }, () => {
    //   this.getSymptomData()
    // })
    // this.apiCalls();
  }

  apiCalls = () => {
    this.getSymptomData()
    this.getSymptomFrequentData()
  }

  getSymptomFrequentData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMNS_FREQ)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              symptomNameList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSymptomData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMS + `?appointment_id=${this.props.appointmentId}&patient_id=${this.props.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              symptomsData: response.data.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postSymptomData = () => {
    try {
      let data = {
        appointment_id: this.props.appointmentId,
        patient_id: this.props.patientId,
        duration: this.state.selectedDuration,
        duration_mode: this.state.selectedDays,
        id: this.state.selectedId ? this.state.selectedId : null,
        presenting_complaints: this.state.presentComplaint,
        presenting_complaints_history: this.state.presentComplaintHistory,
        symptom: this.state.selectedSymptomName,
        symptom_desc: this.state.symptomDescrp
      }
      let validate = this.checkValidation()

      if (validate === null) {
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMS)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.onClearHandler()
              this.getSymptomData()
              this.setState({ openPopUp: false, postLoading: false, isEdit: false })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(validate)
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  deleteSymptomnsData = () => {
    try {
      let data = {
        appointment_id: this.props.appointmentId,
        symptoms_id: this.state.selectedId
      }
      this.setState({ removeData: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SYMPTOM_DELETE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.onClearHandler()
            this.getSymptomData()
            this.setState({ openPopUp: false, removeData: false, isEdit: false, deletePopup: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e) => {
    try {
      let value = e.target.value
      let states = this.state
      switch (states.selectedDays) {
        case '1':
          if (CommonValidation.numberOnly(value) && value <= 31 || value === '') {
            this.setState({ selectedDuration: value })
          }
          break;
        case '2':
          if (CommonValidation.numberOnly(value) && value <= 15 || value === '') {
            this.setState({ selectedDuration: value })
          }
          break;
        case '3':
          if (CommonValidation.numberOnly(value) && value <= 11 || value === '') {
            this.setState({ selectedDuration: value })
          }
          break;
        case '4':
          if (CommonValidation.numberOnly(value) && value <= 15 || value === '') {
            this.setState({ selectedDuration: value })
          }
          break;
        default: break;
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null
      let states = this.state
      if (states.selectedSymptomName === '') {
        message = 'Select or Enter Symptom Name'
      } else if (states.selectedDays === '' || states.selectedDuration === '') {
        message = 'Select Days and Duration'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        selectedDays: '1',
        selectedDuration: '',
        selectedSymptomName: '',
        symptomDescrp: '',
        isEdit: false,
        selectedId: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        isEdit: true,
        selectedDays: item?.duration_mode,
        selectedSymptomName: item?.symptom,
        selectedDuration: item?.duration,
        symptomDescrp: item?.symptom_desc,
        selectedId: item?.id
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (!this.state.isEdit) {
        this.setState({
          deletePopup: true,
          selectedId: item
        })
      } else {
        this.errorMessage('You have click edit button.So, you cannot able to delete')
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderSymptomsTable = () => {
    try {
      let states = this.state
      return (
        <Box component={'div'} border={'1px solid lightgray'} m={'0.5vw'} maxHeight={'10vw'} overflow={'scroll'}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell width={'40%'} sx={{ backgroundColor: Colors.Background, color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.8vw' }}>Symptoms</TableCell>
                <TableCell width={'40%'} sx={{ backgroundColor: Colors.Background, color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.8vw' }}>Duration</TableCell>
                <TableCell width={'20%'} sx={{ backgroundColor: Colors.Background, color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.8vw' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                states.symptomsData?.length > 0 ? states.symptomsData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell width={'40%'} sx={{ fontWeight: 600, fontSize: '0.8vw' }}>{item?.symptom ? item?.symptom : '-'}</TableCell>
                    <TableCell width={'40%'} sx={{ fontWeight: 600, fontSize: '0.8vw' }}>{item?.duration ? `${item?.duration} - ${item?.duration_mode === '1' ? 'Days' : item?.duration_mode === '2' ? 'Weeks' : item?.duration_mode === '3' ? 'Months' : 'Years'}` : '-'}</TableCell>
                    <TableCell width={'20%'}>
                      <Box component={'div'} display={'flex'}>
                        <Button className='Common_Btn_Min_Width'
                          onClick={() => this.onEditHandler(item)}
                        >
                          <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1vw'} width={'1vw'} />
                        </Button>
                        <Button className='Common_Btn_Min_Width'
                          onClick={() => this.onDeleteHandler(item?.id)}
                        >
                          <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1vw'} width={'1vw'} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell />
                    <TableCell align='right' sx={{fontSize: '0.7vw'}}>No Records To Be Shown</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
              }
            </TableBody>
          </Table>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    var states = this.state
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.white }} height={'16vw'}>
          <Box component={'div'} height={'2vw'} backgroundColor={Colors.Background} borderBottom={'1px solid lightgray'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.8vw'} fontWeight={600}>Symptoms</Typography>
          </Box>
          <Box display={'flex'} m={'.5vw'}>
            <Autocomplete
              size='small'
              sx={{ width: '8vw', height:'1vw' }}
              clearIcon
              freeSolo
              disabled={this.props.isLocked}
              options={this.state.symptomNameList}
              value={this.state.selectedSymptomName}
              onChange={(e, value) => {
                this.setState({
                  selectedSymptomName: value
                })
              }}
              onInputChange={(e, value) => {
                this.setState({
                  selectedSymptomName: value
                })
              }}
              renderInput={(params) => <TextField {...params}
                variant='outlined'
                label='Enter Symptoms' />}
            />
            <Box component={'div'} mr={'.5vw'} display={'flex'}>
              <TextField
                size='small'
                disabled={this.props.isLocked}
                sx={{ width: '5vw', ml: '0.5vw' }}
                variant='outlined'
                value={states.selectedDuration}
                onChange={(e) => this.onChangeHandler(e)}
              />
              <FormControl size='small'>
                <Select
                  variant='outlined'
                  disabled={this.props.isLocked}
                  value={states.selectedDays}
                  onChange={(e) => this.setState({ selectedDays: e.target.value }, () => this.setState({ selectedDuration: '' }))}
                >
                  {states.durationDay?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button className='Common_Btn_Min_Width'
            variant='contained'
            sx={{textTransform: 'capitalize'}}
              disabled={this.props.isLocked}
              onClick={() => this.postSymptomData()}
            >
              Add
            </Button>
          </Box>
          <Box component={'div'}>
            {this.renderSymptomsTable()}
          </Box>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.deletePopup ? <DeletePopup
            DeletTitle={`Are you sure you want to delete ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.deleteSymptomnsData.bind(this)}
            disable={this.state.removeData}
          /> : null}
        </Box>
      </ShowComponents>
    )
  }
}

import React, { Component } from "react";
import '../IpDemograhics.css';
import { Box} from "@mui/material";
import { withTranslation } from 'react-i18next';
import { getCachevalue } from "../../../../../Utility/Services/CacheProviderService";
import { t } from "i18next";
import Medication from "../../../IPPatients/PatientsDetails/ClinicalNotes/Medication";

class BillingMedication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip_id: null
    }
  }
  componentDidMount() {
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    this.setState({
      ip_id: details?.ip_admission_id
    })
  }
  render() {
    return (
      <Box >
        <Medication fromBillMedication = {true} ip_admission_id = {this.state?.ip_id}/>
      </Box>
    )
  }
}
export default withTranslation()(BillingMedication)

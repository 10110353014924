import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Autocomplete, Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, FormControlLabel, Stack, TextField, Tooltip, Typography } from '@mui/material';
import CommonGridHeader, { CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import './CorpStyle.css'
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Colors } from '../../../Styles/Colors';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import CreditBillPopUp from '../../FrontOffice/BillingTransaction/CreditBillPopUp';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../../Utility/Constants';

let BillList = []

class PatientDetails extends Component {
    constructor() {
        super()
        this.state = {
            page: 0,
            pageSize: 10,
            PatientBillList: [],
            BillList: [],
            SelectedBillList: [],
            corpCompanyList: [],
            PatientBillListAfterPagination: [],

            date: {},
            dateValue: [],
            filterDate: {},
            filterData: {
                fromDate: new Date(),
                toDate: new Date(),
                label: "Today"
            },
            CorporateSort: "asc",
            BillDateSort: "asc",
            corpSortClicked: true,
            DateSortClicked: false,
            isAllBillsSelected: false,

            FilterPopUpOpen: false,
            isFilterclear: false,
            CompanyNameFilter: false,
            DateFilter: false,
            EmpNameFilter: false,
            BillTypeFilter: false,
            CompanyNameInput: "",
            EmpNameInput: "",
            BilltypeInput: "",
            SelectedCorp: "",
            GenerateBillType: 'consolidate',
            GeneratePopupErrorText: '',
            GenarateBillDescription: '',

            CompanyFiltered: '',
            DateFiltered: {},
            EmployeeFiltered: "",
            BillTypeFiltered: "",
            SelectedCompanyCount: 0,
            DateLabelFiltered: "",
            DateFromDateFiltered: new Date(),
            DateToDateFiltered: new Date(),
            SelectedCorporateId: "",
            SelectedCorporateName: "",

            FilterOpen: false,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            FilterSelectedCorp: [],
            isOPselected: false,
            isIPselected: false,
            isLabselected: false,
            isPharmacyselected: false,
            employeeName:"",
            SelectedBill:{},
            CreditBillPopUp: false
        }
    }

    componentDidMount() {
        this.getPatientDetails()
        this.getCorporateList()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getPatientDetails() {
        try {
            let CorpList = [];
            this.state.FilterSelectedCorp.forEach(element => CorpList.push(element.company_name))
            // let BillTypeList = [];
            // if (this.state.isIPselected) {
            //     BillTypeList.push("ip")
            // }
            // if (this.state.isOPselected) {
            //     BillTypeList.push("op")
            // }
            // if (this.state.isPharmacyselected) {
            //     BillTypeList.push("pharmacy")
            // }
            // if (this.state.isLabselected) {
            //     BillTypeList.push("lab")
            // }
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.GET_PATIENT_BILLING_DEATILS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&employee_name=${this.state.employeeName}&company_name=${CorpList}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            PatientBillList: response.data.data
                        }, ()=>{
                            this.LoaderFunction(false)
                        }
                        
                        // , () => {
                        //     this.state.PatientBillList.forEach(object => {
                        //         object.isSelected = false;
                        //     })
                        //     this.setState({
                        //         PatientBillList: this.state.PatientBillList
                        //     }, () => { 
                        //         // this.storeSelectedBills()
                        //      })
                        // }
                        )

                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    getCorporateList() {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            corpCompanyList: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            CreditBillPopUp: false,
            BillPreviewPop: false
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.getPatientDetails()
            }
        })
    }
    

    CrebillPosted(message) {
        this.setState({
            CreditBillPopUp: false,

        }, () => {
            this.successMessage(message)
            this.getPatientDetails()
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    storeSelectedBills = () => {
        this.state.SelectedBillList = this.state.BillList.filter((item) => item.isSelected == true)
        this.setState({
            SelectedBillList: this.state.SelectedBillList
        }, () => {
            if (this.state.BillList && this.state.BillList.length > 0) {
                const PendingBillsCount = this.state.BillList.filter(list => list.corporate_bill_status === null)
                this.setState({
                    isAllBillsSelected: PendingBillsCount.length == this.state.SelectedBillList.length && this.state.SelectedBillList.length > 0 ? true : false
                })
            }
            const SelectedBillList = this.state.SelectedBillList
            let CompanyCount = [... new Set(SelectedBillList.map(x => x.company_name))];
            this.setState({
                SelectedCompanyCount: CompanyCount.length,
                SelectedBillList: this.state.SelectedBillList.sort((a, b) => { return a["invoice_date"].toLowerCase() > b["invoice_date"] ? 1 : -1 })
            }, () => {
                if (this.state.SelectedCompanyCount === 1) {
                    this.setState({
                        SelectedCorporateId: this.state.corpCompanyList.filter(x => x.company_name.toLowerCase() === CompanyCount[0].toLowerCase()).map(x => x.id)[0],
                        SelectedCorporateName: CompanyCount[0]
                    })
                }
                else {
                    this.setState({
                        SelectedCorporateId: "",
                        SelectedCorporateName: "",
                    })
                }
            })
        })
    }

    handleTableCheckbox = (ID) => {
        let selectedIDs = new Set(ID);
        let selectedRows = this.state.PatientBillList.filter((row) => selectedIDs.has(row.id));
        selectedRows.map(list => (list.isSelected = !list.isSelected))
        this.setState({
            BillList: this.state.BillList,
            SelectedBillList: selectedRows,
        }, () => {
            const SelectedBillList = this.state.SelectedBillList
            let CompanyCount = [... new Set(SelectedBillList.map(x =>  x.company_name))].filter(function (item) { return item != null });;
            this.setState({
                SelectedCompanyCount: CompanyCount.length,
            }, () => {
                if (this.state.SelectedCompanyCount === 1) {
                    this.setState({
                        SelectedCorporateId: this.state.corpCompanyList.filter(x => x.company_name.toLowerCase() === CompanyCount[0]?.toLowerCase()).map(x => x.id)[0],
                        SelectedCorporateName: CompanyCount[0]
                    })
                } else {
                    this.setState({
                        SelectedCorporateId: "",
                        SelectedCorporateName: "",
                    })
                }
            })
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    handleCheckBox = (e, key) => {
        let states = this.state;
        states[key] = e.target.checked
        this.setState({ states }, () => {
            // let BillType = [];
            // let BillStatus = [];
            // let PayMethods = [];
            // let ReceiptType = [];
            // let CreditStatus = [];
            // var states = this.state
            // if (states.FilterDatas.InPatientSelected) {
            //     BillType.push("ip")
            // }
            // if (states.FilterDatas.OutPatientSelected) {
            //     BillType.push("op")
            // }
            // if (states.FilterDatas.PharmacySelected) {
            //     BillType.push("pharmacy")
            // }
            // if (states.FilterDatas.LaboratorySelected) {
            //     BillType.push('lab')
            // }
            // if (states.FilterDatas.ImageLabSelected) {
            //     BillType.push(5)
            // }
            // if (states.FilterDatas.OTSelected) {
            //     BillType.push(6)
            // }


        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.corpCompanyList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.company_name)}
                            value={this.state.FilterSelectedCorp}
                            onChange={(event, newValue) => {
                                this.setState({ FilterSelectedCorp: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Company Name')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            fullWidth
                            size='small'
                            label={t("EmployeeName")}
                            name="employeeName"
                            value={this.state.employeeName}
                            onChange={(e) => {
                                this.setState({
                                    employeeName: e.target.value
                                })
                            }}
                        />
                    </Box>
                    {/* <Box component={"div"}>
                        <Box className="eMed_Filter_Header">
                            <Typography marginLeft={"1vw"}>{t("BillType")}</Typography>
                        </Box>
                        <Box className="eMed_CheckBox_Group">
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isOPselected} onChange={(e) => { this.handleCheckBox(e, "isOPselected") }} size='small' />} label={t("OP")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isIPselected} onChange={(e) => { this.handleCheckBox(e, "isIPselected") }} size='small' />} label={t("IP")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isLabselected} onChange={(e) => { this.handleCheckBox(e, "isLabselected") }} size='small' />} label={t("Lab")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.isPharmacyselected} onChange={(e) => { this.handleCheckBox(e, "isPharmacyselected") }} size='small' />} label={t("Pharmacy")} />
                        </Box>
                    </Box> */}
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                FilterSelectedCorp: [],
                                employeeName: "",
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                            }, () => this.getPatientDetails())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getPatientDetails()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    TableHeader = () => {
        return (
            <Box component={"div"} className='eMed_Table_top_btns'>
                <Box component={"div"} className='eMed_Filter_Icon_div'>
                    <Tooltip title="Filter" placement='top' arrow>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                </Box>
                {/* <Box component={"div"} className='eMed_Filter_Icon_div'>
                    <Tooltip title="Filter" placement='top' arrow>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="filter" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                </Box> */}
                {/* <Badge badgeContent={this.state.SelectedBillList.length} color="error">
                    <Button id="eMed_Config_btn" disabled={this.state.SelectedBillList.length === 0} onClick={() => {
                        if (this.state.SelectedCompanyCount === 1) {
                            this.setState({ isGenerateBillsClicked: true })
                        } 
                        else if(this.state.SelectedBillList.filter((item)=>(item?.company_name === null)).length > 0){
                            this.errorMessage("Invalid Bills were Selected")
                        }
                        else {
                            this.errorMessage("Different Company Bills were Selected. Please Select the Same Company Bills")
                        }
                    }} variant='contained' size='small'>Generate Bill</Button>
                </Badge> */}
            </Box>
        )
    }

    returnSelectedBillsAmount = () => {
        try {
            return this.state.SelectedBillList.reduce((a, b) => a + (b["discount_amount"] || 0), 0)
        }
        catch (e) {
            return 0
        }
    }

    GenerateBillPopUp = () => {
        const { t } = this.props
        return (
            <Dialog open={true}>
                <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderBottom: 0 }}>
                    <Typography fontWeight={600} marginLeft={"1vw"}>{`Generate Bill`}</Typography>
                    <Button onClick={() => { this.setState({ isGenerateBillsClicked: false }) }} >
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </DialogTitle>
                <DialogContent sx={{ width: '30vw', height: '13vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" }} >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} marginTop={'0.5vw'} width={'25vw'}>
                        <Box>
                            <Typography>Company Name</Typography>
                            <Typography fontWeight={600}>{this.state.SelectedCorporateName}</Typography>
                        </Box>
                        <Box>
                            <Typography>Selected Bills</Typography>
                            <Typography fontWeight={600}>{this.state.SelectedBillList.length}</Typography>
                        </Box>
                        <Box>
                            <Typography>Total Amount</Typography>
                            <Typography fontWeight={600}>{CurrencySymbol} {this.returnSelectedBillsAmount()}</Typography>
                        </Box>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <TextField
                            multiline={true}
                            rows={3}
                            inputProps={{ maxLength: 30 }}
                            sx={{ width: "25vw", }}
                            size='small'
                            label={t("Description *")}
                            value={this.state.GenarateBillDescription}
                            onChange={(e) => {
                                this.setState({
                                    GenarateBillDescription: e.target.value
                                })
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.setState({ GenarateBillDescription: "" }) }}>{t("Clear")}</Button>
                    <Button variant='contained' size='small' className='eMed_Filter_Btns' onClick={() => {
                        if (this.state.GenarateBillDescription !== "") {
                            var data = {
                                "invoice_from_date": this.state.SelectedBillList[0].invoice_date,
                                "invoice_to_date": this.state.SelectedBillList[this.state.SelectedBillList.length - 1].invoice_date,
                                "igst_percent": 0,
                                "sgst_percent": 0,
                                "cgst_percent": 0,
                                "employer_id": this.state.SelectedCorporateId,
                                "amount": this.returnSelectedBillsAmount(),
                                "description": this.state.GenarateBillDescription,
                                "line_items": this.state.SelectedBillList
                            }

                            RestAPIService.create(data, Serviceurls.CORPORATE_BILLING_POST).
                                then((response) => {
                                    if (response.data.status === "success") {
                                        this.setState({
                                            GenarateBillDescription: "",
                                            isGenerateBillsClicked: false
                                        })
                                        this.successMessage(response.data.message)
                                    } else {
                                        this.errorMessage(response.data.message)
                                    }
                                }).catch(error => {
                                    if (error?.response?.data?.status === "fail") {
                                        this.errorMessage(error.response.data.message)
                                    } else {
                                        this.errorMessage(error.message)
                                    }
                                })
                        }
                        else {
                            this.errorMessage("Enter the Description for the Bill.")
                        }
                    }}
                    >{t("Generate Bill")}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    mainTable = () => {
        this.state.PatientBillList.forEach((element, index) => { element.sno = index + 1 })
        const { t } = this.props
        let disbale = CheckAccessFunc("front_office", "Settings", "Corporate Configuration", "Patient Details", "SubTab")?.editAccess
        const columns = [
            {
                field: "patient_name", flex: 0.2, headerName: t("Employee Details"), headerAlign: "left", align: "left",
                renderCell: (params) => (
                    <Box component={'div'}>
                        <CommonPatientDetails data={params?.row} fromUserAccess={false} />
                    </Box>
                )
            },
            {
                field: "policy_company_name", flex: 0.150, headerName: t("Company Name"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.policy_company_name ? params?.row?.policy_company_name?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.policy_company_name} arrow>
                            <div>{params?.row?.policy_company_name.slice(0, 15) + "..."}</div></Tooltip> :
                        params?.row?.policy_company_name : "-"}</Box>)
            },
            {
                field: "discharge_date", flex: 0.11, headerName: t("Date"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.discharge_date ? params?.row?.discharge_date : "-"}</Box>)
            },
            {
                field: "ip_number", flex: 0.2, headerName: t("IPNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.ip_number ? params?.row?.ip_number?.length > 22 ?
                        <Tooltip placement='top' title={params?.row?.ip_number} arrow>
                            <div>{"..." + params?.row?.ip_number.slice(5) }</div></Tooltip> :
                        params?.row?.ip_number : "-"}</Box>)
            },
            {
                field: "policy_status", flex: 0.150, headerName: t("status"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.policy_status ? params?.row?.policy_status : "-"}</Box>)
            },
            {
                field: "total_bill_amount", flex: 0.12, headerName: t(`Total Bill (${CurrencySymbol})`), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_bill_amount ? params?.row?.total_bill_amount : params?.row?.total_bill_amount === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "policy_amount", flex: 0.12, headerName: t(`Claim Amount (${CurrencySymbol})`), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.policy_amount ? params?.row?.policy_amount : params?.row?.policy_amount === 0 ? "0" : "-"}</Box>)
            },
            {
                field: 'actions', flex: 0.10, headerName: t('Action'),  headerAlign: "center", align: "center",
                 renderCell: (params) => (<Box component={'div'}>
                     <Tooltip title={t("CreditBill")} placement="top" arrow>
                        <Button disabled={params?.row?.policy_status === "Claimed" && !disbale ? true : false}  className='eMed_usrconf_btn' onClick={() => { this.setState({ CreditBillPopUp: disbale ? true : false, SelectedBill: params.row }) }}>
                            <img src={params?.row?.policy_status !== "Claimed" && disbale  ? ImagePaths.CreateBill.default : ImagePaths.DisabledCreateBill.default} alt="Credit Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                 </Box>)
            }
        ]
        return (
            <Box className="eMed_pd_maintable">
                <DataGrid
                    // onSelectionModelChange={(id) => this.handleTableCheckbox(id)}
                    rows={this.state.PatientBillList}
                    columns={columns}
                    getRowId={(row) => row['sno']}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    hideFooterSelectedRowCount
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    headerHeight={40}
                    components={{
                        Toolbar: CommonGridHeader.CommonToolBar,
                        NoRowsOverlay: () => (
                            <Stack className='eMed_conf_nodata'>
                                {t("NoRecordsFound")}
                            </Stack>
                        )
                    }}
                    rowsPerPageOptions={[10, 20, 30]}
                    disableSelectionOnClick
                    pagination
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    loading={this.state.isLoader}
                />
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
    render() {
        return (
            <Box>
                {this.TableHeader()}
                {this.mainTable()}
                {this.state.isGenerateBillsClicked ? this.GenerateBillPopUp() : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {
                    this.state.CreditBillPopUp ? <CreditBillPopUp CorpBillData={this.state.SelectedBill} CrebillPosted={this.CrebillPosted.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} ReceivePayment={true} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PatientDetails)
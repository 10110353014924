import { Box, Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
class ReferralSource extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            refSource: "",
            description: "",
            refSourceData: [],
            deletePopup: false,
            rowValue: null,
            isEdit: false,
            delSelectedId: null,
            selectedId: null
        }
    }

    componentDidMount() {
        this.getRefSourceData()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    inputTextBox(label, value, name, width, rows) {
        const { t } = this.props
        return (
            <TextField
                size='small'
                multiline
                rows={rows}
                fullWidth={width}
                label={label === "refSource" ? `${t("refSource")} *` : t(label)}
                name={name}
                value={value}
                onChange={(e) => this.onChangeHandler(e)}
            />
        )
    }

    onChangeHandler = (event) => {
        const { name, value } = event.target
        let alpha = CommonValidation.alphabetOnly(value)
        switch (name) {
            case "refSource":
                if (alpha || value === "") {
                    this.setState({ refSource: value })
                }
                break;
            default:
                this.setState({
                    [name]: value
                })
                break;
        }
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    onClearHandler = () => {
        this.setState({
            refSource: "",
            description: ""
        })
    }

    onDeleteHandler = (params) => {
        if (!this.state.isEdit) {
            this.setState({
                deletePopup: true,
                delSelectedId: params.row?.id,
                rowValue: params.row
            })
        }
    }

    getRefSourceData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_SOURCE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refSourceData: response.data.data
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    postReferralSourceData = () => {
        const { t } = this.props
        try {
            let data = {
                "source_name": CommonValidation.removeSpace(this.state.refSource),
                "description": CommonValidation.removeSpace(this.state.description)
            }
            if (this.state.isEdit) {
                data['id'] = this.state.selectedId
            }

            if (data.source_name === "" && data.description === "") {
                this.errorMessage(t("ManitoryFields"))
            } else if (data.source_name === "") {
                this.errorMessage(t("validSrcName"))
            } else {
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.FO_CONFIG_REF_SOURCE).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.getRefSourceData()
                            this.onClearHandler()
                            this.setState({ isEdit: false })
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (values) => {
        const { t } = this.props
        try {
            this.setState({
                isEdit: true,
                refSource: values.row?.source_name,
                description: values.row?.description,
                selectedId: values.row?.id
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteReferralSourceData = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.FO_CONFIG_REF_SOURCE + "?id=" + this.state.delSelectedId).
                then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.setState({ deletePopup: false })
                        this.getRefSourceData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRefSourceLeft = () => {
        this.state.refSourceData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            { field: "sno", sortable: false, flex: 0.33, headerName: t("SNo") },
            { field: "source_name", flex: 0.33, headerName: t("refSource") },
            {
                field: "action", flex: 0.33, headerName: t("Action"), headerAlign: 'center', align: 'center', sortable: false, hideable: false,
                renderCell: (params) => <div>
                    <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Referral Configuration",  "Referral Source", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
                    <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Referral Configuration",  "Referral Source", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
                </div>
            }
        ]
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_left' elevation={1}>
                    <DataGrid
                        rows={this.state.refSourceData}
                        columns={columns}
                        getRowId={(row) => row['id']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Paper>
            </Stack>
        )
    }

    renderRefSourceRight = () => {
        const { t } = this.props
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_right' elevation={2}>
                    <Typography className='eMed_refdoc_title'>{t("refSource")}</Typography>
                    <Grid className='eMed_refsrc_txt_card'>
                        <Grid item xs={12} className='eMed_refsrc_txt'>
                            {this.inputTextBox("refSource", this.state.refSource, "refSource", true, 1)}
                        </Grid>
                        <Grid item xs={12}>
                            {this.inputTextBox("detailDesc", this.state.description, "description", true, 3)}
                        </Grid>
                    </Grid>
                    <Stack spacing={2} direction="row" className='eMed_refsrc_btn' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                        <Button variant='outlined' size="small"
                            id="eMed_Config_btn" onClick={() => {
                                this.onClearHandler()
                            }}>{t("Clear")}</Button>
                        <Button variant='contained' size="small"
                          disabled={!CheckAccessFunc("front_office", "Settings", "Referral Configuration",  "Referral Source", "SubTab")?.editAccess}
                            id="eMed_Config_btn"
                            onClick={() => {
                                this.postReferralSourceData()
                            }}>{this.state.isEdit ? t("Update") : t("Add")}</Button>
                    </Stack>
                </Paper>
            </Stack>
        )
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }

    renderMessageFields = () => {
        const { t } = this.props
        return (
            <>
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")}  ${this.state.rowValue?.source_name ? this.state.rowValue?.source_name : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteReferralSourceData.bind(this)}
                    DeleteNotify={t("effectEntireConfig")}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </>
        )
    }

    render() {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_refdoc_container'>
                <Grid container spacing={2} className='eMed_refdoc_content' >
                    <Grid item xs={8}>
                        {this.renderRefSourceLeft()}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderRefSourceRight()}
                    </Grid>
                </Grid>
                {this.renderMessageFields()}
            </Box>
        )
    }
}

export default withTranslation()(ReferralSource)
import React, { Component } from "react";
import {
    TextField, Button, Stack, Typography, Autocomplete, FormControl, InputLabel, Select,
    MenuItem, Paper, List, ListItem, ListItemButton, ListItemText, Modal, OutlinedInput, Box, Tooltip,
    Grid, Tabs, Tab, InputAdornment
} from "@mui/material";
import "./PharmaPurchase.css";
import { withTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Expiry_period, gstPercentage } from '../../../Utility/Constants';
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CircularProgress from '@mui/material/CircularProgress';
import { CurrencySymbol } from "../../../Utility/Constants";

const DEFAULT_SET = {
    "brand_name": "",
    "generic_name": "",
    "dosage_type": "",
    "dosage_strength": "",
    "batch_no": "",
    "expiry_date": null,
    "schedule": "",
    "group": "",
    "hsn_code": "",
    "bar_code": "",
    "pack_of_drugs": "",
    "sales_unit": "",
    "buying_price": "",
    "free_quantity": "",
    "discount_percentage": "",
    "discount_amount": "",
    "sales_discount": "",
    "sales_discount_amount": "",
    "gst_percentage": 0,
    "mrp_per_pack": "",
    "is_default_group": false,
    "is_default_schedule": false,
    "is_default_hsn": false,
    "is_default_expiryalt": false,
    "is_default_reorder": false,
    "is_default_boxno": false,

    "total_buying_price": 0,
    "mrp_per_quantity": 0,
    "stock_in_quantity": 0,
    "total_quantity": 0,

    "expiry_alert_period": "3",
    "reorder_threshold": "",
    "physical_box_no": "",
    "description": "",
    "manufacturer_name": "",
    "recommended_by": "",
    // excess
    "total_gst_amount": 0,
    "drug_id": null,

    "isGenericSelected": false,
    "previousPriceDetails": null,
    "sales_discount_for_bill": 0,

    // free qty states
    "free_qty_per_unit": 0,
    "free_no_of_unit": 0,
    "free_mrp_unit": 0,
    "free_sales_discount_bills": 0,
    'is_updated': false
}

class PharmaDCRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { ...DEFAULT_SET },
            brand_suggestionList: [],
            EPRValue: 0,
            is_sales_amount_changed: false,
            is_discount_amount_changed: false,
            isDifferentBatchExist: false,
            existBatchList: [],
            selectedBatchNo: "",
            // suggestion 
            genericSuggestionList: [],
            purchase_status: "",
            EditData: {},
            open: false,
            openBatch: true,
            defaultReadOnly: false,
            Tabvalue: "one",
            isBrandLoader: false,
            isBrandEdit: false,
            is_group: false,
            PurchaseID: null,
            is_schedule: false,
            is_hsn: false,
            is_expiryalt: false,
            is_reorder: false,
            is_boxno: false,
            openBrand: false,
            openGSt: false,
            drug_from_dc: false,
        }
        this.numberOfRefs = 3;
        this.textInputDropDownRefs = Array.from({ length: this.numberOfRefs }, () =>
            React.createRef()
        );
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll, true);
    }

    componentDidUpdate() {
        if (this.props.EditData !== undefined && Object.keys(this.props.EditData)?.length > 0 && this.props.EditData !== this.state.EditData) {
            var from_dc = (this.props.EditData)?.item_from_dc ? true : false
            this.setState({
                EditData: this.props.EditData,
                Tabvalue: this.props.tabValue,
                PurchaseID: this.props.PO_id,
                drug_from_dc: from_dc
            }, () => {
                this.assignDetails()
            })
        } else if (this.props.purchase_status !== "" && this.props.purchase_status !== this.state.purchase_status) {

            this.setState({
                purchase_status: this.props.purchase_status,
                defaultReadOnly: this.props.purchase_status === 'Partially Delivered' ? false : true,
                isBrandEdit: this.props.purchase_status === 'Partially Delivered' ? false : true
            })
        } else if (this.props.RightDataClear) {
            this.handleClear()
        }
    }

    handleScroll = () => {
        let { open, openGSt } = this.state;
        if (open) {
            this.setState({
                open: false,
            })
        }
        if (openGSt) {
            this.setState({
                openGSt: false,
            })
        }
    }

    multiplyBy2 = (value) => {
        return value * 2;
    }

    assignDetails = () => {
        let { EditData } = this.state;
        if (EditData !== undefined && Object.keys(EditData)?.length > 0) {

            let gst_percentage = EditData.cgst_percentage ? this.multiplyBy2(EditData.cgst_percentage) : 0;
            let total_gst_amount = EditData.cgst_amount ? this.multiplyBy2(EditData.cgst_amount) : 0;

            let tempItem = {
                "edit_index": EditData.edit_index,
                "serial_no": EditData.serial_no,
                "stock_id": EditData.stock_id ? EditData.stock_id : "",
                "status": EditData.status ? EditData.status : "",
                "is_stocks_sold": EditData.is_stocks_sold,
                "is_stocks_returned": EditData.is_stocks_returned,
                "drug_id": EditData.drug_id ? EditData.drug_id : EditData.drug_ms_id,
                "brand_name": EditData.brand_name,
                "generic_name": EditData.generic_name,
                "dosage_type": EditData.dosage_type,
                "dosage_strength": EditData.dosage_strength,
                "batch_no": EditData.batch_no,
                "expiry_date": EditData.expiry_date,
                "schedule": EditData.schedule,
                "group": EditData.group,
                "hsn_code": EditData.hsn_code,
                "bar_code": EditData.bar_code,
                "pack_of_drugs": +EditData.pack_of_drugs,
                "sales_unit": +EditData.sales_unit,
                "buying_price": +EditData.buying_price ? +EditData.buying_price : 0,
                "free_quantity": +EditData.free_quantity,
                "discount_percentage": +EditData.discount_percentage,
                "discount_amount": +EditData.discount_amount,
                "sales_discount": +EditData.sales_discount,
                "sales_discount_amount": +EditData.sales_discount_amount,
                "gst_percentage": +EditData.gst_percentage || +gst_percentage,
                "mrp_per_pack": +EditData.mrp_per_pack,

                "total_buying_price": +EditData.total_buying_price ? +EditData.total_buying_price : (EditData.buying_price && EditData.sales_unit) ? (+EditData.buying_price * +EditData.sales_unit) : 0,
                "mrp_per_quantity": +EditData.mrp_per_quantity,
                "stock_in_quantity": +EditData.stock_in_quantity,
                "total_quantity": +EditData.total_quantity,

                "expiry_alert_period": EditData.expiry_alert_period ? +EditData.expiry_alert_period : "3",
                "reorder_threshold": +EditData.reorder_threshold,
                "physical_box_no": EditData.physical_box_no,
                "description": EditData.description,
                "manufacturer_name": EditData.manufacturer_name,
                "recommended_by": EditData.recommended_by,
                "total_gst_amount": +EditData.total_gst_amount || +total_gst_amount,
                "epr_value": +EditData.epr_value,
                "quantity": EditData.quantity ? +EditData.quantity : 0,
                "sales_discount_for_bill": EditData.sales_discount_for_bill ? +EditData.sales_discount_for_bill : 0,
                "free_qty_per_unit": EditData.free_qty_per_unit ? EditData.free_qty_per_unit : 0,
                "free_no_of_unit": EditData.free_no_of_unit ? EditData.free_no_of_unit : 0,
                "free_mrp_unit": EditData.free_mrp_unit ? EditData.free_mrp_unit : 0,
                "free_sales_discount_bills": EditData.free_sales_discount_bills ? EditData.free_sales_discount_bills : 0,
                "item_from_dc": EditData.item_from_dc ? EditData.item_from_dc : false,
                'is_updated': EditData.stock_id ? true : false,
                'from_wanted': this.props.fromWanted,
                // 'wanted_quantity':EditData.wanted_quantity ? EditData.wanted_quantity : 0,
                // 'wanted_request':EditData.wanted_request,
                'po_line_item_id': EditData.po_line_item_id ? EditData.po_line_item_id : null
            }
            let list = { ...this.state.data, ...tempItem }
            this.setState({
                data: list,
                defaultReadOnly: false,
                isBrandEdit: (this.state.purchase_status === "" || EditData.status === "Delivered") ? false : true,
                is_group: ((EditData?.is_default_group && !this.state.PurchaseID) || (this.state.PurchaseID && EditData.group)) ? true : false,
                is_schedule: ((EditData?.is_default_schedule && !this.state.PurchaseID) || (this.state.PurchaseID && EditData.schedule)) ? true : false,
                is_hsn: ((EditData?.is_default_hsn && !this.state.PurchaseID) || (this.state.PurchaseID && EditData.hsn_code)) ? true : false,
                is_expiryalt: ((EditData?.is_default_expiryalt && !this.state.PurchaseID) || (this.state.PurchaseID && EditData.expiry_alert_period)) ? true : false,
                is_reorder: ((EditData?.is_default_reorder && !this.state.PurchaseID) || (this.state.PurchaseID && EditData.reorder_threshold)) ? true : false,
                is_boxno: ((EditData?.is_default_boxno && !this.state.PurchaseID) || (this.state.PurchaseID && EditData.physical_box_no)) ? true : false,
            }, () => this._calculateTransaction())

        }
    }

    successMessage = (msg) => {
        this.props.successMessage(msg);
    }
    errorMessage = (msg) => {
        this.props.errorMessage(msg);
    }

    LoaderFunction = (key) => {
        this.props.LoaderFunction(key);
    }

    brandLoader = (key) => {
        this.setState({
            isBrandLoader: key
        })
    }

    _getBrandSuggestionList = (brandName) => {
        try {
            this.brandLoader(true);
            RestAPIService.getAll(`${Serviceurls.PURCHASE_BRAND_NAME_GET}?brand_name=${brandName ? brandName : ""}`)
                .then(response => {
                    let data = response?.data;
                    if (response.data.status === "success") {
                        this.brandLoader(false);
                        this.setState({
                            brand_suggestionList: data.drug_list
                        })
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.brandLoader(false);
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.brandLoader(false);
                        this.errorMessage(error.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    removeGenericDuplicates(data) {
        let uniqueArr = [], uniqueObj = {};
        for (let i in data) {
            let objDisplayName = data[i]['display_name'];
            uniqueObj[objDisplayName] = data[i];
        }
        for (let key in uniqueObj) {
            uniqueArr.push(uniqueObj[key]);
        }
        return uniqueArr;
    }

    _getBrandDetails = () => {
        let { data } = this.state;
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_DRUG_DETAILS_GET}?brand_name=${data.brand_name ? data.brand_name : ""}`)
                .then(response => {
                    if (response.data.status == "success") {
                        let uniqueArr = this.removeGenericDuplicates(response.data.data);
                        if (uniqueArr.length === 1) {

                            let prevPrice = uniqueArr[0]["previous_price_details"].length > 0 ? uniqueArr[0]["previous_price_details"][0] : {};

                            data.drug_id = uniqueArr[0]["drug_id"];
                            data.generic_name = uniqueArr[0]["generic_name"];
                            data.dosage_type = uniqueArr[0]["dosage_type"];
                            data.dosage_strength = uniqueArr[0]["dosage_strength"];
                            data.manufacturer_name = uniqueArr[0]["manufacturer_name"];
                            data.physical_box_no = uniqueArr[0]["physical_box_no"];
                            data.expiry_alert_period = uniqueArr[0]["expiry_alert_period"] ? uniqueArr[0]["expiry_alert_period"] : "3";
                            data.reorder_threshold = uniqueArr[0]["reorder_threshold"];
                            data.hsn_code = uniqueArr[0]["hsn_code"];
                            data.bar_code = uniqueArr[0]["bar_code"];
                            data.gst_percentage = prevPrice.sgst_percentage ? this.multiplyBy2(prevPrice.sgst_percentage) : uniqueArr[0]["gst_percentage"];
                            data.manufacturer_name = uniqueArr[0]["manufacturer_name"];
                            data.recommended_by = uniqueArr[0]["recommended_by"];
                            data.schedule = uniqueArr[0]["schedule"];
                            data.group = uniqueArr[0]["group"];
                            data.is_default_group = uniqueArr[0]["group"] ? true : false;
                            data.is_default_schedule = uniqueArr[0]["schedule"] ? true : false;
                            data.is_default_hsn = uniqueArr[0]["hsn_code"] ? true : false;
                            data.is_default_expiryalt = uniqueArr[0]["expiry_alert_period"] ? true : false;
                            data.is_default_reorder = uniqueArr[0]["reorder_threshold"] ? true : false;
                            data.is_default_boxno = uniqueArr[0]["physical_box_no"] ? true : false;
                            data.pack_of_drugs = prevPrice.pack_of_drugs ? prevPrice.pack_of_drugs : "";
                            data.buying_price = prevPrice.buying_price ? prevPrice.buying_price : "";
                            data.previousPriceDetails = prevPrice.buying_price ? prevPrice.buying_price : null;
                            data.discount_percentage = prevPrice.discount_percentage ? prevPrice.discount_percentage : "";
                            data.discount_amount = prevPrice.discount_amount ? prevPrice.discount_amount : "";
                            data.sales_discount = prevPrice.sales_discount ? prevPrice.sales_discount : "";
                            data.sales_discount_amount = prevPrice.sales_discount_amount ? prevPrice.sales_discount_amount : "";
                            data.mrp_per_pack = prevPrice.mrp_per_pack ? prevPrice.mrp_per_pack : "";
                            data.mrp_per_quantity = prevPrice.mrp_per_quantity ? prevPrice.mrp_per_quantity : 0;
                            data.description = prevPrice.instructions ? prevPrice.instructions : "";
                            data.sales_discount_for_bill = prevPrice.sales_discount_bill ? prevPrice.sales_discount_bill : 0;

                            this.setState({
                                data,
                                is_group: data.is_default_group,
                                is_schedule: data.is_default_schedule,
                                is_hsn: data.is_default_hsn,
                                is_expiryalt: data.is_default_expiryalt,
                                is_reorder: data.is_default_reorder,
                                is_boxno: data.is_default_boxno,
                            }, () => {
                                this._calculateTransaction();
                            })
                        } else {
                            this.setState({
                                genericSuggestionList: uniqueArr,
                                isGenericSelected: true
                            })
                        }
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    _getPreviousDrugDetails = () => {
        let { data } = this.state;
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_PREVIOUS_PRICE_GET}?brand_name=${CommonValidation.removeSpace(data.brand_name)}&generic_name=${CommonValidation.removeSpace(data.generic_name)}&dosage_type=${data.dosage_type}&dosage_strength=${data.dosage_strength}`)
                .then(response => {
                    if (response.data?.status == "success") {
                        data.previousPriceDetails = response.data?.data?.buying_price;
                        this.setState({
                            data
                        })
                    }
                })
                .catch(error => {
                    if (error.response?.data?.status === "fail") {
                        // this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            // this.errorMessage(e.message);
        }
    }

    renderBrandSearch = () => {
        const { t } = this.props;
        let { data, brand_suggestionList, defaultReadOnly, isBrandEdit, drug_from_dc } = this.state;
        return (
            <div id="eMed_pro_date_picker">
                <Autocomplete
                    disableClearable
                    name={"brand_name"}
                    value={data.brand_name ? data.brand_name : ""}
                    sx={{ width: "30vw" }}
                    readOnly={isBrandEdit ? true : false}
                    options={brand_suggestionList.length > 0 ? brand_suggestionList : []}
                    getOptionLabel={(option) => (typeof (option.drug_name) === "string" ? option.drug_name : option)}
                    onChange={(event, newBrand) => {
                        data.brand_name = newBrand.drug_name;
                        this.setState({
                            data
                        }, () => {
                            this._getBrandDetails()
                        })
                    }}
                    disabled={drug_from_dc ? true : false}
                    onInputChange={(event, newBrand) => {
                        let previousBrand_name = JSON.stringify(data.brand_name);
                        data.brand_name = newBrand;
                        if (previousBrand_name != JSON.stringify(newBrand)) {
                            data = { ...data, ...DEFAULT_SET }
                        }
                        this.setState({
                            data
                        })
                        if (newBrand?.length > 2) {
                            this._getBrandSuggestionList(newBrand);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("BrandName") + " *"}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                emed_tid: "BrandName",
                                endAdornment: this.state.isBrandLoader ?
                                    <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                                    : null,
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    handleClick = (statekey, value) => {
        let states = this.state;
        states[statekey] = value;
        this.setState({
            states
        })
    }

    handleAdd = () => {
        if (this.props.callPost) {
            this.errorMessage('You cannot able to add new drug, due to  wanted list request')
        } else {
            let { data, EPRValue, Tabvalue } = this.state
            data["epr_value"] = EPRValue
            data.buying_price = Tabvalue === "two" ? 0 : data.buying_price;
            data.discount_percentage = Tabvalue === "two" ? 0 : data.discount_percentage;
            data.sales_discount = Tabvalue === "two" ? 0 : data.sales_discount;
            data.gst_percentage = Tabvalue === "two" ? 0 : data.gst_percentage;
            let isValid = this._validateData(data);
            if (isValid) {
                // let fromWanted = data?.wanted_request ? false : !this.props.fromWanted
                // if (((data?.pack_of_drugs * data?.sales_unit) <= data?.wanted_quantity) || fromWanted) {
                this.LoaderFunction(true);
                this.setState({
                    defaultReadOnly: ((this.props.purchase_status === 'Partially Delivered') || this.props.purchase_status === '') ? false : true,
                }, () => this.checkBatchNumber())
                // } else {
                //     this.errorMessage(`Quantity (${(data?.pack_of_drugs * data?.sales_unit)}) is greater than request Quantity (${data?.wanted_quantity})`)
                // }
            }

        }
    }

    _validateData = (data) => {
        const { t } = this.props
        let isValid = true;
        let msg = "";
        if (!data.brand_name) { isValid = false; msg = "EnterBrandname"; }
        else if (!data.generic_name) { isValid = false; msg = "EnterGenericname"; }
        else if (!data.dosage_type) { isValid = false; msg = "EnterDosagetype"; }
        else if (!data.batch_no) { isValid = false; msg = "EnterBatchnumber"; }
        else if (!data.expiry_date) { isValid = false; msg = "Enterexpirydate"; }
        else if (!data.pack_of_drugs) { isValid = false; msg = "EnterQtyPerUnit"; }
        else if (this.state.Tabvalue !== "two" && !data.sales_unit) { isValid = false; msg = "EnterNoOfUnit"; }
        else if (!data.mrp_per_pack) { isValid = false; msg = "EnterMRP"; }
        else if (this.state.Tabvalue !== "two" && !data.buying_price) {
            isValid = false; msg = "Enterbuyingprice"
        }
        if (!isValid) {
            this.errorMessage(t(msg))
        }
        return isValid;
    }


    _AddToList = () => {
        const { t } = this.props;
        let { data, EPRValue } = this.state;

        data['epr_value'] = EPRValue
        this.LoaderFunction(false);
        this.successMessage(data.serial_no ? t("updateMsg") : t("createMsg"));
        this.props.sendToList(data, false, this.state.Tabvalue)
        this.handleClear(true);
    }

    checkBatchNumber = () => {
        let { data } = this.state
        let cgst_percentage = parseFloat((data.gst_percentage / 2).toFixed(2))
        let datas = {
            "drug_id": data.drug_id ? +data.drug_id : 0,
            "expiry_date": data.expiry_date,
            "mrp_per_quantity": data.mrp_per_quantity ? parseFloat(data.mrp_per_quantity) : 0,
            "cgst_percentage": cgst_percentage ? +cgst_percentage : "0.00",
            "sgst_percentage": cgst_percentage ? +cgst_percentage : "0.00",
            "stock_id": data.stock_id ? data.stock_id : "0.00"
        }
        try {
            let alreadyExist = this.props.leftTblData?.some(item => item?.drug_id === data?.drug_id && item?.batch_no === data?.batch_no)
            // if (alreadyExist) {
            //     this.errorMessage('Drug already Exist')
            //     this.handleClear(true);
            // } else {
                RestAPIService.create(datas, Serviceurls.PURCHASE_BATCH_LIST_POST)
                    .then(response => {
                        if (response.data.status == "success") {
                            this.LoaderFunction(false);
                            let { data } = this.state
                            let Data = this.removeDuplicate(response.data.data)
                            if (response.data.data && (response.data.data).length === 1) {
                                if ((Data)[0] == data.batch_no) {
                                    this._AddToList();
                                } else {
                                    this.setState({
                                        isDifferentBatchExist: true,
                                        existBatchList: Data
                                    })
                                }
                            } else if (response.data.data.length > 1) {
                                this.setState({
                                    isDifferentBatchExist: true,
                                    existBatchList: Data
                                })
                            } else {
                                this._AddToList();
                            }
                        } else {
                            this._AddToList();
                        }
                    })
                    .catch(error => {
                        this._AddToList();
                        // this.errorMessage(error.response?.data.message);
                    })
            // }
        } catch (e) {
            // this.errorMessage(e.message);
        }
    }

    renderDatePicker = (label, statekey, openMenu, width, mandatory = false, refIndex) => {
        const { t } = this.props;
        let states = this.state;
        let { data, defaultReadOnly, drug_from_dc } = this.state;
        return (
            <div id="eMed_pharmadrctpo_txtbox">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        ref={this.textInputDropDownRefs[refIndex]}
                        open={states[openMenu]}
                        disabled={(drug_from_dc || defaultReadOnly) ? true : false}
                        onOpen={() => this.handleClick(openMenu, true)}
                        minDate={new Date().setMonth(new Date().getMonth() + 1)}
                        onClose={() => this.handleClick(openMenu, false)}
                        views={['year', 'month']}
                        inputFormat="MM-yyyy"
                        label={t(label)}
                        name={statekey}
                        value={data[statekey] ? data[statekey] : null}
                        autoComplete="off"
                        onChange={
                            (value) => {
                                data[statekey] = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                                this.setState({
                                    data
                                })
                            }
                        }
                        renderInput={(params) => <TextField
                            required={mandatory}
                            onKeyDown={(e) => e.preventDefault()}
                            autoComplete="off"
                            size="small"
                            sx={{ width: width ? width : "14.5vw" }}
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                emed_tid: label,
                            }}
                            onClick={() => this.handleClick(openMenu, true)} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    renderDropDown = (label, statekey, showkey, valueKey, Data = [], width, mandatory = false, noShow = false, openMenu, refIndex) => {
        let { data, defaultReadOnly, drug_from_dc } = this.state;
        const { t } = this.props;
        return (
            <div id="eMed_pharmadrctpo_txtbox">
                {statekey === "expiry_alert_period" ?
                    <FormControl sx={{ width: width ? width : "14.5vw" }} size="small">
                        <InputLabel>{t(label)}</InputLabel>
                        <Select
                            value={data[statekey] ? data[statekey] : "0"}
                            name={statekey}
                            label={t(label)}
                            disabled={(drug_from_dc || noShow) ? true : false}
                            readOnly={defaultReadOnly ? true : false}
                            onChange={(e) => {
                                data[statekey] = e.target.value;
                                this.setState({ data })
                            }}
                            inputProps={{ emed_tid: label }}
                        >
                            {
                                Data.map((list, index) => (
                                    <MenuItem emed_tid="drt_exp_period" key={index} value={list[valueKey]} >{list[showkey]}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    :
                    <Autocomplete
                        open={this.state[openMenu] || false}
                        onOpen={() => this.handleClick(openMenu, true)}
                        onClose={() => this.handleClick(openMenu, false)}
                        anchorEl={this.textInputDropDownRefs[refIndex]}
                        disableClearable
                        value={data[statekey] ? data[statekey].toString() : ""}
                        disabled={drug_from_dc ? true : false}
                        options={Data}
                        readOnly={defaultReadOnly ? true : false}
                        getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option)}
                        sx={{ width: width ? width : "14.5vw" }}
                        onChange={(e, newValue) => {
                            data[statekey] = newValue.value ? +newValue.value : newValue
                            if (newValue)
                                this.setState({
                                    data
                                }, () => {
                                    if (statekey === "gst_percentage") {
                                        this._calculateTransaction();
                                    }
                                })
                        }}
                        onInputChange={(event, newValue) => {
                            if (statekey === "gst_percentage") {
                                let isNum = CommonValidation.numberOnly(newValue)
                                if ((((+newValue) < 101) && isNum) || newValue == "") {
                                    data.gst_percentage = +newValue;
                                } else {
                                    data.gst_percentage = 100;
                                }
                                this.setState({
                                    data
                                }, () => {
                                    this._calculateTransaction();
                                })
                            }
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t(label)}
                            required={mandatory}
                            size="small"
                            InputProps={{ ...params.InputProps, emed_tid: label }} />}
                    />
                }
            </div>
        )
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        let { data } = this.state;
        let states = this.state;
        // validation
        let isNum = CommonValidation.numberOnly(value);
        let isDecimal = CommonValidation.DecimalNumber(value);
        let isNumWithDot = CommonValidation.NumberWithDot(value);

        if (name === "sales_unit" || name === "free_quantity" || name === "reorder_threshold" || name === "pack_of_drugs" || name === "free_qty_per_unit" || name === "free_no_of_unit") {
            if (value === "" || (isNum && value > 0)) {
                data[name] = +value.trimStart();
            }
        } else if (name === "sales_discount_for_bill" || name === "free_sales_discount_bills") {
            if (value === "" || (isDecimal && +value <= 100)) {
                data[name] = value.trimStart();
            }
        } else if (name === "mrp_per_pack" || name === "free_mrp_unit") {
            if (value === "" || (isNumWithDot && value > 0)) {
                data[name] = value.trimStart();
            }
        } else if (name === "buying_price") {
            if (value === "" || (isNumWithDot && value)) {
                data[name] = value.trimStart();
            }
        } else if (name === "discount_percentage") {
            if (value === "" || (isDecimal && +value <= 100)) {
                data.discount_percentage = value.trimStart();
                states.is_discount_amount_changed = false;
            }
        } else if (name === "discount_amount") {
            if (value === "" || (isNumWithDot && value <= data.total_buying_price)) {
                data.discount_amount = value.trimStart();
                states.is_discount_amount_changed = true;
            }
        } else if (name === "sales_discount") {
            if (value === "" || (isDecimal && +value <= 100)) {
                data.sales_discount = value.trimStart();
                states.is_sales_amount_changed = false;
            }
        } else if (name === "sales_discount_amount") {
            if (value === "" || (isNumWithDot && value <= data.total_buying_price)) {
                data.sales_discount_amount = value.trimStart();
                states.is_sales_amount_changed = true;
            }
        } else {
            data[name] = value
        }

        this.setState({
            data,
            states
        }, () => {
            let trigger_calc_keys = ['pack_of_drugs', 'sales_unit', 'buying_price', 'free_quantity', 'discount_percentage', 'discount_amount', 'sales_discount', 'sales_discount_amount', 'mrp_per_pack', 'gst_percentage'];
            let sameInList = trigger_calc_keys.includes(name);
            if (sameInList) {
                this._calculateTransaction();
            }
            //     if(data.brand_name && data.generic_name && data.dosage_type && data.dosage_strength && name === "batch_no" && data.batch_no?.length > 2){
            //         this._getPreviousDrugDetails()
            //    }
        })

    }

    _calculateTransaction() {
        let { data, Tabvalue, is_sales_amount_changed, is_discount_amount_changed } = this.state;
        let pack_of_drugs = parseInt(data.pack_of_drugs) || 0;
        let sales_unit = parseInt(data.sales_unit) || 0;
        let buying_price = Tabvalue === "two" ? 0 : parseFloat(data.buying_price) || 0;
        let free_quantity = +data.free_quantity || 0;
        let discount_percentage = Tabvalue === "two" ? 0 : parseFloat(data.discount_percentage) || 0;
        let discount_amount = parseFloat(data.discount_amount) || 0;
        let seller_disc_percent = Tabvalue === "two" ? 0 : parseFloat(data.sales_discount) || 0;
        let seller_disc_amount = parseFloat(data.sales_discount_amount) || 0;
        let mrp_per_pack = parseFloat(data.mrp_per_pack) || 0;
        let gst_percentage = Tabvalue === "two" ? 0 : parseFloat(data.gst_percentage) || 0;

        //Free quantity data
        let free_qty_per_unit = parseInt(data.free_qty_per_unit) || 0;
        let free_mrp_unit = parseFloat(data.free_mrp_unit) || 0;

        // calc quantity
        let stock_in_quantity = Tabvalue === "two" ? 0 : pack_of_drugs * sales_unit;
        data.total_quantity = Tabvalue === "two" ? 0 : ((pack_of_drugs * sales_unit) + (pack_of_drugs * free_quantity));

        let total_buying_price = parseFloat((sales_unit * buying_price).toFixed(2));
        let mrp_per_quantity = pack_of_drugs ? (mrp_per_pack / pack_of_drugs).toFixed(2) : 0;
        let free_mrp_per_quantity = free_qty_per_unit ? (free_mrp_unit / free_qty_per_unit).toFixed(2) : 0;

        // normal discount
        if (is_discount_amount_changed) {
            // set discount percentage
            data.discount_percentage = total_buying_price && discount_amount > 0 ? ((100 * discount_amount) / total_buying_price).toFixed(2) : 0;
        } else {
            discount_amount = total_buying_price > 0 && discount_percentage > 0 ? ((total_buying_price * discount_percentage) / 100).toFixed(2) : 0;
        }

        // total buying price after discount deduction
        let total_buying_price_after_discount = total_buying_price - parseFloat(discount_amount);

        if (is_sales_amount_changed) {
            // set sales discount percent
            data.sales_discount = total_buying_price_after_discount && seller_disc_amount > 0 ? ((100 * seller_disc_amount) / total_buying_price_after_discount).toFixed(2) : 0;
        } else {
            // set sales discount amount
            seller_disc_amount = buying_price && seller_disc_percent > 0 ? ((total_buying_price_after_discount * seller_disc_percent) / 100).toFixed(2) : 0;
        }

        // set data
        data.sales_discount_amount = +seller_disc_amount;
        data.discount_amount = +discount_amount;
        data.stock_in_quantity = +stock_in_quantity;
        data.mrp_per_quantity = Tabvalue === "two" ? +free_mrp_per_quantity : +mrp_per_quantity;

        //total buying price calculation if gst not mentioned
        data.total_buying_price = +(parseFloat(total_buying_price.toFixed(2)) - parseFloat(discount_amount) - parseFloat(seller_disc_amount)).toFixed(2);

        //if gst percentage added
        if (gst_percentage === 0) {
            data.total_gst_amount = 0;
        } else {
            let taxable_amount = parseFloat(total_buying_price) - parseFloat(discount_amount) - parseFloat(seller_disc_amount);
            let gst_amount = ((taxable_amount * gst_percentage) / 100).toFixed(2)
            data.total_gst_amount = +gst_amount;

            // add GST to final buying price
            data.total_buying_price = +(parseFloat(data.total_buying_price) + parseFloat(gst_amount)).toFixed(2)

        }
        // EPRValue Calculation start
        var PurchaseRate = parseInt(buying_price)
        let GST = 1 + (parseFloat(gst_percentage) / 100)
        let PurcahseQty = parseInt(sales_unit)
        let PurcahseQtyandfreeQty = PurcahseQty + (free_quantity ? +free_quantity : 0)
        let Discount = (discount_percentage ? parseFloat(discount_percentage) : 0) + (seller_disc_percent ? parseFloat(seller_disc_percent) : 0)
        let TotalDis = 1 - (Discount / 100)
        let EPRTotalValue = (PurchaseRate * GST / mrp_per_pack) * (PurcahseQty / PurcahseQtyandfreeQty) * (TotalDis)

        this.setState({
            data,
            EPRValue: (EPRTotalValue && EPRTotalValue != Infinity) ? ((EPRTotalValue * 100).toFixed(2)) : 0
        })
    }

    handleGenericSuggestion = (list) => {
        let { data } = this.state;
        if (list) {
            let prevPrice = list.previous_price_details?.length > 0 ? list.previous_price_details[0] : {};

            data.drug_id = list.drug_id;
            data.generic_name = list.generic_name;
            data.dosage_type = list.dosage_type;
            data.dosage_strength = list.dosage_strength;
            data.manufacturer_name = list.manufacturer_name;
            data.physical_box_no = list.physical_box_no;
            data.expiry_alert_period = list.expiry_alert_period ? list.expiry_alert_period : "3";
            data.reorder_threshold = list.reorder_threshold;
            data.hsn_code = list.hsn_code;
            data.bar_code = list.bar_code;
            data.gst_percentage = prevPrice.sgst_percentage ? this.multiplyBy2(prevPrice.sgst_percentage) : list.gst_percentage;
            data.manufacturer_name = list.manufacturer_name;
            data.recommended_by = list.recommended_by;
            data.schedule = list.schedule;
            data.group = list.group;
            data.is_default_group = list.group ? true : false;
            data.is_default_schedule = list.schedule ? true : false;
            data.is_default_hsn = list.hsn_code ? true : false;
            data.is_default_expiryalt = list.expiry_alert_period ? true : false;
            data.is_default_reorder = list.reorder_threshold ? true : false;
            data.is_default_boxno = list.physical_box_no ? true : false;
            data.pack_of_drugs = prevPrice.pack_of_drugs ? prevPrice.pack_of_drugs : "";
            data.buying_price = prevPrice.buying_price ? prevPrice.buying_price : "";
            data.previousPriceDetails = prevPrice.buying_price ? prevPrice.buying_price : null;
            data.discount_percentage = prevPrice.discount_percentage ? prevPrice.discount_percentage : "";
            data.discount_amount = prevPrice.discount_amount ? prevPrice.discount_amount : "";
            data.sales_discount = prevPrice.sales_discount ? prevPrice.sales_discount : "";
            data.sales_discount_amount = prevPrice.sales_discount_amount ? prevPrice.sales_discount_amount : "";
            data.mrp_per_pack = prevPrice.mrp_per_pack ? prevPrice.mrp_per_pack : "";
            data.mrp_per_quantity = prevPrice.mrp_per_quantity ? prevPrice.mrp_per_quantity : 0;
            data.description = prevPrice.instructions ? prevPrice.instructions : "";
            data.sales_discount_for_bill = prevPrice.sales_discount_bill ? prevPrice.sales_discount_bill : 0;

            this.setState({
                data,
                isGenericSelected: false,
                is_group: data.is_default_group,
                is_schedule: data.is_default_schedule,
                is_hsn: data.is_default_hsn,
                is_expiryalt: data.is_default_expiryalt,
                is_reorder: data.is_default_reorder,
                is_boxno: data.is_default_boxno,
            }, () => {
                this._calculateTransaction();
            })
        }
    }

    renderTextBox(label, statekey, mandatory = false, noshow = false, width) {
        const { t } = this.props
        let { data, genericSuggestionList, isGenericSelected, defaultReadOnly } = this.state
        return (
            <div id="eMed_pharmadrctpo_txtbox">
                {
                    statekey === "buying_price" && data.previousPriceDetails ?
                        <Tooltip title={data.previousPriceDetails ? `${t("Previousbuyingprice") + (`- ${CurrencySymbol}`)} ${data.previousPriceDetails}` : null} placement="top" arrow>
                            <TextField
                                label={t(label) + "*"}
                                sx={{ width: width ? width : "14.5vw" }}
                                size="small"
                                autoComplete="off"
                                inputProps={{ emed_tid: label }}
                                InputProps={{
                                    readOnly: defaultReadOnly ? true : false
                                }}
                                name={statekey}
                                value={data[statekey] ? data[statekey] : ""}
                                onChange={this.handleChange}
                            />
                        </Tooltip>
                        :
                        <TextField
                            label={t(label)}
                            required={mandatory || statekey === "mrp_per_pack" ? true : false}
                            sx={{ width: width ? width : "14.5vw" }}
                            disabled={noshow}
                            inputProps={{ emed_tid: label }}
                            InputProps={{
                                readOnly: noshow || defaultReadOnly ? true : false
                            }}
                            size="small"
                            autoComplete="off"
                            name={statekey}
                            value={data[statekey] ? data[statekey] : ""}
                            onChange={this.handleChange}
                        />
                }
                {statekey === "generic_name" && isGenericSelected && genericSuggestionList.length > 1 ?
                    <Paper elevation={2} sx={{ maxHeight: "30vw", overflowY: "scroll" }}>
                        <List
                            sx={{ width: "100%", bgcolor: 'background.paper' }}
                        >
                            {genericSuggestionList && genericSuggestionList.length > 0 ? genericSuggestionList.map((item, index) => {
                                return (<ListItem disablePadding key={index} >
                                    <ListItemButton emed_tid="drt_gen_sug" onClick={() => { this.handleGenericSuggestion(item) }}>
                                        <ListItemText primary={item.display_name} />
                                    </ListItemButton>
                                </ListItem>)
                            }) : null}
                        </List>
                    </Paper> : null}

            </div>
        )
    }

    tileBlock(label, data, width) {
        const { t } = this.props
        return (
            <Stack direction="column" className="eMed_pharmadrctpo_tileBlk" sx={{ width: width ? width : "7.20vw" }}>
                <Typography sx={{ fontSize: '0.78vw', color: "#888888" }}>{t(label)}</Typography>
                <Typography emed_tid={`C_${label}`} sx={{ fontSize: '0.9vw', marginTop: '0.2vw', fontWeight: 500 }}>{data}</Typography>
            </Stack>
        )
    }

    handleClear = (fromAdd = false) => {
        this.setState({
            data: { ...DEFAULT_SET },
            EPRValue: 0,
            isDifferentBatchExist: false,
            existBatchList: [],
            selectedBatchNo: "",
            brand_suggestionList: [],
            is_sales_amount_changed: false,
            is_discount_amount_changed: false,
            genericSuggestionList: [],
            Tabvalue: fromAdd ? "one" : this.state.Tabvalue,
            isBrandEdit: ((this.props.purchase_status === 'Partially Delivered') || this.props.purchase_status === '') ? false : true,
            is_group: false,
            PurchaseID: null,
            is_schedule: false,
            is_hsn: false,
            is_expiryalt: false,
            is_reorder: false,
            is_boxno: false,
            defaultReadOnly: ((this.props.purchase_status === 'Partially Delivered') || this.props.purchase_status === '') ? false : true,
            drug_from_dc: false,
        }, () => {
            if (!fromAdd) { this.props.IsClearEdit(); }
        })
    }

    renderPriceDetails = () => {
        var { drug_from_dc } = this.state
        return (
            <div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("QtyPerUnit", "pack_of_drugs", true, drug_from_dc ? true : false)}
                    {this.renderTextBox("NoofUnit", "sales_unit", true, drug_from_dc ? true : false, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("BuyingpriceperUnit", "buying_price", true)}
                    {this.renderTextBox("FreeQuantityUnit", "free_quantity", false, drug_from_dc ? true : false, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("Discount%", "discount_percentage", false, drug_from_dc ? true : false)}
                    {this.renderTextBox("DiscountAmount", "discount_amount", false, drug_from_dc ? true : false, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("S.Discount%", "sales_discount", false, drug_from_dc ? true : false)}
                    {this.renderTextBox("S.DiscountAmount", "sales_discount_amount", false, drug_from_dc ? true : false, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderDropDown("GST%", "gst_percentage", "label", "value", gstPercentage, "", false, false, "openGSt", 2)}
                    {this.renderTextBox("MRPUnit", "mrp_per_pack", true, drug_from_dc ? true : false, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("SalesDiscount%", "sales_discount_for_bill", false, drug_from_dc ? true : false)}
                </div>
            </div>
        );
    }

    renderFreeQty = () => {
        var { drug_from_dc } = this.state
        return (
            <div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("QtyPerUnit", "free_qty_per_unit", true, drug_from_dc ? true : false)}
                    {this.renderTextBox("NoofUnit", "free_no_of_unit", true, drug_from_dc ? true : false, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("MRPUnit", "free_mrp_unit", true, drug_from_dc ? true : false)}
                    {this.renderTextBox("SalesDiscount%", "free_sales_discount_bills", false, drug_from_dc ? true : false, "14vw")}
                </div>
            </div>
        );
    }

    handleBatchNoChange = (e) => {
        let value = e.target.value;
        this.setState({
            selectedBatchNo: value,
        })
    }

    render() {
        const { t } = this.props
        let { data, isDifferentBatchExist, existBatchList, openBatch, selectedBatchNo, defaultReadOnly, Tabvalue, drug_from_dc } = this.state;
        return (
            <Paper id="eMed_pharmadrctpo_rhtDiv">
                <Grid xs={12} item sx={{ height: "75vh" }}>
                    <Tabs
                        value={this.state.Tabvalue}
                        onChange={(e, newValue) => {
                            if (newValue === "two") {
                                data.free_qty_per_unit = data.pack_of_drugs;
                                data.free_no_of_unit = data.sales_unit;
                                data.free_mrp_unit = data.mrp_per_pack;
                                data.free_sales_discount_bills = data.sales_discount_for_bill;
                            }
                            this.setState({
                                data,
                                Tabvalue: newValue
                            }, () => this._calculateTransaction())
                        }}
                        id="eMed_pharmadrctpo_tabDiv"
                    >
                        <Tab emed_tid="drt_prc_dtl" value="one" label={t("PriceDetails")} className='eMed_tab_header' />
                        <Tab emed_tid="drt_free_qty" value="two" label={t("FreeQty(DiffBatch)")} className='eMed_tab_header' />
                    </Tabs>
                    <div sx={{ height: "69vh" }}>
                        <div className='eMed_pharmadrctpo_rhtHead'>
                            <p className='eMed_pharmadrctpo_rhtHead_txt'>{t("DrugDetails")}</p>
                        </div>
                        <div id="eMed_pharmadrctpo_rhtSubDiv">
                            <div id='eMed_pharmadrctpo_rhtCntBox1' className="eMed_pharmadrctpo_rhtCntBox">
                                <Stack spacing={3}>
                                    {this.renderBrandSearch()}
                                    {this.renderTextBox("GenericName", "generic_name", true, true, "30vw")}
                                </Stack>
                                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                                    {this.renderTextBox("DosageType", "dosage_type", true, true)}
                                    {this.renderTextBox("DosageStrength", "dosage_strength", true, true, "14vw")}
                                </div>
                                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                                    {this.renderTextBox("BatchNumber", "batch_no", true, drug_from_dc ? true : false)}
                                    {this.renderDatePicker("ExpiryDate", "expiry_date", "open", "14vw", true, 1)}
                                </div>
                                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                                    {this.renderTextBox("Schedule", "schedule", false, ((drug_from_dc || this.state.is_schedule) ? true : false))}
                                    {this.renderTextBox("Grouping", "group", false, ((drug_from_dc || this.state.is_group) ? true : false), "14vw")}
                                </div>
                                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                                    {this.renderTextBox("UPC", "bar_code", false, drug_from_dc ? true : false)}
                                    {this.renderTextBox("HSNCode", "hsn_code", false, ((drug_from_dc || this.state.is_hsn) ? true : false), "14vw")}
                                </div>
                            </div>
                            <div>
                                <div className='eMed_pharmadrctpo_rhtHead'>
                                    <p className='eMed_pharmadrctpo_rhtHead_txt'>{Tabvalue === "one" ? t("PriceDetails") : t("FreeQty")}</p>
                                </div>
                                <div id='eMed_pharmadrctpo_rhtCntBox2' className="eMed_pharmadrctpo_rhtCntBox">
                                    {Tabvalue === "one" ? this.renderPriceDetails() : this.renderFreeQty()}
                                    <div className="eMed_pharmadrctpo_rhtCnt_TleBlk">
                                        {this.tileBlock("GST%", Tabvalue === "two" ? 0 : data.gst_percentage, "7.10vw")}
                                        {this.tileBlock("GSTAmount", data.total_gst_amount, "7.10vw")}
                                        {this.tileBlock("TotalBuyingPrice", data.total_buying_price, "14.5vw")}
                                    </div>
                                    <div className="eMed_pharmadrctpo_rhtCnt_TleBlk">
                                        {this.tileBlock("Quantity", data.total_quantity, "14.9vw")}
                                        {this.tileBlock("MRPQuantity", data.mrp_per_quantity, "14.5vw")}
                                    </div>
                                    <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} ml={1} mb={2.5}>
                                        {this.tileBlock("EPR", this.state.EPRValue, "14.9vw")}
                                    </Stack>
                                    <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                                        {this.renderDropDown("ExpiryPeriod", 'expiry_alert_period', "label", "value", Expiry_period, "", false, this.state.is_expiryalt)}
                                        {this.renderTextBox("ReorderThreshold", "reorder_threshold", false, ((drug_from_dc || this.state.is_reorder) ? true : false), "14vw")}
                                    </div>
                                    <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                                        {this.renderTextBox("PhyBox/RoomNo", "physical_box_no", false, ((drug_from_dc || this.state.is_boxno) ? true : false))}
                                        {this.renderTextBox("Description", "description", false, drug_from_dc ? true : false, "14vw")}
                                    </div>
                                    <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                                        {this.renderTextBox("ManufactureName", "manufacturer_name", false, drug_from_dc ? true : false)}
                                        {this.renderTextBox("RecommendedBy", "recommended_by", false, drug_from_dc ? true : false, "14vw")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Stack spacing={2} direction="row" id='eMed_pharmadrctpo_rhtBtnCon' alignItems="center" justifyContent="center">
                            <Button emed_tid="drt_clear" variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                            <Button emed_tid="drt_add" variant='contained' size="small" disabled={this.props.isFinished ? true : false}
                                id="eMed_Config_btn"
                                onClick={() => { this.handleAdd() }}>{t("Add")}</Button>
                        </Stack>

                        {
                            isDifferentBatchExist && existBatchList.length > 0 ?
                                <Modal open={openBatch}>
                                    <Box id="eMed_pharmadrctpo_btcBox">
                                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                            <p className='eMed_bat_font'>{t("ConfirmBatchNo")}</p>
                                            <FormControl sx={{ m: 2, width: 300 }}>
                                                <InputLabel >{t("BatchNumber")}</InputLabel>
                                                <Select
                                                    value={selectedBatchNo ? selectedBatchNo : ""}
                                                    onChange={(e) => this.handleBatchNoChange(e)}
                                                    input={<OutlinedInput label={t("BatchNumber")} />}
                                                    renderValue={(selected) => selected}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 350,
                                                            width: 250
                                                        }
                                                    }}
                                                    inputProps={{ emed_tid: "BatchNumber" }}
                                                >
                                                    {existBatchList && existBatchList.length > 0 && existBatchList.map((item, index) => (
                                                        <MenuItem key={index} value={item}>
                                                            <ListItemText primary={item} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                                <Button emed_tid="drt_batch_upt" disabled={selectedBatchNo ? false : true} size="small" variant="contained" sx={{ backgroundColor: selectedBatchNo ? null : "#ccccb3" }}
                                                    onClick={() => {
                                                        let { data, EPRValue, selectedBatchNo } = this.state;
                                                        data.epr_value = EPRValue
                                                        data.batch_no = selectedBatchNo
                                                        this.props.sendToList(data, false, this.state.Tabvalue)
                                                        this.setState({
                                                            data: { ...DEFAULT_SET },
                                                            EPRValue: 0,
                                                            isDifferentBatchExist: false,
                                                            existBatchList: [],
                                                            selectedBatchNo: "",
                                                        })
                                                    }}>{t("Update")}</Button>
                                                <Button emed_tid="drt_batch_cnl" size="small" variant="outlined" onClick={() => {
                                                    this.setState({
                                                        open: false
                                                    }, () => {
                                                        this._AddToList();
                                                    })
                                                }}>{t("Cancel")}</Button>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Modal>
                                : null
                        }
                    </div>
                </Grid>
            </Paper>
        )
    }
}
export default withTranslation()(PharmaDCRight);

import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';

class PatientReport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      patientList: [],
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        'patientName': '',
      },
      pageName: 'patientReport',
      filterOpen: false,
      totalAmount: 0,
      isLoader: false,
    }
  }

  componentDidMount() {
    this.getpatientList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => { this.getpatientList() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
      </Box>

    )
  }

  getpatientList = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.PHARMA_PATIENT_LIST + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&search_key=${this.state.filterDatas.patientName}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
              this.setState({
                  patientList: response.data.data?.patient_detail,
                  totalAmount: response.data.data?.total_amount,
              })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

//   getRefDoctorListPrint = () => {
//     try {
//       RestAPIService.getAll(Serviceurls.PHARMA_REF_DOCTOR + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export=pdf`).
//         then((response) => {
//           const file = new Blob(
//             [response.data],
//             { type: 'application/pdf' });
//           const fileURL = URL.createObjectURL(file);
//           window.open(fileURL);
//         }).catch((error) => {
//           if (error.response?.data?.message) {
//             this.errorMessage(error.response?.data?.message);
//           } else {
//             this.errorMessage(error.message);
//           }
//         })
//     } catch (error) {
//       this.errorMessage(error.message)
//     }
//   }

  render() {
    const { t } = this.props
    this.state.patientList.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'patient_name', headerName: "Patient Name", flex: 0.25,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.patient_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.patient_name} arrow><div>{params?.row?.patient_name?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.patient_name ? params?.row?.patient_name : '-'}
        </Box>)
      },
      {
        field: 'mobile_number', headerName: "Mobile No", flex: 0.25,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.mobile_number ? params?.row?.mobile_number : '-'}
        </Box>)
      },
      {
        field: 'count', headerName: "No of Bills", flex: 0.25,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.count ? params?.row?.count : '-'}
        </Box>)
      },
      {
        field: 'total', headerName: "Bill Amount", flex: 0.25,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.total ? params?.row?.total : '-'}
        </Box>)
      },
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard("Total Amount", this.state.totalAmount)}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Button emed_tid = "rpt_stnPat_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            {/* <Button className='eMed_Pharma_rts_btn' onClick={()=>{this.getRefDoctorListPrint()}}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
            </Button> */}
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.patientList}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            loading= {this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(PatientReport)

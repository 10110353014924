import React, { Component } from "react";
import { Box, Typography } from "@mui/material";
import { Trans, withTranslation } from 'react-i18next';
import { CommonNavigationHeader } from "../../../Components/Common Components/CommonComponents";
import "./inventory.css"
import StockList from "./StockList";
import ActiveInventory from "./ActiveInventory";
import InActiveInventory from "./InActiveInventory";
import NoMovement from "./NoMovement";
import StockExpiry from "./StockExpiry";
import NewDrug from "./NewDrug";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import ExpiredStock from "./ExpiredStock";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";



const navigationHeaderList = [
    { id: 0, TabName: "Stock List", value: "stockList" },
    { id: 1, TabName: "Active Inventory", value: "activeList" },
    { id: 2, TabName: "Inactive Inventory", value: "inActiveList" },
    { id: 3, TabName: "No movement Inventory", value: "noMovementList" },
    { id: 4, TabName: "Short Expiry Stock", value: "shortExpiryList" },
    { id: 5, TabName: "New Product Status", value: "newDrugList" },
    { id: 6, TabName: "Expired Stock", value: "expiredStock"}
]
class Inventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: "stockList",
            showSuccessResponse: false,
            showErrorResponse:false ,
            responseMessage: "",
            selectedTabID: 0,
            stock_search:'',
            active_search:'',
            inactive_search:'',
            no_mov_search:'',
            quick_expiry_search:'',
            new_product_search:'',
            common_search:''
        }
    }

    componentDidMount(){
        this.props.changeScreen('3')
        this.props.subScreenChange("Inventory")
    }

    componentWillReceiveProps(props){
        let {selectedScreen} = this.state
        if(props.inventorySearch !== this.state.common_search){
            this.setState({
                common_search : props.inventorySearch
            })
            if(selectedScreen === "stockList"){
                this.setState({
                    stock_search : props.inventorySearch
                })
            }else if(selectedScreen === "activeList"){
                this.setState({
                    active_search : props.inventorySearch
                })
            }else if(selectedScreen === "inActiveList"){
                this.setState({
                    inactive_search : props.inventorySearch
                })
            }else if(selectedScreen === "noMovementList"){
                this.setState({
                    no_mov_search : props.inventorySearch
                })
            }else if(selectedScreen === "shortExpiryList"){
                this.setState({
                    quick_expiry_search : props.inventorySearch
                })
            }else if(selectedScreen === "newDrugList"){
                this.setState({
                    new_product_search : props.inventorySearch
                })
            }else if(selectedScreen === 'expiredStock'){
                this.setState({
                    expired_stock : props.inventorySearch
                })
            }
        }
    }

    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse:false ,
            responseMessage: "",
        })
    }

    NavigateScreen(id) { 
        this.setState({
            selectedScreen: navigationHeaderList[id].value,
            selectedTabID: id
        })
        this.props.subScreenChange(navigationHeaderList[id].value)
        this.props.clrSearch()
    }

    selectedTab = (value) => {
        this.props.subScreenChange("")
        this.setState({
            selectedScreen: "stockList",
            selectedTabID: 0
        })
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }
    render() {
        const { t } = this.props
        let {showSuccessResponse, showErrorResponse, responseMessage } = this.state
        return (
            <Box id = "emedhub_inventory_Container">
                <CommonNavigationHeader menu = {navigationHeaderList} NavigateScreen = {this.NavigateScreen.bind(this)} selectedTabID = {this.state.selectedTabID} />
                <div>
                    {(this.state.selectedScreen === "stockList" &&  CheckAccessFunc("Laboratory", "Inventory", "Stock List", null, "Tab")?.viewAccess) ? <StockList search_key={this.state.stock_search}  NavigateScreen = {this.NavigateScreen.bind(this)} responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)}/> : 
                    (this.state.selectedScreen === 'activeList' &&  CheckAccessFunc("Laboratory", "Inventory","Active Inventory", null, "Tab")?.viewAccess) ? <ActiveInventory search_key={this.state.active_search}  responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)} /> :
                    (this.state.selectedScreen === 'inActiveList' &&  CheckAccessFunc("Laboratory", "Inventory", "Inactive Inventory", null, "Tab")?.viewAccess) ? <InActiveInventory search_key={this.state.inactive_search}  responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)} /> : 
                    (this.state.selectedScreen === 'noMovementList' &&  CheckAccessFunc("Laboratory", "Inventory", "No Movement Inventory", null, "Tab")?.viewAccess) ? <NoMovement search_key={this.state.no_mov_search}  responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)} /> :
                    (this.state.selectedScreen === 'shortExpiryList' &&  CheckAccessFunc("Laboratory", "Inventory", "Short Expiry Stock", null, "Tab")?.viewAccess) ? <StockExpiry search_key={this.state.quick_expiry_search} responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)} /> :
                    (this.state.selectedScreen === 'newDrugList' &&  CheckAccessFunc("Laboratory", "Inventory", "New Product Status", null, "Tab")?.viewAccess) ? <NewDrug search_key={this.state.new_product_search}  responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)} selectedTab = {this.selectedTab} /> :
                    (this.state.selectedScreen === 'expiredStock' &&  CheckAccessFunc("Laboratory", "Inventory", "Expired Status", null, "Tab")?.viewAccess) ? <ExpiredStock search_key={this.state.expired_stock} /> : 
                    <Typography className='eMed_NoAccess_Text' sx={{paddingTop:"13vw"}}>No Access For This Screen</Typography>}
                </div>
                {
                    showSuccessResponse ? <ToastMsg severity = {"success"} msg = {responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                { 
                    showErrorResponse ? <ToastMsg severity = {"error"} msg = {responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}

export default withTranslation()(Inventory);
import React, { Component } from 'react';
import { Typography, Button, TextField, OutlinedInput, InputAdornment, Tooltip, MenuItem } from '@mui/material';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import './../../../Laboratory/Home/home.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CircularProgress from '@mui/material/CircularProgress';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
class IPReturns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: "",
            ipReturnData: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        }
    }
    componentDidMount() {
        this.getIPReturn()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getIPReturn() {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_RETURN_HOME + "?search_key=" + this.state.searchkey)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            ipReturnData: response.data.data
                        }, ()=>{this.LoaderFunction(false)});
                    }
                })
                .catch(e => {
                    if (e?.reponse?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.reponse.data.status)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.message)
                    }
                });
        }
        catch (e) {
            this.errorMsgFunc(e.message)
        }
    }
    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    // getIPReturnPrint = (patient_id) => {
    //     RestAPIService.getAll(Serviceurls.PRESCRIPTION_HOME + `?export_type=pdf&patient_id=${patient_id}`).
    //         then((response) => {
    //             const file = new Blob(
    //                 [response.data],
    //                 { type: 'application/pdf' });
    //             const fileURL = URL.createObjectURL(file);
    //             window.open(fileURL);
    //         }).catch((error) => {
    //             this.setState({
    //                 isErrorMsg: true,
    //                 isErrorMsgText: error.reponse.data.message
    //             }, () => {
    //                 setTimeout(() => {
    //                     this.setState({ isErrorMsg: false })
    //                 }, 2000);
    //             })
    //         })
    // }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props
        const { history } = this.props.properties
        return (
            <div>
                <div style={{ display: "flex", margin: "0.5vw" }}>
                    <label style={{ flex: "0.5" }}><b>{t("In Patient Return")}</b></label>
                    <div style={{ flex: "0.5", display: "flex", justifyContent: "end" }}
                        onClick={() => { this.props.onClickreturnClose(false) }}>
                        <img
                            src={ImagePaths.LabCloseButton.default}
                            style={{ cursor: "pointer", height: "1vw", width: "1vw" }}
                            alt=""
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <OutlinedInput
                        placeholder="Search Patients..."
                        onChange={(e) => {
                            this.setState({
                                searchkey: e.target.value
                            }, () => {
                                this.getIPReturn()
                            })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "13vw", marginRight: "0vw" }}
                        endAdornment={
                            <InputAdornment position="end">
                                <i class="fas fa-search"></i>
                            </InputAdornment>
                        }
                        inputProps={{emed_tid:"IPreturn_search_bill"}}
                    />
                </div>
                {this.state.ipReturnData && this.state.ipReturnData.length > 0 ?
                    <div className='eMed_Pres_DataView'>
                        {this.state.ipReturnData.map((item, index) => {
                            const created_date = item?.created_date;

                            // Step 1: Parse the date string into a JavaScript Date object
                            const parts = created_date.split(/[- :]/);
                            const day = parseInt(parts[0], 10);
                            const month = parseInt(parts[1], 10);
                            const year = parseInt(parts[2], 10);
                            let hour = parseInt(parts[3], 10);
                            const minute = parseInt(parts[4], 10);
                            const ampm = parts[5];

                            // Adjust the hour for 12-hour format
                            // if (ampm === "PM" && hour !== 12) {
                            //     hour += 12;
                            // } else if (ampm === "AM" && hour === 12) {
                            //     hour = 0;
                            // }
                            let billDate = item?.invoice_date?.slice(0,10)
                            let rtnDetails = localGetItem("pharmacy_rtn_period")
                            let rtnData = JSON.parse(rtnDetails)
                            return (
                                <MenuItem emed_tid="IPreturn_toBill_item" onClick={()=>{
                                    let isReturn = false
                                    if (rtnData?.rtn_type === 'Days') {
                                        let beforeDate = CommonValidation.getDateBefore(rtnData?.rtn_value)
                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                        if (new Date(billDate) >= new Date(configDate)) {
                                            isReturn = true;
                                        }
                                    } else if (rtnData?.rtn_type === 'Weeks') {
                                        let beforeDate = CommonValidation.getDateBeforeWeeks(rtnData?.rtn_value)
                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                        if (new Date(billDate) >= new Date(configDate)) {
                                            isReturn = true;
                                        }
                                    } else if (rtnData?.rtn_type === 'Months') {
                                        let beforeDate = CommonValidation.getDateBeforeMonths(rtnData?.rtn_value)
                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                        if (new Date(billDate) >= new Date(configDate)) {
                                            isReturn = true;
                                        }
                                    }
                                    if(isReturn){
                                        this.props.properties.history.push({ pathname: "/PharmacyBilling", state: { isFromIpReturn: true , billDetails : item } })
                                        this.props.onClickreturnClose(false)
                                    }else{
                                        this.errorMsgFunc("Invoice Date exceed Return Period")
                                    }
                                }} 
                                
                                className="Lab_Home_Saved_Bill" style={{ cursor: 'pointer' }}>
                                    <div style={{ flex: 0.10 }}>{index + 1}</div>
                                    <div style={{ flex: 0.15 }}><img src={ImagePaths.Male.default} alt='Avatar' /></div>
                                    <div style={{ flex: 0.50 }}>
                                        {item?.patient_name.length > 15 ?
                                            <Tooltip title={item?.patient_name} placement='top'>
                                                <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name.slice(0, 15) + '...' : '-'}</Typography>
                                            </Tooltip> : <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name : '-'}</Typography>}
                                        <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{`${item?.patient_mobile ? item?.patient_mobile : '-'} | ${item?.patient_account_number ? item?.patient_account_number : '-'}`}</Typography>
                                        {item?.ip_number.length > 15 ?
                                            <Tooltip title={item?.ip_number} placement='top'>
                                                <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{`${'IP No: ' + (item?.ip_number ? '...' + item.ip_number.slice(15) : '-')}`}</Typography>
                                            </Tooltip> : <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{`${'IP No: ' + (item?.ip_number ? item?.ip_number : '-')}`}</Typography>}
                                    </div>
                                    <div style={{ flex: 0.25 }}>
                                        <Typography className='savedBillText'>{`${day}-${month}-${year}`}</Typography>
                                        <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{`${hour}:${minute.toString().padStart(2, '0')} ${ampm}`}</Typography>
                                    </div>
                                </MenuItem>
                            )
                        })}


                    </div> :
                   this.state.isLoader ? <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}} className='eMed_App_DataView'><CircularProgress/></div>:<div><Typography id='eMed_Pres_pop_NORec'>{t("No records found")}</Typography></div>}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        );
    }
}
export default withTranslation()(IPReturns)
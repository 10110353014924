import { Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';

class PrescriptionRegister extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Pres_regData: [],
            page: 0,
            pageSize: 10,
            filterOpen: false,
            isLoader: false,
            dateIndex: 1,
            schedule:"",
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            "days":null
        }
    }

    componentDidMount() {
        this.getPrescriptionRegData()
    }

    getPrescriptionRegData = () => {
        try {
            this.LoaderFunction(true);
            let Schedule = this.state.schedule?.replaceAll('&','%26')
            RestAPIService.getAll(Serviceurls.PHARMA_PRESCRIPTION_REG + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&schedule=${Schedule}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            Pres_regData: response.data.data?.result ? response.data.data?.result : [],
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
        }
    }

    getPrescriptionRegPrint = () => {
        try {
            this.setState({disableBtn: true})
            let Schedule = this.state.schedule?.replaceAll('&','%26')
            RestAPIService.getAll(Serviceurls.PHARMA_PRESCRIPTION_REG + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&schedule=${Schedule}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    filterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button emed_tid='purord_close' onClick={() => { this.setState({ filterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle' height={'35vh'}>
                    <Box sx={{ width: "40%", margin: "1vw", marginLeft: "14vw", }}>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.filterDate.bind(this)}
                            HideAllMenu={true}
                        />
                    </Box>
                    <Box>
                        <TextField
                            size='small'
                            autoComplete='off'
                            sx={{ width: '22vw', margin: '0.5vw' }}
                            value={this.state.schedule}
                            label={"Schedule"}
                            inputProps={{ emed_tid: "Schedule" }}
                            onChange={(e) => { 
                                this.setState({
                                    schedule: e.target.value
                                })
                             }}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        emed_tid='fil_clear'
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                schedule : ""
                            }, () => this.getPrescriptionRegData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        emed_tid='fil_search'
                        onClick={() => {
                            this.getPrescriptionRegData()
                            this.setState({
                                filterOpen: false,
                            })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    closeFilter = () => {
        this.setState({ filterOpen: false })
    }

    render() {
        const { t } = this.props
        this.state.Pres_regData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.06 },
            {
                field: 'patient_name', headerName: "Patient Name", flex: 0.15   , type: 'string',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.patient_name?.length > 15 ? <Tooltip placement='top' title={params?.row?.patient_name} arrow><div>{params?.row?.patient_name?.slice(0, 15) + '...'}</div></Tooltip> :
                        params?.row?.patient_name ? params?.row?.patient_name : '-'}
                </Box>)
            },
            {
                field: 'invoice_number', headerName: "Bill No", flex: 0.12, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number?.length > 15 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{ '...' + params?.row?.invoice_number?.slice(-5) }</div></Tooltip> :
                        params?.row?.invoice_number ? params?.row?.invoice_number : '-'}
                </Box>)
            },
            {
                field: 'invoice_date', headerName: "Bill Date", flex: 0.12, align: 'center', headerAlign: 'center',
                renderCell: (params) => {
                    return (<Box component={'div'}> {params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>)
                }
            },
            {
                field: 'doctor_name', headerName: `${"Doctor Name"}`, flex: 0.15, type: "string",
                renderCell: (params) => (<Box>{params?.row?.doctor_name?.length > 15 ? <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name?.slice(0, 15) + '...'}</div></Tooltip> :
                params?.row?.doctor_name ? params?.row?.doctor_name : '-'}</Box>)
            },
            {
                field: 'brand_name', headerName: `${"Medicine Name"}`, flex: 0.15, type: "string",
                renderCell: (params) => (<Box>{params?.row?.brand_name?.length > 15 ? <Tooltip placement='top' title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name?.slice(0, 15) + '...'}</div></Tooltip> :
                params?.row?.brand_name ? params?.row?.brand_name : '-'}</Box>)
            },
            {
                field: 'manufacturer_name', headerName: `${"Manufacture Name"}`, flex: 0.15, type: "string",
                renderCell: (params) => (<Box>{params?.row?.manufacturer_name?.length > 15 ? <Tooltip placement='top' title={params?.row?.manufacturer_name} arrow><div>{params?.row?.manufacturer_name?.slice(0, 15) + '...'}</div></Tooltip> :
                params?.row?.manufacturer_name ? params?.row?.manufacturer_name : '-'}</Box>)
            },
            {
                field: 'quantity', headerName: `${"Quatity"}`, flex: 0.10, type: "number",
                renderCell: (params) => (<Box>{(params.row?.quantity) ? params.row?.quantity : '0'}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {/* {AmountsCard("Purchase Amount", this.state.pur_Amt, false, "white", false, null, 'rpt_consoli_pur_total')}
                        {AmountsCard("Bill Amount", this.state.bill_Amt, false, "white", false, null, 'rpt_consoli_sal_total')}
                        {AmountsCard("GST Amount", this.state.gst_Amt, false, "white", false, null, 'rpt_consoli_pro_total')} */}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_saleOver_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => { this.getPrescriptionRegPrint() }}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.Pres_regData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(PrescriptionRegister)
import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Select, TextField, Typography, FormControl,
    InputLabel, FormControlLabel, Checkbox, Autocomplete, OutlinedInput, Divider, Tooltip, IconButton, Switch
} from '@mui/material';
import { withTranslation } from 'react-i18next'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';

class InvoiceFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            ref: props.location?.pathname,
            FilterOpen: false,
            DortorList: [],
            FilterDatas:
            {
                "FromDate": this.props.FilterDatas.FromDate,
                "ToDate": this.props.FilterDatas.ToDate,
                "PayMethods": this.props.FilterDatas.PayMethods,
                "PayMethodsisCombained": this.props.FilterDatas.PayMethodsisCombained,
                "PayMethodsisReturn": this.props.FilterDatas.PayMethodsisReturn,
                "DateIndex": this.props.FilterDatas.DateIndex,
                "InvoiceNumber": this.props.FilterDatas?.InvoiceNumber,
                "BillType": this.props.FilterDatas?.BillType,
                "InPatientSelected": this.props.FilterDatas.InPatientSelected,
                "OutPatientSelected": this.props.FilterDatas.OutPatientSelected,
                "PharmacySelected": this.props.FilterDatas.PharmacySelected,
                "CreditStatus": this.props.FilterDatas.CreditStatus,
                "InsuranceCredit": this.props.FilterDatas.InsuranceCredit,
                "CorporateCredit": this.props.FilterDatas.CorporateCredit,
                "PatientCredit": this.props.FilterDatas.PatientCredit,
                "is_bill_wise" : this.props.FilterDatas?.is_bill_wise ? this.props.FilterDatas?.is_bill_wise : false,
                "phoneNumber": this.props.FilterDatas?.phoneNumber,
                "patient_Name": this.props.FilterDatas?.patient_Name,
                "UHID_No": this.props.FilterDatas?.UHID_No,
                "Bill_No": this.props.FilterDatas?.Bill_No,
                "IncludePaidBills": this.props.FilterDatas?.IncludePaidBills,
            },
            disableBtn: false,
        }
    }


    FilterDate = (From, To, Index) => {
        this.state.FilterDatas.FromDate = From
        this.state.FilterDatas.ToDate = To
        this.state.FilterDatas.DateIndex = Index
        this.setState({ FilterDatas: this.state.FilterDatas })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    handleCheckBox = (e, key) => {
        let CreditStatus = [];
        let BillType = [];
        if (key === "Combined") {
            this.state.FilterDatas.PayMethodsisCombained = e.target.checked
            this.setState({ FilterDatas: this.state.FilterDatas })
        } else if (key === "Returned") {
            this.state.FilterDatas.PayMethodsisReturn = e.target.checked
            if (e.target.checked) { this.state.FilterDatas.PayMethods = [] }
            this.setState({ FilterDatas: this.state.FilterDatas })
        } else {
            let tempList = this.state.FilterDatas?.PayMethods;
            if (tempList.indexOf(key) > -1) {
                tempList.splice(tempList.indexOf(key), 1)
            } else {
                if (tempList.length > 1)
                    tempList.splice(0, 1)
                tempList.push(key)
            }
            this.state.FilterDatas[key] = e.target.checked
            this.state.FilterDatas.PayMethods = tempList

            if (tempList.length > 0) { this.state.FilterDatas.PayMethodsisReturn = false }
            this.setState({ FilterDatas: this.state.FilterDatas }, () => {
                // Credit type
                if (this.state.FilterDatas.CorporateCredit) {
                    CreditStatus.push('Corprate credit')
                }
                if (this.state.FilterDatas.InsuranceCredit) {
                    CreditStatus.push('Insurance credit')
                }
                if (this.state.FilterDatas.PatientCredit) {
                    CreditStatus.push('Patient credit')
                }


                // Bill type
                if (this.state.FilterDatas.InPatientSelected) {
                    BillType.push("ip")
                }
                if (this.state.FilterDatas.OutPatientSelected) {
                    BillType.push("op")
                }
                if (this.state.FilterDatas.PharmacySelected) {
                    BillType.push("pharmacy")
                }
                this.state.FilterDatas.CreditStatus = CreditStatus
                this.state.FilterDatas.BillType = BillType
                this.setState({ FilterDatas: this.state.FilterDatas })
            })
        }
    }

    errorMessage = (msg) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: msg,
        })
    }

    FilterSearch() {
        this.setState({ FilterOpen: false, disableBtn: true })
        this.props.FilteredData(this.state.FilterDatas)
        
    }

    ClearFilterData() {
        this.setState({
            FilterDatas: {
                "FromDate": (this.props.PageName === "CompletedList") ? "" : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": (this.props.PageName === "CompletedList") ? "" : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": (this.props.PageName === "CompletedList") ? 0 : 1,
                "PayMethods": [],
                "PayMethodsisCombained": false,
                "PayMethodsisReturn": false,
                "InvoiceNumber": "",
                "BillType": ['op', 'ip', 'pharmacy'],
                "CreditStatus": ['Corprate credit', 'Insurance credit', 'Patient credit'],
                "is_bill_wise" : false,
                "phoneNumber": '',
                "patient_Name": '',
                "UHID_No": '',
                "Bill_No": "",
                "IncludePaidBills": false,
            },disableBtn: true,
        }, () => { this.props.ClearFilterData(this.state.FilterDatas) })
    }

    render() {
        const { t } = this.props
        let states = this.state
        return (
            <Box component={"div"} className='eMed_Filter_Billing_Container'>
                <Box component={"div"} className='eMed_Filter_Top'>
                    <Typography variant='h6' fontWeight={"600"}>{t("Filter")}</Typography>
                    <Button emed_tid="close_filter" onClick={() => { this.setState({ FilterOpen: false }, () => { this.props.CloseFilter() }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box component={"div"} className='eMed_Filter_DateFilter' display={'flex'} justifyContent={"flex-end"}>
                    <Box style={{ width: "50%", marginRight: "1vw" }} component={"div"}>
                        <CommonDatePicker
                            FromDate={this.state.FilterDatas.FromDate}
                            ToDate={this.state.FilterDatas.ToDate}
                            DateIndex={this.state.FilterDatas.DateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={(this.props.PageName === "CompletedList" || this.props.PageName === "CreditBills") ? false : true}
                            PharmaDateFilterRestriction={this.props.PageName === "InvoiceList" ? true : false} />
                    </Box>
                </Box>

                {this.props.PageName === "CompletedList" ?
                    <Box component={"div"} style={{ width: "100%", padding: "0.5vw" }}>
                        <OutlinedInput
                            placeholder={t("Search Invoice No")}
                            onChange={(e) => {
                                states.FilterDatas.InvoiceNumber = e.target.value
                                this.setState({
                                    states
                                })
                            }}
                            style={{ height: "3vw", width: "95%", padding: "0.5vw" }}
                            size='medium'
                            value={this.state?.FilterDatas?.InvoiceNumber}
                            endAdornment={
                                <Box component={'div'} display={'flex'} flexDirection={'row'}>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Tooltip title={t("Search")} arrow>
                                        <IconButton position="end">
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                        />
                    </Box> : null}

                {
                    ((this.props.PageName === "InvoiceList") || ((this.props.PageName === "CompletedList"))) ?
                        <Box component={"div"}>
                            <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"} >Payment Method</Typography>
                            </Box>
                            <Box className="eMed_CheckBox_Group">
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="cash_filter" checked={this.state.FilterDatas?.PayMethods?.includes("Cash")} onChange={(e) => { this.handleCheckBox(e, "Cash") }} size='small' />} label={t("Cash")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="card_filter" checked={this.state.FilterDatas?.PayMethods?.includes("Card")} onChange={(e) => { this.handleCheckBox(e, "Card") }} size='small' />} label={t("Card")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="upi_filter" checked={this.state.FilterDatas?.PayMethods?.includes("UPI")} onChange={(e) => { this.handleCheckBox(e, "UPI") }} size='small' />} label={t("UPI")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="bank_filter" checked={this.state.FilterDatas?.PayMethods?.includes("Bank Transfer")} onChange={(e) => { this.handleCheckBox(e, "Bank Transfer") }} size='small' />} label={t("BankTransfer")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="insurance_filter" checked={this.state.FilterDatas?.PayMethods?.includes("Insurance")} onChange={(e) => { this.handleCheckBox(e, "Insurance") }} size='small' />} label={t("Insurance")} />
                                {this.props.PageName === "InvoiceList" ?
                                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="returned_filter" checked={this.state.FilterDatas.PayMethodsisReturn} onChange={(e) => { this.handleCheckBox(e, "Returned") }} size='small' />} label={t("Returned")} /> : null}
                                {this.props.PageName === "InvoiceList" || this.props.PageName === "CompletedList" ?
                                    <FormControlLabel disabled={this.state.FilterDatas?.PayMethods?.length < 2} className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="combined_filter" checked={this.state.FilterDatas.PayMethodsisCombained} onChange={(e) => { this.handleCheckBox(e, "Combined") }} size='small' />} label={t("Combined")} /> : null}

                            </Box>
                        </Box> : null
                }

                {
                    this.props.PageName === "CreditBills" ?
                        <Box component={"div"}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingY: '0.5vw', paddingLeft: "1vw" }}>
                                <label style={{ paddingRight: '0.8vw', paddingTop: '0.1vw' }}>Admission Wise</label>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Switch
                                            size='small'
                                            color="primary"
                                            checked={this.state.FilterDatas?.is_bill_wise ? true : false}
                                            onChange={(e) => {
                                                states.FilterDatas.is_bill_wise = e.target.checked
                                                states.FilterDatas.Bill_No = ''
                                                this.setState({
                                                    FilterDatas: states.FilterDatas
                                                })
                                            }} />}
                                    label="Bill Wise"
                                    labelPlacement="end"
                                />
                            </Box>
                            {this.props.PageName === 'CreditBills' ?
                                <Box>
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            label={t('Patient Name')}
                                            value={this.state.FilterDatas.patient_Name}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.patient_Name = e.target.value.toUpperCase()
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }}
                                        />
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            sx={{ marginLeft: '1vw' }}
                                            label={`UHID`}
                                            value={this.state.FilterDatas.UHID_No}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                if (number || e.target.value === "") {
                                                    this.state.FilterDatas.UHID_No = e.target.value
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }
                                            }
                                        />
                                    </Box>
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} marginLeft={'1vw'}>
                                        <TextField
                                            className="eMed_Bill_Filter_TextFeild_small"
                                            size='small'
                                            label={`Mobile No`}
                                            value={this.state.FilterDatas.phoneNumber}
                                            autoComplete='off'
                                            onChange={(e) => {
                                                let number = CommonValidation.numberOnly(e.target.value);
                                                if ((number && e.target.value.length < 11) || e.target.value === "") {
                                                    this.state.FilterDatas.phoneNumber = e.target.value
                                                    this.setState({ FilterDatas: this.state.FilterDatas })
                                                }
                                            }
                                            }
                                        />
                                        {this.state.FilterDatas?.is_bill_wise ?
                                            <TextField
                                                className="eMed_Bill_Filter_TextFeild_small"
                                                size='small'
                                                sx={{ marginLeft: '1vw' }}
                                                label={t("BillNumber")}
                                                value={this.state.FilterDatas.Bill_No}
                                                autoComplete='off'
                                                onChange={(e) => {
                                                    let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                                    if (number || e.target.value === "") {
                                                        this.state.FilterDatas.Bill_No = e.target.value.toUpperCase()
                                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                                    }
                                                }}
                                            /> : null}
                                    </Box>
                                </Box>

                                : null}
                            {this.props.PageName === 'CreditBills' && !this.props?.Pharmacy ?
                                <Box component={"div"}>
                                    <Box className="eMed_CheckBox_Group">
                                        <FormControlLabel sx={{ width: '12vw' }} control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.FilterDatas.IncludePaidBills} onChange={(e) => { this.handleCheckBox(e, "IncludePaidBills",) }} size='small' />} label={t("Include Paid Bills")} />
                                    </Box>
                                </Box> : null}
                            <Box component={"div"} sx={{ marginBottom: "0.625vw" }}>
                                <Box className="eMed_Filter_Header">
                                    <Typography marginLeft={"1vw"}>Bill Type</Typography>
                                </Box>
                                <Box className="eMed_CheckBox_Group">
                                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="cash_filter" checked={this.state.FilterDatas?.InPatientSelected} onChange={(e) => { this.handleCheckBox(e, "InPatientSelected") }} size='small' />} label={t("IP")} />
                                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="card_filter" checked={this.state.FilterDatas?.OutPatientSelected} onChange={(e) => { this.handleCheckBox(e, "OutPatientSelected") }} size='small' />} label={t("OP")} />
                                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox emed_tid="upi_filter" checked={this.state.FilterDatas?.PharmacySelected} onChange={(e) => { this.handleCheckBox(e, "PharmacySelected") }} size='small' />} label={t("Pharmacy")} />
                                </Box>
                            </Box>
                            <Box component={"div"} sx={{ marginBottom: "0.625vw" }}>
                                <Box className="eMed_Filter_Header">
                                    <Typography marginLeft={"1vw"} >Credit Type</Typography>
                                </Box>
                                <Box className="eMed_CheckBox_Group">
                                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.CorporateCredit} onChange={(e) => { this.handleCheckBox(e, "CorporateCredit") }} size='small' />} label={t("Corporate")} />
                                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.InsuranceCredit} onChange={(e) => { this.handleCheckBox(e, "InsuranceCredit") }} size='small' />} label={t("Insurance")} />
                                    <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.PatientCredit} onChange={(e) => { this.handleCheckBox(e, "PatientCredit") }} size='small' />} label={t("Patient")} />
                                </Box>
                            </Box>
                        </Box>
                        : null
                }

                <Box component={"div"} className="eMed_Filter_Btn_Div">
                    <Button emed_tid="close_btn_filter" size="small" disabled={this.state.disableBtn} className='eMed_Filter_Btns' onClick={() => { this.ClearFilterData() }} variant='outlined'>{t("Clear")}</Button>
                    <Button emed_tid="search_filter" size="small" disabled={this.state.disableBtn || this.state.FilterDatas.CreditStatus.length === 0 || this.state.FilterDatas.BillType.length === 0} className='eMed_Filter_Btns' onClick={() => { this.FilterSearch() }} variant='contained'>{t("Search")}</Button>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

            </Box>
        )
    }
}
export default withTranslation()(InvoiceFilter)

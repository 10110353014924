import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import Loader from '../../../../Components/Loader';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { ImagePaths } from '../../../../Utility/ImagePaths';

const Unit_List = [
  { label: 'mmHg', value: 'mmHg' },
  { label: 'Volt', value: 'volt' }
]

export default class DocBranchicalIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      unitList: Unit_List,
      brachialIndexData: [],
      loading: false,
      postLoading: false,
      abindex_left: '',
      abindex_right: '',
      comments: '',
      hand_left: '',
      hand_right: '',
      leg_dpa_left: '',
      leg_dpa_right: '',
      leg_pta_left: '',
      leg_pta_right: '',
      tbindex_left: '',
      tbindex_right: '',
      toe_left: '',
      toe_right: '',
      selectedUnit: 'mmHg',
      isEdit: false,
      dataAdded: false,
      selectedId: null,
      isZoomTop: false,
      isZoomBtm: false,
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getBrachialIndexData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getBrachialIndexData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_BRANCHICAL_INDEX_LOG + `?appointment_id=${this.state.appointmentId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if (response?.data?.data?.length > 0) {
              this.setState({
                brachialIndexData: response?.data?.data,
                loading: false,
                dataAdded: true
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postBrachialIndexData = () => {
    try {
      let states = this.state
      let data = {
        abindex_left: +states.abindex_left,
        abindex_right: +states.abindex_right,
        appointment_id: +this.state.appointmentId,
        comments: states.comments,
        hand_left: +states.hand_left,
        hand_right: +states.hand_right,
        leg_dpa_left: +states.leg_dpa_left,
        leg_dpa_right: +states.leg_dpa_right,
        leg_pta_left: +states.leg_pta_left,
        leg_pta_right: +states.leg_pta_right,
        tbindex_left: +states.tbindex_left,
        tbindex_right: +states.tbindex_right,
        toe_left: +states.toe_left,
        toe_right: +states.toe_right,
        unit: states.selectedUnit,
        id: this.state.selectedId ? +this.state.selectedId : null
      }
      let validation = this.checkValidation()
      if (validation === null) {
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_BRANCHICAL_INDEX_LOG)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.onClearHandler()
              this.getBrachialIndexData()
              this.setState({ postLoading: false, isEdit: false })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(validation)
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  printBrachialIndexData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_BRANCHICAL_INDEX_LOG + `?appointment_id=${this.state.appointmentId}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  checkValidation = () => {
    try {
      let validate = null
      let states = this.state
      if ((states.abindex_left === '') || (states.abindex_right === '') ||
        (states.hand_left === '') || (states.hand_right === '') ||
        (states.leg_dpa_left === '') || (states.leg_dpa_right === '') ||
        (states.leg_pta_left === '') || (states.leg_pta_right === '') ||
        (states.tbindex_left === '') || (states.tbindex_right === '') ||
        (states.toe_left === '') || (states.toe_right === '')) {
        validate = 'Enter all mandatory Field'
      }
      return validate;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  LoadComments = () => {
    try {
      var states = this.state;
      var ABIndexComments = "";
      var TBIndexComments = "";
      //AB Index 
      if (+(states.abindex_left) < 0.9 && +(states.abindex_right) < 0.9) {
        // this.setState({
        ABIndexComments += " Bilateral Peripheral arterial disease."
        // })
      } else if (+(states.abindex_left) >= 0.9 && +(states.abindex_left) <= 1 && +(states.abindex_right) >= 0.9 && +(states.abindex_right) <= 1) {
        // this.setState({
        ABIndexComments += "Normal."
        // })
      } else if (+(states.abindex_left) > 1.3 && +(states.abindex_right) > 1.3) {
        // this.setState({
        ABIndexComments += " Bilateral Calcified Pedal vessels."
        // })
      } else {
        if (+(states.abindex_left) < 0.9) {
          // this.setState({
          ABIndexComments += " Left Peripheral arterial disease."
          // })
        } if (+(states.abindex_right) < 0.9) {
          // this.setState({
          ABIndexComments += " Right Peripheral arterial disease."
          // })
        } if (+(states.abindex_left) > 1.3) {
          // this.setState({
          ABIndexComments += " Left Calcified Pedal vessels."
          // })
        } if (+(states.abindex_right) > 1.3) {
          // this.setState({
          ABIndexComments += " Right  Calcified Pedal vessels."
          // })
        } if ((+(states.abindex_left) >= 0.9 && +(states.abindex_left) <= 1) || (+(states.abindex_right) >= 0.9 && +(states.abindex_right) <= 1)) {
          ABIndexComments += " Normal."
        }
      }
      //TB Index
      if (+(states.tbindex_left) < 0.9 && +(states.tbindex_right) < 0.9) {
        // this.setState({
        TBIndexComments += " Bilateral Peripheral arterial disease."
        // })
      } else if (+(states.tbindex_left) >= 0.9 && +(states.tbindex_left) <= 1 && +(states.tbindex_right) >= 0.9 && +(states.tbindex_right) <= 1) {
        // this.setState({
        TBIndexComments += " Normal."
        // })
      } else if (+(states.tbindex_left) > 1.3 && +(states.tbindex_right) > 1.3) {
        // this.setState({
        TBIndexComments += " Bilateral Calcified Pedal vessels."
        // })
      } else {
        if (+(states.tbindex_left) < 0.9) {
          // this.setState({
          TBIndexComments += " Left Peripheral arterial disease."
          // })
        } if (+(states.tbindex_right) < 0.9) {
          // this.setState({
          TBIndexComments += " Right Peripheral arterial disease."
          // })
        } if (+(states.tbindex_left) > 1.3) {
          // this.setState({
          TBIndexComments += " Left Calcified Pedal vessels."
          // })
        } if (+(states.tbindex_right) > 1.3) {
          // this.setState({
          TBIndexComments += " Right Calcified Pedal vessels."
          // })
        } if ((+(states.tbindex_left) >= 0.9 && +(states.tbindex_left) <= 1) || (+(states.tbindex_right) >= 0.9 && +(states.tbindex_right) <= 1)) {
          TBIndexComments += " Normal."
        }
      }
      this.setState({
        comments: "AB Index: " + ABIndexComments + "\n" + "TB Index: " + TBIndexComments
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  caluculateValueIndex = () => {
    try {
      let states = this.state
      if (states.hand_right != "" && states.hand_right > 0 && states.hand_left != "" && states.hand_left > 0 && states.leg_pta_left != "" && states.leg_pta_left > 0 && states.leg_pta_right != "" && states.leg_pta_right > 0
        && states.leg_dpa_right != "" && states.leg_dpa_right > 0 && states.leg_dpa_left != "" && states.leg_dpa_left > 0) {
        var ABIndexRight = 0;
        var ABIndexLeft = 0;
        var TBIndexRight = 0;
        var TBIndexLeft = 0;
        var Comments = "";
        if (+(states.leg_pta_left) > +(states.leg_dpa_left)) {
          ABIndexLeft = +(states.leg_pta_left) / +(states.hand_left);
          this.setState({
            abindex_left: ABIndexLeft.toFixed(1)
          }, () => this.LoadComments())
        } else {
          ABIndexLeft = +(states.leg_dpa_left) / +(states.hand_left);
          this.setState({
            abindex_left: ABIndexLeft.toFixed(1)
          }, () => this.LoadComments())
        }
        if (+(states.leg_pta_right) > +(states.leg_dpa_right)) {
          ABIndexRight = +(states.leg_pta_right) / +(states.hand_right);
          this.setState({
            abindex_right: ABIndexRight.toFixed(1)
          }, () => this.LoadComments())
        } else {
          ABIndexRight = +(states.leg_dpa_right) / +(states.hand_right);
          this.setState({
            abindex_right: ABIndexRight.toFixed(1)
          }, () => this.LoadComments())
        }
        if (states.toe_right != "" && states.toe_left != "") {
          TBIndexRight = +(states.toe_right) / +(states.hand_right);
          TBIndexLeft = +(states.toe_left) / +(states.hand_left);
          this.setState({
            tbindex_right: TBIndexRight.toFixed(1),
            tbindex_left: TBIndexLeft.toFixed(1)
          }, () => this.LoadComments())
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      if ((CommonValidation.DecimalNumber(value) && value?.length <= 999) || value === '') {
        this.setState({
          [names]: value
        }, () => {
          this.caluculateValueIndex()
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        loading: false,
        postLoading: false,
        abindex_left: '',
        abindex_right: '',
        comments: '',
        hand_left: '',
        hand_right: '',
        leg_dpa_left: '',
        leg_dpa_right: '',
        leg_pta_left: '',
        leg_pta_right: '',
        tbindex_left: '',
        tbindex_right: '',
        toe_left: '',
        toe_right: '',
        selectedUnit: 'mmHg',
        selectedId: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, label, width, mr, comments, disable, multiline, rows) => {
    try {
      return (
        <TextField
          size='small'
          disabled={disable}
          sx={{ width: width, mr: mr }}
          multiline={multiline}
          rows={rows}
          label={label}
          name={names}
          value={value}
          onChange={(e) => this.onChangeHandler(e, names)}
          InputProps={comments ? {
            endAdornment: <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
              {this.state.selectedUnit === 'mmHg' ? 'mmHg' : 'Volt'}</Typography>
          } : null}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = () => {
    try {
      let brachialData = this.state.brachialIndexData[0]
      this.setState({
        abindex_left: brachialData?.abindex_left,
        abindex_right: brachialData?.abindex_right,
        comments: brachialData?.comments,
        hand_left: brachialData?.hand_left,
        hand_right: brachialData?.hand_right,
        leg_dpa_left: brachialData?.leg_dpa_left,
        leg_dpa_right: brachialData?.leg_dpa_right,
        leg_pta_left: brachialData?.leg_pta_left,
        leg_pta_right: brachialData?.leg_pta_right,
        tbindex_left: brachialData?.tbindex_left,
        tbindex_right: brachialData?.tbindex_right,
        toe_left: brachialData?.toe_left,
        toe_right: brachialData?.toe_right,
        selectedUnit: brachialData?.unit,
        selectedId: brachialData?.id
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderBranchialValues = (title, value, positionAt) => {
    return (
      <Box className={title.includes("Left") ? "emed_Branchial_rightVal" : "emed_Branchial_leftVal"} sx={{ bottom: positionAt }}>
        <Typography id="emed_Branchial_txt">{title}</Typography>
        <Typography id="emed_Branchial_value">{value ? value : "Nil"}</Typography>
      </Box>
    )
  }

  renderBranchialIndex = (title, leftVal, rightVal) => {
    return (
      <Box display={"flex"} alignItems={"center"} sx={{ marginLeft: "11.8vw", width: "50%" }}>
        <Typography id="emed_Branchial_value" sx={{ marginRight: "0.625vw" }}>{leftVal ? leftVal : "Nil"}</Typography>
        <Typography id="emed_Branchial_txt" sx={{ marginRight: "0.625vw" }}>{title}</Typography>
        <Typography id="emed_Branchial_value" sx={{ marginRight: "0.625vw" }}>{rightVal ? rightVal : "Nil"}</Typography>
      </Box>
    )
  }

  renderLeftSide = () => {
    let { isZoomTop, isZoomBtm, brachialIndexData } = this.state;
    let valueList = brachialIndexData?.length > 0 ? brachialIndexData[0] : {};
    let Impressions = valueList?.comments ? valueList?.comments?.split("\n") : [];

    try {
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Ankle Brachial Index</Typography>
            <Box>
              <Button className='Common_Btn_Min_Width'
                onClick={() => {
                  this.setState({
                    isEdit: true,
                    dataAdded: false
                  }, () => this.onEditHandler())
                }}
              >
                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
              </Button>
              <Button className='Common_Btn_Min_Width'
                onClick={() => this.printBrachialIndexData()}
                disabled={this.state.brachialIndexData?.length > 0 ? false : true}
              >
                <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
              </Button>
            </Box>
          </Box>
          <Box component={'div'} height={'25.5vw'} sx={{ backgroundColor: Colors.white, position: "relative" }} overflow={'hidden'}>
            {/* Branchial image */}
            <Box component={'img'}
              height={(isZoomTop || isZoomBtm) ? '22vw' : '21vw'}
              className={`emed_Branchial_container ${isZoomTop ? "emed_Branchial_zoomTop" : isZoomBtm ? "emed_Branchial_zoomBottom" : ""}`}
              src={ImagePaths.branchialIndex.default} alt="branchialIndex" />

            {/* Branchial buttons */}
            {(isZoomTop || isZoomBtm) ?
              <Box component={'img'}
                className="emed_Branchial_BackBtn"
                src={ImagePaths.LabBackButton.default} alt="Back"
                height={'0.8vw'} width={'0.8vw'}
                onClick={() => { this.setState({ isZoomTop: false, isZoomBtm: false }) }} /> : null}

            {(!isZoomBtm && !isZoomTop) ?
              <>
                <Box component={'img'} className="emed_Branchial_TopBtn"
                  src={ImagePaths.zoomInDot.default} alt="zoomIn"
                  height={'0.8vw'} width={'0.8vw'}
                  onClick={() => { this.setState({ isZoomTop: true }) }} />
                <Box component={'img'} className="emed_Branchial_btmBtn"
                  src={ImagePaths.zoomInDot.default} alt="zoomIn"
                  height={'0.8vw'} width={'0.8vw'}
                  onClick={() => { this.setState({ isZoomBtm: true }) }} />
              </> : null}

            {/* Branchial left values */}
            {isZoomBtm ? null : this.renderBranchialValues("Left", valueList?.hand_left, isZoomTop ? "10vw" : "16vw")}
            {isZoomTop ? null :
              <>{this.renderBranchialValues("PTA Left1", valueList?.leg_pta_left, isZoomBtm ? "20vw" : "11vw")}
                {this.renderBranchialValues("DPA Left1", valueList?.leg_dpa_left, isZoomBtm ? "16vw" : "8vw")}
                {this.renderBranchialValues("Toe Left1", valueList?.toe_left, isZoomBtm ? "12vw" : "5vw")}</>
            }


            {/* Branchial Right values */}
            {isZoomBtm ? null : this.renderBranchialValues("Right", valueList?.hand_right, isZoomTop ? "10vw" : "16vw")}
            {isZoomTop ? null :
              <>{this.renderBranchialValues("PTA Right2", valueList?.leg_pta_right, isZoomBtm ? "20vw" : "11vw")}
                {this.renderBranchialValues("DPA Right2", valueList?.leg_dpa_right, isZoomBtm ? "16vw" : "8vw")}
                {this.renderBranchialValues("Toe Right2", valueList?.toe_right, isZoomBtm ? "12vw" : "5vw")}</>
            }

            {this.renderBranchialIndex("T.B Index", valueList?.tbindex_left, valueList?.tbindex_right)}
            {this.renderBranchialIndex("A.B Index", valueList?.abindex_left, valueList?.abindex_right)}

            {Impressions?.length > 0 ?
              <Box className="emed_Branchial_Impression">
                <Typography id="emed_Branchial_value" >{"Impression"}</Typography>
                <Typography id="emed_Branchial_txt" >{Impressions[0]}</Typography>
                <Typography id="emed_Branchial_txt" >{Impressions[1]}</Typography>
              </Box> : null}
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      return (
        <Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Ankle Brachial Index</Typography>
          </Box>
          <Box component={'div'} height={'23vw'} p={'0.5vw'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'}>
            <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mt={'0.5vw'}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Unit</Typography>
              <FormControl size='small' sx={{ Width: '10vw', ml: '1vw' }}>
                <InputLabel>Select Unit</InputLabel>
                <Select
                  label={'Select Unit'}
                  sx={{ width: '10vw' }}
                  value={this.state.selectedUnit}
                  onChange={(e) => this.setState({ selectedUnit: e.target.value })}
                >
                  {this.state.unitList?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Hand</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('hand_right', this.state.hand_right, 'Hand Right *', '15vw', '1vw', true)}
                {this.renderTextBox('hand_left', this.state.hand_left, 'Hand Left *', '15vw', null, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Leg</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('leg_pta_right', this.state.leg_pta_right, 'PTA Right 1 *', '15vw', '1vw', true)}
                {this.renderTextBox('leg_pta_left', this.state.leg_pta_left, 'PTA Left 1 *', '15vw', null, true)}
              </Box>
              <Box component={'div'} display={'flex'} mt={'1vw'}>
                {this.renderTextBox('leg_dpa_right', this.state.leg_dpa_right, 'DPA Right 2 *', '15vw', '1vw', true)}
                {this.renderTextBox('leg_dpa_left', this.state.leg_dpa_left, 'DPA Left  2 *', '15vw', null, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>A.B Index</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('abindex_right', this.state.abindex_right, 'A.B Index Right *', '15vw', '1vw', true, true)}
                {this.renderTextBox('abindex_left', this.state.abindex_left, 'A.B Index Left *', '15vw', null, true, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Toe</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('toe_right', this.state.toe_right, 'Toe Right *', '15vw', '1vw', true)}
                {this.renderTextBox('toe_left', this.state.toe_left, 'Toe Left *', '15vw', null, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>T.B Index</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('tbindex_right', this.state.tbindex_right, 'T.B Index Right *', '15vw', '1vw', true, true)}
                {this.renderTextBox('tbindex_left', this.state.tbindex_left, 'T.B Index Left *', '15vw', null, true, true)}
              </Box>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Comments</Typography>
              </Box>
              <Box component={'div'} display={'flex'}>
                {this.renderTextBox('comments', this.state.comments, 'Comments *', '31vw', null, false, true, true, 3)}
              </Box>
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.onClearHandler()}
            >Clear</Button>
            <Button
              disabled={(this.state.postLoading || this.state.dataAdded)}
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              onClick={() => this.postBrachialIndexData()}
            >Add</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          <Box component={'div'} flex={0.65} overflow={'scroll'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next'
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, Paper, Stack, TextField, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import { Colors } from "../../../Styles/Colors";
import CommonGridHeader, { CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";

class AssetEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Design_List: [],
            EmpList: [],
            EmpCode: "",
            EmpName: "",
            Designation: null,
            Notes: "",
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            isActive: true,
            isLoader: false
        }
    }

    componentDidMount() {
        this.getDesignList()
        this.getEmployeeList()
    }

    getDesignList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_DESIGN_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        let activeData = data.filter((item) => item?.active_status == true)
                        this.setState({
                            Design_List: activeData
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    getEmployeeList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_EMPLOYEE_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            EmpList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    handleSave = () => {
        const { t } = this.props
        let { EmpCode, EmpName, Designation, Notes, isActive, edit_id } = this.state
        let data = {};
        if (EmpName != "" && Designation != null && EmpCode !== '') {
            data = {
                "employee_code": EmpCode,
                "employee_name": EmpName,
                "designation_id": Designation?.id,
                "employee_id": edit_id ? edit_id : null,
                'notes': Notes,
                "active_status": isActive
            }
            let RestAPI = edit_id ? RestAPIService.updateWithOutId(data, Serviceurls.ASSET_EMPLOYEE_LIST_GET_POST) : RestAPIService.create(data, Serviceurls.ASSET_EMPLOYEE_LIST_GET_POST)
            try {
                this.setState({ isLoader: true })
                RestAPI.then(response => {
                    if (response.data.status === "success") {
                        this.setState({ isLoader: false }, () => {
                            this.handleClear();
                            this.getEmployeeList();
                        })
                        this.responseSuccessErrorMessage(response.data.message, true);
                    }
                })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.setState({ isLoader: false })
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.setState({ isLoader: false })
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage("Mandatory Fields Should not be empty", false);
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    msgClose() {
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
    }

    handleEdit = (item) => {
        let states = this.state
        let DesignationData = this.state.Design_List.filter((list) => list?.id == item?.designation_id)
        states["EmpCode"] = item?.employee_code ? item?.employee_code : ""
        states["EmpName"] = item?.employee_name
        states['Designation'] = DesignationData[0]
        states['Notes'] = item?.notes ? item?.notes : ""
        states["isEdit"] = true
        states["edit_id"] = item?.id
        states["isActive"] = item?.active_status
        this.setState({ states })
    }

    handleClear = () => {
        this.setState({
            EmpCode: "",
            EmpName: "",
            Designation: null,
            Notes: "",
            isActive: true,
            edit_id: null,
            isEdit: false
        })
    }

    renderTextField = (value, statekey, Label, required) => {
        const { t } = this.props;
        let states = this.state
        return (
            <TextField
                sx={{ width: "50%", marginX: '0.65vw', marginTop: "1vw" }}
                size="small"
                inputProps={{ maxLength: 100 }}
                autoComplete="off"
                label={t(Label)}
                required={required}
                value={value}
                onChange={(e) => {
                    states[statekey] = e.target.value
                    this.setState({ states })
                }}
            />
        )
    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, EmpList } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.06, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            {
                field: 'employee_code', headerName: t('Employee Code'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.employee_code?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.employee_code} arrow><div>{cellvalues?.row?.employee_code.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.employee_code ? cellvalues?.row?.employee_code : "-"}</div>
                    )
                }
            },
            {
                field: 'employee_name', headerName: t('Employee Name'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.employee_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.employee_name} arrow><div>{cellvalues?.row?.employee_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.employee_name ? cellvalues?.row?.employee_name : "-"}</div>
                    )
                }
            },
            {
                field: 'designation_name', headerName: t('Designation'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.designation_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.designation_name} arrow><div>{cellvalues?.row?.designation_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.designation_name ? cellvalues?.row?.designation_name : "-"}</div>
                    )
                }
            },
            {
                field: 'active_status', headerAlign: 'center', headerName: t('Status'), flex: 0.21, align: "center", renderCell: (cellvalues) => {
                    return (
                        <div style={{ color: cellvalues?.row?.active_status ? Colors.themeDark : Colors.red, backgroundColor: "#e2faf9", width: "4.5vw", padding: "0.5vw", borderRadius: "0.3vw", textAlign: "center", fontSize: "0.9vw", fontWeight: "600" }}>
                            {cellvalues?.row?.active_status ? "Active" : "InActive"}
                        </div>
                    )
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.15, sortable: false, hideable: false, align: "center", renderCell: (cellvalue) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <CommonEditButton size="1.5vw" onClick={this.handleEdit.bind(this, cellvalue.row)} />
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={EmpList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={EmpList.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t("Add Employee")}</p>
                            </div>
                            <div className="eMed_ipPackage_Div" style={{ overflow: "scroll" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                                    {this.renderTextField(this.state.EmpCode, "EmpCode", "Employee Code *", false)}
                                    {this.renderTextField(this.state.EmpName, "EmpName", "Employee Name *", true)}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                                    <div style={{ width: "50%", marginLeft: '0.65vw', marginRight: "0.65vw", marginTop: "1vw" }}>
                                        <Autocomplete
                                            disableClearable
                                            size='small'
                                            value={this.state.Designation}
                                            options={this.state.Design_List?.map((item) => item)}
                                            getOptionLabel={(option) => (option?.designation_name)}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    Designation: newValue,
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} required label={t('Select Designation')} />
                                            )}
                                        />
                                    </div>
                                    {this.renderTextField(this.state.Notes, "Notes", "Notes", false)}
                                </div>
                                <div style={{ paddingTop: "1vw", paddingLeft: "1vw", borderTop: "1px solid #E3E2E2", marginTop: "2vw" }}>
                                    <FormControlLabel checked={this.state.isActive} control={<Checkbox />} onClick={(e) => {
                                        this.setState({ isActive: e.target.checked })
                                    }} label="Active" />
                                </div>
                            </div>
                            <Stack direction="column" sx={{ backgroundColor: Colors.DataTblHeaderbg, height: "7vh" }} alignItems="center" justifyContent="center">
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button variant='contained' size="small" id="eMed_Config_btn" disabled={this.state.isLoader} onClick={() => { this.handleSave() }}>{t("Add")}</Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(AssetEmployee)
import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class PurchaseOverview extends Component {

    constructor(props) {
        super(props)
        this.state = {
            purchaseOverviewData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days":30,
                "billNumber":'',
                "is_bill_wise": false,
            },
            pageName: 'purchaseOverview',
            filterOpen: false,
            totalAmount: 0,
            gst: 0,
            discountAmount: 0,
            netAmount: 0,
            roundOff: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getPurchaseOverviewData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
            page:0,
        }, () => { this.getPurchaseOverviewData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    getPurchaseOverviewData = () => {
        try {
            let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_OVERVIEW_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&bill_number=${this.state.filterDatas.billNumber}&report_type=${billWise}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            purchaseOverviewData: response.data.data?.result,
                            totalAmount: response.data.data?.total_amount,
                            gst: response.data.data?.total_gst_amount,
                            discountAmount: response.data.data?.total_discount_amount,
                            netAmount: response.data.data?.total_net_total,
                            roundOff: response.data.data?.round_off,
                            rowCount: response.data.data?.total_count
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPurchaseOverviewExcel = () => {
        try {
            let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
            this.setState({disableBtn: true})
            RestAPIService.excelGet(Serviceurls.PHARMA_PURCHASE_OVERVIEW_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&bill_number=${this.state.filterDatas.billNumber}&report_type=${billWise}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({disableBtn: false})
        }
    }

    getPurchaseOverviewPrint = () => {
        try {
            this.setState({disableBtn: true})
            let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
          RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_OVERVIEW_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&bill_number=${this.state.filterDatas.billNumber}&report_type=${billWise}&export=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disableBtn: false})
            }).catch((error) => {
              if(error?.response?.data?.message){
                this.errorMessage(error?.response?.data?.message)
              }else{
                this.errorMessage(error.message)
              }
              this.setState({disableBtn: false})
            })
        } catch (error) {
          this.errorMessage(error.message)
          this.setState({disableBtn: false})
        }
      }

    render() {
        const { t } = this.props
        this.state.purchaseOverviewData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'bill_date', headerName: "Bill Date", flex: 0.0714, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    {splitDate(params?.row?.bill_date)?.length > 12 ? <Tooltip placement='top' title={splitDate(params?.row?.bill_date)} arrow><div>{params?.row?.bill_date?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.bill_date ? splitDate(params?.row?.bill_date) : '-'}
                </Box>)
            },
            {
                field: 'grn_dt', headerName: "GRN Date", flex: 0.0714, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    {splitDate(params?.row?.grn_dt)?.length > 12 ? <Tooltip placement='top' title={splitDate(params?.row?.grn_dt)} arrow><div>{params?.row?.grn_dt?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.grn_dt ? splitDate(params?.row?.grn_dt) : '-'}
                </Box>)
            },
            {
                field: 'invoice_number', headerName: "Invoice No.", flex: 0.0714,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number?.length > 10 ?
                        <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
                            <div>{params?.row?.invoice_number?.slice(0, 8) + '...'}</div>
                        </Tooltip> :
                        params?.row?.invoice_number ? params?.row?.invoice_number : '-'}
                </Box>)
            },
            {
                field: 'vendor_name', headerName: "Vendor Name", flex: 0.0714,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.vendor_name?.length > 10 ? <Tooltip placement='top' title={params?.row?.vendor_name} arrow><div>{params?.row?.vendor_name?.slice(0, 8) + '...'}</div></Tooltip> :
                        params?.row?.vendor_name ? params?.row?.vendor_name : '-'}
                </Box>)
            },
            {
                field: 'vendor_gstin_number', headerName: "GST No", flex: 0.0714,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.vendor_gstin_number?.length > 10 ? <Tooltip placement='top' title={params?.row?.vendor_gstin_number} arrow><div>{params?.row?.vendor_gstin_number?.slice(0, 8) + '...'}</div></Tooltip> :
                        params?.row?.vendor_gstin_number ? params?.row?.vendor_gstin_number : '-'}
                </Box>)
            },
            {
                field: 'amountq', headerName: `${"Purchase Amt"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => {
                    var Amt = (parmas.row?.amount ? +(parmas.row?.amount) : 0) + (parmas.row?.discount_amount ? +(parmas.row?.discount_amount) : 0)
                    Amt = Amt.toFixed(2)
                return(
                <Box component={'div'}>
                    {Amt?.length > 12 ? <Tooltip placement='top' title={Amt} arrow><div>{Amt?.slice(0, 12) + '...'}</div></Tooltip> :
                        Amt ? Amt : '0'}
                </Box>
                )}
            },
            {
                field: 'discount_amount', headerName: `${"Discount Amt"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.discount_amount ? parmas.row?.discount_amount : '0'}</Box>)
            },
            {
                field: 'amount', headerName: `${"Gross Amt"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.amount ? parmas.row?.amount : '0'}</Box>)
            },
            {
                field: 'state', headerName: `${"State"}`, flex: 0.0714,
                renderCell: (params) => (<Box>{params?.row?.state ? params?.row?.state?.length > 10 ?
                  <Tooltip placement='top' title={params?.row?.state}><div>{params?.row?.state?.slice(0, 10) + '...'}</div></Tooltip> :
                  params?.row?.state : '-'}</Box>)
              },
            {
                field: 'gst_amount', headerName: `${"GST"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.gst_amount ? parmas.row?.gst_amount : '0'}</Box>)
            },
            {
                field: 'cgst_amount', headerName: `${"CGST"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.cgst_amount ? parmas.row?.cgst_amount : '0'}</Box>)
            },
            {
                field: 'sgst_amount', headerName: `${"SGST"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.sgst_amount ? parmas.row?.sgst_amount : '0'}</Box>)
            },
            {
                field: 'igst', headerName: `${"IGST"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.igst ? parmas.row?.igst : '0'}</Box>)
            },
            {
                field: 'total_buying_price', headerName: `${"Net Amt"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.total_buying_price ? parmas.row?.total_buying_price : '0'}</Box>)
            },
            {
                field: 'round_off', headerName: `${"RoundOff"} (${CurrencySymbol})`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.round_off ? parmas.row?.round_off : '0'}</Box>)
            },
            {
                field: 'epr_value', headerName: `${'EPR Value'}`, flex: 0.0714, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.epr_value ? parmas.row?.epr_value : '0'}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Taxable Amount", this.state.totalAmount, false, "white", false, null, 'rpt_purOver_tax_amt')}
                        {AmountsCard("GST", this.state.gst, false, "white", false, null, 'rpt_purOver_gst')}
                        {AmountsCard("Discount Amount", this.state.discountAmount, false, "white", false, null, 'rpt_purOver_dis_amt')}
                        {AmountsCard("Net Amount", this.state.netAmount, false, "white", false, null, 'rpt_purOver_net_amt')}
                        {AmountsCard("Round Off", this.state.roundOff, false, "white", false, null, 'rpt_purOver_roundoff_amt')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_purOver_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_purOver_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPurchaseOverviewPrint()}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_purOver_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPurchaseOverviewExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.purchaseOverviewData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns:{
                                columnVisibilityModel:{
                                    state:false,
                                    igst:false
                                }
                            }
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getPurchaseOverviewData()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getPurchaseOverviewData())}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        paginationMode='server'
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(PurchaseOverview)
import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Box, Button, Chip, IconButton, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../Components/Loader';
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const givenDate = "#E8FAF4";
const red = "#F78282";
const green = "#04B7B1";
const orange = "#FFC474";
const disableColor = "#F2F2F2"
const AgeTypeList = ['Day', "Week", "Month", "Year"]
export default class DocImmunization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      selectCate: '',
      datePicker: null,
      DatePickerOpen: false,
      listCmt: '',
      immunizationData: [],
      selectedData: {},
      finalArray: [],
      DoseDataList: [],
      RouteDataList: [],
      AgeType: "Day",
      selectImmu : null
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getImmunizationData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getImmunizationData = () => {
    try {
      RestAPIService.getAll(Serviceurls.PD_IMMUNIZATION_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              immunizationData: response?.data?.data
            }, () => {
              let transformedData = {};

              this.state.immunizationData.forEach((item) => {
                var { patient_age, ...rest } = item;
                if (!transformedData[patient_age]) {
                  transformedData[patient_age] = {
                    patient_age,
                    data: [rest],
                  };
                } else {
                  transformedData[patient_age].data.push(rest);
                }
              });
              this.setState({
                finalArray: Object.values(transformedData)
              }, ()=>{ this.renderDoseArray() })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postImmnuization = () => {
    try {
      var states = this.state
      let startDate = new Date(states.datePicker)
      let givenDate = states.datePicker ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}` : null
      let dueDate = states.selectedData?.due_date ? DateTime.fromFormat(states.selectedData?.due_date, "dd-MM-yyyy").toFormat("yyyy-MM-dd") : null;
      var data = {
        "patient_id": states.patientId,
        "patient_age": states?.selectCate,
        "vaccine_name": states?.selectVaccine ? states?.selectVaccine : "",
        "dose": states?.selectDose ? states?.selectDose : "",
        "route": states?.selectRoute ? states?.selectRoute : "",
        "immunization_id": states?.selectedData?.immunization_id ? states?.selectedData?.immunization_id : null,
        "given_date": givenDate,
        "comments": states.listCmt ? CommonValidation.removeSpace(states.listCmt) : "",
        "is_frozen": "",
        "due_date": dueDate ? dueDate : null,
        "new_template" : states?.selectedData?.immunization_id ? false : true,
      }
      if (states.selectedData?.id) {
        data['id'] = states.selectedData?.id
      }
      RestAPIService.create(data, Serviceurls.PD_IMMUNIZATION_GET)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getImmunizationData()
            this.onClearValue()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  postImmnuizationLock = (item) => {
    try {
      var states = this.state
      var data = {
        "patient_id": states.patientId,
        "immunization_id": item.immunization_id,
        "is_frozen": true,
        "id": item.id,
      }
      RestAPIService.create(data, Serviceurls.PD_IMMUNIZATION_LOCK)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getImmunizationData()
            this.onClearValue()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  DeleteImmnuization() {
    try {
      RestAPIService.delete(Serviceurls.PD_IMMUNIZATION_GET + "?immunization_id=" + this.state.selectImmu?.immunization_id + "&patient_id=" + this.state.patientId).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response?.data?.message)
            this.getImmunizationData()
            this.onClearValue()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  returnduedateBgColor(status) {
    switch (status) {
      case "Upcoming": return orange
      case "Over Due": return red
      case "Completed": return green
      default: return "transparent"
    }
  }

  renderTbl = (List) => {
    try {
      return (
        <Box component={'div'} width={'70vw'} height={'67vh'} border={'1px solid lightgray'} backgroundColor={'white'}>
          <Box>
            <TableContainer sx={{ maxHeight: '65vh' }}>
              <Table size='small' stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '8vw' }}>{"Age"}</TableCell>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '22vw' }}>{"Vaccination"}</TableCell>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '10vw' }}>{"Dose"}</TableCell>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '10vw' }}>{"Route"}</TableCell>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '14vw' }}>{"Due Date"}</TableCell>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '13vw' }}>{"Given Date"}</TableCell>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '17vw' }}>{"Comments"}</TableCell>
                    <TableCell sx={{ backgroundColor: '#F5F8F7', fontWeight: 'bold', width: '5vw' }}>{"Action"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {List.map((group, groupIndex) => (
                    group.data.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {rowIndex === 0 && (
                          <TableCell rowSpan={group.data.length} sx={{ width: '8vw', borderRight: '1px solid #ddd' }}>{group.patient_age ? group.patient_age : '-'}</TableCell>
                        )}
                        <TableCell sx={{ width: '22vw' }}>{row?.vaccine_name?.length > 50 ?
                          <Tooltip title={row?.vaccine_name} placement='top'><Typography component={'div'} fontSize={"0.8rem"}>{(row.vaccine_name).slice(0, 50) + '...'}</Typography></Tooltip> :
                          row?.vaccine_name ? <Typography component={'div'} fontSize={"0.8rem"}>{row?.vaccine_name}</Typography> : '-'}</TableCell>
                        <TableCell sx={{ width: '10vw' }}>{row.dose ? row.dose : '-'}</TableCell>
                        <TableCell sx={{ width: '10vw' }}>{row.route ? row.route : '-'}</TableCell>
                        <TableCell sx={{ width: '14vw', backgroundColor: this.returnduedateBgColor(row?.color) }}>{row.due_date ? row.due_date : '-'}</TableCell>
                        <TableCell sx={{ width: '13vw' }}>{row.given_date ? row.given_date : '-'}</TableCell>
                        <TableCell sx={{ width: '17vw' }}>{
                          row?.comments?.length > 12 ?
                            <Tooltip title={row?.comments} placement='top'><Box>{(row.comments).slice(0, 12) + '...'}</Box></Tooltip> :
                            row?.comments ? row?.comments : '-'
                        }</TableCell>
                        <TableCell sx={{ width: '5vw', display:'flex', justifyContent:"center" }}>
                          <Tooltip arrow title='Lock' placement='top'>
                            <IconButton disabled={row.is_frozen} onClick={() => { this.postImmnuizationLock(row) }}>
                              <img style={{ width: '1.4vw', height: '1.4vw' }} src={row.is_frozen ? ImagePaths.LockRed.default : ImagePaths.LockBtn.default} alt='lock' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title='Delete' placement='top'>
                            <IconButton disabled={(row.color === "Completed" || row?.doctor_id === null)} onClick={() => { 
                              // this.DeleteImmnuization(row)
                              this.setState({ isDeleteClicked : true, selectImmu : {...row, "patient_age_" : group.patient_age ? group.patient_age : '-' }})
                               }}>
                              <img style={{ width: '1.4vw', height: '1.4vw', borderRadius:"0.2vw" }} src={(row.color === "Completed" || row?.doctor_id === null) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='lock' />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderDoseArray = () => {
    let list = this.state.immunizationData
    let DoseData = []
    let RouteData = []
    list.forEach((item) => {
      if (!DoseData.includes(item?.dose) && item?.dose) {
        DoseData.push(item?.dose)
      }
    })
    list.forEach((item) => {
      if (!RouteData.includes(item?.route) && item?.route) {
        RouteData.push(item?.route)
      }
    })
    this.setState({
      DoseDataList: DoseData,
      RouteDataList: RouteData,
    })
  }

  renderRightSide = (list) => {
    try {
      let maxiDate = DateTime.now().minus({ days: 1 }).toJSDate();
      return (
        <Box component={'div'} width={'22vw'} ml={'0.5vw'} height={'67vh'} border={'1px solid lightgray'} backgroundColor={'white'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Age</Typography>
          </Box>
          <Box height={'55.5vh'} overflow={'scroll'}>
            <Box component={'div'} p={'.5vw'}>
              {list?.map((item, index) => {
                return (
                  <Chip
                    className={`eMed_chip ${item.patient_age === this.state.selectCate ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={item.patient_age}
                    variant={item.patient_age === this.state.selectCate ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      if (item.patient_age === this.state.selectCate) {
                        this.setState({
                          selectCate: '',
                          selectedData: "",
                          selectDose: "",
                          selectRoute: "",
                          selectVaccine : "",
                          datePicker : null,
                          listCmt : ""
                        })
                      } else {
                        this.setState({
                          selectCate: item?.patient_age,
                          selectedData: "",
                          selectDose: "",
                          selectRoute: "",
                          selectVaccine : "",
                          datePicker : null,
                          listCmt : ""
                        })
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                )
              })}
              {this.state.NewAgeAdd ?
                <Box sx={{ display:'flex', alignItems:"center", justifyContent:'flex-start'}}>
                  <TextField
                    size='small'
                    value={this.state.NewAge}
                    onChange={(e) => {
                      if ((CommonValidation.numberOnly(e.target.value)) || e.target.value === '') {
                        this.setState({ NewAge: e.target.value })
                      }
                    }}
                    inputProps={{ maxLength: 3, style: { height: "0.8vw" } }}
                    sx={{ width: "4vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                  />
                  <Select
                    style={{ height: '2vw', width: "8vw", marginTop: "0.3vw" }}
                    value={this.state.AgeType}
                    onChange={(e) => this.setState({ AgeType: e.target.value })}
                  >
                    {AgeTypeList.map((item, index) => (
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  <IconButton disabled={!this.state.NewAge || !this.state.AgeType} onClick={() => {
                    let List = this.state.finalArray
                    if (!List.some((item)=>(item?.patient_age === `${this.state.NewAge} ${this.state.AgeType}`))) {
                      List.push({ 'patient_age': `${this.state.NewAge} ${this.state.AgeType}`, data: [] })
                    }
                    this.setState({
                      finalArray: List,
                      selectCate: `${this.state.NewAge} ${this.state.AgeType}`,
                      NewAge: "",
                      NewAgeAdd: false
                    })
                  }}>
                    <CheckCircleIcon sx={{ height: '1.8vw', width: '1.8vw' }} color={!this.state.NewAge || !this.state.AgeType ? 'grey' : 'primary'}/>
                  </IconButton>
                  <Tooltip title={"Clear"} arrow placement='top'>
                    <IconButton onClick={()=>{ this.setState({ NewAge : "", AgeType : "Day", NewAgeAdd : false}) }}>
                      <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                    </IconButton>
                  </Tooltip>
                </Box>
                :
                <Tooltip title="Add new Age" placement='top' arrow>
                  <IconButton
                    onClick={() => {
                      this.setState({
                        NewAgeAdd: true
                      })
                    }}>
                    <AddIcon color={'primary'} />
                  </IconButton>
                </Tooltip>
              }
            </Box>
            {this.state.selectCate ? <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{"Vaccination"}</Typography>
              {/* <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{this.state.selectCate}</Typography> */}
            </Box> : null}
            {this.state.selectCate ? <Box component={'div'} p={'.5vw'} flexWrap={'wrap'}>
              {list?.map((group, groupIndex) => (
                group.patient_age === this.state.selectCate ? (
                  group.data.map((val, index) => (
                    <Chip
                      className={`eMed_chip ${val.vaccine_name === this.state.selectVaccine ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={val.vaccine_name}
                      variant={val.vaccine_name === this.state.selectVaccine ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        // Customize your logic based on the clicked chip
                        if (val.vaccine_name === this.state.selectVaccine) {
                          this.setState({ selectVaccine: '' });
                        } else {
                          let Date = val.given_date ? val.given_date.split("-").reverse().join("-") : null
                          this.setState({
                            selectVaccine: val.vaccine_name,
                            selectedData: val,
                            selectDose: val.dose ? val.dose : "",
                            selectRoute: val.route ? val.route : "",
                            datePicker : Date ? Date : null,
                            listCmt : val.comments ? val.comments : "",
                            newVaccList: [],
                          }, () => { this.renderDoseArray() });
                        }
                      }}
                      clickable
                    />
                  ))
                ) : null
              ))}
              {this.state.selectCate ?
                this.state.newVaccList?.map((group, index) => (
                  <Chip
                    className={`eMed_chip ${group.vaccine_name === this.state.selectVaccine ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={group.vaccine_name}
                    variant={group.vaccine_name === this.state.selectVaccine ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      // Customize your logic based on the clicked chip
                      if (group.vaccine_name === this.state.selectVaccine) {
                        this.setState({ 
                          selectVaccine: '',
                          selectedData: "",
                          selectDose: "",
                          selectRoute: "",
                          datePicker: null,
                          listCmt: "",
                          newVaccList: [],
                         });
                      } else {
                        this.setState({
                          selectVaccine: group.vaccine_name,
                          selectedData: group,
                          selectDose: "",
                          selectRoute: "",
                          datePicker: null,
                          listCmt: "",
                          newVaccList: [],
                        });
                      }
                    }}
                    clickable
                  />
                )) : null}
              {this.state.NewVaccAdd ?
                <TextField
                  size='small'
                  value={this.state.NewVaccName}
                  onChange={(e) => {
                    this.setState({ NewVaccName: e.target.value })
                  }}
                  inputProps={{ maxLength: 35, style: { height: "0.8vw" } }}
                  sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                  onKeyDown={(e) => {
                    let NewVaccName = this.state.NewVaccName ? CommonValidation.removeSpace(this.state.NewVaccName) : ""
                    if (e.key === 'Enter' && NewVaccName) {
                      let List = this.state.newVaccList ? this.state.newVaccList : []
                      let isVaccIsAlreadyHave = ((this.state.immunizationData.some((list)=>(list?.vaccine_name === NewVaccName && list?.patient_age === this.state.selectVaccine)))|| List.some((list)=>(list?.vaccine_name === NewVaccName)))
                      if (!isVaccIsAlreadyHave) {
                        List.push({ 'vaccine_name': NewVaccName })
                      }
                      this.setState({
                        newVaccList: List,
                        selectVaccine: NewVaccName,
                        NewVaccName: "",
                        NewVaccAdd: false,
                        selectedData : ""
                      }, () => { this.renderDoseArray() })
                    }
                  }}
                /> :
                <Tooltip title="Add new vaccination" placement='top' arrow>
                  <IconButton
                    onClick={() => {
                      this.setState({
                        NewVaccAdd: true
                      })
                    }}>
                    <AddIcon color={'primary'} />
                  </IconButton>
                </Tooltip>
              }
            </Box> : null}

            <Box>
              <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{"Dose"}</Typography>
              </Box>
              {this.state.DoseDataList && this.state.DoseDataList.length > 0 ?
                <Box component={'div'} p={'.5vw'}>
                  {this.state.DoseDataList?.map((dose, index) => (
                    <Chip
                      className={`eMed_chip ${dose === this.state.selectDose ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={dose}
                      variant={dose === this.state.selectDose ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        // Customize your logic based on the clicked chip
                        if (dose === this.state.selectDose) {
                          this.setState({
                            selectDose: '',
                            datePicker: null,
                            listCmt: ""
                          });
                        } else {
                          this.setState({
                            selectDose: dose,
                            datePicker: null,
                            listCmt: ""
                          });
                        }
                      }}
                      clickable
                    />
                  ))}
                  {this.state.newDoseAdd ?
                    <TextField
                      size='small'
                      value={this.state.newDoseText}
                      onChange={(e) => {
                        this.setState({ newDoseText: e.target.value })
                      }}
                      inputProps={{ maxLength: 35, style: { height: "0.8vw" } }}
                      sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                      onKeyDown={(e) => {
                        let newDoseText = this.state.newDoseText ? CommonValidation.removeSpace(this.state.newDoseText) : ""
                        if (e.key === 'Enter' && newDoseText) {
                          let List = this.state.DoseDataList ? this.state.DoseDataList : []
                          if (!List.includes(newDoseText)) {
                            List.push(newDoseText)
                          }
                          this.setState({
                            DoseDataList: List,
                            selectDose: newDoseText,
                            newDoseText: "",
                            newDoseAdd: false
                          })
                        }
                      }}
                    /> :
                    <Tooltip title="Add new Dose" placement='top' arrow>
                      <IconButton
                        onClick={() => {
                          this.setState({
                            newDoseAdd: true
                          })
                        }}>
                        <AddIcon color={'primary'} />
                      </IconButton>
                    </Tooltip>
                  }
                </Box> : null}

            </Box>
            <Box>
              <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{"Route"}</Typography>
              </Box>
              {this.state.RouteDataList && this.state.RouteDataList.length > 0 ? <Box component={'div'} p={'.5vw'}>
                {this.state.RouteDataList?.map((Route, index) => (
                  <Chip
                    className={`eMed_chip ${Route === this.state.selectRoute ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={Route}
                    variant={Route === this.state.selectRoute ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      // Customize your logic based on the clicked chip
                      if (Route === this.state.selectRoute) {
                        this.setState({
                          selectRoute: '',
                          datePicker: null,
                          listCmt: ""
                        }, () => { });
                      } else {
                        this.setState({
                          selectRoute: Route,
                          datePicker: null,
                          listCmt: ""
                        });
                      }
                    }}
                    clickable
                  />
                ))}
                {this.state.newRouteAdd ?
                  <TextField
                    size='small'
                    value={this.state.newRouteText}
                    onChange={(e) => {
                      this.setState({ newRouteText: e.target.value })
                    }}
                    inputProps={{ maxLength: 35, style: { height: "0.8vw" } }}
                    sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                    onKeyDown={(e) => {
                      let newRouteText = this.state.newRouteText ? CommonValidation.removeSpace(this.state.newRouteText) : ""
                      if (e.key === 'Enter' && newRouteText) {
                        let List = this.state.RouteDataList ? this.state.RouteDataList : []
                        if (!List.includes(newRouteText)) {
                          List.push(newRouteText)
                        }
                        this.setState({
                          RouteDataList: List,
                          selectRoute: newRouteText,
                          newRouteText: "",
                          newRouteAdd: false
                        })
                      }
                    }}
                  /> :
                  <Tooltip title="Add new Route" placement='top' arrow>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          newRouteAdd: true
                        })
                      }}>
                      <AddIcon color={'primary'} />
                    </IconButton>
                  </Tooltip>
                }
              </Box> : null}
            </Box>
            <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Given Date</Typography>
            </Box>
            <Box component={'div'} p={'.5vw'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={this.state.DatePickerOpen}
                  onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                  onClose={() => { this.setState({ DatePickerOpen: false }) }}
                  value={this.state.datePicker}
                  name={"datePicker"}
                  maxDate={maxiDate}
                  inputFormat='DD-MM-YYYY'
                  views={["year", "month", "day"]}
                  onChange={
                    (newDate) => {
                      this.setState({
                        datePicker: newDate,
                      });
                    }}
                  renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '14vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                />
              </LocalizationProvider>
            </Box>
            <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Comments</Typography>
            </Box>
            <Box component={'div'} p={'.5vw'}>
              <TextField
                size='small'
                label='Comments'
                sx={{ width: '21vw' }}
                multiline
                value={this.state.listCmt}
                rows={3}
                onChange={(e) => {
                  let value = e.target.value
                  if (value?.length <= 250) {
                    this.setState({ listCmt: e.target.value })
                  } else {
                    this.errorMessage('Allowed Only 250 Characters')
                  }
                }}
              />
            </Box>
          </Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} justifyContent={'center'} alignItems={'center'} backgroundColor={'#F5F8F7'}>
            <Stack spacing={2} direction="row">
              <Button size='small' sx={{ textTransform: 'capitalize' }} onClick={() => { this.onClearValue() }} variant="outlined">Clear</Button>
              <Button size='small' sx={{ textTransform: 'capitalize' }} disabled={!this.state.datePicker
              } onClick={() => {
                if (this.state.selectCate === "") {
                  this.errorMessage("Select age")
                } else if (!this.state.selectVaccine) {
                  this.errorMessage("Please select vaccination name")
                } else if (!this.state.selectDose) {
                  this.errorMessage("Please select dose")
                } else if (!this.state.selectRoute) {
                  this.errorMessage("Please select route")
                } else {
                  this.postImmnuization()
                }
              }} variant="contained">Add</Button>
            </Stack>
          </Box>
        </Box>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  onClearValue = () => {
    this.setState({
      selectCate: '',
      selectVaccine: '',
      selectedData: {},
      selectDose : "",
      selectRoute : "",
      datePicker: null,
      listCmt: '',
      NewAgeAdd : false,
      NewAge : "",
      AgeType : "Day",
      NewVaccAdd : false,
      NewVaccName : "",
      newDoseAdd : false,
      newDoseText : "",
      newRouteAdd : false,
      newRouteText : "",
      selectImmu : null,
      newVaccList : [],
    })
  }

  popupClose = () => {
    try {
        this.setState({
          selectImmu: null,
          isDeleteClicked : false
        })
    } catch (error) {
        this.errorMessage(error.message)
    }
}

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'78vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} >
          <Box component={'div'} >
            {this.renderTbl(this.state.finalArray)}
          </Box>
          <Box component={'div'} >
            {this.renderRightSide(this.state.finalArray)}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isDeleteClicked && this.state.selectImmu ? <DeletePopup
          DeletTitle={`Are you sure ? Do you want to remove immunization - ${this.state.selectImmu?.patient_age_ ? this.state.selectImmu?.patient_age_ : "-"} (${this.state.selectImmu?.vaccine_name ? this.state.selectImmu?.vaccine_name : "-"})`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DeleteImmnuization.bind(this)}
        /> : null}
      </Box>
    )
  }
}


import { Box, Chip, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { CommonEditButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';
class DocPatientCategoryLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CategoryList: [],
      isDeleteClicked: false,
      SelectedCetegory: {},
      SelectedCateID: null,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetPatientCategoryData() }
  }

  GetPatientCategoryData() {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY + `?patient_id=${this.props?.PatientData?.patient_id}&appointment_id=${this.props?.PatientData?.appointment_id}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              CategoryList: response.data.data ? response.data.data : []
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  onEditHandler(data) {
    this.props.EditGenAdviceText(this.state.GenAdviceText)
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  popupClose() {
    this.setState({ 
      isDeleteClicked: false,
      SelectedCetegory: {},
      SelectedCateID: null,
     })
  }
  DeleteCategory() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY + "?id=" + states.SelectedCateID).
        then((response) => {
          if (response.data.status === "success") {
            this.popupClose()
            this.GetPatientCategoryData()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  render() {
    const { t } = this.props
    return (
      <ShowComponents onIntersection={this.GetPatientCategoryData.bind(this)}>
        <Box display={'flex'} flexDirection={'row'} marginX={"1vw"}>

          <Box className="eMed_DocNotes_FreqChips_Wrapp">
            {
              this.state.CategoryList?.length > 0 ? this.state.CategoryList.map((item, index) => (
                <Chip
                  size='small'
                  className={`eMed_chip ${'eMed_Chip_Border'}`}
                  key={index}
                  label={item?.category}
                  variant={"outlined"}
                  disabled={this.props.isLocked}
                  onDelete={() => { this.setState({ isDeleteClicked: true, SelectedCetegory: item?.category, SelectedCateID: item?.id }) }}
                />
              )) : null
            }

          </Box>
          {/* {
            this.state.CategoryList.length > 0 &&
            this.state.CategoryList.map((list, index)=>(
              
              <Box key={index} className='eMed_DocPatCate_Box'>
                <Typography fontSize={'0.9vw'}>{list?.category ? list?.category : ""}</Typography>
              </Box>
            ))
          } */}
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
        </Box>
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Cetegory")} - ${this.state.SelectedCetegory ? this.state.SelectedCetegory : "-"} ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DeleteCategory.bind(this)}
        /> : null}
      </ShowComponents>
    )
  }
}
export default withTranslation()(DocPatientCategoryLeft)
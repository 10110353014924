import React, { Component } from 'react';
import { Box } from '@mui/material';
import DayWiseGSTSummary from './DayWiseGSTSummary';
import { withTranslation } from 'react-i18next';

class HSNWiseSummaryGST extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Box >
        <DayWiseGSTSummary status="hsn_summary" />
      </Box>
    )
  }
}
export default withTranslation()(HSNWiseSummaryGST);
import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Box, Button, CircularProgress, TextField, Typography, colors } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../Components/Loader';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DataGrid } from '@mui/x-data-grid'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Line } from 'react-chartjs-2'
import { DateTime } from 'luxon'
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'


const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    }
  },
}
export default class DocHbac extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      h1bacData: [],
      selectedDate: null,
      h1bacValue: '',
      clinicId: '',
      patientId: '',
      appointmentId: '',
      loading: false,
      postloading: false,
      DatePickerOpen: false,
      page: 0,
      pageSize: 10,
      listOfDatas: [],
      selectedId: null,
      isEdit: false,
      deletePopup: false,
      removeData: false
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getH1bacData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getH1bacData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_CHART_HBA1C + `?patient_id=${this.state.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let data = response?.data?.data
            let listOfLabels = [];
            let listOfValues = [];
            data.forEach(item => {
              let chart_date = "";
              chart_date = item.chart_date ? formatDate(item.chart_date) : item.chart_date;
              listOfLabels.push(chart_date);
              listOfValues.push(item.chart_value);
            })
            this.setState({
              h1bacData: data,
              loading: false,
              listOfLabels: listOfLabels?.length > 0 ? listOfLabels : [],
              listOfValues: listOfValues?.length > 0 ? listOfValues : []
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postH1bacData = () => {
    try {
      let date = new Date(this.state.selectedDate)
      let dataDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      let data = {
        chart_date: dataDate,
        chart_value: this.state.h1bacValue,
        patient_id: this.state.patientId,
        id: this.state.selectedId ? this.state.selectedId : null
      }
      this.setState({ postloading: true })
      RestAPIService.create(data, Serviceurls.DOC_CHART_HBA1C)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ postloading: false })
            this.successMessage(response?.data?.message)
            this.getH1bacData()
            this.onClearHandler()
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.setState({ postloading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postloading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postloading: false })
      this.errorMessage(error.message)
    }
  }

  deleteH1bacData = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.DOC_CHART_HBA1C + `?patient_id=${this.state.patientId}&id=${this.state.selectedId}`, data)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.onClearHandler()
            this.getH1bacData()
            this.setState({ removeData: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  printH1BacData = (item) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_CHART_HBA1C + `?patient_id=${this.state.patientId}&id=${item?.id}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        selectedDate: item?.chart_date,
        h1bacValue: item?.chart_value,
        selectedId: item?.id,
        isEdit: true
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (this.state.isEdit) {
        this.errorMessage("You have clicked edit.So,Please Update the data")
      } else {
        this.setState({
          deletePopup: true,
          selectedId: item?.id
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        h1bacValue: '',
        selectedDate: null,
        isEdit: false,
        selectedId: null,
        deletePopup: false,
        isEdit: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      let chartFont = "Open Sans";
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            titleFont: {
              family: chartFont,
            },
            bodyFont: {
              family: chartFont,
            },
          }
        },
        scales: {
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
            },
            ticks: {
              align: 'center',
              font: {
                weight: 'bold',
                family: chartFont,
                size: 12
              }
            }
          },
          y: {
            min: 0,
            max: 14,
            stepSize: 1,
            ticks: {
              align: 'center',
              font: {
                weight: 'bold',
                family: chartFont
              },
            }
          },
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 0.2,
            to: 0,
            loop: true
          }
        }
      };
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>HBA1C Chart</Typography>
          </Box>
          <Box component={'div'} height={'25vw'} sx={{ backgroundColor: Colors.white }} p={'0.5vw'}>
            <Line data={{
              labels: this.state.listOfLabels || [],
              datasets: [
                {
                  label: "",
                  backgroundColor: "#04B7B1",
                  borderColor: "#04B7B1",
                  data: this.state.listOfValues || []
                }
              ],
            }}
              options={options}
            />
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      this.state.h1bacData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'chart_date', headerName: 'Date', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.chart_date ? formatDate(params?.row?.chart_date) : '-'}</Box>)
        },
        { field: 'mean_blood_glucose', headerName: 'Blood Glucouse', flex: 0.25 },
        { field: 'chart_value', headerName: 'Value', flex: 0.2 },
        {
          field: 'action', headerName: 'Action', flex: 0.3, align: 'center', headerAlign: 'center',
          renderCell: (params) => (
            <Box>
              <Button className='Common_Btn_Min_Width'
                onClick={() => this.onEditHandler(params?.row)}
              >
                <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Edit.default} />
              </Button>
              <Button className='Common_Btn_Min_Width'
                onClick={() => this.onDeleteHandler(params?.row)}
              >
                <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Delete.default} />
              </Button>
              <Button className='Common_Btn_Min_Width'
                onClick={() => this.printH1BacData(params?.row)}
              >
                <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.PrintIcons.default} />
              </Button>
            </Box>
          )
        }
      ]
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>HBA1C Chart</Typography>
          </Box>
          <Box component={'div'} height={'25vw'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'} p={'0.5vw'}>
            <Box component={'div'}>
              <TextField
                size='small'
                label='HBA1C Value'
                sx={{ width: '15vw', mr: '1vw' }}
                value={this.state.h1bacValue}
                onChange={(e) => {
                  let value = e.target.value
                  if ((CommonValidation.numberOnly(value) && value <= 14) || value === '') {
                    this.setState({ h1bacValue: value })
                  }
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={this.state.isEdit}
                  open={this.state.DatePickerOpen}
                  onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                  onClose={() => { this.setState({ DatePickerOpen: false }) }}
                  maxDate={new Date()}
                  value={this.state.selectedDate}
                  name={"datePicker"}
                  inputFormat='DD-MM-YYYY'
                  views={["year", "month", "day"]}
                  onChange={
                    (newDate) => {
                      this.setState({ selectedDate: newDate });
                    }}
                  renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '15vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                />
              </LocalizationProvider>
            </Box>
            <Box component={'div'} mt={'1vw'}>
              {this.state.postloading ? <CircularProgress color='primary' /> :
                <Button variant='contained'
                  fullWidth
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => this.postH1bacData()}
                >
                  Add
                </Button>
              }
            </Box>
            <Box component={'div'} mt={'1vw'}>
              <DataGrid
                sx={{ height: '17vw' }}
                rows={this.state.h1bacData}
                columns={columns}
                getRowId={(row) => row['sno']}
                page={this.state.page}
                pageSize={this.state.pageSize}
                localeText={{
                  toolbarColumns: "",
                  toolbarDensity: "",
                  toolbarExport: "",
                  toolbarFilters: "",
                  toolbarExportPrint: ""
                }}
                components={{
                  // Toolbar: CommonGridHeader.CommonToolBar,
                  NoRowsOverlay: () => (
                    <Box sx={{ display: 'flex', position: 'relative', top: '5vw', justifyContent: 'center', alignContent: 'center' }}>
                      {'No Records To Be Shown'}
                    </Box>
                  )
                }}
                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                rowsPerPageOptions={[10, 20, 30]}
                pagination
                headerHeight={40}
                disableSelectionOnClick
                loading={this.state.loading}
              />
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          <Box component={'div'} flex={0.65} overflow={'scroll'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`Are you sure you want to delete ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteH1bacData.bind(this)}
          disable={this.state.removeData}
        /> : null}
      </Box>
    )
  }
}

import { Autocomplete, Box, Button, Chip, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { defaultRouteList } from '../../../../Utility/Constants'
import { DataGrid } from '@mui/x-data-grid'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ShowComponents from './ShowComponent';

export default class DocPlan extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            openPopUp: false,
            frequentList: {},
            planData: {},
            selectedInhouse: '',
            selectedProcedure: '',
            selectedRoute: '',
            comments: '',
            description: '',
            routeList: defaultRouteList,
            postLoad: false,
            treatPage: 0,
            treatPageSize: 10,
            proPage: 0,
            proPageSize: 10,
            planTreatments: [],
            planProcedure: [],
            loading: false,
            deletePopup: false,
            selectedId: null,
            removeData: false,
            planType: '',
            isLocked: false,
            procedureId: null,
            isEdit: false

        }
    }

    componentDidMount() {
        // let clinicId = getCachevalue('SelectedDoctorClinic')
        // let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        // this.setState({
        //     clinicId: clinicId,
        //     appointmentId: appointmentId?.appointment_id,
        //     patientId: appointmentId?.patient_id,
        //     isLocked: appointmentId?.is_locked
        // }, () => {
        //     this.getPlanData()
        // })
        // this.apiCalls();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    apiCalls = () => {
        this.getPlanData()
        this.getPlanFrequentList()
    }

    getPlanFrequentList = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PLAN_FREQ)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            frequentList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPlanData = () => {
        try {
            let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.props.appointmentId
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PLAN_GET + `?patient_id=${this.props.patientId}&appointment_id=${appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            planData: response.data.data,
                            planTreatments: response.data.data?.treatments?.length > 0 ? response.data.data?.treatments : [],
                            planProcedure: response.data.data?.procedures?.length > 0 ? response.data.data?.procedures : [],
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    postPlanData = () => {
        try {
            let data = {
                appointment_id: this.props.appointmentId,
                inhouse_treatment: {
                    comments: this.state.comments,
                    dosage: this.state.dosageStrength,
                    name: this.state.selectedInhouse,
                    route: this.state.selectedRoute
                },
                patient_id: this.props.patientId,
                procedure: {
                    description: this.state.description,
                    name: this.state.selectedProcedure
                }
            }
            this.setState({ postLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PLAN_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.onClearHandler()
                        this.setState({ postLoad: false, openPopUp: false })
                        this.getPlanData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    putPlanData = () => {
        try {
            let data = {
                appointment_id: this.props.appointmentId,
                inhouse_treatment: {
                    comments: this.state.comments,
                    dosage: this.state.dosageStrength,
                    name: this.state.selectedInhouse,
                    route: this.state.selectedRoute,
                    treatment_id: this.state.selectedId ? this.state.selectedId : null
                },
                patient_id: this.props.patientId,
                procedure: {
                    description: this.state.description,
                    name: this.state.selectedProcedure,
                    procedure_id: this.state.procedureId ? this.state.procedureId : null
                }
            }
            this.setState({ postLoad: true })
            RestAPIService.updateWithOutId(data, Serviceurls.DOC_DOCTOR_NOTES_PLAN_POST)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response.data.message)
                        this.onClearHandler()
                        this.setState({ postLoad: false, openPopUp: false, isEdit: false })
                        this.getPlanData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    deletePlanData = () => {
        try {
            let data = {
                appointment_id: this.props.appointmentId,
                plan_type: this.state.planType,
                plans_id: this.state.selectedId
            }
            this.setState({ removeData: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PLAN_DELETE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ removeData: false, deletePopup: false, selectedId: null, planType: '' })
                        this.successMessage(response?.data?.message)
                        this.getPlanData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                selectedInhouse: '',
                selectedProcedure: '',
                selectedRoute: '',
                comments: '',
                description: '',
                dosageStrength: '',
                selectedId: null,
                procedureId: null,
                isEdit: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item, type) => {
        try {
            if (type === 'Inhouse') {
                this.setState({
                    comments: item?.comments,
                    dosageStrength: item?.dosage,
                    selectedRoute: item?.route,
                    openPopUp: true,
                    selectedId: item?.id,
                    selectedInhouse: item?.name,
                    isEdit: true
                })
            } else {
                this.setState({
                    selectedProcedure: item?.name,
                    description: item?.description,
                    procedureId: item?.id,
                    openPopUp: true,
                    isEdit: true
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (item, type) => {
        try {
            if (!this.state.isEdit) {
                this.setState({
                    deletePopup: true,
                    selectedId: item?.id,
                    planType: type
                })
            } else {
                this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPopUp = () => {
        try {
            return (
                <Box>
                    <Modal open={this.state.openPopUp}>
                        <Box className='eMed_Doc_Notes_Pop_Up'>
                            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Add Plan</Typography>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false }, () => this.onClearHandler())}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} height={'23vw'} overflow={'scroll'}>
                                <Box component={'div'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>In House Treatment *</Typography>
                                    </Box>
                                    <Box component={'div'} ml={'0.5vw'}>
                                        <Autocomplete
                                            clearIcon
                                            size='small'
                                            sx={{ width: '20vw' }}
                                            freeSolo
                                            options={this.state.frequentList?.inhouse_treatments?.map((item) => item)}
                                            value={this.state.selectedInhouse}
                                            limitTags={3}
                                            autoComplete='off'
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    selectedInhouse: newValue
                                                })
                                            }}
                                            onInputChange={(event, newValue) => {
                                                this.setState({ selectedInhouse: newValue })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select or Enter Inhouse Treatment' />}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Dosage Strength *</Typography>
                                    </Box>
                                    <Box component={'div'} m={'0.5vw'}>
                                        <TextField
                                            size='small'
                                            sx={{ width: '20vw' }}
                                            label='Enter Dosage Strength'
                                            value={this.state.dosageStrength}
                                            onChange={(e) => this.setState({ dosageStrength: e.target.value })}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Route *</Typography>
                                    </Box>
                                    <Box component={'div'} m={'0.5vw'}>
                                        <Autocomplete
                                            clearIcon
                                            size='small'
                                            sx={{ width: '20vw' }}
                                            freeSolo
                                            options={this.state.routeList.map((item) => item.label)}
                                            value={this.state.selectedRoute}
                                            limitTags={3}
                                            autoComplete='off'
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    selectedRoute: newValue
                                                })
                                            }}
                                            onInputChange={(event, newValue) => {
                                                this.setState({ selectedRoute: newValue })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select or Enter Route' />}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Comments</Typography>
                                    </Box>
                                    <Box component={'div'} m={'0.5vw'}>
                                        <TextField
                                            size='small'
                                            sx={{ width: '20vw' }}
                                            multiline
                                            rows={3}
                                            label='Enter Comments'
                                            value={this.state.comments}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250 || value === '') {
                                                    this.setState({ comments: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Procedure *</Typography>
                                    </Box>
                                    <Box component={'div'} ml={'0.5vw'}>
                                        <Autocomplete
                                            clearIcon
                                            size='small'
                                            sx={{ width: '20vw' }}
                                            freeSolo
                                            options={this.state.frequentList?.procedures?.map((item) => item)}
                                            value={this.state.selectedProcedure}
                                            limitTags={3}
                                            autoComplete='off'
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    selectedProcedure: newValue
                                                })
                                            }}
                                            onInputChange={(event, newValue) => {
                                                this.setState({ selectedProcedure: newValue })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select or Enter Procedure' />}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Description *</Typography>
                                    </Box>
                                    <Box component={'div'} m={'0.5vw'}>
                                        <TextField
                                            size='small'
                                            sx={{ width: '20vw' }}
                                            multiline
                                            rows={3}
                                            label='Enter Description'
                                            value={this.state.description}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250 || value === '') {
                                                    this.setState({ description: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.onClearHandler()}
                                >Clear</Button>
                                <Button
                                    disabled={this.state.postLoad}
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => this.state.isEdit ? this.putPlanData() : this.postPlanData()}
                                >Save</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderInhouseTable = () => {
        try {
            return (
                <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '15vw', overflow: 'auto' }}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'25%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Name</TableCell>
                                <TableCell width={'25%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Dosage</TableCell>
                                <TableCell width={'25%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Route</TableCell>
                                <TableCell width={'25%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Comments</TableCell>
                                <TableCell align='center' width={'25%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.planTreatments?.length > 0 ? this.state.planTreatments?.map((item, index) => (
                                <TableRow>
                                    <TableCell>
                                        <Typography fontSize={'0.9vw'} fontWeight={'600'}>{item?.name ? item?.name : '-'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize={'0.9vw'} fontWeight={'600'}>{item?.dosage ? item?.dosage : '-'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize={'0.9vw'} fontWeight={'600'}>{item?.route ? item?.route : '-'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize={'0.9vw'} fontWeight={'600'}>{item?.comments ?
                                            item?.comments?.length > 20 ?
                                                <Tooltip placement='top' title={item?.comments}><div>{item?.comments?.slice(0, 20)}</div></Tooltip> : item?.comments : '-'
                                        }</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button className='Common_Btn_Min_Width'
                                                onClick={() => {
                                                    this.onEditHandler(item, 'Inhouse')
                                                }}
                                            >
                                                <Tooltip placement='top' title='Edit'>
                                                    <Box height={'1.2vw'} width={'1.2vw'} component={'img'} src={ImagePaths.UserEditOn.default} />
                                                </Tooltip>
                                            </Button>
                                            <Button className='Common_Btn_Min_Width'
                                                onClick={() => this.onDeleteHandler(item, 'Inhouse Treatment')}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                <Tooltip placement='top' title='Delete'>
                                                    <Box height={'1.2vw'} width={'1.2vw'} component={'img'} src={ImagePaths.DeleteOutline.default} />
                                                </Tooltip>
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{ position: 'relative', left: '3vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderProcedureTable = () => {
        try {
            return (
                <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '15vw', overflow: 'auto' }}>
                    <Table stickyHeader size='small' >
                        <TableHead>
                            <TableRow>
                                <TableCell width={'33%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Name</TableCell>
                                <TableCell width={'33%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Description</TableCell>
                                <TableCell align='center' width={'33%'} sx={{ fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.planProcedure?.length > 0 ? this.state.planProcedure?.map((item, index) => (
                                <TableRow>
                                    <TableCell>
                                        <Typography fontSize={'0.9vw'} fontWeight={'600'}>{item?.name ? item?.name : '-'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize={'0.9vw'} fontWeight={'600'}>{item?.description ?
                                            item?.description?.length > 25 ?
                                                <Tooltip placement='top' title={item?.description}><div>{item?.description?.slice(0, 25)}</div></Tooltip> : item?.description : '-'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button className='Common_Btn_Min_Width'
                                                onClick={() => {
                                                    this.onEditHandler(item, 'Procedure')
                                                }}
                                            >
                                                <Tooltip placement='top' title='Edit'>
                                                    <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.UserEditOn.default} />
                                                </Tooltip>
                                            </Button>
                                            <Button className='Common_Btn_Min_Width'
                                                onClick={() => this.onDeleteHandler(item, 'Procedure')}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                <Tooltip placement='top' title='Delete'>
                                                    <Box height={'1.2vw'} width={'1.2vw'} component={'img'} src={ImagePaths.DeleteOutline.default} />
                                                </Tooltip>
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='left' sx={{ position: 'relative', right: '1vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                </TableRow>}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPlanTable = () => {
        try {
            this.state.planTreatments?.forEach((element, index) => element.sno = index + 1)
            this.state.planProcedure?.forEach((element, index) => element.sno = index + 1)
            return (
                <Box component={'div'}>
                    <Box component={'div'}>
                        <Box component={'div'} ml={'0.5vw'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>In House Treatment</Typography>
                        </Box>
                        <Box component={'div'} m={'0.5vw'}>
                            {this.renderInhouseTable()}
                        </Box>
                    </Box>
                    <Box component={'div'}>
                        <Box component={'div'} ml={'0.5vw'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>Procedure</Typography>
                        </Box>
                        <Box component={'div'} m={'0.5vw'}>
                            {this.renderProcedureTable()}
                        </Box>

                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'2.5vw'} sx={{ backgroundColor: Colors.Background }} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography pl={'0.5vw'} fontWeight={600}>Plan</Typography>
                        <Box component={'div'} display={'flex'}>
                            <Button
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', mr: '1.5vw', textTransform: 'capitalize' }}
                                onClick={() => this.setState({ openPopUp: true })}
                                disabled={this.state.isLocked || this.props.isLocked}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                        {this.renderPlanTable()}
                    </Box>
                    {this.renderPopUp()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={`Are you sure you want to delete ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.deletePlanData.bind(this)}
                        disable={this.state.removeData}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}

import React, { Component } from 'react';
import "./PharmaInventory.css";
import { Box, Stack, Tooltip, IconButton, Paper, TextField, Button } from "@mui/material";
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import PharmaDirectPo from "../PharmacyPurchase/PharmaDirectPo";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import Loader from '../../../Components/Loader';

class PharmaStockReturn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isReturn: false,
            stockId: null,
            stock_from: "",
            stockData: {},
            quantity: 0,
            reason: "",
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        }
    }

    componentDidUpdate(prevProps) {
        let states = this.state;
        let history = prevProps.history?.location?.state;
        let stockId = history?.stockId;

        if (stockId !== undefined && stockId !== states.stockId) {
            this.setState({
                isReturn: history?.isReturn ? history?.isReturn : false,
                stockId: stockId ? stockId : null,
                stock_from: history?.from ? history?.from : ""
            }, () => {
                this._getStockDetails();
            })
        }
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getStockDetails = () => {
        if (this.state.stockId) {
            try {
                RestAPIService.getAll(`${Serviceurls.PHARMA_STOCK_DETAILS}?list_id=${this.state.stockId}`)
                    .then(response => {
                        if (response.data?.status == "success") {
                            this.setState({
                                stockData: response.data ? response.data.data : {}
                            })
                        }
                    })
                    .catch(error => {
                        if (error.response?.data?.status === "fail") {
                            this.errorMessage(error.response?.data?.message);
                        }
                    })
            } catch (e) {
                this.errorMessage(e.message);
            }
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleTextChange = (e) => {
        let { name, value } = e.target;
        let states = this.state;
        switch (name) {
            case "quantity":
                let isNum = CommonValidation.numberOnly(value)
                if (value === "" || isNum && value > 0) {
                    states[name] = +value.trimStart();
                }
                break;
            default:
                if (value === "" || value.length <= 150) {
                    states[name] = value.trimStart();
                }
                break;
        }
        this.setState({
            states
        })
    }

    renderTextField = (label, statekey) => {
        const { t } = this.props
        let states = this.state
        return (
            <div id="eMed_pharmadrctpo_txtbox">
                <TextField
                    label={t(label)}
                    sx={{ width: "30vw" }}
                    required
                    size="small"
                    autoComplete="off"
                    name={statekey}
                    value={states[statekey] ? states[statekey] : ""}
                    multiline={statekey === "reason" ? true : false}
                    rows={statekey === "reason" ? 3 : null}
                    onChange={this.handleTextChange}
                />
            </div>
        )
    }

    checkMandatory = () => {
        let states = this.state;
        if (states.quantity !== 0 && states.reason !== "") {
            return true;
        }
        return false;
    }

    handleClear = () => {
        this.setState({
            quantity: 0,
            reason: ""
        })
    }

    handleRtnSave = () => {
        let { stockData, quantity, reason } = this.state;
        let isMantadoryAvail = this.checkMandatory();
        if (isMantadoryAvail) {
            let datas = {
                pack_of_drugs: stockData.pack_of_drugs,
                quantity: quantity,
                reason: reason,
                sales_unit: stockData.sales_unit,
                stock_id: stockData.stock_id
            }
            try {
                this.LoaderFunction(true);
                RestAPIService.create(datas, Serviceurls.PHARMA_STOCK_RETURN_POST)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.LoaderFunction(false);
                            this.props.history.push({ pathname: "/PharmacyInventory/StockList" });
                            this.handleClear();
                            this.successMessage(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false);
                            this.errorMessage(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.errorMessage(e.message);
            }
        }
    }

    returnComponent = () => {
        const { t } = this.props;
        return (
            <Paper className="eMed_pharmaRtnStk_rtncont" elevation={2}>
                <div className='eMed_pharmadrctpo_rhtHead'>
                    <p id="eMed_pharmaAdd_headTxt">{t("ReturnStockDetails")}</p>
                </div>
                <Box className="eMed_pharmaRtnStk_rtnDiv">
                    {this.renderTextField("Quantity", "quantity")}
                    {this.renderTextField("Reason", "reason")}
                </Box>
                <Stack spacing={2} direction="row" id='eMed_pharmaRtnStk_rtnBtnCon' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" disabled={!this.checkMandatory()}
                        id="eMed_Config_btn"
                        onClick={() => { this.handleRtnSave() }}>{t("Save")}</Button>
                </Stack>
            </Paper>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <Box component={'div'} className='eMed_pharmaRtnStk_container' >
                <Stack className="eMed_pharmaRtnStk_Head" direction="row" justifyContent="flex-start" alignItems="center" spacing={1.2} >
                    <Tooltip title="Filter" placement='top' arrow>
                        <IconButton size="small" onClick={() => { this.props.history.push({ pathname: '/PharmacyInventory/StockList' }) }} >
                            <img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} />
                        </IconButton>
                    </Tooltip>
                    <p id='eMed_pharmaRtnStk_txt'>{t("BackToStockList")}</p>
                </Stack>
                <Box className="eMed_pharmaRtnStk_content">
                    <PharmaDirectPo
                        from={this.state.isReturn ? "stockListReturn" : "stockListView"}
                        isReturn={this.state.isReturn}
                        stockData={this.state.stockData}
                        returnComponent={this.returnComponent.bind(this)}
                    />
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaStockReturn);

import { Box, Button, Chip, FormControl, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import AddIcon from '@mui/icons-material/Add';
import { DateTime } from 'luxon';

class DocAllergiesRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      AllergyType: "1",
      AllergyStatus: "active",
      AllergyName: "",
      NewAllergyName: "",
      AllergyComments: "",
      NewAllergyAdd: false,
      allergyNameList: [],
      SelectedId: null
    }
  }

  componentDidMount() {
    if (this.props.EditAllergyData && Object.keys(this.props?.EditAllergyData).length !== 0) {
      this.setState({
        AllergyType: this.props.EditAllergyData?.allergy_type ? this.props.EditAllergyData?.allergy_type : "1",
        AllergyStatus: this.props.EditAllergyData?.is_active ? "active" : "inactive",
        AllergyName: this.props.EditAllergyData?.name ? this.props.EditAllergyData?.name : "",
        AllergyComments: this.props.EditAllergyData?.comments ? this.props.EditAllergyData?.comments : "",
        SelectedId: this.props.EditAllergyData?.id ? this.props.EditAllergyData?.id : null,
      }, () => {
        this.props.ClearPropsData()
      })
    } else {
      this.getAllergyNameList(true)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.EditAllergyData !== this.props.EditAllergyData && Object.keys(this.props.EditAllergyData).length !== 0) {
      this.setState({
        AllergyType: this.props.EditAllergyData?.allergy_type ? this.props.EditAllergyData?.allergy_type : "1",
        AllergyStatus: this.props.EditAllergyData?.is_active ? "active" : "inactive",
        AllergyName: this.props.EditAllergyData?.name ? this.props.EditAllergyData?.name : "",
        AllergyComments: this.props.EditAllergyData?.comments ? this.props.EditAllergyData?.comments : "",
        SelectedId: this.props.EditAllergyData?.id ? this.props.EditAllergyData?.id : null,
      }, () => {
        let isAllergy = this.state.allergyNameList.some((list) => (list === this.state.AllergyName))
        if (!isAllergy) {
          let List = this.state.allergyNameList
          List.push(this.state.AllergyName)
          this.setState({
            allergyNameList: List,
          })
        }
        this.props.ClearPropsData()
      })
    }
  }

  getAllergyNameList = (AddItemIfNot = false) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ALLERGY_FEQ)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              allergyNameList: response.data.data
            }, () => {
              if (AddItemIfNot && this.state.AllergyName) {
                let isAllergy = this.state.allergyNameList.some((list) => (list === this.state.AllergyName))
                if (!isAllergy) {
                  let List = this.state.allergyNameList
                  List.push(this.state.AllergyName)
                  this.setState({
                    allergyNameList: List,
                  })
                }
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClearData() {
    if(this.state.SelectedId !== null){
      this.props.CallgetApi('allergies')
    }
    this.setState({
      AllergyType: "1",
      AllergyStatus: "active",
      AllergyName: "",
      NewAllergyName: "",
      AllergyComments: "",
      NewAllergyAdd: false,
      SelectedId: null,
      isDeleteClicked: false,
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      isDeleteClicked: false,
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  DeleteAllergy() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGY_FEQ + "?allergy=" + states.SelectedAllergy).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.getAllergyNameList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  PostAllergies() {
    try {
      var states = this.state
      if (CommonValidation.removeSpace(states.AllergyName) !== "") {
        let data = {
          appointment_id: this.props?.PatientData?.appointment_id,
          patient_id: this.props?.PatientData?.patient_id,
          allergies: {
            allergy_type: this.state.AllergyType,
            comments: this.state.AllergyComments,
            diagnosed_date: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            id: this.state.SelectedId ? this.state.SelectedId : null,
            is_active: this.state.AllergyStatus === "active" ? true : false,
            name: this.state.AllergyName
          }
        }

        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES).
          then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response.data.message)
              this.ClearData()
              this.props.CallgetApi('allergies')
            } else {
              this.errorMessage(response.data.message)
            }
          }).catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Select Allergy Name")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  postTemplateData = () => {
    try {
      let data = {
        doctor_category: this.state.CategoryAddText
      }
      this.setState({ loading: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.ClearData()
            this.getPatientCategoryList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteClicked: false })
  }

  render() {
    const { t } = this.props
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Allergies")}</Typography>
        <Box height={"56.5vh"} overflow={'auto'}>
          <Box>
            <FormControl sx={{ marginLeft: "1vw" }}>
              <RadioGroup
                value={this.state.AllergyType}
                sx={{ display: 'flex', flexDirection: 'row' }}
                onChange={(e) => { this.setState({ AllergyType: e.target.value }) }}
              >
                <FormControlLabel value="1" control={<Radio size='small' emed_tid='medication_radio'/>} label="Medication Allergy" />
                <FormControlLabel value="2" control={<Radio size='small' />} label="Non Medication Allergy" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Allergy Name")}</Typography>
            <Box>
              <Box className="eMed_DocNotes_FreqChips_Wrapp">
                {
                  this.state.allergyNameList?.length > 0 ? this.state.allergyNameList.map((item, index) => (
                    <Chip
                      size='small'
                      className={`eMed_chip ${this.state.AllergyName === item ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      key={index}
                      label={item}
                      variant={this.state.AllergyName === item ? 'contained' : "outlined"}
                      onClick={() => { this.setState({ AllergyName: item }) }}
                      onDelete={() => { this.setState({ isDeleteClicked: true, SelectedAllergy: item }) }}
                    />
                  )) : null
                }
                {this.state.NewAllergyAdd ?
                  <TextField
                    size='small'
                    value={this.state.NewAllergyName}
                    onChange={(e) => {
                      this.setState({ NewAllergyName: e.target.value })
                    }}
                    inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                    sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && this.state.NewAllergyName !== "") {
                        let List = this.state.allergyNameList
                        List.push(this.state.NewAllergyName)
                        this.setState({
                          allergyNameList: List,
                          AllergyName: this.state.NewAllergyName,
                          NewAllergyName: "",
                          NewAllergyAdd: false
                        })
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={this.state.NewAllergyName === ""}
                            onClick={() => {
                              let List = this.state.allergyNameList
                              List.push(this.state.NewAllergyName)
                              this.setState({
                                allergyNameList: List,
                                AllergyName: this.state.NewAllergyName,
                                NewAllergyName: "",
                                NewAllergyAdd: false
                              })
                            }}>
                            <AddIcon color={this.state.NewAllergyName === "" ? 'grey' : 'primary'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }} /> :
                  <IconButton
                    onClick={() => {
                      this.setState({
                        NewAllergyAdd: true
                      })
                    }}>
                    <AddIcon color={'primary'} />
                  </IconButton>
                }
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Allergy Status")}</Typography>
            <FormControl sx={{ marginLeft: "1vw" }}>
              <RadioGroup
                value={this.state.AllergyStatus}
                sx={{ display: 'flex', flexDirection: 'row' }}
                onChange={(e) => { this.setState({ AllergyStatus: e.target.value }) }}
              >
                <FormControlLabel value="active" control={<Radio size='small' />} label="Active" />
                <FormControlLabel value="inactive" control={<Radio size='small' />} label="InActive" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Comments")}</Typography>
            <TextField
              size='small'
              className='eMed_DocNotes_TextFields'
              multiline={true}
              rows={3}
              label={t("Comments ( Max 250 characters)")}
              inputProps={{ maxLength: 250 }}
              value={this.state.AllergyComments}
              onChange={(e) => {
                if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                  this.setState({ AllergyComments: e.target.value })
                }
              }}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" _id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.PostAllergies() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Allergy")} ${this.state.SelectedAllergy ? this.state.SelectedAllergy : "-"} From Frequent List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DeleteAllergy.bind(this)}
        /> : null}
      </Box>
    )
  }
}
export default withTranslation()(DocAllergiesRight)

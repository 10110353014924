import React, { Component } from 'react'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { withTranslation } from 'react-i18next';
import { Autocomplete, Box, Button, Drawer, Modal, Stack, TextField, Tooltip, Typography, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { CheckAccessFunc, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { ConfirmPopup } from '../../../../Components/Common Components/CommonComponents';

class SmsRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            smsRequestData: [],
            smsLimit: '',
            disableBtn: false,
            raiseRequestPopup: false,
            msgCount: "",
            requestBy: '',
            requestDate: new Date(),
            requestNotes: '',
            DatePickerOpen: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            FilterOpen: false,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            requestByData: [],
            filterRequestBy: [],
            filterReqNo: '',
            filterStatusData: ["Pending", "Completed", "Cancelled"],
            filterStatus: '',
            userName: '',
            isLoader: false,
            isEdit: false,
            disableBtn: false,
            requestNo: '',
            requestId: '',
            requestStatus: '',
            deleteRequest: false,
        }
    }

    componentDidMount = () => {
        this.getSmsRequestData()
        this.GetUserDetails()
        let userName = localGetItem("LogedUserName")
        this.setState({ userName: userName })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        })
    }

    popClose = () => {
        this.setState({
            deleteRequest: false,
            disableBtn: false,
        })
    }

    confirmRemove = () => {
        this.removeSmsRequest()
    }

    getSmsRequestData = () => {
        try {
            let userId = []
            this.state.filterRequestBy?.forEach(element => userId?.push(element?.user_name))
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.FO_SMS_REQUEST + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&request_no=${this.state.filterReqNo}&request_by=${userId}&status=${this.state.filterStatus ? this.state.filterStatus : ''}`)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        this.setState({
                            smsRequestData: response?.data?.data,
                            smsLimit: `${response?.data?.used_sms_count} / ${response?.data?.total_sms_count}`,
                            isLoader: false,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ isLoader: false })
                })

        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ isLoader: false })
        }
    }

    printSmsRequest = () => {
        try {
            let userId = []
            this.state.filterRequestBy?.forEach(element => userId?.push(element?.user_name))
            this.setState({ disableBtn: true })
            RestAPIService.getAll(Serviceurls.FO_SMS_REQUEST + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&request_no=${this.state.filterReqNo}&request_by=${userId}&status=${this.state.filterStatus ? this.state.filterStatus : ''}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    )
                    const fileURL = URL.createObjectURL(file)
                    window.open(fileURL)
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({ disableBtn: false })
                })
        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ disableBtn: false })
        }
    }

    exportSmsRequest = () => {
        try {
            let userId = []
            this.state.filterRequestBy?.forEach(element => userId?.push(element?.user_name))
            this.setState({ disableBtn: true })
            RestAPIService.excelGet(Serviceurls.FO_SMS_REQUEST + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&request_no=${this.state.filterReqNo}&request_by=${userId}&status=${this.state.filterStatus ? this.state.filterStatus : ''}&export_type=excel`)
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ disableBtn: false })
        }
    }

    postRaiseRequest = () => {
        try {
            if (this.state.msgCount === '') {
                this.errorMessage("Please Enter Message Count")
                this.setState({ disableBtn: false })
            } else {
                let reqDate = this.state.requestDate ? DateTime.fromJSDate(this.state.requestDate).toFormat('yyyy-MM-dd HH:mm:ss') : null
                let data = {
                    request_date: reqDate,
                    request_by: this.state.userName ? this.state.userName : '',
                    message_count: this.state.msgCount ? +this.state.msgCount : null,
                    notes: this.state.requestNotes ? this.state.requestNotes : ''
                }
                RestAPIService.create(data, Serviceurls.FO_SMS_REQUEST)
                    .then((response) => {
                        if (response?.data?.status === "success") {
                            this.successMessage(response.data?.message)
                            this.getSmsRequestData()
                            this.setState({ raiseRequestPopup: false, disableBtn: false })
                            this.clearData()
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                        this.setState({ disableBtn: false })
                    })
            }

        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ disableBtn: false })
        }
    }

    clearData = () => {
        this.setState({
            msgCount: "",
            requestBy: '',
            requestDate: new Date(),
            requestNotes: '',
            raiseRequestPopup: false,
            isEdit: false,
        })
    }

    GetUserDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET + `?show_all=true`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            requestByData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }

                })
        } catch (error) {
            this.errorMessage(error)
        }
    }

    renderRaisePopup = () => {
        return (
            <Modal open={true}>
                <Box className="eMed_ImgPreviewPop_Main" height={'50vh'}>
                    <Box component={'div'} className="eMed_ImgPreviewPop_Header">
                        <Typography fontWeight={'bold'} variant='h6'>{this.state.isEdit ? `SMS Request - Request No: ${this.state.requestNo}` : "Raise SMS Request"}</Typography>
                        <Button emed_tid='req_popup_close_testID' onClick={this.clearData} >
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1vw', alignContent: 'center', margin: '1vw' }}>
                        <Box sx={{ margin: '1vw' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    open={this.state.DatePickerOpen}
                                    onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                                    onClose={() => { this.setState({ DatePickerOpen: false }) }}
                                    value={this.state.requestDate}
                                    disabled={true}
                                    name={"datePicker"}
                                    inputFormat='DD-MM-YYYY'
                                    views={["year", "month", "day"]}
                                    // onChange={
                                    //     (newDate) => {
                                    //         if (newDate === null) {
                                    //             states["requestDate"] = new Date()
                                    //         } else {
                                    //             states["requestDate"] = newDate.$d
                                    //         }
                                    //         this.setState({
                                    //             states
                                    //         })
                                    //     }}
                                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} variant='standard' label='Request Date *' size='small' sx={{ width: '25vw', mr: '1.5vw', paddingTop: '1vw' }} {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} inputProps={{ ...params.inputProps, 'emed_tid': 'req_popup_datepicker_testID' }} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                label='Request By *'
                                size='small'
                                autoComplete='off'
                                variant='standard'
                                sx={{ width: '25vw' }}
                                inputProps={{ emed_tid: "req_popup_requestBy_testID", maxLength: 30 }}
                                disabled={true}
                                value={this.state.isEdit ? this.state.requestBy : this.state.userName}
                            />
                        </Box>
                        <Box sx={{ margin: '1vw' }}>
                            <TextField
                                label='Message Count *'
                                variant='standard'
                                size='small'
                                autoComplete='off'
                                sx={{ width: '25vw', mr: '1.5vw' }}
                                inputProps={{ emed_tid: "req_popup_msgCount_testID", maxLength: 30 }}
                                value={this.state.msgCount}
                                onChange={(e) => {
                                    if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                                        this.setState({ msgCount: e.target.value })
                                    }
                                }}
                            />
                            <TextField
                                label='Notes'
                                variant='standard'
                                size='small'
                                autoComplete='off'
                                sx={{ width: '25vw' }}
                                value={this.state.requestNotes}
                                inputProps={{ emed_tid: "req_popup_notes_testID" }}
                                onChange={(e) => {
                                    this.setState({ requestNotes: e.target.value })
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '1vw', m: '2vw', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' emed_tid="req_popup_cancel_testID" onClick={this.clearData}>CANCEL</Button>
                        <Button variant='contained' emed_tid="req_popup_sendReq_testID" disabled={this.state.disableBtn} onClick={() => { this.setState({ disableBtn: true }, () => { this.state.isEdit ? this.editSmsRequest() : this.postRaiseRequest() }) }}>{this.state.isEdit ? "Edit Request" : "SEND REQUEST"}</Button>
                    </Box>
                </Box>
            </Modal>
        )
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn' emed_tid="filter_close_testID">
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label='Request No'
                            size='small'
                            autoComplete='off'
                            sx={{ width: '20vw' }}
                            inputProps={{ emed_tid: "req_popup_notes_testID", maxLength: 30 }}
                            value={this.state.filterReqNo}
                            onChange={(e) => {
                                if (CommonValidation.ALPHA_NUMARIC(e.target.value) || e.target.value === '') {
                                    this.setState({ filterReqNo: e.target.value })
                                }
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            multiple
                            autoComplete='off'
                            limitTags={1}
                            options={this.state.requestByData}
                            getOptionLabel={(option) => (option?.user_name)}
                            value={this.state.filterRequestBy}
                            onChange={(event, newValue) => {
                                this.setState({ filterRequestBy: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'emed_tid': 'filter_requestBy_testID' }}
                                    label={t("Request By")}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            options={this.state.filterStatusData}
                            getOptionLabel={(option) => (option)}
                            value={this.state.filterStatus}
                            onChange={(event, newValue) => {
                                this.setState({ filterStatus: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Status")}
                                    inputProps={{ ...params.inputProps, 'emed_tid': 'filter_status_testID' }}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid="filter_clear_testID"
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                filterReqNo: '',
                                filterRequestBy: [],
                                filterStatus: '',
                            },
                                () => this.getSmsRequestData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid="filter_search_testID"
                        onClick={() => {
                            this.getSmsRequestData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    editSmsRequest = () => {
        try {
            let data = {
                id: this.state.requestId ? this.state.requestId : '',
                status: this.state.requestStatus ? this.state.requestStatus : '',
                request_date: this.state.requestDate ? DateTime.fromJSDate(this.state.requestDate).toFormat('yyyy-MM-dd HH:mm:ss') : '',
                request_by: this.state.requestBy ? this.state.requestBy : '',
                message_count: this.state.msgCount ? this.state.msgCount : '',
                notes: this.state.requestNotes ? this.state.requestNotes : '',
            }
            RestAPIService.create(data, Serviceurls.FO_SMS_REQUEST)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        this.successMessage(response.data?.message)
                        this.getSmsRequestData()
                        this.setState({
                            raiseRequestPopup: false,
                            requestDate: new Date(),
                            requestBy: '',
                            msgCount: '',
                            requestNotes: '',
                            requestStatus: '',
                            requestId: '',
                            isEdit: false,
                            disableBtn: false,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ disableBtn: false })
                })

        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ disableBtn: false })
        }
    }

    removeSmsRequest = () => {
        try {
            let data = {
                id: this.state.requestId ? this.state.requestId : '',
                status: 'Cancelled',
                request_date: this.state.requestDate ? DateTime.fromJSDate(this.state.requestDate).toFormat('yyyy-MM-dd') : '',
                request_by: this.state.requestBy ? this.state.requestBy : '',
                message_count: this.state.msgCount ? this.state.msgCount : '',
                notes: this.state.requestNotes ? this.state.requestNotes : '',
            }
            RestAPIService.create(data, Serviceurls.FO_SMS_REQUEST)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        this.successMessage(response.data?.message)
                        this.getSmsRequestData()
                        this.setState({
                            disableBtn: false,
                            deleteRequest: false,
                            requestDate: new Date(),
                            requestBy: '',
                            msgCount: '',
                            requestNotes: '',
                            requestStatus: '',
                            requestId: '',
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ disableBtn: false })
                })

        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ disableBtn: false })
        }
    }

    render() {
        this.state.smsRequestData?.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.01, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "request_no", headerName: t("Request No"), flex: 0.142, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.request_no?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.request_no} arrow><div>{params?.row?.request_no?.slice(0, 12) + "..."}</div></Tooltip>
                    : params?.row?.request_no ? params?.row?.request_no : "-"}</Box>)
            },
            {
                field: "request_date", headerName: t("Request Date"), flex: 0.142, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.request_date ? formatDate(params?.row?.request_date) : '-'}</Box>)
            },
            {
                field: "request_by", headerName: t("Request by"), flex: 0.142, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.request_by?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.request_by} arrow><div>{params?.row?.request_by?.slice(0, 12) + "..."}</div></Tooltip>
                    : params?.row?.request_by ? params?.row?.request_by : "-"}</Box>)
            },
            {
                field: "message_count", headerName: t("Message Count"), flex: 0.1, headerAlign: "center", align: "center",
            },
            {
                field: "notes", headerName: t("Notes"), flex: 0.142, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.notes?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.notes} arrow><div>{params?.row?.notes?.slice(0, 17) + "..."}</div></Tooltip>
                    : params?.row?.notes ? params?.row?.notes : "-"}</Box>)
            },
            {
                field: "status", flex: 0.142, headerName: t("Status"), headerAlign: "center", align: "center",
                renderCell: ({ row }) => {
                    let divColor = row?.status === "Completed" ? "#ECFFE9" : row?.status === "Pending" ? "#FFE9E9" : null;
                    let txtColor = row?.status === "Completed" ? "#1E972A" : row?.status === "Pending" ? "#B82121" : null;
                    return (
                        <Stack sx={{ padding: "0.4vw", borderRadius: "0.5vw", backgroundColor: divColor }}>
                            <Typography sx={{ color: txtColor, fontSize: '0.9vw' }}>{row?.status ? row?.status : "-"}</Typography>
                        </Stack>
                    );
                }
            },
            {
                field: "action", flex: 0.10, headerName: t("Action"), headerAlign: "center", align: "center",
                renderCell: ({ row }) => {
                    let allowEdit = row?.status === "Pending" ? true : false
                    let allowCancel = (row?.status === "Cancelled" || row?.status === "Completed") ? false : true
                    let date = DateTime.fromFormat(row?.request_date, 'dd-MM-yyyy')?.toFormat('yyyy-MM-dd')
                    return (
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            {allowEdit ?
                                <Tooltip title="Edit" placement="top" arrow><div className="eMed_pause">
                                    <IconButton disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Request", "SubTab")?.editAccess} onClick={() => {
                                        this.setState({
                                            isEdit: true,
                                            raiseRequestPopup: true,
                                            requestId: row?.id ? row?.id : '',
                                            requestNo: row?.request_no ? row.request_no : '',
                                            requestBy: row?.request_by ? row?.request_by : '',
                                            requestDate: new Date(date),
                                            msgCount: row?.message_count ? row?.message_count : '',
                                            requestNotes: row?.notes ? row.notes : '',
                                            requestStatus: row?.status ? row?.status : '',
                                        })
                                    }}>
                                        <img className="eMed_upload" src={ImagePaths.LabEditButton.default} alt='edit' />
                                    </IconButton>
                                </div></Tooltip>
                                : null}
                            {allowCancel ?
                                <Tooltip title={t("Remove")} placement="top" arrow>
                                    <IconButton emed_tid="ord_rmv" className='eMed_usrconf_btn' disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Request", "SubTab")?.editAccess} onClick={() => {
                                        this.setState({
                                            deleteRequest: true,
                                            requestId: row?.id ? row?.id : '',
                                            requestNo: row?.request_no ? row.request_no : '',
                                            requestBy: row?.request_by ? row?.request_by : '',
                                            requestDate: new Date(date),
                                            msgCount: row?.message_count ? row?.message_count : '',
                                            requestNotes: row?.notes ? row.notes : '',
                                        })
                                    }}>
                                        <img src={ImagePaths.CrossIcon.default} alt="Remove" className='eMed_action_img' />
                                    </IconButton>
                                </Tooltip> : null}
                        </Box>
                    )
                }
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("SMS Used"), this.state.smsLimit, true, "white", false, null, 'sms_left_card')}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container' width={'40%'}>
                        <Button emed_tid='drt_po_req' className='eMed_Filter_Btns' variant='contained' disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Request", "SubTab")?.editAccess}
                            onClick={() => {
                                this.setState({ raiseRequestPopup: true })
                            }}
                        >{`+ Raise Request`}</Button>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <IconButton emed_tid="filter_btn_testID" onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </IconButton>
                        </Box>
                        <IconButton emed_tid="print_btn_testID" disabled={this.state.disableBtn} onClick={() => { this.printSmsRequest() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </IconButton>
                        <IconButton emed_tid="excel_btn_testID" disabled={this.state.disableBtn} onClick={() => { this.exportSmsRequest() }}>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </IconButton>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.smsRequestData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        loading={this.state.isLoader}
                        hideFooterSelectedRowCount
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        headerHeight={40}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        disableSelectionOnClick
                    />
                </Box>
                {this.state.raiseRequestPopup ?
                    this.renderRaisePopup()
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deleteRequest ? <ConfirmPopup
                    cnfTitle={t('Confirmation')}
                    cnfContent={t('Are you sure, do you want to Cancel SMS request ?')}
                    cnfAlertPopupClose={this.popClose.bind(this)}
                    cnfData={this.confirmRemove.bind(this)}
                    cnfDisable={this.state.disableBtn}

                /> : null}
            </Box>
        )
    }
}

export default withTranslation()(SmsRequest)
import { Autocomplete, Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Asset_Condition, Asset_Status, Asset_Type } from '../../../Utility/Constants';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';

export default class AddAssetTransfer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transNo: '',
            assetName: '',
            verfiyRemarks: '',
            categoryList: [],
            subCategoryList: [],
            assetTypeList: Asset_Type,
            conditionList: Asset_Condition,
            statusList: Asset_Status,
            cityList: [],
            blockList: [],
            floorList: [],
            spotAreaList: [],
            documentList: [{
                doc_name: '',
                remarks: '',
                pdf: ''
            }],
            blockListNew: [],
            assetIdList: [],
            transferByList: [],
            handoverList: [],
            approvedByList: [],
            branchList: [],
            selectedBranch: null,
            selectedCategory: null,
            selectedSubCategory: null,
            selectedAssetType: null,
            selectedCondition: null,
            selectedStatus: null,
            selectedCity: null,
            selectedBlock: null,
            selectedFloor: null,
            selectedSportArea: null,
            selectedDesCity: null,
            selectedDesBlock: null,
            selectedDesFloor: null,
            selectedSportDesArea: null,
            selectedAssetId: null,
            selectedTransferBy: null,
            selectedHandover: null,
            selecetedApprovedBy: null,
            dateAndTime: null,
            dateOpen: false,
            dupAssetList: '[]',
            postLoad: false
        }
        this.fileUploadRef = React.createRef();
    }

    componentDidMount() {
        this.getAssetIdList()
        this.getcategorySubCategoryData()
        this.getAssetBlockFloorSpotData()
        this.getBlockListData()
        this.getEmployeeListData()
    }

    getAssetBlockFloorSpotData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_NEW_BLOCK_FLOOR_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            blockListNew: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getcategorySubCategoryData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSER_NEW_CAT_SUB_CAT_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            categoryList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getBlockListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_BLOCK_LIST_GET_POST).
                then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        let activeCity = data?.filter(item => item?.active_status)
                        let cityOnly = activeCity?.map(item => item?.city)
                        this.setState({
                            cityList: [...new Set(cityOnly)]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getEmployeeListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_EMPLOYEE_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            transferByList: response.data.data,
                            handoverList: response.data.data,
                            approvedByList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAssetIdList = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASSET_LIST_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            assetIdList: response.data.data,
                            dupAssetList: JSON.stringify(response.data.data)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postAssetTransferData = () => {
        try {
            let formData = new FormData()
            let states = this.state

            let sourceDetails = {
                block_id: states.selectedAssetId?.block_id ? states.selectedAssetId?.block_id : '',
                floor_id: states.selectedAssetId?.floor_id ? states.selectedAssetId?.floor_id : '',
                spot_id: states.selectedAssetId?.spot_id ? states.selectedAssetId?.spot_id : ''
            }

            let designationDetails = {
                block_id: states.selectedDesBlock?.block_id ? states.selectedDesBlock?.block_id : '',
                floor_id: states.selectedDesFloor?.floor_id ? states.selectedDesFloor?.floor_id : '',
                spot_id: states.selectedSportDesArea?.id ? states.selectedSportDesArea?.id : ''
            }

            let startDate = new Date(states.dateAndTime)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let dateTime = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}T${Aptime}`

            formData.append('assetlist_id', states.selectedAssetId ? states.selectedAssetId?.id : '')
            formData.append('transfer_no', states.transNo ? states.transNo : '')
            formData.append('transfer_date', states.dateAndTime ? dateTime : '')
            formData.append('transfered_employee', states.selectedTransferBy ? states.selectedTransferBy?.id : '')
            formData.append('handover_employee', states.selectedHandover ? states.selectedHandover?.id : '')
            formData.append('approved_employee', states.selecetedApprovedBy ? states.selecetedApprovedBy?.id : '')
            formData.append('remark', states.verfiyRemarks ? states.verfiyRemarks : '')
            formData.append('source_detail_ids', JSON.stringify(sourceDetails))
            formData.append('designation_detail_ids', JSON.stringify(designationDetails))
            formData.append('doc_length', ((states.documentList[0]?.pdf || states.documentList[0]?.doc_name) ? states.documentList?.length : 0))

            states.documentList?.forEach((item, index) => {
                formData.append(`documentList[${index}].doc_name`, item.doc_name)
                formData.append(`documentList[${index}].remarks`, item.remarks)
                formData.append(`documentList[${index}].pdf `, item.pdf)
            })
            this.setState({ postLoad: true })
            RestAPIService.create(formData, Serviceurls.ASSET_TRANSFER_GET_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.setState({ postLoad: false })
                        setTimeout(() => {
                            this.props.history?.push('/AssetHome/AssetTransfer')
                        }, 500)
                    }
                }).catch((error) => {
                    this.setState({ postLoad: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    TextBox = (names, values, labels, width, mr, mt, onlyRead, placeholder, index) => {
        try {
            return (
                <TextField
                    autoComplete='off'
                    variant='standard'
                    size='small'
                    sx={{ width: width, mr: mr, mt: mt }}
                    value={values}
                    label={labels}
                    placeholder={placeholder}
                    name={names}
                    onChange={(e) => this.onChangeHandler(e, names, index)}
                    InputProps={{ readOnly: onlyRead }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, names, index) => {
        try {
            let value = e.target.value
            let states = this.state
            switch (names) {
                case 'doc_name':
                    states.documentList[index]['doc_name'] = value
                    this.setState({ states })
                    break;
                case 'remarks':
                    states.documentList[index]['remarks'] = value
                    this.setState({ states })
                    break;
                default:
                    if (value?.length <= 100 || value === '') {
                        states[names] = value
                        this.setState({ states })
                    }
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AutoCompleteBox = (options, names, key, labels, width, mr, mt, disable) => {
        try {
            let states = this.state
            return (
                <Autocomplete
                    clearIcon
                    disabled={disable}
                    size='small'
                    sx={{ width: width, mr: mr, mt: mt }}
                    options={options}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option[names]}
                    value={states[key]}
                    onChange={(e, value) => { this.onChangeAutoCompletehandler(value, names, key) }}
                    renderInput={(params) => <TextField variant='standard' {...params} label={labels} />}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeAutoCompletehandler = (value, names, key) => {
        try {
            let states = this.state
            switch (names) {
                case 'block_name':
                    if (key === 'selectedBlock') {
                        this.setState({ selectedBlock: value }, () => {
                            this.setState({
                                floorList: this.state.selectedBlock?.floor_data,
                                selectedFloor: null,
                                selectedSportArea: null,
                            })
                        })
                    } else {
                        this.setState({ selectedDesBlock: value }, () => {
                            this.setState({
                                floorList: this.state.selectedDesBlock?.floor_data,
                                selectedDesFloor: null,
                                selectedSportDesArea: null,
                            })
                        })
                    }
                    break;
                case 'floor_name':
                    if (key === 'selectedFloor') {
                        this.setState({ selectedFloor: value }, () => {
                            this.setState({
                                spotAreaList: this.state.selectedFloor?.spot_data,
                                selectedSportArea: null
                            })
                        })
                    } else {
                        this.setState({ selectedDesFloor: value }, () => {
                            this.setState({
                                spotAreaList: this.state.selectedDesFloor?.spot_data,
                                selectedSportDesArea: null
                            })
                        })
                    }
                    break;
                case 'spot_name':
                    if (key === 'selectedSportArea') {
                        this.setState({ selectedSportArea: value })
                    } else {
                        this.setState({ selectedSportDesArea: value })
                    }
                    break;
                case 'city':
                    if (key === 'selectedCity') {
                        this.setState({ selectedCity: value }, () => {
                            let filterData = this.state.blockListNew?.filter(item => item?.block_id === this.state.selectedCity?.id)
                            this.setState({
                                blockList: filterData,
                                selectedBlock: null,
                                selectedFloor: null,
                                selectedSportArea: null
                            })
                        })
                    } else {
                        this.setState({ selectedDesCity: value }, () => {
                            let filterData = this.state.blockListNew?.filter(item => item?.city === this.state.selectedDesCity)
                            this.setState({
                                blockList: filterData,
                                selectedDesBlock: null,
                                selectedDesFloor: null,
                                selectedSportDesArea: null
                            })
                        })
                    }
                    break;
                case 'category_name':
                    this.setState({ selectedCategory: value }, () => {
                        this.setState({
                            subCategoryList: this.state.selectedCategory?.sub_category_data,
                            selectedSubCategory: null,
                            assetIdList: JSON.parse(this.state.dupAssetList)
                        })
                    })
                    break;
                case 'sub_category_name':
                    this.setState({ selectedSubCategory: value, assetIdList: JSON.parse(this.state.dupAssetList), selectedAssetId: null }, () => {
                        if (this.state.selectedCategory !== null && this.state.selectedSubCategory !== null) {
                            let data = this.state.assetIdList?.filter(item => item?.category__category_name === this.state.selectedCategory?.category_name && item?.sub_category__sub_category_name === this.state.selectedSubCategory?.sub_category_name)
                            if (data?.length === 1) {
                                this.setState({
                                    selectedAssetId: data[0],
                                    assetName: data[0]?.asset_name,
                                    selectedCity: data[0]?.block__city,
                                    selectedBlock: data[0]?.block__block_name,
                                    selectedFloor: data[0]?.floor__floor_name,
                                    selectedSportArea: data[0]?.spot__spot_name,
                                    assetIdList: data,
                                })
                            } else {
                                this.setState({ assetIdList: data })
                            }
                        }
                    })
                    break;
                case 'asset_no':
                    this.setState({ selectedAssetId: value }, () => {
                        this.setState({
                            assetName: this.state.selectedAssetId?.asset_name,
                            selectedCategory: this.state.selectedAssetId?.category__category_name,
                            selectedSubCategory: this.state.selectedAssetId?.sub_category__sub_category_name,
                            selectedBlock: this.state.selectedAssetId?.block__block_name,
                            selectedFloor: this.state.selectedAssetId?.floor__floor_name,
                            selectedCity: this.state.selectedAssetId?.block__city,
                            selectedSportArea: this.state.selectedAssetId?.spot__spot_name
                        })
                    })
                default:
                    states[key] = value
                    this.setState({ states })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    DateAndTimePicker = (date, dateSet, values, key, labels, width, mr, mt) => {
        try {
            let states = this.state
            return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        open={date}
                        onOpen={() => {
                            let states = this.state
                            states[dateSet] = true
                            this.setState({ states })
                        }}
                        onClose={() => {
                            let states = this.state
                            states[dateSet] = false
                            this.setState({ states })
                        }}
                        inputFormat='DD-MM-YYYY hh:mm A'
                        value={values}
                        onChange={(value) => {
                            let states = this.state
                            states[key] = value
                            this.setState({ states })
                        }}
                        maxDate={new Date()}
                        label={labels}
                        renderInput={(props) => <TextField
                            {...props}
                            sx={{ width: width, mr: mr, mt: mt }}
                            variant='standard' size='small'
                            autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={() => {
                                let states = this.state
                                states[dateSet] = true
                                this.setState({ states })
                            }}
                        />}
                    />
                </LocalizationProvider>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkTransferData = () => {
        try {
            let message = null;
            let states = this.state
            if (states.selectedCategory === null || states.selectedSubCategory === null || states.selectedAssetId === null
                || states.assetName === '' || states.selectedCity === null || states.selectedBlock === null || states.selectedFloor === null ||
                states.selectedSportArea === null || states.selectedTransferBy === null || states.selectedDesBlock === null || states.selectedDesCity === null ||
                states.selectedDesFloor === null || states.selectedSportDesArea === null || states.selectedHandover === null || states.selecetedApprovedBy === null
                || states.dateAndTime === null) {
                message = 'Enter all mandatory field'
            } else {
                message = null
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                transNo: '',
                assetName: '',
                verfiyRemarks: '',
                selectedBranch: null,
                selectedCategory: null,
                selectedSubCategory: null,
                selectedAssetType: null,
                selectedCondition: null,
                selectedStatus: null,
                selectedCity: null,
                selectedBlock: null,
                selectedFloor: null,
                selectedSportArea: null,
                selectedDesCity: null,
                selectedDesBlock: null,
                selectedDesFloor: null,
                selectedSportDesArea: null,
                selectedAssetId: null,
                selectedTransferBy: null,
                selectedHandover: null,
                selecetedApprovedBy: null,
                dateAndTime: null,
                dateOpen: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAssetTransfer = () => {
        try {
            let subCat = this.state.subCategoryList?.length > 0 ? false : true
            let block = this.state.selectedCity === null ? true : false
            let floor = this.state.selectedBlock === null ? true : false
            let spot = this.state.selectedFloor === null ? true : false
            let destBlock = this.state.selectedDesCity === null ? true : false
            let destFloor = this.state.selectedDesBlock === null ? true : false
            let destSpot = this.state.selectedDesFloor === null ? true : false
            return (
                <Box component={'div'}>
                    <Box component={'div'} maxHeight={'70vh'} overflow={'auto'}>
                        <Box component={'div'}>
                            <Box component={'div'} height={'4vh'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Asset Transfer</Typography>
                            </Box>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>Asset Details</Typography>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.TextBox('transNo', this.state.transNo, 'Transfer No (Read Only)', '17vw', '0.8vw', '', true)}
                                        {this.DateAndTimePicker(this.state.dateOpen, 'dateOpen', this.state.dateAndTime, 'dateAndTime', 'Date And Time *', '17vw', '0.8vw')}
                                        {this.AutoCompleteBox(this.state.categoryList, 'category_name', 'selectedCategory', 'Catagory *', '17vw', '0.8vw')}
                                        {this.AutoCompleteBox(this.state.subCategoryList, 'sub_category_name', 'selectedSubCategory', 'Sub Catagory *', '17vw', '0.8vw', '', subCat)}
                                        {this.AutoCompleteBox(this.state.assetIdList, 'asset_no', 'selectedAssetId', 'Asset Id *', '17vw', '0.8vw')}
                                    </Box>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.TextBox('assetName', this.state.assetName, 'Asset Name *', '17vw', '0.8vw', '1vw', true)}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} height={'4vh'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Transfer Details</Typography>
                            </Box>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>Asset Source</Typography>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.AutoCompleteBox(this.state.cityList, 'city', 'selectedCity', 'City *', '17vw', '0.8vw', '', true)}
                                        {/* {this.AutoCompleteBox(this.state.branchList, 'branch', 'selectedBranch', 'Branch', '17vw', '0.8vw')} */}
                                        {this.AutoCompleteBox(this.state.blockList, 'block_name', 'selectedBlock', 'Block *', '17vw', '0.8vw', '', true)}
                                        {this.AutoCompleteBox(this.state.floorList, 'floor_name', 'selectedFloor', 'Floor *', '17vw', '0.8vw', '', true)}
                                        {this.AutoCompleteBox(this.state.spotAreaList, 'spot_name', 'selectedSportArea', 'Spot/Area *', '17vw', '0.8vw', '', true)}
                                    </Box>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.AutoCompleteBox(this.state.transferByList, 'employee_name', 'selectedTransferBy', 'Transfer By *', '17vw', '0.8vw')}
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>Asset Destination</Typography>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.AutoCompleteBox(this.state.cityList, 'city', 'selectedDesCity', 'City *', '17vw', '0.8vw')}
                                        {/* {this.AutoCompleteBox(this.state.branchList, 'branch', 'selectedBranch', 'Branch', '17vw', '0.8vw')} */}
                                        {this.AutoCompleteBox(this.state.blockList, 'block_name', 'selectedDesBlock', 'Block *', '17vw', '0.8vw', '', destBlock)}
                                        {this.AutoCompleteBox(this.state.floorList, 'floor_name', 'selectedDesFloor', 'Floor *', '17vw', '0.8vw', '', destFloor)}
                                        {this.AutoCompleteBox(this.state.spotAreaList, 'spot_name', 'selectedSportDesArea', 'Spot/Area *', '17vw', '0.8vw', '', destSpot)}
                                    </Box>
                                    <Box component={'div'} display={'flex'} m={'0.5vw'}>
                                        {this.AutoCompleteBox(this.state.handoverList, 'employee_name', 'selectedHandover', 'Handover To *', '17vw', '0.8vw')}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} height={'3vh'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Verification</Typography>
                            </Box>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'} display={'flex'}>
                                    {this.AutoCompleteBox(this.state.approvedByList, 'employee_name', 'selecetedApprovedBy', 'Approved By *', '17vw', '0.8vw')}
                                    {this.TextBox('verfiyRemarks', this.state.verfiyRemarks, 'Remarks', '20vw', '0.8vw')}
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                            <Box component={'div'} borderBottom={'1px solid lightgray'} p={'0.5vw'}>
                                <Typography fontWeight={600}>Document</Typography>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} display={'flex'} m={'0.5vw'} flexDirection={'column'}>
                                    {this.state.documentList?.map((item, index) => {
                                        return (
                                            <Box component={'div'} key={index} display={'flex'} mt={'0.5vw'}>
                                                {this.TextBox('doc_name', item?.doc_name, 'Document Name', '17vw', '0.8vw', '', '', '', index)}
                                                {this.TextBox('remarks', item?.remarks, 'Remarks', '17vw', '0.8vw', '', '', '', index)}
                                                <Typography fontWeight={600} fontSize={'0.9vw'} sx={{ position: 'relative', top: '1.7vw' }}>{item?.pdf ? item?.pdf?.name : ''}</Typography>
                                                <Box component={'input'} type='file' sx={{ display: 'none' }}
                                                    // multiple
                                                    accept='application/pdf'
                                                    ref={this.fileUploadRef}
                                                    onChange={(e) => {
                                                        let selectedFile = e.target.files[0]
                                                        if (selectedFile && selectedFile?.type === 'application/pdf') {
                                                            this.state.documentList[index]['pdf'] = e.target.files[0]
                                                            this.setState({ docoumentList: this.state.documentList })
                                                        } else {
                                                            this.errorMessage('Please select a PDF file')
                                                        }
                                                    }}
                                                />
                                                <Box component={'div'} sx={{ position: 'relative', top: '1vw' }}>
                                                    <Button
                                                        className='Common_Btn_Min_Width'
                                                        size='small'
                                                        onClick={() => this.fileUploadRef.current.click()}
                                                    ><UploadFileIcon color='primary' /></Button>
                                                    <Button
                                                        className='Common_Btn_Min_Width'
                                                        size='small'
                                                        onClick={() => {
                                                            if (this.state.documentList?.length === 1) {
                                                                let newDoc = [{
                                                                    doc_name: '',
                                                                    remarks: '',
                                                                    pdf: ''
                                                                }]
                                                                this.setState({ documentList: newDoc })
                                                            } else {
                                                                this.state.documentList?.splice(index, 1)
                                                                this.setState({ documentList: this.state.documentList })
                                                            }
                                                        }}
                                                    ><CloseIcon color='error' /></Button>
                                                </Box>

                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Box component={'div'}>
                                    <Button
                                        sx={{ textTransform: 'capitalize' }}
                                        className='Common_Btn_Min_Width'
                                        startIcon={<AddCircleIcon />}
                                        size='small'
                                        onClick={() => {
                                            let newDoc = {
                                                doc_name: '',
                                                remarks: '',
                                                pdf: ''
                                            }
                                            let states = this.state
                                            if (states.documentList[states.documentList?.length - 1]['pdf']?.length === 0) {
                                                this.errorMessage("Previous document is empty")
                                            } else {
                                                this.state.documentList?.push(newDoc)
                                                this.setState({ documentList: this.state.documentList })
                                            }
                                        }}
                                    >Add New Document</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button
                            variant='outlined'
                            sx={{ height: '2vw', mr: '1vw' }}
                            onClick={() => { this.props.history.push('/AssetHome/AssetTransfer') }}
                        >Cancel</Button>
                        <Button
                            variant='contained'
                            sx={{ height: '2vw', mr: '1vw' }}
                            disabled={this.state.postLoad}
                            onClick={() => {
                                let check = this.checkTransferData()
                                if (check === null) {
                                    this.postAssetTransferData()
                                } else {
                                    this.errorMessage(check)
                                }
                            }}
                        >Transfer</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <Box component={'div'}>
                <Box component={'div'} height={'84vh'}>
                    <Box component={'div'} height={'6vh'} display={'flex'} borderBottom={'2px solid lightgray'} alignItems={'center'} p={'0.5vw'}>
                        <Button
                            emed_tid='back_home'
                            className='Common_Btn_Min_Width'
                            startIcon={<ArrowBackIosIcon />}
                            size='small'
                            onClick={() => { this.props.history.push('/AssetHome/AssetTransfer') }}
                        ></Button>
                        <Typography fontWeight={600}>Back To Asset Transfer</Typography>
                    </Box>
                    <Paper sx={{ m: '0.8vw', borderRadius: '5px', maxHeight: '77vh' }} elevation={2}>
                        {this.renderAssetTransfer()}
                    </Paper>
                </Box>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}

import { Autocomplete, Box, Button, Dialog, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import { Colors } from "../../../Styles/Colors";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { DateTime } from "luxon";
import Success from '../../../Assets/Animation/Success.gif';
import { clearCachevalue } from "../../../Utility/Services/CacheProviderService";


class PharmaPORequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            brandNameData: [],
            brandName: null,
            genericName: null,
            dosageStrength: '',
            dosageType: '',
            totalAmount: '',
            requestDiscount: '',
            requestQty: '',
            freeQty: '',
            ratePerQty: '',
            vendorName: null,
            vendorNameData: [],
            gst: '',
            prNo: '',
            prDate: new Date(),
            genericNameData: [],
            productListData: [],
            productDetailsData: [],
            postLoad: false,
            isEdit: false,
            open: false,
            openGeneric: false,
            selectedIndex: null,
            isCompleted: false,
            poNumber: '',
            PORequestData: [],
            lineID: null,
            deletedIDs: [],
            disableBtn: false
        }
    }

    componentDidMount() {
        this.getVendorNameData()
        let po_ID = this.props.history?.location?.states?.selectedID
        if(po_ID){
            this.getPORequestData()
        }
    }

    getPORequestData = () => {
        let po_ID = this.props.history?.location?.states?.selectedID
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_ORDER_PO_REQUEST_LIST}?id=${po_ID ? po_ID : ""}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            PORequestData: response?.data?.data
                        })
                        this.assignPODetails(response?.data?.data)
                    }
                }).catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }
    assignPODetails = (data) =>{
        this.setState({
            vendorName: {name: data[0]?.vendor__name, vendor_id: data[0]?.vendor_id},
            gst: data[0]?.vendor__gstin_number,
            prDate: data[0]?.purchase_order_date,
            productDetailsData: data[0]?.line_items,
        })
    }
    
    getBrandNameData = (brandName) => {
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_BRAND_NAME_GET}?brand_name=${brandName ? brandName : ""}`)
                .then(response => {
                    let data = response?.   data;
                    if (response.data.status === "success") {
                        this.setState({
                            brandNameData: data.drug_list
                        })
                    }
                }).catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getVendorNameData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_LIST + '?from=purchase_order')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            vendorNameData: response.data.data
                        })
                    }
                }).catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                });
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkPostValidation = () => {
        try {
            let message = null
            if (this.state.vendorName === null) {
                message = 'Please Select Vendor Name'
            } else if (this.state.prDate == null) {
                message = 'Please Select Pr Date'
            } else if (this.state.productDetailsData?.length === 0) {
                message = 'Please Add Product Details'
            }else if (this.state.isEdit) {
                message = "You have clicked the Edit button. So, please add or clear the details."
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postPurchaseRequestData = () => {
        try {
            let startDate = new Date(this.state.prDate)
            let purchaseDate = this.state.prDate ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd') : null
            let data = {
                vendor_id: this.state.vendorName?.vendor_id,
                purchase_order_date: purchaseDate,
                line_items: this.state.productDetailsData,
                purchase_request: true,
                gst_no: this.state.gst
            }
            let check = this.checkPostValidation()
            if (check === null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.PURCHASE_PLACEORDER_POST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response.data.message)
                            this.onClearHandler()
                            // this.setState({
                            //     isCompleted: true
                            // })
                            this.setState({
                                productDetailsData: [],
                                vendorName: null,
                                gst: '', postLoad: false,
                                poNumber: response.data?.data?.po_number,
                                disableBtn: false,
                            }, () => {
                                setTimeout(() => {
                                    this.props.history?.push({ pathname: '/PharmacyPurchase' })
                                }, 500)
                            })
                        }
                    }).catch(error => {
                        if (error.response?.data.status === "fail") {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response?.data?.message);
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message);
                        }
                        this.setState({ disableBtn: false })
                    });
            } else {
                this.errorMessage(check)
                this.setState({ disableBtn: false })
            }
        } catch (error) {
            this.setState({ postLoad: false, disableBtn: false })
            this.errorMessage(error.message)
        }
    }

    postPOEditData = () => {
        try {
            let startDate = new Date(this.state.prDate)
            let purchaseDate = this.state.prDate ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd') : null
            let po_ID = this.props.history?.location?.states?.selectedID
            let data = {
                vendor_id: this.state.vendorName?.vendor_id,
                purchase_order_date: purchaseDate,
                line_items: this.state.productDetailsData,
                purchase_request: true,
                gst_no: this.state.gst,
                is_edited: true,
                id: po_ID,
                deleted_ids: this.state.deletedIDs,

            }
            let check = this.checkPostValidation()
            if (check === null) {
                this.setState({ postLoad: true })
                RestAPIService.updateWithOutId(data, Serviceurls.PURCHASE_ORDER_PO_REQUEST_LIST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response.data.message)
                            this.onClearHandler()
                            this.setState({
                                productDetailsData: [],
                                vendorName: null,
                                gst: '', postLoad: false,
                                poNumber: response.data?.data?.po_number,
                                disableBtn: false
                            }, () => {
                                clearCachevalue("IsPORequestEdit")
                                setTimeout(() => {
                                    this.props.history?.push({ pathname: '/PharmacyPurchase/PurchaseOrder/RequestList' });
                                }, 500)
                            })
                        }
                    }).catch(error => {
                        if (error.response?.data.status === "fail") {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response?.data?.message);
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message);
                        }
                        this.setState({ disableBtn: false })
                    });
            } else {
                this.errorMessage(check)
                this.setState({ disableBtn: false })
            }
        } catch (error) {
            this.setState({ postLoad: false, disableBtn: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }


    onChangeHandler = (e, names) => {
        try {
            let value = e.target.value
            if (names === 'requestDiscount') {
                if ((CommonValidation.DecimalNumber(value) && +value <= 100) || value === '') {
                    this.setState({
                        [names]: value
                    })
                    // ,() => {
                    //     if(this.state.requestQty && this.state.ratePerQty){
                    //         let total = (+this.state.requestQty) * (+this.state.ratePerQty)
                    //         this.setState({
                    //             totalAmount:value ? ((total/value)*100) : total
                    //         })
                    //     }
                    // })
                }
            } else if (names === 'requestQty' || names === 'freeQty') {
                if (CommonValidation.numberOnly(value) && value?.length <= 7 || value === '') {
                    this.setState({
                        [names]: value
                    }, () => {
                        if (names === 'requestQty') {
                            if (this.state.requestQty !== '' && this.state.ratePerQty !== '') {
                                this.setState({
                                    totalAmount: ((+this.state.requestQty) * (+this.state.ratePerQty))?.toFixed(2)
                                })
                            } else {
                                this.setState({
                                    totalAmount: ''
                                })
                            }
                        }
                    })
                }
            } else {
                if (CommonValidation.NumberWithDot(value) || value === '') {
                    this.setState({
                        [names]: value
                    }, () => {
                        if (this.state.requestQty !== '' && this.state.ratePerQty !== '') {
                            this.setState({
                                totalAmount: ((+this.state.requestQty) * (+this.state.ratePerQty))?.toFixed(2)
                            })
                        } else {
                            this.setState({
                                totalAmount: ''
                            })
                        }
                    })
                }
            }



        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (names, value, label, width, mr, fullWidth, disabled) => {
        try {
            return (
                <TextField
                    size="small"
                    sx={{ width: width, mr: mr, mt: '1vw' }}
                    fullWidth={fullWidth}
                    InputProps={{ readOnly: disabled}}
                    label={label}
                    value={value}
                    name={names}
                    inputProps={{emed_tid:`PO_request_${names}`}}
                    onChange={(e) => this.onChangeHandler(e, names)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkValidation = () => {
        try {
            let message = null
            if (this.state.brandName === null) {
                message = 'Please Enter Brand Name'
            } else if (this.state.genericName === null) {
                message = 'Please Enter Generic Name'
            } 
            //  else if (this.state.ratePerQty === '') {
            //     message = 'Please Enter Rate Per Quantity'
            // } 
            // else if (this.state.totalAmount === '') {
            //     message = 'Please Enter Total Amount'
            // } 
            else {
                if (this.state.productDetailsData?.length > 0) {
                    let brandName = this.state.brandName?.drug_name ? this.state.brandName?.drug_name : this.state.brandName
                    this.state.productDetailsData?.forEach((element, index) => {
                        if (this.state.selectedIndex === index) {
                            message = null
                        } else {
                            if (element?.drug_ms_id === this.state.drugMsId) {
                                message = 'Product Already Added'
                            }
                        }
                    })
                }
            }
            return message
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addProductDetailsData = () => {
        try {
            let po_ID = this.props.history?.location?.states?.selectedID
            let productList = {
                brand_name: this.state.brandName?.drug_name ? this.state.brandName?.drug_name : this.state.brandName,
                generic_name: this.state.genericName?.generic_name ? this.state.genericName?.generic_name : this.state.genericName,
                dosage_strength: this.state.dosageStrength,
                dosage_type: this.state.dosageType,
                free_quantity: +this.state.freeQty,
                discount_percentage: +this.state.requestDiscount,
                rate_per_unit: +this.state.ratePerQty,
                order_quantity: +this.state.requestQty,
                drug_ms_id: this.state.genericName?.drug_id ? this.state.genericName?.drug_id : this.state.drugMsId,
                buying_price: 0,
                estimated_epr: 0,
            }
            if(po_ID && this.state.lineID){
                productList['id'] = this.state.lineID
            }
            let checkValidation = this.checkValidation()
            if (checkValidation === null) {
                let data = CommonValidation.updateOrInsertList(this.state.productDetailsData, productList, this.state.selectedIndex)
                data?.forEach((element, index) => element.serial_no = index + 1)
                this.setState({ productDetailsData: data }, () => { this.onClearHandler() })
            } else {
                this.errorMessage(checkValidation)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item, index) => {
        try {
            this.setState({
                brandName: item?.brand_name,
                genericName: item?.generic_name,
                dosageStrength: item?.dosage_strength,
                dosageType: item?.dosage_type,
                requestQty: item?.order_quantity ? item?.order_quantity : '',
                ratePerQty: item?.rate_per_unit,
                freeQty: item?.free_quantity,
                requestDiscount: item?.discount_percentage,
                totalAmount: item?.buying_price,
                selectedIndex: index,
                serial_no: item?.serial_no,
                drugMsId: item?.drug_ms_id,
                isEdit: true,
                lineID: item?.id
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (data, index) => {
        try {
            if (this.state.isEdit) {
                this.errorMessage('Your have Click Edit button.So,You cannot able to delete.')
            } else {
                let deleted_ids = [...this.state.deletedIDs]; 
                if(data.id){deleted_ids.push(data.id); }

                this.state.productDetailsData?.splice(index, 1);
                this.setState({
                    productDetailsData: this.state.productDetailsData,
                    deletedIDs: deleted_ids
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                brandNameData: [],
                brandName: null,
                genericName: null,
                dosageStrength: '',
                dosageType: '',
                totalAmount: '',
                requestDiscount: '',
                requestQty: '',
                freeQty: '',
                ratePerQty: '',
                genericNameData: [],
                productListData: [],
                isEdit: false,
                selectedIndex: null,
                lineID: null,
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderLeftScreen = () => {
        let porequest_ID = this.props.history?.location?.states?.selectedID
        try {
            return (
                <Box component={'div'} p={'0.5vw'} >
                    <Box component={'div'}>
                        <Box component={'div'} border={'1px solid lightgray'} height={'7vw'}>
                            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'0.5vw'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Request Details</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} pl={'0.5vw'}>
                                <Box component={'div'}>
                                    <Autocomplete
                                        value={this.state.vendorName}
                                        sx={{ width: '22vw', mt: '1.5vw', mr: '0.5vw' }}
                                        size="small"
                                        options={this.state.vendorNameData}
                                        getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.name}
                                        onChange={(e, value) => {
                                            this.setState({
                                                vendorName: value
                                            }, () => {
                                                if (this.state.vendorName === null) {
                                                    this.setState({ gst: '' })
                                                } else {
                                                    this.setState({ gst: this.state.vendorName?.gstin_number })
                                                }
                                            })
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                              <li {...props} key={option.id}>
                                                {option.name}
                                              </li>
                                            )
                                          }}
                                        renderInput={(params) => <TextField {...params} label={'Vendor Name'} onChange={(e) => { this.setState({ gst: '' }) }} inputProps={{...params.inputProps, 'emed_tid': 'vendor_name',}}/>}
                                    />
                                </Box>
                                <Box component={'div'} mt={'0.5vw'}>
                                    {this.inputTextBox('gst', this.state.gst, 'GST', '10vw', '0.5vw', false, true)}
                                    {/* {this.inputTextBox('prNo', this.state.prNo, 'PR NO', '10vw', '0.5vw')} */}
                                </Box>
                                <Box component={'div'}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            open={this.state.open}
                                            onOpen={() => { this.setState({ open: true }) }}
                                            onClose={() => { this.setState({ open: false }) }}
                                            maxDate={new Date()}
                                            inputFormat="dd-MM-yyyy"
                                            label={'PR Date'}
                                            value={this.state.prDate}
                                            autoComplete="off"
                                            onChange={(value) => this.setState({ prDate: value })}
                                            renderInput={(params) => <TextField
                                                onKeyDown={(e) => e.preventDefault()}
                                                autoComplete="off"
                                                size="small"
                                                sx={{ width: "10vw", mt: '1.5vw' }}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    emed_tid: 'PrDate',
                                                }}
                                                onClick={() => this.setState({ open: true })}
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'} mt={'1vw'} border={'1px solid lightgray'} height={'24vw'} overflow={'auto'}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600' }}>S.No</TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600' }}>Product Details</TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600' }}>Request Quantity</TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600' }}>Free Quantity</TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600' }}>Discount</TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600' }}>Purchase Rate</TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.productDetailsData?.length > 0 ? this.state.productDetailsData?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item?.serial_no}</TableCell>
                                            <TableCell>
                                                <Box component={'div'}>
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.brand_name ?
                                                        item?.brand_name?.length > 15 ?
                                                            <Tooltip><div>{item?.brand_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                                            item?.brand_name : '-'}</Typography>
                                                    <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{item?.generic_name ?
                                                        item?.generic_name?.length > 15 ?
                                                            <Tooltip><div>{item?.generic_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                                            item?.generic_name : '-'}</Typography>
                                                    <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${item?.dosage_type ? item?.dosage_type : '-'} | ${item?.dosage_strength ? item?.dosage_strength : '-'}`}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{item?.order_quantity ? item?.order_quantity : 0}</TableCell>
                                            <TableCell>{item?.free_quantity ? item?.free_quantity : 0}</TableCell>
                                            <TableCell>{item?.discount_percentage ? item?.discount_percentage : 0}</TableCell>
                                            <TableCell>{item?.rate_per_unit ? item?.rate_per_unit : 0}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} display={'flex'}>
                                                    <Button className="Common_Btn_Min_Width" emed_tid='PO_request_edit_btn'
                                                        onClick={() => this.onEditHandler(item, index)}
                                                    >
                                                        <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.2vw'} width={'1.2vw'} />
                                                    </Button>
                                                    <Button className="Common_Btn_Min_Width" emed_tid='PO_request_delete_btn'
                                                        onClick={() => this.onDeleteHandler(item, index)}
                                                    >
                                                        <Box component={'img'} src={ImagePaths.LabDeleteButton.default} height={'1.2vw'} width={'1.2vw'} />
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )) : <TableRow><TableCell colSpan={7} align="center" sx={{ borderBottom: 'none', position: 'relative', top: '10vw' }}>No Records To Be Shown</TableCell></TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box component={'div'} border={'1px solid lightgray'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'} sx={{ backgroundColor: Colors.Background }}>
                            {/* <Button variant="contained">Print</Button>           */}
                            <Button variant="contained" emed_tid='send_request_btn'
                                sx={{ height: '2vw', textTransform: 'capitalize' }}
                                disabled={this.state.postLoad}
                                onClick={() => this.setState({ disableBtn: true }, () => { porequest_ID ? this.postPOEditData() : this.postPurchaseRequestData()})}
                            >{porequest_ID ? "Update Request" : "Send Request"}</Button>
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClosePurchaseRequest = () => {
        this.setState({
            isCompleted: false
        })
    }

    renderRightScreen = () => {
        try {
            return (
                <Box component={'div'} sx={{ backgroundColor: Colors.white }} p={'0.5vw'}>
                    <Box component={'div'} border={'1px solid lightgray'}>
                        <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} alignItems={'center'} pl={'0.5vw'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>Product Details</Typography>
                        </Box>
                        <Box component={'div'} height={'30vw'} p={'0.5vw'}>
                            <Box component={'div'}>
                                <Autocomplete
                                    clearIcon
                                    value={this.state.brandName}
                                    fullWidth
                                    size="small"
                                    readOnly={this.state.isEdit}
                                    options={this.state.brandNameData}
                                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.drug_name}
                                    onChange={(e, value) => {
                                        this.setState({
                                            brandName: value
                                        }, () => {
                                            this.setState({ dosageStrength: '', dosageType: '', genericName: null, genericNameData: [] })
                                            if (this.state.brandName?.drug_deatils?.length > 1) {
                                                this.setState({ genericNameData: this.state.brandName?.drug_deatils }, () => this.setState({ brandNameData: [] }))
                                            } else {
                                                this.setState({
                                                    genericName: this.state.brandName?.drug_deatils[0]?.generic_name,
                                                    dosageStrength: this.state.brandName?.drug_deatils[0]?.drug_strength,
                                                    dosageType: this.state.brandName?.drug_deatils[0]?.dosage_type,
                                                    drugMsId: this.state.brandName?.drug_deatils[0]?.drug_id
                                                }, () => this.setState({ brandNameData: [] }))
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={'Brand Name *'} inputProps={{...params.inputProps, 'emed_tid': 'brand_name',}}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length >= 3) {
                                                this.getBrandNameData(e.target.value)
                                            }
                                            this.setState({ dosageStrength: '', dosageType: '', genericName: '', genericNameData: [] })
                                        }}
                                    />}
                                />
                            </Box>
                            <Box component={'div'}>
                                <Autocomplete
                                    clearIcon
                                    value={this.state.genericName}
                                    fullWidth
                                    size="small"
                                    readOnly={((this.state.brandName?.drug_deatils?.length === 1) || this.state.isEdit) ? true : false}
                                    sx={{ mt: '1vw' }}
                                    options={this.state.genericNameData}
                                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.generic_name}
                                    onChange={(e, value) => {
                                        this.setState({
                                            genericName: value
                                        }, () => {
                                            this.setState({ dosageStrength: '', dosageType: '' })
                                            this.setState({
                                                dosageStrength: this.state.genericName?.drug_strength,
                                                dosageType: this.state.genericName?.dosage_type,
                                                drugMsId: this.state.genericName?.drug_id
                                            })
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={'Generic Name *'} inputProps={{...params.inputProps, 'emed_tid': 'generic_name'}}
                                        onChange={(e) => {
                                            this.setState({ dosageStrength: '', dosageType: '' })
                                        }} />}
                                />
                                <Box component={'div'} display={'flex'}>
                                    {this.inputTextBox('dosageType', this.state.dosageType, 'Dosage Type *', '15vw', '1vw', false, true)}
                                    {this.inputTextBox('dosageStrength', this.state.dosageStrength, 'Dosage Strength *', '15vw', '', false, true)}
                                </Box>
                                <Box component={'div'} display={'flex'} mt={'2vw'}>
                                    {this.inputTextBox('requestQty', this.state.requestQty, 'Request Qty *', '15vw', '1vw')}
                                    {this.inputTextBox('freeQty', this.state.freeQty, 'Free Quantity(Unit(s))', '15vw')}
                                </Box>
                                <Box component={'div'} display={'flex'}>
                                    {this.inputTextBox('ratePerQty', this.state.ratePerQty, 'Purchase Rate', '15vw', '1vw')}
                                    {this.inputTextBox('requestDiscount', this.state.requestDiscount, 'Request Discount', '15vw')}
                                </Box>
                                {/* <Box component={'div'} display={'flex'}>
                                    {this.inputTextBox('totalAmount', this.state.totalAmount, 'Total Amount *', '15vw', '1vw', false, true)}
                                </Box> */}
                            </Box>
                        </Box>
                        <Box component={'div'} height={'2.5vw'} sx={{ backgroundColor: Colors.Background }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Button sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }} emed_tid='PO_request_clear_btn'
                                variant="outlined"
                                onClick={() => this.onClearHandler()}
                            >Clear</Button>
                            <Button sx={{ height: '2vw', textTransform: 'capitalize' }} emed_tid='PO_request_add_btn'
                                variant="contained"
                                onClick={() => this.addProductDetailsData()}
                            >Add</Button>
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    render() {
        return (
            <Box component={'div'} display={'flex'}>
                <Box component={'div'} width={'65%'}>
                    {this.renderLeftScreen()}
                </Box>
                <Box component={'div'} width={'35%'}>
                    {this.renderRightScreen()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                        testId={this.state.poNumber}
                    />
                    : null}
                {this.state.isCompleted ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.isCompleted}
                        // onClose={this.ClosePurchaseRequest}
                        maxWidth={"md"}
                    >
                        <div className='Lab_Home_Dialogboxwidth'>
                            <img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />
                            <h3>{'PO Request Successfully Completed'}</h3>
                            {/* <FormControlLabel labelPlacement="start" className='eMed_create_switch' control={<Switch size="small" onClick={() => { this.setState({ smsActive: true }, () => this.postSms()) }} />} label="Receive SMS" /> */}
                            <div className="Lab_home_pop_buttom_view">
                                <Button variant="outlined" size="small" sx={{ height: '2vw' }} id="eMed_Btn_Text" emed_tid='PO_request_completed_close_btn'
                                    onClick={() => {
                                        this.setState({
                                            productDetailsData: [],
                                            vendorName: null,
                                            gst: '', postLoad: false,
                                        }, () => this.props.history?.push({ pathname: '/PharmacyPurchase' }))
                                    }}>{'Close'}</Button>
                                {/* <Button variant="contained" size="small" sx={{ marginLeft: "0.5vw", height: '2vw' }}
                                    id="eMed_Btn_Text"
                                    onClick={() => {
                                        this.getPurchaseRequestPrint()
                                    }}
                                >{"Print"}</Button> */}
                                {/* <Tooltip title={"Print Patient Barcode"} placement="top">
                                    <IconButton id="eMed_Btn_Text" sx={{ height: '2vw' }} variant="contained" size="small"
                                        onClick={() => {
                                        }}
                                    ><img style={{ width: '1.8vw', height: '1.8vw' }} src={ImagePaths.PrintIcons.default} alt="print" /></IconButton>
                                </Tooltip> */}
                            </div>
                        </div>
                    </Dialog>
                    : null}
            </Box>
        )
    }
}
export default PharmaPORequest;

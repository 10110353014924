import { Box, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './OThome.css'
import { Colors } from '../../Styles/Colors';
import CommonDatePicker from '../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import { ImagePaths } from '../../Utility/ImagePaths';
import CommonGridHeader, { AppointmentCard, CommonGridToolBarWithFilterTextCustom, CommonPatientDetails } from '../../Components/Common Components/CommonComponents';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { DataGrid } from '@mui/x-data-grid';
import { localGetItem, setCachevalue } from '../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate } from '../../Components/CommonFunctions/CommonFunctions';
import PackageDeatailPop from '../../Components/Common Components/PackageDeatailPop';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { ReasonPopup } from '../../Components/Common Components/CommonComponents';

class CounsellorHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      page: 0,
      pageSize: 10,
      selectedCard: "",
      AppointmentList: [],
      TotalCountList: [],
      selectedOption: "",
      SelectedPatientDetails: {},
      counsellorName: "",
      ClinicID: "",
      comments: "",
      isCancelled: "",
      doctorData: [],
      roomChangeDate: new Date(),
      serviceTemplateData: [],
      SelectedPackage: "",
      PackageAmount: "",
      isOPT: false
    }
  }

  componentDidMount() {
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    if (myUser?.clinic_id) {
      this.setState({
        ClinicID: myUser?.clinic_id,
        counsellorName: myUser?.counsellor_name
      }, () => {
        this.getCounsellorAppointments()
      })
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    }, () => { this.getCounsellorAppointments() })
  }

  closePopup() {
    this.setState({
      selectedOption: ""
    })
  }

  ErrorToast(data) {
    this.errorMessage(data)
  }
  SuccessToast(data) {
    this.successMessage(data)
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  gridToolBar = () => {
    // let TextArray = [
    //   { label: "From Date", value: formatDate(this.state.fromDate) },
    //   { label: "To Date", value: formatDate(this.state.toDate) },
    // ]
    var ExportData = []
    this.state.AppointmentList?.length > 0 && this.state.AppointmentList.map((item) => {
      let address1 = `"${item?.address_line_1 ? item?.address_line_1 : ""}"` 
      let address2 = `"${item?.address_line_2 ? item?.address_line_2 : ""}"`
    return (
    ExportData.push({"Patient Name":item?.patient_name,"Mobile Number":item?.patient_mobile_number,"UHID":item?.patient_account_number,"Speciality":item?.speciality,"Surgery":item?.surgery_name,"Reffered Doctor":item?.doctor_name,"Transmitted Date":item?.transmitted_date, "Status": item?.status === "Transmitted" ? "Booked" : item.status === "Completed"? "Approved" : item.status, 
      "Address 1": address1, "Address 2": address2, "City":item?.city,"Pincode":item?.pincode, "State":item?.state, "Country":item?.country 
     })
    )
    }
    )

    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Counsellor Home"} filename={"Counsellor Home"} />
      </Box>

    )
  }

  getCounsellorAppointments = () => {

    try {
      setCachevalue(this.state.fromDate, 'Counseller From Date')
      setCachevalue(this.state.toDate, 'Counseller To Date')
      setCachevalue(this.state.ClinicID, 'Counseller Clinic ID')
      setCachevalue(this.state.selectedCard, 'Counseller Status Data')
      RestAPIService.getAll(Serviceurls.GET_COUNSELLOR_APPOINTMENTS + "?from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate + "&clinic_id=" + this.state.ClinicID + "&status_data=" + this.state.selectedCard).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              AppointmentList: response.data.data,
              TotalCountList: response.data
            }, () => {
              this.state.AppointmentList.forEach((element)=>{
                element.PatientDetails = `${element.patient_name} ${element.patient_account_number} ${element.patient_mobile_number}`
              })
              this.setState({
                isOPT: response.data.data.every(obj => obj["iol_power"] !== null)
              })
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getServiceTemplateData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCPACKAGE + "?clinic_id=" + this.state.ClinicID)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              serviceTemplateData: response?.data?.data
            }, () => {
              this.setState({
                SelectedPackage: this.state?.serviceTemplateData.find((element) => element?.id === this.state?.SelectedPatientDetails?.package_id)
              }, () => { this.setState({ PackageAmount: this.state.SelectedPackage?.total_amount }) })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PostCounsellorRetransmit = () => {
    try {
      let states = this.state;
      let newDate = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
      let newTime = DateTime.fromJSDate(new Date()).toFormat('HH:mm:ss')
      var data = {
        id: states.SelectedPatientDetails.id,
        doctor_id: states.SelectedPatientDetails.doctor_id,
        patient_id: states.SelectedPatientDetails.patient_id,
        appointment_id: states.SelectedPatientDetails.appointment_id,
        clinic_id: states.ClinicID,
        surgery_id: states.SelectedPatientDetails.surgery_id,
        package_id: states.SelectedPatientDetails.package_id,
        surgeon_name: states.SelectedPatientDetails.surgeon_name,
        anaesthetist: states.SelectedPatientDetails.anaesthetist,
        counsellor_name: states.counsellorName,
        notes: states.comments,
        discount_amount: 0,
        net_amount: this.state.PackageAmount,
        status: "Cancelled",
        discount_percentage: 0,
        surgery_date: newDate,
        surgery_time: newTime,
      }
      RestAPIService.create(data, Serviceurls.COUNSELLOR_RETRANSMIT_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              selectedOption: ""
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PatientArrivedPost = () => {
    try {
      let states = this.state;
      var data = {
        id: states.SelectedPatientDetails.id,
        is_arrived: true
      }
      RestAPIService.create(data, Serviceurls.COUNSELLOR_RETRANSMIT_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getCounsellorAppointments()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    }, () => {
      this.getCounsellorAppointments()
    })
  }

  addCancelComments = (value) => {
    this.setState({
      comments: value
    }, () => { this.PostCounsellorRetransmit() })
  }

  renderPatientType = () => {
    const { t } = this.props
    var states = this.state
    return (
      <div className='eMed_OT_Apt_Cards'>
        <AppointmentCard onClick={() => { this.setState({ selectedCard: '' }, () => { this.getCounsellorAppointments() }) }} count={states.TotalCountList.all_count ? states.TotalCountList.all_count : 0} label={t("All")} color={"#616161"} isSelectedCard={states.selectedCard === "" ? true : false} />
        <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Booked' }, () => { this.getCounsellorAppointments() }) }} count={`${states.TotalCountList.booked ? states.TotalCountList.booked : 0}`} label={t("Booked")} color={"#1E972A"} isSelectedCard={states.selectedCard === "Booked" ? true : false} />
        <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Approved' }, () => { this.getCounsellorAppointments() }) }} count={`${states.TotalCountList.approved_count ? states.TotalCountList.approved_count : 0}`} label={t("Approved")} color={"#1E972A"} isSelectedCard={states.selectedCard === "Approved" ? true : false} />
        <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Draft' }, () => { this.getCounsellorAppointments() }) }} count={`${states.TotalCountList.draft ? states.TotalCountList.draft : 0}`} label={t("Draft")} color={"#DE7D24"} isSelectedCard={states.selectedCard === "Draft" ? true : false} />
        <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Cancelled' }, () => { this.getCounsellorAppointments() }) }} count={`${states.TotalCountList.cancelled ? states.TotalCountList.cancelled : 0}`} label={t("Cancelled")} color={"#B8170D"} isSelectedCard={states.selectedCard === "Cancelled" ? true : false} />
      </div>
    )
  }

  ActionBtn = (data) => {
    const { t } = this.props;

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1.5}>
        <Tooltip title={`${t('Arrived')}`} placement='top' arrow>
          <div>
            <IconButton size="small" disabled={data.row?.is_arrived ? true : false} onClick={() => { this.setState({ SelectedPatientDetails: data.row }, () => { this.PatientArrivedPost() }) }}>
              <img className='eMed_IP_AptTbl_Status_Icon' src={data.row?.is_arrived ? ImagePaths.OTSurgeryConvertDisaable.default : ImagePaths.OTSurgeryConvert.default} alt='Package Details' />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={`${t('Package Details')}`} placement='top' arrow>
          <div>
            <IconButton size="small" disabled={false} onClick={() => { this.setState({ selectedOption: "PackageDetail", SelectedPatientDetails: data.row }) }}>
              <img className='eMed_IP_AptTbl_Status_Icon' src={ImagePaths.CreateBill.default} alt='Package Details' />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={t('Cancel')} placement='top' arrow>
          <div>
            <IconButton disabled={data?.row?.status === "Cancelled" || data?.row?.status === "Completed"} size="small" onClick={() => { this.setState({ selectedOption: "CancelPatient", SelectedPatientDetails: data.row }, () => { this.getServiceTemplateData() }) }}>
              <img className='eMed_IP_AptTbl_Status_Icon' src={data?.row?.status === "Cancelled" || data?.row?.status === "Completed" ? ImagePaths.CancelAppDisabled.default : ImagePaths.CancelRed.default} alt='Checkout' />
            </IconButton>
          </div>
        </Tooltip>
        {/* <div>
          <Tooltip title={t("More")} placement='top' arrow>
            <IconButton
              size="small"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  selectedRow: data.id,
                  isSelected: true
                })
              }}
              disabled={false}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </div> */}
      </Stack>
    )
  }

  returnStatus(status,data) {
    const { t } = this.props
    switch (status) {
      case "Completed":
        return <Tooltip placement='top' title={t("Approved")} arrow>
          <div className='eMed_OT_APtTbl_PayPending'>
            <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.CheckGreenIcon.default}></img>
            <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#1E972A" }}>{t("Approved")}</Typography>
          </div></Tooltip>

      case "Approved":
        return <Tooltip placement='top' title={t("Approved")} arrow>
          <div className='eMed_OT_APtTbl_PayPending'>
            <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.CheckGreenIcon.default}></img>
            <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#1E972A" }}>{t("Approved")}</Typography>
          </div></Tooltip>

      case "Cancelled":
        return <Tooltip placement='top' title={data?.counsellor_notes} arrow>
          <div className='eMed_OT_APtTbl_PayPending'>
            <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.CancelIcon.default}></img>
            <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#B8170D" }}>{t("Cancelled")}</Typography>
          </div></Tooltip>

      case "Draft":
        return <Tooltip placement='top' title={t("Draft")} arrow>
          <div className='eMed_OT_APtTbl_PayPending'>
            <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.Draft.default}></img>
            <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#DE7D24" }}>{t("Draft")}</Typography>
          </div></Tooltip>

      default:
        return <Tooltip placement='top' title={t("Booked")} arrow>
          <div className='eMed_OT_APtTbl_PayPending'>
            <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.BookedPatient.default}></img>
            <Typography id='eMed_OT_AptTbl_PayText'>{t("Booked")}</Typography>
          </div></Tooltip>
    }
  }

  routeToDemograhics = (params) => {
    let { history } = this.props;
    setCachevalue(JSON.stringify(params?.row), "DoctorPatientData")
    if (
      params.field === "PatientDetails" || params.field === "speciality" || params.field === "surgery_name" || params.field === "doctor_name" || params.field === "transmitted_date"
      || params.field === "status" || params.field === "iol_power"
    ) {
      history.push({ pathname: "/Counsellor/PatientDetails/DoctorVisitReport",state:{fromCounsellor:true} })
    }
  }

  renderAptTable = () => {
    const { t } = this.props;
    let states = this.state;
    this.state.AppointmentList.forEach((element, index) => element.sno = index + 1)
    const columns = [
      { field: 'PatientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.20, renderCell: ({ row }) => (<CommonPatientDetails data={row} showAdmission={false} />), density: 'compact' },
      {
        field: 'speciality', headerName: t('Speciality'), headerClassName: 'eMed_Apt_TblHead', flex: 0.13, density: 'compact',
        renderCell: ({ row }) => (<div>{row?.speciality?.length > 20 ? <Tooltip placement="top" title={row?.speciality} arrow><div>{row?.speciality.slice(0, 18) + "..."}</div></Tooltip> : row.speciality ? row.speciality : "-"}</div>)
      },
      {
        field: 'surgery_name', headerName: t('Surgery'), headerClassName: 'eMed_Apt_TblHead', flex: 0.14, density: 'compact',
        renderCell: ({ row }) => (<div>{row?.surgery_name?.length > 20 ? <Tooltip placement="top" title={row?.surgery_name} arrow><div>{row?.surgery_name.slice(0, 18) + "..."}</div></Tooltip> : row.surgery_name ? row.surgery_name : "-"}</div>)
      },
      {
        field: 'doctor_name', headerName: t('Referred Doctor'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact',
        renderCell: ({ row }) => (<div>{row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}</div>)
      },
      {
        field: 'transmitted_date', headerName: t('Transmitted Date'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, density: 'compact', headerAlign: 'center', align: "center",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.transmitted_date ? formatDate(row?.transmitted_date) : "-"}</Box>)
      },
      {
        field: 'status', headerName: t('Status'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, density: 'compact', headerAlign: 'center', align: "center",
        renderCell: ({ row }) => (<div> {this.returnStatus(row?.status,row)} </div>)
      },
      {
        field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.10, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
        renderCell: (params) => (<div>{this.ActionBtn(params)}</div>)
      }
    ];
    const OPT_columns = [
      { field: 'patient_name', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.20, renderCell: ({ row }) => (<CommonPatientDetails data={row} showAdmission={false} />), density: 'compact' },
      {
        field: 'speciality', headerName: t('Speciality'), headerClassName: 'eMed_Apt_TblHead', flex: 0.13, density: 'compact',
        renderCell: ({ row }) => (<div>{row?.speciality?.length > 20 ? <Tooltip placement="top" title={row?.speciality} arrow><div>{row?.speciality.slice(0, 18) + "..."}</div></Tooltip> : row.speciality ? row.speciality : "-"}</div>)
      },
      {
        field: 'surgery_name', headerName: t('Surgery'), headerClassName: 'eMed_Apt_TblHead', flex: 0.14, density: 'compact',
        renderCell: ({ row }) => (<div>{row?.surgery_name?.length > 20 ? <Tooltip placement="top" title={row?.surgery_name} arrow><div>{row?.surgery_name.slice(0, 18) + "..."}</div></Tooltip> : row.surgery_name ? row.surgery_name : "-"}</div>)
      },
      {
        field: 'iol_power', headerName: t('Surgery Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.09, density: 'compact', headerAlign: 'center', align: "center",
        renderCell: ({ row }) => (<div>{row?.power?.length > 20 ? <Tooltip placement="top" title={row?.iol_power} arrow><div>{row?.iol_power.slice(0, 18) + "..."}</div></Tooltip> : row.iol_power ? row.iol_power : "-"}</div>)
      },
      {
        field: 'doctor_name', headerName: t('Referred Doctor'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact',
        renderCell: ({ row }) => (<div>{row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}</div>)
      },
      {
        field: 'transmitted_date', headerName: t('Transmitted Date'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, density: 'compact', headerAlign: 'center', align: "center",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.transmitted_date ? formatDate(row?.transmitted_date) : "-"}</Box>)
      },
      {
        field: 'status', headerName: t('Status'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, density: 'compact', headerAlign: 'center', align: "center",
        renderCell: ({ row }) => (<div> {this.returnStatus(row?.status,row)} </div>)
      },
      {
        field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.10, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
        renderCell: (params) => (<div>{this.ActionBtn(params)}</div>)
      }
    ];
    return (
      <div>
        <Box className='eMed_IPAptTbl_Con'>
          <DataGrid
            rows={states.AppointmentList}
            columns={this.state.isOPT ? OPT_columns : columns}
            getRowId={(row) => row.sno}
            pageSize={this.state.pageSize}
            page={this.state.page}
            rowsPerPageOptions={[10, 20, 30]}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            density={'compact'}
            rowHeight={100}
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
            disableSelectionOnClick
            onCellClick={(params) => this.routeToDemograhics(params)}
          />
        </Box>
      </div>
    )
  }


  render() {
    const { t } = this.props;
    return (
      <Box sx={{ backgroundColor: Colors.ComponentBgColor }} className='eMed_OT_Main_Container'>
        <Box className="eMed_OT_page_header">
          <div className='eMed_Price_Card_Icon'>
            <p>{t('Appointments')}</p>
          </div>
          <Box className='eMed_IP_AptDateFlt'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              Future={true}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
        </Box>
        <Box>
          {this.renderPatientType()}
          {this.renderAptTable()}
        </Box>
        {this.state.selectedOption === "PackageDetail" ? <PackageDeatailPop
          PatientDetails={this.state.SelectedPatientDetails}
          ClinicID={this.state.ClinicID}
          ErrorMsg={this.ErrorToast.bind(this)}
          SuccessMsg={this.SuccessToast.bind(this)}
          handleClose={this.closePopup.bind(this)} /> : null}
        {this.state.selectedOption === "CancelPatient" ? <ReasonPopup
          title={"Are you sure? Do you want to cancel the Bill"}
          AlertPopupClose={this.closePopup.bind(this)}
          label={"Enter the Cancel Reason"}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          btnvarient1={'contained'}
          sendCmt={this.addCancelComments.bind(this)} /> : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(CounsellorHome)

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Box, TextField, InputAdornment, Typography } from '@mui/material';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import VoiceToText from '../../../../../../Components/Common Components/VoiceToText';
import MicIcon from '@mui/icons-material/Mic';
import { grey } from '@mui/material/colors';
import { ImagePaths } from "../../../../../../Utility/ImagePaths";
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation';
import { localGetItem } from '../../../../../../Utility/Services/CacheProviderService';

const change_data_obj = {
    "Discharge notes": 'discharge_notes',
    "Family Counselling": 'family_counselling',
    "Medication & Instructions": 'instructions',
    "Doctor Notes": 'write_notes',
};

class WriteNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notesTxt: this.props.doctorTxt,
            notesData: this.props.doctorsData,
            IpPatientData: this.props?.IpPatientData,
            withOutAppointment: this.props?.withOutAppointment,
            listenPopUp: false,
            clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
        }
    }

    componentDidUpdate(prevprops) {
        if((JSON.stringify(prevprops.doctorTxt) !== JSON.stringify(this.props.doctorTxt)) && this.props.doctorTxt){
            this.setState({notesTxt: this.props.doctorTxt});
        }

        if((JSON.stringify(prevprops.doctorsData) !== JSON.stringify(this.props.doctorsData)) && this.props.doctorsData){
            this.setState({notesData: this.props.doctorsData});
        }
        
        // Clear notesTxt when isClearClked is true
        if (prevprops.isClearClked !== this.props.isClearClked && this.props.isClearClked) {
            let updatedNotes = {...this.state.notesTxt};
            updatedNotes.notes = "";
            this.setState({ notesTxt: { ...updatedNotes } },
                () => { this.props.onSaveComplete(); });
        }
        // Call save when isSaveClked is true
        if (prevprops.isSaveClked !== this.props.isSaveClked && this.props.isSaveClked) {
            this.Validation();
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    postNotes = () => {
        try {
            const { notesTxt, clinic_id } = this.state;
            const { IpPatientData, label, CallgetApi } = this.props;
            const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
            var data = {
                "id": notesTxt?.id,
                "doctor_id": notesTxt?.doctor_id || myUser?.doctor_id || IpPatientData?.doctor_id,
                "clinic_id": clinic_id,
                "ip_admission_id": IpPatientData?.id,
                "change_data": change_data_obj[label],
                "notes": CommonValidation.removeSpace(notesTxt.notes)
            }
            RestAPIService.create(data, Serviceurls.IP_DOC_WRITE_NOTES).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message);
                        CallgetApi();
                    } else {
                        this.errorMessage(response.data.message);
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                }).finally(() => {
                    this.props.onSaveComplete();
                });
        } catch (error) {
            this.errorMessage(error.message);
        } 
    }

    Validation = () => {
        var states = this.state
        if (CommonValidation.removeSpace(states.notesTxt.notes) !== "") {
            this.postNotes()
        } else {
            this.errorMessage(`Enter ${this.props.label}`)
            this.props.onSaveComplete();
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    listenPopUp = () => {
        this.setState({
            listenPopUp: false
        })
    }

    voiceToTextgetter = (textStorage) => {
        if (textStorage !== '') {
            let updatedNotes = { ...this.state.notesTxt };
            const newValue = updatedNotes?.notes?.length > 0 ?
                updatedNotes?.notes + " " + textStorage : textStorage;
            const finalValue = newValue?.length > 250 ? newValue.substring(0, 250) : newValue;
            updatedNotes.notes = finalValue;
            this.setState({
                notesTxt: { ...updatedNotes }
            })    
        } else {
            this.setState({ listenPopUp: false });
        }
    }

    renderVoiceTexbox = () => {
        const { t } = this.props
        return (
            <TextField
                sx={{ width: '100%', marginTop: '1vw' }}
                multiline
                // rows={this.props.rowlength ? this.props.rowlength : 3}
                value={this.state.notesTxt.notes ? this.state.notesTxt.notes : ""}
                label={t(this.props.placeholder)}
                inputProps={{ maxLength: 2000, emed_tid: this.props.placeholder }}
                variant="outlined"
                onChange={(event) => {
                    let updatedNotes = {...this.state.notesTxt};
                    const newValue = event.target.value;
                    // if ((newValue?.length <= 250) || newValue === "") {
                        updatedNotes.notes = newValue;
                        this.setState({
                            notesTxt: {...updatedNotes}
                        })
                    // }
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <MicIcon sx={{ color: grey[1000], cursor: "pointer" }}
                            onClick={() => { this.setState({ listenPopUp: true }) }} />
                    </InputAdornment>
                }}
            />)
    }

    render() {
        const { t } = this.props;
        return (
            <Box className="eMed_WrtNotes_Cont">
                {this.renderVoiceTexbox()}
                <div style={{ margin: "10px 0px" }}>
                    {
                        this.state.notesData?.length > 0 ? this.state.notesData.map((item, index) => {
                            return (
                                <Box height={"100%"} key={index} marginY={"10px"}>
                                    <Box className="eMed_WrtNotes_docDiv">
                                        <Box component={'div'} className='eMed_patient_image'>
                                            <img className='eMed_WrtNotes_docImg'
                                                src={item?.doctor__photo_url ? item.doctor__photo_url : ImagePaths.Male.default}
                                                alt="male" />
                                        </Box>
                                        <Box component={'div'} className="eMed_Pateint_Detail_LenthData">
                                            <Box component={'div'} className='eMed_patient_txt'>
                                                <Typography fontSize={"0.95dvw"} fontWeight={600}>{item?.doctor_name || "-"}</Typography>
                                                <Typography fontSize={"0.8dvw"} >{item?.doctor_speciality || "-"}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography fontSize={"0.85dvw"} marginY={"10px"}>{item?.notes || "-"}</Typography>
                                    {item?.date ?
                                        <Typography fontSize={"0.8dvw"} marginTop={"10px"}>{item?.date || "-"}</Typography>
                                        : null}
                                </Box>
                            )
                        }) : null
                    }
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.listenPopUp ?
                    <VoiceToText
                        voiceTextConverter={this.voiceToTextgetter.bind(this)}
                        listenPopUp={this.listenPopUp.bind(this)}
                    /> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(WriteNotes)
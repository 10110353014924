import { Box, Button, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, CommonPatientCard, CommonPatientDetails, CommonPopUp, ConsultantPatientCard, DeletePopup } from '../../../../../Components/Common Components/CommonComponents'
import MUIRichTextEditor from 'mui-rte'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors } from '../../../../../Styles/Colors'
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'
import Loader from '../../../../../Components/Loader';
import MicIcon from '@mui/icons-material/Mic';
import { grey } from '@mui/material/colors';
import VoiceToText from '../../../../../Components/Common Components/VoiceToText';
import { ImagePaths } from '../../../../../Utility/ImagePaths';

class IpNurseNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nurseNotesText: "",
      patientData: {},
      nurseText: "",
      text: "",
      clinicalNurseNotesData: [],
      delSelectedId: '',
      selectedEditId: "",
      delSelectedId: "",
      deletePopup: false,
      nurseNotesHistory: [],
      isHistoryClicked: false,
      postCall: false,
      withOutAppointment: {},
      listenPopUp : false,
      clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  componentDidMount() {
    let patientDatas = getCachevalue("IppatientData")
    let patients = JSON.parse(patientDatas)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    if (details?.patient_id === patients?.patient_id) {
      this.setState({
        withOutAppointment: details
      }, () => {
        this.getNurseNotesPatientData()
        this.getNurseNotesData()
      })
    }
    this.setState({
      patientData: patients
    }, () => {
      this.getNurseNotesPatientData()
      this.getNurseNotesData()
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  onClearHandler = () => {
    let clear = `{"blocks":[{"key":"1cu3s","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`
    this.setState((prevState) => ({
      nurseNotesText: clear,
      isEdit: this.props.is_From_IpNotes ? false : prevState.isEdit,
      selectedEditId: this.props.is_From_IpNotes ? "" : prevState.selectedEditId,
    }));
  }

  getNurseNotesPatientData = () => {
    let admission_id =   this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
    let url = `${Serviceurls.FO_PATIENT_DETAILS}?admission_id=${admission_id}`;
    if (this.props.is_From_IpNotes && this.state.clinic_id) {
      url = `${url}&clinic_id=${this.state.clinic_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              nurse_attender_name: response.data.data[0].attender_name,
              nurse_attender_mobile: response.data.data[0].attender_mobile,
              nurse_doctor_name: response.data.data[0].doctor_name,
              nurse_doctor_id: response.data.data[0].doctor_id,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getNurseNotesHistoryData = (id) => {
    try {
      let url = Serviceurls.FO_IP_HISTORY + `?condition=notesnursenotes&row_id=${id}`;
      if (this.state.clinic_id) {
          url = `${url}&clinic_id=${this.state.clinic_id}`
      }
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              nurseNotesHistory: response.data.data,
              isHistoryClicked: true
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getNurseNotesData = () => {
    let admission_id =   this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"));
    let url = `${Serviceurls.FO_IP_CLINICAL_NURSE_NOTES}?ip_admission_id=${admission_id}`;
    if (this.props.is_From_IpNotes && UserData?.doctor_id) {
      url = `${url}&doctor_id=${UserData?.doctor_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              clinicalNurseNotesData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postIpNurseNotesData = () => {
    try {
      let data = {
        "ip_admission_id": this.state.patientData?.id,
        "nurse_notes": this.state.nurseNotesText
      }
      if (this.state.isEdit) {
        data["notes_id"] = this.state.selectedEditId
      }
      let nurseNotesText = JSON.parse(data.nurse_notes)
      if (nurseNotesText.blocks[0].text === "") {
        this.errorMessage("Nurse Notes is Mandatory")
      } else {
        this.LoaderFunction(true)
        RestAPIService.create(data, Serviceurls.FO_IP_CLINICAL_NURSE_NOTES)
          .then((response) => {
            if (response.data.status === 'success') {
              this.onClearHandler()
              this.getNurseNotesData()
              this.setState({ isEdit: false, postCall: false })
              this.LoaderFunction(false)
              this.successMessage(response.data.message)
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.LoaderFunction(false)
              this.errorMessage(error.response.data.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (params) => {
    try {
      this.setState({
        isEdit: true,
        nurseNotesText: params?.row?.nurse_notes,
        selectedEditId: params?.row?.id
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    this.setState({ deletePopup: false })
  }

  onDeleteHandler = (value) => {
    if (!this.state.isEdit) {
      this.setState({
        deletePopup: true,
        rowValue: value?.row,
        delSelectedId: value?.row?.id
      })
    }
  }

  deleteNurseNotesData = () => {
    try {
      RestAPIService.delete(Serviceurls.FO_IP_CLINICAL_NURSE_NOTES + '?notes_id=' + this.state.delSelectedId)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ deletePopup: false })
            this.getNurseNotesData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  MenuItem = (data) => {
    const { t } = this.props
    const list = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <Button
           disabled = {this.state.withOutAppointment?.is_ip}
            className='eMed_vital_more'
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </Button>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => { this.getNurseNotesHistoryData(data?.row?.id) }) }}>
              {t("History")}
            </MenuItem>
          </Menu> : null}
      </div>
    )
  }

  getNurseHistory = () => {
    const { t } = this.props
    this.state.nurseNotesHistory.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "modified_by_to", headerName: "Modified By", flex: 0.33,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by_to ? params?.row?.modified_by_to : '-'}</Box>)
      },
      {
        field: "modified_date", headerName: "Modified Date And Time", flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date : '-'}</Box>)
      },
      {
        field: "nurse_notes_from", headerName: "Previous Nurse Notes", flex: 0.33,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.nurse_notes_from ? (JSON.parse(params?.row?.nurse_notes_from).blocks[0].text.length > 20 ?
            <Tooltip placement='top' title={JSON.parse(params?.row?.nurse_notes_from).blocks[0].text} arrow>
              <div>{JSON.parse(params?.row?.nurse_notes_from).blocks[0].text.slice(0, 20) + '...'}</div></Tooltip>
            : JSON.parse(params?.row?.nurse_notes_from).blocks[0].text) : "-"}</Box>)
      }
    ]
    return (
      <Box component={'div'} className='eMed_history_nurse_grid'>
        <DataGrid
          rows={this.state.nurseNotesHistory}
          columns={columns}
          getRowId={(row) => row.sno}
          density='compact'
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Box>
            )
          }}
        />
      </Box>
    )
  }

  renderLeftContent = () => {
    const { t } = this.props
    const columns = [
      {
        field: "date_time", headerName: "Date & Time", flex: 0.23, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.date_time ? params?.row?.date_time.length > 20 ?
          <Tooltip title={params?.row?.date_time} placement='top' arrow><div>{params?.row?.date_time.slice(0, 20) + '...'}</div></Tooltip> : params?.row?.date_time : "-"}</Box>)
      },
      {
        field: "nurse_notes", headerName: "Nurse Notes", flex: 0.37,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.nurse_notes ? (JSON.parse(params?.row?.nurse_notes).blocks[0].text.length > 35 ?
            <Tooltip placement='top' title={JSON.parse(params?.row?.nurse_notes).blocks[0].text} arrow>
              <div>{JSON.parse(params?.row?.nurse_notes).blocks[0].text.slice(0, 35) + '...'}</div></Tooltip>
            : JSON.parse(params?.row?.nurse_notes).blocks[0].text) : "-"}</Box>)
      },
      {
        field: "modified_by", headerName: "Modified By", flex: 0.2,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by ? params?.row?.modified_by : '-'}</Box>)
      },
      {
        field: "action", headerName: "Action", flex: 0.2, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'center'} paddingRight={'0.5vw'}>
          {this.props.is_From_IpNotes ?
            <Tooltip title="View" placement="top" arrow>
              <Button emed_tid={this.props.testID} className="eMed_Edit_btn eMed_usrconf_btn" size='small'>
                <img src={ImagePaths.LabViewButton.default} style={{ height: '1.5dvw', width: '1.5dvw' }} alt='View'
                  onClick={() => { this.onEditHandler(params) }} />
              </Button>
            </Tooltip> :
            <CommonEditButton disable={this.state.withOutAppointment?.is_ip} size='1.5vw' onClick={this.onEditHandler.bind(this, params)} />}
          {this.props.is_From_IpNotes ? null : <CommonDeleteButton disable={this.state.withOutAppointment?.is_ip} size='1.5vw' onClick={this.onDeleteHandler.bind(this, params)} />}
          {this.props.is_From_IpNotes ? null : this.MenuItem(params)}
        </Box>)
      }
    ]
    return (
      <Box component={'div'} className='eMed_nur_notes_left_card'>
        <DataGrid
          className='eMed_nur_notes_grid'
          rows={this.state.clinicalNurseNotesData}
          columns={columns}
          getRowId={(row) => row?.id}
          density='compact'
          components={{
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Box>
            )
          }}
          disableSelectionOnClick
          hideFooter
        />
      </Box>
    )
  }

  historyPopUpClose() {
    this.setState({
      isHistoryClicked: false,
      nurseNotesHistory: []
    })
  }
  
  voiceToTextgetter = (textStorage)=>{
    if( textStorage != ""){
      let states = this.state
      if(states.nurseNotesText?.length){
        let data = JSON.parse(states.nurseNotesText)
       states.nurseNotesText = `{"blocks":[{"key":"1cu3s","text":"${data.blocks[0].text + " " + textStorage}" ,"type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`
        this.setState({
          states
         })
      }
      else{
        states.nurseNotesText = `{"blocks":[{"key":"1cu3s","text":"${textStorage}" ,"type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`
        this.setState({
          states
        })
      }
    }
    this.setState({
      listenPopUp : false,
    })
}
    
  renderRightContent = () => {
    const { t } = this.props
    return (
      <Box component={'div'} className='eMed_nur_notes_right_card'>
        <Box component={'div'} className='eMed_nur_notes_title' sx={{display:'flex',justifyContent:"space-between"}}>
          <Typography fontWeight={600} color = {Colors.SecondaryText}>Nurse Notes</Typography>
          <MicIcon sx={{ color: grey[600] ,cursor:"pointer"}}  onClick={()=>{this.setState({listenPopUp:true}) }}></MicIcon>
        </Box>
        <Box component={'div'} className='eMed_nur_notes_content'>
          <MUIRichTextEditor
            controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "numberList", "bulletList", "quote", "clear", "save"]}
            label={`Type ${t("RestraintConsent")} Here...`}
            inlineToolbar={true}
            defaultValue={this.state.nurseNotesText}
            
            onSave={(data) => this.setState({ nurseNotesText: data, postCall: true })}
          />  
        </Box>
        <Box component={'div'} className='eMed_nur_notes_btn_card eMed_nur_notes_btn'>
            <Button
              size='small'
              variant='outlined'
              disabled = {this.state.withOutAppointment?.is_ip}
              sx={{ marginRight: "0.5vw", textTransform: "capitalize" }}
              onClick={() => { this.onClearHandler() }}
            >{t("Clear")}</Button>
            {this.props.is_From_IpNotes ? null :
            <Button
              size='small'
              variant='contained'
              sx={{ marginLeft: "0.5vw", textTransform: "capitalize" }}
              disabled={ CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Nurse Notes", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true}
              onClick={() => { this.state.postCall ? this.postIpNurseNotesData() : this.errorMessage("Please Click the Save Button on the text Editor") }}
            >{t("Add")}</Button>}
        </Box>
      </Box>
    )
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  render() {
    const { t } = this.props
    let attenderDetails = {
      lineOne: t("AttenderName"),
      lineTwo: `${this.state.nurse_attender_name ? this.state.nurse_attender_name : " -"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${this.state.nurse_attender_mobile ? this.state.nurse_attender_mobile : "-"}`
    }
    let doctorDetails = {
      lineOne: t("ConsultantName"),
      lineTwo: `${this.state.nurse_doctor_name ? this.state.nurse_doctor_name : " -"}`
    }
    return (
      <Box component={'div'} className='eMed_nur_note_container'>
        <Box display={'flex'} >
          <Box className='eMed_nur_notes_details eMed_patient_nur_notes'>
            <CommonPatientDetails data={this.state.patientData} showDetailed={true} />
          </Box>
          <CommonPatientCard details={attenderDetails} showDetailed={true} />
          <ConsultantPatientCard name={doctorDetails} width = {this.props.is_From_IpNotes ? "20dvw" : null}/>
        </Box>
        <Box component={'div'} className='eMed_nur_note_card'>
          <Box component={'div'} className='eMed_nur_note_left'>
            {this.renderLeftContent()}
          </Box>
          <Box component={'div'} className='eMed_nur_note_right'>
            {this.renderRightContent()}
          </Box>
        </Box>
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`${t("deleteMsg")} ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteNurseNotesData.bind(this)}
        /> : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {(this.state.isHistoryClicked) ?
          <CommonPopUp width={"56vw"}
            backgroundColor={Colors.white}
            popUpClose={this.historyPopUpClose.bind(this)}
            title={t("History")}
            component={this.getNurseHistory.bind(this, this.state.nurseNotesHistory)} />
          : null
        }
        <Loader loaderOpen={this.state.isLoader} />
        {(this.state.listenPopUp)?
        <VoiceToText
        voiceTextConverter  = {this.voiceToTextgetter.bind(this)}
        listenPopUp={this.state.listenPopUp}
        />
        :null}
      </Box>
    )
  }
}

export default withTranslation()(IpNurseNotes)

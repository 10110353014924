const setCachevalue = (value, key) => {
    sessionStorage.setItem(key,value);
}
const getCachevalue = (key) => {
    let value = sessionStorage.getItem(key);
    return value;
}
const clearCachevalue = (key) => {
    sessionStorage.removeItem(key);
}
const clearAllCachevalue = () => {
    sessionStorage.clear();
}
const localSetItem = (key, value) =>{
    return localStorage.setItem(key, value);
}
const localGetItem = (key) =>{
    return localStorage.getItem(key);
}
const localClearItem = (key) => {
    return localStorage.removeItem(key);
}
const localClearAll = () =>{
    return localStorage.clear();
}
export { setCachevalue, getCachevalue, clearCachevalue, clearAllCachevalue,localSetItem,localGetItem,localClearItem,localClearAll }
import React, { Component } from 'react'
import SubLocbilling from './SubLocbilling'

export default class SubLocGenericConsp extends Component {
  render() {
    return (
        <SubLocbilling isForGenericConsumpsion={true} history={this.props.history}/>
    )
  }
}

import React, { Component } from 'react'
import '../IpDemograhics.css';
import { Box, Grid, Paper, Stack, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { withTranslation } from 'react-i18next';
import { CommonPatientDetails, CommonPatientCard } from "../../../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../../../Styles/Colors";
import { getCachevalue } from "../../../../../Utility/Services/CacheProviderService";
import { t } from "i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DischargeSummary from "../../../IPPatients/PatientsDetails/DischargeSummary";
import SurgeryNotes from "../../../IPPatients/PatientsDetails/SurgeryNotes";

class BillingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientData: {},
      attenderData: {},
      ip_id: {},
    }
  }

  componentDidMount() {
    let patientDetails = getCachevalue("IppatientData");
    let patients = JSON.parse(patientDetails);
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    this.setState({
      patientData: patients,
      ip_id: details
    })
  }

  render() {
    const { t } = this.props;
    let states = this.state;

    const summaryList = [
      { id: 1, label: t("DischargeSummary"), data: <DischargeSummary fromBillSummary = {true} ip_admission_id = {this.state?.ip_id?.ip_admission_id}/> },
      { id: 2, label: t("SurgeryNotes"), data: < SurgeryNotes fromBillSummary = {true} ip_admission_id = {this.state?.ip_id?.ip_admission_id}/> },
    ];

    let data = {
      lineOne: t("Emergency Contact"),
      lineTwo: `${states.ip_id?.attender_name ? states.ip_id?.attender_name : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${states.ip_id?.attender_mobile ? states.ip_id?.attender_mobile : "-"}`
    }

    return (
      <Box id="eMed_AdHistory_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container className="eMed_AdHistory_Box" >
          <Stack className="eMed_AdHistory_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
            <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
              <CommonPatientDetails data={this.state.patientData} />
            </Stack>
            <CommonPatientCard details={data} showDetailed={true} />
          </Stack>
          <Box className="eMed_AdHistory_Div" sx={{ backgroundColor: Colors.configHomeBg, marginTop: "0.625vw" }}>
          {
              summaryList.length > 0 && summaryList.map(list => (
                <Accordion id="eMed_AdHistory_Accord" key={list.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography id="eMed_AdHistory_AccordTxt">{list.label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails id="eMed_AdHistory_AccordDetails">
                    <Box sx={{width: "100%"}}>
                    {list.data}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
            }
          </Box>
        </Grid>
      </Box>
    )
  }
}
export default withTranslation()(BillingSummary)

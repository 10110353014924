import React, { Component } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Drawer, IconButton, Paper, Tooltip, Button, Menu, MenuItem, Modal, Typography, TextField, FormControl, InputLabel, Select, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { CommonGridToolBarWithFilterText, CommonPopUp } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CheckAccessFunc, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import SubLocReportFilter from "./SubLocReportFilter";
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

const Expiry_Period = [
  { label: "1 Months", value: 1 },
  { label: "2 Months", value: 2 },
  { label: "3 Months", value: 3 },
  { label: "4 Months", value: 4 },
  { label: "5 Months", value: 5 },
  { label: "6 Months", value: 6 },
]

class SubLocStockList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DataList: [],
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        "brandName": ''
      },
      anchorEl: null,
      selectedRow: null,
      isSelected: false,
      isHistoryClicked: false,
      historyDataList: [],
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      pageName: 'SubStockList',
      openStockEditPopup: false,
      editStockData: null,
      duplicateStockData: '[]',
      radioReason: null,
      reason:''
    }
  }

  componentDidMount() {
    this.getSubLocStockList();
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
      this.getSubLocStockList()
    })
  }

  getSubLocStockList = () => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_STOCK_LIST_REPORT_GET + `?brand_name=${this.state.filterDatas.brandName}`)
        .then((response) => {
          if (response.data.status === "success") {
            let list = response.data?.data?.length > 0 ? response.data.data : [];
            list.length > 0 && list?.forEach((item, i) => {
              item["sno"] = i + 1;
            })
            this.setState({
              DataList: list
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getStockListHistoryData = (item) => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_STOCK_LIST_HISTORY_GET + `?list_id=${item?.id}&drug_ms_id=${item?.drug_ms_id}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              historyDataList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: this.state.filterDatas.fromDate },
      { label: "To Date", value: this.state.filterDatas.toDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  handleTableCheckbox = (id) => {
    let states = this.state;
    let selectedIDs = new Set(id);
    let selectedRows = states["DataList"].filter((row) => selectedIDs.has(row.sno));
    let FinalData = []
    selectedRows.forEach((item) => {
      FinalData.push({})
    })
    this.setState({ SelectedDrugList: FinalData })
  }

  MenuList = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "ReturnStock", label: t("ReturnStock") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            emed_tid="subLoc_stklstrpt_morevert"
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.stock_id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow === data.stock_id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem emed_tid={`subLoc_stklstrpt_${option.label}`} key={option} onClick={() => {
                this.setState({ anchorEl: null, selectedOption: option.value })
              }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  // history pop up start 
  stockHistoryClose = () => {
    this.setState({
      isHistoryClicked: false,
      historyDataList: [],
    })
  }

  _showStockHistory = () => {
    const { t } = this.props;
    let { historyDataList } = this.state;
    historyDataList?.forEach((element, index) => { element.sno = index + 1 })
    const historyColumn = [
      {
        field: 'created_by', headerName: t('ModifiedBy'), width: 350, sortable: false, hideable: false, renderCell: ({ row }) => {
          return (<div>{row?.created_by?.length > 20 ? <Tooltip placement="top" title={row?.created_by} arrow><div>{row?.created_by.slice(0, 15) + "..."}</div></Tooltip> : row?.created_by ? row?.created_by : "-"}</div>)
        }
      },
      {
        field: 'modified_date', headerName: t("ModifiedDateAndTime"), sortable: false, hideable: false, width: 250, renderCell: ({ row }) => (
          <Box component={'div'}>{row?.modified_date ? row?.modified_date : '-'}</Box>
        )
      },
      {
        field: 'previous_reorder_threshold', headerName: t('previousReorderThreshold'), width: 250, type: "number", renderCell: ({ row }) => (
          <div>{row?.previous_reorder_threshold ? row?.previous_reorder_threshold : "-"}</div>)
      },
      {
        field: 'physical_box_no', headerName: t('previousPhysicalBoxNo'), sortable: false, hideable: false, width: 250, renderCell: ({ row }) => (
          <div>{row?.physical_box_no ? row?.physical_box_no : "-"}</div>)
      },
      {
        field: 'previous_expiry_alert_period', headerName: t('previousExpiryAlertPeriod'), sortable: false, hideable: false, width: 250, renderCell: ({ row }) => (
          <div>{row?.previous_expiry_alert_period ? row?.previous_expiry_alert_period : "-"}</div>)
      },
      {
        field: 'adjusted_quantity', headerName: t('Current Quantity'), width: 250, sortable: false, hideable: false, renderCell: ({ row }) => (
          <div>{row?.adjusted_quantity?.length > 20 ? <Tooltip placement="top" title={row?.adjusted_quantity} arrow><div>{row?.adjusted_quantity.slice(0, 15) + "..."}</div></Tooltip> : row?.adjusted_quantity ? row?.adjusted_quantity : "-"}</div>
        )
      },
      {
        field: 'previous_quantity', headerName: t('previousQuantity'), width: 250, sortable: false, hideable: false, renderCell: ({ row }) => (
          <div>{row?.previous_quantity?.length > 20 ? <Tooltip placement="top" title={row?.previous_quantity} arrow><div>{row?.previous_quantity.slice(0, 15) + "..."}</div></Tooltip> : row?.previous_quantity ? row?.previous_quantity : "-"}</div>
        )
      },
      {
        field: 'reason', headerName: t('previousReason'), width: 250, sortable: false, hideable: false, renderCell: ({ row }) => (
          <div>{row?.reason?.length > 20 ?
            <Tooltip placement="top" title={row?.reason} arrow><div>{row?.reason.slice(0, 15) + "..."}</div></Tooltip>
            : row?.reason ? row?.reason : "-"}</div>)
      },
      {
        field: 'comments', headerName: t('previousComments'), width: 250, sortable: false, hideable: false, renderCell: ({ row }) => (
          <div>{row?.comments?.length > 20 ?
            <Tooltip placement="top" title={row?.comments} arrow><div>{row?.comments.slice(0, 15) + "..."}</div></Tooltip>
            : row?.comments ? row?.comments : "-"}</div>)
      },
    ];
    return (
      <Box id="eMed_pharma_settings_stkTbl">
        <DataGrid
          rows={historyDataList}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
        />
      </Box>
    )
  }
  // history poop up end

  updateStockListData = () => {
    try {
      let states = this.state
      let data = {
        "list_id": +states.editStockData?.id,
        "reason": +states.radioReason,
        "comments": states.reason,
        'adjusted_quantity': states.editStockData?.stock_in_quantity,
        'reorder_threshold': +states.editStockData?.reorder_threshold,
        'expiry_alert_period': states.editStockData?.expiry_alert_period,
        'physical_box_no': states.editStockData?.box_no,
        'adjusted_min_hold_quantity':+states.editStockData?.min_hold_quantity
      }
      if (states.radioReason === null && states.reason === '') {
        this.errorMessage("Enter Reason and Comments")
      } else {
        RestAPIService.updateWithOutId(data, Serviceurls.SUBLOC_STOCK_LIST_REPORT_GET)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({ openStockEditPopup: false })
              this.getSubLocStockList()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHander = (e, names) => {
    try {
      let value = e.target.value
      let states = this.state
      switch (names) {
        case 'minimumHoldQty':
          if(CommonValidation.numberOnly(value) && value.length <= 10 || value === ''){
            states.editStockData.min_hold_quantity = value
            this.setState({ states })
          }
          break;
        case 'reorderThreshold':
          if (CommonValidation.numberOnly(value) && value.length <= 10 || value === '') {
            states.editStockData.reorder_threshold = value
            this.setState({ states })
          }
          break;
        case 'boxNo':
          if (CommonValidation.alphanumValidation(value) && value.length <= 20 || value === '') {
            states.editStockData.box_no = value
            this.setState({ states })
          }
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  editTextBox = (names, values, labels, width, mr, disable) => {
    try {
      return (
        <TextField
          sx={{ width: width, mr: mr, ml: '1vw' }}
          disabled={disable}
          size='small'
          label={labels}
          name={names}
          value={values}
          onChange={(e) => this.onChangeHander(e, names)}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  stockListEditPopup = () => {
    try {
      let states = this.state
      return (
        <Modal open={this.state.openStockEditPopup}>
          <Box className="eMed_Sub_Loc_Rpt_Popup">
            <Box p={'0.5vw 1vw'} display={'flex'} justifyContent={'space-between'}>
              <Typography color={Colors.SecondaryText} fontWeight={600}>Edit Stock Correction</Typography>
              <Button
                onClick={() => {
                  this.setState({
                    openStockEditPopup: false,
                    editStockData: JSON.parse(this.state.duplicateStockData)
                  })
                }}>
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} alt='close' width={'1vw'} height={'1vw'} />
              </Button>
            </Box>
            <Box component={'div'} height={'51vh'}>
              <Box mb={'1vw'}>
                {this.editTextBox("brandName", states.editStockData?.brand_name, "Brand Name", '12vw', null, true)}
                {this.editTextBox("genericName", states.editStockData?.generic_name, "Generic Name", '12vw', null, true)}
                {this.editTextBox("dosageType", states.editStockData?.dosage_type, "Dosage Type", '12vw', null, true)}
                {this.editTextBox("dosageStrength", states.editStockData?.dosage_strength, "Dosage Strength", '12vw', null, true)}
              </Box>
              <Box mb={'1vw'}>
                {this.editTextBox("batchNumber", states.editStockData?.batch_no, "Batch No", '12vw', null, true)}
                {this.editTextBox("schedule", states.editStockData?.schedule, "Schedule", '12vw', null, true)}
                {this.editTextBox("Group", states.editStockData?.group, "Group", '12vw', null, true)}
                {this.editTextBox("minimumHoldQty", states.editStockData?.min_hold_quantity, "Minimum Hold Qty", '12vw', null, false)}
              </Box>
              <Box>
                {this.editTextBox("reorderThreshold", states.editStockData?.reorder_threshold, "Reorder Threshold", '12vw', null, false)}
                {this.editTextBox("boxNo", states.editStockData?.box_no, "Phy. Box No.", '12vw', null, false)}
                <FormControl size='small' sx={{ width: "12vw", ml: '1vw' }}>
                  <InputLabel>Expiry Alert Period</InputLabel>
                  <Select
                    label={'Expiry Alert Period'}
                    value={states.editStockData?.expiry_alert_period}
                    onChange={(e) => {
                      states.editStockData.expiry_alert_period = e.target.value
                      this.setState({ states })
                    }}
                  >
                    {Expiry_Period.map((item, index) => (
                      <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ width: '12vw', ml: '1vw' }}
                  size='small'
                  label="Quantity"
                  inputProps={{ style: { textAlign: 'center' } }}
                  InputProps={{
                    startAdornment: <Button
                      className='Common_Btn_Min_Width' startIcon={<RemoveCircle />}
                      onClick={() => {
                        states.editStockData.stock_in_quantity--
                        this.setState({ states })
                      }}
                    ></Button>,
                    endAdornment: <Button
                      className='Common_Btn_Min_Width' startIcon={<AddCircle />}
                      onClick={() => {
                        states.editStockData.stock_in_quantity++
                        this.setState({ states })
                      }}
                    ></Button>
                  }}
                  value={states.editStockData?.stock_in_quantity}
                  onChange={(e) => this.onChangeHander(e, 'quantity')}
                />
              </Box>
              <Box component={'div'} pr={'1.5vw'} mt={'1vw'}>
                <Typography ml={'1vw'} fontWeight={600} color={Colors.SecondaryText}>Enter Reason</Typography>
                <RadioGroup
                  size='small' sx={{ display: 'flex', flexDirection: 'row', ml: '1vw' }}
                  onChange={(e) => {
                    this.setState({ radioReason: e.target.value })
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label='Breakage' />
                  <FormControlLabel value={2} control={<Radio />} label='Loss' />
                  <FormControlLabel value={3} control={<Radio />} label='Expired' />
                  <FormControlLabel value={6} control={<Radio />} label='Miscellaneous' />
                  <FormControlLabel value={8} control={<Radio />} label='Return to Main Location' />
                  <FormControlLabel value={7} control={<Radio />} label='Others' />
                </RadioGroup>
                <TextField
                  label='Comments'
                  sx={{ ml: '1vw' }}
                  fullWidth
                  multiline
                  rows={3}
                  value={this.state.reason}
                  onChange={(e) => {
                    this.setState({ reason: e.target.value })
                  }}
                />
              </Box>
            </Box>
            <Box component={'div'} height={'5vh'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              <Button
                sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                variant='outlined'
                onClick={() => {
                  this.setState({
                    openStockEditPopup: false,
                    editStockData: JSON.parse(this.state.duplicateStockData)
                  })
                }}
              >Cancel</Button>
              <Button
                sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1.5vw' }}
                variant='contained'
                onClick={() => this.updateStockListData()}
              >Update</Button>
            </Box>
          </Box>
        </Modal>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTable = () => {
    const { t } = this.props
    let states = this.state;
    const columns = [
      {
        field: "id", flex: 0.08, headerName: t("StockID"), headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.stock_id?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.id} arrow><div>{params?.row?.id.slice(0, 16) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.id ? params?.row?.id : "-"}</Box>)
      },
      {
        field: "brand_name", flex: 0.13, headerName: t("BrandName"),
        renderCell: (params) => (params?.row?.brand_name?.length > 20 ?
          <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0, 18) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.brand_name ? params?.row?.brand_name : "-"}</Box>)
      },
      {
        field: "generic_name", flex: 0.12, headerName: t("Generic Name"),
        renderCell: (params) => (params?.row?.generic_name?.length > 20 ?
          <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 18) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.generic_name ? params?.row?.generic_name : "-"}</Box>)
      },
      {
        field: "dosage_type", flex: 0.11, headerName: t("DosageType"),
        renderCell: (params) => (params?.row?.dosage_type?.length > 20 ?
          <Tooltip placement="top" title={params?.row?.dosage_type} arrow><div>{params?.row?.dosage_type.slice(0, 18) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.dosage_type ? params?.row?.dosage_type : "-"}</Box>)
      },
      {
        field: "dosage_strength", flex: 0.11, headerName: t("DosageStrength"), headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.dosage_strength?.length > 20 ?
          <Tooltip placement="top" title={params?.row?.dosage_strength} arrow><div>{params?.row?.dosage_strength.slice(0, 18) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.dosage_strength ? params?.row?.dosage_strength : "-"}</Box>)
      },
      {
        field: "batch_no", flex: 0.09, headerName: t("BatchNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.batch_no?.length > 20 ?
          <Tooltip placement="top" title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no.slice(0, 18) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.batch_no ? params?.row?.batch_no : "-"}</Box>)
      },
      {
        field: "expiry_date", flex: 0.09, headerName: t("ExpiryDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.expiry_date ? (params?.row?.expiry_date) : "-"}</Box>)
      },
      ,
      {
        field: "box_no", flex: 0.06, headerName: t("Box"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.box_no ? params?.row?.box_no : "-"}</Box>)
      },
      {
        field: "stock_in_quantity", flex: 0.08, headerName: t("AvailableQty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity : "-"}</Box>)
      },
      {
        field: "mrp_per_quantity", flex: 0.09, headerName: t("MRPQuantity"), hideable: false, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.mrp_per_quantity ? params?.row?.mrp_per_quantity : "-"}</Box>)
      },
      {
        field: "action", flex: 0.09, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
        renderCell: (params) => {
          
          let Disabled = CheckAccessFunc("sub_location", "Reports", "Stock List",  null, "Tab")?.editAccess === false
          return (
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Tooltip title={t("Edit")} placement="top" arrow>
                <Button emed_tid='subLoc_stklstrpt_edit' className='eMed_usrconf_btn' disabled={Disabled}
                  onClick={() => {
                    this.setState({
                      openStockEditPopup: true,
                      editStockData: JSON.parse(JSON.stringify(params?.row)),
                      duplicateStockData: JSON.stringify(params?.row)
                    })
                  }}>
                  <img src={Disabled ? ImagePaths.DisabledEdit.default :ImagePaths.Edit.default} alt="Return" className='eMed_action_img' />
                </Button>
              </Tooltip>
              <Tooltip title={t("History")} placement="top" arrow>
                <Button
                  emed_tid='subLoc_stklstrpt_hst'
                  className='eMed_usrconf_btn'
                  onClick={() => {
                    this.getStockListHistoryData(params?.row)
                    this.setState({ isHistoryClicked: true })
                  }}>
                  <img src={ImagePaths.stockHistory.default} alt="View" className='eMed_action_img' />
                </Button>
              </Tooltip>
              {/* {this.MenuList(params?.row)} */}
            </Box>
          )
        }
      }
    ]
    return (
      <Box component={'div'}>
        <Paper sx={{ height: '69vh' }}>
          <DataGrid
            rows={states.DataList}
            columns={columns}
            getRowId={(row) => row.sno}
            page={states.page}
            pageSize={states.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => this.setState({ page: newPage })}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
        </Paper>
      </Box>
    )
  }

  render() {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box className='eMed_Purchase_Table_top' marginRight={'0.5vw'}>
          <IconButton emed_tid={"subLoc_stklstrpt_flt"} className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
          </IconButton>
          <IconButton emed_tid={"subLoc_stklstrpt_print"} className='eMed_Pharma_rts_btn' onClick={() => { }}>
            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
          </IconButton>
          {/* <IconButton emed_tid={"subLoc_stklstrpt_export"} className='eMed_Pharma_rts_btn' onClick={() => { }}>
            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
          </IconButton> */}
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          {this.renderTable()}
        </Box>
        {this.stockListEditPopup()}
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <SubLocReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.isHistoryClicked ?
          <CommonPopUp width={"75vw"}
            backgroundColor={Colors.white}
            popUpClose={this.stockHistoryClose.bind(this)}
            title={t("History")}
            component={this._showStockHistory.bind(this)} />
          : null
        }
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(SubLocStockList);


import React, { Component } from 'react';
import "../PharmaSettings.css";
import { Box, Button, Stack, Tooltip, Typography, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CommonGridHeader, { CommonEditButton, CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddandEditSubLocation from './AddandEditSubLocation';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';

class SubLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            addSublocPopup: false,
            selecSubLoc: {},
            isEditSubLoc: false,
            subLocationList: [],
        }
    }

    componentDidMount() {
        this.getSublocationList()
    }

    getSublocationList = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_SUB_LOCATION)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            subLocationList: response.data.data ? response.data.data : []
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    // comment for after use
    // MenuItem = (data) => {
    //     const { t } = this.props
    //     const multi_option = [
    //         { value: "History", label: t("History") },
    //     ]
    //     return (
    //         <div>
    //             <Tooltip title={t("More")} placement='top' arrow>
    //                 <IconButton
    //                     onClick={(e) => {
    //                         this.setState({
    //                             anchorEl1: e.currentTarget,
    //                             selectedRow: data.id,
    //                             isSelected: true
    //                         })
    //                     }}
    //                 >
    //                     <MoreVertIcon />
    //                 </IconButton>
    //             </Tooltip>
    //             {(this.state.isSelected && this.state.selectedRow == data.id) ?
    //                 <Menu
    //                     anchorEl={this.state.anchorEl1}
    //                     open={Boolean(this.state.anchorEl1)}
    //                     onClose={() => {
    //                         this.setState({ anchorEl1: null, })
    //                     }}>
    //                     {multi_option.map((option) => (
    //                         <MenuItem key={option} onClick={() => { this.setState({ anchorEl1: null, isHistoryClicked: true }, () => { this._getHistoryDetails(data.row.id) }) }}>
    //                             {t(option.label)}
    //                         </MenuItem>
    //                     ))}
    //                 </Menu> : null}
    //         </div>
    //     )
    // }
    // comment for after use

    popupClose = () => {
        this.setState({
            addSublocPopup: false,
            isEditSubLoc: false
        }, () => {
            this.getSublocationList()
        })
    }
    renderAddSubLocation = () => {
        return (
            <Modal open={this.state.addSublocPopup}>
                <AddandEditSubLocation subLocation={this.state.selecSubLoc} addPopupClose={this.popupClose.bind(this)} isEdit={this.state.isEditSubLoc} successMsg={this.successMessage.bind(this)} errorMsg={this.errorMessage.bind(this)}/>
            </Modal>
        )
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        states.subLocationList?.forEach((element, index) => { element.sno = index + 1 })
        const columns = [
            {
                field: 'sublocation_type', headerName: t('Type'), flex: 0.10,
                renderCell: (params) => (<Typography>{params?.row?.sublocation_type ? params.row.sublocation_type : "-"}</Typography>)
            },
            {
                field: 'sublocation_name', headerName: t('SubName'), flex: 0.20,
                renderCell: (params) => (
                    params?.row?.sublocation_name.length > 18 ?
                        <Tooltip title={params.row.sublocation_name} placement='top' arrow>
                            <Typography>{params.row.sublocation_name.slice(0, 18) + '...'}</Typography>
                        </Tooltip> : <Typography>{params.row.sublocation_name ? params.row.sublocation_name : "-"}</Typography>)
            },
            {
                field: 'is_allow_bill', headerName: t('PatientBill'), flex: 0.15,
                renderCell: (params) => (<Typography>{params?.row?.is_allow_bill === true ? "Yes" : "No"}</Typography>)
            },
            {
                field: 'is_active', headerName: t('status'), flex: 0.15,
                renderCell: (params) => (params?.row?.is_active === true ? <Typography color={'green'}>{ 'Active'}</Typography> :<Typography color={'red'}>{ 'Inactive'}</Typography> )
            },
            {
                field: 'updated_by', headerName: t('CreatedBy'), flex: 0.30,
                renderCell: (params) => (
                        <Typography>{(params.row.updated_by && params.row.updated_date) ? `${params.row.updated_by + ' On ' + params.row.updated_date}` : '-' }</Typography>)
            },
            {
                field: 'action', headerName: t('Action'), flex: 0.10,
                renderCell: (params) => (
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Tooltip title={"Edit Sub Location"} placement="top" arrow>
                            <IconButton emed_tid="edit_subloc" className='eMed_usrconf_btn' onClick={() => {
                                this.setState({
                                    addSublocPopup: true, selecSubLoc: params?.row, isEditSubLoc: true
                                })
                            }}>
                                <img style={{ width: '1.5vw', height: '1.5vw' }} src={ImagePaths.Edit.default} alt="edit" className='eMed_action_img' />
                            </IconButton>
                        </Tooltip>
                        {/* {this.MenuItem(params)} */}
                    </Box>
                )
            }
        ]

        return (
            <Box component={'div'} className='eMed_rts_container'>
                <div className='eMed_Purchase_Table_top'>
                    <Button variant='contained' sx={{ textTransform: 'capitalize' }} emed_tid="add_subloc" className='eMed_usrconf_btn' onClick={() => { this.setState({ addSublocPopup: true }) }}>
                        {t('AddSubLoc')}
                    </Button>
                    {/* <Tooltip title="Filter" placement='top' arrow>
                        <Button emed_tid="reqDrug_flt" className='eMed_usrconf_btn' onClick={() => { }}>
                            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                        </Button>
                    </Tooltip> */}
                </div>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={states.subLocationList}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={states.page}
                        pageSize={states.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                {this.renderAddSubLocation()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(SubLocation)

import React, { Component } from 'react'
import '../Canteen.css'
import { Box, Button, Paper, Tooltip, Stack, Typography, Drawer, TextField, FormControlLabel, Checkbox, RadioGroup, Radio, IconButton, Menu, MenuItem, Grow } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { setCachevalue } from '../../../Utility/Services/CacheProviderService';
import ChangePaymentMode from '../../FrontOffice/BillingTransaction/ChangePaymentMode';


class CanteenReceiptBill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      receiptBillData: [],
      totalBill: '',
      page: 0,
      pageSize: 10,
      isLoader: false,
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      receiptNoFrom: '',
      receiptNoTo: '',
      isCash: true,
      isCard: true,
      isUPI: true,
      isBankTrans: true,
      isCheque: true,
      isCombined: true,
      PayMethods: [1, 2, 3, 4, 5],
      AgainstCredit: 'both',
      isErrorMsg: false,
      isErrorMsgText: '',
      ChangePaymentPopup: false,
      successMsg: false,
      successMsgText: '',
      showRACard: false,
      showRFcard: false,
      showNFcard: false,
      showCancelcard: false,
      total_RA_Amt: 0,
      RA_Cash: 0,
      RA_Card: 0,
      RA_upi: 0,
      RA_bank: 0,
      total_RF_Amt: 0,
      RF_Cash: 0,
      RF_upi: 0,
      RF_bank: 0,
      total_Can_Amt: 0,
      Can_Cash: 0,
      Can_upi: 0,
      Can_bank: 0,
      total_Net_Amt: 0,
      Net_Cash: 0,
      Net_Card: 0,
      Net_upi: 0,
      Net_bank: 0,

    }
  }

  componentDidMount() {
    this.getCanteenReceiptBill()
  }

  getCanteenReceiptBill = () => {
    try {
      let states = this.state
      let againstCredit = states.AgainstCredit === 'both' ? '' : "&against_credit=" + states.AgainstCredit
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_RECEIPTBILL + `?from_date=${states.fromDate}&to_date=${states.toDate}&bill_no_from=${states.receiptNoFrom}&bill_no_to=${states.receiptNoTo}&payment_method=${states.PayMethods}${againstCredit}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              receiptBillData: response.data.data,
              totalBill: response.data?.no_of_bills,
              total_RA_Amt: response.data?.total_amount ? response.data?.total_amount : 0,
              RA_Cash: response.data?.total_cash ? response.data?.total_cash : 0,
              RA_Card: response.data?.total_card ? response.data?.total_card : 0,
              RA_upi: response.data?.total_upi ? response.data?.total_upi : 0,
              RA_bank: response.data?.total_bank ? response.data?.total_bank : 0,
              total_RF_Amt: response.data?.refund_total_amount ? response.data?.refund_total_amount : 0,
              RF_Cash: response.data?.refund_cash ? response.data?.refund_cash : 0,
              RF_upi: response.data?.refund_upi ? response.data?.refund_upi : 0,
              RF_bank: response.data?.refund_bank ? response.data?.refund_bank : 0,
              total_Can_Amt: response.data?.cancel_total_amount ? response.data?.cancel_total_amount : 0,
              Can_Cash: response.data?.cancel_cash ? response.data?.cancel_cash : 0,
              Can_upi: response.data?.cancel_upi ? response.data?.cancel_upi : 0,
              Can_bank: response.data?.cancel_bank ? response.data?.cancel_bank : 0,
              total_Net_Amt: response.data?.net_total_amount ? response.data?.net_total_amount : 0,
              Net_Cash: response.data?.net_cash ? response.data?.net_cash : 0,
              Net_Card: response.data?.net_card ? response.data?.net_card : 0,
              Net_upi: response.data?.net_upi ? response.data?.net_upi : 0,
              Net_bank: response.data?.net_bank ? response.data?.net_bank : 0,
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }
  PrintReceiptBill(id) {
    try {
      RestAPIService.PrintPDF(Serviceurls.CANTEEN_RECEIPT_PRINT + `?receipt_id=${id ? id : null}`).
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }
  successMessage = (msg) => {
    this.setState({
      successMsg: true,
      successMsgText: msg
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.fromDate) },
      { label: "To Date", value: formatDate(this.state.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }
  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  clearFilter = () => {
    this.setState({
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      receiptNoFrom: '',
      receiptNoTo: '',
      isCash: true,
      isCard: true,
      isUPI: true,
      isBankTrans: true,
      isCheque: true,
      isCombined: true,
      PayMethods: [1, 2, 3, 4, 5],
      AgainstCredit: 'both'
    }, () => {
      this.getCanteenReceiptBill()
    })
  }

  searchFilter = () => {
    let states = this.state
    if (states.receiptNoFrom !== '') {
      if (states.receiptNoTo !== '' && states.receiptNoFrom <= states.receiptNoTo) {
        this.getCanteenReceiptBill()
        this.setState({ filterOpen: false, });
      } else {
        if (states.receiptNoTo === '') {
          this.errorMessage("Please Enter Receipt To Number");
        } else {
          this.errorMessage("Receipt To No is Greater than Receipt From No");
        }
      }
    } else if (states.receiptNoTo !== '') {
      if (states.receiptNoFrom !== '' && states.receiptNoFrom <= states.receiptNoTo) {
        this.getCanteenReceiptBill()
        this.setState({ filterOpen: false, });
      } else {
        if (states.receiptNoFrom === '') {
          this.errorMessage("Please Enter Receipt From Number");
        } else {
          this.errorMessage("Receipt To No is Greater than Receipt From No");
        }
      }
    } else {
      this.getCanteenReceiptBill()
      this.setState({ filterOpen: false, });
    }
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box className='eMed_Canteen_FilterBox' >
        <Box className='eMed_CanteenFilter_Head'>
          <Typography variant='h6' fontWeight={600}>Filter</Typography>
          <Tooltip>
            <Button onClick={() => { this.setState({ filterOpen: false }) }}>
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
            </Button>
          </Tooltip>
        </Box>
        <Box className='eMed_Canteen_fil_content'>
          <Box className='eMed_Canteen_Date'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box className='eMed_Canteen_fil_text'>
            <TextField
              inputProps={{ maxLength: 10 }}
              className="eMed_Bill_Filter_TextFeild_small"
              sx={{ marginRight: '1vw' }}
              size='small'
              label={"Receipt No From"}
              value={this.state.receiptNoFrom}
              autoComplete='off'
              onChange={(e) => {
                let value = e.target.value
                if (CommonValidation.numberOnly(value) || value === '') {
                  this.setState({
                    receiptNoFrom: value
                  })
                }
              }}
            />
            <TextField
              inputProps={{ maxLength: 10 }}
              className="eMed_Bill_Filter_TextFeild_small"
              size='small'
              label={"Receipt No To"}
              value={this.state.receiptNoTo}
              onChange={(e) => {
                let value = e.target.value
                if (CommonValidation.numberOnly(value) || value === '') {
                  this.setState({
                    receiptNoTo: value
                  })
                }
              }}
            />
          </Box>
          <Box marginTop={'1.5vw'}>
            <Box className="eMed_Filter_Header">
              <Typography marginLeft={"1vw"}>{"Payment Method"}</Typography>
            </Box>
            <Box className="eMed_CheckBox_Group">
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox checked={this.state.isBankTrans} onChange={(e) => { this.handleCheckBox(e, "isBankTrans") }} size='small' />} label={t("BankTransfer")} />
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox disabled={this.state.PayMethods?.length >= 2 ? false : true} checked={this.state.isCombined} onChange={(e) => { this.handleCheckBox(e, "isCombined") }} size='small' />} label={t("Combined")} />

            </Box>
          </Box>
          <Box component={'div'}>
            <Box className="eMed_Filter_Header">
              <Typography marginLeft={"1vw"}>{t("Against Credit")}</Typography>
            </Box>
            <Box component={'div'} className="eMed_CheckBox_Group">
              <RadioGroup
                value={this.state.AgainstCredit}
                sx={{ display: 'flex', flexDirection: 'row' }}
                onChange={(e) => {
                  this.setState({ AgainstCredit: e.target.value })
                }}
              >
                <FormControlLabel value={'true'} control={<Radio size='small' />} label="Yes" />
                <FormControlLabel value={'false'} control={<Radio size='small' />} label="No" />
                <FormControlLabel value={'both'} control={<Radio size='small' />} label="Both" />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
        <Box>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => { this.clearFilter() }}>Clear</Button>
            <Button size='small' variant='contained' disabled={this.state.PayMethods.length === 0} className='eMed_Canteen_BtnText' onClick={() => { this.searchFilter() }}>Search</Button>
          </Stack>
        </Box>
      </Box>
    )
  }

  handleCheckBox = (e, key) => {
    this.state[key] = e.target.checked
    let paymentmethd = []
    if (this.state.isCash) {
      paymentmethd.push(1)
    }
    if (this.state.isCard) {
      paymentmethd.push(2)
    }
    if (this.state.isUPI) {
      paymentmethd.push(3)
    }
    if (this.state.isBankTrans) {
      paymentmethd.push(5)
    }
    if (this.state.isCheque) {
      paymentmethd.push(4)
    }
    this.state.PayMethods = paymentmethd
    this.setState({
      isCash: this.state.isCash,
      isCard: this.state.isCard,
      isUPI: this.state.isUPI,
      isBankTrans: this.state.isBankTrans,
      isCheque: this.state.isCheque,
      isCombined: paymentmethd?.length >= 2 ? true : false,
      PayMethods: this.state.PayMethods,
    })
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "ChangePaymentMode", label: "Change Payment Mode" },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option}
                // disabled={option.value === "ChangePaymentMode" ? ((data?.row?.amount_received && PaymentChangeAccess) ? false : true) : false}
                onClick={() => {
                  this.setState({ anchorEl: null }, () => {
                    if (option.value === "ChangePaymentMode") {
                      this.setState({ ChangePaymentPopup: true, SelectedBill: data?.row })
                    }
                  })
                }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  renderTbl = () => {
    const { t } = this.props

    this.state.receiptBillData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "invoice_date", headerName: "Date", flex: 0.15, align: "center", headerAlign: "center",
        renderCell: (params) => (params?.row?.invoice_date ? params?.row?.invoice_date : '-')
      },
      {
        field: "receipt_number", headerName: "Receipt Number", flex: 0.15, type: 'number',
        renderCell: (params) => (params?.row?.receipt_number ? params?.row?.receipt_number : '-')
      },
      {
        field: "bill_no", headerName: "Bill Number", flex: 0.15, type: "number",
        renderCell: (params) => (params?.row?.bill_no?.length > 15 ?
        <Tooltip title={params?.row?.bill_no} placement='top' arrow><Typography>{'...' + params?.row?.bill_no.slice(-15) }</Typography></Tooltip> :
        <Typography>{params?.row?.bill_no ? params?.row?.bill_no : '-'}</Typography>)
      },
      {
        field: "payment_mode_types", headerName: "Payment Method", flex: 0.10, type: "string",
        renderCell: (params) => (params?.row?.payment_mode_types?.length > 15 ?
          <Tooltip title={params?.row?.payment_mode_types} placement='top' arrow><Typography>{params?.row?.payment_mode_types.slice(0, 15) + '...' }</Typography></Tooltip> :
          <Typography>{params?.row?.payment_mode_types ? params?.row?.payment_mode_types : '-'}</Typography>)
      },
      {
        field: "total_amount", headerName: "Amount", flex: 0.15, type: 'number',
        renderCell: (params) => (params?.row?.total_amount ? params?.row?.total_amount : '0')
      },
      {
        field: "created_by", headerName: "Collected By", flex: 0.18, type: 'string',
        renderCell: (params) => (params?.row?.created_by ? params?.row?.created_by : '0')
      },
      {
        field: "action", headerName: "Action", flex: 0.12, align: "center", headerAlign: "center",
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={t("PrintBill")} placement="top" arrow>
            <Button onClick={() => { this.PrintReceiptBill(params.row?.id) }} className='eMed_usrconf_btn'>
              <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          {this.MenuItem(params)}
        </Box>
      },
    ]
    return (
      <Box height={'64vh'}>
        <DataGrid
          rows={this.state.receiptBillData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          hideFooterSelectedRowCount
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          headerHeight={40}
          components={{
            Toolbar: this.gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          rowsPerPageOptions={[10, 20, 30]}
          disableSelectionOnClick
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          loading={this.state.isLoader}
        />
      </Box>
    )
  }
  closeFilter = () => {
    this.setState({ filterOpen: false })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }

  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      FilterOpen: false,
      BillPreviewPop: false,
      ChangePaymentPopup: false,
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
        this.getCanteenReceiptBill()
      }
    })
  }
  PaymentModeChanged(message) {
    this.setState({
      ChangePaymentPopup: false,
    }, () => {
      this.successMessage(message)
      this.getCanteenReceiptBill()
    })
  }

  render() {
    const {t} = this.props
    return (
      <Box className='eMed_Canteen_Menuitem_Con' sx={{ backgroundColor: Colors.Background }}>
        <Box className='eMed_Canteen_TopCon'>
          <Box className='eMed_Canteen_CardCon'>
            {AmountsCard('Total Bill', this.state.totalBill ? this.state.totalBill : 0, true, "white", false, null, 'camteen_no_bill')}
            <Button className='eMed_Recpt_header_amt_card' sx={{ marginLeft: "0.1vw", }}
              onClick={() => { this.setState({ showRACard: !this.state.showRACard, showRFcard: false, showNFcard: false, showCancelcard: false, }) }}>
              {AmountsCard("Received Amount", this.state.total_RA_Amt ? this.state.total_RA_Amt : 0, false, this.state.showRACard ? Colors.Primary : "white", true, this.state.showRACard ? "white" : Colors.Primary, 'canteen_ra_amount')}
            </Button>
            <Button className='eMed_Recpt_header_amt_card' sx={{ marginLeft: "0.1vw", }}
              onClick={() => { this.setState({ showRACard: false, showRFcard: !this.state.showRFcard, showNFcard: false, showCancelcard: false, }) }}>
              {AmountsCard(t("Refund Amount"), this.state.total_RF_Amt ? this.state.total_RF_Amt : 0, false, this.state.showRFcard ? Colors.Primary : "white", true, this.state.showRFcard ? "white" : Colors.Primary, 'canteen_rf_amount')}
            </Button>
            <Button className='eMed_Recpt_header_amt_card' sx={{ marginLeft: "0.1vw", }}
              onClick={() => { this.setState({ showRACard: false, showRFcard: false, showNFcard: false, showCancelcard: !this.state.showCancelcard, }) }}>
              {AmountsCard(t("Cancelled Amount"), this.state.total_Can_Amt ? this.state.total_Can_Amt : 0, false, this.state.showCancelcard ? Colors.Primary : "white", true, this.state.showCancelcard ? "white" : Colors.Primary, 'canteen_cancel_amount')}
            </Button>
            <Button className='eMed_Recpt_header_amt_card' sx={{ marginLeft: "0.1vw", }}
              onClick={() => { this.setState({ showRACard: false, showRFcard: false, showNFcard: !this.state.showNFcard, showCancelcard: false, }) }}>
              {AmountsCard(t("Net Amount"), this.state.total_Net_Amt ? this.state.total_Net_Amt : 0, false, this.state.showNFcard ? Colors.Primary : "white", true, this.state.showNFcard ? "white" : Colors.Primary, 'canteen_net_amount')}
            </Button>
            <Box component={'div'} sx={{ width: '30vw', overflow: 'scroll', marginLeft: '1vw', height: "5.5vw", display: 'flex', alignItems: 'center' }} >
              {this.state.showRACard ?
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                  <Grow in={this.state.showRACard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showRACard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.RA_Cash, false, "white", false, null, 'canteen_recev_cash')}</Grow>
                  <Grow in={this.state.showRACard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showRACard ? { timeout: 1000 } : {})}>{AmountsCard(t("Card"), this.state.RA_Card, false, "white", false, null, 'canteen_recev_card')}</Grow>
                  <Grow in={this.state.showRACard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showRACard ? { timeout: 1500 } : {})}>{AmountsCard(t("UPI"), this.state.RA_upi, false, "white", false, null, 'canteen_recev_upi')}</Grow>
                  <Grow in={this.state.showRACard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showRACard ? { timeout: 2000 } : {})}>{AmountsCard(t("Bank"), this.state.RA_bank, false, "white", false, null, 'canteen_recev_bank')}</Grow>
                </Box> : null}
              {this.state.showRFcard ?
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                  <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showRFcard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.RF_Cash, false, "white", false, null, 'canteen_rtn_cash')}</Grow>
                  <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showRFcard ? { timeout: 1000 } : {})}>{AmountsCard(t("UPI"), this.state.RF_upi, false, "white", false, null, 'canteen_rtn_upi')}</Grow>
                  <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showRFcard ? { timeout: 1500 } : {})}>{AmountsCard(t("Bank"), this.state.RF_bank, false, "white", false, null, 'canteen_rtn_bank')}</Grow>
                </Box> : null}
              {this.state.showCancelcard ?
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                  <Grow in={this.state.showCancelcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showCancelcard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.Can_Cash, false, "white", false, null, 'canteen_net_cash')}</Grow>
                  <Grow in={this.state.showCancelcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showCancelcard ? { timeout: 1500 } : {})}>{AmountsCard(t("UPI"), this.state.Can_upi, false, "white", false, null, 'canteen_net_upi')}</Grow>
                  <Grow in={this.state.showCancelcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showCancelcard ? { timeout: 2000 } : {})}>{AmountsCard(t("Bank"), this.state.Can_bank, false, "white", false, null, 'canteen_net_bank')}</Grow>
                </Box> : null}
              {this.state.showNFcard ?
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                  <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showNFcard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.Net_Cash, false, "white", false, null, 'canteen_rtn_cash')}</Grow>
                    <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showNFcard ? { timeout: 1000 } : {})}>{AmountsCard(t("Card"), this.state.Net_Card, false, "white", false, null, 'canteen_recev_card')}</Grow>
                  <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showNFcard ? { timeout: 1000 } : {})}>{AmountsCard(t("UPI"), this.state.Net_upi, false, "white", false, null, 'canteen_rtn_upi')}</Grow>
                  <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showNFcard ? { timeout: 1500 } : {})}>{AmountsCard(t("Bank"), this.state.Net_bank, false, "white", false, null, 'canteen_rtn_bank')}</Grow>
                </Box> : null}
            </Box>
          </Box>
          <Box className='eMed_Canteen_FilterCon'>
            <Tooltip title="Filter" placement='top' arrow>
              <Button onClick={() => { this.setState({ filterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_Canteen_img' />
              </Button>
            </Tooltip>
            {/* <Box sx={{ borderRight: '1px solid #616161', height: '2vw', marginBottom: '.4vw' }}></Box>
            <Tooltip title="Print" placement='top' arrow>
              <Button>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_Canteen_img' />
              </Button>
            </Tooltip> */}
          </Box>
        </Box>
        <Paper className='eMed_Canteen_SecondCon' elevation={2}>
          {this.renderTbl()}
        </Paper>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          /> : null}
        {
          this.state.ChangePaymentPopup ? <ChangePaymentMode isFromCanteen={true} BillData={this.state.SelectedBill} PaymentModeChanged={this.PaymentModeChanged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
        }
      </Box>
    )
  }
}
export default withTranslation()(CanteenReceiptBill);
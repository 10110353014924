import React, { Component } from 'react'
import ComingSoon from '../../Upcomingpages/ComingSoon'

export default class RadiologyBilling extends Component {
  render() {
    return (
      <ComingSoon/>
    )
  }
}

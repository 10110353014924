import { Box, Button, Drawer, Stack, TextField } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import SubLocReportFilter from './SubLocReportFilter'
import { DateTime } from 'luxon'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { CheckAccessFunc, formatDate } from '../../../Components/CommonFunctions/CommonFunctions'

const data = [
  { brand_name: 'Test', generic_name: "Generic", dosage_type: "Tablet", dosage_strength: '20 ML', available_qty: '50', request_qty: null },
  { brand_name: 'Test Two', generic_name: "Generic Two", dosage_type: "Tablet", dosage_strength: '65 ML', available_qty: '50', request_qty: null },
]
export default class SubLocStockExpiry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stockExpiryData: [],
      page: 0,
      pageSize: 10,
      returnDrugList: [],
      filterDatas: {
        'months': 1,
        'brandName':''
      },
      pageName: "StockExpiry",
      filterOpen: false
    }
  }

  componentDidMount() {
    this.getStockExpiryListData()
  }

  getStockExpiryListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_STOCK_EXPIRY_REPORT_GET + `?month=${this.state.filterDatas.months}&brand_name=${this.state.filterDatas.brandName}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              stockExpiryData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getShortExpiryPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_STOCK_EXPIRY_REPORT_GET + `?export_type=pdf&month=${this.state.filterDatas.months}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
      this.getStockExpiryListData()
    })
  }

  gridToolBar = () => {
    let TextArray = [
      // { label: "From Date", value: this.state.filterDatas.fromDate },
      // { label: "To Date", value: this.state.filterDatas.toDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }


  onClickCheckBoxHandler = (ids) => {
    try {
      const selectedIds = new Set(ids)
      const selectedRows = this.state.stockExpiryData?.filter((row) => selectedIds.has(row.sno))
      this.setState({ returnDrugList: selectedRows })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    this.state.stockExpiryData.forEach((element, index) => element.sno = index + 1)
    const column = [
      {
        field: 'brand_name', headerName: 'Brand Name', flex: 0.143,
        renderCell: (params) => (<Box>{params?.row?.brand_name ? params?.row?.brand_name : '-'}</Box>)
      },
      {
        field: 'dosage_type', headerName: 'Dosage Type', flex: 0.143,
        renderCell: (params) => (<Box>{params?.row?.dosage_type ? params?.row?.dosage_type : '-'}</Box>)
      },
      {
        field: 'dosage_strength', headerName: 'Dosage Strength', flex: 0.143,
        renderCell: (params) => (<Box>{params?.row?.dosage_strength ? params?.row?.dosage_strength : '-'}</Box>)
      },
      {
        field: 'batch_no', headerName: 'Batch No.', flex: 0.143,
        renderCell: (params) => (<Box>{params?.row?.batch_no ? params?.row?.batch_no : '-'}</Box>)
      },
      {
        field: 'expiry_date', headerName: 'Expiry Date', flex: 0.143,
        renderCell: (params) => (<Box>{params?.row?.expiry_date ? (params?.row?.expiry_date) : '-'}</Box>)
      },
      {
        field: 'stock_in_quantity', headerName: 'Available Stock', flex: 0.143,
        renderCell: (params) => (<Box>{params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity : '-'}</Box>)
      },
      {
        field: 'request_qty', headerName: 'To Location', flex: 0.143,
        renderCell: (params) => (
          <Box>{params?.row?.available_qty ? params?.row?.available_qty : '-'}</Box>
        )
      }
    ]
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '80vh' }}>
        <Box component={'div'} height={'8vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <Button
              size='small'
              disabled={CheckAccessFunc("sub_location", "Reports", "Short Expiry",  null, "Tab")?.editAccess === false}
              sx={{ mr: '1vw', height: '2vw', textTransform: 'capitalize' }}
              variant='contained'
              endIcon={this.state.returnDrugList?.length ? `(${this.state.returnDrugList?.length})` : null}
              onClick={() => {
                if (this.state.returnDrugList?.length > 0) {
                  this.props.history.push({ pathname: "/SubLocationStockTransfer/RaiseRequest", state: { drugRequestList: this.state.returnDrugList, page: "stockExpiry" } })
                } else {
                  this.errorMessage("Select atleast one drug from the list")
                }
              }}
            >Return Request</Button>
          </Box>
          <Box component={'div'} borderLeft={'2px solid gray'} pl={'1vw'}>
            <Button
              className='Common_Btn_Min_Width'
              sx={{ height: '2.5vw', width: '2.5vw' }}
              onClick={() => this.setState({ filterOpen: true })}
            >
              <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} alt='filter' />
            </Button>
            <Button
              className='Common_Btn_Min_Width'
              sx={{ height: '2.5vw', width: '2.5vw',mr: '0.5vw' }}
              onClick={() => this.getShortExpiryPrint()}
            >
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} alt='filter' />
            </Button>
            {/* <Button className='Common_Btn_Min_Width' sx={{ height: '2.5vw', width: '2.5vw', mr: '0.5vw' }}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} alt='filter' />
            </Button> */}
          </Box>
        </Box>
        <Box component={'div'} height={'72vh'} padding={'0.5vw'}>
          <DataGrid
            checkboxSelection
            onSelectionModelChange={(ids) => { this.onClickCheckBoxHandler(ids) }}
            sx={{ backgroundColor: 'white' }}
            columns={column}
            disableColumnFilter
            rows={this.state.stockExpiryData}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <SubLocReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

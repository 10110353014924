import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Loader from '../../../Components/Loader';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { Card } from 'antd';
import { Colors } from '../../../Styles/Colors';
import { CurrencySymbol } from '../../../Utility/Constants';

class SummeryReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            BillList: [],
            TotalAmount: 0,
            PaidAmount: 0,
            PendingAmount: 0,
        }
    }

    componentDidMount() {
        this.GetBillList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetBillList() {
        try {
            var states = this.state
            let doctorId = [];
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.SUMMERY_BILL_REPORT).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BillList: response.data.data?.summary,
                            TotalAmount: response.data?.data?.total_bill_amount,
                            PaidAmount: response.data?.data?.total_received_amount,
                            PendingAmount: response.data?.data?.total_balance_amount,
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    returnColor(amount) {
        if (amount === undefined || amount === null || amount === 0) {
            return Colors.black
        }
        else if (amount < 0) {
            return Colors.red
        }
        else if (amount > 0) {
            return 'green'
        }
        else {
            return Colors.black
        }
    }
    LongAmountsCard = (label, amount, TitleCard, ColorDiff = false ) => {
        return (
            <Card elevation={3} className='eMed_Amt_card_div' sx={{ width: '13vw' }}>
                <div className='eMed_Amt_card' style={{ width: TitleCard ? "14vw":'13vw' }}>
                    <Typography sx={{ fontWeight: "600", fontSize: '1vw', color: ColorDiff ? this.returnColor(+amount) : Colors.black }} className='eMed_Amt_card_amount'>{(amount === undefined || amount === null) ? `${CurrencySymbol} 0` : AmountFormat(amount)}</Typography>
                    <Typography sx={{ fontSize: "0.8vw", textTransform: 'capitalize', fontWeight: !TitleCard ? null: "600",  }} className='eMed_Amt_card_label'>{label.split('_').join(' ')}</Typography>
                </div>
            </Card>
        )
    }
    render() {
        const { t } = this.props
        const { history } = this.props;
        try{
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {this.LongAmountsCard(t("Total Bill Amount"), this.state.TotalAmount, true)}
                        {this.LongAmountsCard(t("Total Received Amount"), this.state.PaidAmount, true)}
                        {this.LongAmountsCard(t("Total Balance Amount"), this.state.PendingAmount, true, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>

                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    {
                        this.state.BillList.length > 0 ?
                            this.state.BillList.map((item, index) => {
                                let Module = Object.keys(item)[0];
                                let CardItems = item[Module]
                                let FinalCards = []
                                Object.keys(CardItems).forEach((key) => { FinalCards.push({ key: key, value: CardItems[key] }) })
                                return (
                                    <Box>
                                        <Box className="eMed_Summery_Cards_wrapper">
                                            <Typography width={"8vw"} textTransform={'capitalize'} fontWeight={600}>{Object.keys(item)[0]}</Typography>
                                            <Box className="eMed_Summery_Cards_row">
                                                {FinalCards.length > 0 ?
                                                    FinalCards.map((item) => (
                                                        this.LongAmountsCard(item.key, item.value,false, (item.key === "total_balance_amount"))
                                                    )) : null}
                                            </Box>
                                        </Box>
                                        <Divider />
                                    </Box>
                                )
                            }

                            ) :
                            <Box className="eMed_Summary_Report_Loader_comp"> <CircularProgress/> </Box>
                    }
                </Box>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {/* <Loader loaderOpen={this.state.isLoader} /> */}
            </Box>
        )}
        catch(e){
            this.errorMessage(e.message)
        }
    }
}
export default withTranslation()(SummeryReport)